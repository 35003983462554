// V1
import "./EditPost.css";
import Card from "../Card";
import Button from "../Button";
import Loader from "../Loader";
import { useRef, useState, useEffect } from "react";
// import bold from "../../icons/bold.svg";
// import italic from "../../icons/italic.svg";
// import underline from "../../icons/underline.svg";
// import link from "../../icons/link.svg";
// import smile from "../../icons/smile.svg";
import { ReactComponent as ImageIcon } from "../../icons/image-thick.svg";
import { ReactComponent as VideoIcon } from "../../icons/video.svg";
import { ReactComponent as FolderIcon } from "../../icons/folder-outline.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import ReactQuill, { Quill } from "react-quill";
import QuillMention from "quill-mention";
import quillEmoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { useMutation } from "@apollo/client";
import { UPDATE_POST } from "../../api/post";
import { SEARCH } from "../../api/user";
import { client } from "../../utils/client";
import makeFileUrl from "../../utils/makeFileUrl";
import SelectMedia from "../SelectMedia";
import YouTubeVideo from "../YouTubeVideo";
import { useAppState } from "../../utils/appState";
import captureException from "../../utils/captureException";

Quill.register("modules/mentions", QuillMention);
Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true
);

const CustomToolbar = () => (
  <div id="custom-toolbar">
    <div className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-link"></button>
      <button className="ql-emoji"></button>
    </div>
  </div>
);

async function suggestPeople(searchTerm) {
  const result = await client.query({
    query: SEARCH,
    variables: {
      term: searchTerm.trim(),
    },
  });

  // console.log("Mention result:", result);
  return [
    ...result.data.search.profiles.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/profile/" + x.id,
      type: "profile",
    })),
    ...result.data.search.organizations.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/page/" + x.id,
      type: "organization",
    })),
  ];
}

const mentionModule = {
  allowedChars: /^[A-Za-z\s]*$/,
  mentionDenotationChars: ["@"],
  minChars: 2,
  dataAttributes: [
    "id",
    "value",
    "denotationChar",
    "link",
    "target",
    "disabled",
    "type",
  ],
  source: async function (searchTerm, renderList, mentionChar) {
    if (!searchTerm || searchTerm.length === 0) {
      // console.log("No term");
      renderList([], searchTerm);
    }
    // console.log("Searching");
    const matches = await suggestPeople(searchTerm);
    // console.log("Matches:", matches);
    renderList(matches);
  },
  renderItem: function (item, searchTerm) {
    // console.log(item);
    const profilePhoto = item.profilePhoto
      ? makeFileUrl(item.profilePhoto)
      : false;
    return `<div class="mention-item">
        <div class="mention-item__image">${
          profilePhoto ? `<img src=${profilePhoto} />` : ""
        }</div>
        <div class="mention-item__text">
          <div class="mention-item__text__primary">${item.name}</div>
          <div class="mention-item__text__secondary">@${item.handle}</div>
        </div>
      </div>`;
  },
};

export default function EditPost({
  post,
  queryInfo,
  dismiss,
  sourceId,
  sourceType,
  mediaQueries,
}) {
  const [editing, setEditing] = useState(true);
  const [content, setContent] = useState(post.content);
  const [truncatedContent, setTruncatedContent] = useState(
    post.truncatedContent
  );
  const [postPhotos, setPostPhotos] = useState(
    post.tags
      .filter((t) => t.media && t.media.type === "IMAGE")
      .map((t) => t.media) || []
  );
  const [postVideos, setPostVideos] = useState(
    post.tags
      .filter((t) => t.media && t.media.type === "VIDEO")
      .map((t) => t.media) || []
  );
  const [postAlbums, setPostAlbums] = useState(
    post.tags.filter((t) => t.mediaSet).map((t) => t.mediaSet) || []
  );
  const [contentLength, setContentLength] = useState(0);
  const [, setAppState] = useAppState();
  const editorRef = useRef(null);

  // const sourceId = post.source.id;
  // const sourceType = post.sourceType;

  useEffect(() => {
    if (editorRef?.current) {
      // console.log("Focusing editor");
      editorRef.current.focus();
      setContentLength(editorRef.current.getEditor().getLength());
    }
  }, [editing]);

  const [updatePost, { loading }] = useMutation(UPDATE_POST, {
    update: (cache, { data: { updatePost } }) => {
      if (queryInfo?.query) {
        const q = cache.readQuery({
          query: queryInfo.query,
          variables: queryInfo.variables,
        });

        cache.writeQuery({
          query: queryInfo.query,
          variables: queryInfo.variables,
          data: { ...queryInfo.onUpdateData(q, updatePost) },
        });
      } else {
        if (queryInfo?.onUpdateData) {
          queryInfo.onUpdateData(cache, updatePost);
        }
      }

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EditPost",
          mutation: "UPDATE_POST",
        },
      });
      console.log(error);
    },
  });

  const onContentChange = (newContent, delta, source, editor) => {
    // console.log(editor.getContents());

    const text = editor.getText().trim();
    if (!text) {
      setTruncatedContent(false);
      setContent(false);
    } else {
      // console.log(editor.getLength());
      if (editor.getLength() > 200) {
        setTruncatedContent(editor.getContents(0, 200).insert("..."));
      } else {
        setTruncatedContent(editor.getContents());
      }
      setContent(editor.getContents());
    }

    setContentLength(editor.getLength());
  };

  // console.log(post);

  return (
    <Card className="create-post-card">
      <div className="avatar" />
      {editing ? (
        <div className="input-wrapper">
          {/*<div className="media-selector hover-pointer">
            <ImageIcon style={{ height: 12 }} /> Media
      </div>*/}
          <ReactQuill
            id={"create-post-editor"}
            ref={editorRef}
            defaultValue={content}
            onChange={onContentChange}
            placeholder={"Say something..."}
            modules={{
              toolbar: {
                container: "#custom-toolbar",
              },
              // toolbar: [["bold", "italic", "underline", "link", "emoji"]],
              mention: mentionModule,
              "emoji-toolbar": true,
              "emoji-textarea": false,
              "emoji-shortname": true,
            }}
          />
          {(!!postPhotos.length ||
            !!postVideos.length ||
            !!postAlbums.length) && (
            <div className="selected-media-container">
              {postPhotos.map((m) => (
                <div
                  className="selected-media-container__preview"
                  onClick={() => {
                    setPostPhotos(postPhotos.filter((x) => x.id !== m.id));
                  }}
                >
                  <img src={makeFileUrl(m)} alt="" />
                  <div className="selected-media-container__preview__overlay">
                    <div className="media-type">
                      <ImageIcon className="media-type__icon" />
                    </div>
                    <div className="deselect">
                      <TimesIcon className="deselect-icon" />
                    </div>
                  </div>
                </div>
              ))}

              {postVideos.map((m) => (
                <div
                  className="selected-media-container__preview"
                  onClick={() => {
                    setPostVideos(postVideos.filter((x) => x.id !== m.id));
                  }}
                >
                  {/* <img src={makeFileUrl(m)} /> */}
                  <div className="video">
                    {m.isExternal ? (
                      <YouTubeVideo filename={m.filename} height={64} />
                    ) : (
                      <video controlsList="nodownload">
                        <source src={makeFileUrl(m)} type="video/mp4" />
                      </video>
                    )}
                  </div>
                  <div className="selected-media-container__preview__overlay">
                    <div className="media-type">
                      <VideoIcon className="media-type__icon" />
                    </div>
                    <div className="deselect">
                      <TimesIcon className="deselect-icon" />
                    </div>
                  </div>
                </div>
              ))}

              {postAlbums.map((m) => (
                <div
                  className="selected-media-container__preview"
                  onClick={() => {
                    setPostAlbums(postAlbums.filter((x) => x.id !== m.id));
                  }}
                >
                  {/* <img src={makeFileUrl(m)} /> */}
                  {m.preview ? (
                    <img src={makeFileUrl(m.preview)} alt="" />
                  ) : (
                    <ImageIcon className="placeholder-icon" />
                  )}
                  <div className="selected-media-container__preview__overlay">
                    <div className="media-type">
                      <FolderIcon className="media-type__icon" />
                    </div>
                    <div className="deselect">
                      <TimesIcon className="deselect-icon" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="custom-toolbar-wrapper">
            <CustomToolbar />

            <div className="media-options">
              <div
                className="attach-image-btn"
                onClick={() => {
                  setAppState({
                    modal: (
                      <SelectMedia
                        sourceId={sourceId}
                        sourceType={sourceType}
                        photosQuery={mediaQueries.photos.query}
                        photosQueryVars={
                          mediaQueries.photos.vars || { id: sourceId }
                        }
                        videosQuery={mediaQueries.videos.query}
                        videosQueryVars={
                          mediaQueries.videos.vars || { id: sourceId }
                        }
                        albumsQuery={mediaQueries.albums.query}
                        albumsQueryVars={{ id: sourceId }}
                        getPhotos={mediaQueries.photos.getPhotos}
                        getPhotosCount={mediaQueries.photos.getPhotosCount}
                        getVideos={mediaQueries.videos.getVideos}
                        getVideosCount={mediaQueries.videos.getVideosCount}
                        getAlbums={mediaQueries.albums.getAlbums}
                        getAlbumsCount={mediaQueries.albums.getAlbumsCount}
                        onSubmitSelection={(photos, videos, albums) => {
                          setPostPhotos(photos);
                          setPostVideos(videos);
                          setPostAlbums(albums);
                        }}
                        defaultValue={{
                          photos: postPhotos,
                          videos: postVideos,
                          albums: postAlbums,
                        }}
                        onUploadPhoto={mediaQueries.photos.onUploadPhoto}
                        onUploadVideo={mediaQueries.videos.onUploadVideo}
                      />
                    ),
                  });
                }}
              >
                <ImageIcon
                  style={{
                    stroke: "var(--dark-4)",
                    height: "16px",
                    width: "24px",
                  }}
                />
                {/* ADD MEDIA */}
              </div>
            </div>

            <div
              className={
                "character-count " + (contentLength - 2 > 1500 ? "red" : "")
              }
            >
              {contentLength - 2 < 0 ? 0 : contentLength - 2}/1500
            </div>
          </div>
        </div>
      ) : (
        <div
          className="input-toggle hover-pointer"
          onClick={() => {
            setEditing(true);
          }}
        >
          Post Something
        </div>
      )}

      <div>
        <Button
          className={
            "medium blue primary submit-post-btn " +
            (!content ? "disabled" : "")
          }
          onClick={() => {
            if (loading) {
              return;
            }

            if (contentLength - 2 > 1500) {
              return;
            }

            const vars = {
              content,
              truncatedContent,
              tags: {
                profiles: [],
                organizations: [],
                media: [
                  ...postPhotos.map((x) => x.id),
                  ...postVideos.map((x) => x.id),
                ],
                mediaSets: [...postAlbums.map((x) => x.id)],
              },
              contentLength: contentLength - 2,
            };

            content?.ops?.forEach((op) => {
              if (op.insert?.mention) {
                vars.tags[op.insert.mention.type + "s"].push(
                  op.insert.mention.id
                );
              }
            });

            // console.log(vars);

            // console.log(editorRef.current.getText());

            // return;
            // console.log("Update post vars:", vars);
            updatePost({
              variables: {
                where: { id: post.id },
                data: vars,
              },
            });
          }}
        >
          {loading ? <Loader /> : "Save"}
        </Button>

        <Button className="tertiary medium red" onClick={dismiss}>
          Cancel
        </Button>
      </div>
    </Card>
  );
}
