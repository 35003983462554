import "./ProfileOnboarding.css";
import { useState } from "react";
import useRouterQuery from "../../utils/useRouterQuery";
import { Link, useHistory } from "react-router-dom";
import { USER_PROFILE, UPDATE_USER_PROFILE } from "../../api/user";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useQuery, useMutation } from "@apollo/client";
import Dropdown from "../../components/Dropdown";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import { ReactComponent as IndiaFlagIcon } from "../../icons/india-flag.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import Chip from "../../components/Chip";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import Loader from "../../components/Loader";
import isValidPhoneNumber from "../../utils/isValidPhoneNumber";
import moment from "moment";
import Square from "../../mobileComponents/Square";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

function PersonalInfo({ profile }) {
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [invalidPhone, setInvalidPhone] = useState("");
  const [location, setLocation] = useState("");
  const [invalidLocation, setInvalidLocation] = useState("");
  const [dob, setDob] = useState(false);
  const [invalidDob, setInvalidDob] = useState("");
  const [gender, setGender] = useState("");
  const [invalidGender, setInvalidGender] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cities, setCities] = useState([]);

  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    update: (cache, { data: { updateUserProfile } }) => {
      // const q = cache.readQuery({
      //   query: ORGANIZATIONS,
      //   variables: { where: {} },
      // });
      // // console.log("Q:", q);
      // if (q?.organizations) {
      //   cache.writeQuery({
      //     query: ORGANIZATIONS,
      //     variables: { where: {} },
      //     data: {
      //       organizations: [createOrganization, ...q.organizations],
      //     },
      //   });
      // }
      // history.replace("/app/onboard_page/" + createOrganization.id);
      // setStep(2);
      history.push("/onboarding?step=2");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ProfileOnboarding.PersonalInfo",
          mutation: "UPDATE_USER_PROFILE",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="mobile-auth-form">
      <div className="mobile-auth-form__header">
        <div className="mobile-auth-form__header__primary">
          Set up your profile
        </div>
        <div className="mobile-auth-form__header__secondary">
          Step 1 of 3 : Enter your personal details
        </div>
      </div>

      <form
        className="mobile-auth-form__form"
        onSubmit={(e) => {
          // console.log("Form submitted");
          e.preventDefault();

          //   console.log("Cities:", cities);
          //   return;

          if (loading) return;

          if (
            !phone ||
            !isValidPhoneNumber(phone) ||
            !dob ||
            !cities.length ||
            !gender
          ) {
            if (!cities.length) {
              setInvalidLocation(true);
            }

            if (!phone || !isValidPhoneNumber(phone)) {
              setInvalidPhone(true);
            }

            if (!dob) {
              setInvalidDob(true);
            }

            return;
          }

          const citiesToConnect = cities.filter((c) => !c.id);
          const citiesToDisconnect = profile.cities.filter(
            (c) => !cities.find((x) => c.id === x.id)
          );

          const v = {
            data: {},
          };

          if (citiesToConnect.length || citiesToDisconnect.length) {
            v.data.cities = {};
            if (citiesToConnect.length) {
              v.data.cities.connect = citiesToConnect.map((c) => ({
                city: c.city,
                state: c.state,
                country: c.country,
                lat: c.lat,
                lng: c.lng,
              }));
            }

            if (citiesToDisconnect.length) {
              v.data.cities.disconnect = citiesToDisconnect.map((c) => ({
                id: c.id,
              }));
            }
          }

          if (phone) {
            v.data.phone = phone;
          }

          if (dob) {
            v.data.dob = moment(dob).toISOString();
          }

          if (gender) {
            v.data.gender = gender;
          }

          // console.log(v);
          // return;

          updateUserProfile({
            variables: v,
          });

          // setErrorMessage("");
        }}
      >
        <div className="mobile-auth-form__form__group">
          <div className="mobile-auth-form__form__group__label">
            Phone <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <div
            className={
              "mobile-auth-form__form__group__field " +
              (invalidPhone ? "error" : "")
            }
          >
            <div className="mobile-auth-form__form__group__field__icon">
              <PhoneIcon style={{ stroke: "var(--light-3)", height: 14 }} />
            </div>

            <input
              type="text"
              className="mobile-auth-form__form__group__field__input"
              value={phone}
              onChange={(e) => {
                if (errorMessage) {
                  setErrorMessage("");
                }
                setPhone(e.target.value);
              }}
              onFocus={() => {
                setInvalidPhone(false);
              }}
            />
          </div>
        </div>

        <div className="onboarding-form-mobile__group">
          <div className="onboarding-form-mobile__group__label">
            Location <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <PlacesAutocomplete
            value={location}
            onChange={(x) => {
              setLocation(x);
              setErrorMessage("");
            }}
            onSelect={(loc) => {
              // console.log(loc);
              const city = loc?.split(",")[0]?.trim();
              const state = loc?.split(",")[1]?.trim();
              const country = loc?.split(",")[2]?.trim();

              geocodeByAddress(loc)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  setCities((prev) => {
                    return [
                      ...prev,
                      {
                        city,
                        state,
                        country,
                        lat: latLng.lat,
                        lng: latLng.lng,
                      },
                    ];
                  });
                })
                .catch((error) => console.error("Error", error));
              setLocation("");
            }}
            debounce={200}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Dropdown
                className="onboarding-form-mobile__dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "onboarding-form-mobile__group__field multi " +
                      (invalidLocation ? "error" : "")
                    }
                  >
                    <div className="onboarding-form-mobile__group__field__icon">
                      <LocationIcon
                        style={{ stroke: "var(--light-3)", height: 14 }}
                      />
                    </div>
                    <div className="onboarding-form-mobile__group__field__multi-select-wrapper">
                      {cities.map((c, i) => (
                        <Chip
                          key={"location" + i}
                          className="multi-select-item"
                          onClick={() => {
                            setCities((prev) => {
                              return [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1, prev.length),
                              ];
                            });
                          }}
                        >
                          <span className="multi-select-item__text">
                            {c.city}
                          </span>{" "}
                          <TimesIcon
                            style={{
                              stroke: "var(--light-1)",
                              height: "14px",
                            }}
                          />{" "}
                        </Chip>
                      ))}
                      {cities.length < 2 && (
                        <input
                          {...getInputProps({
                            className:
                              "onboarding-form-mobile__group__field__input",
                            onFocus: () => {
                              setInvalidLocation(false);
                            },
                            autoComplete: "off",
                          })}
                          value={location}
                          id="location"
                        />
                      )}

                      {/* </div> */}
                    </div>

                    <div className="static-icon" style={{ marginRight: "8px" }}>
                      <IndiaFlagIcon />
                    </div>
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="onboarding-form-mobile__group__field__dropdown-menu">
                    {loading && <Spinner />}
                    {suggestions
                      .filter(
                        (suggestion) =>
                          suggestion?.description?.split(",")?.length &&
                          suggestion?.description?.split(",").length > 2
                      )
                      .map((suggestion, i) => (
                        <div
                          key={"city-suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className:
                              "onboarding-form-mobile__group__field__dropdown-menu__item",
                          })}
                        >
                          {suggestion.description}
                          {/*<span className="state">{city.within.value}</span>*/}
                        </div>
                      ))}
                  </Card>
                )}
                onClose={() => {
                  setLocation("");
                }}
              />
            )}
          </PlacesAutocomplete>
        </div>

        <div className="mobile-auth-form__form__row">
          <div className="mobile-auth-form__form__row__column">
            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                Date of Birth{" "}
                <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <div
                className={
                  "mobile-auth-form__form__group__field " +
                  (invalidDob ? "error" : "")
                }
              >
                {/* <div className="mobile-auth-form__form__group__field__icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div> */}

                <input
                  type="date"
                  className="mobile-auth-form__form__group__field__input"
                  value={dob}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setDob(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidDob(false);
                  }}
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                />
              </div>
            </div>
          </div>
          <div className="mobile-auth-form__form__row__column">
            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Gender <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <Dropdown
                className="onboarding-form-mobile__dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "onboarding-form-mobile__group__field " +
                      (invalidGender ? "error" : "")
                    }
                  >
                    {/* <div className="onboarding-form-mobile__group__field__icon">
                      <LocationIcon
                        style={{ stroke: "var(--light-3)", height: 14 }}
                      />
                    </div> */}
                    <input
                      type="text"
                      value={gender}
                      className="onboarding-form-mobile__group__field__input select"
                      onChange={(e) => {
                        if (errorMessage) {
                          setErrorMessage("");
                        }
                        setGender(e.target.value);
                      }}
                      onFocus={() => {
                        setInvalidGender(false);
                      }}
                      style={{ paddingLeft: "8px" }}
                      disabled
                    />
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="onboarding-form-mobile__group__field__dropdown-menu">
                    <div
                      className="onboarding-form-mobile__group__field__dropdown-menu__item"
                      onClick={() => {
                        setGender("Male");
                        setShowMenu(false);
                      }}
                    >
                      Male
                    </div>

                    <div
                      className="onboarding-form-mobile__group__field__dropdown-menu__item"
                      onClick={() => {
                        setGender("Female");
                        setShowMenu(false);
                      }}
                    >
                      Female
                    </div>

                    <div
                      className="onboarding-form-mobile__group__field__dropdown-menu__item"
                      onClick={() => {
                        setGender("Other");
                        setShowMenu(false);
                      }}
                    >
                      Other
                    </div>
                  </Card>
                )}
              />
            </div>
          </div>
        </div>

        {errorMessage && (
          <div className="mobile-auth-form__form__error">{errorMessage}</div>
        )}

        <DebouncedButton
          type="submit"
          className={"mobile-auth-form__form__submit "}
          loading={loading}
          disableDefaultStyles={true}
        >
          {loading ? <Loader theme="small" /> : "Next"}
        </DebouncedButton>
        {/* <button
          type="submit"
          className={
            "mobile-auth-form__form__submit " + (loading ? "disabled" : "")
          }
        >
          {loading ? <Loader theme="small" /> : "Next"}
        </button> */}
      </form>
    </div>
  );
}

function Interests() {
  const history = useHistory();
  const [interests, setInterests] = useState([]);

  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    update: (cache, { data: { updateUserProfile } }) => {
      // const q = cache.readQuery({
      //   query: ORGANIZATIONS,
      //   variables: { where: {} },
      // });
      // // console.log("Q:", q);
      // if (q?.organizations) {
      //   cache.writeQuery({
      //     query: ORGANIZATIONS,
      //     variables: { where: {} },
      //     data: {
      //       organizations: [createOrganization, ...q.organizations],
      //     },
      //   });
      // }
      // history.replace("/app/onboard_page/" + createOrganization.id);
      // setStep(2);
      history.push("/onboarding?step=3");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ProfileOnboarding.Interests",
          mutation: "UPDATE_USER_PROFILE",
        },
      });
      console.log(error);
    },
  });

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
  };

  return (
    <div className="mobile-auth-form">
      <div className="mobile-auth-form__header">
        <div className="mobile-auth-form__header__primary">
          Set up your profile
        </div>
        <div className="mobile-auth-form__header__secondary">
          Step 2 of 3 : Tell us your interests
        </div>
      </div>

      <form
        className="onboarding-form-mobile"
        onSubmit={(e) => {
          // console.log("Form submitted");
          e.preventDefault();

          if (loading) return;

          if (!interests.length) {
            history.push("/onboarding?step=3");
            return;
          }

          const vars = {};

          vars.interests = {
            connect: interests.map((i) => ({ name: i.name })),
          };

          updateUserProfile({
            variables: {
              data: vars,
            },
          });

          // setErrorMessage("");

          // if (!name || !handle) {
          //   if (!name) {
          //     setInvalidName(true);
          //   }

          //   if (!handle) {
          //     setInvalidHandle(true);
          //   }

          //   setErrorMessage("All fields are required");
          //   return;
          // }

          // isUniqueHandle({
          //   variables: { handle: handle },
          // });

          // login({
          //   variables: {
          //     email,
          //     password,
          //   },
          // });
        }}
      >
        <div className="onboard-page-mobile__categories__subtitle">
          What sports are you interested in?
        </div>

        <div className="onboard-page-mobile__sports__list">
          {["Football", "Cricket", "Basketball", "Tennis", "Badminton"].map(
            (s) => (
              <Square key={"Sport-" + s}>
                {({ squareRef, dimensions }) => (
                  <div
                    ref={squareRef}
                    className={
                      "onboard-page-mobile__sports__list__item " +
                      (interests.find((i) => i.name === s) ? "selected" : "")
                    }
                    onClick={() => {
                      setInterests((prev) => {
                        if (prev.find((i) => i.name === s)) {
                          return prev.filter((i) => i.name !== s);
                        } else {
                          return [...prev, { name: s }];
                        }
                      });
                    }}
                    style={{ height: dimensions.width }}
                  >
                    <div className="sport-icon">{sportsIcons[s]}</div>
                    <div className="sport-text">{s}</div>
                  </div>
                )}
              </Square>
            )
          )}
        </div>

        <DebouncedButton
          type="submit"
          className={"onboarding-form-mobile__submit "}
          loading={loading}
          disableDefaultStyles={true}
        >
          {loading ? <Loader theme="small" /> : "Next"}
        </DebouncedButton>

        {/* <button
          type="submit"
          className={
            "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
          }
        >
          {loading ? <Loader theme="small" /> : "Next"}
        </button> */}
      </form>
    </div>
  );
}

function Customization() {
  const history = useHistory();
  const [playsSport, setPlaysSport] = useState({
    selected: false,
    value: false,
  });
  const [worksInSport, setWorksInSport] = useState({
    selected: false,
    value: false,
  });
  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    update: (cache, { data: { updateUserProfile } }) => {
      // const q = cache.readQuery({
      //   query: ORGANIZATIONS,
      //   variables: { where: {} },
      // });
      // // console.log("Q:", q);
      // if (q?.organizations) {
      //   cache.writeQuery({
      //     query: ORGANIZATIONS,
      //     variables: { where: {} },
      //     data: {
      //       organizations: [createOrganization, ...q.organizations],
      //     },
      //   });
      // }
      // history.replace("/app/onboard_page/" + createOrganization.id);
      // setStep(2);
      history.push("/app");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ProfileOnboarding.Customization",
          mutation: "UPDATE_USER_PROFILE",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="mobile-auth-form">
      <div className="mobile-auth-form__header">
        <div className="mobile-auth-form__header__primary">
          Set up your profile
        </div>
        <div className="mobile-auth-form__header__secondary">
          Step 3 of 3 : Customize your experience
        </div>
      </div>

      <form
        onSubmit={(e) => {
          // console.log("Form submitted");
          e.preventDefault();

          if (loading) return;

          const vars = {
            worksInSport: worksInSport.value,
            playsSport: playsSport.value,
          };

          updateUserProfile({
            variables: {
              data: vars,
            },
          });

          // setErrorMessage("");

          // if (!name || !handle) {
          //   if (!name) {
          //     setInvalidName(true);
          //   }

          //   if (!handle) {
          //     setInvalidHandle(true);
          //   }

          //   setErrorMessage("All fields are required");
          //   return;
          // }

          // isUniqueHandle({
          //   variables: { handle: handle },
          // });

          // login({
          //   variables: {
          //     email,
          //     password,
          //   },
          // });
        }}
        className="onboarding-form-mobile"
      >
        <div className="onboarding-form-mobile__group">
          <div className="onboard-page-mobile__categories__subtitle">
            Do you enjoy playing sports?
          </div>

          <div className="onboarding-form-mobile__radio">
            <div
              className={
                "onboarding-form-mobile__radio__btn " +
                (playsSport.selected && playsSport.value ? "selected" : "")
              }
              onClick={() => {
                setPlaysSport((prev) => {
                  if (prev.selected && prev.value) {
                    return { selected: false, value: false };
                  } else {
                    return { selected: true, value: true };
                  }
                });
              }}
            >
              Yes, I do
            </div>

            <div
              className={
                "onboarding-form-mobile__radio__btn " +
                (playsSport.selected && !playsSport.value ? "selected" : "")
              }
              onClick={() => {
                setPlaysSport((prev) => {
                  if (prev.selected && !prev.value) {
                    return { selected: false, value: false };
                  } else {
                    return { selected: true, value: false };
                  }
                });
              }}
            >
              Not, really
            </div>
          </div>
        </div>
        <div className="onboarding-form-mobile__group">
          <div className="onboard-page-mobile__categories__subtitle">
            Do you work with sports organizations?
          </div>

          <div className="onboarding-form-mobile__radio">
            <div
              className={
                "onboarding-form-mobile__radio__btn " +
                (worksInSport.selected && worksInSport.value ? "selected" : "")
              }
              onClick={() => {
                setWorksInSport((prev) => {
                  if (prev.selected && prev.value) {
                    return { selected: false, value: false };
                  } else {
                    return { selected: true, value: true };
                  }
                });
              }}
            >
              Yes, I do
            </div>

            <div
              className={
                "onboarding-form-mobile__radio__btn " +
                (worksInSport.selected && !worksInSport.value ? "selected" : "")
              }
              onClick={() => {
                setWorksInSport((prev) => {
                  if (prev.selected && !prev.value) {
                    return { selected: false, value: false };
                  } else {
                    return { selected: true, value: false };
                  }
                });
              }}
            >
              Not, really
            </div>
          </div>
        </div>

        <DebouncedButton
          type="submit"
          className={"onboarding-form-mobile__submit "}
          loading={loading}
          disableDefaultStyles={true}
        >
          {loading ? <Loader theme="small" /> : "Finish"}
        </DebouncedButton>

        {/* <button
          type="submit"
          className={
            "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
          }
        >
          {loading ? <Loader theme="small" /> : "Next"}
        </button> */}
      </form>
    </div>
  );
}

// function VerifyEmail() {
//   const history = useHistory();
//   return (
//     <div className="mobile-auth-form">
//       <div className="mobile-auth-form__header">
//         <div className="mobile-auth-form__header__primary">
//           Set up your profile
//         </div>
//         <div className="mobile-auth-form__header__secondary">
//           Step 4 of 4 : Verify your email
//         </div>
//       </div>

//       <div className="mobile-auth-form__body">
//         <p>
//           You can complete your profile anytime by clicking the verification
//           link sent to your registered email address.
//         </p>

//         <button
//           onClick={() => {
//             history.replace("/app");
//           }}
//           className={"onboarding-form-mobile__submit "}
//         >
//           Go to App
//         </button>
//       </div>
//     </div>
//   );
// }

export default function ProfileOnboarding() {
  const query = useRouterQuery();
  const step = Number(query.get("step") || 0);
  const { loading, error, data } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileOnboarding",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  const profile = data.userProfile;

  return (
    <div className="mobile-auth-page__wrapper">
      <div className="mobile-auth-page">
        <Link to="/" className="mobile-auth-page__logo">
          <img src="/logo.svg" alt="" />
        </Link>

        {(!step || step === 1) && <PersonalInfo profile={profile} />}
        {step === 2 && <Interests />}
        {step === 3 && <Customization />}
        {/* {step === 4 && <VerifyEmail />} */}

        <div className="mobile-auth-page__graphic">
          <img src="/circle-bg.svg" alt="" />
        </div>

        <div className="mobile-auth-page__logo-bottom">
          <img src="/logo-white.svg" alt="" />
        </div>
      </div>
    </div>
  );
}
