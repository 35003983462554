import { gql } from "@apollo/client";

const UNCLAP = gql`
  mutation unclap($postId: ID, $mediaId: ID) {
    unclap(postId: $postId, mediaId: $mediaId) {
      id
    }
  }
`;

export default UNCLAP;
