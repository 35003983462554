// V1
import { useState, useCallback } from "react";
import "./ManageEventRoles.css";
import Card from "../../Card";
import NewDropdown from "../../NewDropdown";
import Input from "../../Input";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import Spinner from "../../Spinner";
import { EVENT_ROLES, UPDATE_EVENT } from "../../../api/event";
import { ORGANIZATION_SEARCH, PROFILE_SEARCH, SEARCH } from "../../../api/user";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import debounce from "lodash.debounce";
import Avatar from "../../Avatar";
import Button from "../../Button";
import Loader from "../../Loader";
import captureException from "../../../utils/captureException";
import { useAppState } from "../../../utils/appState";

function SearchProfile({ setProfile }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(PROFILE_SEARCH);

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = data?.search?.profiles || [];

  let placeholderArray = ["profiles"];

  return (
    <NewDropdown
      wrapperClass="search"
      targetWrapperClass="search-wrapper"
      placement={"bottom-start"}
      menuStyle={{ width: "100%" }}
      target={
        <Input
          iconLeft={
            <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
          }
          placeholder={`Search for ${placeholderArray.join("/")}`}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e);
          }}
          useExternalValue={true}
          value={searchTerm}
        />
      }
      menu={({ setShowMenu }) => (
        <Card className="app-search-menu">
          {loading && <Spinner />}
          {!loading && !profiles?.length && (
            <div className="no-data">
              {!searchTerm ? (
                <div>
                  Search for {placeholderArray.join("/")} by name or handle
                </div>
              ) : (
                <div>No results found</div>
              )}
            </div>
          )}
          {!!profiles?.length && (
            <div className="search-results-section">
              <div className="search-results-section__title">PROFILES</div>
              <div className="search-results-section__items">
                {profiles?.map((o, i) => (
                  <div
                    key={"venue-search-item-" + o.id + i}
                    onClick={() => {
                      // if (submitting) return;
                      setShowMenu(false);
                      setSearchTerm("");
                      setProfile(o);
                      // updateEvent({
                      //   variables: {
                      //     where: {
                      //       id: ev.id,
                      //     },
                      //     data: {
                      //       roles: {
                      //         create: {
                      //           type: { connect: { name: roleType } },
                      //           profile: { connect: { id: o.id } },
                      //           relationshipsTo: {
                      //             create: {
                      //               from: {
                      //                 connect: { id: roleId },
                      //               },
                      //             },
                      //           },
                      //         },
                      //       },
                      //     },
                      //   },
                      // });
                      // setSelectedProfiles((prev) => [...prev, p]);
                    }}
                    className="search-results-section__items__item"
                  >
                    <div className="search-results-section__items__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>
                    <div className="search-results-section__items__item__desc">
                      <div className="search-results-section__items__item__desc__primary">
                        {o.name}
                      </div>
                      <div className="search-results-section__items__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Card>
      )}
    />
  );
}

function AddRoleWithDescription({ ev, label, placeholder, roleType }) {
  const [, setAppState] = useAppState();
  const [profile, setProfile] = useState("");
  const [description, setDescription] = useState("");

  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
      roles {
        id
        description
        type {
          id
          name
        }        
        profile {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
        organization {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
      }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        setAppState({ modal: false });

        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <Card className="add-fixture-referee">
      <div className="add-fixture-referee__title">
        <div className="add-fixture-referee__title__text">{label}</div>

        <div
          className="add-fixture-referee__title__dismiss-btn"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="add-fixture-referee__title__dismiss-btn__icon" />
        </div>
      </div>

      <div className="add-fixture-referee__form">
        <div className="add-fixture-referee__form__group">
          <div className="add-fixture-referee__form__group__label">
            Select profile
          </div>

          <div className="add-fixture-referee__form__group__input">
            {profile ? (
              <div className="add-fixture-referee__form__group__input__selected-profile">
                <div className="add-fixture-referee__form__group__input__selected-profile__desc">
                  {profile.name}
                </div>

                <div
                  className="add-fixture-referee__form__group__input__selected-profile__dismiss"
                  onClick={() => {
                    setProfile(false);
                  }}
                >
                  <TimesIcon className="add-fixture-referee__form__group__input__selected-profile__dismiss__icon" />
                </div>
              </div>
            ) : (
              <SearchProfile setProfile={setProfile} />
            )}
          </div>
        </div>

        <div className="add-fixture-referee__form__group">
          <div className="add-fixture-referee__form__group__label">
            Description
          </div>

          <div className="add-fixture-referee__form__group__input">
            <Input
              placeholder={placeholder}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              useExternalValue={true}
              value={description}
            />
            {/* <input
              type="text"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            /> */}
          </div>
        </div>

        <Button
          className="blue primary large add-fixture-referee__form__submit"
          onClick={() => {
            if (submitting || !description || !profile?.id) return;

            updateEvent({
              variables: {
                where: {
                  id: ev.id,
                },
                data: {
                  roles: {
                    create: {
                      type: { connect: { name: roleType } },
                      profile: { connect: { id: profile.id } },
                      description,
                    },
                  },
                },
              },
            });
          }}
        >
          {submitting ? <Loader theme={"small"} /> : "Submit"}
        </Button>
      </div>
    </Card>
  );
}

function EditRoles({
  ev,
  roles,
  query,
  targets = {},
  title,
  label,
  roleType,
  hasDescription,
  placeholder,
}) {
  const [, setAppState] = useAppState();
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(query);

  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
      roles {
        id
        type {
          id
          name
        }        
        profile {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
        organization {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
      }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  // const getSuggestions = (term) => {
  //   console.log("Firing query");
  //   if (term) {
  //     search({ variables: { term: term.trim() } });
  //   }
  // };

  // const debouncedGetSuggestions = useCallback(
  //   debounce((term) => getSuggestions(term), 500),
  //   { leading: false, trailing: true }
  // );

  const profiles = targets.profiles ? data?.search?.profiles : [];
  const organizations = targets.organizations
    ? data?.search?.organizations
    : [];
  let placeholderArray = [];

  if (targets.profiles) {
    placeholderArray.push("profiles");
  }

  if (targets.organizations) {
    placeholderArray.push("pages");
  }

  return (
    <Card className="manage-event-role-card">
      <div className="card-title">{title}</div>

      <div className="card-secondary-title">
        Is your event's {label} on Sports Paddock? You can tag them and have the
        event show up on their page for greater visibility.
      </div>
      <div className="search-container">
        <NewDropdown
          wrapperClass="search"
          targetWrapperClass="search-wrapper"
          placement={"bottom-start"}
          menuStyle={{ width: "100%" }}
          target={
            hasDescription ? (
              <Button
                className="primary blue medium"
                onClick={() => {
                  setAppState({
                    modal: (
                      <AddRoleWithDescription
                        ev={ev}
                        label={label}
                        placeholder={placeholder}
                        roleType={roleType}
                      />
                    ),
                  });
                }}
              >
                Add role
              </Button>
            ) : (
              <Input
                iconLeft={
                  <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
                }
                placeholder={`Search for ${placeholderArray.join("/")}`}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debouncedSearch(e);
                }}
                useExternalValue={true}
                value={searchTerm}
              />
            )
          }
          menu={({ setShowMenu }) => (
            <Card className="app-search-menu">
              {loading && <Spinner />}
              {!loading && !profiles?.length && !organizations?.length && (
                <div className="no-data">
                  {!searchTerm ? (
                    <div>
                      Search for {placeholderArray.join("/")} by name or handle
                    </div>
                  ) : (
                    <div>No results found</div>
                  )}
                </div>
              )}
              {targets.profiles && !!profiles?.length && (
                <div className="search-results-section">
                  <div className="search-results-section__title">PROFILES</div>
                  <div className="search-results-section__items">
                    {profiles?.map((o, i) => (
                      <div
                        key={"venue-search-item-" + o.id + i}
                        onClick={() => {
                          if (submitting) return;
                          setShowMenu(false);
                          setSearchTerm("");
                          updateEvent({
                            variables: {
                              where: {
                                id: ev.id,
                              },
                              data: {
                                roles: {
                                  create: {
                                    type: { connect: { name: roleType } },
                                    profile: { connect: { id: o.id } },
                                  },
                                },
                              },
                            },
                          });
                          // setSelectedProfiles((prev) => [...prev, p]);
                        }}
                        className="search-results-section__items__item"
                      >
                        <div className="search-results-section__items__item__image">
                          {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                        </div>
                        <div className="search-results-section__items__item__desc">
                          <div className="search-results-section__items__item__desc__primary">
                            {o.name}
                          </div>
                          <div className="search-results-section__items__item__desc__secondary">
                            @{o.handle}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {targets.organizations && !!organizations?.length && (
                <div className="search-results-section">
                  <div className="search-results-section__title">PAGES</div>
                  <div className="search-results-section__items">
                    {organizations?.map((o, i) => (
                      <div
                        key={"venue-search-item-" + o.id + i}
                        onClick={() => {
                          if (submitting) return;
                          setShowMenu(false);
                          setSearchTerm("");
                          updateEvent({
                            variables: {
                              where: {
                                id: ev.id,
                              },
                              data: {
                                roles: {
                                  create: {
                                    type: { connect: { name: roleType } },
                                    organization: { connect: { id: o.id } },
                                  },
                                },
                              },
                            },
                          });
                          // setSelectedProfiles((prev) => [...prev, p]);
                        }}
                        className="search-results-section__items__item"
                      >
                        <div className="search-results-section__items__item__image">
                          {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                        </div>
                        <div className="search-results-section__items__item__desc">
                          <div className="search-results-section__items__item__desc__primary">
                            {o.name}
                          </div>
                          <div className="search-results-section__items__item__desc__secondary">
                            @{o.handle}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Card>
          )}
        />
      </div>
      <div className="roles-list">
        {roles.map((r) => (
          <div className="roles-list__item" key={"venue-roles-" + r.id}>
            <div className="roles-list__item__image">
              {(r.profile?.profilePhoto || r.organization?.profilePhoto) && (
                <Avatar
                  media={
                    r.profile?.profilePhoto || r.organization?.profilePhoto
                  }
                />
              )}
            </div>
            <div className="roles-list__item__content">
              <div className="roles-list__item__content__primary">
                {r.profile?.name || r.organization?.name}{" "}
                {!r.confirmed && "(Request sent)"}
              </div>
              <div className="roles-list__item__content__secondary">
                {hasDescription
                  ? r.description
                  : `@${r.profile?.handle || r.organization?.handle}`}
              </div>
            </div>
            <div className="roles-list__item__options">
              <div
                className="roles-list__item__options__delete"
                onClick={() => {
                  if (submitting) return;
                  updateEvent({
                    variables: {
                      where: { id: ev.id },
                      data: {
                        roles: {
                          deleteMany: [{ id: r.id }],
                        },
                      },
                    },
                  });
                }}
              >
                <TrashIcon
                  style={{ stroke: "var(--red-main)", height: "14px" }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function ManageEventRoles({ id }) {
  const { loading, error, data, refetch } = useQuery(EVENT_ROLES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventRoles",
        query: "EVENT_ROLES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const roles = ev?.roles;

  return (
    <div>
      <EditRoles
        ev={ev}
        roles={roles.filter((r) => r.type.name === "VENUE")}
        query={ORGANIZATION_SEARCH}
        targets={{ profiles: false, organizations: true }}
        title={"Tag your venue"}
        label={"venue"}
        roleType={"VENUE"}
      />

      <EditRoles
        ev={ev}
        roles={roles.filter((r) => r.type.name === "SPONSOR")}
        query={ORGANIZATION_SEARCH}
        targets={{ profiles: false, organizations: true }}
        title={"Tag your sponsors"}
        label={"sponsor"}
        roleType={"SPONSOR"}
      />

      <EditRoles
        ev={ev}
        roles={roles.filter((r) => r.type.name === "PARTNER")}
        query={ORGANIZATION_SEARCH}
        targets={{ profiles: false, organizations: true }}
        title={"Tag your partners"}
        label={"partner"}
        roleType={"PARTNER"}
      />

      <EditRoles
        ev={ev}
        roles={roles.filter((r) => r.type.name === "MEDIA STAFF")}
        query={PROFILE_SEARCH}
        targets={{ profiles: true }}
        title={"Tag your media team"}
        label="media team"
        roleType={"MEDIA STAFF"}
        hasDescription={true}
        placeholder={"eg. Photographer, Videographer, etc.,"}
      />

      <EditRoles
        ev={ev}
        roles={roles.filter((r) => r.type.name === "OPERATIONS STAFF")}
        query={PROFILE_SEARCH}
        targets={{ profiles: true }}
        title={"Tag your operations team"}
        label="operations team"
        roleType={"OPERATIONS STAFF"}
        hasDescription={true}
        placeholder={"eg. Medical Manager, Operations Manager, etc.,"}
      />
    </div>
  );
}

export default ManageEventRoles;
