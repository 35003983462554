import { gql } from "@apollo/client";

const GENERATE_FIXTURES = gql`
  mutation generateFixtures(
    $eventId: ID!
    $rounds: [GenerateFixtureEventPartInput!]
    $fixtures: [GenerateFixtureFixtureInput!]
    $roles: [GenerateFixtureRoleInput!]
  ) {
    generateFixtures(
      eventId: $eventId
      rounds: $rounds
      fixtures: $fixtures
      roles: $roles
    ) {
      success
      error
    }
  }
`;

export default GENERATE_FIXTURES;
