import "./EventRoles.css";
import { useQuery } from "@apollo/client";
import { EVENT_ROLES } from "../../../api/event";
import { Link } from "react-router-dom";
import { titleCase } from "../../../utils/titleCase";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import Square from "../../Square";
import Placeholder from "../../../components/Placeholder";

export default function EventRoles({ id }) {
  const { loading, error, data } = useQuery(EVENT_ROLES, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventRoles",
        query: "EVENT_ROLES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-roles-mobile">
        <div
          className="event-placements-mobile__title"
          style={{ marginBottom: "24px" }}
        >
          <Placeholder style={{ width: "64px", height: "10px" }} pulse={true} />
        </div>
        <div className="event-roles-mobile__wrapper">
          <div className="event-roles-mobile__item">
            <div className="event-roles-mobile__item__image"></div>
            <div className="event-roles-mobile__item__text">
              <div className="event-roles-mobile__item__text__primary">
                <Placeholder
                  style={{ width: "160px", height: "10px" }}
                  pulse={true}
                />
              </div>
              <div className="event-roles-mobile__item__text__secondary">
                <Placeholder
                  style={{ width: "80px", height: "10px", marginTop: "8px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="event-roles-mobile__wrapper">
          <div className="event-roles-mobile__item">
            <div className="event-roles-mobile__item__image"></div>
            <div className="event-roles-mobile__item__text">
              <div className="event-roles-mobile__item__text__primary">
                <Placeholder
                  style={{ width: "160px", height: "10px" }}
                  pulse={true}
                />
              </div>
              <div className="event-roles-mobile__item__text__secondary">
                <Placeholder
                  style={{ width: "80px", height: "10px", marginTop: "8px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // console.log("EVENT ROLES DATA:", data);

  const ev = data?.event;
  return (
    <div className="event-roles-mobile">
      <div
        className="event-placements-mobile__title"
        style={{ marginBottom: "24px" }}
      >
        Participants & Partners
      </div>
      {ev.organizedBy && (
        <Link
          to={"/app/page/" + ev.organizedBy.id}
          className="event-roles-mobile__wrapper"
        >
          <Square>
            {({ squareRef, dimensions }) => (
              <div className="event-roles-mobile__item">
                <div className="event-roles-mobile__item__image">
                  {ev.organizedBy.profilePhoto && (
                    <Avatar media={ev.organizedBy.profilePhoto} />
                  )}
                </div>
                <div className="event-roles-mobile__item__text">
                  <div className="event-roles-mobile__item__text__primary">
                    {ev.organizedBy.name}
                  </div>
                  <div className="event-roles-mobile__item__text__secondary">
                    Organizer
                  </div>
                </div>
              </div>
            )}
          </Square>
        </Link>
      )}

      {ev.participants.map((role) => (
        <Link
          key={role.id}
          to={
            role.organization
              ? "/app/page/" + role.organization?.id
              : "/app/profile/" + role.profile?.id
          }
          className="event-roles-mobile__wrapper"
        >
          <Square>
            {({ squareRef, dimensions }) => (
              <div className="event-roles-mobile__item">
                <div className="event-roles-mobile__item__image">
                  {(role.organization?.profilePhoto ||
                    role.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        role.organization?.profilePhoto ||
                        role.profile?.profilePhoto
                      }
                    />
                  )}
                </div>
                <div className="event-roles-mobile__item__text">
                  <div className="event-roles-mobile__item__text__primary">
                    {role.organization?.name || role.profile?.name}
                  </div>
                  <div className="event-roles-mobile__item__text__secondary">
                    {titleCase(role.type.name)}
                  </div>
                </div>
              </div>
            )}
          </Square>
        </Link>
      ))}

      {ev.roles.map((role) => (
        <Link
          key={role.id}
          to={`/app/${role.organization ? "page" : "profile"}/${
            role.organization?.id || role.profile?.id
          }`}
          className="event-roles-mobile__wrapper"
        >
          <Square>
            {({ squareRef, dimensions }) => (
              <div className="event-roles-mobile__item">
                <div className="event-roles-mobile__item__image">
                  {(role.organization?.profilePhoto ||
                    role.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        role.organization?.profilePhoto ||
                        role.profile?.profilePhoto
                      }
                    />
                  )}
                </div>
                <div className="event-roles-mobile__item__text">
                  <div className="event-roles-mobile__item__text__primary">
                    {role.organization?.name || role.profile?.name}
                  </div>
                  <div className="event-roles-mobile__item__text__secondary">
                    {titleCase(role.type.name)}
                  </div>
                </div>
              </div>
            )}
          </Square>
        </Link>
      ))}
    </div>
  );
}
