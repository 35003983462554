import "./PageCard.css";
import { ReactComponent as PinIcon } from "../../../icons/pin.svg";
import { ReactComponent as TagIcon } from "../../../icons/tag.svg";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar";

export default function PageCard({ organization }) {
  return (
    <Link
      to={"/app/page/" + organization.id}
      className="directory-page-mobile__list__item"
    >
      <div
        className={
          "directory-page-mobile__list__item__image " +
          (organization.profilePhoto ? "black" : "")
        }
      >
        {organization.profilePhoto && (
          <Avatar media={organization.profilePhoto} />
        )}
      </div>

      <div className="directory-page-mobile__list__item__text">
        <div className="directory-page-mobile__list__item__text__primary">
          {organization.name}
        </div>

        <div className="directory-page-mobile__list__item__text__secondary">
          <PinIcon className="meta-icon" />
          {organization.cities.map((c) => c.value).join(", ") ||
            "Unknown location"}
        </div>

        <div className="directory-page-mobile__list__item__text__secondary">
          <TagIcon className="meta-icon" />
          {(organization.types || [])
            .slice(0, 2)
            .map((t) => t.name)
            .join(", ")}
          {organization.types?.length === 3
            ? ", " + organization.types[2].name
            : organization.types?.length > 3
            ? " + " + (organization.types.length - 2) + " other categories"
            : ""}

          {!organization.types?.length && "No categories added"}
        </div>
      </div>
    </Link>
  );
}
