import "./EditInterests.css";
import { useState } from "react";
import { useAppState } from "../../utils/appState";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import { ReactComponent as ControllerIcon } from "../../icons/controller.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import { useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import captureException from "../../utils/captureException";

export default function EditInterests({ title, config }) {
  const [, setAppState] = useAppState();
  const [interests, setInterests] = useState(config.default || []);

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };

  const [mutation, { loading }] = useMutation(config.mutation, {
    update: config.update,
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EditInterests",
          mutation: "variable",
        },
      });
      console.log(error);
    },
  });

  const sports = [
    { name: "Football" },
    { name: "Basketball" },
    { name: "Cricket" },
    { name: "Badminton" },
    { name: "Tennis" },
    { name: "E-Gaming" },
  ];

  const dismiss = () => {
    setAppState({ modal: false });
  };

  const toConnect = interests
    .map((i) => i.name)
    .filter((i) => !config.default.map((x) => x.name).includes(i));

  const toDisconnect = config.default
    .map((i) => i.name)
    .filter((i) => !interests.map((x) => x.name).includes(i));

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">{title}</div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <div className="modal-form-mobile__body__interests-grid">
            {sports.map((sport, i) => (
              <div
                key={"profile-sport-" + sport.id + i}
                className={
                  "interest hover-pointer " +
                  (interests.find((i) => i.name === sport.name)
                    ? ""
                    : "inactive")
                }
                onClick={() => {
                  if (interests.find((i) => i.name === sport.name)) {
                    setInterests((prev) =>
                      prev.filter((i) => i.name !== sport.name)
                    );
                  } else {
                    setInterests((prev) => [...prev, sport]);
                  }
                }}
              >
                <div className="icon">
                  {sportsIcons[sport.name] || (
                    <EllipsisIcon className="sports-icon-svg" />
                  )}
                </div>
                <div className="desc">{sport.name}</div>
              </div>
            ))}
          </div>

          <div className="modal-form-mobile__form__options">
            <button
              type="submit"
              className="modal-form-mobile__form__options__submit"
              onClick={() => {
                if (loading) return;

                mutation({
                  variables: {
                    where: { id: config.id },
                    data: {
                      interests: {
                        connect: toConnect.map((i) => ({ name: i })),
                        disconnect: toDisconnect.map((i) => ({ name: i })),
                      },
                    },
                  },
                });
              }}
            >
              {loading ? <Loader theme={"small"} /> : "Submit"}
            </button>

            <button
              className="modal-form-mobile__form__options__cancel"
              onClick={(e) => {
                e.preventDefault();
                dismiss();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
