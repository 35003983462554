import "./CreatePage.css";
import { useState } from "react";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as DirectoryIcon } from "../../icons/directory.svg";
import { ReactComponent as AtIcon } from "../../icons/at-sign.svg";
import Loader from "../../components/Loader";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_ORGANIZATION, ORGANIZATIONS } from "../../api/organization";
import { IS_UNIQUE_HANDLE } from "../../api";
import { useHistory, useLocation } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

export default function CreatePage() {
  usePageTracking({ title: "Create Page" });
  const history = useHistory();
  const location = useLocation();

  const [name, setName] = useState("");
  const [invalidName, setInvalidName] = useState("");

  const [handle, setHandle] = useState("");
  const [invalidHandle, setInvalidHandle] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [createOrganization, { loading }] = useMutation(CREATE_ORGANIZATION, {
    update: (cache, { data: { createOrganization } }) => {
      const q = cache.readQuery({
        query: ORGANIZATIONS,
        variables: { where: {} },
      });

      // console.log("Q:", q);
      if (q?.organizations) {
        cache.writeQuery({
          query: ORGANIZATIONS,
          variables: { where: {} },
          data: {
            organizations: [createOrganization, ...q.organizations],
          },
        });
      }

      history.replace("/app/onboard_page/" + createOrganization.id);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "CreatePage",
          mutation: "CREATE_ORGANIZATION",
        },
      });
      console.log(error);
    },
  });

  const [isUniqueHandle, isUniqueHandleQuery] = useLazyQuery(IS_UNIQUE_HANDLE, {
    onCompleted: (data) => {
      // console.log(data);
      if (!data?.isUniqueHandle?.valid) {
        setInvalidHandle(true);
        setErrorMessage("This handle is unavailable");
        // setStep(0);
        return;
      }

      const v = {
        data: {
          name,
          handle,
        },
      };

      createOrganization({
        variables: v,
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "query",
          component: "CreatePage",
          query: "IS_UNIQUE_HANDLE",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="create-page-mobile">
      <div className="create-page-mobile__nav">
        <div
          className="create-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
      </div>

      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Create a page
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (loading) return;

            setErrorMessage("");

            if (!name || !handle) {
              if (!name) {
                setInvalidName(true);
              }

              if (!handle) {
                setInvalidHandle(true);
              }

              setErrorMessage("All fields are required");
              return;
            }

            isUniqueHandle({
              variables: { handle: handle },
            });

            // login({
            //   variables: {
            //     email,
            //     password,
            //   },
            // });
          }}
        >
          <div className="onboarding-form-mobile__groups">
            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Name <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <div
                className={
                  "onboarding-form-mobile__group__field " +
                  (invalidName ? "error" : "")
                }
              >
                <div className="onboarding-form-mobile__group__field__icon">
                  <DirectoryIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div>

                <input
                  type="text"
                  className="onboarding-form-mobile__group__field__input"
                  value={name}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setName(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidName(false);
                  }}
                  autoFocus
                />
              </div>
            </div>

            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Handle <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <div
                className={
                  "onboarding-form-mobile__group__field " +
                  (invalidHandle ? "error" : "")
                }
              >
                <div className="onboarding-form-mobile__group__field__icon">
                  <AtIcon style={{ stroke: "var(--light-3)", height: 14 }} />
                </div>

                <input
                  type="text"
                  value={handle}
                  className="onboarding-form-mobile__group__field__input"
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setHandle(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidHandle(false);
                  }}
                />
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="onboarding-form-mobile__error">{errorMessage}</div>
          )}

          <DebouncedButton
            type="submit"
            className="onboarding-form-mobile__submit"
            loading={loading || isUniqueHandleQuery.loading}
          >
            {loading || isUniqueHandleQuery.loading ? (
              <Loader theme="small" />
            ) : (
              "Submit"
            )}
          </DebouncedButton>

          {/* <button
            type="submit"
            className={
              "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            }
          >
            {loading || isUniqueHandleQuery.loading ? (
              <Loader theme="small" />
            ) : (
              "Submit"
            )}
          </button> */}
        </form>
      </div>

      <div className="onboarding-form-mobile__graphic">
        <img src="/circle-bg.svg" alt="" />
      </div>

      <div className="onboarding-form-mobile__logo-bottom">
        <img src="/logo-white.svg" alt="" />
      </div>
    </div>
  );
}
