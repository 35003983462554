import { gql } from "@apollo/client";

const PROFILE_BIO = gql`
  query profileQuery($id: ID!) {
    profile(id: $id) {
      id
      city {
        id
        value
        lat
        lng
      }
      cities {
        id
        value
        lat
        lng
      }
      state {
        id
        value
      }
      country {
        id
        value
      }
      dob
      interests {
        id
        name
      }
    }
  }
`;

export default PROFILE_BIO;
