import { useState, useEffect } from "react";
import EditPhotoBrowserDescription from "../EditPhotoBrowserDescription";
import ReactQuill from "react-quill";
import "./PhotoBrowserDescription.css";

export default function PhotoBrowserDescription({ media, onDelete }) {
  const [truncated, setTruncated] = useState(
    (media.contentLength || 0) > 500 ? true : false
  );
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const onMentionClick = (e) => {
      e.stopImmediatePropagation();
      window.open(process.env.REACT_APP_ENDPOINT + e.value.link, "_blank");
    };
    window.addEventListener("mention-clicked", onMentionClick, false);

    return () => {
      window.removeEventListener("mention-clicked", onMentionClick, false);
    };
  });

  if (editing) {
    return (
      <EditPhotoBrowserDescription
        media={media}
        dismiss={() => {
          setEditing(false);
        }}
      />
    );
  }

  return (
    <div className="media-description">
      <ReactQuill
        id={"media-description-" + media.id}
        value={truncated ? media.truncatedDescription : media.description}
        readOnly={true}
        modules={{ toolbar: false }}
      />
      {truncated ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(false);
          }}
        >
          See more
        </div>
      ) : media.contentLength > 500 ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(true);
          }}
        >
          See less
        </div>
      ) : (
        false
      )}
      {media.canManageMedia && (
        <div
          className="edit-btn"
          onClick={() => {
            setEditing(true);
          }}
        >
          Edit Description
        </div>
      )}
    </div>
  );
}
