import "./EventStandingsSummary.css";
import { useQuery } from "@apollo/client";
import { EVENT_STANDINGS } from "../../../api/event";
import { useHistory, useLocation } from "react-router-dom";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import { Link } from "react-router-dom";
import Placeholder from "../../../components/Placeholder";

export default function EventStandingsSummary({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(EVENT_STANDINGS, {
    variables: { id, take: 1 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventStandingsSummary",
        query: "EVENT_STANDINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-standings-summary-mobile">
        <div className="event-standings-summary-mobile__title">
          <Placeholder style={{ height: "10px", width: "80px" }} pulse={true} />
        </div>

        <div className="event-standings-summary-mobile__table">
          <div className="event-standings-summary-mobile__table__list">
            <div className="event-standings-summary-mobile__table__list__header">
              <div className="row">
                <div className="td" style={{ width: "10%" }}>
                  <Placeholder
                    style={{ height: "10px", width: "10px" }}
                    pulse={true}
                  />
                </div>
                <div className="td" style={{ width: "70%" }}>
                  <Placeholder
                    style={{ height: "10px", width: "32px" }}
                    pulse={true}
                  />
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  <Placeholder
                    style={{ height: "10px", width: "10px" }}
                    pulse={true}
                  />
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  <Placeholder
                    style={{ height: "10px", width: "10px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
            <div className="event-standings-summary-mobile__table__list__body">
              {[1, 2, 3].map((item) => (
                <div key={"standings-summary-item-" + item} className="row">
                  <Placeholder
                    style={{ height: "10px", width: "100%" }}
                    pulse={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const standing = data?.event?.standings?.[0];

  return (
    <div className="event-standings-summary-mobile">
      <div className="event-standings-summary-mobile__title">Standings</div>
      {standing ? (
        <div className="event-standings-summary-mobile__table">
          <div className="event-standings-summary-mobile__table__list">
            <div className="event-standings-summary-mobile__table__list__header">
              <div className="row">
                <div className="td" style={{ width: "10%" }}>
                  Pos
                </div>
                <div className="td" style={{ width: "70%" }}>
                  Team
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  GP
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  P
                </div>
              </div>
            </div>
            <div className="event-standings-summary-mobile__table__list__body">
              {standing.items.map((item) => (
                <div key={"standings-summary-item-" + item.id} className="row">
                  <div className="td" style={{ width: "10%" }}>
                    {item.order + 1}
                  </div>
                  <div className="td" style={{ width: "70%" }}>
                    <div className="logo">
                      {(item.organization?.profilePhoto ||
                        item.profile?.profilePhoto) && (
                        <Avatar
                          media={
                            item.organization?.profilePhoto ||
                            item.profile?.profilePhoto
                          }
                        />
                      )}
                    </div>
                    <Link
                      to={`/app/${item.organization ? "page" : "profile"}/${
                        item.organization?.id || item.profile?.id
                      }`}
                    >
                      {item.organization?.name || item.profile?.name}
                    </Link>
                  </div>
                  <div className="td number" style={{ width: "10%" }}>
                    {item.metas.find((m) => m.label === "Games Played")?.value}
                  </div>

                  <div className="td number bold" style={{ width: "10%" }}>
                    {item.metas.find((m) => m.label === "Points")?.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">
          <div className="text">No standings updated yet.</div>
          <div
            className="link hover-pointer hover-underline"
            onClick={() => {
              refetch();
            }}
          >
            Check again
          </div>
        </div>
      )}

      {standing && (
        <div className="event-standings-summary-mobile__footer hover-pointer">
          <div
            className=""
            onClick={() => {
              history.replace(location.pathname + "?t=standings");
            }}
          >
            See full standings
          </div>
        </div>
      )}
    </div>
  );
}
