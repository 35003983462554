import { gql } from "@apollo/client";

const STAT_TYPES = gql`
  query statTypes($sport: String!) {
    statTypes(sport: $sport) {
      id
      name
      group {
        id
        name
      }
      relationshipsFrom {
        id
        to {
          id
          name
        }
        type {
          id
          name
        }
      }
      relationshipsTo {
        id
        from {
          id
          name
        }
        type {
          id
          name
        }
      }
    }
  }
`;

export default STAT_TYPES;
