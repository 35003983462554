import { gql } from "@apollo/client";

const MEDIA_SET = gql`
  query mediaset($id: ID!) {
    mediaSet(id: $id) {
      id
      name
      preview {
        id
        filename
      }
      canManageMediaSet
      photosCount
      source {
        id
        event {
          id
        }
        profile {
          id
        }
        organization {
          id
        }
        fixture {
          id
          scheduledStart
          roles(where: { type: { name: "PARTICIPANT" } }) {
            id
            organization {
              id
              name
            }
            profile {
              id
              name
            }
          }
        }
      }
      media {
        id
        filename
      }
    }
  }
`;

export default MEDIA_SET;
