import { gql } from "@apollo/client";

const UPDATE_SERVICE = gql`
  mutation updateService($where: UniqueInput!, $data: UpdateServiceInput!) {
    updateService(data: $data, where: $where) {
      id
      name
      description
      profilePhoto {
        id
        filename
      }
    }
  }
`;

export default UPDATE_SERVICE;
