import { useEffect } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import MobileLayout from "./layouts/MobileLayout";
import LandingLayout from "./layouts/LandingLayout";
import MobileLandingLayout from "./layouts/MobileLandingLayout";
import AppLayout from "./layouts/AppLayout";
import ScoringLayout from "./layouts/ScoringLayout";
import Logout from "./screens/Logout";
import VerifyEmail from "./screens/VerifyEmail";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { useAppState } from "./utils/appState";

function App() {
  const [, setAppState] = useAppState();
  // const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  // const [waitingWorker, setWaitingWorker] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("sp_auth_token") || false;
    const expires = localStorage.getItem("sp_expires_at") || false;

    if (token && expires && moment().isBefore(expires)) {
      setAppState({ isLoggedIn: true });
    }
  }, [setAppState]);

  if (isMobile) {
    return (
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>

        <Route path="/verify/:token">
          <VerifyEmail />
        </Route>

        <Route path="/app">
          <MobileLayout />{" "}
        </Route>

        <Route path="/">
          <MobileLandingLayout />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>

        <Route path="/verify/:token">
          <VerifyEmail />
        </Route>

        <Route path="/app">
          <AppLayout />
        </Route>

        <Route path="/score/:fixtureId">
          <ScoringLayout />
        </Route>

        <Route path="/">
          <LandingLayout />
        </Route>
      </Switch>
    );
  }
}

export default App;
