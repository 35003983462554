import { gql } from "@apollo/client";

const ORGANIZATIONS = gql`
  query organizationsQuery($where: OrganizationWhereInput, $cursor: ID) {
    organizations(where: $where, cursor: $cursor) {
      id
      name
      types {
        id
        name
      }
      followersCount
      description
      city {
        id
        value
      }
      cities {
        id
        value
      }
      profilePhoto {
        id
        filename
      }
      isFollowedByUser {
        id
        confirmed
      }
    }
  }
`;

export default ORGANIZATIONS;
