import "./Account.css";
import {
  SavedPosts,
  AccountNotifications,
  AccountRegistrations,
} from "../../components/Account";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import Avatar from "../../components/Avatar";
import useRouterQuery from "../../utils/useRouterQuery";
import { useQuery } from "@apollo/client";
import { ACCOUNT } from "../../api/account";
import { Link } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

export default function Account() {
  usePageTracking({ title: "Account Settings" });

  const query = useRouterQuery();
  const { loading, error, data } = useQuery(ACCOUNT);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Account",
        query: "ACCOUNT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const selected = query.get("s") || "notifications";

  const profile = data?.userAccount?.profile || {};

  // console.log("ACCOunt DATA:", data);

  return (
    <div className="account-page">
      <div className="account-page__nav">
        {loading ? (
          <Spinner />
        ) : (
          <Card className="account-page__nav__card">
            <div className="account-page__nav__card__profile">
              <div className="account-page__nav__card__profile__img">
                {profile?.profilePhoto && (
                  <Avatar media={profile.profilePhoto} />
                )}
              </div>
              <div className="account-page__nav__card__profile__name">
                <div className="account-page__nav__card__profile__name__primary">
                  {profile?.name}
                </div>

                <div className="account-page__nav__card__profile__name__secondary">
                  @{profile?.handle}
                </div>
              </div>
            </div>
            <div className="account-page__nav__card__menu">
              <Link
                to="/app/account?s=notifications"
                className={
                  "account-page__nav__card__menu__item " +
                  (selected === "notifications" ? "selected" : "")
                }
              >
                Notifications
              </Link>
              <Link
                to="/app/account?s=registrations"
                className={
                  "account-page__nav__card__menu__item " +
                  (selected === "registrations" ? "selected" : "")
                }
              >
                Registrations
              </Link>
              <Link
                to="/app/account?s=saved"
                className={
                  "account-page__nav__card__menu__item " +
                  (selected === "saved" ? "selected" : "")
                }
              >
                Saved Posts
              </Link>
            </div>
          </Card>
        )}
      </div>
      <div className="account-page__content">
        {{
          notifications: <AccountNotifications />,
          registrations: <AccountRegistrations />,
          saved: <SavedPosts />,
        }[selected || "notifications"] || <AccountNotifications />}
      </div>
    </div>
  );
}
