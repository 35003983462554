import "./CreateEvent.css";
import { useState } from "react";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as DirectoryIcon } from "../../icons/directory.svg";
import { ReactComponent as BuildingIcon } from "../../icons/building.svg";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import Loader from "../../components/Loader";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_EVENT, EVENTS } from "../../api/event";
import { ReactComponent as IndiaFlagIcon } from "../../icons/india-flag.svg";
import Chip from "../../components/Chip";
import { USER_ORGANIZATIONS } from "../../api/user";
// import { IS_UNIQUE_HANDLE } from "../../api";
import { Link, useHistory } from "react-router-dom";
import Alert from "../../components/Alert";
import { CAN_CREATE_EVENT } from "../../api/user";
import Spinner from "../../components/Spinner";
import Dropdown from "../../components/Dropdown";
import Card from "../../components/Card";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

export default function CreateEvent() {
  usePageTracking({ title: "Create Event" });
  const history = useHistory();
  // const location = useLocation();

  const [name, setName] = useState("");
  const [invalidName, setInvalidName] = useState("");

  const [organizer, setOrganizer] = useState("");
  const [invalidOrganizer, setInvalidOrganizer] = useState("");

  const [location, setLocation] = useState("");
  const [invalidLocation, setInvalidLocation] = useState("");

  const [participantType, setParticipantType] = useState("");
  const [invalidParticipantType, setInvalidParticipantType] = useState("");

  const [city, setCity] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const participantTypes = [
    { label: "Teams", value: "TEAM" },
    { label: "Individuals", value: "INDIVIDUAL" },
  ];

  const authQuery = useQuery(CAN_CREATE_EVENT);

  const userOrgsQuery = useQuery(USER_ORGANIZATIONS);

  const [createEvent, { loading }] = useMutation(CREATE_EVENT, {
    update: (cache, { data: { createEvent } }) => {
      const q = cache.readQuery({
        query: EVENTS,
        variables: { where: {} },
      });

      // console.log("Q:", q);
      if (q?.events) {
        cache.writeQuery({
          query: EVENTS,
          variables: { where: {} },
          data: {
            events: [createEvent, ...q.events],
          },
        });
      }

      history.replace("/app/onboard_event/" + createEvent.id);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "CreateEvent",
          mutation: "CREATE_EVENT",
        },
      });
      console.log(error);
    },
  });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "CreateEvent",
        query: "CAN_CREATE_EVENT",
      },
    });
    console.log(authQuery.error);
    return <div>Error</div>;
  }

  if (userOrgsQuery.error) {
    captureException({
      error: userOrgsQuery.error,
      info: {
        type: "query",
        component: "CreateEvent",
        query: "USER_ORGANIZATIONS",
      },
    });
    console.log(userOrgsQuery.error);
    return <div>Error</div>;
  }

  if (
    authQuery.loading ||
    !authQuery.data ||
    userOrgsQuery.loading ||
    !userOrgsQuery.data
  ) {
    return <Spinner />;
  }

  const authorization = authQuery.data?.canCreateEvent;
  const orgs =
    userOrgsQuery.data?.userOrganizations?.filter((o) => o.isAdmin) || [];
  // console.log("Orgs:", orgs);

  const authErrors = {
    isLoggedIn: {
      head: "You are not logged in",
      message: "You must be logged in and verified to create events and pages.",
      link: {
        url: "/auth",
        text: "Click here to login",
      },
    },
    isOnboarded: {
      head: "Your profile is incomplete",
      message:
        "You must complete the entire onboarding process and verify your email to create events and pages.",
      link: {
        url: "/onboarding",
        text: "Click here to complete onboarding",
      },
    },
    hasPages: {
      head: "No event organizers detected",
      message:
        "You are currently not an admin of any pages. Every event must have an organizer page. You can either create one by clicking the link below or you can contact the admin of an existing page to add you as an admin.",
      link: {
        url: "/app/create_page",
        text: "Click here to create page",
      },
    },
  };

  if (
    !authorization.isLoggedIn ||
    !authorization.isOnboarded ||
    !authorization.hasPages
  ) {
    let authError = {};
    if (!authorization.isLoggedIn) {
      authError = authErrors.isLoggedIn;
    } else if (!authorization.isOnboarded) {
      authError = authErrors.isOnboarded;
    } else if (!authorization.hasPages) {
      authError = authErrors.hasPages;
    }
    return (
      <div className="create-page-mobile">
        <div className="create-page-mobile__nav">
          <div
            className="create-page-mobile__nav__back-btn"
            onClick={() => {
              if (location.key) {
                history.goBack();
              } else {
                history.replace("/app");
              }
            }}
          >
            <ArrowLeftIcon
              style={{ height: "16px", stroke: "var(--dark-4)" }}
            />
          </div>
        </div>

        <div className="onboarding-form-mobile__wrapper">
          <div className="onboarding-form-mobile__title">
            <div className="onboarding-form-mobile__title__primary">
              Create an event
            </div>
          </div>

          <div className="auth-alert">
            <Alert
              theme="red"
              head={authError?.head}
              body={
                <div>
                  <div>{authError?.message}</div>
                  <Link to={authError?.link?.url} className="auth-alert-link">
                    {authError?.link?.text}
                  </Link>
                </div>
              }
            />
          </div>
        </div>

        <div className="onboarding-form-mobile__graphic">
          <img src="/circle-bg.svg" alt="" />
        </div>

        <div className="onboarding-form-mobile__logo-bottom">
          <img src="/logo-white.svg" alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className="create-page-mobile">
      <div className="create-page-mobile__nav">
        <div
          className="create-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
      </div>

      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Create an event
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (loading) return;

            setErrorMessage("");

            if (!name || !organizer || !city || !participantType) {
              if (!name) {
                setInvalidName(true);
              }

              if (!organizer) {
                setInvalidOrganizer(true);
              }

              if (!city) {
                setInvalidLocation(true);
              }

              if (!participantType) {
                setInvalidParticipantType(true);
              }

              setErrorMessage("All fields are required");
              return;
            }

            const vars = {
              name,
              city: city.city,
              state: city.state,
              country: city.country,
              participantType: participantType.value,
              organizedBy: { connect: { id: organizer.id } },
            };

            if (city.lat) {
              vars.lat = city.lat;
            }

            if (city.lng) {
              vars.lng = city.lng;
            }

            createEvent({
              variables: {
                data: vars,
              },
            });

            // if (!name || !handle) {
            //   if (!name) {
            //     setInvalidName(true);
            //   }

            //   if (!handle) {
            //     setInvalidHandle(true);
            //   }

            //   setErrorMessage("All fields are required");
            //   return;
            // }

            // isUniqueHandle({
            //   variables: { handle: handle },
            // });

            // login({
            //   variables: {
            //     email,
            //     password,
            //   },
            // });
          }}
        >
          <div className="onboarding-form-mobile__groups">
            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Name <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <div
                className={
                  "onboarding-form-mobile__group__field " +
                  (invalidName ? "error" : "")
                }
              >
                <div className="onboarding-form-mobile__group__field__icon">
                  <DirectoryIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div>

                <input
                  type="text"
                  className="onboarding-form-mobile__group__field__input"
                  value={name}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setName(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidName(false);
                  }}
                  autoFocus
                />
              </div>
            </div>

            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Organizer <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <Dropdown
                className="onboarding-form-mobile__dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "onboarding-form-mobile__group__field " +
                      (invalidOrganizer ? "error" : "")
                    }
                  >
                    <div className="onboarding-form-mobile__group__field__icon">
                      <BuildingIcon
                        style={{ fill: "var(--light-3)", height: 14 }}
                      />
                    </div>

                    <input
                      type="text"
                      value={organizer?.name}
                      className="onboarding-form-mobile__group__field__input select"
                      disabled
                      onFocus={() => {
                        setInvalidOrganizer(false);
                        if (errorMessage) {
                          setErrorMessage("");
                        }
                      }}
                    />
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card
                    className="onboarding-form-mobile__group__field__dropdown-menu"
                    style={{ maxHeight: "240px", overflowY: "auto" }}
                  >
                    {orgs.map((org, i) => (
                      <div
                        className="onboarding-form-mobile__group__field__dropdown-menu__item"
                        onClick={() => {
                          setOrganizer(org.organization);
                          setShowMenu(false);
                        }}
                      >
                        {org.organization?.name}
                      </div>
                    ))}
                  </Card>
                )}
              />
            </div>

            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                City <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <PlacesAutocomplete
                value={location}
                onChange={(x) => {
                  setLocation(x);
                  setErrorMessage("");
                }}
                onSelect={(loc) => {
                  // console.log(loc);
                  const city = loc?.split(",")[0]?.trim();
                  const state = loc?.split(",")[1]?.trim();
                  const country = loc?.split(",")[2]?.trim();

                  geocodeByAddress(loc)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                      // setLatLng(latLng);
                      setCity({
                        city,
                        state,
                        country,
                        lat: latLng.lat,
                        lng: latLng.lng,
                      });
                    })
                    .catch((error) => console.error("Error", error));
                  setLocation("");
                }}
                debounce={200}
                searchOptions={{
                  types: ["(cities)"],
                  componentRestrictions: {
                    country: "IN",
                  },
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Dropdown
                    className="onboarding-form-mobile__dropdown-input-wrapper"
                    placement="bottom-start"
                    style={{ width: "100%" }}
                    target={
                      <div
                        className={
                          "onboarding-form-mobile__group__field multi " +
                          (invalidLocation ? "error" : "")
                        }
                      >
                        <div className="onboarding-form-mobile__group__field__icon">
                          <LocationIcon
                            style={{ stroke: "var(--light-3)", height: 14 }}
                          />
                        </div>
                        <div className="onboarding-form-mobile__group__field__multi-select-wrapper">
                          {city && (
                            <Chip
                              className="multi-select-item"
                              onClick={() => {
                                setCity("");
                              }}
                            >
                              <span className="multi-select-item__text">
                                {city.city}
                              </span>{" "}
                              <TimesIcon
                                style={{
                                  stroke: "var(--light-1)",
                                  height: "14px",
                                }}
                              />{" "}
                            </Chip>
                          )}

                          {!city && (
                            <input
                              {...getInputProps({
                                className:
                                  "onboarding-form-mobile__group__field__input",
                                onFocus: () => {
                                  setInvalidLocation(false);
                                },
                                autoComplete: "off",
                              })}
                              value={location}
                              id="location"
                            />
                          )}

                          {/* </div> */}
                        </div>

                        <div
                          className="static-icon"
                          style={{ marginRight: "8px" }}
                        >
                          <IndiaFlagIcon />
                        </div>
                      </div>
                    }
                    menu={({ setShowMenu }) => (
                      <Card className="onboarding-form-mobile__group__field__dropdown-menu">
                        {loading && <Spinner />}
                        {suggestions
                          .filter(
                            (suggestion) =>
                              suggestion?.description?.split(",")?.length &&
                              suggestion?.description?.split(",").length > 2
                          )
                          .map((suggestion, i) => (
                            <div
                              key={"city-suggestion-" + i}
                              {...getSuggestionItemProps(suggestion, {
                                className:
                                  "onboarding-form-mobile__group__field__dropdown-menu__item",
                              })}
                            >
                              {suggestion.description}
                              {/*<span className="state">{city.within.value}</span>*/}
                            </div>
                          ))}
                      </Card>
                    )}
                    onClose={() => {
                      setLocation("");
                    }}
                  />
                )}
              </PlacesAutocomplete>
            </div>

            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Participant Type{" "}
                <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <Dropdown
                className="onboarding-form-mobile__dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "onboarding-form-mobile__group__field " +
                      (invalidParticipantType ? "error" : "")
                    }
                  >
                    <div className="onboarding-form-mobile__group__field__icon">
                      <BuildingIcon
                        style={{ fill: "var(--light-3)", height: 14 }}
                      />
                    </div>

                    <input
                      type="text"
                      value={participantType?.label || ""}
                      className="onboarding-form-mobile__group__field__input select"
                      disabled
                      onFocus={() => {
                        setInvalidParticipantType(false);
                        if (errorMessage) {
                          setErrorMessage("");
                        }
                      }}
                    />
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="onboarding-form-mobile__group__field__dropdown-menu">
                    {participantTypes.map((pt, i) => (
                      <div
                        key={"pt-" + i}
                        className="onboarding-form-mobile__group__field__dropdown-menu__item"
                        onClick={() => {
                          setParticipantType(pt);
                          setShowMenu(false);
                        }}
                      >
                        {pt.label}
                      </div>
                    ))}
                  </Card>
                )}
              />
            </div>
          </div>

          {errorMessage && (
            <div className="onboarding-form-mobile__error">{errorMessage}</div>
          )}

          <DebouncedButton
            type="submit"
            className="onboarding-form-mobile__submit"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Submit"}
          </DebouncedButton>
          {/* <button
            type="submit"
            className={
              "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            }
          >
            {loading ? <Loader theme="small" /> : "Submit"}
          </button> */}
        </form>
      </div>

      <div className="onboarding-form-mobile__graphic">
        <img src="/circle-bg.svg" alt="" />
      </div>

      <div className="onboarding-form-mobile__logo-bottom">
        <img src="/logo-white.svg" alt="" />
      </div>
    </div>
  );
}
