import { gql } from "@apollo/client";

const EVENT = gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      id
      name
      scheduledStart
      scheduledEnd
      registrationStart
      registrationEnd
      registrationAmount
      description
      participantType
      venue {
        id
        name
      }
      sport {
        id
        name
      }
      venueLabel
      venueValue
      venueLat
      venueLng
      profilePhotos {
        id
        filename
      }
      profilePhoto {
        id
        filename
      }
      coverPhotos {
        id
        filename
      }
      coverPhoto {
        id
        filename
      }
      city {
        id
        value
        lat
        lng
      }
      userIsRegistered {
        id
        paid
        profile {
          id
          profilePhoto {
            id
            filename
          }
          name
        }
        organization {
          id
          profilePhoto {
            id
            filename
          }
          name
        }
      }
    }
  }
`;

export default EVENT;
