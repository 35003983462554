import { useState, useEffect, useRef } from "react";

const useIntersectionObserver = (ref, defaultValue) => {
  const [isOnScreen, setIsOnScreen] = useState(defaultValue);
  // const [intersectionRatio, setIntersectionRatio] = useState(0);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      console.log("Intersection Observer:", entry.isIntersecting);
      setIsOnScreen(entry.isIntersecting);
    }, {});
  }, []);

  useEffect(() => {
    if (ref.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
};

export default useIntersectionObserver;
