// V1
import { useLayoutEffect, useState, useRef } from "react";
import "./Home.css";
import Auth from "../../screens/Auth";
import {
  ProfileCard,
  FollowersSummary,
  UpcomingEvents,
  PostsList,
  RequestsList,
} from "../../components/Home";
import { useQuery, useMutation } from "@apollo/client";
import { USER_PROFILE, TEST } from "../../api/user";
import { useAppState } from "../../utils/appState";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import Card from "../../components/Card";
import DebouncedButton from "../../components/Button/DebouncedButton";
import Loader from "../../components/Loader";

function Test() {
  const [mutate, { loading }] = useMutation(TEST);
  // const [loading, setLoading] = useMutation(false);
  // const { loading, error, data } = useQuery(TEST);

  // if (error) {
  //   // console.log("TEST ERROR:", error);
  //   // console.log("TEST GRAPHQL ERROR:", error.graphQLErrors);
  //   // console.log("TEST NETWORK ERROR:", error.networkError);

  //   return <div>Error</div>;
  // }

  // if (loading || !data) {
  //   return <div>Loading</div>;
  // }

  return (
    <DebouncedButton
      className={"blue primary medium"}
      onClick={() => {
        // setLoading(true);
        console.log("Sending api request");
        mutate();
      }}
      loading={loading}
    >
      {loading ? <Loader theme={"small"} /> : "Debounce Test"}
    </DebouncedButton>
  );
}

function Home() {
  usePageTracking({ title: "Home Page" });

  const [leftSticky, setLeftSticky] = useState("sticky-top");
  const [rightSticky, setRightSticky] = useState("sticky-top");
  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);
  const [{ isLoggedIn }] = useAppState();
  const { data, error } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Home",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
  }

  useLayoutEffect(() => {
    const elementHeight = leftColumnRef?.current?.clientHeight || 0;
    const elementY = leftColumnRef?.current?.offsetTop || 0;
    const viewportHeight = window.innerHeight;

    if (elementY + elementHeight > viewportHeight - 32) {
      setLeftSticky("sticky-bottom");
    }
  }, []);

  useLayoutEffect(() => {
    const elementHeight = rightColumnRef?.current?.clientHeight;
    const elementY = rightColumnRef?.current?.offsetTop;
    const viewportHeight = window.innerHeight;

    if (elementY + elementHeight > viewportHeight - 32) {
      setRightSticky("sticky-bottom");
    }
  }, []);

  const userProfile = data?.userProfile;

  return (
    <div className="App-content">
      {isLoggedIn ? (
        <div className="home-page">
          <div className={"left-column " + leftSticky}>
            <div className={"sticky-wrapper " + leftSticky} ref={leftColumnRef}>
              <ProfileCard />
              <FollowersSummary profileId={userProfile?.id} />
            </div>
          </div>

          <div className="middle-column">
            <PostsList />
          </div>

          <div className={"right-column " + rightSticky}>
            <div
              className={"sticky-wrapper " + rightSticky}
              ref={rightColumnRef}
            >
              {/* <Test /> */}
              {/* <Test /> */}
              <RequestsList />
              <UpcomingEvents />
            </div>
          </div>
        </div>
      ) : (
        <Auth
          hideLogo={true}
          text={"You need to login to view your dashboard."}
        />
      )}
    </div>
  );
}

export default Home;
