import { Fragment, useState } from "react";
import "./ScoreCricket.css";
import { useMutation, useQuery } from "@apollo/client";
import {
  FIXTURE_SETTINGS,
  FIXTURE_STATS,
  UPDATE_FIXTURE,
} from "../../../api/fixture";
import { STAT_TYPES } from "../../../api/sport";
import Settings from "./Settings";
import ScoringHeader from "./ScoringHeader";
import BattingSummary from "./BattingSummary";
import BowlingSummary from "./BowlingSummary";
import CurrentOver from "./CurrentOver";
import Actions from "./Actions";
import StatsList from "./StatsList";
import SelectPlayer from "./SelectPlayer";
import SelectBatsmen from "./SelectBatsmen";
import PublishStatsWrapper from "./PublishStatsWrapper";
import Spinner from "../../Spinner";
import { usePageTracking } from "../../../utils/usePageTracking";
import captureException from "../../../utils/captureException";

function ScoreCricket({
  fixtureId,
  fixture,
  eventId,
  statsCacheState,
  periods,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  completed,
  participants,
  statTypes,
  stats,
}) {
  const [cache, setCache] = useState(statsCacheState);
  const [editSettings, setEditSettings] = useState(!cache?.settings);
  const [, setEditLineups] = useState(false);
  const [publish, setPublish] = useState(false);
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    cache?.selectedPeriodId || ""
  );
  const [battingTeam, setBattingTeam] = useState(
    cache?.periods?.[selectedPeriodId]?.battingTeam || ""
  );

  const [bowlingTeam, setBowlingTeam] = useState(
    cache?.periods?.[selectedPeriodId]?.bowlingTeam || ""
  );

  const [batsmen, setBatsmen] = useState(
    cache?.periods?.[selectedPeriodId]?.batsmen || [{}, {}]
  );

  const [bowler, setBowler] = useState(
    cache?.periods?.[selectedPeriodId]?.bowler || {}
  );

  const [strikeIndex, setStrikeIndex] = useState(
    cache?.periods?.[selectedPeriodId]?.strikeIndex || 0
  );

  const [currentOverNumber, setCurrentOverNumber] = useState(
    cache?.periods?.[selectedPeriodId]?.currentOverNumber || 0
  );

  // const [currentBallLabel, setCurrentBallLabel] = useState(
  //   cache?.periods?.[selectedPeriodId]?.currentBallLabel || 1
  // );

  // const [currentOver, setCurrentOver] = useState(
  //   cache?.periods?.[selectedPeriodId]?.currentOver || [{}, {}, {}, {}, {}, {}]
  // );

  // const [currentBallIndex, setCurrentBallIndex] = useState(
  //   cache?.periods?.[selectedPeriodId]?.currentBallIndex || 0
  // );

  const [overlay, setOverlay] = useState("");

  const [altOverlay, setAltOverlay] = useState("");

  const [batsmenOverlay, setBatsmenOverlay] = useState("");

  const [selectedTag, setSelectedTag] = useState([]);

  const [selectedWicket, setSelectedWicket] = useState("");

  const [selectedFielder, setSelectedFielder] = useState("");

  const [selectedBatsman, setSelectedBatsman] = useState("");

  const [updateFixture] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricketNew",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  const ballsBowled = stats
    .filter(
      (s) =>
        s.statType.name === "Balls Bowled" &&
        s.fixturePeriod.id === selectedPeriodId
    )
    .sort(
      (a, b) =>
        Number(b.value) - Number(a.value) ||
        (b.createdAt > a.createdAt ? 1 : -1)
    );
  const groupedByOver = ballsBowled.reduce((accum, curr) => {
    const ballsBowledStat = curr;

    const runsScoredStat = stats.find(
      (s) =>
        s.statType.name === "Runs Scored" &&
        s.relationshipsFrom.find((r) => r.to.id === ballsBowledStat.id)
    );

    const wicketStat = stats.find(
      (s) =>
        [
          "Bowled",
          "LBW",
          "Caught",
          "Caught & Bowled",
          "Run Out",
          "Stumped",
          "Retired",
        ].includes(s.statType.name) &&
        s.relationshipsTo.find((r) => r.from.id === runsScoredStat.id)
    );

    const wicketTakenStat =
      wicketStat &&
      stats.find(
        (s) =>
          s.statType.name === "Wicket Taken" &&
          s.relationshipsTo.find((r) => r.from.id === wicketStat.id)
      );

    const runsConcededStat = stats.find(
      (s) =>
        s.statType.name === "Runs Conceded" &&
        s.relationshipsTo.find((r) => r.from.id === ballsBowledStat.id)
    );

    const fieldingStat =
      wicketStat &&
      wicketStat.statType.name !== "Bowled" &&
      wicketStat.statType.name !== "LBW" &&
      wicketStat.statType.name !== "Retired" &&
      stats.find(
        (s) =>
          ["Catch", "Run Out", "Stumping"].includes(s.statType.name) &&
          s.relationshipsTo.find((r) => r.from.id === wicketStat.id)
      );

    const extraStats = stats.filter(
      (s) =>
        ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name) &&
        s.relationshipsTo?.find((r) => r.from.id === ballsBowledStat.id)
    );

    const ballsFacedStat = stats.find(
      (s) =>
        s.statType.name === "Balls Faced" &&
        s.relationshipsTo?.find((r) => r.from.id === runsScoredStat.id)
    );

    const group = Math.floor(curr.value);
    const overIndex = accum.findIndex((x) => x.group === group);
    const overItem = {
      ballsBowledStat,
      runsScoredStat,
      runsConcededStat,
      wicketStat,
      wicketTakenStat,
      fieldingStat,
      extraStats,
      ballsFacedStat,
    };
    if (overIndex > -1) {
      accum[overIndex].stats.push(overItem);
    } else {
      accum.push({
        group,
        stats: [overItem],
      });
    }

    return accum;
  }, []);

  console.log(groupedByOver);

  const changePeriod = (newPeriodId) => {
    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...cache,
            selectedPeriodId: newPeriodId,
            settings: true,
            periods: {
              ...cache?.periods,
              [selectedPeriodId]: {
                battingTeam,
                bowlingTeam,
                batsmen,
                bowler,
                strikeIndex,
                currentOverNumber,
                // currentBallLabel,
                // currentOver,
                // currentBallIndex,
              },
            },
          },
        },
      },
      update: (cache, { data: { updateFixture } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            statsCacheState(existingClaimRefs, { readField }) {
              return updateFixture.statsCacheState;
            },
          },
        });
        // cache.writeQuery({
        //   query: FIXTURE_SETTINGS,
        //   variables: { id: fixtureId },
        //   data: {
        //     statsCacheState: updateFixture.statsCacheState,
        //   },
        // });

        setCache(updateFixture.statsCacheState);
        setBattingTeam(
          updateFixture.statsCacheState.periods[newPeriodId]?.battingTeam ||
            false
        );
        setBowlingTeam(
          updateFixture.statsCacheState.periods[newPeriodId]?.bowlingTeam ||
            false
        );
        setBatsmen(
          updateFixture.statsCacheState.periods[newPeriodId]?.batsmen || [
            {},
            {},
          ]
        );
        setBowler(
          updateFixture.statsCacheState.periods[newPeriodId]?.bowler || {}
        );
        setStrikeIndex(
          updateFixture.statsCacheState.periods[newPeriodId]?.strikeIndex || 0
        );
        setCurrentOverNumber(
          updateFixture.statsCacheState.periods[newPeriodId]
            ?.currentOverNumber || 0
        );
        // setCurrentBallLabel(
        //   updateFixture.statsCacheState.periods[newPeriodId]
        //     ?.currentBallLabel || 1
        // );
        // setCurrentOver(
        //   updateFixture.statsCacheState.periods[newPeriodId]?.currentOver || [
        //     {},
        //     {},
        //     {},
        //     {},
        //     {},
        //     {},
        //   ]
        // );
        // setCurrentBallIndex(
        //   updateFixture.statsCacheState.periods[newPeriodId]
        //     ?.currentBallIndex || 0
        // );
      },
    });
    setSelectedPeriodId(newPeriodId);
  };

  const updateCache = (newValues = {}) => {
    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...cache,
            selectedPeriodId,
            settings: true,
            periods: {
              ...cache?.periods,
              [selectedPeriodId]: {
                battingTeam,
                bowlingTeam,
                batsmen,
                bowler,
                strikeIndex,
                currentOverNumber,
                // currentBallLabel,
                ...newValues,
              },
            },
          },
        },
      },
      update: (cache, { data: { updateFixture } }) => {
        // const q = cache.readQuery({
        //   query: FIXTURE_SETTINGS,
        //   variables: { id: fixtureId },
        // });

        // cache.writeQuery({
        //   query: FIXTURE_SETTINGS,
        //   variables: { id: fixtureId },
        //   data: {
        //     fixture: {
        //       ...q.fixture,
        //       statsCacheState: updateFixture.statsCacheState,
        //     },
        //   },
        // });

        cache.modify({
          id: cache.identify(fixture),
          fields: {
            statsCacheState(existingClaimRefs, { readField }) {
              return updateFixture.statsCacheState;
            },
          },
        });

        setCache(updateFixture.statsCacheState);
      },
    });
  };

  const onDeleteLastBall = ({ extras, previousGroupStats }) => {
    // let newOver = [...currentOver];

    // let newBallIndex = Math.max(currentBallIndex - 1, 0);
    // let newBallLabel =
    //   extras?.length &&
    //   extras.find((x) => ["Wide", "No Ball"].includes(x.statType.name))
    //     ? currentBallLabel
    //     : Math.max(currentBallLabel - 1, 0);

    let newOverNumber = currentOverNumber;

    // if (!currentBallIndex) {
    //   newOverNumber = currentOverNumber - 1;
    //   const previousOver = previousGroupStats
    //     ?.sort((a, b) =>
    //       Number(a.ball.stringValue) > Number(b.ball.stringValue) ? 1 : -1
    //     )
    //     .map((s) => ({
    //       runs: s.value || 0,
    //       wicket: s.wicket?.statType.name || "",
    //       extra: s.extra.map((x) => x.statType.name),
    //     }));
    //   newOver = [...previousOver.slice(0, previousOver.length - 1), {}];
    //   newBallIndex = newOver.length - 1;
    //   const previousGroupExtras = previousGroupStats.reduce((accum, curr) => {
    //     accum += curr.extra?.length || 0;
    //     return accum;
    //   }, 0);
    //   newBallLabel = newOver.length - previousGroupExtras;
    // }

    // console.log("Previous group stats:", previousGroupStats);

    // console.log("Current Ball Index:", currentBallIndex);
    // console.log("Setting new ball index:", newBallIndex);

    // console.log("Current Ball Label:", currentBallLabel);
    // console.log("Setting new ball label:", newBallLabel);

    // console.log("Current Over:", currentOver);
    // console.log("Setting new current over:", newOver);

    // console.log("Current Over Number:", currentOverNumber);
    // console.log("Setting new current over number:", newOverNumber);

    // return;

    // setCurrentOver(newOver);
    setCurrentOverNumber(newOverNumber);
    // setCurrentBallIndex(newBallIndex);
    // setCurrentBallLabel(newBallLabel);

    updateFixture({
      variables: {
        where: { id: fixtureId },
        data: {
          statsCacheState: {
            ...cache,
            selectedPeriodId,
            settings: true,
            periods: {
              ...cache.periods,
              [selectedPeriodId]: {
                battingTeam,
                bowlingTeam,
                batsmen,
                bowler,
                strikeIndex,
                currentOverNumber: newOverNumber,
                // currentBallLabel: newBallLabel,
              },
            },
          },
        },
      },
    });
  };

  const onAddStat = (runs) => {
    // const newOver = [...currentOver];

    // newOver[currentBallIndex] = {
    //   runs: runs + "",
    //   wicket: selectedWicket || "",
    //   extra: selectedTag || [],
    // };

    // if (selectedTag.includes("Wide") || selectedTag.includes("No Ball")) {
    //   newOver.push({});
    // }

    // setCurrentOver(newOver);

    const currentBatsmen = [...batsmen];

    if (selectedWicket) {
      if (
        selectedWicket === "Bowled" ||
        selectedWicket === "LBW" ||
        selectedWicket === "Caught" ||
        selectedWicket === "Caught & Bowled" ||
        selectedWicket === "Stumped"
      ) {
        currentBatsmen[strikeIndex] = {};
      }

      if (selectedWicket === "Run Out" || selectedWicket === "Retired") {
        //TO DO
        const dismissedBatsmanIndex = currentBatsmen.findIndex(
          (x) => x.profile.id === selectedBatsman.profile.id
        );
        // console.log("Dismissed batsman index:", dismissedBatsmanIndex);
        if (dismissedBatsmanIndex > -1) {
          currentBatsmen[dismissedBatsmanIndex] = {};
        }
      }

      setBatsmen(currentBatsmen);
    }

    const newStrikeIndex = runs % 2 ? (strikeIndex === 1 ? 0 : 1) : strikeIndex;
    setStrikeIndex(newStrikeIndex);

    // const newBallIndex = currentBallIndex + 1;
    // setCurrentBallIndex(newBallIndex);
    // const newBallLabel =
    //   selectedTag.includes("Wide") || selectedTag.includes("No Ball")
    //     ? currentBallLabel
    //     : currentBallLabel + 1;
    // setCurrentBallLabel(newBallLabel);

    setSelectedWicket("");
    setSelectedTag([]);
    setSelectedFielder("");
    setSelectedBatsman("");

    updateFixture({
      variables: {
        where: { id: fixtureId },
        data: {
          statsCacheState: {
            ...cache,
            selectedPeriodId,
            settings: true,
            periods: {
              ...cache.periods,
              [selectedPeriodId]: {
                battingTeam,
                bowlingTeam,
                batsmen: currentBatsmen,
                bowler,
                strikeIndex: newStrikeIndex,
                currentOverNumber,
                // currentBallLabel: newBallLabel,
              },
            },
          },
        },
      },
    });
  };

  return (
    <div className="score-cricket">
      <ScoringHeader
        fixtureId={fixtureId}
        periods={periods}
        changePeriod={changePeriod}
        selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
        setEditSettings={setEditSettings}
        setEditLineups={setEditLineups}
        cache={cache}
        updateCache={updateCache}
        setPublish={setPublish}
      />
      <div className="body">
        {selectedPeriodId && (
          <Fragment>
            <div className="left-column">
              <div className="summary">
                <BattingSummary
                  fixtureId={fixtureId}
                  currentOverNumber={currentOverNumber}
                  batsmen={batsmen}
                  setOverlay={setOverlay}
                  battingTeam={battingTeam}
                  strikeIndex={strikeIndex}
                  setStrikeIndex={setStrikeIndex}
                  participants={participants}
                  setBattingTeam={setBattingTeam}
                  setBowlingTeam={setBowlingTeam}
                  setBatsmen={setBatsmen}
                  setBowler={setBowler}
                  updateCache={updateCache}
                  stats={stats}
                  groupedByOver={groupedByOver}
                />

                <BowlingSummary
                  team={bowlingTeam}
                  bowler={bowler}
                  setBowler={setBowler}
                  setOverlay={setOverlay}
                  battingTeam={battingTeam}
                  updateCache={updateCache}
                  fixtureId={fixtureId}
                  fixture={fixture}
                  cache={cache}
                  stats={stats}
                />
              </div>

              <CurrentOver
                currentOverNumber={currentOverNumber}
                setCurrentOverNumber={setCurrentOverNumber}
                strikeIndex={strikeIndex}
                setStrikeIndex={setStrikeIndex}
                setOverlay={setOverlay}
                setBowler={setBowler}
                fixtureId={fixtureId}
                cache={cache}
                selectedPeriodId={selectedPeriodId}
                battingTeam={battingTeam}
                bowlingTeam={bowlingTeam}
                batsmen={batsmen}
                bowler={bowler}
                stats={stats}
                groupedByOver={groupedByOver}
              />

              <Actions
                fixtureId={fixtureId}
                eventId={eventId}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                selectedWicket={selectedWicket}
                setSelectedWicket={setSelectedWicket}
                setOverlay={setOverlay}
                setSelectedFielder={setSelectedFielder}
                setBatsmenOverlay={setBatsmenOverlay}
                setSelectedBatsman={setSelectedBatsman}
                batsmen={batsmen}
                bowler={bowler}
                strikeIndex={strikeIndex}
                statTypes={statTypes}
                battingTeam={battingTeam}
                bowlingTeam={bowlingTeam}
                selectedFielder={selectedFielder}
                selectedBatsman={selectedBatsman}
                currentOverNumber={currentOverNumber}
                onAddStat={onAddStat}
                selectedPeriodId={selectedPeriodId}
                stats={stats}
                groupedByOver={groupedByOver}
              />
            </div>

            <div className="right-column">
              <StatsList
                id={fixtureId}
                fixture={fixture}
                battingTeam={battingTeam}
                statTypes={statTypes}
                battingLineup={
                  battingTeam?.id === homeTeam?.organization?.id
                    ? homeLineup
                    : awayLineup
                }
                bowlingLineup={
                  battingTeam?.id === homeTeam?.organization?.id
                    ? awayLineup
                    : homeLineup
                }
                setOverlay={setOverlay}
                setAltOverlay={setAltOverlay}
                onDeleteLastBall={onDeleteLastBall}
                currentOverNumber={currentOverNumber}
                selectedPeriodId={selectedPeriodId}
                cache={cache}
                stats={stats}
                groupedByOver={groupedByOver}
              />
            </div>
          </Fragment>
        )}
      </div>

      {editSettings && (
        <Settings
          fixtureId={fixtureId}
          setEditSettings={setEditSettings}
          statsCacheState={statsCacheState}
          defaultPeriods={periods}
          participants={participants}
        />
      )}

      {overlay && (
        <SelectPlayer
          title={overlay.title}
          onSelect={overlay.onSelect}
          defaultTeam={overlay.defaultTeam}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
          dismiss={() => {
            setOverlay(false);
          }}
        />
      )}

      {/* Needed for adding wickets on editing stat list item */}
      {altOverlay && (
        <SelectPlayer
          title={altOverlay.title}
          onSelect={altOverlay.onSelect}
          defaultTeam={altOverlay.defaultTeam}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
          dismiss={() => {
            setAltOverlay(false);
          }}
        />
      )}

      {batsmenOverlay && (
        <SelectBatsmen
          title={batsmenOverlay.title}
          onSelect={batsmenOverlay.onSelect}
          defaultTeam={batsmenOverlay.defaultTeam}
          batsmen={batsmen}
          dismiss={() => {
            setBatsmenOverlay(false);
          }}
        />
      )}

      {(publish || completed) && (
        <PublishStatsWrapper
          fixtureId={fixtureId}
          eventId={eventId}
          setPublish={setPublish}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
          completed={completed}
          cache={cache}
        />
      )}
    </div>
  );
}

function ScoreCricketWrapper({ id }) {
  usePageTracking({ title: "Cricket Scoring" });
  const settingsQuery = useQuery(FIXTURE_SETTINGS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  const statTypesQuery = useQuery(STAT_TYPES, {
    variables: { sport: "Cricket" },
  });

  const statsQuery = useQuery(FIXTURE_STATS, {
    variables: { id },
  });

  if (settingsQuery.error) {
    captureException({
      error: settingsQuery.error,
      info: {
        type: "query",
        component: "ScoreCricketNew.ScoreCricketWrapper",
        query: "FIXTURE_SETTINGS",
      },
    });
    console.log(settingsQuery.error);
  }

  if (statTypesQuery.error) {
    captureException({
      error: statTypesQuery.error,
      info: {
        type: "query",
        component: "ScoreCricketNew.ScoreCricketWrapper",
        query: "STAT_TYPES",
      },
    });
    console.log(statTypesQuery.error);
  }

  if (statsQuery.error) {
    captureException({
      error: statsQuery.error,
      info: {
        type: "query",
        component: "ScoreCricketNew.ScoreCricketWrapper",
        query: "FIXTURE_STATS",
      },
    });
    console.log(statsQuery.error);
  }

  if (
    settingsQuery.loading ||
    !settingsQuery.data ||
    statTypesQuery.loading ||
    !statTypesQuery.data ||
    statsQuery.loading ||
    !statsQuery.data
  ) {
    return <Spinner />;
  }

  if (settingsQuery.error || statTypesQuery.error || statsQuery.error) {
    return <div>Error</div>;
  }

  const data = settingsQuery?.data;
  const cache = data?.fixture?.statsCacheState;
  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  const homeTeam = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayTeam = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  const homeLineup = data?.fixture?.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === homeTeam?.organization?.id
  );

  const awayLineup = data?.fixture?.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === awayTeam?.organization?.id
  );

  const eventId = data?.fixture?.event?.id;
  const completed = data?.fixture?.completed;
  const statTypes = statTypesQuery?.data?.statTypes;
  const roles = data?.fixture?.roles;
  const participants = roles?.filter((r) => r.type.name === "PARTICIPANT");
  //   console.log("Cache:", cache);
  const stats = statsQuery.data.fixture.stats;

  // console.log("NEW GROUPED BY OVER:", ballsBowled);

  return (
    <ScoreCricket
      fixtureId={id}
      fixture={data.fixture}
      eventId={eventId}
      statsCacheState={cache}
      periods={periods}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
      completed={completed}
      participants={participants}
      statTypes={statTypes}
      stats={stats}
    />
  );
}

export default ScoreCricketWrapper;
