import "./FixtureReport.css";

import { useQuery } from "@apollo/client";
import { FIXTURE_REPORT, FIXTURE_MEDIA } from "../../../api/fixture";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import Post from "../../../components/Post";
import CreatePost from "../../CreatePost";
import EditPost from "../../EditPost";
import { useAppState } from "../../../utils/appState";
import captureException from "../../../utils/captureException";
import MatchReport from "../../../components/MatchReport";
import CreateMatchReport from "../../../mobileComponents/CreateMatchReport";
import EditMatchReport from "../../EditMatchReport";

function FixtureReport({ id, canManageFixture }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(FIXTURE_REPORT, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureReport",
        query: "FIXTURE_REPORT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const report = data?.fixture?.report;
  // console.log("Fixture report:", data);

  return (
    <div className="fixture-report">
      <div
        className="fixture-report__container"
        style={{ gridColumnStart: 1, gridColumnEnd: 13, paddingTop: "16px" }}
      >
        {!report && (
          <div className="no-data">
            <div className="text">
              No match report added yet. Check back soon!
            </div>
            {canManageFixture && (
              <div
                className="link"
                onClick={() => {
                  setAppState({
                    modal: (
                      <div className="modal-form-mobile-wrapper">
                        <CreateMatchReport
                          sourceId={id}
                          sourceType={"fixture"}
                          postType={"REPORT"}
                          mediaQueries={{
                            photos: {
                              onUploadPhoto: (cache, createMedia) => {},
                            },
                          }}
                          onCreatePost={(cache, createPost) => {
                            const q = cache.readQuery({
                              query: FIXTURE_REPORT,
                              variables: { id },
                            });

                            // console.log("q:", q);
                            cache.writeQuery({
                              query: FIXTURE_REPORT,
                              variables: { id },
                              data: {
                                fixture: {
                                  ...q.fixture,
                                  report: { ...createPost },
                                },
                              },
                            });

                            setAppState({ modal: false });
                          }}
                          dismiss={() => {
                            setAppState({ modal: false });
                          }}
                        />
                        {/* <CreatePost
                          sourceId={id}
                          sourceType={"fixture"}
                          postType={"REPORT"}
                          mediaQueries={{
                            photos: {
                              query: FIXTURE_MEDIA,
                              vars: { id: id, type: "IMAGE" },
                              getPhotos: (data) => {
                                return data?.fixture?.media || [];
                              },
                              getPhotosCount: (data) => {
                                return data?.fixture?.photosCount || 0;
                              },
                              onUploadPhoto: (cache, createMedia) => {
                                // console.log("Updating post photo cache");
                                const q = cache.readQuery({
                                  query: FIXTURE_MEDIA,
                                  variables: { id, type: "IMAGE" },
                                });

                                // console.log(
                                //   "Updating post photo cache 2:",
                                //   q.organization,
                                //   createMedia
                                // );

                                cache.writeQuery({
                                  query: FIXTURE_MEDIA,
                                  variables: { id, type: "IMAGE" },
                                  data: {
                                    fixture: {
                                      ...q.fixture,
                                      photosCount: q.fixture.photosCount + 1,
                                      media: [
                                        createMedia.media,
                                        ...q.fixture.media,
                                      ],
                                    },
                                  },
                                });
                              },
                            },
                            videos: {
                              query: FIXTURE_MEDIA,
                              vars: { id, type: "VIDEO" },
                              getVideos: (data) => {
                                return data?.fixture?.media || [];
                              },
                              getVideosCount: (data) => {
                                return data?.fixture?.videosCount || 0;
                              },
                              onUploadVideo: (cache, createMedia) => {
                                const q = cache.readQuery({
                                  query: FIXTURE_MEDIA,
                                  variables: { id, type: "VIDEO" },
                                });

                                cache.writeQuery({
                                  query: FIXTURE_MEDIA,
                                  variables: { id, type: "VIDEO" },
                                  data: {
                                    fixture: {
                                      ...q.fixture,
                                      videosCount: q.fixture.videosCount + 1,
                                      media: [
                                        createMedia.media,
                                        ...q.fixture.media,
                                      ],
                                    },
                                  },
                                });
                              },
                            },
                            albums: {
                              query: FIXTURE_MEDIA,
                              getAlbums: (data) => {
                                return data?.fixture?.mediaSet
                                  ? [data?.fixture?.mediaSet]
                                  : [];
                              },
                              getAlbumsCount: (data) => {
                                return 1;
                              },
                            },
                          }}
                          onCreatePost={(cache, createPost) => {
                            const q = cache.readQuery({
                              query: FIXTURE_REPORT,
                              variables: { id },
                            });

                            // console.log("q:", q);
                            cache.writeQuery({
                              query: FIXTURE_REPORT,
                              variables: { id },
                              data: {
                                fixture: {
                                  ...q.fixture,
                                  report: { ...createPost },
                                },
                              },
                            });

                            setAppState({ modal: false });
                          }}
                        /> */}
                      </div>
                    ),
                  });
                }}
              >
                Add Match Report
              </div>
            )}
          </div>
        )}

        {report && (
          <MatchReport
            key={report.id}
            post={report}
            options={false}
            hideHeader={true}
            canEdit={
              canManageFixture
                ? () => {
                    setAppState({
                      modal: (
                        <div className="modal-form-mobile-wrapper">
                          <EditMatchReport
                            post={report}
                            dismiss={() => {
                              setAppState({ modal: false });
                            }}
                            mediaQueries={{
                              photos: {
                                onUploadPhoto: (cache, createMedia) => {},
                              },
                            }}
                            queryInfo={{
                              onUpdateData: (cache, updatePost) => {
                                // console.log("Updated post:", updatePost);
                                cache.modify({
                                  id: cache.identify(report),
                                  fields: {
                                    title() {
                                      return updatePost.title;
                                    },
                                    content() {
                                      return updatePost.content;
                                    },
                                    truncatedContent() {
                                      return updatePost.truncatedContent;
                                    },
                                    contentLength() {
                                      return updatePost.contentLength;
                                    },
                                    tags() {
                                      return updatePost.tags;
                                    },
                                  },
                                });

                                setAppState({ modal: false });
                              },
                            }}
                            sourceId={id}
                            sourceType={"fixture"}
                          />
                        </div>
                      ),
                    });
                  }
                : false
            }
            hideLeagueName={true}
            contentExpanded={true}
          />
        )}
      </div>
    </div>
  );
}

export default FixtureReport;
