import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppState } from "../../utils/appState";
import { VERIFY_EMAIL } from "../../api/auth";
import captureException from "../../utils/captureException";

function VerifyEmail() {
  const history = useHistory();
  const [verifying, setVerifying] = useState(true);
  const [{ isLoggedIn }, setAppState] = useAppState();
  const { token } = useParams();

  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    update: (cache, { data: { verifyEmail } }) => {
      if (!verifyEmail.token || !verifyEmail.expiresAt) {
        // setError(true);
        setVerifying(false);
        return;
      }

      localStorage.setItem("sp_auth_token", verifyEmail.token);
      localStorage.setItem("sp_expires_at", verifyEmail.expiresAt);
      localStorage.setItem("sp_name", verifyEmail.name);
      setAppState({ isLoggedIn: true });

      history.push("/app");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "VerifyEmail",
          mutation: "VERIFY_EMAIL",
        },
      });
      console.log(error);
    },
  });

  useEffect(() => {
    verifyEmail({ variables: { token } });
  });

  return (
    <div className="verify-email-page">
      <div className="logo">
        <img src="/logo.svg" alt="" />
      </div>
      <div className="primary-text">
        {verifying ? "Verifying your email..." : "Failed to verify email"}
      </div>
      <div className="secondary-text">
        Your verification token may have expired.{" "}
        {isLoggedIn
          ? "Click here to resend the verification email."
          : "You must login to resend the verification email."}
      </div>
    </div>
  );
}

export default VerifyEmail;
