import { gql } from "@apollo/client";

const FIXTURE_LINEUPS = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      event {
        id
      }
      roles {
        id
        order
        type {
          id
          name
        }
        profile {
          id
          name
          profilePhoto{
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto{
            id
            filename
          }
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
      }
    }
  }
`;

export default FIXTURE_LINEUPS;
