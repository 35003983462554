import "./EventOnboarding.css";
import { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_EVENT } from "../../api/event";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import Square from "../../mobileComponents/Square";
import Loader from "../../components/Loader";
import moment from "moment";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

function EventSport({ id, setStep }) {
  const [sport, setSport] = useState("");

  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`id sport{id name}`),
    {
      update: (cache, { data: { updateEvent } }) => {
        // const q = cache.readQuery({
        //   query: EVENT,
        //   variables: { where: {} },
        // });

        // // console.log("Q:", q);
        // if (q?.organizations) {
        //   cache.writeQuery({
        //     query: ORGANIZATIONS,
        //     variables: { where: {} },
        //     data: {
        //       organizations: [createOrganization, ...q.organizations],
        //     },
        //   });
        // }

        // history.replace("/app/onboard_page/" + createOrganization.id);
        // setStep(3);
        setStep(2);
        // history.replace("/app/page/" + id);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "EventOnboarding.EventSport",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
  };

  return (
    <div className="onboard-page-mobile__sports">
      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Set up your event
          </div>

          <div className="onboarding-form-mobile__title__secondary">
            Step 1 of 2: Select a sport
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (loading) return;

            if (!sport) {
              return;
            }

            updateEvent({
              variables: {
                where: { id },
                data: {
                  sport: {
                    connect: { name: sport.name },
                  },
                },
              },
            });

            // setErrorMessage("");

            // if (!name || !handle) {
            //   if (!name) {
            //     setInvalidName(true);
            //   }

            //   if (!handle) {
            //     setInvalidHandle(true);
            //   }

            //   setErrorMessage("All fields are required");
            //   return;
            // }

            // isUniqueHandle({
            //   variables: { handle: handle },
            // });

            // login({
            //   variables: {
            //     email,
            //     password,
            //   },
            // });
          }}
        >
          <div className="onboard-page-mobile__categories__subtitle">
            What sport is this event for?
          </div>

          <div className="onboard-page-mobile__sports__list">
            {["Football", "Cricket", "Basketball", "Tennis", "Badminton"].map(
              (s) => (
                <Square key={"Sport-" + s}>
                  {({ squareRef, dimensions }) => (
                    <div
                      ref={squareRef}
                      className={
                        "onboard-page-mobile__sports__list__item " +
                        (sport?.name === s ? "selected" : "")
                      }
                      onClick={() => {
                        setSport({
                          name: s,
                        });
                        // setInterests((prev) => {
                        //   if (prev.find((i) => i.name === s)) {
                        //     return prev.filter((i) => i.name !== s);
                        //   } else {
                        //     return [...prev, { name: s }];
                        //   }
                        // });
                      }}
                      style={{ height: dimensions.width }}
                    >
                      <div className="sport-icon">{sportsIcons[s]}</div>
                      <div className="sport-text">{s}</div>
                    </div>
                  )}
                </Square>
              )
            )}
          </div>

          <DebouncedButton
            type="submit"
            className="onboarding-form-mobile__submit"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Next"}
          </DebouncedButton>

          {/* <button
            type="submit"
            className={
              "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            }
          >
            {loading ? <Loader theme="small" /> : "Next"}
          </button> */}
        </form>
      </div>
    </div>
  );
}

function EventDates({ id, setStep }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`id sport{id name}`),
    {
      update: (cache, { data: { updateEvent } }) => {
        // const q = cache.readQuery({
        //   query: EVENT,
        //   variables: { where: {} },
        // });

        // // console.log("Q:", q);
        // if (q?.organizations) {
        //   cache.writeQuery({
        //     query: ORGANIZATIONS,
        //     variables: { where: {} },
        //     data: {
        //       organizations: [createOrganization, ...q.organizations],
        //     },
        //   });
        // }

        // history.replace("/app/onboard_page/" + createOrganization.id);
        // setStep(3);
        // setStep(2);
        history.replace("/app/event/" + id);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "EventOnboarding.EventDates",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="onboard-page-mobile__contact">
      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Set up your event
          </div>

          <div className="onboarding-form-mobile__title__secondary">
            Step 2 of 2 : Enter event dates
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (loading) return;

            if (
              !startDate &&
              !endDate &&
              !registrationStartDate &&
              !registrationEndDate
            ) {
              history.replace("/app/event/" + id);
            }

            const vars = {};

            if (startDate) {
              vars.scheduledStart = moment(startDate).toISOString();
            }

            if (endDate) {
              vars.scheduledEnd = moment(endDate).toISOString();
            }

            if (registrationStartDate) {
              vars.registrationStart = moment(
                registrationStartDate
              ).toISOString();
            }

            if (registrationEndDate) {
              vars.registrationEnd = moment(registrationEndDate).toISOString();
            }

            updateEvent({
              variables: {
                where: { id },
                data: vars,
              },
            });
          }}
        >
          <div className="onboarding-form-mobile__groups">
            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                Start Date
              </div>

              <div className={"mobile-auth-form__form__group__field "}>
                {/* <div className="mobile-auth-form__form__group__field__icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div> */}

                <input
                  type="date"
                  className="mobile-auth-form__form__group__field__input"
                  value={startDate}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setStartDate(e.target.value);
                  }}
                  onFocus={() => {
                    // setInvalidDob(false);
                  }}
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                />
              </div>
            </div>

            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                End Date
              </div>

              <div className={"mobile-auth-form__form__group__field "}>
                {/* <div className="mobile-auth-form__form__group__field__icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div> */}

                <input
                  type="date"
                  className="mobile-auth-form__form__group__field__input"
                  value={endDate}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setEndDate(e.target.value);
                  }}
                  onFocus={() => {
                    // setInvalidDob(false);
                  }}
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                />
              </div>
            </div>

            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                Registration Start Date{" "}
              </div>

              <div className={"mobile-auth-form__form__group__field "}>
                {/* <div className="mobile-auth-form__form__group__field__icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div> */}

                <input
                  type="date"
                  className="mobile-auth-form__form__group__field__input"
                  value={registrationStartDate}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setRegistrationStartDate(e.target.value);
                  }}
                  onFocus={() => {
                    // setInvalidDob(false);
                  }}
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                />
              </div>
            </div>

            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                Registration End Date{" "}
              </div>

              <div className={"mobile-auth-form__form__group__field "}>
                {/* <div className="mobile-auth-form__form__group__field__icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div> */}

                <input
                  type="date"
                  className="mobile-auth-form__form__group__field__input"
                  value={registrationEndDate}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setRegistrationEndDate(e.target.value);
                  }}
                  onFocus={() => {
                    // setInvalidDob(false);
                  }}
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                />
              </div>
            </div>
          </div>

          <DebouncedButton
            type="submit"
            className="onboarding-form-mobile__submit"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Finish"}
          </DebouncedButton>
          {/* <button
            type="submit"
            className={
              "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            }
          >
            {loading ? <Loader theme="small" /> : "Finish"}
          </button> */}
        </form>
      </div>
    </div>
  );
}

export default function EventOnboarding() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [step, setStep] = useState(1);

  return (
    <div className="onboard-page-mobile">
      <div className="onboard-page-mobile__nav">
        <div
          className="onboard-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
      </div>

      {step === 1 && <EventSport id={id} setStep={setStep} />}
      {step === 2 && <EventDates id={id} setStep={setStep} />}

      <div className="onboarding-form-mobile__graphic">
        <img src="/circle-bg.svg" alt="" />
      </div>

      <div className="onboarding-form-mobile__logo-bottom">
        <img src="/logo-white.svg" alt="" />
      </div>
    </div>
  );
}
