import { gql } from "@apollo/client";

const PUBLISH_FIXTURE = gql`
  mutation publishFixture(
    $fixtureId: ID!
    $placements: [PublishFixturePlacementsInput!]
    $tables: [PublishFixtureTableInput!]
  ) {
    publishFixture(
      fixtureId: $fixtureId
      placements: $placements
      tables: $tables
    ) {
      id
    }
  }
`;

export default PUBLISH_FIXTURE;
