export const barChartTooltipAlt = {
  backgroundColor: "rgba(255,255,255,0.9)",
  titleColor: "#2e3438",
  titleFont: {
    family: "'neue-haas-grotesk-display', sans-serif",
  },
  bodyColor: "#818f97",
  displayColors: false,
  bodyAlign: "center",
  bodyFont: {
    family: "'neue-haas-grotesk-display', sans-serif",
    size: 24,
    weight: 700,
  },
  //   callbacks: {
  //     label,
  //   },
};
