import "./EventItem.css";
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg";
import { ReactComponent as PinIcon } from "../../../icons/pin.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar";

export default function EventItem({ ev, showDate = true }) {
  return (
    <Link to={"/app/event/" + ev.id} className="events-page-mobile__list__item">
      <div className="events-page-mobile__list__item__cover">
        {ev.coverPhoto && (
          <div className={"image " + (ev.coverPhoto ? "black" : "")}>
            <Avatar media={ev.coverPhoto} />
            {/* <div className="overlay" /> */}
          </div>
        )}
      </div>

      <div className="events-page-mobile__list__item__text">
        <div className="events-page-mobile__list__item__text__primary">
          {ev.name}
        </div>

        <div className="events-page-mobile__list__item__text__secondary">
          <PinIcon
            style={{ stroke: "var(--dark-4)", height: "10px" }}
            className="events-page-mobile__list__item__text__secondary__icon"
          />
          {ev.venueLabel ? ev.venueLabel + (ev.city?.value ? ", " : "") : ""}{" "}
          {ev.city?.value || ""}
          {!ev.venueLabel && !ev.city?.value && "TBD"}
        </div>

        <div className="events-page-mobile__list__item__text__secondary">
          <CalendarIcon
            style={{ stroke: "var(--dark-4)", height: "10px" }}
            className="events-page-mobile__list__item__text__secondary__icon"
          />
          {ev.scheduledStart
            ? moment(ev.scheduledStart).format("Do MMM YYYY")
            : "TBD"}
          {ev.scheduledStart && ev.scheduledEnd
            ? " - " + moment(ev.scheduledEnd).format("Do MMM YYYY")
            : ""}
        </div>
      </div>
    </Link>
  );
}
