import { gql } from "@apollo/client";

const CREATE_MEDIA_SET = gql`
  mutation createMediaSet(
    $name: String!
    $sourceId: ID!
    $sourceType: String!
  ) {
    createMediaSet(name: $name, sourceId: $sourceId, sourceType: $sourceType) {
      id
      name
      preview {
        id
        filename
      }
      source {
        id
        fixture {
          id
          scheduledStart
          roles(where: { type: { name: "PARTICIPANT" } }) {
            id
            organization {
              id
              name
            }
            profile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default CREATE_MEDIA_SET;
