import { Fragment, useState } from "react";
import "./ProfileStats.css";
import FilterInput from "../../components/FilterInput";
import Chip from "../Chip";
import { useParams } from "react-router-dom";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as Basketball3sIcon } from "../../icons/basketball-3x3.svg";
import { ReactComponent as Basketball5sIcon } from "../../icons/basketball-5x5.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import { ReactComponent as ControllerIcon } from "../../icons/controller.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useQuery } from "@apollo/client";

import {
  BasketballStats,
  FootballStats,
  CricketStats,
  EgamingStats,
  IndividualStats,
  BadmintonStats,
} from "./Stats";
import { PROFILE_EVENTS } from "../../api/profile";
import Loader from "../Loader";
import captureException from "../../utils/captureException";
import FootballStatsOld from "./Stats/FootballStats/FootballStatsOld";
// import FootballStatsTest from "./Stats/FootballStats/FootballStatsTest";

function SportIcon({ sport, selected }) {
  switch (sport) {
    case "Football":
      return (
        <FootballIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Esports":
      return (
        <ControllerIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Basketball":
      return (
        <BasketballIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Basketball 3x3":
      return (
        <Basketball3sIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Basketball 5x5":
      return (
        <Basketball5sIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Cricket":
      return (
        <CricketIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Box Cricket":
      return (
        <CricketIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Tennis":
      return (
        <TennisIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    case "Badminton":
      return (
        <BadmintonIcon
          style={{
            height: 20,
            fill: selected ? "white" : "var(--blue-main)",
            marginRight: "4px",
            marginLeft: "-4px",
          }}
        />
      );

    default:
      return;
  }
}

const sportsIcons = {
  Football: FootballIcon,
  Basketball: BasketballIcon,
  "Basketball 3x3": Basketball3sIcon,
  "Basketball 5x5": Basketball5sIcon,
  Cricket: CricketIcon,
  "Box Cricket": CricketIcon,
  Esports: ControllerIcon,
  Badminton: BadmintonIcon,
  Tennis: TennisIcon,
  Other: EllipsisIcon,
};

function ProfileStats() {
  const { id } = useParams();
  const [sport, setSport] = useState("");
  const [, setEvent] = useState("");
  const [events, setEvents] = useState([]);

  const { loading, error, data, refetch } = useQuery(PROFILE_EVENTS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileStats",
        query: "PROFILE_EVENTS",
      },
    });
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const userEvents = data?.profile?.events;
  // console.log(userEvents);

  const userSports = userEvents
    ?.map((e) => e.sport?.name)
    .filter(function (value, index, self) {
      return value && self.indexOf(value) === index;
    });

  const selectedSport = sport || userSports?.[0];

  // console.log("SS:", selectedSport);
  // console.log("Events:", userEvents);

  return (
    <div className="profile-stats">
      {!userEvents?.length && (
        <div style={{ gridColumnStart: 1, gridColumnEnd: 9 }}>
          <div className="no-data">
            <div>There are no stats for this page yet.</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        </div>
      )}

      {!!userEvents?.length && (
        <Fragment>
          <div className="left-column">
            {selectedSport === "Football" && (
              // <FootballStatsTest id={id} events={events.map((x) => x.id)} />
              <Fragment>
                {/* <FootballStatsOld id={id} events={events.map((x) => x.id)} /> */}
                <FootballStats id={id} events={events.map((x) => x.id)} />
              </Fragment>
            )}
            {selectedSport.includes("Basketball") && (
              <BasketballStats
                id={id}
                sport={selectedSport}
                events={events.map((x) => x.id)}
              />
            )}
            {selectedSport.includes("Cricket") && (
              <CricketStats
                id={id}
                sport={selectedSport}
                events={events.map((x) => x.id)}
              />
            )}
            {selectedSport === "Tennis" && (
              <IndividualStats id={id} events={events.map((x) => x.id)} />
            )}
            {selectedSport === "Badminton" && (
              <BadmintonStats id={id} events={events.map((x) => x.id)} />
            )}
            {selectedSport === "Esports" && (
              <EgamingStats id={id} events={events.map((x) => x.id)} />
            )}
            {selectedSport === "Other" && (
              <IndividualStats id={id} events={events.map((x) => x.id)} />
            )}
          </div>

          <div className="right-column">
            <div className="card-section">
              <div className="filter-item">
                <div className="filter-item__input__label">Filter by Sport</div>

                <div className="filter-item__input">
                  {userSports?.map((s) => (
                    <div
                      className={
                        "filter-item__input__button-filter " +
                        (sport === s || (!sport && userSports?.[0] === s)
                          ? "selected"
                          : "")
                      }
                      onClick={() => {
                        setSport(s);
                        setEvent("");
                      }}
                    >
                      {sportsIcons[s] && (
                        <SportIcon
                          sport={s}
                          selected={
                            sport === s || (!sport && userSports?.[0] === s)
                          }
                        />
                      )}
                      {s}
                    </div>
                  ))}

                  {/*<div
                className={
                  "filter-item__input__button-filter " +
                  (sport === "Other" ? "selected" : "")
                }
                onClick={() => {
                  setSport("Other");
                  setEvent("");
                }}
              >
                <EllipsisIcon
                  style={{
                    height: 20,
                    fill: sport === "Other" ? "white" : "var(--blue-main)",
                  }}
                />
                </div>*/}
                </div>
              </div>

              <div className="filter-item">
                <div className="filter-item__input__label">Filter by Event</div>
                <div className="filter-item__input">
                  <FilterInput
                    placeholder={"Select an event"}
                    items={userEvents.filter(
                      (x) =>
                        selectedSport &&
                        x.sport?.name === selectedSport &&
                        !events.find((y) => y.id === x.id)
                    )}
                    onItemSelect={(ev) => {
                      setEvents((prev) => [...prev, ev]);
                    }}
                  />
                </div>

                <div className="filter-item__applied">
                  {events.map((s) => (
                    <Chip
                      onClick={() => {
                        const newValue = events.filter((x) => x.id !== s.id);
                        setEvents(newValue);
                        // removeFilter(newValue);
                        // setAppState({ [appStateKey]: newValue });
                      }}
                    >
                      {s.name}{" "}
                      <TimesIcon style={{ stroke: "white", height: 12 }} />
                    </Chip>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ProfileStats;
