import { gql } from "@apollo/client";

const SPORTS = gql`
  query sports {
    sports {
      id
      createdAt
      name
      participantType
    }
  }
`;

export default SPORTS;
