import { useState } from "react";
import { useQuery } from "@apollo/client";
import { BADMINTON_STATS } from "../../../../api/fixture";
import Card from "../../../Card";
import Spinner from "../../../Spinner";
import LineChart from "../../../LineChart";
import NewDropdown from "../../../NewDropdown";
import { ReactComponent as ChevronDownIcon } from "../../../../icons/chevron-down.svg";
import { pointsTimelineTooltip } from "../tooltips/pointsTimelineTooltip";

export default function PointsTimeline({
  periods,
  fixtureId,
  homeParticipant,
  awayParticipant,
}) {
  const [selectedPeriod, setSelectedPeriod] = useState(periods[0] || false);
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats = data?.fixture?.stats;
  const participantType = data?.fixture?.event?.participantType;
  const points = stats
    ?.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.fixturePeriod.id === selectedPeriod?.id
    )
    .sort((a, b) => a.timeStamp - b.timeStamp);

  // console.log("POINTS:", points);
  const datasets = points.reduce(
    (accum, curr, i) => {
      if (
        participantType === "INDIVIDUAL"
          ? curr.profile.id === homeParticipant.profile.id
          : curr.organization.id === homeParticipant.organization.id
      ) {
        accum[0].data.push(accum[0].data[accum[0].data.length - 1] + 1);
        accum[1].data.push(accum[1].data[accum[1].data.length - 1]);
      } else {
        accum[0].data.push(accum[0].data[accum[0].data.length - 1]);
        accum[1].data.push(accum[1].data[accum[1].data.length - 1] + 1);
      }

      return accum;
    },
    [
      {
        label:
          homeParticipant.profile?.name || homeParticipant.organization?.name,
        displayColors: false,
        data: [0],
        fill: false,
        borderColor: "#13a0fa",
        pointHoverBackgroundColor: "#ebf7ff",
        pointHoverBorderWidth: 4,
        tension: 0,
      },
      {
        label:
          awayParticipant.profile?.name || awayParticipant.organization?.name,
        data: [0],
        fill: false,
        borderColor: "#ffaa2b",
        pointHoverBackgroundColor: "#fff7eb",
        pointHoverBorderWidth: 4,
        tension: 0,
      },
    ]
  );

  // console.log("Datasets:", datasets);
  return (
    <Card className="fixture-points-timeline">
      <div className="fixture-points-timeline__header">
        <div className="fixture-points-timeline__header__title">
          Point-by-point
        </div>
        <NewDropdown
          target={
            <div className="fixture-points-timeline__header__select-period">
              {selectedPeriod?.label || ""}
              <ChevronDownIcon
                style={{ height: "14px", fill: "var(--light-4)" }}
              />
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="fixture-points-timeline__select-period-menu">
              {periods.map((period) => (
                <div
                  className="fixture-points-timeline__select-period-menu__item"
                  onClick={() => {
                    setSelectedPeriod(period);
                    setShowMenu(false);
                  }}
                >
                  {period.label}
                </div>
              ))}
            </Card>
          )}
        />
      </div>
      <div className="fixture-points-timeline__body">
        <LineChart
          datasets={datasets}
          labels={[...Array(datasets[0].data.length).keys()]}
          suggestedMin={0}
          suggestedMax={21}
          tooltip={pointsTimelineTooltip}
        />
      </div>
    </Card>
  );
}
