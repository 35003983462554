import { gql } from "@apollo/client";

const CAN_CREATE_EVENT = gql`
  query canCreateEventQuery {
    canCreateEvent {
      isLoggedIn
      isOnboarded
      hasPages
    }
  }
`;

export default CAN_CREATE_EVENT;
