import "./LandingNew.css";
import { ReactComponent as PlayIcon } from "../../icons/play.svg";
import { useHistory } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";
import { useAppState } from "../../utils/appState";
import { Test } from "../../components";

export default function LandingNew() {
  usePageTracking({ title: "Landing Page" });
  const history = useHistory();
  const [{ isLoggedIn }] = useAppState();

  return (
    <div className="landing-page-new">
      <div className="landing-page-new__blur">
        <img src="blur-1.png" />
      </div>
      <div className="landing-page-new__bg">
        <img src="lines.png" />
      </div>
      <div className="landing-page-new__logo">
        <img src="sp-symbol-color.svg" />
      </div>

      <div className="landing-page-new__text">
        <div className="landing-page-new__text__secondary">ALL THINGS</div>
        <div className="landing-page-new__text__primary">SPORT</div>
        <div className="landing-page-new__text__tertiary">
          Experience the thrill of sports like never before with Sports Paddock.
          Create, manage, and join events, build teams, and connect with fellow
          athletes. Sign up now and join the sports revolution.
        </div>
        <div
          onClick={() => {
            isLoggedIn ? history.push("/app") : history.push("/auth?s=signup");
          }}
          className="landing-page-new__text__link"
        >
          Get Started Now
          <PlayIcon className="landing-page-new__text__link__icon" />
        </div>
      </div>

      <div className="landing-page-new__banner">
        <img src="banner.png" />
      </div>

      <div className="landing-page-new__hero">
        <img src="hero.png" />
      </div>

      <div className="landing-page-new__cta">
        <button
          className="landing-page-new__cta__section"
          onClick={() => {
            isLoggedIn ? history.push("/app") : history.push("/auth?s=login");
          }}
        >
          LOGIN
        </button>
        <div className="landing-page-new__cta__seperator">|</div>
        <button
          className="landing-page-new__cta__section"
          onClick={() => {
            isLoggedIn ? history.push("/app") : history.push("/auth?s=signup");
          }}
        >
          SIGNUP
        </button>
        {/* {"LOGIN | SIGNUP"} */}
      </div>
    </div>
  );
}
