import "./BadmintonStats.css";
import { useQuery } from "@apollo/client";
import { PROFILE_STATS } from "../../../../api/profile";
import PieChart from "../../../../components/PieChart";
import captureException from "../../../../utils/captureException";

function ProfileRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record-mobile__wrapper">
      <div
        className="organization-record-mobile"
        style={{ boxShadow: "none", margin: 0 }}
      >
        <div
          className="organization-record-mobile__meta"
          style={{ width: "60%" }}
        >
          <div className="organization-record-mobile__meta__title">RECORD</div>

          <div className="organization-record-mobile__meta__highlight">
            <div className="organization-record-mobile__meta__highlight__value">
              {total}
            </div>
            <div className="organization-record-mobile__meta__highlight__label">
              Games Played
            </div>
          </div>
        </div>

        <div
          className="organization-record-mobile__items"
          style={{
            width: "40%",
            gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          }}
        >
          <div className="organization-record-mobile__items__item">
            <div className="organization-record-mobile__items__item__value">
              {wins}
            </div>

            <div className="organization-record-mobile__items__item__graph">
              <div className="organization-record-mobile__items__item__graph__track">
                <div
                  className="organization-record-mobile__items__item__graph__fill"
                  style={{ height: total ? (wins / total) * 100 + "%" : "1%" }}
                ></div>
              </div>
            </div>

            <div className="organization-record-mobile__items__item__label">
              W
            </div>
          </div>

          <div className="organization-record-mobile__items__item">
            <div className="organization-record-mobile__items__item__value">
              {losses}
            </div>

            <div className="organization-record-mobile__items__item__graph">
              <div className="organization-record-mobile__items__item__graph__track">
                <div
                  className="organization-record-mobile__items__item__graph__fill"
                  style={{
                    height: total ? (losses / total) * 100 + "%" : "1%",
                  }}
                ></div>
              </div>
            </div>

            <div className="organization-record-mobile__items__item__label">
              L
            </div>
          </div>
        </div>
      </div>
      <div
        className="profile-shooting-stats-mobile__doughnut__wrapper"
        style={{ padding: "32px" }}
      >
        <div className="profile-shooting-stats-mobile__doughnut">
          <div className="profile-shooting-stats-mobile__doughnut__bg">
            <PieChart
              value={wins || 0}
              total={losses || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={wins || 0}
            total={losses || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats-mobile__doughnut__meta">
            <div className="profile-shooting-stats-mobile__doughnut__meta__value">
              {Math.round((wins / total) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats-mobile__doughnut__meta__key">
              Win Rate <br /> (%)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfilePoints({ total, wins, losses, unforcedErrors }) {
  return (
    <div className="organization-record-mobile__wrapper">
      <div
        className="organization-record-mobile"
        style={{ boxShadow: "none", margin: 0 }}
      >
        <div
          className="organization-record-mobile__meta"
          style={{ width: "60%" }}
        >
          <div className="organization-record-mobile__meta__title">POINTS</div>

          <div className="organization-record-mobile__meta__highlight">
            <div className="organization-record-mobile__meta__highlight__value">
              {total}
            </div>
            <div className="organization-record-mobile__meta__highlight__label">
              Points Played
            </div>
          </div>
        </div>

        <div
          className="organization-record-mobile__items"
          style={{
            width: "40%",
            gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          }}
        >
          <div className="organization-record-mobile__items__item">
            <div className="organization-record-mobile__items__item__value">
              {wins}
            </div>

            <div className="organization-record-mobile__items__item__graph">
              <div className="organization-record-mobile__items__item__graph__track">
                <div
                  className="organization-record-mobile__items__item__graph__fill"
                  style={{ height: total ? (wins / total) * 100 + "%" : "1%" }}
                ></div>
              </div>
            </div>

            <div className="organization-record-mobile__items__item__label">
              W
            </div>
          </div>

          <div className="organization-record-mobile__items__item">
            <div className="organization-record-mobile__items__item__value">
              {losses}
            </div>

            <div className="organization-record-mobile__items__item__graph">
              <div className="organization-record-mobile__items__item__graph__track">
                <div
                  className="organization-record-mobile__items__item__graph__fill"
                  style={{
                    height: total ? (losses / total) * 100 + "%" : "1%",
                  }}
                ></div>
              </div>
            </div>

            <div className="organization-record-mobile__items__item__label">
              L
            </div>
          </div>
        </div>
      </div>
      <div
        className="profile-shooting-stats-mobile__doughnut__wrapper"
        style={{ padding: "32px" }}
      >
        <div className="profile-shooting-stats-mobile__doughnut">
          <div className="profile-shooting-stats-mobile__doughnut__bg">
            <PieChart
              value={unforcedErrors || 0}
              total={losses - unforcedErrors || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={unforcedErrors || 0}
            total={losses - unforcedErrors || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats-mobile__doughnut__meta">
            <div className="profile-shooting-stats-mobile__doughnut__meta__value">
              {Math.round((unforcedErrors / losses) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats-mobile__doughnut__meta__key">
              Unforced Error <br /> (%)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileServes({ total, wins, losses }) {
  return (
    <div className="organization-record-mobile__wrapper">
      <div
        className="organization-record-mobile"
        style={{ boxShadow: "none", margin: 0 }}
      >
        <div
          className="organization-record-mobile__meta"
          style={{ width: "60%" }}
        >
          <div className="organization-record-mobile__meta__title">SERVES</div>

          <div className="organization-record-mobile__meta__highlight">
            <div className="organization-record-mobile__meta__highlight__value">
              {total}
            </div>
            <div className="organization-record-mobile__meta__highlight__label">
              Serves
            </div>
          </div>
        </div>

        <div
          className="organization-record-mobile__items"
          style={{
            width: "40%",
            gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          }}
        >
          <div className="organization-record-mobile__items__item">
            <div className="organization-record-mobile__items__item__value">
              {wins}
            </div>

            <div className="organization-record-mobile__items__item__graph">
              <div className="organization-record-mobile__items__item__graph__track">
                <div
                  className="organization-record-mobile__items__item__graph__fill"
                  style={{ height: total ? (wins / total) * 100 + "%" : "1%" }}
                ></div>
              </div>
            </div>

            <div className="organization-record-mobile__items__item__label">
              W
            </div>
          </div>

          <div className="organization-record-mobile__items__item">
            <div className="organization-record-mobile__items__item__value">
              {losses}
            </div>

            <div className="organization-record-mobile__items__item__graph">
              <div className="organization-record-mobile__items__item__graph__track">
                <div
                  className="organization-record-mobile__items__item__graph__fill"
                  style={{
                    height: total ? (losses / total) * 100 + "%" : "1%",
                  }}
                ></div>
              </div>
            </div>

            <div className="organization-record-mobile__items__item__label">
              L
            </div>
          </div>
        </div>
      </div>
      <div
        className="profile-shooting-stats-mobile__doughnut__wrapper"
        style={{ padding: "32px" }}
      >
        <div className="profile-shooting-stats-mobile__doughnut">
          <div className="profile-shooting-stats-mobile__doughnut__bg">
            <PieChart
              value={wins || 0}
              total={losses || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={wins || 0}
            total={losses || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats-mobile__doughnut__meta">
            <div className="profile-shooting-stats-mobile__doughnut__meta__value">
              {Math.round((wins / total) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats-mobile__doughnut__meta__key">
              Serves Won <br /> (%)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileParticipationHistory({ participationHistory }) {
  const rows = participationHistory.map((p) => ({
    ...p,
    stats: JSON.parse(p.stats.data),
  }));
  return (
    <div className="profile-participation-stats-mobile">
      <div className="profile-participation-stats-mobile__title">
        PARTICIPATION HISTORY
      </div>

      <div className="profile-participation-stats-mobile__table">
        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "50%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header with-image">
            Event
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td with-image " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {/* <div className="profile-participation-stats-mobile__table__td__image"></div> */}
                <div className="profile-participation-stats-mobile__table__td__meta">
                  <div className="profile-participation-stats-mobile__table__td__meta__primary">
                    {r.event?.name}
                  </div>

                  {/* <div className="profile-participation-stats-mobile__table__td__meta__secondary">
                        {r.organization?.name}
                      </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            GP
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Appearances"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            G
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Goal"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            A
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Assist"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            RC
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Red Card"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            YC
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Yellow Card"] || 0}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function BadmintonStats({ id, events }) {
  const { loading, error, data } = useQuery(PROFILE_STATS, {
    variables: {
      id,
      sport: "Badminton",
      statTypes: ["Point Played", "Point Won", "Service", "Unforced Error"],
      participationStatTypes: [
        "Point Played",
        "Point Won",
        "Service",
        "Unforced Error",
      ],
      events,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.BadmintonStats",
        query: "PROFILE_STATS",
      },
    });
    console.log(error);
  }

  if (loading) {
    return <div>Loading</div>;
  }

  // console.log("STATS:", data);

  const { appearances, participationHistory, servicesWon } =
    data?.profile?.stats || {};

  const statsData = JSON.parse(
    data?.profile?.stats?.aggregations?.data || "{}"
  );

  // console.log("Record:", data?.profile?.stats?.record);
  const record = JSON.parse(data?.profile?.stats?.record?.data || {});
  // console.log("PH:", participationHistory);
  return (
    <div className="profile-stats-mobile__badminton">
      <ProfileRecordStats
        total={appearances}
        wins={record.wins}
        losses={record.losses}
      />

      <ProfilePoints
        total={statsData["Point Played"] || 0}
        wins={statsData["Point Won"] || 0}
        losses={
          (statsData["Point Played"] || 0) - (statsData["Point Won"] || 0)
        }
        unforcedErrors={statsData["Unforced Error"] || 0}
      />

      <ProfileServes
        total={statsData["Service"]}
        wins={servicesWon}
        losses={(statsData["Service"] || 0) - (servicesWon || 0)}
      />

      <ProfileParticipationHistory
        participationHistory={participationHistory}
      />
    </div>
  );
}
