import "./EventFixtures.css";
import Card from "../Card";
import { useState, Fragment } from "react";
import FixtureListItem from "../FixtureListItem";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  EVENT_FIXTURES,
  EVENT_PARTICIPANTS,
  EVENT_REGISTRATIONS,
  EVENT_REGISTRATION_FILTERS,
} from "../../api/event";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import Placeholder from "../Placeholder/Placeholder";
import captureException from "../../utils/captureException";

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value?.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function EventPart({ part, fixtures, parts }) {
  return (
    <Fragment>
      <div className="event-part">
        <div className="event-part-title">
          {part?.label || "Ungrouped fixtures"}
        </div>
        <div className="event-fixtures-list">
          {part?.fixtures?.map((fixture) => (
            <div
              key={"event-fixtures-part-fixtures-" + fixture.id}
              className="event-fixture-card-container"
            >
              <Link to={"/app/fixture/" + fixture.id}>
                <FixtureListItem fixture={fixture} />
              </Link>
            </div>
          ))}
        </div>
      </div>

      {parts
        ?.filter((p) => p.partOf?.id === part.id)
        .map((subpart) => (
          <EventPart
            key={"event-fixtures-subpart-" + subpart.id}
            part={{
              ...subpart,
              fixtures: fixtures?.filter((f) => f.eventPart?.id === subpart.id),
            }}
            parts={parts}
            fixtures={fixtures}
          />
        ))}
    </Fragment>
  );
}

function EventFixtures() {
  const { id } = useParams();
  const [teamsFilter, setTeamsFilter] = useState([]);
  const { loading, error, data, refetch, variables } = useQuery(
    EVENT_FIXTURES,
    {
      variables: { id },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventFixtures",
        query: "EVENT_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-fixtures">
        <div className="left-column">
          <div className="event-part">
            <div className="event-part-title"></div>
            <div className="event-fixtures-list">
              {[1, 2, 3, 4].map((i) => (
                <div
                  key={"ef-ph-" + i}
                  className="event-fixture-card-container"
                >
                  <Card className="fixture-list-item">
                    <div className="participants">
                      {[1, 2].map((p) => (
                        <div key={"ef-ph-2-" + p} className="participant">
                          <div className="image"></div>
                          <div className="name">
                            <Placeholder
                              style={{ height: "12px", width: "80px" }}
                              pulse={true}
                            />
                          </div>
                          <div className="score">
                            <Placeholder
                              style={{ height: "12px", width: "20px" }}
                              pulse={true}
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="datetime"></div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="right-column"></div>
      </div>
    );
  }

  const fixtures = data.event?.fixtures || [];
  const parts = data.event?.parts || [];
  const sortedParts = [...parts]
    .sort((a, b) => {
      return a.order - b.order;
    })
    .map((part) => {
      const partFixtures = fixtures?.filter((f) => f.eventPart?.id === part.id);
      let fixturesWithNoDates = [];
      let fixturesWithDates = [];
      for (let i = 0; i < partFixtures.length; i++) {
        const f = partFixtures[i];
        if (f.scheduledStart) {
          fixturesWithDates.push(f);
        } else {
          fixturesWithNoDates.push(f);
        }
      }
      const sortedFixturesWithNoDates = fixturesWithNoDates.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const sortedFixturesWithDates = fixturesWithDates.sort((a, b) => {
        return new Date(a.scheduledStart) - new Date(b.scheduledStart);
      });

      return {
        ...part,
        fixtures: [...sortedFixturesWithDates, ...sortedFixturesWithNoDates],
      };
    });

  const participantType = data?.event?.participantType;

  console.log("PT:", participantType);

  return (
    <div className="event-fixtures">
      <div className="left-column">
        {!fixtures?.length && (
          <div className="no-data">
            <div className="text">
              Fixtures for this event will be added soon. Stay tuned!
            </div>
          </div>
        )}

        {sortedParts
          ?.filter((part) => !part.partOf)
          .map((part) => (
            <EventPart
              key={"event-fixtures-part-" + part.id}
              part={part}
              parts={parts}
              fixtures={fixtures}
            />
          ))}

        {fixtures.find((f) => !f.eventPart) && (
          <EventPart
            part={false}
            fixtures={fixtures?.filter((f) => !f.eventPart)}
          />
        )}
      </div>
      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={
              participantType === "TEAM" ? "Enter a team" : "Enter a player"
            }
            query={
              participantType === "TEAM"
                ? EVENT_PARTICIPANTS
                : EVENT_REGISTRATION_FILTERS
            }
            vars={{ id, historical: true }}
            items={(d) => {
              if (participantType === "TEAM") {
                return d?.event?.participants?.map((x) => {
                  return x.organization || x.profile;
                });
              } else {
                return d?.event?.registrationFilters?.map((x) => {
                  return x.organization || x.profile;
                });
              }
            }}
            addFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  ...(participantType === "TEAM"
                    ? { teams: orgs.map((s) => s.id) }
                    : { players: orgs.map((s) => s.id) }),
                },
              });
            }}
            removeFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  ...(participantType === "TEAM"
                    ? { teams: orgs.map((s) => s.id) }
                    : { players: orgs.map((s) => s.id) }),
                },
              });
            }}
            defaultValue={teamsFilter}
            appStateKey={"teamsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default EventFixtures;
