import { gql } from "@apollo/client";

export function USER_PROFILE({ selectionSet }) {
  return gql`
      query userProfile {
        userProfile {
          id
          ${selectionSet}
        }
      }
    `;
}
