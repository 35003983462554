import { gql } from "@apollo/client";

const GET_CACHED_PROFILE_STATS = gql`
  query profileStatsQuery($profileId: ID!, $sport: String!, $events: [ID!]) {
    getCachedProfileStats(
      profileId: $profileId
      sport: $sport
      events: $events
    ) {
      id
      updatedAt
      data
    }
  }
`;

export default GET_CACHED_PROFILE_STATS;
