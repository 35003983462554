import { useState, Fragment } from "react";
import "./Onboarding.css";
import Button from "../Button";
import Chip from "../Chip";
import Card from "../Card";
import Alert from "../Alert";
import Dropdown from "../Dropdown";
import Spinner from "../Spinner";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import { ReactComponent as IndiaFlagIcon } from "../../icons/india-flag.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useAppState } from "../../utils/appState";
import { useMutation, useQuery } from "@apollo/client";
import { USER_PROFILE, UPDATE_USER_PROFILE } from "../../api/user";
import { SEND_VERIFICATION_EMAIL } from "../../api/auth";
import { SPORTS } from "../../api/sport";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import determineOnboardingStep from "../../utils/determineOnboardingStep";
import isValidPhoneNumber from "../../utils/isValidPhoneNumber";
import captureException from "../../utils/captureException";
import DebouncedButton from "../Button/DebouncedButton";
import Loader from "../Loader";

function Row({ children, columns, gap = 40 }) {
  return (
    <div
      className="row"
      style={{
        gridTemplateColumns: `repeat(${columns || 2}, minmax(0,1fr))`,
        gridColumnGap: gap,
      }}
    >
      {children}
    </div>
  );
}

function EmailOnboarding({ setStep }) {
  const [, setAppState] = useAppState();
  const [resentVerificationEmail, setResentVerificationEmail] = useState(false);
  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL, {
    update: (cache, { data: { sendVerificationEmail } }) => {
      if (sendVerificationEmail.success) {
        setResentVerificationEmail(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Onboarding.EmailOnboarding",
          mutation: "SEND_VERIFICATION_EMAIL",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="onboarding-form">
      <div className="onboarding-primary-text">Here we go!</div>

      <div className="onboarding-secondary-text">
        You can complete your profile by clicking the verification link sent to
        your registered email address.{" "}
      </div>

      <div className="onboarding-secondary-text">
        <span
          className="link-text hover-pointer"
          onClick={() => {
            sendVerificationEmail();
          }}
        >
          Click here
        </span>{" "}
        to resend the verification link.
      </div>

      {resentVerificationEmail && (
        <div style={{ marginTop: 32 }}>
          <Alert
            head={"Verification link has been sent to your email."}
            theme="green"
          />
        </div>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="options-group">
          <Button
            className="primary blue large "
            style={{ marginRight: "auto" }}
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            Finish
          </Button>
        </div>
      </form>
    </div>
  );
}

function PreferencesOnboarding({ setStep, profile }) {
  const [playsSport, setPlaysSport] = useState(profile.playsSport || false);
  const [worksInSport, setWorksInSport] = useState(
    profile.worksInSport || false
  );
  const [interests, setInterests] = useState(profile.interests);
  const [otherSportSearch, setOtherSportSearch] = useState("");

  const sportsQuery = useQuery(SPORTS);

  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    update: (cache, { data: { updateUserProfile } }) => {
      setStep(2);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Onboarding.PreferencesOnboarding",
          mutation: "UPDATE_USER_PROFILE",
        },
      });
      console.log(error);
    },
  });

  if (sportsQuery.error) {
    captureException({
      error: sportsQuery.error,
      info: {
        type: "query",
        component: "Onboarding.PreferencesOnboarding",
        query: "SPORTS",
      },
    });
  }

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
  };

  const filteredSportsList =
    sportsQuery?.data?.sports.filter(
      (s) =>
        !["Football", "Basketball", "Cricket", "Tennis", "Badminton"].includes(
          s.name
        )
    ) || [];
  const otherSportsList = otherSportSearch
    ? filteredSportsList.filter((s) =>
        s.name.toLowerCase().includes(otherSportSearch)
      )
    : filteredSportsList;

  const otherSportsSelectedCount = filteredSportsList.filter((s) =>
    interests.find((i) => i.name === s.name)
  ).length;

  return (
    <div className="onboarding-form">
      <div className="onboarding-primary-text">Warming up...</div>
      <div className="onboarding-secondary-text">
        Customize your Sports Paddock experience by filling out the details
        below. You can also{" "}
        <span
          className="hover-pointer text-link"
          onClick={() => {
            setStep(2);
          }}
        >
          do this later
        </span>
        .
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) {
            return;
          }

          const vars = {
            worksInSport,
            playsSport,
          };

          if (interests?.length) {
            vars.interests = {
              connect: interests.map((i) => ({ name: i.name })),
            };
          }

          updateUserProfile({
            variables: {
              data: vars,
            },
          });
        }}
      >
        <div className="form-group vertical">
          <div className="static-label">
            Select the options that apply to you.
          </div>
          <div className="preference-options">
            <Row columns={2} gap={32}>
              <Card
                className={
                  "preference-options-item hover-pointer " +
                  (playsSport ? "selected" : "")
                }
                onClick={() => {
                  setPlaysSport(!playsSport);
                }}
              >
                <div className="icon"></div>
                <div className="text">
                  <div className="primary">I enjoy playing sports.</div>
                  <div className="secondary"></div>
                </div>
                <div className="selected-indicator"></div>
              </Card>

              <Card
                className={
                  "preference-options-item hover-pointer " +
                  (worksInSport ? "selected" : "")
                }
                onClick={() => {
                  setWorksInSport(!worksInSport);
                }}
              >
                <div className="icon"></div>
                <div className="text">
                  <div className="primary">
                    I work with sports organizations.{" "}
                  </div>
                  <div className="secondary"></div>
                </div>
                <div className="selected-indicator"></div>
              </Card>
            </Row>
          </div>
        </div>

        <div className="form-group vertical">
          <div className="static-label">What sports are you interested in?</div>

          <div className="sports-selector">
            {["Football", "Cricket", "Basketball", "Tennis", "Badminton"].map(
              (s) => (
                <Card
                  className={
                    "sports-selector-item " +
                    (interests.find((i) => i.name === s) ? "selected" : "")
                  }
                  onClick={() => {
                    if (interests.find((i) => i.name === s)) {
                      setInterests(interests.filter((i) => i.name !== s));
                    } else {
                      setInterests([...interests, { name: s }]);
                    }
                  }}
                >
                  <div className="sport-icon">{sportsIcons[s]}</div>
                  <div className="sport-text">{s}</div>
                </Card>
              )
            )}

            <Dropdown
              target={({ showMenu }) => (
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input hide-label-on-active"
                    onChange={(e) => {
                      setOtherSportSearch(e.target.value);
                    }}
                    value={otherSportSearch}
                  />
                  {!showMenu && (
                    <label>
                      {otherSportsSelectedCount
                        ? otherSportsSelectedCount + " "
                        : ""}{" "}
                      Other
                    </label>
                  )}
                </div>
              )}
              menu={({ setShowMenu }) => (
                <Card className="sports-preference-input-menu">
                  {otherSportsList.map((sport) => (
                    <div
                      className="other-sports-selector-item"
                      onClick={() => {
                        if (interests.find((i) => i.name === sport.name)) {
                          setInterests(
                            interests.filter((i) => i.name !== sport.name)
                          );
                        } else {
                          setInterests([...interests, sport]);
                        }
                      }}
                    >
                      <div className="other-sports-selector-item-text">
                        {sport.name}
                      </div>
                      <div
                        className={
                          "app-checkbox " +
                          (interests.find((i) => i.name === sport.name)
                            ? "checked"
                            : "")
                        }
                      >
                        <div className="checkbox-indicator ">
                          {interests.find((i) => i.name === sport.name) && (
                            <img src={"/check.svg"} alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              )}
              onClose={() => {
                setOtherSportSearch("");
              }}
            />
          </div>
        </div>

        <div className="options-group">
          {/* <div
            className="skip-button hover-pointer"
            onClick={() => {
              setStep(2);
            }}
          >
            Skip this step
          </div> */}
          <div
            className="skip-button hover-pointer"
            onClick={() => {
              setStep(0);
            }}
            // style={{ marginRight: "auto" }}
          >
            Prev
          </div>

          <DebouncedButton
            type="submit"
            className="app-button primary blue large submit-button"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Next"}
          </DebouncedButton>
          {/* <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Next{" "}
          </button> */}
        </div>
      </form>
    </div>
  );
}

function ProfileOnboarding({ setStep, profile }) {
  // console.log(profile);
  const [location, setLocation] = useState("");

  const [cities, setCities] = useState(
    profile?.cities.map((c) => ({ ...c, city: c.value })) || []
  );
  const [phone, setPhone] = useState(profile?.phone || "");
  const [dob, setDob] = useState(
    profile?.dob ? moment(profile.dob).format("YYYY-MM-DD") : false
  );
  const [gender, setGender] = useState(profile?.gender);

  const [locationError, setLocationError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [dobError, setDobError] = useState(false);

  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    update: (cache, { data: { updateUserProfile } }) => {
      setStep(1);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Onboarding.ProfileOnboarding",
          mutation: "UPDATE_USER_PROFILE",
        },
      });
      console.log(error);
    },
  });

  // const citiesList = cities.filter((c) => {
  //   if (!location) {
  //     return true;
  //   } else {
  //     const regex = new RegExp(location, "i");
  //     return regex.test(c.name);
  //   }
  // });

  return (
    <div className="onboarding-form">
      <div className="onboarding-primary-text">Set up your profile</div>
      <div className="onboarding-secondary-text">
        Fill in the details below to set up your profile and start connecting
        with the amazing Sports Paddock community.
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) {
            return;
          }

          if (!phone || !isValidPhoneNumber(phone) || !dob || !cities.length) {
            if (!cities.length) {
              setLocationError(true);
            }

            if (!phone || !isValidPhoneNumber(phone)) {
              setPhoneError(true);
            }

            if (!dob) {
              setDobError(true);
            }

            return;
          }

          const citiesToConnect = cities.filter((c) => !c.id) || [];
          const citiesToDisconnect =
            profile?.cities.filter((c) => !cities.find((x) => c.id === x.id)) ||
            [];

          const v = {
            data: {},
          };

          if (citiesToConnect.length || citiesToDisconnect.length) {
            v.data.cities = {};
            if (citiesToConnect.length) {
              v.data.cities.connect = citiesToConnect.map((c) => ({
                city: c.city,
                state: c.state,
                country: c.country,
                lat: c.latLng.lat,
                lng: c.latLng.lng,
              }));
            }

            if (citiesToDisconnect.length) {
              v.data.cities.disconnect = citiesToDisconnect.map((c) => ({
                id: c.id,
              }));
            }
          }

          if (phone) {
            v.data.phone = phone;
          }

          if (dob) {
            v.data.dob = moment(dob).toISOString();
          }

          if (gender) {
            v.data.gender = gender;
          }

          // console.log(v);
          // return;

          updateUserProfile({
            variables: v,
          });
        }}
      >
        {/* <div className="form-group">
          <input
            type="text"
            id="username"
            value={handle}
            className={
              "input " +
              (handle ? "hasValue " : " ") +
              (handleError ? "invalid" : "")
            }
            onChange={(e) => {
              setHandle(e.target.value);
            }}
            onFocus={() => {
              setHandleError(false);
            }}
          />
          <label for="username">Pick a unique handle</label>
          <div className="static-icon">
            <AtIcon style={{ stroke: "var(--light-4)", height: 14 }} />
          </div>
        </div> */}

        {/* <div
          className="form-group"
          style={{ gridColumnStart: 1, gridColumnEnd: 4, zIndex: 10 }}
        >
          <PlacesAutocomplete
            value={location}
            onChange={(x) => {
              setLocation(x);
            }}
            onSelect={(loc) => {
              const city = loc?.split(",")[0].trim();
              const state = loc?.split(",")[1].trim();
              const country = loc?.split(",")[2].trim();
              setLocation(city);
              setSelectedCity(city);
              setSelectedState(state);
              setSelectedCountry(country);
              geocodeByAddress(loc)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  setLatLng(latLng);
                })
                .catch((error) => console.error("Error", error));
            }}
            debounce={200}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Dropdown
                className="dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div className="dropdown-input-wrapper__container">
                    <Chip className="multi-select-item">{"Bangalore"} <TimesIcon style={{stroke:"var(--light-3)"}} /> </Chip>
                    {cities.map(c=>(<Chip className="multi-select-item">{c.city} <TimesIcon style={{stroke:"var(--light-3)"}} /> </Chip>))}
                    <input
                      {...getInputProps({
                        className:
                          "input " +
                          (location ? "hasValue " : " ") +
                          (locationError ? "invalid" : ""),
                        onFocus: () => {
                          setLocationError(false);
                        },
                        autocomplete: "off",
                      })}
                      value={location}
                    />
                    <label for="location">
                      Location{" "}
                      <span className="input-required-indicator">*</span>
                    </label>
                    <div className="static-icon">
                      <LocationIcon
                        style={{ stroke: "var(--light-4)", height: 14 }}
                      />
                    </div>

                    <div
                      className="static-icon"
                      style={{ right: 0, left: "auto", bottom: 10 }}
                    >
                      <IndiaFlagIcon />
                    </div>
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="dropdown-input-wrapper-menu">
                    {loading && <Spinner />}
                    {suggestions
                      .filter(
                        (suggestion) =>
                          suggestion?.description?.split(",")?.length &&
                          suggestion?.description?.split(",").length > 2
                      )
                      .map((suggestion, i) => (
                        <div
                          key={"city-suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className: "city-item",
                          })}
                        >
                          {suggestion.description}
                          
                        </div>
                      ))}
                  </Card>
                )}
                onClose={()=>{
                  // setLocation("")
                }}
              />
            )}
          </PlacesAutocomplete>
        </div> */}

        <div
          className="form-group"
          style={{ gridColumnStart: 1, gridColumnEnd: 4, zIndex: 10 }}
        >
          <PlacesAutocomplete
            value={location}
            onChange={(x) => {
              setLocation(x);
            }}
            onSelect={(loc) => {
              const city = loc?.split(",")[0].trim();
              const state = loc?.split(",")[1].trim();
              const country = loc?.split(",")[2].trim();
              // setSelectedCity(city);
              // setSelectedState(state);
              // setSelectedCountry(country);

              geocodeByAddress(loc)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  // setLatLng(latLng);
                  setCities((prev) => {
                    return [
                      ...prev,
                      {
                        city,
                        state,
                        country,
                        latLng,
                      },
                    ];
                  });
                })
                .catch((error) => console.error("Error", error));
              setLocation("");
            }}
            debounce={200}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Dropdown
                className="dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "dropdown-input-wrapper__multi-select-container " +
                      (locationError ? "invalid" : "")
                    }
                  >
                    <div className="static-icon">
                      <LocationIcon
                        style={{ stroke: "var(--light-4)", height: 14 }}
                      />
                    </div>
                    {/* <Chip className="multi-select-item">{"Bangalore"} <TimesIcon style={{stroke:"var(--light-3)"}} /> </Chip> */}
                    {/* <Chip className="multi-select-item">{"Delhi"} <TimesIcon style={{stroke:"var(--light-3)"}} /> </Chip> */}
                    <div className="dropdown-input-wrapper__multi-select-container__inputs-wrapper">
                      {cities.map((c, i) => (
                        <Chip
                          key={"location" + i}
                          className="multi-select-item"
                          onClick={() => {
                            setCities((prev) => {
                              return [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1, prev.length),
                              ];
                            });
                          }}
                        >
                          <span className="multi-select-item__text">
                            {c.city}
                          </span>{" "}
                          <TimesIcon
                            style={{ stroke: "var(--light-1)", height: "14px" }}
                          />{" "}
                        </Chip>
                      ))}
                      <input
                        {...getInputProps({
                          className:
                            "input " +
                            (location || cities?.length ? "hasValue " : " ") +
                            (locationError ? "invalid" : ""),
                          onFocus: () => {
                            setLocationError(false);
                          },
                          autocomplete: "off",
                        })}
                        value={location}
                        id="location"
                      />

                      {/* </div> */}

                      <label for="location">
                        Location{" "}
                        <span className="input-required-indicator">*</span>
                      </label>
                    </div>

                    <div
                      className="static-icon"
                      style={{ right: 0, left: "auto", bottom: 10 }}
                    >
                      <IndiaFlagIcon />
                    </div>
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="dropdown-input-wrapper-menu">
                    {loading && <Spinner />}
                    {suggestions
                      .filter(
                        (suggestion) =>
                          suggestion?.description?.split(",")?.length &&
                          suggestion?.description?.split(",").length > 2
                      )
                      .map((suggestion, i) => (
                        <div
                          key={"city-suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className: "city-item",
                          })}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                  </Card>
                )}
                onClose={() => {
                  setLocation("");
                }}
              />
            )}
          </PlacesAutocomplete>
        </div>

        <Row columns={24} gap={16}>
          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 10 }}
          >
            <input
              type="text"
              id="phone"
              value={phone}
              className={
                "input " +
                (phone ? "hasValue " : " ") +
                (phoneError ? "invalid" : "")
              }
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              onFocus={() => {
                setPhoneError(false);
              }}
            />
            <label for="phone">
              Phone <span className="input-required-indicator">*</span>
            </label>
            <div className="static-icon">
              <PhoneIcon style={{ stroke: "var(--light-4)", height: 14 }} />
            </div>
          </div>
          <div
            className="form-group"
            style={{ gridColumnStart: 11, gridColumnEnd: 20 }}
          >
            <input
              type="date"
              id="dob"
              value={dob}
              className={
                "input " +
                (dob ? "hasValue " : " ") +
                (dobError ? "invalid" : "")
              }
              onChange={(e) => {
                setDob(e.target.value);
              }}
              style={{ paddingLeft: 0 }}
              onFocus={() => {
                setDobError(false);
              }}
            />
            <label for="dob" style={{ left: 0 }}>
              Date of Birth <span className="input-required-indicator">*</span>
            </label>
          </div>

          <div
            className="form-group"
            style={{ gridColumnStart: 21, gridColumnEnd: 25 }}
          >
            <Dropdown
              className="dropdown-input-wrapper"
              placement="bottom-start"
              style={{ width: "100%" }}
              target={
                <Fragment>
                  <input
                    type="text"
                    id="gender"
                    value={gender}
                    className={"input " + (gender ? "hasValue " : " ")}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    style={{ paddingLeft: 0 }}
                    autocomplete="off"
                  />
                  <label for="gender" style={{ left: 0 }}>
                    Gender
                  </label>
                </Fragment>
              }
              menu={({ setShowMenu }) => (
                <Card className="gender-menu">
                  <div
                    className="gender-menu-item hover-pointer"
                    onClick={() => {
                      setGender("Male");
                      setShowMenu(false);
                    }}
                    style={{ padding: 8 }}
                  >
                    Male
                  </div>
                  <div
                    className="gender-menu-item hover-pointer"
                    onClick={() => {
                      setGender("Female");
                      setShowMenu(false);
                    }}
                    style={{ padding: 8 }}
                  >
                    Female
                  </div>
                  <div
                    className="gender-menu-item hover-pointer"
                    onClick={() => {
                      setGender("Other");
                      setShowMenu(false);
                    }}
                    style={{ padding: 8 }}
                  >
                    Other
                  </div>
                </Card>
              )}
            />
          </div>
        </Row>

        <div className="options-group">
          <DebouncedButton
            type="submit"
            className="app-button primary blue large submit-button"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Next"}
          </DebouncedButton>
          {/* <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Next{" "}
          </button> */}
        </div>
      </form>
    </div>
  );
}

function OnboardingPager({ profile }) {
  const [step, setStep] = useState(determineOnboardingStep(profile));

  return (
    <Fragment>
      {!step && <ProfileOnboarding setStep={setStep} profile={profile} />}
      {step === 1 && (
        <PreferencesOnboarding setStep={setStep} profile={profile} />
      )}
      {step === 2 && <EmailOnboarding setStep={setStep} profile={profile} />}
    </Fragment>
  );
}

function Onboarding() {
  const { loading, error, data } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Onboarding",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <div>Loading</div>
      </div>
    );
  }

  const profile = data.userProfile;
  // console.log("Onboarding Profile:", profile);

  return (
    <div className="onboarding-page">
      <div className="form-container">
        <div className="logo">
          <img src={"/logo.svg"} alt="" />
        </div>
        <div className="onboarding-content">
          <OnboardingPager profile={profile} />
        </div>
      </div>
      <div className="graphic"></div>
    </div>
  );
}

export default Onboarding;
