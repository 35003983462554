import "./ManageEventDetails.css";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { EVENT, UPDATE_EVENT } from "../../../api/event";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../../../components/Spinner";
import moment from "moment";
import MapContainer from "../../../components/Map";
import { useAppState } from "../../../utils/appState";
import ModalForm from "../../ModalForm";
import LocationSearch from "../../../components/LocationSearch";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import captureException from "../../../utils/captureException";

function EditEventLocation({ id }) {
  const [, setAppState] = useAppState();

  const dismiss = () => {
    setAppState({ modal: false });
  };

  // const { loading, error, data } = useQuery(config.query, {
  //   variables: { id: config.id },
  // });

  const [mutation, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`id venueLabel venueValue venueLat venueLng`),
    {
      update: (cache, { data: { updateEvent } }) => {
        const q = cache.readQuery({
          query: EVENT,
          variables: {
            id,
          },
        });

        cache.writeQuery({
          query: EVENT,
          variables: { id },
          data: {
            event: {
              ...q.event,
              ...updateEvent,
            },
          },
        });
        // setUpdating(false);
        // setAppState({ modal: false });
        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventDetails.EditEventLocation",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  // const locs = config.getLocations(data);

  return (
    <div className="modal-form-mobile-wrapper">
      <div
        className="modal-form-mobile"
        style={{ height: "90%", display: "flex", flexDirection: "column" }}
      >
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Edit event location
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div
          className="modal-form-mobile__body"
          style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
          <div className="modal-form-mobile__body__location-search">
            <div className="modal-form-mobile__body__location-search__input">
              <LocationSearch
                defaultSearchString={""}
                searchOptions={{
                  componentRestrictions: {
                    country: "IN",
                  },
                }}
                onLocationSelect={({ address, setSearchString }) => {
                  if (!address || submitting) {
                    return;
                  }

                  geocodeByAddress(address)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                      const locTokens = address.split(",");
                      const newVenue = {
                        lat: latLng.lat,
                        lng: latLng.lng,
                        label: locTokens[0].trim(),
                        value: locTokens.splice(1, locTokens.length).join(","),
                      };

                      mutation({
                        variables: {
                          where: { id },
                          data: {
                            venueLabel: newVenue.label,
                            venueValue: newVenue.value,
                            venueLat: newVenue.lat,
                            venueLng: newVenue.lng,
                          },
                        },
                      });
                      // setSearchString("");
                    })
                    .catch((error) => console.error("Error", error));
                }}
                placeholder={"Search..."}
              />
            </div>

            <div className="modal-form-mobile__body__location-search__results"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ManageEventInfo({ id }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventDetails.ManageEventInfo",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;

  const onClickEdit = ({ label, type, key, formValue, selectionSet, vars }) => {
    setAppState({
      modal: (
        <ModalForm
          title={"Edit event"}
          config={{
            fields: [
              {
                label,
                type,
                key,
                formValue,
              },
            ],
            mutation: UPDATE_EVENT(selectionSet),
            onSubmit: ({ fields, mutate }) => {
              // console.log("Submitted:", fields);
              const val = fields[0].formValue;
              const v = vars(val);
              mutate({
                variables: {
                  where: { id: ev.id },
                  data: v,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  return (
    <div className="manage-event-details-mobile__info">
      <div className="manage-event-details-mobile__info__title">Event Info</div>

      <div className="manage-event-details-mobile__info__fields">
        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Name
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.name}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              onClickEdit({
                label: "Name",
                type: "text",
                key: "name",
                formValue: ev.name,
                selectionSet: `name`,
                vars: (val) => ({ name: val }),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Start Date
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.scheduledStart
              ? moment(ev.scheduledStart).format("Do MMM YYYY")
              : "-"}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              onClickEdit({
                label: "Start Date",
                type: "date",
                key: "scheduledStart",
                formValue: ev.scheduledStart
                  ? moment(ev.scheduledStart).format("YYYY-MM-DD")
                  : "",
                selectionSet: `scheduledStart`,
                vars: (val) => ({
                  scheduledStart: val ? moment(val).toISOString() : null,
                }),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            End Date
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.scheduledEnd
              ? moment(ev.scheduledEnd).format("Do MMM YYYY")
              : "-"}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              onClickEdit({
                label: "End Date",
                type: "date",
                key: "scheduledEnd",
                formValue: ev.scheduledEnd
                  ? moment(ev.scheduledEnd).format("YYYY-MM-DD")
                  : "",
                selectionSet: `scheduledEnd`,
                vars: (val) => ({
                  scheduledEnd: val ? moment(val).toISOString() : null,
                }),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Registration Start Date
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.registrationStart
              ? moment(ev.registrationStart).format("Do MMM YYYY")
              : "-"}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              onClickEdit({
                label: "Registration Start Date",
                type: "date",
                key: "registrationStart",
                formValue: ev.registrationStart
                  ? moment(ev.registrationStart).format("YYYY-MM-DD")
                  : "",
                selectionSet: `registrationStart`,
                vars: (val) => ({
                  registrationStart: val ? moment(val).toISOString() : null,
                }),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Registration End Date
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.registrationEnd
              ? moment(ev.registrationEnd).format("Do MMM YYYY")
              : "-"}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              onClickEdit({
                label: "Registration End Date",
                type: "date",
                key: "registrationEnd",
                formValue: ev.registrationEnd
                  ? moment(ev.registrationEnd).format("YYYY-MM-DD")
                  : "",
                selectionSet: `registrationEnd`,
                vars: (val) => ({
                  registrationEnd: val ? moment(val).toISOString() : null,
                }),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Registration Amount
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.registrationAmount || "-"}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              onClickEdit({
                label: "Registration Amount",
                type: "number",
                key: "registrationAmount",
                formValue: ev.registrationAmount,
                selectionSet: `registrationAmount`,
                vars: (val) => ({
                  registrationAmount: Number(val) || null,
                }),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Sport
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.sport?.name || "-"}
          </div>

          <div className="manage-event-details-mobile__info__fields__group__edit-btn">
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Participant Type
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.participantType || "-"}
          </div>

          <div
            className="manage-event-details-mobile__info__fields__group__edit-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <ModalForm
                    title={"Select Participant Type"}
                    config={{
                      fields: [
                        {
                          label: "Participant Type",
                          type: "select",
                          items: [
                            { value: "TEAM", label: "Team" },
                            { value: "INDIVIDUAL", label: "Individual" },
                          ],
                          key: "participantType",
                          formValue: ev.participantType,
                        },
                      ],
                      mutation: UPDATE_EVENT("participantType"),
                      onSubmit: ({ fields, mutate }) => {
                        // console.log("Submitted:", fields);
                        const val = fields[0].formValue;
                        // const v = val;
                        mutate({
                          variables: {
                            where: { id: ev.id },
                            data: {
                              participantType: val,
                            },
                          },
                          // update: () => {
                          //   setAppState({ modal: false });
                          // },
                        });
                      },
                    }}
                  />
                ),
              });
            }}
          >
            <PencilIcon style={{ height: "10px" }} />
          </div>
        </div>

        <div className="manage-event-details-mobile__info__fields__group column">
          <div className="manage-event-details-mobile__info__fields__group__key">
            Description{" "}
            <div
              className="manage-event-details-mobile__info__fields__group__edit-btn"
              onClick={() => {
                onClickEdit({
                  label: "Description",
                  type: "textarea",
                  key: "description",
                  formValue: ev.description,
                  selectionSet: `description`,
                  vars: (val) => ({
                    description: val || null,
                  }),
                });
              }}
              style={{ marginLeft: "auto" }}
            >
              <PencilIcon style={{ height: "10px" }} />
            </div>
          </div>

          <div className="manage-event-details-mobile__info__fields__group__value">
            {ev.description || "-"}
          </div>
        </div>
      </div>
    </div>
  );
}

function DeleteEventLocation({ ev }) {
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`id venueLabel venueValue venueLat venueLng`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            venueLabel: () => {
              return updateEvent.venueLabel;
            },
            venueValue: () => {
              return updateEvent.venueValue;
            },
            venueLat: () => {
              return updateEvent.venueLat;
            },
            venueLng: () => {
              return updateEvent.venueLng;
            },
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventVenue.EditEventVenue",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div
      className={
        "manage-event-details-mobile__location__options__option delete " +
        (loading ? "disabled" : "")
      }
      onClick={() => {
        if (loading) {
          return;
        }

        updateEvent({
          variables: {
            where: { id: ev.id },
            data: {
              venueLabel: null,
              venueValue: null,
              venueLat: null,
              venueLng: null,
            },
          },
        });
      }}
    >
      <TrashIcon className="manage-event-details-mobile__location__options__option__icon" />{" "}
      Delete
    </div>
  );
}

function ManageEventLocation({ id }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventDetails.ManageEventLocation",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;

  return (
    <div className="manage-event-details-mobile__location">
      <div className="manage-event-details-mobile__location__title">
        Location
      </div>

      <div className="manage-event-details-mobile__location__map">
        <MapContainer
          venue={{
            label: ev.venueLabel,
            value: ev.venueValue,
            lat: ev.venueLat,
            lng: ev.venueLng,
          }}
        />
      </div>

      <div className="manage-event-details-mobile__location__value">
        <div className="manage-event-details-mobile__location__value__text">
          <div className="manage-event-details-mobile__location__value__text__primary">
            {ev.venueLabel || "No venue added"}
          </div>

          <div className="manage-event-details-mobile__location__value__text__secondary">
            {ev.venueValue || ""}
          </div>
        </div>

        {/* <div
          className="manage-event-details-mobile__location__value__edit-btn"
          onClick={() => {
            setAppState({ modal: <EditEventLocation id={ev.id} /> });
          }}
        >
          <PencilIcon style={{ height: "10px" }} />
        </div> */}
      </div>

      <div className="manage-event-details-mobile__location__options">
        <div
          className="manage-event-details-mobile__location__options__option edit"
          onClick={() => {
            setAppState({ modal: <EditEventLocation id={ev.id} /> });
          }}
        >
          <PencilIcon className="manage-event-details-mobile__location__options__option__icon" />{" "}
          Edit
        </div>

        {ev.venueLabel && <DeleteEventLocation ev={ev} />}
      </div>
    </div>
  );
}

export default function ManageEventDetails({ id }) {
  return (
    <div className="manage-event-details-mobile">
      <ManageEventInfo id={id} />
      <ManageEventLocation id={id} />
    </div>
  );
}
