import Spinner from "../../../components/Spinner";
import "./OrganizationVideos.css";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_VIDEOS } from "../../../api/organization";
import VideoCard from "../../../mobileComponents/VideoCard";
import Loader from "../../../components/Loader";
import AddVideo from "../../AddVideo";
import { useAppState } from "../../../utils/appState";
import Button from "../../../components/Button";
import captureException from "../../../utils/captureException";

export default function OrganizationVideos({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const { networkStatus, error, data, variables, fetchMore } = useQuery(
    ORGANIZATION_VIDEOS,
    {
      variables: {
        id,
        type: "VIDEO",
        take: 5,
        filters: {
          sports: [],
          teams: [],
          events: [],
        },
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationVideos",
        query: "ORGANIZATION_VIDEOS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 1 || networkStatus === 2) {
    return (
      <div
        style={{
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const videos = data?.organization?.media || [];
  const videosCount = data?.organization?.videosCount || 0;
  return (
    <div className="organization-videos-mobile">
      {canManagePage && (
        <Button
          className="primary medium blue"
          style={{ margin: "16px" }}
          onClick={() => {
            setAppState({
              modal: (
                <AddVideo
                  config={{
                    source: data?.organization,
                    sourceType: "organization",
                  }}
                />
              ),
            });
          }}
        >
          Add Video
        </Button>
      )}

      {!videosCount && (
        <div className="no-data">
          <div className="text">No videos found.</div>
        </div>
      )}

      <div className="organization-videos-gallery">
        {videos.map((v) => (
          <VideoCard key={v.id} media={v} videoHeight={180} />
        ))}
      </div>

      {videos.length < videosCount && (
        <div
          className="show-more-container"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: videos.length ? videos[videos.length - 1]?.id : null,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
        </div>
      )}
    </div>
  );
}
