import "./Landing.css";
import { Link } from "react-router-dom";
import { useAppState } from "../../utils/appState";
import { usePageTracking } from "../../utils/usePageTracking";

function Landing() {
  usePageTracking({ title: "Landing Page" });
  const [{ isLoggedIn }] = useAppState();

  return (
    <div className="mobile-landing-page__wrapper">
      <div className="mobile-landing-page">
        <div className="mobile-landing-page__logo">
          <img src="/logo.svg" alt="" />
        </div>

        <div className="mobile-landing-page__primary">
          Welcome to Sports Paddock!
        </div>

        <div className="mobile-landing-page__secondary">
          <div className="mobile-landing-page__secondary__p">
            Experience the thrill of sports like never before with <br /> Sports
            Paddock. Create, manage, and join events,
            <br /> build teams, and connect with fellow athletes.
          </div>

          <div className="mobile-landing-page__secondary__p">
            Sign up now and join the sports revolution.
          </div>
        </div>

        <Link
          to={isLoggedIn ? "/app" : "/auth"}
          className="mobile-landing-page__cta"
        >
          Get Started
        </Link>

        <div className="mobile-landing-page__graphic">
          <div className="mobile-landing-page__graphic__green" />
          <div className="mobile-landing-page__graphic__dark-green" />
          <div className="mobile-landing-page__graphic__blue" />
        </div>

        <div className="mobile-landing-page__logo-bottom">
          <img src="/logo-white.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Landing;
