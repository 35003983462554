// V1
import "./PhotosCard.css";
import { useAppState } from "../../utils/appState";
import { useMutation, useQuery } from "@apollo/client";
import Card from "../Card";
import Loader from "../Loader";
import Placeholder from "../Placeholder";
import PhotoBrowser from "../PhotoBrowser";
import Uploader from "../Uploader";
import { useEffect, useState } from "react";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { DELETE_MEDIA } from "../../api/media";
import Avatar from "../Avatar/Avatar";
import captureException from "../../utils/captureException";

function PhotosCardItem({
  photo,
  query,
  vars,
  getPhotos,
  totalPhotos,
  onDelete,
}) {
  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: onDelete,
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PhotosCard",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });
  const [, setAppState] = useAppState();
  return (
    <div className="photos-card__gallery__gallery-item">
      <div
        className="photos-card__gallery__gallery-item__image"
        onClick={() => {
          setAppState({
            modal: (
              <PhotoBrowser
                query={query}
                vars={vars}
                getPhotos={getPhotos}
                totalPhotos={totalPhotos}
                media={photo}
              />
            ),
          });
        }}
      >
        <Avatar media={photo} />
      </div>

      {photo.canManageMedia && (
        <div className="photos-card__gallery__gallery-item__image-options">
          <div
            className="photos-card__gallery__gallery-item__image-options__option delete"
            onClick={() => {
              if (deleting) return;
              // console.log("Deleting");
              deleteMedia({ variables: { id: photo.id } });
            }}
          >
            <TrashIcon style={{ stroke: "white", height: "10px" }} />
          </div>
        </div>
      )}
    </div>
  );
}

function PhotosCard({
  title = "All Photos",
  query,
  vars,
  getPhotos = () => [],
  totalPhotos = () => 0,
  noData = "",
  filters = {},
  processFilters = () => {
    return false;
  },
  onDelete = () => {},
  style = {},
  addPhoto,
}) {
  const [, setAppState] = useAppState();
  const [canShowEnd, setCanShowEnd] = useState(false);
  const { networkStatus, error, data, variables, fetchMore, refetch } =
    useQuery(query, {
      variables: vars,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    const filterVars = processFilters(filters);

    if (filterVars) {
      refetch({
        ...vars,
        ...filterVars,
      });
    }
  }, [filters]);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PhotosCard",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 2 || networkStatus === 4) {
    return (
      <Card className="photos-card" style={style}>
        <div className="photos-card__card-title">
          <Placeholder style={{ height: "14px", width: "80px" }} pulse={true} />
        </div>

        <div className="photos-card__gallery">
          {[1, 2, 3, 4].map((photo) => (
            <div
              key={"photocard-placeholder-" + photo}
              className="photos-card__gallery__gallery-item"
            >
              <div
                className="photos-card__gallery__gallery-item__image"
                style={{ background: "var(--blue-subtle)" }}
              ></div>
            </div>
          ))}
        </div>

        <div className="photos-card__show-more-container">
          <Placeholder style={{ height: "14px", width: "80px" }} pulse={true} />
        </div>
      </Card>
    );
  }

  const photos = getPhotos(data);
  const photosCount = totalPhotos(data);
  // console.log("PHOTOS CARD DATA:", data);

  return (
    <Card className="photos-card" style={style}>
      <div className="photos-card__card-title">
        {title}{" "}
        {addPhoto && (
          <div
            className="add-photo-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <Uploader
                    accept=".jpg,.png,.jpeg"
                    label="Event Photos"
                    multiple
                    sourceId={addPhoto.sourceId}
                    sourceType={addPhoto.sourceType}
                    mediaSetId={false}
                    onCreateMedia={addPhoto.onAddPhoto}
                  />
                ),
              });
            }}
          >
            Add Photo
          </div>
        )}
      </div>

      {!!photos.length && (
        <div className="photos-card__gallery">
          {photos?.map((photo) => (
            <PhotosCardItem
              key={photo.id}
              photo={photo}
              query={query}
              vars={vars}
              getPhotos={getPhotos}
              totalPhotos={totalPhotos}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}

      {!photos?.length && <div className="no-data">{noData}</div>}

      {photos?.length < photosCount && (
        <div
          className="photos-card__show-more-container"
          onClick={() => {
            setCanShowEnd(true);
            fetchMore({
              variables: {
                ...variables,
                cursor: photos[photos.length - 1]?.id,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "See More"}
        </div>
      )}

      {!!photos.length && photos.length >= photosCount && canShowEnd && (
        <div className="no-data">End of photos</div>
      )}
    </Card>
  );
}

export default PhotosCard;
