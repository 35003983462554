import "./Card.css";

function Card({ children, className, onClick = () => {}, style = {} }) {
  return (
    <div className={"app-card " + className} onClick={onClick} style={style}>
      {children}
    </div>
  );
}

export default Card;
