function isOnboarded(profile) {
  if (!profile) return false;
  // const { handle, city, phone, dob, gender, emailVerified } = profile;

  const fields = ["handle", "phone", "dob", "gender", "emailVerified"];

  const output = {
    onboarded: true,
    pendingFields: [],
    totalFields: fields.length + 1,
  };

  fields.forEach((f) => {
    if (!profile[f]) {
      output.pendingFields.push(f);
    }
  });

  if (!profile.cities?.length) {
    output.pendingFields.push("cities");
  }

  if (output.pendingFields.length) {
    output.onboarded = false;
    return output;
  }

  return output;
}

export default isOnboarded;
