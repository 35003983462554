const overItemSelectionSet = (selectedPeriod, currentOverNumber) => `
cricketLiveScoring{
    id
    overStats(periodId:"${selectedPeriod.id}", overNumber:${currentOverNumber}){
        id
        over
        ballsBowled{
            id
            value
            profile{
                id
                name
            }
            organization{
              id
              name
            }
        }
        ballsFaced{
            id
        }
        runsScored{
            id
            value
            profile{
                id
                name
            }
            organization{
              id
              name
            }
        }
        runsConceded{
            id
            value
            profile{
              id
              name
            }
        }
        wicket{
            id
            statType{
                id
                name
            }
            profile{
              id
            }
            organization{
                id
            }
        }
        wicketTaken{
            id
            profile{
                id
            }
            organization{
                id
            }                                              
        }
        extras{
            id
            statType{
                id
                name
            }
            value
        }
        fielding{
            id
            profile{
                id
            }
        }
    }        
  }`;

export default overItemSelectionSet;
