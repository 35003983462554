import { gql } from "@apollo/client";

const SAVE_POST = gql`
  mutation savePost($id: ID!) {
    savePost(id: $id) {
      id
    }
  }
`;

export default SAVE_POST;
