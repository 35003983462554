import { gql } from "@apollo/client";

const DELETE_AWARD = gql`
  mutation deleteAward($id: ID!) {
    deleteAward(id: $id) {
      id
    }
  }
`;

export default DELETE_AWARD;
