import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Avatar from "../../../components/Avatar";
import "./SelectPlayer.css";
import { useCricketScoringState } from "../utils/useCricketScoringState";

export default function SelectPlayer({
  title,
  onSelect,
  defaultTeam,
  hideTabs,
}) {
  const [{ homeTeam, awayTeam, homeLineup, awayLineup, setOverlay }] =
    useCricketScoringState();
  const [isHomeTeam, setIsHomeTeam] = useState(
    defaultTeam?.id === homeTeam?.organization?.id ? true : false
  );
  const dismiss = () => {
    setOverlay(false);
  };
  const lineup = isHomeTeam ? homeLineup : awayLineup;
  console.log(defaultTeam, lineup);
  return (
    <div className="ScoringApp-cricket__scoring__modal-form">
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        {title}
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={dismiss}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>
      {!hideTabs && (
        <div className="ScoringApp-cricket__scoring__modal-form__tabs">
          <div
            className={
              "ScoringApp-cricket__scoring__modal-form__tabs__tab " +
              (isHomeTeam ? "active" : "")
            }
            onClick={() => {
              setIsHomeTeam(true);
            }}
          >
            {homeTeam.organization.name}
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__modal-form__tabs__tab " +
              (!isHomeTeam ? "active" : "")
            }
            onClick={() => {
              setIsHomeTeam(false);
            }}
          >
            {awayTeam.organization.name}
          </div>
        </div>
      )}

      <div className="ScoringApp-cricket__scoring__modal-form__list">
        {lineup.map((r) => (
          <div
            className="ScoringApp-cricket__scoring__modal-form__list__item"
            onClick={() => {
              onSelect(r);
              //   dismiss();
            }}
          >
            <div className="ScoringApp-cricket__scoring__modal-form__list__item__img">
              {r.profile?.profilePhoto && (
                <Avatar media={r.profile.profilePhoto} />
              )}
            </div>
            <div className="ScoringApp-cricket__scoring__modal-form__list__item__name">
              <div className="ScoringApp-cricket__scoring__modal-form__list__item__name__primary">
                {r.profile.name}
              </div>
              <div className="ScoringApp-cricket__scoring__modal-form__list__item__name__secondary">
                @{r.profile.handle}
              </div>
            </div>
            <div className="ScoringApp-cricket__scoring__modal-form__list__item__num">
              {r.order || "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
