import "./Settings.css";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import PeriodSettings from "../PeriodSettings";
import LineupSettings from "../LineupSettings";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import BallsPerOverSettings from "../BallsPerOverSettings";
import TotalOversSetting from "../TotalOversSetting";
import TossSettings from "../TossSettings";
import DlsSettings from "../DlsSettings";
import WicketsPerInningSetting from "../WicketsPerInningSetting";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";

function CollectStatsButton({ id, onClick }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
        periods{
          id
        }
        metas{
          id
          label
          value
          stringValue
        }        
    `,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <></>;
  }

  const fixture = data?.fixture;
  const metas = fixture?.metas;
  const periods = fixture?.periods;
  const ballsPerOverMeta = metas?.find((m) => m.label === "ballsPerOver");
  const oversPerInningMeta = metas?.find((m) => m.label === "oversPerInning");
  const wicketsPerInningMeta = metas?.find(
    (m) => m.label === "wicketsPerInning"
  );

  const settings = {
    hasPeriods: periods?.length || 0,
    ballsPerOver: ballsPerOverMeta?.value,
    oversPerInning: oversPerInningMeta?.value,
    wicketsPerInning: wicketsPerInningMeta?.value,
  };

  const missingSettings =
    !settings.hasPeriods ||
    !settings.ballsPerOver ||
    !settings.oversPerInning ||
    !settings.wicketsPerInning;

  return (
    <button
      className={
        "app-button primary green medium ScoringApp-football__header__nav-right__nav-btn "
      }
      onClick={onClick}
      disabled={loading || missingSettings}
    >
      Proceed to collect stats
    </button>
  );
}

export default function Settings({ id, completed, setStep }) {
  const history = useHistory();

  return (
    <div className="ScoringApp-football__settings">
      <div className="ScoringApp-football__header">
        <div className="ScoringApp-football__header__nav-left">
          <div
            className="ScoringApp-football__header__nav-left__back-btn"
            onClick={() => {
              history.replace("/app/fixture/" + id);
            }}
          >
            <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
            <div className="ScoringApp-football__header__nav-left__back-btn__text">
              Back to fixture
            </div>
          </div>
        </div>

        <div className="ScoringApp-football__header__main">Match Settings</div>

        <div className="ScoringApp-football__header__nav-right">
          {completed ? (
            <Button
              className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
              onClick={() => {
                setStep(2);
              }}
            >
              Update final score
            </Button>
          ) : (
            <CollectStatsButton
              id={id}
              onClick={() => {
                setStep(1);
              }}
            />
          )}

          {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
        </div>
      </div>

      <div className="ScoringApp-football__body">
        <div className="ScoringApp-football__settings__grid">
          <div className="ScoringApp-football__settings__grid__left-column">
            <PeriodSettings id={id} />
            <BallsPerOverSettings id={id} />
            <TotalOversSetting id={id} />
            <WicketsPerInningSetting id={id} />
            <TossSettings id={id} />
            <DlsSettings id={id} />
            {/* <PenaltyShootoutSettings id={id} /> */}
          </div>

          <div className="ScoringApp-football__settings__grid__right-column">
            <LineupSettings id={id} />
          </div>
        </div>
      </div>
    </div>
  );
}
