import "./BadmintonStats.css";
import Card from "../../../Card";
import Button from "../../../Button";
import Dropdown from "../../../Dropdown";
import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import { useQuery } from "@apollo/client";
import { STAT_TYPES } from "../../../../api/sport";
import { useState } from "react";
import { EVENT_STATS } from "../../../../api/event";
import Avatar from "../../../Avatar";
import captureException from "../../../../utils/captureException";
import { Link } from "react-router-dom";

// function TopTeams({ statTypes, eventId }) {
//   const defaultStatType = statTypes.find((x) => x.name === "Goal");
//   const [selectedStat, setSelectedStat] = useState(defaultStatType);

//   const { loading, error, data, refetch } = useQuery(EVENT_STATS, {
//     variables: {
//       id: eventId,
//       statTypeId: defaultStatType.id,
//       type: "organization",
//     },
//     notifyOnNetworkStatusChange: true,
//   });

//   if (error) {
//     console.log(error);
//     return <div>Error</div>;
//   }

//   if (loading) {
//     return <div>Loading</div>;
//   }

//   const tables = data?.eventStats;
//   console.log(tables);

//   return (
//     <Card className="top-teams">
//       <div className="card-title">
//         <div className="text">Team Performance</div>
//         <Dropdown
//           target={
//             <Button className="secondary  small">
//               {selectedStat.name} <ChevronDown className="icon" />
//             </Button>
//           }
//           menu={({ setShowMenu }) => (
//             <div className="stat-type-selection-menu">
//               {statTypes.map((st) => (
//                 <div
//                   className="stat-type-selection-item"
//                   onClick={() => {
//                     setSelectedStat(st);
//                     setShowMenu(false);
//                     refetch({
//                       id: eventId,
//                       statTypeId: st.id,
//                       type: "organization",
//                     });
//                   }}
//                 >
//                   {st.name}
//                 </div>
//               ))}
//             </div>
//           )}
//         />
//       </div>
//       <div className="card-chart">
//         <BarChart
//           labels={tables.map((t) => t.organization.name)}
//           datasets={[
//             {
//               data: tables.map((t) => t.value),
//               backgroundColor: tables.map((t, i) => {
//                 if (!i) {
//                   return "#a1f291";
//                 } else {
//                   return "#b9e3fe";
//                 }
//               }),
//             },
//           ]}
//         />
//       </div>
//     </Card>
//   );
// }

function TopPlayers({ statTypes, eventId }) {
  const defaultStatType = statTypes.find((x) => x.name === "Point Won");
  const [selectedStat, setSelectedStat] = useState(defaultStatType);

  const { loading, error, data, refetch } = useQuery(EVENT_STATS, {
    variables: {
      id: eventId,
      statTypeId: defaultStatType.id,
      type: "profile",
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.BadmintonStats.TopPlayers",
        query: "EVENT_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const tables = data?.eventStats;
  // console.log(tables);

  return (
    <Card className="top-players">
      <div className="card-title">
        <div className="text">Player Performance</div>
        <Dropdown
          target={
            <Button className="secondary  small">
              {selectedStat.name} <ChevronDown className="icon" />
            </Button>
          }
          menu={({ setShowMenu }) => (
            <div className="stat-type-selection-menu">
              {statTypes.map((st) => (
                <div
                  className="stat-type-selection-item"
                  onClick={() => {
                    setSelectedStat(st);
                    setShowMenu(false);
                    refetch({
                      id: eventId,
                      statTypeId: st.id,
                      type: "profile",
                    });
                  }}
                >
                  {st.name}
                </div>
              ))}
            </div>
          )}
        />
      </div>

      <div className="top-players-list">
        {tables.map((t) => (
          <div className="top-players-list-item">
            <div className="player-image">
              {t.profile?.profilePhoto && (
                <Avatar media={t.profile.profilePhoto} />
              )}
              {/* <div className="team-logo">
                {t.profile?.registrations[0]?.organization?.profilePhoto && (
                  <img
                    className="team-logo__img"
                    src={makeFileUrl(
                      t.profile.registrations[0].organization.profilePhoto
                    )}
                  />
                )}
              </div> */}
            </div>
            <div className="player-meta">
              <div className="primary-text">
                <Link to={"/app/profile/" + t.profile.id}>
                  {t.profile.name}
                </Link>
              </div>
              {/* <div className="secondary-text">
                {t.profile.registrations[0]?.organization.name}
              </div> */}
            </div>
            <div className="value">{t.value}</div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default function BadmintonStats({ id }) {
  const { loading, error, data } = useQuery(STAT_TYPES, {
    variables: { sport: "Badminton" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.BadmintonStats",
        query: "STAT_TYPES",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const statTypes = data?.statTypes;

  if (!statTypes.length) {
    return <div className="">No stat types added</div>;
  }

  return (
    <div className="event-stats-page">
      <div className="left-column">
        <TopPlayers statTypes={statTypes} eventId={id} />
      </div>

      <div className="right-column">
        {/* <TopPlayers statTypes={statTypes} eventId={id} /> */}
      </div>
    </div>
  );
}
