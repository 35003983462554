import "./FixtureLineups.css";
import { useState, Fragment } from "react";
import { ReactComponent as UpDownIcon } from "../../../icons/up-down.svg";
import { ReactComponent as ListIcon } from "../../../icons/list.svg";
import { ReactComponent as PitchIcon } from "../../../icons/pitch.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  FIXTURE,
  UPDATE_FIXTURE_ROLE,
  CREATE_FIXTURE_ROLE,
  DELETE_FIXTURE_ROLE,
} from "../../../api/fixture";
import { FORMATIONS } from "../../../api/sport";
import AppLink from "../../../components/AppLink";
import Pitch from "../../../components/Pitch";
import Spinner from "../../../components/Spinner";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import { EVENT_REGISTRATIONS } from "../../../api/event";
import ModalForm from "../../ModalForm";

import { useAppState } from "../../../utils/appState";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";

function FormationsList({ sportId, fixtureRole, setShowMenu }) {
  const { loading, error, data } = useQuery(FORMATIONS, {
    variables: { where: { sport: { id: sportId } } },
  });

  const [updateFixtureRole, { loading: submitting }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      update: (cache, { data: { updateFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixtureRole),
          fields: {
            formation(_, { INVALIDATE }) {
              if (!updateFixtureRole.formation) {
                return INVALIDATE;
              }
              const newFormationRef = cache.writeFragment({
                data: updateFixtureRole.formation,
                fragment: gql`
                  fragment NewFormation on Formation {
                    id
                    name
                    positions {
                      id
                      positionType {
                        id
                        shortName
                      }
                      x
                      y
                    }
                  }
                `,
              });

              return newFormationRef;
            },
          },
        });

        setShowMenu(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups.FormationsList",
            mutation: "UPDATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLineup.FormationsList",
        query: "FORMATIONS",
      },
    });
    return <div>Error</div>;
  }

  const formations = data?.formations || [];

  return (
    <Card className="fixture-lineups-mobile__select-formation-menu">
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="fixture-lineups-mobile__select-formation-menu__list">
          <div
            className="fixture-lineups-mobile__select-formation-menu__list__item"
            onClick={() => {
              if (submitting) {
                return;
              }

              updateFixtureRole({
                variables: {
                  where: {
                    id: fixtureRole.id,
                  },
                  data: {
                    formation: { disconnect: true },
                  },
                },
              });
            }}
          >
            None
          </div>
          {formations.map((f) => (
            <div
              className="fixture-lineups-mobile__select-formation-menu__list__item"
              onClick={() => {
                if (submitting) {
                  return;
                }

                updateFixtureRole({
                  variables: {
                    where: {
                      id: fixtureRole.id,
                    },
                    data: {
                      formation: { connect: { id: f.id } },
                    },
                  },
                });
              }}
            >
              {f.name}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function DeleteLineupPlayer({ onClick }) {
  const [loading, setLoading] = useState(false);
  return (
    <div
      className="fixture-lineups-mobile__content__section__list__item__options__delete"
      onClick={() => {
        if (loading) return;
        setLoading(true);
        onClick();
      }}
    >
      {loading ? (
        <Spinner size={14} />
      ) : (
        <TrashIcon className="fixture-lineups-mobile__content__section__list__item__options__delete__icon" />
      )}
    </div>
  );
}

function EditJerseyNumber({ role, canManageFixture, players }) {
  const [, setAppState] = useAppState();
  const [, setError] = useState(false);

  return (
    <div
      className={
        "fixture-lineups-mobile__content__section__list__item__options__num " +
        (canManageFixture ? "editable" : "")
      }
      onClick={() => {
        setAppState({
          modal: (
            <ModalForm
              title={"Edit lineup"}
              config={{
                fields: [
                  {
                    label: "Jersey Num",
                    type: "number",
                    key: "order",
                    formValue: role?.order || "",
                  },
                ],
                mutation: UPDATE_FIXTURE_ROLE,
                onSubmit: ({ fields, mutate }) => {
                  // console.log("Submitted:", fields);
                  const val = fields[0].formValue;
                  const v = Number(val) || "";

                  if (v === role.order) {
                    setAppState({ modal: false });
                  }

                  const numTaken = v && players.find((x) => x.order === v);

                  if (numTaken) {
                    // console.log("numTaken by:", numTaken);
                    setError(true);
                    return;
                  }

                  mutate({
                    variables: {
                      where: { id: role.id },
                      data: {
                        order: v,
                      },
                    },
                    update: (cache, { data: { updateFixtureRole } }) => {
                      cache.modify({
                        id: cache.identify(role),
                        fields: {
                          order() {
                            return updateFixtureRole.order;
                          },
                        },
                      });

                      setAppState({ modal: false });
                    },
                  });
                },
              }}
            />
          ),
        });
      }}
    >
      {typeof role?.order === "number"
        ? role.order
        : canManageFixture
        ? "J.Num"
        : ""}
    </div>
  );
}

function RegistrationListItem({
  player,
  fixture,
  team,
  subtype = "STARTER",
  disable = () => false,
  onItemClick = () => {},
}) {
  const [loading, setLoading] = useState(false);

  const disabled = disable(player.profile.id);

  return (
    <div
      className={"lineup-list-item " + (disabled ? "disabled" : "")}
      onClick={() => {
        if (loading) return;
        setLoading(true);
        onItemClick(player.profile.id, player.jerseyNum);
      }}
    >
      <div className="image">
        {player.profile?.profilePhoto && (
          <Avatar media={player.profile.profilePhoto} />
        )}
      </div>
      <div className="name">
        <div className="name__primary">{player.profile.name}</div>
        <div className="name__secondary">@{player.profile.handle}</div>
      </div>
      <div className="num">{player.jerseyNum}</div>
      <div className="checkbox">{loading && <Spinner />}</div>
      {disabled && <div className="disabled" />}
    </div>
  );
}

function RegistrationsList({
  fixture,
  team,
  label,
  subtype,
  disable,
  onItemClick,
}) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: {
      id: fixture.event.id,
      where: { organization: { id: team.organization.id } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLineups.RegistrationsList",
        query: "EVENT_REGISTRATIONS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="lineups-page">
        <div className="lineup-editor">
          <Spinner />
        </div>
      </div>
    );
  }

  const registrations = data.event.registrations;

  return (
    <div
      className="fixture-lineups-list-container"
      style={{ width: "90%", maxHeight: "90%" }}
    >
      <div className="fixture-lineups-list-container__header">
        {label || "Select Starters"}
        <div
          className="dismiss-btn"
          onClick={() => {
            setAppState({ modal: false });
            // dismiss();
          }}
        >
          <TimesIcon style={{ stroke: "var(--light-3)", height: "16px" }} />
        </div>
      </div>
      <div className="lineup-list">
        {registrations.map((p) => (
          <RegistrationListItem
            key={p.id}
            player={p}
            fixture={fixture}
            team={team}
            subtype={subtype}
            disable={disable}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
}

function FixtureLineupsHeader({
  teams,
  selectedTeamIndex,
  setSelectedTeamIndex,
  view,
  setView,
  pitchView,
}) {
  const selectedTeam = teams[selectedTeamIndex];
  return (
    <div className="fixture-lineups-mobile__header">
      <div
        className="fixture-lineups-mobile__header__team-selector"
        onClick={() => {
          setSelectedTeamIndex((prev) => (prev ? 0 : 1));
        }}
      >
        <div className="fixture-lineups-mobile__header__team-selector__team">
          <div className="fixture-lineups-mobile__header__team-selector__team__logo">
            {selectedTeam?.organization?.profilePhoto && (
              <Avatar media={selectedTeam.organization.profilePhoto} />
            )}
          </div>
          {selectedTeam?.organization?.name}
        </div>

        <div className="fixture-lineups-mobile__header__team-selector__icon">
          <UpDownIcon style={{ height: "10px", fill: "var(--light-3)" }} />
        </div>
      </div>

      {pitchView && (
        <div className="fixture-lineups-mobile__header__view-selector">
          <div
            className="fixture-lineups-mobile__header__view-selector__option"
            onClick={() => {
              setView("list");
            }}
          >
            <ListIcon
              style={{
                height: "14px",
                ...(view === "list"
                  ? { fill: "var(--blue-main)" }
                  : { fill: "var(--dark-4)" }),
              }}
            />
          </div>

          <div
            className={"fixture-lineups-mobile__header__view-selector__option "}
            onClick={() => {
              setView("pitch");
            }}
          >
            <PitchIcon
              style={{
                height: "14px",
                ...(view === "pitch"
                  ? { fill: "var(--blue-main)" }
                  : { fill: "var(--dark-4)" }),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function LineupsList({
  fixture,
  team,
  players,
  disableFormation,
  canManageFixture,
  onItemClick,
  onDelete,
}) {
  const [, setAppState] = useAppState();
  return (
    <div className="fixture-lineups-mobile__content__section__list">
      {team.formation && !disableFormation ? (
        <div className="fixture-lineups-mobile__content__section__list__items">
          {team.formation?.positions
            .map((p) => ({
              ...p,
              role: players.find((r) => r.position?.id === p.id),
            }))
            .map((p) => (
              <div
                className="fixture-lineups-mobile__content__section__list__item"
                key={"formation-list-item-" + p.id}
              >
                {canManageFixture && p.role && (
                  <div
                    className="starters__body__list__item__option delete"
                    onClick={() => {
                      onDelete(p.role.id);
                    }}
                    style={{ marginLeft: 0, marginRight: "8px" }}
                  >
                    <TrashIcon className="starters__body__list__item__option__icon" />
                  </div>
                )}

                <div className="fixture-lineups-mobile__content__section__list__item__image">
                  {p.role?.profile?.profilePhoto && (
                    <Avatar media={p.role.profile.profilePhoto} />
                  )}
                </div>

                <div className="fixture-lineups-mobile__content__section__list__item__name">
                  {p.role ? (
                    <AppLink to={"/app/profile/" + p.role.profile.id}>
                      {p.role.profile.name}
                    </AppLink>
                  ) : (
                    <div
                      onClick={() => {
                        if (canManageFixture) {
                          setAppState({
                            modal: (
                              <div className="modal-form-mobile-wrapper">
                                <RegistrationsList
                                  fixture={fixture}
                                  team={team}
                                  disable={
                                    (profileId) => {
                                      // console.log("PID:", profileId);
                                      // console.log("Players:", players);
                                      return players?.find(
                                        (r) =>
                                          r.profile?.id === profileId &&
                                          r.subtypes.find((x) =>
                                            ["STARTER", "SUBSTITUTE"].includes(
                                              x.name
                                            )
                                          ) &&
                                          team.formation.positions.find(
                                            (position) =>
                                              position.id === r.position.id
                                          )
                                      );
                                    }
                                    // role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                                  }
                                  onItemClick={(profileId, jerseyNum) => {
                                    onItemClick(profileId, jerseyNum, p.id);
                                  }}
                                />
                              </div>
                            ),
                          });
                        }
                      }}
                    >
                      {canManageFixture ? "Select player" : "-"}
                    </div>
                  )}
                </div>
                <div className="fixture-lineups-mobile__content__section__list__item__options">
                  {p.role && (
                    <EditJerseyNumber
                      role={p.role}
                      canManageFixture={canManageFixture}
                      players={players}
                    />
                  )}
                  <div className="fixture-lineups-mobile__content__section__list__item__options__option">
                    {p.positionType.shortName}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="fixture-lineups-mobile__content__section__list__items">
          {players.map((p) => (
            <div
              className="fixture-lineups-mobile__content__section__list__item"
              key={"list-item-" + p.id}
            >
              <div className="fixture-lineups-mobile__content__section__list__item__image">
                {p.profile?.profilePhoto && (
                  <Avatar media={p.profile.profilePhoto} />
                )}
              </div>

              <div className="fixture-lineups-mobile__content__section__list__item__name">
                <AppLink to={"/app/profile/" + p.profile.id}>
                  {p.profile.name}
                </AppLink>
              </div>

              <div className="fixture-lineups-mobile__content__section__list__item__options">
                <EditJerseyNumber
                  role={p}
                  canManageFixture={canManageFixture}
                  players={players}
                />

                {canManageFixture && (
                  <DeleteLineupPlayer
                    onClick={() => {
                      onDelete(p.id);
                    }}
                  />
                  // <div
                  //   className="starters__body__list__item__option delete"
                  //   onClick={() => {
                  //     onItemClick({ role: p });
                  //   }}
                  // >
                  //   <TrashIcon className="starters__body__list__item__option__icon" />
                  // </div>
                )}
              </div>
            </div>
          ))}

          {canManageFixture && (
            <div
              className="fixture-lineups-mobile__content__section__list__item"
              onClick={() => {
                setAppState({
                  modal: (
                    <div className="modal-form-mobile-wrapper">
                      <RegistrationsList
                        fixture={fixture}
                        team={team}
                        disable={
                          (profileId) => {
                            // console.log("PID:", profileId);
                            // console.log("Players:", players);
                            return players?.find(
                              (r) =>
                                r.profile?.id === profileId &&
                                r.subtypes.find((x) =>
                                  ["STARTER", "SUBSTITUTE"].includes(x.name)
                                )
                            );
                          }
                          // role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                        }
                        onItemClick={onItemClick}
                      />
                    </div>
                  ),
                });
              }}
            >
              <div className="fixture-lineups-mobile__content__section__list__item__image"></div>

              <div className="fixture-lineups-mobile__content__section__list__item__name">
                Add Player
              </div>

              <div className="fixture-lineups-mobile__content__section__list__item__options">
                <div className="fixture-lineups-mobile__content__section__list__item__options__num"></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function FixtureLineupsContent({
  fixture,
  teams,
  lineups,
  selectedTeamIndex,
  view,
  canManageFixture,
  addLineupPlayer,
  removeLineupPlayer,
}) {
  const selectedTeam = teams[selectedTeamIndex];
  const selectedLineup = lineups[selectedTeamIndex];

  return (
    <div className="fixture-lineups-mobile__content">
      <div className="fixture-lineups-mobile__content__section">
        <div className="fixture-lineups-mobile__content__section__header">
          <div className="fixture-lineups-mobile__content__section__header__title">
            Starters
          </div>

          {fixture.event.sport.name === "Football" && (
            <Fragment>
              {canManageFixture ? (
                <NewDropdown
                  target={
                    <div className="fixture-lineups-mobile__content__section__header__formation">
                      {selectedTeam?.formation?.name || "Select Formation"}
                      <ChevronDown className="fixture-lineups-mobile__content__section__header__formation__icon" />
                    </div>
                    // <div className="starters__title__text">
                    //   {team.formation?.name || "Select Formation"}{" "}
                    //   <ChevronDown className="starters__title__text__icon" />
                    // </div>
                  }
                  menu={({ setShowMenu }) => (
                    <FormationsList
                      sportId={fixture.event.sport.id}
                      fixtureRole={selectedTeam}
                      setShowMenu={setShowMenu}
                    />
                  )}
                />
              ) : (
                <div className="fixture-lineups-mobile__content__section__header__formation">
                  {selectedTeam?.formation?.name || ""}
                  {/* <ChevronDown className="fixture-lineups-mobile__content__section__header__formation__icon" /> */}
                </div>
              )}
            </Fragment>
          )}
        </div>

        {view === "pitch" ? (
          <Pitch
            width={324}
            markers={
              selectedTeam.formation?.positions.map((p) => ({
                ...p,
                label: p.positionType.shortName,
                // secondaryLabel: lastname(
                //   lineupRoles.find((lr) => lr.position?.id === p.id)?.profile
                //     ?.name
                // ),
                secondaryLabel: "",
                role: selectedLineup.find(
                  (r) =>
                    r.position?.id === p.id &&
                    r.subtypes.find((x) => x.name === "STARTER")
                ),
              })) || []
            }
            markerOnClick={(marker) => {}}
            lockEditing={true}
          />
        ) : (
          <LineupsList
            fixture={fixture}
            team={selectedTeam}
            players={selectedLineup.filter((p) => {
              return p.subtypes.find((x) => x.name === "STARTER");
            })}
            canManageFixture={canManageFixture}
            onItemClick={(profileId, jerseyNum, formationPositionId) => {
              addLineupPlayer(
                profileId,
                selectedTeam.organization.id,
                formationPositionId || false,
                "STARTER",
                jerseyNum
              );
            }}
            onDelete={(roleId) => {
              removeLineupPlayer(roleId);
            }}
          />
        )}
      </div>

      <div className="fixture-lineups-mobile__content__section">
        <div className="fixture-lineups-mobile__content__section__header">
          <div className="fixture-lineups-mobile__content__section__header__title">
            Subs
          </div>
        </div>

        <LineupsList
          fixture={fixture}
          team={selectedTeam}
          players={selectedLineup.filter((p) => {
            return p.subtypes.find((x) => x.name === "SUBSTITUTE");
          })}
          disableFormation={true}
          canManageFixture={canManageFixture}
          onItemClick={(profileId, jerseyNum) => {
            addLineupPlayer(
              profileId,
              selectedTeam.organization.id,
              false,
              "SUBSTITUTE",
              jerseyNum
            );
          }}
          onDelete={(roleId) => {
            removeLineupPlayer(roleId);
          }}
        />
      </div>
    </div>
  );
}

export default function FixtureLineups({ id, canManageFixture }) {
  const [, setAppState] = useAppState();
  const [view, setView] = useState("list");
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);

  const { loading, data, error } = useQuery(FIXTURE, { variables: { id } });

  const [updateFixtureRole, { loading: updatingFixtureRole }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups",
            mutation: "UPDATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );
  const [createFixtureRole, { loading: creatingFixtureRole }] = useMutation(
    CREATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups",
            mutation: "CREATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );
  const [deleteFixtureRole, { loading: deletingFixtureRole }] = useMutation(
    DELETE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups",
            mutation: "DELETE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLineups",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="no-data">
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;
  const participants = fixture.roles.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const homeTeam = participants.find((x) => !x.order);
  const awayTeam = participants.find((x) => x.order);

  const homeLineupPlayers = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === homeTeam?.organization?.id
  );

  const awayLineupPlayers = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === awayTeam?.organization?.id
  );

  const addLineupPlayer = (
    profileId,
    organizationId,
    formationPositionId,
    subtype,
    jerseyNum
  ) => {
    if (creatingFixtureRole || updatingFixtureRole) {
      return;
    }

    const existingRole = fixture.roles.find(
      (r) => r.type.name === "LINEUP PLAYER" && r.profile.id === profileId
    );

    if (existingRole) {
      // Update existing role

      updateFixtureRole({
        variables: {
          where: { id: existingRole.id },
          data: {
            subtypes: {
              connect: { name: subtype },
              ...(subtype === "STARTER"
                ? { disconnect: { name: "SUBSTITUTE" } }
                : { disconnect: { name: "STARTER" } }),
            },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
          },
        },
        update: (cache, { data: { updateFixtureRole } }) => {
          cache.modify({
            id: cache.identify(existingRole),
            fields: {
              subtypes() {
                return updateFixtureRole.subtypes;
              },
              position() {
                return updateFixtureRole.position;
              },
            },
          });

          setAppState({ modal: false });
        },
      });
    } else {
      // Add new role
      createFixtureRole({
        variables: {
          data: {
            fixture: { connect: { id: fixture.id } },
            type: { connect: { name: "LINEUP PLAYER" } },
            subtypes: { connect: { name: subtype } },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
            organization: { connect: { id: organizationId } },
            profile: { connect: { id: profileId } },
            ...(jerseyNum ? { order: jerseyNum } : {}),
          },
        },
        update: (cache, { data: { createFixtureRole } }) => {
          cache.modify({
            id: cache.identify(fixture),
            fields: {
              roles(existingRefs, { readField }) {
                const newRef = cache.writeFragment({
                  data: createFixtureRole,
                  fragment: gql`
                    fragment NewFixtureRole on FixtureRole {
                      id
                      order
                      type {
                        id
                        name
                      }
                      subtypes {
                        id
                        name
                      }
                      profile {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                      formation {
                        id
                        name
                        positions {
                          id
                          positionType {
                            id
                            shortName
                          }
                          x
                          y
                        }
                      }
                      position {
                        id
                      }
                    }
                  `,
                });

                return [newRef, ...existingRefs];
              },
            },
          });
          setAppState({ modal: false });
        },
      });
    }
  };

  const removeLineupPlayer = (roleId) => {
    if (deletingFixtureRole) return;
    deleteFixtureRole({
      variables: {
        id: roleId,
      },
      update: (cache, { data: { deleteFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            roles(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) => deleteFixtureRole.id !== readField("id", ref)
              );
            },
          },
        });
      },
    });
  };

  return (
    <div className="fixture-lineups-mobile">
      <FixtureLineupsHeader
        teams={[homeTeam, awayTeam]}
        selectedTeamIndex={selectedTeamIndex}
        setSelectedTeamIndex={setSelectedTeamIndex}
        view={view}
        setView={setView}
        pitchView={fixture?.event?.sport?.name === "Football" ? true : false}
      />

      <div className="fixture-lineups-mobile__content-wrapper">
        <FixtureLineupsContent
          fixture={fixture}
          teams={[homeTeam, awayTeam]}
          lineups={[homeLineupPlayers, awayLineupPlayers]}
          selectedTeamIndex={selectedTeamIndex}
          setSelectedTeamIndex={setSelectedTeamIndex}
          view={view}
          canManageFixture={canManageFixture}
          addLineupPlayer={addLineupPlayer}
          removeLineupPlayer={removeLineupPlayer}
        />
      </div>
    </div>
  );
}
