import "./CurrentInnings.css";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import Avatar from "../../../components/Avatar";
import Loader from "../../../components/Loader";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";
import { Fragment } from "react";
import SelectPlayer from "../SelectPlayer";
import ballsToOvers from "../utils/ballsToOvers";

function BattingRow({ batsman, i }) {
  const [
    { strikeIndex, fixtureId, batsmen, setOverlay, battingTeam },
    setCricketScoringState,
  ] = useCricketScoringState();
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      cricketLiveScoring{
        id
        battingSummaries(ids:["${batsman.profile?.id}"]){
          id
          runs
          balls
          fours
          sixes
        }        
      }
  `,
    }),
    {
      variables: {
        id: fixtureId,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  const isLoading = loading || !data;
  const summaries = data?.fixture?.cricketLiveScoring?.battingSummaries;
  const summary = summaries?.find((s) => s.id === batsman.profile?.id);
  console.log("Batting Rows:", data);

  return (
    <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row">
      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td hover-pointer"
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => {
          setOverlay(
            <SelectPlayer
              title={"Select Batsman " + (i + 1)}
              onSelect={(player) => {
                const newBatsmen = [...batsmen];
                newBatsmen[i] = { profile: player.profile };
                setCricketScoringState({
                  batsmen: newBatsmen,
                });
                setOverlay(false);
              }}
              hideTabs={true}
              defaultTeam={battingTeam}
            />
          );
        }}
      >
        <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td__img">
          {batsman?.profile?.profilePhoto && (
            <Avatar media={batsman.profile.profilePhoto} />
          )}
        </div>
        {batsman?.profile ? (
          batsman.profile.name
        ) : (
          <div className="select-player " style={{ color: "var(--dark-4)" }}>
            Select player
          </div>
        )}
      </div>

      <div
        className={
          "ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td strike " +
          (strikeIndex === i ? "active" : "")
        }
        style={{ width: "20%", textAlign: "right" }}
        onClick={() => {
          setCricketScoringState({
            strikeIndex: i,
          });
        }}
      >
        On Strike
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.runs || 0}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.balls || 0}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.fours || 0}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.sixes || 0}
      </div>
    </div>
  );
}

function BowlingRow({ bowler, ballsPerOver }) {
  const [{ fixtureId, setOverlay, bowlingTeam }, setCricketScoringState] =
    useCricketScoringState();
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
        cricketLiveScoring{
          id
          bowlingSummaries(ids:["${bowler.profile?.id}"]){
            id
            runs
            overs
            balls
            wickets
            extras
          }        
        }
    `,
    }),
    {
      variables: {
        id: fixtureId,
      },
      // fetchPolicy: "network-only",
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  const isLoading = loading || !data;
  const summaries = data?.fixture?.cricketLiveScoring?.bowlingSummaries;
  const summary = summaries?.find((s) => s.id === bowler.profile?.id);
  console.log("Bowling Row:", data);

  return (
    <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row">
      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td hover-pointer"
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => {
          setOverlay(
            <SelectPlayer
              title={"Select Bowler"}
              onSelect={(player) => {
                setCricketScoringState({
                  bowler: { profile: player.profile },
                });
                setOverlay(false);
              }}
              hideTabs={true}
              defaultTeam={bowlingTeam}
            />
          );
        }}
      >
        <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td__img">
          {bowler?.profile?.profilePhoto && (
            <Avatar media={bowler.profile.profilePhoto} />
          )}
        </div>
        {bowler?.profile ? (
          bowler.profile.name
        ) : (
          <div className="select-player " style={{ color: "var(--dark-4)" }}>
            Select player
          </div>
        )}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          ballsToOvers(summary?.balls, ballsPerOver) || 0
        )}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.runs || 0}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.wickets || 0}
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
        style={{ width: "10%", textAlign: "right" }}
      >
        {isLoading ? <Loader /> : summary?.extras || 0}
      </div>
    </div>
  );
}

export default function CurrentInnings({ periods, homeTeam, awayTeam }) {
  const [
    {
      fixtureId,
      selectedPeriod,
      battingTeam,
      bowlingTeam,
      batsmen,
      bowler,
      strikeIndex,
      setOverlay,
      cache,
    },
    setCricketScoringState,
  ] = useCricketScoringState();

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      metas {
        id
        label
        shortLabel
        value
        stringValue
      }
      cricketLiveScoring{
        id
        periodScores{
          id
          runs
          wickets
          overs
        }        
      }
  `,
    }),
    {
      variables: {
        id: fixtureId,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  //   if (loading || !data) {
  //     return <Loader theme="small" />;
  //   }

  const stats = data?.fixture?.cricketLiveScoring?.periodScores || [];
  const score = selectedPeriod
    ? stats.find((s) => s.id === selectedPeriod.id)
    : false;
  const metas = data?.fixture?.metas;
  const ballsPerOverMeta = metas?.find((m) => m.label === "ballsPerOver");
  const ballsPerOver = ballsPerOverMeta?.value || 6;

  return (
    <div className="ScoringApp-cricket__scoring__current-innings">
      <div className="ScoringApp-cricket__scoring__current-innings__header">
        <NewDropdown
          target={
            <div className="ScoringApp-cricket__scoring__current-innings__header__selected-period">
              {selectedPeriod?.label || "Select a period to get started"}{" "}
              <ChevronDown className="ScoringApp-cricket__scoring__current-innings__header__selected-period__icon" />
            </div>
            // <div className="selected-period-selector">
            //   {selectedPeriod?.label || "Select period to get started"}
            //   <ChevronDown className="selected-period-selector__icon" />
            // </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu">
              {periods.map((p) => (
                <div
                  key={p.id}
                  className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu__item"
                  onClick={() => {
                    // setRunning(false);
                    // changePeriod(p.id);
                    console.log(cache);
                    // return;

                    setCricketScoringState({
                      selectedPeriod: p,
                      battingTeam: cache?.periods?.[p.id]?.battingTeam || "",
                      bowlingTeam: cache?.periods?.[p.id]?.bowlingTeam || "",
                      batsmen: cache?.periods?.[p.id]?.batsmen || [{}, {}],
                      bowler: cache?.periods?.[p.id]?.bowler || {},
                      strikeIndex: cache?.periods?.[p.id]?.strikeIndex || 0,
                      currentOverNumber:
                        cache?.periods?.[p.id]?.currentOverNumber || 0,
                    });
                    setShowMenu(false);
                  }}
                >
                  {p.label}
                </div>
              ))}
            </Card>
          )}
        />

        {!!selectedPeriod && (
          <NewDropdown
            target={
              <div className="ScoringApp-cricket__scoring__current-innings__header__selected-period">
                {battingTeam?.name || "Select batting team"}{" "}
                <ChevronDown className="ScoringApp-cricket__scoring__current-innings__header__selected-period__icon" />
              </div>
              // <div className="selected-period-selector">
              //   {selectedPeriod?.label || "Select period to get started"}
              //   <ChevronDown className="selected-period-selector__icon" />
              // </div>
            }
            menu={({ setShowMenu }) => (
              <Card className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu">
                <div
                  className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu__item"
                  onClick={() => {
                    setCricketScoringState({
                      battingTeam: homeTeam.organization,
                      bowlingTeam: awayTeam.organization,
                      batsmen: [{}, {}],
                      bowler: {},
                      strikeIndex: 0,
                    });

                    // Update the cache

                    setShowMenu(false);
                  }}
                >
                  {homeTeam?.organization?.name}
                </div>

                <div
                  className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu__item"
                  onClick={() => {
                    // setRunning(false);
                    // changePeriod(p.id);
                    setCricketScoringState({
                      battingTeam: awayTeam.organization,
                      bowlingTeam: homeTeam.organization,
                      batsmen: [{}, {}],
                      bowler: {},
                      strikeIndex: 0,
                    });
                    setShowMenu(false);
                  }}
                >
                  {awayTeam?.organization?.name}
                </div>
              </Card>
            )}
          />
        )}

        {loading || !data ? (
          <div className="ScoringApp-cricket__scoring__current-innings__header__score">
            <Loader theme="small white" />
          </div>
        ) : (
          <Fragment>
            {score && (
              <div className="ScoringApp-cricket__scoring__current-innings__header__score">
                <span className="bold">
                  {score.runs} / {score.wickets}
                </span>{" "}
                {score.overs?.toFixed(1)} Overs
              </div>
            )}
          </Fragment>
        )}
      </div>

      <div className="ScoringApp-cricket__scoring__current-innings__body">
        {selectedPeriod && battingTeam && (
          <div className="ScoringApp-cricket__scoring__current-innings__body__section">
            <div className="ScoringApp-cricket__scoring__current-innings__body__section__header">
              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "40%" }}
              >
                Batsman
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "20%" }}
              ></div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                Runs
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                Balls
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                4s
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                6s
              </div>
            </div>

            <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows">
              {batsmen.map((b, i) => (
                <Fragment>
                  {b?.profile ? (
                    <BattingRow batsman={b} i={i} />
                  ) : (
                    <div
                      key={"batsman-" + i}
                      className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row"
                    >
                      <div
                        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td hover-pointer"
                        style={{
                          width: "40%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td__img">
                          {b?.profile?.profilePhoto && (
                            <Avatar media={b.profile.profilePhoto} />
                          )}
                        </div>
                        {b?.profile ? (
                          b.profile.name
                        ) : (
                          <div
                            className="select-player "
                            style={{ color: "var(--dark-4)" }}
                            onClick={() => {
                              setOverlay(
                                <SelectPlayer
                                  title={"Select Batsman " + (i + 1)}
                                  onSelect={(player) => {
                                    const newBatsmen = [...batsmen];
                                    newBatsmen[i] = { profile: player.profile };
                                    setCricketScoringState({
                                      batsmen: newBatsmen,
                                    });
                                    setOverlay(false);
                                  }}
                                  hideTabs={true}
                                  defaultTeam={battingTeam}
                                />
                              );
                            }}
                          >
                            Select player
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          "ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td strike " +
                          (strikeIndex === i ? "active" : "")
                        }
                        style={{ width: "20%", textAlign: "right" }}
                        onClick={() => {
                          setCricketScoringState({
                            strikeIndex: i,
                          });
                        }}
                      >
                        On Strike
                      </div>

                      <div
                        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                        style={{ width: "10%", textAlign: "right" }}
                      ></div>

                      <div
                        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                        style={{ width: "10%", textAlign: "right" }}
                      ></div>

                      <div
                        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                        style={{ width: "10%", textAlign: "right" }}
                      ></div>

                      <div
                        className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                        style={{ width: "10%", textAlign: "right" }}
                      ></div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}

        {selectedPeriod && bowlingTeam && (
          <div className="ScoringApp-cricket__scoring__current-innings__body__section">
            <div className="ScoringApp-cricket__scoring__current-innings__body__section__header">
              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "60%" }}
              >
                Bowler
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                Overs
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                Runs
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                Wickets
              </div>

              <div
                className="ScoringApp-cricket__scoring__current-innings__body__section__header__th"
                style={{ width: "10%", textAlign: "right" }}
              >
                Extras
              </div>
            </div>

            <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows">
              {bowler.profile ? (
                <BowlingRow bowler={bowler} ballsPerOver={ballsPerOver} />
              ) : (
                <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row">
                  <div
                    className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td hover-pointer"
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td__img">
                      {bowler?.profile?.profilePhoto && (
                        <Avatar media={bowler.profile.profilePhoto} />
                      )}
                    </div>
                    {bowler?.profile?.name || (
                      <div
                        className="select-player "
                        style={{ color: "var(--dark-4)" }}
                        onClick={() => {
                          setOverlay(
                            <SelectPlayer
                              title={"Select Bowler"}
                              onSelect={(player) => {
                                setCricketScoringState({
                                  bowler: { profile: player.profile },
                                });
                                setOverlay(false);
                              }}
                              hideTabs={true}
                              defaultTeam={bowlingTeam}
                            />
                          );
                        }}
                      >
                        Select player
                      </div>
                    )}
                  </div>

                  <div
                    className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                    style={{ width: "10%", textAlign: "right" }}
                  ></div>

                  <div
                    className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                    style={{ width: "10%", textAlign: "right" }}
                  ></div>

                  <div
                    className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                    style={{ width: "10%", textAlign: "right" }}
                  ></div>

                  <div
                    className="ScoringApp-cricket__scoring__current-innings__body__section__rows__row__td"
                    style={{ width: "10%", textAlign: "right" }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
