// NEW FOOTBALL SCORING APP
import "./ScoreCricket.css";
import { useState } from "react";
import { useQuery } from "@apollo/client";
// import { FIXTURE_SETTINGS } from "../../api/fixture";
import Spinner from "../../components/Spinner";
import Settings from "./Settings";
import Scoring from "./Scoring";
import Publish from "./Publish";
import { FIXTURE } from "../../api/functions";
import { CAN_MANAGE_FIXTURE } from "../../api/user";

// function ScoringHeader() {
//   return (
//     <div className="ScoringApp-football__header">
//       <div className="ScoringApp-football__header__nav-left">
//         <div className="ScoringApp-football__header__nav-left__back-btn">
//           <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
//           <div className="ScoringApp-football__header__nav-left__back-btn__text">
//             Back to fixture
//           </div>
//         </div>
//       </div>

//       <div className="ScoringApp-football__header__main"></div>

//       <div className="ScoringApp-football__header__nav-right">
//         <div className="ScoringApp-football__header__nav-right__settings-btn"></div>
//       </div>
//     </div>
//   );
// }

function ScoreCricket({ id, completed }) {
  const [step, setStep] = useState(completed ? 2 : 0);

  return (
    <div className="ScoringApp-football">
      {step === 0 && (
        <Settings id={id} completed={completed} setStep={setStep} />
      )}
      {step === 1 && <Scoring id={id} setStep={setStep} />}
      {step === 2 && (
        <Publish id={id} setStep={setStep} completed={completed} />
      )}
    </div>
  );
}

export default function ScoreCricketWrapper({ id }) {
  // // usePageTracking({ title: "Football Scoring" });
  const authQuery = useQuery(CAN_MANAGE_FIXTURE, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
        completed
    `,
    }),
    {
      variables: { id },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error || authQuery.error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.ScoreFootballWrapper",
    //     query: "FIXTURE_SETTINGS",
    //   },
    // });
    console.log(error);
    console.log(authQuery.error);
    return <div>Error</div>;
  }

  if (loading || !data || authQuery.loading || !authQuery.data) {
    return <Spinner />;
  }

  // const cache = data?.fixture?.statsCacheState;
  // const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
  //   return a.order - b.order;
  // });

  // const homeTeam = data?.fixture?.roles
  //   .filter((r) => r.type.name === "PARTICIPANT")
  //   .find((p) => !p.order);

  // const awayTeam = data?.fixture?.roles
  //   .filter((r) => r.type.name === "PARTICIPANT")
  //   .find((p) => p.order);

  // const homeLineup = data?.fixture?.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization.id === homeTeam?.organization?.id
  // );

  // const awayLineup = data?.fixture?.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization.id === awayTeam?.organization?.id
  // );

  // const eventId = data?.fixture?.event?.id;
  const canManageFixture = authQuery?.data?.canManageFixture?.authorized;
  const completed = data?.fixture?.completed;

  if (!canManageFixture) {
    return (
      <div className="no-data-placeholder">
        You are not authorized to access this page.
      </div>
    );
  }

  return <ScoreCricket id={id} completed={completed} />;
}
