import "./AboutOrg.css";
import { Fragment } from "react";
import {
  ORGANIZATION,
  ORGANIZATION_BIO,
  UPDATE_ORGANIZATION,
} from "../../../api/organization";
import { useQuery, useMutation } from "@apollo/client";
import Placeholder from "../../../components/Placeholder";
import moment from "moment";
import { ReactComponent as FootballIcon } from "../../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../../icons/tennis.svg";
import { ReactComponent as ControllerIcon } from "../../../icons/controller.svg";
import { ReactComponent as EllipsisIcon } from "../../../icons/ellipsis.svg";
import { ReactComponent as BuildingIcon } from "../../../icons/building.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { useAppState } from "../../../utils/appState";
import ModalForm from "../../ModalForm";
import EditInterests from "../../EditInterests/EditInterests";
import EditLocations from "../../EditLocations";
import EditOrgCategories from "../EditOrgCategories";
import LocationSearch from "../../../components/LocationSearch";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Spinner from "../../../components/Spinner";
import MapContainer from "../../../components/Map";
import captureException from "../../../utils/captureException";

function EditOrgLocation({ id }) {
  const [, setAppState] = useAppState();

  const dismiss = () => {
    setAppState({ modal: false });
  };

  // const { loading, error, data } = useQuery(config.query, {
  //   variables: { id: config.id },
  // });

  const [mutation, { loading: submitting }] = useMutation(
    UPDATE_ORGANIZATION(
      `id locationLabel locationValue locationLat locationLng`
    ),
    {
      update: (cache, { data: { updateOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATION_BIO,
          variables: {
            id,
          },
        });

        cache.writeQuery({
          query: ORGANIZATION_BIO,
          variables: { id },
          data: {
            organization: {
              ...q.organization,
              ...updateOrganization,
            },
          },
        });
        // setUpdating(false);
        // setAppState({ modal: false });
        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "AboutOrg.EditOrgLocation",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  // const locs = config.getLocations(data);

  return (
    <div className="modal-form-mobile-wrapper">
      <div
        className="modal-form-mobile"
        style={{ height: "90%", display: "flex", flexDirection: "column" }}
      >
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Edit page location
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div
          className="modal-form-mobile__body"
          style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
          <div className="modal-form-mobile__body__location-search">
            <div className="modal-form-mobile__body__location-search__input">
              <LocationSearch
                defaultSearchString={""}
                searchOptions={{
                  componentRestrictions: {
                    country: "IN",
                  },
                }}
                onLocationSelect={({ address, setSearchString }) => {
                  if (!address || submitting) {
                    return;
                  }

                  geocodeByAddress(address)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                      const locTokens = address.split(",");
                      const newVenue = {
                        lat: latLng.lat,
                        lng: latLng.lng,
                        label: locTokens[0].trim(),
                        value: locTokens.splice(1, locTokens.length).join(","),
                      };

                      mutation({
                        variables: {
                          where: { id },
                          data: {
                            locationLabel: newVenue.label,
                            locationValue: newVenue.value,
                            locationLat: newVenue.lat,
                            locationLng: newVenue.lng,
                          },
                        },
                      });
                      // setSearchString("");
                    })
                    .catch((error) => console.error("Error", error));
                }}
                placeholder={"Search..."}
              />
            </div>

            <div className="modal-form-mobile__body__location-search__results"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrgBio({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };
  const { loading, error, data } = useQuery(ORGANIZATION_BIO, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AboutOrg.OrgBio",
        query: "ORGANIZATION_BIO",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        className="profile-overview-mobile__about"
        style={{
          marginTop: "16px",
        }}
      >
        <div className="profile-overview-mobile__about__title">
          <Placeholder style={{ height: "10px", width: "64px" }} pulse={true} />
        </div>
        <div className="profile-overview-mobile__about__groups">
          <div className="profile-overview-mobile__about__groups__group row">
            <Placeholder
              style={{ height: "10px", width: "100%" }}
              pulse={true}
            />
          </div>

          <div className="profile-overview-mobile__about__groups__group row">
            <Placeholder
              style={{ height: "10px", width: "100%" }}
              pulse={true}
            />
          </div>

          <div className="profile-overview-mobile__about__groups__group row">
            <Placeholder
              style={{ height: "10px", width: "100%" }}
              pulse={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const org = data.organization;

  const onEditFoundedClick = () => {
    setAppState({
      modal: (
        <ModalForm
          title={"Edit your page"}
          config={{
            fields: [
              {
                label: "Founded",
                type: "date",
                key: "founded",
                formValue: org.founded
                  ? moment(org.founded).format("YYYY-MM-DD")
                  : "",
              },
            ],
            mutation: UPDATE_ORGANIZATION(`founded`),
            onSubmit: ({ fields, mutate }) => {
              const val = fields[0].formValue;
              const vars = {
                founded: val ? moment(val).toISOString() : null,
              };
              mutate({
                variables: {
                  where: { id: org.id },
                  data: vars,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  const onEditPhoneClick = () => {
    setAppState({
      modal: (
        <ModalForm
          title={"Edit your page"}
          config={{
            fields: [
              {
                label: "Phone",
                type: "text",
                key: "phone",
                formValue: org.phone || "",
              },
            ],
            mutation: UPDATE_ORGANIZATION(`phone`),
            onSubmit: ({ fields, mutate }) => {
              const val = fields[0].formValue;
              const vars = {
                phone: val || null,
              };
              mutate({
                variables: {
                  where: { id: org.id },
                  data: vars,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  const onEditEmailClick = () => {
    setAppState({
      modal: (
        <ModalForm
          title={"Edit your page"}
          config={{
            fields: [
              {
                label: "Email",
                type: "email",
                key: "email",
                formValue: org.email || "",
              },
            ],
            mutation: UPDATE_ORGANIZATION(`email`),
            onSubmit: ({ fields, mutate }) => {
              const val = fields[0].formValue;
              const vars = {
                email: val || null,
              };
              mutate({
                variables: {
                  where: { id: org.id },
                  data: vars,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  const onEditInterestsClick = () => {
    setAppState({
      modal: (
        <EditInterests
          title={"Edit your page"}
          config={{
            id: org.id,
            default: org.interests,
            // fields: [
            //   {
            //     label: "Date of birth",
            //     type: "date",
            //     key: "dob",
            //     formValue: profile.dob
            //       ? moment(profile.dob).format("YYYY-MM-DD")
            //       : "",
            //   },
            // ],
            mutation: UPDATE_ORGANIZATION("interests{id name}"),
            update: (cache, { data: { updateOrganization } }) => {
              const q = cache.readQuery({
                query: ORGANIZATION_BIO,
                variables: { id },
              });

              cache.writeQuery({
                query: ORGANIZATION_BIO,
                variables: { id },
                data: {
                  organization: {
                    ...q.organization,
                    interests: updateOrganization.interests,
                  },
                },
              });

              setAppState({ modal: false });
            },
          }}
        />
      ),
    });
  };

  const onEditLocationsClick = () => {
    setAppState({
      modal: (
        <EditLocations
          title={"Edit your page"}
          config={{
            id: org.id,
            getLocations: (data) => data?.organization?.cities || [],
            query: ORGANIZATION_BIO,
            mutation: UPDATE_ORGANIZATION("cities{id value lat lng}"),
            update: (cache, { data: { updateOrganization } }) => {
              const q = cache.readQuery({
                query: ORGANIZATION_BIO,
                variables: { id },
              });

              cache.writeQuery({
                query: ORGANIZATION_BIO,
                variables: { id },
                data: {
                  organization: {
                    ...q.organization,
                    cities: updateOrganization.cities,
                  },
                },
              });

              // setIndex(0);
            },
          }}
        />
      ),
    });
  };

  return (
    <div
      className="organization-overview__about"
      style={{
        paddingTop: "4px",
        marginTop: "16px",
      }}
    >
      <div
        className="profile-overview-mobile__about__groups"
        style={{
          paddingBottom: "12px",
        }}
      >
        <div className="profile-overview-mobile__about__groups__group row">
          <div className="profile-overview-mobile__about__groups__group__key">
            Founded
          </div>

          <div className="profile-overview-mobile__about__groups__group__value">
            {org.founded ? moment(org.founded).year() : "-"}
          </div>

          {canManagePage && (
            <div
              className="profile-overview-mobile__about__groups__group__edit-btn"
              onClick={onEditFoundedClick}
            >
              <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
            </div>
          )}
        </div>

        <div className="profile-overview-mobile__about__groups__group row">
          <div className="profile-overview-mobile__about__groups__group__key">
            Phone
          </div>

          <div className="profile-overview-mobile__about__groups__group__value">
            {org.phone || "-"}
          </div>

          {canManagePage && (
            <div
              className="profile-overview-mobile__about__groups__group__edit-btn"
              onClick={onEditPhoneClick}
            >
              <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
            </div>
          )}
        </div>

        <div className="profile-overview-mobile__about__groups__group row">
          <div className="profile-overview-mobile__about__groups__group__key">
            Email
          </div>

          <div className="profile-overview-mobile__about__groups__group__value">
            {org.email || "-"}
          </div>

          {canManagePage && (
            <div
              className="profile-overview-mobile__about__groups__group__edit-btn"
              onClick={onEditEmailClick}
            >
              <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
            </div>
          )}
        </div>

        <div className="profile-overview-mobile__about__groups__group">
          <div className="profile-overview-mobile__about__groups__group__key">
            Interests
            {canManagePage && (
              <div
                className="profile-overview-mobile__about__groups__group__edit-btn"
                style={{ marginLeft: "auto" }}
                onClick={onEditInterestsClick}
              >
                <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
              </div>
            )}
          </div>

          <div className="profile-overview-mobile__about__groups__group__value grid">
            {org.interests.map((i, x) => (
              <div key={"org-bio-card-interests-" + i.id + x} className="chip">
                <div className="icon">
                  {sportsIcons[i.name] || (
                    <EllipsisIcon className="sports-icon-svg" />
                  )}
                </div>
                <div className="desc">{i.name}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="profile-overview-mobile__about__groups__group">
          <div className="profile-overview-mobile__about__groups__group__key">
            Cities
            {canManagePage && (
              <div
                className="profile-overview-mobile__about__groups__group__edit-btn"
                style={{ marginLeft: "auto" }}
                onClick={onEditLocationsClick}
              >
                <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
              </div>
            )}
          </div>

          <div className="profile-overview-mobile__about__groups__group__value grid">
            {org.cities?.map((i) => (
              <div key={"profile-bio-card-interests-" + i.id} className="chip">
                <div className="icon">
                  <BuildingIcon className="sports-icon-svg" />
                </div>
                <div className="desc">{i.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function OrgLocation({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(ORGANIZATION_BIO, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AboutOrg.OrgLocation",
        query: "ORGANIZATION_BIO",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        className="manage-event-details-mobile__location"
        style={{ marginTop: "16px" }}
      >
        <div className="manage-event-details-mobile__location__title">
          <Placeholder style={{ height: "10px", width: "64px" }} pulse={true} />
        </div>

        <div className="manage-event-details-mobile__location__map">
          <Placeholder style={{ height: "100%", width: "100%" }} pulse={true} />
        </div>

        <div className="manage-event-details-mobile__location__value">
          <div className="manage-event-details-mobile__location__value__text">
            <div className="manage-event-details-mobile__location__value__text__primary">
              <Placeholder
                style={{ height: "12px", width: "100px" }}
                pulse={true}
              />
            </div>

            <div className="manage-event-details-mobile__location__value__text__secondary">
              <Placeholder
                style={{ height: "12px", width: "64px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const org = data?.organization;

  return (
    <div
      className="manage-event-details-mobile__location"
      style={{ marginTop: "16px" }}
    >
      <div className="manage-event-details-mobile__location__title">
        Location
      </div>

      <div className="manage-event-details-mobile__location__map">
        <MapContainer
          venue={{
            label: org.locationLabel,
            value: org.locationValue,
            lat: org.locationLat,
            lng: org.locationLng,
          }}
        />
      </div>

      <div className="manage-event-details-mobile__location__value">
        <div className="manage-event-details-mobile__location__value__text">
          <div className="manage-event-details-mobile__location__value__text__primary">
            {org.locationLabel || "No location added"}
          </div>

          <div className="manage-event-details-mobile__location__value__text__secondary">
            {org.locationValue || ""}
          </div>
        </div>

        <div
          className="manage-event-details-mobile__location__value__edit-btn"
          onClick={() => {
            setAppState({ modal: <EditOrgLocation id={org.id} /> });
          }}
        >
          <PencilIcon style={{ height: "10px" }} />
        </div>
      </div>
    </div>
  );
}

export default function AboutOrg({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AboutOrg",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="organization-overview__about">
        <div className="organization-overview__about__title">
          <Placeholder style={{ height: "10px", width: "40px" }} pulse={true} />
        </div>

        <div className="organization-overview__about__desc">
          <Placeholder style={{ height: "12px", width: "100%" }} pulse={true} />
          <Placeholder
            style={{ height: "12px", width: "40%", marginTop: "8px" }}
            pulse={true}
          />
        </div>

        <div className="organization-overview__about__tags">
          <div className="organization-overview__about__tags__tag">
            <Placeholder
              style={{ height: "12px", width: "40px" }}
              pulse={false}
            />
          </div>

          <div className="organization-overview__about__tags__tag">
            <Placeholder
              style={{ height: "12px", width: "40px" }}
              pulse={false}
            />
          </div>
        </div>
      </div>
    );
  }

  const org = data.organization;

  const onEditDescriptionClick = () => {
    setAppState({
      modal: (
        <ModalForm
          title={"Edit your page"}
          config={{
            fields: [
              {
                label: "Description",
                type: "textarea",
                key: "description",
                formValue: org.description || "",
                maxCharacters: 5000,
              },
            ],
            mutation: UPDATE_ORGANIZATION(`description`),
            onSubmit: ({ fields, mutate }) => {
              const val = fields[0].formValue;
              if (val > 5000) return;
              const vars = {
                description: val || null,
              };
              mutate({
                variables: {
                  where: { id: org.id },
                  data: vars,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  const onEditCategoriesClick = () => {
    setAppState({
      modal: <EditOrgCategories id={org.id} types={org.types} />,
    });
  };

  return (
    <Fragment>
      <div className="organization-overview__about">
        <div className="organization-overview__about__title">
          About
          {canManagePage && (
            <div
              className="profile-overview-mobile__about__groups__group__edit-btn"
              onClick={onEditDescriptionClick}
              style={{ marginLeft: "auto" }}
            >
              <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
            </div>
          )}
        </div>

        <div className="organization-overview__about__desc">
          {org.description}
        </div>

        <div className="organization-overview__about__tags">
          {org.types.map((t) => (
            <div className="organization-overview__about__tags__tag">
              {t.name}
            </div>
          ))}

          {canManagePage && (
            <div
              className="organization-overview__about__tags__tag edit"
              onClick={onEditCategoriesClick}
            >
              Edit Categories
            </div>
          )}
        </div>
      </div>
      <OrgBio id={id} canManagePage={canManagePage} />
      <OrgLocation id={id} canManagePage={canManagePage} />
    </Fragment>
  );
}
