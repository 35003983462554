import "./Tooltip.css";
import { usePopper } from "react-popper";
import { Fragment, useState } from "react";

function Tooltip({
  text,
  component,
  children,
  placement = "bottom",
  style = {},
  className = "",
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  const onMouseEnter = () => {
    setShowMenu(true);
  };

  const onMouseLeave = () => {
    setShowMenu(false);
  };

  return (
    <Fragment>
      <div
        ref={setReferenceElement}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
      >
        {children}
      </div>

      {showMenu && (
        <div
          className="app-tooltip"
          ref={setPopperElement}
          style={{ ...styles.popper, ...style }}
          {...attributes.popper}
        >
          {component || text}
        </div>
      )}
    </Fragment>
  );
}

export default Tooltip;
