import { gql } from "@apollo/client";

const PROFILE_STATS = gql`
  query profileQuery(
    $id: ID!
    $sport: String!
    $events: [ID!]
    $participationStatTypes: [String!]
    $statTypes: [String!]
  ) {
    profile(id: $id) {
      id
      stats(sport: $sport, events: $events) {
        appearances
        minutesPlayed
        positions {
          id
          x
          y
          positionType {
            id
            name
            shortName
          }
        }
        participationHistory(statTypes: $participationStatTypes) {
          event {
            id
            name
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          stats {
            data
          }
        }
        aggregations(statTypes: $statTypes) {
          data
        }
        dismissals {
          data
        }
        wicketsTaken {
          data
        }
        servicesWon
        record {
          data
        }
      }
    }
  }
`;

export default PROFILE_STATS;

// stats(sport: $sport, events: $events) {
//   appearances
//   minutesPlayed
// positions {
//   id
//   x
//   y
//   positionType {
//     id
//     name
//     shortName
//   }
// }
//   participationHistory(statTypes: $participationStatTypes) {
//     event {
//       id
//       name
//     }
//     organization {
//       id
//       name
//       profilePhoto {
//         id
//         filename
//       }
//     }
//     stats {
//       data
//     }
//   }
//   aggregations(statTypes: $statTypes) {
//     data
//   }
//   dismissals {
//     data
//   }
//   wicketsTaken {
//     data
//   }
// }
