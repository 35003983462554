import "./ProfileEventsSummary.css";
import { useHistory, useLocation } from "react-router-dom";
import { PROFILE_EVENTS } from "../../../api/profile";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function ProfileEventsSummary({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data } = useQuery(PROFILE_EVENTS, {
    variables: { id, take: 5 },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileEventsSummary",
        query: "PROFILE_EVENTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading || !data) {
    return (
      <div className="profile-overview-mobile__events">
        <div className="profile-overview-mobile__events__header">
          <div className="profile-overview-mobile__events__header__title">
            <Placeholder
              style={{ height: "10px", width: "64px" }}
              pulse={true}
            />
          </div>
        </div>
        <div className="profile-overview-mobile__events__list">
          {[1, 2, 3].map((ev) => (
            <div
              key={"profile-overview-participation-" + ev}
              className="profile-overview-mobile__events__list__item"
            >
              <div className="profile-overview-mobile__events__list__item__image"></div>
              <div className="profile-overview-mobile__events__list__item__desc">
                <div className="profile-overview-mobile__events__list__item__desc__primary">
                  <Placeholder
                    style={{ height: "12px", width: "100px" }}
                    pulse={true}
                  />
                </div>
                <div className="profile-overview-mobile__events__list__item__desc__secondary">
                  <Placeholder
                    style={{ height: "12px", width: "40px", marginTop: "8px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const profile = data?.profile;
  const events = profile?.events || [];

  // console.log(events);
  return (
    <div className="profile-overview-mobile__events">
      <div className="profile-overview-mobile__events__header">
        <div className="profile-overview-mobile__events__header__title">
          Events
        </div>{" "}
        <div
          className="profile-overview-mobile__events__header__link"
          onClick={() => {
            history.replace(location.pathname + "?t=events");
          }}
        >
          See all events
        </div>
      </div>
      {events.length ? (
        <div className="profile-overview-mobile__events__list">
          {events.slice(0, 5).map((ev) => (
            <Link
              key={"profile-overview-participation-" + ev.id}
              to={"/app/event/" + ev.id}
              className="profile-overview-mobile__events__list__item"
            >
              <div className="profile-overview-mobile__events__list__item__image">
                {ev.profilePhoto && <Avatar media={ev.profilePhoto} />}
              </div>
              <div className="profile-overview-mobile__events__list__item__desc">
                <div className="profile-overview-mobile__events__list__item__desc__primary">
                  {ev.name}
                </div>
                <div className="profile-overview-mobile__events__list__item__desc__secondary">
                  {ev.type?.name}
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <div>
            {profile.name} has not particpated
            <br /> in any events yet.
          </div>
        </div>
      )}
    </div>
  );
}
