import { gql } from "@apollo/client";

const CREATE_AWARD_TYPE = gql`
  mutation createAwardType($data: CreateAwardTypeInput!) {
    createAwardType(data: $data) {
      id
      name
    }
  }
`;

export default CREATE_AWARD_TYPE;
