import "./NotificationItem.css";
import moment from "moment";
import Avatar from "../Avatar";
import { Link } from "react-router-dom";

function PublishFixtureConfirmNotification({ notification }) {
  console.log("Publish notification:", notification);
  const participants = notification.source?.fixture?.roles || [];
  const sortedParticipants = [...participants]?.sort(
    (a, b) => a.order - b.order
  );
  const homeParticipant = sortedParticipants[0];
  const awayParticipant = sortedParticipants[1];
  const event = notification?.source?.fixture?.event;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {event?.profilePhoto && <Avatar media={event.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/fixture/" + notification.source?.fixture?.id}>
            <span className="bold">
              {homeParticipant?.organization?.name ||
                homeParticipant?.profile?.name}
            </span>{" "}
            vs{" "}
            <span className="bold">
              {awayParticipant?.organization?.name ||
                awayParticipant?.profile?.name}
            </span>
          </Link>{" "}
          from{" "}
          <Link to={"/app/event/" + event.id}>
            <span className="bold">{event.name}</span>
          </Link>{" "}
          was published successfully.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function EventRoleAcceptedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          accepted{" "}
          <Link to={"/app/event/" + notification.destination?.event?.id}>
            <span className="bold">
              {notification.destination?.event?.name}
            </span>
          </Link>
          's request to add{" "}
          <Link to={"/app/page/" + notification.source?.organization?.id}>
            <span className="bold">
              {notification.source?.organization?.name}
            </span>
          </Link>{" "}
          as a {notification.meta?.roleType}.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function EventRoleDeclinedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          declined{" "}
          <Link to={"/app/event/" + notification.destination?.event?.id}>
            <span className="bold">
              {notification.destination?.event?.name}
            </span>
          </Link>
          's request to add{" "}
          <Link to={"/app/page/" + notification.source?.organization?.id}>
            <span className="bold">
              {notification.source?.organization?.name}
            </span>
          </Link>{" "}
          as a {notification.meta?.roleType}.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function FixtureRoleAcceptedNotification({ notification }) {
  console.log("Accepted Not:", notification);
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          accepted{" "}
          <Link
            to={"/app/event/" + notification.destination?.fixture?.event?.id}
          >
            <span className="bold">
              {notification.destination?.fixture?.event?.name}
            </span>
          </Link>
          's request to be added as a {notification.meta?.roleType}.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function FixtureRoleDeclinedNotification({ notification }) {
  console.log("Declined Not:", notification);
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          declined{" "}
          <Link
            to={"/app/event/" + notification.destination?.fixture?.event?.id}
          >
            <span className="bold">
              {notification.destination?.fixture?.event?.name}
            </span>
          </Link>
          's request to be added as a {notification.meta?.roleType}.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function MembershipDeclinedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          declined{" "}
          <Link to={"/app/page/" + notification.destination?.organization?.id}>
            <span className="bold">
              {notification.destination?.organization?.name}
            </span>
          </Link>
          's membership request.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function MembershipAcceptedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          accepted{" "}
          <Link to={"/app/page/" + notification.destination?.organization?.id}>
            <span className="bold">
              {notification.destination?.organization?.name}
            </span>
          </Link>
          's membership request.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function NewFollowerNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <Link to={"/app/profile/" + notification.source?.profile?.id}>
            <span className="bold">{notification.source?.profile?.name}</span>
          </Link>{" "}
          started following{" "}
          <Link to={"/app/page/" + notification.destination?.organization?.id}>
            <span className="bold">
              {notification.destination?.organization?.name}
            </span>
          </Link>
          .
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function FollowAcceptedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <Link to={"/app/profile/" + notification.source?.profile?.id}>
          <span className="bold">{notification.source?.profile?.name}</span>
        </Link>{" "}
        accepted your follow request.
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

export default function NotificationItem({ notification }) {
  if (notification.type === "MEMBERSHIP-DECLINED") {
    return <MembershipDeclinedNotification notification={notification} />;
  } else if (notification.type === "MEMBERSHIP-ACCEPTED") {
    return <MembershipAcceptedNotification notification={notification} />;
  } else if (notification.type === "FOLLOW-REQUEST-ACCEPTED") {
    return <FollowAcceptedNotification notification={notification} />;
  } else if (notification.type === "NEW-FOLLOWER") {
    return <NewFollowerNotification notification={notification} />;
  } else if (notification.type === "EVENT-ROLE-ACCEPTED") {
    return <EventRoleAcceptedNotification notification={notification} />;
  } else if (notification.type === "EVENT-ROLE-DECLINED") {
    return <EventRoleDeclinedNotification notification={notification} />;
  } else if (notification.type === "FIXTURE-ROLE-ACCEPTED") {
    return <FixtureRoleAcceptedNotification notification={notification} />;
  } else if (notification.type === "FIXTURE-ROLE-DECLINED") {
    return <FixtureRoleDeclinedNotification notification={notification} />;
  } else if (notification.type === "PUBLISH-CONFIRMATION") {
    return <PublishFixtureConfirmNotification notification={notification} />;
  } else {
    return <div />;
  }
}
