import "./StatInputForm.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Card from "../../../components/Card";
import Avatar from "../../../components/Avatar";
import { useFootballScoringState } from "../utils/useFootballScoringState";
import { useState } from "react";
import flattenStatTypes from "../utils/flattenStatTypes";
import canSubmitFormData from "../utils/canSubmitFormData";
import Spinner from "../../../components/Spinner";
import { useMutation, gql } from "@apollo/client";
import { CREATE_STAT } from "../../../api/fixture";
import { FIXTURE_STATS, UPDATE_FIXTURE } from "../../../api/functions/fixture";
import makeStatVars from "../utils/makeStatVars";
import moment from "moment";
import { createId } from "@paralleldrive/cuid2";
import makeOptimisticResponse from "../utils/makeOptimisticResponse";

export default function StatInputForm({
  fixtureId,
  fixture,
  eventId,
  selectedStat,
  homeTeam,
  awayTeam,
  defaultSelectedTeamIndex = 0,
  homeLineup,
  awayLineup,
  defaultTimestamp,
  periods,
  selectedPeriod,
  statTypes,
  hideTimestamp,
}) {
  //   console.log(selectedPeriodId);
  const [{ setOverlay, stats, cache }, setFootballScoringState] =
    useFootballScoringState();
  const [timestamp, setTimestamp] = useState(
    defaultTimestamp ? Math.ceil(defaultTimestamp / 60) : "1"
  );
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(
    defaultSelectedTeamIndex
  );
  const [periodId, setPeriodId] = useState(selectedPeriod?.id);
  const [formIndex, setFormIndex] = useState(0);
  const [formData, setFormData] = useState(flattenStatTypes(selectedStat));
  const [submitting, setSubmitting] = useState(false);
  //   console.log(fixtureId);

  const [updateCacheMutation] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: ``,
    })
  );

  const [mutate, { loading }] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      console.log("Update function running:", createStat);
      const newStats = [
        createStat,
        ...(createStat?.relationshipsTo || []).map((r) => ({
          ...r.from,
          relationshipsFrom: [{ to: createStat }],
          relationshipsTo: [],
        })),
        ...(createStat?.relationshipsFrom || []).map((r) => ({
          ...r.to,
          relationshipsFrom: [],
          relationshipsTo: [{ from: createStat }],
        })),
      ];

      const scoreStats = newStats.filter((x) =>
        ["Goal", "Own Goal"].includes(x.statType.name)
      );

      if (scoreStats.length) {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            highlights: (existingRefs, { readField }) => {
              const newStatRefs = scoreStats.map((x) =>
                cache.writeFragment({
                  data: x,
                  fragment: gql`
                    fragment NewStat on Stat {
                      id
                      statType {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                    }
                  `,
                })
              );

              return [...newStatRefs, ...existingRefs];
            },
          },
        });
      }

      setFootballScoringState({
        stats: {
          ...stats,
          [periodId]: [...newStats, ...(stats[periodId] || [])],
        },
      });

      if (createStat.isTemp) {
        // Update the scoreline

        // Close the form
        setOverlay(false);
      }
    },
    onError: (error) => {
      // console.log("Operation:", operation);
      // if (graphQLErrors) {
      //   console.log("Trying again");
      //   return forward(operation);
      // }

      // if (networkError) {
      //   console.log("Network Error:", networkError);
      // }
      // console.log(options);
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreFootball.StatInputFormNew",
      //     mutation: "CREATE_STAT",
      //     formState: {
      //       currentStat,
      //       formIndex,
      //       timestamp,
      //       primaryStat,
      //       associatedStats,
      //       isHomeTeam,
      //       periodId,
      //     },
      //   },
      // });
      console.log(error);
    },
  });

  const lineup = selectedTeamIndex ? awayLineup : homeLineup;
  //   console.log(formIndex);

  const submit = (data) => {
    if (loading || !timestamp || !Number(timestamp)) return;
    console.log("SUBMIT");
    const primaryStat = data[0];
    const associatedStats = [...data]
      .slice(1, data.length)
      .filter((x) => !x.skipped);
    const primaryStatType = statTypes.find(
      (st) => st.name === primaryStat.label
    );

    const vars = {
      ...makeStatVars({
        formDatum: primaryStat,
        fixtureId,
        eventId,
        timestamp,
        periodId,
        statTypes,
      }),
      ...(associatedStats.length
        ? {
            relationshipsFrom: {
              create: associatedStats.map((x) => ({
                relationship: {
                  connect: {
                    id: primaryStatType.relationshipsFrom.find(
                      (r) => r.type.name === "Sibling" && r.to.name === x.label
                    ).id,
                  },
                },
                to: {
                  create: makeStatVars({
                    formDatum: x,
                    fixtureId,
                    eventId,
                    timestamp,
                    periodId,
                    statTypes,
                  }),
                },
              })),
            },
          }
        : {}),
    };

    // console.log("Vars:", vars);
    // return;
    // const optimisticResponse = {
    //   createStat: makeOptimisticResponse({
    //     vars,
    //     statTypes,
    //     profiles: data.map((d) => d.data?.profile).filter((p) => !!p),
    //     organizations: data.map((d) => d.data?.organization).filter((o) => !!o),
    //   }),
    // };

    // console.log(optimisticResponse);
    // Update cache
    const newCache = {
      selectedPeriodId: selectedPeriod.id,
      periods: {
        ...(cache?.periods || {}),
        [selectedPeriod.id]: defaultTimestamp,
      },
    };

    updateCacheMutation({
      variables: {
        where: { id: fixtureId },
        data: {
          statsCacheState: newCache,
        },
      },
    });

    setFootballScoringState({
      cache: newCache,
    });

    // Create stat
    mutate({
      variables: {
        data: vars,
      },
      optimisticResponse: {
        // createStat: makeOptimisticResponse({
        //   vars,
        //   statTypes,
        //   profiles: data.map((d) => d.data?.profile).filter((p) => !!p),
        //   organizations: data
        //     .map((d) => d.data?.organization)
        //     .filter((o) => !!o),
        // }),
        createStat: {
          id: createId(),
          __typename: "Stat",
          createdAt: moment().toISOString(),
          timeStamp: Number(timestamp),
          value: null,
          stringValue: null,
          statType: primaryStatType,
          profile: primaryStat.data.profile,
          organization: primaryStat.data.organization,
          fixturePeriod: {
            id: periodId,
          },
          relationshipsFrom: [],
          relationshipsTo: [],
          isTemp: true,
        },
      },
    });
  };

  const skip = () => {
    setFormData((prev) => {
      const newFormData = [...prev];
      newFormData[formIndex].skipped = true;

      if (canSubmitFormData(newFormData)) {
        submit(newFormData);
        setSubmitting(true);
        // setOverlay(false);
        // submit form and close
      } else {
        setFormIndex((prev) => prev + 1);
      }

      return newFormData;
    });
  };

  const onPlayerClick = (role) => {
    setFormData((prev) => {
      const newFormData = [...prev];
      newFormData[formIndex].data = {
        profile: role.profile,
        organization: role.organization,
      };

      if (canSubmitFormData(newFormData)) {
        submit(newFormData);
        setSubmitting(true);
        // setOverlay(false);
        // submit form and close
      } else {
        setFormIndex((prev) => prev + 1);
        return newFormData;
      }
    });
  };

  if (submitting || loading) {
    return (
      <Card className="ScoringApp-football__scoring__form">
        <Spinner />
      </Card>
    );
  }

  return (
    <Card className="ScoringApp-football__scoring__form">
      <div className="ScoringApp-football__scoring__form__header">
        <div className="ScoringApp-football__scoring__form__header__title">
          Add {selectedStat.label}
        </div>

        <div
          className="ScoringApp-football__scoring__form__header__dismiss"
          onClick={() => {
            setOverlay(false);
          }}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "20px" }} />
        </div>
      </div>

      {formData.map((f) => {
        if (f.data || f.skipped) {
          return (
            <div
              key={f.label}
              className="ScoringApp-football__scoring__form__saved"
            >
              <div className="ScoringApp-football__scoring__form__saved__timestamp">
                {timestamp}'
              </div>

              {f.skipped ? (
                <div className="ScoringApp-football__scoring__form__saved__statement skipped">
                  {f.label} Skipped
                </div>
              ) : (
                <div className="ScoringApp-football__scoring__form__saved__statement">
                  <span className="bold">{f.label}</span> by{" "}
                  <span className="bold">{f.data.profile?.name}</span>
                </div>
              )}
            </div>
          );
        } else {
          return <div key={f.label} />;
        }
      })}

      <div className="ScoringApp-football__scoring__form__meta">
        <div className="ScoringApp-football__scoring__form__meta__label">
          Period
        </div>

        <div className="ScoringApp-football__scoring__form__meta__editor">
          <select
            value={periodId}
            onChange={(e) => setPeriodId(e.target.value)}
          >
            {periods?.map((p) => (
              <option key={p.id} value={p.id}>
                {p.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!hideTimestamp && (
        <div className="ScoringApp-football__scoring__form__meta">
          <div className="ScoringApp-football__scoring__form__meta__label">
            Timestamp
          </div>

          <div className="ScoringApp-football__scoring__form__meta__editor">
            <input
              type="number"
              min={0}
              step={1}
              value={timestamp}
              onChange={(e) => {
                setTimestamp(e.target.value);
              }}
            />
          </div>
        </div>
      )}

      {formIndex > 0 && (
        <div className="ScoringApp-football__scoring__form__secondary-header">
          <div className="ScoringApp-football__scoring__form__secondary-header__title">
            Who got the {formData[formIndex]?.label}?
          </div>

          <div
            className="ScoringApp-football__scoring__form__secondary-header__skip"
            onClick={skip}
          >
            Skip
          </div>
        </div>
      )}

      <div className="ScoringApp-football__scoring__form__select-player">
        <div className="ScoringApp-football__scoring__form__select-player__tabs">
          <div
            className={
              "ScoringApp-football__scoring__form__select-player__tabs__tab " +
              (selectedTeamIndex ? "" : "selected")
            }
            onClick={() => {
              setSelectedTeamIndex(0);
            }}
          >
            {homeTeam?.organization?.name}
          </div>

          <div
            className={
              "ScoringApp-football__scoring__form__select-player__tabs__tab " +
              (selectedTeamIndex ? "selected" : "")
            }
            onClick={() => {
              setSelectedTeamIndex(1);
            }}
          >
            {awayTeam?.organization?.name}
          </div>
        </div>

        <div className="ScoringApp-football__scoring__form__select-player__list">
          {lineup.map((r) => (
            <div
              className="ScoringApp-football__scoring__form__select-player__list__item"
              onClick={() => {
                if (loading) return;
                onPlayerClick(r);
              }}
              key={r.id}
            >
              <div className="ScoringApp-football__scoring__form__select-player__list__item__img">
                {r.profile?.profilePhoto && (
                  <Avatar media={r.profile.profilePhoto} />
                )}
              </div>
              <div className="ScoringApp-football__scoring__form__select-player__list__item__name">
                <div className="ScoringApp-football__scoring__form__select-player__list__item__name__primary">
                  {r.profile.name}
                </div>
                <div className="ScoringApp-football__scoring__form__select-player__list__item__name__secondary">
                  @{r.profile.handle}
                </div>
              </div>
              <div className="ScoringApp-football__scoring__form__select-player__list__item__num">
                {r.order}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
    // <div className="stat-input-form">
    //   <div className="stat-input-form__title">
    //     Add {selectedStat.name}
    //     <div className="dismiss-btn" onClick={dismiss}>
    //       <TimesIcon style={{ stroke: "var(--light-2", height: "20px" }} />
    //     </div>
    //   </div>

    //   <div className="timestamp-editor">
    //     <div className="label">Period</div>

    //     <div className="input">
    //       <select
    //         value={periodId}
    //         onChange={(e) => setPeriodId(e.target.value)}
    //       >
    //         {periods.map((p) => (
    //           <option key={p.id} value={p.id}>
    //             {p.label}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //   </div>

    //   {!isPenaltyShootout && (
    //     <div className="timestamp-editor">
    //       <div className="label">Timestamp</div>
    //       <div className="input">
    //         <div
    //           className="icon"
    //           onClick={() => {
    //             setTimestamp((prev) => Math.max(0, prev - 1));
    //           }}
    //         >
    //           <MinusIcon style={{ stroke: "var(--dark-4", height: "14px" }} />
    //         </div>
    //         <div className="value">{timestamp}</div>
    //         <div
    //           className="icon"
    //           onClick={() => {
    //             setTimestamp((prev) => prev + 1);
    //           }}
    //         >
    //           <PlusIcon style={{ stroke: "var(--dark-4", height: "14px" }} />
    //         </div>
    //       </div>
    //     </div>
    //   )}

    //   {primaryStat && (
    //     <div className="saved-stats">
    //       <div className="saved-stat-item">
    //         <div className="timestamp">{timestamp}'</div>
    //         <div className="statement">
    //           <span className="bold">{primaryStat.statType.name}</span> by{" "}
    //           <span className="bold">{primaryStat.profile?.name}</span>
    //         </div>
    //         <div className="edit-btn">Edit</div>
    //       </div>
    //       {associatedStats.map((a, i) => (
    //         <div key={"ssi-" + i} className="saved-stat-item">
    //           <div className="timestamp">{timestamp}'</div>
    //           <div className="statement">
    //             <span className="bold">{a.statType.name}</span> by{" "}
    //             <span className="bold">{a.profile?.name}</span>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   )}

    //   {primaryStat && !!associates.length && (
    //     <div className="associated-stat-title">
    //       Add Associated {associates[formIndex].name}
    //       <div
    //         className="skip-associated-btn"
    //         onClick={() => {
    //           skip();
    //         }}
    //       >
    //         Skip
    //       </div>
    //     </div>
    //   )}

    //   <div className="select-player">
    //     <div className="team-selector">
    //       <div
    //         className={"team-selector__item " + (isHomeTeam ? "active" : "")}
    //         onClick={() => {
    //           setIsHomeTeam(true);
    //         }}
    //       >
    //         {homeTeam.organization.name}
    //       </div>

    //       <div
    //         className={"team-selector__item " + (!isHomeTeam ? "active" : "")}
    //         onClick={() => {
    //           setIsHomeTeam(false);
    //         }}
    //       >
    //         {awayTeam.organization.name}
    //       </div>
    //     </div>
    //   </div>

    //   <div className="player-list">
    //     {lineup.map((r) => (
    //       <div
    //         className="select-player__item"
    //         onClick={() => {
    //           onPlayerClick(r);
    //         }}
    //         key={r.id}
    //       >
    //         <div className="img">
    //           {r.profile?.profilePhoto && (
    //             <Avatar media={r.profile.profilePhoto} />
    //           )}
    //         </div>
    //         <div className="name">
    //           <div className="name__primary">{r.profile.name}</div>
    //           <div className="name__secondary">@{r.profile.handle}</div>
    //         </div>
    //         <div className="num-container">
    //           <div className="num-val">{r.order}</div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
}
