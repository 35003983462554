import "./Chip.css";

function Chip({ children, onClick = () => {}, className = "" }) {
  return (
    <div className={"app-chip " + className} onClick={onClick}>
      {children}
    </div>
  );
}

export default Chip;
