import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../../icons/times.svg";
import Avatar from "../../../Avatar";

export default function SelectPlayer({
  title,
  onSelect,
  defaultTeam,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  dismiss,
}) {
  const [isHomeTeam, setIsHomeTeam] = useState(
    defaultTeam?.id === homeTeam?.organization?.id ? true : false
  );
  const lineup = isHomeTeam ? homeLineup : awayLineup;
  return (
    <div className="stat-overlay">
      <div className="stat-input-form">
        <div className="stat-input-form__title">
          {title}
          <div className="dismiss-btn" onClick={dismiss}>
            <TimesIcon style={{ stroke: "var(--light-2", height: "20px" }} />
          </div>
        </div>
        <div className="select-player">
          <div className="team-selector">
            <div
              className={"team-selector__item " + (isHomeTeam ? "active" : "")}
              onClick={() => {
                setIsHomeTeam(true);
              }}
            >
              {homeTeam.organization.name}
            </div>

            <div
              className={"team-selector__item " + (!isHomeTeam ? "active" : "")}
              onClick={() => {
                setIsHomeTeam(false);
              }}
            >
              {awayTeam.organization.name}
            </div>
          </div>
        </div>

        <div className="player-list">
          {lineup.map((r) => (
            <div
              className="select-player__item"
              onClick={() => {
                onSelect(r);
                dismiss();
              }}
            >
              <div className="img">
                {r.profile?.profilePhoto && (
                  <Avatar media={r.profile.profilePhoto} />
                )}
              </div>
              <div className="name">
                <div className="name__primary">{r.profile.name}</div>
                <div className="name__secondary">@{r.profile.handle}</div>
              </div>
              <div className="num-container">
                {/* <JerseyIcon
                  style={{ fill: "var(--light-4)", position: "absolute" }}
                /> */}
                <div className="num-val">{r.order || "-"}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
