import "./Directory.css";
import { useState, Fragment } from "react";
import { useAppState } from "../../utils/appState";
import { useQuery } from "@apollo/client";
import { ORGANIZATIONS, ORGANIZATION_TYPES } from "../../api/organization";
import { DirectoryCategories } from "../../mobileComponents/Directory";
import { PageCard } from "../../mobileComponents/Directory";
import Placeholder from "../../components/Placeholder";
import Loader from "../../components/Loader";
import Filters from "../../mobileComponents/Filters";
import { SPORTS } from "../../api/sport";
import LocationSearch from "../../mobileComponents/LocationSearch";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function NoOrgsPlaceholder({ refetch }) {
  return (
    <div className="no-data">
      <div>There are no pages for this selection.</div>
      <div
        className="link hover-pointer hover-underline"
        onClick={() => {
          refetch();
        }}
      >
        Check again
      </div>
    </div>
  );
}

function ListLoading() {
  return (
    <Fragment>
      <div className="directory-page-mobile__list__item">
        <div className={"directory-page-mobile__list__item__image "}></div>

        <div className="directory-page-mobile__list__item__text">
          <div className="directory-page-mobile__list__item__text__primary">
            <Placeholder
              style={{ height: "14px", width: "120px" }}
              pulse={true}
            />
          </div>

          <div className="directory-page-mobile__list__item__text__secondary">
            <Placeholder
              style={{ height: "10px", width: "70px" }}
              pulse={true}
            />
          </div>

          <div className="directory-page-mobile__list__item__text__secondary">
            <Placeholder
              style={{ height: "10px", width: "60px" }}
              pulse={true}
            />
          </div>
        </div>
      </div>

      <div className="directory-page-mobile__list__item">
        <div className={"directory-page-mobile__list__item__image "}></div>

        <div className="directory-page-mobile__list__item__text">
          <div className="directory-page-mobile__list__item__text__primary">
            <Placeholder
              style={{ height: "14px", width: "120px" }}
              pulse={true}
            />
          </div>

          <div className="directory-page-mobile__list__item__text__secondary">
            <Placeholder
              style={{ height: "10px", width: "70px" }}
              pulse={true}
            />
          </div>

          <div className="directory-page-mobile__list__item__text__secondary">
            <Placeholder
              style={{ height: "10px", width: "60px" }}
              pulse={true}
            />
          </div>
        </div>
      </div>

      <div className="directory-page-mobile__list__item">
        <div className={"directory-page-mobile__list__item__image "}></div>

        <div className="directory-page-mobile__list__item__text">
          <div className="directory-page-mobile__list__item__text__primary">
            <Placeholder
              style={{ height: "14px", width: "120px" }}
              pulse={true}
            />
          </div>

          <div className="directory-page-mobile__list__item__text__secondary">
            <Placeholder
              style={{ height: "10px", width: "70px" }}
              pulse={true}
            />
          </div>

          <div className="directory-page-mobile__list__item__text__secondary">
            <Placeholder
              style={{ height: "10px", width: "60px" }}
              pulse={true}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function Directory() {
  usePageTracking({ title: "Directory" });
  const [
    { directoryCategory, directorySports, directoryTypes, directoryLocations },
    setAppState,
  ] = useAppState();

  const [sports, setSports] = useState(directorySports || []);
  const [types, setTypes] = useState(directoryTypes || []);
  const [locations, setLocations] = useState(directoryLocations || []);
  const [category, setCategory] = useState("");
  const typesQuery = useQuery(ORGANIZATION_TYPES);
  const sportsQuery = useQuery(SPORTS);
  const { error, data, networkStatus, variables, fetchMore, refetch } =
    useQuery(ORGANIZATIONS, {
      variables: { where: {} },
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Directory",
        query: "ORGANIZATIONS",
      },
    });
    console.log(error);
    return <div />;
  }

  const onCategorySelect = (cat) => {
    if (category !== cat) {
      setCategory(cat);
      refetch({
        where: {
          ...(variables.where || {}),
          types: [...(types.map((x) => x.id) || []), ...(cat ? [cat] : [])],
        },
      });
      setAppState({ directoryCategory: cat });
    } else {
      setCategory("");
      refetch({
        where: {
          ...(variables.where || {}),
          types: [...(types.map((x) => x.id) || [])],
        },
      });
      setAppState({ directoryCategory: "" });
    }
  };

  const onTypeSelect = (item, selected) => {
    let newFilters;

    if (selected["Categories"].find((x) => x.id === item.id)) {
      newFilters = selected["Categories"].filter((x) => x.id !== item.id);
    } else {
      newFilters = [...selected["Categories"], item];
    }

    refetch({
      where: {
        ...(variables.where || {}),
        types: [
          ...newFilters.map((x) => x.id),
          ...(category ? [category] : []),
        ],
      },
    });
    setTypes(newFilters);
    setAppState({ directoryTypes: newFilters });

    // console.log("New filters:", newFilters);
    return {
      ...selected,
      Categories: newFilters,
    };
  };

  const onLocationSelect = (locations) => {
    setLocations(locations);
    refetch({
      where: {
        ...(variables.where || {}),
        cities: locations.map((s) => s.name),
      },
    });
    setAppState({ directoryLocations: locations });
  };

  const onSportSelect = (item, selected) => {
    let newFilters;

    if (selected["Sports"].find((x) => x.id === item.id)) {
      newFilters = selected["Sports"].filter((x) => x.id !== item.id);
    } else {
      newFilters = [...selected["Sports"], item];
    }

    refetch({
      where: {
        ...(variables.where || {}),
        sports: [...newFilters.map((x) => x.id)],
      },
    });
    setSports(newFilters);
    setAppState({ directorySports: newFilters });

    // console.log("New filters:", newFilters);
    return {
      ...selected,
      Sports: newFilters,
    };
  };

  const organizations = data?.organizations || [];
  const sportsList = sportsQuery.data?.sports || [];
  const categories = typesQuery.data?.organizationTypes || [];

  return (
    <div className="directory-page-mobile">
      <div className="directory-page-mobile__filters">
        <DirectoryCategories
          onCategorySelect={onCategorySelect}
          defaultValue={directoryCategory}
          onFiltersClick={() => {
            setAppState({
              modal: (
                <Filters
                  defaultSelected={{
                    Sports: sports,
                    Categories: types,
                  }}
                  categories={[
                    {
                      label: "Sports",
                      items: sportsList.map((s) => ({
                        ...s,
                        label: s.name,
                      })),
                      itemFilter: (selected) => {
                        return (item) => item;
                      },
                      onSelect: onSportSelect,
                      isSelected: (selected, item) => {
                        return selected.find((x) => x.id === item.id);
                      },
                    },
                    {
                      label: "Categories",
                      items: categories.map((e) => ({ ...e, label: e.name })),
                      // .filter((e) => e.sport.name === selectedSport)
                      // .map((e) => ({ ...e, label: e.name })),
                      itemFilter: (selected) => {
                        return (item) => item;
                      },
                      onSelect: onTypeSelect,
                      isSelected: (selected, item) => {
                        return selected.find((x) => x.id === item.id);
                      },
                    },
                  ]}
                />
              ),
            });
          }}
          onLocationClick={() => {
            setAppState({
              modal: (
                <LocationSearch
                  locations={locations}
                  onLocationSelect={onLocationSelect}
                />
              ),
            });
          }}
        />
      </div>

      <div className="directory-page-mobile__list">
        {(networkStatus === 1 || networkStatus === 2) && <ListLoading />}
        {networkStatus !== 2 &&
          organizations.map((o) => <PageCard key={o.id} organization={o} />)}

        {!(networkStatus === 1 || !data) && !organizations?.length && (
          <NoOrgsPlaceholder refetch={refetch} />
        )}

        {!!organizations?.length &&
          networkStatus !== 1 &&
          networkStatus !== 2 && (
            <div className="show-more-container">
              <div
                className="primary small blue"
                onClick={() => {
                  if (networkStatus === 3) return;
                  fetchMore({
                    variables: {
                      ...variables,
                      cursor: organizations[organizations.length - 1].id,
                    },
                  });
                }}
              >
                {networkStatus === 3 ? (
                  <Loader theme={"small blue"} />
                ) : (
                  "Show More"
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default Directory;
