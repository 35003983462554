// V1
import { useState, Fragment } from "react";
import "./Organization.css";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import Loader from "../../components/Loader";
import Placeholder from "../../components/Placeholder";
import PhotoBrowser from "../../components/PhotoBrowser";
import UploaderWithCropper from "../../components/UploaderWithCropper";
import ClickOutside from "../../components/ClickOutside";
import FollowButton from "../../components/FollowButton";
import MapContainer from "../../components/Map";
import LocationSearch from "../../components/LocationSearch";
import Image from "../../components/Image";
import PartyList from "../../components/PartyList";
import {
  OrganizationOverview,
  OrganizationEvents,
  OrganizationServices,
  OrganizationPosts,
  OrganizationPhotos,
  OrganizationVideos,
  OrganizationStats,
} from "../../components/Organization";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  ORGANIZATION,
  ORGANIZATION_BIO,
  UPDATE_ORGANIZATION,
  ORGANIZATION_MEMBERS,
} from "../../api/organization";
import { CAN_MANAGE_PAGE } from "../../api/user";
import { INTERACTION_STATS, FOLLOWERS } from "../../api/profile";
import { DELETE_MEDIA } from "../../api/media";
import moment from "moment";
import ToggleState from "../../components/ToggleState";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as TeamIcon } from "../../icons/club.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import { ReactComponent as ControllerIcon } from "../../icons/controller.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as BuildingIcon } from "../../icons/building.svg";
import { useAppState } from "../../utils/appState";
import useRouterQuery from "../../utils/useRouterQuery";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Avatar from "../../components/Avatar";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

const organizationTabs = [
  { label: "Overview", component: OrganizationOverview, key: "overview" },
  { label: "Events", component: OrganizationEvents, key: "events" },
  { label: "Posts", component: OrganizationPosts, key: "posts" },
  { label: "Photos", component: OrganizationPhotos, key: "photos" },
  { label: "Videos", component: OrganizationVideos, key: "videos" },
  // { label: "Timeline", component: OrganizationTimeline, key: "timeline" },
  { label: "Services", component: OrganizationServices, key: "services" },
  { label: "Statistics", component: OrganizationStats, key: "statistics" },
];

function UpdateOrganizationInterests({ setIndex, id, defaultInterests }) {
  const [interests, setInterests] = useState(defaultInterests || []);

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };

  const sports = [
    { name: "Football" },
    { name: "Basketball" },
    { name: "Cricket" },
    { name: "Badminton" },
    { name: "Tennis" },
    { name: "E-Gaming" },
  ];

  const [updateOrganization, { loading }] = useMutation(
    UPDATE_ORGANIZATION("interests{id name}"),
    {
      update: (cache, { data: { updateOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATION_BIO,
          variables: { id },
        });

        cache.writeQuery({
          query: ORGANIZATION_BIO,
          variables: { id },
          data: {
            organization: {
              ...q.organization,
              interests: updateOrganization.interests,
            },
          },
        });

        setIndex(0);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Organization.UpdateOrganizationInterests",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const toConnect = interests
    .map((i) => i.name)
    .filter((i) => !defaultInterests.map((x) => x.name).includes(i));

  const toDisconnect = defaultInterests
    .map((i) => i.name)
    .filter((i) => !interests.map((x) => x.name).includes(i));

  return (
    <div className="info-group vertical">
      <div className="key">
        Interests
        <div className="edit-bio-form">
          <button
            type={"submit "}
            className={
              "submit " +
              (!toConnect.length && !toDisconnect.length ? "disabled" : "")
            }
            onClick={() => {
              // console.log("To connect:", toConnect);
              // console.log("To disconnect:", toDisconnect);
              if (loading) return;
              updateOrganization({
                variables: {
                  where: { id },
                  data: {
                    interests: {
                      connect: toConnect.map((i) => ({ name: i })),
                      disconnect: toDisconnect.map((i) => ({ name: i })),
                    },
                  },
                },
              });
            }}
          >
            <CheckIcon className="icon" />
          </button>
          <button
            onClick={() => {
              setIndex(0);
            }}
            className="cancel"
          >
            <TimesIcon className="icon" />
          </button>
        </div>
      </div>
      <div className="value grid">
        {sports.map((sport, i) => (
          <div
            key={"org-sport-form-" + sport.id + i}
            className={
              "interest hover-pointer " +
              (interests.find((i) => i.name === sport.name) ? "" : "inactive")
            }
            onClick={() => {
              if (interests.find((i) => i.name === sport.name)) {
                setInterests((prev) =>
                  prev.filter((i) => i.name !== sport.name)
                );
              } else {
                setInterests((prev) => [...prev, sport]);
              }
            }}
          >
            <div className="icon">
              {sportsIcons[sport.name] || (
                <EllipsisIcon className="sports-icon-svg" />
              )}
            </div>
            <div className="desc">{sport.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function UpdateOrganizationLocations({ setIndex, id, defaultLocations }) {
  const [updateOrganization, { loading }] = useMutation(
    UPDATE_ORGANIZATION("cities{id value lat lng}"),
    {
      update: (cache, { data: { updateOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATION_BIO,
          variables: { id },
        });

        cache.writeQuery({
          query: ORGANIZATION_BIO,
          variables: { id },
          data: {
            organization: {
              ...q.organization,
              cities: updateOrganization.cities,
            },
          },
        });

        // setIndex(0);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Organization.UpdateOrganizationLocations",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="info-group vertical">
      <div className="key">
        Locations
        <div className="edit-bio-form">
          <button
            onClick={() => {
              setIndex(0);
            }}
            className="cancel"
          >
            <TimesIcon className="icon" />
          </button>
        </div>
      </div>
      <LocationSearch
        defaultSearchString={""}
        onLocationSelect={({ address, setSearchString }) => {
          if (!address) {
            return;
          }

          const city = address?.split(",")[0].trim();
          const state = address?.split(",")[1].trim();
          const country = address?.split(",")[2].trim();

          geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
              updateOrganization({
                variables: {
                  where: { id },
                  data: {
                    cities: {
                      connect: [
                        {
                          city,
                          state,
                          country,
                          lat: latLng.lat,
                          lng: latLng.lng,
                        },
                      ],
                    },
                  },
                },
              });
              setSearchString("");
            })
            .catch((error) => console.error("Error", error));
        }}
        placeholder={"Add a city"}
      />
      <div className="value grid">
        {defaultLocations.map((loc, i) => (
          <div
            key={"org-default-loc-" + loc.value + i}
            className={"interest hover-pointer "}
            onClick={() => {
              if (loading) return;
              updateOrganization({
                variables: {
                  where: { id },
                  data: {
                    cities: {
                      disconnect: [{ id: loc.id }],
                    },
                  },
                },
              });
            }}
          >
            <div className="icon">
              <TimesIcon
                className="sports-icon-svg"
                style={{ stroke: "white", fill: "none" }}
              />
            </div>
            <div className="desc">{loc.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function SelectProfilePhotoList({ organization, setUpload }) {
  const [, setAppState] = useAppState();
  const [selectedPhoto, setSelectedPhoto] = useState(organization.profilePhoto);
  // const photos = organization.profilePhotos;

  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id: organization.id },
  });

  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      // refetch();
      if (!((data?.organization?.profilePhotos?.length || 0) - 1 > 0)) {
        // console.log("NO PROFILE PHOTOS LEFT");
        setUpload(true);
      }

      cache.modify({
        id: cache.identify(organization),
        fields: {
          profilePhotos(existingMediaRefs, { readField }) {
            return existingMediaRefs.filter(
              (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
            );
          },
          ...(deleteMedia.id === organization.profilePhoto?.id
            ? { profilePhoto: () => null }
            : {}),
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Organization.SelectProfilePhotoList",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [updateOrganization, { loading: updating }] = useMutation(
    UPDATE_ORGANIZATION(`profilePhoto{id filename}`, {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Organization.SelectProfilePhotoList",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    })
  );

  const updateProfilePhoto = () => {
    updateOrganization({
      variables: {
        where: { id: organization.id },
        data: {
          profilePhoto: selectedPhoto?.id
            ? { connect: { id: selectedPhoto.id } }
            : { disconnect: true },
        },
      },
      update: (cache, { data: { updateOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATION,
          variables: { id: organization.id },
        });

        cache.writeQuery({
          query: ORGANIZATION,
          variables: { id: organization.id },
          data: {
            organization: {
              ...q.organization,
              profilePhoto: updateOrganization.profilePhoto,
            },
          },
        });

        setAppState({ modal: false });
      },
    });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.SelectProfilePhotoList",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const photos = data.organization.profilePhotos;

  return (
    <Fragment>
      <div className="select-profile-photo__images">
        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 0)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 1)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 2)
            .map((m) => (
              <div className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 3)
            .map((m) => (
              <div className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-profile-photo-list-options">
        <Button
          className="primary medium blue"
          onClick={() => {
            if (updating) return;
            updateProfilePhoto(selectedPhoto);
          }}
        >
          {updating ? <Loader /> : "Save"}
        </Button>
        <Button
          className="tertiary"
          style={{ marginTop: "16px" }}
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
}

function SelectProfilePhoto({ organization }) {
  const [upload, setUpload] = useState(
    organization.profilePhotos?.length ? false : true
  );
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id: organization.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.SelectProfilePhoto",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="select-profile-photo-wrapper">
        <Card>
          <Spinner />
        </Card>
      </div>
    );
  }

  if (upload) {
    return (
      <UploaderWithCropper
        accept=".jpg,.png,.jpeg"
        label="Upload display photo"
        multiple={false}
        sourceId={organization.id}
        sourceType={"organization"}
        subtype={"PROFILE PHOTO"}
        onCreateMedia={(cache, createMedia) => {
          const q = cache.readQuery({
            query: ORGANIZATION,
            variables: { id: organization.id },
          });

          cache.writeQuery({
            query: ORGANIZATION,
            variables: { id: organization.id },
            data: {
              organization: {
                ...q.organization,
                profilePhoto: {
                  ...createMedia,
                },
                profilePhotos: [
                  ...q.organization.profilePhotos,
                  { ...createMedia },
                ],
              },
            },
          });

          setUpload(false);
          setAppState({ modal: false });
        }}
      />
    );
  } else {
    return (
      <ClickOutside
        onClickOutside={() => {
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div className="select-profile-photo-wrapper" ref={wrapperRef}>
            <Card className="select-profile-photo">
              <div className="select-profile-photo__header">
                <div className="select-profile-photo__header__title">
                  Select profile image
                </div>
                <div
                  className="select-profile-photo__header__link"
                  onClick={() => {
                    setUpload(true);
                  }}
                >
                  Upload New
                </div>
              </div>

              <SelectProfilePhotoList
                organization={organization}
                setUpload={setUpload}
              />
            </Card>
          </div>
        )}
      </ClickOutside>
    );
  }
}

function SelectCoverPhotoList({ organization, setUpload }) {
  // console.log("OPened cover photo list");
  const [, setAppState] = useAppState();
  const [selectedPhoto, setSelectedPhoto] = useState(organization.coverPhoto);
  // const photos = organization.coverPhotos;

  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id: organization.id },
  });

  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      // refetch();
      if (!((data?.organization?.coverPhotos?.length || 0) - 1 > 0)) {
        // console.log("NO PROFILE PHOTOS LEFT");
        setUpload(true);
      }

      cache.modify({
        id: cache.identify(organization),
        fields: {
          coverPhotos(existingMediaRefs, { readField }) {
            return existingMediaRefs.filter(
              (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
            );
          },
          ...(deleteMedia.id === organization.coverPhoto?.id
            ? { coverPhoto: () => null }
            : {}),
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Organization.SelectCoverPhotoList",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [updateOrganization, { loading: updating }] = useMutation(
    UPDATE_ORGANIZATION(`coverPhoto{id filename}`, {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Organization.SelectCoverPhotoList",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    })
  );

  const updateProfilePhoto = () => {
    updateOrganization({
      variables: {
        where: { id: organization.id },
        data: {
          coverPhoto: selectedPhoto?.id
            ? { connect: { id: selectedPhoto.id } }
            : { disconnect: true },
        },
      },
      update: (cache, { data: { updateOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATION,
          variables: { id: organization.id },
        });

        cache.writeQuery({
          query: ORGANIZATION,
          variables: { id: organization.id },
          data: {
            organization: {
              ...q.organization,
              coverPhoto: updateOrganization.coverPhoto,
            },
          },
        });

        setAppState({ modal: false });
      },
    });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.SelectCoverPhotoList",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const photos = data.organization.coverPhotos;

  return (
    <Fragment>
      <div className="select-profile-photo__images">
        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 0)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 1)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 2)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 3)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-profile-photo-list-options">
        <Button
          className="primary medium blue"
          onClick={() => {
            if (updating) return;
            updateProfilePhoto(selectedPhoto);
          }}
        >
          {updating ? <Loader /> : "Save"}
        </Button>
        <Button
          className="tertiary"
          style={{ marginTop: "16px" }}
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
}

function SelectCoverPhoto({ organization }) {
  const [upload, setUpload] = useState(
    organization.coverPhotos?.length ? false : true
  );
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id: organization.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.SelectCoverPhoto",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="select-profile-photo-wrapper">
        <Card>
          <Spinner />
        </Card>
      </div>
    );
  }

  if (upload) {
    return (
      <UploaderWithCropper
        accept=".jpg,.png,.jpeg"
        label="Upload cover photo"
        multiple={false}
        sourceId={organization.id}
        sourceType={"organization"}
        subtype={"COVER PHOTO"}
        cropperWidth={240}
        cropperHeight={40}
        onCreateMedia={(cache, createMedia) => {
          const q = cache.readQuery({
            query: ORGANIZATION,
            variables: { id: organization.id },
          });

          cache.writeQuery({
            query: ORGANIZATION,
            variables: { id: organization.id },
            data: {
              organization: {
                ...q.organization,
                coverPhoto: {
                  ...createMedia,
                },
                coverPhotos: [
                  ...q.organization.coverPhotos,
                  { ...createMedia },
                ],
              },
            },
          });

          setUpload(false);

          setAppState({ modal: false });
        }}
      />
      // <Uploader
      //   accept=".jpg,.png,.jpeg"
      //   label="Upload cover photo"
      //   multiple={false}
      //   sourceId={organization.id}
      //   sourceType={"organization"}
      //   subtype={"COVER PHOTO"}
      //   onCreateMedia={(cache, createMedia) => {
      //     const q = cache.readQuery({
      //       query: ORGANIZATION,
      //       variables: { id: organization.id },
      //     });

      //     cache.writeQuery({
      //       query: ORGANIZATION,
      //       variables: { id: organization.id },
      //       data: {
      //         organization: {
      //           ...q.organization,
      //           coverPhoto: {
      //             ...createMedia,
      //           },
      //           coverPhotos: [
      //             ...q.organization.coverPhotos,
      //             { ...createMedia },
      //           ],
      //         },
      //       },
      //     });

      //     setUpload(false);
      //     setAppState({ modal: false });
      //   }}
      // />
    );
  } else {
    return (
      <ClickOutside
        onClickOutside={() => {
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div className="select-profile-photo-wrapper" ref={wrapperRef}>
            <Card className="select-profile-photo">
              <div className="select-profile-photo__header">
                <div className="select-profile-photo__header__title">
                  Select cover photo
                </div>
                <div
                  className="select-profile-photo__header__link"
                  onClick={() => {
                    setUpload(true);
                  }}
                >
                  Upload New
                </div>
              </div>

              <SelectCoverPhotoList
                organization={organization}
                setUpload={setUpload}
              />
            </Card>
          </div>
        )}
      </ClickOutside>
    );
  }
}

function UpdateOrgBio({
  setIndex,
  label,
  selectionSet,
  id,
  vars,
  defaultValue = "",
  defaultLocation = "",
  defaultCity = "",
  defaultState = "",
  defaultCountry = "",
  defaultLatLng = "",
  inputType = "text",
}) {
  const [updateOrganization, { loading }] = useMutation(
    UPDATE_ORGANIZATION(selectionSet),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Organization.UpdateOrgBio",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );
  const [value, setValue] = useState(defaultValue);
  const [location, setLocation] = useState(defaultLocation);
  const [city, setCity] = useState(defaultCity);
  const [state, setState] = useState(defaultState);
  const [country, setCountry] = useState(defaultCountry);
  const [latLng, setLatLng] = useState(defaultLatLng);

  return (
    <form
      className="edit-bio-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (loading) return;
        const formData = vars(value, location, {
          city,
          state,
          country,
          latLng,
        });

        // console.log("Form data:", formData);
        if (formData) {
          updateOrganization({
            variables: {
              where: { id },
              data: formData,
            },
            update: () => {
              setIndex(0);
            },
          });
        }
      }}
    >
      <div className="edit-bio-form-label">Edit {label}</div>
      <div className="edit-bio-form-body">
        {inputType === "location" ? (
          <LocationSearch
            defaultSearchString={[location?.label, location?.value]
              .filter((x) => x)
              .join(",")}
            onLocationSelect={({ address, setSearchString }) => {
              setSearchString(address);
              geocodeByAddress(address)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  const locTokens = address.split(",");
                  const newLocation = {
                    lat: latLng.lat,
                    lng: latLng.lng,
                    label: locTokens[0].trim(),
                    value: locTokens.splice(1, locTokens.length).join(","),
                  };
                  setLocation(newLocation);
                })
                .catch((error) => console.error("Error", error));
            }}
            placeholder={"Search address"}
            searchOptions={{
              componentRestrictions: {
                country: "IN",
              },
            }}
            inputClass={"organization-address-input"}
          />
        ) : (
          <Fragment>
            {inputType === "city" ? (
              <LocationSearch
                defaultSearchString={[city, state, country]
                  .filter((x) => x)
                  .join(", ")}
                onLocationSelect={({ address, setSearchString }) => {
                  const city = address?.split(",")[0].trim();
                  const state = address?.split(",")[1].trim();
                  const country = address?.split(",")[2].trim();
                  setSearchString(city);
                  setCity(city);
                  setState(state);
                  setCountry(country);
                  geocodeByAddress(address)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                      setLatLng(latLng);
                    })
                    .catch((error) => console.error("Error", error));
                }}
                placeholder={"Search city"}
              />
            ) : (
              <input
                type={inputType}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            )}
          </Fragment>
        )}
        {loading ? (
          <div
            style={{
              width: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader theme={"blue small"} />
          </div>
        ) : (
          <Fragment>
            <button type="submit" className="submit">
              <CheckIcon className="icon" />
            </button>
            <button
              onClick={() => {
                setIndex(0);
              }}
              className="cancel"
            >
              <TimesIcon className="icon" />
            </button>
          </Fragment>
        )}
      </div>
    </form>
  );
}

function OrgSummaryCard({
  id,
  organization,
  profilePhoto,
  setSelectedTabIndex,
  canManagePage,
}) {
  const [, setAppState] = useAppState();
  const location = useLocation();
  const history = useHistory();
  const { loading, error, data } = useQuery(INTERACTION_STATS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.OrgSummaryCard",
        query: "INTERACTION_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="profile-summary-card">
        <div className="profile-summary-card__image-wrapper">
          <div className="image"></div>
        </div>

        <div className="stats">
          <div className="stat-item">
            <div className="value">
              <div className="placeholder" />
            </div>
            <div className="key">
              <Placeholder style={{ width: "30px" }} pulse={true} />
            </div>
          </div>

          <div className="stat-item">
            <div className="value">
              <div className="placeholder" />
            </div>
            <div className="key">
              <Placeholder style={{ width: "30px" }} pulse={true} />
            </div>
          </div>

          <div className="stat-item">
            <div className="value">
              <div className="placeholder" />
            </div>
            <div className="key">
              <Placeholder style={{ width: "30px" }} pulse={true} />
            </div>
          </div>
        </div>
        <Placeholder
          style={{
            background: "var(--blue-subtle)",
            height: "32px",
            width: "100%",
            borderRadius: "8px",
          }}
          pulse={true}
        />
      </Card>
    );
  }

  const interactionStats = data.interactionStats;
  // console.log("Interaction Stats:", interactionStats);

  return (
    <Card className="profile-summary-card">
      <div className="profile-summary-card__image-wrapper">
        <div
          className="image"
          onClick={() => {
            console.log("Launching phtot");
            setAppState({
              modal: (
                <PhotoBrowser
                  query={ORGANIZATION}
                  vars={{ id }}
                  getPhotos={(data) => data.organization?.profilePhotos || []}
                  totalPhotos={(data) =>
                    data.organization?.profilePhotos?.length || 0
                  }
                  media={profilePhoto}
                />
              ),
            });
          }}
        >
          {profilePhoto ? (
            <Avatar media={profilePhoto} />
          ) : (
            <TeamIcon style={{ fill: "var(--light-1)", height: "64px" }} />
          )}
          {profilePhoto && <div className="overlay" />}
        </div>

        {canManagePage && (
          <div
            className="edit-profile-photo-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <SelectProfilePhoto
                    organization={organization}
                    canManagePage={canManagePage}
                  />
                ),
              });
            }}
          >
            <PencilIcon className="icon" />
          </div>
        )}
      </div>

      <div className="profile-summary-card__handle">@{organization.handle}</div>

      <div className="stats">
        <div
          className="stat-item"
          onClick={() => {
            history.replace(location.pathname + "?t=posts");
          }}
        >
          <div className="value">{interactionStats.posts}</div>
          <div className="key">Posts</div>
        </div>

        <div
          className="stat-item"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`${organization.name}'s Followers`}
                  query={FOLLOWERS}
                  variables={{ id }}
                  getData={((d) => d?.followers) || []}
                  noData={() => (
                    <div className="no-data">
                      <div>{organization.name} has no followers yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.organization?.handle || item.profile?.handle
                      ? "@" +
                        (item.organization?.handle || item.profile?.handle)
                      : ""
                  }
                  totalItems={interactionStats.followers}
                />
              ),
            });
          }}
        >
          <div className="value">{interactionStats.followers}</div>
          <div className="key">Fans</div>
        </div>

        <div
          className="stat-item"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`${organization.name}'s Members`}
                  query={ORGANIZATION_MEMBERS}
                  variables={{ id, where: { toDate: null, approved: true } }}
                  fetchPolicy={"network-only"}
                  getData={((d) => d?.organization?.members) || []}
                  noData={() => (
                    <div className="no-data">
                      <div>{organization.name} has no members yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.profile?.handle ? "@" + item.profile.handle : ""
                  }
                  totalItems={interactionStats.memberships}
                />
              ),
            });
          }}
        >
          <div className="value">{interactionStats.memberships || 0}</div>
          <div className="key">Members</div>
        </div>
      </div>
      <FollowButton
        id={id}
        type={"organization"}
        onFollow={(cache) => {
          const q = cache.readQuery({
            query: INTERACTION_STATS,
            variables: { id },
          });
          cache.writeQuery({
            query: INTERACTION_STATS,
            variables: { id },
            data: {
              interactionStats: {
                ...q.interactionStats,
                followers: q.interactionStats.followers + 1,
              },
            },
          });
        }}
        onUnfollow={(cache) => {
          const q = cache.readQuery({
            query: INTERACTION_STATS,
            variables: { id },
          });
          cache.writeQuery({
            query: INTERACTION_STATS,
            variables: { id },
            data: {
              interactionStats: {
                ...q.interactionStats,
                followers: q.interactionStats.followers - 1,
              },
            },
          });
        }}
      />
    </Card>
  );
}

function OrgBioCard({ id, canManagePage }) {
  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };
  const { loading, error, data } = useQuery(ORGANIZATION_BIO, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.OrgBioCard",
        query: "ORGANIZATION_BIO",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="user-bio-card">
        <div className="info-group row">
          <Placeholder style={{ width: "100%" }} pulse={true} />
        </div>

        <div className="info-group row">
          <Placeholder style={{ width: "80%" }} pulse={true} />
        </div>

        <div className="info-group row">
          <Placeholder style={{ width: "100%" }} pulse={true} />
        </div>
      </Card>
    );
  }

  const organization = data.organization;
  // console.log("BIO:", organization);

  return (
    <Card className="user-bio-card">
      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group row">
                  <div className="key">Founded</div>
                  <div className="value">
                    {organization.founded
                      ? moment(organization.founded).year()
                      : "-"}
                  </div>
                  {canManagePage && (
                    <div
                      className="edit-bio-btn"
                      onClick={() => {
                        setIndex(1);
                      }}
                    >
                      <PencilIcon className="icon" />
                    </div>
                  )}
                </div>
              );
            case 1:
              return (
                <UpdateOrgBio
                  setIndex={setIndex}
                  label={"founded"}
                  id={id}
                  selectionSet={`founded`}
                  defaultValue={organization.founded || ""}
                  vars={(val) => ({ founded: moment(val).toISOString() })}
                  inputType={"date"}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group row">
                  <div className="key">Phone</div>
                  <div className="value">{organization.phone || "-"}</div>
                  {canManagePage && (
                    <div
                      className="edit-bio-btn"
                      onClick={() => {
                        setIndex(1);
                      }}
                    >
                      <PencilIcon className="icon" />
                    </div>
                  )}
                </div>
              );
            case 1:
              return (
                <UpdateOrgBio
                  setIndex={setIndex}
                  label={"phone"}
                  id={id}
                  selectionSet={`phone`}
                  defaultValue={organization.phone || ""}
                  vars={(val) => ({ phone: val })}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group row">
                  <div className="key">Email</div>
                  <div className="value">{organization.email || "-"}</div>
                  {canManagePage && (
                    <div
                      className="edit-bio-btn"
                      onClick={() => {
                        setIndex(1);
                      }}
                    >
                      <PencilIcon className="icon" />
                    </div>
                  )}
                </div>
              );
            case 1:
              return (
                <UpdateOrgBio
                  setIndex={setIndex}
                  label={"email"}
                  id={id}
                  selectionSet={`email`}
                  defaultValue={organization.email || ""}
                  vars={(val) => ({ email: val })}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group vertical">
                  <div className="key">
                    Interests{" "}
                    {canManagePage && (
                      <div
                        className="edit-bio-btn"
                        onClick={() => {
                          setIndex(1);
                        }}
                      >
                        <PencilIcon className="icon" />
                      </div>
                    )}
                  </div>
                  <div className="value grid">
                    {organization.interests.map((i, j) => (
                      <div key={"org-interests-" + j + i} className="interest">
                        <div className="icon">
                          {sportsIcons[i.name] || (
                            <EllipsisIcon className="sports-icon-svg" />
                          )}
                        </div>
                        <div className="desc">{i.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            case 1:
              return (
                <UpdateOrganizationInterests
                  setIndex={setIndex}
                  id={id}
                  defaultInterests={organization.interests}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group vertical">
                  <div className="key">
                    Locations{" "}
                    {canManagePage && (
                      <div
                        className="edit-bio-btn"
                        onClick={() => {
                          setIndex(1);
                        }}
                      >
                        <PencilIcon className="icon" />
                      </div>
                    )}
                  </div>
                  <div className="value grid">
                    {organization.cities?.map((i, j) => (
                      <div
                        key={"org-bio-card-cities-" + i.value + j}
                        className="interest"
                      >
                        <div className="icon">
                          <BuildingIcon className="sports-icon-svg" />
                        </div>
                        <div className="desc">{i.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            case 1:
              return (
                <Fragment>
                  <UpdateOrganizationLocations
                    setIndex={setIndex}
                    id={id}
                    defaultLocations={organization.cities}
                  />
                </Fragment>
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group vertical">
                  <div className="map" style={{ marginBottom: 16 }}>
                    <MapContainer
                      venue={{
                        label: organization.locationLabel,
                        value: organization.locationValue,
                        lat: organization.locationLat,
                        lng: organization.locationLng,
                      }}
                    />
                  </div>
                  <div className="value address">
                    <div className="text">
                      {organization.locationValue || "No address added"}
                    </div>
                    {canManagePage && (
                      <div
                        className="edit-bio-btn"
                        onClick={() => {
                          setIndex(1);
                        }}
                      >
                        <PencilIcon className="icon" />
                      </div>
                    )}
                  </div>
                </div>
              );
            case 1:
              return (
                <UpdateOrgBio
                  setIndex={setIndex}
                  label={"address"}
                  id={id}
                  selectionSet={`locationValue locationLabel locationLat locationLng`}
                  defaultValue={organization.locationValue || ""}
                  defaultLocation={{
                    lat: organization.locationLat,
                    lng: organization.locationLng,
                    label: organization.locationLabel,
                    value: organization.locationValue,
                  }}
                  inputType={"location"}
                  vars={(val, location) => {
                    // console.log(location);
                    return {
                      locationValue: location.value,
                      locationLabel: location.label,
                      locationLat: location.lat,
                      locationLng: location.lng,
                    };
                  }}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>
    </Card>
  );
}

function Organization() {
  usePageTracking({ title: "Organization" });
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const tab = query.get("t") || "overview";
  const selectedTab = organizationTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;
  const [, setAppState] = useAppState();

  const authQuery = useQuery(CAN_MANAGE_PAGE, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id },
  });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Organization",
        query: "CAN_MANAGE_PAGE",
      },
    });
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "400px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const org = data.organization;
  const canManagePage = authQuery?.data?.canManagePage?.authorized;

  return (
    <div className="App-content">
      <div className="profile-page">
        <div className="cover">
          {org?.coverPhoto && <Avatar media={org.coverPhoto} />}
          {org?.coverPhoto && (
            <div
              className="overlay"
              onClick={() => {
                // console.log("Launching phtot");
                setAppState({
                  modal: (
                    <PhotoBrowser
                      query={ORGANIZATION}
                      vars={{ id }}
                      getPhotos={(data) => data.organization?.coverPhotos || []}
                      totalPhotos={(data) =>
                        data.organization?.coverPhotos?.length || 0
                      }
                      media={org.coverPhoto}
                    />
                  ),
                });
              }}
            />
          )}
          {canManagePage && (
            <div
              className="edit-cover-btn"
              onClick={() => {
                setAppState({
                  modal: (
                    <SelectCoverPhoto
                      organization={org}
                      canManagePage={canManagePage}
                    />
                  ),
                });
              }}
            >
              <PencilIcon className="icon" /> Edit cover photo
            </div>
          )}
        </div>
        <div className="profile-name">{org.name}</div>
        <div className="tabs">
          {organizationTabs.map((t, i) => (
            <div
              key={"org-tab-" + i}
              className={
                "tab-item hover-pointer " +
                (selectedTab.key === t.key ? "active" : "")
              }
              onClick={() => {
                history.replace(location.pathname + "?t=" + t.key);
              }}
            >
              {t.label}
            </div>
          ))}
        </div>
        <div className="body">
          <div className="sidebar">
            <OrgSummaryCard
              id={id}
              organization={org}
              profilePhoto={org.profilePhoto}
              canManagePage={canManagePage}
            />

            <OrgBioCard id={id} canManagePage={canManagePage} />
          </div>
          <div className="main">
            <SelectedComponent
              id={id}
              canManagePage={canManagePage}
              organization={org}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organization;
