import { gql } from "@apollo/client";

const EVENT_REGISTRATIONS = gql`
  query eventQuery($id: ID!, $where: EventRegistrationWhereInput) {
    event(id: $id) {
      id
      participantType
      registrations(where: $where) {
        id
        amountPaid
        paid
        jerseyNum
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

export default EVENT_REGISTRATIONS;
