import Card from "../../../components/Card";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import "./AddBall.css";
import Button from "../../../components/Button";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import SelectPlayer from "../SelectPlayer";
import Loader from "../../../components/Loader";
import { useMutation, gql } from "@apollo/client";
import { CREATE_CRICKET_STAT } from "../../../api/fixture";
import captureException from "../../../utils/captureException";
import makeStat from "../utils/makeStat";
import { client } from "../../../utils/client";
import { FIXTURE } from "../../../api/functions";

export default function AddBall() {
  const [
    {
      currentOverNumber,
      setOverlay,
      battingTeam,
      bowlingTeam,
      fixtureId,
      selectedPeriod,
      eventId,
      statTypes,
    },
  ] = useCricketScoringState();
  const [editing, setEditing] = useState(false);
  const [ballNumber, setBallNumber] = useState("");
  const [batsman, setBatsman] = useState("");
  const [bowler, setBowler] = useState("");

  const [createCricketStat, { loading }] = useMutation(CREATE_CRICKET_STAT, {
    update: async (cache, { data: { createCricketStat } }) => {
      cache.modify({
        id: "CricketLiveScoringStats:" + fixtureId,
        fields: {
          overStats(existingRefs, { readField }) {
            const newRef = cache.writeFragment({
              data: createCricketStat,
              fragment: gql`
                fragment NewOverItem on OverItem {
                  id
                  over
                  ballsBowled {
                    id
                    value
                    profile {
                      id
                      name
                    }
                    organization {
                      id
                      name
                    }
                  }
                  ballsFaced {
                    id
                  }
                  runsScored {
                    id
                    value
                    profile {
                      id
                      name
                    }
                    organization {
                      id
                      name
                    }
                  }
                  runsConceded {
                    id
                    value
                    profile {
                      id
                      name
                    }
                  }
                  wicket {
                    id
                    statType {
                      id
                      name
                    }
                    profile {
                      id
                    }
                    organization {
                      id
                    }
                  }
                  wicketTaken {
                    id
                    profile {
                      id
                    }
                    organization {
                      id
                    }
                  }
                  extras {
                    id
                    statType {
                      id
                      name
                    }
                    value
                  }
                  fielding {
                    id
                    profile {
                      id
                    }
                  }
                }
              `,
            });

            return [...existingRefs, newRef].sort((a, b) => {
              const c = readField("ballsBowled", a);
              const d = readField("ballsBowled", b);

              const x = readField("value", c);
              const y = readField("value", d);
              console.log("Sorting");
              console.log(x);
              console.log(y);
              return x - y;
            });
          },
        },
      });

      // console.log("RETURNED OVER ITEM:", createCricketStat);

      cache.modify({
        id: "CricketBattingSummaryItem:" + batsman.id,
        fields: {
          balls(currentBalls) {
            return currentBalls + 1;
          },
        },
      });

      cache.modify({
        id: "CricketBowlingSummaryItem:" + bowler.id,
        fields: {
          balls(currentBalls) {
            return currentBalls + 1;
          },
        },
      });

      // console.log("Fetching updated period score");
      const updatedPeriodScores = await client.query({
        query: FIXTURE({
          selectionSet: `                    
          cricketLiveScoring{
            id
            periodScores{
              id
              runs
              wickets
              overs
            }        
          }
      `,
        }),
        variables: {
          id: fixtureId,
        },
        fetchPolicy: "network-only",
      });
      // console.log("Updated period score:", updatedPeriodScores);
      // Update period scores
      cache.modify({
        id: "CricketLiveScoringStats:" + fixtureId,
        fields: {
          periodScores(current) {
            return updatedPeriodScores.data.fixture.cricketLiveScoring
              .periodScores;
          },
        },
      });

      setEditing(false);
      setBatsman(false);
      setBallNumber("");
      setBowler(false);

      // onAddStat();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.RunContainer",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const dismiss = () => {
    setEditing(false);
  };

  if (editing) {
    return (
      <div className="ScoringApp-cricket__scoring__add-form__wrapper">
        <Card className="ScoringApp-cricket__scoring__add-form">
          <div
            className="ScoringApp-cricket__scoring__add-form__title"
            style={{}}
          >
            <div
              className="ScoringApp-cricket__scoring__add-form__title__text"
              style={{}}
            >
              Add a ball to over {currentOverNumber + 1}
            </div>

            <div
              className="ScoringApp-cricket__scoring__add-form__title__dismiss"
              onClick={dismiss}
            >
              <TimesIcon className="ScoringApp-cricket__scoring__add-form__title__dismiss__icon " />
            </div>
          </div>

          <form
            className="ScoringApp-cricket__scoring__add-form__body"
            onSubmit={(e) => {
              e.preventDefault();
              if (loading) return;
              if (!batsman?.id || !bowler?.id || !ballNumber) {
                return;
              }

              const runsScoredStat = makeStat({
                eventId,
                fixtureId,
                fixturePeriodId: selectedPeriod?.id,
                organization: battingTeam,
                profile: batsman,
                statType: statTypes.find((s) => s.name === "Runs Scored"),
                value: 0,
              });

              const ballsFacedStat = makeStat({
                eventId,
                fixtureId,
                fixturePeriodId: selectedPeriod?.id,
                organization: battingTeam,
                profile: batsman,
                statType: statTypes.find((s) => s.name === "Balls Faced"),
                value: 1,
              });

              const ballsBowledStat = makeStat({
                eventId,
                fixtureId,
                fixturePeriodId: selectedPeriod?.id,
                organization: bowlingTeam,
                profile: bowler,
                statType: statTypes.find((s) => s.name === "Balls Bowled"),
                value: Number(ballNumber),
                stringValue: ballNumber + "",
              });

              const runsConcededStat = makeStat({
                eventId,
                fixtureId,
                fixturePeriodId: selectedPeriod?.id,
                organization: bowlingTeam,
                profile: bowler,
                statType: statTypes.find((s) => s.name === "Runs Conceded"),
                value: 0,
              });

              const runsScoredRelationships = [];
              const ballsBowledRelationships = [{ ...runsConcededStat }];

              ballsBowledStat.relationshipsFrom = {
                create: ballsBowledRelationships.map((r) => ({
                  to: { create: r },
                })),
              };

              runsScoredRelationships.push(ballsFacedStat);
              runsScoredRelationships.push(ballsBowledStat);

              runsScoredStat.relationshipsFrom = {
                create: runsScoredRelationships.map((r) => ({
                  to: { create: r },
                })),
              };

              createCricketStat({
                variables: {
                  data: runsScoredStat,
                },
              });
            }}
          >
            <div className="ScoringApp-cricket__scoring__add-form__body__group">
              <div className="ScoringApp-cricket__scoring__add-form__body__group__label">
                Ball Number
              </div>

              <div className="ScoringApp-cricket__scoring__add-form__body__group__input">
                <input
                  type={"number"}
                  value={ballNumber}
                  onChange={(e) => {
                    setBallNumber(e.target.value);
                  }}
                  step="0.1"
                  min={currentOverNumber + 0.1}
                  max={currentOverNumber + 0.9}
                />
              </div>
            </div>

            <div className="ScoringApp-cricket__scoring__add-form__body__group">
              <div className="ScoringApp-cricket__scoring__add-form__body__group__label">
                Batsman
              </div>

              <div className="ScoringApp-cricket__scoring__add-form__body__group__input">
                <div
                  className="ScoringApp-cricket__scoring__add-form__body__group__input__container"
                  onClick={() => {
                    if (batsman) {
                      setBatsman(false);
                    } else {
                      setOverlay(
                        <SelectPlayer
                          title="Select Batsman"
                          onSelect={(player) => {
                            setBatsman(player.profile);
                            setOverlay(false);
                          }}
                          defaultTeam={battingTeam}
                          hideTabs={true}
                        />
                      );
                    }
                  }}
                >
                  {batsman ? (
                    <div className="ScoringApp-cricket__scoring__add-form__body__group__input__container__value">
                      {batsman.name}
                    </div>
                  ) : (
                    <div className="ScoringApp-cricket__scoring__add-form__body__group__input__container__placeholder">
                      Select Batsman
                    </div>
                  )}
                  {batsman && (
                    <div className="ScoringApp-cricket__scoring__add-form__body__group__input__container__dismiss">
                      <TimesIcon className="ScoringApp-cricket__scoring__add-form__body__group__input__container__dismiss__icon" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="ScoringApp-cricket__scoring__add-form__body__group">
              <div className="ScoringApp-cricket__scoring__add-form__body__group__label">
                Bowler
              </div>

              <div className="ScoringApp-cricket__scoring__add-form__body__group__input">
                <div
                  className="ScoringApp-cricket__scoring__add-form__body__group__input__container"
                  onClick={() => {
                    if (bowler) {
                      setBowler(false);
                    } else {
                      setOverlay(
                        <SelectPlayer
                          title="Select Bowler"
                          onSelect={(player) => {
                            setBowler(player.profile);
                            setOverlay(false);
                          }}
                          defaultTeam={bowlingTeam}
                          hideTabs={true}
                        />
                      );
                    }
                  }}
                >
                  {bowler ? (
                    <div className="ScoringApp-cricket__scoring__add-form__body__group__input__container__value">
                      {bowler.name}
                    </div>
                  ) : (
                    <div className="ScoringApp-cricket__scoring__add-form__body__group__input__container__placeholder">
                      Select Bowler
                    </div>
                  )}

                  {bowler && (
                    <div className="ScoringApp-cricket__scoring__add-form__body__group__input__container__dismiss">
                      <TimesIcon className="ScoringApp-cricket__scoring__add-form__body__group__input__container__dismiss__icon" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ScoringApp-cricket__scoring__add-form__body__group">
              <button
                className="app-button primary green medium ScoringApp-cricket__scoring__add-form__submit"
                style={{ marginTop: "12px", width: "100%", border: "none" }}
              >
                {loading ? <Loader theme={"small"} /> : "Submit"}
              </button>
            </div>
          </form>
        </Card>
      </div>
    );
  }

  return (
    <div className="ScoringApp-cricket__scoring__add-ball-btn-wrapper">
      <Button
        className="primary blue medium ScoringApp-cricket__scoring__add-ball-btn-wrapper__btn"
        onClick={() => {
          setEditing(true);
        }}
      >
        Add a ball
      </Button>
    </div>
  );
}
