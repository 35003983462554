import "./FootballStats.css";
import Card from "../../../Card";
import Pitch from "../../../Pitch";
import PieChart from "../../../PieChart";
import Square from "../../../../mobileComponents/Square";
import VerticalText from "../../../../mobileComponents/VerticalText";
import {
  PROFILE_STATS,
  PROFILE_AWARDS,
  GET_CACHED_PROFILE_STATS,
  SYNC_CACHED_PROFILE_STATS,
} from "../../../../api/profile";
import { useQuery } from "@apollo/client";
// import ProfileAwards from "../ProfileAwards/ProfileAwards";
import { Link } from "react-router-dom";
import makeFileUrl from "../../../../utils/makeFileUrl";
import captureException from "../../../../utils/captureException";
import { Fragment } from "react";
import Spinner from "../../../Spinner";
import moment from "moment";

function SyncProfileStats({ updatedAt, profileId, sport, events }) {
  const { loading, error } = useQuery(SYNC_CACHED_PROFILE_STATS, {
    variables: {
      profileId,
      sport,
      events,
    },
    onCompleted: (data) => {
      console.log("Synced data:", data);
      // Update the cache
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.FootballStats",
        query: "SYNC_CACHED_PROFILE_STATS",
      },
    });
    console.log(error);
  }

  return (
    <div className="profile-stats-sync-container">
      <div className="profile-stats-sync-container__status">
        {loading ? (
          <Fragment>
            <Spinner size={12} />
            <span>Checking for updates...</span>
          </Fragment>
        ) : (
          ""
        )}
      </div>

      <div className="profile-stats-sync-container__last-updated">
        Last synced {moment(updatedAt).fromNow()}
      </div>
    </div>
  );
}

function ProfileAttackStats({
  appearances,
  goals,
  assists,
  keyPasses,
  shotsPerGame,
  minutesPerGame,
}) {
  return (
    <div className="profile-attack-stats no-margin">
      <div className="profile-attack-stats__title">ATTACK</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",

                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {appearances}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Appearances
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",

                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {goals}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Goals
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",

                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {assists}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Assists
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {keyPasses}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Key Passes
              </div>
            </div>
          )}
        </Square>

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {minutesPerGame}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Mins / Game
              </div>
            </div>
          )}
        </Square> */}

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {shotsPerGame}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Shots / Game
              </div>
            </div>
          )}
        </Square> */}
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"ATTACK"}
              fontStyle={{
                fontSize: "48px",
                letterSpacing: "-4px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "48px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileShootingStats({
  goals,
  totalShots,
  shotsOnTarget,
  shotsOffTarget,
}) {
  return (
    <div className="profile-shooting-stats">
      <div className="profile-shooting-stats__title">SHOOTING</div>

      <div className="profile-shooting-stats__items">
        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Total Shots
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {totalShots || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{ width: totalShots ? "100%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Shots On Target
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {shotsOnTarget || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: totalShots
                    ? (shotsOnTarget / totalShots) * 100 + "%"
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Shots Off Target
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {shotsOffTarget || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: totalShots
                    ? (shotsOffTarget / totalShots) * 100 + "%"
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {!!totalShots && (
        <div className="profile-shooting-stats__doughnut">
          <div className="profile-shooting-stats__doughnut__bg">
            <PieChart
              value={shotsOnTarget}
              total={shotsOffTarget || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={shotsOnTarget}
            total={shotsOffTarget || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats__doughnut__meta">
            <div className="profile-shooting-stats__doughnut__meta__value">
              {Math.round((shotsOnTarget / totalShots) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats__doughnut__meta__key">
              Shot <br /> Accuracy
            </div>
          </div>
        </div>
      )}

      {!!totalShots && (
        <div className="profile-shooting-stats__doughnut">
          <div className="profile-shooting-stats__doughnut__bg">
            <PieChart
              value={goals}
              total={(totalShots || 0) - (goals || 0)}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={goals}
            total={(totalShots || 0) - (goals || 0)}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats__doughnut__meta">
            <div className="profile-shooting-stats__doughnut__meta__value">
              {Math.round((goals / totalShots) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats__doughnut__meta__key">
              Conversion <br /> Rate
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ProfilePassingStats({ successfulPasses, attemptedPasses }) {
  return (
    <div className="profile-passing-stats">
      <div className="profile-passing-stats__title">PASSING</div>
      <div className="profile-passing-stats__items">
        <div className="profile-passing-stats__items__item">
          <div className="profile-passing-stats__items__item__meta">
            <div className="profile-passing-stats__items__item__meta__key">
              Attempted Passes
            </div>

            <div className="profile-passing-stats__items__item__meta__value">
              {attemptedPasses || 0}
            </div>
          </div>

          <div className="profile-passing-stats__items__item__graph">
            <div className="profile-passing-stats__items__item__graph__track">
              <div
                className="profile-passing-stats__items__item__graph__fill"
                style={{
                  width: attemptedPasses
                    ? (successfulPasses / attemptedPasses) * 100 + "%"
                    : "1%",
                }}
              ></div>
            </div>
          </div>

          <div className="profile-passing-stats__items__item__meta">
            <div className="profile-passing-stats__items__item__meta__key">
              Successful Passes
            </div>

            <div className="profile-passing-stats__items__item__meta__value">
              {successfulPasses || 0}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-passing-stats__doughnut">
        <div className="profile-passing-stats__doughnut__bg">
          <PieChart
            value={successfulPasses || 0}
            total={
              attemptedPasses ? attemptedPasses - successfulPasses || 0 : 1
            }
            colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
          />
        </div>
        <PieChart
          value={successfulPasses || 0}
          total={attemptedPasses ? attemptedPasses - successfulPasses || 0 : 1}
          colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
        />
        <div className="profile-passing-stats__doughnut__meta">
          <div className="profile-passing-stats__doughnut__meta__value">
            {attemptedPasses
              ? Math.round((successfulPasses / attemptedPasses) * 100) + "%"
              : "-"}
          </div>

          <div className="profile-passing-stats__doughnut__meta__key">
            Pass <br /> Accuracy
          </div>
        </div>
      </div>

      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-passing-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"PASSING"}
              fontStyle={{
                fontSize: "64px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "49px",
                height: "64px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfilePositionsPlayed({ positions }) {
  return (
    <div className="profile-positions-played">
      <div className="profile-positions-played__title">POSITIONS PLAYED</div>

      <div className="profile-positions-played__pitch">
        <Pitch
          markers={
            positions?.map((p) => ({
              ...p,
              label: p.positionType.shortName || "",
            })) || []
          }
        />
      </div>
    </div>
  );
}

function ProfileDefenseStats({
  tackles,
  blocks,
  saves,
  ownGoals,
  redCards,
  yellowCards,
  fouls,
  offsides,
  interceptions,
  clearances,
}) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">DEFENSE</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {tackles}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Tackles
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {blocks}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Blocks
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {saves}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Saves
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {ownGoals}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Own Goals
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {redCards}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Red Cards
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {yellowCards}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Yellow Cards
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {fouls}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Fouls
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {offsides}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Offsides
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {interceptions}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Interceptions
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {clearances}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Clearances
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"DEFENSE"}
              fontStyle={{
                fontSize: "76px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "57px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileParticipationHistory({ participationHistory = [] }) {
  const rows = participationHistory.map((p) => ({
    ...p,
    stats: JSON.parse(p.stats.data),
  }));
  return (
    <div className="profile-participation-stats">
      <div className="profile-participation-stats__title">
        PARTICIPATION HISTORY
      </div>

      <div className="profile-participation-stats__table">
        <div
          className="profile-participation-stats__table__column"
          style={{ width: "50%" }}
        >
          <div className="profile-participation-stats__table__column__header with-image">
            Event
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td with-image " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {/* <div className="profile-participation-stats__table__td__image"></div> */}
                <div className="profile-participation-stats__table__td__meta">
                  <Link
                    to={"/app/event/" + r.event?.id}
                    className="profile-participation-stats__table__td__meta__primary"
                    style={{ display: "block" }}
                  >
                    {r.event?.name}
                  </Link>

                  {r.organization ? (
                    <Link
                      to={"/app/page/" + r.organization.id}
                      className="profile-participation-stats__table__td__meta__secondary"
                    >
                      {r.organization.name}
                    </Link>
                  ) : (
                    <div className="profile-participation-stats__table__td__meta__secondary">
                      {r.organization?.name}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            GP
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Appearances"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            G
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Goal"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            A
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Assist"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            RC
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Red Card"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            YC
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Yellow Card"] || 0}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileAwards({ id, sport }) {
  const { loading, error, data } = useQuery(PROFILE_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.FootballStats.ProfileAwards",
        query: "PROFILE_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const profile = data.profile;
  const placements = profile?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats">
      <div className="profile-awards-stats__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats__items">
          <div className="no-data">No awards for this player yet.</div>
        </div>
      )}

      <div className="profile-awards-stats__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats__items__item">
              <div className="profile-awards-stats__items__item__img"></div>
              <div className="profile-awards-stats__items__item__desc">
                <div className="profile-awards-stats__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function summarizeStatsCaches(cachesArray = []) {
  return cachesArray.reduce(
    (accum, curr) => {
      const d = curr?.data;
      if (!d) {
        return accum;
      }

      const parsedData = JSON.parse(d);

      // Updated at
      if (!accum.updatedAt) {
        accum.updatedAt = curr.updatedAt;
      } else if (moment(curr.updatedAt).isAfter(accum.updatedAt)) {
        accum.updatedAt = curr.updatedAt;
      }

      // Aggregations
      const aggs = parsedData.aggregations || {};
      for (let key in aggs) {
        if (!accum.aggregations[key]) {
          accum.aggregations[key] = 0;
        }

        accum.aggregations[key] = accum.aggregations[key] + aggs[key];
      }

      // Appearances
      accum.appearances = accum.appearances + parsedData.appearances;

      // Participation History
      accum.participationHistory = [
        ...accum.participationHistory,
        ...(parsedData.participationHistory || []),
      ];

      // Positions
      accum.positions = [...accum.positions, ...parsedData.positions];

      return accum;
    },
    {
      updatedAt: "",
      aggregations: {},
      appearances: 0,
      participationHistory: [],
      positions: [],
    }
  );
}

function FootballStats({ id, events = [] }) {
  const sport = "Football";
  const { loading, error, data } = useQuery(GET_CACHED_PROFILE_STATS, {
    variables: {
      profileId: id,
      sport,
      events,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.FootballStats",
        query: "GET_CACHED_PROFILE_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "160px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  // console.log("STATS:", data);
  const caches = data?.getCachedProfileStats;
  const statsData = summarizeStatsCaches(caches);
  console.log("Cached data:", statsData);
  const {
    updatedAt,
    appearances,
    aggregations,
    positions,
    participationHistory,
  } = statsData;

  //   const { appearances, positions, participationHistory } =
  //     data?.profile?.stats || {};

  //   const statsData = JSON.parse(
  //     data?.profile?.stats?.aggregations?.data || "{}"
  //   );

  // const {
  //   goal,
  //   assists,
  //   keyPasses,
  //   shotsOnTarget,
  //   shotsOffTarget,
  //   tackles,
  //   blocks,
  //   saves,
  //   ownGoals,
  //   redCards,
  //   yellowCards,
  //   fouls,
  //   offsides,
  // } = statsData;

  //   const participationData = {
  //     headers: [
  //       {
  //         label: "Team",
  //         weight: 30,
  //         hasImage: true,
  //         image: (d) => d?.organization?.profilePhoto,
  //         link: (d) => `/app/page/${d?.organization?.id}`,
  //         data: (d) => d.organization?.name,
  //       },
  //       {
  //         label: "Event",
  //         weight: 30,
  //         link: (d) => `/app/event/${d?.event?.id}`,
  //         data: (d) => d.event?.name,
  //       },
  //       {
  //         label: "GP",
  //         weight: 8,
  //         type: "number",
  //         data: (d) => {
  //           const participationStats = JSON.parse(d.stats.data);
  //           return participationStats["Appearances"];
  //         },
  //       },
  //       {
  //         label: "G",
  //         weight: 8,
  //         type: "number",
  //         data: (d) => {
  //           const participationStats = JSON.parse(d.stats.data);
  //           return participationStats["Goal"];
  //         },
  //       },
  //       {
  //         label: "A",
  //         weight: 8,
  //         type: "number",
  //         data: (d) => {
  //           const participationStats = JSON.parse(d.stats.data);
  //           return participationStats["Assist"];
  //         },
  //       },
  //       {
  //         label: "RC",
  //         weight: 8,
  //         type: "number",
  //         data: (d) => {
  //           const participationStats = JSON.parse(d.stats.data);
  //           return participationStats["Red Card"];
  //         },
  //       },
  //       {
  //         label: "YC",
  //         weight: 8,
  //         type: "number",
  //         data: (d) => {
  //           const participationStats = JSON.parse(d.stats.data);
  //           return participationStats["Yellow Card"];
  //         },
  //       },
  //     ],
  //     // data: data?.profile?.stats?.participationHistory?.map((p) => {
  //     //   const participationStats = JSON.parse(p.stats.data);

  //     //   return [
  //     //     p.organization?.name,
  //     //     p.event?.name,
  //     //     participationStats["Appearances"],
  //     //     participationStats["Goal"],
  //     //     participationStats["Assist"],
  //     //     participationStats["Red Card"],
  //     //     participationStats["Yellow Card"],
  //     //   ];
  //     // }),
  //     data: data?.profile?.stats?.participationHistory || [],
  //   };

  // console.log("SD: ", statsData);
  return (
    <Fragment>
      <SyncProfileStats
        profileId={id}
        events={events}
        sport={sport}
        updatedAt={updatedAt}
      />

      <ProfileAttackStats
        appearances={appearances}
        minutesPerGame={
          appearances
            ? (aggregations["Minutes Played"] / appearances).toFixed(1)
            : 0
        }
        goals={aggregations["Goal"] || 0}
        assists={aggregations["Assist"] || 0}
        keyPasses={aggregations["Key Pass"] || 0}
        shotsPerGame={
          appearances
            ? (
                ((aggregations["Shot On Target"] || 0) +
                  (aggregations["Shot Off Target"] || 0)) /
                appearances
              ).toFixed(1)
            : 0
        }
      />

      <ProfileShootingStats
        goals={aggregations["Goal"] || 0}
        shotsOnTarget={aggregations["Shot On Target"] || 0}
        shotsOffTarget={aggregations["Shot Off Target"] || 0}
        totalShots={
          (aggregations["Shot Off Target"] || 0) +
          (aggregations["Shot On Target"] || 0)
        }
      />

      <div className="profile-stats-grid">
        <ProfilePassingStats
          attemptedPasses={aggregations["Pass Attempted"] || 0}
          successfulPasses={aggregations["Pass Completed"] || 0}
        />

        <ProfilePositionsPlayed positions={positions} />
      </div>

      <ProfileDefenseStats
        tackles={aggregations["Tackle"] || 0}
        blocks={aggregations["Block"] || 0}
        saves={aggregations["Save"] || 0}
        ownGoals={aggregations["Own Goal"] || 0}
        redCards={aggregations["Red Card"] || 0}
        yellowCards={aggregations["Yellow Card"] || 0}
        fouls={aggregations["Foul"] || 0}
        offsides={aggregations["Offside"] || 0}
        interceptions={aggregations["Interception"] || 0}
        clearances={aggregations["Clearance"] || 0}
      />

      <ProfileParticipationHistory
        participationHistory={participationHistory}
      />

      <ProfileAwards id={id} sport={"Football"} />
    </Fragment>
  );
}

export default FootballStats;
