import "./PhotosSummaryCard.css";
import Square from "../Square";
import { useHistory, useLocation } from "react-router-dom";
import Placeholder from "../../components/Placeholder";
import { useQuery } from "@apollo/client";
import Avatar from "../../components/Avatar";
import captureException from "../../utils/captureException";

export default function PhotosSummaryCard({
  id,
  query,
  getPhotos,
  onClick = () => {},
}) {
  const history = useHistory();
  const location = useLocation();

  const { loading, error, data } = useQuery(query, {
    variables: { id, type: "IMAGE", take: 8 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PhotosSummaryCard",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="photos-summary-mobile">
        <div className="photos-summary-mobile__header">
          <div className="photos-summary-mobile__header__title">
            <Placeholder
              style={{ height: "12px", width: "40px" }}
              pulse={true}
            />
          </div>

          <div className="photos-summary-mobile__header__link">
            <Placeholder
              style={{ height: "12px", width: "40px" }}
              pulse={true}
            />
          </div>
        </div>

        <div className="photos-summary-mobile__body">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <Square key={"card-" + i}>
              {({ squareRef, dimensions }) => (
                <div
                  ref={squareRef}
                  className="photos-summary-mobile__body__image"
                  style={{ height: dimensions.width }}
                ></div>
              )}
            </Square>
          ))}
        </div>
      </div>
    );
  }

  const photos = getPhotos(data);

  return (
    <div className="photos-summary-mobile">
      <div className="photos-summary-mobile__header">
        <div className="photos-summary-mobile__header__title">Photos</div>

        <div
          className="photos-summary-mobile__header__link"
          onClick={() => {
            history.replace(location.pathname + "?t=photos");
          }}
        >
          See All
        </div>
      </div>

      {!!photos.length ? (
        <div className="photos-summary-mobile__body">
          {photos.map((m) => (
            <Square>
              {({ squareRef, dimensions }) => (
                <div
                  ref={squareRef}
                  className="photos-summary-mobile__body__image"
                  style={{ height: dimensions.width }}
                  onClick={onClick}
                >
                  <Avatar media={m} />
                </div>
              )}
            </Square>
          ))}
        </div>
      ) : (
        <div className="no-data">No tagged photos found.</div>
      )}
    </div>
  );
}
