export default function getNames(fullname) {
  let tokens = fullname?.split(" ") || [];
  let output = {};

  output.firstname = tokens.shift();
  output.lastname = tokens.pop();
  output.middlenames = tokens;

  return output;
}
