import "./OrganizationPosts.css";
import {
  ORGANIZATION_POSTS,
  ORGANIZATION_ALBUMS,
  ORGANIZATION_PHOTOS,
  ORGANIZATION_VIDEOS,
} from "../../../api/organization";
import { useQuery } from "@apollo/client";
import Post from "../../../components/Post";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { useAppState } from "../../../utils/appState";
import CreatePost from "../../../mobileComponents/CreatePost";
import captureException from "../../../utils/captureException";
import MatchReport from "../../../components/MatchReport";
import { Fragment } from "react";

export default function OrganizationPosts({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const { error, data, fetchMore, networkStatus } = useQuery(
    ORGANIZATION_POSTS,
    {
      variables: { id, take: 25 },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationPosts",
        query: "ORGANIZATION_POSTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div
        style={{
          height: 160,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const org = data?.organization;
  const posts = org?.posts;
  return (
    <div className="organization-posts-mobile">
      {canManagePage && (
        <Button
          className="primary medium blue"
          style={{ margin: "16px" }}
          onClick={() => {
            setAppState({
              modal: (
                <div className="modal-form-mobile-wrapper">
                  <CreatePost
                    sourceId={id}
                    sourceType={"organization"}
                    mediaQueries={{
                      photos: {
                        query: ORGANIZATION_PHOTOS,
                        getPhotos: (data) => {
                          return data?.organization?.media || [];
                        },
                        getPhotosCount: (data) => {
                          return data?.organization?.photosCount || 0;
                        },
                        onUploadPhoto: (cache, createMedia) => {
                          // console.log("Updating post photo cache");
                          const q = cache.readQuery({
                            query: ORGANIZATION_PHOTOS,
                            variables: { id },
                          });

                          // console.log(
                          //   "Updating post photo cache 2:",
                          //   q.organization,
                          //   createMedia
                          // );

                          cache.writeQuery({
                            query: ORGANIZATION_PHOTOS,
                            variables: { id },
                            data: {
                              organization: {
                                ...q.organization,
                                photosCount: q.organization.photosCount + 1,
                                media: [
                                  createMedia.media,
                                  ...q.organization.media,
                                ],
                              },
                            },
                          });
                        },
                      },
                      videos: {
                        query: ORGANIZATION_VIDEOS,
                        getVideos: (data) => {
                          return data?.organization?.media || [];
                        },
                        getVideosCount: (data) => {
                          return data?.organization?.videosCount || 0;
                        },
                        onUploadVideo: (cache, createMedia) => {
                          const q = cache.readQuery({
                            query: ORGANIZATION_VIDEOS,
                            variables: { id },
                          });

                          cache.writeQuery({
                            query: ORGANIZATION_VIDEOS,
                            variables: { id },
                            data: {
                              organization: {
                                ...q.organization,
                                videosCount: q.organization.videosCount + 1,
                                media: [
                                  createMedia.media,
                                  ...q.organization.media,
                                ],
                              },
                            },
                          });
                        },
                      },
                      albums: {
                        query: ORGANIZATION_ALBUMS,
                        getAlbums: (data) => {
                          return data?.organization?.mediaSets || [];
                        },
                        getAlbumsCount: (data) => {
                          return data?.organization?.albumsCount || 0;
                        },
                      },
                    }}
                    onCreatePost={(cache, createPost) => {
                      const q = cache.readQuery({
                        query: ORGANIZATION_POSTS,
                        variables: { id },
                      });

                      // console.log("q:", q);
                      cache.writeQuery({
                        query: ORGANIZATION_POSTS,
                        variables: { id },
                        data: {
                          organization: {
                            ...q.organization,
                            posts: [createPost, ...q.organization.posts],
                          },
                        },
                      });

                      cache.modify({
                        id: cache.identify({
                          __typename: "InteractionSummary",
                          id,
                        }),
                        fields: {
                          posts(count) {
                            // console.log("Old posts count:", count);
                            return posts.length + 1;
                          },
                        },
                      });
                    }}
                  />
                </div>
              ),
            });
          }}
        >
          Create Post
        </Button>
      )}

      {!!posts?.length && (
        <div className="organization-posts-list">
          {posts.map((post) => (
            <Fragment>
              {post.postType === "REPORT" ? (
                <MatchReport post={post} />
              ) : (
                <Post
                  key={post.id}
                  post={post}
                  queryInfo={{
                    query: ORGANIZATION_POSTS,
                    variables: { id },
                    onDeleteData: (prevData, deletePost) => {
                      if (!prevData?.organization) {
                        return [];
                      }
                      return {
                        organization: {
                          ...prevData.organization,
                          posts: [...prevData.organization.posts].filter(
                            (p) => p.id !== deletePost.id
                          ),
                        },
                      };
                    },
                    onUpdateData: (prevData, updatePost) => {
                      if (!prevData?.organization) {
                        return [];
                      }

                      const postIndex = prevData.organization.posts.findIndex(
                        (p) => p.id === updatePost.id
                      );

                      if (postIndex > -1) {
                        const newPosts = [...prevData.organization.posts];
                        newPosts[postIndex] = updatePost;
                        // console.log("New updatedpost index:", postIndex);
                        // console.log("New posts array:", newPosts);
                        return {
                          organization: {
                            ...prevData.organization,
                            posts: newPosts,
                          },
                        };
                      } else {
                        return { organization: { ...prevData.organization } };
                      }
                    },
                  }}
                  options={["edit", "delete"]}
                />
              )}
            </Fragment>
          ))}

          <div className="show-more-container">
            <Button
              className="primary small blue show-more-button"
              onClick={() => {
                fetchMore({
                  variables: {
                    cursor: posts[posts.length - 1].id,
                  },
                });
              }}
            >
              {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
            </Button>
          </div>
        </div>
      )}

      {!posts?.length && (
        <div className="no-data">
          <div>No posts to show.</div>
        </div>
      )}
    </div>
  );
}
