import { gql } from "@apollo/client";

const ACCOUNT_SAVED_POSTS = gql`
  query accountRegistrations {
    userAccount {
      savedPosts {
        id
        createdAt
        sourceType
        isSaved
        clapCount
        isClapped
        source {
          id
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          event {
            id
            name
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          fixture {
            id
            event {
              id
              name
              organizedBy {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
        author {
          id
          name
        }
        content
        truncatedContent
        contentLength
        tags {
          id
          media {
            id
            type
            filename
            isExternal
          }
          mediaSet {
            id
            preview {
              id
              filename
            }
          }
        }
      }
    }
  }
`;

export default ACCOUNT_SAVED_POSTS;
