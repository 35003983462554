// V1
import "./HeadToHead.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { FIXTURES } from "../../../api/fixture";
import Spinner from "../../Spinner";
import FixtureListItem from "../../FixtureListItem";
import { Link } from "react-router-dom";

export default function HeadToHead({
  homeParticipant,
  awayParticipant,
  sport,
}) {
  const { loading, error, data } = useQuery(FIXTURES, {
    variables: {
      where: {
        roles: {
          some: {
            type: { name: "PARTICIPANT" },
            profile: { id: homeParticipant.id },
          },
        },
        roles: {
          some: {
            type: { name: "PARTICIPANT" },
            profile: { id: awayParticipant.id },
          },
        },
        event: { sport: { name: sport } },
        completed: true,
      },
    },
  });

  if (error) {
    console.log(error);
  }

  if (loading || !data) {
    return (
      <Card>
        <Spinner />
      </Card>
    );
  }

  const fixtures = data?.fixtures;

  return (
    <Card className="head-to-head">
      <div className="card-title">Head To Head</div>
      {!loading && !fixtures.length && (
        <div className="no-data">
          No previous fixtures between these participants.
        </div>
      )}
      {fixtures.map((fixture) => (
        <Link to={"/app/fixture/" + fixture.id}>
          <FixtureListItem fixture={fixture} />
        </Link>
      ))}
    </Card>
  );
}
