import { useQuery, useMutation } from "@apollo/client";
import "./Status.css";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/functions";
import Loader from "../../../components/Loader";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import { useState } from "react";
import SelectWithoutSearch from "../../../components/SelectWithoutSearch/SelectWithoutSearch";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import oversToBalls from "../utils/oversToBalls";
import ballsToOvers from "../utils/ballsToOvers";

function FirstInningsStatus({ status, metas, participants }) {
  const tossWinnerMeta = metas.find((m) => m.label === "tossWinner");
  const tossDecisionMeta = metas.find((m) => m.label === "tossDecision");
  const tossWinner = participants?.find(
    (p) => p.organization.id === tossWinnerMeta?.stringValue
  );
  const statement = tossWinner
    ? tossWinner.organization?.name +
      " won the toss" +
      (tossDecisionMeta ? " and " + tossDecisionMeta.stringValue : ".")
    : "-";

  if (!tossWinner) {
    return <div>No toss result added in settings.</div>;
  }
  return <div>{statement}</div>;
}

function SecondInningsStatus({ status, metas, liveScores }) {
  const { targetOvers, targetRuns, participant } = status;
  const ballsPerOverMeta = metas.find((m) => m.label === "ballsPerOver");
  const ballsPerOver = ballsPerOverMeta?.value;
  const liveScoreItem = liveScores.find((s) => s.id === participant?.id);
  const currentRuns = liveScoreItem?.runs || 0;
  const currentOvers = liveScoreItem?.overs || 0;
  const currentBalls = oversToBalls(currentOvers, ballsPerOver);
  const targetBalls = oversToBalls(targetOvers, ballsPerOver);

  const runsNeeded = targetRuns - currentRuns;
  const ballsAvailable = Math.max(targetBalls - currentBalls, 0);
  const oversAvailable = ballsToOvers(ballsAvailable, ballsPerOver);

  return (
    <div>
      {participant.name} needs {runsNeeded} runs in {oversAvailable} overs to
      win
    </div>
  );
}

function CustomStatus({ status }) {
  return <div></div>;
}

function EditSecondInningsStatusDetails({ statusMeta, participants }) {
  const [{ setOverlay, fixtureId }] = useCricketScoringState();
  const [runs, setRuns] = useState("");
  const [participant, setParticipant] = useState("");
  const [overs, setOvers] = useState("");
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
          metas{
            id
            label
            value
            stringValue
        }
          `,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            metas() {
              return updateFixture.metas;
            },
          },
        });

        setOverlay(false);
      },
    }
  );

  const dismiss = () => {
    setOverlay(false);
  };

  return (
    <div className="ScoringApp-cricket__scoring__modal-form">
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        Target details
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={dismiss}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <form
        className="ScoringApp-cricket__scoring__modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();
          if (!runs || !overs || !participant) {
            return;
          }

          const metaVars = {};
          if (statusMeta?.id) {
            metaVars.update = {
              where: { id: statusMeta.id },
              data: {
                stringValue: JSON.stringify({
                  type: "SECOND_INNINGS",
                  targetOvers: overs,
                  targetRuns: runs,
                  participant: {
                    id: participant.id,
                    name: participant.name,
                  },
                }),
              },
            };
          } else {
            metaVars.create = {
              label: "status",
              stringValue: JSON.stringify({
                type: "SECOND_INNINGS",
                targetOvers: overs,
                targetRuns: runs,
                participant: {
                  id: participant.id,
                  name: participant.name,
                },
              }),
            };
          }

          updateFixture({
            variables: {
              where: { id: fixtureId },
              data: {
                metas: metaVars,
              },
            },
          });
        }}
      >
        <div className="ScoringApp-cricket__scoring__modal-form__body__group">
          <div className="ScoringApp-cricket__scoring__modal-form__body__group__label">
            Select Team
          </div>

          <div className="ScoringApp-cricket__scoring__modal-form__body__group__input">
            <SelectWithoutSearch
              items={participants}
              onItemSelect={(item) => {
                setParticipant(item);
              }}
            />
          </div>
        </div>

        <div className="ScoringApp-cricket__scoring__modal-form__body__group">
          <div className="ScoringApp-cricket__scoring__modal-form__body__group__label">
            Runs Target
          </div>

          <div className="ScoringApp-cricket__scoring__modal-form__body__group__input">
            <input
              type="number"
              step="1"
              min="0"
              value={runs}
              onChange={(e) => {
                setRuns(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="ScoringApp-cricket__scoring__modal-form__body__group">
          <div className="ScoringApp-cricket__scoring__modal-form__body__group__label">
            Overs Target
          </div>

          <div className="ScoringApp-cricket__scoring__modal-form__body__group__input">
            <input
              type="number"
              step="0.1"
              min="0.1"
              value={overs}
              onChange={(e) => {
                setOvers(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="ScoringApp-cricket__scoring__modal-form__body__group__submit">
          <button className="app-button primary green medium ScoringApp-cricket__scoring__modal-form__body__group__submit__btn">
            {loading ? <Loader theme="small" /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

function EditStatus({ statusMeta, participants }) {
  const [{ setOverlay, fixtureId }] = useCricketScoringState();
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
          metas{
            id
            label
            value
            stringValue
        }
          `,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            metas() {
              return updateFixture.metas;
            },
          },
        });
      },
    }
  );

  const options = [
    {
      label: "First Innings",
      onClick: () => {
        const metaVars = {};
        if (statusMeta?.id) {
          metaVars.update = {
            where: { id: statusMeta.id },
            data: {
              stringValue: JSON.stringify({
                type: "FIRST_INNINGS",
              }),
            },
          };
        } else {
          metaVars.create = {
            label: "status",
            stringValue: JSON.stringify({
              type: "FIRST_INNINGS",
            }),
          };
        }

        updateFixture({
          variables: {
            where: { id: fixtureId },
            data: {
              metas: metaVars,
            },
          },
        });
      },
    },
    {
      label: "Second Innings",
      onClick: () => {
        setOverlay(
          <EditSecondInningsStatusDetails
            statusMeta={statusMeta}
            participants={participants}
          />
        );
      },
    },
  ];
  return (
    <NewDropdown
      target={
        <div className="ScoringApp-cricket__scoring__status__edit">
          {loading ? <Loader theme="small" /> : "Edit status"}
        </div>
      }
      menu={({ setShowMenu }) => (
        <Card className="ScoringApp-cricket__scoring__status__edit__menu">
          {options.map((option) => (
            <div
              className="ScoringApp-cricket__scoring__status__edit__menu__item"
              onClick={() => {
                option.onClick();
                setShowMenu(false);
              }}
            >
              {option.label}
            </div>
          ))}
          {!!statusMeta?.id && (
            <div
              className="ScoringApp-cricket__scoring__status__edit__menu__item"
              onClick={() => {
                //   option.onClick();
                updateFixture({
                  variables: {
                    where: { id: fixtureId },
                    data: {
                      metas: {
                        update: {
                          where: { id: statusMeta.id },
                          data: {
                            stringValue: null,
                          },
                        },
                      },
                    },
                  },
                });
                setShowMenu(false);
              }}
            >
              None
            </div>
          )}
        </Card>
      )}
      placement={"bottom-start"}
    />
  );
}

export default function Status({ id }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
        metas{
            id
            label
            value
            stringValue
        }
        roles(where:{type:{name:"PARTICIPANT"}}){
            id
            order
            type{
              id
              name
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
        }
        cricketLiveScoring{
            id
            liveScores{
                id
                runs
                overs    
            }
        }
        
    `,
    }),
    {
      variables: {
        id,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div className="ScoringApp-cricket__scoring__status">Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__status">
        <Loader theme={"small"} />
      </div>
    );
  }

  const statusMeta = data?.fixture?.metas.find((m) => m.label === "status");
  const status = statusMeta?.stringValue
    ? JSON.parse(statusMeta.stringValue)
    : "";
  const statusType = status?.type;

  return (
    <div className="ScoringApp-cricket__scoring__status">
      {statusType === "FIRST_INNINGS" && (
        <FirstInningsStatus
          status={status}
          metas={data?.fixture?.metas}
          participants={data?.fixture?.roles}
        />
      )}
      {statusType === "SECOND_INNINGS" && (
        <SecondInningsStatus
          status={status}
          metas={data?.fixture?.metas}
          liveScores={data?.fixture?.cricketLiveScoring?.liveScores || []}
        />
      )}
      {statusType === "CUSTOM" && (
        <CustomStatus status={status} metas={data?.fixture?.metas} />
      )}

      {statusType && (
        <span
          className="seperator"
          style={{ marginLeft: "12px", marginRight: "12px" }}
        >
          |
        </span>
      )}

      <EditStatus
        statusMeta={statusMeta}
        participants={data?.fixture?.roles?.map((r) => r.organization)}
      />
    </div>
  );
}
