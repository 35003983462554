// V1
import "./FixtureLatestVideo.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { FIXTURE_MEDIA } from "../../../api/fixture";
import YouTubeVideo from "../../YouTubeVideo";
import makeFileUrl from "../../../utils/makeFileUrl";
import { FIXTURE } from "../../../api/functions";

function FixtureLatestVideo({ id }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      pinnedMedia {
        id
        isExternal
        filename        
      }
      media(type:"VIDEO", take:1) {
        id
        isExternal
        filename        
      }
    `,
    }),
    {
      variables: { id, type: "VIDEO" },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="match-highlights-card">
        <div className="video" style={{ background: "var(--light-2)" }}></div>
      </Card>
    );
  }

  const video = data?.fixture?.pinnedMedia || data?.fixture?.media?.[0];
  // console.log("FLV:", video);

  if (!video) {
    return <div />;
  }

  return (
    <Card className="match-highlights-card">
      {video && (
        <div className="video" style={{ height: "320px" }}>
          {video.isExternal ? (
            <YouTubeVideo filename={video.filename} />
          ) : (
            <video controls controlsList="nodownload">
              <source src={makeFileUrl(video)} type="video/mp4" />
            </video>
          )}
        </div>
      )}
    </Card>
  );
}

export default FixtureLatestVideo;
