import "./EventOverview.css";
import EventRoles from "../EventRoles";
import AboutEvent from "../AboutEvent";
import EventLatestHighlights from "../EventLatestHighlights";
import EventResultsSummary from "../EventResultsSummary";
import EventStandingsSummary from "../EventStandingsSummary";
import EventAwards from "../EventAwards";
import EventPlacements from "../EventPlacements";
import ManageEventPrompt from "../ManageEventPrompt";

export default function EventOverview({ id, ev, canManageEvent }) {
  return (
    <div className="event-overview-mobile">
      {canManageEvent && (
        <div className="event-overview-mobile__manage-wrapper">
          <ManageEventPrompt id={id} />
        </div>
      )}

      <div className="event-overview-mobile__about-wrapper">
        <AboutEvent id={id} />
      </div>
      <div className="event-overview-mobile__placements-wrapper">
        <EventPlacements id={id} />
      </div>
      <div className="event-overview-mobile__highlights-wrapper">
        <EventLatestHighlights id={id} />
      </div>
      <div className="event-overview-mobile__results-wrapper">
        <EventResultsSummary id={id} />
      </div>
      <div className="event-overview-mobile__standings-wrapper">
        <EventStandingsSummary id={id} />
      </div>
      <div className="event-overview-mobile__roles-wrapper">
        <EventRoles id={id} />
      </div>
      <div className="event-overview-mobile__awards-wrapper">
        <EventAwards id={id} />
      </div>
    </div>
  );
}
