import { useState, Fragment } from "react";
import "./Register.css";
import Card from "../../Card";
import Button from "../../Button";
import Spinner from "../../Spinner";
import ClickOutside from "../../ClickOutside";
import { USER_ORGANIZATIONS } from "../../../api/user";
import { EVENT, REGISTER } from "../../../api/event";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { useAppState } from "../../../utils/appState";
import Loader from "../../Loader";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as CalendarCheckIcon } from "../../../icons/calendar-check.svg";
import { usePageTracking } from "../../../utils/usePageTracking";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function RegistrationComplete({ event }) {
  const [, setAppState] = useAppState();
  return (
    <div className="event-registration-complete">
      <div
        className="dismiss-icon"
        onClick={() => {
          setAppState({ modal: false });
        }}
      >
        <TimesIcon style={{ stroke: "var(--dark-4)" }} />
      </div>
      <div className="icon">
        <CalendarCheckIcon
          style={{ height: "128px", fill: "var(--blue-main)" }}
        />
      </div>
      <div className="description">
        <div className="blue-txt">Congratulations!</div>
        <div>You have successfully registered for {event.name}.</div>
        <div>We will notify you when your registration is confirmed!</div>
      </div>
    </div>
  );
}

function Register({ event }) {
  usePageTracking({ title: "Event Registration" });
  const [selectedTeam, setSelectedTeam] = useState("");
  const [findTeam, setFindTeam] = useState("");
  const [complete, setComplete] = useState(false);
  const [, setAppState] = useAppState();
  const [register, { loading: submitting }] = useMutation(REGISTER, {
    update: (cache, { data: { register } }) => {
      const q = cache.readQuery({
        query: EVENT,
        variables: {
          id: event.id,
        },
      });

      cache.writeQuery({
        query: EVENT,
        variables: {
          id: event.id,
        },
        data: {
          event: {
            ...q.event,
            userIsRegistered: register,
          },
        },
      });

      setComplete(true);
      // setAppState({ modal: false });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Register",
          mutation: "REGISTER",
        },
      });
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(USER_ORGANIZATIONS);
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Register",
        query: "USER_ORGANIZATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const teams = data?.userOrganizations || [];

  if (complete) {
    return <RegistrationComplete event={event} />;
  }

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="event-registration-form-wrapper" ref={wrapperRef}>
          <Card className="event-registration-form">
            <div
              className="dismiss-icon"
              onClick={() => {
                setAppState({ modal: false });
              }}
            >
              <TimesIcon style={{ stroke: "var(--light-4)", height: "16px" }} />
            </div>
            <div className="event-registration-form__form-type">
              REGISTRATION FORM
            </div>
            {/*event.coverPhoto && (
              <div className="event-registration-form__cover">
                <img src={makeFileUrl(event.coverPhoto)} />
                <div className="overlay" />
              </div>
            )*/}

            <div className="event-registration-form__header">
              <div className="event-registration-form__header__image"></div>

              <div className="event-registration-form__header__title">
                <div className="event-registration-form__header__title__primary">
                  {event.name}
                </div>
                <div className="event-registration-form__header__title__secondary">
                  {event.city?.value} |{" "}
                  {event.scheduledStart &&
                    moment(event.scheduledStart).format("DD MMM YYYY")}{" "}
                  -{" "}
                  {event.scheduledEnd &&
                    moment(event.scheduledEnd).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="event-registration-form__header__amount">
                ₹ {event.registrationAmount}
              </div>
            </div>

            {event.participantType === "TEAM" && (
              <div className="event-registration-form__body">
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="event-registration-form__body__team-selector">
                    {!teams.length ? (
                      <div className="no-data">
                        <div>You are not a member of any teams.</div>
                        <div>Don't worry - we will find a team for you!</div>
                        <div>
                          Click the button below to complete registration.
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="event-registration-form__body__team-selector__description">
                          <div>
                            Please select the team you want to register with
                            from the list below.
                          </div>
                          <div>
                            If you want us to find a team for you, select "Find
                            me a team".
                          </div>
                        </div>
                        <div className="event-registration-form__body__team-selector__list">
                          {teams.map((t) => (
                            <Card
                              className={
                                "event-registration-form__body__team-selector__item " +
                                (t.id === selectedTeam.id ? "selected" : "")
                              }
                              onClick={() => {
                                if (selectedTeam?.id === t.id) {
                                  setSelectedTeam("");
                                } else {
                                  setSelectedTeam(t);
                                }

                                setFindTeam(false);
                              }}
                            >
                              <div className="event-registration-form__body__team-selector__item__image">
                                {t.organization?.profilePhoto && (
                                  <Avatar media={t.organization.profilePhoto} />
                                )}
                              </div>
                              <div className="event-registration-form__body__team-selector__item__desc">
                                {t.organization.name}
                              </div>
                            </Card>
                          ))}

                          <Card
                            className={
                              "event-registration-form__body__team-selector__item " +
                              (findTeam ? "selected" : "")
                            }
                            onClick={() => {
                              if (findTeam) {
                                setFindTeam(false);
                              } else {
                                setFindTeam(true);
                              }
                              setSelectedTeam("");
                            }}
                          >
                            <div className="event-registration-form__body__team-selector__item__image"></div>
                            <div className="event-registration-form__body__team-selector__item__desc">
                              Find me a team
                            </div>
                          </Card>
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
                {/*event.participantType === "TEAM" ? (
                <div style={{ color: "var(--dark-1)", marginBottom: "16px" }}>
                  Select which team you want to register with from the list
                  below.
                </div>
              ) : (
                <div style={{ color: "var(--dark-1)" }}>
                  This is an individual event. Click the button below to
                  complete your registration.
                </div>
              )*/}

                {/*event.participantType === "TEAM" && (
                <div className="event-registration-form__body__team-selector">
                  {loading && <Spinner />}
                  {!loading &&
                    teams.map((t) => (
                      <Card
                        className={
                          "event-registration-form__body__team-selector__item " +
                          (t.id === selectedTeam.id ? "selected" : "")
                        }
                        onClick={() => {
                          if (selectedTeam.id === t.id) {
                            setSelectedTeam("");
                          } else {
                            setSelectedTeam(t);
                          }

                          setFindTeam(false);
                        }}
                      >
                        <div className="event-registration-form__body__team-selector__item__image"></div>
                        <div className="event-registration-form__body__team-selector__item__desc">
                          {t.organization.name}
                        </div>
                      </Card>
                    ))}

                  <Card
                    className={
                      "event-registration-form__body__team-selector__item " +
                      (findTeam ? "selected" : "")
                    }
                    onClick={() => {
                      if (findTeam) {
                        setFindTeam(false);
                      } else {
                        setFindTeam(true);
                      }
                      setSelectedTeam("");
                    }}
                  >
                    <div className="event-registration-form__body__team-selector__item__image"></div>
                    <div className="event-registration-form__body__team-selector__item__desc">
                      Find me a team
                    </div>
                  </Card>
                </div>
                  )*/}
              </div>
            )}

            <div className="event-registration-form__footer">
              <Button
                className="primary green large"
                onClick={() => {
                  if (submitting) {
                    return;
                  }

                  register({
                    variables: {
                      eventId: event.id,
                      organizationId: selectedTeam?.organization?.id || null,
                    },
                  });
                }}
              >
                {submitting ? <Loader /> : "Complete Registration"}
              </Button>
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

export default Register;
