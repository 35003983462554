import { gql } from "@apollo/client";

export function FIXTURE({ selectionSet }) {
  return gql`
      query fixture($id: ID!) {
        fixture(id: $id) {
          id
          ${selectionSet}
        }
      }
    `;
}

export function FIXTURES({ selectionSet }) {
  return gql`
      query fixtures($where: FixturesWhereInput!) {
        fixtures(where: $where) {
          id
          ${selectionSet}
        }
      }
    `;
}

export function UPCOMING_FIXTURES({ selectionSet }) {
  return gql`
      query upcomingFixtures($where: FixtureWhereInput!) {
        upcomingFixtures(where: $where)  {
          id
          ${selectionSet}
        }
      }
    `;
}

export function UPDATE_FIXTURE({ selectionSet }) {
  return gql`
    mutation updateFixture($where: UniqueInput!, $data: UpdateFixtureInput!) {
      updateFixture(where: $where, data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function CREATE_FIXTURE_ROLE({ selectionSet }) {
  return gql`
    mutation createFixtureRole($data: CreateFixtureRoleInput!) {
      createFixtureRole(data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function UPDATE_FIXTURE_ROLE({ selectionSet }) {
  return gql`
    mutation updateFixtureRole(
      $where: UniqueInput!
      $data: UpdateFixtureRoleInput!
    ) {
      updateFixtureRole(where: $where, data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function DELETE_FIXTURE_ROLE({ selectionSet }) {
  return gql`
    mutation deleteFixtureRole($id: ID!) {
      deleteFixtureRole(id: $id) {
        id
        ${selectionSet}
      }
    }
  `;
}

export const FIXTURE_STATS = gql`
  query fixture(
    $id: ID!
    $where: FixtureStatsWhereInput
    $take: Int
    $cursor: ID
  ) {
    fixture(id: $id) {
      id
      stats(where: $where, take: $take, cursor: $cursor) {
        id
        tempId
        createdAt
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        fixturePeriod {
          id
          order
          label
        }
        relationshipsTo {
          id
          from {
            id
            createdAt
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
            fixturePeriod {
              id
              order
              label
            }
            value
          }
        }
      }
    }
  }
`;
