import { gql } from "@apollo/client";

const FIXTURE_SETTINGS = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      completed
      statsCacheState
      event {
        id
        sport {
          id
          name
        }
      }
      metas {
        id
        label
        value
        stringValue
      }
      periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
      }
      roles {
        id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
      }
    }
  }
`;

export default FIXTURE_SETTINGS;
