import "./CricketStats.css";
import {
  ORGANIZATION_STATS,
  ORGANIZATION_AWARDS,
} from "../../../../api/organization";
import { useQuery } from "@apollo/client";
import Square from "../../../Square";
import moment from "moment";
import Avatar from "../../../../components/Avatar";
import captureException from "../../../../utils/captureException";

function OrganizationRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record-mobile">
      <div className="organization-record-mobile__meta">
        <div className="organization-record-mobile__meta__title">RECORD</div>

        <div className="organization-record-mobile__meta__highlight">
          <div className="organization-record-mobile__meta__highlight__value">
            {total}
          </div>
          <div className="organization-record-mobile__meta__highlight__label">
            Games Played
          </div>
        </div>
      </div>

      <div className="organization-record-mobile__items">
        <div className="organization-record-mobile__items__item">
          <div className="organization-record-mobile__items__item__value">
            {wins}
          </div>

          <div className="organization-record-mobile__items__item__graph">
            <div className="organization-record-mobile__items__item__graph__track">
              <div
                className="organization-record-mobile__items__item__graph__fill"
                style={{ height: total ? (wins / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record-mobile__items__item__label">
            W
          </div>
        </div>

        <div className="organization-record-mobile__items__item">
          <div className="organization-record-mobile__items__item__value">
            {losses}
          </div>

          <div className="organization-record-mobile__items__item__graph">
            <div className="organization-record-mobile__items__item__graph__track">
              <div
                className="organization-record-mobile__items__item__graph__fill"
                style={{ height: total ? (losses / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record-mobile__items__item__label">
            L
          </div>
        </div>

        <div className="organization-record-mobile__items__item">
          <div className="organization-record-mobile__items__item__value">
            {draws}
          </div>

          <div className="organization-record-mobile__items__item__graph">
            <div className="organization-record-mobile__items__item__graph__track">
              <div
                className="organization-record-mobile__items__item__graph__fill"
                style={{ height: total ? (draws / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record-mobile__items__item__label">
            D
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganizationBattingStats({ runsScored, ballsFaced, strikeRate }) {
  return (
    <div className="profile-fielding-stats-mobile">
      <div className="profile-fielding-stats-mobile__title">BATTING</div>

      <div className="profile-fielding-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats-mobile__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats-mobile__items__item__value">
                {runsScored}
              </div>

              <div className="profile-fielding-stats-mobile__items__item__key">
                Runs
                <br /> Scored
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats-mobile__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats-mobile__items__item__value">
                {ballsFaced}
              </div>

              <div className="profile-fielding-stats-mobile__items__item__key">
                Balls <br />
                Faced
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats-mobile__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats-mobile__items__item__value">
                {strikeRate}
              </div>

              <div className="profile-fielding-stats-mobile__items__item__key">
                Strike
                <br /> Rate
              </div>
            </div>
          )}
        </Square>
      </div>

      <div className="profile-fielding-stats-mobile__graphic">
        <div className="profile-fielding-stats-mobile__graphic__text green">
          BATTING
        </div>
        <div className="profile-fielding-stats-mobile__graphic__text blue">
          BATTING
        </div>
      </div>
    </div>
  );
}

function FormItem({ id, fixture }) {
  const orgPlacement = fixture.placements?.find(
    (p) => p.organization.id === id
  );
  const opponentPlacement = fixture.placements?.find(
    (p) => p.organization.id !== id
  );

  const opponentRole = fixture.roles.find((r) => r.organization.id !== id);
  const result =
    (orgPlacement?.value || 0) > (opponentPlacement?.value || 0)
      ? "W"
      : (orgPlacement?.value || 0) < (opponentPlacement?.value || 0)
      ? "L"
      : "D";

  return (
    <div className="organization-form-mobile__items__item">
      <div className="organization-form-mobile__items__item__meta">
        <div className="organization-form-mobile__items__item__meta__image">
          {opponentRole?.organization?.profilePhoto && (
            <Avatar media={opponentRole.organization.profilePhoto} />
          )}
        </div>

        <div className="organization-form-mobile__items__item__meta__text">
          <div className="organization-form-mobile__items__item__meta__text__primary">
            {opponentRole?.organization?.name}
          </div>

          <div className="organization-form-mobile__items__item__meta__text__secondary">
            {fixture.scheduledStart
              ? moment(fixture.scheduledStart).format("Do MMM YYYY")
              : "-"}
          </div>
        </div>
      </div>

      <div className="organization-form-mobile__items__item__result">
        <div
          className={
            "organization-form-mobile__items__item__result__text " +
            (result === "W" ? "green" : result === "L" ? "red" : "orange")
          }
        >
          {result === "W" ? "WIN" : result === "L" ? "LOSS" : "DRAW"}
        </div>

        {/* <div className="organization-form-mobile__items__item__result__score">
            {orgPlacement?.value || 0} - {opponentPlacement?.value || 0}
          </div> */}
      </div>
    </div>
  );
}

function OrganizationFormStats({ id, form }) {
  // const formFixtures = form.filter((f) =>
  //   f.roles.find((r) => r.organization.id !== id)
  // );

  // console.log("Form:", form);

  return (
    <div className="organization-form-mobile">
      <div className="organization-form-mobile__title">FORM</div>

      <div className="organization-form-mobile__items">
        {!form?.length && (
          <div className="no-data">No previous matches found</div>
        )}
        {form?.map((fixture) => (
          <FormItem id={id} fixture={fixture} />
        ))}
      </div>
    </div>
  );
}

function OrganizationBowlingStats({ wicketsTaken, ballsBowled, economyRate }) {
  return (
    <div className="profile-fielding-stats-mobile">
      <div className="profile-fielding-stats-mobile__title">BOWLING</div>

      <div className="profile-fielding-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats-mobile__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats-mobile__items__item__value">
                {wicketsTaken}
              </div>

              <div className="profile-fielding-stats-mobile__items__item__key">
                Wickets
                <br /> Taken
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats-mobile__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats-mobile__items__item__value">
                {ballsBowled}
              </div>

              <div className="profile-fielding-stats-mobile__items__item__key">
                Balls
                <br /> Bowled
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats-mobile__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats-mobile__items__item__value">
                {economyRate}
              </div>

              <div className="profile-fielding-stats-mobile__items__item__key">
                Economy
                <br /> Rate
              </div>
            </div>
          )}
        </Square>
      </div>

      <div className="profile-fielding-stats-mobile__graphic">
        <div className="profile-fielding-stats-mobile__graphic__text green">
          BOWLING
        </div>
        <div className="profile-fielding-stats-mobile__graphic__text blue">
          BOWLING
        </div>
      </div>
    </div>
  );
}

function OrganizationAwards({ id, sport }) {
  const { loading, error, data } = useQuery(ORGANIZATION_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.CricketStats.OrganizationAwards",
        query: "ORGANIZATION_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const organization = data.organization;
  const placements = organization?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats-mobile">
      <div className="profile-awards-stats-mobile__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats-mobile__items">
          <div className="no-data">No awards for this team yet.</div>
        </div>
      )}

      <div className="profile-awards-stats-mobile__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats-mobile__items__item">
              <div className="profile-awards-stats-mobile__items__item__img"></div>
              <div className="profile-awards-stats-mobile__items__item__desc">
                <div className="profile-awards-stats-mobile__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats-mobile__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function CricketStats({ id, events }) {
  const { error, data } = useQuery(ORGANIZATION_STATS, {
    variables: {
      id,
      sport: "Cricket",
      statTypes: [
        "Runs Scored",
        "Runs Conceded",
        "Balls Faced",
        "Balls Bowled",
        "Wicket Taken",
      ],
      events,
      rolesWhereInput: { type: { name: "PARTICIPANT" } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.CricketStats",
        query: "ORGANIZATION_STATS",
      },
    });
    console.log(error);
  }

  // console.log("STATS:", data);

  const { totalGamesPlayed, form } = data?.organization?.stats || {};

  const record = JSON.parse(data?.organization?.stats?.record?.data || "{}");
  const aggregationsData = JSON.parse(
    data?.organization?.stats?.aggregations?.data || "{}"
  );

  return (
    <div className="organization-stats-mobile__cricket">
      <OrganizationRecordStats
        total={totalGamesPlayed}
        wins={record.wins}
        losses={record.losses}
        draws={record.draws}
      />

      <OrganizationBattingStats
        runsScored={aggregationsData["Runs Scored"] || 0}
        ballsFaced={aggregationsData["Balls Faced"] || 0}
        strikeRate={Math.floor(
          (aggregationsData["Runs Scored"] / aggregationsData["Balls Faced"]) *
            100
        )}
      />

      <OrganizationBowlingStats
        wicketsTaken={aggregationsData["Wicket Taken"] || 0}
        ballsBowled={aggregationsData["Balls Bowled"] || 0}
        economyRate={
          Math.floor(
            (aggregationsData["Runs Conceded"] /
              (Math.floor(aggregationsData["Balls Bowled"] / 6) +
                (aggregationsData["Balls Bowled"] % 6) / 6)) *
              100
          ) / 100 || 0
        }
      />

      <OrganizationFormStats id={id} form={form} />

      <OrganizationAwards id={id} sport={"Cricket"} />
    </div>
  );
}
