// V1
import { useState, Fragment } from "react";
import "./ManageEventStandings.css";
import Card from "../../Card";
import Row from "../../Row";
import Button from "../../Button";
import Loader from "../../Loader";
import Chip from "../../Chip";
import SelectWithoutSearch from "../../SelectWithoutSearch";
import {
  EVENT_PARTS,
  EVENT_STANDINGS,
  CALCULATE_STANDINGS,
  DELETE_STANDING,
  UPDATE_STANDING_ITEM_META,
  REORDER_STANDING_ITEMS,
} from "../../../api/event";
import { useMutation, useQuery } from "@apollo/client";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import Spinner from "../../Spinner";
import moment from "moment";
import ClickOutside from "../../ClickOutside";
import captureException from "../../../utils/captureException";

function EditStandingItemField({
  config = {},
  defaultValue,
  dismiss = () => {},
}) {
  const [val, setVal] = useState(defaultValue);
  const [updateStandingItemMeta, { loading }] = useMutation(
    UPDATE_STANDING_ITEM_META,
    {
      update(cache, { data: { updateStandingItemMeta } }) {
        // cache.modify
        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventStandings.EditStandingItemField",
            mutation: "UPDATE_STANDING_ITEM_META",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <ClickOutside onClickOutside={dismiss}>
      {(wrapperRef) => (
        <form
          ref={wrapperRef}
          className="standings-list-item__items__row__data__form"
          onSubmit={(e) => {
            e.preventDefault();
            if (loading || !config.meta) {
              return;
            }
            updateStandingItemMeta({
              variables: {
                where: { id: config.meta.id },
                data: config.vars(val),
              },
            });
          }}
        >
          <input
            type={config.type || "text"}
            value={val}
            onChange={(e) => {
              setVal(e.target.value);
            }}
            disabled={loading}
            autoFocus
          />
        </form>
      )}
    </ClickOutside>
  );
}

function EditableField({ config, value, style }) {
  const [editing, setEditing] = useState(false);

  return (
    <div className="standings-list-item__items__row__data" style={style}>
      {editing ? (
        <EditStandingItemField
          config={config}
          defaultValue={value}
          dismiss={() => {
            setEditing(false);
          }}
        />
      ) : (
        <span
          className="hover-pointer"
          onClick={() => {
            setEditing(true);
          }}
        >
          {value}
        </span>
      )}
    </div>
  );
}

function getTableConfig({ sport = {} }) {
  if (sport.name === "Football") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "15%" },
        value: (item) => {
          return item.order + 1;
        },
        canReorder: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { width: "29%" },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Games Played"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Won"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Drawn"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Lost"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },

      {
        label: "GF",
        tooltip: "Goals For",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Goals For")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Goals For"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "GA",
        tooltip: "Goals Against",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Goals Against")?.value || 0
          );
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Goals Against"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "GD",
        tooltip: "Goal Difference",
        style: { width: "7%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Difference")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Difference"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7%", justifyContent: "flex-end", fontWeight: "700" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Points"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
    ];
  } else if (sport.name?.includes("Basketball")) {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
        canReorder: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { width: "30%" },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },

      {
        label: "Pct",
        tooltip: "Win Percentage",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Win Percentage")?.value || 0
          ).toFixed(2);
        },
      },
      {
        label: "For",
        tooltip: "Points Scored",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points For")?.value || 0;
        },
      },
      {
        label: "Against",
        tooltip: "Points Conceded",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Points Against")?.value || 0
          );
        },
      },
      {
        label: "Diff",
        tooltip: "Point Difference",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            (item.metas.find((m) => m.label === "Points For")?.value || 0) -
            (item.metas.find((m) => m.label === "Points Against")?.value || 0)
          );
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name === "Cricket") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "15%" },
        value: (item) => {
          return item.order + 1;
        },
        canReorder: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { width: "41%" },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Games Played"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Won"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Drawn"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Lost"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "NRR",
        tooltip: "Net Run Rate",
        style: { width: "8%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas
              .find((m) => m.label === "Net Run Rate")
              ?.value?.toFixed(3) || "-"
          );
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Net Run Rate"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      // {
      //   label: "For",
      //   tooltip: "Run Rate For",
      //   style: { width: "8%", justifyContent: "flex-end" },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate For")?.stringValue ||
      //       "-"
      //     );
      //   },
      //   canEdit: (item) => ({
      //     type: "text",
      //     meta: item.metas.find((m) => m.label === "Run Rate For"),
      //     vars: (val) => ({ stringValue: val || "" }),
      //   }),
      // },
      // {
      //   label: "Against",
      //   tooltip: "Run Rate Against ",
      //   style: { width: "8%", justifyContent: "flex-end" },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate Against")
      //         ?.stringValue || "-"
      //     );
      //   },
      //   canEdit: (item) => ({
      //     type: "text",
      //     meta: item.metas.find((m) => m.label === "Run Rate Against"),
      //     vars: (val) => ({ stringValue: val || "" }),
      //   }),
      // },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.2%", justifyContent: "flex-end", fontWeight: "700" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Points"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
    ];
  } else if (sport.name === "Badminton") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { width: "67.5%" },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        placeholderWidth: "160px",
        hasLink: (item) => {
          return `/app/${item.organization ? "page" : "profile"}/${
            item.organization?.id || item.profile?.id
          }`;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "+/-",
        tooltip: "Point Difference",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          const scored =
            item.metas.find((m) => m.label === "Points For")?.value || 0;
          const conceded =
            item.metas.find((m) => m.label === "Points Against")?.value || 0;
          return scored - conceded;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { width: "67.5%" },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "7.5%" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.5%" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  }
}

function Standing({ eventId, standing, sport }) {
  const [isReordering, setIsReordering] = useState(false);
  const [isReorderingDirection, setIsReorderingDirection] = useState("");

  const [deleteStanding, { loading: deleting }] = useMutation(DELETE_STANDING, {
    update: (cache, { data: { deleteStanding } }) => {
      const q = cache.readQuery({
        query: EVENT_STANDINGS,
        variables: { id: eventId },
      });

      cache.writeQuery({
        query: EVENT_STANDINGS,
        variables: { id: eventId },
        data: {
          event: {
            ...q.event,
            standings: q.event.standings.filter(
              (s) => s.id !== deleteStanding.id
            ),
            parts: q.event.parts.map((p) => {
              return {
                ...p,
                standings: p.standings.filter(
                  (s) => s.id !== deleteStanding.id
                ),
              };
            }),
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventStandings.Standing",
          mutation: "DELETE_STANDING",
        },
      });
      console.log(error);
    },
  });

  const [reorderStandingItems, { loading: reordering }] = useMutation(
    REORDER_STANDING_ITEMS,
    {
      update: (cache, { data: { reorderStandingItems } }) => {
        // console.log("SI:", reorderStandingItems);
        setIsReordering(false);
        setIsReorderingDirection("");
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventStandings.Standing",
            mutation: "REORDER_STANDING_ITEMS",
          },
        });
        console.log(error);
      },
    }
  );

  const tableConfig = getTableConfig({ sport });

  return (
    <Card className="standings-list-item">
      <div className="standings-list-item__header">
        <div className="label">
          {standing.title || standing.eventPart?.label || standing.event?.name}
        </div>
        <div className="createdAt">
          Calculated at {moment(standing.createdAt).format("Do MMM YYYY")}
        </div>
        <div
          className="delete-btn"
          onClick={() => {
            // console.log("Clicked");
            if (deleting) return;
            deleteStanding({
              variables: {
                id: standing.id,
              },
            });
          }}
        >
          {deleting ? <Spinner size={12} /> : <TrashIcon className="icon" />}
        </div>
      </div>
      <div className="standings-list-item__fields">
        {tableConfig.map((c) => (
          <div
            key={"standing-fields-" + standing.id + c.label}
            className="standings-list-item__fields__field"
            style={c.style}
          >
            {c.label}
          </div>
        ))}
      </div>
      <div className="standings-list-item__items">
        {[...(standing.items || [])]
          ?.sort((a, b) => a.order - b.order)
          ?.map((item, i) => (
            <div key={item.id} className="standings-list-item__items__row">
              {tableConfig.map((c) => (
                <Fragment key={"standing-item-fields-" + item.id + c.label}>
                  {c.canEdit ? (
                    <EditableField
                      config={c.canEdit(item)}
                      value={c.value(item)}
                      style={c.style}
                    />
                  ) : (
                    <div
                      className="standings-list-item__items__row__data"
                      style={c.style}
                    >
                      {c.canReorder ? (
                        <div className="standings-list-item__items__row__data__order">
                          {c.value(item)}
                        </div>
                      ) : (
                        c.value(item)
                      )}

                      {c.canReorder && (
                        <Fragment>
                          {!!i ? (
                            <div
                              className="standings-list-item__items__row__data__reorder up"
                              onClick={() => {
                                if (reordering) return;

                                setIsReordering(item.id);
                                setIsReorderingDirection("up");

                                const prevItem = standing.items.find(
                                  (x) => x.order === item.order - 1
                                );
                                // console.log(item);
                                // console.log(prevItem);

                                reorderStandingItems({
                                  variables: {
                                    data: [
                                      {
                                        where: { id: item.id },
                                        order: item.order - 1,
                                      },
                                      {
                                        where: { id: prevItem.id },
                                        order: prevItem.order + 1,
                                      },
                                    ],
                                  },
                                });
                              }}
                            >
                              {isReordering === item.id &&
                              isReorderingDirection === "up" ? (
                                <Spinner size={12} />
                              ) : (
                                <ChevronDownIcon
                                  style={{
                                    height: "14px",
                                    stroke: "var(--dark-4)",
                                  }}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="standings-list-item__items__row__data__reorder up"></div>
                          )}

                          {i < (standing.items?.length || 0) - 1 && (
                            <div
                              className="standings-list-item__items__row__data__reorder down"
                              onClick={() => {
                                if (reordering) return;

                                setIsReordering(item.id);
                                setIsReorderingDirection("down");

                                const nextItem = standing.items.find(
                                  (x) => x.order === item.order + 1
                                );
                                // console.log(item);
                                // console.log(prevItem);

                                reorderStandingItems({
                                  variables: {
                                    data: [
                                      {
                                        where: { id: item.id },
                                        order: item.order + 1,
                                      },
                                      {
                                        where: { id: nextItem.id },
                                        order: nextItem.order - 1,
                                      },
                                    ],
                                  },
                                });
                              }}
                            >
                              {isReordering === item.id &&
                              isReorderingDirection === "down" ? (
                                <Spinner size={12} />
                              ) : (
                                <ChevronDownIcon
                                  style={{
                                    height: "14px",
                                    stroke: "var(--dark-4)",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          ))}
      </div>
    </Card>
  );
}

function StandingsList({ id }) {
  const { loading, error, data } = useQuery(EVENT_STANDINGS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventStandings.StandingsList",
        query: "EVENT_STANDINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const sport = data?.event?.sport;
  const standings = data?.event?.standings;
  const parts = data?.event?.parts;
  console.log(standings);

  return (
    <div className="standings-list">
      {standings
        .filter((s) => !s.eventPart)
        .map((s) => (
          <Standing key={s.id} standing={s} eventId={id} sport={sport} />
        ))}

      {parts?.map((p) => {
        if (p.standings?.length) {
          return (
            <Standing
              key={p.standings[0].id}
              standing={p.standings[0]}
              eventId={id}
              sport={sport}
            />
          );
        } else {
          return <div />;
        }
      })}
    </div>
  );
}

function ManageEventStandings({ id }) {
  const [eventPartId, setEventPartId] = useState("");
  const [pointsPerWin, setPointsPerWin] = useState(3);
  const [pointsPerLoss, setPointsPerLoss] = useState(0);
  const [pointsPerDraw, setPointsPerDraw] = useState(1);

  const [calculateStandings, { loading }] = useMutation(CALCULATE_STANDINGS, {
    update: (cache, { data: { calculateStandings } }) => {
      const q = cache.readQuery({
        query: EVENT_STANDINGS,
        variables: { id },
      });

      cache.writeQuery({
        query: EVENT_STANDINGS,
        variables: { id },
        data: {
          event: {
            ...q.event,
            standings: [calculateStandings, ...q.event.standings],
          },
        },
      });

      // setTitle("");
      // setEventParts([]);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventStandings",
          mutation: "CALCULATE_STANDINGS",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="manage-event-standings">
      <div className="left-column">
        <StandingsList id={id} />
      </div>
      <div className="right-column">
        <Card className="recalculate-event-standings">
          <div className="card-title">Calculate Standings</div>
          <form className="recalculate-event-standings-form">
            <Row columns={4} gap={32}>
              <div
                className="form-group"
                style={{
                  gridColumnStart: "1",
                  gridColumnEnd: "5",
                  marginBottom: "16px",
                }}
              >
                <div className="form-group-label">Stage</div>

                <SelectWithoutSearch
                  placeholder={"All Stages"}
                  items={(d) => [
                    { name: "All Stages" },
                    ...d.event?.parts?.map((p) => ({ ...p, name: p.label })),
                  ]}
                  query={EVENT_PARTS}
                  variables={{ id }}
                  onItemSelect={(item) => {
                    if (!item.id) {
                      setEventPartId("");
                      // setEventParts([]);
                    } else {
                      setEventPartId(item.id);
                      // setEventParts((prev) => {
                      //   if (prev.find((x) => x.id === item.id)) {
                      //     return prev;
                      //   } else {
                      //     return [...prev, item];
                      //   }
                      // });
                    }
                  }}
                  // clearOnItemSelect={true}
                />
              </div>
            </Row>

            {/* {!!eventParts.length && (
              <div className="recalculate-event-standings__periods">
                {eventParts.map((p, i) => (
                  <Chip
                    className="recalculate-event-standings__periods__item"
                    key={"chip-" + p.id}
                    onClick={() => {
                      setEventParts((prev) => {
                        return eventParts.filter((x) => x?.id !== p?.id);
                      });
                    }}
                  >
                    {p.name}{" "}
                    <TimesIcon className="recalculate-event-standings__periods__item__icon" />
                  </Chip>
                ))}
              </div>
            )} */}

            <Row columns={3} gap={32}>
              <div className="form-group">
                <div className="form-group-label">Points per win</div>
                <div className="form-group-input">
                  <input
                    type="number"
                    value={pointsPerWin}
                    onChange={(e) => {
                      setPointsPerWin(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Points per draw</div>
                <div className="form-group-input">
                  <input
                    type="number"
                    value={pointsPerDraw}
                    onChange={(e) => {
                      setPointsPerDraw(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Points per loss</div>
                <div className="form-group-input">
                  <input
                    type="number"
                    value={pointsPerLoss}
                    onChange={(e) => {
                      setPointsPerLoss(e.target.value);
                    }}
                  />
                </div>
              </div>
            </Row>

            <div className="form-options">
              <Button
                className="primary large blue"
                onClick={() => {
                  if (loading) return;

                  // const eventPartIds = eventParts.map((x) => x.id);

                  const vars = {
                    eventId: id,
                    eventPartId,
                    // eventPartIds,
                    pointsPerWin: Number(pointsPerWin),
                    pointsPerDraw: Number(pointsPerDraw),
                    pointsPerLoss: Number(pointsPerLoss),
                  };

                  // console.log(vars);

                  calculateStandings({
                    variables: vars,
                  });
                }}
              >
                {loading ? <Loader /> : "Calculate"}
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default ManageEventStandings;
