import "./EditOrgCategories.css";
import { useState } from "react";
import { useAppState } from "../../../utils/appState";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_ORGANIZATION,
  ORGANIZATION_TYPES,
} from "../../../api/organization";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Spinner from "../../../components/Spinner";
import captureException from "../../../utils/captureException";

export default function EditOrgCategories({ id, types = [] }) {
  const [, setAppState] = useAppState();
  const [selectedCategories, setSelectedCategories] = useState([...types]);
  const { loading, error, data } = useQuery(ORGANIZATION_TYPES);

  const [updateOrganization, { loading: submitting }] = useMutation(
    UPDATE_ORGANIZATION(`types{id name}`),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "EditOrgCategories",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const dismiss = () => {
    setAppState({ modal: false });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EditOrgCategories",
        query: "ORGANIZATION_TYPES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const onAddCategory = (item) => {
    if (submitting) return;

    updateOrganization({
      variables: {
        where: { id },
        data: {
          types: {
            connect: { id: item.id },
          },
        },
      },
    });
  };

  const onRemoveCategory = (item) => {
    if (submitting) return;
    updateOrganization({
      variables: {
        where: { id },
        data: {
          types: {
            disconnect: {
              id: item.id,
            },
          },
        },
      },
    });
  };

  const categories = data?.organizationTypes;

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Update Categories
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          {loading ? (
            <Spinner />
          ) : (
            <div className="modal-form-mobile__body__categories-list">
              {categories?.map((c) => (
                <div
                  className={
                    "modal-form-mobile__body__categories-list__item " +
                    (selectedCategories.find((t) => t.id === c.id)
                      ? "selected"
                      : "")
                  }
                  style={{ marginRight: "16px", marginBottom: "16px" }}
                  onClick={() => {
                    if (selectedCategories.find((t) => t.id === c.id)) {
                      setSelectedCategories((prev) =>
                        [...prev].filter((x) => x.id !== c.id)
                      );
                      onRemoveCategory(c);
                    } else {
                      setSelectedCategories((prev) => [...prev, c]);
                      onAddCategory(c);
                    }
                  }}
                >
                  {c.name}
                </div>
              ))}
            </div>
          )}

          {/* <div className="modal-form-mobile__body__interests-grid">
            {sports.map((sport, i) => (
              <div
                key={"profile-sport-" + sport.id + i}
                className={
                  "interest hover-pointer " +
                  (interests.find((i) => i.name === sport.name)
                    ? ""
                    : "inactive")
                }
                onClick={() => {
                  if (interests.find((i) => i.name === sport.name)) {
                    setInterests((prev) =>
                      prev.filter((i) => i.name !== sport.name)
                    );
                  } else {
                    setInterests((prev) => [...prev, sport]);
                  }
                }}
              >
                <div className="icon">
                  {sportsIcons[sport.name] || (
                    <EllipsisIcon className="sports-icon-svg" />
                  )}
                </div>
                <div className="desc">{sport.name}</div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}
