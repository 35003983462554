import { Fragment } from "react";
import NewDropdown from "../../../NewDropdown";
import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import { ReactComponent as StrikeIcon } from "../../../../icons/strike.svg";
import ballsCompleted from "../utils/ballsCompleted";

const battingHeaders = [
  { label: "Batsman", width: "60%", textAlign: "left", key: "name" },
  { label: "Runs", width: "10%", textAlign: "right", key: "runs" },
  { label: "Balls", width: "10%", textAlign: "right", key: "balls" },
  { label: "4s", width: "10%", textAlign: "right", key: "4s" },
  { label: "6s", width: "10%", textAlign: "right", key: "6s" },
];

function Score({
  fixtureId,
  battingTeam,
  currentOverNumber,
  stats,
  groupedByOver,
}) {
  const runs = stats
    .filter(
      (s) =>
        (s.organization.id === battingTeam?.id &&
          s.statType.name === "Runs Scored") ||
        (battingTeam &&
          s.organization.id !== battingTeam?.id &&
          ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name))
    )
    .reduce((accum, curr) => {
      accum += curr.value;
      return accum;
    }, 0);

  const wickets = stats.filter(
    (s) =>
      s.organization.id === battingTeam?.id &&
      [
        "Bowled",
        "LBW",
        "Caught",
        "Caught & Bowled",
        "Stumped",
        "Run Out",
        "Retired",
      ].includes(s.statType.name) &&
      s.relationshipsTo.find((x) => x.from.statType.name === "Runs Scored")
  ).length;
  //
  console.log(
    "BATTING WICKETS:",
    stats.filter(
      (s) =>
        s.organization.id === battingTeam?.id &&
        [
          "Bowled",
          "LBW",
          "Caught",
          "Caught & Bowled",
          "Stumped",
          "Run Out",
          "Retired",
        ].includes(s.statType.name)
    )
  );

  const currentBallLabel = ballsCompleted({
    overNumber: currentOverNumber,
    groupedStats: groupedByOver,
  });

  return (
    <div className="score">
      {runs} / {wickets} ({currentOverNumber + "." + currentBallLabel} overs){" "}
    </div>
  );
}

export default function BattingSummary({
  fixtureId,
  currentOverNumber,
  batsmen,
  switchTeams,
  setOverlay,
  battingTeam,
  strikeIndex,
  setStrikeIndex,
  participants = [],
  setBattingTeam,
  setBowlingTeam,
  setBatsmen,
  setBowler,
  updateCache,
  stats,
  groupedByOver,
}) {
  // const { loading, error, data } = useQuery(FIXTURE_STATS, {
  //   variables: { id: fixtureId },
  // });

  // if (error) {
  //   console.log(error);
  //   return <div>Error</div>;
  // }

  // if (loading || !data) {
  //   return <Loader />;
  // }

  // const stats = data.fixture.stats;
  // console.log("Stats:", stats);
  // const runs = stats
  //   .filter(
  //     (s) =>
  //       (s.organization.id === battingTeam.id &&
  //         s.statType.name === "Runs Scored") ||
  //       (s.organization.id !== battingTeam.id &&
  //         ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name))
  //   )
  //   .reduce((accum, curr) => {
  //     accum += curr.value;
  //     return accum;
  //   }, 0);

  return (
    <div className="batting-summary">
      <div className="head">
        <div className="section-title">BATTING </div>
        <NewDropdown
          target={
            <div className="selected-batting-team">
              <div className="selected-batting-team__name">
                {battingTeam?.name || "Select batting team"}
              </div>
              <div className="selected-batting-team__image"></div>
              <ChevronDown className="selected-batting-team__icon" />
            </div>
          }
          menu={({ setShowMenu }) => (
            <div className="selected-batting-team__menu">
              {participants.map((p) => (
                <div
                  key={"participant-" + p.id}
                  className="selected-batting-team__menu__item"
                  onClick={() => {
                    const newBattingTeam = p.organization;
                    const newBowlingTeam = participants.find(
                      (x) => x.organization.id !== p.organization.id
                    ).organization;
                    const newBatsmen = [{}, {}];
                    const newBowler = {};
                    const newStrikeIndex = 0;
                    setBattingTeam(newBattingTeam);
                    setBowlingTeam(newBowlingTeam);
                    setBatsmen(newBatsmen);
                    setBowler(newBowler);
                    setStrikeIndex(newStrikeIndex);
                    updateCache({
                      battingTeam: newBattingTeam,
                      bowlingTeam: newBowlingTeam,
                      batsmen: newBatsmen,
                      bowler: newBowler,
                      strikeIndex: newStrikeIndex,
                    });
                    setShowMenu(false);
                  }}
                >
                  {p.organization.name}
                </div>
              ))}
            </div>
          )}
        />
      </div>

      <Score
        fixtureId={fixtureId}
        battingTeam={battingTeam}
        currentOverNumber={currentOverNumber}
        stats={stats}
        groupedByOver={groupedByOver}
      />

      <div className="on-strike">
        <div className="fields">
          {battingHeaders.map((h) => (
            <div
              key={"batting-header-" + h.label}
              className="th"
              style={{ width: h.width, textAlign: h.textAlign }}
            >
              {h.label}
            </div>
          ))}
        </div>

        {batsmen.map((b, i) => (
          <div className="row" key={"batsman-" + i}>
            <div className="batsman" style={{ width: "60%" }}>
              <div
                className="icon"
                onClick={() => {
                  setStrikeIndex(i);
                  updateCache({ strikeIndex: i });
                }}
              >
                <StrikeIcon
                  style={{
                    fill:
                      strikeIndex === i ? "var(--red-main)" : "var(--dark-4)",
                    height: "14px",
                  }}
                />
              </div>
              <div
                className="batsman-profile"
                onClick={() => {
                  setOverlay({
                    title: "Select Batsman " + (i + 1),
                    onSelect: (player) => {
                      const newBatsmen = [...batsmen];
                      newBatsmen[i] = { profile: player.profile };
                      setBatsmen(newBatsmen);
                      updateCache({ batsmen: newBatsmen });
                    },
                    defaultTeam: battingTeam,
                  });
                }}
              >
                {b.profile?.name ? (
                  <Fragment>
                    <div className="profile-pic" /> {b.profile.name}
                  </Fragment>
                ) : (
                  <div className="input">Select Batsman</div>
                )}
              </div>
            </div>
            <div className="runs" style={{ width: "10%", textAlign: "right" }}>
              {stats
                .filter(
                  (s) =>
                    s?.profile?.id === b?.profile?.id &&
                    s.statType.name === "Runs Scored"
                )
                .reduce((accum, curr) => {
                  accum += curr.value;
                  return accum;
                }, 0) || 0}
            </div>
            <div className="balls" style={{ width: "10%", textAlign: "right" }}>
              {stats
                .filter(
                  (s) =>
                    s?.profile?.id === b?.profile?.id &&
                    s.statType.name === "Balls Faced"
                )
                .reduce((accum, curr) => {
                  accum += curr.value;
                  return accum;
                }, 0) || 0}
            </div>
            <div className="fours" style={{ width: "10%", textAlign: "right" }}>
              {
                stats.filter(
                  (s) =>
                    s?.profile?.id === b?.profile?.id &&
                    s.statType.name === "Runs Scored" &&
                    s.value === 4
                )?.length
              }
            </div>
            <div className="sixes" style={{ width: "10%", textAlign: "right" }}>
              {
                stats.filter(
                  (s) =>
                    s?.profile?.id === b?.profile?.id &&
                    s.statType.name === "Runs Scored" &&
                    s.value === 6
                )?.length
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
