import "./Statement.css";
import { Fragment } from "react";

/*
  Goal: Show,
  Shot On Target: Show if it has no children,
  Shot Off Target: Show,
  Corner: Show,
  Penalty Won: Show,
  Penalty Scored: Show if it has no parent goal,
  Penalty Missed: Show,

  Pass Completed: Show if no children,
  Pass Attempted: Show if no children,
  Assist: Don't Show,
  Key Pass: Show if it has no associated stats,

  Own Goal: Show,
  Tackle: Show,
  Block: Show,
  Clearance: Show,
  Interception: Show,
  Penalty Conceded: Show,

  Save: Show,
  Foul: Show if it has no children,
  Offside: Show,
  Red Card: Show,
  Yellow Card: Show,

  Sub Off: Show,
  Sub On: Don't Show,

  Penalty Shootout Goal: Hide,
  Penalty Shootout Miss: Hide



  Always Hidden:[
    Assist,


  ]
*/

export default function Statement({ stat, stats }) {
  const parentStats = stat.relationshipsTo || [];
  const childStats = stats.filter((s) =>
    s.relationshipsTo?.find((x) => x.from.id === stat.id)
  );

  if (stat.statType.name === "Sub On" || stat.statType.name === "Sub Off") {
    console.log("Sub:", stat);
  }

  return (
    <Fragment>
      <div className="ScoringApp-football__scoring__period__list__item__section__statements__statement">
        <span>
          <span className="bold">{stat.statType.name}</span> by{" "}
          <span className="bold">{stat.profile?.name}</span>
        </span>
      </div>
      {childStats.map((r) => (
        <Statement key={r.id} stat={r} stats={stats} />
      ))}
    </Fragment>
  );
}
