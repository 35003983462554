// V1
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  ORGANIZATION,
  UPDATE_ORGANIZATION,
  ORGANIZATION_TYPES,
} from "../../../api/organization";
import "./OrganizationDescription.css";
import Card from "../../Card";
import Chip from "../../Chip";
import Button from "../../Button";
import Placeholder from "../../Placeholder";
import ToggleState from "../../ToggleState";
import FilterInput from "../../FilterInput";
import { Fragment } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { CAN_MANAGE_PAGE } from "../../../api/user";
import Loader from "../../Loader";
import captureException from "../../../utils/captureException";

function UpdateTypes({ id, setIndex, types = [] }) {
  const [updateOrganization, { loading: submitting }] = useMutation(
    UPDATE_ORGANIZATION(`types{id name}`),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "OrganizationDescription.UpdateTypes",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const onAddCategory = (item) => {
    if (submitting) return;
    updateOrganization({
      variables: {
        where: { id },
        data: {
          types: {
            connect: { id: item.id },
          },
        },
      },
    });
  };

  const onRemoveCategory = (item) => {
    if (submitting) return;
    updateOrganization({
      variables: {
        where: { id },
        data: {
          types: {
            disconnect: {
              id: item.id,
            },
          },
        },
      },
    });
  };

  return (
    <div className="update-types-container">
      <div className="description-card-types">
        {types.map((t) => (
          <Chip
            key={"update-types-item-" + t.id}
            className="editable"
            onClick={() => {
              onRemoveCategory(t);
            }}
          >
            {t.name}{" "}
            <TimesIcon style={{ height: 12, stroke: "var(--blue-main)" }} />{" "}
          </Chip>
        ))}
      </div>
      <div className="update-types-form">
        <FilterInput
          placeholder={"Add a category"}
          query={ORGANIZATION_TYPES}
          items={(d) => {
            return d.organizationTypes;
          }}
          onItemSelect={(item) => {
            onAddCategory(item);
          }}
        />
        <Button
          onClick={() => {
            setIndex(0);
          }}
          className="tertiary blue dismiss-btn"
        >
          Done
        </Button>
      </div>
    </div>
  );
}

function UpdateDescription({ id, setIndex, defaultValue }) {
  const [description, setDescription] = useState(defaultValue || "");
  const [updateOrganization, { loading: submitting }] = useMutation(
    UPDATE_ORGANIZATION(`description`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATION,
          variables: { id },
        });

        cache.writeQuery({
          query: ORGANIZATION,
          variables: { id },
          data: {
            organization: {
              ...q.organization,
              description: updateOrganization.description,
            },
          },
        });

        setIndex(0);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "OrganizationDescription.UpdateDescription",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const onSubmit = (e) => {
    e && e.preventDefault();
    if (submitting) return;
    if (description.length > 5000) {
      return;
    }
    updateOrganization({
      variables: {
        where: {
          id,
        },
        data: {
          description,
        },
      },
    });
  };

  return (
    <form className="update-organization-description-form" onSubmit={onSubmit}>
      <textarea
        placeholder={"Enter page description here"}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      ></textarea>
      <div className="options">
        <div
          className={"char-count " + (description.length > 5000 ? "error" : "")}
        >
          {description.length} / 5000
          {description.length > 5000 && (
            <div className="char-count-error-desc">
              Description cannot exceed 5000 characters.
            </div>
          )}
        </div>
        <Button
          className={"tertiary small "}
          onClick={() => {
            setIndex(0);
          }}
        >
          Cancel
        </Button>

        <Button
          className={
            "primary green small " +
            (description.length > 5000 ? "disabled" : "")
          }
          onClick={onSubmit}
        >
          {submitting ? <Loader theme={"small"} /> : "Save"}
        </Button>
      </div>
    </form>
  );
}

function OrganizationDescription({ id, canManagePage }) {
  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationDescription",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="description-card">
        <div className="description-card-title">
          <Placeholder
            style={{ height: "12px", width: "120px" }}
            pulse={true}
          />
        </div>

        <div className="description-card-types">
          <Chip>
            <div style={{ height: "12px", width: "60px" }} />
          </Chip>
        </div>

        <div className="description-card-content">
          <Placeholder style={{ height: "12px", width: "100%" }} pulse={true} />
          <Placeholder
            style={{ height: "12px", width: "100%", marginTop: "8px" }}
            pulse={true}
          />
          <Placeholder
            style={{ height: "12px", width: "80%", marginTop: "8px" }}
            pulse={true}
          />
        </div>
      </Card>
    );
  }

  const org = data?.organization;

  return (
    <Card className="description-card">
      <div className="description-card-title">About {org.name}</div>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="description-card-types">
                  {org.types.map((t) => (
                    <Chip key={"org-desc-type-item-" + t.id}>{t.name}</Chip>
                  ))}
                  {canManagePage && (
                    <Chip
                      className="edit-type hover-pointer"
                      onClick={() => {
                        setIndex(1);
                      }}
                    >
                      Edit Categories
                    </Chip>
                  )}
                </div>
              );
            case 1:
              return (
                <UpdateTypes id={id} setIndex={setIndex} types={org.types} />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <Fragment>
                  <div className="description-card-content">
                    {org?.description || <div>No description added.</div>}
                  </div>
                  {canManagePage && (
                    <div className="edit-wrapper">
                      <div
                        className="edit-org-button hover-pointer"
                        onClick={() => {
                          setIndex(1);
                        }}
                      >
                        Edit Description
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            case 1:
              return (
                <UpdateDescription
                  id={id}
                  setIndex={setIndex}
                  defaultValue={org?.description}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>
    </Card>
  );
}

export default OrganizationDescription;
