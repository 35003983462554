import "./SportsList.css";
import { useState, Fragment } from "react";
import { useQuery } from "@apollo/client";
import Placeholder from "../../../components/Placeholder";
import { SPORTS } from "../../../api/sport";
import { ReactComponent as CaretDownIcon } from "../../../icons/caret-down.svg";
import { ReactComponent as EqualizerIcon } from "../../../icons/equalizer.svg";
import { ReactComponent as PinIcon } from "../../../icons/pin.svg";
import captureException from "../../../utils/captureException";

export default function SportsList({
  onSportSelect,
  onLocationClick,
  onFiltersClick,
  defaultValue,
}) {
  const [selected, setSelected] = useState(defaultValue || "");
  const { loading, error, data } = useQuery(SPORTS);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "SportsList",
        query: "SPORTS",
      },
    });
    return null;
  }

  if (loading || !data) {
    return (
      <Fragment>
        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />

        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />
        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />
        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />
      </Fragment>
    );
  }

  const sports = data?.sports || [];
  const sortedSports = [...sports].sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
  );

  if (!sports) return null;

  return (
    <Fragment>
      <div
        className="events-page-mobile__filters__item"
        style={{ paddingLeft: "4px", paddingRight: "4px", fontWeight: "600" }}
        onClick={onFiltersClick}
      >
        <EqualizerIcon style={{ marginRight: "8px" }} />
        Filters
        <CaretDownIcon style={{ marginLeft: "4px" }} />
      </div>

      <div
        className="directory-page-mobile__filters__item"
        style={{ paddingLeft: "4px", paddingRight: "4px", fontWeight: "600" }}
        onClick={onLocationClick}
      >
        <PinIcon
          style={{
            stroke: "var(--light-4)",
            marginRight: "8px",
            height: "10px",
            width: "10px",
          }}
        />
        Location
        <CaretDownIcon style={{ marginLeft: "4px" }} />
      </div>

      {sortedSports?.map((s) => (
        <div
          key={s.id}
          className={
            "directory-page-mobile__filters__item " +
            (selected === s.id ? "selected" : "")
          }
          onClick={() => {
            if (selected !== s.id) {
              setSelected(s.id);
            } else {
              setSelected("");
            }

            onSportSelect(s.id);
          }}
        >
          {s.name}
        </div>
      ))}
    </Fragment>
  );
}
