import { client } from "./client";
import { SEARCH } from "../api/user";
import makeFileUrl from "./makeFileUrl";

async function suggestPeople(searchTerm) {
  const result = await client.query({
    query: SEARCH,
    variables: {
      term: searchTerm.trim(),
    },
  });

  // console.log("Mention result:", result);
  return [
    ...result.data.search.profiles.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/profile/" + x.id,
      type: "profile",
    })),
    ...result.data.search.organizations.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/page/" + x.id,
      type: "organization",
    })),
    // ...result.data.search.events.map((x) => ({
    //   ...x,
    //   value: x.name,
    //   type: "event",
    // })),
  ];
  // const allPeople = await search({ variables: { term: searchTerm.trim() } });
  // return allPeople.map((person) => ({ ...person, value: person.name }));
}

const mentionModule = {
  allowedChars: /^[A-Za-z\s]*$/,
  mentionDenotationChars: ["@"],
  minChars: 2,
  linkTarget: "_self",
  dataAttributes: [
    "id",
    "value",
    "denotationChar",
    "link",
    "target",
    "disabled",
    "type",
  ],
  source: async function (searchTerm, renderList, mentionChar) {
    if (!searchTerm || searchTerm.length === 0) {
      // console.log("No term");
      renderList([], searchTerm);
    }
    // console.log("Searching");
    const matches = await suggestPeople(searchTerm);
    // console.log("Matches:", matches);
    renderList(matches);
  },
  renderItem: function (item, searchTerm) {
    // console.log(item);
    const profilePhoto = item.profilePhoto
      ? makeFileUrl(item.profilePhoto)
      : false;
    return `<div class="mention-item">
        <div class="mention-item__image">${
          profilePhoto ? `<img src=${profilePhoto} />` : ""
        }</div>
        <div class="mention-item__text">
          <div class="mention-item__text__primary">${item.name}</div>
          <div class="mention-item__text__secondary">@${item.handle}</div>
        </div>
      </div>`;
  },
};

export default mentionModule;
