import { useQuery } from "@apollo/client";
import "./Scoring.css";
import { useParams } from "react-router-dom";
import { FIXTURE } from "../../api/functions";
import captureException from "../../utils/captureException";
import Spinner from "../../components/Spinner";
import ScoreFootball from "../ScoreFootball";
import ScoreCricket from "../ScoreCricket";
import ScoreBoxCricket from "../ScoreBoxCricket";
import ScoreBadminton from "../ScoreBadminton";
import { Fragment } from "react";

export default function Scoring() {
  // Get the fixture id
  const { fixtureId } = useParams();
  //   console.log("FixtureId:", fixtureId);
  // Check if user is authorized for this fixture
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
    event{
      id
      sport{
        id
        name
      }
    }
  `,
    }),
    {
      variables: { id: fixtureId },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Scoring",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data?.fixture;
  const sport = fixture?.event?.sport?.name;

  if (!sport) {
    <div>Invalid sport</div>;
  }

  return (
    <Fragment>
      {sport.includes("Football") && <ScoreFootball id={fixture.id} />}
      {sport === "Cricket" && <ScoreCricket id={fixture.id} />}
      {sport === "Box Cricket" && <ScoreBoxCricket id={fixture.id} />}
      {sport === "Badminton" && <ScoreBadminton id={fixture.id} />}
    </Fragment>
  );
}
