import { gql } from "@apollo/client";

const UPDATE_FOLLOW = gql`
  mutation updateFollow($where: UniqueInput!, $data: UpdateFollowInput!) {
    updateFollow(where: $where, data: $data) {
      id
      confirmed
      handled
      follower {
        id
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
      following {
        id
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export default UPDATE_FOLLOW;
