import "./OrganizationEvents.css";
import { ORGANIZATION_EVENTS } from "../../../api/organization";
import { useQuery } from "@apollo/client";
import Spinner from "../../../components/Spinner";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import { EventItem } from "../../../mobileComponents/Events";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function OrganizationEvents({ id }) {
  const { error, data, fetchMore, networkStatus } = useQuery(
    ORGANIZATION_EVENTS,
    {
      variables: { id, takeRegistrations: 4, take: 5 },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationEvents",
        query: "ORGANIZATION_EVENTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div className="organization-events-mobile">
        <div className="organization-events-mobile-list">
          {[1, 2].map((ev) => (
            <div className="events-page-mobile__list__item">
              <div className="events-page-mobile__list__item__cover"></div>

              <div className="events-page-mobile__list__item__text">
                <div className="events-page-mobile__list__item__text__primary">
                  <Placeholder
                    style={{ height: "12px", width: "200px" }}
                    pulse={true}
                  />
                </div>

                <div className="events-page-mobile__list__item__text__secondary">
                  <Placeholder
                    style={{ height: "8px", width: "100px" }}
                    pulse={true}
                  />
                </div>

                <div className="events-page-mobile__list__item__text__secondary">
                  <Placeholder
                    style={{ height: "8px", width: "80px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const org = data?.organization;
  const events = org?.events;

  return (
    <div className="organization-events-mobile">
      {!!events?.length && (
        <div className="organization-events-mobile-list">
          {events.map((ev) => (
            <EventItem key={ev.id} ev={ev} />
          ))}
          <div className="show-more-container">
            <Button
              className="primary small blue show-more-button"
              onClick={() => {
                fetchMore({
                  variables: {
                    cursor: events[events.length - 1].id,
                  },
                });
              }}
            >
              {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
