function determineOnboardingStep(profile) {
  if (!profile) return 0;
  const { phone, dob, gender, emailVerified, cities } = profile;

  if (!cities.length || !phone || !dob || !gender) {
    return 0;
  }

  if (!emailVerified) {
    return 2;
  }

  return 2;
}

export default determineOnboardingStep;
