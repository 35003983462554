import { gql } from "@apollo/client";

const USER_PROFILE = gql`
  query userProfileQuery {
    userProfile {
      id
      name
      email
      handle
      phone
      city {
        id
        type {
          id
          name
        }
        value
      }
      state {
        id
        type {
          id
          name
        }
        value
      }
      country {
        id
        type {
          id
          name
        }
        value
      }
      dob
      gender
      worksInSport
      playsSport
      interests {
        id
        name
      }
      emailVerified
      profilePhoto {
        id
        filename
      }
      coverPhoto {
        id
        filename
      }
      cities {
        id
        type {
          id
          name
        }
        value
      }
    }
  }
`;

export default USER_PROFILE;
