import { useState, Fragment } from "react";
import "./Settings.css";
import { ReactComponent as ChevronLeft } from "../../../../icons/chevron-left.svg";
import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import { ReactComponent as PencilIcon } from "../../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../../icons/trash.svg";
import { ReactComponent as ListIcon } from "../../../../icons/list.svg";
import { ReactComponent as PitchIcon } from "../../../../icons/pitch.svg";
import { ReactComponent as CheckIcon } from "../../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../../icons/times.svg";
import Card from "../../../Card";
import NewDropdown from "../../../NewDropdown";
import Spinner from "../../../Spinner";
import Pitch from "../../../Pitch";
import Button from "../../../Button";
import { useQuery, useMutation, gql } from "@apollo/client";
import { FORMATIONS } from "../../../../api/sport";
import {
  FIXTURE_STATS,
  FIXTURE,
  UPDATE_FIXTURE,
  UPDATE_FIXTURE_ROLE,
  CREATE_FIXTURE_ROLE,
  DELETE_FIXTURE_ROLE,
} from "../../../../api/fixture";
import { EVENT_REGISTRATIONS } from "../../../../api/event";
import { useAppState } from "../../../../utils/appState";
import { useHistory } from "react-router-dom";
import Loader from "../../../Loader";
import Avatar from "../../../Avatar";
import captureException from "../../../../utils/captureException";

function PenaltyShootoutSettings({ period, fixtureId, canDelete }) {
  const [updateFixture, { loading: updating }] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreFootball.Settings.PenaltyShootoutSettings",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  return (
    <Card className="period-settings-card" style={{ marginTop: "32px" }}>
      <div className="period-settings-card__title">
        Penalty Shootout Settings
      </div>
      <div className="period-settings-card__body">
        {period ? (
          <div className="period-settings-card__body__action-wrapper">
            <div className="period-settings-card__body__blurb">
              Penalty shootout has been activated.
            </div>
            <div className="period-settings-card__body__action">
              <Button
                className={
                  "small red primary " + (!canDelete ? "disabled" : "")
                }
                onClick={() => {
                  if (updating) return;
                  const vars = {};
                  vars.deleteMany = [{ id: period.id }];

                  updateFixture({
                    variables: {
                      where: { id: fixtureId },
                      data: {
                        periods: vars,
                      },
                    },
                    update: (cache, { data: { updateFixture } }) => {
                      // console.log(updateFixture);
                      const q = cache.readQuery({
                        query: FIXTURE,
                        variables: { id: fixtureId },
                      });

                      cache.writeQuery({
                        query: FIXTURE,
                        variables: { id: fixtureId },
                        data: {
                          fixture: {
                            ...q.fixture,
                            periods: [...updateFixture.periods],
                          },
                        },
                      });

                      // setPeriods(
                      //   [...updateFixture.periods].sort(
                      //     (a, b) => a.order - b.order
                      //   )
                      // );
                      // dismiss();
                    },
                  });
                }}
              >
                {updating ? <Loader theme={"small"} /> : "Deactivate"}
              </Button>
            </div>
          </div>
        ) : (
          <div className="period-settings-card__body__action-wrapper">
            <div className="period-settings-card__body__blurb">
              Did this game go to penalties? Click the button to activate
              penalty shootouts.
            </div>
            <div
              className="period-settings-card__body__action"
              onClick={() => {
                if (updating) return;
                const vars = {};
                vars.create = [
                  {
                    label: "Penalty Shootout",
                    order: 5,
                    duration: 0,
                    metas: {
                      create: [
                        {
                          label: "periodType",
                          stringValue: "PENALTY SHOOTOUT",
                        },
                      ],
                    },
                  },
                ];

                updateFixture({
                  variables: {
                    where: { id: fixtureId },
                    data: {
                      periods: vars,
                    },
                  },
                  update: (cache, { data: { updateFixture } }) => {
                    // console.log(updateFixture);
                    const q = cache.readQuery({
                      query: FIXTURE,
                      variables: { id: fixtureId },
                    });

                    cache.writeQuery({
                      query: FIXTURE,
                      variables: { id: fixtureId },
                      data: {
                        fixture: {
                          ...q.fixture,
                          periods: [...updateFixture.periods],
                        },
                      },
                    });

                    // setPeriods(
                    //   [...updateFixture.periods].sort(
                    //     (a, b) => a.order - b.order
                    //   )
                    // );
                    // dismiss();
                  },
                });
              }}
            >
              <Button className="small green primary">
                {updating ? <Loader theme={"small"} /> : "Activate"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

function LineupsListItem({
  player,
  fixture,
  team,
  subtype = "STARTER",
  disable = () => false,
  onItemClick = () => {},
}) {
  const disabled = disable(player?.profile?.id);

  return (
    <div
      className={"lineup-list-item " + (disabled ? "disabled" : "")}
      onClick={() => {
        if (disabled) return;
        // console.log(player.jerseyNum);
        onItemClick(player?.profile?.id, player?.jerseyNum);
      }}
    >
      <div className="image">
        {player.profile?.profilePhoto && (
          <Avatar media={player.profile.profilePhoto} />
        )}
      </div>
      <div className="name">
        <div className="name__primary">{player?.profile?.name}</div>
        <div className="name__secondary">@{player?.profile?.handle}</div>
      </div>
      <div className="num">{player?.jerseyNum}</div>
      {/* <div className="checkbox">{loading && <Spinner />}</div> */}
      {disabled && <div className="disabled" />}
    </div>
  );
}

function LineupList({
  fixture,
  team,
  subtype,
  disable,
  label,
  onItemClick,
  setOverlay,
}) {
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: {
      id: fixture.event.id,
      where: { organization: { id: team.organization.id } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreFootball.Settings.LineupList",
        query: "EVENT_RIGISTRATIONS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="lineups-page">
        <div className="lineup-editor">
          <Spinner />
        </div>
      </div>
    );
  }

  const registrations = data.event.registrations;

  return (
    <div className="fixture-lineups-list-container">
      <div className="fixture-lineups-list-container__header">
        {label || "Select Starters"}
        <div
          className="dismiss-btn"
          onClick={() => {
            setOverlay(false);
            // dismiss();
          }}
        >
          Done
        </div>
      </div>
      <div className="lineup-list">
        {!registrations?.length && (
          <div className="no-data">No players registered for this team.</div>
        )}
        {registrations.map((p) => (
          <LineupsListItem
            key={p.id}
            player={p}
            fixture={fixture}
            team={team}
            subtype={subtype}
            disable={disable}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
}

function EditJerseyNumber({ role, canManageFixture, players }) {
  // console.log(players);

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(role.order || "");
  const [updateFixtureRole, { loading }] = useMutation(UPDATE_FIXTURE_ROLE, {
    update: (cache, { data: { updateFixtureRole } }) => {
      cache.modify({
        id: cache.identify(role),
        fields: {
          order() {
            return updateFixtureRole.order;
          },
        },
      });

      setEditing(false);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreFootball.Settings.EditJerseyNumber",
          mutation: "UPDATE_FIXTURE_ROLE",
        },
      });
      console.log(error);
      setError(true);
    },
  });

  return (
    <Fragment>
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (loading) {
              return;
            }

            if (value && Number(value) === role.order) {
              setEditing(false);
            }

            const numTaken =
              value &&
              Number(value) &&
              players.find((x) => x.order === Number(value));
            if (numTaken) {
              setError(true);
              return;
            }
            updateFixtureRole({
              variables: {
                where: { id: role.id },
                data: {
                  order: Number(value),
                },
              },
            });
          }}
        >
          <input
            type="text"
            className={
              "starters__body__list__item__option placeholder " +
              (error ? "error" : "")
            }
            value={value}
            onChange={(e) => {
              setError(false);
              setValue(e.target.value);
            }}
          />
        </form>
      ) : (
        <div
          className="starters__body__list__item__option placeholder"
          onClick={() => {
            setEditing(true);
          }}
        >
          {typeof role.order === "number" ? role.order : "J.Num"}
        </div>
      )}
    </Fragment>
  );
}

function FormationsList({ sportId, fixtureRole, setShowMenu }) {
  const { loading, error, data } = useQuery(FORMATIONS, {
    variables: { where: { sport: { id: sportId } } },
  });

  const [updateFixtureRole, { loading: submitting }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      update: (cache, { data: { updateFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixtureRole),
          fields: {
            formation(_, { INVALIDATE }) {
              if (!updateFixtureRole.formation) {
                return INVALIDATE;
              }
              const newFormationRef = cache.writeFragment({
                data: updateFixtureRole.formation,
                fragment: gql`
                  fragment NewFormation on Formation {
                    id
                    name
                    positions {
                      id
                      positionType {
                        id
                        shortName
                      }
                      x
                      y
                    }
                  }
                `,
              });

              return newFormationRef;
            },
          },
        });

        setShowMenu(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreFootball.Settings.FormationsList",
            mutation: "UPDATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreFootball.Settings.FormationsList",
        query: "FORMATIONS",
      },
    });
    return <div>Error</div>;
  }

  const formations = data?.formations || [];

  return (
    <Card className="select-formation-menu">
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="select-formation-menu__list">
          <div
            className="select-formation-menu__list__item"
            onClick={() => {
              if (submitting) {
                return;
              }

              updateFixtureRole({
                variables: {
                  where: {
                    id: fixtureRole.id,
                  },
                  data: {
                    formation: { disconnect: true },
                  },
                },
              });
            }}
          >
            None
          </div>
          {formations.map((f) => (
            <div
              key={f.id}
              className="select-formation-menu__list__item"
              onClick={() => {
                if (submitting) {
                  return;
                }

                updateFixtureRole({
                  variables: {
                    where: {
                      id: fixtureRole.id,
                    },
                    data: {
                      formation: { connect: { id: f.id } },
                    },
                  },
                });
              }}
            >
              {f.name}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function StartersList({ team, players, fixture, onItemClick }) {
  return (
    <div className="starters__body__list">
      {team.formation ? (
        <div className="starters__body__list__items">
          {team.formation?.positions
            .map((p) => ({
              ...p,
              role: players.find(
                (r) =>
                  r.position?.id === p.id &&
                  r.subtypes.find((x) => x.name === "STARTER")
              ),
            }))
            .map((p) => (
              <div key={p.id} className="starters__body__list__item">
                {p.role && (
                  <div
                    className="starters__body__list__item__option delete"
                    onClick={() => {
                      onItemClick(p);
                    }}
                    style={{ marginLeft: 0, marginRight: "8px" }}
                  >
                    <TrashIcon className="starters__body__list__item__option__icon" />
                  </div>
                )}
                <div className="starters__body__list__item__image">
                  {p.role?.profile?.profilePhoto && (
                    <Avatar media={p.role.profile.profilePhoto} />
                  )}
                </div>
                <div className="starters__body__list__item__name">
                  {p.role ? (
                    p.role.profile?.name
                  ) : (
                    <div
                      onClick={() => {
                        onItemClick(p);
                      }}
                    >
                      {"Select player"}
                    </div>
                  )}
                </div>
                <div className="starters__body__list__item__options">
                  {p.role && (
                    <EditJerseyNumber role={p.role} players={players} />
                  )}

                  <div className="starters__body__list__item__option placeholder">
                    {p.positionType.shortName}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="starters__body__list__items">
          {/* {!players.filter((p) => {
            return p.subtypes.find((x) => x.name === "STARTER");
          }).length && <div className="no-data">No starters added yet.</div>} */}
          {players
            .filter((p) => {
              return p.subtypes.find((x) => x.name === "STARTER");
            })
            .map((p) => (
              <div key={p.id} className="starters__body__list__item">
                <div className="starters__body__list__item__image"></div>
                <div className="starters__body__list__item__name">
                  {p.profile?.name}
                </div>
                <div className="starters__body__list__item__options">
                  <EditJerseyNumber role={p} players={players} />

                  <div
                    className="starters__body__list__item__option delete"
                    onClick={() => {
                      onItemClick({ role: p });
                    }}
                  >
                    <TrashIcon className="starters__body__list__item__option__icon" />
                  </div>
                </div>
              </div>
            ))}
          <div
            className="starters__body__list__item"
            onClick={() => {
              onItemClick({});
            }}
          >
            <div className="starters__body__list__item__image"></div>
            <div className="starters__body__list__item__name hover-pointer">
              Add Starter
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Starters({
  setOverlay,
  players,
  fixture,
  team,
  addStarter,
  removeStarter,
}) {
  const [view, setView] = useState("list");
  return (
    <Card className="starters">
      <div className="card-title">Starters</div>
      <div className="starters__title">
        <NewDropdown
          target={
            <div className="starters__title__text">
              {team.formation?.name || "Select Formation"}{" "}
              <ChevronDown className="starters__title__text__icon" />
            </div>
          }
          menu={({ setShowMenu }) => (
            <FormationsList
              sportId={fixture.event.sport.id}
              fixtureRole={team}
              setShowMenu={setShowMenu}
            />
          )}
        />

        <div className="starters__title__selection">
          <div
            className={
              "starters__title__selection__option " +
              (view === "list" ? "selected" : "")
            }
            onClick={() => {
              setView("list");
            }}
          >
            <ListIcon
              style={{ height: "14px" }}
              className="starters__title__selection__option__icon"
            />
          </div>
          <div
            className={
              "starters__title__selection__option " +
              (view === "pitch" ? "selected" : "")
            }
            onClick={() => {
              setView("pitch");
            }}
          >
            <PitchIcon
              style={{ height: "18px" }}
              className="starters__title__selection__option__icon"
            />
          </div>
        </div>
      </div>

      <div className="starters__body">
        {view === "pitch" ? (
          <Card className="pitch-card">
            <Pitch
              width={324}
              markers={
                team.formation?.positions.map((p) => ({
                  ...p,
                  label: p.positionType.shortName,
                  // secondaryLabel: lastname(
                  //   lineupRoles.find((lr) => lr.position?.id === p.id)?.profile
                  //     ?.name
                  // ),
                  secondaryLabel: "",
                  role: players.find(
                    (r) =>
                      r.position?.id === p.id &&
                      r.subtypes.find((x) => x.name === "STARTER")
                  ),
                })) || []
              }
              markerOnClick={(marker) => {
                if (marker.role) {
                  removeStarter(marker.role.id);
                } else {
                  setOverlay({
                    onItemClick: (profileId, jerseyNum) => {
                      addStarter(
                        profileId,
                        team.organization.id,
                        marker.id,
                        "STARTER",
                        jerseyNum
                      );
                    },
                  });
                  // setAppState({
                  //   modal: (
                  //     <LineupList
                  //       fixture={fixture}
                  //       team={team}
                  //       disable={(role) =>
                  //         role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                  //       }
                  //       onItemClick={(profileId) => {
                  //         addStarter(
                  //           profileId,
                  //           team.organization.id,
                  //           marker.id,
                  //           "STARTER"
                  //         );
                  //       }}
                  //     />
                  //   ),
                  // });
                }
              }}
              lockEditing={false}
            />
          </Card>
        ) : (
          <StartersList
            team={team}
            fixture={fixture}
            players={players}
            onItemClick={(item) => {
              if (item.role) {
                removeStarter(item.role.id);
              } else {
                setOverlay({
                  onItemClick: (profileId, jerseyNum) => {
                    addStarter(
                      profileId,
                      team.organization.id,
                      item.id,
                      "STARTER",
                      jerseyNum
                    );
                  },
                });
                // setAppState({
                //   modal: (
                //     <LineupList
                //       fixture={fixture}
                //       team={team}
                //       onItemClick={(profileId) => {
                //         addStarter(
                //           profileId,
                //           team.organization.id,
                //           item.id,
                //           "STARTER"
                //         );
                //       }}
                //     />
                //   ),
                // });
              }
            }}
          />
        )}
      </div>
    </Card>
  );
}

function Subs({ setOverlay, players, fixture, team, addSub, removeSub }) {
  return (
    <Card className="starters">
      <div className="card-title">Subs</div>
      <div className="subs__body">
        <div className="starters__body__list">
          <div className="starters__body__list__items">
            {players
              .filter((p) => {
                return p.subtypes.find((x) => x.name === "SUBSTITUTE");
              })
              .map((p) => (
                <div key={p.id} className="starters__body__list__item">
                  <div className="starters__body__list__item__image">
                    {p.profile?.profilePhoto && (
                      <Avatar media={p.profile.profilePhoto} />
                    )}
                  </div>
                  <div className="starters__body__list__item__name">
                    {p.profile?.name}
                  </div>
                  <div className="starters__body__list__item__options">
                    <EditJerseyNumber role={p} players={players} />
                    {/* <div className="starters__body__list__item__option placeholder">
                      {p.order || (canManageFixture ? "J.Num" : "")}
                    </div> */}
                    <div
                      className="starters__body__list__item__option delete"
                      onClick={() => {
                        removeSub(p.id);
                      }}
                    >
                      <TrashIcon className="starters__body__list__item__option__icon" />
                    </div>
                  </div>
                </div>
              ))}
            <div
              className="starters__body__list__item hover-pointer"
              onClick={() => {
                setOverlay({
                  onItemClick: (profileId, jerseyNum) => {
                    // console.log("J Num:", jerseyNum);
                    addSub(
                      profileId,
                      team.organization.id,
                      false,
                      "SUBSTITUTE",
                      jerseyNum
                    );
                  },
                });
              }}
            >
              <div className="starters__body__list__item__image"></div>
              <div className="starters__body__list__item__name">Add Sub</div>
            </div>
          </div>
          {/* {canManageFixture && (
          <div className="starters__body__list__footer">
            <Button
              className="primary orange medium"
              onClick={() => {
                setAppState({
                  modal: (
                    <LineupList
                      fixture={fixture}
                      team={team}
                      subtype={"SUBSTITUTE"}
                      disable={(role) =>
                        role?.subtypes.find((x) => x.name === "STARTER")
                      }
                      label={"Select Substitutes"}
                    />
                  ),
                });
              }}
            >
              Edit Subs
            </Button>
          </div>
        )} */}
        </div>
      </div>
    </Card>
  );
}

function Period({ period, index, fixtureId, setPeriods, canDelete }) {
  const [editing, setEditing] = useState(false);
  const [updateFixture, { loading }] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreFootball.Settings.Period",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  if (editing) {
    return (
      <CreateOrEditPeriod
        id={period.id}
        defaultLabel={period.label}
        defaultDuration={period.duration}
        order={period.order}
        setPeriods={setPeriods}
        fixtureId={fixtureId}
        dismiss={() => {
          setEditing(false);
        }}
      />
    );
  }

  return (
    <div className="period-settings-card__body__item">
      <div className="period-settings-card__body__item__details">
        <div className="order">{index + 1}.</div>
        {period.label} ({period.duration || 0} Mins)
      </div>
      <div className="period-settings-card__body__item__options">
        <button
          className="period-settings-card__body__item__options__option edit"
          onClick={() => {
            setEditing(true);
          }}
        >
          <PencilIcon className="period-settings-card__body__item__options__option__icon edit" />
        </button>
        <button
          className={
            "period-settings-card__body__item__options__option delete " +
            (!canDelete || loading ? "disabled" : "")
          }
          onClick={() => {
            if (!canDelete || loading) return;

            updateFixture({
              variables: {
                where: { id: fixtureId },
                data: {
                  periods: {
                    deleteMany: [{ id: period.id }],
                  },
                },
              },
              update: (cache, { data: { updateFixture } }) => {
                // console.log(updateFixture);
                const q = cache.readQuery({
                  query: FIXTURE,
                  variables: { id: fixtureId },
                });

                cache.writeQuery({
                  query: FIXTURE,
                  variables: { id: fixtureId },
                  data: {
                    fixture: {
                      ...q.fixture,
                      periods: [...updateFixture.periods],
                    },
                  },
                });

                setPeriods(
                  [...updateFixture.periods]
                    .filter(
                      (p) =>
                        !p.metas.find(
                          (m) =>
                            m.label === "periodType" &&
                            m.stringValue === "PENALTY SHOOTOUT"
                        )
                    )
                    .sort((a, b) => a.order - b.order)
                );
              },
            });
          }}
        >
          {loading ? (
            <Spinner size={12} color={"var(--red-darker)"} />
          ) : (
            <TrashIcon className="period-settings-card__body__item__options__option__icon delete" />
          )}
        </button>
      </div>
    </div>
  );
}

function CreateOrEditPeriod({
  id,
  defaultLabel,
  defaultDuration,
  order,
  setPeriods,
  fixtureId,
  dismiss,
}) {
  const [label, setLabel] = useState(defaultLabel || "");
  const [duration, setDuration] = useState(defaultDuration || 0);
  const [updateFixture, { loading }] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreFootball.Settings.CreateOrEditPeriod",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  return (
    <form
      className="period-form"
      onSubmit={(e) => {
        e.preventDefault();

        if (loading) return;
        const vars = {};

        if (id) {
          vars.update = {
            where: { id },
            data: {
              label: label,
              order: order,
              duration: Number(duration) || 0,
            },
          };
        } else {
          vars.create = [
            {
              label: label,
              order: order,
              duration: Number(duration) || 0,
            },
          ];
        }

        updateFixture({
          variables: {
            where: { id: fixtureId },
            data: {
              periods: vars,
            },
          },
          update: (cache, { data: { updateFixture } }) => {
            // console.log(updateFixture);
            const q = cache.readQuery({
              query: FIXTURE,
              variables: { id: fixtureId },
            });

            cache.writeQuery({
              query: FIXTURE,
              variables: { id: fixtureId },
              data: {
                fixture: {
                  ...q.fixture,
                  periods: [...updateFixture.periods],
                },
              },
            });

            setPeriods(
              [...updateFixture.periods]
                .filter(
                  (p) =>
                    !p.metas.find(
                      (m) =>
                        m.label === "periodType" &&
                        m.stringValue === "PENALTY SHOOTOUT"
                    )
                )
                .sort((a, b) => a.order - b.order)
            );
            dismiss();
          },
        });
      }}
    >
      <div className="period-form__row">
        <div className="period-form__group text">
          <div className="period-form__group__label">Label</div>
          <input
            type="text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            className="period-form__input"
            placeholder="Enter label here"
          />
        </div>

        <div className="period-form__group">
          <div className="period-form__group__label">Minutes</div>
          <input
            type="number"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            className="period-form__input duration"
          />
        </div>

        <div className="period-form__options">
          <button
            type="submit"
            className={
              "period-form__options__option submit " +
              (loading ? "disabled" : "")
            }
          >
            {loading ? (
              <Spinner size={12} color={"var(--green-darker)"} />
            ) : (
              <CheckIcon className="icon" />
            )}
          </button>
          <div
            className={
              "period-form__options__option cancel " +
              (loading ? "disabled" : "")
            }
            onClick={() => {
              dismiss();
            }}
          >
            <TimesIcon className="icon" />
          </div>
        </div>
      </div>
    </form>
  );
}

function PeriodSettings({ defaultPeriods, fixtureId }) {
  const [periods, setPeriods] = useState(
    defaultPeriods.filter(
      (p) =>
        !p.metas.find(
          (m) =>
            m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
        )
    )
  );
  const [adding, setAdding] = useState(false);
  const { loading, error, data } = useQuery(FIXTURE_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreFootball.Settings.PeriodSettings",
        query: "FIXTURE_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="period-settings-card">
        <div className="period-settings-card__title">
          Edit periods in this fixture
        </div>
        <div className="period-settings-card__body">
          <Spinner />
        </div>
      </Card>
    );
  }

  const stats = data?.fixture?.stats;
  const shootout = defaultPeriods.find((p) =>
    p.metas.find(
      (m) => m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
    )
  );

  return (
    <Fragment>
      <Card className="period-settings-card">
        <div className="period-settings-card__title">
          Edit periods in this fixture
        </div>
        <div className="period-settings-card__body">
          {periods?.map((p, i) => (
            <Period
              key={p.id}
              period={p}
              index={i}
              // durations={durations}
              // setDurations={setDurations}
              setPeriods={setPeriods}
              fixtureId={fixtureId}
              canDelete={
                stats?.find((x) => x.fixturePeriod?.id === p.id) ? false : true
              }
            />
          ))}
          {adding ? (
            <CreateOrEditPeriod
              dismiss={() => {
                setAdding(false);
              }}
              order={periods.length}
              fixtureId={fixtureId}
              setPeriods={setPeriods}
            />
          ) : (
            <div
              className="period-settings-card__add-period"
              onClick={() => {
                setAdding(true);
              }}
            >
              + Add a period
            </div>
          )}
        </div>
      </Card>
      <PenaltyShootoutSettings
        period={shootout}
        fixtureId={fixtureId}
        canDelete={
          shootout && stats?.find((x) => x.fixturePeriod?.id === shootout.id)
            ? false
            : true
        }
      />
    </Fragment>
  );
}

function LineupSettings({ id }) {
  const [overlay, setOverlay] = useState(false);
  const { loading, data, error } = useQuery(FIXTURE, { variables: { id } });
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const [updateFixtureRole, { loading: updatingFixtureRole }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreFootball.Settings.LineupSettings",
            mutation: "UPDATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );
  const [createFixtureRole, { loading: creatingFixtureRole }] = useMutation(
    CREATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreFootball.Settings.LineupSettings",
            mutation: "CREATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );
  const [deleteFixtureRole, { loading: deletingFixtureRole }] = useMutation(
    DELETE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreFootball.Settings.LineupSettings",
            mutation: "DELETE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreFootball.Settings.LineupSettings",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="lineup-settings-card">
        <div className="lineup-settings-card__title">Edit lineups</div>
        <div
          style={{
            height: "160px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      </Card>
    );
  }

  const fixture = data.fixture;
  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const addLineupPlayer = (
    profileId,
    organizationId,
    formationPositionId,
    subtype,
    jerseyNum
  ) => {
    // console.log("Jersey NUm:", jerseyNum);
    if (creatingFixtureRole || updatingFixtureRole) {
      return;
    }

    const existingRole = fixture.roles.find(
      (r) => r.type.name === "LINEUP PLAYER" && r.profile?.id === profileId
    );

    if (existingRole) {
      // Update existing role

      updateFixtureRole({
        variables: {
          where: { id: existingRole.id },
          data: {
            subtypes: {
              connect: { name: subtype },
              ...(subtype === "STARTER"
                ? { disconnect: { name: "SUBSTITUTE" } }
                : { disconnect: { name: "STARTER" } }),
            },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
          },
        },
        update: (cache, { data: { updateFixtureRole } }) => {
          cache.modify({
            id: cache.identify(existingRole),
            fields: {
              subtypes() {
                return updateFixtureRole.subtypes;
              },
              position() {
                return updateFixtureRole.position;
              },
            },
          });

          setOverlay(false);
        },
      });
    } else {
      // Add new role
      createFixtureRole({
        variables: {
          data: {
            fixture: { connect: { id: fixture.id } },
            type: { connect: { name: "LINEUP PLAYER" } },
            subtypes: { connect: { name: subtype } },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
            organization: { connect: { id: organizationId } },
            profile: { connect: { id: profileId } },
            ...(jerseyNum ? { order: jerseyNum } : {}),
          },
        },
        update: (cache, { data: { createFixtureRole } }) => {
          cache.modify({
            id: cache.identify(fixture),
            fields: {
              roles(existingRefs, { readField }) {
                const newRef = cache.writeFragment({
                  data: createFixtureRole,
                  fragment: gql`
                    fragment NewFixtureRole on FixtureRole {
                      id
                      order
                      type {
                        id
                        name
                      }
                      subtypes {
                        id
                        name
                      }
                      profile {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                      formation {
                        id
                        name
                        positions {
                          id
                          positionType {
                            id
                            shortName
                          }
                          x
                          y
                        }
                      }
                      position {
                        id
                      }
                    }
                  `,
                });

                return [newRef, ...existingRefs];
              },
            },
          });
          setOverlay(false);
        },
      });
    }
  };
  const removeLineupPlayer = (roleId) => {
    if (deletingFixtureRole) return;
    deleteFixtureRole({
      variables: {
        id: roleId,
      },
      update: (cache, { data: { deleteFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            roles(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) => deleteFixtureRole.id !== readField("id", ref)
              );
            },
          },
        });
      },
    });
  };

  return (
    <Card className="lineup-settings-card">
      <div
        className={"lineup-settings-card__overlay " + (overlay ? "show" : "")}
      >
        <LineupList
          fixture={fixture}
          team={participants[selectedItemIndex]}
          disable={(profileId) =>
            fixture.roles.find(
              (r) =>
                r.type.name === "LINEUP PLAYER" && r.profile?.id === profileId
            )
          }
          onItemClick={(profileId, jerseyNum) => {
            overlay?.onItemClick(profileId, jerseyNum);
            // addStarter(
            //   profileId,
            //   team.organization.id,
            //   marker.id,
            //   "STARTER"
            // );
          }}
          setOverlay={setOverlay}
        />
      </div>

      <div className="lineup-settings-card__title">Edit lineups</div>
      <div className="lineup-settings-card__body">
        <div className="lineup-settings-card__body__selector">
          {participants.map((p, i) => (
            <div
              key={p.id}
              className={
                "lineup-settings-card__body__selector__item " +
                (selectedItemIndex === i ? "selected" : "")
              }
              onClick={() => {
                setSelectedItemIndex(i);
              }}
            >
              <div className="lineup-settings-card__body__selector__item__img">
                {p?.organization?.profilePhoto && (
                  <Avatar media={p.organization.profilePhoto} />
                )}
              </div>
              <div className="lineup-settings-card__body__selector__item__text">
                {p?.organization?.name}
              </div>
            </div>
          ))}
        </div>

        <div className="lineup-settings-card__body__starters">
          <Starters
            setOverlay={setOverlay}
            players={fixture.roles.filter(
              (r) =>
                r.type.name === "LINEUP PLAYER" &&
                r.organization.id ===
                  participants[selectedItemIndex]?.organization?.id
            )}
            fixture={fixture}
            team={participants[selectedItemIndex]}
            addStarter={addLineupPlayer}
            removeStarter={removeLineupPlayer}
          />
        </div>

        <div className="lineup-settings-card__body__subs">
          <Subs
            setOverlay={setOverlay}
            players={fixture.roles.filter(
              (r) =>
                r.type.name === "LINEUP PLAYER" &&
                r.organization.id ===
                  participants[selectedItemIndex]?.organization?.id
            )}
            fixture={fixture}
            team={participants[selectedItemIndex]}
            addSub={addLineupPlayer}
            removeSub={removeLineupPlayer}
          />
        </div>
      </div>
    </Card>
  );
}

export default function Settings({
  fixtureId,
  setEditSettings,
  statsCacheState,
  defaultPeriods,
}) {
  const [, setAppState] = useAppState();
  const history = useHistory();

  return (
    <div className="football-settings">
      <div className="football-settings-header">
        <div
          className="close-scoring-btn"
          onClick={() => {
            history.push("/app/fixture/" + fixtureId);
            setAppState({ modal: false });
          }}
        >
          <div className="icon">
            <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
          </div>
          <div className="text">Back to fixture</div>
        </div>
        <div className="settings-title">Match Settings</div>

        <div className="football-settings-header__btn-wrapper">
          {defaultPeriods?.length && (
            <Button
              className="green medium primary"
              onClick={() => {
                setEditSettings(false);
              }}
            >
              Proceed to Collect Stats
            </Button>
          )}
        </div>
      </div>

      <div className="settings-form-new">
        <div className="settings-form-new__body">
          <div className="period-settings-new">
            <PeriodSettings
              defaultPeriods={defaultPeriods}
              fixtureId={fixtureId}
            />
          </div>
          <div className="lineup-settings-new">
            <LineupSettings id={fixtureId} />
          </div>
        </div>
      </div>
    </div>
  );
}
