import { gql } from "@apollo/client";

const UPDATE_MEDIA = gql`
  mutation updateMedia($where: UniqueInput!, $data: UpdateMediaInput!) {
    updateMedia(where: $where, data: $data) {
      id
      createdAt
      clapCount
      isClapped
      createdByProfile {
        id
        name
      }
      description
      truncatedDescription
      contentLength
      tags {
        id
        party {
          id
          profile {
            id
            name
            handle
          }
          organization {
            id
            name
            handle
          }
          event {
            id
            name
          }
        }
      }
      source {
        id
        event {
          id
          name
          organizedBy {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        fixture {
          id
          event {
            id
            name
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          roles {
            id
            order
            type {
              id
              name
            }
            subtypes {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
            formation {
              id
              name
              positions {
                id
                positionType {
                  id
                  shortName
                }
                x
                y
              }
            }
          }
          placements {
            id
            value
            valueString
            organization {
              id
              name
            }
          }
          event {
            id
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_MEDIA;
