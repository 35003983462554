import { gql } from "@apollo/client";

export function ORGANIZATION_EVENTS({ selectionSet }) {
  return gql`
    query organizationQuery(
      $id: ID!
      $take: Int
      $filters: OrganizationEventFilters
      $cursor: ID
    ) {
      organization(id: $id) {
        id
        events(take: $take, filters: $filters, cursor: $cursor) {
          id          
          ${selectionSet}
        }
      }
    }
  `;
}
