import "./FixtureReport.css";
import { Quill } from "react-quill";
import QuillMention from "quill-mention";
import quillEmoji from "quill-emoji";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FIXTURE_REPORT, FIXTURE_MEDIA } from "../../api/fixture";
import Spinner from "../Spinner";
import Button from "../Button";
import CreatePost from "../CreatePost";
import EditPost from "../EditPost";
import Post from "../Post";
import captureException from "../../utils/captureException";
import CreateMatchReport from "../CreateMatchReport";
import MatchReport from "../MatchReport";
import EditMatchReport from "../EditMatchReport";
import DeleteMatchReport from "../DeleteMatchReport";

Quill.register("modules/mentions", QuillMention);
Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true
);

function FixtureReport({ id, canManageFixture }) {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { loading, error, data } = useQuery(FIXTURE_REPORT, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureReport",
        query: "FIXTURE_REPORT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const report = data?.fixture?.report;
  // console.log("Fixture report:", data);

  return (
    <div className="fixture-report">
      <div className="fixture-report__container">
        {!report && !editing && (
          <div className="no-data">
            <div className="text">
              No match report added yet. Check back soon!
            </div>
            {canManageFixture && (
              <div
                className="link"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Add Match Report
              </div>
            )}
          </div>
        )}

        {/* {report && !editing && canManageFixture && (
          <div className="fixture-report__edit-button-container">
            <Button
              className="primary blue small link"
              onClick={() => {
                setEditing(true);
              }}
            >
              Edit Match Report
            </Button>
          </div>
        )} */}

        {editing && canManageFixture && report && (
          <EditMatchReport
            post={report}
            dismiss={() => {
              setEditing(false);
            }}
            mediaQueries={{
              photos: {
                onUploadPhoto: (cache, createMedia) => {},
              },
            }}
            queryInfo={{
              onUpdateData: (cache, updatePost) => {
                // console.log("Updated post:", updatePost);
                cache.modify({
                  id: cache.identify(report),
                  fields: {
                    title() {
                      return updatePost.title;
                    },
                    content() {
                      return updatePost.content;
                    },
                    truncatedContent() {
                      return updatePost.truncatedContent;
                    },
                    contentLength() {
                      return updatePost.contentLength;
                    },
                    tags() {
                      return updatePost.tags;
                    },
                  },
                });

                setEditing(false);
              },
            }}
            sourceId={id}
            sourceType={"fixture"}
          />
        )}

        {editing && canManageFixture && !report && (
          <CreateMatchReport
            sourceId={id}
            sourceType={"fixture"}
            postType={"REPORT"}
            mediaQueries={{
              photos: {
                onUploadPhoto: (cache, createMedia) => {},
              },
            }}
            onCreatePost={(cache, createPost) => {
              const q = cache.readQuery({
                query: FIXTURE_REPORT,
                variables: { id },
              });

              // console.log("q:", q);
              cache.writeQuery({
                query: FIXTURE_REPORT,
                variables: { id },
                data: {
                  fixture: {
                    ...q.fixture,
                    report: { ...createPost },
                  },
                },
              });

              setEditing(false);
            }}
            dismiss={() => {
              setEditing(false);
            }}
          />
        )}

        {/* {report && !editing && (
          <Post
            key={report.id}
            post={report}
            options={false}
            hideHeader={true}
          />
          
        )} */}

        {report && !editing && !deleting && (
          <MatchReport
            key={report.id}
            post={report}
            options={false}
            hideHeader={true}
            canEdit={
              canManageFixture
                ? () => {
                    setEditing(true);
                  }
                : false
            }
            canDelete={
              canManageFixture
                ? () => {
                    setDeleting(true);
                  }
                : false
            }
            hideLeagueName={true}
            contentExpanded={true}
          />
        )}

        {report && deleting && canManageFixture && (
          <DeleteMatchReport
            dismiss={() => {
              setDeleting(false);
            }}
            postId={report.id}
            fixtureId={id}
          />
        )}
      </div>
    </div>
  );
}

export default FixtureReport;
