import "./Row.css";

function Row({ children, columns, gap = 40 }) {
  return (
    <div
      className="app-layout-row"
      style={{
        gridTemplateColumns: `repeat(${columns || 2}, minmax(0,1fr))`,
        gridColumnGap: gap,
      }}
    >
      {children}
    </div>
  );
}

export default Row;
