import "./ModalForm.css";
import { useAppState } from "../../utils/appState";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { Fragment, useState } from "react";
import { useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import captureException from "../../utils/captureException";

function TextFormGroup({ config, setFields, value }) {
  const onChange = (e) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = e.target.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__group">
      <div className="modal-form-mobile__form__group__label">
        {config.label}
      </div>

      <div className="modal-form-mobile__form__group__input">
        <input type="text" value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function EmailFormGroup({ config, setFields, value }) {
  const onChange = (e) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = e.target.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__group">
      <div className="modal-form-mobile__form__group__label">
        {config.label}
      </div>

      <div className="modal-form-mobile__form__group__input">
        <input type="email" value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function TextAreaFormGroup({ config, setFields, value }) {
  const maxCharacters = config.maxCharacters || 5000;
  const onChange = (e) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = e.target.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__group">
      <div className="modal-form-mobile__form__group__label">
        {config.label}

        <div
          className={
            "char-count " +
            ((value?.length || 0) > maxCharacters ? "error" : "")
          }
        >
          {value?.length || 0} / {maxCharacters}
          {/* {value.length > 5000 && (
            <div className="char-count-error-desc">
              Description cannot exceed 5000 characters.
            </div>
          )} */}
        </div>
      </div>

      <div className="modal-form-mobile__form__group__textarea">
        <textarea value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function NumberFormGroup({ config, setFields, value }) {
  const onChange = (e) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = e.target.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__group">
      <div className="modal-form-mobile__form__group__label">
        {config.label}
      </div>

      <div className="modal-form-mobile__form__group__input">
        <input type="number" value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function DateFormGroup({ config, setFields, value }) {
  // const [value, setValue] = useState(defaultValue);
  const onChange = (e) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = e.target.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__group">
      <div className="modal-form-mobile__form__group__label">
        {config.label}
      </div>

      <div className="modal-form-mobile__form__group__input">
        <input type="date" value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function DateTimeFormGroup({ config, setFields, value }) {
  // console.log("Default val:", value);
  const onChange = (e) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = e.target.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__group">
      <div className="modal-form-mobile__form__group__label">
        {config.label}
      </div>

      <div className="modal-form-mobile__form__group__input">
        <input type="datetime-local" value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function SelectFormGroup({ config, setFields, value }) {
  const onChange = (i) => {
    setFields((prev) => {
      const fieldIndex = prev.findIndex((f) => f.key === config.key);
      // console.log("Field Index:", fieldIndex);
      if (fieldIndex > -1) {
        const newFields = [...prev];
        newFields[fieldIndex].formValue = i.value;
        // console.log("New Fields:", newFields);
        return newFields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="modal-form-mobile__form__select">
      {config.items.map((i) => (
        <div
          className={
            "modal-form-mobile__form__select__option " +
            (value === i.value ? "selected" : "")
          }
          onClick={() => {
            onChange(i);
          }}
        >
          <div className="modal-form-mobile__form__select__option__label">
            {i.label}
          </div>

          <div className="modal-form-mobile__form__select__option__icon-wrapper">
            <CheckIcon className="modal-form-mobile__form__select__option__icon-wrapper__icon" />
          </div>
        </div>
      ))}
    </div>
  );
}

function CityFormGroup({ config }) {
  return <div></div>;
}

function AddressFormGroup({ config }) {
  return <form></form>;
}

function ModalFormWrapper({ config, dismiss }) {
  const [, setAppState] = useAppState();

  const [mutate, { loading }] = useMutation(config.mutation, {
    onCompleted: () => {
      setAppState({ modal: false });
    },
    ...(config.update ? { update: config.update } : {}),
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ModalForm.ModalFormWrapper",
          mutation: "variable",
        },
      });
      console.log(error);
    },
  });

  const [fields, setFields] = useState(config.fields);

  return (
    <form
      className="modal-form-mobile__form"
      onSubmit={(e) => {
        e.preventDefault();
        config.onSubmit({ fields, mutate });
      }}
    >
      {fields.map((field) => (
        <Fragment>
          {(!field.type || field.type === "text") && (
            <TextFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "email" && (
            <EmailFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "textarea" && (
            <TextAreaFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "number" && (
            <NumberFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "date" && (
            <DateFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "datetime" && (
            <DateTimeFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "select" && (
            <SelectFormGroup
              config={field}
              setFields={setFields}
              value={field.formValue}
            />
          )}

          {field.type === "city" && (
            <CityFormGroup config={field} setFields={setFields} />
          )}

          {field.type === "address" && (
            <AddressFormGroup config={field} setFields={setFields} />
          )}
        </Fragment>
      ))}

      <div className="modal-form-mobile__form__options">
        <button
          type="submit"
          className="modal-form-mobile__form__options__submit"
        >
          {loading ? <Loader theme={"small"} /> : "Submit"}
        </button>

        <button
          className="modal-form-mobile__form__options__cancel"
          onClick={(e) => {
            e.preventDefault();
            dismiss();
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default function ModalForm({ title, config = {} }) {
  const [, setAppState] = useAppState();
  const dismiss = () => {
    setAppState({ modal: false });
  };
  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">{title}</div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <ModalFormWrapper config={config} dismiss={dismiss} />
        </div>
      </div>
    </div>
  );
}
