import { useState, Fragment } from "react";
import "./CreatePage.css";
import Card from "../../components/Card";
import Chip from "../../components/Chip";
import Dropdown from "../../components/Dropdown";
import Spinner from "../../components/Spinner";
import Row from "../../components/Row";
import Alert from "../../components/Alert";
import Onboarding from "../../components/Onboarding";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  CREATE_ORGANIZATION,
  ORGANIZATIONS,
  ORGANIZATION_TYPES,
} from "../../api/organization";
import { USER_PROFILE } from "../../api/user";
import { SPORTS } from "../../api/sport";
import { Link } from "react-router-dom";
import { ReactComponent as DirectoryIcon } from "../../icons/directory.svg";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import { ReactComponent as IndiaFlagIcon } from "../../icons/india-flag.svg";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import { ReactComponent as AtIcon } from "../../icons/at-sign.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as TimesCircleIcon } from "../../icons/times-circle.svg";
import { useQuery } from "@apollo/client";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useAppState } from "../../utils/appState";
import isOnboarded from "../../utils/isOnboarded";
import { IS_UNIQUE_HANDLE } from "../../api";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";
import Loader from "../../components/Loader";
/*
Categories:
1. Team / Sports Club
2. Venue / Sport Facilities
3. Event Organizer
4. Sports Entertainment
5. Sport programs and services
6. Merchandise & Equipment manufacturer
7. Merchandise & equipment seller
8. Sponsorships & endorsements
9. Sports media & content
10. Sports education & programs
11. Sports Academy
12. Sports nutrition, medicine & physiotherapy
13. Fan Club
*/

function PreferencesForm({
  setStep,
  vars,
  setVars,
  invalidHandle,
  setInvalidHandle,
  handleError,
  setHandleError,
}) {
  const [selectedCategories, setSelectedCategories] = useState(
    vars.selectedCategories || []
  );
  const [interests, setInterests] = useState(vars.interests || []);
  const [otherSportSearch, setOtherSportSearch] = useState("");
  const history = useHistory();
  const sportsQuery = useQuery(SPORTS);
  const { data, error } = useQuery(ORGANIZATION_TYPES);

  if (sportsQuery.error) {
    captureException({
      error: sportsQuery.error,
      info: {
        type: "query",
        component: "CreatePage.PreferencesForm",
        query: "SPORTS",
      },
    });
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "CreatePage.PreferencesForm",
        query: "ORGANIZATION_TYPES",
      },
    });
  }

  const [createOrganization, { loading: creating }] = useMutation(
    CREATE_ORGANIZATION,
    {
      update: (cache, { data: { createOrganization } }) => {
        const q = cache.readQuery({
          query: ORGANIZATIONS,
          variables: { where: {} },
        });

        // console.log("Q:", q);
        if (q?.organizations) {
          cache.writeQuery({
            query: ORGANIZATIONS,
            variables: { where: {} },
            data: {
              organizations: [createOrganization, ...q.organizations],
            },
          });
        }

        history.push("/app/page/" + createOrganization.id);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "CreatePage.PreferencesForm",
            mutation: "CREATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const [isUniqueHandle, { loading: checkingHandle }] = useLazyQuery(
    IS_UNIQUE_HANDLE,
    {
      onCompleted: (data) => {
        // console.log(data);
        if (!data?.isUniqueHandle?.valid) {
          setInvalidHandle(true);
          setHandleError("This handle is unavailable");
          setStep(0);
          return;
        }

        const v = {
          data: {
            name: vars.name,
            handle: vars.handle,
          },
        };

        if (vars.cities.length) {
          v.data.cities = { connect: vars.cities };
        }

        if (vars.phone) {
          v.data.phone = vars.phone;
        }

        if (vars.email) {
          v.data.email = vars.email;
        }

        if (selectedCategories.length) {
          v.data.types = {
            connect: selectedCategories.map((i) => ({ id: i.id })),
          };
        }

        if (interests.length) {
          v.data.interests = {
            connect: interests.map((i) => ({ name: i.name })),
          };
        }

        // console.log(v);
        // return;

        createOrganization({
          variables: v,
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "query",
            component: "CreatePage.PreferencesForm",
            query: "IS_UNIQUE_HANDLE",
          },
        });
        console.log(error);
      },
    }
  );

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
  };

  const filteredSportsList =
    sportsQuery?.data?.sports.filter(
      (s) =>
        !["Football", "Basketball", "Cricket", "Tennis", "Badminton"].includes(
          s.name
        )
    ) || [];
  const otherSportsList = otherSportSearch
    ? filteredSportsList.filter((s) =>
        s.name.toLowerCase().includes(otherSportSearch)
      )
    : filteredSportsList;

  const otherSportsSelectedCount = filteredSportsList.filter((s) =>
    interests.find((i) => i.name === s.name)
  ).length;

  const categories = data?.organizationTypes;

  return (
    <div className="onboarding-form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (checkingHandle || creating) {
            return;
          }
          // console.log(v);
          // return;

          isUniqueHandle({
            variables: { handle: vars.handle },
          });
        }}
      >
        <div className="form-group vertical">
          <div className="static-label">
            Select the categories that apply to your page.
          </div>
          <div className="input-tags">
            {categories?.map((c) => (
              <Card
                key={c.id}
                className={
                  "category hover-pointer " +
                  (selectedCategories.find((x) => x.id === c.id)
                    ? "selected"
                    : "")
                }
                onClick={() => {
                  let newCategories;

                  if (selectedCategories.find((x) => x.id === c.id)) {
                    newCategories = selectedCategories.filter(
                      (x) => x.id !== c.id
                    );
                    // setSelectedCategories(
                    //   selectedCategories.filter((x) => x.id !== c.id)
                    // );
                  } else {
                    newCategories = [...selectedCategories, c];
                    // setSelectedCategories([...selectedCategories, c]);
                  }

                  setSelectedCategories(newCategories);
                  setVars({
                    ...vars,
                    selectedCategories: newCategories,
                  });
                  // setSelectedCategories(c.id);
                }}
              >
                {c.name}
              </Card>
            ))}
          </div>
        </div>

        <div className="form-group vertical" style={{ marginTop: "24px" }}>
          <div className="static-label">
            What sports is this page related to?
          </div>

          <div className="sports-selector">
            {["Football", "Cricket", "Basketball", "Tennis", "Badminton"].map(
              (s) => (
                <Card
                  key={"Sport-" + s}
                  className={
                    "sports-selector-item " +
                    (interests.find((i) => i.name === s) ? "selected" : "")
                  }
                  onClick={() => {
                    let newInterests;

                    if (interests.find((i) => i.name === s)) {
                      newInterests = interests.filter((i) => i.name !== s);
                      // setInterests(interests.filter((i) => i.name !== s));
                    } else {
                      newInterests = [...interests, { name: s }];
                      // setInterests([...interests, { name: s }]);
                    }

                    setInterests(newInterests);
                    setVars({
                      ...vars,
                      interests: newInterests,
                    });
                  }}
                >
                  <div className="sport-icon">{sportsIcons[s]}</div>
                  <div className="sport-text">{s}</div>
                </Card>
              )
            )}

            <Dropdown
              target={({ showMenu }) => (
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input hide-label-on-active"
                    onChange={(e) => {
                      setOtherSportSearch(e.target.value);
                    }}
                    value={otherSportSearch}
                  />
                  {!showMenu && (
                    <label>
                      {otherSportsSelectedCount
                        ? otherSportsSelectedCount + " "
                        : ""}{" "}
                      Other
                    </label>
                  )}
                </div>
              )}
              menu={({ setShowMenu }) => (
                <Card className="sports-preference-input-menu">
                  {otherSportsList.map((sport) => (
                    <div
                      key={sport.id}
                      className="other-sports-selector-item"
                      onClick={() => {
                        if (interests.find((i) => i.name === sport.name)) {
                          setInterests(
                            interests.filter((i) => i.name !== sport.name)
                          );
                        } else {
                          setInterests([...interests, sport]);
                        }
                      }}
                    >
                      <div className="other-sports-selector-item-text">
                        {sport.name}
                      </div>
                      <div
                        className={
                          "app-checkbox " +
                          (interests.find((i) => i.name === sport.name)
                            ? "checked"
                            : "")
                        }
                      >
                        <div className="checkbox-indicator ">
                          {interests.find((i) => i.name === sport.name) && (
                            <img src={"/check.svg"} alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              )}
              onClose={() => {
                setOtherSportSearch("");
              }}
            />
          </div>
        </div>

        <div className="options-group">
          <DebouncedButton
            type="submit"
            className="app-button primary large blue submit"
            loading={checkingHandle || creating}
            // style={errorMessage ? { marginTop: "24px" } : {}}
          >
            {checkingHandle || creating ? <Loader theme="small" /> : "Finish"}
          </DebouncedButton>

          {/* <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Finish{" "}
          </button> */}
        </div>
      </form>
    </div>
  );
}

function BasicInfoForm({
  setStep,
  vars,
  setVars,
  invalidHandle,
  setInvalidHandle,
  handleError,
  setHandleError,
}) {
  const [name, setName] = useState(vars.name || "");
  const [handle, setHandle] = useState(vars.handle || "");
  const [location, setLocation] = useState(vars.city || "");

  const [cities, setCities] = useState(vars.cities || []);
  const [phone, setPhone] = useState(vars.phone || "");
  const [email, setEmail] = useState(vars.email || "");
  const [nameError, setNameError] = useState(false);
  const [locationError, setLocationError] = useState(false);

  // const citiesList = cities.filter((c) => {
  //   if (!location) {
  //     return true;
  //   } else {
  //     const regex = new RegExp(location, "i");
  //     return regex.test(c.name);
  //   }
  // });

  return (
    <div className="onboarding-form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!name || !vars.cities?.length || !handle) {
            if (!name) {
              setNameError(true);
            }

            if (!vars.cities?.length) {
              setLocationError(true);
            }

            if (!handle) {
              setInvalidHandle(true);
            }

            return;
          }
          setStep(1);
        }}
      >
        <div className="form-group">
          <input
            type="text"
            id="username"
            value={name}
            className={
              "input " +
              (name ? "hasValue " : " ") +
              (nameError ? "invalid" : "")
            }
            onChange={(e) => {
              setName(e.target.value);
              setVars({
                ...vars,
                name: e.target.value,
              });
            }}
            onFocus={() => {
              setNameError(false);
            }}
            autoComplete={"off"}
          />
          <label htmlFor="username">
            Enter the name of your page{" "}
            <span className="input-required-indicator">*</span>
          </label>
          <div className="static-icon">
            <DirectoryIcon
              style={{ stroke: "var(--light-4)", height: "16px" }}
            />
          </div>
        </div>

        <div className="form-group">
          <input
            type="text"
            id="handle"
            value={handle}
            className={
              "input " +
              (handle ? "hasValue " : " ") +
              (invalidHandle ? "invalid" : "")
            }
            onChange={(e) => {
              setHandle(e.target.value);
              setVars({
                ...vars,
                handle: e.target.value,
              });
            }}
            onFocus={() => {
              setHandleError(false);
              setInvalidHandle(false);
            }}
            autoComplete={"off"}
          />
          <label htmlFor="username">
            Enter a unique handle{" "}
            <span className="input-required-indicator">*</span>
          </label>
          <div className="static-icon">
            <AtIcon style={{ stroke: "var(--light-4)", height: 14 }} />
          </div>
          {handleError && (
            <div className="assistive">
              <div className="assistive-icon">
                <TimesCircleIcon
                  style={{ height: "10px", stroke: "var(--red-main)" }}
                />
              </div>
              <div className="assistive-text">{handleError}</div>
            </div>
          )}
        </div>

        <div
          className="form-group"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            // zIndex: 10,
            ...(handleError ? { marginTop: "56px" } : {}),
          }}
        >
          <PlacesAutocomplete
            value={location}
            onChange={(x) => {
              setLocation(x);
            }}
            onSelect={(loc) => {
              // console.log(loc);
              const city = loc?.split(",")[0]?.trim();
              const state = loc?.split(",")[1]?.trim();
              const country = loc?.split(",")[2]?.trim();

              geocodeByAddress(loc)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  setCities((prev) => {
                    return [
                      ...prev,
                      {
                        city,
                        state,
                        country,
                        lat: latLng.lat,
                        lng: latLng.lng,
                      },
                    ];
                  });
                  setVars({
                    ...vars,
                    cities: [
                      ...(vars.cities || []),
                      {
                        city,
                        state,
                        country,
                        lat: latLng.lat,
                        lng: latLng.lng,
                      },
                    ],
                  });
                })
                .catch((error) => console.error("Error", error));
              setLocation("");
            }}
            debounce={200}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Dropdown
                className="dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "dropdown-input-wrapper__multi-select-container " +
                      (locationError ? "invalid" : "")
                    }
                  >
                    <div className="static-icon">
                      <LocationIcon
                        style={{ stroke: "var(--light-4)", height: 14 }}
                      />
                    </div>
                    <div className="dropdown-input-wrapper__multi-select-container__inputs-wrapper">
                      {cities.map((c, i) => (
                        <Chip
                          key={"location" + i}
                          className="multi-select-item"
                          onClick={() => {
                            setCities((prev) => {
                              return [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1, prev.length),
                              ];
                            });

                            setVars((prev) => {
                              return {
                                ...prev,
                                cities: [
                                  ...prev.cities.slice(0, i),
                                  ...prev.cities.slice(i + 1, prev.length),
                                ],
                              };
                            });
                          }}
                        >
                          <span className="multi-select-item__text">
                            {c.city}
                          </span>{" "}
                          <TimesIcon
                            style={{ stroke: "var(--light-1)", height: "14px" }}
                          />{" "}
                        </Chip>
                      ))}
                      <input
                        {...getInputProps({
                          className:
                            "input " +
                            (location || cities?.length ? "hasValue " : " ") +
                            (locationError ? "invalid" : ""),
                          onFocus: () => {
                            setLocationError(false);
                          },
                          autoComplete: "off",
                        })}
                        value={location}
                        id="location"
                      />

                      {/* </div> */}

                      <label htmlFor="location">
                        Location{" "}
                        <span className="input-required-indicator">*</span>
                      </label>
                    </div>

                    <div
                      className="static-icon"
                      style={{ right: 0, left: "auto", bottom: 10 }}
                    >
                      <IndiaFlagIcon />
                    </div>
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="dropdown-input-wrapper-menu">
                    {loading && <Spinner />}
                    {suggestions
                      .filter(
                        (suggestion) =>
                          suggestion?.description?.split(",")?.length &&
                          suggestion?.description?.split(",").length > 2
                      )
                      .map((suggestion, i) => (
                        <div
                          key={"city-suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className: "city-item",
                          })}
                        >
                          {suggestion.description}
                          {/*<span className="state">{city.within.value}</span>*/}
                        </div>
                      ))}
                  </Card>
                )}
                onClose={() => {
                  setLocation("");
                }}
              />
            )}
          </PlacesAutocomplete>
        </div>

        <Row columns={24} gap={16}>
          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 13 }}
          >
            <input
              type="text"
              id="phone"
              value={phone}
              className={"input " + (phone ? "hasValue " : " ")}
              onChange={(e) => {
                setPhone(e.target.value);
                setVars({
                  ...vars,
                  phone: e.target.value,
                });
              }}
              autoComplete={"off"}
            />
            <label htmlFor="phone">Contact Number</label>
            <div className="static-icon">
              <PhoneIcon style={{ stroke: "var(--light-4)", height: 14 }} />
            </div>
          </div>

          <div
            className="form-group"
            style={{ gridColumnStart: 13, gridColumnEnd: 25 }}
          >
            <input
              type="text"
              id="email"
              value={email}
              className={"input " + (email ? "hasValue " : " ")}
              onChange={(e) => {
                setEmail(e.target.value);
                setVars({
                  ...vars,
                  email: e.target.value,
                });
              }}
              autoComplete={"off"}
            />
            <label htmlFor="phone">Contact Email</label>
            <div className="static-icon">
              <MailIcon style={{ fill: "var(--light-4)", height: "16px" }} />
            </div>
          </div>
        </Row>

        <div className="options-group">
          <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Next{" "}
          </button>
        </div>
      </form>
    </div>
  );
}

function CreatePageFormOnboardingWrapper({
  step,
  setStep,
  vars,
  setVars,
  invalidHandle,
  setInvalidHandle,
  handleError,
  setHandleError,
}) {
  const [, setAppState] = useAppState();
  const { loading, data, error } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "CreatePage.CreatePageOnboardingFormWrapper",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div className="">Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const profile = data?.userProfile;
  const onboarded = isOnboarded(profile);

  if (!onboarded) {
    return (
      <div className="auth-alert">
        <Alert
          theme="red"
          head="You're profile is incomplete"
          body={
            <div>
              <div>
                You must complete the entire onboarding process to create pages
                and events.
              </div>
              <div
                onClick={() => {
                  setAppState({ modal: <Onboarding /> });
                }}
                className="auth-alert-link"
              >
                Click here to complete onboarding
              </div>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <Fragment>
      {step === 0 && (
        <BasicInfoForm
          setStep={setStep}
          vars={vars}
          setVars={setVars}
          invalidHandle={invalidHandle}
          setInvalidHandle={setInvalidHandle}
          handleError={handleError}
          setHandleError={setHandleError}
        />
      )}
      {step === 1 && (
        <PreferencesForm
          setStep={setStep}
          vars={vars}
          setVars={setVars}
          invalidHandle={invalidHandle}
          setInvalidHandle={setInvalidHandle}
          handleError={handleError}
          setHandleError={setHandleError}
        />
      )}
    </Fragment>
  );
}

function CreatePage() {
  usePageTracking({ title: "Create Page" });
  const [step, setStep] = useState(0);
  const [vars, setVars] = useState({});
  const [invalidHandle, setInvalidHandle] = useState(false);
  const [handleError, setHandleError] = useState("");
  const [{ isLoggedIn }] = useAppState();

  return (
    <div className="create-page">
      <div className="content-wrapper">
        <div className="page-title">{vars.name || "New Page"}</div>

        <div className="page-subtitle">
          Let's get your page setup for you...
        </div>

        <div className="create-page-form-container">
          {!isLoggedIn ? (
            <div className="auth-alert">
              <Alert
                theme="red"
                head="You are not logged in"
                body={
                  <div>
                    <div>
                      You must be logged in and verified to create events and
                      pages.
                    </div>
                    <Link to="/auth" className="auth-alert-link">
                      Click here to login
                    </Link>
                  </div>
                }
              />
            </div>
          ) : (
            <CreatePageFormOnboardingWrapper
              step={step}
              setStep={setStep}
              vars={vars}
              setVars={setVars}
              invalidHandle={invalidHandle}
              setInvalidHandle={setInvalidHandle}
              handleError={handleError}
              setHandleError={setHandleError}
            />
          )}
        </div>
      </div>

      <div className="graphic-wrapper"></div>
    </div>
  );
}

export default CreatePage;
