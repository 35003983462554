import "./Notifications.css";
import { useQuery } from "@apollo/client";
import { NOTIFICATIONS } from "../../api/profile";
import Spinner from "../../components/Spinner";
import moment from "moment";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import Avatar from "../../components/Avatar";

function PublishFixtureConfirmNotification({ notification }) {
  console.log("Publish notification:", notification);
  const participants = notification.source?.fixture?.roles || [];
  const sortedParticipants = [...participants]?.sort(
    (a, b) => a.order - b.order
  );
  const homeParticipant = sortedParticipants[0];
  const awayParticipant = sortedParticipants[1];
  const event = notification?.source?.fixture?.event;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {event?.profilePhoto && <Avatar media={event.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <span className="bold">
            {homeParticipant?.organization?.name ||
              homeParticipant?.profile?.name}
          </span>{" "}
          vs{" "}
          <span className="bold">
            {awayParticipant?.organization?.name ||
              awayParticipant?.profile?.name}
          </span>{" "}
          from <span className="bold">{event.name}</span> was published
          successfully.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function EventRoleAcceptedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <span className="bold">{notification.source?.profile?.name}</span>{" "}
          accepted{" "}
          <span className="bold">{notification.destination?.event?.name}</span>
          's request to add{" "}
          <span className="bold">
            {notification.source?.organization?.name}
          </span>{" "}
          as a {notification.meta?.roleType}.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function EventRoleDeclinedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <span className="bold">{notification.source?.profile?.name}</span>{" "}
          declined{" "}
          <span className="bold">{notification.destination?.event?.name}</span>
          's request to add{" "}
          <span className="bold">
            {notification.source?.organization?.name}
          </span>{" "}
          as a {notification.meta?.roleType}.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function MembershipDeclinedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <span className="bold">{notification.source?.profile?.name}</span>{" "}
          declined{" "}
          <span className="bold">
            {notification.destination?.organization?.name}
          </span>
          's membership request.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function MembershipAcceptedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <span className="bold">{notification.source?.profile?.name}</span>{" "}
          accepted{" "}
          <span className="bold">
            {notification.destination?.organization?.name}
          </span>
          's membership request.
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function NewFollowerNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <div className="primary-text">
          <span className="bold">{notification.source?.profile?.name}</span>{" "}
          started following{" "}
          <span className="bold">
            {notification.destination?.organization?.name}
          </span>
          .
        </div>
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function FollowAcceptedNotification({ notification }) {
  const profile = notification.source?.profile;
  return (
    <div className="notifications-list-item">
      <div className="image">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="text">
        <span className="bold">{notification.source?.profile?.name}</span>{" "}
        accepted your follow request.
        <div className="date">{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}

function Notification({ notification }) {
  if (notification.type === "MEMBERSHIP-DECLINED") {
    return <MembershipDeclinedNotification notification={notification} />;
  } else if (notification.type === "MEMBERSHIP-ACCEPTED") {
    return <MembershipAcceptedNotification notification={notification} />;
  } else if (notification.type === "FOLLOW-REQUEST-ACCEPTED") {
    return <FollowAcceptedNotification notification={notification} />;
  } else if (notification.type === "NEW-FOLLOWER") {
    return <NewFollowerNotification notification={notification} />;
  } else if (notification.type === "EVENT-ROLE-ACCEPTED") {
    return <EventRoleAcceptedNotification notification={notification} />;
  } else if (notification.type === "EVENT-ROLE-DECLINED") {
    return <EventRoleDeclinedNotification notification={notification} />;
  } else if (notification.type === "PUBLISH-CONFIRMATION") {
    return <PublishFixtureConfirmNotification notification={notification} />;
  } else {
    return <div />;
  }
}

export default function Notifications() {
  usePageTracking({ title: "Notifications" });
  const { loading, error, data } = useQuery(NOTIFICATIONS, {
    notifyOnNetworkStatusChange: true,
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Notifications",
        query: "NOTIFICATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        className="loading-wrapper"
        style={{
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const notifications = data?.notifications;

  return (
    <div className="notifications-page-mobile">
      {notifications?.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
}
