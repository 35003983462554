import "./AlbumsCard.css";
import Placeholder from "../../components/Placeholder";
import moment from "moment";
import { useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { useAppState } from "../../utils/appState";
import ModalForm from "../ModalForm";
import { CREATE_MEDIA_SET } from "../../api/media";
import Avatar from "../../components/Avatar/Avatar";
import captureException from "../../utils/captureException";

export default function AlbumsCard({
  query,
  vars,
  getAlbums = () => [],
  totalAlbums = () => 0,
  noData = "",
  setSelectedAlbum,
  filters = {},
  processFilters = () => {
    return false;
  },
  addAlbum,
}) {
  const [, setAppState] = useAppState();
  const { networkStatus, error, data, variables, fetchMore } = useQuery(query, {
    variables: vars,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  // useEffect(() => {
  //   const filterVars = processFilters(filters);

  //   if (filterVars) {
  //     refetch({
  //       ...vars,
  //       ...filterVars,
  //     });
  //   }
  // }, [filters]);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AlbumsCard",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 2 || networkStatus === 4) {
    // console.log("Loader:", networkStatus);
    return (
      <div className="albums-card-mobile">
        <div className="albums-card-mobile__header">
          <div className="albums-card-mobile__header__title">
            <Placeholder style={{ height: "12px", width: "40px" }} />
          </div>
        </div>

        <div className="albums-card-mobile__gallery">
          <div className="albums-card-mobile__gallery__item">
            <div className="albums-card-mobile__gallery__item__image"></div>

            <div className="albums-card-mobile__gallery__item__desc">
              <div className="albums-card-mobile__gallery__item__desc__primary">
                <Placeholder style={{ height: "12px", width: "100px" }} />
              </div>
              <div className="albums-card-mobile__gallery__item__desc__secondary"></div>
            </div>
          </div>
        </div>

        <div className="albums-card-mobile__footer">
          <div className="albums-card-mobile__footer__show-more">
            <Placeholder style={{ height: "12px", width: "40px" }} />
          </div>
        </div>
      </div>
    );
  }

  const albums = getAlbums(data);
  const albumsCount = totalAlbums(data);

  const onAddAlbumClick = () => {
    setAppState({
      modal: (
        <ModalForm
          title={"Add an album"}
          config={{
            fields: [
              {
                label: "Name",
                type: "text",
                key: "name",
                formValue: "",
              },
            ],
            mutation: CREATE_MEDIA_SET,
            onSubmit: ({ fields, mutate }) => {
              // console.log("Submitted:", fields);
              const val = fields[0].formValue;

              if (!val) {
                return;
              }

              const vars = {
                name: val,
                sourceId: addAlbum.sourceId,
                sourceType: addAlbum.sourceType,
              };
              mutate({
                variables: vars,
                update: (cache, result) => {
                  addAlbum.onAddAlbum(cache, result);
                  setAppState({ modal: false });
                },
              });
            },
          }}
        />
      ),
    });
  };

  return (
    <div className="albums-card-mobile">
      <div className="albums-card-mobile__header">
        <div className="albums-card-mobile__header__title">Albums</div>
        {addAlbum && (
          <div
            className="albums-card-mobile__header__btn"
            onClick={onAddAlbumClick}
          >
            Add Album
          </div>
        )}
      </div>

      <div className="albums-card-mobile__gallery">
        {albums?.map((album) => (
          <div
            key={"album-item-" + album.id}
            className="albums-card-mobile__gallery__item"
            onClick={() => {
              // console.log("Clicked album");
              setSelectedAlbum(album.id);
            }}
          >
            <div className="albums-card-mobile__gallery__item__image">
              {album.preview && <Avatar media={album.preview} />}
            </div>

            <div className="albums-card-mobile__gallery__item__desc">
              <div className="albums-card-mobile__gallery__item__desc__primary">
                {album.source?.fixture
                  ? album.source.fixture.roles
                      .map((r) => r.organization?.name || r.profile?.name)
                      .join(" vs ")
                  : album.name}
              </div>
              <div className="albums-card-mobile__gallery__item__desc__secondary">
                {album.source?.fixture?.scheduledStart ? (
                  <div>
                    [
                    {moment(album.source.fixture.scheduledStart).format(
                      "DD-MM-YYYY"
                    )}
                    ]
                  </div>
                ) : (
                  <span />
                )}
              </div>
            </div>
          </div>
        ))}

        {!albums?.filter((x) => x.preview)?.length && (
          <div className="no-data">{noData}</div>
        )}
      </div>

      {albums?.length < albumsCount && (
        <div className="albums-card-mobile__footer">
          <div
            className="albums-card-mobile__footer__show-more"
            onClick={() => {
              fetchMore({
                variables: {
                  ...variables,
                  cursor: albums[albums.length - 1]?.id,
                },
              });
            }}
          >
            {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
          </div>
        </div>
      )}
    </div>
  );
}
