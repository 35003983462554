import "./Button.css";

function Button({ className, children, style = {}, onClick = () => {}, type }) {
  return (
    <div
      className={"app-button " + className}
      style={style}
      onClick={onClick}
      type={type}
    >
      {children}
    </div>
  );
}

export default Button;
