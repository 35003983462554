import { gql } from "@apollo/client";

const CAN_MANAGE_PAGE = gql`
  query canManagePageQuery($id: ID!) {
    canManagePage(id: $id) {
      authorized
    }
  }
`;

export default CAN_MANAGE_PAGE;
