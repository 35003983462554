import { gql } from "@apollo/client";

const UPDATE_ORGANIZATION = (selectionSet) => {
  return gql`
    mutation updateOrganizationMutation($where:UniqueInput!, $data: UpdateOrganizationInput!) {
      updateOrganization(where:$where, data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
};

export default UPDATE_ORGANIZATION;
