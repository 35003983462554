import { useState } from "react";
import "./OverItem.css";
import { ReactComponent as PencilIcon } from "../../../../icons/pencil.svg";
import { ReactComponent as TimesIcon } from "../../../../icons/times.svg";
import { ReactComponent as PlayIcon } from "../../../../icons/play.svg";
import { ReactComponent as TrashIcon } from "../../../../icons/trash.svg";
import NewDropdown from "../../../NewDropdown";
import Card from "../../../Card";
import Spinner from "../../../Spinner";
import Loader from "../../../Loader";
import { useMutation } from "@apollo/client";
import {
  UPDATE_STAT,
  DELETE_FIXTURE_STAT,
  CREATE_STAT,
} from "../../../../api/fixture";
import captureException from "../../../../utils/captureException";

function makeWicketStat({
  fixture,
  fixturePeriodId,
  wicket,
  dismissedBatsman,
  fielder,
  runsScoredStat,
  ballsBowledStat,
  statTypes,
}) {
  const wicketStat = {
    event: { connect: { id: fixture.event.id } },
    timeStamp: 1,
    organization: {
      connect: { id: runsScoredStat.organization.id },
    },
    profile: {
      connect: {
        id:
          wicket.name === "Run Out" || wicket.name === "Retired"
            ? dismissedBatsman.profile.id
            : runsScoredStat.profile.id,
      },
    },
    statType: {
      connect: {
        id: wicket.id,
      },
    },
    fixture: { connect: { id: fixture.id } },
    fixturePeriod: { connect: { id: fixturePeriodId } },
    sport: { connect: { name: "Cricket" } },
    value: 1,
  };

  const wicketTakenStat =
    wicket && wicket.name !== "Run Out" && wicket.name !== "Retired"
      ? {
          event: { connect: { id: fixture.event.id } },
          timeStamp: 1,
          organization: {
            connect: {
              id: ballsBowledStat.organization.id,
            },
          },
          profile: { connect: { id: ballsBowledStat.profile.id } },
          statType: {
            connect: {
              id: statTypes.find((s) => s.name === "Wicket Taken").id,
            },
          },
          fixture: { connect: { id: fixture.id } },
          fixturePeriod: { connect: { id: fixturePeriodId } },
          sport: { connect: { name: "Cricket" } },
        }
      : false;

  const fieldingStat =
    wicket &&
    wicket.name !== "Bowled" &&
    wicket.name !== "LBW" &&
    wicket.name !== "Retired"
      ? {
          event: { connect: { id: fixture.event.id } },
          timeStamp: 1,
          organization: {
            connect: {
              id: ballsBowledStat.organization.id,
            },
          },
          profile: {
            connect: {
              id:
                wicket.name === "Caught & Bowled"
                  ? ballsBowledStat.profile.id
                  : fielder.id,
            },
          },
          statType: {
            connect: {
              id: statTypes.find((s) => {
                const fieldingStatName =
                  wicket.name === "Caught" || wicket.name === "Caught & Bowled"
                    ? "Catch"
                    : wicket.name === "Run Out"
                    ? "Run Out"
                    : "Stumping";

                return s.name === fieldingStatName;
              }).id,
            },
          },
          fixture: { connect: { id: fixture.id } },
          fixturePeriod: { connect: { id: fixturePeriodId } },
          sport: { connect: { name: "Cricket" } },
        }
      : false;

  const wicketRelationships = [];

  if (wicketStat && wicketTakenStat) {
    wicketRelationships.push(wicketTakenStat);
  }

  if (wicketStat && fieldingStat) {
    wicketRelationships.push(fieldingStat);
  }

  if (wicketRelationships.length) {
    wicketStat.relationshipsFrom = {
      create: wicketRelationships.map((r) => ({ to: { create: r } })),
    };
  }

  wicketStat.relationshipsTo = {
    create: {
      from: {
        connect: { id: runsScoredStat.id },
      },
    },
  };

  return wicketStat;
}

function AddWicket({
  statTypes,
  dismiss,
  fixture,
  runsScoredStat,
  ballsBowledStat,
  setOverlay,
  setAltOverlay,
  selectedPeriodId,
}) {
  const [createStat] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      cache.modify({
        id: cache.identify(fixture),
        fields: {
          stats(existingStatsRefs, { readField }) {
            return [...existingStatsRefs, createStat];
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.AddWicket",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const wicketTypes = statTypes.filter((s) =>
    [
      "Bowled",
      "LBW",
      "Caught",
      "Caught & Bowled",
      "Run Out",
      "Stumped",
      "Retired",
    ].includes(s.name)
  );
  return (
    <Card className="add-wicket-menu">
      {wicketTypes.map((s) => (
        <div
          className="add-wicket-menu__item"
          onClick={() => {
            if (
              s.name === "Bowled" ||
              s.name === "LBW" ||
              s.name === "Caught & Bowled"
            ) {
              // console.log("Create stat");
              const wicketStat = makeWicketStat({
                fixture,
                fixturePeriodId: selectedPeriodId,
                wicket: s,
                dismissedBatsman: false,
                fielder: false,
                runsScoredStat,
                ballsBowledStat,
                statTypes,
              });

              createStat({
                variables: {
                  data: wicketStat,
                },
              });
            } else if (s.name === "Caught" || s.name === "Stumped") {
              // console.log("Launch fielders modal");

              setOverlay({
                title: "Select Fielder",
                onSelect: (player) => {
                  const wicketStat = makeWicketStat({
                    fixture,
                    fixturePeriodId: selectedPeriodId,
                    wicket: s,
                    dismissedBatsman: false,
                    fielder: player.profile,
                    runsScoredStat,
                    ballsBowledStat,
                    statTypes,
                  });

                  createStat({
                    variables: {
                      data: wicketStat,
                    },
                  });
                },
              });
            } else if (s.name === "Run Out") {
              // console.log("Launch batsmen & fielder modals");
              setOverlay({
                title: "Select Fielder",
                onSelect: (fielder) => {
                  setAltOverlay({
                    title: "Select Dismissed Batsman",
                    onSelect: (batsman) => {
                      const wicketStat = makeWicketStat({
                        fixture,
                        fixturePeriodId: selectedPeriodId,
                        wicket: s,
                        dismissedBatsman: batsman,
                        fielder: fielder.profile,
                        runsScoredStat,
                        ballsBowledStat,
                        statTypes,
                      });

                      // console.log("Fielder:", fielder);
                      // console.log("Batsman:", batsman);

                      createStat({
                        variables: {
                          data: wicketStat,
                        },
                      });
                    },
                  });
                },
              });
            } else if (s.name === "Retired") {
              // console.log("Launch batsmen modal");
              setOverlay({
                title: "Select Dismissed Batsman",
                onSelect: (player) => {
                  const wicketStat = makeWicketStat({
                    fixture,
                    fixturePeriodId: selectedPeriodId,
                    wicket: s,
                    dismissedBatsman: player,
                    fielder: false,
                    runsScoredStat,
                    ballsBowledStat,
                    statTypes,
                  });

                  createStat({
                    variables: {
                      data: wicketStat,
                    },
                  });
                },
              });
            }
          }}
        >
          {s.name}
        </div>
      ))}
    </Card>
  );
}

function AddExtraRunItem({ value, onClick, disabled }) {
  return (
    <div
      className={"add-extra-menu__column__item " + (disabled ? "disabled" : "")}
      onClick={onClick}
    >
      {value}
    </div>
  );
}

function AddExtraTypeItem({ statType, onClick, selected }) {
  return (
    <div
      className={"add-extra-menu__column__item " + (selected ? "selected" : "")}
      onClick={onClick}
    >
      {statType.name}
    </div>
  );
}

function AddExtra({
  statTypes,
  ballsBowledStat,
  ballsFacedStat,
  dismiss,
  fixture,
  group,
  itemIndex,
  cachedState,
  selectedPeriodId,
  currentOverNumber,
}) {
  const [value, setValue] = useState(false);
  const [statType, setStatType] = useState("");
  const runs = [1, 2, 3, 4, 5, 6];
  const extras = statTypes.filter((st) =>
    ["Wide", "No Ball", "Bye", "Leg Bye"].includes(st.name)
  );

  const [updateStat, { loading: updating }] = useMutation(UPDATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.AddExtra",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });
  const [deleteFixtureStat] = useMutation(DELETE_FIXTURE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.AddExtra",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });
  const [createStat, { loading }] = useMutation(CREATE_STAT, {
    // update: (cache, { data: { createStat } }) => {
    //   cache.modify({
    //     id: cache.identify(fixture),
    //     fields: {
    //       stats(existingStatsRefs, { readField }) {
    //         return [...existingStatsRefs, createStat];
    //       },
    //     },
    //   });
    // },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.AddExtra",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });
  // console.log("Fixture:", fixture);

  // console.log("Add extra  Extras:", extras);
  const addStat = (s, val, updateBallNumbers) => {
    createStat({
      variables: {
        data: {
          event: { connect: { id: fixture.event.id } },
          timeStamp: 1,
          organization: {
            connect: {
              id: ballsBowledStat.organization.id,
            },
          },
          profile: {
            connect: { id: ballsBowledStat.profile.id },
          },
          statType: {
            connect: {
              id: s.id,
            },
          },
          fixture: { connect: { id: fixture.id } },
          fixturePeriod: { connect: { id: selectedPeriodId } },
          sport: { connect: { name: "Cricket" } },
          // value: extra === "Wide" || extra === "No Ball" ? 1 : value,
          value: val,
          relationshipsTo: {
            create: {
              from: {
                connect: { id: ballsBowledStat.id },
              },
            },
          },
        },
      },
      update: async (cache, { data }) => {
        // if it's a wide or no ball subtracting 0.1 from every ball label
        if (updateBallNumbers) {
          const subgroup = [...group].reverse().slice(itemIndex + 1);

          for (let i = 0; i < subgroup.length; i++) {
            const subgroupStat = subgroup[i];
            await updateStat({
              variables: {
                where: { id: subgroupStat.ballsBowledStat.id },
                data: {
                  value: subgroupStat.ballsBowledStat.value - 0.1,
                  stringValue: subgroupStat.ballsBowledStat.value - 0.1 + "",
                },
              },
              update: (cache, { data }) => {
                cache.modify({
                  id: cache.identify(subgroupStat.ballsBowledStat),
                  fields: {
                    value(existingStatRefs, { readField }) {
                      return data.updateStat.value;
                    },
                    stringValue(existingStatRefs, { readField }) {
                      return data.updateStat.stringValue;
                    },
                  },
                });
              },
            });
          }
        }

        if (s.name === "Wide") {
          await deleteFixtureStat({
            variables: {
              id: ballsFacedStat.id,
            },
          });
        }

        cache.modify({
          id: cache.identify(fixture),
          fields: {
            stats(existingStatsRefs, { readField }) {
              return [...existingStatsRefs, data.createStat];
            },
          },
        });

        dismiss();
      },
    });
  };
  return (
    <Card className="add-extra-menu">
      <div className="add-extra-menu__column">
        {extras.map((s) => (
          <AddExtraTypeItem
            statType={s}
            onClick={() => {
              if (loading || updating) return;
              if (s.name === "No Ball") {
                addStat(s, 1, true);
                // console.log("Balls Bowled Stat:", ballsBowledStat);
                // const over = Math.floor(ballsBowledStat.value);
                // const subgroup = [...group].reverse().slice(itemIndex + 1);
                // console.log("Over:", over);
                // console.log("Subgroup:", subgroup);
                // return;
              } else {
                setStatType((prev) => {
                  if (prev?.id === s.id) {
                    setValue(false);
                    return "";
                  } else {
                    setValue(true);
                    return s;
                  }
                });
              }
            }}
            selected={statType?.id === s.id}
          />
        ))}
      </div>
      <div className="add-extra-menu__column">
        {runs.map((r) => (
          <AddExtraRunItem
            value={r}
            onClick={() => {
              if (!statType?.id) return;
              if (!value) return;

              addStat(statType, r, statType.name === "Wide" ? true : false);

              // createStat({
              //   variables: {
              //     data: {
              //       event: { connect: { id: fixture.event.id } },
              //       timeStamp: 1,
              //       organization: {
              //         connect: {
              //           id: ballsBowledStat.organization.id,
              //         },
              //       },
              //       profile: {
              //         connect: { id: ballsBowledStat.profile.id },
              //       },
              //       statType: {
              //         connect: {
              //           id: statType.id,
              //         },
              //       },
              //       fixture: { connect: { id: fixture.id } },
              //       sport: { connect: { name: "Cricket" } },
              //       // value: extra === "Wide" || extra === "No Ball" ? 1 : value,
              //       value: r,
              //       relationshipsTo: {
              //         create: {
              //           from: {
              //             connect: { id: ballsBowledStat.id },
              //           },
              //         },
              //       },
              //     },
              //   },
              // });

              dismiss();
            }}
            disabled={!value}
          />
        ))}
      </div>
    </Card>
  );
}

function BatsmanMenuItem({
  role,
  runsScoredStat,
  ballsFacedStat,
  closeMenu,
  wicketStat,
}) {
  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    // update: (cache, { data }) => {
    //   cache.modify({
    //     id: cache.identify(data.updateStat),
    //     fields: {
    //       profile(existingClaimRefs, { readField }) {
    //         return data.updateStat.profile;
    //       },
    //     },
    //   });

    //   closeMenu();
    // },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });
  return (
    <div
      className="edit-over-item-form__menu__items__item"
      onClick={() => {
        updateStat({
          variables: {
            where: { id: runsScoredStat.id },
            data: {
              profile: { connect: { id: role.profile.id } },
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(runsScoredStat),
              fields: {
                profile(existingClaimRefs, { readField }) {
                  return data.updateStat.profile;
                },
              },
            });

            closeMenu();
          },
        });

        if (ballsFacedStat) {
          updateStat({
            variables: {
              where: { id: ballsFacedStat.id },
              data: {
                profile: { connect: { id: role.profile.id } },
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify(ballsFacedStat),
                fields: {
                  profile(existingClaimRefs, { readField }) {
                    return data.updateStat.profile;
                  },
                },
              });

              closeMenu();
            },
          });
        }

        if (wicketStat) {
          updateStat({
            variables: {
              where: { id: wicketStat.id },
              data: {
                profile: { connect: { id: role.profile.id } },
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify(wicketStat),
                fields: {
                  profile(existingClaimRefs, { readField }) {
                    return data.updateStat.profile;
                  },
                },
              });

              closeMenu();
            },
          });
        }
      }}
    >
      <div className="edit-over-item-form__menu__items__item__label">
        {role?.profile?.name}
      </div>

      {loading && (
        <div className="edit-over-item-form__menu__items__item__loading">
          <Spinner />
        </div>
      )}
    </div>
  );
}

function BowlerMenuItem({
  role,
  ballsBowledStat,
  runsConcededStat,
  extraStat,
  wicketTakenStat,
  fieldingStat,
  closeMenu,
}) {
  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    // update: (cache, { data }) => {
    //   cache.modify({
    //     id: cache.identify(stat),
    //     fields: {
    //       value(existingClaimRefs, { readField }) {
    //         return data.updateStat.value;
    //       },
    //     },
    //   });

    //   closeMenu();
    // },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BowlerMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });
  return (
    <div
      className="edit-over-item-form__menu__items__item"
      onClick={() => {
        const oldBowler = { ...ballsBowledStat.profile };

        updateStat({
          variables: {
            where: { id: ballsBowledStat.id },
            data: {
              profile: { connect: { id: role.profile.id } },
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(ballsBowledStat),
              fields: {
                profile(existingClaimRefs, { readField }) {
                  return data.updateStat.profile;
                },
              },
            });

            closeMenu();
          },
        });

        updateStat({
          variables: {
            where: { id: runsConcededStat.id },
            data: {
              profile: { connect: { id: role.profile.id } },
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(runsConcededStat),
              fields: {
                profile(existingClaimRefs, { readField }) {
                  return data.updateStat.profile;
                },
              },
            });

            closeMenu();
          },
        });

        if (extraStat?.length) {
          for (let i = 0; i < extraStat.length; i++) {
            updateStat({
              variables: {
                where: { id: extraStat[i].id },
                data: {
                  profile: { connect: { id: role.profile.id } },
                },
              },
              update: (cache, { data }) => {
                cache.modify({
                  id: cache.identify(extraStat[i]),
                  fields: {
                    profile(existingClaimRefs, { readField }) {
                      return data.updateStat.profile;
                    },
                  },
                });

                closeMenu();
              },
            });
          }
        }

        if (wicketTakenStat) {
          updateStat({
            variables: {
              where: { id: wicketTakenStat.id },
              data: {
                profile: { connect: { id: role.profile.id } },
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify(wicketTakenStat),
                fields: {
                  profile(existingClaimRefs, { readField }) {
                    return data.updateStat.profile;
                  },
                },
              });

              closeMenu();
            },
          });
        }

        if (fieldingStat && fieldingStat.profile.id === oldBowler.id) {
          updateStat({
            variables: {
              where: { id: fieldingStat.id },
              data: {
                profile: { connect: { id: role.profile.id } },
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify(fieldingStat),
                fields: {
                  profile(existingClaimRefs, { readField }) {
                    return data.updateStat.profile;
                  },
                },
              });

              closeMenu();
            },
          });
        }

        // updateStat({
        //   variables: {
        //     where: { id: stat.id },
        //     data: {},
        //   },
        // });
      }}
    >
      <div className="edit-over-item-form__menu__items__item__label">
        {role?.profile?.name}
      </div>

      {loading && (
        <div className="edit-over-item-form__menu__items__item__loading">
          <Spinner />
        </div>
      )}
    </div>
  );
}

function RunsScoredMenuItem({ label, stat, closeMenu, runsConcededStat }) {
  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.RunsScoredMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="edit-over-item-form__menu__items__item"
      onClick={() => {
        // console.log("CO:", currentOverNumber, typeof currentOverNumber);
        // return;

        updateStat({
          variables: {
            where: { id: stat.id },
            data: {
              value: label,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(stat),
              fields: {
                value(existingClaimRefs, { readField }) {
                  return data.updateStat.value;
                },
              },
            });

            closeMenu();
          },
        });

        updateStat({
          variables: {
            where: { id: runsConcededStat.id },
            data: {
              value: label,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(runsConcededStat),
              fields: {
                value(existingClaimRefs, { readField }) {
                  return data.updateStat.value;
                },
              },
            });

            closeMenu();
          },
        });
      }}
    >
      <div className="edit-over-item-form__menu__items__item__label">
        {label}
      </div>

      {loading && (
        <div className="edit-over-item-form__menu__items__item__loading">
          <Spinner />
        </div>
      )}
    </div>
  );
}

function Tag({
  stat,
  ball,
  ballsFacedStat,
  runsScoredStat,
  statTypes,
  fixture,
  group,
  itemIndex,
  cachedState,
  selectedPeriodId,
  currentOverNumber,
}) {
  const [createStat] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.Tag",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });
  const [updateStat] = useMutation(UPDATE_STAT, {
    update: (cache, { data: { updateStat } }) => {
      cache.modify({
        id: cache.identify(updateStat),
        fields: {
          value(existingStatRefs, { readField }) {
            return updateStat.value;
          },
          stringValue(existingStatRefs, { readField }) {
            return updateStat.stringValue;
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.Tag",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });
  const [deleteFixtureStat, { loading }] = useMutation(DELETE_FIXTURE_STAT, {
    update: async (cache, { data: { deleteFixtureStat } }) => {
      // const over = Math.floor(ball.value);

      if (stat.statType.name === "Wide" || stat.statType.name === "No Ball") {
        const subgroup = [...group].reverse().slice(itemIndex + 1);

        for (let i = 0; i < subgroup.length; i++) {
          const s = subgroup[i];
          await updateStat({
            variables: {
              where: { id: s.ballsBowledStat.id },
              data: {
                value: s.ballsBowledStat.value + 0.1,
                stringValue: s.ballsBowledStat.value + 0.1 + "",
              },
            },
          });
        }
      }

      if (stat.statType.name === "Wide") {
        await createStat({
          variables: {
            data: {
              event: { connect: { id: fixture.event.id } },
              timeStamp: 1,
              organization: {
                connect: { id: runsScoredStat.organization.id },
              },
              profile: { connect: { id: runsScoredStat.profile.id } },
              statType: {
                connect: {
                  id: statTypes.find((s) => s.name === "Balls Faced").id,
                },
              },
              fixture: { connect: { id: fixture.id } },
              fixturePeriod: {
                connect: { id: runsScoredStat.fixturePeriod.id },
              },
              sport: { connect: { name: "Cricket" } },
              value: 1,
              relationshipsTo: {
                create: [{ from: { connect: { id: runsScoredStat.id } } }],
              },
            },
          },
        });
      }

      deleteFixtureStat.forEach(({ id }) => {
        // console.log("Evicting ", id);
        const normalizedId = cache.identify({ id, __typename: "Stat" });
        cache.evict({ id: normalizedId });
        cache.gc();
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.Tag",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="edit-over-item-form__tags__tag"
      onClick={() => {
        // console.log("Group:", [...group].reverse());
        // console.log("Item index:", itemIndex);
        // console.log("Sub group", [...group].reverse().slice(itemIndex + 1));
        // console.log("Deleting stat: ", stat);
        // console.log("Ball: ", ball);
        // return;

        deleteFixtureStat({ variables: { id: stat.id } });
      }}
    >
      {stat?.statType?.name} ({stat.value})
      {loading ? (
        <Loader />
      ) : (
        <TrashIcon className="edit-over-item-form__tags__tag__icon" />
      )}
    </div>
  );
}

function DeleteOverItem({
  label,
  runsScoredStat,
  extraStats,
  onDeleteLastBall,
  previousGroupStats,
}) {
  const [deleteFixtureStat, { loading }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      // const statIdList = deleteFixtureStat.map((x) => x.id);
      // cache.modify({
      //   id: cache.identify(fixture),
      //   fields: {
      //     stats(existingStatRefs, { readField }) {
      //       return existingStatRefs.filter(
      //         (statRef) => !statIdList.includes(readField("id", statRef))
      //       );
      //     },
      //   },
      // });

      onDeleteLastBall({ extras: [...extraStats], previousGroupStats });

      deleteFixtureStat.forEach(({ id }) => {
        // console.log("Evicting ", id);
        const normalizedId = cache.identify({ id, __typename: "Stat" });
        cache.evict({ id: normalizedId });
        cache.gc();
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.DeleteOverItem",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="delete-over-item"
      onClick={() => {
        // onDeleteLastBall({ extras, previousGroupStats });
        // return;
        deleteFixtureStat({
          variables: {
            id: runsScoredStat.id,
          },
        });
      }}
    >
      {loading ? <Loader /> : "Delete ball " + label}
    </div>
  );
}

function EditOverItem({
  overItem,
  battingLineup,
  bowlingLineup,
  dismiss,
  stats,
  fixture,
  statTypes,
  setOverlay,
  setAltOverlay,
  canDelete,
  onDeleteLastBall,
  previousGroupStats,
  currentOverNumber,
  itemIndex,
  fixtureId,
  selectedPeriodId,
  cache,
  group,
}) {
  // console.log("Edit over item stat:", overItem);
  const {
    runsScoredStat,
    ballsBowledStat,
    ballsFacedStat,
    extraStats,
    wicketStat,
    runsConcededStat,
    wicketTakenStat,
    fieldingStat,
  } = overItem;
  // const runsScoredStat = overItem.runsScoredStat;
  // const ballsBowledStat = overItem.balls;
  // const ballsFacedStat = stat.relationshipsFrom?.find(
  //   (s) => s.to?.statType?.name === "Balls Faced"
  // )?.to;
  // const extraStat = stat.extra;
  // const wicketStat = stat.wicket;
  // console.log(
  //   "Wicket stat raw:",
  //   stats.find((s) => s.id === wicketStat?.id)
  // );

  // const runsConcededStat = stat.runsConceded;
  // const wicketTakenStat = stat.wicketTaken;
  // const fieldingStat = stat.fielding;

  return (
    <div className="edit-over-item-form">
      <div className="edit-over-item-form__title">
        Edit ball {ballsBowledStat.value?.toFixed(1)}{" "}
        <div className="edit-over-item-form__title__dismiss" onClick={dismiss}>
          <TimesIcon style={{ stroke: "var(--light-4)", height: "14px" }} />
        </div>
      </div>

      <div className="edit-over-item-form__items">
        <div className="edit-over-item-form__items__item">
          <div className="edit-over-item-form__items__item__label">
            Runs Scored
          </div>
          <NewDropdown
            target={({ setShowMenu }) => (
              <div className="edit-over-item-form__items__item__value">
                {runsScoredStat.value}{" "}
                <PlayIcon
                  style={{
                    fill: "var(--light-4)",
                    marginLeft: "4px",
                    height: "12px",
                    width: "12px",
                    transform: "rotate(90deg)",
                  }}
                />
              </div>
            )}
            menu={({ setShowMenu }) => (
              <Card className="edit-over-item-form__menu">
                <div className="edit-over-item-form__menu__items">
                  <RunsScoredMenuItem
                    label={0}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                  <RunsScoredMenuItem
                    label={1}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                  <RunsScoredMenuItem
                    label={2}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                  <RunsScoredMenuItem
                    label={3}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                  <RunsScoredMenuItem
                    label={4}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                  <RunsScoredMenuItem
                    label={5}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                  <RunsScoredMenuItem
                    label={6}
                    stat={runsScoredStat}
                    runsConcededStat={runsConcededStat}
                    closeMenu={() => {
                      setShowMenu(false);
                    }}
                    currentOverNumber={currentOverNumber}
                    itemIndex={itemIndex}
                    fixtureId={fixtureId}
                    selectedPeriodId={selectedPeriodId}
                    cachedState={cache}
                  />
                </div>
              </Card>
            )}
          />
        </div>

        <div className="edit-over-item-form__items__item">
          <div className="edit-over-item-form__items__item__label">Batsman</div>
          <NewDropdown
            target={({ setShowMenu }) => (
              <div className="edit-over-item-form__items__item__value">
                {runsScoredStat.profile.name}{" "}
                <PlayIcon
                  style={{
                    fill: "var(--light-4)",
                    marginLeft: "4px",
                    height: "12px",
                    width: "12px",
                    transform: "rotate(90deg)",
                  }}
                />
              </div>
            )}
            menu={({ setShowMenu }) => (
              <Card className="edit-over-item-form__menu">
                <div className="edit-over-item-form__menu__items">
                  {battingLineup.map((r) => (
                    <BatsmanMenuItem
                      role={r}
                      closeMenu={() => {
                        setShowMenu(false);
                      }}
                      runsScoredStat={runsScoredStat}
                      ballsFacedStat={ballsFacedStat}
                      wicketStat={wicketStat}
                    />
                  ))}
                </div>
              </Card>
            )}
          />
        </div>

        <div className="edit-over-item-form__items__item">
          <div className="edit-over-item-form__items__item__label">Bowler</div>
          <NewDropdown
            target={({ setShowMenu }) => (
              <div className="edit-over-item-form__items__item__value">
                {ballsBowledStat.profile.name}{" "}
                <PlayIcon
                  style={{
                    fill: "var(--light-4)",
                    marginLeft: "4px",
                    height: "12px",
                    width: "12px",
                    transform: "rotate(90deg)",
                  }}
                />
              </div>
            )}
            menu={({ setShowMenu }) => (
              <Card className="edit-over-item-form__menu">
                <div className="edit-over-item-form__menu__items">
                  {bowlingLineup.map((r) => (
                    <BowlerMenuItem
                      role={r}
                      closeMenu={() => {
                        setShowMenu(false);
                      }}
                      ballsBowledStat={ballsBowledStat}
                      runsConcededStat={runsConcededStat}
                      extraStats={extraStats}
                      wicketTakenStat={wicketTakenStat}
                      fieldingStat={fieldingStat}
                    />
                  ))}
                </div>
              </Card>
            )}
          />
        </div>
      </div>

      <div className="edit-over-item-form__tags">
        {!!extraStats?.length &&
          extraStats.map((e) => (
            <Tag
              stat={e}
              ball={ballsBowledStat}
              ballsFacedStat={ballsFacedStat}
              runsScoredStat={runsScoredStat}
              statTypes={statTypes}
              fixture={fixture}
              group={group}
              itemIndex={itemIndex}
              cachedState={cache}
              selectedPeriodId={selectedPeriodId}
              currentOverNumber={currentOverNumber}
            />
          ))}

        <NewDropdown
          placement="bottom-start"
          target={
            <div className="edit-over-item-form__tags__tag add">
              + Add Extra
            </div>
          }
          menu={({ setShowMenu }) => (
            <AddExtra
              statTypes={statTypes}
              ballsBowledStat={ballsBowledStat}
              ballsFacedStat={ballsFacedStat}
              fixture={fixture}
              dismiss={() => {
                setShowMenu(false);
              }}
              group={group}
              itemIndex={itemIndex}
              cachedState={cache}
              selectedPeriodId={selectedPeriodId}
              currentOverNumber={currentOverNumber}
            />
          )}
        />
      </div>

      <div className="edit-over-item-form__tags">
        {wicketStat && (
          <Tag
            stat={wicketStat}
            fixture={fixture}
            group={group}
            itemIndex={itemIndex}
            cachedState={cache}
          />
          // <div className="edit-over-item-form__tags__tag">
          //   {wicketStat?.statType?.name}
          //   <TrashIcon className="edit-over-item-form__tags__tag__icon" />
          // </div>
        )}

        {!wicketStat && (
          <NewDropdown
            placement="bottom-start"
            target={
              <div className="edit-over-item-form__tags__tag add">
                + Add Wicket
              </div>
            }
            menu={({ setShowMenu }) => (
              <AddWicket
                statTypes={statTypes}
                ballsBowledStat={ballsBowledStat}
                runsScoredStat={runsScoredStat}
                fixture={fixture}
                dismiss={() => {
                  setShowMenu(false);
                }}
                setOverlay={setOverlay}
                setAltOverlay={setAltOverlay}
                selectedPeriodId={selectedPeriodId}
              />
            )}
          />
        )}
      </div>

      {canDelete && (
        <DeleteOverItem
          label={ballsBowledStat.value?.toFixed(1)}
          runsScoredStat={runsScoredStat}
          extraStats={extraStats}
          onDeleteLastBall={onDeleteLastBall}
          previousGroupStats={previousGroupStats}
        />
      )}
    </div>
  );
}

export default function OverItem({
  overItem,
  battingLineup,
  bowlingLineup,
  stats,
  fixture,
  statTypes,
  setOverlay,
  setAltOverlay,
  canDelete,
  onDeleteLastBall,
  previousGroupStats,
  currentOverNumber,
  itemIndex,
  fixtureId,
  selectedPeriodId,
  cache,
  group,
}) {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditOverItem
        overItem={overItem}
        battingLineup={battingLineup}
        bowlingLineup={bowlingLineup}
        dismiss={() => {
          setEditing(false);
        }}
        stats={stats}
        fixture={fixture}
        statTypes={statTypes}
        setOverlay={setOverlay}
        setAltOverlay={setAltOverlay}
        canDelete={canDelete}
        onDeleteLastBall={onDeleteLastBall}
        previousGroupStats={previousGroupStats}
        currentOverNumber={currentOverNumber}
        itemIndex={itemIndex}
        fixtureId={fixtureId}
        selectedPeriodId={selectedPeriodId}
        cache={cache}
        group={group}
      />
    );
  } else {
    return (
      <div className="over-item">
        <div className="label">
          {overItem.ballsBowledStat?.value?.toFixed(1)}
        </div>
        <div className="run">{overItem.runsScoredStat.value}</div>
        <div className="text">
          <div className="primary">
            {overItem.ballsBowledStat.profile.name} to{" "}
            {overItem.runsScoredStat.profile.name}
          </div>
          <div className="secondary">
            {overItem.extraStats?.length
              ? overItem.extraStats
                  .map((x) => x.statType.name + `(${x.value})`)
                  .join(", ")
              : ""}
            {overItem.extraStats?.length && overItem.wicketStat ? " | " : ""}{" "}
            {overItem.wicketStat?.statType.name || ""}
          </div>
        </div>
        <div
          className="edit"
          onClick={() => {
            setEditing(true);
          }}
        >
          <PencilIcon style={{ height: "14px", fill: "var(--light-4" }} />
        </div>
      </div>
    );
  }
}
