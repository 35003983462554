import {
  ManageEventAwards,
  ManageEventDetails,
  ManageEventFixtures,
  ManageEventTeams,
  ManageEventRegistrations,
  ManageEventPosts,
  ManageEventStandings,
  ManageEventPartners,
} from "../../mobileComponents/ManageEvent";
import "./ManageEvent.css";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useRouterQuery from "../../utils/useRouterQuery";
import { useRef } from "react";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import { useQuery } from "@apollo/client";
import { EVENT } from "../../api/event";
import { CAN_MANAGE_EVENT } from "../../api/user";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import Placeholder from "../../components/Placeholder";
import makeFileUrl from "../../utils/makeFileUrl";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

const manageEventTabs = [
  { label: "Details", component: ManageEventDetails, key: "details" },
  {
    label: "Teams",
    component: ManageEventTeams,
    key: "teams",
  },
  {
    label: "Registrations",
    component: ManageEventRegistrations,
    key: "registrations",
  },
  { label: "Fixtures", component: ManageEventFixtures, key: "fixtures" },
  { label: "Standings", component: ManageEventStandings, key: "standings" },
  { label: "Posts", component: ManageEventPosts, key: "posts" },
  { label: "Awards", component: ManageEventAwards, key: "awards" },
  { label: "Partners", component: ManageEventPartners, key: "partners" },
];

export default function ManageEvent() {
  usePageTracking({ title: "Manage Event" });
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const headerRef = useRef(null);
  const isOnScreen = useIntersectionObserver(headerRef, true);
  const tab = query.get("t") || "details";
  const selectedTab = manageEventTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const { error: authError, data: authData } = useQuery(CAN_MANAGE_EVENT, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(EVENT, {
    variables: { id },
  });

  if (authError) {
    captureException({
      error: authError,
      info: {
        type: "query",
        component: "ManageEvent",
        query: "CAN_MANAGE_EVENT",
      },
    });
    console.log(authError);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEvent",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="organization-page-mobile">
        <div className="organization-page-mobile__nav">
          <div
            className="organization-page-mobile__nav__back-btn"
            onClick={() => {
              if (location.key) {
                history.goBack();
              } else {
                history.replace("/app/directory");
              }
            }}
          >
            <ArrowLeftIcon
              style={{ height: "16px", stroke: "var(--dark-4)" }}
            />
          </div>
        </div>
        <div className="organization-page-mobile__cover" ref={headerRef}></div>

        <div className="organization-page-mobile__header">
          <div className="organization-page-mobile__header__image"></div>

          <div className="organization-page-mobile__header__desc">
            <div className="organization-page-mobile__header__desc__primary">
              <Placeholder
                style={{ height: "12px", width: "100px" }}
                pulse={true}
              />
            </div>

            <div className="organization-page-mobile__header__desc__secondary">
              <Placeholder
                style={{ height: "12px", width: "60px", marginTop: "8px" }}
                pulse={true}
              />
            </div>

            <Placeholder
              style={{
                height: "24px",
                width: "80px",
                background: "var(--blue-subtle)",
                borderRadius: "8px",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-page-mobile__tabs">
          {[1, 2, 3].map((t, i) => (
            <div
              key={"org-tab-" + i}
              className={"organization-page-mobile__tabs__tab "}
            >
              <Placeholder
                style={{
                  height: "10px",
                  width: "40px",
                  background: "var(--light-2)",
                }}
                pulse={false}
              />
            </div>
          ))}
        </div>

        <div className="organization-page-mobile__body"></div>
      </div>
    );
  }

  const ev = data.event;
  const canManageEvent = authData?.canManageEvent?.authorized;

  if (!canManageEvent) {
    return <div>You are not authorized to manage this event.</div>;
  }

  return (
    <div className="event-page-mobile">
      <div className="event-page-mobile__nav">
        <div
          className="event-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app/events");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
        {!isOnScreen && (
          <div className="event-page-mobile__nav__content">
            <div className="event-page-mobile__nav__content__img"></div>
            {ev.name}
          </div>
        )}
      </div>

      <div className="event-page-mobile__cover" ref={headerRef}>
        {ev?.coverPhoto && <img src={makeFileUrl(ev.coverPhoto)} alt="" />}
      </div>

      <div className="event-page-mobile__header">
        <div
          className="event-page-mobile__header__image"
          style={{ height: "40px", width: "40px" }}
        >
          {ev.profilePhoto && <img src={makeFileUrl(ev.profilePhoto)} alt="" />}
        </div>

        <div className="event-page-mobile__header__desc">
          <div className="event-page-mobile__header__desc__secondary">
            Event Manager
          </div>

          <div className="event-page-mobile__header__desc__primary">
            {ev.name}
          </div>
        </div>
      </div>

      <div className="event-page-mobile__tabs">
        {manageEventTabs.map((t, i) => (
          <div
            key={"ev-tab-" + i}
            className={
              "event-page-mobile__tabs__tab " +
              (selectedTab.key === t.key ? "active" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>

      <div className="event-page-mobile__body">
        <SelectedComponent id={id} ev={ev} />
      </div>
    </div>
  );
}
