import { gql } from "@apollo/client";

const DELETE_SERVICE = gql`
  mutation deleteService($id: ID!) {
    deleteService(id: $id) {
      id
    }
  }
`;

export default DELETE_SERVICE;
