import { gql } from "@apollo/client";

const CREATE_FIXTURE = gql`
  mutation createFixture($data: CreateFixtureInput!) {
    createFixture(data: $data) {
      id
      createdAt
      statsCacheState
      scheduledStart
      actualStart
      totalLegs
      legs {
        id
      }
      roles {
        id
        order
        type {
          id
          name
        }
        organization {
          id
          name
        }
        profile {
          id
          name
        }
      }
      eventPart {
        id
      }
    }
  }
`;

export default CREATE_FIXTURE;
