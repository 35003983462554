import { gql } from "@apollo/client";

const CREATE_CRICKET_STAT = gql`
  mutation createCricketStat($data: CreateStatInput!) {
    createCricketStat(data: $data) {
      id
      over
      ballsBowled {
        id
        value
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
      ballsFaced {
        id
      }
      runsScored {
        id
        value
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
      runsConceded {
        id
        value
        profile {
          id
          name
        }
      }
      wicket {
        id
        statType {
          id
          name
        }
        profile {
          id
        }
        organization {
          id
        }
      }
      wicketTaken {
        id
        profile {
          id
        }
        organization {
          id
        }
      }
      extras {
        id
        statType {
          id
          name
        }
        value
      }
      fielding {
        id
        profile {
          id
        }
      }
    }
  }
`;

export default CREATE_CRICKET_STAT;
