import "./CricketStats.css";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../../../api/functions";
import Spinner from "../../../../components/Spinner";
import captureException from "../../../../utils/captureException";
import { Link } from "react-router-dom";

const battingLegend = [
  { label: "R", value: "Runs Scored" },
  { label: "B", value: "Balls Faced" },
  { label: "SR", value: "Strike Rate" },
];

const bowlingLegend = [
  { label: "O", value: "Overs" },
  { label: "R", value: "Runs Conceded" },
  { label: "W", value: "Wickets Taken" },
  { label: "WD", value: "Wide" },
  { label: "NB", value: "No Ball" },
  { label: "ECON", value: "Economy Rate" },
];

const battingTableCells = [
  {
    label: "BATTING",
    width: "45%",
    style: { width: "45%" },
    data: (row, ballsPerOver, notOuts) => (
      <>
        <div
          className="fixture-stats-scorecard-mobile__table__td__line"
          style={{ fontWeight: "600" }}
        >
          {row.profile?.name}
        </div>
        <div
          className="fixture-stats-scorecard-mobile__table__td__line"
          style={{ color: "var(--dark-3)", marginTop: "2px" }}
        >
          {getDismissal(row, notOuts)}
        </div>
      </>
    ),
    link: (row) => "/app/profile/" + row.profile.id,
    hasImage: (row) => {
      return row.profile.profilePhoto;
    },
  },
  // {
  //   label: "",
  //   width: "25%",
  //   style: { width: "25%" },
  //   data: (row, ballsPerOver, notOuts) => {
  //     return getDismissal(row, notOuts);
  //     // return dismissalStat?.statType?.name || "";
  //   },
  // },
  {
    label: "R",
    width: "10%",
    style: { width: "10%", fontWeight: "700" },
    data: (row) => row?.rowData?.runsScored || 0,
    legend: "Runs",
  },
  {
    label: "B",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row?.rowData?.ballsFaced || 0,
    legend: "Balls Faced",
  },
  {
    label: "4s",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row?.rowData?.fours || 0,
  },
  {
    label: "6s",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row?.rowData?.sixes || 0,
  },
  {
    label: "SR",
    width: "15%",
    style: { width: "15%" },
    data: (row) => {
      const ballsFaced = row?.rowData?.ballsFaced || 0;
      const runsScored = row?.rowData?.runsScored || 0;

      if (!ballsFaced) {
        return 0;
      } else {
        return ((runsScored / ballsFaced) * 100).toFixed(2);
      }
    },
    legend: "Strike Rate",
  },
];

const bowlingTableCells = [
  {
    label: "BOWLING",
    width: "35%",
    style: {
      width: "35%",
      fontWeight: "600",
      // display: "flex",
      // flexDirection: "row",
      // alignItems: "center",
    },
    data: (row) => row.profile.name,
    link: (row) => "/app/profile/" + row.profile.id,
    hasImage: (row) => {
      return row.profile.profilePhoto;
    },
  },
  {
    label: "O",
    width: "10%",
    style: { width: "10%" },
    data: (row, ballsPerOver) => {
      const ballsBowled = row.rowData?.ballsBowled || 0;

      if (!ballsBowled) {
        return 0;
      } else {
        return (
          Math.floor(ballsBowled / ballsPerOver) +
          (ballsBowled % ballsPerOver) / 10
        );
      }
    },
    legend: "Overs",
  },
  {
    label: "R",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row.rowData?.runsConceded || 0,
    legned: "Runs Conceded",
  },
  {
    label: "W",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row.rowData?.wicketsTaken || 0,
    legend: "Wickets Taken",
  },

  {
    label: "WD",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row.rowData?.wides || 0,
    legend: "Wide",
  },
  {
    label: "NB",
    width: "10%",
    style: { width: "10%" },
    data: (row) => row.rowData?.noBalls || 0,
    legend: "NB",
  },
  {
    label: "ECON",
    width: "15%",
    style: { width: "15%" },
    data: (row, ballsPerOver) => {
      const ballsBowled = row.rowData?.ballsBowled || 0;
      if (!ballsBowled) return 0;

      // console.log(ballsBowled, ballsPerOver);

      const overs =
        Math.floor(ballsBowled / ballsPerOver) + ballsBowled / ballsPerOver;

      // console.log(overs);

      const runsConceded = row.rowData?.runsConceded || 0;

      return (runsConceded / overs).toFixed(2);
    },
    legend: "Economy Rate",
  },
];

const getDismissal = (row, notOuts = []) => {
  const wicket = row?.rowData?.wicket;
  const wicketTaken = wicket?.relationshipsFrom.find(
    (r) => r.to?.statType?.name === "Wicket Taken"
  )?.to;

  if (!wicket) {
    return <div>{notOuts.includes(row.profile.id) ? "Not Out" : ""}</div>;
  } else if (wicket.statType.name === "Caught & Bowled") {
    return (
      <div>
        {"c & b"} {wicketTaken.profile.name}
      </div>
    );
  } else if (wicket.statType.name === "Run Out") {
    const fielding = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Run Out"
    )?.to;
    const stumping = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Run Out Stumping"
    )?.to;
    if (stumping) {
      return (
        <div>
          run out ({fielding?.profile?.name} / {stumping?.profile?.name}){" "}
        </div>
      );
    } else {
      return <div>run out {fielding?.profile?.name}</div>;
    }
  } else if (wicket.statType.name === "Caught") {
    const fielding = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Catch"
    )?.to;
    return (
      <div>
        c {fielding?.profile?.name} b {wicketTaken?.profile?.name}
      </div>
    );
  } else if (wicket.statType.name === "LBW") {
    return <div>lbw {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Bowled") {
    return <div>b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Stumped") {
    const stumping = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Stumping"
    )?.to;
    return (
      <div>
        st. {stumping?.profile?.name} b {wicketTaken?.profile?.name}
      </div>
    );
  } else if (wicket.statType.name === "Retired") {
    return <div>retired</div>;
  } else if (wicket.statType.name === "Obstruction") {
    return <div>obstruction</div>;
  } else if (wicket.statType.name === "Hit Wicket") {
    return <div>hit wicket b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Double Hit") {
    return <div>double hit b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Out of Bounds") {
    return <div>out of bounds</div>;
  } else if (wicket.statType.name === "Ball Handling") {
    return <div>ball handling</div>;
  } else if (wicket.statType.name === "Out by Dots") {
    return <div>out by dots b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Timed Out") {
    return <div>timed out </div>;
  }
};

function BattingTable({ lineup, score, extras, ballsPerOver }) {
  // console.log("Batting stats:", stats);

  let extrasStatement = [];
  const extraKeyAbbrevs = {
    wides: "w",
    noBalls: "nb",
    byes: "b",
    legByes: "lb",
  };

  for (let key in extras) {
    if (key !== "total" && extras[key]) {
      extrasStatement.push(extraKeyAbbrevs[key] + " " + extras[key]);
    }
  }

  let notOutsArray = [];

  [...lineup].reverse().forEach((role) => {
    if (!role.rowData?.wicket) {
      notOutsArray.push(role.profile.id);
    }
  });

  const notOuts = notOutsArray.slice(-2);

  // console.log(notOuts);
  const overs = score.overs;
  const overTokens = overs && (overs + "").split(".");
  const runRateOvers =
    (overTokens?.[0] ? Number(overTokens[0]) : 0) +
    (overTokens?.[1] ? Number(overTokens[1]) / ballsPerOver : 0);
  const runRate = runRateOvers ? (score.runs / runRateOvers).toFixed(2) : 0;
  console.log(ballsPerOver);
  return (
    <div className="fixture-stats-scorecard-mobile__table">
      <div className="fixture-stats-scorecard-mobile__table__header">
        <div className="fixture-stats-scorecard-mobile__table__row">
          {battingTableCells.map((cell) => (
            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: cell.width }}
            >
              {cell.label}
            </div>
          ))}
          {/* <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "45%" }}
          >
            BATTING
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            R
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            B
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            4s
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            6s
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "15%" }}
          >
            SR
          </div> */}
        </div>
      </div>

      <div className="fixture-stats-scorecard-mobile__table__body">
        {lineup.map((r, i) => (
          <div
            key={r.id + "-batting-stats-" + i}
            className="fixture-stats-scorecard-mobile__table__row"
          >
            {battingTableCells.map((cell) => (
              <div
                className="fixture-stats-scorecard-mobile__table__td"
                style={cell.style}
              >
                {cell.link ? (
                  <Link to={cell.link(r)}>
                    {cell.data(r, ballsPerOver, notOuts)}
                  </Link>
                ) : (
                  cell.data(r, ballsPerOver, notOuts)
                )}
              </div>
            ))}
          </div>
        ))}

        {/* {stats.map((row, i) => (
          <div
            key={team.id + "-batting-stats-" + i}
            className="fixture-stats-scorecard-mobile__table__row"
          >
            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "45%" }}
            >
              <div
                className="fixture-stats-scorecard-mobile__table__td__line"
                style={{ fontWeight: "600" }}
              >
                {row.profile?.name}
              </div>
              <div
                className="fixture-stats-scorecard-mobile__table__td__line"
                style={{ color: "var(--dark-3)", marginTop: "2px" }}
              >
                {getDismissal(row.profile.id, allStats)}
              </div>
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%", fontWeight: "700" }}
            >
              {row.runsScored}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.ballsFaced}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.fours}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.sixes}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "15%" }}
            >
              {row.strikeRate}
            </div>
          </div>
        ))} */}
      </div>

      <div className="fixture-stats-scorecard-mobile__table__footer">
        <div className="fixture-stats-scorecard-mobile__table__row">
          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "45%" }}
          >
            <div
              className="fixture-stats-scorecard-mobile__table__td__line"
              style={{ fontWeight: "600" }}
            >
              Extras{" "}
            </div>
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%", fontWeight: "700" }}
          >
            {extras.total}
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "45%" }}
          >
            {extras.total && (
              <div
                className="fixture-stats-scorecard-mobile__table__td__line"
                style={{ color: "var(--dark-4)" }}
              >
                ({extrasStatement.join(", ")})
              </div>
            )}
          </div>
        </div>

        <div className="fixture-stats-scorecard-mobile__table__row">
          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "45%" }}
          >
            <div
              className="fixture-stats-scorecard-mobile__table__td__line"
              style={{ fontWeight: "600" }}
            >
              Total
            </div>
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td "
            style={{ width: "10%", fontWeight: "700" }}
          >
            {score?.stringValue}
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "45%" }}
          >
            <div
              className="fixture-stats-scorecard-mobile__table__td__line"
              style={{ color: "var(--dark-4)" }}
            >
              {score.overs} Ov
              {runRate && <> (RR: {runRate})</>}
              {/* 20 Ov (RR : 9.85) */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function BowlingTable({ lineup, ballsPerOver }) {
  return (
    <div className="fixture-stats-scorecard-mobile__table">
      <div className="fixture-stats-scorecard-mobile__table__header">
        <div className="fixture-stats-scorecard-mobile__table__row">
          {bowlingTableCells.map((cell) => (
            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: cell.width }}
            >
              {cell.label}
            </div>
          ))}
          {/* <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "35%" }}
          >
            BOWLING
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            O
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            R
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            W
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            WD
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "10%" }}
          >
            NB
          </div>

          <div
            className="fixture-stats-scorecard-mobile__table__td"
            style={{ width: "15%" }}
          >
            ECON
          </div> */}
        </div>
      </div>

      <div className="fixture-stats-scorecard-mobile__table__body">
        {lineup.map((r, i) => (
          <div
            className="fixture-stats-scorecard-mobile__table__row"
            key={r.id + "-bowling-row-" + i}
          >
            {bowlingTableCells.map((cell) => (
              <div
                className="fixture-stats-scorecard-mobile__table__td"
                style={cell.style}
              >
                {cell.link ? (
                  <Link to={cell.link(r)}>{cell.data(r, ballsPerOver)}</Link>
                ) : (
                  cell.data(r, ballsPerOver)
                )}
              </div>
            ))}
            {/* <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "35%" }}
            >
              <div
                className="fixture-stats-scorecard-mobile__table__td__line"
                style={{ fontWeight: "600" }}
              >
                {row.profile?.name}
              </div>
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.overs}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.runsConceded}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%", fontWeight: 700 }}
            >
              {row.wicketsTaken}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.wides}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.noBalls}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "15%" }}
            >
              {row.economyRate}
            </div> */}
          </div>
        ))}
        {/* {stats.map((row, i) => (
          <div
            className="fixture-stats-scorecard-mobile__table__row"
            key={team.id + "-bowling-row-" + i}
          >
            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "35%" }}
            >
              <div
                className="fixture-stats-scorecard-mobile__table__td__line"
                style={{ fontWeight: "600" }}
              >
                {row.profile?.name}
              </div>
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.overs}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.runsConceded}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%", fontWeight: 700 }}
            >
              {row.wicketsTaken}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.wides}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "10%" }}
            >
              {row.noBalls}
            </div>

            <div
              className="fixture-stats-scorecard-mobile__table__td"
              style={{ width: "15%" }}
            >
              {row.economyRate}
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
}

function Scorecard({
  team,
  battingLineup,
  bowlingLineup,
  score,
  ballsPerOver,
}) {
  return (
    <div className="fixture-stats-scorecard-mobile">
      <div className="fixture-stats-scorecard-mobile__title">
        <div className="fixture-stats-scorecard-mobile__title__image"></div>

        <div className="fixture-stats-scorecard-mobile__title__text">
          {team.name}
        </div>
      </div>

      <BattingTable
        lineup={battingLineup}
        score={score}
        ballsPerOver={ballsPerOver}
        extras={bowlingLineup.reduce(
          (accum, curr) => {
            const wides = curr.rowData.wides || 0;
            const noBalls = curr.rowData.noBalls || 0;
            const byes = curr.rowData.byes || 0;
            const legByes = curr.rowData.legByes || 0;
            const total = wides + noBalls + byes + legByes;

            accum.wides = accum.wides + wides;
            accum.noBalls = accum.noBalls + noBalls;
            accum.byes = accum.byes + byes;
            accum.legByes = accum.legByes + legByes;
            accum.total = accum.total + total;

            return accum;
          },
          {
            wides: 0,
            noBalls: 0,
            byes: 0,
            legByes: 0,
            total: 0,
          }
        )}
      />

      <div className="fixture-stats-scorecard-mobile__legend">
        {battingLegend.map((x) => (
          <div className="fixture-stats-cricket__table__legend__item">
            {x.label} - {x.value}
          </div>
        ))}
      </div>

      {/* <div className="fixture-stats-scorecard-mobile__info">
        <div className="fixture-stats-scorecard-mobile__info__item">
          <span className="bold">Did not bat:</span> Batsman, Batsman, Batsman
        </div>
      </div> */}

      <BowlingTable lineup={bowlingLineup} ballsPerOver={ballsPerOver} />

      <div className="fixture-stats-scorecard-mobile__legend">
        {bowlingLegend.map((x) => (
          <div className="fixture-stats-scorecard-mobile__legend__item">
            {x.label} - {x.value}
          </div>
        ))}
      </div>

      {/* <div className="fixture-stats-scorecard-mobile__info">
        <div className="fixture-stats-scorecard-mobile__info__item">
          <span className="bold">Fall of wickets:</span> Batsman, Batsman,
          Batsman
        </div>
      </div> */}
    </div>
  );
}

export default function CricketStats({ fixture }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
    cricketLiveScoring{
      id
      liveScores{
        id
        value
        stringValue
        runs
        wickets
        overs
      }
      scorecards{
          id
          items{
              id
              wicket{
                  id
                  statType{
                      id
                      name
                  }
                  relationshipsFrom{
                      id
                      to{
                          id
                          statType{
                              id
                              name
                          }
                          profile{
                              id
                              name                                
                          }
                      }
                  }
              }
              runsScored
              ballsFaced
              fours
              sixes
              ballsBowled
              runsConceded
              wicketsTaken
              wides
              noBalls
          }
      }
    }
    periods{
      id
      order
      label
    }
    metas {
      id
      label
      value
      stringValue
    }
    roles {
      id
      order
      sortOrder
      type {
        id
        name
      }
      subtypes {
        id
        name
      }
      profile {
        id
        name
        firstname
        lastname
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
`,
    }),
    {
      variables: { id: fixture.id },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture.CricketStats",
        query: "CRICKET_STATS",
      },
    });
    console.log(error);

    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const participants = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const sortedParticipants = [...(participants || [])].sort(
    (a, b) => a.order - b.order
  );

  const homeTeam = sortedParticipants[0];
  const homeScorecard = data?.fixture?.cricketLiveScoring?.scorecards?.find(
    (s) => s.id === homeTeam?.organization?.id
  );

  // console.log("Home scorecard:", homeScorecard);

  const awayTeam = sortedParticipants[1];
  const awayScorecard = data?.fixture?.cricketLiveScoring?.scorecards?.find(
    (s) => s.id === awayTeam?.organization?.id
  );

  const players = data?.fixture?.roles?.filter(
    (r) => r.type.name === "LINEUP PLAYER"
  );

  const homePlayers = players?.filter(
    (r) => r.organization?.id === homeTeam?.organization.id
  );

  const awayPlayers = players?.filter(
    (r) => r.organization?.id === awayTeam?.organization.id
  );

  const homeStarters = homePlayers.filter((r) =>
    r.subtypes.find((x) => x.name === "STARTER")
  );

  const homeSubs = homePlayers.filter((r) =>
    r.subtypes.find((x) => x.name !== "STARTER")
  );

  const awayStarters = awayPlayers.filter((r) =>
    r.subtypes.find((x) => x.name === "STARTER")
  );

  const awaySubs = awayPlayers.filter((r) =>
    r.subtypes.find((x) => x.name !== "STARTER")
  );

  const stats = data?.fixture?.stats || [];
  const metas = data?.fixture?.metas;
  const ballsPerOverMeta = metas?.find((m) => m.label === "ballsPerOver");
  const ballsPerOver = ballsPerOverMeta?.value || 6;

  const homeBattingLineup = [...homeStarters]
    .sort((a, b) => {
      return (a.sortOrder || 100) - (b.sortOrder || 100);
    })
    .map((r) => ({
      ...r,
      rowData: homeScorecard?.items?.find((x) => x.id === r.profile?.id),
    }));

  const homeBowlingLineup = [...homeStarters]
    .map((r) => ({
      ...r,
      rowData: homeScorecard?.items?.find((x) => x.id === r.profile?.id),
    }))
    .filter((r) => r.rowData?.ballsBowled);

  const awayBattingLineup = [...awayStarters]
    .sort((a, b) => {
      return (a.sortOrder || 100) - (b.sortOrder || 100);
    })
    .map((r) => ({
      ...r,
      rowData: awayScorecard?.items?.find((x) => x.id === r.profile?.id),
    }));

  const awayBowlingLineup = [...awayStarters]
    .map((r) => ({
      ...r,
      rowData: awayScorecard?.items?.find((x) => x.id === r.profile?.id),
    }))
    .filter((r) => r.rowData?.ballsBowled);

  const liveScores = data?.fixture?.cricketLiveScoring?.liveScores;
  const homeLiveScore = liveScores.find(
    (x) => x.id === homeTeam?.organization?.id
  );

  const awayLiveScore = liveScores.find(
    (x) => x.id === awayTeam?.organization?.id
  );

  return (
    <div className="fixture-stats-mobile__cricket">
      <Scorecard
        team={homeTeam.organization}
        battingLineup={homeBattingLineup}
        bowlingLineup={awayBowlingLineup}
        score={homeLiveScore}
        ballsPerOver={ballsPerOver}
      />

      <Scorecard
        team={awayTeam.organization}
        battingLineup={awayBattingLineup}
        bowlingLineup={homeBowlingLineup}
        score={awayLiveScore}
        ballsPerOver={ballsPerOver}
      />
    </div>
  );
}
