import "./CreateMenu.css";
import { Link } from "react-router-dom";

function CreateMenu() {
  return (
    <div className="create-menu">
      <Link className="create-menu-item hover-pointer" to="/app/create_page">
        <div className="icon"></div>
        <div className="text">
          <div className="primary">Create a Page</div>
          <div className="secondary">
            Make a page for your team, venue, academy or sports business and get
            listed on the Directory.
          </div>
        </div>
      </Link>

      <Link to="/app/create_event" className="create-menu-item">
        <div className="icon"></div>
        <div className="text">
          <div className="primary">Create an Event</div>
          <div className="secondary">
            Easily create, manage and promote your sporting events using the
            tools provided by Sports Paddock.
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CreateMenu;
