import { gql } from "@apollo/client";

const FIXTURE_TIMELINE = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      uploaded
      event {
        id
        sport {
          id
          name
        }
      }
      periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
      }
      roles {
        id
        order
        type {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      stats {
        id
        createdAt
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        fixturePeriod {
          id
          order
          label
        }
        relationshipsFrom {
          id
          relationship {
            id
            type {
              id
              name
            }
          }
          to {
            id
            createdAt
            value
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            fixturePeriod {
              id
              order
              label
            }
          }
        }
        relationshipsTo {
          id
          relationship {
            id
            type {
              id
              name
            }
          }
          from {
            id
            createdAt
            value
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            fixturePeriod {
              id
              order
              label
            }
          }
        }
      }
    }
  }
`;

export default FIXTURE_TIMELINE;
