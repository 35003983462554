import { gql } from "@apollo/client";

const CLAP = gql`
  mutation clap($postId: ID, $mediaId: ID) {
    clap(postId: $postId, mediaId: $mediaId) {
      id
    }
  }
`;

export default CLAP;
