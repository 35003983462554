import "./Scoring.css";
import { useState, Fragment, useEffect } from "react";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as PauseIcon } from "../../../icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../../icons/play.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as WhistleIcon } from "../../../icons/whistle.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import NewDropdown from "../../../components/NewDropdown";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";
import Avatar from "../../../components/Avatar";
import {
  useCricketScoringState,
  CricketStatsContextProvider,
} from "../utils/useCricketScoringState";
import Spinner from "../../../components/Spinner";
import { FIXTURE_STATS, UPDATE_FIXTURE } from "../../../api/functions/fixture";
import { useHistory } from "react-router-dom";
import BallByBall from "../BallByBall";
import CurrentInnings from "../CurrentInnings";
import CurrentOver from "../CurrentOver";
import Status from "../Status";

function ScoringHeader({ id, setStep, homeTeam, awayTeam }) {
  const history = useHistory();
  return (
    <div className="ScoringApp-cricket__scoring__header">
      <div className="ScoringApp-cricket__scoring__header__left-column">
        <div
          className="ScoringApp-football__header__nav-left__back-btn"
          onClick={() => {
            history.replace("/app/fixture/" + id);
          }}
        >
          <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
          <div className="ScoringApp-football__header__nav-left__back-btn__text">
            Back to fixture
          </div>
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__header__middle-column">
        <div className="ScoringApp-cricket__scoring__header__middle-column__team home">
          <div className="ScoringApp-cricket__scoring__header__middle-column__team__img">
            {homeTeam?.organization?.profilePhoto && (
              <Avatar media={homeTeam.organization.profilePhoto} />
            )}
          </div>

          <div className="ScoringApp-cricket__scoring__header__middle-column__team__name">
            {homeTeam?.organization?.name}
          </div>
        </div>

        <Score id={id} homeTeam={homeTeam} awayTeam={awayTeam} />

        <div className="ScoringApp-cricket__scoring__header__middle-column__team away">
          <div className="ScoringApp-cricket__scoring__header__middle-column__team__img">
            {awayTeam?.organization?.profilePhoto && (
              <Avatar media={awayTeam.organization.profilePhoto} />
            )}
          </div>

          <div className="ScoringApp-cricket__scoring__header__middle-column__team__name">
            {awayTeam?.organization?.name}
          </div>
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__header__right-column">
        <div
          className="ScoringApp-football__header__nav-right__nav-btn square"
          onClick={() => {
            setStep(0);
          }}
        >
          <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
        </div>
        <Button
          className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
          onClick={() => {
            setStep(2);
          }}
        >
          Verify & Publish
        </Button>
      </div>
    </div>
  );
}

function ScoringBody({ periods, homeTeam, awayTeam }) {
  const [{ selectedPeriod, battingTeam }] = useCricketScoringState();
  return (
    <div className="ScoringApp-cricket__scoring__body">
      <div className="ScoringApp-cricket__scoring__body__left-column">
        <CurrentInnings
          periods={periods}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
        />

        {selectedPeriod && battingTeam && <CurrentOver />}
      </div>
      <div className="ScoringApp-cricket__scoring__body__right-column">
        {selectedPeriod && battingTeam && <BallByBall />}
      </div>
    </div>
  );
}

function Score({ id, homeTeam, awayTeam }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      cricketLiveScoring{
        id
        liveScores{
          id
          value
          stringValue
          runs
          wickets
        }
      }
  `,
    }),
    {
      variables: {
        id,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div className="ScoringApp-cricket__scoring__header__score" />;
  }

  if (loading || !data) {
    return <div className="ScoringApp-cricket__scoring__header__score" />;
  }

  const stats = data?.fixture?.cricketLiveScoring?.liveScores || [];
  const homeScore = stats.find((s) => s.id === homeTeam.organization?.id);

  const awayScore = stats.find((s) => s.id === awayTeam.organization?.id);

  console.log("Score:", stats, homeScore);

  return (
    <div className="ScoringApp-cricket__scoring__header__score">
      <div className="ScoringApp-cricket__scoring__header__score__value">
        {homeScore.runs}/{homeScore.wickets}
      </div>

      <div className="ScoringApp-cricket__scoring__header__score__seperator">
        vs
      </div>

      <div className="ScoringApp-cricket__scoring__header__score__value">
        {awayScore.runs}/{awayScore.wickets}
      </div>
    </div>
  );
}

export default function Scoring({ id, setStep }) {
  const history = useHistory();
  const [overlay, setOverlay] = useState(false);

  const [updateCache] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
    statsCacheState
  `,
    })
  );

  // const updateCache = (variables, cb) => {
  //   updateCacheMutation({
  //     variables,
  //     update: (cache, { data: { updateFixture } }) => {
  //       cache.modify({
  //         id: cache.identify(updateFixture),
  //         fields: {
  //           statsCacheState() {
  //             return updateFixture.statsCacheState;
  //           },
  //         },
  //       });

  //       cb && cb(updateFixture.statsCacheState);
  //     },
  //   });
  // };

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      completed
      statsCacheState
      event{
        id
        sport{
          id
          statTypes{
            id
            name
            group {
              id
              name
            }
            relationshipsFrom {
              id
              to {
                id
                name
              }
              type {
                id
                name
              }
            }
            relationshipsTo {
              id
              from {
                id
                name
              }
              type {
                id
                name
              }
            }
          }
        }
      }
      periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
      }
      roles{
        id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      
  `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-football__scoring">
        <div />
        <Spinner color="#ffffff" />
      </div>
    );
  }

  // console.log(data?.fixture?.roles);

  const participants = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const homeTeam = participants?.find((p) => !p.order);

  const awayTeam = participants?.find((p) => p.order);

  const players = data?.fixture?.roles?.filter(
    (r) => r.type.name === "LINEUP PLAYER"
  );

  const homeLineup = players?.filter(
    (r) => r.organization?.id === homeTeam?.organization?.id
  );

  const awayLineup = players?.filter(
    (r) => r.organization?.id === awayTeam?.organization?.id
  );

  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  const eventId = data?.fixture?.event?.id;
  const statTypes = data?.fixture?.event?.sport?.statTypes;
  const cache = data?.fixture?.statsCacheState || {};
  const selectedPeriodId = cache?.selectedPeriodId;

  console.log("Cache:", cache);

  const initialState = {
    selectedPeriod: selectedPeriodId
      ? periods.find((x) => x.id === cache.selectedPeriodId)
      : false,
    fixtureId: id,
    eventId,
    setOverlay: setOverlay,
    cache,
    updateCache,
    battingTeam: cache?.periods?.[selectedPeriodId]?.battingTeam || "",
    bowlingTeam: cache?.periods?.[selectedPeriodId]?.bowlingTeam || "",
    batsmen: cache?.periods?.[selectedPeriodId]?.batsmen || [{}, {}],
    bowler: cache?.periods?.[selectedPeriodId]?.bowler || {},
    strikeIndex: cache?.periods?.[selectedPeriodId]?.strikeIndex || 0,
    currentOverNumber:
      cache?.periods?.[selectedPeriodId]?.currentOverNumber || 0,
    homeLineup,
    awayLineup,
    homeTeam,
    awayTeam,
    statTypes,
  };

  return (
    <CricketStatsContextProvider initialState={initialState}>
      <div className="ScoringApp-cricket__scoring">
        <ScoringHeader
          id={id}
          setStep={setStep}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
        />
        <Status
          id={id}
          setStep={setStep}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
        />

        <ScoringBody
          periods={periods}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
        />

        {!!overlay && (
          <div className="ScoringApp-cricket__scoring__overlay">{overlay}</div>
        )}
      </div>
    </CricketStatsContextProvider>
  );
}
