// V1
import { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

function LocationSearch({
  onLocationSelect,
  placeholder,
  searchOptions,
  defaultSearchString = "",
  inputClass = "",
  itemClass = "",
}) {
  const [searchString, setSearchString] = useState(defaultSearchString);
  const onChange = (x) => {
    setSearchString(x);
  };

  const onSelect = (address) => {
    // console.log(address);
    onLocationSelect({ address, setSearchString });
  };

  return (
    <PlacesAutocomplete
      value={searchString}
      onChange={onChange}
      onSelect={onSelect}
      debounce={200}
      searchOptions={
        searchOptions || {
          types: ["(cities)"],
          componentRestrictions: {
            country: "IN",
          },
        }
      }
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: placeholder || "Search Places ...",
              className: "location-search-input " + inputClass,
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions
              .filter(
                (suggestion) =>
                  suggestion?.description?.split(",")?.length &&
                  suggestion?.description?.split(",").length > 2
              )
              .map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active " + itemClass
                  : "suggestion-item " + itemClass;
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={"location-suggestion-" + i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style: {
                        padding: "8px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        height: "auto",
                        ...style,
                      },
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default LocationSearch;
