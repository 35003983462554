import { useMutation } from "@apollo/client";
import { FIXTURE_STATS, CREATE_STAT } from "../../../../api/fixture";
import Card from "../../../Card";
import Spinner from "../../../Spinner";
import ballsCompleted from "../utils/ballsCompleted";
import captureException from "../../../../utils/captureException";

export default function RunContainer({
  fixtureId,
  eventId,
  label,
  value,
  battingOrganization,
  bowlingOrganization,
  fixture,
  batsman,
  dismissedBatsman,
  bowler,
  statTypes,
  extra,
  wicket,
  fielder,
  currentOverNumber,
  onAddStat,
  selectedPeriodId,
  stats,
  groupedByOver,
}) {
  const [createStat, { loading }] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      const q = cache.readQuery({
        query: FIXTURE_STATS,
        variables: { id: fixtureId },
      });

      // console.log("Update:", createStat);

      cache.writeQuery({
        query: FIXTURE_STATS,
        variables: { id: fixtureId },
        data: {
          fixture: {
            ...q.fixture,
            stats: [createStat, ...q.fixture.stats],
          },
        },
      });

      onAddStat();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.RunContainer",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="run-container"
      onClick={() => {
        // return;

        if (loading) {
          return;
        }

        if (!batsman?.profile || !bowler?.profile) {
          return;
        }

        let runsScoredValue = value;

        if (extra?.length && !(extra.length === 1 && extra[0] === "No Ball")) {
          runsScoredValue = 0;
        }

        // console.log("Runs Scored:", runsScoredValue, value);
        // return;

        function getExtraValue(x, value) {
          if (x === "Wide") {
            return 1 + value;
          } else if (x === "No Ball") {
            return 1;
          } else if (x === "Bye") {
            return value;
          } else if (x === "Leg Bye") {
            return value;
          }
        }

        const currentBallLabel = ballsCompleted({
          overNumber: currentOverNumber,
          groupedStats: groupedByOver,
        });

        const runsScoredStat = {
          event: { connect: { id: eventId } },
          timeStamp: 1,
          organization: {
            connect: { id: battingOrganization.id },
          },
          profile: { connect: { id: batsman.profile.id } },
          statType: {
            connect: {
              id: statTypes.find((s) => s.name === "Runs Scored").id,
            },
          },
          fixture: { connect: { id: fixtureId } },
          fixturePeriod: { connect: { id: selectedPeriodId } },
          sport: { connect: { name: "Cricket" } },
          value: runsScoredValue,
        };

        const ballsFacedStat = extra?.includes("Wide")
          ? false
          : {
              event: { connect: { id: eventId } },
              timeStamp: 1,
              organization: {
                connect: { id: battingOrganization.id },
              },
              profile: { connect: { id: batsman.profile.id } },
              statType: {
                connect: {
                  id: statTypes.find((s) => s.name === "Balls Faced").id,
                },
              },
              fixture: { connect: { id: fixtureId } },
              fixturePeriod: { connect: { id: selectedPeriodId } },
              sport: { connect: { name: "Cricket" } },
              value: 1,
            };

        const ballsBowledStat = {
          event: { connect: { id: eventId } },
          timeStamp: 1,
          organization: {
            connect: { id: bowlingOrganization.id },
          },
          profile: { connect: { id: bowler.profile.id } },
          statType: {
            connect: {
              id: statTypes.find((s) => s.name === "Balls Bowled").id,
            },
          },
          fixture: { connect: { id: fixtureId } },
          fixturePeriod: { connect: { id: selectedPeriodId } },
          sport: { connect: { name: "Cricket" } },
          value: currentOverNumber + 0.1 * (currentBallLabel + 1),
          stringValue: currentOverNumber + "." + (currentBallLabel + 1),
        };

        const runsConcededStat = {
          event: { connect: { id: eventId } },
          timeStamp: 1,
          organization: {
            connect: {
              id: bowlingOrganization.id,
            },
          },
          profile: { connect: { id: bowler.profile.id } },
          statType: {
            connect: {
              id: statTypes.find((s) => s.name === "Runs Conceded").id,
            },
          },
          fixture: { connect: { id: fixtureId } },
          fixturePeriod: { connect: { id: selectedPeriodId } },
          sport: { connect: { name: "Cricket" } },
          value: runsScoredValue,
        };

        const extraStat = extra?.length
          ? extra.map((x) => ({
              event: { connect: { id: eventId } },
              timeStamp: 1,
              organization: {
                connect: {
                  id: bowlingOrganization.id,
                },
              },
              profile: { connect: { id: bowler.profile.id } },
              statType: {
                connect: {
                  id: statTypes.find((s) => s.name === x).id,
                },
              },
              fixture: { connect: { id: fixtureId } },
              fixturePeriod: { connect: { id: selectedPeriodId } },
              sport: { connect: { name: "Cricket" } },
              // value: extra === "Wide" || extra === "No Ball" ? 1 : value,
              value: getExtraValue(x, value),
            }))
          : false;

        const wicketStat = wicket
          ? {
              event: { connect: { id: eventId } },
              timeStamp: 1,
              organization: {
                connect: { id: battingOrganization.id },
              },
              profile: {
                connect: {
                  id:
                    wicket === "Run Out" || wicket === "Retired"
                      ? dismissedBatsman.profile.id
                      : batsman.profile.id,
                },
              },
              statType: {
                connect: {
                  id: statTypes.find((s) => s.name === wicket).id,
                },
              },
              fixture: { connect: { id: fixtureId } },
              fixturePeriod: { connect: { id: selectedPeriodId } },
              sport: { connect: { name: "Cricket" } },
              value: 1,
            }
          : false;

        const wicketTakenStat =
          wicket && wicket !== "Run Out" && wicket !== "Retired"
            ? {
                event: { connect: { id: eventId } },
                timeStamp: 1,
                organization: {
                  connect: {
                    id: bowlingOrganization.id,
                  },
                },
                profile: { connect: { id: bowler.profile.id } },
                statType: {
                  connect: {
                    id: statTypes.find((s) => s.name === "Wicket Taken").id,
                  },
                },
                fixture: { connect: { id: fixtureId } },
                fixturePeriod: { connect: { id: selectedPeriodId } },
                sport: { connect: { name: "Cricket" } },
              }
            : false;

        const fieldingStat =
          wicket &&
          wicket !== "Bowled" &&
          wicket !== "LBW" &&
          wicket !== "Retired"
            ? {
                event: { connect: { id: eventId } },
                timeStamp: 1,
                organization: {
                  connect: {
                    id: bowlingOrganization.id,
                  },
                },
                profile: {
                  connect: {
                    id:
                      wicket === "Caught & Bowled"
                        ? bowler.profile.id
                        : fielder.id,
                  },
                },
                statType: {
                  connect: {
                    id: statTypes.find((s) => {
                      const fieldingStatName =
                        wicket === "Caught" || wicket === "Caught & Bowled"
                          ? "Catch"
                          : wicket === "Run Out"
                          ? "Run Out"
                          : "Stumping";

                      return s.name === fieldingStatName;
                    }).id,
                  },
                },
                fixture: { connect: { id: fixtureId } },
                fixturePeriod: { connect: { id: selectedPeriodId } },
                sport: { connect: { name: "Cricket" } },
              }
            : false;

        const runsScoredRelationships = [];
        const ballsBowledRelationships = [{ ...runsConcededStat }];
        const wicketRelationships = [];

        if (wicketStat && wicketTakenStat) {
          wicketRelationships.push(wicketTakenStat);
        }

        if (wicketStat && fieldingStat) {
          wicketRelationships.push(fieldingStat);
        }

        if (wicketRelationships.length) {
          wicketStat.relationshipsFrom = {
            create: wicketRelationships.map((r) => ({ to: { create: r } })),
          };
        }

        // ballsBowledRelationships.push(runsConcededStat);

        if (extraStat?.length) {
          extraStat.forEach((xs) => {
            ballsBowledRelationships.push(xs);
          });
        }

        ballsBowledStat.relationshipsFrom = {
          create: ballsBowledRelationships.map((r) => ({ to: { create: r } })),
        };

        if (ballsFacedStat) {
          runsScoredRelationships.push(ballsFacedStat);
        }

        runsScoredRelationships.push(ballsBowledStat);

        if (wicketStat) {
          runsScoredRelationships.push(wicketStat);
        }

        runsScoredStat.relationshipsFrom = {
          create: runsScoredRelationships.map((r) => ({ to: { create: r } })),
        };

        // console.log(runsScoredStat);
        // console.log("Batsman:", batsman);
        // console.log("Bowler:", bowler);
        // console.log("Fielder:", fielder);
        // console.log("Wicket:", wicket);
        // console.log("Extra:", extra);
        // console.log("Fielding Stat:", fieldingStat);
        // console.log(
        //   "Cond:",
        //   wicket &&
        //     (wicket !== "Bowled" || wicket !== "LBW" || wicket !== "Retired")
        // );
        // return;
        // console.log("Root stat:", runsScoredStat);
        // return;
        createStat({
          variables: {
            data: runsScoredStat,
          },
        });
      }}
    >
      <Card className="run">{loading ? <Spinner /> : label}</Card>
    </div>
  );
}
