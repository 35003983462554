// V1
import { useState, useRef } from "react";
import "./Uploader.css";
import Card from "../Card";
import Button from "../Button";
import { ReactComponent as FolderIcon } from "../../icons/folder.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import axios from "axios";
import { CREATE_MEDIA } from "../../api/media";
import { useMutation } from "@apollo/client";
import ClickOutside from "../ClickOutside";
import { useAppState } from "../../utils/appState";
import truncateText from "../../utils/truncateText";
import { isMobile } from "react-device-detect";
import Compressor from "compressorjs";
import captureException from "../../utils/captureException";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2000000;
const BYTES_PER_MEGA_BYTE = 1000000;

const convertBytesToMB = (bytes) => bytes / BYTES_PER_MEGA_BYTE;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const uploadFile = async ({
  url,
  file,
  headers = {},
  onProgress = () => {},
}) => {
  // console.log("Uploading the file:", file)
  const options = {
    headers: {
      ...headers,
      "Content-Type": file.type,
    },
    onUploadProgress: (p) => {
      // console.log("Progress:", p.loaded, p.total);
      onProgress(Math.round((p.loaded / p.total) * 100), file);
    },
  };
  const response = await axios.put(url, file, options);

  return response;
};

function ImagePreview({ file, index, setAspectRatio, setDimension }) {
  const [src, setSrc] = useState("");
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (e) {
    setSrc(e.target.result);
    const img = new Image();
    img.src = e.target.result;
    img.onload = function () {
      // const height = this.height || 1;
      // const width = this.width || 0;
      const width = this.naturalWidth;
      const height = this.naturalHeight;
      // console.log("heihgt", height);
      // console.log("width", width);
      setDimension(height, width, file);
      setAspectRatio(width / height, file);
    };
  };

  if (!src) {
    return <div />;
  } else {
    return <img src={src} alt={`file preview ${index}`} />;
  }

  // return <img src={URL.createObjectURL(file)} alt={`file preview ${index}`} />;
}

function Uploader({
  type = "IMAGE",
  subtype,
  sourceId,
  sourceType,
  label,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  updateFilesCb = () => {},
  onCreateMedia = () => {},
  mediaSetId,
  dismiss,
  ...otherProps
}) {
  const fileInputField = useRef(null);
  const [, setAppState] = useAppState();
  const [files, setFiles] = useState({});
  const [filesTooBig, setFilesTooBig] = useState([]);
  const [aspectRatios, setAspectRatios] = useState({});
  const [dimensions, setDimensions] = useState({});
  const [progress, setProgress] = useState({});
  const filesLength = Object.keys(files).reduce((accum, curr) => {
    const file = files[curr];
    if (!progress[file.name]) {
      accum++;
    }
    return accum;
  }, 0);

  const [createMedia] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Uploader",
          mutation: "CREATE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const addNewFiles = (newFiles) => {
    const invalidFiles = [];
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      } else {
        invalidFiles.push(file);
      }
    }
    setFilesTooBig((prev) => [...prev, ...invalidFiles]);
    return { ...files };
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const onProgress = (p, file) => {
    setProgress((prev) => ({
      ...prev,
      [file.name]: p,
    }));
  };

  const setAspectRatio = (ratio, file) => {
    if (aspectRatios[file.name]) {
      return;
    }

    setAspectRatios((prev) => ({
      ...prev,
      [file.name]: ratio,
    }));
  };

  const setDimension = (height, width, file) => {
    if (dimensions[file.name]) {
      return;
    }

    setDimensions((prev) => ({
      ...prev,
      [file.name]: { height, width },
    }));
  };

  // console.log("ARs:", aspectRatios);
  // console.log("Dimenstions:", dimensions);
  // console.log("Media Set Id:", mediaSetId);
  // console.log("UPLOADER SOURCE ID:", sourceId);
  // console.log("UPLOADER SOURCE TYPE:", sourceType);

  return (
    <ClickOutside
      onClickOutside={() => {
        if (dismiss) {
          dismiss();
        } else {
          setAppState({ modal: false });
        }
      }}
    >
      {(wrapperRef) => (
        <div
          className="app-uploader-wrapper"
          ref={wrapperRef}
          style={isMobile ? { width: "90%" } : {}}
        >
          <Card className="app-uploader">
            <div
              className="title"
              style={
                isMobile
                  ? {
                      fontFamily: "var(--nhg-display)",
                      /* font-style: normal; */
                      fontWeight: "700",
                      fontSize: "14px",
                      // margin-top: 16px;
                      // margin-bottom: 16px;
                    }
                  : {}
              }
            >
              Upload Files
            </div>
            <div className="input-container">
              <FolderIcon className="input-icon" />
              <div className="input-placeholder">
                {isMobile
                  ? "Select your files"
                  : "Drag and drop your files here or"}
              </div>
              <Button className="primary small blue browse-files-btn">
                Browse files
              </Button>
              <input
                type="file"
                ref={fileInputField}
                title=""
                value=""
                onChange={handleNewFileUpload}
                {...otherProps}
              />
            </div>
            <div className="files-list">
              {Object.keys(files).map((filename, index) => {
                let file = files[filename];
                let isImageFile = file.type.split("/")[0] === "image";

                return (
                  <div key={"file-item-" + index} className="file-item">
                    <div className="preview">
                      {isImageFile && (
                        <ImagePreview
                          file={file}
                          index={index}
                          setAspectRatio={setAspectRatio}
                          setDimension={setDimension}
                        />
                      )}
                    </div>
                    <div className="meta">
                      <div className="desc">
                        <div className="file-name">{file.name}</div>
                        <div className="file-size">
                          {convertBytesToMB(file.size).toFixed(1) || "< 1"} MB
                        </div>
                      </div>
                      <div className="progress-container">
                        <div className="progress-bar">
                          <div
                            className={
                              "progress-amount " +
                              (progress[file.name] === 100 ? "complete" : "")
                            }
                            style={{ width: (progress[file.name] || 0) + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="options">
                      <div
                        className="option"
                        onClick={() => {
                          removeFile(file.name);
                        }}
                      >
                        <TimesIcon
                          style={{ stroke: "var(--light-3)", height: "16px" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              {filesTooBig.map((file, index) => {
                let isImageFile = file.type.split("/")[0] === "image";
                return (
                  <div key={"file-item-" + index} className="file-item">
                    <div className="preview">
                      {isImageFile && (
                        <ImagePreview
                          file={file}
                          index={index}
                          setAspectRatio={setAspectRatio}
                        />
                      )}
                    </div>
                    <div className="meta">
                      <div className="desc">
                        <div className="file-name">
                          {truncateText(file.name, 20)}
                        </div>
                        <div className="file-size">
                          {convertBytesToMB(file.size).toFixed(1) || "< 1"} MB
                        </div>
                      </div>
                      <div className="error-container">
                        This file is too big. Max file size is 2 MB for photos
                        and 10 MB for videos.
                      </div>
                    </div>
                    <div className="options">
                      <div
                        className="option"
                        onClick={() => {
                          setFilesTooBig((prev) =>
                            prev.filter((f) => f.name !== file.name)
                          );
                          // removeFile(file.name);
                        }}
                      >
                        <TimesIcon
                          style={{ stroke: "var(--light-3)", height: "16px" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {!!filesLength ? (
              <Button
                className="primary green large upload-btn"
                onClick={async () => {
                  if (!sourceId || !sourceType) {
                    console.error("No source details");
                    return;
                  }

                  Object.keys(files).forEach((filename) => {
                    const file = files[filename];
                    const sanitizedFilename = file.name
                      .replaceAll("#", "")
                      .trim();
                    if (!sanitizedFilename) {
                      console.error("File must have a valid name");
                      return;
                    }
                    console.log("TEST PASSED: 1");
                    createMedia({
                      variables: {
                        type,
                        subtype,
                        filename: sanitizedFilename,
                        private: false,
                        isExternal: false,
                        sourceId,
                        sourceType,
                        ...(mediaSetId ? { mediaSetId } : {}),
                        ...(aspectRatios[filename]
                          ? { aspectRatio: aspectRatios[filename] }
                          : {}),
                        ...(dimensions[filename]
                          ? {
                              height: dimensions[filename].height,
                              width: dimensions[filename].width,
                            }
                          : {}),
                      },
                      update: async (cache, { data: { createMedia } }) => {
                        console.log("TEST PASSED: 2");
                        if (type === "IMAGE") {
                          new Compressor(file, {
                            width: 20,
                            success: async (result) => {
                              // Full file
                              console.log("TEST PASSED: 3");
                              await uploadFile({
                                url: createMedia.url,
                                file,
                                headers: {
                                  "x-amz-acl": "public-read",
                                },
                                onProgress,
                              });

                              // Placeholder file
                              await uploadFile({
                                url: createMedia.placeholderUrl,
                                file: result,
                                headers: {
                                  "x-amz-acl": "public-read",
                                },
                                onProgress: () => {},
                              });

                              onCreateMedia(cache, createMedia);
                            },
                            error(err) {
                              console.log(err.message);
                            },
                          });
                        } else {
                          await uploadFile({
                            url: createMedia.url,
                            file,
                            headers: {
                              "x-amz-acl": "public-read",
                            },
                            onProgress,
                          });

                          onCreateMedia(cache, createMedia);
                        }
                      },
                    });
                  });
                }}
              >
                Upload {filesLength} {type === "IMAGE" ? "Image" : "File"}
                {files.length > 1 ? "s" : ""}
              </Button>
            ) : (
              <Button
                className="tertiary"
                onClick={() => {
                  if (dismiss) {
                    dismiss();
                  } else {
                    setAppState({ modal: false });
                  }
                }}
                style={{ color: "var(--dark-4)", marginTop: "16px" }}
              >
                Close
              </Button>
            )}
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

export default Uploader;
