import { gql } from "@apollo/client";

const EVENT_FIXTURES = gql`
  query eventFixturesQuery(
    $id: ID!
    $take: Int
    $cursor: ID
    $filters: EventFixturesFilters
    $orderBy: String
  ) {
    event(id: $id) {
      id
      participantType
      parts {
        id
        type
        label
        order
        partOf {
          id
        }
      }
      fixtures(
        take: $take
        cursor: $cursor
        filters: $filters
        orderBy: $orderBy
      ) {
        id
        createdAt
        scheduledStart
        actualStart
        totalLegs
        legs {
          id
        }
        roles {
          id
          order
          type {
            id
            name
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        event {
          id
          sport {
            id
            name
          }
        }
        eventPart {
          id
        }
        placements(where: { type: { name: "RESULT" } }) {
          id
          order
          organization {
            id
          }
          profile {
            id
          }
          value
          valueString
        }
        badmintonBoxScore {
          id
          label
          homeScore
          awayScore
        }
      }
    }
  }
`;

export default EVENT_FIXTURES;
