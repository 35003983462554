import { useQuery } from "@apollo/client";
import { useBadmintonScoringState } from "../utils/useBadmintonScoringState";
import "./StatsList.css";
import { FIXTURE, FIXTURE_STATS } from "../../../api/functions";
import Spinner from "../../../components/Spinner";
import StatsListItem from "../StatsListItem";

export default function StatsList({
  homeParticipant,
  awayParticipant,
  participantType,
}) {
  const [{ fixtureId, selectedPeriod }] = useBadmintonScoringState();

  const { loading, data, error } = useQuery(FIXTURE_STATS, {
    variables: {
      id: fixtureId,
      where: {
        fixturePeriod: {
          id: { in: [selectedPeriod?.id] },
        },
      },
    },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__stats-list">
        <div className="ScoringApp-cricket__scoring__stats-list__header">
          Points
        </div>

        <div className="ScoringApp-cricket__scoring__stats-list__list">
          <Spinner />
        </div>
      </div>
    );
  }

  const stats = data?.fixture?.stats || [];
  //   const [
  //     {
  //       fixtureId,
  //       selectedPeriod,
  //       battingTeam,
  //       batsmen,
  //       bowler,
  //       strikeIndex,
  //       currentOverNumber,
  //     },
  //     setCricketScoringState,
  //   ] = useCricketScoringState();

  //   const { loading, error, data } = useQuery(
  //     FIXTURE({
  //       selectionSet: overItemSelectionSet(selectedPeriod, currentOverNumber),
  //     }),
  //     {
  //       variables: {
  //         id: fixtureId,
  //       },
  //     }
  //   );

  //   if (error) {
  //     console.log(error);
  //     return <div />;
  //   }

  //   if (loading || !data) {
  //     return (
  //       <div className="ScoringApp-cricket__scoring__current-over">
  //         <Spinner />
  //       </div>
  //     );
  //   }

  //   const overItems = data?.fixture?.cricketLiveScoring?.overStats || [];
  //   const sortedOverItems = [...overItems].reverse();
  console.log(stats);
  return (
    <div className="ScoringApp-cricket__scoring__stats-list">
      <div className="ScoringApp-cricket__scoring__stats-list__header">
        Points
      </div>

      <div
        className="ScoringApp-cricket__scoring__stats-list__list"
        style={{ background: "var(--light-2)", overflow: "auto" }}
      >
        {stats
          .filter((s) => s.statType.name === "Point Won")
          .map((s) => (
            <StatsListItem
              stat={s}
              stats={stats}
              homeParticipant={homeParticipant}
              awayParticipant={awayParticipant}
              participantType={participantType}
            />
          ))}
      </div>
    </div>
  );
}
