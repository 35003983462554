import "./BasketballStats.css";
import { useState, useRef, useEffect } from "react";
import { ReactComponent as UpDown } from "../../../../icons/up-down.svg";
import { useQuery } from "@apollo/client";
import Spinner from "../../../../components/Spinner";
import NewDropdown from "../../../../components/NewDropdown";
import Card from "../../../../components/Card";
import { BASKETBALL_STATS } from "../../../../api/fixture";
import Square from "../../../Square/Square";
import Avatar from "../../../../components/Avatar";
import captureException from "../../../../utils/captureException";
// import useIntersectionObserver from "../../../../utils/useIntersectionObserver";

const tableCells = [
  // {
  //   label: "Player",
  //   tooltip: "Player",
  //   width: "16%",
  //   style: { width: "16%" },
  //   data: (row) => row.profile.name,
  //   onClick: (row) => "/app/profile/" + row.profile.id,
  // },
  {
    label: "1FGA",
    groups: ["Shooting"],
    tooltip: "1 Pt. Field Goals Attempted",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["1FGA"] || 0,
  },
  {
    label: "1FGM",
    groups: ["Shooting"],
    tooltip: "1 Pt. Field Goals Made",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["1FGM"] || 0,
  },
  {
    label: "2FGA",
    groups: ["Shooting"],
    tooltip: "2 Pt. Field Goals Attempted",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["2FGA"] || 0,
  },
  {
    label: "2FGM",
    groups: ["Shooting"],
    tooltip: "2 Pt. Field Goals Made",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["2FGM"] || 0,
  },
  {
    label: "3FGA",
    groups: ["Shooting"],
    tooltip: "3 Pt. Field Goals Attempted",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["3FGA"] || 0,
  },
  {
    label: "3FGM",
    groups: ["Shooting"],
    tooltip: "3 Pt. Field Goals Made",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["3FGM"] || 0,
  },
  {
    label: "FTA",
    groups: ["Shooting"],
    tooltip: "Free Throws Attempted",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["FTA"] || 0,
  },
  {
    label: "FTM",
    groups: ["Shooting"],
    tooltip: "Free Throws Made",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["FTM"] || 0,
  },
  {
    label: "AST",
    groups: ["Offense"],
    tooltip: "Assists",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["AST"] || 0,
  },
  {
    label: "T/O",
    groups: ["Offense"],
    tooltip: "Turnovers",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["T/O"] || 0,
  },
  {
    label: "OFF REB",
    groups: ["Offense"],
    tooltip: "Offensive Rebounds",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["OFF REB"] || 0,
  },
  {
    label: "PTS",
    groups: ["Offense"],
    tooltip: "Points",
    width: "5%",
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["PTS"] || 0,
  },
  {
    label: "BLK",
    groups: ["Defense"],
    tooltip: "Blocks",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["BLK"] || 0,
  },
  {
    label: "DEF REB",
    groups: ["Defense"],
    tooltip: "Blocks",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["DEF REB"] || 0,
  },
  {
    label: "STL",
    groups: ["Defense"],
    tooltip: "Steals",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["STL"] || 0,
  },
  {
    label: "FOULS",
    groups: ["Defense"],
    tooltip: "Fouls",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["FOULS"] || 0,
  },
];

function TableHeader({ label, style = {} }) {
  // const [scroll, setScroll] = useState(0);
  // const handleScroll = (e) => {
  //   console.log(e.target.scrollTop);
  //   setScroll(e.target.scrollTop);
  // };
  // useEffect(() => {
  //   console.log("Running effect for:", label);
  //   const element = document.getElementById("fixture-scroll-container");

  //   if (element) {
  //     element.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     element.removeEventListener("scroll", handleScroll);
  //   };
  // }, [label]);

  return (
    <Square>
      {({ squareRef, dimensions }) => {
        // console.log("Dimensions:", dimensions);
        return (
          <div
            className={"match-player-stats-mobile__table__header "}
            style={style}
            ref={squareRef}
          >
            {label}
          </div>
        );
      }}
    </Square>
  );
}

function PlayerStats({ fixture, homeTeam, awayTeam, homeLineup, awayLineup }) {
  const [isScrolled, setIsScrolled] = useState(0);
  const scrollRef = useRef(null);
  useEffect(() => {
    const div = scrollRef?.current;
    const handleScroll = () => {
      // console.log(div.scrollLeft);
      if (div.scrollLeft > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => div?.removeEventListener("scroll", handleScroll);
  }, [scrollRef]);
  const categories = [
    { name: "Offense" },
    { name: "Defense" },
    { name: "Shooting" },
  ].sort((a, b) => a.name.localeCompare(b.name));

  const [selectedTeam, setSelectedTeam] = useState(homeTeam);
  const [selectedCategory, setSelectedCategory] = useState(categories?.[0]);
  const [sortField] = useState("name");
  const [sortOrder] = useState("asc");

  const rows = fixture.statTables;

  const teamRows = rows
    .filter((r) => r.organization?.id === selectedTeam.organization?.id)
    .reduce((accum, curr) => {
      const playerRowIndex = accum.findIndex(
        (x) => x.profile?.id === curr.profile?.id
      );
      if (playerRowIndex < 0) {
        accum.push({
          profile: curr.profile,
          [curr.statType.name]: curr.value || 0,
        });
      } else {
        accum[playerRowIndex][curr.statType.name] = curr.value || 0;
      }
      return accum;
    }, []);

  const playersWithNoStats = (
    selectedTeam.id === homeTeam.id ? homeLineup : awayLineup
  ).filter((l) => !teamRows.find((r) => r.profile?.id === l.profile?.id));

  const tableRows = [...teamRows, ...playersWithNoStats];

  const sortedTableRows = tableRows?.sort((a, b) => {
    if (sortField === "name") {
      if (sortOrder === "desc") {
        return b.profile?.name.localeCompare(a.profile?.name);
      } else {
        return a.profile?.name.localeCompare(b.profile?.name);
      }
    } else {
      const targetCell = tableCells.find((x) => x.label === sortField);
      if (sortOrder === "desc") {
        return (targetCell?.data(b) || 0) - (targetCell?.data(a) || 0);
      } else {
        return (targetCell?.data(a) || 0) - (targetCell?.data(b) || 0);
      }
    }
  });

  // console.log("Team rows:", sortedTableRows);

  return (
    <div className="match-player-stats-mobile">
      <div className="match-player-stats-mobile__header">
        <div className="match-player-stats-mobile__header__wrapper">
          <NewDropdown
            target={
              <div className="match-player-stats-mobile__header__team-selector">
                <div className="match-player-stats-mobile__header__team-selector__image">
                  {selectedTeam?.organization?.profilePhoto && (
                    <Avatar media={selectedTeam.organization.profilePhoto} />
                  )}
                </div>
                {selectedTeam?.organization?.name}
                <UpDown
                  style={{
                    fill: "var(--light-3)",
                    height: "10px",
                    marginLeft: "8px",
                  }}
                />
              </div>
            }
            menu={({ setShowMenu }) => (
              <Card className="fixture-stats-page__player-stats__header__team__menu">
                <div
                  className="fixture-stats-page__player-stats__header__team__menu__item"
                  onClick={() => {
                    setSelectedTeam(homeTeam);
                    setShowMenu(false);
                  }}
                >
                  {homeTeam?.organization?.name}
                </div>
                <div
                  className="fixture-stats-page__player-stats__header__team__menu__item"
                  onClick={() => {
                    setSelectedTeam(awayTeam);
                    setShowMenu(false);
                  }}
                >
                  {awayTeam?.organization?.name}
                </div>
              </Card>
            )}
          />

          <NewDropdown
            wrapperClass="fixture-stats-page__player-stats__header__category__wrapper"
            target={
              <div className="match-player-stats-mobile__header__category-selector">
                {selectedCategory?.name}
                <UpDown
                  style={{
                    fill: "var(--light-3)",
                    height: "10px",
                    marginLeft: "8px",
                  }}
                />
              </div>
            }
            menu={({ setShowMenu }) => (
              <Card className="fixture-stats-page__player-stats__header__category__menu">
                {categories
                  //   .filter((c) =>
                  //     [
                  //       "Attack",
                  //       "Passing",
                  //       "Defense",
                  //       "Discipline",
                  //       "Goalkeeping",
                  //     ].includes(c.name)
                  //   )
                  .map((category) => (
                    <div
                      className="fixture-stats-page__player-stats__header__category__menu__item"
                      onClick={() => {
                        setSelectedCategory(category);
                        setShowMenu(false);
                      }}
                    >
                      {category.name}
                    </div>
                  ))}
              </Card>
            )}
          />
        </div>
      </div>

      <div className="match-player-stats-mobile__table">
        <div
          className={
            "match-player-stats-mobile__table__fixed-columns " +
            (isScrolled ? "inset" : "")
          }
        >
          <div className="match-player-stats-mobile__table__column">
            <TableHeader
              label={"PLAYER"}
              style={{ fontWeight: "600", color: "var(--dark-1)" }}
            />
            {/* <div
              className="match-player-stats-mobile__table__header"
              style={{ fontWeight: "600", color: "var(--dark-1)" }}
            >
              PLAYER
            </div> */}
            <div className="match-player-stats-mobile__table__rows">
              {sortedTableRows?.map((row, i) => (
                <div className="match-player-stats-mobile__table__rows__row">
                  <div
                    className={
                      "match-player-stats-mobile__table__rows__row__td " +
                      (i % 2 ? "even" : "odd")
                    }
                    style={{ fontWeight: "600", color: "var(--dark-2)" }}
                  >
                    <div className="match-player-stats-mobile__table__rows__row__td__image">
                      {row.profile?.profilePhoto && (
                        <Avatar media={row.profile.profilePhoto} />
                      )}
                    </div>

                    <div className="match-player-stats-mobile__table__rows__row__td__text">
                      {row.profile?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={
            "match-player-stats-mobile__table__scroll-columns " +
            (isScrolled ? "inset" : "")
          }
          ref={scrollRef}
        >
          {tableCells
            .filter((cell) => cell.groups.includes(selectedCategory.name))
            .map((cell) => (
              <div className="match-player-stats-mobile__table__column">
                <div className="match-player-stats-mobile__table__header">
                  {cell.label.toUpperCase()}{" "}
                </div>
                <div className="match-player-stats-mobile__table__rows">
                  {sortedTableRows?.map((row, i) => (
                    <div className="match-player-stats-mobile__table__rows__row">
                      <div
                        className={
                          "match-player-stats-mobile__table__rows__row__td " +
                          (i % 2 ? "even" : "odd")
                        }
                        style={{
                          color: "var(--dark-4)",
                          ...(cell.data && cell.data(row)
                            ? { fontWeight: "700" }
                            : { color: "var(--light-3)" }),
                        }}
                      >
                        <div className="match-player-stats-mobile__table__rows__row__td__text">
                          {cell.data ? cell.data(row) : ""}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function MatchStatsItem({
  homeValue,
  awayValue,
  label,
  homeGraphWidth,
  awayGraphWidth,
}) {
  return (
    <div className="match-stats-item-mobile">
      <div className="match-stats-item-mobile__meta">
        <div className="match-stats-item-mobile__meta__value home">
          {homeValue}
        </div>

        <div className="match-stats-item-mobile__meta__label">{label}</div>

        <div className="match-stats-item-mobile__meta__value away">
          {awayValue}
        </div>
      </div>

      <div className="match-stats-item-mobile__graph">
        <div className="match-stats-item-mobile__graph__section home">
          <div
            className="match-stats-item-mobile__graph__section__fill"
            style={{ width: homeGraphWidth }}
          ></div>
        </div>

        <div className="match-stats-item-mobile__graph__section away">
          <div
            className="match-stats-item-mobile__graph__section__fill"
            style={{ width: awayGraphWidth }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function MatchStats({ homeTeam, awayTeam, homeStats = {}, awayStats = {} }) {
  const homePoints = homeStats.points || 0;
  const awayPoints = awayStats.points || 0;
  const totalPoints = homePoints + awayPoints;
  //   const homeShotsOnTarget = homeStats.shotsOnTarget || 0;
  //   const awayShotsOnTarget = awayStats.shotsOnTarget || 0;

  const homeSteals = homeStats.steals || 0;
  const awaySteals = awayStats.steals || 0;
  const totalSteals = homeSteals + awaySteals;

  const homeBlocks = homeStats.blocks || 0;
  const awayBlocks = awayStats.blocks || 0;
  const totalBlocks = homeBlocks + homeBlocks;

  const homeOffRebs = homeStats.offensiveRebounds || 0;
  const awayOffRebs = awayStats.offensiveRebounds || 0;
  const totalOffRebs = homeOffRebs + awayOffRebs;

  const homeDefRebs = homeStats.defensiveRebounds || 0;
  const awayDefRebs = awayStats.defensiveRebounds || 0;
  const totalDefRebs = homeDefRebs + awayDefRebs;

  const homeTurnovers = homeStats.turnovers || 0;
  const awayTurnovers = awayStats.turnovers || 0;
  const totalTurnovers = homeTurnovers + awayTurnovers;

  const homeFouls = homeStats.fouls || 0;
  const awayFouls = awayStats.fouls || 0;
  const totalFouls = homeFouls + awayFouls;

  return (
    <div className="fixture-stats-mobile__football__match-stats">
      <div className="fixture-stats-mobile__football__match-stats__title">
        Match Stats
      </div>

      <div className="fixture-stats-mobile__football__match-stats__items">
        <MatchStatsItem
          label={"Points"}
          homeValue={homePoints}
          awayValue={awayPoints}
          homeGraphWidth={
            totalPoints ? (homePoints / totalPoints) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalPoints ? (awayPoints / totalPoints) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Steals"}
          homeValue={homeSteals}
          awayValue={awaySteals}
          homeGraphWidth={
            totalSteals ? (homeSteals / totalSteals) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalSteals ? (awaySteals / totalSteals) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Blocks"}
          homeValue={homeBlocks}
          awayValue={awayBlocks}
          homeGraphWidth={
            totalBlocks ? (homeBlocks / totalBlocks) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalBlocks ? (awayBlocks / totalBlocks) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Off Reb"}
          homeValue={homeOffRebs}
          awayValue={awayOffRebs}
          homeGraphWidth={
            totalOffRebs ? (homeOffRebs / totalOffRebs) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalOffRebs ? (awayOffRebs / totalOffRebs) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Def Reb"}
          homeValue={homeDefRebs}
          awayValue={awayDefRebs}
          homeGraphWidth={
            totalDefRebs ? (homeDefRebs / totalDefRebs) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalDefRebs ? (awayDefRebs / totalDefRebs) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"T/O"}
          homeValue={homeTurnovers}
          awayValue={awayTurnovers}
          homeGraphWidth={
            totalTurnovers ? (homeTurnovers / totalTurnovers) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalTurnovers ? (awayTurnovers / totalTurnovers) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Fouls"}
          homeValue={homeFouls}
          awayValue={awayFouls}
          homeGraphWidth={
            totalFouls ? (homeFouls / totalFouls) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalFouls ? (awayFouls / totalFouls) * 100 + "%" : "0%"
          }
        />
      </div>
    </div>
  );
}

export default function BasketballStats({ fixture }) {
  const { loading, error, data } = useQuery(BASKETBALL_STATS, {
    variables: { id: fixture.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture.BasketballStats",
        query: "BASKETBALL_STATS",
      },
    });
    console.log(error);

    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeTeam = participants[0];
  const awayTeam = participants[1];

  const homeLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[0]?.organization?.id
  );

  const awayLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[1]?.organization?.id
  );

  const homeStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === homeTeam.organization.id
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === awayTeam.organization.id
  );

  return (
    <div className="fixture-stats-mobile__football">
      <MatchStats
        fixture={fixture}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeStats={homeStats}
        awayStats={awayStats}
      />

      <PlayerStats
        fixture={data?.fixture}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeLineup={homeLineup}
        awayLineup={awayLineup}
      />
    </div>
  );
}
