// V1
import { useQuery } from "@apollo/client";
import { FIXTURE_STATS } from "../../api/fixture";
import Card from "../Card";
// import Spinner from "../Spinner";
import LineChart from "../LineChart";
import "./CricketBallByBallGraph.css";

export default function CricketBallByBall({
  periods,
  fixtureId,
  homeParticipant,
  awayParticipant,
}) {
  //   const [selectedPeriod, setSelectedPeriod] = useState(periods[0] || false);
  const { loading, error, data } = useQuery(FIXTURE_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const stats = data?.fixture?.stats;
  const labels = [0];
  const datasets = stats
    .filter((s) => s.statType.name === "Balls Bowled")
    .sort(
      (a, b) =>
        (Number(a.stringValue) || 0) - (Number(b.stringValue) || 0) ||
        (b.createdAt > a.createdAt ? 1 : -1)
    )
    .reduce((accum, curr, i) => {
      const accumIndex = accum.findIndex(
        (g) => g.ballNumber === curr.stringValue
      );

      if (accumIndex < 0) {
        const newBallItem = {
          ballNumber: curr.stringValue,
          stats: {
            [homeParticipant.organization.id]: [],
            [awayParticipant.organization.id]: [],
          },
        };

        newBallItem.stats[curr.organization.id].push(curr);
        labels.push(curr.stringValue);
        accum.push(newBallItem);
      } else {
        accum[accumIndex].stats[curr.organization.id].push(curr);
      }

      return accum;
    }, [])
    .reduce(
      (accum, curr, i) => {
        const homeRunsScored = curr.stats[
          awayParticipant.organization.id
        ].reduce((a, c) => {
          const rs = c.relationshipsTo?.find(
            (r) => r.from.statType.name === "Runs Scored"
          );
          a.push(rs.from);
          return a;
        }, []);

        const awayRunsScored = curr.stats[
          homeParticipant.organization.id
        ].reduce((a, c) => {
          const rs = c.relationshipsTo?.find(
            (r) => r.from.statType.name === "Runs Scored"
          );
          a.push(rs.from);
          return a;
        }, []);

        const homeExtras = curr.stats[awayParticipant.organization.id].reduce(
          (a, c) => {
            c.relationshipsFrom
              ?.filter((r) =>
                ["Wide", "No Ball", "Bye", "Leg Bye"].includes(
                  r.to?.statType?.name
                )
              )
              .forEach((r) => a.push(r.to));
            return a;
          },
          []
        );

        const awayExtras = curr.stats[homeParticipant.organization.id].reduce(
          (a, c) => {
            c.relationshipsFrom
              ?.filter((r) =>
                ["Wide", "No Ball", "Bye", "Leg Bye"].includes(
                  r.to?.statType?.name
                )
              )
              .forEach((r) => a.push(r.to));
            return a;
          },
          []
        );

        if (homeRunsScored?.length || homeExtras?.length) {
          // console.log("Index:", i);
          // console.log("Home runs scored:", homeRunsScored);
          // console.log("Home extras scored:", homeExtras);
          const homeRunsValue = homeRunsScored?.reduce((a, c) => {
            a += c.value;
            return a;
          }, 0);

          const homeExtrasValue =
            homeExtras?.reduce((a, c, i) => {
              a += c.value;
              return a;
            }, 0) || 0;

          accum[0].data.push(
            homeRunsValue + homeExtrasValue + accum[0].data[i]
          );
        }

        if (awayRunsScored?.length || awayExtras?.length) {
          const awayRunsValue = awayRunsScored?.reduce((a, c) => {
            a += c.value;
            return a;
          }, 0);

          const awayExtrasValue =
            awayExtras?.reduce((a, c, i) => {
              a += c.value;
              return a;
            }, 0) || 0;

          accum[1].data.push(
            awayRunsValue + awayExtrasValue + accum[1].data[i]
          );
        }

        return accum;
      },
      [
        {
          data: [0],
          fill: false,
          borderColor: "#13a0fa",
          backgroundColor: "#ffffff",
          tension: 0,
        },
        {
          data: [0],
          fill: false,
          borderColor: "#ffaa2b",
          backgroundColor: "#ffffff",
          tension: 0,
        },
      ]
    );

  // console.log("Datasets:", datasets);
  const filteredDatasets = datasets.filter((d) => d.data.length > 1);
  // console.log("Filtered datasets:", filteredDatasets);
  return (
    <Card className="fixture-points-timeline" style={{ marginBottom: "24px" }}>
      <div className="fixture-points-timeline__header">
        <div className="fixture-points-timeline__header__title">
          Ball-by-ball
        </div>

        <div className="fixture-points-timeline__header__legend">
          <div className="fixture-points-timeline__header__legend__item">
            <div
              className="fixture-points-timeline__header__legend__item__color"
              style={{ background: "#13a0fa" }}
            ></div>

            <div className="fixture-points-timeline__header__legend__item__label">
              {homeParticipant?.organization?.name}
            </div>
          </div>

          <div className="fixture-points-timeline__header__legend__item">
            <div
              className="fixture-points-timeline__header__legend__item__color"
              style={{ background: "#ffaa2b" }}
            ></div>

            <div className="fixture-points-timeline__header__legend__item__label">
              {awayParticipant?.organization?.name}
            </div>
          </div>
        </div>
      </div>
      <div className="fixture-points-timeline__body">
        <LineChart
          datasets={filteredDatasets.filter((d) => d.data.length > 1)}
          labels={labels}
          suggestedMin={0}
          suggestedMax={Math.max(
            datasets[0]?.data[datasets[0].data.length - 1] + 1,
            (datasets[1]?.data[datasets[1].data.length - 1] || 0) + 1
          )}
        />
      </div>
    </Card>
  );
}
