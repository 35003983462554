import { useRef, useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import QuillMention from "quill-mention";
import quillEmoji from "quill-emoji";
import { client } from "../../../../utils/client";
import { SEARCH } from "../../../../api/user";
import { UPDATE_MEDIA, MEDIA } from "../../../../api/media";
import makeFileUrl from "../../../../utils/makeFileUrl";
import "./EditPhotoBrowserDescription.css";
import { useMutation } from "@apollo/client";

Quill.register("modules/mentions", QuillMention);
Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true
);

const CustomToolbar = () => (
  <div id="custom-toolbar">
    <div className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-link"></button>
      <button className="ql-emoji"></button>
    </div>
  </div>
);

async function suggestPeople(searchTerm) {
  const result = await client.query({
    query: SEARCH,
    variables: {
      term: searchTerm.trim(),
    },
  });

  // console.log("Mention result:", result);
  return [
    ...result.data.search.profiles.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/profile/" + x.id,
      type: "profile",
    })),
    ...result.data.search.organizations.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/page/" + x.id,
      type: "organization",
    })),
    // ...result.data.search.events.map((x) => ({
    //   ...x,
    //   value: x.name,
    //   type: "event",
    // })),
  ];
  // const allPeople = await search({ variables: { term: searchTerm.trim() } });
  // return allPeople.map((person) => ({ ...person, value: person.name }));
}

const mentionModule = {
  allowedChars: /^[A-Za-z\s]*$/,
  mentionDenotationChars: ["@"],
  minChars: 2,
  dataAttributes: [
    "id",
    "value",
    "denotationChar",
    "link",
    "target",
    "disabled",
    "type",
  ],
  source: async function (searchTerm, renderList, mentionChar) {
    if (!searchTerm || searchTerm.length === 0) {
      // console.log("No term");
      renderList([], searchTerm);
    }
    // console.log("Searching");
    const matches = await suggestPeople(searchTerm);
    // console.log("Matches:", matches);
    renderList(matches);
  },
  renderItem: function (item, searchTerm) {
    // console.log(item);
    const profilePhoto = item.profilePhoto
      ? makeFileUrl(item.profilePhoto)
      : false;
    return `<div class="mention-item">
          <div class="mention-item__image">${
            profilePhoto ? `<img src=${profilePhoto} />` : ""
          }</div>
          <div class="mention-item__text">
            <div class="mention-item__text__primary">${item.name}</div>
            <div class="mention-item__text__secondary">@${item.handle}</div>
          </div>
        </div>`;
  },
};

export default function EditPhotoBrowserDescription({ media, dismiss }) {
  const editorRef = useRef(null);
  const [content, setContent] = useState(media.description);
  const [truncatedContent, setTruncatedContent] = useState(
    media.truncatedDescription
  );
  const [contentLength, setContentLength] = useState(0);

  useEffect(() => {
    if (editorRef?.current) {
      // console.log("Focusing editor");
      editorRef.current.focus();
      setContentLength(editorRef.current.getEditor().getLength());
    }
  }, []);

  const onContentChange = (newContent, delta, source, editor) => {
    // console.log(editor.getContents());

    if (editor.getLength() > 200) {
      setTruncatedContent(editor.getContents(0, 200).insert("..."));
    } else {
      setTruncatedContent(editor.getContents());
    }
    setContent(editor.getContents());
    // const text = editor.getText().trim();
    // if (!text) {
    //   setTruncatedContent(false);
    //   setContent(false);
    // } else {
    //   // console.log(editor.getLength());
    //   if (editor.getLength() > 200) {
    //     setTruncatedContent(editor.getContents(0, 200).insert("..."));
    //   } else {
    //     setTruncatedContent(editor.getContents());
    //   }
    //   setContent(editor.getContents());
    // }

    setContentLength(editor.getLength());
  };

  const [updateMedia] = useMutation(UPDATE_MEDIA, {
    update: (cache, { data: { updateMedia } }) => {
      cache.writeQuery({
        query: MEDIA,
        variables: { id: media.id },
        data: {
          media: {
            ...updateMedia,
          },
        },
      });
      dismiss();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="edit-media-description">
      <div className="input-wrapper">
        <ReactQuill
          id={"edit-media-description-editor"}
          ref={editorRef}
          defaultValue={media.description}
          onChange={onContentChange}
          placeholder={"Say something..."}
          modules={{
            toolbar: {
              container: "#custom-toolbar",
            },
            // toolbar: [["bold", "italic", "underline", "link", "emoji"]],
            mention: mentionModule,
            "emoji-toolbar": true,
            "emoji-textarea": false,
            "emoji-shortname": true,
          }}
        />

        <div
          className={
            "character-count " + (contentLength - 2 > 1500 ? "red" : "")
          }
        >
          {contentLength - 2 < 0 ? 0 : contentLength - 2}/1500
        </div>
      </div>

      <div className="custom-toolbar-wrapper">
        <CustomToolbar />
        <div className="custom-toolbar-wrapper__options-right">
          <div
            className="custom-toolbar-wrapper__options-right__option"
            onClick={dismiss}
          >
            Cancel
          </div>
          <div
            className="custom-toolbar-wrapper__options-right__option save"
            onClick={() => {
              if (contentLength - 2 > 1500) {
                return;
              }

              const vars = {
                description: content,
                truncatedDescription: truncatedContent,
                contentLength: contentLength - 2,
                tags: {
                  profiles: [],
                  organizations: [],
                  events: [],
                },
              };

              content?.ops?.forEach((op) => {
                if (op.insert?.mention) {
                  vars.tags[op.insert.mention.type + "s"].push(
                    op.insert.mention.id
                  );
                }
              });

              // console.log(vars);

              // console.log(editorRef.current.getText());

              // return;

              updateMedia({
                variables: {
                  where: { id: media.id },
                  data: vars,
                },
              });
            }}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
}
