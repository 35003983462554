export default function makeStat({
  eventId,
  fixtureId,
  fixturePeriodId,
  organization,
  profile,
  statType,
  value,
  stringValue,
}) {
  return {
    event: { connect: { id: eventId } },
    timeStamp: 1,
    organization: {
      connect: { id: organization.id },
    },
    profile: { connect: { id: profile.id } },
    statType: {
      connect: {
        id: statType.id,
      },
    },
    fixture: { connect: { id: fixtureId } },
    fixturePeriod: { connect: { id: fixturePeriodId } },
    sport: { connect: { name: "Cricket" } },
    value,
    ...(stringValue ? { stringValue } : {}),
  };
}
