import { gql } from "@apollo/client";

const UPDATE_PROFILE = (selectionSet) => {
  return gql`
    mutation updateProfileMutation($where:UniqueInput!, $data: UpdateProfileInput!) {
      updateProfile(where:$where, data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
};

export default UPDATE_PROFILE;
