import { gql } from "@apollo/client";

const USER_REQUESTS = gql`
  query userRequests {
    userRequests {
      requestType
      follow {
        id
        follower {
          id
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        following {
          id
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
      }
      membership {
        id
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        role
        isAdmin
      }
      eventRole {
        id
        description
        type {
          id
          name
        }
        event {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        relationshipsTo {
          id
          from {
            id
            organization {
              id
              name
            }
          }
        }
      }
      fixtureRole {
        id
        description
        fixture {
          id
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
      }
    }
  }
`;

export default USER_REQUESTS;
