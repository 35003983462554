import { gql } from "@apollo/client";

const CREATE_AWARD = gql`
  mutation createAward($data: CreateAwardInput!) {
    createAward(data: $data) {
      id
      profile {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
      type {
        id
        name
      }
    }
  }
`;

export default CREATE_AWARD;
