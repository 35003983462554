import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Placeholder from "../../../components/Placeholder";
import Input from "../../../components/Input";
import NewDropdown from "../../../components/NewDropdown";
import Spinner from "../../../components/Spinner";
import Loader from "../../../components/Loader";
import "./FixtureReferees.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import { FIXTURE_ROLES } from "../../../api/fixture";
import { UPDATE_FIXTURE } from "../../../api/functions";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Avatar from "../../../components/Avatar";
import { useAppState } from "../../../utils/appState";
import { useState, useCallback } from "react";
import { PROFILE_SEARCH } from "../../../api/user";
import debounce from "lodash.debounce";
import captureException from "../../../utils/captureException";

function SearchProfile({ setProfile }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(PROFILE_SEARCH);

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = data?.search?.profiles || [];

  let placeholderArray = ["profiles"];

  return (
    <NewDropdown
      wrapperClass="search"
      targetWrapperClass="search-wrapper"
      placement={"bottom-start"}
      menuStyle={{ width: "100%" }}
      target={
        <Input
          iconLeft={
            <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
          }
          placeholder={`Search for ${placeholderArray.join("/")}`}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e);
          }}
          useExternalValue={true}
          value={searchTerm}
        />
      }
      menu={({ setShowMenu }) => (
        <Card className="app-search-menu">
          {loading && <Spinner />}
          {!loading && !profiles?.length && (
            <div className="no-data">
              {!searchTerm ? (
                <div>
                  Search for {placeholderArray.join("/")} by name or handle
                </div>
              ) : (
                <div>No results found</div>
              )}
            </div>
          )}
          {!!profiles?.length && (
            <div className="search-results-section">
              <div className="search-results-section__title">PROFILES</div>
              <div className="search-results-section__items">
                {profiles?.map((o, i) => (
                  <div
                    key={"venue-search-item-" + o.id + i}
                    onClick={() => {
                      // if (submitting) return;
                      setShowMenu(false);
                      setSearchTerm("");
                      setProfile(o);
                      // updateEvent({
                      //   variables: {
                      //     where: {
                      //       id: ev.id,
                      //     },
                      //     data: {
                      //       roles: {
                      //         create: {
                      //           type: { connect: { name: roleType } },
                      //           profile: { connect: { id: o.id } },
                      //           relationshipsTo: {
                      //             create: {
                      //               from: {
                      //                 connect: { id: roleId },
                      //               },
                      //             },
                      //           },
                      //         },
                      //       },
                      //     },
                      //   },
                      // });
                      // setSelectedProfiles((prev) => [...prev, p]);
                    }}
                    className="search-results-section__items__item"
                  >
                    <div className="search-results-section__items__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>
                    <div className="search-results-section__items__item__desc">
                      <div className="search-results-section__items__item__desc__primary">
                        {o.name}
                      </div>
                      <div className="search-results-section__items__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Card>
      )}
    />
  );
}

function AddFixtureReferee({ fixture }) {
  const [, setAppState] = useAppState();
  const [profile, setProfile] = useState("");
  const [description, setDescription] = useState("");

  const [updateFixture, { loading: submitting }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
        roles {
          id
          description
          type {
            id
            name
          }        
          profile {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }          
        }`,
    }),
    {
      update: (cache, { data: { updateFixture } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            roles() {
              return updateFixture.roles;
            },
          },
        });

        setAppState({ modal: false });
        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureReferees.AddFixtureReferee",
            mutation: "UPDATE_FIXTURE",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="modal-form-mobile-wrapper">
      <Card className="add-fixture-referee">
        <div className="add-fixture-referee__title">
          <div className="add-fixture-referee__title__text">
            Add an Official
          </div>

          <div
            className="add-fixture-referee__title__dismiss-btn"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon className="add-fixture-referee__title__dismiss-btn__icon" />
          </div>
        </div>

        <div className="add-fixture-referee__form">
          <div className="add-fixture-referee__form__group">
            <div className="add-fixture-referee__form__group__label">
              Select official
            </div>

            <div className="add-fixture-referee__form__group__input">
              {profile ? (
                <div className="add-fixture-referee__form__group__input__selected-profile">
                  <div className="add-fixture-referee__form__group__input__selected-profile__desc">
                    {profile.name}
                  </div>

                  <div
                    className="add-fixture-referee__form__group__input__selected-profile__dismiss"
                    onClick={() => {
                      setProfile(false);
                    }}
                  >
                    <TimesIcon className="add-fixture-referee__form__group__input__selected-profile__dismiss__icon" />
                  </div>
                </div>
              ) : (
                <SearchProfile setProfile={setProfile} />
              )}
            </div>
          </div>

          <div className="add-fixture-referee__form__group">
            <div className="add-fixture-referee__form__group__label">
              Description
            </div>

            <div className="add-fixture-referee__form__group__input">
              <Input
                placeholder={`eg., Linesman, Referee, etc..`}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                useExternalValue={true}
                value={description}
              />
              {/* <input
              type="text"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            /> */}
            </div>
          </div>

          <Button
            className="blue primary large add-fixture-referee__form__submit"
            onClick={() => {
              if (submitting || !description || !profile?.id) return;

              updateFixture({
                variables: {
                  where: { id: fixture.id },
                  data: {
                    roles: {
                      create: [
                        {
                          type: { connect: { name: "REFEREE" } },
                          profile: { connect: { id: profile.id } },
                          description,
                        },
                      ],
                    },
                  },
                },
              });
            }}
          >
            {submitting ? <Loader theme={"small"} /> : "Submit"}
          </Button>
        </div>
      </Card>
    </div>
  );
}

function FixtureRefereeItem({ fixture, role, canManageFixture }) {
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
        roles {
          id
          description
          type {
            id
            name
          }        
          profile {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }          
        }`,
    }),
    {
      update: (cache, { data: { updateFixture } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            roles() {
              return updateFixture.roles;
            },
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureReferees.FixtureRefereeItem",
            mutation: "UPDATE_FIXTURE",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="fixture-referees__list__item">
      <div className="fixture-referees__list__item__image">
        {role.profile?.profilePhoto && (
          <Avatar media={role.profile.profilePhoto} />
        )}
      </div>

      <div className="fixture-referees__list__item__desc">
        <div className="fixture-referees__list__item__desc__primary">
          {role.profile?.name} {role.confirmed ? "" : "(Request Sent)"}
        </div>

        <div className="fixture-referees__list__item__desc__secondary">
          {role.description || "Match Official"}
        </div>
      </div>

      {canManageFixture && (
        <div className="fixture-referees__list__item__options">
          <div
            className="fixture-referees__list__item__options__option"
            onClick={() => {
              if (loading) return;
              updateFixture({
                variables: {
                  where: { id: fixture.id },
                  data: {
                    roles: {
                      deleteMany: [{ id: role.id }],
                    },
                  },
                },
              });
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <TrashIcon className="fixture-referees__list__item__options__option__icon" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default function FixtureReferees({ id, canManageFixture }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(FIXTURE_ROLES, {
    variables: {
      id,
      where: { type: { name: "REFEREE" } },
    },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="fixture-referees">
        <div className="fixture-referees__title">
          <div className="fixture-referees__title__text">Officials</div>
        </div>

        <div className="fixture-referees__list">
          <div className="fixture-referees__list__item">
            <div className="fixture-referees__list__item__image"></div>

            <div className="fixture-referees__list__item__desc">
              <div className="fixture-referees__list__item__desc__primary">
                <Placeholder
                  style={{ height: "10px", width: "88px" }}
                  pulse={true}
                />
              </div>

              <div className="fixture-referees__list__item__desc__secondary">
                <Placeholder
                  style={{ height: "10px", width: "40px" }}
                  pulse={true}
                />
              </div>
            </div>

            {/* <div className="fixture-referees__list__item__options">
              <div className="fixture-referees__list__item__options__option">
                <TrashIcon className="fixture-referees__list__item__options__option__icon" />
              </div>
            </div> */}
          </div>
        </div>
      </Card>
    );
  }

  const roles = data?.fixture?.roles || [];

  const list = canManageFixture ? roles : roles.filter((r) => r.confirmed);

  return (
    <Card className="fixture-referees">
      <div className="fixture-referees__title">
        <div className="fixture-referees__title__text">Officials</div>

        {canManageFixture && (
          <div
            className="fixture-referees__title__add"
            onClick={() => {
              setAppState({
                modal: <AddFixtureReferee fixture={data?.fixture} />,
              });
            }}
          >
            Add
          </div>
        )}
      </div>

      <div className="fixture-referees__list">
        {!list.length && <div className="no-data">No officials added</div>}

        {list.map((role) => (
          <FixtureRefereeItem
            fixture={data?.fixture}
            role={role}
            canManageFixture={canManageFixture}
          />
        ))}
      </div>
    </Card>
  );
}
