import { useEffect, useState } from "react";

export default function Fill({ number }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setWidth(number);
    }, 200);
  }, [number]);

  return (
    <div
      className="onboarding-prompt__cta__progress"
      style={{ width: width + "%" }}
    />
  );
}
