import { Fragment, useState, useEffect } from "react";
import "./ScoreTennis.css";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SwapIcon } from "../../../icons/swap.svg";
import { useAppState } from "../../../utils/appState";
import { useMutation, useQuery, gql } from "@apollo/client";
import Spinner from "../../Spinner";
import Loader from "../../Loader";
import Card from "../../Card";
import Button from "../../Button";
import Select from "../../Select";
import {
  BADMINTON_STATS,
  FIXTURE_SETTINGS,
  CREATE_STAT,
  UPDATE_FIXTURE,
  DELETE_FIXTURE_STAT,
  PUBLISH_INDIVIDUAL_FIXTURE,
  UPDATE_STAT,
} from "../../../api/fixture";
import { useHistory } from "react-router-dom";
import Settings from "./Settings";
import NewDropdown from "../../NewDropdown/NewDropdown";
import { usePageTracking } from "../../../utils/usePageTracking";
import captureException from "../../../utils/captureException";

function CreateTieBreak({
  ev,
  currentGame,
  statTypes,
  fixture,
  selectedPeriod,
}) {
  const [createNewStat] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.CreateTieBreak",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });
  return (
    <Button
      className="score-tennis__create-tie-break-btn secondary medium"
      onClick={() => {
        if (!statTypes.find((st) => st.name === "Tie Break")) return;

        createNewStat({
          variables: {
            data: {
              event: { connect: { id: ev.id } },
              value: (currentGame?.value || 0) + 1,
              statType: {
                connect: {
                  id: statTypes.find((st) => st.name === "Games Played").id,
                },
              },
              fixture: { connect: { id: fixture.id } },
              sport: { connect: { name: "Tennis" } },
              fixturePeriod: { connect: { id: selectedPeriod.id } },
              relationshipsFrom: {
                create: {
                  to: {
                    create: {
                      event: { connect: { id: ev.id } },
                      statType: {
                        connect: {
                          id: statTypes.find((st) => st.name === "Tie Break")
                            .id,
                        },
                      },
                      fixture: { connect: { id: fixture.id } },
                      sport: { connect: { name: "Tennis" } },
                      fixturePeriod: { connect: { id: selectedPeriod.id } },
                    },
                  },
                },
              },
            },
          },
          update: (cache, { data: { createStat: newGamesPlayedStat } }) => {
            cache.modify({
              id: cache.identify(fixture),
              fields: {
                stats(existingRefs, { readField }) {
                  const newGamesPlayedStatRef = cache.writeFragment({
                    data: newGamesPlayedStat,
                    fragment: gql`
                      fragment NewStat on Stat {
                        id
                        createdAt
                        timeStamp
                        value
                        stringValue
                        statType {
                          id
                          name
                        }
                        profile {
                          id
                          name
                        }
                        organization {
                          id
                          name
                        }
                        fixturePeriod {
                          id
                          order
                          label
                        }
                        relationshipsFrom {
                          id
                          to {
                            id
                            createdAt
                            stringValue
                            value
                            timeStamp
                            statType {
                              id
                              name
                            }
                            profile {
                              id
                              name
                            }
                            organization {
                              id
                              name
                            }
                            fixturePeriod {
                              id
                              label
                            }
                          }
                        }
                        relationshipsTo {
                          id
                          from {
                            id
                            createdAt
                            stringValue
                            value
                            timeStamp
                            statType {
                              id
                              name
                            }
                            profile {
                              id
                              name
                            }
                            organization {
                              id
                              name
                            }
                            fixturePeriod {
                              id
                              label
                            }
                          }
                        }
                      }
                    `,
                  });

                  return [...existingRefs, newGamesPlayedStatRef];
                },
              },
            });
          },
        });
      }}
    >
      Add tie break
    </Button>
  );
}

function PublishStatsConfirm({
  dismiss,
  fixtureId,
  completed,
  setPublish,
  homePlayer,
  awayPlayer,
  periods,
}) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  const [publishFixture, { loading: publishing }] = useMutation(
    PUBLISH_INDIVIDUAL_FIXTURE,
    {
      update: (cache, { data: { publishIndividualFixture } }) => {
        const q = cache.readQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
        });

        cache.writeQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
          data: {
            fixture: {
              ...q.fixture,
              completed: true,
            },
          },
        });

        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreTennis.PublishStatsConfirm",
            mutation: "PUBLISH_INDIVIDUAL_FIXTURE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreTennis.PublishStatsConfirm",
        query: "BADMINTONS_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const fixture = data?.fixture;
  const event = fixture?.event;
  const sport = event?.sport;
  const statTypes = sport?.statTypes;
  const stats = data?.fixture?.stats;
  const placementValues = periods?.reduce(
    (accum, curr) => {
      const homePoints =
        stats.filter(
          (s) =>
            s.statType.name === "Games Won" &&
            s.fixturePeriod.id === curr.id &&
            s.profile.id === homePlayer.profile.id
        ).length || 0;
      const awayPoints =
        stats.filter(
          (s) =>
            s.statType.name === "Games Won" &&
            s.fixturePeriod.id === curr.id &&
            s.profile.id === awayPlayer.profile.id
        ).length || 0;

      if (homePoints > awayPoints) {
        accum[homePlayer.profile.id]++;
      } else if (homePoints < awayPoints) {
        accum[awayPlayer.profile.id]++;
      }

      return accum;
    },
    { [homePlayer.profile.id]: 0, [awayPlayer.profile.id]: 0 }
  );
  // const periods = data?.fixture?.periods
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // Sets Played
  const setsPlayedStatType = statTypes?.find((s) => s.name === "Sets Played");
  const totalSetsPlayed =
    stats?.map((s) => s.fixturePeriod?.id).filter(onlyUnique).length || 0;

  // Sets Won
  const setsWonStatType = statTypes?.find((s) => s.name === "Sets Won");
  const homePlayerSetsWon = placementValues[homePlayer.profile.id];
  const awayPlayerSetsWon = placementValues[awayPlayer.profile.id];

  // Games Played
  const gamesPlayedStatType = statTypes?.find((s) => s.name === "Games Played");
  const totalGamesPlayed =
    stats?.filter((s) => s.statType.name === "Games Won").length || 0;

  // Games Won
  const gamesWonStatType = statTypes?.find((s) => s.name === "Games Won");
  const homePlayerGamesWon =
    stats.filter(
      (s) =>
        s.statType.name === "Games Won" &&
        s.profile.id === homePlayer.profile.id
    ).length || 0;
  const awayPlayerGamesWon =
    stats.filter(
      (s) =>
        s.statType.name === "Games Won" &&
        s.profile.id === awayPlayer.profile.id
    ).length || 0;

  // Points Played
  const pointsPlayedStatType = statTypes?.find(
    (s) => s.name === "Points Played"
  );
  const totalPointsPlayed =
    stats?.filter((s) => s.statType.name === "Points Won").length || 0;

  // Points Won
  const pointsWonStatType = statTypes?.find((s) => s.name === "Points Won");
  const homePlayerPointsWon =
    stats.filter(
      (s) =>
        s.statType.name === "Points Won" &&
        s.profile.id === homePlayer.profile.id
    ).length || 0;
  const awayPlayerPointsWon =
    stats.filter(
      (s) =>
        s.statType.name === "Points Won" &&
        s.profile.id === awayPlayer.profile.id
    ).length || 0;

  // Serve
  const serveStatType = statTypes?.find((s) => s.name === "Serve");
  const serveWonStatType = statTypes?.find((s) => s.name === "Serve Won");

  const serveSummary = stats
    .filter((s) => s.statType.name === "Games Played")
    .reduce(
      (accum, curr) => {
        const isTieBreak = curr.relationshipsFrom?.find(
          (r) => r.to?.statType?.name === "Tie Break"
        );

        if (isTieBreak) {
          curr.relationshipsFrom
            .filter((r) => r.to.statType.name === "Points Won")
            .forEach((r) => {
              const serve = stats.find(
                (s) =>
                  s.statType.name === "Serve" &&
                  s.relationshipsTo?.find((z) => z.from?.id === r.to.id)
              );

              accum[serve.profile.id].total = accum[serve.profile.id].total + 1;

              if (r.to.profile.id === serve.profile.id) {
                accum[serve.profile.id].won = accum[serve.profile.id].won + 1;
              }
            });
        } else {
          const serve = curr.relationshipsFrom.find(
            (r) => r.to?.statType?.name === "Serve"
          )?.to;

          curr.relationshipsFrom
            .filter((r) => r.to.statType.name === "Points Won")
            .map((r) => r.to)
            .forEach((s) => {
              accum[serve.profile.id].total = accum[serve.profile.id].total + 1;
              if (s.profile.id === serve.profile.id) {
                accum[serve.profile.id].won = accum[serve.profile.id].won + 1;
              }
            });
        }

        return accum;
      },
      {
        [homePlayer.profile.id]: { total: 0, won: 0 },
        [awayPlayer.profile.id]: { total: 0, won: 0 },
      }
    );

  // Double Fault
  const doubleFaultStatType = statTypes?.find((s) => s.name === "Double Fault");
  const homeDoubleFaults =
    stats.filter(
      (s) =>
        s.statType.name === "Double Fault" &&
        s.profile.id === homePlayer.profile.id
    ).length || 0;
  const awayDoubleFaults =
    stats.filter(
      (s) =>
        s.statType.name === "Double Fault" &&
        s.profile.id === awayPlayer.profile.id
    ).length || 0;

  // Unforced Error
  const unforcedErrorStatType = statTypes?.find(
    (s) => s.name === "Unforced Error"
  );
  const homeUnforcedErrors =
    stats.filter(
      (s) =>
        s.statType.name === "Unforced Error" &&
        s.profile.id === homePlayer.profile.id
    ).length || 0;
  const awayUnforcedErrors =
    stats.filter(
      (s) =>
        s.statType.name === "Unforced Error" &&
        s.profile.id === awayPlayer.profile.id
    ).length || 0;

  // Ace
  const aceStatType = statTypes?.find((s) => s.name === "Ace");
  const homeAces =
    stats.filter(
      (s) => s.statType.name === "Ace" && s.profile.id === homePlayer.profile.id
    ).length || 0;
  const awayAces =
    stats.filter(
      (s) => s.statType.name === "Ace" && s.profile.id === awayPlayer.profile.id
    ).length || 0;

  const tables = [
    // Sets Played
    {
      profileId: homePlayer.profile.id,
      statTypeId: setsPlayedStatType.id,
      value: totalSetsPlayed,
      // label: "Sets Played",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: setsPlayedStatType.id,
      value: totalSetsPlayed,
      // label: "Sets Played",
    },
    // Sets Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: setsWonStatType.id,
      value: homePlayerSetsWon,
      // label: "Sets Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: setsWonStatType.id,
      value: awayPlayerSetsWon,
      // label: "Sets Won",
    },
    // Games Played
    {
      profileId: homePlayer.profile.id,
      statTypeId: gamesPlayedStatType.id,
      value: totalGamesPlayed,
      // label: "Games Played",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: gamesPlayedStatType.id,
      value: totalGamesPlayed,
      // label: "Games Played",
    },
    // Games Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: gamesWonStatType.id,
      value: homePlayerGamesWon,
      // label: "Games Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: gamesWonStatType.id,
      value: awayPlayerGamesWon,
      // label: "Games Won",
    },
    // Points Played
    {
      profileId: homePlayer.profile.id,
      statTypeId: pointsPlayedStatType.id,
      value: totalPointsPlayed,
      // label: "Points Played",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: pointsPlayedStatType.id,
      value: totalPointsPlayed,
      // label: "Points Played",
    },
    // Points Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: pointsWonStatType.id,
      value: homePlayerPointsWon,
      // label: "Points Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: pointsWonStatType.id,
      value: awayPlayerPointsWon,
      // label: "Points Won",
    },
    // Serve
    {
      profileId: homePlayer.profile.id,
      statTypeId: serveStatType.id,
      value: serveSummary[homePlayer.profile.id].total,
      // label: "Serve",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: serveStatType.id,
      value: serveSummary[awayPlayer.profile.id].total,
      // label: "Serve",
    },
    // Serve Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: serveWonStatType.id,
      value: serveSummary[homePlayer.profile.id].won,
      // label: "Serve Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: serveWonStatType.id,
      value: serveSummary[awayPlayer.profile.id].won,
      // label: "Serve Won",
    },
    // Double Fault
    {
      profileId: homePlayer.profile.id,
      statTypeId: doubleFaultStatType.id,
      value: homeDoubleFaults,
      // label: "Double Fault",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: doubleFaultStatType.id,
      value: awayDoubleFaults,
      // label: "Double Fault",
    },
    // Unforced Error
    {
      profileId: homePlayer.profile.id,
      statTypeId: unforcedErrorStatType.id,
      value: homeUnforcedErrors,
      // label: "Unforced Error",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: unforcedErrorStatType.id,
      value: awayUnforcedErrors,
      // label: "Unforced Error",
    },
    // Ace
    {
      profileId: homePlayer.profile.id,
      statTypeId: aceStatType.id,
      value: homeAces,
      // label: "Ace",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: aceStatType.id,
      value: awayAces,
      // label: "Ace",
    },
  ];

  // console.log("Stat Types;", statTypes);

  return (
    <div className="publish-stats">
      <div className="publish-stats__header">
        <div
          className="close-scoring-btn"
          onClick={() => {
            if (!completed) {
              setPublish(false);
            } else {
              // history.push("/app/fixture/" + fixtureId);
              // setAppState({ modal: false });
            }
          }}
        >
          <div className="icon">
            <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
          </div>
          <div className="text">{completed ? "Back to fixture" : "Back"}</div>
        </div>
        <div className="publish-stats-title">
          {"Verify Fixture Stats & Result"}
        </div>

        {!loading && (
          <div className="publish-stats-btn-wrapper">
            <div
              className="publish-stats-btn"
              onClick={() => {
                // setConfirm(true);
              }}
            >
              {completed ? "Update Final Score" : "Publish this fixture"}
            </div>
          </div>
        )}
      </div>
      <div className="confirm-publish-stats">
        <div className="confirm-publish-stats__form">
          <div className="confirm-publish-stats__form__header">
            {completed
              ? "This fixture has already been published"
              : "Are you sure you want to publish this fixture?"}
          </div>
          <div className="confirm-publish-stats__form__text">
            {completed ? (
              <p>
                Please contact us if you need to change the scores for this
                fixture.
              </p>
            ) : (
              <Fragment>
                <p>
                  This cannot be undone. Please note, users will still be able
                  to see the score for the fixture from the fixtures page
                  without publishing.
                </p>
                <p>
                  Once you confirm we will update individual player and event
                  stats, and the fixture will be available for use in standings
                  calculations, so do make sure all stats are accurate before
                  you publish the fixture.
                </p>
              </Fragment>
            )}
          </div>

          <div className="confirm-publish-stats__form__options">
            <Button
              className="secondary medium"
              onClick={() => {
                if (!completed) {
                  dismiss();
                } else {
                  setAppState({ modal: false });
                }
              }}
              disabled={loading}
            >
              {completed ? "Go back to fixture" : "No, go back"}
            </Button>
            {!completed && (
              <Button
                className="primary medium green"
                onClick={() => {
                  if (publishing) return;
                  // console.log("Tyoes:", statTypes);
                  // console.log("Tables:", tables);
                  // console.log("Placement Values:", placementValues);

                  // return;
                  publishFixture({
                    variables: {
                      fixtureId,
                      placements: [
                        {
                          profileId: homePlayer.profile.id,
                          value: placementValues[homePlayer.profile.id],
                        },
                        {
                          profileId: awayPlayer.profile.id,
                          value: placementValues[awayPlayer.profile.id],
                        },
                      ],
                      tables,
                    },
                  });
                }}
                disabled={publishing}
              >
                {publishing ? <Loader /> : "Yes, publish this fixture"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TimerNew({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  timestamp,
  setTimestamp,
  cache,
  updateCache,
  currentGame,
}) {
  const [, setSecondsElapsed] = useState(timestamp || 0);
  const [running, setRunning] = useState(false);
  const [decrementPressed] = useState(false);
  const [incrementPressed] = useState(false);

  useEffect(() => {
    setSecondsElapsed(timestamp);
  }, [timestamp]);

  useEffect(() => {
    // console.log("Running interval effect");
    let timer;

    if (running) {
      // console.log("Setting interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev + 1;
            if (!(newTimestamp % 60)) {
              updateCache();
            }
            return newTimestamp;
          });
        }, 1000);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [running]);

  useEffect(() => {
    let timer;

    if (decrementPressed) {
      // console.log("Setting decrement interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev - 1;
            return newTimestamp;
          });
        }, 100);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [decrementPressed]);

  useEffect(() => {
    let timer;

    if (incrementPressed) {
      // console.log("Setting decrement interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev + 1;
            return newTimestamp;
          });
        }, 100);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [incrementPressed]);

  return (
    <div className="time">
      <NewDropdown
        target={
          <div className="selected-period-selector">
            {selectedPeriod?.label
              ? `${selectedPeriod.label} ${
                  currentGame
                    ? `(${
                        currentGame?.relationshipsFrom?.find(
                          (x) => x.to?.statType?.name === "Tie Break"
                        )
                          ? "Tie Break"
                          : "Game " + currentGame.value
                      })`
                    : ""
                }
                `
              : "Select set to get started"}
            <ChevronDown className="selected-period-selector__icon" />
          </div>
        }
        menu={({ setShowMenu }) => (
          <div className="selected-period-selector-menu">
            {periods.map((p) => (
              <div
                className="selected-period-selector-menu__item"
                onClick={() => {
                  setRunning(false);
                  changePeriod(p.id);
                  setShowMenu(false);
                }}
              >
                {p.label}
              </div>
            ))}
          </div>
        )}
      />

      {/* <NewDropdown
        target={
          <div
            className="selected-period-selector"
            style={{ marginTop: "8px" }}
          >
            Game 1
            <ChevronDown className="selected-period-selector__icon" />
          </div>
        }
        menu={({ setShowMenu }) => (
          <div className="selected-period-selector-menu">
            {periods.map((p) => (
              <div
                className="selected-period-selector-menu__item"
                onClick={() => {
                  setRunning(false);
                  changePeriod(p.id);
                  setShowMenu(false);
                }}
              >
                {p.label}
              </div>
            ))}
          </div>
        )}
      /> */}

      {/* {selectedPeriod && (
        <Fragment>
          <div className="current-time">
            {!running && (
              <div
                className="option"
                onMouseDown={() => {
                  setDecrementPressed(true);
                }}
                onMouseUp={() => {
                  setDecrementPressed(false);
                  updateCache();
                }}
                onClick={() => {
                  setTimestamp((prev) => {
                    const newTimestamp = prev - 1;
                    if (!(newTimestamp % 60)) {
                      updateCache();
                    }
                    return newTimestamp;
                  });
                }}
              >
                <MinusIcon style={{ height: "14px" }} />
              </div>
            )}

            <div className="time">
              {minutes < 10 ? "0" + minutes : minutes} :{" "}
              {seconds < 10 ? "0" + seconds : seconds}
            </div>

            {!running && (
              <div
                className="option"
                onMouseDown={() => {
                  setIncrementPressed(true);
                }}
                onMouseUp={() => {
                  setIncrementPressed(false);
                  updateCache();
                }}
                onClick={() => {
                  setTimestamp((prev) => {
                    const newTimestamp = prev + 1;
                    if (!(newTimestamp % 60)) {
                      updateCache();
                    }
                    return newTimestamp;
                  });
                }}
              >
                <PlusIcon style={{ height: "14px" }} />
              </div>
            )}
          </div>

          {running ? (
            <div
              className="pause"
              onClick={() => {
                setRunning(false);
                updateCache();
              }}
            >
              <div className="icon">
                <PauseIcon style={{ height: "14px", fill: "white" }} />
              </div>
              
            </div>
          ) : (
            <div
              className="start"
              onClick={() => {
                setRunning(true);
              }}
            >
              <div className="icon">
                <PlayIcon style={{ height: "14px", fill: "white" }} />
              </div>
              
            </div>
          )}

          <div
            className="end-of-period"
            onClick={() => {
              setRunning(false);
              changePeriod();
              updateCache();
            }}
          >
            <div className="icon">
              <WhistleIcon style={{ height: "14px", fill: "white" }} />
            </div>
            
          </div>
        </Fragment>
      )} */}
    </div>
  );
}

function ScoringHeader({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  timestamp,
  setTimestamp,
  setEditSettings,
  setEditLineups,
  cache,
  updateCache,
  setPublish,
}) {
  const [, setAppState] = useAppState();
  const history = useHistory();
  return (
    <div className="header">
      <div
        className="close-scoring-btn"
        onClick={() => {
          history.push("/app/fixture/" + fixtureId);
          setAppState({ modal: false });
        }}
      >
        <div className="icon">
          <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
        </div>
        <div className="text">Back to fixture</div>
      </div>

      {/* <TimerNew
        fixtureId={fixtureId}
        periods={periods}
        selectedPeriod={selectedPeriod}
        changePeriod={changePeriod}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        cache={cache}
        updateCache={updateCache}
      /> */}
      <div className="time"></div>

      <div className="settings">
        {/* <div
          className="edit-lineups-btn"
          onClick={() => {
            setEditLineups(true);
          }}
        >
          <ListIcon style={{ fill: "white", height: "14px" }} />
          Lineups
        </div> */}
        <div
          className="publish-stats-btn"
          onClick={() => {
            setPublish(true);
          }}
        >
          {"Verify & Publish"}
        </div>
        <div
          className="settings-btn"
          onClick={() => {
            setEditSettings(true);
          }}
        >
          <SettingsIcon style={{ stroke: "var(--light-1" }} />
        </div>
      </div>
    </div>
  );
}

const determineLatestGame = (stats, period) => {
  if (!period) return;
  const gamesPlayedStats = stats
    .filter(
      (s) =>
        s.statType.name === "Games Played" && s.fixturePeriod.id === period.id
    )
    .sort((a, b) => b.value - a.value);

  if (!gamesPlayedStats?.length) {
    return { value: 1 };
  } else {
    const gamesWithoutWinner = gamesPlayedStats
      .filter(
        (s) =>
          !s.relationshipsFrom?.find(
            (r) => r.to?.statType?.name === "Games Won"
          )
      )
      .reverse();
    if (!gamesWithoutWinner?.length) {
      return { value: gamesPlayedStats[0].value + 1 };
    } else {
      return gamesWithoutWinner[0];
    }
    // if (
    //   gamesPlayedStats[0].relationshipsFrom?.find(
    //     (r) => r.to.statType.name === "Games Won"
    //   )
    // ) {
    //   return { value: gamesPlayedStats[0].value + 1 };
    // } else {
    //   return gamesPlayedStats[0];
    // }
  }
};

const isGameWinningPoint = (
  homePointsScored,
  awayPointsScored,
  homePlayer,
  awayPlayer,
  profileId
) => {
  const isHomePoint = homePlayer.profile.id === profileId;
  if (homePointsScored?.length >= 3 && awayPointsScored?.length >= 3) {
    if (isHomePoint) {
      if (homePointsScored.length > awayPointsScored.length) {
        return true;
      } else {
        return false;
      }
    } else {
      if (awayPointsScored.length > homePointsScored.length) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    if (isHomePoint) {
      if (homePointsScored.length === 3) {
        return true;
      } else {
        return false;
      }
    } else {
      if (awayPointsScored.length === 3) {
        return true;
      } else {
        return false;
      }
    }
  }
};

const determineCurrentScore = (
  homePointsScored,
  awayPointsScored,
  isTieBreaker
) => {
  const output = { home: 0, away: 0 };
  const scoreMap = {
    1: 15,
    2: 30,
    3: 40,
  };

  if (isTieBreaker) {
    output.home = homePointsScored?.length || 0;
    output.away = awayPointsScored?.length || 0;
    return output;
  }

  if (homePointsScored?.length >= 3 && awayPointsScored?.length >= 3) {
    if (homePointsScored.length === awayPointsScored.length) {
      output.home = "Deuce";
      output.away = "Deuce";
    } else if (homePointsScored.length > awayPointsScored.length) {
      if (homePointsScored.length - awayPointsScored.length > 1) {
        output.home = "Game";
        output.away = "";
      } else {
        output.home = "Adv";
        output.away = "";
      }
    } else {
      if (awayPointsScored.length - homePointsScored.length > 1) {
        output.home = "";
        output.away = "Game";
      } else {
        output.home = "";
        output.away = "Adv";
      }
    }
  } else {
    if (homePointsScored?.length) {
      if (homePointsScored?.length > 3) {
        output.home = "Game";
      } else {
        output.home = scoreMap[homePointsScored.length];
      }
    } else {
      output.home = 0;
    }

    if (awayPointsScored?.length) {
      if (awayPointsScored?.length > 3) {
        output.away = "Game";
      } else {
        output.away = scoreMap[awayPointsScored.length];
      }
    } else {
      output.away = 0;
    }
  }
  return output;
};

function CurrentSet({
  fixtureId,
  periods,
  selectedPeriod,
  changePeriod,
  timestamp,
  setTimestamp,
  cache,
  updateCache,
  homePlayer,
  awayPlayer,
  currentServer,
  setCurrentServer,
}) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  //   const [createStat, { loading: submitting }] = useMutation(CREATE_STAT, {
  //     update: (cache, { data: { createStat } }) => {
  //       const q = cache.readQuery({
  //         query: BADMINTON_STATS,
  //         variables: { id: fixtureId },
  //       });

  //       console.log("Update:", createStat);
  //       const newStats = [
  //         createStat,
  //         ...createStat.relationshipsTo.map((r) => ({
  //           ...r.from,
  //           relationshipsFrom: [],
  //           relationshipsTo: [],
  //         })),
  //         ...createStat.relationshipsFrom.map((r) => ({
  //           ...r.to,
  //           relationshipsFrom: [],
  //           relationshipsTo: [],
  //         })),
  //       ];

  //       cache.writeQuery({
  //         query: BADMINTON_STATS,
  //         variables: { id: fixtureId },
  //         data: {
  //           fixture: {
  //             ...q.fixture,
  //             stats: [...newStats, ...q.fixture.stats],
  //           },
  //         },
  //       });

  //       if (createStat.profile.id !== currentServer.profile.id) {
  //         if (createStat.profile.id === homePlayer.profile.id) {
  //           setCurrentServer(homePlayer);
  //           updateCache(homePlayer);
  //         } else {
  //           setCurrentServer(awayPlayer);
  //           updateCache(awayPlayer);
  //         }
  //       }
  //       // dismiss();
  //     },
  //   });
  const [createNewStat, { loading: submitting }] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.CurrentSet",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      if (deleteFixtureStat?.length) {
        const q = cache.readQuery({
          query: BADMINTON_STATS,
          variables: {
            id: fixtureId,
          },
        });

        const statIds = deleteFixtureStat.map((x) => x.id);

        cache.writeQuery({
          query: BADMINTON_STATS,
          variables: {
            id: fixtureId,
          },
          data: {
            fixture: {
              ...q.fixture,
              stats: [
                ...q.fixture.stats.filter((x) => !statIds.includes(x.id)),
              ],
            },
          },
        });

        // dismiss();
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.CurrentSet",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreTennis.CurrentSet",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const fixture = data?.fixture;
  const ev = fixture?.event;
  const sport = ev?.sport;
  const statTypes = sport?.statTypes || [];

  const stats =
    data?.fixture?.stats?.filter(
      (s) => s.fixturePeriod?.id === selectedPeriod?.id
    ) || [];
  // console.log("STATS:", stats);

  const latestGame = stats
    ?.filter((s) => s.statType.name === "Games Played")
    .sort((a, b) => b.value - a.value)?.[0];

  const isTieBreaker = latestGame?.relationshipsFrom?.find(
    (r) => r.to?.statType?.name === "Tie Break"
  )?.to;

  const gamesWithoutWinner = stats
    ?.filter(
      (s) =>
        s.statType.name === "Games Played" &&
        !s.relationshipsFrom?.find((r) => r.to.statType.name === "Games Won")
    )
    .sort((a, b) => a.value - b.value);

  const latestOngoingGame = gamesWithoutWinner?.length
    ? gamesWithoutWinner[0]
    : false;

  // const latestOngoingGame =  latestGame?.relationshipsFrom?.find(
  //   (r) => r.to.statType.name === "Games Won"
  // )
  //   ? false
  //   : latestGame;

  const homePointsWon = latestOngoingGame?.relationshipsFrom
    ?.filter(
      (r) =>
        r.to.statType.name === "Points Won" &&
        r.to.profile.id === homePlayer.profile.id
    )
    .map((x) => x.to);
  const awayPointsWon = latestOngoingGame?.relationshipsFrom
    ?.filter(
      (r) =>
        r.to.statType.name === "Points Won" &&
        r.to.profile.id === awayPlayer.profile.id
    )
    .map((x) => x.to);

  const scores = determineCurrentScore(
    homePointsWon,
    awayPointsWon,
    isTieBreaker
  );

  //   const homeScore =
  //     stats.filter(
  //       (s) =>
  //         s.statType.name === "Point Won" &&
  //         s.profile?.id === homePlayer.profile?.id
  //     )?.length || 0;

  //   const awayScore =
  //     stats.filter(
  //       (s) =>
  //         s.statType.name === "Point Won" &&
  //         s.profile?.id === awayPlayer.profile?.id
  //     )?.length || 0;

  const incrementScore = ({ profileId }) => {
    if (!currentServer || submitting) return;
    // STEP 1: Determine which game it is - Find the highest games played stat for the selected time period. If the game does not have a games won associated with it,
    //         move on to step 2. Otherwise, create a new games played stat and move on to step 2.

    // STEP 2: Add a point won stat associated to the games played stat from step 1 - if the point won wins the game, create a new game won stat as well.
    let currentGame = determineLatestGame(stats, selectedPeriod);

    const isTieBreaker = currentGame?.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Tie Break"
    )?.to;

    if (!currentGame.id) {
      createNewStat({
        variables: {
          data: {
            event: { connect: { id: ev.id } },
            value: currentGame.value,
            statType: {
              connect: {
                id: statTypes.find((st) => st.name === "Games Played").id,
              },
            },
            fixture: { connect: { id: fixture.id } },
            sport: { connect: { name: "Tennis" } },
            fixturePeriod: { connect: { id: selectedPeriod.id } },
            relationshipsFrom: {
              create: {
                to: {
                  create: {
                    event: { connect: { id: ev.id } },
                    profile: {
                      connect: {
                        id: currentServer.profile.id,
                      },
                    },
                    statType: {
                      connect: {
                        id: statTypes.find((st) => st.name === "Serve").id,
                      },
                    },
                    fixture: { connect: { id: fixture.id } },
                    sport: { connect: { name: "Tennis" } },
                    fixturePeriod: { connect: { id: selectedPeriod.id } },
                  },
                },
              },
            },
          },
        },
        update: (cache, { data: { createStat: newGamesPlayedStat } }) => {
          // Do step 2 here
          createNewStat({
            variables: {
              data: {
                event: { connect: { id: ev.id } },
                // value: currentGame.value,
                statType: {
                  connect: {
                    id: statTypes.find((st) => st.name === "Points Won").id,
                  },
                },
                fixture: { connect: { id: fixture.id } },
                sport: { connect: { name: "Tennis" } },
                fixturePeriod: { connect: { id: selectedPeriod.id } },
                profile: { connect: { id: profileId } },
                relationshipsTo: {
                  create: {
                    from: {
                      connect: { id: newGamesPlayedStat.id },
                    },
                  },
                },
                ...(isTieBreaker
                  ? {
                      relationshipsFrom: {
                        create: {
                          to: {
                            create: {
                              event: { connect: { id: ev.id } },
                              profile: {
                                connect: {
                                  id: currentServer.profile.id,
                                },
                              },
                              statType: {
                                connect: {
                                  id: statTypes.find(
                                    (st) => st.name === "Serve"
                                  ).id,
                                },
                              },
                              fixture: { connect: { id: fixture.id } },
                              sport: { connect: { name: "Tennis" } },
                              fixturePeriod: {
                                connect: { id: selectedPeriod.id },
                              },
                            },
                          },
                        },
                      },
                    }
                  : {}),
              },
            },
            update: (cache, { data: { createStat } }) => {
              cache.modify({
                id: cache.identify(fixture),
                fields: {
                  stats(existingRefs, { readField }) {
                    const newStatRef = cache.writeFragment({
                      data: createStat,
                      fragment: gql`
                        fragment NewStat on Stat {
                          id
                          createdAt
                          timeStamp
                          value
                          stringValue
                          statType {
                            id
                            name
                          }
                          profile {
                            id
                            name
                          }
                          organization {
                            id
                            name
                          }
                          fixturePeriod {
                            id
                            order
                            label
                          }
                          relationshipsFrom {
                            id
                            to {
                              id
                              createdAt
                              stringValue
                              value
                              timeStamp
                              statType {
                                id
                                name
                              }
                              profile {
                                id
                                name
                              }
                              organization {
                                id
                                name
                              }
                              fixturePeriod {
                                id
                                label
                              }
                            }
                          }
                          relationshipsTo {
                            id
                            from {
                              id
                              createdAt
                              stringValue
                              value
                              timeStamp
                              statType {
                                id
                                name
                              }
                              profile {
                                id
                                name
                              }
                              organization {
                                id
                                name
                              }
                              fixturePeriod {
                                id
                                label
                              }
                            }
                          }
                        }
                      `,
                    });

                    const newGamesPlayedStatRef = cache.writeFragment({
                      data: newGamesPlayedStat,
                      fragment: gql`
                        fragment NewStat on Stat {
                          id
                          createdAt
                          timeStamp
                          value
                          stringValue
                          statType {
                            id
                            name
                          }
                          profile {
                            id
                            name
                          }
                          organization {
                            id
                            name
                          }
                          fixturePeriod {
                            id
                            order
                            label
                          }
                          relationshipsFrom {
                            id
                            to {
                              id
                              createdAt
                              stringValue
                              value
                              timeStamp
                              statType {
                                id
                                name
                              }
                              profile {
                                id
                                name
                              }
                              organization {
                                id
                                name
                              }
                              fixturePeriod {
                                id
                                label
                              }
                            }
                          }
                          relationshipsTo {
                            id
                            from {
                              id
                              createdAt
                              stringValue
                              value
                              timeStamp
                              statType {
                                id
                                name
                              }
                              profile {
                                id
                                name
                              }
                              organization {
                                id
                                name
                              }
                              fixturePeriod {
                                id
                                label
                              }
                            }
                          }
                        }
                      `,
                    });

                    return [...existingRefs, newGamesPlayedStatRef, newStatRef];
                  },
                  // relationshipsFrom(existingRefs, { readField }) {
                  //   const rel = stat.relationshipsFrom.find(
                  //     (x) => x.to.id === deleteFixtureStat[0].id
                  //   );
                  //   return existingRefs.filter(
                  //     (relRef) => rel.id !== readField("id", relRef)
                  //   );
                  // },
                },
              });
            },
          });
        },
      });
    } else {
      // Do step 2 here
      createNewStat({
        variables: {
          data: {
            event: { connect: { id: ev.id } },
            statType: {
              connect: {
                id: statTypes.find((st) => st.name === "Points Won").id,
              },
            },
            fixture: { connect: { id: fixture.id } },
            sport: { connect: { name: "Tennis" } },
            fixturePeriod: { connect: { id: selectedPeriod.id } },
            profile: { connect: { id: profileId } },
            relationshipsTo: {
              create: {
                from: {
                  connect: { id: currentGame.id },
                },
              },
            },
            ...(isTieBreaker
              ? {
                  relationshipsFrom: {
                    create: {
                      to: {
                        create: {
                          event: { connect: { id: ev.id } },
                          profile: {
                            connect: {
                              id: currentServer.profile.id,
                            },
                          },
                          statType: {
                            connect: {
                              id: statTypes.find((st) => st.name === "Serve")
                                .id,
                            },
                          },
                          fixture: { connect: { id: fixture.id } },
                          sport: { connect: { name: "Tennis" } },
                          fixturePeriod: {
                            connect: { id: selectedPeriod.id },
                          },
                        },
                      },
                    },
                  },
                }
              : {}),
          },
        },
        update: (cache, { data: { createStat } }) => {
          cache.modify({
            id: cache.identify(fixture),
            fields: {
              stats(existingRefs, { readField }) {
                const newStatRef = cache.writeFragment({
                  data: createStat,
                  fragment: gql`
                    fragment NewStat on Stat {
                      id
                      createdAt
                      timeStamp
                      value
                      stringValue
                      statType {
                        id
                        name
                      }
                      profile {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                      fixturePeriod {
                        id
                        order
                        label
                      }
                      relationshipsFrom {
                        id
                        to {
                          id
                          createdAt
                          stringValue
                          value
                          timeStamp
                          statType {
                            id
                            name
                          }
                          profile {
                            id
                            name
                          }
                          organization {
                            id
                            name
                          }
                          fixturePeriod {
                            id
                            label
                          }
                        }
                      }
                      relationshipsTo {
                        id
                        from {
                          id
                          createdAt
                          stringValue
                          value
                          timeStamp
                          statType {
                            id
                            name
                          }
                          profile {
                            id
                            name
                          }
                          organization {
                            id
                            name
                          }
                          fixturePeriod {
                            id
                            label
                          }
                        }
                      }
                    }
                  `,
                });

                return [...existingRefs, newStatRef];
              },
              // relationshipsFrom(existingRefs, { readField }) {
              //   const rel = stat.relationshipsFrom.find(
              //     (x) => x.to.id === deleteFixtureStat[0].id
              //   );
              //   return existingRefs.filter(
              //     (relRef) => rel.id !== readField("id", relRef)
              //   );
              // },
            },
          });
        },
      });

      if (
        !isTieBreaker &&
        isGameWinningPoint(
          homePointsWon,
          awayPointsWon,
          homePlayer,
          awayPlayer,
          profileId
        )
      ) {
        createNewStat({
          variables: {
            data: {
              event: { connect: { id: ev.id } },
              statType: {
                connect: {
                  id: statTypes.find((st) => st.name === "Games Won").id,
                },
              },
              fixture: { connect: { id: fixture.id } },
              sport: { connect: { name: "Tennis" } },
              fixturePeriod: { connect: { id: selectedPeriod.id } },
              profile: { connect: { id: profileId } },
              relationshipsTo: {
                create: {
                  from: {
                    connect: { id: currentGame.id },
                  },
                },
              },
            },
          },
          update: (cache, { data: { createStat } }) => {
            cache.modify({
              id: cache.identify(fixture),
              fields: {
                stats(existingRefs, { readField }) {
                  const newStatRef = cache.writeFragment({
                    data: createStat,
                    fragment: gql`
                      fragment NewStat on Stat {
                        id
                        createdAt
                        timeStamp
                        value
                        stringValue
                        statType {
                          id
                          name
                        }
                        profile {
                          id
                          name
                        }
                        organization {
                          id
                          name
                        }
                        fixturePeriod {
                          id
                          order
                          label
                        }
                        relationshipsFrom {
                          id
                          to {
                            id
                            createdAt
                            stringValue
                            value
                            timeStamp
                            statType {
                              id
                              name
                            }
                            profile {
                              id
                              name
                            }
                            organization {
                              id
                              name
                            }
                            fixturePeriod {
                              id
                              label
                            }
                          }
                        }
                        relationshipsTo {
                          id
                          from {
                            id
                            createdAt
                            stringValue
                            value
                            timeStamp
                            statType {
                              id
                              name
                            }
                            profile {
                              id
                              name
                            }
                            organization {
                              id
                              name
                            }
                            fixturePeriod {
                              id
                              label
                            }
                          }
                        }
                      }
                    `,
                  });

                  return [...existingRefs, newStatRef];
                },
                // relationshipsFrom(existingRefs, { readField }) {
                //   const rel = stat.relationshipsFrom.find(
                //     (x) => x.to.id === deleteFixtureStat[0].id
                //   );
                //   return existingRefs.filter(
                //     (relRef) => rel.id !== readField("id", relRef)
                //   );
                // },
              },
            });
          },
        });

        // Switch the current server
        if (currentServer.profile.id === homePlayer.profile.id) {
          setCurrentServer(awayPlayer);
          updateCache(awayPlayer);
        } else {
          setCurrentServer(homePlayer);
          updateCache(homePlayer);
        }
      }
    }

    // createStat({
    //   variables: {
    //     data: {
    //       event: { connect: { id: ev.id } },
    //       timeStamp: stats.filter(
    //         (s) =>
    //           s.statType.name === "Point Won" &&
    //           s.fixturePeriod.id === selectedPeriod.id
    //       ).length,
    //       profile: { connect: { id: profileId } },
    //       statType: {
    //         connect: { id: statTypes.find((st) => st.name === "Point Won").id },
    //       },
    //       fixture: { connect: { id: fixture.id } },
    //       sport: { connect: { name: "Badminton" } },
    //       fixturePeriod: { connect: { id: selectedPeriod.id } },
    //       relationshipsFrom: {
    //         create: {
    //           to: {
    //             create: {
    //               event: { connect: { id: ev.id } },
    //               profile: { connect: { id: currentServer.profile.id } },
    //               statType: {
    //                 connect: {
    //                   id: statTypes.find((st) => st.name === "Service").id,
    //                 },
    //               },
    //               fixture: { connect: { id: fixture.id } },
    //               sport: { connect: { name: "Badminton" } },
    //               fixturePeriod: { connect: { id: selectedPeriod.id } },
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    // });
  };

  const decrementScore = ({ profileId }) => {
    // if (deleting) return;
    // const statToDelete = stats.find(
    //   (s) => s.statType.name === "Point Won" && s.profile.id === profileId
    // );
    // if (statToDelete) {
    //   deleteStat({ variables: { id: statToDelete.id } });
    // }
  };

  return (
    <div className="score-badminton-current-set">
      <TimerNew
        fixtureId={fixtureId}
        periods={periods}
        selectedPeriod={selectedPeriod}
        changePeriod={changePeriod}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        cache={cache}
        updateCache={updateCache}
        currentGame={latestOngoingGame}
      />
      <div className="score-badminton-current-set__player">
        <div className="score-badminton-current-set__player__details home">
          <div className="score-badminton-current-set__player__details__image"></div>
          <div className="score-badminton-current-set__player__details__info">
            <div className="score-badminton-current-set__player__details__info__name">
              {homePlayer.profile.name}
            </div>

            <div
              className={
                "score-badminton-current-set__player__details__info__service-indicator " +
                (currentServer.id === homePlayer.id ? "selected" : "")
              }
              onClick={() => {
                setCurrentServer(homePlayer);
                updateCache(homePlayer);
              }}
            >
              Serve
            </div>
          </div>
        </div>

        <div className="score-badminton-current-set__score">
          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              incrementScore({ profileId: homePlayer.profile.id });
            }}
          >
            {submitting ? <Spinner /> : <PlusIcon className="icon" />}
          </div>

          <div className="score-badminton-current-set__score__value">
            {scores.home}
          </div>

          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              decrementScore({ profileId: homePlayer.profile.id });
            }}
          >
            {deleting ? <Spinner /> : <MinusIcon className="icon" />}
          </div>
        </div>
      </div>

      <div className="score-badminton-current-set__player">
        <div className="score-badminton-current-set__player__details away">
          <div className="score-badminton-current-set__player__details__image"></div>
          <div className="score-badminton-current-set__player__details__info">
            <div className="score-badminton-current-set__player__details__info__name">
              {awayPlayer.profile.name}
            </div>

            <div
              className={
                "score-badminton-current-set__player__details__info__service-indicator " +
                (currentServer.id === awayPlayer.id ? "selected" : "")
              }
              onClick={() => {
                setCurrentServer(awayPlayer);
                updateCache(awayPlayer);
              }}
            >
              Serve
            </div>
          </div>
        </div>

        <div className="score-badminton-current-set__score">
          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              incrementScore({ profileId: awayPlayer.profile.id });
            }}
          >
            {submitting ? <Spinner /> : <PlusIcon className="icon" />}
          </div>

          <div className="score-badminton-current-set__score__value">
            {scores.away}
          </div>

          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              decrementScore({ profileId: awayPlayer.profile.id });
            }}
          >
            {deleting ? <Spinner /> : <MinusIcon className="icon" />}
          </div>
        </div>
      </div>
    </div>
  );
}

function BoxScore({ periods, fixtureId, homePlayer, awayPlayer }) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreTennis.BoxScore",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats = data?.fixture?.stats;
  return (
    <div className="score-badminton-box-score">
      <div className="score-badminton-box-score__column players">
        <div className="score-badminton-box-score__column__label">Player</div>
        <div className="score-badminton-box-score__column__value">
          <div className="score-badminton-box-score__column__value__image"></div>
          {homePlayer?.profile?.name}
        </div>
        <div className="score-badminton-box-score__column__value">
          <div className="score-badminton-box-score__column__value__image"></div>
          {awayPlayer?.profile?.name}
        </div>
      </div>

      {periods.map((period) => (
        <div className="score-badminton-box-score__column period">
          <div className="score-badminton-box-score__column__label">
            {period.label}
          </div>
          <div className="score-badminton-box-score__column__value">
            {
              stats.filter(
                (s) =>
                  s.statType?.name === "Games Won" &&
                  s.fixturePeriod?.id === period.id &&
                  s.profile?.id === homePlayer?.profile?.id
              ).length
            }
          </div>
          <div className="score-badminton-box-score__column__value">
            {
              stats.filter(
                (s) =>
                  s.statType?.name === "Games Won" &&
                  s.fixturePeriod?.id === period.id &&
                  s.profile?.id === awayPlayer?.profile?.id
              ).length
            }
          </div>
        </div>
      ))}
    </div>
  );
}

function EventListItemPointOptions({
  stat,
  stats,
  eventId,
  homePlayer,
  awayPlayer,
  statTypes,
  fixtureId,
  fixture,
  server,
}) {
  // const unforcedErrorStat = stat.relationshipsFrom.find(
  //   (s) => s.to.statType.name === "Unforced Error"
  // )?.to;

  const [deleteStat, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      if (deleteFixtureStat?.length) {
        // cache.modify({
        //   id: cache.identify(stat),
        //   fields: {
        //     relationshipsFrom(existingRefs, { readField }) {
        //       const rel = stat.relationshipsFrom?.find(
        //         (x) => x.to.id === deleteFixtureStat[0].id
        //       );
        //       return existingRefs.filter(
        //         (relRef) => rel?.id !== readField("id", relRef)
        //       );
        //     },
        //   },
        // });

        cache.modify({
          id: cache.identify(fixture),
          fields: {
            stats(existingRefs, { readField }) {
              return existingRefs.filter(
                (statRef) =>
                  deleteFixtureStat[0].id !== readField("id", statRef)
              );
            },
          },
        });
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.EventListItemPointOptions",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [createNewStat] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      cache.modify({
        id: cache.identify(fixture),
        fields: {
          stats(existingRefs, { readField }) {
            const newStatRef = cache.writeFragment({
              data: createStat,
              fragment: gql`
                fragment NewStat on Stat {
                  id
                  createdAt
                  timeStamp
                  value
                  stringValue
                  statType {
                    id
                    name
                  }
                  profile {
                    id
                    name
                  }
                  organization {
                    id
                    name
                  }
                  fixturePeriod {
                    id
                    order
                    label
                  }
                  relationshipsFrom {
                    id
                    to {
                      id
                      createdAt
                      stringValue
                      value
                      timeStamp
                      statType {
                        id
                        name
                      }
                      profile {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                      fixturePeriod {
                        id
                        label
                      }
                    }
                  }
                  relationshipsTo {
                    id
                    from {
                      id
                      createdAt
                      stringValue
                      value
                      timeStamp
                      statType {
                        id
                        name
                      }
                      profile {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                      fixturePeriod {
                        id
                        label
                      }
                    }
                  }
                }
              `,
            });

            return [...existingRefs, newStatRef];
          },
          // relationshipsFrom(existingRefs, { readField }) {
          //   const rel = stat.relationshipsFrom.find(
          //     (x) => x.to.id === deleteFixtureStat[0].id
          //   );
          //   return existingRefs.filter(
          //     (relRef) => rel.id !== readField("id", relRef)
          //   );
          // },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.EventListItemPointOptions",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const unforcedErrorStat = stats.find(
    (y) =>
      y.statType.name === "Unforced Error" &&
      y.relationshipsTo?.find((z) => z.from?.id === stat.id)
  );

  // const doubleFaultStat = stat.relationshipsFrom.find(
  //   (s) => s.to.statType.name === "Double Fault"
  // )?.to;

  const doubleFaultStat = stats.find(
    (y) =>
      y.statType.name === "Double Fault" &&
      y.relationshipsTo?.find((z) => z.from?.id === stat.id)
  );

  // const aceStat = stat.relationshipsFrom.find(
  //   (s) => s.to.statType.name === "Double Fault"
  // )?.to;

  const aceStat = stats.find(
    (y) =>
      y.statType.name === "Ace" &&
      y.relationshipsTo?.find((z) => z.from?.id === stat.id)
  );

  if (unforcedErrorStat) {
    return (
      <div className="score-tennis-event-list-item__points__point__statement">
        Unforced error by{" "}
        <span className="bold score-tennis-event-list-item__points__point__statement__name">
          {" "}
          {unforcedErrorStat.profile.name}
        </span>
        <div
          className="score-tennis-event-list-item__body__statement__delete"
          onClick={() => {
            if (deleting) return;
            deleteStat({
              variables: {
                id: unforcedErrorStat.id,
              },
            });
          }}
        >
          {deleting ? <Spinner /> : <TrashIcon className="icon" />}
        </div>
      </div>
    );
  }

  if (doubleFaultStat) {
    return (
      <div className="score-tennis-event-list-item__points__point__statement">
        Double fault by{" "}
        <span className="bold score-tennis-event-list-item__points__point__statement__name">
          {" "}
          {doubleFaultStat.profile.name}
        </span>
        <div
          className="score-tennis-event-list-item__body__statement__delete"
          onClick={() => {
            if (deleting) return;
            deleteStat({
              variables: {
                id: doubleFaultStat.id,
              },
            });
          }}
        >
          {deleting ? <Spinner /> : <TrashIcon className="icon" />}
        </div>
      </div>
    );
  }

  if (aceStat) {
    return (
      <div className="score-tennis-event-list-item__points__point__statement">
        Ace by{" "}
        <span className="bold score-tennis-event-list-item__points__point__statement__name">
          {" "}
          {aceStat.profile.name}
        </span>
        <div
          className="score-tennis-event-list-item__body__statement__delete"
          onClick={() => {
            if (deleting) return;
            deleteStat({
              variables: {
                id: aceStat.id,
              },
            });
          }}
        >
          {deleting ? <Spinner /> : <TrashIcon className="icon" />}
        </div>
      </div>
    );
  }

  return (
    <div className="score-tennis-event-list-item__body__options">
      <div
        className="score-tennis-event-list-item__body__options__option"
        onClick={() => {
          createNewStat({
            variables: {
              data: {
                event: { connect: { id: eventId } },
                profile: {
                  connect: {
                    id:
                      stat.profile?.id === homePlayer?.profile?.id
                        ? awayPlayer?.profile.id
                        : homePlayer?.profile.id,
                  },
                },
                statType: {
                  connect: {
                    id: statTypes.find((st) => st.name === "Unforced Error").id,
                  },
                },
                fixture: { connect: { id: fixtureId } },
                sport: { connect: { name: "Tennis" } },
                fixturePeriod: { connect: { id: stat.fixturePeriod.id } },
                relationshipsTo: {
                  create: {
                    from: {
                      connect: {
                        id: stat.id,
                      },
                    },
                  },
                },
              },
            },
          });
        }}
      >
        <PlusIcon className="icon" /> Unforced Error
      </div>

      {stat.profile.id !== server?.profile.id && (
        <div
          className="score-tennis-event-list-item__body__options__option"
          onClick={() => {
            createNewStat({
              variables: {
                data: {
                  event: { connect: { id: eventId } },
                  profile: {
                    connect: {
                      id:
                        stat.profile?.id === homePlayer?.profile?.id
                          ? awayPlayer?.profile.id
                          : homePlayer?.profile.id,
                    },
                  },
                  statType: {
                    connect: {
                      id: statTypes.find((st) => st.name === "Double Fault").id,
                    },
                  },
                  fixture: { connect: { id: fixtureId } },
                  sport: { connect: { name: "Tennis" } },
                  fixturePeriod: { connect: { id: stat.fixturePeriod.id } },
                  relationshipsTo: {
                    create: {
                      from: {
                        connect: {
                          id: stat.id,
                        },
                      },
                    },
                  },
                },
              },
            });
          }}
        >
          <PlusIcon className="icon" /> Double Fault
        </div>
      )}

      {stat.profile.id === server?.profile.id && (
        <div
          className="score-tennis-event-list-item__body__options__option"
          onClick={() => {
            createNewStat({
              variables: {
                data: {
                  event: { connect: { id: eventId } },
                  profile: {
                    connect: {
                      id:
                        stat.profile?.id === homePlayer?.profile?.id
                          ? homePlayer?.profile.id
                          : awayPlayer?.profile.id,
                    },
                  },
                  statType: {
                    connect: {
                      id: statTypes.find((st) => st.name === "Ace").id,
                    },
                  },
                  fixture: { connect: { id: fixtureId } },
                  sport: { connect: { name: "Tennis" } },
                  fixturePeriod: { connect: { id: stat.fixturePeriod.id } },
                  relationshipsTo: {
                    create: {
                      from: {
                        connect: {
                          id: stat.id,
                        },
                      },
                    },
                  },
                },
              },
            });
          }}
        >
          <PlusIcon className="icon" /> Ace
        </div>
      )}
    </div>
  );
}

function EventListItem({
  stat,
  stats = [],
  statTypes,
  homePlayer,
  awayPlayer,
  fixtureId,
  fixture,
  eventId,
  selectedPeriod,
}) {
  const [createNewStat] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.EventListItem",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [deleteStat, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      // if (deleteFixtureStat?.length) {
      //   cache.modify({
      //     id: cache.identify(stat),
      //     fields: {
      //       relationshipsFrom(existingRefs, { readField }) {
      //         const rel = stat.relationshipsFrom.find(
      //           (x) => x.to.id === deleteFixtureStat[0].id
      //         );
      //         return existingRefs.filter(
      //           (relRef) => rel.id !== readField("id", relRef)
      //         );
      //       },
      //     },
      //   });
      //   cache.modify({
      //     id: cache.identify(fixture),
      //     fields: {
      //       stats(existingRefs, { readField }) {
      //         return existingRefs.filter(
      //           (statRef) =>
      //             deleteFixtureStat[0].id !== readField("id", statRef)
      //         );
      //       },
      //     },
      //   });
      // }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.EventListItem",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [updateStat, { loading: updating }] = useMutation(UPDATE_STAT, {
    update: (cache, { data: { updateStat } }) => {
      // console.log("US:", updateStat);
      cache.modify({
        id: cache.identify(updateStat),
        fields: {
          profile() {
            return updateStat.profile;
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis.EventListItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  //   const points = stats
  //     .filter(
  //       (s) =>
  //         s.statType.name === "Point Won" &&
  //         s.timeStamp <= stat.timeStamp &&
  //         s.fixturePeriod.id === selectedPeriod?.id
  //     )
  //     .sort((a, b) => b.timeStamp - a.timeStamp);

  //   const homeScore =
  //     points.filter((p) => p.profile.id === homePlayer?.profile?.id).length || 0;

  //   const awayScore =
  //     points.filter((p) => p.profile.id === awayPlayer?.profile?.id).length || 0;

  //   const serviceStat = stat.relationshipsFrom.find(
  //     (s) => s.to.statType.name === "Service"
  //   )?.to;

  //   const unforcedErrorStat = stat.relationshipsFrom.find(
  //     (s) => s.to.statType.name === "Unforced Error"
  //   )?.to;

  //   const doubleFaultStat = stat.relationshipsFrom.find(
  //     (s) => s.to.statType.name === "Double Fault"
  //   )?.to;
  const getScore = ({ stat, stats, server, isTieBreaker, homePlayer }) => {
    const orderedStats = stats.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
    );
    // console.log("GET SCORE ORDERED STATS:", orderedStats);
    const statIndex = orderedStats.findIndex((s) => s.id === stat.id);
    const homePointsScored = orderedStats.filter(
      (s, i) =>
        (isTieBreaker ? homePlayer : server).profile?.id === s.profile?.id &&
        i <= statIndex
    );
    const awayPointsScored = orderedStats.filter(
      (s, i) =>
        (isTieBreaker ? homePlayer : server).profile?.id !== s.profile?.id &&
        i <= statIndex
    );

    const scores = determineCurrentScore(
      homePointsScored,
      awayPointsScored,
      isTieBreaker
    );

    if (scores.home === "Deuce" || scores.away === "Deuce") {
      return "Deuce";
    } else if (scores.home === "Adv" || scores.away === "Adv") {
      return "Advantage";
    } else if (scores.home === "Game" || scores.away === "Game") {
      return "Game";
    } else {
      return scores.home + "-" + scores.away;
    }
  };

  const server = stat.relationshipsFrom.find(
    (r) => r.to?.statType?.name === "Serve"
  )?.to;

  const isTieBreaker = stat.relationshipsFrom.find(
    (r) => r.to?.statType?.name === "Tie Break"
  )?.to;

  const gameWonBy = stat.relationshipsFrom.find(
    (r) => r.to.statType.name === "Games Won"
  );

  if (isTieBreaker) {
    // console.log("TIE BREAK:", stat);
  }

  return (
    <Card className="score-tennis-event-list-item">
      <div className="score-tennis-event-list-item__header">
        <div className="score-tennis-event-list-item__header__title">
          {isTieBreaker
            ? "Tie Break"
            : stat.fixturePeriod.label + " : Game " + stat.value}
        </div>

        <div
          className="score-tennis-event-list-item__header__delete-btn"
          onClick={() => {
            if (deleting) return;
            deleteStat({
              variables: {
                id: stat.id,
              },
              update: (cache, { data: { deleteFixtureStat } }) => {
                if (deleteFixtureStat?.length) {
                  cache.modify({
                    id: cache.identify(stat),
                    fields: {
                      relationshipsFrom(existingRefs, { readField }) {
                        const rel = stat.relationshipsFrom.find(
                          (x) => x.to.id === deleteFixtureStat[0].id
                        );
                        return existingRefs.filter(
                          (relRef) => rel.id !== readField("id", relRef)
                        );
                      },
                    },
                  });

                  cache.modify({
                    id: cache.identify(fixture),
                    fields: {
                      stats(existingRefs, { readField }) {
                        return existingRefs.filter(
                          (statRef) =>
                            deleteFixtureStat[0].id !== readField("id", statRef)
                        );
                      },
                    },
                  });
                }
              },
            });
          }}
        >
          {deleting ? <Spinner /> : <TrashIcon className="icon" />}
        </div>
      </div>

      <div className="score-tennis-event-list-item__metas">
        {(gameWonBy || isTieBreaker) && (
          <div className="score-tennis-event-list-item__metas__meta">
            Game won by:{" "}
            {gameWonBy ? (
              <div className="score-tennis-event-list-item__metas__meta__name">
                <span className="bold">{gameWonBy?.to?.profile?.name}</span>
              </div>
            ) : (
              <Select
                items={[homePlayer.profile, awayPlayer.profile]}
                onItemSelect={(profile) => {
                  createNewStat({
                    variables: {
                      data: {
                        event: { connect: { id: eventId } },
                        statType: {
                          connect: {
                            id: statTypes.find((st) => st.name === "Games Won")
                              .id,
                          },
                        },
                        fixture: { connect: { id: fixture.id } },
                        sport: { connect: { name: "Tennis" } },
                        fixturePeriod: { connect: { id: selectedPeriod.id } },
                        profile: { connect: { id: profile.id } },
                        relationshipsTo: {
                          create: {
                            from: {
                              connect: { id: stat.id },
                            },
                          },
                        },
                      },
                    },
                    update: (cache, { data: { createStat } }) => {
                      cache.modify({
                        id: cache.identify(fixture),
                        fields: {
                          stats(existingRefs, { readField }) {
                            const newStatRef = cache.writeFragment({
                              data: createStat,
                              fragment: gql`
                                fragment NewStat on Stat {
                                  id
                                  createdAt
                                  timeStamp
                                  value
                                  stringValue
                                  statType {
                                    id
                                    name
                                  }
                                  profile {
                                    id
                                    name
                                  }
                                  organization {
                                    id
                                    name
                                  }
                                  fixturePeriod {
                                    id
                                    order
                                    label
                                  }
                                  relationshipsFrom {
                                    id
                                    to {
                                      id
                                      createdAt
                                      stringValue
                                      value
                                      timeStamp
                                      statType {
                                        id
                                        name
                                      }
                                      profile {
                                        id
                                        name
                                      }
                                      organization {
                                        id
                                        name
                                      }
                                      fixturePeriod {
                                        id
                                        label
                                      }
                                    }
                                  }
                                  relationshipsTo {
                                    id
                                    from {
                                      id
                                      createdAt
                                      stringValue
                                      value
                                      timeStamp
                                      statType {
                                        id
                                        name
                                      }
                                      profile {
                                        id
                                        name
                                      }
                                      organization {
                                        id
                                        name
                                      }
                                      fixturePeriod {
                                        id
                                        label
                                      }
                                    }
                                  }
                                }
                              `,
                            });

                            return [...existingRefs, newStatRef];
                          },
                          // relationshipsFrom(existingRefs, { readField }) {
                          //   const rel = stat.relationshipsFrom.find(
                          //     (x) => x.to.id === deleteFixtureStat[0].id
                          //   );
                          //   return existingRefs.filter(
                          //     (relRef) => rel.id !== readField("id", relRef)
                          //   );
                          // },
                        },
                      });
                    },
                  });
                }}
                placeholder={"Select player"}
              />
            )}
            {gameWonBy && (
              <div
                className="score-tennis-event-list-item__body__statement__switch"
                onClick={() => {
                  if (updating) return;
                  updateStat({
                    variables: {
                      where: { id: gameWonBy.to.id },
                      data: {
                        profile: {
                          connect: {
                            id:
                              gameWonBy?.to?.profile.id ===
                              homePlayer.profile.id
                                ? awayPlayer.profile.id
                                : homePlayer.profile.id,
                          },
                        },
                      },
                    },
                  });
                }}
              >
                {updating ? <Spinner /> : <SwapIcon className="icon" />}
              </div>
            )}
          </div>
        )}

        {!isTieBreaker && (
          <div className="score-tennis-event-list-item__metas__meta">
            Service:{" "}
            <div className="score-tennis-event-list-item__metas__meta__name">
              <span className="bold">{server?.profile.name}</span>
            </div>
            <div
              className="score-tennis-event-list-item__body__statement__switch"
              onClick={() => {
                if (updating) return;
                updateStat({
                  variables: {
                    where: { id: server.id },
                    data: {
                      profile: {
                        connect: {
                          id:
                            server?.profile.id === homePlayer.profile.id
                              ? awayPlayer.profile.id
                              : homePlayer.profile.id,
                        },
                      },
                    },
                  },
                });
              }}
            >
              {updating ? <Spinner /> : <SwapIcon className="icon" />}
            </div>
          </div>
        )}
      </div>

      <div className="score-tennis-event-list-item__points">
        {stat.relationshipsFrom
          .filter((r) => r.to.statType.name === "Points Won")
          .map((x) => x.to)
          .sort((a, b) =>
            new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
          )
          .map((x) => (
            <div className="score-tennis-event-list-item__points__point">
              <div>
                <div className="score-tennis-event-list-item__points__point__value">
                  {getScore({
                    stat: x,
                    stats: stat.relationshipsFrom
                      .filter((r) => r.to.statType.name === "Points Won")
                      .map((x) => x.to),
                    server: stat.relationshipsFrom.find(
                      (r) => r.to?.statType?.name === "Serve"
                    )?.to,
                    isTieBreaker,
                    homePlayer,
                  })}

                  <div
                    className="score-tennis-event-list-item__points__point__delete-btn"
                    onClick={() => {
                      if (deleting) return;
                      deleteStat({
                        variables: {
                          id: x.id,
                        },
                        update: (cache, { data: { deleteFixtureStat } }) => {
                          if (deleteFixtureStat?.length) {
                            cache.modify({
                              id: cache.identify(stat),
                              fields: {
                                relationshipsFrom(existingRefs, { readField }) {
                                  const rel = stat.relationshipsFrom.find(
                                    (x) => x.to.id === deleteFixtureStat[0].id
                                  );
                                  return existingRefs.filter(
                                    (relRef) =>
                                      rel.id !== readField("id", relRef)
                                  );
                                },
                              },
                            });

                            cache.modify({
                              id: cache.identify(fixture),
                              fields: {
                                stats(existingRefs, { readField }) {
                                  return existingRefs.filter(
                                    (statRef) =>
                                      deleteFixtureStat[0].id !==
                                      readField("id", statRef)
                                  );
                                },
                              },
                            });

                            if (gameWonBy) {
                              deleteStat({
                                variables: {
                                  id: gameWonBy.to.id,
                                },
                                update: (
                                  cache,
                                  {
                                    data: {
                                      deleteFixtureStat: deleteWonByStat,
                                    },
                                  }
                                ) => {
                                  cache.modify({
                                    id: cache.identify(stat),
                                    fields: {
                                      relationshipsFrom(
                                        existingRefs,
                                        { readField }
                                      ) {
                                        const rel = stat.relationshipsFrom.find(
                                          (x) =>
                                            x.to.id === deleteWonByStat[0].id
                                        );
                                        return existingRefs.filter(
                                          (relRef) =>
                                            rel.id !== readField("id", relRef)
                                        );
                                      },
                                    },
                                  });

                                  cache.modify({
                                    id: cache.identify(fixture),
                                    fields: {
                                      stats(existingRefs, { readField }) {
                                        return existingRefs.filter(
                                          (statRef) =>
                                            deleteWonByStat[0].id !==
                                            readField("id", statRef)
                                        );
                                      },
                                    },
                                  });
                                },
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    {deleting ? <Spinner /> : <TrashIcon className="icon" />}
                  </div>
                </div>
                <div className="score-tennis-event-list-item__points__point__statement">
                  Point won by{" "}
                  <span className="bold score-tennis-event-list-item__points__point__statement__name">
                    {" "}
                    {x.profile.name}
                  </span>
                  <div
                    className="score-tennis-event-list-item__body__statement__switch"
                    onClick={() => {
                      if (updating) return;
                      updateStat({
                        variables: {
                          where: { id: x.id },
                          data: {
                            profile: {
                              connect: {
                                id:
                                  x.profile.id === homePlayer.profile.id
                                    ? awayPlayer.profile.id
                                    : homePlayer.profile.id,
                              },
                            },
                          },
                        },
                      });
                    }}
                  >
                    {updating ? <Spinner /> : <SwapIcon className="icon" />}
                  </div>
                </div>
                {isTieBreaker && (
                  <div>
                    Service:{" "}
                    {
                      stats.find(
                        (y) =>
                          y.statType.name === "Serve" &&
                          y.relationshipsTo?.find((z) => z.from?.id === x.id)
                      )?.profile?.name
                    }
                  </div>
                )}

                <EventListItemPointOptions
                  stat={x}
                  stats={stats}
                  eventId={eventId}
                  homePlayer={homePlayer}
                  awayPlayer={awayPlayer}
                  statTypes={statTypes}
                  fixtureId={fixtureId}
                  fixture={fixture}
                  server={
                    isTieBreaker
                      ? stats.find(
                          (y) =>
                            y.statType.name === "Serve" &&
                            y.relationshipsTo?.find((z) => z.from?.id === x.id)
                        )
                      : server
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </Card>
  );
}

function EventsList({ fixtureId, selectedPeriod, homePlayer, awayPlayer }) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreTennis.EventsList",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats =
    data?.fixture?.stats
      ?.filter(
        (s) =>
          s.statType.name === "Games Played" &&
          s.fixturePeriod.id === selectedPeriod?.id
      )
      ?.sort((a, b) => b.value - a.value) || [];

  const hasTieBreak = stats.find((stat) =>
    stat.relationshipsFrom?.find((r) => r.to?.statType?.name === "Tie Break")
  );

  return (
    <div className="score-badminton-events-list">
      {!hasTieBreak && (
        <CreateTieBreak
          ev={data?.fixture?.event}
          currentGame={stats?.[0]}
          statTypes={data?.fixture?.event?.sport?.statTypes}
          fixture={data?.fixture}
          selectedPeriod={selectedPeriod}
        />
      )}

      {stats.map((stat) => (
        <EventListItem
          stat={stat}
          stats={data?.fixture?.stats}
          statTypes={data?.fixture?.event?.sport?.statTypes}
          homePlayer={homePlayer}
          awayPlayer={awayPlayer}
          fixtureId={fixtureId}
          eventId={data?.fixture?.event?.id}
          selectedPeriod={selectedPeriod}
          fixture={data?.fixture}
        />
      ))}
    </div>
  );
}

function ScoreTennis({
  fixtureId,
  fixture,
  eventId,
  statsCacheState,
  periods,
  homePlayer,
  awayPlayer,
  completed,
}) {
  const [cache, setCache] = useState(statsCacheState);
  const [editSettings, setEditSettings] = useState(!cache?.settings);
  const [publish, setPublish] = useState(false);
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    cache?.selectedPeriodId || ""
  );
  const [timestamp, setTimestamp] = useState(cache?.timestamp || 0);
  const [currentServer, setCurrentServer] = useState(
    statsCacheState?.currentServer || ""
  );

  const [updateFixture] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreTennis",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  const changePeriod = (newPeriodId) => {
    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...statsCacheState,
            selectedPeriodId: newPeriodId,
            // periods: {
            //   ...(statsCacheState?.periods || {}),
            //   ...{ [selectedPeriodId]: timestamp },
            // },
          },
        },
      },
      update: (cache, { data: { updateFixture } }) => {
        cache.writeQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
          data: {
            statsCacheState: updateFixture.statsCacheState,
            periods: updateFixture.periods,
          },
        });

        setCache(updateFixture.statsCacheState);
      },
    });
    setSelectedPeriodId(newPeriodId);
    // setTimestamp(newTimestamp);
  };

  const updateCache = (server) => {
    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...statsCacheState,
            selectedPeriodId,
            // timestamp,
            // periods: {
            //   ...(statsCacheState?.periods || {}),
            //   ...{ [selectedPeriodId]: timestamp },
            // },
            currentServer: server || currentServer,
          },
        },
      },
    });
  };

  return (
    <div className="score-football">
      <ScoringHeader
        fixtureId={fixtureId}
        periods={periods}
        changePeriod={changePeriod}
        selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        setEditSettings={setEditSettings}
        cache={cache}
        updateCache={updateCache}
        setPublish={setPublish}
      />

      <div className="body">
        <div className="score-badminton-body__main">
          <div className="score-badminton-body__main__current-set">
            <CurrentSet
              fixtureId={fixtureId}
              periods={periods}
              changePeriod={changePeriod}
              selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
              timestamp={timestamp}
              setTimestamp={setTimestamp}
              setEditSettings={setEditSettings}
              cache={cache}
              updateCache={updateCache}
              setPublish={setPublish}
              homePlayer={homePlayer}
              awayPlayer={awayPlayer}
              currentServer={currentServer}
              setCurrentServer={setCurrentServer}
            />
          </div>

          <div className="score-badminton-body__main__box-score">
            <BoxScore
              fixtureId={fixtureId}
              periods={periods}
              homePlayer={homePlayer}
              awayPlayer={awayPlayer}
            />
          </div>
        </div>

        <div className="score-badminton-body__events">
          <EventsList
            fixtureId={fixtureId}
            selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
            homePlayer={homePlayer}
            awayPlayer={awayPlayer}
          />
        </div>
        {/* <div className="left-column">
          <StatTypesList
            onClick={(statType) => {
              setSelectedStat(statType);
              setIsHomeStat(true);
            }}
            selectedPeriodId={selectedPeriodId}
          />
        </div>
        <div className="middle-column">
          <StatsList
            fixtureId={fixtureId}
            homePlayer={homePlayer}
            awayPlayer={awayPlayer}
            onDelete={(s, st, t) => {
              setDeleteStat({ stat: s, stats: st, timestamp: t });
            }}
          />
        </div>
        <div className="right-column">
          <StatTypesList
            onClick={(statType) => {
              setSelectedStat(statType);
              setIsHomeStat(false);
            }}
            selectedPeriodId={selectedPeriodId}
          />
        </div> */}
      </div>

      {/* {selectedStat && (
        <OverlayNew
          fixtureId={fixtureId}
          eventId={eventId}
          dismiss={() => {
            setSelectedStat(false);
          }}
          selectedStat={selectedStat}
          homePlayer={homePlayer}
          awayPlayer={awayPlayer}
          isHomeStat={isHomeStat}
          defaultTimestamp={timestamp}
          selectedPeriodId={selectedPeriodId}
          periods={periods}
        />
      )} */}

      {/* {deleteStat && (
        <DeleteStatsConfirm
          fixtureId={fixtureId}
          dismiss={() => {
            setDeleteStat(false);
          }}
          stat={deleteStat.stat}
          stats={deleteStat.stats}
          timestamp={deleteStat.timestamp}
        />
      )} */}

      {editSettings && (
        <Settings
          fixtureId={fixtureId}
          setEditSettings={setEditSettings}
          statsCacheState={statsCacheState}
          defaultPeriods={periods}
        />
      )}

      {/* {completed && <div>Published</div>} */}

      {(publish || completed) && (
        // <PublishStatsWrapper
        //   fixtureId={fixtureId}
        //   eventId={eventId}
        //   setPublish={setPublish}
        //   homePlayer={homePlayer}
        //   awayPlayer={awayPlayer}
        //   completed={completed}
        // />
        <PublishStatsConfirm
          dismiss={() => {
            setPublish(false);
          }}
          fixtureId={fixtureId}
          setPublish={setPublish}
          periods={periods}
          completed={completed}
          homePlayer={homePlayer}
          awayPlayer={awayPlayer}
          // placements={[
          //   { organizationId: homeTeam.organization.id, value: homeScore },
          //   { organizationId: awayTeam.organization.id, value: awayScore },
          // ]}
          // tables={statTable.reduce((accum, curr) => {
          //   for (let statTypeId in curr.stats) {
          //     if (curr.stats[statTypeId]) {
          //       accum.push({
          //         profileId: curr.profile.id,
          //         organizationId: curr.organization.id,
          //         statTypeId,
          //         value: curr.stats[statTypeId],
          //       });
          //     }
          //   }

          //   return accum;
          // }, [])}
        />
      )}
    </div>
  );
}

function ScoreTennisWrapper({ id }) {
  usePageTracking({ title: "Tennis Scoring" });
  const { loading, error, data } = useQuery(FIXTURE_SETTINGS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreTennis.ScoreTennisWrapper",
        query: "FIXTURE_SETTINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const cache = data?.fixture?.statsCacheState;
  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  const homePlayer = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayPlayer = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  //   const homeLineup = data?.fixture?.roles.filter(
  //     (r) =>
  //       r.type.name === "LINEUP PLAYER" &&
  //       r.organization.id === homeTeam?.organization?.id
  //   );

  //   const awayLineup = data?.fixture?.roles.filter(
  //     (r) =>
  //       r.type.name === "LINEUP PLAYER" &&
  //       r.organization.id === awayTeam?.organization?.id
  //   );

  const eventId = data?.fixture?.event?.id;
  const completed = data?.fixture?.completed;
  // console.log("Completed:", completed);

  return (
    <ScoreTennis
      fixtureId={id}
      fixture={data.fixture}
      eventId={eventId}
      statsCacheState={cache}
      periods={periods}
      homePlayer={homePlayer}
      awayPlayer={awayPlayer}
      completed={completed}
    />
  );
}

export default ScoreTennisWrapper;
