import { gql } from "@apollo/client";

const ORGANIZATION_STATS = gql`
  query organizationQuery(
    $id: ID!
    $sport: String!
    $events: [ID!]
    $statTypes: [String!]
    $rolesWhereInput: EventRoleWhereInput
  ) {
    organization(id: $id) {
      id
      stats(sport: $sport, events: $events) {
        totalGamesPlayed
        record {
          data
        }
        aggregations(statTypes: $statTypes) {
          data
        }
        form {
          id
          scheduledStart
          roles(where: $rolesWhereInput) {
            id
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          placements(where: { type: { name: "RESULT" } }) {
            id
            order
            value
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
        }
      }
    }
  }
`;

export default ORGANIZATION_STATS;
