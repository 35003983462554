import "./FixtureVideos.css";
import { useQuery } from "@apollo/client";
import { FIXTURE_MEDIA } from "../../../api/fixture";
import VideoCard from "../../../mobileComponents/VideoCard";
import Button from "../../../components/Button";
import AddVideo from "../../AddVideo";
import { useAppState } from "../../../utils/appState";
import captureException from "../../../utils/captureException";
import Spinner from "../../../components/Spinner";

export default function FixtureVideos({ id, canManageFixture }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(FIXTURE_MEDIA, {
    variables: { id, type: "VIDEO" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureVideos",
        query: "FIXTURE_MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="no-data">
        <Spinner />
      </div>
    );
  }

  const pinnedVideo = data?.fixture?.pinnedMedia;
  const videos = data?.fixture?.media?.filter(
    (m) => m.type === "VIDEO" && m.id !== pinnedVideo?.id
  );

  return (
    <div className="fixture-videos-mobile">
      {canManageFixture && (
        <Button
          className="primary medium blue"
          style={{ margin: "16px" }}
          onClick={() => {
            setAppState({
              modal: (
                <AddVideo
                  config={{
                    source: data?.fixture,
                    sourceType: "fixture",
                  }}
                />
              ),
            });
          }}
        >
          Add Video
        </Button>
      )}

      {!videos?.length && (
        <div className="no-videos-container">
          <div className="no-data">No tagged videos for this page yet.</div>
        </div>
      )}

      <div className="organization-videos-gallery">
        {pinnedVideo && <VideoCard media={pinnedVideo} videoHeight={180} />}
        {videos.map((v) => (
          <VideoCard key={v.id} media={v} videoHeight={180} />
        ))}
      </div>

      {/* {videos.length < videosCount && (
        <div
          className="show-more-container"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: videos.length ? videos[videos.length - 1]?.id : null,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
        </div>
      )} */}
    </div>
  );
}
