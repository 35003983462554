import { gql } from "@apollo/client";

const FORMATIONS = gql`
  query formations($where: FormationWhereInput!) {
    formations(where: $where) {
      id
      name
      positions {
        id
        positionType {
          id
          name
          shortName
        }
        x
        y
      }
    }
  }
`;

export default FORMATIONS;
