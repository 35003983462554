import "./EventLatestHighlights.css";
import { useQuery } from "@apollo/client";
import { EVENT_MEDIA } from "../../../api/event";
import YouTubeVideo from "../../../components/YouTubeVideo";
import makeFileUrl from "../../../utils/makeFileUrl";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";
import { useHistory, useLocation } from "react-router-dom";

export default function EventLatestHighlights({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(EVENT_MEDIA, {
    variables: { id, type: "VIDEO" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventLatestHighlights",
        query: "EVENT_MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-latest-highlights-mobile">
        <div className="event-latest-highlights-mobile__title">
          <Placeholder style={{ height: "10px", width: "64px" }} pulse={true} />
        </div>
        <div
          className="event-latest-highlights-mobile__video-wrapper"
          style={{ height: "160px" }}
        >
          <Placeholder style={{ height: "100%", width: "100%" }} pulse={true} />
        </div>
      </div>
    );
  }

  const video = data?.event?.media?.[0];

  return (
    <div className="event-latest-highlights-mobile">
      <div className="event-latest-highlights-mobile__title">
        Latest Highlights
      </div>
      <div className="event-latest-highlights-mobile__video-wrapper">
        {video && (
          <div
            className="event-latest-highlights-mobile__video-container"
            style={{
              height: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {video.isExternal ? (
              <YouTubeVideo filename={video.filename} />
            ) : (
              <video controls controlsList="nodownload">
                <source src={makeFileUrl(video)} type="video/mp4" />
              </video>
            )}
          </div>
        )}

        {!video && (
          <div className="no-data">
            <div className="text">No videos added to this event yet.</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        )}
      </div>

      {!!data?.event?.media?.length && (
        <div
          className="event-latest-highlights-mobile__footer"
          onClick={() => {
            history.replace(location.pathname + "?t=videos");
          }}
        >
          See more videos
        </div>
      )}
    </div>
  );
}
