import "./AccountButton.css";
import NewDropdown from "../NewDropdown";
import Card from "../Card";
import { ReactComponent as ProfileIcon } from "../../icons/profile.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import { ReactComponent as HeadphoneIcon } from "../../icons/headphone.svg";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { USER_PROFILE } from "../../api/user";
import makeInitials from "../../utils/makeInitials";
import Avatar from "../Avatar";
import { useAppState } from "../../utils/appState";
import CreateSupportTicket from "../CreateSupportTicket";

function AccountButtonWrapper({ isLoggedIn }) {
  const history = useHistory();
  if (isLoggedIn) {
    return <AccountButton />;
  } else {
    return (
      <div
        className="account"
        onClick={() => {
          history.push("/auth");
        }}
      >
        <ProfileIcon
          style={{ stroke: "var(--blue-lighter)", height: "16px" }}
        />
      </div>
    );
  }
}

function AccountButton() {
  const [, setAppState] = useAppState();
  const history = useHistory();
  const { loading, error, data } = useQuery(USER_PROFILE);

  if (error) {
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return <div />;
  }

  const userProfile = data.userProfile;

  return (
    <NewDropdown
      target={
        <div className="account">
          {userProfile?.profilePhoto ? (
            <Avatar media={userProfile.profilePhoto} />
          ) : (
            makeInitials(userProfile?.name)
          )}
        </div>
      }
      menu={({ setShowMenu }) => (
        <Card className="account-button-menu">
          <div
            className="account-button-menu-item"
            onClick={() => {
              if (userProfile?.id) {
                history.push("/app/profile/" + userProfile.id);
              }
              setShowMenu(false);
            }}
          >
            <div className="icon">
              <ProfileIcon className="account-button-menu-icon" />
            </div>
            <div className="desc">View Profile</div>
          </div>
          <div
            className="account-button-menu-item"
            onClick={() => {
              if (userProfile?.id) {
                history.push("/app/account");
              }
              setShowMenu(false);
            }}
          >
            <div className="icon">
              <SettingsIcon className="account-button-menu-icon" />
            </div>
            <div className="desc">My Account</div>
          </div>
          <div
            className="account-button-menu-item"
            onClick={() => {
              // if (userProfile?.id) {
              //   history.push("/app/account");
              // }
              // setShowMenu(false);
              setAppState({
                modal: (
                  <CreateSupportTicket
                    title={"Contact Us"}
                    type={"ENQUIRY"}
                    fields={{
                      email: true,
                      phone: true,
                      subject: true,
                    }}
                    messageLabel={"Message"}
                    messageRequired={true}
                    getVars={({ email, phone, subject, description }) => {
                      const v = {
                        type: "ENQUIRY",
                        description: description || undefined,
                        email,
                        phone,
                        subject,
                      };

                      return v;
                    }}
                  />
                ),
              });
            }}
          >
            <div className="icon">
              <HeadphoneIcon
                className="account-button-menu-icon"
                style={{ stroke: "none", fill: "var(--light-4)" }}
              />
            </div>
            <div className="desc">Contact Us</div>
          </div>

          <div
            className="account-button-menu-item"
            onClick={() => {
              history.push("/logout");
              setShowMenu(false);
            }}
          >
            <div className="icon">
              <LogoutIcon className="account-button-menu-icon" />
            </div>
            <div className="desc">Logout</div>
          </div>
        </Card>
      )}
    />
  );
}

export default AccountButtonWrapper;
