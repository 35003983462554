import "./AccountNotifications.css";
import Spinner from "../../Spinner";
import Card from "../../Card";
import NotificationItem from "../../NotificationItem";
import Button from "../../Button";
import Loader from "../../Loader";
import { useQuery } from "@apollo/client";
import { ACCOUNT_NOTIFICATIONS } from "../../../api/account";
import captureException from "../../../utils/captureException";

export default function AccountNotifications() {
  const { loading, error, data, networkStatus, fetchMore } = useQuery(
    ACCOUNT_NOTIFICATIONS
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AccountNotifications",
        query: "ACCOUNT_NOTIFICATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const notifications = data?.userAccount?.notifications || [];
  // console.log()

  return (
    <div className="account-notifications">
      {notifications.map((n) => (
        <Card key={n.id} className="account-notifications__item">
          <NotificationItem notification={n} />
        </Card>
      ))}

      {!!notifications?.length && (
        <div
          className="show-more-container"
          style={{
            padding: "32px",
            paddingTop: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="primary small blue show-more-button"
            onClick={() => {
              fetchMore({
                variables: {
                  cursor: notifications[notifications.length - 1].id,
                },
              });
            }}
          >
            {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
          </Button>
        </div>
      )}
    </div>
  );
}
