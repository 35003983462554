import { gql } from "@apollo/client";

const UPCOMING_FIXTURES = gql`
  query upcomingFixtures($where: FixtureWhereInput!) {
    upcomingFixtures(where: $where) {
      id
      createdAt
      scheduledStart
      actualStart
      totalLegs
      isUserFixture
      legs {
        id
      }
      roles {
        id
        order
        type {
          id
          name
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      event {
        id
        sport {
          id
          name
        }
      }
      eventPart {
        id
      }
      placements(where: { type: { name: "RESULT" } }) {
        id
        order
        organization {
          id
        }
        profile {
          id
        }
        value
        valueString
      }
      badmintonBoxScore {
        id
        label
        homeScore
        awayScore
      }
    }
  }
`;

export default UPCOMING_FIXTURES;
