import "./AboutEvent.css";
import { useQuery } from "@apollo/client";
import { EVENT } from "../../../api/event";
import moment from "moment";
import MapContainer from "../../../components/Map";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function AboutEvent({ id }) {
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AboutEvent",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-overview__about">
        <div className="event-overview__about__title">
          <Placeholder style={{ height: "10px", width: "64px" }} pulse={true} />
        </div>

        <div
          className="event-overview__about__desc"
          style={{ paddingBottom: "10px" }}
        >
          <Placeholder style={{ height: "10px", width: "100%" }} pulse={true} />
          <Placeholder
            style={{ height: "10px", width: "100%", marginTop: "12px" }}
            pulse={true}
          />
          <Placeholder
            style={{ height: "10px", width: "60%", marginTop: "12px" }}
            pulse={true}
          />
        </div>

        <div className="event-overview__about__venue">
          <div className="event-overview__about__venue__map">
            <Placeholder
              style={{ height: "100%", width: "100%" }}
              pulse={true}
            />
          </div>
          <div
            className="event-overview__about__venue__org"
            style={{ marginTop: "12px" }}
          >
            <div className="event-overview__about__venue__org__image"></div>

            <div className="event-overview__about__venue__org__desc">
              <div className="event-overview__about__venue__org__desc__primary">
                <Placeholder
                  style={{ height: "10px", width: "64px" }}
                  pulse={true}
                />
              </div>
              <div className="event-overview__about__venue__org__desc__secondary">
                <Placeholder
                  style={{ height: "10px", width: "128px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const ev = data?.event;
  const hideRegistrationDetails = !ev.scheduledStart
    ? false
    : moment().isAfter(ev.scheduledStart)
    ? true
    : false;

  return (
    <div className="event-overview__about">
      <div className="event-overview__about__title">About</div>

      {ev.description && (
        <div
          className="event-overview__about__desc"
          style={{ paddingBottom: "10px" }}
        >
          {ev.description}
        </div>
      )}

      <div className="event-overview__about__dates">
        <div className="event-overview__about__dates__group">
          <div className="event-overview__about__dates__group__key">
            Start Date
          </div>

          <div className="event-overview__about__dates__group__value">
            {ev.scheduledStart
              ? moment(ev.scheduledStart).format("Do MMM YYYY")
              : "TBD"}
          </div>
        </div>

        <div className="event-overview__about__dates__group">
          <div className="event-overview__about__dates__group__key">
            End Date
          </div>

          <div className="event-overview__about__dates__group__value">
            {ev.scheduledEnd
              ? moment(ev.scheduledEnd).format("Do MMM YYYY")
              : "TBD"}
          </div>
        </div>

        {!hideRegistrationDetails && (
          <div className="event-overview__about__dates__group">
            <div className="event-overview__about__dates__group__key">
              Registration Ends
            </div>

            <div className="event-overview__about__dates__group__value">
              {ev.registrationEnd
                ? moment(ev.registrationEnd).format("Do MMM YYYY")
                : "TBD"}
            </div>
          </div>
        )}

        {!hideRegistrationDetails && (
          <div className="event-overview__about__dates__group">
            <div className="event-overview__about__dates__group__key">
              Registration Amount
            </div>

            <div className="event-overview__about__dates__group__value">
              {ev.registrationAmount ? "INR " + ev.registrationAmount : "TBD"}
            </div>
          </div>
        )}
      </div>

      <div className="event-overview__about__venue">
        <div className="event-overview__about__venue__map">
          <MapContainer
            venue={{
              label: ev.venueLabel,
              value: ev.venueValue,
              lat: ev.venueLat || ev.city?.lat,
              lng: ev.venueLng || ev.city?.lng,
            }}
          />
        </div>
        <div className="event-overview__about__venue__org">
          {!ev.venueLabel && (
            <div className="event-overview__about__venue__org__image"></div>
          )}

          <div className="event-overview__about__venue__org__desc">
            <div className="event-overview__about__venue__org__desc__primary">
              {ev.venueLabel || "Venue TBD"}
            </div>
            <div className="event-overview__about__venue__org__desc__secondary">
              {ev.venueValue}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
