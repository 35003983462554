import { useState } from "react";
import { useAppState } from "../../utils/appState";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import "./Filters.css";

export default function Filters({
  categories = [],
  selectedFilters,
  defaultSelected,
}) {
  // console.log("Filters:", categories);
  const [selected, setSelected] = useState(defaultSelected);

  // console.log("Selected:", selected);
  const [, setAppState] = useAppState();
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const selectedCategory = categories[selectedCategoryIndex];
  return (
    <div className="app-filters-mobile">
      <div className="app-filters-mobile__content">
        <div className="app-filters-mobile__content__title">
          <div className="app-filters-mobile__content__title__text">
            FILTERS
          </div>
          <div
            className="app-filters-mobile__dismiss"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon style={{ stroke: "var(--light-2)", height: 14 }} />
          </div>
        </div>

        <div className="app-filters-mobile__content__body">
          <div className="app-filters-mobile__content__body__categories">
            {categories.map((c, i) => (
              <div
                key={"filter-category-" + i}
                className={
                  "app-filters-mobile__content__body__categories__item " +
                  (selectedCategoryIndex === i ? "selected" : "")
                }
                onClick={() => {
                  setSelectedCategoryIndex(i);
                }}
              >
                {c.label}
              </div>
            ))}
          </div>

          <div className="app-filters-mobile__content__body__items">
            {selectedCategory.items
              .filter(selectedCategory.itemFilter(selected))
              .map((item, i) => (
                <div
                  key={"filter-item-" + i}
                  className={
                    "app-filters-mobile__content__body__items__item " +
                    (selectedCategory.isSelected(
                      selected[selectedCategory.label],
                      item
                    )
                      ? "selected"
                      : "")
                  }
                  onClick={() => {
                    const newFilters =
                      selectedCategory.onSelect &&
                      selectedCategory.onSelect(item, selected);
                    setSelected(newFilters);
                  }}
                >
                  {item.label}
                  {selectedCategory.isSelected(
                    selected[selectedCategory.label],
                    item
                  ) && (
                    <div className="app-filters-mobile__content__body__items__item__check">
                      <CheckIcon style={{ height: "14px", fill: "white" }} />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* <div
        className="app-filters-mobile__dismiss"
        onClick={() => {
          setAppState({ modal: false });
        }}
      >
        Close
      </div> */}
    </div>
  );
}
