import "./ManageEventFixtures.css";
import { useState, Fragment } from "react";
import {
  EVENT_FIXTURES,
  UPDATE_EVENT,
  UPDATE_EVENT_PART,
  EVENT_PARTICIPANTS,
  EVENT_REGISTRATIONS,
} from "../../../api/event";
import { CREATE_FIXTURE } from "../../../api/fixture";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../../../components/Spinner";
import Loader from "../../../components/Loader";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import { ReactComponent as EyeIcon } from "../../../icons/eye.svg";
import { ReactComponent as ArrowIcon } from "../../../icons/arrow-left.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as UpDownIcon } from "../../../icons/up-down.svg";
import { useAppState } from "../../../utils/appState";
import FixtureListItem from "../../FixtureListItem";
import { Link } from "react-router-dom";
import moment from "moment";
import captureException from "../../../utils/captureException";

function EditEventPartLabel({ part, ev }) {
  const [, setAppState] = useAppState();
  const [label, setLabel] = useState(part.label);
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`parts {
    id
    type
    label
    order
    partOf {
      id
    }
  }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            parts() {
              return updateEvent.parts;
            },
          },
        });

        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventFixtures.EditEventPartLabel",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const dismiss = () => {
    setAppState({ modal: false });
  };

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Edit {part.label}
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();
              // config.onSubmit({ fields, mutate });
              if (loading || !label) return;
              updateEvent({
                variables: {
                  where: { id: ev.id },
                  data: {
                    parts: {
                      update: {
                        where: { id: part.id },
                        data: { label },
                      },
                    },
                  },
                },
              });
            }}
          >
            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">Label</div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="text"
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {loading ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  dismiss();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function SelectFixtureParticipant({ ev, onSelect, dismiss }) {
  const participantType = ev.participantType;
  const query =
    participantType === "TEAM" ? EVENT_PARTICIPANTS : EVENT_REGISTRATIONS;
  const { loading, error, data } = useQuery(query, {
    variables: { id: ev.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventFixtures.SelectFixtureParticipant",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const items =
    participantType === "TEAM"
      ? data?.event?.participants?.map((p) => p.organization) || []
      : data?.event?.registrations?.map((p) => p.profile) || [];

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div
            className="modal-form-mobile__title__icon-left"
            onClick={dismiss}
          >
            <ArrowIcon style={{ stroke: "var(--light-3" }} />
          </div>
          <div className="modal-form-mobile__title__text">
            Select participant
          </div>
        </div>

        <div
          className="modal-form-mobile__body"
          style={{ flexGrow: 1, overflow: "auto" }}
        >
          {items.map((item) => (
            <div
              className="edit-event-registration-mobile__team-selection-item"
              onClick={() => {
                onSelect(item);
                dismiss();
              }}
            >
              <div className="edit-event-registration-mobile__team-selection-item__img"></div>

              <div className="edit-event-registration-mobile__team-selection-item__text">
                <div className="edit-event-registration-mobile__team-selection-item__text__primary">
                  {item.name}
                </div>

                <div className="edit-event-registration-mobile__team-selection-item__text__secondary">
                  @{item.handle}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function AddFixture({ ev, participantType, part }) {
  const [, setAppState] = useAppState();
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [scheduledStart, setScheduledStart] = useState("");
  const [participantSelector, setParticipantSelector] = useState(false);

  const dismiss = () => {
    setAppState({ modal: false });
  };

  const [createFixture, { loading }] = useMutation(CREATE_FIXTURE, {
    update: (cache, { data: { createFixture } }) => {
      const q = cache.readQuery({
        query: EVENT_FIXTURES,
        variables: { id: ev.id },
      });

      cache.writeQuery({
        query: EVENT_FIXTURES,
        variables: { id: ev.id },
        data: {
          event: {
            ...q.event,
            fixtures: [...q.event.fixtures, createFixture],
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventFixtures.AddFixture",
          mutation: "CREATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  if (participantSelector) {
    return (
      <SelectFixtureParticipant
        ev={ev}
        onSelect={
          participantSelector === "home"
            ? (item) => {
                setHomeTeam(item);
              }
            : (item) => {
                setAwayTeam(item);
              }
        }
        dismiss={() => {
          setParticipantSelector("");
        }}
      />
    );
  }

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Add a fixture to {part?.label || "Event"}
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();

              if (!homeTeam || !awayTeam) return;

              createFixture({
                variables: {
                  data: {
                    event: { connect: { id: ev.id } },
                    eventPart: { connect: { id: part.id } },
                    ...(scheduledStart
                      ? { scheduledStart: moment(scheduledStart).toISOString() }
                      : {}),
                    periods: {
                      create: [
                        {
                          order: 0,
                          label: "First Half",
                        },
                        {
                          order: 1,
                          label: "Second Half",
                        },
                      ],
                    },
                    roles: {
                      create: [
                        {
                          order: 0,
                          type: { connect: { name: "PARTICIPANT" } },
                          ...(participantType === "TEAM"
                            ? { organization: { connect: { id: homeTeam.id } } }
                            : { profile: { connect: { id: homeTeam.id } } }),
                        },
                        {
                          order: 1,
                          type: { connect: { name: "PARTICIPANT" } },
                          ...(participantType === "TEAM"
                            ? { organization: { connect: { id: awayTeam.id } } }
                            : { profile: { connect: { id: awayTeam.id } } }),
                        },
                      ],
                    },
                  },
                },
              });
              // config.onSubmit({ fields, mutate });
            }}
          >
            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Home Team
              </div>

              <div
                className="modal-form-mobile__form__group__input"
                onClick={() => {
                  setParticipantSelector("home");
                }}
              >
                <div className="modal-form-mobile__form__group__input__select">
                  <div className="modal-form-mobile__form__group__input__select__value">
                    {homeTeam?.name || "Select home team"}
                  </div>
                  <div className="modal-form-mobile__form__group__input__select__icon">
                    <UpDownIcon
                      style={{ height: "12px", fill: "var(--light-3)" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Away Team
              </div>

              <div
                className="modal-form-mobile__form__group__input"
                onClick={() => {
                  setParticipantSelector("away");
                }}
              >
                <div className="modal-form-mobile__form__group__input__select">
                  <div className="modal-form-mobile__form__group__input__select__value">
                    {awayTeam?.name || "Select away team"}
                  </div>
                  <div className="modal-form-mobile__form__group__input__select__icon">
                    <UpDownIcon
                      style={{ height: "12px", fill: "var(--light-3)" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Scheduled Start
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="datetime-local"
                  value={scheduledStart}
                  onChange={(e) => {
                    setScheduledStart(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {loading ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  dismiss();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function AddEventPart({ eventId, order, partOf }) {
  const [, setAppState] = useAppState();
  const [label, setLabel] = useState("");
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`parts {
    id
    type
    label
    order
    partOf {
      id
    }
  }`),
    {
      update: () => {
        // setName("");
        // setForm(false);
        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventFixtures.AddEventPart",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const dismiss = () => {
    setAppState({ modal: false });
  };

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Add a stage to {partOf?.label || "Event"}
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();
              // config.onSubmit({ fields, mutate });
              if (loading || !label) return;
              updateEvent({
                variables: {
                  where: { id: eventId },
                  data: {
                    parts: {
                      create: {
                        order: order || 0,
                        type: "Stage",
                        label: label,
                        ...(partOf?.id
                          ? { partOf: { connect: { id: partOf?.id } } }
                          : {}),
                      },
                    },
                  },
                },
              });
            }}
          >
            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">Label</div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="text"
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {loading ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  dismiss();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function EventPart({ ev, partId, dismiss }) {
  const [, setAppState] = useAppState();
  const part = ev?.parts?.find((p) => p.id === partId);
  const fixtures = partId
    ? ev?.fixtures?.filter((f) => f?.eventPart?.id === partId)
    : [];

  return (
    <div className="manage-event-fixtures-mobile__event-part">
      <div className="manage-event-fixtures-mobile__event-part__title">
        <div
          className="manage-event-fixtures-mobile__event-part__title__back"
          onClick={dismiss}
        >
          <ArrowIcon className="icon" />
        </div>

        <div className="manage-event-fixtures-mobile__event-part__title__text">
          {part.label}
        </div>

        <div className="manage-event-fixtures-mobile__event-part__title__links">
          <div
            className="manage-event-fixtures-mobile__event-part__title__links__link"
            onClick={() => {
              setAppState({
                modal: (
                  <AddFixture
                    ev={ev}
                    participantType={ev.participantType}
                    part={part}
                  />
                ),
              });
            }}
          >
            Add Fixture
          </div>

          <div
            className="manage-event-fixtures-mobile__event-part__title__links__link"
            style={{ color: "var(--light-4)" }}
            onClick={() => {
              setAppState({
                modal: <EditEventPartLabel ev={ev} part={part} />,
              });
            }}
          >
            Edit
          </div>
        </div>
      </div>

      <div className="manage-event-fixtures-mobile__event-part__fixtures">
        {fixtures.map((f) => (
          <Link to={"/app/fixture/" + f.id}>
            <FixtureListItem fixture={f} />
          </Link>
        ))}
      </div>
    </div>
  );
}

// function EventPart() {}

function EventPartIndexItem({ ev, part, isRoot, setViewGroup, prev, next }) {
  const [, setAppState] = useAppState();
  const [reorderDirection, setReorderDirection] = useState("");
  const [expand, setExpand] = useState(false);
  const [updateEventPart, { loading }] = useMutation(UPDATE_EVENT_PART, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventFixtures.EventPartIndexItem",
          mutation: "UPDATE_EVENT_PART",
        },
      });
      console.log(error);
    },
  });

  const parts = ev?.parts || [];
  const fixtures = ev?.fixtures || [];
  const subparts = part
    ? parts
        .filter((p) => p.partOf?.id === part?.id)
        .sort((a, b) => a.order - b.order)
    : [];

  const f = fixtures.filter((f) => f?.eventPart?.id === part?.id);

  console.log(part);

  return (
    <div
      className={
        "manage-event-fixtures-mobile__index__item " + (isRoot ? "root" : "")
      }
    >
      <div className="manage-event-fixtures-mobile__index__item__header">
        <div
          className={
            "manage-event-fixtures-mobile__index__item__header__collapse " +
            (expand ? "open" : "")
          }
          onClick={() => {
            setExpand((prev) => !prev);
          }}
        >
          <ChevronDownIcon className="icon" />
        </div>

        <div className="manage-event-fixtures-mobile__index__item__header__title">
          {part.label}
        </div>

        <div className="manage-event-fixtures-mobile__index__item__header__options">
          {!subparts?.length && (
            <div
              className="manage-event-fixtures-mobile__index__item__header__options__option"
              onClick={() => {
                setViewGroup(part.id);
              }}
            >
              <EyeIcon className="view-icon" />
            </div>
          )}

          <div
            className="manage-event-fixtures-mobile__index__item__header__options__option"
            onClick={() => {
              if (!prev) return;

              setReorderDirection("down");

              updateEventPart({
                variables: {
                  where: { id: part.id },
                  data: {
                    order: part.order - 1,
                  },
                },
              });

              updateEventPart({
                variables: {
                  where: { id: prev.id },
                  data: {
                    order: part.order,
                  },
                },
              });
            }}
          >
            {prev && (
              <Fragment>
                {loading && reorderDirection === "down" ? (
                  <Spinner size={12} />
                ) : (
                  <ArrowIcon className="icon arrow-up" />
                )}
              </Fragment>
            )}
          </div>

          <div
            className="manage-event-fixtures-mobile__index__item__header__options__option"
            onClick={() => {
              if (!next) return;

              setReorderDirection("up");

              updateEventPart({
                variables: {
                  where: { id: part.id },
                  data: {
                    order: part.order + 1,
                  },
                },
              });

              updateEventPart({
                variables: {
                  where: { id: next.id },
                  data: {
                    order: part.order,
                  },
                },
              });
            }}
          >
            {next && (
              <Fragment>
                {loading && reorderDirection === "up" ? (
                  <Spinner size={12} />
                ) : (
                  <ArrowIcon className="icon arrow-down" />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>

      {expand && (
        <div className="manage-event-fixtures-mobile__index__item__subparts">
          {subparts.map((p, i) => (
            <EventPartIndexItem
              key={p.id}
              ev={ev}
              part={p}
              isRoot={false}
              setViewGroup={setViewGroup}
              prev={!!i && subparts[i - 1]}
              next={i < subparts.length - 1 && subparts[i + 1]}
            />
          ))}

          {!f.length && (
            <div className="manage-event-fixtures-mobile__index__item">
              <div className="manage-event-fixtures-mobile__index__item__header">
                <div
                  className="manage-event-fixtures-mobile__index__item__header__title"
                  style={{ color: "var(--blue-main)" }}
                  onClick={() => {
                    setAppState({
                      modal: (
                        <AddEventPart
                          eventId={ev.id}
                          partOf={part}
                          order={subparts.length}
                        />
                      ),
                    });
                  }}
                >
                  Add part to {part.label}
                </div>
              </div>
            </div>
          )}

          {!subparts?.length && (
            <div className="manage-event-fixtures-mobile__index__item">
              <div className="manage-event-fixtures-mobile__index__item__header">
                <div
                  className="manage-event-fixtures-mobile__index__item__header__title"
                  style={{ color: "var(--blue-main)" }}
                  onClick={() => {
                    setViewGroup(part.id);
                  }}
                >
                  Add/View {part.label} fixtures
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function EventPartIndex({ ev, setViewGroup }) {
  const [, setAppState] = useAppState();
  const parts = ev?.parts || [];

  const sortedParts = parts
    .filter((p) => !p.partOf)
    .sort((a, b) => a.order - b.order);

  return (
    <div className="manage-event-fixtures-mobile__index">
      {sortedParts.map((part, i) => (
        <EventPartIndexItem
          key={part.id}
          ev={ev}
          part={part}
          isRoot={true}
          setViewGroup={setViewGroup}
          prev={!!i && sortedParts[i - 1]}
          next={i < sortedParts.length - 1 && sortedParts[i + 1]}
        />
      ))}

      <div className="manage-event-fixtures-mobile__index__item root">
        <div className="manage-event-fixtures-mobile__index__item__header">
          <div
            className={
              "manage-event-fixtures-mobile__index__item__header__collapse "
            }
          ></div>

          <div
            className="manage-event-fixtures-mobile__index__item__header__title"
            style={{ color: "var(--blue-main)" }}
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPart
                    eventId={ev.id}
                    partOf={false}
                    order={sortedParts.length}
                  />
                ),
              });
            }}
          >
            Add a stage
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ManageEventFixtures({ id }) {
  const [viewGroup, setViewGroup] = useState(false);
  const { loading, error, data } = useQuery(EVENT_FIXTURES, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventFixtures",
        query: "EVENT_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const parts = ev?.parts || [];

  if (!parts.length) {
    return;
  }

  if (viewGroup) {
    return (
      <div className="manage-event-fixtures-mobile">
        <EventPart
          ev={ev}
          partId={viewGroup}
          dismiss={() => {
            setViewGroup(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="manage-event-fixtures-mobile">
      {!parts.length && (
        <div className="no-data">
          <div className="no-data__line">
            Add a stage to start adding fixtures.
          </div>

          <div className="link">Add a stage</div>
        </div>
      )}

      {parts.length && <EventPartIndex ev={ev} setViewGroup={setViewGroup} />}
    </div>
  );
}
