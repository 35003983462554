// V1
import "./PageCard.css";
import Card from "../../Card";
import Button from "../../Button";
import { ReactComponent as PinIcon } from "../../../icons/pin.svg";
import { ReactComponent as TagIcon } from "../../../icons/tag.svg";
import { Link } from "react-router-dom";
import { CREATE_FOLLOW, UNFOLLOW } from "../../../api/follow";
import { useMutation } from "@apollo/client";
import Loader from "../../Loader";
import { ORGANIZATIONS } from "../../../api/organization";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function PageCardFollowButton({ id, isFollowedByUser, variables }) {
  // console.log(isFollowedByUser);
  const [createFollow, { loading: submitting }] = useMutation(CREATE_FOLLOW, {
    update: (cache, { data: { createFollow } }) => {
      if (createFollow) {
        const q = cache.readQuery({
          query: ORGANIZATIONS,
          variables,
        });

        const listIndex = q.organizations?.findIndex((p) => p.id === id);

        if (listIndex && listIndex > -1) {
          const newList = [...q.organizations];
          newList[listIndex] = {
            ...q.organizations[listIndex],
            isFollowedByUser: createFollow,
          };

          cache.writeQuery({
            query: ORGANIZATIONS,
            variables,
            data: { organizations: newList },
          });
        }
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PageCard.PageCardFollowButton",
          mutation: "CREATE_FOLLOW",
        },
      });
      console.log(error);
    },
  });

  const [unfollow, { loading: unfollowing }] = useMutation(UNFOLLOW, {
    update: (cache, { data: { unfollow } }) => {
      if (unfollow) {
        const q = cache.readQuery({
          query: ORGANIZATIONS,
          variables,
        });

        const listIndex = q.organizations?.findIndex((p) => p.id === id);

        if (listIndex && listIndex > -1) {
          const newList = [...q.organizations];
          newList[listIndex] = {
            ...q.organizations[listIndex],
            isFollowedByUser: null,
          };

          cache.writeQuery({
            query: ORGANIZATIONS,
            variables,
            data: { organizations: newList },
          });
        }
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PageCard.PageCardFollowButton",
          mutation: "UNFOLLOW",
        },
      });
      console.log(error);
    },
  });

  const handleButtonClick = (e) => {
    if (submitting || unfollowing) {
      return;
    }

    if (isFollowedByUser?.confirmed) {
      unfollow({
        variables: { id: isFollowedByUser.id },
      });
      return;
    }

    createFollow({
      variables: {
        followingId: id,
        followingType: "organization",
      },
    });
  };

  return (
    <Button
      className={`small secondary  ${
        isFollowedByUser ? "neutral" : "blue"
      }  page-follow-button  ${submitting || unfollowing ? "submitting" : ""}`}
      onClick={handleButtonClick}
    >
      {submitting || unfollowing ? (
        <Loader theme={"small " + (submitting ? "blue" : "red")} />
      ) : !isFollowedByUser ? (
        "Support"
      ) : (
        <span>
          <span className="show-on-hover">Stop supporting</span>
          <span className="hide-on-hover">Supporting</span>
        </span>
      )}
      {/* {isFollowedByUser ? "Following" : "Follow"} */}
    </Button>
  );
}

function PageCard({ organization, variables }) {
  return (
    <Card className="directory-page-card">
      <div className={"image " + (organization.profilePhoto ? "" : "")}>
        {organization.profilePhoto && (
          <Avatar media={organization.profilePhoto} />
          // <img src={makeFileUrl(organization.profilePhoto)} alt={""} />
        )}
      </div>
      <div className="meta">
        <div className="head">
          <div className="text">
            <Link to={"/app/page/" + organization.id} className="h6">
              {organization.name}
            </Link>
            <div className="secondary-text">
              <PinIcon className="meta-icon" />
              {organization.cities.map((c) => c.value).join(", ") ||
                "Unknown location"}
            </div>
            <div className="secondary-text">
              <TagIcon className="meta-icon" />
              {(organization.types || [])
                .slice(0, 2)
                .map((t) => t.name)
                .join(", ")}
              {organization.types?.length === 3
                ? ", " + organization.types[2].name
                : organization.types?.length > 3
                ? " + " + (organization.types.length - 2) + " other categories"
                : ""}

              {!organization.types?.length && "No categories added"}
            </div>
          </div>

          <PageCardFollowButton
            id={organization.id}
            isFollowedByUser={organization.isFollowedByUser}
            variables={variables}
          />
        </div>
      </div>
    </Card>
  );
}

export default PageCard;
