import "./PagesList.css";
import { useQuery } from "@apollo/client";
import { USER_PROFILE } from "../../../api/user";
import { PAGES } from "../../../api/profile";
import Placeholder from "../../../components/Placeholder";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar/Avatar";
import captureException from "../../../utils/captureException";

function PagesList({ id }) {
  const { loading, error, data } = useQuery(PAGES, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PagesList",
        query: "PAGES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="home-page-mobile__profile-pages-card__list">
        <div className="home-page-mobile__profile-pages-card__list__item">
          <div className="home-page-mobile__profile-pages-card__list__item__image">
            <Placeholder
              style={{ height: "100%", width: "100%" }}
              pulse={true}
            />
          </div>
        </div>

        <div className="home-page-mobile__profile-pages-card__list__item">
          <div className="home-page-mobile__profile-pages-card__list__item__image">
            <Placeholder
              style={{ height: "100%", width: "100%" }}
              pulse={true}
            />
          </div>
        </div>

        <div className="home-page-mobile__profile-pages-card__list__item">
          <div className="home-page-mobile__profile-pages-card__list__item__image">
            <Placeholder
              style={{ height: "100%", width: "100%" }}
              pulse={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const pages = data.pages;
  return (
    <div className="home-page-mobile__profile-pages-card__list">
      {pages.map((p) => (
        <Link
          to={"/app/page/" + p.organization?.id}
          key={"home-user-pages-" + p.id}
          className="home-page-mobile__profile-pages-card__list__item"
        >
          <div className="home-page-mobile__profile-pages-card__list__item__image">
            {p.organization?.profilePhoto && (
              <Avatar media={p.organization.profilePhoto} />
            )}
          </div>
          <div className="home-page-mobile__profile-pages-card__list__item__name">
            {p.organization?.name}
          </div>
        </Link>
      ))}
    </div>
  );
}

export default function PagesListWrapper() {
  const { loading, error, data } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PagesList.PagesListWrapper",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="home-page-mobile__profile-pages-card">
        <div className="home-page-mobile__profile-pages-card__title">
          <Placeholder style={{ width: "60px", height: "10px" }} pulse={true} />
        </div>
        <div className="home-page-mobile__profile-pages-card__list">
          <div className="home-page-mobile__profile-pages-card__list__item">
            <div className="home-page-mobile__profile-pages-card__list__item__image" />
          </div>

          <div className="home-page-mobile__profile-pages-card__list__item">
            <div className="home-page-mobile__profile-pages-card__list__item__image" />
          </div>

          <div className="home-page-mobile__profile-pages-card__list__item">
            <div className="home-page-mobile__profile-pages-card__list__item__image" />
          </div>
        </div>
      </div>
    );
  }

  const profileId = data?.userProfile.id;

  return (
    <div className="home-page-mobile__profile-pages-card">
      <div className="home-page-mobile__profile-pages-card__title">
        Your Pages
      </div>
      <PagesList id={profileId} />
    </div>
  );
}
