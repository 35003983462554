import "./VideoCard.css";
import YouTubeVideo from "../../components/YouTubeVideo";
import Placeholder from "../../components/Placeholder";
import makeFileUrl from "../../utils/makeFileUrl";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { MEDIA } from "../../api/media";
import Avatar from "../../components/Avatar";
import captureException from "../../utils/captureException";
import FixtureListItem from "../FixtureListItem/FixtureListItem";

function VideoCardSource({ media }) {
  const source = media.source;

  if (source.fixture) {
    const fixture = source.fixture;
    const participants = fixture.roles
      .filter((r) => r.type.name === "PARTICIPANT")
      .sort((a, b) => a.order - b.order);
    const homeTeam = participants[0];
    const awayTeam = participants[1];
    const homeScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[0].organization?.id
    );

    const awayScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[1].organization?.id
    );

    return (
      <FixtureListItem
        fixture={fixture}
        hideDate={true}
        className="video-card-mobile-fixture-source"
      />
    );
    return (
      <Link to={"/app/fixture/" + fixture.id} className="video-info__source">
        <FixtureListItem
          fixture={fixture}
          hideDate={true}
          className="video-card-fixture-source"
        />
        {/* <div className="video-info__source__fixture">
          <div className="video-info__source__fixture__team home">
            <div className="video-info__source__fixture__team__image">
              {homeTeam.organization?.profilePhoto && (
                <Avatar media={homeTeam.organization.profilePhoto} />
              )}
            </div>

            <div className="video-info__source__fixture__team__title">
              {homeTeam?.organization?.name}
            </div>

            <div className="video-info__source__fixture__team__score">
              {homeScore?.value}
            </div>
          </div>
          <div className="video-info__source__fixture__seperator">-</div>
          <div className="video-info__source__fixture__team home">
            <div className="video-info__source__fixture__team__image">
              {awayTeam.organization?.profilePhoto && (
                <Avatar media={awayTeam.organization.profilePhoto} />
              )}
            </div>
            <div className="video-info__source__fixture__team__title">
              {awayTeam?.organization?.name}
            </div>
            <div className="video-info__source__fixture__team__score">
              {awayScore?.value}
            </div>
          </div>
        </div> */}
      </Link>
    );
  } else if (source.event) {
    const event = source.event;
    return (
      <Link to={"/app/event/" + event.id} className="video-info__source">
        <div className="video-info__source__image">
          {event?.profilePhoto && <Avatar media={event.profilePhoto} />}
        </div>
        <div className="video-info__source__text">
          <div className="video-info__source__text__primary">{event.name}</div>
        </div>
      </Link>
    );
  } else if (source.organization) {
    const organization = source.organization;
    return (
      <Link
        to={"/app/organization/" + organization.id}
        className="video-info__source"
      >
        <div className="video-info__source__image">
          {organization?.profilePhoto && (
            <Avatar media={organization.profilePhoto} />
          )}
        </div>
        <div className="video-info__source__text">
          <div className="video-info__source__text__primary">
            {organization.name}
          </div>
          <div className="video-info__source__text__secondary">
            @ {organization.handle}
          </div>
        </div>
      </Link>
    );
  } else if (source.profile) {
    const profile = source.profile;
    return (
      <Link to={"/app/profile/" + profile.id} className="video-info__source">
        <div className="video-info__source__image">
          {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
        </div>
        <div className="video-info__source__text">
          <div className="video-info__source__text__primary">
            {profile.name}
          </div>
          <div className="video-info__source__text__secondary">
            @ {profile.handle}
          </div>
        </div>
      </Link>
    );
  } else {
    return <div className="video-info__source"></div>;
  }
}

function VideoCardDescription({ media }) {
  const [truncated, setTruncated] = useState(
    (media.contentLength || 0) > 50 ? true : false
  );

  if (!media.description) {
    return null;
  }

  return (
    <div className="video-card-mobile__description">
      <ReactQuill
        id={"media-description-" + media.id}
        value={truncated ? media.truncatedDescription : media.description}
        readOnly={true}
        modules={{ toolbar: false }}
      />
      {truncated ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(false);
          }}
        >
          See more
        </div>
      ) : media.contentLength > 50 ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(true);
          }}
        >
          See less
        </div>
      ) : (
        false
      )}
    </div>
  );
}

function VideoCardMeta({ id }) {
  const { loading, error, data } = useQuery(MEDIA, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "VideoCard.VideoCardMets",
        query: "MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="video-info">
        <div className="video-info__source">
          <div className="video-info__source__image"></div>
          <div className="video-info__source__text">
            <div className="video-info__source__text__primary">
              <Placeholder
                style={{ height: "12px", width: "80px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
        <div className="video-info__desc">
          <Placeholder style={{ height: "10px", width: "100%" }} pulse={true} />
          <Placeholder
            style={{ height: "10px", width: "80%", marginTop: "8px" }}
            pulse={true}
          />
        </div>
      </div>
    );
  }

  const media = data.media;
  return (
    <Fragment>
      <VideoCardSource media={media} />
      <VideoCardDescription media={media} />
    </Fragment>
  );
}

export default function VideoCard({ media, videoHeight = 320 }) {
  return (
    <div className="video-card-mobile">
      <VideoCardMeta id={media.id} />
      <div
        className="video-card-mobile__video"
        style={{ height: videoHeight + "px" }}
      >
        {media.isExternal ? (
          <YouTubeVideo filename={media.filename} />
        ) : (
          <video controls controlsList="nodownload">
            <source src={makeFileUrl(media)} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
}
