import "./EditOverItem.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as PlayIcon } from "../../../icons/play.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import Card from "../../../components/Card";
import NewDropdown from "../../../components/NewDropdown";
import { Fragment, useState } from "react";
import ClickOutside from "../../../components/ClickOutside";
import {
  DELETE_FIXTURE_STAT,
  DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION,
  UPDATE_STAT,
  CREATE_STAT,
} from "../../../api/fixture";
import { FIXTURE } from "../../../api/functions";
import { useMutation } from "@apollo/client";
import captureException from "../../../utils/captureException";
import Spinner from "../../../components/Spinner";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import SelectPlayer from "../SelectPlayer";
import makeStat from "../utils/makeStat";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import SelectDismissedBatsman from "../SelectDismissedBatsman";
import { client } from "../../../utils/client";

function ConfirmDeleteBall({ overItem }) {
  const [{ setOverlay, fixtureId, selectedPeriod }] = useCricketScoringState();
  const {
    runsScored,
    ballsBowled,
    ballsFaced,
    extras,
    wicket,
    wicketTaken,
    runsConceded,
    fielding,
  } = overItem;

  const [deleteFixtureStat, { loading }] = useMutation(DELETE_FIXTURE_STAT, {
    update: async (cache, { data: { deleteFixtureStat } }) => {
      // Update the teams score & wickets
      cache.modify({
        id: "CricketLiveScoreItem:" + runsScored.organization.id,
        fields: {
          runs(current) {
            const totalExtras = extras.reduce((accum, curr) => {
              accum = accum + curr.value;
              return accum;
            }, 0);
            return current - runsScored.value - totalExtras;
          },
          wickets(current) {
            if (wicket && wicket.statType.name !== "Retired Hurt") {
              return current - 1;
            } else {
              return current;
            }
          },
        },
      });

      // Update the innings score & wickets
      const updatedPeriodScores = await client.query({
        query: FIXTURE({
          selectionSet: `                    
          cricketLiveScoring{
            id
            periodScores{
              id
              runs
              wickets
              overs
            }        
          }
      `,
        }),
        variables: {
          id: fixtureId,
        },
        fetchPolicy: "network-only",
      });
      // console.log("Updated period score:", updatedPeriodScores);
      // Update period scores
      cache.modify({
        id: "CricketLiveScoringStats:" + fixtureId,
        fields: {
          periodScores(current) {
            return updatedPeriodScores.data.fixture.cricketLiveScoring
              .periodScores;
          },
        },
      });

      // Update the innings score & wickets
      // cache.modify({
      //   id: "CricketPeriodScoreItem:" + selectedPeriod.id,
      //   fields: {
      //     runs(current) {
      //       const totalExtras = extras.reduce((accum, curr) => {
      //         accum = accum + curr.value;
      //         return accum;
      //       }, 0);
      //       return current - runsScored.value - totalExtras;
      //     },
      //     wickets(current) {
      //       if (wicket && wicket.statType.name !== "Retired Hurt") {
      //         return current - 1;
      //       } else {
      //         return current;
      //       }
      //     },
      //     overs(current) {
      //       // const isWide = createCricketStat.extras?.find(
      //       //   (x) => x.statType.name === "Wide"
      //       // );

      //       // const isNoBall = createCricketStat.extras?.find(
      //       //   (x) => x.statType.name === "No Ball"
      //       // );

      //       // if (isWide || isNoBall) {
      //       //   return currentOvers;
      //       // }

      //       // if (createCricketStat.ballsBowled.value > currentOvers) {
      //       //   return createCricketStat.ballsBowled.value;
      //       // } else {
      //       //   return currentOvers;
      //       // }

      //       // console.log("Current overs:", current);
      //       // console.log("Balls Bowled:", ballsBowled);

      //       const isWide = extras?.find((x) => x.statType.name === "Wide");
      //       const isNoBall = extras?.find((x) => x.statType.name === "No Ball");

      //       if (isWide || isNoBall) {
      //         return current;
      //       } else {
      //         if (ballsBowled.value < current) {
      //           return current;
      //         } else {
      //           return current - 0.1;
      //         }
      //       }
      //     },
      //   },
      // });

      // Update the batsmans runs scored, balls Faced, fours & sixes
      cache.modify({
        id: "CricketBattingSummaryItem:" + runsScored.profile.id,
        fields: {
          runs(current) {
            return current - runsScored.value;
          },
          balls(current) {
            const isWide = extras?.find((x) => x.statType.name === "Wide");

            if (isWide) {
              return current;
            } else {
              return current - 1;
            }
          },
          fours(current) {
            if (runsScored.value === 4) {
              return current - 1;
            } else {
              return current;
            }
          },
          sixes(current) {
            if (runsScored.value === 6) {
              return current - 1;
            } else {
              return current;
            }
          },
        },
      });

      // Update the bowlers balls bowled, wickets, extras and runs conceded
      cache.modify({
        id: "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
        fields: {
          runs(current) {
            return current - runsScored.value;
          },
          balls(current) {
            const isWide = extras?.find((x) => x.statType.name === "Wide");
            const isNoBall = extras?.find((x) => x.statType.name === "No Ball");

            if (isWide || isNoBall) {
              return current;
            } else {
              return current - 1;
            }
          },
          wickets(current) {
            if (
              wicketTaken &&
              wicketTaken.profile.id === ballsBowled.profile.id
            ) {
              return current - 1;
            } else {
              return current;
            }
          },
          extras(current) {
            const totalExtras = extras.reduce((accum, curr) => {
              accum = accum + curr.value;
              return accum;
            }, 0);
            return current - totalExtras;
          },
        },
      });

      // Remove the over item
      cache.modify({
        id: "CricketLiveScoringStats:" + fixtureId,
        fields: {
          overStats(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => ballsBowled.id !== readField("id", ref)
            );
          },
        },
      });

      setOverlay(false);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.DeleteOverItem",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });
  return (
    <div
      className="ScoringApp-cricket__scoring__modal-form"
      style={{ height: "auto" }}
    >
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        Confirm ball deletion
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={() => {
            setOverlay(false);
          }}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__error-msg">
        Are you sure you want to delete this ball? Please make sure to manually
        update any ball numbers that may be affected.
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__options">
        <Button
          className="medium ScoringApp-cricket__scoring__modal-form__options__option cancel"
          onClick={() => {
            setOverlay(false);
          }}
        >
          No, Cancel
        </Button>

        <Button
          className="primary red medium ScoringApp-cricket__scoring__modal-form__options__option"
          onClick={() => {
            if (loading) return;
            deleteFixtureStat({
              variables: {
                id: runsScored.id,
              },
            });
          }}
        >
          {loading ? <Loader theme="small white" /> : "Yes, delete this ball"}
        </Button>
      </div>
    </div>
  );
}

function ConfirmAddWicket({
  wicket,
  fielder,
  batsman,
  ballsBowled,
  runsScored,
}) {
  const [{ setOverlay, statTypes, eventId, fixtureId, selectedPeriod }] =
    useCricketScoringState();
  //   const loading = false;
  const [mutate, { loading }] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.Tag",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [deleteStat] = useMutation(DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });
  return (
    <div
      className="ScoringApp-cricket__scoring__modal-form"
      style={{ height: "auto" }}
    >
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        Add Wicket Confirmation
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={() => {
            setOverlay(false);
          }}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__error-msg">
        Are you sure you want to add the following wicket to this ball?
        <div>
          <span className="bold" style={{ fontWeight: "700" }}>
            Wicket Type:
          </span>{" "}
          {wicket}
        </div>
        {wicket === "Caught" && (
          <div>
            <span className="bold" style={{ fontWeight: "700" }}>
              Catch:
            </span>{" "}
            {fielder.name}
          </div>
        )}
        {wicket === "Run Out" && (
          <Fragment>
            <div>
              <span className="bold" style={{ fontWeight: "700" }}>
                Fielder:
              </span>{" "}
              {fielder.name}
            </div>
            <div>
              <span className="bold" style={{ fontWeight: "700" }}>
                Dismissed batsman:
              </span>{" "}
              {batsman.name}
            </div>
          </Fragment>
        )}
        {wicket === "Stumped" && (
          <div>
            <span className="bold" style={{ fontWeight: "700" }}>
              Wicketkeeper:
            </span>{" "}
            {fielder.name}
          </div>
        )}
        {(wicket === "Retired" || wicket === "Retired Hurt") && (
          <div>
            <span className="bold" style={{ fontWeight: "700" }}>
              Batsman:
            </span>{" "}
            {batsman.name}
          </div>
        )}
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__options">
        <Button
          className="medium ScoringApp-cricket__scoring__modal-form__options__option cancel"
          onClick={() => {
            setOverlay(false);
          }}
        >
          Cancel
        </Button>

        <Button
          className="primary green medium ScoringApp-cricket__scoring__modal-form__options__option"
          onClick={() => {
            if (loading) return;
            if (!wicket) return;

            // wicket stat
            mutate({
              variables: {
                data: {
                  ...makeStat({
                    eventId,
                    fixtureId,
                    fixturePeriodId: selectedPeriod.id,
                    organization: runsScored.organization,
                    profile:
                      wicket === "Run Out" || wicket === "Retired"
                        ? batsman
                        : runsScored.profile,
                    statType: statTypes.find((s) => s.name === wicket),
                    value: 1,
                  }),
                  relationshipsTo: {
                    create: [{ from: { connect: { id: runsScored.id } } }],
                  },
                },
              },
              update: async (cache, { data: { createStat } }) => {
                // Add wicket to over item
                cache.modify({
                  id: "OverItem:" + ballsBowled.id,
                  fields: {
                    wicket() {
                      return createStat;
                    },
                  },
                });

                // Increment team wickets
                cache.modify({
                  id: "CricketLiveScoreItem:" + runsScored.organization.id,
                  fields: {
                    wickets(current) {
                      if (createStat.statType.name === "Retired Hurt") {
                        return current;
                      } else {
                        return current + 1;
                      }
                    },
                  },
                });

                // Increment innings wickets
                // Update the innings score & wickets
                const updatedPeriodScores = await client.query({
                  query: FIXTURE({
                    selectionSet: `                    
                        cricketLiveScoring{
                          id
                          periodScores{
                            id
                            runs
                            wickets
                            overs
                          }        
                        }
                    `,
                  }),
                  variables: {
                    id: fixtureId,
                  },
                  fetchPolicy: "network-only",
                });
                // console.log("Updated period score:", updatedPeriodScores);
                // Update period scores
                cache.modify({
                  id: "CricketLiveScoringStats:" + fixtureId,
                  fields: {
                    periodScores(current) {
                      return updatedPeriodScores.data.fixture.cricketLiveScoring
                        .periodScores;
                    },
                  },
                });

                // Create wicket taken stat if necessary
                if (
                  wicket !== "Run Out" &&
                  wicket !== "Retired" &&
                  wicket !== "Retired Hurt"
                ) {
                  mutate({
                    variables: {
                      data: {
                        ...makeStat({
                          eventId,
                          fixtureId,
                          fixturePeriodId: selectedPeriod.id,
                          organization: ballsBowled.organization,
                          profile: ballsBowled.profile,
                          statType: statTypes.find(
                            (s) => s.name === "Wicket Taken"
                          ),
                          value: 0,
                        }),
                        relationshipsTo: {
                          create: [
                            { from: { connect: { id: createStat.id } } },
                          ],
                        },
                      },
                    },
                    update: (cache, { data: { createStat } }) => {
                      // Increment bowler wickets
                      cache.modify({
                        id:
                          "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                        fields: {
                          wickets(current) {
                            return current + 1;
                          },
                        },
                      });

                      // Add wicket taken to over item
                      cache.modify({
                        id: "OverItem:" + ballsBowled.id,
                        fields: {
                          wicketTaken() {
                            return createStat;
                          },
                        },
                      });
                    },
                  });
                }

                // Create fielding stat if necessary
                if (
                  wicket !== "Bowled" &&
                  wicket !== "LBW" &&
                  wicket !== "Retired" &&
                  wicket !== "Retired Hurt"
                ) {
                  mutate({
                    variables: {
                      data: {
                        ...makeStat({
                          eventId,
                          fixtureId,
                          fixturePeriodId: selectedPeriod.id,
                          organization: ballsBowled.organization,
                          profile:
                            wicket === "Caught & Bowled"
                              ? ballsBowled.profile
                              : fielder,
                          statType: statTypes.find((s) => {
                            const fieldingStatName =
                              wicket === "Caught" ||
                              wicket === "Caught & Bowled"
                                ? "Catch"
                                : wicket === "Run Out"
                                ? "Run Out"
                                : "Stumping";

                            return s.name === fieldingStatName;
                          }),

                          value: 0,
                        }),
                        relationshipsTo: {
                          create: [
                            { from: { connect: { id: createStat.id } } },
                          ],
                        },
                      },
                    },
                    update: (cache, { data: { createStat } }) => {
                      // Add fielding to over item
                      cache.modify({
                        id: "OverItem:" + ballsBowled.id,
                        fields: {
                          fielding() {
                            return createStat;
                          },
                        },
                      });
                    },
                  });
                }
                setOverlay(false);
              },
            });
          }}
        >
          {loading ? <Loader theme="small white" /> : "Add Wicket"}
        </Button>
      </div>
    </div>
  );
}

function AddWicket({ ballsBowled, runsScored }) {
  const [{ setOverlay }] = useCricketScoringState();

  const wicketTypes = [
    {
      label: "Bowled",
      onClick: () => {
        // Add wicket
        setOverlay(
          <ConfirmAddWicket
            wicket={"Bowled"}
            ballsBowled={ballsBowled}
            runsScored={runsScored}
          />
        );
      },
    },
    {
      label: "LBW",
      onClick: () => {
        // Add wicket
        setOverlay(
          <ConfirmAddWicket
            wicket={"LBW"}
            ballsBowled={ballsBowled}
            runsScored={runsScored}
          />
        );
      },
    },
    {
      label: "Caught",
      onClick: () => {
        // Select fielder
        setOverlay(
          <SelectPlayer
            title="Select Catcher"
            defaultTeam={ballsBowled.organization}
            onSelect={(player) => {
              //   setCricketScoringState({
              //     selectedFielder: player.profile,
              //     selectedWicket: "Caught",
              //   });
              setOverlay(
                <ConfirmAddWicket
                  wicket={"Caught"}
                  fielder={player.profile}
                  ballsBowled={ballsBowled}
                  runsScored={runsScored}
                />
              );
            }}
            hideTabs={true}
          />
        );
        // Add wicket
      },
    },
    {
      label: "Caught & Bowled",
      onClick: () => {
        // Add wicket
        setOverlay(
          <ConfirmAddWicket
            wicket={"Caught & Bowled"}
            ballsBowled={ballsBowled}
            runsScored={runsScored}
          />
        );
      },
    },
    {
      label: "Run Out",
      onClick: () => {
        // Select fielder
        // Select dismissed batsman
        // Add wicket
        setOverlay(
          <SelectPlayer
            title="Select Fielder"
            defaultTeam={ballsBowled.organization}
            onSelect={(fielder) => {
              setOverlay(
                <SelectDismissedBatsman
                  onSelect={(batsman) => {
                    setOverlay(
                      <ConfirmAddWicket
                        wicket={"Run Out"}
                        fielder={fielder.profile}
                        batsman={batsman.profile}
                        ballsBowled={ballsBowled}
                        runsScored={runsScored}
                      />
                    );
                  }}
                />
              );
            }}
            hideTabs={true}
          />
        );
      },
    },
    {
      label: "Stumped",
      onClick: () => {
        // Select wicketkeeper
        // Add wicket
        setOverlay(
          <SelectPlayer
            title="Select Wicketkeeper"
            defaultTeam={ballsBowled.organization}
            onSelect={(player) => {
              //   setCricketScoringState({
              //     selectedFielder: player.profile,
              //     selectedWicket: "Caught",
              //   });
              setOverlay(
                <ConfirmAddWicket
                  wicket={"Stumped"}
                  fielder={player.profile}
                  ballsBowled={ballsBowled}
                  runsScored={runsScored}
                />
              );
            }}
            hideTabs={true}
          />
        );
      },
    },

    {
      label: "Retired",
      onClick: () => {
        // Select dismissed batsman
        // Add wicket
        setOverlay(
          <SelectDismissedBatsman
            onSelect={(batsman) => {
              setOverlay(
                <ConfirmAddWicket
                  wicket={"Retired"}
                  batsman={batsman.profile}
                  ballsBowled={ballsBowled}
                  runsScored={runsScored}
                />
              );
            }}
          />
        );
      },
    },

    {
      label: "Retired Hurt",
      onClick: () => {
        // Select dismissed batsman
        // Add wicket
        setOverlay(
          <SelectDismissedBatsman
            onSelect={(batsman) => {
              setOverlay(
                <ConfirmAddWicket
                  wicket={"Retired Hurt"}
                  batsman={batsman.profile}
                  ballsBowled={ballsBowled}
                  runsScored={runsScored}
                />
              );
            }}
          />
        );
      },
    },
  ];
  const runs = [1, 2, 3, 4, 5, 6];

  return (
    <NewDropdown
      placement="bottom-start"
      target={
        <div className="ScoringApp-cricket__scoring__stats-list__edit-item__create-btn">
          + Add Wicket
        </div>
      }
      menu={({ setShowMenu }) => (
        <Card className="ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu">
          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column">
            {wicketTypes.map((s) => (
              <div
                className={
                  "ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item "
                }
                onClick={() => {
                  s.onClick();
                  setShowMenu(false);
                }}
              >
                {s.label}
              </div>
            ))}
          </div>
        </Card>
      )}
    />
  );
}

function ConfirmAddExtra({
  extra,
  value,
  ballsBowled,
  ballsFaced,
  runsScored,
}) {
  const [{ setOverlay, eventId, fixtureId, selectedPeriod, statTypes }] =
    useCricketScoringState();
  const [createStat, { loading }] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.Tag",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [deleteStat] = useMutation(DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="ScoringApp-cricket__scoring__modal-form"
      style={{ height: "auto" }}
    >
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        Add Extra Confirmation
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={() => {
            setOverlay(false);
          }}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__error-msg">
        Please remember to manually update any ball numbers, runs scored and
        wickets that may be affected by adding wides and no balls.
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__options">
        <Button
          className="medium ScoringApp-cricket__scoring__modal-form__options__option cancel"
          onClick={() => {
            setOverlay(false);
          }}
        >
          Cancel
        </Button>

        <Button
          className="primary green medium ScoringApp-cricket__scoring__modal-form__options__option"
          onClick={() => {
            if (loading) return;

            createStat({
              variables: {
                data: {
                  ...makeStat({
                    eventId,
                    fixtureId,
                    fixturePeriodId: selectedPeriod.id,
                    organization: ballsBowled.organization,
                    profile: ballsBowled.profile,
                    statType: statTypes.find((s) => s.name === extra),
                    value,
                  }),
                  relationshipsTo: {
                    create: {
                      from: {
                        connect: { id: ballsBowled.id },
                      },
                    },
                  },
                },
              },
              update: async (cache, { data: { createStat } }) => {
                // Add extra to over item
                cache.modify({
                  id: "OverItem:" + ballsBowled.id,
                  fields: {
                    extras(existingRefs = [], { readField }) {
                      return [...existingRefs, createStat];
                    },
                  },
                });

                // Increment bowler extras && decrement balls bowled if its a wide or no ball
                cache.modify({
                  id: "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                  fields: {
                    extras(current) {
                      return current + value;
                    },
                    balls(current) {
                      if (extra === "Wide" || extra === "No Ball") {
                        return current - 1;
                      } else {
                        return current;
                      }
                    },
                  },
                });

                // Increment batting team score
                cache.modify({
                  id: "CricketLiveScoreItem:" + runsScored.organization.id,
                  fields: {
                    runs(current) {
                      return current + value;
                    },
                  },
                });

                // Update the innings score & wickets
                const updatedPeriodScores = await client.query({
                  query: FIXTURE({
                    selectionSet: `                    
                      cricketLiveScoring{
                        id
                        periodScores{
                          id
                          runs
                          wickets
                          overs
                        }        
                      }
                  `,
                  }),
                  variables: {
                    id: fixtureId,
                  },
                  fetchPolicy: "network-only",
                });
                // console.log("Updated period score:", updatedPeriodScores);
                // Update period scores
                cache.modify({
                  id: "CricketLiveScoringStats:" + fixtureId,
                  fields: {
                    periodScores(current) {
                      return updatedPeriodScores.data.fixture.cricketLiveScoring
                        .periodScores;
                    },
                  },
                });

                // If its a wide, balls faced has to be deleted
                if (extra === "Wide") {
                  deleteStat({
                    variables: {
                      id: ballsFaced.id,
                    },
                    update: (
                      cache,
                      { data: { deleteFixtureStatWithoutPropagation } }
                    ) => {
                      // Remove balls faced from over item
                      cache.modify({
                        id: "OverItem:" + ballsBowled.id,
                        fields: {
                          ballsFaced() {
                            return null;
                          },
                        },
                      });

                      // Decrement batsman balls faced
                      cache.modify({
                        id:
                          "CricketBattingSummaryItem:" + runsScored.profile.id,
                        fields: {
                          balls(current) {
                            return current - 1;
                          },
                        },
                      });
                    },
                  });
                }

                setOverlay(false);
              },
            });
          }}
        >
          {loading ? (
            <Loader theme="small white" />
          ) : (
            "Add" +
            (value > 1 ? " " + value : "") +
            (" " + extra + (value > 1 ? "s" : ""))
          )}
        </Button>
      </div>
    </div>
  );
}

function AddExtra({ existingExtras, ballsBowled, ballsFaced, runsScored }) {
  const [{ setOverlay }] = useCricketScoringState();
  const [selectedExtra, setSelectedExtra] = useState();
  const hasWides = existingExtras.find((s) => s.statType.name === "Wide");
  const hasNoBalls = existingExtras.find((s) => s.statType.name === "No Ball");
  const hasByes = existingExtras.find((s) => s.statType.name === "Bye");
  const hasLegByes = existingExtras.find((s) => s.statType.name === "Leg Bye");
  const extras = [
    {
      label: "Wide",
      onClick: () => {
        setSelectedExtra("Wide");
      },
      disabled: !!existingExtras.length,
    },
    {
      label: "No Ball",
      onClick: (setShowMenu) => {
        setOverlay(
          <ConfirmAddExtra
            extra="No Ball"
            value={1}
            ballsBowled={ballsBowled}
            ballsFaced={ballsFaced}
            runsScored={runsScored}
          />
        );
        setSelectedExtra("");
        setShowMenu(false);
      },
      disabled: hasWides || hasNoBalls,
    },
    {
      label: "Bye",
      onClick: () => {
        setSelectedExtra("Bye");
      },
      disabled: hasWides || hasLegByes || hasByes,
    },
    {
      label: "Leg Bye",
      onClick: () => {
        setSelectedExtra("Leg Bye");
      },
      disabled: hasWides || hasByes || hasLegByes,
    },
  ];
  const runs = [1, 2, 3, 4, 5, 6];

  return (
    <NewDropdown
      placement="bottom-start"
      target={
        <div className="ScoringApp-cricket__scoring__stats-list__edit-item__create-btn">
          + Add Extra
        </div>
      }
      menu={({ setShowMenu }) => (
        <Card className="ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu">
          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column">
            {extras.map((s) => (
              <div
                className={
                  "ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item " +
                  (selectedExtra === s.label ? "selected " : " ") +
                  (s.disabled ? "disabled" : "")
                }
                onClick={() => s.onClick(setShowMenu)}
              >
                {s.label}
              </div>
            ))}
          </div>
          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column">
            {runs.map((r) => (
              <div
                className={
                  "ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item " +
                  (selectedExtra ? "" : "disabled")
                }
                onClick={() => {
                  setOverlay(
                    <ConfirmAddExtra
                      extra={selectedExtra}
                      value={r}
                      ballsBowled={ballsBowled}
                      ballsFaced={ballsFaced}
                      runsScored={runsScored}
                    />
                  );
                  setSelectedExtra("");
                  setShowMenu(false);
                }}
              >
                {r}
              </div>
            ))}
          </div>
        </Card>
      )}
      onClose={() => {
        setSelectedExtra("");
      }}
    />
  );
}

function Batsman({ runsScored, ballsFaced, wicket }) {
  const [{ setOverlay }] = useCricketScoringState();
  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value hover-pointer"
      onClick={() => {
        if (loading) return;
        setOverlay(
          <SelectPlayer
            title="Select batsman"
            onSelect={(player) => {
              // Update Runs Scored
              updateStat({
                variables: {
                  where: { id: runsScored.id },
                  data: { profile: { connect: { id: player.profile.id } } },
                },
                update: (cache, { data: { updateStat } }) => {
                  // Decrement the old batsmans runs, fours or sixes
                  cache.modify({
                    id: "CricketBattingSummaryItem:" + runsScored.profile.id,
                    fields: {
                      runs(current) {
                        return current - runsScored.value;
                      },
                      fours(current) {
                        if (runsScored.value === 4) {
                          return current - 1;
                        } else {
                          return current;
                        }
                      },
                      sixes(current) {
                        if (runsScored.value === 6) {
                          return current - 1;
                        } else {
                          return current;
                        }
                      },
                    },
                  });

                  // Increment the new batsmans runs, fours or sixes
                  cache.modify({
                    id: "CricketBattingSummaryItem:" + updateStat.profile.id,
                    fields: {
                      runs(current) {
                        return current + runsScored.value;
                      },
                      fours(current) {
                        if (runsScored.value === 4) {
                          return current + 1;
                        } else {
                          return current;
                        }
                      },
                      sixes(current) {
                        if (runsScored.value === 6) {
                          return current + 1;
                        } else {
                          return current;
                        }
                      },
                    },
                  });
                },
              });

              // Update balls faced
              if (ballsFaced) {
                updateStat({
                  variables: {
                    where: { id: ballsFaced.id },
                    data: { profile: { connect: { id: player.profile.id } } },
                  },
                  update: (cache, { data: { updateStat } }) => {
                    // Decrement the old batsmans balls faced
                    cache.modify({
                      id: "CricketBattingSummaryItem:" + runsScored.profile.id,
                      fields: {
                        balls(current) {
                          return current - 1;
                        },
                      },
                    });
                    // Increment the new batsmans balls faced
                    cache.modify({
                      id: "CricketBattingSummaryItem:" + updateStat.profile.id,
                      fields: {
                        balls(current) {
                          return current + 1;
                        },
                      },
                    });
                  },
                });
              }

              // Update wicket
              if (wicket && wicket.profile.id === runsScored.profile.id) {
                updateStat({
                  variables: {
                    where: { id: wicket.id },
                    data: { profile: { connect: { id: player.profile.id } } },
                  },
                  update: (cache, { data: { updateStat } }) => {
                    cache.modify({
                      id: "Stat:" + wicket.id,
                      fields: {
                        profile() {
                          return updateStat.profile;
                        },
                      },
                    });
                  },
                });
              }

              setOverlay(false);
            }}
            defaultTeam={runsScored.organization}
            hideTabs={true}
          />
        );
      }}
    >
      {runsScored.profile.name}{" "}
      {loading ? (
        <Spinner size={12} />
      ) : (
        <PlayIcon
          style={{
            fill: "var(--light-4)",
            marginLeft: "4px",
            height: "12px",
            width: "12px",
            transform: "rotate(90deg)",
          }}
        />
      )}
    </div>
  );
}

function Bowler({ ballsBowled, runsConceded, extras, wicketTaken, fielding }) {
  const [{ setOverlay }] = useCricketScoringState();
  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value hover-pointer"
      onClick={() => {
        if (loading) return;
        setOverlay(
          <SelectPlayer
            title="Select bowler"
            onSelect={(player) => {
              const shouldUpdateFielding =
                ballsBowled?.profile?.id === fielding?.profile?.id;

              // Update Balls Bowled
              updateStat({
                variables: {
                  where: { id: ballsBowled.id },
                  data: { profile: { connect: { id: player.profile.id } } },
                },
                update: (cache, { data: { updateStat } }) => {
                  // Decrement the old bowlers balls bowled
                  cache.modify({
                    id: "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                    fields: {
                      balls(current) {
                        return current - 1;
                      },
                    },
                  });
                  // Incrment the new bowlers balls bowled
                  cache.modify({
                    id: "CricketBowlingSummaryItem:" + updateStat.profile.id,
                    fields: {
                      balls(current) {
                        return current + 1;
                      },
                    },
                  });
                },
              });

              // Update Runs Conceded
              updateStat({
                variables: {
                  where: { id: runsConceded.id },
                  data: { profile: { connect: { id: player.profile.id } } },
                },
                update: (cache, { data: { updateStat } }) => {
                  // Decrement the old bowlers runs conceded
                  cache.modify({
                    id: "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                    fields: {
                      runs(current) {
                        return current - runsConceded.value;
                      },
                    },
                  });
                  // Incrment the new bowlers runs conceded
                  cache.modify({
                    id: "CricketBowlingSummaryItem:" + updateStat.profile.id,
                    fields: {
                      runs(current) {
                        return current + runsConceded.value;
                      },
                    },
                  });
                },
              });

              // Update extras
              if (extras?.length) {
                extras.forEach((extra) => {
                  updateStat({
                    variables: {
                      where: { id: extra.id },
                      data: { profile: { connect: { id: player.profile.id } } },
                    },
                    update: (cache, { data: { updateStat } }) => {
                      // Decrement the old bowlers extras
                      cache.modify({
                        id:
                          "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                        fields: {
                          extras(current) {
                            return current - extra.value;
                          },
                        },
                      });
                      // Incrment the new bowlers extras
                      cache.modify({
                        id:
                          "CricketBowlingSummaryItem:" + updateStat.profile.id,
                        fields: {
                          extras(current) {
                            return current + extra.value;
                          },
                        },
                      });
                    },
                  });
                });
              }

              // Update wicket taken
              if (wicketTaken) {
                updateStat({
                  variables: {
                    where: { id: wicketTaken.id },
                    data: { profile: { connect: { id: player.profile.id } } },
                  },
                  update: (cache, { data: { updateStat } }) => {
                    // Decrement the old bowlers wickets
                    cache.modify({
                      id: "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                      fields: {
                        wickets(current) {
                          return current - 1;
                        },
                      },
                    });
                    // Incrment the new bowlers wickets
                    cache.modify({
                      id: "CricketBowlingSummaryItem:" + updateStat.profile.id,
                      fields: {
                        wickets(current) {
                          return current + 1;
                        },
                      },
                    });
                  },
                });
              }

              // Update fielding stat
              if (shouldUpdateFielding) {
                updateStat({
                  variables: {
                    where: { id: fielding.id },
                    data: { profile: { connect: { id: player.profile.id } } },
                  },
                  update: (cache, { data: { updateStat } }) => {
                    // Update the stats profile
                    cache.modify({
                      id: "Stat:" + fielding.id,
                      fields: {
                        profile() {
                          return updateStat.profile;
                        },
                      },
                    });
                  },
                });
              }

              setOverlay(false);
            }}
            defaultTeam={ballsBowled.organization}
            hideTabs={true}
          />
        );
      }}
    >
      {ballsBowled.profile.name}{" "}
      {loading ? (
        <Spinner size={12} />
      ) : (
        <PlayIcon
          style={{
            fill: "var(--light-4)",
            marginLeft: "4px",
            height: "12px",
            width: "12px",
            transform: "rotate(90deg)",
          }}
        />
      )}
    </div>
  );
}

function RunsScored({ num, runsScored, runsConceded }) {
  const [{ battingTeam, fixtureId, selectedPeriod }] = useCricketScoringState();
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [val, setVal] = useState(num);

  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  if (editing) {
    return (
      <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value">
        <ClickOutside
          onClickOutside={() => {
            setEditing(false);
          }}
        >
          {(wrapperRef) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (loading) return;
                // Update
                if (typeof Number(val) !== "number") {
                  return;
                }

                const diff = Number(val) - num;

                updateStat({
                  variables: {
                    where: { id: runsScored.id },
                    data: {
                      value: Number(val),
                    },
                  },
                  update: async (cache, { data: { updateStat } }) => {
                    cache.modify({
                      id: "CricketBattingSummaryItem:" + runsScored.profile.id,
                      fields: {
                        runs(currentRuns) {
                          return currentRuns + diff;
                        },
                        fours(currentFours) {
                          if (updateStat.value === 4 && diff) {
                            return currentFours + 1;
                          } else if (num === 4 && updateStat.value !== 4) {
                            return currentFours - 1;
                          }
                        },
                        sixes(currentSixes) {
                          if (updateStat.value === 6 && diff) {
                            return currentSixes + 1;
                          } else if (num === 6 && updateStat.value !== 6) {
                            return currentSixes - 1;
                          }
                        },
                      },
                    });

                    cache.modify({
                      id: "CricketLiveScoreItem:" + battingTeam.id,
                      fields: {
                        runs(currentRuns) {
                          return currentRuns + diff;
                        },
                      },
                    });

                    // Update the innings score & wickets
                    const updatedPeriodScores = await client.query({
                      query: FIXTURE({
                        selectionSet: `                    
                          cricketLiveScoring{
                            id
                            periodScores{
                              id
                              runs
                              wickets
                              overs
                            }        
                          }
                      `,
                      }),
                      variables: {
                        id: fixtureId,
                      },
                      fetchPolicy: "network-only",
                    });
                    // console.log("Updated period score:", updatedPeriodScores);
                    // Update period scores
                    cache.modify({
                      id: "CricketLiveScoringStats:" + fixtureId,
                      fields: {
                        periodScores(current) {
                          return updatedPeriodScores.data.fixture
                            .cricketLiveScoring.periodScores;
                        },
                      },
                    });

                    // cache.modify({
                    //   id: "CricketPeriodScoreItem:" + selectedPeriod.id,
                    //   fields: {
                    //     runs(currentRuns) {
                    //       return currentRuns + diff;
                    //     },
                    //   },
                    // });
                  },
                });

                updateStat({
                  variables: {
                    where: { id: runsConceded.id },
                    data: {
                      value: Number(val),
                    },
                  },
                  update: (cache, { data: { updateStat } }) => {
                    cache.modify({
                      id:
                        "CricketBowlingSummaryItem:" + runsConceded.profile.id,
                      fields: {
                        runs(currentRuns) {
                          return currentRuns + diff;
                        },
                      },
                    });
                  },
                });

                setEditing(false);
              }}
              ref={wrapperRef}
            >
              <input
                className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value__input"
                type="number"
                value={val}
                onChange={(e) => {
                  setVal(e.target.value);
                }}
                step="1"
                min="0"
              />
            </form>
          )}
        </ClickOutside>
      </div>
    );
  }
  return (
    <div
      className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value hover-pointer"
      onClick={() => {
        if (loading) return;
        setEditing(true);
      }}
    >
      {num}{" "}
      {loading ? (
        <Spinner size={12} />
      ) : (
        <PencilIcon
          style={{
            fill: "var(--light-4)",
            marginLeft: "4px",
            height: "12px",
            width: "12px",
          }}
        />
      )}
    </div>
  );
}

function ConfirmDeleteExtra({ extra, runsScored, ballsBowled }) {
  const [{ setOverlay, eventId, fixtureId, selectedPeriod, statTypes }] =
    useCricketScoringState();
  const [createStat] = useMutation(CREATE_STAT, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.Tag",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [deleteStat, { loading }] = useMutation(
    DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreCricket.OverItem.BatsmanMenuItem",
            mutation: "UPDATE_STAT",
          },
        });
        console.log(error);
      },
    }
  );
  return (
    <div
      className="ScoringApp-cricket__scoring__modal-form"
      style={{ height: "auto" }}
    >
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        Confirm Delete Extra
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={() => {
            setOverlay(false);
          }}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__error-msg">
        Are you sure you want to delete this extra? Please remember to update
        any ball numbers that may be affected by deleting wides and no balls.
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__options">
        <Button
          className="medium ScoringApp-cricket__scoring__modal-form__options__option cancel"
          onClick={() => {
            setOverlay(false);
          }}
        >
          No, cancel
        </Button>

        <Button
          className="primary red medium ScoringApp-cricket__scoring__modal-form__options__option"
          onClick={() => {
            if (loading) return;

            deleteStat({
              variables: {
                id: extra.id,
              },
              update: async (
                cache,
                { data: { deleteFixtureStatWithoutPropagation } }
              ) => {
                if (extra.statType.name === "Wide") {
                  // Add a balls faced stat
                  createStat({
                    variables: {
                      data: {
                        ...makeStat({
                          eventId,
                          fixtureId,
                          fixturePeriodId: selectedPeriod.id,
                          organization: runsScored.organization,
                          profile: runsScored.profile,
                          statType: statTypes.find(
                            (x) => x.name === "Balls Faced"
                          ),
                          value: 1,
                        }),
                        relationshipsTo: {
                          create: [
                            { from: { connect: { id: runsScored.id } } },
                          ],
                        },
                      },
                    },
                    update: (cache, { data: { createStat } }) => {
                      cache.modify({
                        id: "OverItem:" + ballsBowled.id,
                        fields: {
                          ballsFaced() {
                            return createStat;
                          },
                        },
                      });

                      // Increment batsmans balls faced
                      cache.modify({
                        id:
                          "CricketBattingSummaryItem:" + runsScored.profile.id,
                        fields: {
                          balls(current) {
                            return current + 1;
                          },
                        },
                      });
                    },
                  });
                }

                // Decrement bowlers extras
                cache.modify({
                  id: "CricketBowlingSummaryItem:" + ballsBowled.profile.id,
                  fields: {
                    extras(current) {
                      return current - extra.value;
                    },
                    balls(current) {
                      if (
                        extra.statType.name === "Wide" ||
                        extra.statType.name === "No Ball"
                      ) {
                        return current + 1;
                      } else {
                        return current;
                      }
                    },
                  },
                });

                // Decrement batting team score
                cache.modify({
                  id: "CricketLiveScoreItem:" + runsScored.organization.id,
                  fields: {
                    runs(current) {
                      return current - extra.value;
                    },
                  },
                });

                // console.log("Fetching updated period score");
                const updatedPeriodScores = await client.query({
                  query: FIXTURE({
                    selectionSet: `                    
                    cricketLiveScoring{
                      id
                      periodScores{
                        id
                        runs
                        wickets
                        overs
                      }        
                    }
                `,
                  }),
                  variables: {
                    id: fixtureId,
                  },
                  fetchPolicy: "network-only",
                });
                // console.log("Updated period score:", updatedPeriodScores);
                // Update period scores
                cache.modify({
                  id: "CricketLiveScoringStats:" + fixtureId,
                  fields: {
                    periodScores(current) {
                      return updatedPeriodScores.data.fixture.cricketLiveScoring
                        .periodScores;
                    },
                  },
                });

                // Remove extra from over item
                cache.modify({
                  id: "OverItem:" + ballsBowled.id,
                  fields: {
                    extras(existingRefs, { readField }) {
                      return existingRefs.filter(
                        (ref) => extra.id !== readField("id", ref)
                      );
                    },
                  },
                });

                setOverlay(false);
              },
            });
          }}
        >
          {loading ? <Loader theme="small white" /> : "Yes, delete this extra"}
        </Button>
      </div>
    </div>
  );
}

function Extra({ extra, runsScored, ballsBowled }) {
  const [{ setOverlay }] = useCricketScoringState();

  return (
    <div
      className="ScoringApp-cricket__scoring__stats-list__edit-item__tag"
      onClick={() => {
        setOverlay(
          <ConfirmDeleteExtra
            extra={extra}
            runsScored={runsScored}
            ballsBowled={ballsBowled}
          />
        );
      }}
    >
      {extra.statType?.name} ({extra.value})
      <TrashIcon className="ScoringApp-cricket__scoring__stats-list__edit-item__tag__icon" />
    </div>
  );
}

function Wicket({ wicket, wicketTaken, fielding, ballsBowled }) {
  const [{ setOverlay, fixtureId, selectedPeriod }] = useCricketScoringState();
  const [deleteStat, { loading }] = useMutation(
    DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreCricket.OverItem.BatsmanMenuItem",
            mutation: "UPDATE_STAT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div
      className="ScoringApp-cricket__scoring__stats-list__edit-item__tag"
      onClick={() => {
        if (loading) return;

        // Delete stat && delete wicket taken stat && fielding stat
        deleteStat({
          variables: {
            id: wicket.id,
          },
          update: async (
            cache,
            { data: { deleteFixtureStatWithoutPropagation } }
          ) => {
            // Decrement team scores & wickets
            cache.modify({
              id: "CricketLiveScoreItem:" + wicket.organization.id,
              fields: {
                wickets(current) {
                  if (wicket.statType.name === "Retired Hurt") {
                    return current;
                  } else {
                    return current - 1;
                  }
                },
              },
            });
            // Decrement innings scores & wickets
            // cache.modify({
            //   id: "CricketPeriodScoreItem:" + selectedPeriod.id,
            //   fields: {
            //     wickets(current) {
            //       if (wicket.statType.name === "Retired Hurt") {
            //         return current;
            //       } else {
            //         return current - 1;
            //       }
            //     },
            //   },
            // });
            // Update the innings score & wickets
            const updatedPeriodScores = await client.query({
              query: FIXTURE({
                selectionSet: `                    
                  cricketLiveScoring{
                    id
                    periodScores{
                      id
                      runs
                      wickets
                      overs
                    }        
                  }
              `,
              }),
              variables: {
                id: fixtureId,
              },
              fetchPolicy: "network-only",
            });
            // console.log("Updated period score:", updatedPeriodScores);
            // Update period scores
            cache.modify({
              id: "CricketLiveScoringStats:" + fixtureId,
              fields: {
                periodScores(current) {
                  return updatedPeriodScores.data.fixture.cricketLiveScoring
                    .periodScores;
                },
              },
            });

            // Remove wicket from over item
            cache.modify({
              id: "OverItem:" + ballsBowled.id,
              fields: {
                wicket() {
                  return null;
                },
              },
            });
          },
        });

        if (wicketTaken) {
          deleteStat({
            variables: {
              id: wicketTaken.id,
            },
            update: (
              cache,
              { data: { deleteFixtureStatWithoutPropagation } }
            ) => {
              // Decrement bowler wickets if necessary
              cache.modify({
                id: "CricketBowlingSummaryItem:" + wicketTaken.profile.id,
                fields: {
                  wickets(current) {
                    return current - 1;
                  },
                },
              });

              // Remove wicket taken from over item
              cache.modify({
                id: "OverItem:" + ballsBowled.id,
                fields: {
                  wicketTaken() {
                    return null;
                  },
                },
              });
            },
          });
        }

        if (fielding) {
          deleteStat({
            variables: {
              id: fielding.id,
            },
            update: (
              cache,
              { data: { deleteFixtureStatWithoutPropagation } }
            ) => {
              // Remove fielding from over item
              cache.modify({
                id: "OverItem:" + ballsBowled.id,
                fields: {
                  fielding() {
                    return null;
                  },
                },
              });
            },
          });
        }
      }}
    >
      {wicket.statType?.name}
      {loading ? (
        <Spinner size={12} />
      ) : (
        <TrashIcon className="ScoringApp-cricket__scoring__stats-list__edit-item__tag__icon" />
      )}
    </div>
  );
}

function BatsmanMenuItem({ role }) {
  return <div>{role.profile.name}</div>;
}

function BowlerMenuItem({ role }) {
  return <div>{role.profile.name}</div>;
}

function BallNumber({ num, stat }) {
  const [{ fixtureId }] = useCricketScoringState();
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [val, setVal] = useState(num);

  const [updateStat, { loading }] = useMutation(UPDATE_STAT, {
    update: async (cache, { data }) => {
      // Update the innings score & wickets
      const updatedPeriodScores = await client.query({
        query: FIXTURE({
          selectionSet: `                    
          cricketLiveScoring{
            id
            periodScores{
              id
              runs
              wickets
              overs
            }        
          }
      `,
        }),
        variables: {
          id: fixtureId,
        },
        fetchPolicy: "network-only",
      });
      // console.log("Updated period score:", updatedPeriodScores);
      // Update period scores
      cache.modify({
        id: "CricketLiveScoringStats:" + fixtureId,
        fields: {
          periodScores(current) {
            return updatedPeriodScores.data.fixture.cricketLiveScoring
              .periodScores;
          },
        },
      });
      // cache.modify({
      //   id: cache.identify(data.updateStat),
      //   fields: {
      //     profile(existingClaimRefs, { readField }) {
      //       return data.updateStat.profile;
      //     },
      //   },
      // });

      // closeMenu();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.OverItem.BatsmanMenuItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  if (editing) {
    return (
      <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value">
        <ClickOutside
          onClickOutside={() => {
            setEditing(false);
          }}
        >
          {(wrapperRef) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (loading) return;
                // Update
                if (typeof Number(val) !== "number") {
                  return;
                }

                updateStat({
                  variables: {
                    where: { id: stat.id },
                    data: {
                      value: Number(val),
                      stringValue: val + "",
                    },
                  },
                });

                setEditing(false);
              }}
              ref={wrapperRef}
            >
              <input
                className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value__input"
                type="number"
                value={val}
                onChange={(e) => {
                  setVal(e.target.value);
                }}
                step="0.1"
                max={Number((num + "").split(".")[0] || 0) + 0.9}
                min={Number((num + "").split(".")[0] || 0) + 0.1}
              />
            </form>
          )}
        </ClickOutside>
      </div>
    );
  }
  return (
    <div
      className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value hover-pointer"
      onClick={() => {
        if (loading) return;
        setEditing(true);
      }}
    >
      {num}{" "}
      {loading ? (
        <Spinner size={12} />
      ) : (
        <PencilIcon
          style={{
            fill: "var(--light-4)",
            marginLeft: "4px",
            height: "12px",
            width: "12px",
          }}
        />
      )}
    </div>
  );
}

export default function EditOverItem({ overItem, dismiss }) {
  const [{ setOverlay }] = useCricketScoringState();
  const {
    runsScored,
    ballsBowled,
    ballsFaced,
    extras,
    wicket,
    wicketTaken,
    runsConceded,
    fielding,
  } = overItem;

  const bowlingLineup = [];

  return (
    <div className="ScoringApp-cricket__scoring__stats-list__edit-item__wrapper">
      <Card className="ScoringApp-cricket__scoring__stats-list__edit-item">
        <div className="ScoringApp-cricket__scoring__stats-list__edit-item__title">
          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__title__text">
            Edit ball {ballsBowled.value?.toFixed(1)}
          </div>

          <div
            className="ScoringApp-cricket__scoring__stats-list__edit-item__title__dismiss"
            onClick={dismiss}
          >
            <TimesIcon className="ScoringApp-cricket__scoring__stats-list__edit-item__title__dismiss__icon" />
          </div>
        </div>

        <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main">
          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group">
            <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__label">
              Ball Number
            </div>

            <BallNumber
              num={ballsBowled.value?.toFixed(1)}
              stat={ballsBowled}
            />
          </div>

          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group">
            <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__label">
              Runs Scored
            </div>

            <RunsScored
              num={runsScored.value}
              runsScored={runsScored}
              runsConceded={runsConceded}
            />
          </div>

          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group">
            <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__label">
              Batsmen
            </div>

            <Batsman
              runsScored={runsScored}
              ballsFaced={ballsFaced}
              wicket={wicket}
            />

            {/* <NewDropdown
              target={({ setShowMenu }) => (
                
              )}
              menu={({ setShowMenu }) => (
                <Card className="edit-over-item-form__menu">
                  <div className="edit-over-item-form__menu__items">
                    {battingLineup.map((r) => (
                      <BatsmanMenuItem
                        role={r}
                        closeMenu={() => {
                          setShowMenu(false);
                        }}
                      />
                    ))}
                  </div>
                </Card>
              )}
            /> */}
          </div>

          <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group">
            <div className="ScoringApp-cricket__scoring__stats-list__edit-item__main__group__label">
              Bowler
            </div>

            <Bowler
              ballsBowled={ballsBowled}
              runsConceded={runsConceded}
              extras={extras}
              wicketTaken={wicketTaken}
              fielding={fielding}
            />
          </div>
        </div>

        <div className="ScoringApp-cricket__scoring__stats-list__edit-item__extras">
          {extras?.map((extra) => (
            <Extra
              extra={extra}
              runsScored={runsScored}
              ballsBowled={ballsBowled}
            />
          ))}

          <AddExtra
            existingExtras={extras}
            ballsBowled={ballsBowled}
            ballsFaced={ballsFaced}
            runsScored={runsScored}
          />
        </div>

        <div className="ScoringApp-cricket__scoring__stats-list__edit-item__wickets">
          {wicket ? (
            <Wicket
              wicket={wicket}
              wicketTaken={wicketTaken}
              fielding={fielding}
              ballsBowled={ballsBowled}
            />
          ) : (
            <AddWicket ballsBowled={ballsBowled} runsScored={runsScored} />
            // <div className="ScoringApp-cricket__scoring__stats-list__edit-item__create-btn">
            //   + Add Wicket
            // </div>
          )}
        </div>

        <div
          className="ScoringApp-cricket__scoring__stats-list__edit-item__danger-zone"
          onClick={() => {
            setOverlay(<ConfirmDeleteBall overItem={overItem} />);
          }}
        >
          Delete this ball
        </div>
      </Card>
    </div>
  );
}
