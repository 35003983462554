import { gql } from "@apollo/client";

const UPDATE_STAT = gql`
  mutation updateStat($where: UniqueInput!, $data: UpdateStatInput!) {
    updateStat(where: $where, data: $data) {
      id
      createdAt
      timeStamp
      value
      stringValue
      statType {
        id
        name
      }
      profile {
        id
        name
      }
      organization {
        id
        name
      }
      fixturePeriod {
        id
        order
        label
      }
      relationshipsFrom {
        id
        to {
          id
          createdAt
          stringValue
          value
          timeStamp
          statType {
            id
            name
          }
          profile {
            id
            name
          }
          organization {
            id
            name
          }
          fixturePeriod {
            id
            label
          }
        }
      }
      relationshipsTo {
        id
        from {
          id
          createdAt
          stringValue
          value
          timeStamp
          statType {
            id
            name
          }
          profile {
            id
            name
          }
          organization {
            id
            name
          }
          fixturePeriod {
            id
            label
          }
        }
      }
    }
  }
`;

export default UPDATE_STAT;
