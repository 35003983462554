import "./CreatePost.css";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import { useRef, useState, useEffect } from "react";
import { ReactComponent as ImageIcon } from "../../icons/image-thick.svg";
import { ReactComponent as VideoIcon } from "../../icons/video.svg";
import { ReactComponent as FolderIcon } from "../../icons/folder-outline.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import ReactQuill, { Quill } from "react-quill";
import QuillMention from "quill-mention";
import quillEmoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { useMutation } from "@apollo/client";
import { CREATE_POST } from "../../api/post";
import { SEARCH } from "../../api/user";
import { client } from "../../utils/client";
import makeFileUrl from "../../utils/makeFileUrl";
// import SelectMedia from "../../components/SelectMedia";
import SelectMedia from "../SelectMedia";
import YouTubeVideo from "../../components/YouTubeVideo";
import { useAppState } from "../../utils/appState";
import captureException from "../../utils/captureException";

Quill.register("modules/mentions", QuillMention);
Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true
);

const CustomToolbar = () => (
  <div id="custom-toolbar" style={{ paddingRight: 0 }}>
    {/*<select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
      <option value="1"></option>
      <option value="2"></option>
      <option selected></option>
    </select>*/}
    <div className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-link"></button>
      <button className="ql-emoji"></button>
    </div>

    {/* <select className="ql-color">
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option selected></option>
    </select> */}
    {/* <button className="ql-insertStar">
      <CustomButton />
    </button> */}
  </div>
);

async function suggestPeople(searchTerm) {
  const result = await client.query({
    query: SEARCH,
    variables: {
      term: searchTerm.trim(),
      take: 25,
    },
  });

  // console.log("Mention result:", result);
  return [
    ...result.data.search.profiles.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/profile/" + x.id,
      type: "profile",
    })),
    ...result.data.search.organizations.map((x) => ({
      ...x,
      value: x.name,
      link: "/app/page/" + x.id,
      type: "organization",
    })),
    // ...result.data.search.events.map((x) => ({
    //   ...x,
    //   value: x.name,
    //   type: "event",
    // })),
  ];
  // const allPeople = await search({ variables: { term: searchTerm.trim() } });
  // return allPeople.map((person) => ({ ...person, value: person.name }));
}

const mentionModule = {
  allowedChars: /^[A-Za-z\s]*$/,
  mentionDenotationChars: ["@"],
  minChars: 2,
  linkTarget: "_self",
  dataAttributes: [
    "id",
    "value",
    "denotationChar",
    "link",
    "target",
    "disabled",
    "type",
  ],
  source: async function (searchTerm, renderList, mentionChar) {
    if (!searchTerm || searchTerm.length === 0) {
      // console.log("No term");
      renderList([], searchTerm);
    }
    // console.log("Searching");
    const matches = await suggestPeople(searchTerm);
    // console.log("Matches:", matches);
    renderList(matches);
  },
  renderItem: function (item, searchTerm) {
    // console.log(item);
    const profilePhoto = item.profilePhoto
      ? makeFileUrl(item.profilePhoto)
      : false;
    return `<div class="mention-item">
      <div class="mention-item__image">${
        profilePhoto ? `<img src=${profilePhoto} />` : ""
      }</div>
      <div class="mention-item__text">
        <div class="mention-item__text__primary">${item.name}</div>
        <div class="mention-item__text__secondary">@${item.handle}</div>
      </div>
    </div>`;
  },
};

function CreatePost({ sourceId, sourceType, onCreatePost, mediaQueries }) {
  const [editing, setEditing] = useState(true);
  const [content, setContent] = useState("");
  const [truncatedContent, setTruncatedContent] = useState("");
  const [postPhotos, setPostPhotos] = useState([]);
  const [postVideos, setPostVideos] = useState([]);
  const [postAlbums, setPostAlbums] = useState([]);
  const [contentLength, setContentLength] = useState(0);
  const [selectingMedia, setSelectingMedia] = useState(false);
  const [, setAppState] = useAppState();
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef?.current) {
      // console.log("Focusing editor");
      editorRef.current.focus();
    }
  }, [editing]);

  const [createPost, { loading }] = useMutation(CREATE_POST, {
    update: (cache, { data: { createPost } }) => {
      onCreatePost(cache, createPost);
      setContent("");
      setPostPhotos([]);
      setPostVideos([]);
      setPostAlbums([]);
      setEditing(false);
      setAppState({
        modal: false,
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "CreatePost",
          mutation: "CREATE_POST",
        },
      });
      console.log(error);
    },
  });

  const onContentChange = (newContent, delta, source, editor) => {
    // console.log(editor.getContents());

    const text = editor.getText().trim();
    if (!text) {
      setTruncatedContent(false);
      setContent(false);
    } else {
      if (editor.getLength() > 200) {
        setTruncatedContent(editor.getContents(0, 200).insert("..."));
      } else {
        setTruncatedContent(editor.getContents());
      }
      setContent(editor.getContents());
    }

    setContentLength(editor.getLength());
  };

  if (selectingMedia) {
    return (
      <SelectMedia
        sourceId={sourceId}
        sourceType={sourceType}
        photosQuery={mediaQueries.photos.query}
        photosQueryVars={{ id: sourceId }}
        videosQuery={mediaQueries.videos.query}
        videosQueryVars={{ id: sourceId }}
        albumsQuery={mediaQueries.albums.query}
        albumsQueryVars={{ id: sourceId }}
        getPhotos={mediaQueries.photos.getPhotos}
        getPhotosCount={mediaQueries.photos.getPhotosCount}
        getVideos={mediaQueries.videos.getVideos}
        getVideosCount={mediaQueries.videos.getVideosCount}
        getAlbums={mediaQueries.albums.getAlbums}
        getAlbumsCount={mediaQueries.albums.getAlbumsCount}
        onSubmitSelection={(photos, videos, albums) => {
          setPostPhotos(photos);
          setPostVideos(videos);
          setPostAlbums(albums);
        }}
        defaultValue={{
          photos: postPhotos,
          videos: postVideos,
          albums: postAlbums,
        }}
        onUploadPhoto={mediaQueries.photos.onUploadPhoto}
        onUploadVideo={mediaQueries.videos.onUploadVideo}
        dismiss={() => {
          setSelectingMedia(false);
        }}
      />
    );
  }

  return (
    <Card
      className="create-post-card"
      style={{
        width: "90%",
        maxHeight: "90%",
        padding: 0,
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div className="modal-form-mobile__title">
        <div className="modal-form-mobile__title__text">New Post</div>
        <div
          className="modal-form-mobile__title__dismiss-btn"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
        </div>
      </div>

      <div className="create-post-card-body-mobile">
        <div
          className="input-wrapper"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          {/*<div className="media-selector hover-pointer">
            <ImageIcon style={{ height: 12 }} /> Media
      </div>*/}
          <ReactQuill
            id={"create-post-editor"}
            ref={editorRef}
            defaultValue={content}
            onChange={onContentChange}
            placeholder={"Say something..."}
            modules={{
              toolbar: {
                container: "#custom-toolbar",
              },
              // toolbar: [["bold", "italic", "underline", "link", "emoji"]],
              mention: mentionModule,
              "emoji-toolbar": true,
              "emoji-textarea": false,
              "emoji-shortname": true,
            }}
          />

          {(!!postPhotos.length ||
            !!postVideos.length ||
            !!postAlbums.length) && (
            <div
              className="selected-media-container"
              style={{
                gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
              }}
            >
              {postPhotos.map((m) => (
                <div
                  className="selected-media-container__preview"
                  onClick={() => {
                    setPostPhotos(postPhotos.filter((x) => x.id !== m.id));
                  }}
                >
                  <img src={makeFileUrl(m)} alt="" />
                  <div className="selected-media-container__preview__overlay">
                    <div className="media-type">
                      <ImageIcon className="media-type__icon" />
                    </div>
                    <div className="deselect">
                      <TimesIcon className="deselect-icon" />
                    </div>
                  </div>
                </div>
              ))}

              {postVideos.map((m) => (
                <div
                  className="selected-media-container__preview"
                  onClick={() => {
                    setPostVideos(postVideos.filter((x) => x.id !== m.id));
                  }}
                >
                  {/* <img src={makeFileUrl(m)} /> */}
                  <div className="video">
                    {m.isExternal ? (
                      <YouTubeVideo filename={m.filename} height={64} />
                    ) : (
                      <video controlsList="nodownload">
                        <source src={makeFileUrl(m)} type="video/mp4" />
                      </video>
                    )}
                  </div>
                  <div className="selected-media-container__preview__overlay">
                    <div className="media-type">
                      <VideoIcon className="media-type__icon" />
                    </div>
                    <div className="deselect">
                      <TimesIcon className="deselect-icon" />
                    </div>
                  </div>
                </div>
              ))}

              {postAlbums.map((m) => (
                <div
                  className="selected-media-container__preview"
                  onClick={() => {
                    setPostAlbums(postAlbums.filter((x) => x.id !== m.id));
                  }}
                >
                  {/* <img src={makeFileUrl(m)} /> */}
                  {m.preview ? (
                    <img src={makeFileUrl(m.preview)} alt="" />
                  ) : (
                    <ImageIcon className="placeholder-icon" />
                  )}
                  <div className="selected-media-container__preview__overlay">
                    <div className="media-type">
                      <FolderIcon className="media-type__icon" />
                    </div>
                    <div className="deselect">
                      <TimesIcon className="deselect-icon" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            className="custom-toolbar-wrapper"
            // style={{ marginBottom: "24px" }}
          >
            <CustomToolbar />

            <div className="media-options">
              <div
                className="attach-image-btn"
                style={{ marginLeft: "16px" }}
                onClick={() => {
                  setSelectingMedia(true);
                }}
              >
                <ImageIcon
                  style={{
                    stroke: "var(--dark-4)",
                    height: "16px",
                    width: "24px",
                  }}
                />
                {/* ADD MEDIA */}
              </div>
            </div>

            <div
              className={
                "character-count " + (contentLength - 2 > 1500 ? "red" : "")
              }
              style={{ right: "16px", bottom: "14px" }}
            >
              {contentLength - 2 < 0 ? 0 : contentLength - 2}/1500
            </div>
          </div>
        </div>
      </div>

      <div className="create-post-card-footer-mobile">
        <Button
          className={
            "medium blue primary submit-post-btn " +
            (!content || contentLength - 2 > 1500 ? "disabled" : "")
          }
          style={{ margin: "16px" }}
          onClick={() => {
            if (loading) {
              return;
            }

            if (contentLength - 2 > 1500) {
              return;
            }

            const vars = {
              sourceId,
              sourceType,
              truncatedContent,
              content,
              tags: {
                profiles: [],
                organizations: [],
                media: [
                  ...postPhotos.map((x) => x.id),
                  ...postVideos.map((x) => x.id),
                ],
                mediaSets: [...postAlbums.map((x) => x.id)],
              },
              contentLength: contentLength - 2,
            };

            content?.ops?.forEach((op) => {
              if (op.insert?.mention) {
                vars.tags[op.insert.mention.type + "s"].push(
                  op.insert.mention.id
                );
              }
            });

            // console.log(vars);

            // console.log(editorRef.current.getText());

            // return;

            createPost({
              variables: {
                data: vars,
              },
            });
          }}
        >
          {loading ? <Loader theme={"small"} /> : "Post it!"}
        </Button>
      </div>
    </Card>
  );
}

export default CreatePost;
