// V1
import "./ProfilePosts.css";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Post from "../Post";
import Spinner from "../Spinner";
import Loader from "../Loader";
import Button from "../Button";
import { PROFILE_POSTS } from "../../api/profile";
import captureException from "../../utils/captureException";
import { Fragment } from "react";
import MatchReport from "../MatchReport";

function ProfilePosts() {
  const { id } = useParams();
  const { error, data, fetchMore, networkStatus } = useQuery(PROFILE_POSTS, {
    variables: { id, take: 25 },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfilePosts",
        query: "PROFILE_POSTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div className="profile-posts">
        <div className="left-column">
          <div
            style={{
              height: 160,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  const org = data?.profile;
  const posts = org?.posts;

  // console.log(posts);
  return (
    <div className="profile-posts">
      <div className="left-column">
        {!posts?.length && (
          <div className="no-posts-container">
            <div className="text">No tagged posts for this profile yet.</div>
          </div>
        )}
        {!!posts?.length && (
          <div className="profile-posts-list">
            {posts.map((post) => (
              <Fragment>
                {post.postType === "REPORT" ? (
                  <MatchReport post={post} />
                ) : (
                  <Post
                    key={post.id}
                    post={post}
                    queryInfo={{
                      query: PROFILE_POSTS,
                      variables: { id },
                      onDeleteData: (prevData, deletePost) => {
                        if (!prevData?.profile) {
                          return [];
                        }
                        return {
                          profile: {
                            ...prevData.profile,
                            posts: [...prevData.profile.posts].filter(
                              (p) => p.id !== deletePost.id
                            ),
                          },
                        };
                      },
                      onUpdateData: (prevData, updatePost) => {
                        if (!prevData?.profile) {
                          return [];
                        }

                        const postIndex = prevData.profile.posts.findIndex(
                          (p) => p.id === updatePost.id
                        );

                        if (postIndex > -1) {
                          const newPosts = [...prevData.profile.posts];
                          newPosts[postIndex] = updatePost;
                          // console.log("New updatedpost index:", postIndex);
                          // console.log("New posts array:", newPosts);
                          return {
                            profile: {
                              ...prevData.profile,
                              posts: newPosts,
                            },
                          };
                        } else {
                          return { profile: { ...prevData.profile } };
                        }
                      },
                    }}
                    options={["edit", "delete"]}
                  />
                )}
              </Fragment>
            ))}

            <div className="show-more-container">
              <Button
                className="primary small blue show-more-button"
                onClick={() => {
                  fetchMore({
                    variables: {
                      cursor: posts[posts.length - 1].id,
                    },
                  });
                }}
              >
                {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="right-column">
        {/* <div className="card-section">
          <div className="card-section-title">Filters</div>
        </div> */}
      </div>
    </div>
  );
}

export default ProfilePosts;
