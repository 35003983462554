import Card from "../Card";
import "./EditProfile.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE } from "../../api/profile";
import captureException from "../../utils/captureException";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useAppState } from "../../utils/appState";
import Loader from "../Loader";

export default function EditProfile({ profile }) {
  const [, setAppState] = useAppState();

  const [name, setName] = useState(profile.name);
  const dismiss = () => {
    setAppState({ modal: false });
  };
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE("name"), {
    update: (cache, { data: { updateProfile } }) => {
      cache.modify({
        id: cache.identify(profile),
        fields: {
          name: () => {
            return updateProfile.name;
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EditProfile",
          mutation: "UPDATE_PROFILE",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="edit-profile-name__wrapper">
      <Card className="edit-profile-name">
        <div className="edit-profile-name__header">
          <div className="edit-profile-name__header__title">Edit Profile</div>

          <div className="edit-profile-name__header__dismiss" onClick={dismiss}>
            <TimesIcon className="edit-profile-name__header__dismiss__icon" />
          </div>
        </div>

        <form
          className="edit-profile-name__form"
          onSubmit={(e) => {
            e.preventDefault();
            if (loading || !name) return;

            updateProfile({
              variables: {
                where: { id: profile.id },
                data: { name },
              },
            });
          }}
        >
          <div className="edit-profile-name__form__group">
            <div className="edit-profile-name__form__group__label">Name</div>

            <input
              className="edit-profile-name__form__group__input"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="edit-profile-name__form__submit">
            <button
              type="submit"
              className={
                "app-button primary blue medium edit-profile-name__form__submit__btn "
              }
            >
              {loading ? <Loader theme={"small"} /> : "Submit"}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
}
