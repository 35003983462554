import Spinner from "../../../Spinner";
import Card from "../../../Card";
import { useQuery } from "@apollo/client";
import { TENNIS_STATS } from "../../../../api/fixture";

export default function BoxScore({
  periods,
  fixtureId,
  homePlayer,
  awayPlayer,
}) {
  const { loading, error, data } = useQuery(TENNIS_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats = data?.fixture?.stats;
  const periodsWithScores = periods.map((period) => ({
    ...period,
    homeScore: stats.filter(
      (s) =>
        s.statType?.name === "Games Won" &&
        s.fixturePeriod?.id === period.id &&
        s.profile?.id === homePlayer?.profile?.id
    ).length,
    awayScore: stats.filter(
      (s) =>
        s.statType?.name === "Games Won" &&
        s.fixturePeriod?.id === period.id &&
        s.profile?.id === awayPlayer?.profile?.id
    ).length,
  }));

  return (
    <Card className="fixture-badminton-stats__box-score">
      <div className="fixture-badminton-stats__box-score__column players">
        <div className="fixture-badminton-stats__box-score__column__label">
          PLAYER
        </div>
        <div className="fixture-badminton-stats__box-score__column__value">
          <div className="fixture-badminton-stats__box-score__column__value__image"></div>
          {homePlayer?.profile?.name}
        </div>
        <div className="fixture-badminton-stats__box-score__column__value">
          <div className="fixture-badminton-stats__box-score__column__value__image"></div>
          {awayPlayer?.profile?.name}
        </div>
      </div>

      {periodsWithScores.map((period) => (
        <div className="fixture-badminton-stats__box-score__column period">
          <div className="fixture-badminton-stats__box-score__column__label">
            {period.label.toUpperCase()}
          </div>
          <div
            className={
              "fixture-badminton-stats__box-score__column__value " +
              (period.homeScore > period.awayScore ? "bold" : "")
            }
          >
            {period.homeScore}
          </div>
          <div
            className={
              "fixture-badminton-stats__box-score__column__value " +
              (period.awayScore > period.homeScore ? "bold" : "")
            }
          >
            {period.awayScore}
          </div>
        </div>
      ))}
    </Card>
  );
}
