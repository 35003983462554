import { gql } from "@apollo/client";

const EVENT_SUGGESTED_PARTICIPANTS = gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      id
      suggestedParticipants {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default EVENT_SUGGESTED_PARTICIPANTS;
