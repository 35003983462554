import "./EventPlacements.css";
import { useQuery } from "@apollo/client";
import { EVENT_PLACEMENTS } from "../../../api/event";
import { ReactComponent as GoldMedalIcon } from "../../../icons/medal.svg";
import { ReactComponent as SilverMedalIcon } from "../../../icons/medal-silver.svg";
import { ReactComponent as BronzeMedalIcon } from "../../../icons/medal-bronze.svg";
import { useHistory } from "react-router-dom";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";

function EventPlacementItem({ label, placement, ev, medal }) {
  const history = useHistory();
  const getParticipant = (placement, event) => {
    if (event.participantType === "INDIVIDUAL") {
      return placement.profile;
    } else {
      return placement.organization;
    }
  };

  return (
    <div
      className="event-placements-mobile__item"
      onClick={() => {
        if (ev.participantType === "INDIVIDUAL") {
          history.push("/app/profile/" + placement.profile.id);
        } else {
          history.push("/app/page/" + placement.organization.id);
        }
      }}
    >
      <div className="event-placements-mobile__item__img">
        {getParticipant(placement, ev).profilePhoto && (
          <Avatar media={getParticipant(placement, ev).profilePhoto} />
        )}
      </div>

      <div className="event-placements-mobile__item__text">
        <div className="event-placements-mobile__item__text__primary">
          {getParticipant(placement, ev).name}
        </div>

        <div className="event-placements-mobile__item__text__secondary">
          {label}
        </div>
      </div>

      <div className="event-placements-mobile__item__icon">{medal}</div>
    </div>
  );
}

export default function EventPlacements({ id }) {
  const { error, data, loading } = useQuery(EVENT_PLACEMENTS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventPlacements",
        query: "EVENT_PLACEMENTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const event = data?.event;
  const placements = event?.placements;

  const eventPlacements = placements?.filter(
    (p) => !p.awardType && !p.fixture && !p.eventPart
  );
  const winner = eventPlacements?.find((p) => p.order === 0);
  const runnerUp = eventPlacements?.find((p) => p.order === 1);
  const secondRunnerUp = eventPlacements?.find((p) => p.order === 2);

  if (!eventPlacements?.length) {
    return <div />;
  }

  return (
    <div className="event-placements-mobile">
      <div className="event-placements-mobile__title">Event Results</div>

      {winner && (
        <EventPlacementItem
          label={"Winner"}
          ev={event}
          placement={winner}
          medal={<GoldMedalIcon style={{ height: "32px" }} />}
        />
      )}
      {runnerUp && (
        <EventPlacementItem
          label={"Runner Up"}
          ev={event}
          placement={runnerUp}
          medal={<SilverMedalIcon style={{ height: "32px" }} />}
        />
      )}
      {secondRunnerUp && (
        <EventPlacementItem
          label={"Third Place"}
          ev={event}
          placement={secondRunnerUp}
          medal={<BronzeMedalIcon style={{ height: "32px", opacity: 0.8 }} />}
        />
      )}
    </div>
  );
}
