import "./Alert.css";
import { Fragment } from "react";

function Alert({
  theme = "",
  width,
  indicator,
  dismiss,
  head,
  body,
  foot,
  headRight,
}) {
  return (
    <div className={"app-alert " + theme} style={{ width: width || "auto" }}>
      {indicator && (
        <div className="alert-indicator">
          <img src={"/info.svg"} alt="" />
        </div>
      )}

      <div className="alert-content">
        <div className="alert-head">
          <div className="alert-head-label">{head}</div>

          <div className="alert-head-right">
            {dismiss ? (
              <img
                src={"/times.svg"}
                className="dismiss-button"
                onClick={dismiss}
                alt=""
              />
            ) : (
              <Fragment>{headRight}</Fragment>
            )}
          </div>
        </div>

        {body && <div className="alert-body">{body}</div>}

        {foot && <div className="alert-foot">{foot}</div>}
      </div>
    </div>
  );
}

export default Alert;
