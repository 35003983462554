import { gql } from "@apollo/client";

const HANDLE_FIXTURE_ROLE = gql`
  mutation handleFixtureRole($id: ID!, $approve: Boolean!) {
    handleFixtureRole(id: $id, approve: $approve) {
      id
    }
  }
`;

export default HANDLE_FIXTURE_ROLE;
