import { gql } from "@apollo/client";

const EVENT_VENUE = gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      id
      venue {
        id
        name
      }
    }
  }
`;

export default EVENT_VENUE;
