import "./ProfilePagesSummary.css";
import { useQuery } from "@apollo/client";
import { PAGES, INTERACTION_STATS } from "../../../api/profile";
import { Link } from "react-router-dom";
import PartyList from "../../PartyList";
import { useAppState } from "../../../utils/appState";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function ProfilePagesSummary({ id, profile }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(PAGES, {
    variables: { id, take: 5 },
  });

  const interactionStatsQuery = useQuery(INTERACTION_STATS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfilePagesSummary",
        query: "PAGES",
      },
    });
    console.log(error);
  }

  if (interactionStatsQuery.error) {
    captureException({
      error: interactionStatsQuery.error,
      info: {
        type: "query",
        component: "ProfilePagesSummary",
        query: "INTERACTION_STATS",
      },
    });
    // console.log(interactionStatsQuery.error);
  }

  if (error || interactionStatsQuery.error) {
    return <div>Error</div>;
  }

  if (
    loading ||
    !data ||
    interactionStatsQuery.loading ||
    !interactionStatsQuery.data
  ) {
    return (
      <div className="profile-overview-mobile__pages">
        <div className="profile-overview-mobile__pages__header">
          <div className="profile-overview-mobile__pages__header__title">
            <Placeholder
              style={{ height: "10px", width: "64px" }}
              pulse={true}
            />
          </div>
        </div>
        <div className="profile-overview-mobile__pages__list">
          {[1, 2, 3].map((p) => (
            <div
              key={"profile-orgs-summary-loading-" + p}
              className="profile-overview-mobile__pages__list__item"
            >
              <div className="profile-overview-mobile__pages__list__item__image"></div>
              <div className="profile-overview-mobile__pages__list__item__desc">
                <div className="profile-overview-mobile__pages__list__item__desc__primary">
                  <Placeholder
                    style={{ height: "12px", width: "100px" }}
                    pulse={true}
                  />
                </div>
                <div className="profile-overview-mobile__pages__list__item__desc__secondary">
                  <Placeholder
                    style={{ height: "12px", width: "40px", marginTop: "8px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const pages = data.pages;
  const totalPages = interactionStatsQuery.data.interactionStats?.memberships;
  // console.log("PAGES:", pages);

  return (
    <div className="profile-overview-mobile__pages">
      <div className="profile-overview-mobile__pages__header">
        <div className="profile-overview-mobile__pages__header__title">
          Pages
        </div>

        <div
          className="profile-overview-mobile__pages__header__link"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`${profile.name}'s Pages`}
                  query={PAGES}
                  variables={{ id }}
                  fetchPolicy={"network-only"}
                  getData={
                    ((d) =>
                      d?.pages?.map((p) => ({
                        organization: p.organization,
                      }))) || []
                  }
                  noData={() => (
                    <div className="no-data">
                      <div>{profile.name} has no pages yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.organization?.handle
                      ? "@" + item.organization?.handle
                      : ""
                  }
                  totalItems={totalPages}
                />
              ),
            });
          }}
        >
          See all
        </div>
      </div>
      {pages.length ? (
        <div className="profile-overview-mobile__pages__list">
          {pages.slice(0, 5).map((p) => (
            <Link
              key={"profile-orgs-summary-" + p.id}
              to={"/app/page/" + p.organization.id}
              className="profile-overview-mobile__pages__list__item"
            >
              <div className="profile-overview-mobile__pages__list__item__image">
                {p.organization.profilePhoto && (
                  <Avatar media={p.organization.profilePhoto} />
                )}
              </div>
              <div className="profile-overview-mobile__pages__list__item__desc">
                <div className="profile-overview-mobile__pages__list__item__desc__primary">
                  {p.organization.name}
                </div>
                <div className="profile-overview-mobile__pages__list__item__desc__secondary">
                  {p.role}
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <div>
            {profile.name} is not a member
            <br /> of any pages yet.
          </div>
        </div>
      )}
    </div>
  );
}
