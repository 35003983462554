import "./RegisterButton.css";
import { useQuery, useMutation } from "@apollo/client";
import { EVENT, REGISTER } from "../../api/event";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import captureException from "../../utils/captureException";
import { useAppState } from "../../utils/appState";

export default function RegisterButton({ id }) {
  const history = useHistory();
  const [{ isLoggedIn }, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });
  const [register, { loading: submitting }] = useMutation(REGISTER, {
    update: (cache, { data: { register } }) => {
      const q = cache.readQuery({
        query: EVENT,
        variables: {
          id,
        },
      });

      cache.writeQuery({
        query: EVENT,
        variables: {
          id,
        },
        data: {
          event: {
            ...q.event,
            userIsRegistered: register,
          },
        },
      });

      //   setComplete(true);
      // setAppState({ modal: false });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "RegisterButton",
          mutation: "REGISTER",
        },
      });
      console.log(error);
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "RegisterButton",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const ev = data?.event;
  const disabled =
    loading ||
    !isLoggedIn ||
    !ev ||
    !ev.registrationStart ||
    (ev.registrationStart && moment(ev.registrationStart).isAfter(moment())) ||
    (ev.registrationEnd && moment(ev.registrationEnd).isBefore(moment())) ||
    ev.userIsRegistered;
  const isRegistered = ev.userIsRegistered;

  // console.log(ev.participantType);

  const handleClick = () => {
    if (submitting) return;

    if (ev.participantType === "INDIVIDUAL") {
      if (isRegistered) {
        history.push("/app/register/" + id);
      } else {
        if (disabled) return;
        register({
          variables: {
            eventId: id,
            organizationId: null,
          },
        });
      }
    } else {
      history.push("/app/register/" + id);
    }
  };

  return (
    <Button
      className={
        "blue medium option register-button " +
        (isRegistered ? "secondary " : "primary ") +
        (disabled ? "disabled" : "")
      }
      onClick={handleClick}
      disabled={disabled}
    >
      {submitting ? (
        <Loader theme="small" />
      ) : isRegistered ? (
        "Registered"
      ) : (
        "Register"
      )}
    </Button>
  );
}
