export default function canSubmitFormData(formData) {
  let output = true;

  formData.forEach((x) => {
    if (!x.data && !x.skipped) {
      output = false;
    }
  });

  return output;
}
