import { useState, Fragment } from "react";
import "./ResetPassword.css";
import { usePageTracking } from "../../utils/usePageTracking";
import { useHistory, Link, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../api/auth";
import captureException from "../../utils/captureException";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import DebouncedButton from "../../components/Button/DebouncedButton";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function ResetPassword() {
  usePageTracking({ title: "Reset Password" });
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState();
  const [resetted, setResetted] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const { token } = useParams();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    update: (cache, { data: { resetPassword } }) => {
      if (resetPassword.error) {
        setResetError(true);
        setErrorMessage(resetPassword.error);
      } else {
        setResetted(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ResetPassword",
          mutation: "RESET_PASSWORD",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="mobile-auth-page__wrapper">
      <div className="mobile-auth-page">
        <Link to="/" className="mobile-auth-page__logo">
          <img src="/logo.svg" alt="" />
        </Link>

        <div className="mobile-auth-form">
          <div className="mobile-auth-form__header">Reset your password?</div>

          {!resetted ? (
            <Fragment>
              {/* <div className="mobile-auth-form__secondary-header">
                Enter your email below to reset your password
              </div> */}
              <form
                className="mobile-auth-form__form"
                onSubmit={(e) => {
                  // console.log("Form submitted");
                  e.preventDefault();

                  if (loading) return;

                  setErrorMessage("");

                  if (!password) {
                    setInvalidPassword(true);
                    setErrorMessage("Please enter a valid password");
                    return;
                  }

                  resetPassword({
                    variables: {
                      password,
                      token,
                    },
                  });
                }}
              >
                <div className="mobile-auth-form__form__group">
                  <div className="mobile-auth-form__form__group__label">
                    Enter your new password below
                  </div>

                  <div
                    className={
                      "mobile-auth-form__form__group__field " +
                      (invalidPassword ? "error" : "")
                    }
                  >
                    <div className="mobile-auth-form__form__group__field__icon">
                      <LockIcon
                        style={{ fill: "var(--light-3)", height: 14 }}
                      />
                    </div>

                    <input
                      type={showPassword ? "text" : "password"}
                      id="current-password"
                      value={password}
                      className="mobile-auth-form__form__group__field__input"
                      autoComplete="current-password"
                      onChange={(e) => {
                        if (errorMessage) {
                          setErrorMessage("");
                        }
                        setPassword(e.target.value);
                      }}
                      onFocus={() => {
                        setInvalidPassword(false);
                        setErrorMessage("");
                      }}
                    />

                    <div
                      className="mobile-auth-form__form__group__field__icon"
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                    >
                      <EyeIcon
                        style={{
                          fill: showPassword
                            ? "var(--blue-main)"
                            : "var(--light-4)",
                          height: 14,
                        }}
                      />
                    </div>
                  </div>
                </div>

                {errorMessage && (
                  <div className="mobile-auth-form__form__error">
                    {errorMessage}
                  </div>
                )}

                <DebouncedButton
                  type="submit"
                  className={"mobile-auth-form__form__submit "}
                  loading={loading}
                  disableDefaultStyles={true}
                >
                  {loading ? <Loader theme="small" /> : "Submit"}
                </DebouncedButton>
                {/* <button
          type="submit"
          className={
            "mobile-auth-form__form__submit " + (loading ? "disabled" : "")
          }
        >
          {loading ? <Loader theme="small" /> : "Submit"}
        </button> */}
              </form>
            </Fragment>
          ) : (
            <Fragment>
              <div className="mobile-auth-form__secondary-header">
                You have successfully reset your password. Head over to the
                login page to get started.
              </div>
            </Fragment>
          )}

          <div className="mobile-auth-form__footer">
            {resetError && (
              <div className="mobile-auth-form__footer__statement">
                <Link
                  to={"/auth?s=login"}
                  // onClick={() => {
                  //   setFormType("signup");
                  // }}
                  className="mobile-auth-form__footer__statement__link"
                >
                  Request a new token
                </Link>
              </div>
            )}

            <div className="mobile-auth-form__footer__statement">
              <Link
                to={"/auth?s=login"}
                // onClick={() => {
                //   setFormType("signup");
                // }}
                className="mobile-auth-form__footer__statement__link"
              >
                Back to login
              </Link>
            </div>
          </div>
        </div>

        <div className="mobile-auth-page__graphic">
          <img src="/circle-bg.svg" alt="" />
        </div>

        <div className="mobile-auth-page__logo-bottom">
          <img src="/logo-white.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
