// V1
import "./OrganizationVideos.css";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Placeholder from "../Placeholder";
import { useAppState } from "../../utils/appState";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  ORGANIZATION_VIDEOS,
  ORGANIZATION_EVENTS,
} from "../../api/organization";
import { CREATE_MEDIA } from "../../api/media";
import VideoCard from "../VideoCard";
import ClickOutside from "../ClickOutside";
import { useState } from "react";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import Loader from "../Loader";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { SPORTS } from "../../api/sport";
import captureException from "../../utils/captureException";

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function AddVideo({ id, organization }) {
  const [externalLink, setExternalLink] = useState("");
  const [, setAppState] = useAppState();

  const [createMedia] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "OrganizationVideos",
          mutation: "CREATE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="add-video-wrapper" ref={wrapperRef}>
          <Card className={"add-video-container"}>
            <div className="add-video-container__title">
              Enter YouTube Embed Link
            </div>
            <div className="add-video-container__secondary-title">
              To get your video's embed link, click the "Share" button <br /> on
              the video's YouTube page and click "Copy".
            </div>
            <input
              type="text"
              placeholder={"Enter YouTube link here"}
              className="add-video-container__link-input"
              value={externalLink}
              onChange={(e) => {
                setExternalLink(e.target.value);
              }}
            />
            <Button
              className="primary green large"
              onClick={() => {
                if (!externalLink) return;
                const filename = externalLink.replace(
                  "https://youtu.be/",
                  "https://www.youtube.com/embed/"
                );
                createMedia({
                  variables: {
                    type: "VIDEO",
                    filename,
                    private: false,
                    isExternal: true,
                    sourceId: id,
                    sourceType: "organization",
                  },
                  update: (cache, { data: { createMedia } }) => {
                    // console.log("Upload video update function");
                    cache.modify({
                      id: cache.identify(organization),
                      fields: {
                        media(existingMediaRefs, { readField }) {
                          const newMediaRef = cache.writeFragment({
                            data: createMedia.media,
                            fragment: gql`
                              fragment NewMedia on Media {
                                id
                                createdAt
                                type
                                subtype
                                isExternal
                                filename
                                public
                                aspectRatio
                                canManageMedia
                              }
                            `,
                          });

                          // Quick safety check - if the new comment is already
                          // present in the cache, we don't need to add it again.
                          if (
                            existingMediaRefs.some(
                              (ref) =>
                                readField("id", ref) === createMedia.media.id
                            )
                          ) {
                            return existingMediaRefs;
                          }
                          // console.log("NEW VIDEO LIST:", [
                          //   newMediaRef,
                          //   ...existingMediaRefs,
                          // ]);
                          return [newMediaRef, ...existingMediaRefs];

                          // const newMediaSets = [
                          //   createMediaSet,
                          //   ...existingMediaRefs,
                          // ];
                          // console.log("new Media Sets:", newMediaSets);
                          // return newMediaSets;
                        },
                      },
                    });

                    // const q = cache.readQuery({
                    //   query: EVENT_VIDEOS,
                    //   variables: updateVariables,
                    // });

                    // cache.writeQuery({
                    //   query: EVENT_VIDEOS,
                    //   variables: updateVariables,
                    //   data: {
                    //     event: {
                    //       ...q.event,
                    //       media: [
                    //         { ...createMedia.media },
                    //         ...q.event.media,
                    //       ],
                    //     },
                    //   },
                    // });

                    setAppState({ modal: false });
                  },
                });
              }}
            >
              Add Video
            </Button>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

function OrganizationVideos({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const [sportsFilter, setSportsFilter] = useState([]);
  const [eventsFilter, setEventsFilter] = useState([]);
  const { networkStatus, error, data, refetch, variables, fetchMore } =
    useQuery(ORGANIZATION_VIDEOS, {
      variables: {
        id,
        type: "VIDEO",
        take: 5,
        filters: {
          sports: [],
          teams: [],
          events: [],
        },
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationVideos",
        query: "ORGANIZATION_VIDEOS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 1 || networkStatus === 2) {
    return (
      <div className="profile-videos">
        <div className="left-column">
          <div className="profile-videos-gallery">
            {[1, 2].map((v) => (
              <Card key={"profile-video-ph-" + v} className="video-list-item">
                <div className="video-wrapper">
                  <Placeholder
                    style={{
                      height: "280px",
                      width: "100%",
                      background: "var(--blue-subtle)",
                    }}
                    pulse={false}
                  />
                </div>
                <div className="video-info">
                  <div className="video-info__source">
                    <div className="video-info__source__image"></div>
                    <div className="video-info__source__text">
                      <div className="video-info__source__text__primary">
                        <Placeholder
                          style={{ height: "12px", width: "80px" }}
                          pulse={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="video-info__desc">
                    <Placeholder
                      style={{ height: "10px", width: "100%" }}
                      pulse={true}
                    />
                    <Placeholder
                      style={{ height: "10px", width: "80%", marginTop: "8px" }}
                      pulse={true}
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>

        <div className="right-column"></div>
      </div>
    );
  }

  const videos = data?.organization?.media || [];
  const videosCount = data?.organization?.videosCount || 0;

  // console.log("V:", videos);
  // console.log("VC:", videosCount);

  return (
    <div className="organization-videos">
      <div className="left-column">
        {canManagePage && (
          <div className="upload-btn-container">
            <Button
              className="primary blue large"
              onClick={async () => {
                setAppState({
                  modal: (
                    <AddVideo
                      id={id}
                      organization={data?.organization}
                      updateVariables={variables}
                    />
                  ),
                });
              }}
            >
              Add Video
            </Button>
          </div>
        )}

        {!videosCount && (
          <div className="no-videos-container">
            <div className="text">No tagged videos for this page yet.</div>
          </div>
        )}

        <div className="organization-videos-gallery">
          {videos.map((v) => (
            <VideoCard key={v.id} media={v} videoHeight={280} />
          ))}
        </div>

        {videos.length < videosCount && (
          <Card
            className="show-more-container"
            onClick={() => {
              fetchMore({
                variables: {
                  ...variables,
                  cursor: videos.length ? videos[videos.length - 1]?.id : null,
                },
              });
            }}
          >
            {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
          </Card>
        )}
      </div>

      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={"Enter a sport"}
            query={SPORTS}
            items={(d) => {
              return d?.sports;
            }}
            addFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  sports: sports.map((s) => s.name),
                },
              });
            }}
            removeFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  sports: sports.map((s) => s.name),
                },
              });
            }}
            defaultValue={sportsFilter}
            appStateKey={"sportsFilter"}
          />

          {/* <DirectoryFilter
            placeholder={"Enter a team"}
            query={ORGANIZATION_EVENT_PARTICIPANTS}
            vars={{ id }}
            items={(d) => {
              return d?.organization?.eventParticipants || [];
            }}
            addFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  teams: orgs.map((s) => s.id),
                },
              });
            }}
            removeFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  teams: orgs.map((s) => s.id),
                },
              });
            }}
            defaultValue={teamsFilter}
            appStateKey={"teamsFilter"}
          /> */}

          <DirectoryFilter
            placeholder={"Enter an event"}
            query={ORGANIZATION_EVENTS}
            vars={{ id }}
            items={(d) => {
              return d?.organization?.events;
            }}
            addFilter={(events) => {
              setEventsFilter(events.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  events: events.map((s) => s.id),
                },
              });
            }}
            removeFilter={(events) => {
              setEventsFilter(events.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  events: events.map((s) => s.id),
                },
              });
            }}
            defaultValue={eventsFilter}
            appStateKey={"eventsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default OrganizationVideos;
