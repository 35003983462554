import { gql } from "@apollo/client";

const EVENT_PROFILE_STATS = gql`
  query eventProfileStatsQuery($id: ID!, $statTypeId: ID!) {
    eventProfileStats(id: $id, statTypeId: $statTypeId) {
      id
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      profile {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      value
    }
  }
`;

export default EVENT_PROFILE_STATS;
