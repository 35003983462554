import { gql } from "@apollo/client";

const CREATE_ORGANIZATION = gql`
  mutation createOrg($data: CreateOrganizationInput!) {
    createOrganization(data: $data) {
      id
      name
      types {
        id
        name
      }
      followersCount
      description
      city {
        id
        value
      }
      cities {
        id
        value
      }
      profilePhoto {
        id
        filename
      }
      isFollowedByUser {
        id
      }
    }
  }
`;

export default CREATE_ORGANIZATION;
