import { Component } from "react";
import GoogleMapReact from "google-map-react";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import "./Map.css";

const Marker = ({ label }) => (
  <div className="app-map-marker">
    <LocationIcon className="app-map-marker__icon" />
    <div className="app-map-marker__label">{label}</div>
  </div>
);

class SimpleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: props.venue.lat || 20.5937,
      lng: props.venue.lng || 78.9629,
      zoom: props.venue.lat ? 12 : 5,
      label: props.venue.label || "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.venue) {
      this.setState({
        lat: nextProps.venue.lat,
        lng: nextProps.venue.lng,
        zoom: 10,
        label: nextProps.venue.label,
      });
    } else {
      this.setState({
        lat: 20.5937,
        lng: 78.9629,
        zoom: 5,
        label: "",
      });
    }
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCFQb6KcnsvOKg-gNCOUgTlP2F-C7lhmR4" }}
          center={{ lat: this.state.lat, lng: this.state.lng }}
          zoom={this.state.zoom}
        >
          {this.state.label && (
            <Marker
              lat={this.state.lat}
              lng={this.state.lng}
              label={this.state.label}
            />
          )}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
