// V1
import "./ProfileOrgsSummary.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { PAGES, INTERACTION_STATS } from "../../../api/profile";
import { Link } from "react-router-dom";
import PartyList from "../../PartyList/PartyList";
import { useAppState } from "../../../utils/appState";
import Placeholder from "../../Placeholder";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function ProfileOrgsSummary({ id, profile }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(PAGES, {
    variables: { id, take: 5 },
  });

  const interactionStatsQuery = useQuery(INTERACTION_STATS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileOrgsSummary",
        query: "PAGES",
      },
    });
    console.log(error);
  }

  if (interactionStatsQuery.error) {
    captureException({
      error: interactionStatsQuery.error,
      info: {
        type: "query",
        component: "ProfileOrgsSummary",
        query: "INTERACTION_STATS",
      },
    });
    console.log(interactionStatsQuery.error);
  }

  if (error || interactionStatsQuery.error) {
    return <div>Error</div>;
  }

  if (
    loading ||
    !data ||
    interactionStatsQuery.loading ||
    !interactionStatsQuery.data
  ) {
    return (
      <Card className="profile-overview-organizations-card">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "48px" }} pulse={true} />
        </div>
        <div className="profile-orgs-summary-list">
          {[1, 2, 3].map((p) => (
            <div
              key={"profile-orgs-summary-" + p}
              className="profile-orgs-summary-list-item"
            >
              <div className="image"></div>
              <div className="desc">
                <div className="primary-text">
                  <Placeholder
                    style={{ height: "12px", width: "120px" }}
                    pulse={true}
                  />
                </div>
                <div className="secondary-text" style={{ marginTop: "8px" }}>
                  <Placeholder
                    style={{ height: "12px", width: "60px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const pages = data.pages;
  const totalPages = interactionStatsQuery.data.interactionStats?.memberships;
  // console.log("PAGES:", pages);

  return (
    <Card className="profile-overview-organizations-card">
      <div className="card-title">
        Pages{" "}
        <div
          className="card-title-link"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`${profile.name}'s Pages`}
                  query={PAGES}
                  variables={{ id }}
                  fetchPolicy={"network-only"}
                  getData={
                    ((d) =>
                      d?.pages?.map((p) => ({
                        organization: p.organization,
                      }))) || []
                  }
                  noData={() => (
                    <div className="no-data">
                      <div>{profile.name} has no pages yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.organization?.handle
                      ? "@" + item.organization?.handle
                      : ""
                  }
                  totalItems={totalPages}
                />
              ),
            });
          }}
        >
          See all pages
        </div>
      </div>
      {pages.length ? (
        <div className="profile-orgs-summary-list">
          {pages.slice(0, 5).map((p) => (
            <Link
              key={"profile-orgs-summary-" + p.id}
              to={"/app/page/" + p.organization.id}
              className="profile-orgs-summary-list-item"
            >
              <div className="image">
                {p.organization.profilePhoto && (
                  <Avatar media={p.organization.profilePhoto} />
                )}
              </div>
              <div className="desc">
                <div className="primary-text">{p.organization.name}</div>
                <div className="secondary-text">{p.role}</div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <div>
            {profile.name} is not a member
            <br /> of any pages yet.
          </div>
        </div>
      )}
    </Card>
  );
}

export default ProfileOrgsSummary;
