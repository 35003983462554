import "./Register.css";
import { useState, Fragment } from "react";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { EVENT, REGISTER } from "../../api/event";
import { USER_ORGANIZATIONS } from "../../api/user";
import Spinner from "../../components/Spinner";
import Loader from "../../components/Loader";
import moment from "moment";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as PinIcon } from "../../icons/pin.svg";
import { ReactComponent as RupeeIcon } from "../../icons/rupee.svg";
import makeFileUrl from "../../utils/makeFileUrl";
import Barcode from "react-jsbarcode";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function RegistrationComplete({ ev, registration }) {
  return (
    <div className="event-registration-mobile__invoice">
      <div className="event-registration-mobile__invoice__content">
        <div className="event-registration-mobile__invoice__content__header">
          You are registered !
        </div>

        <div className="event-registration-mobile__invoice__content__body">
          <div
            className="event-registration-mobile__invoice__content__body__group"
            style={{ width: "50%" }}
          >
            <div className="event-registration-mobile__invoice__content__body__group__label">
              PROFILE
            </div>

            <div className="event-registration-mobile__invoice__content__body__group__value">
              <div className="event-registration-mobile__invoice__content__body__group__value__img">
                {registration?.profile?.profilePhoto && (
                  <img
                    src={makeFileUrl(registration?.profile?.profilePhoto)}
                    alt=""
                  />
                )}
              </div>
              {registration?.profile?.name}
            </div>
          </div>

          <div
            className="event-registration-mobile__invoice__content__body__group"
            style={{ width: "50%" }}
          >
            <div className="event-registration-mobile__invoice__content__body__group__label">
              TEAM
            </div>

            <div className="event-registration-mobile__invoice__content__body__group__value">
              <div className="event-registration-mobile__invoice__content__body__group__value__img">
                {registration?.organization?.profilePhoto && (
                  <img
                    src={makeFileUrl(registration?.organization?.profilePhoto)}
                    alt=""
                  />
                )}
              </div>
              {registration?.organization?.name}
            </div>
          </div>

          <div
            className="event-registration-mobile__invoice__content__body__group"
            style={{ width: "50%" }}
          >
            <div className="event-registration-mobile__invoice__content__body__group__label">
              DATE
            </div>

            <div className="event-registration-mobile__invoice__content__body__group__value">
              {ev.scheduledStart
                ? moment(ev.scheduledStart).format("Do MMM YYYY")
                : "TBD"}
            </div>
          </div>

          <div
            className="event-registration-mobile__invoice__content__body__group"
            style={{ width: "50%" }}
          >
            <div className="event-registration-mobile__invoice__content__body__group__label">
              ENTRY FEE
            </div>

            <div className="event-registration-mobile__invoice__content__body__group__value">
              ₹ {ev.registrationAmount || 0} (
              {registration.paid ? "Paid" : "Not Paid"})
            </div>
          </div>
        </div>

        <div className="event-registration-mobile__invoice__content__barcode">
          <Barcode
            value={registration.id}
            options={{ displayValue: false, height: 56, width: 1 }}
          />
        </div>

        {/* <div className="event-registration-mobile__invoice__content__footer">
          Back to event
        </div> */}
      </div>
    </div>
  );
}

export default function Register() {
  usePageTracking({ title: "Event Registration" });
  const { id } = useParams();
  const history = useHistory();

  const [selectedTeam, setSelectedTeam] = useState("");
  const [findTeam, setFindTeam] = useState("");

  const { loading, error, data } = useQuery(EVENT, { variables: { id } });
  const organizationsQuery = useQuery(USER_ORGANIZATIONS);
  const [register, { loading: submitting }] = useMutation(REGISTER, {
    update: (cache, { data: { register } }) => {
      const q = cache.readQuery({
        query: EVENT,
        variables: {
          id,
        },
      });

      cache.writeQuery({
        query: EVENT,
        variables: {
          id,
        },
        data: {
          event: {
            ...q.event,
            userIsRegistered: register,
          },
        },
      });

      //   setComplete(true);
      // setAppState({ modal: false });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Register",
          mutation: "REGISTER",
        },
      });
      console.log(error);
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Register",
        query: "USER_ORGANIZATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  // const disabled =
  //   !ev ||
  //   !ev.registrationStart ||
  //   (ev.registrationStart && moment(ev.registrationStart).isAfter(moment())) ||
  //   (ev.registrationEnd && moment(ev.registrationEnd).isBefore(moment())) ||
  //   ev.userIsRegistered;
  const isRegistered = ev?.userIsRegistered;

  const teams = organizationsQuery?.data?.userOrganizations || [];

  return (
    <div className="event-registration-mobile">
      <div className="event-registration-mobile__header">
        <div className="event-registration-mobile__header__nav">
          <div
            className="event-registration-mobile__header__nav__back-btn"
            onClick={() => {
              history.replace("/app/event/" + id);
            }}
          >
            <ArrowLeftIcon
              style={{ height: "16px", stroke: "var(--light-2)" }}
            />
          </div>

          <div className="event-registration-mobile__header__nav__title">
            REGISTRATION FORM
          </div>
        </div>

        <div className="event-registration-mobile__header__content">
          <div className="event-registration-mobile__header__content__img"></div>

          <div className="event-registration-mobile__header__title">
            <div className="event-registration-mobile__header__title__primary">
              {ev.name}
            </div>
            <div className="event-registration-mobile__header__title__secondary">
              <PinIcon
                style={{ stroke: "var(--light-4)", height: "10px" }}
                className="events-page-mobile__list__item__text__secondary__icon"
              />
              {ev.venueLabel
                ? ev.venueLabel + (ev.city?.value ? ", " : "")
                : ""}{" "}
              {ev.city?.value || ""}
              {!ev.venueLabel && !ev.city?.value && "TBD"}
            </div>

            <div className="event-registration-mobile__header__title__secondary">
              <CalendarIcon
                style={{ stroke: "var(--light-4)", height: "10px" }}
                className="events-page-mobile__list__item__text__secondary__icon"
              />
              {ev.scheduledStart
                ? moment(ev.scheduledStart).format("Do MMM YYYY")
                : "TBD"}
              {ev.scheduledStart && ev.scheduledEnd
                ? " - " + moment(ev.scheduledEnd).format("Do MMM YYYY")
                : ""}
            </div>

            <div className="event-registration-mobile__header__title__secondary">
              <RupeeIcon
                style={{ stroke: "var(--light-4)", height: "10px" }}
                className="events-page-mobile__list__item__text__secondary__icon"
              />
              {ev.registrationAmount || "-"}
            </div>
          </div>
        </div>
      </div>

      {isRegistered ? (
        <RegistrationComplete ev={ev} registration={isRegistered} />
      ) : (
        <Fragment>
          <div className="event-registration-mobile__body">
            {organizationsQuery.loading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Fragment>
                <div className="event-registration-mobile__body__title">
                  <div className="event-registration-mobile__body__title__primary">
                    Select your team
                  </div>
                </div>

                <div className="event-registration-mobile__body__list">
                  <div className="event-registration-mobile__body__list__desc">
                    Choose the team you want to register with from the list or
                    select "Find me a team" and we'll find one for you.
                  </div>

                  {teams?.map((team) => (
                    <div
                      className="event-registration-mobile__body__list__item"
                      onClick={() => {
                        if (selectedTeam?.id === team.id) {
                          setSelectedTeam("");
                        } else {
                          setSelectedTeam(team);
                        }

                        setFindTeam(false);
                      }}
                    >
                      <div className="event-registration-mobile__body__list__item__img"></div>

                      <div className="event-registration-mobile__body__list__item__desc">
                        <div className="event-registration-mobile__body__list__item__desc__primary">
                          {team.organization?.name}
                        </div>

                        <div className="event-registration-mobile__body__list__item__desc__secondary">
                          @{team.organization?.handle}
                        </div>
                      </div>

                      <div className="event-registration-mobile__body__list__item__indicator">
                        {team.id === selectedTeam?.id && (
                          <div className="event-registration-mobile__body__list__item__indicator__icon">
                            <CheckIcon
                              style={{ height: "14px", fill: "var(--light-1)" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}

                  <div
                    className="event-registration-mobile__body__list__item"
                    onClick={() => {
                      if (findTeam) {
                        setFindTeam(false);
                      } else {
                        setFindTeam(true);
                      }
                      setSelectedTeam("");
                    }}
                  >
                    <div className="event-registration-mobile__body__list__item__img"></div>

                    <div className="event-registration-mobile__body__list__item__desc">
                      <div className="event-registration-mobile__body__list__item__desc__primary">
                        Find me a team
                      </div>

                      {/* <div className="event-registration-mobile__body__list__item__desc__secondary">
                @orghandle
              </div> */}
                    </div>

                    <div className="event-registration-mobile__body__list__item__indicator">
                      {findTeam && (
                        <div className="event-registration-mobile__body__list__item__indicator__icon">
                          <CheckIcon
                            style={{ height: "14px", fill: "var(--light-1)" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>

          {/* <div className="event-registration-mobile__options">
        <div className="event-registration-mobile__options__item">
          <div className="event-registration-mobile__options__item__checkbox"></div>
          <div className="event-registration-mobile__options__item__text">
            Find me a team
          </div>
        </div>
      </div> */}

          <div className="event-registration-mobile__footer">
            <div
              className="event-registration-mobile__footer__btn"
              onClick={() => {
                if (submitting) {
                  return;
                }

                register({
                  variables: {
                    eventId: id,
                    organizationId: selectedTeam?.organization?.id || null,
                  },
                });
              }}
            >
              {submitting ? (
                <Loader theme={"small"} />
              ) : (
                <Fragment>
                  <div className="event-registration-mobile__footer__btn__left">
                    <div className="event-registration-mobile__footer__btn__left__secondary">
                      ENTRY FEE
                    </div>

                    <div className="event-registration-mobile__footer__btn__left__primary">
                      ₹ {ev.registrationAmount || 0}
                    </div>
                  </div>

                  <div className="event-registration-mobile__footer__btn__right">
                    Register Now
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
