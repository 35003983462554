import "./Publish.css";
import { useState, Fragment } from "react";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { FIXTURE } from "../../../api/functions";
import { PUBLISH_FIXTURE } from "../../../api/fixture";
import Spinner from "../../../components/Spinner";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

function PublishStatsConfirm({
  fixtureId,
  completed,
  dismiss,
  placements,
  tables,
}) {
  const [publishFixture, { loading }] = useMutation(PUBLISH_FIXTURE, {
    update: (cache, { data: { publishFixture } }) => {
      cache.modify({
        id: "Fixture:" + fixtureId,
        fields: {
          completed: () => {
            return true;
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreFootball.PublishStatsConfirm",
      //     mutation: "PUBLISH_FIXTURE",
      //   },
      // });
      console.log(error);
    },
  });

  return (
    <div className="ScoringApp-football__publish__confirm">
      <div className="ScoringApp-football__publish__confirm__form">
        <div className="ScoringApp-football__publish__confirm__form__header">
          {completed
            ? "Are you sure you want to update the final score for this fixture?"
            : "Are you sure you want to publish this fixture?"}
        </div>
        <div className="ScoringApp-football__publish__confirm__form__text">
          {completed ? (
            <p>
              Please note, you may need to recalculate any relevant standings
              tables for the league.
            </p>
          ) : (
            <Fragment>
              <p>
                This cannot be undone. Please note, users will still be able to
                see the score for the fixture from the fixtures page without
                publishing.
              </p>
              <p>
                Once you confirm we will update individual player, team and
                event stats, and the fixture will be available for use in
                standings calculations, so do make sure all stats are accurate
                before you publish the fixture.
              </p>
            </Fragment>
          )}
        </div>

        <div className="ScoringApp-football__publish__confirm__form__options">
          <Button
            className="secondary medium"
            onClick={() => {
              dismiss();
            }}
            disabled={loading}
          >
            No, go back
          </Button>
          <Button
            className="primary medium green"
            onClick={() => {
              if (loading) return;
              const vars = {
                fixtureId,
                placements,
                tables,
              };
              // console.log(vars);
              // return;
              publishFixture({
                variables: {
                  fixtureId,
                  placements,
                  tables,
                },
              });
            }}
            disabled={loading}
          >
            {loading ? <Loader /> : "Yes, publish this fixture"}
          </Button>
        </div>
      </div>
    </div>
  );
}

function EditScore({ score, dismiss, onUpdate }) {
  const [value, setValue] = useState(score || 0);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onUpdate(Number(value));
        dismiss();
      }}
      className="ScoringApp-football__publish__table__header__form"
    >
      <input
        type="number"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="ScoringApp-football__publish__table__header__form__input"
      />

      <button
        type="submit"
        className="ScoringApp-football__publish__table__header__form__btn submit"
      >
        <CheckIcon className="ScoringApp-football__publish__table__header__form__btn__icon" />
      </button>
      <button
        className="ScoringApp-football__publish__table__header__form__btn"
        onClick={() => {
          dismiss();
        }}
      >
        <TimesIcon className="ScoringApp-football__publish__table__header__form__btn__icon" />
      </button>
    </form>
  );
}

function Cell({ value, update }) {
  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState(value);

  return (
    <div className="ScoringApp-football__publish__table__body__column__row__data">
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            update && update(val);
            setEditing(false);
          }}
          className="ScoringApp-football__publish__table__body__column__row__data__form"
        >
          <div className="ScoringApp-football__publish__table__body__column__row__data__form__input">
            <input
              type={"number"}
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
            />
          </div>
          <button
            type={"submit"}
            className="ScoringApp-football__publish__table__body__column__row__data__form__btn submit"
          >
            <CheckIcon className="ScoringApp-football__publish__table__body__column__row__data__form__btn__icon" />
          </button>
          <button
            onClick={() => {
              setVal(value);
              setEditing(false);
            }}
            className="ScoringApp-football__publish__table__body__column__row__data__form__btn cancel"
          >
            <TimesIcon className="ScoringApp-football__publish__table__body__column__row__data__form__btn__icon" />
          </button>
        </form>
      ) : (
        <div
          className={
            "ScoringApp-football__publish__table__body__column__row__data__value " +
            (value ? "bold" : "light")
          }
          onClick={() => {
            setEditing(true);
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
}

function StatTable({
  organization,
  score,
  wickets,
  rows,
  statTypes,
  setRows,
  setScore,
  setWickets,
  completed,
}) {
  const [editingScore, setEditingScore] = useState(false);
  const [editingWickets, setEditingWickets] = useState(false);
  const sections = [
    {
      title: "Batting",
      statTypes: [
        {
          name: "Balls Faced",
        },
        {
          name: "Runs Scored",
        },
        {
          name: "Bowled",
        },
        {
          name: "LBW",
        },
        {
          name: "Caught & Bowled",
        },
        {
          name: "Caught",
        },
        {
          name: "Stumped",
        },
        {
          name: "Run Out",
        },
        {
          name: "Retired",
        },
        {
          name: "Obstruction",
        },
        {
          name: "Hit Wicket",
        },
        {
          name: "Double Hit",
        },
        {
          name: "Out of Bounds",
        },
        {
          name: "Ball Handling",
        },
        {
          name: "Out by Dots",
        },
        {
          name: "Timed Out",
        },
        {
          name: "Not Out",
        },
      ],
    },
    {
      title: "Bowling & Fielding",
      statTypes: [
        {
          name: "Balls Bowled",
        },
        {
          name: "Runs Conceded",
        },
        {
          name: "Wicket Taken",
        },
        {
          name: "No Ball",
        },
        {
          name: "Wide",
        },
        {
          name: "Bye",
        },
        {
          name: "Leg Bye",
        },
        {
          name: "Catch",
        },
        {
          name: "Stumping",
        },
        {
          name: "Maiden Overs",
        },
      ],
    },
  ];
  return (
    <div className="ScoringApp-football__publish__table">
      <div className="ScoringApp-football__publish__table__header">
        <div className="ScoringApp-football__publish__table__header__title">
          <div className="ScoringApp-football__publish__table__header__title__logo"></div>

          <div className="ScoringApp-football__publish__table__header__title__text">
            {organization.name}
          </div>
        </div>

        {editingScore ? (
          <EditScore
            score={score}
            dismiss={() => {
              setEditingScore(false);
            }}
            onUpdate={(val) => {
              setScore(val);
            }}
          />
        ) : (
          <div className="ScoringApp-football__publish__table__header__value">
            <div className="ScoringApp-football__publish__table__header__value__score">
              {score}
            </div>
            <div
              className="ScoringApp-football__publish__table__header__value__option"
              onClick={() => {
                setEditingScore(true);
              }}
            >
              <PencilIcon className="ScoringApp-football__publish__table__header__value__option__icon" />
            </div>
          </div>
        )}

        <div className="ScoringApp-football__publish__table__header__seperator">
          /
        </div>

        {editingWickets ? (
          <EditScore
            score={wickets}
            dismiss={() => {
              setEditingWickets(false);
            }}
            onUpdate={(val) => {
              setWickets(val);
            }}
          />
        ) : (
          <div className="ScoringApp-football__publish__table__header__value">
            <div className="ScoringApp-football__publish__table__header__value__score">
              {wickets}
            </div>
            <div
              className="ScoringApp-football__publish__table__header__value__option"
              onClick={() => {
                setEditingWickets(true);
              }}
            >
              <PencilIcon className="ScoringApp-football__publish__table__header__value__option__icon" />
            </div>
          </div>
        )}
      </div>

      {sections.map((s) => (
        <Fragment>
          <div className="ScoringApp-football__publish__table__section-title">
            {s.title}
          </div>
          <div className="ScoringApp-football__publish__table__body">
            {completed && (
              <div className="ScoringApp-football__publish__table__body__overlay">
                <div className="ScoringApp-football__publish__table__body__overlay__text">
                  This fixture has already been published, and stats are locked
                  for editing. You can still update the final score of the
                  match.
                </div>
              </div>
            )}
            <div className="ScoringApp-football__publish__table__body__column players">
              <div className="ScoringApp-football__publish__table__body__column__header">
                <div className="ScoringApp-football__publish__table__body__column__row">
                  <div className="ScoringApp-football__publish__table__body__column__row__data">
                    <div className="ScoringApp-football__publish__table__body__column__row__data__value">
                      Player
                    </div>
                  </div>
                </div>
              </div>
              {rows.map((row) => (
                <div className="ScoringApp-football__publish__table__body__column__row">
                  <div
                    className={
                      "ScoringApp-football__publish__table__body__column__row__data " +
                      (row.isInLineup ? "highlight" : "")
                    }
                  >
                    <div className="ScoringApp-football__publish__table__body__column__row__data__value">
                      {row.profile.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="ScoringApp-football__publish__table__body__column stats">
              <div className="ScoringApp-football__publish__table__body__column__header">
                <div className="ScoringApp-football__publish__table__body__column__row">
                  {s.statTypes
                    .map((stName) =>
                      statTypes.find((st) => st.name === stName.name)
                    )
                    .filter((st) => !!st)
                    .map((st) => (
                      <div className="ScoringApp-football__publish__table__body__column__row__data">
                        <div className="ScoringApp-football__publish__table__body__column__row__data__value">
                          {st.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {rows.map((row) => (
                <div className="ScoringApp-football__publish__table__body__column__row">
                  {s.statTypes
                    .map((stName) => {
                      const target = statTypes.find(
                        (st) => st.name === stName.name
                      );
                      if (target) {
                        return {
                          ...target,
                          getValue: stName.value,
                        };
                      } else {
                        return target;
                      }
                    })
                    .filter((st) => !!st)
                    .map((st) => (
                      <Cell
                        value={row.stats[st.id]}
                        update={(val) => {
                          setRows((prev) => {
                            let newStatTable = [...prev];

                            const rowIndex = prev.findIndex(
                              (x) => x.profile.id === row.profile.id
                            );
                            // console.log(rowIndex);
                            // console.log(newStatTable[rowIndex]);

                            newStatTable[rowIndex] = {
                              ...prev[rowIndex],
                              stats: {
                                ...prev[rowIndex].stats,
                                [st.id]: Number(val) || 0,
                              },
                            };

                            return newStatTable;
                          });
                        }}
                      />
                    ))}
                </div>
              ))}
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}

function PublishTables({
  id,
  setStep,
  loading,
  completed,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  defaultHomeScore,
  defaultAwayScore,
  defaultRows,
  statTypes,
}) {
  const history = useHistory();
  const [overlay, setOverlay] = useState(false);
  const [rows, setRows] = useState(defaultRows || []);
  const [homeScore, setHomeScore] = useState(defaultHomeScore.runs || 0);
  const [homeWickets, setHomeWickets] = useState(defaultHomeScore.wickets || 0);
  const [awayScore, setAwayScore] = useState(defaultAwayScore.runs || 0);
  const [awayWickets, setAwayWickets] = useState(defaultAwayScore.wickets || 0);

  // const homeTeam = summary?.homeParticipant;
  // const awayTeam = summary?.awayParticipant;
  // const homeScore = summary?.homeScore;
  // const awayScore = summary?.awayScore;
  // const statTypes = summary?.statTypes || [];
  // const items = summary?.items || [];

  // const hasPenaltyShootout = summary?.hasPenaltyShootout;
  // const homePenalties = summary?.homePenalties;
  // const awayPenalties = summary?.awayPenalties;

  return (
    <div className="ScoringApp-football__publish">
      <div className="ScoringApp-football__header">
        <div className="ScoringApp-football__header__nav-left">
          {completed ? (
            <div
              className="ScoringApp-football__header__nav-left__back-btn"
              onClick={() => {
                history.replace("/app/fixture/" + id);
                // setStep(1);
              }}
            >
              <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
              <div className="ScoringApp-football__header__nav-left__back-btn__text">
                Back to fixture
              </div>
            </div>
          ) : (
            <div
              className="ScoringApp-football__header__nav-left__back-btn"
              onClick={() => {
                setStep(1);
              }}
            >
              <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
              <div className="ScoringApp-football__header__nav-left__back-btn__text">
                Back to collect stats
              </div>
            </div>
          )}
        </div>

        <div className="ScoringApp-football__header__main">
          Verify Fixture Stats & Result
        </div>

        <div className="ScoringApp-football__header__nav-right">
          <div
            className="ScoringApp-football__header__nav-right__nav-btn square"
            onClick={() => {
              setStep(0);
            }}
          >
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <Button
            className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
            onClick={() => {
              // setStep(1);
              setOverlay(
                <PublishStatsConfirm
                  fixtureId={id}
                  completed={completed}
                  dismiss={() => {
                    setOverlay(false);
                  }}
                  placements={[
                    {
                      organizationId: homeTeam?.organization?.id,
                      value: Number(homeScore) || 0,
                      valueString: homeScore + "/" + homeWickets,
                    },
                    {
                      organizationId: awayTeam?.organization?.id,
                      value: Number(awayScore) || 0,
                      valueString: awayScore + "/" + awayWickets,
                    },
                  ]}
                  tables={rows.reduce((accum, curr) => {
                    for (let statTypeId in curr.stats) {
                      if (curr.stats[statTypeId]) {
                        accum.push({
                          profileId: curr.profile.id,
                          organizationId: curr.organization.id,
                          statTypeId,
                          value: Number(curr.stats[statTypeId] || 0),
                        });
                      }
                    }

                    return accum;
                  }, [])}
                />
              );
            }}
          >
            {completed ? "Update final score" : "Publish this fixture"}
          </Button>
          {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
        </div>
      </div>

      <div
        className="ScoringApp-football__body"
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div className="ScoringApp-football__publish__grid">
            <div className="ScoringApp-football__publish__grid__container">
              <StatTable
                organization={homeTeam?.organization}
                rows={rows.filter(
                  (r) => r.organization?.id === homeTeam?.organization?.id
                )}
                score={homeScore}
                wickets={homeWickets}
                statTypes={statTypes}
                setRows={setRows}
                setScore={setHomeScore}
                setWickets={setHomeWickets}
                completed={completed}
                lineup={homeLineup}
              />
            </div>

            <div className="ScoringApp-football__publish__grid__container">
              <StatTable
                organization={awayTeam?.organization}
                rows={rows.filter(
                  (r) => r.organization?.id === awayTeam?.organization?.id
                )}
                score={awayScore}
                wickets={awayWickets}
                statTypes={statTypes}
                setRows={setRows}
                setScore={setAwayScore}
                setWickets={setAwayWickets}
                completed={completed}
                lineup={awayLineup}
              />
            </div>
          </div>
        )}
      </div>

      {!!overlay && (
        <div className="ScoringApp-football__scoring__overlay">{overlay}</div>
      )}
    </div>
  );
}

function PublishWrapper({
  id,
  setStep,
  completed,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
}) {
  let playerIdsString = ``;
  const playerIds = [...homeLineup, ...awayLineup].map((r) => r.profile?.id);
  playerIds.forEach(
    (x, i) =>
      (playerIdsString += '"' + x + '"' + (i < playerIds.length - 1 ? "," : ""))
  );

  console.log("Pids string:", playerIdsString);

  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
      completed
      event{
        id
        sport{
          id
          statTypes{
            id
            name
          }
        }
      }
      placements {
        id
        value
        valueString
        organization {
          id
          name
        }
      }
      cricketLiveScoring{
        id
        liveScores{
          id
          value
          stringValue
          runs
          wickets          
        }
        battingSummaries(ids:[${playerIdsString}]){
          id
          runs
          balls
          fours
          sixes
          bowled
          lbw
          caughtAndBowled
          caught
          stumped
          runOut
          retired
          obstruction
          hitWicket
          doubleHit
          outOfBounds
          ballHandling
          outByDots
          timedOut
          notOut
        }
        bowlingSummaries(ids:[${playerIdsString}]){
          id
          runs
          overs
          balls
          wickets
          extras
          noBall
          wide
          bye
          legBye
          catch
          stumping
          maidenOvers
        }
      }
      `,
    }),
    { variables: { id }, fetchPolicy: "network-only" }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-football__publish">
        <div className="ScoringApp-football__header">
          <div className="ScoringApp-football__header__nav-left">
            {completed ? (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  // setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to fixture
                </div>
              </div>
            ) : (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to collect stats
                </div>
              </div>
            )}
          </div>

          <div className="ScoringApp-football__header__main">
            Verify Fixture Stats & Result
          </div>

          <div className="ScoringApp-football__header__nav-right">
            {/* <Button
              className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
              disabled={true}
            >
              Publish this fixture
            </Button> */}
            {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
          </div>
        </div>

        <div
          className="ScoringApp-football__body"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Spinner />
        </div>
      </div>
    );
  }

  const scores = data?.fixture?.cricketLiveScoring?.liveScores;
  const placements = data?.fixture?.placements || [];
  console.log(data?.fixture);
  const homeScore = data?.fixture?.completed
    ? {
        runs:
          placements.find((x) => x.organization.id === homeTeam.organization.id)
            ?.value || 0,
        wickets: placements
          .find((x) => x.organization.id === homeTeam.organization.id)
          ?.valueString?.split("/")?.[1],
      }
    : scores?.find((s) => s.id === homeTeam.organization?.id);
  const awayScore = data?.fixture?.completed
    ? {
        runs:
          placements.find((x) => x.organization.id === awayTeam.organization.id)
            ?.value || 0,
        wickets: placements
          .find((x) => x.organization.id === awayTeam.organization.id)
          ?.valueString?.split("/")?.[1],
      }
    : scores?.find((s) => s.id === awayTeam.organization?.id);
  const statTypes = data?.fixture?.event?.sport?.statTypes;

  console.log(homeScore, awayScore);

  const battingSummaries =
    data?.fixture?.cricketLiveScoring?.battingSummaries || [];
  const bowlingSummaries =
    data?.fixture?.cricketLiveScoring?.bowlingSummaries || [];

  // return <div />;

  let rows = [];

  let homeNotOutsArray = [];
  let awayNotOutsArray = [];

  [...homeLineup].reverse().forEach((role) => {
    const summary = battingSummaries.find((x) => x.id === role.profile?.id);

    if (
      !summary.bowled &&
      !summary.lbw &&
      !summary.caughtAndBowled &&
      !summary.caught &&
      !summary.stumped &&
      !summary.runOut &&
      !summary.retired &&
      !summary.obstruction &&
      !summary.hitWicket &&
      !summary.doubleHit &&
      !summary.outOfBounds &&
      !summary.ballHandling &&
      !summary.outByDots &&
      !summary.timedOut
    ) {
      homeNotOutsArray.push(role.profile.id);
    }
  });

  [...awayLineup].reverse().forEach((role) => {
    const summary = battingSummaries.find((x) => x.id === role.profile?.id);

    if (
      !summary.bowled &&
      !summary.lbw &&
      !summary.caughtAndBowled &&
      !summary.caught &&
      !summary.stumped &&
      !summary.runOut &&
      !summary.retired &&
      !summary.obstruction &&
      !summary.hitWicket &&
      !summary.doubleHit &&
      !summary.outOfBounds &&
      !summary.ballHandling &&
      !summary.outByDots &&
      !summary.timedOut
    ) {
      awayNotOutsArray.push(role.profile.id);
    }
  });

  const homeNotOuts = homeNotOutsArray.slice(-2);
  const awayNotOuts = awayNotOutsArray.slice(-2);

  console.log("Home not outs:", homeNotOuts);
  console.log("Away not outs:", awayNotOuts);

  function lineupToRows(participant) {
    return (role) => {
      const battingSummary = battingSummaries.find(
        (x) => x.id === role.profile?.id
      );

      const bowlingSummary = bowlingSummaries.find(
        (x) => x.id === role.profile?.id
      );

      const notOutsArray =
        participant.organization?.id === homeTeam.organization?.id
          ? homeNotOuts
          : awayNotOuts;

      const mergedSummary = {
        id: role.profile?.id,
        "Runs Scored": battingSummary?.runs || 0,
        "Balls Faced": battingSummary?.balls || 0,
        Bowled: battingSummary?.bowled || 0,
        LBW: battingSummary?.lbw || 0,
        Caught: battingSummary?.caught || 0,
        "Caught & Bowled": battingSummary?.caughtAndBowled || 0,
        Stumped: battingSummary?.stumped || 0,
        "Run Out": battingSummary?.runOut || 0,
        Retired: battingSummary?.retired || 0,
        Obstruction: battingSummary?.obstruction || 0,
        "Hit Wicket": battingSummary?.hitWicket || 0,
        "Double Hit": battingSummary?.doubleHit || 0,
        "Out of Bounds": battingSummary?.outOfBounds || 0,
        "Ball Handling": battingSummary?.ballHandling || 0,
        "Out by Dots": battingSummary?.outByDots || 0,
        "Timed Out": battingSummary?.timedOut || 0,
        "Not Out": notOutsArray.includes(role.profile?.id) ? 1 : 0,
        "Runs Conceded": bowlingSummary?.runs || 0,
        "Balls Bowled": bowlingSummary?.balls || 0,
        "Wicket Taken": bowlingSummary?.wickets || 0,
        "No Ball": bowlingSummary?.noBall || 0,
        Wide: bowlingSummary?.wide || 0,
        Bye: bowlingSummary?.bye || 0,
        "Leg Bye": bowlingSummary?.legBye || 0,
        Catch: bowlingSummary?.catch || 0,
        Stumping: bowlingSummary?.stumping || 0,
        "Maiden Overs": bowlingSummary?.maidenOvers || 0,
      };

      // Calculate not outs

      const roleStats = statTypes.reduce((accum, curr) => {
        const val = mergedSummary[curr.name];
        accum[curr.id] = val;
        return accum;
      }, {});

      rows.push({
        profile: role.profile,
        organization: participant.organization,
        stats: roleStats,
      });
    };
  }

  homeLineup.forEach(lineupToRows(homeTeam));
  awayLineup.forEach(lineupToRows(awayTeam));

  console.log("Bowling Summaries:", bowlingSummaries);

  return (
    <PublishTables
      id={id}
      setStep={setStep}
      loading={loading || !data}
      completed={completed}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
      defaultHomeScore={homeScore}
      defaultAwayScore={awayScore}
      defaultRows={rows}
      statTypes={statTypes}
    />
  );
}

export default function Publish({ id, setStep, completed }) {
  console.log(completed);
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `        
        roles{
          id
          order
          sortOrder
          createdAt
          type {
            id
            name
          }
          subtypes {
            id
            name
          }
          profile {
            id
            name
            handle
            profilePhoto {
              id
              filename
            }
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }        
      `,
    }),
    { variables: { id }, fetchPolicy: "network-only" }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  // const summary = data?.fixture?.publishStatsSummary;

  const participants = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const homeTeam = participants?.find((p) => !p.order);

  const awayTeam = participants?.find((p) => p.order);

  const players = data?.fixture?.roles?.filter(
    (r) => r.type.name === "LINEUP PLAYER"
  );

  const homeLineup = players
    ?.filter((r) => r.organization?.id === homeTeam?.organization?.id)
    .sort((a, b) => {
      return (
        (a.sortOrder || 100) - (b.sortOrder || 100) ||
        new Date(a.createdAt) - new Date(b.createdAt)
      );
    });

  const awayLineup = players
    ?.filter((r) => r.organization?.id === awayTeam?.organization?.id)
    .sort((a, b) => {
      return (
        (a.sortOrder || 100) - (b.sortOrder || 100) ||
        new Date(a.createdAt) - new Date(b.createdAt)
      );
    });

  // console.log(summary);

  if (loading || !data) {
    return (
      <div className="ScoringApp-football__publish">
        <div className="ScoringApp-football__header">
          <div className="ScoringApp-football__header__nav-left">
            {completed ? (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  // setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to fixture
                </div>
              </div>
            ) : (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to collect stats
                </div>
              </div>
            )}
          </div>

          <div className="ScoringApp-football__header__main">
            Verify Fixture Stats & Result
          </div>

          <div className="ScoringApp-football__header__nav-right">
            {/* <Button
              className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
              disabled={true}
            >
              Publish this fixture
            </Button> */}
            {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
          </div>
        </div>

        <div
          className="ScoringApp-football__body"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <PublishWrapper
        id={id}
        setStep={setStep}
        completed={completed}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeLineup={homeLineup}
        awayLineup={awayLineup}
      />
    );
  }
}
