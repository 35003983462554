import { gql } from "@apollo/client";

const PROFILE_AWARDS = gql`
  query profileAwardsQuery($id: ID!, $sport: String) {
    profile(id: $id) {
      id
      placements(sport: $sport) {
        id
        awardType {
          id
          name
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
      }
    }
  }
`;

export default PROFILE_AWARDS;
