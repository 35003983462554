import { gql } from "@apollo/client";

const INTERACTION_STATS = gql`
  query interactionStatsQuery($id: ID!) {
    interactionStats(id: $id) {
      id
      followers
      following
      posts
      memberships
    }
  }
`;

export default INTERACTION_STATS;
