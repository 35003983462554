import { Link } from "react-router-dom";
import "./ManageEventPrompt.css";

export default function ManageEventPrompt({ id }) {
  return (
    <div className="manage-event-prompt-mobile">
      <div className="manage-event-prompt-mobile__text">
        <div className="manage-event-prompt-mobile__text__primary">
          Welcome event admin,
        </div>

        <div className="manage-event-prompt-mobile__text__secondary">
          You are authorized to edit this event. Ensure all details are filled
          and correct to find fans around you.
        </div>
      </div>

      <Link
        to={"/app/event/" + id + "/manage"}
        className="manage-event-prompt-mobile__action"
      >
        Manage Event
      </Link>
    </div>
  );
}
