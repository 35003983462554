// V1
import "./PartyList.css";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useAppState } from "../../utils/appState";
import { useQuery } from "@apollo/client";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Avatar from "../../components/Avatar";
import captureException from "../../utils/captureException";

function PartyList({
  title,
  query,
  variables,
  fetchPolicy,
  getData,
  noData,
  secondaryText,
  totalItems,
  hideFollow,
}) {
  const [, setAppState] = useAppState();
  const { loading, error, data, fetchMore, networkStatus } = useQuery(query, {
    variables,
    ...(fetchPolicy ? { fetchPolicy } : {}),
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PartyList",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const list = getData(data);
  // console.log(data);

  return (
    <div className="app-party-list-mobile-container">
      <div className="app-party-list-mobile">
        <div className="app-party-list-mobile__header">
          <div className="app-party-list-mobile__header__title">{title}</div>
          <div
            className="app-party-list-mobile__header__dismiss"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon className="app-party-list-mobile__header__dismiss__icon" />
          </div>
        </div>

        {networkStatus === 1 ? (
          <div
            style={{
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div className="app-party-list-mobile__body">
            {list.map((item, i) => (
              <Link
                key={"party-list-item-" + i}
                to={
                  item.profile
                    ? `/app/profile/${item.profile.id}`
                    : `/app/page/${item.organization.id}`
                }
                className="app-party-list-mobile__body__item"
                onClick={() => {
                  setAppState({ modal: false });
                }}
              >
                <div className="app-party-list-mobile__body__item__image">
                  {item.profile
                    ? item.profile.profilePhoto && (
                        <Avatar media={item.profile.profilePhoto} />
                      )
                    : item.organization?.profilePhoto && (
                        <Avatar media={item.organization?.profilePhoto} />
                      )}
                </div>
                <div className="app-party-list-mobile__body__item__desc">
                  <div className="app-party-list-mobile__body__item__desc__primary">
                    {item.organization
                      ? item.organization.name
                      : item.profile.name}
                  </div>

                  <div className="app-party-list-mobile__body__item__desc__secondary">
                    {secondaryText ? secondaryText(item) : ""}
                  </div>
                </div>
                <div className="app-party-list-mobile__body__item__options">
                  {/* {!hideFollow && (
                  <Button className="secondary small blue">Follow</Button>
                )} */}
                </div>
              </Link>
            ))}

            {!loading && !list?.length && noData()}

            {!!list?.length && totalItems && totalItems > list.length && (
              <div
                className="app-party-list-mobile__body__item--show-more"
                onClick={() => {
                  if (networkStatus === 3) return;
                  fetchMore({
                    variables: {
                      cursor: list[list.length - 1].id,
                      take: 25,
                    },
                  });
                }}
              >
                {networkStatus === 3 ? <Loader theme={"grey"} /> : "Show More"}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PartyList;
