import { useRef, useState } from "react";
import "./BasketballStats.css";
import Card from "../../../Card";
import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import { ReactComponent as PlayIcon } from "../../../../icons/play.svg";
import { FIXTURE, BASKETBALL_STATS } from "../../../../api/fixture";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../Spinner";
import AppLink from "../../../AppLink";
import Tooltip from "../../../Tooltip";
import NewDropdown from "../../../NewDropdown";
import Avatar from "../../../Avatar";
import useElementDimensions from "../../../../utils/useElementDimensions";

const tableCells = [
  // {
  //   label: "Player",
  //   tooltip: "Player",
  //   width: "16%",
  //   style: { width: "16%" },
  //   data: (row) => row.profile.name,
  //   onClick: (row) => "/app/profile/" + row.profile.id,
  // },
  {
    label: "1FGA",
    groups: ["Offense"],
    tooltip: "1 Pt. Field Goals Attempted",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["1FGA"] || 0,
  },
  {
    label: "1FGM",
    groups: ["Offense"],
    tooltip: "1 Pt. Field Goals Made",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["1FGM"] || 0,
  },
  {
    label: "2FGA",
    groups: ["Offense"],
    tooltip: "2 Pt. Field Goals Attempted",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["2FGA"] || 0,
  },
  {
    label: "2FGM",
    groups: ["Offense"],
    tooltip: "2 Pt. Field Goals Made",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["2FGM"] || 0,
  },
  {
    label: "3FGA",
    groups: ["Offense"],
    tooltip: "3 Pt. Field Goals Attempted",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["3FGA"] || 0,
  },
  {
    label: "3FGM",
    groups: ["Offense"],
    tooltip: "3 Pt. Field Goals Made",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["3FGM"] || 0,
  },
  {
    label: "FTA",
    groups: ["Offense"],
    tooltip: "Free Throws Attempted",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["FTA"] || 0,
  },
  {
    label: "FTM",
    groups: ["Offense"],
    tooltip: "Free Throws Made",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["FTM"] || 0,
  },
  {
    label: "AST",
    groups: ["Offense"],
    tooltip: "Assists",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["AST"] || 0,
  },
  {
    label: "T/O",
    groups: ["Offense"],
    tooltip: "Turnovers",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["T/O"] || 0,
  },
  {
    label: "OFF REB",
    groups: ["Offense"],
    tooltip: "Offensive Rebounds",
    width: "5%",
    widthPercent: 8,
    style: { width: "8%", textAlign: "right" },
    data: (row) => row["OFF REB"] || 0,
  },
  {
    label: "PTS",
    groups: ["Offense"],
    tooltip: "Points",
    width: "5%",
    widthPercent: 7,
    style: { width: "7%", textAlign: "right" },
    data: (row) => row["PTS"] || 0,
  },
  {
    label: "BLK",
    groups: ["Defense"],
    tooltip: "Blocks",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["BLK"] || 0,
  },
  {
    label: "DEF REB",
    groups: ["Defense"],
    tooltip: "Blocks",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["DEF REB"] || 0,
  },
  {
    label: "STL",
    groups: ["Defense"],
    tooltip: "Steals",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["STL"] || 0,
  },
  {
    label: "FOULS",
    groups: ["Defense"],
    tooltip: "Fouls",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["FOULS"] || 0,
  },
];

function ShotsSummary({
  homeTeam,
  awayTeam,
  homeStats,
  awayStats,
  homeShots = 0,
  awayShots = 0,
  homeShotsOnTarget = 0,
  awayShotsOnTarget = 0,
  title,
}) {
  // const homeShots = homeStats.totalShots || 0;
  // const awayShots = awayStats.totalShots || 0;
  const totalShots = homeShots + awayShots;
  // const homeShotsOnTarget = homeStats.shotsOnTarget || 0;
  // const awayShotsOnTarget = awayStats.shotsOnTarget || 0;

  const homeTotalShotsWidth = totalShots ? (homeShots / totalShots) * 100 : 0;
  const awayTotalShotsWidth = totalShots ? (awayShots / totalShots) * 100 : 0;

  const homeShotsOnTargetWidth = totalShots
    ? (homeShotsOnTarget / totalShots) * 100
    : 0;
  const awayShotsOnTargetWidth = totalShots
    ? (awayShotsOnTarget / totalShots) * 100
    : 0;

  const homeTotalShotsHeight =
    homeShots > awayShots
      ? 100
      : homeShots === awayShots
      ? 100
      : (homeShots / awayShots) * 100;
  const awayTotalShotsHeight =
    awayShots > homeShots
      ? 100
      : homeShots === awayShots
      ? 100
      : (awayShots / homeShots) * 100;

  const homeShotsOnTargetHeight = homeShots
    ? (homeShotsOnTarget / homeShots) * homeTotalShotsHeight
    : 0;
  const awayShotsOnTargetHeight = awayShots
    ? (awayShotsOnTarget / awayShots) * awayTotalShotsHeight
    : 0;

  return (
    <Card className="shots-summary">
      <div className="card-title">
        <div className="card-title__team home">
          <div className="card-title__team__image">
            {homeTeam?.organization?.profilePhoto && (
              <Avatar media={homeTeam.organization.profilePhoto} />
            )}
          </div>
          <div className="card-title__team__name">
            {homeTeam.organization.name}
          </div>
        </div>
        <div className="card-title__label"> {title}</div>
        <div className="card-title__team away">
          <div className="card-title__team__name">
            {awayTeam.organization.name}
          </div>
          <div className="card-title__team__image">
            {awayTeam?.organization?.profilePhoto && (
              <Avatar media={awayTeam.organization.profilePhoto} />
            )}
          </div>
        </div>
      </div>
      {/* <div className="match-stats__teams">
        <div className="match-stats__teams__team home">
          <div className="match-stats__teams__team__image">
            {homeTeam?.organization?.profilePhoto && (
              <img src={makeFileUrl(homeTeam.organization.profilePhoto)} />
            )}
          </div>
          <div className="match-stats__teams__team__name">
            {homeTeam.organization.name}
          </div>
        </div>

        <div className="match-stats__teams__team away">
          <div className="match-stats__teams__team__name">
            {awayTeam.organization.name}
          </div>
          <div className="match-stats__teams__team__image">
            {awayTeam?.organization?.profilePhoto && (
              <img src={makeFileUrl(awayTeam.organization.profilePhoto)} />
            )}
          </div>
        </div>
      </div> */}

      <div className="shots-summary__body">
        <div className="shots-summary__body__section home">
          <div className="shots-summary__body__section__title">
            <div className="shots-summary__body__section__title__value">
              {homeShots} ({homeShotsOnTarget})
            </div>
          </div>
          <div className="shots-summary__body__section__graphic">
            <div
              className="shots-summary__body__section__graphic__total-shots"
              style={{
                height: homeTotalShotsHeight + "%",
                width: homeTotalShotsWidth + "%",
              }}
            ></div>
            <div
              className="shots-summary__body__section__graphic__shots-on-goal"
              style={{
                height: homeShotsOnTargetHeight + "%",
                width: homeShotsOnTargetWidth + "%",
              }}
            ></div>
          </div>
        </div>

        <div className="shots-summary__body__section away">
          <div className="shots-summary__body__section__title">
            <div className="shots-summary__body__section__title__value">
              {awayShots} ({awayShotsOnTarget})
            </div>
          </div>
          <div className="shots-summary__body__section__graphic">
            <div
              className="shots-summary__body__section__graphic__total-shots"
              style={{
                height: awayTotalShotsHeight + "%",
                width: awayTotalShotsWidth + "%",
              }}
            ></div>
            <div
              className="shots-summary__body__section__graphic__shots-on-goal"
              style={{
                height: awayShotsOnTargetHeight + "%",
                width: awayShotsOnTargetWidth + "%",
              }}
            ></div>
          </div>
        </div>
      </div>
    </Card>
  );
}

function PlayerStats({ fixture, homeTeam, awayTeam, homeLineup, awayLineup }) {
  // console.log("PLAYER STATS FIXTURE:", fixture);
  const categories = [...fixture.event?.sport?.statTypeGroups]
    .filter((c) => ["Offense", "Defense"].includes(c.name))
    .sort((a, b) => b.name.localeCompare(a.name));

  const [selectedTeam, setSelectedTeam] = useState(homeTeam);
  const [selectedCategory, setSelectedCategory] = useState(categories?.[0]);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef();
  const tableDimensions = useElementDimensions(tableRef);

  const rows = fixture.statTables;

  const teamRows = rows
    .filter((r) => r.organization?.id === selectedTeam.organization?.id)
    .reduce((accum, curr) => {
      const playerRowIndex = accum.findIndex(
        (x) => x.profile.id === curr.profile.id
      );
      if (playerRowIndex < 0) {
        accum.push({
          profile: curr.profile,
          [curr.statType.name]: curr.value || 0,
        });
      } else {
        accum[playerRowIndex][curr.statType.name] = curr.value || 0;
      }
      return accum;
    }, []);

  // const awayTeamRows = rows
  //   .filter((r) => r.organization?.id === awayTeam.organization?.id)
  //   .reduce((accum, curr) => {
  //     const playerRowIndex = accum.findIndex(
  //       (x) => x.profile.id === curr.profile.id
  //     );
  //     if (playerRowIndex < 0) {
  //       accum.push({
  //         profile: curr.profile,
  //         [curr.statType.name]: curr.value || 0,
  //       });
  //     } else {
  //       accum[playerRowIndex][curr.statType.name] = curr.value || 0;
  //     }
  //     return accum;
  //   }, []);

  const playersWithNoStats = (
    selectedTeam.id === homeTeam.id ? homeLineup : awayLineup
  ).filter((l) => !teamRows.find((r) => r.profile?.id === l.profile?.id));
  // const awayPlayersWithNoStats = awayLineup.filter(
  //   (l) => !awayTeamRows.find((r) => r.profile?.id === l.profile?.id)
  // );

  const tableRows = [...teamRows, ...playersWithNoStats];
  const sortedTableRows = tableRows?.sort((a, b) => {
    if (sortField === "name") {
      if (sortOrder === "desc") {
        return b.profile.name.localeCompare(a.profile.name);
      } else {
        return a.profile.name.localeCompare(b.profile.name);
      }
    } else {
      const targetCell = tableCells.find((x) => x.label === sortField);
      if (sortOrder === "desc") {
        return (targetCell?.data(b) || 0) - (targetCell?.data(a) || 0);
      } else {
        return (targetCell?.data(a) || 0) - (targetCell?.data(b) || 0);
      }
    }
  });

  // console.log("Team rows:", sortedTableRows);
  // console.log("Team rows:", playersWithNoStats)

  const selectedTableCells = tableCells
    .filter((cell) => cell.groups.includes(selectedCategory.name))
    .map((c) => ({
      ...c,
      pixelWidth: (c.widthPercent * (tableDimensions?.width || 0)) / 100,
    }));

  const primaryColumnWidth = tableDimensions?.width
    ? tableDimensions.width -
      selectedTableCells.reduce((accum, curr) => {
        accum = accum + curr.pixelWidth;
        return accum;
      }, 0)
    : 0;

  return (
    <Card className="fixture-stats-page__player-stats">
      <div className="fixture-stats-page__player-stats__header" ref={tableRef}>
        <NewDropdown
          target={
            <div className="fixture-stats-page__player-stats__header__team">
              <div className="fixture-stats-page__player-stats__header__team__image">
                {selectedTeam?.organization?.profilePhoto && (
                  <Avatar media={selectedTeam.organization.profilePhoto} />
                )}
              </div>

              <div className="fixture-stats-page__player-stats__header__team__name">
                {selectedTeam.organization?.name}
              </div>

              <ChevronDown
                style={{ stroke: "var(--dark-2)", height: "14px" }}
              />
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="fixture-stats-page__player-stats__header__team__menu">
              <div
                className="fixture-stats-page__player-stats__header__team__menu__item"
                onClick={() => {
                  setSelectedTeam(homeTeam);
                  setShowMenu(false);
                }}
              >
                {homeTeam?.organization?.name}
              </div>
              <div
                className="fixture-stats-page__player-stats__header__team__menu__item"
                onClick={() => {
                  setSelectedTeam(awayTeam);
                  setShowMenu(false);
                }}
              >
                {awayTeam?.organization?.name}
              </div>
            </Card>
          )}
        />

        <NewDropdown
          wrapperClass="fixture-stats-page__player-stats__header__category__wrapper"
          target={
            <div className="fixture-stats-page__player-stats__header__category">
              {selectedCategory?.name}
              <ChevronDown
                style={{ stroke: "var(--dark-2)", height: "14px" }}
              />
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="fixture-stats-page__player-stats__header__category__menu">
              {categories
                .filter((c) => ["Offense", "Defense"].includes(c.name))
                .map((category) => (
                  <div
                    className="fixture-stats-page__player-stats__header__category__menu__item"
                    onClick={() => {
                      setSelectedCategory(category);
                      setShowMenu(false);
                    }}
                  >
                    {category.name}
                  </div>
                ))}
            </Card>
          )}
        />
      </div>
      <div className="fixture-stats-page__player-stats__table">
        <div className="fixture-stats-page__player-stats__table__header">
          <div className="table-stats__row">
            <div
              className="table-stats__row__data hover-pointer"
              style={{ width: primaryColumnWidth + "px" }}
              onClick={() => {
                setSortField("name");
                setSortOrder((prev) => {
                  if (prev === "asc") {
                    return "desc";
                  } else {
                    return "asc";
                  }
                });
              }}
            >
              Player{" "}
              {sortField === "name" ? (
                <PlayIcon
                  style={{
                    transform:
                      sortOrder === "asc" ? "rotate(-90deg)" : "rotate(90deg)",
                    fill: "var(--blue-lighter)",
                    height: "10px",
                  }}
                />
              ) : (
                ""
              )}
            </div>
            {selectedTableCells.map((cell) => (
              <div
                className="table-stats__row__data hover-pointer"
                style={{
                  ...cell.style,
                  flexShrink: 0,
                  width: cell.pixelWidth + "px",
                }}
                onClick={() => {
                  setSortField(cell.label);
                  setSortOrder((prev) => {
                    if (prev === "asc") {
                      return "desc";
                    } else {
                      return "asc";
                    }
                  });
                }}
              >
                <Tooltip text={cell.tooltip}>
                  {cell.label}{" "}
                  {sortField === cell.label ? (
                    <PlayIcon
                      style={{
                        transform:
                          sortOrder === "asc"
                            ? "rotate(-90deg)"
                            : "rotate(90deg)",
                        fill: "var(--blue-lighter)",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Tooltip>
              </div>
            ))}
          </div>
        </div>

        <div className="fixture-stats-page__player-stats__table__body">
          {sortedTableRows?.map((row, i) => (
            <div
              className="table-stats__row"
              style={{ ...(i % 2 ? { background: "var(--light-2)" } : {}) }}
            >
              <div
                className="table-stats__row__data hover-pointer"
                style={{ width: primaryColumnWidth + "px" }}
                onClick={() => history.push("/app/profile/" + row.profile.id)}
              >
                {row.profile.name}
              </div>
              {selectedTableCells.map((cell) => (
                <div
                  className="table-stats__row__data"
                  style={{
                    ...cell.style,
                    flexShrink: 0,
                    width: cell.pixelWidth + "px",
                  }}
                >
                  {cell.onClick ? (
                    <AppLink to={cell.onClick(row)}>
                      {cell.data ? cell.data(row) : ""}
                    </AppLink>
                  ) : cell.data ? (
                    <Tooltip text={cell.tooltip}>
                      <span
                        style={{
                          ...(!cell.data(row)
                            ? { opacity: 0.2 }
                            : { fontWeight: 700 }),
                        }}
                      >
                        {cell.data(row) || 0}
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ))}

          {/* {playersWithNoStats.map((row) => (
            <div className="table-stats__row">
              <div
                className="table-stats__row__data"
                style={{ width: "25%" }}
                onClick={(row) => "/app/profile/" + row.profile.id}
              >
                {row.profile.name}
              </div>
              {tableCells
                .filter((cell) => cell.groups.includes(selectedCategory.name))
                .map((cell) => (
                  <div
                    className="table-stats__row__data"
                    style={{
                      ...cell.style,
                      ...(!cell.data(row)
                        ? { opacity: 0.2 }
                        : { fontWeight: 700 }),
                    }}
                  >
                    {cell.onClick ? (
                      <AppLink to={cell.onClick(row)}>
                        {cell.data ? cell.data(row) : ""}
                      </AppLink>
                    ) : cell.data ? (
                      cell.data(row) || 0
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
          ))} */}
        </div>
      </div>
    </Card>
  );
}

function MatchStats({ homeStats = {}, awayStats = {}, homeTeam, awayTeam }) {
  return (
    <div className="fixture-stats-page__football__match-stats">
      <Card className="match-stats">
        <div className="card-title">Match Stats</div>
        <div className="match-stats__teams">
          <div className="match-stats__teams__team home">
            <div className="match-stats__teams__team__image">
              {homeTeam?.organization?.profilePhoto && (
                <Avatar media={homeTeam.organization.profilePhoto} />
              )}
            </div>
            <div className="match-stats__teams__team__name">
              {homeTeam.organization.name}
            </div>
          </div>

          <div className="match-stats__teams__team away">
            <div className="match-stats__teams__team__name">
              {awayTeam.organization.name}
            </div>
            <div className="match-stats__teams__team__image">
              {awayTeam?.organization?.profilePhoto && (
                <Avatar media={awayTeam.organization.profilePhoto} />
              )}
            </div>
          </div>
        </div>
        <div className="match-stats-list">
          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.points || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.points /
                        (homeStats.points + awayStats.points) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.points > awayStats.points
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Points</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.points /
                        (homeStats.points + awayStats.points) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.points > homeStats.points
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.points || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.assists || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.assists /
                        (homeStats.assists + awayStats.assists) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.assists > awayStats.assists
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Assists</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.assists /
                        (homeStats.assists + awayStats.assists) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.assists > homeStats.assists
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.assists || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.steals || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.steals /
                        (homeStats.steals + awayStats.steals) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.steals > awayStats.steals
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Steals</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.steals /
                        (homeStats.steals + awayStats.steals) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.steals > homeStats.steals
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.steals || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.blocks || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.blocks /
                        (homeStats.blocks + awayStats.blocks) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.blocks > awayStats.blocks
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Blocks</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.blocks /
                        (homeStats.blocks + awayStats.blocks) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.blocks > homeStats.blocks
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.blocks || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.offensiveRebounds || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.offensiveRebounds /
                        (homeStats.offensiveRebounds +
                          awayStats.offensiveRebounds) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.offensiveRebounds < awayStats.offensiveRebounds
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Off Reb</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.offensiveRebounds /
                        (homeStats.offensiveRebounds +
                          awayStats.offensiveRebounds) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.offensiveRebounds < homeStats.offensiveRebounds
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.offensiveRebounds || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.defensiveRebounds || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.defensiveRebounds /
                        (homeStats.defensiveRebounds +
                          awayStats.defensiveRebounds) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.defensiveRebounds < awayStats.defensiveRebounds
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Def Reb</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.defensiveRebounds /
                        (homeStats.defensiveRebounds +
                          awayStats.defensiveRebounds) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.defensiveRebounds < homeStats.defensiveRebounds
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.defensiveRebounds || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.turnovers || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.turnovers /
                        (homeStats.turnovers + awayStats.turnovers) || 0) *
                        100 +
                      "%",
                    background:
                      homeStats.turnovers < awayStats.turnovers
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">T/O</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.turnovers /
                        (homeStats.turnovers + awayStats.turnovers) || 0) *
                        100 +
                      "%",
                    background:
                      awayStats.turnovers < homeStats.turnovers
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.turnovers || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.fouls || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.fouls / (homeStats.fouls + awayStats.fouls) ||
                        0) *
                        100 +
                      "%",
                    background:
                      homeStats.fouls < awayStats.fouls
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Fouls</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.fouls / (homeStats.fouls + awayStats.fouls) ||
                        0) *
                        100 +
                      "%",
                    background:
                      awayStats.fouls < homeStats.fouls
                        ? "var(--red-lighter)"
                        : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.fouls || 0}</div>
          </div>
        </div>
      </Card>
    </div>
  );
}

function BasketballStatsContent({
  id,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  sport,
}) {
  // console.log(id, homeTeam, awayTeam);
  const { loading, error, data } = useQuery(BASKETBALL_STATS, {
    variables: { id },
  });

  if (error) {
    console.log(error);

    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const homeStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === homeTeam.organization.id
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === awayTeam.organization.id
  );

  // console.log(homeStats);
  // console.log(awayStats);

  return (
    <div className="fixture-stats-page">
      <div
        className="left-column"
        style={{ gridColumnStart: 1, gridColumnEnd: 6 }}
      >
        <MatchStats
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeStats={homeStats}
          awayStats={awayStats}
        />

        {sport !== "Basketball" && (
          <ShotsSummary
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            homeStats={homeStats}
            awayStats={awayStats}
            homeShots={homeStats.fga1 || 0}
            homeShotsOnTarget={homeStats.fgm1 || 0}
            awayShots={awayStats.fga1 || 0}
            awayShotsOnTarget={awayStats.fgm1 || 0}
            title={"1 Pt. Field Goals(Made)"}
          />
        )}

        <ShotsSummary
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeStats={homeStats}
          awayStats={awayStats}
          homeShots={homeStats.fga2 || 0}
          homeShotsOnTarget={homeStats.fgm2 || 0}
          awayShots={awayStats.fga2 || 0}
          awayShotsOnTarget={awayStats.fgm2 || 0}
          title={"2 Pt. Field Goals(Made)"}
        />

        {sport === "Basketball" && (
          <ShotsSummary
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            homeStats={homeStats}
            awayStats={awayStats}
            homeShots={homeStats.fga3 || 0}
            homeShotsOnTarget={homeStats.fgm3 || 0}
            awayShots={awayStats.fga3 || 0}
            awayShotsOnTarget={awayStats.fgm3 || 0}
            title={"3 Pt. Field Goals(Made)"}
          />
        )}

        {/* <Card className="team-details">
          <div className="team-name">{homeTeam.organization.name}</div>
          <div className="logo"></div>
        </Card> */}

        {/* <Card className="basketball-shots-summary">
          <div className="basketball-shots-summary__item">
            <div className="basketball-shots-summary__item__values">
              <div className="basketball-shots-summary__item__values__value">
                <div className="basketball-shots-summary__item__values__value__number">
                  {homeStats.fgm1 || 0}
                </div>
                <div className="basketball-shots-summary__item__values__value__label">
                  1FGM
                </div>
              </div>
              <div className="basketball-shots-summary__item__values__value">
                <div className="basketball-shots-summary__item__values__value__number">
                  {homeStats?.fga1 || 0}
                </div>
                <div className="basketball-shots-summary__item__values__value__label">
                  1FGA
                </div>
              </div>
            </div>
            <div className="basketball-shots-summary__item__graph">
              <PieChart
                value={homeStats.fgm1 || 0}
                total={homeStats.fga1 || 1}
                cutout="70%"
                color={"#78c9fc"}
              />
              <div className="percentage">
                {(homeStats.fgm1 || 0) / (homeStats.fga1 || 1)}%
              </div>
            </div>
          </div>
          <div className="basketball-shots-summary__item">
            <div className="basketball-shots-summary__item__values">
              <div className="basketball-shots-summary__item__values__value">
                <div className="basketball-shots-summary__item__values__value__number">
                  {homeStats?.fgm2 || 0}
                </div>
                <div className="basketball-shots-summary__item__values__value__label">
                  2FGM
                </div>
              </div>
              <div className="basketball-shots-summary__item__values__value">
                <div className="basketball-shots-summary__item__values__value__number">
                  {homeStats?.fga2 || 0}
                </div>
                <div className="basketball-shots-summary__item__values__value__label">
                  2FGA
                </div>
              </div>
            </div>

            <div className="basketball-shots-summary__item__graph">
              <PieChart
                value={homeStats.fgm2 || 0}
                total={homeStats.fga2 || 1}
                cutout="70%"
                color={"#78c9fc"}
              />
              <div className="percentage">
                {(homeStats.fgm2 || 0) / (homeStats.fga2 || 1)}%
              </div>
            </div>
          </div>
          <div className="basketball-shots-summary__item">
            <div className="basketball-shots-summary__item__values">
              <div className="basketball-shots-summary__item__values__value">
                <div className="basketball-shots-summary__item__values__value__number">
                  {homeStats?.fgm3 || 0}
                </div>
                <div className="basketball-shots-summary__item__values__value__label">
                  3FGM
                </div>
              </div>
              <div className="basketball-shots-summary__item__values__value">
                <div className="basketball-shots-summary__item__values__value__number">
                  {homeStats?.fga3 || 0}
                </div>
                <div className="basketball-shots-summary__item__values__value__label">
                  3FGA
                </div>
              </div>
            </div>

            <div className="basketball-shots-summary__item__graph">
              <PieChart
                value={homeStats.fgm3 || 0}
                total={homeStats.fga3 || 1}
                cutout="70%"
                color={"#78c9fc"}
              />
              <div className="percentage">
                {(homeStats.fgm3 || 0) / (homeStats.fga3 || 1)}%
              </div>
            </div>
          </div>

          <div className="horizontal-stacked-bar-summary">
            {homeStats.ftm && (
              <div
                className="fill"
                style={{
                  width: `${
                    ((homeStats.fta || 0) / (homeStats.ftm || 1)) * 100
                  }%`,
                }}
              >
                {homeStats.ftm || 0} FTM
              </div>
            )}

            <div
              className="background"
              style={{
                width: `${
                  100 - ((homeStats.fta || 0) / (homeStats.ftm || 1)) * 100
                }%`,
              }}
            >
              {homeStats.fta || 0} FTA
            </div>
          </div>
        </Card> */}
      </div>

      <div
        className="right-column"
        style={{ gridColumnStart: 6, gridColumnEnd: 13 }}
      >
        <PlayerStats
          fixture={data.fixture}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
        />
      </div>

      {/* <div className="middle-column">
        <Card className="match-stats">
          <div className="card-title">Match Stats</div>
          <div className="match-stats-list">
            <div className="match-stats-list-item">
              <div className="home-value">{homeStats.points || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.points /
                          (homeStats.points + awayStats.points) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.points > awayStats.points
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Points</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.points /
                          (homeStats.points + awayStats.points) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.points > homeStats.points
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{awayStats.points || 0}</div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">{homeStats.assists || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.assists /
                          (homeStats.assists + awayStats.assists) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.assists > awayStats.assists
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Assists</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.assists /
                          (homeStats.assists + awayStats.assists) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.assists > homeStats.assists
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{awayStats.assists || 0}</div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">{homeStats.steals || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.steals /
                          (homeStats.steals + awayStats.steals) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.steals > awayStats.steals
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Steals</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.steals /
                          (homeStats.steals + awayStats.steals) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.steals > homeStats.steals
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{awayStats.steals || 0}</div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">{homeStats.blocks || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.blocks /
                          (homeStats.blocks + awayStats.blocks) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.blocks > awayStats.blocks
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Blocks</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.blocks /
                          (homeStats.blocks + awayStats.blocks) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.blocks > homeStats.blocks
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{awayStats.blocks || 0}</div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">
                {homeStats.offensiveRebounds || 0}
              </div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.offensiveRebounds /
                          (homeStats.offensiveRebounds +
                            awayStats.offensiveRebounds) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.offensiveRebounds <
                        awayStats.offensiveRebounds
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Off Reb</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.offensiveRebounds /
                          (homeStats.offensiveRebounds +
                            awayStats.offensiveRebounds) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.offensiveRebounds <
                        homeStats.offensiveRebounds
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">
                {awayStats.offensiveRebounds || 0}
              </div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">
                {homeStats.defensiveRebounds || 0}
              </div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.defensiveRebounds /
                          (homeStats.defensiveRebounds +
                            awayStats.defensiveRebounds) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.defensiveRebounds <
                        awayStats.defensiveRebounds
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Def Reb</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.defensiveRebounds /
                          (homeStats.defensiveRebounds +
                            awayStats.defensiveRebounds) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.defensiveRebounds <
                        homeStats.defensiveRebounds
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">
                {awayStats.defensiveRebounds || 0}
              </div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">{homeStats.turnovers || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.turnovers /
                          (homeStats.turnovers + awayStats.turnovers) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.turnovers < awayStats.turnovers
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">T/O</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.turnovers /
                          (homeStats.turnovers + awayStats.turnovers) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.turnovers < homeStats.turnovers
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{awayStats.turnovers || 0}</div>
            </div>

            <div className="match-stats-list-item">
              <div className="home-value">{homeStats.fouls || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (homeStats.fouls /
                          (homeStats.fouls + awayStats.fouls) || 0) *
                          100 +
                        "%",
                      background:
                        homeStats.fouls < awayStats.fouls
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Fouls</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (awayStats.fouls /
                          (homeStats.fouls + awayStats.fouls) || 0) *
                          100 +
                        "%",
                      background:
                        awayStats.fouls < homeStats.fouls
                          ? "var(--red-lighter)"
                          : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{awayStats.fouls || 0}</div>
            </div>
          </div>
        </Card>
        
        <Card className="head-to-head">
          <div className="card-title">Head to Head</div>
        </Card>
      </div> */}
    </div>
  );
}

function BasketballStats() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FIXTURE, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;
  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[0]?.organization?.id
  );

  const awayLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[1]?.organization?.id
  );

  const sport = fixture?.event?.sport?.name;

  return (
    <BasketballStatsContent
      id={id}
      sport={sport}
      homeTeam={participants[0]}
      awayTeam={participants[1]}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
    />
  );
}

export default BasketballStats;
