import "./Activity.css";
import moment from "moment";

function Activity({ dayCount = 365 }) {
  const columns = new Array(dayCount)
    .fill()
    .map((_, i) => {
      return moment().subtract(i, "day");
    })
    .reduce((accum, curr, i) => {
      const index = Math.floor(i / 7);
      if (!accum[index]) {
        accum[index] = [];
      }

      accum[index].push(curr);

      return accum;
    }, []);

  const daysOfTheWeek = ["", "M", "", "W", "", "F", ""];

  const months = columns.map((c, i) => {
    const minColumnDate = c[c.length - 1];
    return minColumnDate.format("MMM");
  });

  return (
    <div className="app-activity-container">
      <div className="days">
        {daysOfTheWeek.map((d, i) => (
          <div key={"day-" + i} className="day">
            {d}
          </div>
        ))}
      </div>

      <div className="weeks">
        {columns.map((c, i) => (
          <div key={"column-" + i} className="column">
            <div className="month">
              {months[i] !== months[i + 1] ? months[i] : ""}
            </div>
            {c.map((day, j) => (
              <div key={"cell-" + j} className="cell" />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Activity;
