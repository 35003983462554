import { gql } from "@apollo/client";

const UPDATE_FIXTURE = gql`
  mutation updateFixtureRole(
    $where: UniqueInput!
    $data: UpdateFixtureRoleInput!
  ) {
    updateFixtureRole(where: $where, data: $data) {
      id
      order
      sortOrder
      type {
        id
        name
      }
      subtypes {
        id
        name
      }
      profile {
        id
        name
        lastname
        firstname
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
      }
      formation {
        id
        name
        positions {
          id
          positionType {
            id
            shortName
          }
          x
          y
        }
      }
      position {
        id
      }
    }
  }
`;

export default UPDATE_FIXTURE;
