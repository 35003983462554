import { gql } from "@apollo/client";

const SYNC_CACHED_PROFILE_STATS = gql`
  query syncProfileStatsQuery(
    $profileId: ID!
    $sport: String!
    $events: [ID!]
  ) {
    syncCachedProfileStats(
      profileId: $profileId
      sport: $sport
      events: $events
    ) {
      id
      updatedAt
      data
    }
  }
`;

export default SYNC_CACHED_PROFILE_STATS;
