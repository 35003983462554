import "./EventStandings.css";
import { useRef, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { EVENT_STANDINGS } from "../../../api/event";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import { Link } from "react-router-dom";
import Placeholder from "../../../components/Placeholder";

function getTableConfig({ sport = {} }) {
  if (sport.name === "Football") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        fixed: true,
        hasLink: (item) => {
          return "/app/page/" + item.organization?.id;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },

      {
        label: "GF",
        tooltip: "Goals For",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Goals For")?.value || 0;
        },
      },
      {
        label: "GA",
        tooltip: "Goals Against",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Goals Against")?.value || 0
          );
        },
      },
      {
        label: "GD",
        tooltip: "Goal Difference",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Difference")?.value || 0;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name?.includes("Basketball")) {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
        legend: false,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        fixed: true,
        hasLink: (item) => {
          return "/app/page/" + item.organization?.id;
        },
        legend: false,
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
        legend: true,
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
        legend: true,
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
        legend: true,
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
        legend: true,
      },

      {
        label: "Win %",
        tooltip: "Win Percentage",
        style: { width: "60px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Win Percentage")?.value || 0
          ).toFixed(2);
        },
        legend: true,
      },
      {
        label: "PF",
        tooltip: "Points For",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points For")?.value || 0;
        },
        legend: true,
      },

      {
        label: "PA",
        tooltip: "Points Against",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Points Against")?.value || 0
          );
        },
        legend: true,
      },
      {
        label: "+/-",
        tooltip: "Point Difference",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            (item.metas.find((m) => m.label === "Points For")?.value || 0) -
            (item.metas.find((m) => m.label === "Points Against")?.value || 0)
          );
        },
        legend: true,
      },
      {
        label: "Pts",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
        legend: true,
      },
    ];
  } else if (sport.name === "Cricket") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        fixed: true,
        hasLink: (item) => {
          return "/app/page/" + item.organization?.id;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "NRR",
        tooltip: "Net Run Rate",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas
              .find((m) => m.label === "Net Run Rate")
              ?.value?.toFixed(3) || "-"
          );
        },
      },
      // {
      //   label: "For",
      //   tooltip: "Run Rate For",
      //   style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate For")?.stringValue ||
      //       "-"
      //     );
      //   },
      // },
      // {
      //   label: "Against",
      //   tooltip: "Run Rate Against ",
      //   style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate Against")
      //         ?.stringValue || "-"
      //     );
      //   },
      // },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name === "Badminton") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        placeholderWidth: "160px",
        fixed: true,
        hasLink: (item) => {
          return `/app/${item.organization ? "page" : "profile"}/${
            item.organization?.id || item.profile?.id
          }`;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },

      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "+/-",
        tooltip: "Point Difference",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          const scored =
            item.metas.find((m) => m.label === "Points For")?.value || 0;
          const conceded =
            item.metas.find((m) => m.label === "Points Against")?.value || 0;
          return scored - conceded;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        placeholderWidth: "160px",
        fixed: true,
        hasLink: (item) => {
          return `/app/${item.organization ? "page" : "profile"}/${
            item.organization?.id || item.profile?.id
          }`;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  }
}

function StandingsTable({ title = "Standings", tableConfig, standing }) {
  const [isScrolled, setIsScrolled] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const div = scrollRef?.current;
    const handleScroll = () => {
      // console.log(div.scrollLeft);
      if (div.scrollLeft > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => div?.removeEventListener("scroll", handleScroll);
  }, [scrollRef]);

  return (
    <div className="event-standings-mobile__tables__table">
      <div className="event-standings-mobile__tables__table__title">
        {title}
      </div>
      <div className="event-standings-mobile__tables__table__columns">
        <div className="event-standings-mobile__tables__table__columns-fixed">
          <div className="event-standings-mobile__tables__table__header">
            <div className="row">
              {tableConfig
                .filter((c) => c.fixed)
                .map((c) => (
                  <div
                    key={"event-standings-header-" + standing.id + c.label}
                    className="td"
                    style={c.style}
                  >
                    {c.label}
                  </div>
                ))}
            </div>
          </div>
          <div className="event-standings-mobile__tables__table__body">
            {standing.items.map((item) => (
              <div key={"event-standings-item-" + item.id} className="row">
                {tableConfig
                  .filter((c) => c.fixed)
                  .map((c) => (
                    <div
                      key={"event-standings-item-td-" + item.id + c.label}
                      className="td"
                      style={c.style}
                    >
                      {c.hasImage && (
                        <div className="logo">
                          {c.hasImage(item) && (
                            <Avatar media={c.hasImage(item)} />
                          )}
                        </div>
                      )}

                      {c.hasLink ? (
                        <Link to={c.hasLink(item)}>{c.value(item)}</Link>
                      ) : (
                        c.value(item)
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>

        <div
          className={
            "event-standings-mobile__tables__table__columns-scroll " +
            (isScrolled ? "inset" : "")
          }
          ref={scrollRef}
        >
          <div className="event-standings-mobile__tables__table__header">
            <div className="row">
              {tableConfig
                .filter((c) => !c.fixed)
                .map((c) => (
                  <div
                    key={"event-standings-header-" + standing.id + c.label}
                    className="td"
                    style={c.style}
                  >
                    {c.label}
                  </div>
                ))}
            </div>
          </div>
          <div className="event-standings-mobile__tables__table__body">
            {standing.items.map((item) => (
              <div key={"event-standings-item-" + item.id} className="row">
                {tableConfig
                  .filter((c) => !c.fixed)
                  .map((c) => (
                    <div
                      key={"event-standings-item-td-" + item.id + c.label}
                      className="td"
                      style={c.style}
                    >
                      {c.hasImage && (
                        <div className="logo">
                          {c.hasImage(item) && (
                            <Avatar media={c.hasImage(item)} />
                          )}
                        </div>
                      )}

                      {c.hasLink ? (
                        <Link to={c.hasLink(item)}>{c.value(item)}</Link>
                      ) : (
                        c.value(item)
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="event-standings-mobile__tables__table__legend">
        {tableConfig
          .filter((x) => !!x.legend)
          .map((x) => (
            <div className="event-standings-mobile__tables__table__legend__item">
              {x.label} - {x.tooltip}
            </div>
          ))}
      </div>
    </div>
  );
}

export default function EventStandings({ id }) {
  const { loading, error, data } = useQuery(EVENT_STANDINGS, {
    variables: { id, take: 1 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventStandings",
        query: "EVENT_STANDINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-standings-mobile">
        <div className="event-standings-mobile__tables">
          <div className="event-standings-mobile__tables__table">
            <div className="event-standings-mobile__tables__table__title">
              <Placeholder
                style={{ height: "10px", width: "80px" }}
                pulse={true}
              />
            </div>
            <div className="event-standings-mobile__tables__table__columns">
              <div className="event-standings-mobile__tables__table__columns-fixed">
                <div className="event-standings-mobile__tables__table__header">
                  <div className="row">
                    {[1, 2, 3].map((c) => (
                      <div key={"event-standings-header-" + c} className="td">
                        <Placeholder
                          style={{
                            height: "10px",
                            width: "20px",
                            marginRight: "12px",
                          }}
                          pulse={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="event-standings-mobile__tables__table__body">
                  <Placeholder
                    style={{ height: "10px", width: "100%" }}
                    pulse={true}
                  />

                  <Placeholder
                    style={{ height: "10px", width: "100%", marginTop: "10px" }}
                    pulse={true}
                  />

                  <Placeholder
                    style={{ height: "10px", width: "100%", marginTop: "10px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sport = data?.event?.sport;
  const standing = data?.event?.standings?.[0];
  const parts = data?.event?.parts;
  const tableConfig = getTableConfig({ sport });

  return (
    <div className="event-standings-mobile">
      <div className="event-standings-mobile__tables">
        {standing && (
          <StandingsTable
            title={"Standings"}
            tableConfig={tableConfig}
            standing={standing}
          />
        )}

        {parts?.map((p) => {
          if (p.standings?.length) {
            return (
              <StandingsTable
                key={"event-part-standings-card-" + p.id}
                title={p.label + " Standings"}
                tableConfig={tableConfig}
                standing={p.standings[0]}
              />
            );
          } else {
            return <div key={"empty-part-" + p.id} />;
          }
        })}

        {!standing && <div className="no-data">No standings added yet.</div>}
      </div>
    </div>
  );
}
