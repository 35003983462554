import { gql } from "@apollo/client";

const CREATE_FOLLOW = gql`
  mutation createFollow($followingId: ID!, $followingType: String!) {
    createFollow(followingId: $followingId, followingType: $followingType) {
      id
      confirmed
      handled
      follower {
        id
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
      following {
        id
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export default CREATE_FOLLOW;
