import Square from "../Square";
import "./VideosSummaryCard.css";
import { useHistory, useLocation } from "react-router-dom";
import Placeholder from "../../components/Placeholder";
import { useQuery } from "@apollo/client";
import makeFileUrl from "../../utils/makeFileUrl";
import YouTubeVideo from "../../components/YouTubeVideo";
import captureException from "../../utils/captureException";

export default function VideosSummaryCard({ id, query, getVideo }) {
  const history = useHistory();
  const location = useLocation();

  const { loading, error, data } = useQuery(query, {
    variables: { id, type: "VIDEO", take: 1 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "VideosSummaryCard",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="photos-summary-mobile">
        <div className="photos-summary-mobile__header">
          <div className="photos-summary-mobile__header__title">
            <Placeholder
              style={{ height: "12px", width: "40px" }}
              pulse={true}
            />
          </div>

          <div className="photos-summary-mobile__header__link">
            <Placeholder
              style={{ height: "12px", width: "40px" }}
              pulse={true}
            />
          </div>
        </div>

        <div className="photos-summary-mobile__body">
          <Square>
            {({ squareRef, dimensions }) => (
              <div
                ref={squareRef}
                className="videos-summary-mobile__body"
                style={{ height: dimensions.width / 2 }}
              ></div>
            )}
          </Square>
        </div>
      </div>
    );
  }

  const video = getVideo(data);

  return (
    <div className="videos-summary-mobile">
      <div className="videos-summary-mobile__header">
        <div className="videos-summary-mobile__header__title">Videos</div>

        <div
          className="videos-summary-mobile__header__link"
          onClick={() => {
            history.replace(location.pathname + "?t=videos");
          }}
        >
          See All
        </div>
      </div>

      {video ? (
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              ref={squareRef}
              className="videos-summary-mobile__body"
              style={{ height: dimensions.width / 2 }}
            >
              <div className="video" style={{ height: "100%" }}>
                {video.isExternal ? (
                  <YouTubeVideo filename={video.filename} />
                ) : (
                  <video controls controlsList="nodownload">
                    <source src={makeFileUrl(video)} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          )}
        </Square>
      ) : (
        <div className="no-data">No videos found.</div>
      )}
    </div>
  );
}
