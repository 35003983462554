// V1
import "./ProfileCard.css";
import Card from "../../Card";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as DirectoryIcon } from "../../../icons/directory.svg";
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg";
import { ReactComponent as ProfileIcon } from "../../../icons/profile.svg";
import Placeholder from "../../Placeholder";
import { useQuery } from "@apollo/client";
// import { USER_PROFILE } from "../../../api/user";
import getNames from "../../../utils/getNames";
import Avatar from "../../Avatar/Avatar";
import captureException from "../../../utils/captureException";
import { USER_PROFILE } from "../../../api/functions";

function ProfileCard() {
  const { loading, error, data } = useQuery(
    USER_PROFILE({
      selectionSet: `
      name
      handle
      profilePhoto {
        id
        filename
      }
    `,
    })
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileCard",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <Card className="home-profile-card">
        <div className="home-profile-card__header">
          <div className="home-profile-card__header__image"></div>

          <div className="home-profile-card__header__meta">
            <div className="primary">
              <Placeholder style={{ width: "120px" }} pulse={true} />
            </div>
            <div className="secondary">
              <Placeholder style={{ width: "60px" }} pulse={true} />
            </div>
          </div>
        </div>
        <div className="home-profile-card__desc">
          <Placeholder
            style={{ width: "100%", marginBottom: "12px" }}
            pulse={true}
          />
          <Placeholder
            style={{ width: "100%", marginBottom: "12px" }}
            pulse={true}
          />
          <Placeholder style={{ width: "70%" }} pulse={true} />
        </div>

        <div className="links">
          <Link to={"/app"} className="link active">
            <div className="icon">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
            <div className="desc">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
          </Link>

          <Link to={"/app"} className="link">
            <div className="icon">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
            <div className="desc">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
          </Link>

          <Link to={"/app"} className="link">
            <div className="icon">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
            <div className="desc">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
          </Link>

          <Link to={"/app"} className="link">
            <div className="icon">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
            <div className="desc">
              <Placeholder style={{ width: "24px" }} pulse={true} />
            </div>
          </Link>
        </div>
      </Card>
    );
  }

  const userProfile = data.userProfile;

  return (
    <Card className="home-profile-card">
      <div className="home-profile-card__header">
        <div className="home-profile-card__header__image">
          {userProfile?.profilePhoto && (
            <Avatar media={userProfile.profilePhoto} />
          )}
        </div>

        <div className="home-profile-card__header__meta">
          <div className="primary">
            Welcome {getNames(userProfile?.name)?.firstname} !
          </div>
          <div className="secondary">@{userProfile?.handle}</div>
        </div>
      </div>
      <div className="home-profile-card__desc">
        This is your dashboard. Use it to stay updated with the pages you follow
        and to discover cool sporting activities around you.{" "}
      </div>

      <div className="links">
        <Link to={"/app"} className="link active">
          <div className="icon">
            <HomeIcon style={{ height: 20 }} />
          </div>
          <div className="desc">HOME</div>
        </Link>

        <Link to={"/app/events"} className="link">
          <div className="icon">
            <CalendarIcon style={{ height: 20 }} />
          </div>
          <div className="desc">EVENTS</div>
        </Link>

        <Link to={"/app/directory"} className="link">
          <div className="icon">
            <DirectoryIcon style={{ height: 20 }} />
          </div>
          <div className="desc">DIRECTORY</div>
        </Link>

        <Link to={"/app/profile/" + userProfile?.id} className="link">
          <div className="icon">
            <ProfileIcon style={{ height: 20 }} />
          </div>
          <div className="desc">PROFILE</div>
        </Link>
      </div>
    </Card>
  );
}

export default ProfileCard;
