import "./CurrentOver.css";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import Spinner from "../../../components/Spinner";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import { useQuery, useMutation, gql } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";
import { CREATE_CRICKET_STAT } from "../../../api/fixture";
import extraAbbrevs from "../utils/extraAbbrevs";
import SelectPlayer from "../SelectPlayer";
import ballsCompleted from "../utils/ballsCompleted";
import SelectDismissedBatsman from "../SelectDismissedBatsman";
import captureException from "../../../utils/captureException";
import ErrorNotification from "../ErrorNotification";
import makeStat from "../utils/makeStat";

function RunContainer({
  value,
  currentBallLabel,
  cricketLiveScoring,
  powerplay,
}) {
  const [
    {
      batsmen,
      bowler,
      strikeIndex,
      selectedExtras,
      selectedWicket,
      eventId,
      fixtureId,
      selectedPeriod,
      battingTeam,
      bowlingTeam,
      statTypes,
      currentOverNumber,
      selectedFielder,
      dismissedBatsman,
      setOverlay,
      bowlingSummaries = [],
      cache: cachedState,
      updateCache,
    },
    setCricketScoringState,
  ] = useCricketScoringState();

  const [createCricketStat, { loading }] = useMutation(CREATE_CRICKET_STAT, {
    update: (cache, { data: { createCricketStat } }) => {
      cache.modify({
        id: cache.identify(cricketLiveScoring),
        fields: {
          overStats(existingRefs, { readField }) {
            const newRef = cache.writeFragment({
              data: createCricketStat,
              fragment: gql`
                fragment NewOverItem on OverItem {
                  id
                  over
                  ballsBowled {
                    id
                    value
                    profile {
                      id
                      name
                    }
                    organization {
                      id
                      name
                    }
                  }
                  ballsFaced {
                    id
                  }
                  runsScored {
                    id
                    value
                    profile {
                      id
                      name
                    }
                    organization {
                      id
                      name
                    }
                  }
                  runsConceded {
                    id
                    value
                    profile {
                      id
                      name
                    }
                  }
                  wicket {
                    id
                    statType {
                      id
                      name
                    }
                    profile {
                      id
                    }
                    organization {
                      id
                    }
                  }
                  wicketTaken {
                    id
                    profile {
                      id
                    }
                    organization {
                      id
                    }
                  }
                  extras {
                    id
                    statType {
                      id
                      name
                    }
                    value
                  }
                  fielding {
                    id
                    profile {
                      id
                    }
                  }
                }
              `,
            });

            return [...existingRefs, newRef];
          },
        },
      });

      // console.log("RETURNED OVER ITEM:", createCricketStat);

      cache.modify({
        id: "CricketBattingSummaryItem:" + batsmen[strikeIndex].profile.id,
        fields: {
          runs(currentRuns) {
            return currentRuns + createCricketStat.runsScored.value;
          },
          balls(currentBalls) {
            return currentBalls + 1;
          },
          fours(currentFours) {
            if (createCricketStat.runsScored.value === 4) {
              return currentFours + 1;
            } else {
              return currentFours;
            }
          },
          sixes(currentSixes) {
            if (createCricketStat.runsScored.value === 6) {
              return currentSixes + 1;
            } else {
              return currentSixes;
            }
          },
        },
      });

      cache.modify({
        id: "CricketBowlingSummaryItem:" + bowler.profile.id,
        fields: {
          runs(currentRuns) {
            return currentRuns + createCricketStat.runsConceded.value;
          },
          balls(currentBalls) {
            return currentBalls + 1;
          },
          wickets(currentWickets) {
            if (createCricketStat.wicketTaken) {
              return currentWickets + 1;
            } else {
              return currentWickets;
            }
          },
          extras(currentExtras) {
            if (createCricketStat.extras.length) {
              const extrasValue = createCricketStat.extras.reduce(
                (accum, curr) => {
                  accum = accum + curr.value;
                  return accum;
                },
                0
              );
              return currentExtras + extrasValue;
            } else {
              return currentExtras;
            }
          },
        },
      });

      cache.modify({
        id: "CricketLiveScoreItem:" + battingTeam.id,
        fields: {
          runs(currentRuns) {
            const r = createCricketStat.runsScored.value || 0;
            const e =
              createCricketStat.extras?.reduce((accum, curr) => {
                accum = accum + curr.value;
                return accum;
              }, 0) || 0;

            const addedRuns = r + e;

            return currentRuns + (powerplay ? addedRuns * 2 : addedRuns);
          },
          wickets(currentWickets) {
            if (createCricketStat.wicket) {
              return currentWickets + 1;
            } else {
              return currentWickets;
            }
          },
          overs(currentOvers) {
            if (createCricketStat.ballsBowled.value > currentOvers) {
              return createCricketStat.ballsBowled.value;
            } else {
              return currentOvers;
            }
          },
        },
      });

      cache.modify({
        id: "CricketPeriodScoreItem:" + selectedPeriod.id,
        fields: {
          runs(currentRuns) {
            const r = createCricketStat.runsScored.value || 0;
            const e =
              createCricketStat.extras?.reduce((accum, curr) => {
                accum = accum + curr.value;
                return accum;
              }, 0) || 0;

            const addedRuns = r + e;

            return currentRuns + (powerplay ? addedRuns * 2 : addedRuns);
          },
          wickets(currentWickets) {
            if (createCricketStat.wicket) {
              return currentWickets + 1;
            } else {
              return currentWickets;
            }
          },
          overs(currentOvers) {
            if (createCricketStat.ballsBowled.value > currentOvers) {
              return createCricketStat.ballsBowled.value;
            } else {
              return currentOvers;
            }
          },
        },
      });

      const newBatsmen = [...batsmen];
      const newStrikeIndex =
        value % 2 ? (strikeIndex === 1 ? 0 : 1) : strikeIndex;

      if (selectedWicket) {
        if (
          [
            "Bowled",
            "LBW",
            "Caught",
            "Caught & Bowled",
            "Stumped",
            "Hit Wicket",
            "Double Hit",
            "Out of Bounds",
            "Out by Dots",
          ].includes(selectedWicket)
        ) {
          newBatsmen[strikeIndex] = {};
        }

        if (
          [
            "Run Out",
            "Retired",
            "Obstruction",
            "Ball Handling",
            "Timed Out",
          ].includes(selectedWicket)
        ) {
          const dismissedBatsmanIndex = newBatsmen.findIndex(
            (x) => x.profile.id === dismissedBatsman.id
          );
          if (dismissedBatsmanIndex > -1) {
            newBatsmen[dismissedBatsmanIndex] = {};
          }
        }
      }

      setCricketScoringState({
        batsmen: newBatsmen,
        strikeIndex: newStrikeIndex,
        selectedWicket: "",
        selectedExtras: [],
        selectedFielder: "",
        dismissedBatsman: "",
      });

      updateCache({
        variables: {
          where: { id: fixtureId },
          data: {
            statsCacheState: {
              selectedPeriodId: selectedPeriod.id,
              periods: {
                ...(cachedState?.periods || {}),
                [selectedPeriod.id]: {
                  battingTeam,
                  bowlingTeam,
                  batsmen: newBatsmen,
                  bowler,
                  strikeIndex: newStrikeIndex,
                  currentOverNumber,
                },
              },
            },
          },
        },
        update: (c, { data: { updateFixture } }) => {
          setCricketScoringState({
            cache: updateFixture.statsCacheState,
          });
        },
      });

      // onAddStat();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBoxCricket.RunContainer",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const submit = () => {
    if (loading) return;

    const batsman = batsmen[strikeIndex];

    if (!batsman?.profile) {
      setOverlay(
        <ErrorNotification
          title={"Invalid batsman"}
          message={
            "Please make sure the on strike batsman is selected and try again"
          }
        />
      );
      return;
    }

    if (!bowler?.profile) {
      setOverlay(
        <ErrorNotification
          title={"Invalid bowler"}
          message={"Please make sure a bowler is selected and try again"}
        />
      );
      return;
    }

    let runsScoredValue = value;

    if (
      selectedExtras?.length &&
      !(selectedExtras.length === 1 && selectedExtras[0] === "No Ball")
    ) {
      runsScoredValue = 0;
    }

    function getExtraValue(x, value) {
      if (x === "Wide") {
        return 1 + value;
      } else if (x === "No Ball") {
        return 1;
      } else if (x === "Bye") {
        return value;
      } else if (x === "Leg Bye") {
        return value;
      }
    }

    const runsScoredStat = makeStat({
      eventId,
      fixtureId,
      fixturePeriodId: selectedPeriod?.id,
      organization: battingTeam,
      profile: batsman.profile,
      statType: statTypes.find((s) => s.name === "Runs Scored"),
      value: runsScoredValue,
    });

    const ballsFacedStat = selectedExtras?.includes("Wide")
      ? false
      : makeStat({
          eventId,
          fixtureId,
          fixturePeriodId: selectedPeriod?.id,
          organization: battingTeam,
          profile: batsman.profile,
          statType: statTypes.find((s) => s.name === "Balls Faced"),
          value: 1,
        });

    const ballsBowledStat = makeStat({
      eventId,
      fixtureId,
      fixturePeriodId: selectedPeriod?.id,
      organization: bowlingTeam,
      profile: bowler.profile,
      statType: statTypes.find((s) => s.name === "Balls Bowled"),
      value: currentOverNumber + 0.1 * (currentBallLabel + 1),
      stringValue: currentOverNumber + "." + (currentBallLabel + 1),
    });

    const runsConcededStat = makeStat({
      eventId,
      fixtureId,
      fixturePeriodId: selectedPeriod?.id,
      organization: bowlingTeam,
      profile: bowler.profile,
      statType: statTypes.find((s) => s.name === "Runs Conceded"),
      value: runsScoredValue,
    });

    const extraStats = selectedExtras?.length
      ? selectedExtras.map((x) =>
          makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile: bowler.profile,
            statType: statTypes.find((s) => s.name === x),
            value: getExtraValue(x, value),
          })
        )
      : false;

    const wicketStat = selectedWicket
      ? makeStat({
          eventId,
          fixtureId,
          fixturePeriodId: selectedPeriod?.id,
          organization: battingTeam,
          profile:
            selectedWicket === "Run Out" ||
            selectedWicket === "Retired" ||
            selectedWicket === "Obstruction" ||
            selectedWicket === "Ball Handling" ||
            selectedWicket === "Timed Out"
              ? dismissedBatsman
              : batsman.profile,
          statType: statTypes.find((s) => s.name === selectedWicket),
          value: 1,
        })
      : false;

    const wicketTakenStat =
      selectedWicket &&
      selectedWicket !== "Run Out" &&
      selectedWicket !== "Retired" &&
      selectedWicket !== "Out of Bounds" &&
      selectedWicket !== "Obstruction" &&
      selectedWicket !== "Timed Out" &&
      selectedWicket !== "Ball Handling"
        ? makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile: bowler.profile,
            statType: statTypes.find((s) => s.name === "Wicket Taken"),
            value: 0,
          })
        : false;

    const fieldingStat =
      selectedWicket &&
      selectedWicket !== "Bowled" &&
      selectedWicket !== "LBW" &&
      selectedWicket !== "Retired" &&
      selectedWicket !== "Out of Bounds" &&
      selectedWicket !== "Obstruction" &&
      selectedWicket !== "Timed Out" &&
      selectedWicket !== "Ball Handling" &&
      selectedWicket !== "Hit Wicket" &&
      selectedWicket !== "Double Hit" &&
      selectedWicket !== "Out of Bounds" &&
      selectedWicket !== "Out by Dots"
        ? makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile:
              selectedWicket === "Caught & Bowled"
                ? bowler.profile
                : selectedFielder,
            statType: statTypes.find((s) => {
              const fieldingStatName =
                selectedWicket === "Caught" ||
                selectedWicket === "Caught & Bowled"
                  ? "Catch"
                  : selectedWicket === "Run Out"
                  ? "Run Out"
                  : "Stumping";

              return s.name === fieldingStatName;
            }),
            value: 0,
          })
        : false;

    const runsScoredRelationships = [];

    const ballsBowledRelationships = [{ ...runsConcededStat }];

    const wicketRelationships = [];

    if (wicketStat && wicketTakenStat) {
      wicketRelationships.push(wicketTakenStat);
    }

    if (wicketStat && fieldingStat) {
      wicketRelationships.push(fieldingStat);
    }

    if (wicketRelationships.length) {
      wicketStat.relationshipsFrom = {
        create: wicketRelationships.map((r) => ({ to: { create: r } })),
      };
    }

    if (extraStats?.length) {
      extraStats.forEach((xs) => {
        ballsBowledRelationships.push(xs);
      });
    }

    ballsBowledStat.relationshipsFrom = {
      create: ballsBowledRelationships.map((r) => ({ to: { create: r } })),
    };

    if (ballsFacedStat) {
      runsScoredRelationships.push(ballsFacedStat);
    }

    runsScoredRelationships.push(ballsBowledStat);

    if (wicketStat) {
      runsScoredRelationships.push(wicketStat);
    }

    runsScoredStat.relationshipsFrom = {
      create: runsScoredRelationships.map((r) => ({ to: { create: r } })),
    };

    // console.log(wicketTakenStat);
    // return;

    createCricketStat({
      variables: {
        data: runsScoredStat,
      },
    });
  };

  return (
    <div
      className="ScoringApp-cricket__scoring__current-over__body__section__item"
      style={{ width: "25%", height: "112px" }}
      onClick={submit}
    >
      {loading ? <Spinner /> : value}
    </div>
  );
}

export default function CurrentOver() {
  const [
    {
      fixtureId,
      selectedPeriod,
      battingTeam,
      batsmen,
      bowler,
      strikeIndex,
      currentOverNumber,
      setOverlay,
      bowlingTeam,
      selectedExtras = [],
      selectedWicket,
    },
    setCricketScoringState,
  ] = useCricketScoringState();

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
          cricketLiveScoring{
            id
            powerplays{
              id
              metas{
                id
                value
              }
            }
            periodScores{
              id
              runs
              wickets
              overs
            }        
            overStats(periodId:"${selectedPeriod.id}", overNumber:${currentOverNumber}){
                id
                over
                ballsBowled{
                    id
                    value
                }
                ballsFaced{
                    id
                }
                runsScored{
                    id
                    value
                }
                runsConceded{
                    id
                }
                wicket{
                    id
                    statType{
                        id
                        name
                    }
                }
                wicketTaken{
                    id
                }
                extras{
                    id
                    statType{
                        id
                        name
                    }
                }
                fielding{
                    id
                }
            }        
          }
      `,
    }),
    {
      variables: {
        id: fixtureId,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__current-over">
        <Spinner />
      </div>
    );
  }

  const overItems = data?.fixture?.cricketLiveScoring?.overStats || [];
  const currentBallLabel = ballsCompleted(overItems);
  const score = selectedPeriod
    ? data?.fixture?.cricketLiveScoring?.periodScores?.find(
        (s) => s.id === selectedPeriod.id
      )
    : false;
  const totalOvers = Math.ceil(score?.overs || 0);
  const overNumbers = [...Array(totalOvers).keys()];
  const powerplays =
    data?.fixture?.cricketLiveScoring?.powerplays?.find(
      (x) => x.id === selectedPeriod?.id
    )?.metas || [];
  const currentOverIsPowerplay = powerplays.find(
    (x) => x.value === currentOverNumber
  );
  // console.log("Overs list:", currentOverNumber);

  const addStat = (runs) => {};

  return (
    <div className="ScoringApp-cricket__scoring__current-over">
      <div className="ScoringApp-cricket__scoring__current-over__header">
        <NewDropdown
          wrapperClass="ScoringApp-cricket__scoring__current-over__header__selector__wrapper"
          targetWrapperClass="ScoringApp-cricket__scoring__current-over__header__selector__target"
          target={
            <div className="ScoringApp-cricket__scoring__current-over__header__selector">
              <div className="ScoringApp-cricket__scoring__current-over__header__selector__secondary">
                SELECTED OVER
              </div>

              <div className="ScoringApp-cricket__scoring__current-over__header__selector__primary">
                {(currentOverNumber || 0) + "." + currentBallLabel}
                <ChevronDown className="ScoringApp-cricket__scoring__current-over__header__selector__primary__icon" />
              </div>
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="ScoringApp-cricket__scoring__current-over__menu">
              {overNumbers.map((o) => (
                <div
                  className="ScoringApp-cricket__scoring__current-over__menu__item"
                  onClick={() => {
                    setCricketScoringState({
                      currentOverNumber: o,
                    });
                    setShowMenu(false);
                  }}
                >
                  {o + 1}
                </div>
              ))}
            </Card>
          )}
          placement={"top-end"}
        />

        <div className="ScoringApp-cricket__scoring__current-over__header__items">
          {overItems.map((o) => (
            <div
              key={o.id}
              className={
                "ScoringApp-cricket__scoring__current-over__header__items__item " +
                (o.extras?.find((x) =>
                  ["Wide", "No Ball"].includes(x.statType.name)
                )
                  ? "muted"
                  : "")
              }
            >
              <div className="ScoringApp-cricket__scoring__current-over__header__items__item__tag">
                {o.extras
                  ?.map((x) => extraAbbrevs[x.statType.name])
                  .join("+") || ""}
              </div>
              <div className="ScoringApp-cricket__scoring__current-over__header__items__item__run">
                {o.runsScored?.value || 0}
              </div>
              <div className="ScoringApp-cricket__scoring__current-over__header__items__item__wicket">
                {o.wicket?.statType?.name?.toUpperCase() || ""}
              </div>
            </div>
          ))}
        </div>

        <div className="ScoringApp-cricket__scoring__current-over__header__options">
          <div
            className="ScoringApp-cricket__scoring__current-over__header__options__option hover-pointer"
            onClick={() => {
              setCricketScoringState({
                currentOverNumber: currentOverNumber + 1,
                strikeIndex: strikeIndex ? 0 : 1,
              });

              setOverlay(
                <SelectPlayer
                  title="Select Bowler"
                  onSelect={(player) => {
                    setCricketScoringState({
                      bowler: { profile: player.profile },
                    });

                    setOverlay(false);
                  }}
                  defaultTeam={bowlingTeam}
                  hideTabs={true}
                />
              );
            }}
          >
            Over Complete
          </div>
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__current-over__body">
        <div
          className="ScoringApp-cricket__scoring__current-over__body__section"
          style={{ width: "25%" }}
        >
          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("Wide") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("Wide")) {
                setCricketScoringState({
                  selectedExtras: [],
                });
              } else {
                setCricketScoringState({
                  selectedExtras: ["Wide"],
                });
              }
            }}
            style={{ width: "50%", height: "112px" }}
          >
            WIDE
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("No Ball") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("No Ball")) {
                setCricketScoringState({
                  selectedExtras: [],
                });
              } else {
                setCricketScoringState({
                  selectedExtras: ["No Ball"],
                });
              }
            }}
            style={{ width: "50%", height: "112px" }}
          >
            NO BALL
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("Bye") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("Bye")) {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras].filter(
                      (x) => x !== "Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: [],
                  });
                }
              } else {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras, "Bye"].filter(
                      (x) => x !== "Leg Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: ["Bye"],
                  });
                }
              }
            }}
            style={{ width: "50%", height: "112px" }}
          >
            BYE
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("Leg Bye") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("Leg Bye")) {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras].filter(
                      (x) => x !== "Leg Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: [],
                  });
                }
              } else {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras, "Leg Bye"].filter(
                      (x) => x !== "Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: ["Leg Bye"],
                  });
                }
              }
            }}
            style={{ width: "50%", height: "112px" }}
          >
            LEG BYE
          </div>
        </div>

        <div
          className="ScoringApp-cricket__scoring__current-over__body__section"
          style={{ width: "40%" }}
        >
          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Bowled" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Bowled") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Bowled",
                });
              }
            }}
          >
            BOWLED
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "LBW" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "LBW") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "LBW",
                });
              }
            }}
          >
            LBW
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Caught" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Caught") {
                setCricketScoringState({
                  selectedWicket: "",
                  selectedFielder: "",
                });
              } else {
                setOverlay(
                  <SelectPlayer
                    title="Select Catcher"
                    defaultTeam={bowlingTeam}
                    onSelect={(player) => {
                      setCricketScoringState({
                        selectedFielder: player.profile,
                        selectedWicket: "Caught",
                      });
                      setOverlay(false);
                    }}
                    hideTabs={true}
                  />
                );
              }
            }}
          >
            CAUGHT
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Caught & Bowled" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Caught & Bowled") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Caught & Bowled",
                });
              }
            }}
          >
            C & B
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Stumped" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Stumped") {
                setCricketScoringState({
                  selectedWicket: "",
                  selectedFielder: "",
                });
              } else {
                setOverlay(
                  <SelectPlayer
                    title="Select Wicketkeeper"
                    defaultTeam={bowlingTeam}
                    onSelect={(player) => {
                      setCricketScoringState({
                        selectedFielder: player.profile,
                        selectedWicket: "Stumped",
                      });
                      setOverlay(false);
                    }}
                    hideTabs={true}
                  />
                );
              }
            }}
          >
            STUMPED
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Run Out" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Run Out") {
                setCricketScoringState({
                  selectedWicket: "",
                  selectedFielder: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectPlayer
                    title="Select Fielder"
                    defaultTeam={bowlingTeam}
                    onSelect={(player) => {
                      setCricketScoringState({
                        selectedFielder: player.profile,
                      });

                      setOverlay(
                        <SelectDismissedBatsman
                          onSelect={(player) => {
                            setCricketScoringState({
                              dismissedBatsman: player.profile,
                              selectedWicket: "Run Out",
                            });
                            setOverlay(false);
                          }}
                        />
                      );
                    }}
                    hideTabs={true}
                  />
                );
              }
            }}
          >
            RUN OUT
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Retired" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Retired") {
                setCricketScoringState({
                  selectedWicket: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectDismissedBatsman
                    onSelect={(player) => {
                      setCricketScoringState({
                        dismissedBatsman: player.profile,
                        selectedWicket: "Retired",
                      });
                      setOverlay(false);
                    }}
                  />
                );
              }
            }}
          >
            RETIRED
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Obstruction" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Obstruction") {
                setCricketScoringState({
                  selectedWicket: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectDismissedBatsman
                    onSelect={(player) => {
                      setCricketScoringState({
                        dismissedBatsman: player.profile,
                        selectedWicket: "Obstruction",
                      });
                      setOverlay(false);
                    }}
                  />
                );
              }
            }}
          >
            OBSTRUCTION
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Hit Wicket" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Hit Wicket") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Hit Wicket",
                });
              }
            }}
          >
            HIT WICKET
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Double Hit" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Double Hit") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Double Hit",
                });
              }
            }}
          >
            DOUBLE HIT
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Out of Bounds" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Out of Bounds") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Out of Bounds",
                });
              }
            }}
          >
            OUT OF BOUNDS
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Ball Handling" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Ball Handling") {
                setCricketScoringState({
                  selectedWicket: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectDismissedBatsman
                    onSelect={(player) => {
                      setCricketScoringState({
                        dismissedBatsman: player.profile,
                        selectedWicket: "Ball Handling",
                      });
                      setOverlay(false);
                    }}
                  />
                );
              }
            }}
          >
            BALL HANDLING
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Out by Dots" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Out by Dots") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Out by Dots",
                });
              }
            }}
          >
            OUT BY DOTS
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Timed Out" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Timed Out") {
                setCricketScoringState({
                  selectedWicket: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectDismissedBatsman
                    onSelect={(player) => {
                      setCricketScoringState({
                        dismissedBatsman: player.profile,
                        selectedWicket: "Timed Out",
                      });
                      setOverlay(false);
                    }}
                  />
                );
              }
            }}
          >
            TIMED OUT
          </div>
        </div>

        <div
          className="ScoringApp-cricket__scoring__current-over__body__section"
          style={{ width: "35%" }}
        >
          <RunContainer
            value={0}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <RunContainer
            value={1}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <RunContainer
            value={2}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <RunContainer
            value={3}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <RunContainer
            value={4}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <RunContainer
            value={5}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <RunContainer
            value={6}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
            powerplay={currentOverIsPowerplay}
          />

          <div
            className="ScoringApp-cricket__scoring__current-over__body__section__item"
            style={{ width: "25%", height: "112px" }}
          >
            ...
          </div>
        </div>
      </div>
    </div>
  );
}
