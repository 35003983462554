import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import NewDropdown from "../../../NewDropdown";

export default function InningsSelector({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  cache,
  updateCache,
}) {
  return (
    <div className="time">
      <NewDropdown
        target={
          <div className="selected-period-selector">
            {selectedPeriod?.label || "Select period to get started"}
            <ChevronDown className="selected-period-selector__icon" />
          </div>
        }
        menu={({ setShowMenu }) => (
          <div className="selected-period-selector-menu">
            {periods.map((p) => (
              <div
                className="selected-period-selector-menu__item"
                onClick={() => {
                  // setRunning(false);
                  changePeriod(p.id);
                  setShowMenu(false);
                }}
              >
                {p.label}
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
}
