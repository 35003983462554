import "./FixtureStats.css";
import Spinner from "../../components/Spinner";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../api/fixture";
import {
  FootballStats,
  CricketStats,
  BasketballStats,
  BadmintonStats,
  TennisStats,
} from "./Stats";
import CricketStatsNew from "./Stats/CricketStats/CricketStatsNew";
import captureException from "../../utils/captureException";

function FixtureStats() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FIXTURE, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureStats",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data?.fixture;
  const sport = fixture?.event?.sport?.name;

  return (
    <div className="fixture-stats">
      {sport === "Football" && <FootballStats />}
      {(sport === "Cricket" || sport === "Box Cricket") && <CricketStatsNew />}
      {sport.includes("Basketball") && <BasketballStats />}
      {sport === "Badminton" && <BadmintonStats />}
      {sport === "Tennis" && <TennisStats />}
    </div>
  );
}

export default FixtureStats;
