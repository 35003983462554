// V1
import { Fragment, useState, useCallback } from "react";
import "./ManageOrganizationMembers.css";
import Button from "../../Button";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { useAppState } from "../../../utils/appState";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Spinner from "../../Spinner";
import Toggle from "../../Toggle";
import Card from "../../Card";
import {
  ORGANIZATION_MEMBERS,
  UPDATE_ORGANIZATION,
} from "../../../api/organization";
import { PROFILE_SEARCH } from "../../../api/user";
import Input from "../../Input";
import debounce from "lodash.debounce";
import NewDropdown from "../../NewDropdown";
import ClickOutside from "../../ClickOutside";
import moment from "moment";
import Loader from "../../Loader";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function DeleteOrgMember({ organization, membership, setShowMenu, refetch }) {
  const [updateOrganization, { loading: submitting }] = useMutation(
    UPDATE_ORGANIZATION(`members(where:{toDate:null}) {
      id
      profile {
        id
        name
      }
      isAdmin
      role
      approved
  }`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        setShowMenu(false);
        refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageOrganizationMembers.DeleteOrgMember",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <Card className="delete-org-member-menu">
      <div className="desc">Are you sure?</div>
      <Button
        className="primary red small"
        onClick={() => {
          if (submitting) return;
          updateOrganization({
            variables: {
              where: { id: organization.id },
              data: {
                members: {
                  update: {
                    where: { id: membership.id },
                    data: {
                      toDate: moment().toISOString(),
                    },
                  },
                },
              },
            },
          });
        }}
      >
        {submitting ? <Loader theme={"small"} /> : "Yes, delete"}
      </Button>
      <Button
        className="tertiary small"
        onClick={() => {
          setShowMenu(false);
        }}
      >
        No, cancel
      </Button>
    </Card>
  );
}

function AddOrgMembers({ organization, back, refetchList, refetchSummary }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(PROFILE_SEARCH);
  const [updateOrganization, { loading: submitting }] = useMutation(
    UPDATE_ORGANIZATION(`members(where:{toDate:null}) {
      id
      createdAt
      profile {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      isAdmin
      role
      approved
  }`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        refetchList();
        refetchSummary();

        back();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageOrganizationMembers.AddOrgMembers",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedGetSuggestions = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = data?.search?.profiles;
  // console.log(data?.search);

  return (
    <div className="app-party-list">
      <div className="app-party-list__header">
        <div
          className="app-party-list__header__back"
          onClick={() => {
            back();
          }}
        >
          <ArrowLeftIcon style={{ stroke: "var(--dark-4)" }} />
        </div>
        <div className="app-party-list__header__title">
          Add Organization Members
        </div>
      </div>

      <div className="app-party-list__body">
        <div className="app-party-list__body__input">
          <NewDropdown
            wrapperClass="search"
            targetWrapperClass="search-wrapper"
            placement={"bottom-start"}
            menuStyle={{ width: "100%" }}
            target={
              <Input
                iconLeft={
                  <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
                }
                placeholder={"Search for profiles"}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debouncedGetSuggestions(e);
                }}
                useExternalValue={true}
                value={searchTerm}
              />
            }
            menu={({ setShowMenu }) => (
              <Card className="app-search-menu">
                {loading && <Spinner />}
                {!loading && !profiles?.length && (
                  <div className="no-data">
                    {!searchTerm ? (
                      <div>Search for profiles</div>
                    ) : (
                      <div>No results found</div>
                    )}
                  </div>
                )}
                {!!profiles?.length && (
                  <div className="search-results-section">
                    <div className="search-results-section__title">
                      PROFILES
                    </div>
                    <div className="search-results-section__items">
                      {profiles?.map((p) => (
                        <div
                          key={p.id}
                          onClick={() => {
                            setShowMenu(false);
                            setSearchTerm("");
                            setSelectedProfiles((prev) => [...prev, p]);
                          }}
                          className="search-results-section__items__item"
                        >
                          <div className="search-results-section__items__item__image">
                            {p?.profilePhoto && (
                              <Avatar media={p.profilePhoto} />
                            )}
                          </div>
                          <div className="search-results-section__items__item__desc">
                            <div className="search-results-section__items__item__desc__primary">
                              {p.name}
                            </div>
                            <div className="search-results-section__items__item__desc__secondary">
                              @{p.handle}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Card>
            )}
          />
        </div>
        {selectedProfiles.map((profile, i) => (
          <div
            key={"selected-profile-" + profile.id}
            className="app-party-list__body__item"
          >
            <div className="app-party-list__body__item__image">
              {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
            </div>
            <div className="app-party-list__body__item__desc">
              <div className="app-party-list__body__item__desc__primary">
                {profile?.name}
              </div>

              <div className="app-party-list__body__item__desc__secondary">
                <div className="role-selection-wrapper">
                  <NewDropdown
                    target={
                      <div className="selected-role">
                        {profile.role || "Select a role"}{" "}
                        <ChevronDown
                          style={{ stroke: "var(--dark-4)", height: "12px" }}
                        />
                      </div>
                    }
                    menu={({ setShowMenu }) => (
                      <Card className="role-selection-menu">
                        <div
                          className="role-selection-menu-item"
                          onClick={() => {
                            const newSelectedProfiles = [...selectedProfiles];
                            newSelectedProfiles[i] = {
                              ...newSelectedProfiles[i],
                              role: "Squad Member",
                            };
                            setSelectedProfiles(newSelectedProfiles);
                            setShowMenu(false);
                          }}
                        >
                          Squad member
                        </div>
                        <div
                          className="role-selection-menu-item"
                          onClick={() => {
                            const newSelectedProfiles = [...selectedProfiles];
                            newSelectedProfiles[i] = {
                              ...newSelectedProfiles[i],
                              role: "Employee",
                            };
                            setSelectedProfiles(newSelectedProfiles);
                            setShowMenu(false);
                          }}
                        >
                          Employee
                        </div>
                      </Card>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="app-party-list__body__item__options">
              <div
                className="app-party-list__body__item__options__icon-option"
                onClick={() => {
                  const newSelectedProfiles = [...selectedProfiles];
                  newSelectedProfiles[i] = false;

                  setSelectedProfiles(newSelectedProfiles.filter((x) => x));
                }}
              >
                <TimesIcon
                  style={{ stroke: "var(--red-lighter", height: "14px" }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="app-party-list__footer">
        <Button
          className={
            "primary large blue " +
            (!selectedProfiles.length || selectedProfiles.find((p) => !p.role)
              ? "disabled"
              : "")
          }
          onClick={() => {
            if (submitting) return;

            for (let i = 0; i < selectedProfiles.length; i++) {
              if (!selectedProfiles[i].role) {
                return;
              }
            }

            updateOrganization({
              variables: {
                where: { id: organization.id },
                data: {
                  members: {
                    create: selectedProfiles.map((p) => ({
                      profile: { connect: { id: p.id } },
                      role: p.role,
                      fromDate: moment().toISOString(),
                    })),
                  },
                },
              },
            });
          }}
        >
          {submitting ? (
            <Loader theme={"small"} />
          ) : (
            <Fragment>
              Add {selectedProfiles.length || ""} member
              {selectedProfiles?.length && selectedProfiles.length > 1
                ? "s"
                : ""}
            </Fragment>
          )}
        </Button>
      </div>
    </div>
  );
}

function ManageOrganizationMembers({ organization, refetchSummary }) {
  const [, setAppState] = useAppState();
  const [addMember, setAddMember] = useState(false);
  const { loading, error, data, refetch } = useQuery(ORGANIZATION_MEMBERS, {
    variables: { id: organization.id, where: { toDate: null } },
  });

  const [updateOrganization] = useMutation(
    UPDATE_ORGANIZATION(`members(where:{toDate:null}) {
      id
      createdAt
      profile {
        id
        name
      }
      isAdmin
      role
      approved
  }`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageOrganizationMembers",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageOrganizationMembers",
        query: "ORGANIZATION_MEMBERS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (addMember) {
    return (
      <AddOrgMembers
        organization={organization}
        back={() => {
          setAddMember(false);
        }}
        refetchList={refetch}
        refetchSummary={refetchSummary}
      />
    );
  }

  const list = data?.organization?.members || [];
  const sortedList = [...list].sort((a, b) => {
    return (
      new Date(a.createdAt) - new Date(b.createdAt) || a.id.localeCompare(b.id)
    );
  });
  // console.log("Sorted List:", sortedList);
  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="app-party-list" ref={wrapperRef}>
          <div className="app-party-list__header">
            <div className="app-party-list__header__title">
              Manage Organization Members
            </div>
            <div
              className="app-party-list__header__dismiss"
              onClick={() => {
                setAppState({ modal: false });
              }}
            >
              <TimesIcon className="app-party-list__header__dismiss__icon" />
            </div>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="app-party-list__body">
                {sortedList.map((item) => (
                  <div key={item.id} className="app-party-list__body__item">
                    <div className="app-party-list__body__item__image">
                      {item.profile?.profilePhoto && (
                        <Avatar media={item.profile.profilePhoto} />
                      )}
                    </div>
                    <div className="app-party-list__body__item__desc">
                      <div className="app-party-list__body__item__desc__primary">
                        {item.organization
                          ? item.organization.name
                          : item.profile.name}
                      </div>

                      <div className="app-party-list__body__item__desc__secondary">
                        {item.role} {!item.approved && "(Request Sent)"}
                      </div>
                    </div>
                    <div className="app-party-list__body__item__options">
                      <div className="app-party-list__body__item__options__admin-option">
                        <div className="label">Is Admin?</div>
                        <Toggle
                          defaultValue={item.isAdmin}
                          onToggle={(val) => {
                            updateOrganization({
                              variables: {
                                where: { id: organization.id },
                                data: {
                                  members: {
                                    update: {
                                      where: { id: item.id },
                                      data: {
                                        isAdmin: val,
                                      },
                                    },
                                  },
                                },
                              },
                            });
                          }}
                        />
                      </div>

                      <NewDropdown
                        target={
                          <div className="app-party-list__body__item__options__delete-option">
                            <TrashIcon
                              style={{
                                stroke: "var(--red-lighter)",
                                height: "14px",
                              }}
                            />
                          </div>
                        }
                        menu={({ setShowMenu }) => (
                          <DeleteOrgMember
                            organization={organization}
                            membership={item}
                            setShowMenu={setShowMenu}
                            refetch={refetch}
                          />
                        )}
                      />
                    </div>
                  </div>
                ))}

                {!loading && !list?.length && (
                  <div className="no-data">
                    <div>{organization.name} has no members yet.</div>
                  </div>
                )}
              </div>
              <div className="app-party-list__footer">
                <Button
                  className="primary large blue"
                  onClick={() => {
                    setAddMember(true);
                  }}
                >
                  Add new members
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </ClickOutside>
  );
}

export default ManageOrganizationMembers;
