import { Fragment } from "react";

const bowlingHeaders = [
  { label: "Bowler", width: "60%", textAlign: "left", key: "name" },
  { label: "Overs", width: "10%", textAlign: "right", key: "overs" },
  { label: "Runs", width: "10%", textAlign: "right", key: "runs" },
  { label: "Wickets", width: "10%", textAlign: "right", key: "wickets" },
  { label: "Extras", width: "10%", textAlign: "right", key: "extras" },
];

export default function BowlingSummary({
  team,
  bowler,
  setOverlay,
  battingTeam,
  setBowler,
  updateCache,
  fixtureId,
  cache,
  stats,
  fixture,
}) {
  // const { loading, error, data } = useQuery(FIXTURE_STATS, {
  //   variables: { id: fixtureId },
  // });

  // if (error) {
  //   console.log(error);
  //   return <div>Error</div>;
  // }

  // if (loading || !data) {
  //   return <Loader />;
  // }

  // const fixture = data.fixture;
  // const stats = data.fixture.stats;
  //   console.log(
  //     "Wickets taken stats:",
  //     stats.filter((s) => s.statType.name === "Wicket Taken")
  //   );

  // console.log(
  //   "Balls bowled stats:",
  //   stats.filter(
  //     (s) =>
  //       s.profile.id === bowler.profile.id &&
  //       s.statType.name === "Runs Conceded"
  //   )
  // );
  const ballsBowled =
    stats.filter(
      (s) =>
        s?.profile?.id === bowler?.profile?.id &&
        s.statType.name === "Balls Bowled"
    )?.length || 0;

  const extraStats =
    stats.filter(
      (s) =>
        s?.profile?.id === bowler?.profile?.id &&
        ["No Ball", "Wide", "Leg Bye", "Bye"].includes(s.statType.name)
    ) || [];

  const extraBalls = extraStats.filter((s) =>
    ["No Ball", "Wide"].includes(s.statType.name)
  ).length;

  const extraRuns =
    extraStats.reduce((accum, curr) => {
      accum += curr.value;
      return accum;
    }, 0) || 0;

  // console.log(
  //   "BOWLR EXTRS:",
  //   stats.filter(
  //     (s) =>
  //       s?.profile?.id === bowler?.profile?.id &&
  //       ["No Ball", "Wide", "Leg Bye", "Bye"].includes(s.statType.name)
  //   )
  // );

  const ballsPerOver =
    fixture?.metas?.find((m) => m.label === "ballsPerOver")?.value || 6;

  console.log("Bowling Summary:", fixture);

  const overs =
    Math.floor(
      (Math.floor((ballsBowled - extraBalls) / ballsPerOver) +
        0.1 * ((ballsBowled - extraBalls) % ballsPerOver)) *
        10
    ) / 10;

  const runsConceded =
    stats
      .filter(
        (s) =>
          s?.profile?.id === bowler?.profile?.id &&
          s.statType.name === "Runs Conceded"
      )
      .reduce((accum, curr) => {
        accum += curr.value;
        return accum;
      }, 0) || 0;

  const wicketsTaken =
    stats
      .filter(
        (s) =>
          s?.profile?.id === bowler?.profile?.id &&
          s.statType.name === "Wicket Taken"
      )
      .reduce((accum, curr) => {
        accum += 1;
        return accum;
      }, 0) || 0;

  return (
    <div className="bowling-summary">
      <div className="head">
        <div className="section-title">BOWLING</div>
        <div className="team">
          <div className="name">{team?.name}</div>
          <div className="image"></div>
        </div>
      </div>

      <div className="fields">
        {bowlingHeaders.map((h) => (
          <div
            key={"bowling-table-field-" + h.label}
            className="th"
            style={{ width: h.width, textAlign: h.textAlign }}
          >
            {h.label}
          </div>
        ))}
      </div>

      <div className="row">
        <div
          className="bowler"
          style={{ width: "60%" }}
          onClick={() => {
            setOverlay({
              title: "Select Bowler",
              onSelect: (player) => {
                setBowler({ profile: player.profile });
                updateCache({ bowler: { profile: player.profile } });
              },
              defaultTeam: team,
            });
          }}
        >
          {bowler.profile?.name ? (
            <Fragment>
              <div className="profile-pic" /> {bowler.profile.name}
            </Fragment>
          ) : (
            <div className="input">Select Bowler</div>
          )}
        </div>
        <div className="overs" style={{ width: "10%", textAlign: "right" }}>
          {overs}
        </div>
        <div className="runs" style={{ width: "10%", textAlign: "right" }}>
          {runsConceded}
        </div>
        <div className="wickets" style={{ width: "10%", textAlign: "right" }}>
          {wicketsTaken}
        </div>
        <div className="maidens" style={{ width: "10%", textAlign: "right" }}>
          {extraRuns}
        </div>
      </div>
    </div>
  );
}
