import "./OnboardingPrompt.css";
import { useAppState } from "../../utils/appState";
import Onboarding from "../Onboarding";
import { useHistory } from "react-router-dom";
import { Fragment, useState } from "react";
import Counter from "./Counter";
import Fill from "./Fill";
import { ReactComponent as AlertIcon } from "../../icons/alert-circle.svg";
import { SEND_VERIFICATION_EMAIL } from "../../api/auth";
import { useMutation } from "@apollo/client";
import Spinner from "../Spinner";
import captureException from "../../utils/captureException";

function ResendVerificationEmail() {
  const [resentVerificationEmail, setResentVerificationEmail] = useState(false);
  const [sendVerificationEmail, { loading }] = useMutation(
    SEND_VERIFICATION_EMAIL,
    {
      update: (cache, { data: { sendVerificationEmail } }) => {
        if (sendVerificationEmail.success) {
          setResentVerificationEmail(true);
        }
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "OnboardingPrompt.ResendVerificationEmail",
            mutation: "SEND_VERIFICATION_EMAIL",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <Fragment>
      <div
        className={
          "onboarding-prompt__cta hover-pointer " +
          (loading || resentVerificationEmail ? "disabled" : "")
        }
        onClick={() => {
          // setAppState({ modal: <Onboarding /> });

          sendVerificationEmail();
        }}
      >
        {/* <div
            className="onboarding-prompt__cta__progress"
            style={{ width: completion + "%" }}
          ></div> */}
        {/* <Fill number={completion} /> */}
        <div className="onboarding-prompt__cta__text">
          <div className="onboarding-prompt__cta__primary">
            {loading && <Spinner color="white" size={14} />}
            {resentVerificationEmail
              ? "VERIFICATION EMAIL SENT"
              : loading
              ? "SENDING VERIFICATION EMAIL"
              : "RESEND VERIFICATION EMAIL"}
          </div>
          {/* <div className="onboarding-prompt__cta__secondary">
            <Counter number={remaining} duration={2000} />% Remaining
          </div> */}
        </div>
      </div>

      <div className="onboarding-prompt__estimate">
        Estimated Completion Time: A few seconds
      </div>
    </Fragment>
  );
}

function CompleteProfilePrompt({ onboardingInfo = {} }) {
  const [, setAppState] = useAppState();

  const { pendingFields = [], totalFields = 1 } = onboardingInfo;
  const completion = Math.round(
    ((totalFields - pendingFields.length) / totalFields) * 100
  );
  const remaining = 100 - completion;
  const estimatedTime = Math.ceil((4 * remaining) / 100);

  const onlyOnboardingRemaining =
    pendingFields.length === 1 && pendingFields[0] === "emailVerified";

  // console.log("COMPLETE:", completion);
  // console.log("REM:", remaining);

  // console.log("CALC:", totalFields, pendingFields.length);

  return (
    <div className="onboarding-prompt">
      <div className="onboarding-prompt__meta">
        <div className="onboarding-prompt__title">
          <div className="onboarding-prompt__title__icon">
            <AlertIcon style={{ marginTop: "2px" }} />
          </div>
          <div className="onboarding-prompt__title__text">
            Your profile is incomplete!
          </div>
        </div>

        <div className="onboarding-prompt__desc">
          <div>
            <div className="onboarding-prompt__desc__line">
              You are almost done!{" "}
              {onlyOnboardingRemaining ? (
                <span>Verifying your email </span>
              ) : (
                <span>
                  Just{" "}
                  <span
                    className="onboarding-prompt__desc__line__link hover-pointer"
                    onClick={() => {
                      setAppState({ modal: <Onboarding /> });
                    }}
                  >
                    a few details
                  </span>{" "}
                </span>
              )}
              gives you access to all of Sports Paddock's content & features.
            </div>
            <div className="onboarding-prompt__desc__line">
              (And{" "}
              {onlyOnboardingRemaining ? (
                "get's rid of this annoying reminder."
              ) : (
                <span
                  className="onboarding-prompt__desc__line__link hover-pointer"
                  onClick={() => {
                    setAppState({ modal: <Onboarding /> });
                  }}
                >
                  get's rid of this annoying reminder.
                </span>
              )}
              )
            </div>
          </div>
        </div>
      </div>

      <div className="onboarding-prompt__options">
        {onlyOnboardingRemaining ? (
          <ResendVerificationEmail />
        ) : (
          <Fragment>
            <div
              className="onboarding-prompt__cta hover-pointer"
              onClick={() => {
                setAppState({ modal: <Onboarding /> });
              }}
            >
              {/* <div
            className="onboarding-prompt__cta__progress"
            style={{ width: completion + "%" }}
          ></div> */}
              <Fill number={completion} />
              <div className="onboarding-prompt__cta__text">
                <div className="onboarding-prompt__cta__primary">
                  COMPLETE YOUR PROFILE
                </div>
                <div className="onboarding-prompt__cta__secondary">
                  <Counter number={remaining} duration={2000} />% Remaining
                </div>
              </div>
            </div>

            <div className="onboarding-prompt__estimate">
              Estimated Completion Time: {estimatedTime} Minute
              {estimatedTime > 1 ? "s" : ""}
            </div>
          </Fragment>
        )}
      </div>

      {/* <div className="onboarding-prompt__title__progress">
        <div className="onboarding-prompt__title__progress__container">
          <div className="onboarding-prompt__title__progress__container__fill"></div>

          <div className="onboarding-prompt__title__progress__container__text"></div>
        </div>
      </div> */}
    </div>
  );
}

function LoginPrompt() {
  const history = useHistory();
  return (
    <div className="onboarding-prompt">
      <div className="onboarding-prompt__meta">
        <div className="onboarding-prompt__title">
          <div className="onboarding-prompt__title__icon">
            <AlertIcon style={{ marginTop: "2px" }} />
          </div>
          <div className="onboarding-prompt__title__text">
            You are not logged in!
          </div>
        </div>

        <div className="onboarding-prompt__desc">
          <div>
            <div className="onboarding-prompt__desc__line">
              <span
                className="onboarding-prompt__desc__line__link hover-pointer"
                onClick={() => {
                  history.push("/auth");
                }}
              >
                Login
              </span>{" "}
              or{" "}
              <span
                className="onboarding-prompt__desc__line__link hover-pointer"
                onClick={() => {
                  history.push("/auth");
                }}
              >
                create an account
              </span>{" "}
              to access all of Sports Paddock's content & features.
            </div>
            <div className="onboarding-prompt__desc__line">
              (And{" "}
              <span
                className="onboarding-prompt__desc__line__link hover-pointer"
                onClick={() => {
                  history.push("/auth");
                }}
              >
                get's rid of this annoying reminder.
              </span>
              )
            </div>
          </div>
        </div>
      </div>

      <div className="onboarding-prompt__options">
        <div
          className="onboarding-prompt__cta hover-pointer"
          onClick={() => {
            history.push("/auth");
          }}
        >
          {/* <div
            className="onboarding-prompt__cta__progress"
            style={{ width: completion + "%" }}
          ></div> */}
          {/* <Fill number={completion} /> */}
          <div className="onboarding-prompt__cta__text">
            <div className="onboarding-prompt__cta__primary">
              CLICK HERE TO LOGIN
            </div>
            {/* <div className="onboarding-prompt__cta__secondary">
              <Counter number={remaining} duration={2000} />% Remaining
            </div> */}
          </div>
        </div>

        <div className="onboarding-prompt__estimate">
          Estimated Completion Time: 2 Mins
        </div>
      </div>

      {/* <div className="onboarding-prompt__title__progress">
        <div className="onboarding-prompt__title__progress__container">
          <div className="onboarding-prompt__title__progress__container__fill"></div>

          <div className="onboarding-prompt__title__progress__container__text"></div>
        </div>
      </div> */}
    </div>
  );
}

function OnboardingPrompt({ onboardingInfo }) {
  // const history = useHistory();
  const [{ isLoggedIn }] = useAppState();

  if (isLoggedIn) {
    return <CompleteProfilePrompt onboardingInfo={onboardingInfo} />;
  } else {
    return <LoginPrompt />;
  }

  // return (
  //   <div
  //     className="onboarding-prompt hover-pointer"
  //     onClick={() => {
  //       if (!isLoggedIn) {
  //         history.push("/auth");
  //       } else {
  //         setAppState({ modal: <Onboarding /> });
  //       }
  //     }}
  //   >
  //     <div className="onboarding-prompt__title">
  //       {isLoggedIn ? "Your profile is incomplete" : "You are not logged in"}
  //     </div>

  //     <div className="onboarding-prompt__desc">
  //       {isLoggedIn ? (
  //         <div>
  //           <div className="onboarding-prompt__desc__line">
  //             Hi Username. Just{" "}
  //             <span className="onboarding-prompt__desc__line__link">
  //               a few more details
  //             </span>{" "}
  //             gives you access to all of Sports Paddock's content & features.
  //           </div>
  //           <div className="onboarding-prompt__desc__line">
  //             (And{" "}
  //             <span className="onboarding-prompt__desc__line__link">
  //               get's rid of this annoying reminder.
  //             </span>
  //             )
  //           </div>
  //         </div>
  //       ) : (
  //         "You are not logged in"
  //       )}
  //     </div>
  //   </div>
  // );
}

export default OnboardingPrompt;
