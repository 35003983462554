import { useRef, useEffect, useState } from "react";
import makeFileUrl from "../../utils/makeFileUrl";
import "./Image.css";

function Image({ media }) {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    // console.log("width", ref.current.offsetWidth);
    setWidth(ref?.current?.offsetWidth || 0);
  }, [ref]);
  return (
    <div className="app-img-wrapper" ref={ref}>
      {!imageLoaded && (
        <div
          className="app-img-placeholder"
          style={{
            height: width / (media.aspectRatio || 1) + "px",
            background: "var(--light-1)",
          }}
        />
      )}
      <img
        src={makeFileUrl(media)}
        onLoad={() => {
          setImageLoaded(true);
        }}
        className={imageLoaded ? "" : "hidden"}
        alt=""
      />
      <div className="overlay" />
    </div>
  );
}

export default Image;
