import "./BallsPerOverSettings.css";
import { Fragment, useState } from "react";
import Card from "../../../components/Card";
import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/functions";
import Spinner from "../../../components/Spinner";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";

const bpoSelectionSet = `
  metas {
    id
    label
    shortLabel
    value
    stringValue
  }
`;

function EditBallsPerOver({ meta, dismiss, fixture }) {
  const [ballsPerOver, setBallsPerOver] = useState(meta?.value || "");
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: bpoSelectionSet,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  return (
    <form
      className="ScoringApp-football__settings__period-settings__form"
      onSubmit={(e) => {
        e.preventDefault();

        if (loading) return;
        const vars = {};

        if (meta?.id) {
          vars.update = {
            where: { id: meta.id },
            data: {
              value: Number(ballsPerOver) || 0,
            },
          };
        } else {
          vars.create = [
            {
              label: "ballsPerOver",
              value: Number(ballsPerOver) || 0,
            },
          ];
        }

        updateFixture({
          variables: {
            where: { id: fixture.id },
            data: {
              metas: vars,
            },
          },
          update: (cache, { data: { updateFixture } }) => {
            // console.log(updateFixture);
            cache.modify({
              id: cache.identify(updateFixture),
              fields: {
                metas() {
                  return updateFixture.metas;
                },
              },
            });

            dismiss();
          },
        });
      }}
    >
      <div className="ScoringApp-football__settings__period-settings__form__row">
        <div className="ScoringApp-football__settings__period-settings__form__row__group text">
          {/* <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
            Label
          </div> */}
          <input
            type="text"
            value={ballsPerOver}
            onChange={(e) => {
              setBallsPerOver(e.target.value);
            }}
            className="ScoringApp-football__settings__period-settings__form__row__group__input label"
            placeholder="Enter balls per over"
          />
        </div>

        <div className="ScoringApp-football__settings__period-settings__form__row__options">
          <button
            type="submit"
            className={
              "ScoringApp-football__settings__period-settings__form__row__options__option submit " +
              (loading ? "disabled" : "")
            }
          >
            {loading ? (
              <Spinner size={12} color={"var(--green-darker)"} />
            ) : (
              <CheckIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
            )}
          </button>
          <div
            className={
              "ScoringApp-football__settings__period-settings__form__row__options__option cancel " +
              (loading ? "disabled" : "")
            }
            onClick={() => {
              dismiss();
            }}
          >
            <TimesIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
          </div>
        </div>
      </div>
    </form>
  );
}

export default function BallsPerOverSettings({ id }) {
  const [editing, setEditing] = useState(false);
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: bpoSelectionSet,
    }),
    {
      variables: {
        id,
      },
    }
  );

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.PeriodSettings",
    //     query: "FIXTURE_STATS",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  const metas = data?.fixture?.metas;
  const ballsPerOverMeta = metas?.find((m) => m.label === "ballsPerOver");

  return (
    <Card className="ScoringApp-football__settings__period-settings">
      <div className="ScoringApp-football__settings__period-settings__title">
        Edit balls per over
      </div>

      <div className="ScoringApp-football__settings__period-settings__body">
        {loading || !data ? (
          <div className="ScoringApp-football__settings__period-settings__body__loading">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {editing ? (
              <EditBallsPerOver
                meta={ballsPerOverMeta}
                dismiss={() => {
                  setEditing(false);
                }}
                fixture={data?.fixture}
              />
            ) : (
              <div className="ScoringApp-football__settings__period-settings__period">
                <div className="ScoringApp-football__settings__period-settings__period__details">
                  {ballsPerOverMeta?.value || "-"}
                </div>
                <div className="ScoringApp-football__settings__period-settings__period__options">
                  <div
                    className="ScoringApp-football__settings__period-settings__period__options__option edit"
                    onClick={() => {
                      setEditing(true);
                    }}
                  >
                    <PencilIcon className="ScoringApp-football__settings__period-settings__period__options__option__icon" />
                  </div>
                </div>
              </div>
            )}

            {/* {periods.map((p, i) => (
              <Period key={p.id} period={p} index={i} fixtureId={id} />
            ))}

            {adding ? (
              <CreateOrEditPeriod
                dismiss={() => {
                  setAdding(false);
                }}
                order={periods.length}
                fixtureId={id}
              />
            ) : (
              <div
                className="ScoringApp-football__settings__period-settings__body__add-period"
                onClick={() => {
                  setAdding(true);
                }}
              >
                + Add a period
              </div>
            )} */}
          </Fragment>
        )}
      </div>
    </Card>
  );
}
