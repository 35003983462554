// V1
import { useState } from "react";
import "./EventsCard.css";
import Card from "../../Card";
import EventCalendar from "../../EventCalendar";
import Placeholder from "../../Placeholder";
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { USER_FIXTURES } from "../../../api/user";
import moment from "moment";
import captureException from "../../../utils/captureException";

function EventsCard() {
  const [eventsDate, setEventsDate] = useState(moment().toISOString());
  const { loading, error, data, refetch } = useQuery(USER_FIXTURES, {
    variables: {
      where: {
        startDate: moment().startOf("month").toISOString(),
        endDate: moment().endOf("month").toISOString(),
      },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventsCard",
        query: "USER_FIXTURES",
      },
    });
    console.log(error);
    // return <div>Error</div>;
  }

  const fixtures = data?.userFixtures || [];
  const getParticipantName = (role) => {
    return role?.organization?.name || role?.profile?.name || "TBD";
  };

  return (
    <Card className="events-card">
      <div className="header">
        <CalendarIcon style={{ height: 36, stroke: "var(--blue-lighter)" }} />
        <div className="text">Events</div>
      </div>
      <div className="info">
        <p>
          Discover awesome tournaments, events, classes and other sporting
          activities near you with Sports Paddock Events. Start exploring &
          participating now!
        </p>
        <p></p>
      </div>
      <div className="user-events">
        <EventCalendar
          loading={loading}
          onNextClick={(date) => {
            setEventsDate(date);
            refetch({
              where: {
                startDate: moment(date).startOf("month").toISOString(),
                endDate: moment(date).endOf("month").toISOString(),
              },
            });
          }}
          onPrevClick={(date) => {
            setEventsDate(date);
            refetch({
              where: {
                startDate: moment(date).startOf("month").toISOString(),
                endDate: moment(date).endOf("month").toISOString(),
              },
            });
          }}
          onDateSelect={(date) => {
            setEventsDate(date);
          }}
          fixtures={fixtures}
        />
        <div className="calendar-fixture-list">
          {loading && (
            <Card className="calendar-fixture-list-item">
              <div className="participants">
                <div className="participant">
                  <div className="image"></div>
                  <div className="name">
                    <Placeholder style={{ width: "60px" }} pulse={true} />
                  </div>
                </div>

                <div className="participant">
                  <div className="image"></div>
                  <div className="name">
                    <Placeholder style={{ width: "60px" }} pulse={true} />
                  </div>
                </div>
              </div>

              <div className="datetime">
                <div className="date">
                  <Placeholder style={{ width: "24px" }} pulse={true} />
                </div>
              </div>
            </Card>
          )}

          {!loading &&
            !fixtures?.filter(
              (f) =>
                moment(eventsDate).startOf("day").isBefore(f.scheduledStart) &&
                moment(eventsDate).endOf("day").isAfter(f.scheduledStart)
            ).length && (
              <div className="no-data">
                <div>You have no events scheduled for this day.</div>
              </div>
            )}

          {!loading &&
            fixtures
              .filter(
                (f) =>
                  moment(eventsDate)
                    .startOf("day")
                    .isBefore(f.scheduledStart) &&
                  moment(eventsDate).endOf("day").isAfter(f.scheduledStart)
              )
              .map((fixture) => (
                <Link to={"/app/fixture/" + fixture.id} key={fixture.id}>
                  <Card className="calendar-fixture-list-item">
                    <div className="participants">
                      <div className="participant">
                        <div className="image"></div>
                        <div className="name">
                          {getParticipantName(
                            fixture.roles
                              .filter((r) => r.type.name === "PARTICIPANT")
                              .sort((a, b) => a.order - b.order)?.[0]
                          )}
                        </div>
                        <div className="score">4</div>
                      </div>

                      <div className="participant">
                        <div className="image"></div>
                        <div className="name">
                          {getParticipantName(
                            fixture.roles
                              .filter((r) => r.type.name === "PARTICIPANT")
                              .sort((a, b) => a.order - b.order)?.[1]
                          )}
                        </div>
                        <div className="score">2</div>
                      </div>
                    </div>

                    <div className="datetime">
                      <div className="date">FT</div>
                    </div>
                  </Card>
                </Link>
              ))}
        </div>
      </div>
    </Card>
  );
}

export default EventsCard;
