import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./utils/appState";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./utils/client";

Sentry.init({
  dsn: "https://93664d1c17184f55a5a3967dea8439fe@o4505396266926080.ingest.sentry.io/4505396285407232",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/backend\.sportspaddock\.com/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const initialState = {
  modal: false,
  isLoggedIn: false,
};

const reducer = (state, action = {}) => {
  return {
    ...state,
    ...action,
  };
};

//PROD LINK
// const httpLink = new BatchHttpLink({
//   uri: "https://sp-backend-zonb6.ondigitalocean.app/",
// });

// DEV LINK
// console.log("API Endpoint:", process.env.REACT_APP_API_ENDPOINT);

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
