import { gql } from "@apollo/client";

const UPDATE_EVENT_PART = gql`
  mutation updateEventPartMutation(
    $where: UniqueInput!
    $data: UpdateEventPartInput!
  ) {
    updateEventPart(where: $where, data: $data) {
      id
      type
      label
      order
      partOf {
        id
      }
    }
  }
`;

export default UPDATE_EVENT_PART;
