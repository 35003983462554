import { gql } from "@apollo/client";

const HANDLE_MEMBERSHIP = gql`
  mutation handleMembership($id: ID!, $approve: Boolean!) {
    handleMembership(id: $id, approve: $approve) {
      id
    }
  }
`;

export default HANDLE_MEMBERSHIP;
