import "./VerticalText.css";

export default function VerticalText({
  width = 64,
  height = 64,
  text,
  fontStyle,
}) {
  return (
    <div
      className="vertical-text-container"
      style={{
        height: height + "px",
        width: width + "px",
        left: height + "px",
        transformOrigin: `0 ${height}px`,
      }}
    >
      <div className="vertical-text-container__text blue" style={fontStyle}>
        {text}
      </div>

      <div className="vertical-text-container__text green" style={fontStyle}>
        {text}
      </div>

      {/* <div className="vertical-text-container__text" style={fontStyle}>
        <div className="vertical-text-container__text__green"></div>
      </div> */}

      {/* <div className="vertical-text-container__blue" style={{ fontSize }}>
        {text}
      </div>

      <div className="vertical-text-container__green" style={{ fontSize }}>
        {text}
      </div> */}
    </div>
  );
}
