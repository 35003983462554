// V1
import "./ManageEventDetails.css";
import ManageEventInfo from "../ManageEventInfo";
import ManageEventRoles from "../ManageEventRoles";
import ManageEventVenue from "../ManageEventVenue";
import { useParams } from "react-router-dom";

function ManageEventDetails() {
  const { id } = useParams();
  return (
    <div className="manage-event-details">
      <ManageEventInfo id={id} />
      <ManageEventVenue id={id} />
      <ManageEventRoles id={id} />
    </div>
  );
}

export default ManageEventDetails;
