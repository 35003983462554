import { gql } from "@apollo/client";

const BADMINTON_STATS = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      placements {
        id
        value
        organization {
          id
          name
        }
      }
      event {
        id
        participantType
        sport {
          id
          name
          statTypes {
            id
            name
          }
        }
      }
      statsSummary {
        participantId
        pointsWon
        services
        servicesWon
        doubleFaults
        unforcedErrors
      }
      stats {
        id
        createdAt
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
        }
        organization {
          id
          name
        }
        fixturePeriod {
          id
          order
          label
        }
        relationshipsFrom {
          id
          to {
            id
            createdAt
            stringValue
            value
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
            fixturePeriod {
              id
              label
            }
          }
        }
        relationshipsTo {
          id
          from {
            id
            createdAt
            stringValue
            value
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
            fixturePeriod {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export default BADMINTON_STATS;
