import "./EventPhotos.css";
import { Fragment, useState } from "react";
import AlbumsCard from "../../AlbumsCard";
import PhotosCard from "../../PhotosCard";
import { gql } from "@apollo/client";
import { EVENT_PHOTOS, EVENT_ALBUMS } from "../../../api/event";
import Album from "../../../mobileComponents/Album";

export default function EventPhotos({ id, ev, canManageEvent }) {
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [teamsFilter] = useState([]);

  return (
    <div className="event-photos-mobile">
      {selectedAlbum ? (
        <Album
          id={selectedAlbum}
          dismiss={() => {
            setSelectedAlbum("");
          }}
        />
      ) : (
        <Fragment>
          <AlbumsCard
            query={EVENT_ALBUMS}
            vars={{
              id,
              filters: { teams: [] },
              take: 2,
            }}
            getAlbums={(data) => data?.event?.mediaSets || []}
            totalAlbums={(data) => data?.event?.albumsCount || 0}
            noData={`No albums tagged with ${ev.name} yet.`}
            setSelectedAlbum={setSelectedAlbum}
            filters={{ teamsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  teams: teamsFilter,
                },
              };
            }}
            addAlbum={
              canManageEvent
                ? {
                    sourceId: id,
                    sourceType: "event",
                    onAddAlbum: (cache, { data: { createMediaSet } }) => {
                      cache.modify({
                        id: cache.identify(ev),
                        fields: {
                          mediaSets(existingMediaRefs, { readField }) {
                            const newMediasetRef = cache.writeFragment({
                              data: createMediaSet,
                              fragment: gql`
                                fragment NewMediaSet on MediaSet {
                                  id
                                  name
                                  preview {
                                    id
                                    filename
                                  }
                                  source {
                                    id
                                    fixture {
                                      id
                                      scheduledStart
                                      roles(
                                        where: { type: { name: "PARTICIPANT" } }
                                      ) {
                                        id
                                        organization {
                                          id
                                          name
                                        }
                                        profile {
                                          id
                                          name
                                        }
                                      }
                                    }
                                  }
                                }
                              `,
                            });

                            // Quick safety check - if the new comment is already
                            // present in the cache, we don't need to add it again.
                            if (
                              existingMediaRefs.some(
                                (ref) =>
                                  readField("id", ref) === createMediaSet.id
                              )
                            ) {
                              return existingMediaRefs;
                            }

                            return [newMediasetRef, ...existingMediaRefs];

                            // const newMediaSets = [
                            //   createMediaSet,
                            //   ...existingMediaRefs,
                            // ];
                            // console.log("new Media Sets:", newMediaSets);
                            // return newMediaSets;
                          },
                        },
                      });
                    },
                  }
                : false
            }
          />

          <PhotosCard
            query={EVENT_PHOTOS}
            vars={{
              id,
              take: 16,
              filters: { teams: [] },
            }}
            getPhotos={(data) => data?.event?.media || []}
            totalPhotos={(data) => data?.event?.photosCount || 0}
            noData={`No tagged photos of ${ev.name} yet.`}
            filters={{ teamsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  teams: teamsFilter,
                },
              };
            }}
            onDelete={(cache, { data: { deleteMedia } }) => {
              cache.modify({
                id: cache.identify(ev),
                fields: {
                  media(existingMediaRefs, { readField }) {
                    return existingMediaRefs.filter(
                      (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
                    );
                  },
                },
              });
            }}
            addPhoto={
              canManageEvent
                ? {
                    sourceId: id,
                    sourceType: "event",
                    onAddPhoto: (cache, createMedia) => {
                      cache.modify({
                        id: cache.identify(ev),
                        fields: {
                          media(existingMediaRefs, { readField }) {
                            const newMediaRef = cache.writeFragment({
                              data: createMedia.media,
                              fragment: gql`
                                fragment NewMedia on Media {
                                  id
                                  createdAt
                                  type
                                  subtype
                                  isExternal
                                  filename
                                  public
                                  aspectRatio
                                  canManageMedia
                                }
                              `,
                            });

                            // Quick safety check - if the new comment is already
                            // present in the cache, we don't need to add it again.
                            if (
                              existingMediaRefs.some(
                                (ref) =>
                                  readField("id", ref) === createMedia.media.id
                              )
                            ) {
                              return existingMediaRefs;
                            }

                            return [newMediaRef, ...existingMediaRefs];

                            // const newMediaSets = [
                            //   createMediaSet,
                            //   ...existingMediaRefs,
                            // ];
                            // console.log("new Media Sets:", newMediaSets);
                            // return newMediaSets;
                          },
                        },
                      });
                      // setAppState({ modal: false });
                    },
                  }
                : false
            }
          />
        </Fragment>
      )}
    </div>
  );
}
