import "./Search.css";
import { useState, useCallback, Fragment } from "react";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { useLocation, useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { ES_SEARCH, SEARCH } from "../../api/user";
import { useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import makeFileUrl from "../../utils/makeFileUrl";
import Spinner from "../../components/Spinner";
import Loader from "../../components/Loader";
import captureException from "../../utils/captureException";
import Avatar from "../../components/Avatar";

// export default function Search() {
//   const history = useHistory();
//   const location = useLocation();
//   const [searchTerm, setSearchTerm] = useState("");
//   const [pagedSearchSection, setPagedSearchSection] = useState("");
//   const [, setResults] = useState({
//     profiles: [],
//     organizations: [],
//     events: [],
//   });
//   const [search, { loading, error, data, fetchMore, networkStatus }] =
//     useLazyQuery(SEARCH, {
//       notifyOnNetworkStatusChange: true,
//     });

//   if (error) {
//     captureException({
//       error,
//       info: {
//         type: "query",
//         component: "Search",
//         query: "SEARCH",
//       },
//     });
//     console.log(error);
//   }

//   const handleChange = (e) => {
//     if (e.target.value) {
//       // console.log("Firing query");
//       search({
//         variables: { term: e.target.value.trim() },
//       });
//     } else {
//       setResults(false);
//     }
//   };

//   const debouncedSearch = useCallback(
//     debounce(handleChange, 500, {
//       leading: false,
//       trailing: true,
//     }),
//     []
//   );

//   const profiles = data?.search?.profiles || [];
//   const organizations = data?.search?.organizations || [];
//   const events = data?.search?.events || [];

//   const profileItems = pagedSearchSection ? profiles : profiles.slice(0, 5);
//   const organizationItems = pagedSearchSection
//     ? organizations
//     : organizations.slice(0, 5);
//   const eventItems = pagedSearchSection ? events : events.slice(0, 5);

//   // console.log("NS:", networkStatus);
//   return (
//     <div className="search-page-mobile">
//       <div className="search-page-mobile__header">
//         <div
//           className="search-page-mobile__header__back-btn"
//           onClick={() => {
//             if (location.key) {
//               history.goBack();
//             } else {
//               history.replace("/app");
//             }
//           }}
//         >
//           <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
//         </div>

//         <div className="search-page-mobile__header__input-wrapper">
//           <input
//             onChange={(e) => {
//               setSearchTerm(e.target.value);
//               debouncedSearch(e);
//             }}
//             value={searchTerm}
//             placeholder={"Search"}
//             autoFocus={true}
//           />

//           {networkStatus === 1 && (
//             <div className="search-page-mobile__header__input-wrapper__loading">
//               <Spinner size={14} />
//             </div>
//           )}
//         </div>

//         <div className="search-page-mobile__header__options"></div>
//       </div>

//       <div className="search-page-mobile__tabs">
//         <div
//           className={
//             "search-page-mobile__tabs__tab " +
//             (!pagedSearchSection ? "active" : "")
//           }
//           onClick={() => {
//             setPagedSearchSection("");
//           }}
//         >
//           All
//         </div>

//         <div
//           className={
//             "search-page-mobile__tabs__tab " +
//             (pagedSearchSection === "profiles" ? "active" : "")
//           }
//           onClick={() => {
//             setPagedSearchSection("profiles");
//           }}
//         >
//           Profiles
//         </div>

//         <div
//           className={
//             "search-page-mobile__tabs__tab " +
//             (pagedSearchSection === "organizations" ? "active" : "")
//           }
//           onClick={() => {
//             setPagedSearchSection("organizations");
//           }}
//         >
//           Pages
//         </div>

//         <div
//           className={
//             "search-page-mobile__tabs__tab " +
//             (pagedSearchSection === "events" ? "active" : "")
//           }
//           onClick={() => {
//             setPagedSearchSection("events");
//           }}
//         >
//           Events
//         </div>
//       </div>

//       <div className="search-page-mobile__body">
//         {searchTerm ? (
//           <Fragment>
//             {(!pagedSearchSection || pagedSearchSection === "profiles") &&
//               !!profiles.length && (
//                 <div className="search-page-mobile__section">
//                   {!pagedSearchSection && (
//                     <div className="search-page-mobile__section__title">
//                       PROFILES
//                       {!!profiles?.length && (
//                         <div
//                           className="search-page-mobile__section__title__show-more"
//                           onClick={() => {
//                             //   console.log("Clicked search");
//                             setPagedSearchSection("profiles");
//                           }}
//                         >
//                           Show More
//                         </div>
//                       )}
//                     </div>
//                   )}

//                   <div className="search-page-mobile__section__items">
//                     {!profiles?.length && (
//                       <div className="no-data">
//                         <div>No results found</div>
//                       </div>
//                     )}
//                     {profileItems?.map((item) => (
//                       <Link
//                         key={item.id}
//                         to={"/app/profile/" + item.id}
//                         onClick={() => {
//                           //   setShowMenu(false);
//                           setSearchTerm("");
//                         }}
//                         className="search-page-mobile__section__items__item"
//                       >
//                         <div className="search-page-mobile__section__items__item__image">
//                           {item.profilePhoto && (
//                             <img src={makeFileUrl(item.profilePhoto)} alt="" />
//                           )}
//                         </div>
//                         <div className="search-page-mobile__section__items__item__desc">
//                           <div className="search-page-mobile__section__items__item__desc__primary">
//                             {item.name}
//                           </div>
//                           {item.handle && (
//                             <div className="search-page-mobile__section__items__item__desc__secondary">
//                               @{item.handle}
//                             </div>
//                           )}
//                         </div>
//                       </Link>
//                     ))}

//                     {!!profiles?.length && pagedSearchSection && (
//                       <div
//                         className="search-page-mobile__section__show-more"
//                         onClick={() => {
//                           // console.log("Clicked search");
//                           fetchMore({
//                             variables: {
//                               term: searchTerm,
//                               disableOrganizations: true,
//                               disableEvents: true,
//                               take: 25,
//                               cursor: profiles?.[profiles?.length - 1].id,
//                             },
//                           });
//                         }}
//                       >
//                         {networkStatus === 3 ? (
//                           <Loader theme="blue small" />
//                         ) : (
//                           "Show More"
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               )}

//             {(!pagedSearchSection || pagedSearchSection === "organizations") &&
//               !!organizations.length && (
//                 <div className="search-page-mobile__section">
//                   {!pagedSearchSection && (
//                     <div className="search-page-mobile__section__title">
//                       ORGANIZATIONS
//                       {!!organizations?.length && (
//                         <div
//                           className="search-page-mobile__section__title__show-more"
//                           onClick={() => {
//                             //   console.log("Clicked search");
//                             setPagedSearchSection("organizations");
//                           }}
//                         >
//                           Show More
//                         </div>
//                       )}
//                     </div>
//                   )}

//                   <div className="search-page-mobile__section__items">
//                     {!organizations?.length && (
//                       <div className="no-data">
//                         <div>No results found</div>
//                       </div>
//                     )}
//                     {organizationItems?.map((item) => (
//                       <Link
//                         key={item.id}
//                         to={"/app/page/" + item.id}
//                         onClick={() => {
//                           //   setShowMenu(false);
//                           setSearchTerm("");
//                         }}
//                         className="search-page-mobile__section__items__item"
//                       >
//                         <div className="search-page-mobile__section__items__item__image">
//                           {item.profilePhoto && (
//                             <img src={makeFileUrl(item.profilePhoto)} alt="" />
//                           )}
//                         </div>
//                         <div className="search-page-mobile__section__items__item__desc">
//                           <div className="search-page-mobile__section__items__item__desc__primary">
//                             {item.name}
//                           </div>
//                           {item.handle && (
//                             <div className="search-page-mobile__section__items__item__desc__secondary">
//                               @{item.handle}
//                             </div>
//                           )}
//                         </div>
//                       </Link>
//                     ))}

//                     {!!organizations?.length && pagedSearchSection && (
//                       <div
//                         className="search-page-mobile__section__show-more"
//                         onClick={() => {
//                           // console.log("Clicked search");
//                           fetchMore({
//                             variables: {
//                               term: searchTerm,
//                               disableProfiles: true,
//                               disableEvents: true,
//                               take: 25,
//                               cursor:
//                                 organizations?.[organizations?.length - 1].id,
//                             },
//                           });
//                         }}
//                       >
//                         {loading ? "..." : "Show More"}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               )}

//             {(!pagedSearchSection || pagedSearchSection === "events") &&
//               !!events.length && (
//                 <div className="search-page-mobile__section">
//                   {!pagedSearchSection && (
//                     <div className="search-page-mobile__section__title">
//                       EVENTS
//                       {!!events?.length && (
//                         <div
//                           className="search-page-mobile__section__title__show-more"
//                           onClick={() => {
//                             //   console.log("Clicked search");
//                             setPagedSearchSection("events");
//                           }}
//                         >
//                           Show More
//                         </div>
//                       )}
//                     </div>
//                   )}

//                   <div className="search-page-mobile__section__items">
//                     {!events?.length && (
//                       <div className="no-data">
//                         <div>No results found</div>
//                       </div>
//                     )}
//                     {eventItems?.map((item) => (
//                       <Link
//                         key={item.id}
//                         to={"/app/event/" + item.id}
//                         onClick={() => {
//                           //   setShowMenu(false);
//                           setSearchTerm("");
//                         }}
//                         className="search-page-mobile__section__items__item"
//                       >
//                         <div className="search-page-mobile__section__items__item__image">
//                           {item.profilePhoto && (
//                             <img src={makeFileUrl(item.profilePhoto)} alt="" />
//                           )}
//                         </div>
//                         <div className="search-page-mobile__section__items__item__desc">
//                           <div className="search-page-mobile__section__items__item__desc__primary">
//                             {item.name}
//                           </div>
//                           {item.handle && (
//                             <div className="search-page-mobile__section__items__item__desc__secondary">
//                               @{item.handle}
//                             </div>
//                           )}
//                         </div>
//                       </Link>
//                     ))}

//                     {!!events?.length && pagedSearchSection && (
//                       <div
//                         className="search-page-mobile__section__show-more"
//                         onClick={() => {
//                           // console.log("Clicked search");
//                           fetchMore({
//                             variables: {
//                               term: searchTerm,
//                               disableProfiles: true,
//                               disableOrganizations: true,
//                               take: 25,
//                               cursor: events?.[events?.length - 1].id,
//                             },
//                           });
//                         }}
//                       >
//                         {loading ? "..." : "Show More"}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               )}
//             {/* {!pagedSearchSection ||
//               (pagedSearchSection === "profiles" && (
//                 <AppSearchSection
//                   title={"PROFILES"}
//                   defaultItems={results?.profiles || []}
//                   linkTo={(item) => "/app/profile/" + item.id}
//                   setSearchTerm={setSearchTerm}
//                   showMore={() => {
//                     setPagedSearchSection("profiles");
//                   }}
//                   selectedTab={pagedSearchSection}
//                   searchTerm={searchTerm}
//                   loading={loading}
//                   fetchMore={fetchMore}
//                 />
//               ))}

//             {(!pagedSearchSection ||
//               pagedSearchSection === "organizations") && (
//               <AppSearchSection
//                 title={"PAGES"}
//                 defaultItems={results?.organizations || []}
//                 linkTo={(item) => "/app/page/" + item.id}
//                 setSearchTerm={setSearchTerm}
//                 showMore={() => {
//                   setPagedSearchSection("organizations");
//                 }}
//                 selectedTab={pagedSearchSection}
//                 searchTerm={searchTerm}
//                 loading={loading}
//                 fetchMore={fetchMore}
//               />
//             )}

//             {(!pagedSearchSection || pagedSearchSection === "events") && (
//               <AppSearchSection
//                 title={"EVENTS"}
//                 defaultItems={results?.events || []}
//                 linkTo={(item) => "/app/event/" + item.id}
//                 setSearchTerm={setSearchTerm}
//                 showMore={() => {
//                   setPagedSearchSection("events");
//                 }}
//                 selectedTab={pagedSearchSection}
//                 searchTerm={searchTerm}
//                 loading={loading}
//                 fetchMore={fetchMore}
//               />
//             )} */}
//           </Fragment>
//         ) : (
//           <div className="no-data">
//             <div>Search for profiles, pages or events</div>
//           </div>
//         )}

//         {/* {pagedSearchSection ? (
//           <PagedViewWrapper
//             selectedTab={pagedSearchSection}
//             setSearchTerm={setSearchTerm}
//             dismiss={() => setPagedSearchSection("")}
//             searchTerm={searchTerm}
//           />
//         ) : (
//           <Fragment>
//             {loading && (
//               <div
//                 style={{
//                   height: "100px",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//               >
//                 <Spinner />
//               </div>
//             )}
//             {!loading &&
//               !profiles?.length &&
//               !organizations?.length &&
//               !events?.length && (
//                 <div className="no-data">
//                   {!searchTerm ? (
//                     <div>Search for profiles, pages or events</div>
//                   ) : (
//                     <div>No results found</div>
//                   )}
//                 </div>
//               )}

//             {!!profiles?.length && (
//               <AppSearchSection
//                 title={"PROFILES"}
//                 defaultItems={profiles}
//                 linkTo={(item) => "/app/profile/" + item.id}
//                 setSearchTerm={setSearchTerm}
//                 showMore={() => {
//                   setPagedSearchSection("profiles");
//                 }}
//               />
//             )}

//             {!!organizations?.length && (
//               <AppSearchSection
//                 title={"PAGES"}
//                 defaultItems={organizations}
//                 linkTo={(item) => "/app/page/" + item.id}
//                 setSearchTerm={setSearchTerm}
//                 showMore={() => {
//                   setPagedSearchSection("organizations");
//                 }}
//               />
//             )}

//             {!!events?.length && (
//               <AppSearchSection
//                 title={"EVENTS"}
//                 defaultItems={events}
//                 linkTo={(item) => "/app/event/" + item.id}
//                 showMore={() => {
//                   setPagedSearchSection("events");
//                 }}
//               />
//             )}
//           </Fragment>
//         )} */}
//       </div>
//     </div>
//   );
// }

function linkTo(item) {
  const urlMapping = {
    profile: "profile",
    organization: "page",
    event: "event",
  };

  if (!item?.id) {
    return "";
  }

  return "/app/" + urlMapping[item.node_type] + "/" + item.id;
}

export default function Search() {
  const history = useHistory();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [section, setSection] = useState(false);
  // const [pagedSearchSection, setPagedSearchSection] = useState("");
  // const [, setResults] = useState({
  //   profiles: [],
  //   organizations: [],
  //   events: [],
  // });
  const [esSearch, { loading, error, data, fetchMore, networkStatus }] =
    useLazyQuery(ES_SEARCH);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Search",
        query: "ES_SEARCH",
      },
    });
    console.log(error);
  }

  const handleChange = (e, section, nextCursor) => {
    if (e.target.value) {
      // console.log("Firing query");
      esSearch({
        variables: {
          term: e.target.value.trim(),
          types: section ? [section] : null,
          cursor: 0,
        },
      });
    }
  };

  const handleSectionChange = (s) => {
    esSearch({
      variables: {
        term: searchTerm.trim(),
        types: s ? [s] : null,
        cursor: 0,
      },
    });
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const payload = data?.esSearch;
  const total = payload?.total || 0;
  const nextCursor = payload?.nextCursor || 0;
  const hits = payload?.hits || [];

  // console.log("NS:", networkStatus);
  return (
    <div className="search-page-mobile">
      <div className="search-page-mobile__header">
        <div
          className="search-page-mobile__header__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>

        <div className="search-page-mobile__header__input-wrapper">
          <input
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSearch(e, section, nextCursor);
            }}
            value={searchTerm}
            placeholder={"Search"}
            autoFocus={true}
          />

          {networkStatus === 1 && (
            <div className="search-page-mobile__header__input-wrapper__loading">
              <Spinner size={14} />
            </div>
          )}
        </div>

        <div className="search-page-mobile__header__options"></div>
      </div>

      <div className="search-page-mobile__tabs">
        <div
          className={
            "search-page-mobile__tabs__tab " + (!section ? "active" : "")
          }
          onClick={() => {
            setSection(false);
            handleSectionChange(false);
          }}
        >
          All
        </div>

        <div
          className={
            "search-page-mobile__tabs__tab " +
            (section === "profile" ? "active" : "")
          }
          onClick={() => {
            setSection("profile");
            handleSectionChange("profile");
          }}
        >
          Profiles
        </div>

        <div
          className={
            "search-page-mobile__tabs__tab " +
            (section === "organization" ? "active" : "")
          }
          onClick={() => {
            setSection("organization");
            handleSectionChange("organization");
          }}
        >
          Pages
        </div>

        <div
          className={
            "search-page-mobile__tabs__tab " +
            (section === "event" ? "active" : "")
          }
          onClick={() => {
            setSection("event");
            handleSectionChange("event");
          }}
        >
          Events
        </div>
      </div>

      <div className="search-page-mobile__body">
        {!searchTerm ? (
          <div className="no-data">
            <div>Search for profiles, pages or events</div>
          </div>
        ) : (
          <div className="search-page-mobile__section__items">
            {loading ? (
              <div className="no-data">
                <Spinner />
              </div>
            ) : (
              <Fragment>
                {hits?.map((item) => (
                  <Link
                    key={item.id}
                    to={linkTo(item)}
                    onClick={() => {
                      //   setShowMenu(false);
                      setSearchTerm("");
                      setSection(false);
                    }}
                    className="search-page-mobile__section__items__item"
                  >
                    <div className="search-page-mobile__section__items__item__image">
                      {item.profilePhoto && (
                        <Avatar media={{ filename: item.profilePhoto }} />
                        // <img src={makeFileUrl(item.profilePhoto)} alt="" />
                      )}
                    </div>
                    <div className="search-page-mobile__section__items__item__desc">
                      <div className="search-page-mobile__section__items__item__desc__primary">
                        {item.name}
                      </div>
                      {item.handle && (
                        <div className="search-page-mobile__section__items__item__desc__secondary">
                          @{item.handle}
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
              </Fragment>
            )}

            {!loading && !!hits?.length && hits.length < total && (
              <div
                className="search-page-mobile__section__show-more"
                onClick={() => {
                  // console.log("Clicked search");
                  fetchMore({
                    variables: {
                      term: searchTerm.trim(),
                      cursor: nextCursor,
                      types: section ? [section] : null,
                    },
                  });
                }}
              >
                {loading ? "..." : "Show More"}
              </div>
            )}
          </div>
        )}

        {/* {pagedSearchSection ? (
          <PagedViewWrapper
            selectedTab={pagedSearchSection}
            setSearchTerm={setSearchTerm}
            dismiss={() => setPagedSearchSection("")}
            searchTerm={searchTerm}
          />
        ) : (
          <Fragment>
            {loading && (
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner />
              </div>
            )}
            {!loading &&
              !profiles?.length &&
              !organizations?.length &&
              !events?.length && (
                <div className="no-data">
                  {!searchTerm ? (
                    <div>Search for profiles, pages or events</div>
                  ) : (
                    <div>No results found</div>
                  )}
                </div>
              )}

            {!!profiles?.length && (
              <AppSearchSection
                title={"PROFILES"}
                defaultItems={profiles}
                linkTo={(item) => "/app/profile/" + item.id}
                setSearchTerm={setSearchTerm}
                showMore={() => {
                  setPagedSearchSection("profiles");
                }}
              />
            )}

            {!!organizations?.length && (
              <AppSearchSection
                title={"PAGES"}
                defaultItems={organizations}
                linkTo={(item) => "/app/page/" + item.id}
                setSearchTerm={setSearchTerm}
                showMore={() => {
                  setPagedSearchSection("organizations");
                }}
              />
            )}

            {!!events?.length && (
              <AppSearchSection
                title={"EVENTS"}
                defaultItems={events}
                linkTo={(item) => "/app/event/" + item.id}
                showMore={() => {
                  setPagedSearchSection("events");
                }}
              />
            )}
          </Fragment>
        )} */}
      </div>
    </div>
  );
}
