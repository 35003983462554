// V1
import { Fragment, useEffect } from "react";
import "./ProfileVideos.css";
import Card from "../../components/Card";
import { useQuery } from "@apollo/client";
import {
  PROFILE_EVENTS,
  PROFILE_VIDEOS,
  PROFILE_TAGGED_VIDEOS,
} from "../../api/profile";
import { PAGES } from "../../api/profile";
import { SPORTS } from "../../api/sport";
import VideoCard from "../VideoCard";
import { useState } from "react";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import Loader from "../Loader";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import Placeholder from "../Placeholder";
import captureException from "../../utils/captureException";

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function VideosSection({
  title,
  query,
  vars,
  getVideos,
  totalVideos,
  noData,
  filters,
  processFilters,
}) {
  const { networkStatus, error, data, variables, fetchMore, refetch } =
    useQuery(query, {
      variables: vars,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    const filterVars = processFilters(filters);

    if (filterVars) {
      refetch({
        ...vars,
        ...filterVars,
      });
    }
  }, [filters]);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "VideosSection",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 2 || networkStatus === 4) {
    return (
      <Fragment>
        {[1, 2].map((v) => (
          <Card key={"profile-video-ph-" + v} className="video-list-item">
            <div className="video-wrapper">
              <Placeholder
                style={{
                  height: "280px",
                  width: "100%",
                  background: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="video-info">
              <div className="video-info__source">
                <div className="video-info__source__image"></div>
                <div className="video-info__source__text">
                  <div className="video-info__source__text__primary">
                    <Placeholder
                      style={{ height: "12px", width: "80px" }}
                      pulse={true}
                    />
                  </div>
                </div>
              </div>
              <div className="video-info__desc">
                <Placeholder
                  style={{ height: "10px", width: "100%" }}
                  pulse={true}
                />
                <Placeholder
                  style={{ height: "10px", width: "80%", marginTop: "8px" }}
                  pulse={true}
                />
              </div>
            </div>
          </Card>
        ))}
      </Fragment>
    );
  }

  const videos = getVideos(data);
  const videosCount = totalVideos(data);

  if (!videos.length) {
    return (
      <div className="profile-videos-gallery__section">
        <div className="profile-videos-gallery__section__title">{title}</div>

        {!videos.length && (
          <div className="no-videos-container" style={{ paddingTop: "8px" }}>
            <div className="text">{noData}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="profile-videos-gallery__section">
      <div className="profile-videos-gallery__section__title">{title}</div>

      {videos.map((v) => (
        <VideoCard key={v.id} media={v} videoHeight={280} />
      ))}

      {!!videos.length && videos.length < videosCount && (
        <Card
          className="show-more-container"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: videos.length ? videos[videos.length - 1]?.id : null,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
        </Card>
      )}
    </div>
  );
}

function ProfileVideos({ id, profile }) {
  const [sportsFilter, setSportsFilter] = useState([]);
  const [teamsFilter, setTeamsFilter] = useState([]);
  const [eventsFilter, setEventsFilter] = useState([]);

  return (
    <div className="profile-videos">
      <div className="left-column">
        {/* {!videosCount && (
          <div className="no-videos-container">
            <div className="text">No tagged videos for this profile yet.</div>
          </div>
        )} */}
        <div
          className="profile-videos-gallery"
          style={{ flexDirection: "column" }}
        >
          <VideosSection
            title="Tagged Videos"
            query={PROFILE_TAGGED_VIDEOS}
            vars={{
              id,
              take: 2,
              filters: { sports: [], teams: [], events: [] },
            }}
            getVideos={(data) => data?.profile?.taggedMedia || []}
            totalVideos={(data) => data?.profile?.taggedVideosCount || 0}
            noData={`No tagged videos found.`}
            filters={{ sportsFilter, teamsFilter, eventsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  sports: sportsFilter,
                  teams: teamsFilter,
                  events: eventsFilter,
                },
              };
            }}
          />

          <VideosSection
            title={profile.name + "'s Matches"}
            query={PROFILE_VIDEOS}
            vars={{
              id,
              take: 2,
              filters: { sports: [], teams: [], events: [] },
            }}
            getVideos={(data) => data?.profile?.media || []}
            totalVideos={(data) => data?.profile?.videosCount || 0}
            noData={`No match videos found.`}
            filters={{ sportsFilter, teamsFilter, eventsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  sports: sportsFilter,
                  teams: teamsFilter,
                  events: eventsFilter,
                },
              };
            }}
          />
          {/* <TaggedVideos id={id} /> */}
          {/* <MatchVideos id={id} /> */}

          {/* {videos.map((v) => (
            <VideoCard key={v.id} media={v} videoHeight={280} />
          ))} */}
        </div>
      </div>

      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={"Enter a sport"}
            query={SPORTS}
            items={(d) => {
              return d?.sports;
            }}
            addFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
              // refetch({
              //   ...variables,
              //   filters: {
              //     ...variables.filters,
              //     sports: sports.map((s) => s.name),
              //   },
              // });
            }}
            removeFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
              // refetch({
              //   ...variables,
              //   filters: {
              //     ...variables.filters,
              //     sports: sports.map((s) => s.name),
              //   },
              // });
            }}
            defaultValue={sportsFilter}
            appStateKey={"sportsFilter"}
          />

          <DirectoryFilter
            placeholder={"Enter a team"}
            query={PAGES}
            vars={{ id, historical: true }}
            items={(d) => {
              return d?.pages?.map((x) => x.organization);
            }}
            addFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              // refetch({
              //   ...variables,
              //   filters: {
              //     ...variables.filters,
              //     teams: orgs.map((s) => s.id),
              //   },
              // });
            }}
            removeFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              // refetch({
              //   ...variables,
              //   filters: {
              //     ...variables.filters,
              //     teams: orgs.map((s) => s.id),
              //   },
              // });
            }}
            defaultValue={teamsFilter}
            appStateKey={"teamsFilter"}
          />

          <DirectoryFilter
            placeholder={"Enter an event"}
            query={PROFILE_EVENTS}
            vars={{ id }}
            items={(d) => {
              return d?.profile?.events;
            }}
            addFilter={(events) => {
              setEventsFilter(events.map((s) => s.id));
              // refetch({
              //   ...variables,
              //   filters: {
              //     ...variables.filters,
              //     events: events.map((s) => s.id),
              //   },
              // });
            }}
            removeFilter={(events) => {
              setEventsFilter(events.map((s) => s.id));
              // refetch({
              //   ...variables,
              //   filters: {
              //     ...variables.filters,
              //     events: events.map((s) => s.id),
              //   },
              // });
            }}
            defaultValue={eventsFilter}
            appStateKey={"eventsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileVideos;
