import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      expiresAt
      name
    }
  }
`;

const SIGNUP = gql`
  mutation signup(
    $name: String!
    $firstname: String!
    $lastname: String!
    $handle: String!
    $email: String!
    $password: String!
  ) {
    signup(
      name: $name
      firstname: $firstname
      lastname: $lastname
      handle: $handle
      email: $email
      password: $password
    ) {
      token
      expiresAt
      name
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      token
      expiresAt
      name
    }
  }
`;

const SEND_VERIFICATION_EMAIL = gql`
  mutation sendVerificationEmail {
    sendVerificationEmail {
      success
      error
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
      error
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      success
      error
    }
  }
`;

const IS_UNIQUE_HANDLE = gql`
  query isUniqueHandle($handle: String!) {
    isUniqueHandle(handle: $handle) {
      valid
    }
  }
`;

export {
  LOGIN,
  SIGNUP,
  VERIFY_EMAIL,
  SEND_VERIFICATION_EMAIL,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  IS_UNIQUE_HANDLE,
};
