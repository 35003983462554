export default function ballsCompleted(overItems) {
  const totalBalls = overItems?.length || 0;
  const totalExtras =
    overItems?.filter((item) =>
      item.extras?.find((s) => ["Wide", "No Ball"].includes(s.statType.name))
    ).length || 0;

  // console.log("Total Balls:", totalBalls);
  // console.log("Total Extras:", totalExtras);
  return totalBalls - totalExtras;
}
