import "./BasketballStats.css";
import Square from "../../../Square";
import VerticalText from "../../../VerticalText";
import { useQuery } from "@apollo/client";
import { PROFILE_AWARDS, PROFILE_STATS } from "../../../../api/profile";
import captureException from "../../../../utils/captureException";
import { Link } from "react-router-dom";

function ProfileOffenseStats({
  appearances,
  points,
  assists,
  pointsPerGame,
  assistsPerGame,
  rebounds,
}) {
  return (
    <div className="profile-attack-stats-mobile">
      <div className="profile-attack-stats-mobile__title">OFFENSE</div>
      <div className="profile-attack-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {appearances}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Appearances
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {points}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Points
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {assists}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Assists
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {pointsPerGame}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                PPG
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {assistsPerGame}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                APG
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {rebounds}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Rebounds
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats-mobile__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"OFFENSE"}
              fontStyle={{
                fontSize: "76px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "57px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileShootingStats({
  onePointFgm = 0,
  onePointFga = 0,
  twoPointFgm = 0,
  twoPointFga = 0,
  threePointFgm = 0,
  threePointFga = 0,
  ftm = 0,
  fta = 0,
}) {
  const onePointFgp = onePointFga ? (onePointFgm / onePointFga) * 100 : 0;
  const twoPointFgp = twoPointFga ? (twoPointFgm / twoPointFga) * 100 : 0;
  const threePointFgp = threePointFga
    ? (threePointFgm / threePointFga) * 100
    : 0;

  const ftp = fta ? (ftm / fta) * 100 : 0;

  return (
    <div className="profile-shooting-stats-mobile">
      <div className="profile-shooting-stats-mobile__title">SHOOTING</div>

      <div className="profile-shooting-stats-mobile__items">
        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              1 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {onePointFgm || 0} / {onePointFga || 0} ({onePointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: onePointFga ? onePointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              2 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {twoPointFgm || 0} / {twoPointFga || 0} ({twoPointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: twoPointFga ? twoPointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              3 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {threePointFgm || 0} / {threePointFga || 0} (
              {threePointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: threePointFga ? threePointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              Free Throws
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {ftm || 0} / {fta || 0} ({ftp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: fta ? ftp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileDefenseStats({ blocks, steals, turnovers, fouls, rebounds }) {
  return (
    <div className="profile-attack-stats-mobile">
      <div className="profile-attack-stats-mobile__title">DEFENSE</div>
      <div className="profile-attack-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {blocks}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Blocks
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {steals}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Steals
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {turnovers}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Turnovers
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {fouls}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Fouls
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {rebounds}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Rebounds
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats-mobile__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"DEFENSE"}
              fontStyle={{
                fontSize: "64px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "49px",
                height: "64px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileParticipationHistory({ participationHistory = [] }) {
  const rows = participationHistory.map((p) => ({
    ...p,
    stats: JSON.parse(p.stats.data),
  }));
  return (
    <div className="profile-participation-stats-mobile">
      <div className="profile-participation-stats-mobile__title">
        PARTICIPATION HISTORY
      </div>

      <div className="profile-participation-stats-mobile__table">
        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "50%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header with-image">
            Event
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td with-image " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {/* <div className="profile-participation-stats-mobile__table__td__image"></div> */}
                <Link
                  to={"/app/event/" + r.event?.id}
                  className="profile-participation-stats-mobile__table__td__meta"
                >
                  <div className="profile-participation-stats-mobile__table__td__meta__primary">
                    {r.event?.name}
                  </div>

                  <div className="profile-participation-stats-mobile__table__td__meta__secondary">
                    {r.organization?.name}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            GP
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Appearances"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            P
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["PTS"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            A
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["AST"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            S
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["STL"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            B
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["BLK"] || 0}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileAwards({ id, sport }) {
  const { loading, error, data } = useQuery(PROFILE_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.BasketballStats.ProfileAwards",
        query: "PROFILE_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const profile = data.profile;
  const placements = profile?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats-mobile">
      <div className="profile-awards-stats-mobile__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats-mobile__items">
          <div className="no-data">No awards for this player yet.</div>
        </div>
      )}

      <div className="profile-awards-stats-mobile__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats-mobile__items__item">
              <div className="profile-awards-stats-mobile__items__item__img"></div>
              <div className="profile-awards-stats-mobile__items__item__desc">
                <div className="profile-awards-stats-mobile__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats-mobile__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function BasketballStats({ id, sport, events }) {
  const { loading, error, data } = useQuery(PROFILE_STATS, {
    variables: {
      id,
      sport: sport || "Basketball",
      statTypes: [
        "1FGA",
        "1FGM",
        "2FGA",
        "2FGM",
        "3FGA",
        "3FGM",
        "BLK",
        "PTS",
        "FTA",
        "FTM",
        "OFF REB",
        "DEF REB",
        "AST",
        "STL",
        "T/O",
        "FOULS",
      ],
      participationStatTypes: ["PTS", "AST", "STL", "BLK"],
      events,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.BasketballStats",
        query: "PROFILE_STATS",
      },
    });
    console.log(error);
  }

  if (loading) {
    return <div>Loading</div>;
  }

  // console.log("STATS:", data);

  const { appearances, participationHistory } = data?.profile?.stats || {};

  const statsData = JSON.parse(
    data?.profile?.stats?.aggregations?.data || "{}"
  );

  return (
    <div className="profile-stats-mobile__basketball">
      <ProfileOffenseStats
        appearances={appearances || 0}
        points={statsData["PTS"] || 0}
        assists={statsData["AST"] || 0}
        pointsPerGame={
          appearances ? (statsData["PTS"] / appearances).toFixed(1) : 0
        }
        assistsPerGame={
          appearances ? (statsData["AST"] / appearances).toFixed(1) : 0
        }
        rebounds={statsData["OFF REB"] || 0}
      />
      <ProfileShootingStats
        onePointFga={statsData["1FGA"] || 0}
        onePointFgm={statsData["1FGM"] || 0}
        twoPointFga={statsData["2FGA"] || 0}
        twoPointFgm={statsData["2FGM"] || 0}
        threePointFga={statsData["3FGA"] || 0}
        threePointFgm={statsData["3FGM"] || 0}
        fta={statsData["FTA"] || 0}
        ftm={statsData["FTM"] || 0}
      />
      <ProfileDefenseStats
        blocks={statsData["BLK"] || 0}
        steals={statsData["STL"] || 0}
        turnovers={statsData["T/O"] || 0}
        fouls={statsData["FOULS"] || 0}
        rebounds={statsData["DEF REB"] || 0}
      />
      <ProfileParticipationHistory
        participationHistory={participationHistory}
      />
      <ProfileAwards id={id} sport={"Basketball"} />
    </div>
  );
}
