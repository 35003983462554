// V1
import "./ManageEvent.css";
import ManageEventDetails from "../ManageEventDetails";
import ManageEventFixtures from "../ManageEventFixtures";
import ManageEventStandings from "../ManageEventStandings";
import ManageEventPosts from "../ManageEventPosts";
import ManageEventAwards from "../ManageEventAwards";
import ManageEventParties from "../ManageEventParties";
import Spinner from "../../Spinner";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { EVENT } from "../../../api/event";
import { CAN_MANAGE_EVENT } from "../../../api/user";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";
import useRouterQuery from "../../../utils/useRouterQuery";
import { usePageTracking } from "../../../utils/usePageTracking";
import captureException from "../../../utils/captureException";

const manageEventTabs = [
  { label: "Details", component: ManageEventDetails, key: "details" },
  {
    label: "Teams & Registrations",
    component: ManageEventParties,
    key: "regs",
  },
  { label: "Fixtures", component: ManageEventFixtures, key: "fixtures" },
  { label: "Standings", component: ManageEventStandings, key: "standings" },
  { label: "Posts", component: ManageEventPosts, key: "posts" },
  { label: "Awards", component: ManageEventAwards, key: "awards" },
];

function ManageEvent() {
  usePageTracking({ title: "Manage Event" });
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const tab = query.get("t") || "details";
  const {
    loading: authLoading,
    error: authError,
    data: authData,
  } = useQuery(CAN_MANAGE_EVENT, { variables: { id } });
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });
  const selectedTab = manageEventTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  if (authError) {
    captureException({
      error: authError,
      info: {
        type: "query",
        component: "ManageEvent",
        query: "CAN_MANAGE_EVENT",
      },
    });
    console.log(authError);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEvent",
        query: "EVENT",
      },
    });
    console.log(error);
  }

  if (error || authError) {
    return <div>Error</div>;
  }

  if (loading || authLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const canManageEvent = authData?.canManageEvent?.authorized;

  if (!canManageEvent) {
    return <div>You are not authorized to manage this event.</div>;
  }

  return (
    <div className="scoring-app-page">
      <div className="scoring-app-page-title">
        <div
          className="back-btn hover-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowLeftIcon style={{ stroke: "var(--dark-4)" }} />
        </div>
        {ev.name}
      </div>
      <div className="tabs">
        {manageEventTabs.map((t, i) => (
          <div
            key={"manage-event-tab-" + t.key}
            className={
              "tab-item hover-pointer " +
              (selectedTab.key === t.key ? "active" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>
      <div className="scoring-app-body">
        <SelectedComponent id={id} />
      </div>
    </div>
  );
}

export default ManageEvent;
