import { gql } from "@apollo/client";

const EVENT_ALBUMS = gql`
  query eventQuery(
    $id: ID!
    $take: Int
    $cursor: ID
    $filters: EventMediaFilters
  ) {
    event(id: $id) {
      id
      albumsCount
      mediaSets(take: $take, cursor: $cursor, filters: $filters) {
        id
        name
        preview {
          id
          filename
        }
        source {
          id
          fixture {
            id
            scheduledStart
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              organization {
                id
                name
              }
              profile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default EVENT_ALBUMS;
