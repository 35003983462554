// V1
import Card from "../../Card";
import "./FixtureTimeline.css";
import AppLink from "../../AppLink";
import Tooltip from "../../Tooltip/Tooltip";
// import { FIXTURE_TIMELINE } from "../../../api/fixture";
import { useQuery } from "@apollo/client";
import { ReactComponent as FootballIcon } from "../../../icons/football.svg";
import { ReactComponent as SwapIcon } from "../../../icons/swap.svg";
import { ReactComponent as EllipsisIcon } from "../../../icons/ellipsis.svg";
import Avatar from "../../Avatar";
import { FIXTURE } from "../../../api/functions";

const sumToPeriod = (index, periods) => {
  let sum = 0;

  for (let i = 0; i < index + 1; i++) {
    sum += periods[i]?.duration || 45;
  }

  return sum;
};

function FixtureTimeline({ id }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      periods {
        id
        order
        duration
        metas {
          id
          label
          stringValue
        }
      }
      roles(where:{type:{name:"PARTICIPANT"}}){
        id
        order
        type {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      stats(where:{statType:{name:{in:["Goal", "Own Goal", "Red Card", "Yellow Card", "Sub On", "Sub Off"]}}}){
        id
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        fixturePeriod {
          id
          order
          label
        }
      }
    `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const homeTeam = data?.fixture?.roles?.find(
    (r) => !r.order && r.type.name === "PARTICIPANT"
  );
  const awayTeam = data?.fixture?.roles?.find(
    (r) => r.order && r.type.name === "PARTICIPANT"
  );

  const stats = data?.fixture?.stats.filter(
    (s) =>
      s.statType.name === "Goal" ||
      s.statType.name === "Own Goal" ||
      s.statType.name === "Red Card" ||
      s.statType.name === "Yellow Card" ||
      s.statType.name === "Sub On" ||
      s.statType.name === "Sub Off"
  );

  // console.log("TIMESTAMP STATS:", stats);
  if (!stats?.length) {
    return <div />;
  }

  const periods = data?.fixture?.periods?.length
    ? [...data?.fixture?.periods]
        .filter(
          (p) =>
            !p.metas.find(
              (m) =>
                m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
            )
        )
        .sort((a, b) => a.order - b.order)
    : [{ duration: 45 }, { duration: 45 }];

  const totalDuration = periods.reduce((accum, curr) => {
    accum += curr.duration || 45;
    return accum;
  }, 0);

  const eventIcons = {
    Goal: <FootballIcon style={{ height: "14px", fill: "var(--blue-main)" }} />,
    "Own Goal": (
      <FootballIcon style={{ height: "14px", fill: "var(--blue-main)" }} />
    ),
    "Sub On": <SwapIcon style={{ height: "14px", fill: "var(--dark-4)" }} />,
    Ellipsis: (
      <EllipsisIcon style={{ height: "14px", fill: "var(--blue-main)" }} />
    ),
    "Red Card": (
      <div
        style={{
          height: "12px",
          width: "9px",
          background: "var(--red-main)",
          borderRadius: "2px",
        }}
      />
    ),
    "Yellow Card": (
      <div
        style={{
          height: "12px",
          width: "9px",
          background: "var(--yellow-main)",
          borderRadius: "2px",
        }}
      />
    ),
  };

  const statsGroupedByTimestamp = stats.reduce((accum, curr) => {
    const org =
      curr.statType.name === "Own Goal"
        ? curr.organization.id === homeTeam.organization.id
          ? awayTeam.organization
          : homeTeam.organization
        : curr.organization;
    // console.log("Org:", org);
    const periodIndex = periods.findIndex(
      (p) => p.id === curr.fixturePeriod?.id
    );

    const timestamp =
      periodIndex < 0
        ? curr.timeStamp || 1
        : Math.min(sumToPeriod(periodIndex, periods), curr.timeStamp || 1);
    const shift =
      periodIndex > -1 && timestamp === sumToPeriod(periodIndex, periods)
        ? true
        : false;
    const groupIndex = accum.findIndex(
      (x) => x.organization.id === org.id && x.timestamp === timestamp
    );
    if (groupIndex < 0) {
      accum.push({
        timestamp,
        shift,
        organization: org,
        stats: [curr],
      });
    } else {
      accum[groupIndex].stats.push(curr);
    }

    return accum;
  }, []);

  // console.log("Grouped stats:", statsGroupedByTimestamp);

  return (
    <Card className="fixture-timeline">
      <div className="fixture-timeline__home">
        <div className="logo">
          {homeTeam?.organization?.profilePhoto && (
            <Avatar media={homeTeam.organization.profilePhoto} />
          )}
        </div>
        <AppLink to={"/app/page/" + homeTeam.organization.id}>
          <div className="desc">{homeTeam?.organization?.name}</div>
        </AppLink>
      </div>
      <div className="fixture-timeline__timeline">
        <div className="fixture-timeline__timeline__indicators"></div>
        <div className="fixture-timeline__timeline__timestamps">
          {periods.map((p, i) => (
            <div
              className="fixture-timeline__timeline__timestamps__period"
              key={"timeline-period-" + i}
              style={{
                ...(!i
                  ? {
                      borderBottomLeftRadius: "7px",
                      borderTopLeftRadius: "7px",
                    }
                  : {}),
                ...(i === periods.length - 1
                  ? {
                      borderBottomRightRadius: "7px",
                      borderTopRightRadius: "7px",
                    }
                  : {}),
                width: ((p.duration || 45) / totalDuration) * 100 + "%",
              }}
            >
              <div className="period-end-marker ">
                {i === periods.length - 1 ? "FT" : sumToPeriod(i, periods)}
              </div>
            </div>
          ))}

          {statsGroupedByTimestamp.map((g) => (
            <div
              key={"timeline-timestamped-" + g.timestamp}
              className="stamp"
              style={{
                left: ((g.timestamp || 1) / totalDuration) * 100 + "%",
                ...(g.shift ? { transform: "translateX(-12px)" } : {}),
              }}
            >
              {g.timestamp || 1}

              {g.stats.length > 1 ? (
                <div
                  className="stamp-icon"
                  style={
                    g.organization?.id === homeTeam.organization?.id
                      ? { bottom: "16px" }
                      : { top: "16px" }
                  }
                >
                  <Tooltip
                    placement={
                      g.organization?.id === homeTeam.organization?.id
                        ? "top"
                        : "bottom"
                    }
                    component={
                      <Card className="tooltip-stats">
                        {g.stats.map((s) => (
                          <div
                            key={"tooltip-stats-" + s.id}
                            className="tooltip-stats__item"
                          >
                            <div className="tooltip-stats__item__meta">
                              <div className="tooltip-stats__item__meta__timestamp">
                                {s.timeStamp}'
                              </div>

                              <div className="tooltip-stats__item__meta__stat-type">
                                {s.statType.name}
                              </div>
                            </div>

                            <div className="tooltip-stats__item__player">
                              {s.profile.name}
                            </div>
                          </div>
                        ))}
                      </Card>
                    }
                    style={{
                      background: "var(--light-1)",
                      // padding: 0,
                      color: "var(--dark-1)",
                    }}
                  >
                    {eventIcons["Ellipsis"]}
                  </Tooltip>
                </div>
              ) : (
                <div
                  className="stamp-icon"
                  style={
                    g.organization?.id === homeTeam.organization?.id
                      ? { bottom: "16px" }
                      : { top: "16px" }
                  }
                >
                  <Tooltip
                    placement={
                      g.organization?.id === homeTeam.organization?.id
                        ? "top"
                        : "bottom"
                    }
                    component={
                      <Card className="tooltip-stats">
                        <div className="tooltip-stats__item">
                          <div className="tooltip-stats__item__meta">
                            <div className="tooltip-stats__item__meta__timestamp">
                              {g.stats[0]?.timeStamp}'
                            </div>

                            <div className="tooltip-stats__item__meta__stat-type">
                              {g.stats[0]?.statType?.name}
                            </div>
                          </div>

                          <div className="tooltip-stats__item__player">
                            {g.stats[0]?.profile?.name}
                          </div>
                        </div>
                      </Card>
                    }
                    style={{
                      background: "var(--light-1)",
                      // padding: 0,
                      color: "var(--dark-1)",
                    }}
                  >
                    {eventIcons[g.stats[0]?.statType?.name]}
                  </Tooltip>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="fixture-timeline__timeline__indicators"></div>
      </div>
      <div className="fixture-timeline__away">
        <div className="logo">
          {awayTeam?.organization?.profilePhoto && (
            <Avatar media={awayTeam.organization.profilePhoto} />
          )}
        </div>
        <AppLink to={"/app/page/" + awayTeam.organization.id}>
          <div className="desc">{awayTeam?.organization?.name}</div>
        </AppLink>
      </div>
    </Card>
  );
}

export default FixtureTimeline;
