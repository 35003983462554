import "./EventsIndex.css";

function EventsIndex() {
  return (
    <div className="scoring_events-index">
      <div className="scoring-app-page-title">Your Events</div>

      <div className="scoring_events-index_list-container">
        <div className="scoring_events-index_list">List here</div>
        <div className="scoring_events-index_list-filters">Filters</div>
      </div>
    </div>
  );
}

export default EventsIndex;
