export default function showStat(stat, stats) {
  const hiddenList = [
    "Assist",
    "Sub On",
    "Penalty Shootout Goal",
    "Penalty Shootout Miss",
  ];
  const parentStats = stat.relationshipsTo || [];
  const childStats =
    stats.filter((s) =>
      s.relationshipsTo?.find((x) => x.from.id === stat.id)
    ) || [];

  if (!parentStats?.length && !childStats?.length) {
    return true;
  } else if (hiddenList.includes(stat.statType.name)) {
    return false;
  } else if (stat.statType.name === "Shot On Target") {
    if (childStats.length) {
      return false;
    } else {
      return true;
    }
  } else if (stat.statType.name === "Penalty Scored") {
    if (parentStats.length) {
      return false;
    } else {
      return true;
    }
  } else if (stat.statType.name === "Pass Attempted") {
    if (childStats.length) {
      return false;
    } else {
      return true;
    }
  } else if (stat.statType.name === "Key Pass") {
    if (parentStats.find((x) => x?.from?.statType?.name === "Goal")) {
      return false;
    } else {
      return true;
    }
  } else if (stat.statType.name === "Foul") {
    if (childStats.length) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
