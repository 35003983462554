import "./Input.css";
import { useState } from "react";

function Input({
  type = "text",
  iconLeft,
  iconRight,
  label,
  assistiveIcon,
  assistiveText,
  disabled,
  placeholder,
  onChange = () => {},
  useExternalValue = false,
  value,
  autofocus = false,
}) {
  const [inputState, setInputState] = useState("");
  const [inputValue, setInputValue] = useState("");

  return (
    <div className="app-input">
      {label && <div className="label">Label</div>}

      <div
        className={
          "input-wrapper " + (disabled ? "disabled " : "") + inputState
        }
      >
        {iconLeft && <div className="input-icon-left">{iconLeft}</div>}

        <input
          type={type || "text"}
          onFocus={() => {
            setInputState("focused");
          }}
          onBlur={() => {
            setInputState("");
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e);
          }}
          value={useExternalValue ? value : inputValue}
          placeholder={placeholder}
          style={iconLeft ? {} : { paddingLeft: 16 }}
          {...(autofocus ? { autoFocus: true } : {})}
        />
        {iconRight && <div className="input-icon-right">{iconRight}</div>}
      </div>

      {assistiveText && (
        <div className="assistive">
          <div className="assistive-icon">Icon</div>
          <div className="assistive-text">Assistive Text</div>
        </div>
      )}
    </div>
  );
}

export default Input;
