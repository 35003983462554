import Card from "../../Card";
import Button from "../../Button";
import { CLAIM_PROFILE } from "../../../api/profile";
import { useMutation } from "@apollo/client";
import "./ClaimProfile.css";
import Loader from "../../Loader";
import captureException from "../../../utils/captureException";

export default function ClaimProfile({ profile }) {
  const [claim, { loading }] = useMutation(CLAIM_PROFILE, {
    update: (cache, { data: { claimProfile } }) => {
      if (claimProfile.id) {
        cache.modify({
          id: cache.identify(profile),
          fields: {
            canBeClaimed() {
              return { claimId: claimProfile.id, canBeClaimed: false };
            },
          },
        });
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ClaimProfile",
          mutation: "CLAIM_PROFILE",
        },
      });
      console.log(error);
    },
  });

  if (profile.canBeClaimed?.claimId) {
    return (
      <Card className="claim-profile-card">
        <div className="desc primary">Claim under review</div>
        <div className="desc">
          Make sure your profile details are correct to help us validate your
          claim!
        </div>
      </Card>
    );
  }

  if (!profile.canBeClaimed?.canBeClaimed) {
    return <div />;
  }

  return (
    <Card className="claim-profile-card">
      <div className="desc primary">Is this your profile?</div>

      <div className="desc secondary">
        Click the button to claim this profile.
      </div>
      <Button
        className="primary medium green"
        onClick={() => {
          if (loading) return;
          claim({
            variables: {
              profileId: profile.id,
            },
          });
        }}
      >
        {loading ? <Loader /> : "Claim Profile"}
      </Button>
    </Card>
  );
}
