import { useRef, useState } from "react";
import "./SelectWithoutSearch.css";
import Dropdown from "../Dropdown";
import Input from "../Input";
import Spinner from "../Spinner";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import { useQuery } from "@apollo/client";
import captureException from "../../utils/captureException";

function FilterInputMenu({
  loading,
  wrapper,
  items,
  onItemSelect,
  setShowMenu,
  selected,
  setSelected,
  clearOnItemSelect,
}) {
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="filter-input-menu"
      style={{ width: wrapper?.current?.offsetWidth || "100%" }}
    >
      <div className="filter-input-menu-list">
        {items.map((item, i) => (
          <div
            key={"select-without-search-item-" + item.name + i}
            className="filter-input-menu-list-item"
            onClick={() => {
              onItemSelect(item);
              if (!clearOnItemSelect) {
                setSelected(item);
              }
              setShowMenu(false);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

function InputWithQuery({
  query,
  variables,
  items,
  placeholder,
  wrapperRef,
  onItemSelect,
  clearOnItemSelect,
  selected,
  setSelected,
}) {
  const { loading, error, data } = useQuery(query, {
    ...(variables ? { variables } : {}),
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "SelectWithoutSearch.InputWithQuery",
        query: "variable",
      },
    });
    // console.log("FILTER INPUT QUERY ERROR:", error);
  }

  const list = (data && items(data)) || [];
  // console.log("INPUT LIST : " + placeholder + ":", list);

  return (
    <FilterDropdown
      wrapperRef={wrapperRef}
      placeholder={placeholder}
      items={list}
      loading={loading}
      onItemSelect={onItemSelect}
      clearOnItemSelect={clearOnItemSelect}
      selected={selected}
      setSelected={setSelected}
    />
  );
}

function InputWithoutQuery({
  items,
  placeholder,
  wrapperRef,
  onItemSelect,
  clearOnItemSelect,
  selected,
  setSelected,
}) {
  return (
    <FilterDropdown
      wrapperRef={wrapperRef}
      placeholder={placeholder}
      items={items}
      onItemSelect={onItemSelect}
      clearOnItemSelect={clearOnItemSelect}
      selected={selected}
      setSelected={setSelected}
    />
  );
}

function FilterDropdown({
  wrapperRef,
  placeholder,
  items,
  onItemSelect,
  clearOnItemSelect,
  selected,
  setSelected,
}) {
  return (
    <div
      ref={wrapperRef}
      className="filter-input-dropdown disable-without-background"
    >
      <Dropdown
        target={
          <Input
            placeholder={placeholder}
            iconRight={
              <ChevronDownIcon
                style={{
                  height: 17,
                  marginTop: 2,
                  stroke: "var(--blue-lighter)",
                }}
              />
            }
            onChange={(e) => {}}
            useExternalValue={true}
            value={selected.name}
            disabled={true}
          />
        }
        menu={({ setShowMenu }) => (
          <FilterInputMenu
            items={items}
            wrapper={wrapperRef}
            onItemSelect={onItemSelect}
            setShowMenu={setShowMenu}
            selected={selected}
            setSelected={setSelected}
            clearOnItemSelect={clearOnItemSelect}
          />
        )}
      />
    </div>
  );
}

function SelectWithoutSearch({
  items = [],
  placeholder,
  query,
  variables,
  onItemSelect,
  defaultValue,
  clearOnItemSelect,
}) {
  const [selected, setSelected] = useState(defaultValue || "");
  const wrapperRef = useRef(null);
  const FilterComponent = query ? InputWithQuery : InputWithoutQuery;

  return (
    <FilterComponent
      query={query}
      variables={variables}
      items={items}
      placeholder={placeholder}
      wrapperRef={wrapperRef}
      onItemSelect={onItemSelect}
      selected={selected}
      setSelected={setSelected}
      clearOnItemSelect={clearOnItemSelect}
    />
  );
}

export default SelectWithoutSearch;
