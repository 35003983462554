// import { createContext, useState } from "react";

// const [selectedPeriod, setSelectedPeriod] = useState(undefined);

// const FootballStatsContext = createContext({
//   selectedPeriod,
//   setSelectedPeriod,
// });

// const FootballStatsContextProvider = ({ children }) => {
//   return (
//     <FootballStatsContext.Provider
//       value={{ selectedPeriod, setSelectedPeriod }}
//     >
//       {children}
//     </FootballStatsContext.Provider>
//   );
// };

// export { FootballStatsContext, FootballStatsContextProvider };

import React, { createContext, useContext, useReducer } from "react";

const reducer = (state, action = {}) => {
  return {
    ...state,
    ...action,
  };
};

export const FootballStatsContext = createContext();

export const FootballStatsContextProvider = ({ children, initialState }) => (
  <FootballStatsContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </FootballStatsContext.Provider>
);

export const useFootballScoringState = () => useContext(FootballStatsContext);
