import Placeholder from "../../../components/Placeholder";
import "./OrgInteractionStats.css";
import { useQuery } from "@apollo/client";
import { INTERACTION_STATS, FOLLOWERS } from "../../../api/profile";
import { ORGANIZATION_MEMBERS } from "../../../api/organization";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../../utils/appState";
import PartyList from "../../PartyList";
import captureException from "../../../utils/captureException";

export default function OrgInteractionStats({ id, organization }) {
  const [, setAppState] = useAppState();
  const history = useHistory();

  const { loading, error, data } = useQuery(INTERACTION_STATS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrgInteractionStats",
        query: "INTERACTION_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="organization-overview__interaction">
        <div className="organization-overview__interaction__item">
          <div className="organization-overview__interaction__item__value">
            <Placeholder
              style={{ height: "16px", width: "16px" }}
              pulse={true}
            />
          </div>

          <div className="organization-overview__interaction__item__label">
            <Placeholder
              style={{
                height: "10px",
                width: "40px",
                background: "var(--light-2)",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-overview__interaction__item">
          <div className="organization-overview__interaction__item__value">
            <Placeholder
              style={{ height: "16px", width: "16px" }}
              pulse={true}
            />
          </div>

          <div className="organization-overview__interaction__item__label">
            <Placeholder
              style={{
                height: "10px",
                width: "40px",
                background: "var(--light-2)",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-overview__interaction__item">
          <div className="organization-overview__interaction__item__value">
            <Placeholder
              style={{ height: "16px", width: "16px" }}
              pulse={true}
            />
          </div>

          <div className="organization-overview__interaction__item__label">
            <Placeholder
              style={{
                height: "10px",
                width: "40px",
                background: "var(--light-2)",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>
      </div>
    );
  }

  const interactionStats = data.interactionStats;
  return (
    <div className="organization-overview__interaction">
      <div
        className="organization-overview__interaction__item"
        onClick={() => {
          history.replace("/app/page/" + id + "?t=posts");
        }}
      >
        <div className="organization-overview__interaction__item__value">
          {interactionStats.posts}
        </div>

        <div className="organization-overview__interaction__item__label">
          Posts
        </div>
      </div>

      <div
        className="organization-overview__interaction__item"
        onClick={() => {
          setAppState({
            modal: (
              <PartyList
                title={`${organization.name}'s Followers`}
                query={FOLLOWERS}
                variables={{ id }}
                getData={((d) => d?.followers) || []}
                noData={() => (
                  <div className="no-data">
                    <div>{organization.name} has no followers yet.</div>
                  </div>
                )}
                secondaryText={(item) =>
                  item.organization?.handle || item.profile?.handle
                    ? "@" + (item.organization?.handle || item.profile?.handle)
                    : ""
                }
                totalItems={interactionStats.followers}
              />
            ),
          });
        }}
      >
        <div className="organization-overview__interaction__item__value">
          {interactionStats.followers}
        </div>

        <div className="organization-overview__interaction__item__label">
          Fans
        </div>
      </div>

      <div
        className="organization-overview__interaction__item"
        onClick={() => {
          setAppState({
            modal: (
              <PartyList
                title={`${organization.name}'s Members`}
                query={ORGANIZATION_MEMBERS}
                variables={{ id, where: { toDate: null, approved: true } }}
                fetchPolicy={"network-only"}
                getData={((d) => d?.organization?.members) || []}
                noData={() => (
                  <div className="no-data">
                    <div>{organization.name} has no members yet.</div>
                  </div>
                )}
                secondaryText={(item) =>
                  item.profile?.handle ? "@" + item.profile.handle : ""
                }
                totalItems={interactionStats.memberships}
              />
            ),
          });
        }}
      >
        <div className="organization-overview__interaction__item__value">
          {interactionStats.memberships}
        </div>

        <div className="organization-overview__interaction__item__label">
          Members
        </div>
      </div>
    </div>
  );
}
