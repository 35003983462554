// V1
import { useState, Fragment } from "react";
import "./Profile.css";
import Button from "../../components/Button";
import Card from "../../components/Card";
import PhotoBrowser from "../../components/PhotoBrowser";
import PartyList from "../../components/PartyList";
import Image from "../../components/Image";
import Loader from "../../components/Loader";
import FollowButton from "../../components/FollowButton";
import ClickOutside from "../../components/ClickOutside";
import Placeholder from "../../components/Placeholder";
import Avatar from "../../components/Avatar";
import {
  ProfileOverview,
  ProfilePosts,
  ProfilePhotos,
  ProfileVideos,
  ProfileStats,
  ProfileEvents,
  ClaimProfile,
} from "../../components/Profile";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  PROFILE,
  INTERACTION_STATS,
  PROFILE_BIO,
  PAGES,
  UPDATE_PROFILE,
  FOLLOWERS,
  FOLLOWING,
} from "../../api/profile";
import { DELETE_MEDIA } from "../../api/media";
import { CAN_MANAGE_PROFILE, UPDATE_USER_PROFILE } from "../../api/user";
import moment from "moment";
import Spinner from "../../components/Spinner";
import ToggleState from "../../components/ToggleState";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as PlayerIcon } from "../../icons/player.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import { ReactComponent as ControllerIcon } from "../../icons/controller.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as BuildingIcon } from "../../icons/building.svg";
import { useAppState } from "../../utils/appState";
import useRouterQuery from "../../utils/useRouterQuery";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import LocationSearch from "../../components/LocationSearch";
import UploaderWithCropper from "../../components/UploaderWithCropper";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

const profileTabs = [
  { label: "Overview", component: ProfileOverview, key: "overview" },
  { label: "Events", component: ProfileEvents, key: "events" },
  { label: "Tagged Posts", component: ProfilePosts, key: "posts" },
  { label: "Photos", component: ProfilePhotos, key: "photos" },
  { label: "Videos", component: ProfileVideos, key: "videos" },
  // { label: "Timeline", component: ProfileTimeline, key: "timeline" },
  { label: "Statistics", component: ProfileStats, key: "statistics" },
];

function UpdateProfileInterests({ setIndex, id, defaultInterests }) {
  const [interests, setInterests] = useState(defaultInterests || []);

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };

  const sports = [
    { name: "Football" },
    { name: "Basketball" },
    { name: "Cricket" },
    { name: "Badminton" },
    { name: "Tennis" },
    { name: "E-Gaming" },
  ];

  const [updateProfile, { loading }] = useMutation(
    UPDATE_PROFILE("interests{id name}"),
    {
      update: (cache, { data: { updateProfile } }) => {
        const q = cache.readQuery({ query: PROFILE_BIO, variables: { id } });

        cache.writeQuery({
          query: PROFILE_BIO,
          variables: { id },
          data: {
            profile: { ...q.profile, interests: updateProfile.interests },
          },
        });

        setIndex(0);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Profile.UpdateProfileInterests",
            mutation: "UPDATE_PROFILE",
          },
        });
        console.log(error);
      },
    }
  );

  const toConnect = interests
    .map((i) => i.name)
    .filter((i) => !defaultInterests.map((x) => x.name).includes(i));

  const toDisconnect = defaultInterests
    .map((i) => i.name)
    .filter((i) => !interests.map((x) => x.name).includes(i));

  return (
    <div className="info-group vertical">
      <div className="key">
        Interests
        <div className="edit-bio-form">
          <button
            type={"submit "}
            className={
              "submit " +
              (!toConnect.length && !toDisconnect.length ? "disabled" : "")
            }
            onClick={() => {
              if (loading) return;
              // console.log("To connect:", toConnect);
              // console.log("To disconnect:", toDisconnect);
              updateProfile({
                variables: {
                  where: { id },
                  data: {
                    interests: {
                      connect: toConnect.map((i) => ({ name: i })),
                      disconnect: toDisconnect.map((i) => ({ name: i })),
                    },
                  },
                },
              });
            }}
          >
            <CheckIcon className="icon" />
          </button>
          <button
            onClick={() => {
              setIndex(0);
            }}
            className="cancel"
          >
            <TimesIcon className="icon" />
          </button>
        </div>
      </div>
      <div className="value grid">
        {sports.map((sport, i) => (
          <div
            key={"profile-sport-" + sport.id + i}
            className={
              "interest hover-pointer " +
              (interests.find((i) => i.name === sport.name) ? "" : "inactive")
            }
            onClick={() => {
              if (interests.find((i) => i.name === sport.name)) {
                setInterests((prev) =>
                  prev.filter((i) => i.name !== sport.name)
                );
              } else {
                setInterests((prev) => [...prev, sport]);
              }
            }}
          >
            <div className="icon">
              {sportsIcons[sport.name] || (
                <EllipsisIcon className="sports-icon-svg" />
              )}
            </div>
            <div className="desc">{sport.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function UpdateProfileLocations({ setIndex, id, defaultLocations }) {
  const [updateProfile, { loading }] = useMutation(
    UPDATE_PROFILE("cities{id value lat lng}"),
    {
      update: (cache, { data: { updateProfile } }) => {
        const q = cache.readQuery({ query: PROFILE_BIO, variables: { id } });

        cache.writeQuery({
          query: PROFILE_BIO,
          variables: { id },
          data: {
            profile: { ...q.profile, cities: updateProfile.cities },
          },
        });

        // setIndex(0);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Profile.UpdateProfileLocations",
            mutation: "UPDATE_PROFILE",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="info-group vertical">
      <div className="key">
        Locations
        <div className="edit-bio-form">
          <button
            onClick={() => {
              setIndex(0);
            }}
            className="cancel"
          >
            <TimesIcon className="icon" />
          </button>
        </div>
      </div>
      <LocationSearch
        defaultSearchString={""}
        onLocationSelect={({ address, setSearchString }) => {
          if (!address) {
            return;
          }

          const city = address?.split(",")[0].trim();
          const state = address?.split(",")[1].trim();
          const country = address?.split(",")[2].trim();

          geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
              updateProfile({
                variables: {
                  where: { id },
                  data: {
                    cities: {
                      connect: [
                        {
                          city,
                          state,
                          country,
                          lat: latLng.lat,
                          lng: latLng.lng,
                        },
                      ],
                    },
                  },
                },
              });
              setSearchString("");
            })
            .catch((error) => console.error("Error", error));
        }}
        placeholder={"Search city"}
      />
      <div className="value grid">
        {defaultLocations.map((loc) => (
          <div
            key={"profile-sport-" + loc.id}
            className={"interest hover-pointer "}
            onClick={() => {
              if (loading) return;
              updateProfile({
                variables: {
                  where: { id },
                  data: {
                    cities: {
                      disconnect: [{ id: loc.id }],
                    },
                  },
                },
              });
            }}
          >
            <div className="icon">
              <TimesIcon
                className="sports-icon-svg"
                style={{ stroke: "white", fill: "none" }}
              />
            </div>
            <div className="desc">{loc.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function SelectProfilePhotoList({ profile, setUpload }) {
  const [, setAppState] = useAppState();
  const [selectedPhoto, setSelectedPhoto] = useState(profile.profilePhoto);

  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id: profile.id },
  });

  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      // refetch();
      if (!((data?.profile?.profilePhotos?.length || 0) - 1 > 0)) {
        // console.log("NO PROFILE PHOTOS LEFT");
        setUpload(true);
      }

      cache.modify({
        id: cache.identify(profile),
        fields: {
          profilePhotos(existingMediaRefs, { readField }) {
            return existingMediaRefs.filter(
              (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
            );
          },
          ...(deleteMedia.id === profile.profilePhoto?.id
            ? { profilePhoto: () => null }
            : {}),
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Profile.SelectProfilePhotoList",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [updateProfile, { loading: updating }] = useMutation(
    UPDATE_PROFILE(`profilePhoto{id filename}`, {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Profile.SelectProfilePhotoList",
            mutation: "UPDATE_PROFILE",
          },
        });
        console.log(error);
      },
    })
  );

  const updateProfilePhoto = () => {
    updateProfile({
      variables: {
        where: { id: profile.id },
        data: {
          profilePhoto: selectedPhoto?.id
            ? { connect: { id: selectedPhoto.id } }
            : { disconnect: true },
        },
      },
      update: (cache, { data: { updateProfile } }) => {
        const q = cache.readQuery({
          query: PROFILE,
          variables: { id: profile.id },
        });

        cache.writeQuery({
          query: PROFILE,
          variables: { id: profile.id },
          data: {
            profile: {
              ...q.profile,
              profilePhoto: updateProfile.profilePhoto,
            },
          },
        });

        setAppState({ modal: false });
      },
    });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.SelectProfilePhotoList",
        query: "PROFILE",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const photos = data.profile.profilePhotos;

  return (
    <Fragment>
      <div className="select-profile-photo__images">
        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 0)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 1)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 2)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 3)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-profile-photo-list-options">
        <Button
          className="primary medium blue"
          onClick={() => {
            if (updating) return;
            updateProfilePhoto(selectedPhoto);
          }}
        >
          {updating ? <Loader /> : "Save"}
        </Button>
        <Button
          className="tertiary"
          style={{ marginTop: "16px" }}
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
}

function SelectProfilePhoto({ profile }) {
  const [upload, setUpload] = useState(
    profile.profilePhotos?.length ? false : true
  );
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id: profile.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.SelectProfilePhoto",
        query: "PROFILE",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="select-profile-photo-wrapper">
        <Card>
          <Spinner />
        </Card>
      </div>
    );
  }

  if (upload) {
    return (
      <UploaderWithCropper
        accept=".jpg,.png,.jpeg"
        label="Upload profile photo"
        multiple={false}
        sourceId={profile.id}
        sourceType={"profile"}
        subtype={"PROFILE PHOTO"}
        onCreateMedia={(cache, createMedia) => {
          const q = cache.readQuery({
            query: PROFILE,
            variables: { id: profile.id },
          });

          cache.writeQuery({
            query: PROFILE,
            variables: { id: profile.id },
            data: {
              profile: {
                ...q.profile,
                profilePhoto: {
                  ...createMedia,
                },
                profilePhotos: [...q.profile.profilePhotos, { ...createMedia }],
              },
            },
          });

          setUpload(false);

          setAppState({ modal: false });
        }}
      />
    );
  } else {
    return (
      <ClickOutside
        onClickOutside={() => {
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div className="select-profile-photo-wrapper" ref={wrapperRef}>
            <Card className="select-profile-photo">
              <div className="select-profile-photo__header">
                <div className="select-profile-photo__header__title">
                  Select profile image
                </div>
                <div
                  className="select-profile-photo__header__link hover-pointer"
                  onClick={() => {
                    setUpload(true);
                  }}
                >
                  Upload New
                </div>
              </div>

              <SelectProfilePhotoList profile={profile} setUpload={setUpload} />
            </Card>
          </div>
        )}
      </ClickOutside>
    );
  }
}

function SelectCoverPhotoList({ profile, setUpload }) {
  // console.log("OPened cover photo list");
  const [, setAppState] = useAppState();
  const [selectedPhoto, setSelectedPhoto] = useState(profile.coverPhoto);
  // const photos = profile.coverPhotos;

  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id: profile.id },
  });

  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      // refetch();
      if (!((data?.profile?.coverPhotos?.length || 0) - 1 > 0)) {
        // console.log("NO PROFILE PHOTOS LEFT");
        setUpload(true);
      }

      cache.modify({
        id: cache.identify(profile),
        fields: {
          coverPhotos(existingMediaRefs, { readField }) {
            return existingMediaRefs.filter(
              (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
            );
          },
          ...(deleteMedia.id === profile.coverPhoto?.id
            ? { coverPhoto: () => null }
            : {}),
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Profile.SelectCoverPhotoList",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [updateProfile, { loading: updating }] = useMutation(
    UPDATE_PROFILE(`coverPhoto{id filename}`, {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Profile.SelectCoverPhotoList",
            mutation: "UPDATE_PROFILE",
          },
        });
        console.log(error);
      },
    })
  );

  const updateProfilePhoto = () => {
    updateProfile({
      variables: {
        where: { id: profile.id },
        data: {
          coverPhoto: selectedPhoto?.id
            ? { connect: { id: selectedPhoto.id } }
            : { disconnect: true },
        },
      },
      update: (cache, { data: { updateProfile } }) => {
        const q = cache.readQuery({
          query: PROFILE,
          variables: { id: profile.id },
        });

        cache.writeQuery({
          query: PROFILE,
          variables: { id: profile.id },
          data: {
            profile: {
              ...q.profile,
              coverPhoto: updateProfile.coverPhoto,
            },
          },
        });

        setAppState({ modal: false });
      },
    });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.SelectCoverPhotoList",
        query: "PROFILE",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const photos = data.profile.coverPhotos;

  return (
    <Fragment>
      <div className="select-profile-photo__images">
        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 0)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 1)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 2)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 3)
            .map((m) => (
              <div className="select-profile-photo__images__item" key={m.id}>
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-profile-photo-list-options">
        <Button
          className="primary medium blue"
          onClick={() => {
            if (updating) return;
            updateProfilePhoto(selectedPhoto);
          }}
        >
          {updating ? <Loader /> : "Save"}
        </Button>
        <Button
          className="tertiary"
          style={{ marginTop: "16px" }}
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
}

function SelectCoverPhoto({ profile }) {
  const [upload, setUpload] = useState(
    profile.coverPhotos?.length ? false : true
  );
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id: profile.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.SelectCoverPhoto",
        query: "PROFILE",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="select-profile-photo-wrapper">
        <Card>
          <Spinner />
        </Card>
      </div>
    );
  }

  if (upload) {
    return (
      <UploaderWithCropper
        accept=".jpg,.png,.jpeg"
        label="Upload cover photo"
        multiple={false}
        sourceId={profile.id}
        sourceType={"profile"}
        subtype={"COVER PHOTO"}
        cropperWidth={240}
        cropperHeight={40}
        onCreateMedia={(cache, createMedia) => {
          const q = cache.readQuery({
            query: PROFILE,
            variables: { id: profile.id },
          });

          cache.writeQuery({
            query: PROFILE,
            variables: { id: profile.id },
            data: {
              profile: {
                ...q.profile,
                coverPhoto: {
                  ...createMedia,
                },
                coverPhotos: [...q.profile.coverPhotos, { ...createMedia }],
              },
            },
          });

          setUpload(false);

          setAppState({ modal: false });
        }}
      />
    );
  } else {
    return (
      <ClickOutside
        onClickOutside={() => {
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div className="select-profile-photo-wrapper" ref={wrapperRef}>
            <Card className="select-profile-photo">
              <div className="select-profile-photo__header">
                <div className="select-profile-photo__header__title">
                  Select cover photo
                </div>
                <div
                  className="select-profile-photo__header__link hover-pointer"
                  onClick={() => {
                    setUpload(true);
                  }}
                >
                  Upload New
                </div>
              </div>

              <SelectCoverPhotoList profile={profile} setUpload={setUpload} />
            </Card>
          </div>
        )}
      </ClickOutside>
    );
  }
}

function UpdateProfileBio({
  setIndex,
  label,
  selectionSet,
  id,
  vars,
  defaultValue = "",
  defaultCity = "",
  defaultState = "",
  defaultLatLng = "",
  defaultCountry = "",
  inputType = "text",
}) {
  const [updateOrganization, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Profile.UpdateProfileBio",
          mutation: "UPDATE_USER_PROFILE",
        },
      });
      console.log(error);
    },
  });
  const [value, setValue] = useState(defaultValue);
  const [city, setCity] = useState(defaultCity);
  const [state, setState] = useState(defaultState);
  const [country, setCountry] = useState(defaultCountry);
  const [latLng, setLatLng] = useState(defaultLatLng);

  return (
    <form
      className="edit-bio-form"
      onSubmit={(e) => {
        e.preventDefault();

        if (loading) return;
        const formData = vars(value, { city, state, country, latLng });

        if (formData) {
          updateOrganization({
            variables: {
              where: { id },
              data: formData,
            },
            update: () => {
              setIndex(0);
            },
          });
        }
      }}
    >
      <div className="edit-bio-form-label">Edit {label}</div>
      <div className="edit-bio-form-body">
        {inputType === "location" ? (
          <LocationSearch
            defaultSearchString={[city, state, country]
              .filter((x) => x)
              .join(", ")}
            onLocationSelect={({ address, setSearchString }) => {
              const city = address?.split(",")[0].trim();
              const state = address?.split(",")[1].trim();
              const country = address?.split(",")[2].trim();
              setSearchString(city);
              setCity(city);
              setState(state);
              setCountry(country);
              geocodeByAddress(address)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  setLatLng(latLng);
                })
                .catch((error) => console.error("Error", error));
            }}
            placeholder={"Search city"}
          />
        ) : (
          <input
            type={inputType}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        )}

        {loading ? (
          <div
            style={{
              width: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader theme={"blue small"} />
          </div>
        ) : (
          <Fragment>
            <button type="submit" className="submit">
              <CheckIcon className="icon" />
            </button>
            <button
              onClick={() => {
                setIndex(0);
              }}
              className="cancel"
            >
              <TimesIcon className="icon" />
            </button>
          </Fragment>
        )}
      </div>
    </form>
  );
}

function ProfileSummaryCard({ id, profile, profilePhoto, canManageProfile }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(INTERACTION_STATS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.ProfileSummaryCard",
        query: "INTERACTION_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="profile-summary-card">
        <div className="profile-summary-card__image-wrapper">
          <div className="image"></div>
        </div>

        <div className="stats">
          <div className="stat-item">
            <div className="value">
              <div className="placeholder" />
            </div>
            <div className="key">
              <Placeholder style={{ width: "30px" }} pulse={true} />
            </div>
          </div>

          <div className="stat-item">
            <div className="value">
              <div className="placeholder" />
            </div>
            <div className="key">
              <Placeholder style={{ width: "30px" }} pulse={true} />
            </div>
          </div>

          <div className="stat-item">
            <div className="value">
              <div className="placeholder" />
            </div>
            <div className="key">
              <Placeholder style={{ width: "30px" }} pulse={true} />
            </div>
          </div>
        </div>
        <Placeholder
          style={{
            background: "var(--blue-subtle)",
            height: "32px",
            width: "100%",
            borderRadius: "8px",
          }}
          pulse={true}
        />
      </Card>
    );
  }

  const interactionStats = data.interactionStats;
  // console.log(interactionStats);

  return (
    <Card className="profile-summary-card">
      <div className="profile-summary-card__image-wrapper">
        <div
          className="image"
          onClick={() => {
            setAppState({
              modal: (
                <PhotoBrowser
                  query={PROFILE}
                  vars={{ id }}
                  getPhotos={(data) => data.profile?.profilePhotos || []}
                  totalPhotos={(data) =>
                    data.profile?.profilePhotos?.length || 0
                  }
                  media={profilePhoto}
                />
              ),
            });
          }}
        >
          {profilePhoto ? (
            <Avatar media={profilePhoto} />
          ) : (
            <PlayerIcon style={{ fill: "var(--light-1)", height: "64px" }} />
          )}
          {profilePhoto && <div className="overlay" />}
        </div>
        {canManageProfile && (
          <div
            className="edit-profile-photo-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <SelectProfilePhoto
                    profile={profile}
                    canManageProfile={canManageProfile}
                  />
                ),
              });
            }}
          >
            <PencilIcon className="icon" />
          </div>
        )}
      </div>

      <div className="profile-summary-card__handle">@{profile.handle}</div>

      <div className="stats">
        <div
          className="stat-item"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`${profile.name}'s Pages`}
                  query={PAGES}
                  variables={{ id }}
                  fetchPolicy={"network-only"}
                  getData={
                    ((d) =>
                      d?.pages?.map((p) => ({
                        organization: p.organization,
                      }))) || []
                  }
                  noData={() => (
                    <div className="no-data">
                      <div>{profile.name} has no pages yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.organization?.handle
                      ? "@" + item.organization?.handle
                      : ""
                  }
                  totalItems={interactionStats.memberships}
                />
              ),
            });
          }}
        >
          <div className="value">{interactionStats.memberships}</div>
          <div className="key">Pages</div>
        </div>

        <div
          className="stat-item"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`${profile.name}'s Fans`}
                  query={FOLLOWERS}
                  variables={{ id }}
                  getData={((d) => d?.followers) || []}
                  noData={() => (
                    <div className="no-data">
                      <div>{profile.name} has no followers yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.organization?.handle || item.profile?.handle
                      ? "@" +
                        (item.organization?.handle || item.profile?.handle)
                      : ""
                  }
                  totalItems={interactionStats.followers}
                />
              ),
            });
          }}
        >
          <div className="value">{interactionStats.followers}</div>
          <div className="key">Fans</div>
        </div>

        <div
          className="stat-item"
          onClick={() => {
            setAppState({
              modal: (
                <PartyList
                  title={`Supported by ${profile.name}`}
                  query={FOLLOWING}
                  variables={{ id }}
                  getData={((d) => d?.following) || []}
                  noData={() => (
                    <div className="no-data">
                      <div>{profile.name} is not following anyone yet.</div>
                    </div>
                  )}
                  secondaryText={(item) =>
                    item.organization?.handle || item.profile?.handle
                      ? "@" +
                        (item.organization?.handle || item.profile?.handle)
                      : ""
                  }
                  totalItems={interactionStats.following}
                />
              ),
            });
          }}
        >
          <div className="value">{interactionStats.following}</div>
          <div className="key">Supporting</div>
        </div>
      </div>
      <FollowButton
        id={id}
        type={"profile"}
        onUnfollow={(cache, follow) => {
          if (follow.confirmed) {
            const q = cache.readQuery({
              query: INTERACTION_STATS,
              variables: { id },
            });
            cache.writeQuery({
              query: INTERACTION_STATS,
              variables: { id },
              data: {
                interactionStats: {
                  ...q.interactionStats,
                  followers: q.interactionStats.followers - 1,
                },
              },
            });
          }
        }}
      />
    </Card>
  );
}

function ProfileBioCard({ id, canManageProfile }) {
  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };

  const { loading, error, data } = useQuery(PROFILE_BIO, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.ProfileBioCard",
        query: "PROFILE_BIO",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="user-bio-card">
        <div className="info-group row">
          <Placeholder style={{ width: "100%" }} pulse={true} />
        </div>

        <div className="info-group row">
          <Placeholder style={{ width: "80%" }} pulse={true} />
        </div>

        <div className="info-group row">
          <Placeholder style={{ width: "100%" }} pulse={true} />
        </div>
      </Card>
    );
  }

  const profile = data.profile;

  return (
    <Card className="user-bio-card">
      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group row">
                  <div className="key">Age</div>
                  <div className="value">
                    {(profile.dob && moment().diff(profile.dob, "years")) ||
                      "-"}
                  </div>
                  {canManageProfile && (
                    <div
                      className="edit-bio-btn"
                      onClick={() => {
                        setIndex(1);
                      }}
                    >
                      <PencilIcon className="icon" />
                    </div>
                  )}
                </div>
              );
            case 1:
              return (
                <UpdateProfileBio
                  setIndex={setIndex}
                  label={"dob"}
                  id={id}
                  selectionSet={`dob`}
                  defaultValue={
                    profile.dob ? moment(profile.dob).format("YYYY-MM-DD") : "-"
                  }
                  vars={(val) => ({ dob: moment(val).toISOString() })}
                  inputType={"date"}
                />
              );
            default:
              return <div className="info-group row"></div>;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group vertical">
                  <div className="key">
                    Interests{" "}
                    {canManageProfile && (
                      <div
                        className="edit-bio-btn"
                        onClick={() => {
                          setIndex(1);
                        }}
                      >
                        <PencilIcon className="icon" />
                      </div>
                    )}
                  </div>
                  <div className="value grid">
                    {profile.interests.map((i, x) => (
                      <div
                        key={"profile-bio-card-interests-" + i.id + x}
                        className="interest"
                      >
                        <div className="icon">
                          {sportsIcons[i.name] || (
                            <EllipsisIcon className="sports-icon-svg" />
                          )}
                        </div>
                        <div className="desc">{i.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            case 1:
              return (
                <UpdateProfileInterests
                  setIndex={setIndex}
                  id={id}
                  defaultInterests={profile.interests}
                />
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>

      <ToggleState>
        {({ index, setIndex }) => {
          switch (index) {
            case 0:
              return (
                <div className="info-group vertical">
                  <div className="key">
                    Locations{" "}
                    {canManageProfile && (
                      <div
                        className="edit-bio-btn"
                        onClick={() => {
                          setIndex(1);
                        }}
                      >
                        <PencilIcon className="icon" />
                      </div>
                    )}
                  </div>
                  <div className="value grid">
                    {profile.cities?.map((i) => (
                      <div
                        key={"profile-bio-card-interests-" + i.id}
                        className="interest"
                      >
                        <div className="icon">
                          <BuildingIcon className="sports-icon-svg" />
                        </div>
                        <div className="desc">{i.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            case 1:
              return (
                <Fragment>
                  <UpdateProfileLocations
                    setIndex={setIndex}
                    id={id}
                    defaultLocations={profile.cities}
                  />
                </Fragment>
              );
            default:
              return <div />;
          }
        }}
      </ToggleState>
    </Card>
  );
}

function Profile() {
  usePageTracking({ title: "Profile" });
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const tab = query.get("t") || "overview";
  const selectedTab = profileTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;
  const [, setAppState] = useAppState();

  const authQuery = useQuery(CAN_MANAGE_PROFILE, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id },
  });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Profile",
        query: "CAN_MANAGE_PROFILE",
      },
    });
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile",
        query: "PROFILE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "400px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const profile = data.profile;
  const canManageProfile = authQuery?.data?.canManageProfile?.authorized;

  console.log("CAN MANAGE PROFILE:", canManageProfile);
  return (
    <div className="App-content">
      <div className="profile-page">
        <div className="cover">
          {profile.coverPhoto && <Avatar media={profile.coverPhoto} />}
          {profile.coverPhoto && (
            <div
              className="overlay"
              onClick={() => {
                // console.log("Launching phtot");
                setAppState({
                  modal: (
                    <PhotoBrowser
                      query={PROFILE}
                      vars={{ id }}
                      getPhotos={(data) => data.profile?.coverPhotos || []}
                      totalPhotos={(data) =>
                        data.profile?.coverPhotos?.length || 0
                      }
                      media={profile.coverPhoto}
                    />
                  ),
                });
              }}
            />
          )}
          {canManageProfile && (
            <div
              className="edit-cover-btn"
              onClick={() => {
                setAppState({
                  modal: (
                    <SelectCoverPhoto
                      profile={profile}
                      canManageProfile={canManageProfile}
                    />
                  ),
                });
              }}
            >
              <PencilIcon className="icon" /> Edit cover photo
            </div>
          )}
        </div>
        <div className="profile-name">{profile.name}</div>
        <div className="tabs">
          {profileTabs.map((t, i) => (
            <div
              key={"profile-tab-" + i}
              className={
                "tab-item hover-pointer " +
                (selectedTab.key === t.key ? "active" : "")
              }
              onClick={() => {
                // console.log(location.pathname);
                history.replace(location.pathname + "?t=" + t.key);
              }}
            >
              {t.label}
            </div>
          ))}
        </div>
        <div className="body">
          <div className="sidebar">
            <ProfileSummaryCard
              id={id}
              profilePhoto={profile.profilePhoto}
              profile={profile}
              canManageProfile={canManageProfile}
            />

            <ProfileBioCard id={id} canManageProfile={canManageProfile} />

            <ClaimProfile profile={profile} />
          </div>
          <div className="main">
            <SelectedComponent
              id={id}
              profile={profile}
              canManageProfile={canManageProfile}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
