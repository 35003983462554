import { gql } from "@apollo/client";

const EVENT_PARTS = gql`
  query eventFixturesQuery($id: ID!) {
    event(id: $id) {
      id
      parts {
        id
        type
        label
        order
        partOf {
          id
        }
      }
    }
  }
`;

export default EVENT_PARTS;
