import "./FixtureLatestVideo.css";
import { useQuery } from "@apollo/client";
import { FIXTURE_MEDIA } from "../../../api/fixture";
import YouTubeVideo from "../../../components/YouTubeVideo";
import makeFileUrl from "../../../utils/makeFileUrl";
import captureException from "../../../utils/captureException";
import { useHistory, useLocation } from "react-router-dom";

export default function FixtureLatestVideo({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(FIXTURE_MEDIA, {
    variables: { id, type: "VIDEO" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLatestVideo",
        query: "FIXTURE_MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const video = data?.fixture?.pinnedMedia || data?.fixture?.media?.[0];
  // console.log("FLV:", video);

  if (!video) {
    return <div />;
  }
  return (
    <div className="fixture-latest-highlights-mobile">
      <div className="fixture-latest-highlights-mobile__title">
        Latest Highlights
      </div>
      <div className="fixture-latest-highlights-mobile__video-wrapper">
        {video && (
          <div
            className="fixture-latest-highlights-mobile__video-container"
            style={{
              height: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {video.isExternal ? (
              <YouTubeVideo filename={video.filename} />
            ) : (
              <video controls controlsList="nodownload">
                <source src={makeFileUrl(video)} type="video/mp4" />
              </video>
            )}
          </div>
        )}

        {!video && (
          <div className="no-data">
            <div className="text">No videos added to this event yet.</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        )}
      </div>

      <div
        className="fixture-latest-highlights-mobile__footer"
        onClick={() => {
          history.replace(location.pathname + "?t=videos");
        }}
      >
        See more videos
      </div>
    </div>
  );
}
