import "./ProfileInteractionStats.css";
import { useQuery } from "@apollo/client";
import {
  INTERACTION_STATS,
  PAGES,
  FOLLOWERS,
  FOLLOWING,
} from "../../../api/profile";
import Placeholder from "../../../components/Placeholder";
import { useAppState } from "../../../utils/appState";
import PartyList from "../../PartyList";
import captureException from "../../../utils/captureException";

export default function ProfileInteractionStats({ id, profile }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(INTERACTION_STATS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileInteractionStats",
        query: "INTERACTION_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="organization-overview__interaction">
        <div className="organization-overview__interaction__item">
          <div className="organization-overview__interaction__item__value">
            <Placeholder
              style={{ height: "16px", width: "16px" }}
              pulse={true}
            />
          </div>

          <div className="organization-overview__interaction__item__label">
            <Placeholder
              style={{
                height: "10px",
                width: "40px",
                background: "var(--light-2)",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-overview__interaction__item">
          <div className="organization-overview__interaction__item__value">
            <Placeholder
              style={{ height: "16px", width: "16px" }}
              pulse={true}
            />
          </div>

          <div className="organization-overview__interaction__item__label">
            <Placeholder
              style={{
                height: "10px",
                width: "40px",
                background: "var(--light-2)",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-overview__interaction__item">
          <div className="organization-overview__interaction__item__value">
            <Placeholder
              style={{ height: "16px", width: "16px" }}
              pulse={true}
            />
          </div>

          <div className="organization-overview__interaction__item__label">
            <Placeholder
              style={{
                height: "10px",
                width: "40px",
                background: "var(--light-2)",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>
      </div>
    );
  }

  const interactionStats = data.interactionStats;
  return (
    <div className="profile-overview__interaction">
      <div
        className="profile-overview__interaction__item"
        onClick={() => {
          setAppState({
            modal: (
              <PartyList
                title={`${profile.name}'s Pages`}
                query={PAGES}
                variables={{ id }}
                fetchPolicy={"network-only"}
                getData={
                  ((d) =>
                    d?.pages?.map((p) => ({
                      organization: p.organization,
                    }))) || []
                }
                noData={() => (
                  <div className="no-data">
                    <div>{profile.name} has no pages yet.</div>
                  </div>
                )}
                secondaryText={(item) =>
                  item.organization?.handle
                    ? "@" + item.organization?.handle
                    : ""
                }
                totalItems={interactionStats.memberships}
              />
            ),
          });
        }}
      >
        <div className="profile-overview__interaction__item__value">
          {interactionStats.memberships}
        </div>

        <div className="profile-overview__interaction__item__label">Pages</div>
      </div>

      <div
        className="profile-overview__interaction__item"
        onClick={() => {
          setAppState({
            modal: (
              <PartyList
                title={`${profile.name}'s Fans`}
                query={FOLLOWERS}
                variables={{ id }}
                getData={((d) => d?.followers) || []}
                noData={() => (
                  <div className="no-data">
                    <div>{profile.name} has no fans yet.</div>
                  </div>
                )}
                secondaryText={(item) =>
                  item.organization?.handle || item.profile?.handle
                    ? "@" + (item.organization?.handle || item.profile?.handle)
                    : ""
                }
                totalItems={interactionStats.followers}
              />
            ),
          });
        }}
      >
        <div className="profile-overview__interaction__item__value">
          {interactionStats.followers}
        </div>

        <div className="profile-overview__interaction__item__label">Fans</div>
      </div>

      <div
        className="profile-overview__interaction__item"
        onClick={() => {
          setAppState({
            modal: (
              <PartyList
                title={`Supported by ${profile.name}`}
                query={FOLLOWING}
                variables={{ id }}
                getData={((d) => d?.following) || []}
                noData={() => (
                  <div className="no-data">
                    <div>{profile.name} is not supporting anyone yet.</div>
                  </div>
                )}
                secondaryText={(item) =>
                  item.organization?.handle || item.profile?.handle
                    ? "@" + (item.organization?.handle || item.profile?.handle)
                    : ""
                }
                totalItems={interactionStats.following}
              />
            ),
          });
        }}
      >
        <div className="profile-overview__interaction__item__value">
          {interactionStats.following}
        </div>

        <div className="profile-overview__interaction__item__label">
          Supporting
        </div>
      </div>
    </div>
  );
}
