import { gql } from "@apollo/client";

const DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION = gql`
  mutation deleteFixtureStatWithoutPropagation($id: ID!) {
    deleteFixtureStatWithoutPropagation(id: $id) {
      id
    }
  }
`;

export default DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION;
