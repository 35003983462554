// V1
import "./Album.css";
import { DELETE_MEDIA_SET, MEDIA_SET, DELETE_MEDIA } from "../../api/media";
import { useQuery, gql, useMutation } from "@apollo/client";
import Placeholder from "../Placeholder";
import Uploader from "../Uploader";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import Card from "../Card";
import moment from "moment";
import { useAppState } from "../../utils/appState";
import PhotoBrowser from "../PhotoBrowser";
import Avatar from "../Avatar/Avatar";
import captureException from "../../utils/captureException";
import Loader from "../Loader";

const getSource = (party) => {
  if (!party) {
    return;
  }
  if (party.fixture?.id) {
    return {
      sourceId: party.fixture.id,
      sourceType: "fixture",
      source: party.fixture,
    };
  } else if (party.event?.id) {
    return {
      sourceId: party.event.id,
      sourceType: "event",
      source: party.event,
    };
  } else if (party.organization?.id) {
    return {
      sourceId: party.organization.id,
      sourceType: "organization",
      source: party.organization,
    };
  } else if (party.profile?.id) {
    return {
      sourceId: party.profile.id,
      sourceType: "profile",
      source: party.profile,
    };
  }
};

function Album({ id, dismiss = () => {}, onDelete }) {
  const [, setAppState] = useAppState();
  const [deleteMedia, { loading: deletingMedia }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      if (deleteMedia.id) {
        cache.modify({
          id: "MediaSet:" + id,
          fields: {
            media(existingMediaRefs, { readField }) {
              return existingMediaRefs.filter(
                (mediaRef) => readField("id", mediaRef) !== deleteMedia.id
              );
            },
            preview(currentRef, { readField }) {
              if (readField("id", currentRef) === deleteMedia.id) {
                return null;
              } else {
                return currentRef;
              }
            },
          },
        });
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Album",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [deleteAlbum, { loading: deleting }] = useMutation(DELETE_MEDIA_SET, {
    update: (cache, { data: { deleteMediaSet } }) => {
      if (deleteMediaSet.id) {
        onDelete ? onDelete(cache, deleteMediaSet) : dismiss();
      }
    },
  });
  const { loading, error, data } = useQuery(MEDIA_SET, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Album",
        query: "MEDIA_SET",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data || !data.mediaSet) {
    return (
      <Card className="app-album-container">
        <div className="app-album-container__head">
          <div className="app-album-container__head__link">
            <Placeholder
              style={{ height: "12px", width: "100px", marginLeft: "8px" }}
              pulse={true}
            />
          </div>
          <div className="app-album-container__head__title">
            <div className="app-album-container__head__title__text">
              <Placeholder
                style={{ height: "24px", width: "240px" }}
                pulse={true}
              />
            </div>
          </div>
          <div className="app-album-container__head__meta">
            <div className="app-album-container__head__timestamp">
              <Placeholder
                style={{ height: "12px", width: "80px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
        <div className="app-album-container__body">
          {[1, 2, 3].map((m) => (
            <div key={m.id} className="app-album-container__body__item">
              <div className="app-album-container__body__item__image">
                <Placeholder
                  style={{
                    height: "160px",
                    width: "100%",
                    background: "var(--blue-subtle)",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const mediaSet = data?.mediaSet;
  const mediaSetSource = getSource(mediaSet.source);
  // console.log("MEDIA SET:", mediaSet);

  return (
    <Card className="app-album-container">
      <div className="app-album-container__head">
        <div
          className="app-album-container__head__link"
          onClick={() => {
            dismiss();
          }}
        >
          <ChevronLeftIcon
            style={{ stroke: "var(--dark-4)", height: "12px" }}
          />
          Back to photos
        </div>
        <div className="app-album-container__head__title">
          <div className="app-album-container__head__title__text">
            {mediaSet?.source.fixture
              ? `${
                  mediaSet.source?.fixture?.scheduledStart
                    ? `[${moment(mediaSet.source.fixture.scheduledStart).format(
                        "DD-MM-YYYY"
                      )}] `
                    : ""
                }${mediaSet.source.fixture.roles
                  .map((r) => r.organization?.name || r.profile?.name)
                  .join(" vs ")}`
              : mediaSet.name}
          </div>
        </div>
        <div className="app-album-container__head__meta">
          <div className="app-album-container__head__timestamp">
            Created at {moment(mediaSet.createdAt).format("Do MMM YYYY")}
          </div>

          {mediaSet.canManageMediaSet && !deleting && (
            <div
              className="app-album-container__head__add-photos-btn"
              onClick={() => {
                setAppState({
                  modal: (
                    <Uploader
                      accept=".jpg,.png,.jpeg"
                      label="Event Photos"
                      multiple
                      sourceId={mediaSetSource.sourceId}
                      sourceType={mediaSetSource.sourceType}
                      mediaSetId={id}
                      onCreateMedia={(cache, createMedia) => {
                        cache.modify({
                          id: cache.identify(mediaSet),
                          fields: {
                            media(existingMediaRefs, { readField }) {
                              const newMediaRef = cache.writeFragment({
                                data: createMedia.media,
                                fragment: gql`
                                  fragment NewMedia on Media {
                                    id
                                    createdAt
                                    type
                                    subtype
                                    isExternal
                                    filename
                                    public
                                    aspectRatio
                                    canManageMedia
                                  }
                                `,
                              });

                              if (
                                existingMediaRefs.some(
                                  (ref) =>
                                    readField("id", ref) ===
                                    createMedia.media.id
                                )
                              ) {
                                return existingMediaRefs;
                              }

                              return [newMediaRef, ...existingMediaRefs];
                            },
                          },
                        });

                        cache.modify({
                          id: cache.identify(mediaSetSource.source),
                          fields: {
                            media(existingMediaRefs, { readField }) {
                              const newMediaRef = cache.writeFragment({
                                data: createMedia.media,
                                fragment: gql`
                                  fragment NewMedia on Media {
                                    id
                                    createdAt
                                    type
                                    subtype
                                    isExternal
                                    filename
                                    public
                                    aspectRatio
                                    canManageMedia
                                  }
                                `,
                              });

                              if (
                                existingMediaRefs.some(
                                  (ref) =>
                                    readField("id", ref) ===
                                    createMedia.media.id
                                )
                              ) {
                                return existingMediaRefs;
                              }

                              return [newMediaRef, ...existingMediaRefs];
                            },
                          },
                        });
                      }}
                    />
                  ),
                });
              }}
            >
              Add photos
            </div>
          )}

          {mediaSet.canManageMediaSet && !mediaSet?.media?.length && (
            <div
              className="app-album-container__head__delete-photos-btn"
              onClick={() => {
                if (deleting) {
                  return;
                }
                deleteAlbum({
                  variables: {
                    id,
                  },
                });
              }}
            >
              {deleting ? <Loader theme="small red" /> : "Delete Album"}
            </div>
          )}
        </div>
      </div>
      <div className="app-album-container__body">
        {mediaSet?.media.map((m) => (
          <div
            key={m.id}
            className="app-album-container__body__item"
            onClick={() => {
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={MEDIA_SET}
                    vars={{ id }}
                    getPhotos={(data) => data?.mediaSet?.media || []}
                    totalPhotos={(data) => data?.mediaSet?.photosCount || 0}
                    media={m}
                  />
                ),
              });
            }}
          >
            <div className="app-album-container__body__item__image">
              <Avatar media={m} />
              {/* <img src={makeFileUrl(m)} /> */}

              {mediaSet.canManageMediaSet && (
                <div className="app-album-container__body__item__image__options">
                  <div
                    className="app-album-container__body__item__image__options__option"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (deleting) return;

                      // console.log("Deleting");
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    <TrashIcon style={{ stroke: "white", height: "10px" }} />
                  </div>
                </div>
              )}
              {/* <div className="overlay" /> */}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function AlbumWrapper({ id, dismiss = () => {}, onDelete }) {
  if (!id) {
    return <div />;
  } else {
    return <Album id={id} dismiss={dismiss} onDelete={onDelete} />;
  }
}

export default Album;
