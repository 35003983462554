// V1
import { useState } from "react";
import "./UpcomingEvents.css";
import Card from "../../Card";
import EventCalendar from "../../EventCalendar";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
// import { UPCOMING_FIXTURES, USER_FIXTURES } from "../../../api/user";
import moment from "moment";
import Placeholder from "../../Placeholder";
import captureException from "../../../utils/captureException";
import FixtureListItem from "../../FixtureListItem";
import { FIXTURES, UPCOMING_FIXTURES } from "../../../api/functions";

function UpcomingFixturesList({ fixtures = [] }) {
  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(
    FIXTURES({
      selectionSet: `
        isUserFixture
        event{
          id
          sport{
            id
            name
          }
        }
        roles(where:{type:{name:"PARTICIPANT"}}) {
          id
          order
          type {
            id
            name
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        badmintonBoxScore {
          id
          label
          homeScore
          awayScore
        }
        placements(where: { type: { name: "RESULT" } }) {
          id
          order
          organization {
            id
          }
          profile {
            id
          }
          value
          valueString
        }        
      `,
    }),
    {
      variables: {
        where: {
          id: { in: fixtures.map((f) => f.id) },
        },
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="calendar-fixture-list-item">
        <div className="participants">
          <div className="participant">
            <div className="image"></div>
            <div className="name">
              <Placeholder style={{ width: "60px" }} pulse={true} />
            </div>
          </div>

          <div className="participant">
            <div className="image"></div>
            <div className="name">
              <Placeholder style={{ width: "60px" }} pulse={true} />
            </div>
          </div>
        </div>

        <div className="datetime">
          <div className="date">
            <Placeholder style={{ width: "24px" }} pulse={true} />
          </div>
        </div>
      </Card>
    );
  }

  const selectedDayFixtures = data?.fixtures || [];
  const userFixtures = selectedDayFixtures.filter((f) => f.isUserFixture);
  const otherFixtures = selectedDayFixtures.filter((f) => !f.isUserFixture);

  return (
    <>
      {userFixtures.map((fixture) => (
        <Link to={"/app/fixture/" + fixture.id} key={fixture.id}>
          <FixtureListItem
            fixture={fixture}
            hideDate={true}
            className="home-upcoming-events-card__fixture-list-item"
          />
        </Link>
      ))}

      {otherFixtures.map((fixture) => (
        <Link to={"/app/fixture/" + fixture.id} key={fixture.id}>
          <FixtureListItem
            fixture={fixture}
            hideDate={true}
            className="home-upcoming-events-card__fixture-list-item"
          />
        </Link>
      ))}

      {!selectedDayFixtures?.length && (
        <div className="no-data">
          <div>You have no events scheduled for this day.</div>
          {/* <div>Let's change that.</div> */}
          <div
            className="link hover-pointer hover-underline"
            onClick={() => {
              history.push("/app/events");
            }}
          >
            Find Events Near You
          </div>
        </div>
      )}
    </>
  );
}

function UpcomingEvents() {
  const [eventsDate, setEventsDate] = useState(moment().toISOString());
  const { loading, error, data, refetch } = useQuery(
    UPCOMING_FIXTURES({
      selectionSet: `
        scheduledStart
        isUserFixture
      `,
    }),
    {
      variables: {
        where: {
          startDate: moment().startOf("month").toISOString(),
          endDate: moment().endOf("month").toISOString(),
        },
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "UpcomingEvents",
        query: "USER_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const fixtures = data?.upcomingFixtures || [];
  const selectedDayFixtures =
    fixtures.filter(
      (f) =>
        moment(eventsDate).startOf("day").isBefore(f.scheduledStart) &&
        moment(eventsDate).endOf("day").isAfter(f.scheduledStart)
    ) || [];

  // const userFixtures = selectedDayFixtures.filter((f) => f.isUserFixture);
  // const otherFixtures = selectedDayFixtures.filter((f) => !f.isUserFixture);

  // const getParticipantName = (role) => {
  //   return role?.organization?.name || role?.profile?.name || "TBD";
  // };

  // console.log(fixtures);

  return (
    <Card className="home-upcoming-events-card">
      <div className="home-upcoming-events-card__calendar">
        <EventCalendar
          loading={loading}
          onNextClick={(date) => {
            setEventsDate(date);
            refetch({
              where: {
                startDate: moment(date).startOf("month").toISOString(),
                endDate: moment(date).endOf("month").toISOString(),
              },
            });
          }}
          onPrevClick={(date) => {
            setEventsDate(date);
            refetch({
              where: {
                startDate: moment(date).startOf("month").toISOString(),
                endDate: moment(date).endOf("month").toISOString(),
              },
            });
          }}
          onDateSelect={(date) => {
            setEventsDate(date);
          }}
          fixtures={fixtures}
        />
      </div>

      <div className="calendar-fixture-list">
        {loading ? (
          <Card className="calendar-fixture-list-item">
            <div className="participants">
              <div className="participant">
                <div className="image"></div>
                <div className="name">
                  <Placeholder style={{ width: "60px" }} pulse={true} />
                </div>
              </div>

              <div className="participant">
                <div className="image"></div>
                <div className="name">
                  <Placeholder style={{ width: "60px" }} pulse={true} />
                </div>
              </div>
            </div>

            <div className="datetime">
              <div className="date">
                <Placeholder style={{ width: "24px" }} pulse={true} />
              </div>
            </div>
          </Card>
        ) : (
          <UpcomingFixturesList fixtures={selectedDayFixtures} />
        )}

        {/* {!loading && <UpcomingFixturesList fixtures={selectedDayFixtures} />} */}

        {/* {!loading &&
          userFixtures.map((fixture) => (
            <Link to={"/app/fixture/" + fixture.id} key={fixture.id}>
              <FixtureListItem
                fixture={fixture}
                hideDate={true}
                className="home-upcoming-events-card__fixture-list-item"
              />
            </Link>
          ))}

        {!loading &&
          otherFixtures.map((fixture) => (
            <Link to={"/app/fixture/" + fixture.id} key={fixture.id}>
              <FixtureListItem
                fixture={fixture}
                hideDate={true}
                className="home-upcoming-events-card__fixture-list-item"
              />
            </Link>
          ))} */}

        {/* {!loading && !selectedDayFixtures?.length && (
          <div className="no-data">
            <div>You have no events scheduled for this day.</div>            
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                history.push("/app/events");
              }}
            >
              Find Events Near You
            </div>
          </div>
        )} */}
      </div>
    </Card>
  );
}

export default UpcomingEvents;
