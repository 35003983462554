import "./BasketballStats.css";
import Card from "../../../Card";
import {
  ORGANIZATION_STATS,
  ORGANIZATION_AWARDS,
} from "../../../../api/organization";
import { useQuery } from "@apollo/client";
// import OrganizationFormStats from "../../../OrganizationFormStats";
import StatPercentage from "../StatPercentage";
// import OrganizationAwards from "../OrganizationAwards/OrganizationAwards";
import captureException from "../../../../utils/captureException";
import { Fragment } from "react";
import Square from "../../../../mobileComponents/Square";
import VerticalText from "../../../../mobileComponents/VerticalText";
import Avatar from "../../../Avatar";
import moment from "moment";

function OrganizationRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record">
      <div className="organization-record__meta">
        <div className="organization-record__meta__title">RECORD</div>

        <div className="organization-record__meta__highlight">
          <div className="organization-record__meta__highlight__value">
            {total}
          </div>
          <div className="organization-record__meta__highlight__label">
            Games Played
          </div>
        </div>
      </div>

      <div className="organization-record__items">
        <div
          className="organization-record__items__item"
          style={{ marginTop: "8px" }}
        >
          <div className="organization-record__items__item__label">Win</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ height: total ? (wins / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">{wins}</div>
        </div>

        <div
          className="organization-record__items__item"
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div className="organization-record__items__item__label">Loss</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ height: total ? (losses / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">
            {losses}
          </div>
        </div>

        <div
          className="organization-record__items__item"
          style={{ marginBottom: "8px" }}
        >
          <div className="organization-record__items__item__label">Draw</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ height: total ? (draws / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">{draws}</div>
        </div>
      </div>
    </div>
  );
}

function OrganizationOffenseStats({
  gamesPlayed,
  gamesWon,
  points,
  shots,
  shotsMade,
}) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">OFFENSE</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (points / gamesPlayed) % 1
                    ? (points / gamesPlayed).toFixed(1)
                    : points / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Points <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (shots / gamesPlayed) % 1
                    ? (shots / gamesPlayed).toFixed(1)
                    : shots / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Shots <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {assists}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Penalties
                <br /> Won
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {keyPasses}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Penalties <br /> Scored
              </div>
            </div>
          )}
        </Square> */}

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {shots
                  ? (shotsMade / shots) % 1
                    ? ((shotsMade / shots) * 100).toFixed(1)
                    : (shotsMade / shots) * 100
                  : 0}
                %
              </div>
              <div className="profile-attack-stats__items__item__label">
                Shot <br /> Accuracy
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? Math.round((gamesWon / gamesPlayed) * 100) + "%"
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Win Rate <br /> (%)
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"ATTACK"}
              fontStyle={{
                fontSize: "48px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "36px",
                height: "48px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function OrganizationShootingStats({
  onePointFgm = 0,
  onePointFga = 0,
  twoPointFgm = 0,
  twoPointFga = 0,
  threePointFgm = 0,
  threePointFga = 0,
  ftm = 0,
  fta = 0,
}) {
  const onePointFgp = onePointFga ? (onePointFgm / onePointFga) * 100 : 0;
  const twoPointFgp = twoPointFga ? (twoPointFgm / twoPointFga) * 100 : 0;
  const threePointFgp = threePointFga
    ? (threePointFgm / threePointFga) * 100
    : 0;

  const ftp = fta ? (ftm / fta) * 100 : 0;

  return (
    <div
      className="profile-shooting-stats"
      style={{
        gridColumnStart: 1,
        gridColumnEnd: 3,
      }}
    >
      <div className="profile-shooting-stats__title">SHOOTING</div>

      <div
        className="profile-shooting-stats__items"
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 5,
        }}
      >
        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              1 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {onePointFgm || 0} / {onePointFga || 0} ({onePointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{ width: onePointFga ? onePointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              2 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {twoPointFgm || 0} / {twoPointFga || 0} ({twoPointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{ width: twoPointFga ? twoPointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              3 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {threePointFgm || 0} / {threePointFga || 0} (
              {threePointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{ width: threePointFga ? threePointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Free Throws
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {ftm || 0} / {fta || 0} ({ftp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{ width: fta ? ftp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganizationFormStats({ id, form }) {
  // const formFixtures = form.filter((f) =>
  //   f.roles.find((r) => r.organization.id !== id)
  // );

  // console.log("Form:", form);

  return (
    <div className="organization-form">
      <div className="organization-form__title">FORM</div>

      <div className="organization-form__items">
        {!form?.length && (
          <div className="no-data">No previous matches found</div>
        )}
        {form.map((fixture) => (
          <FormItem id={id} fixture={fixture} />
        ))}
      </div>
    </div>
  );
}

function FormItem({ id, fixture }) {
  const orgPlacement = fixture.placements?.find(
    (p) => p.organization.id === id
  );
  const opponentPlacement = fixture.placements?.find(
    (p) => p.organization.id !== id
  );

  const opponentRole = fixture.roles.find((r) => r.organization.id !== id);
  const result =
    (orgPlacement?.value || 0) > (opponentPlacement?.value || 0)
      ? "W"
      : (orgPlacement?.value || 0) < (opponentPlacement?.value || 0)
      ? "L"
      : "D";

  return (
    <div className="organization-form__items__item">
      <div className="organization-form__items__item__meta">
        <div className="organization-form__items__item__meta__image">
          {opponentRole?.organization?.profilePhoto && (
            <Avatar media={opponentRole.organization.profilePhoto} />
          )}
        </div>

        <div className="organization-form__items__item__meta__text">
          <div className="organization-form__items__item__meta__text__primary">
            {opponentRole?.organization?.name}
          </div>

          <div className="organization-form__items__item__meta__text__secondary">
            {fixture.scheduledStart
              ? moment(fixture.scheduledStart).format("Do MMM YYYY")
              : "-"}
          </div>
        </div>
      </div>

      <div className="organization-form__items__item__result">
        <div
          className={
            "organization-form__items__item__result__text " +
            (result === "W" ? "green" : result === "L" ? "red" : "orange")
          }
        >
          {result === "W" ? "WIN" : result === "L" ? "LOSS" : "DRAW"}
        </div>

        <div className="organization-form__items__item__result__score">
          {orgPlacement?.value || 0} - {opponentPlacement?.value || 0}
        </div>
      </div>
    </div>
  );
}

function OrganizationAwards({ id, sport }) {
  const { loading, error, data } = useQuery(ORGANIZATION_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.BasketballStats.OrganizationAwards",
        query: "ORGANIZATION_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const organization = data.organization;
  const placements = organization?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats">
      <div className="profile-awards-stats__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats__items">
          <div className="no-data">No awards for this team yet.</div>
        </div>
      )}

      <div className="profile-awards-stats__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats__items__item">
              <div className="profile-awards-stats__items__item__img"></div>
              <div className="profile-awards-stats__items__item__desc">
                <div className="profile-awards-stats__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function BasketballStats({ id, sport, events }) {
  const { error, data, loading } = useQuery(ORGANIZATION_STATS, {
    variables: {
      id,
      sport: sport || "Basketball",
      statTypes: [
        "1FGM",
        "1FGA",
        "2FGM",
        "2FGA",
        "3FGA",
        "3FGM",
        "FTM",
        "FTA",
        "PTS",
        "BLK",
        "STL",
        "T/O",
        "FOULS",
      ],
      events,
      rolesWhereInput: { type: { name: "PARTICIPANT" } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.BasketballStats",
        query: "ORGANIZATION_STATS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  // console.log("STATS:", data);

  const { totalGamesPlayed, form } = data?.organization?.stats || {};

  const record = JSON.parse(data?.organization?.stats?.record?.data || "{}");
  const aggregationsData = JSON.parse(
    data?.organization?.stats?.aggregations?.data || "{}"
  );

  return (
    <Fragment>
      <OrganizationRecordStats
        total={totalGamesPlayed}
        wins={record.wins}
        losses={record.losses}
        draws={record.draws}
      />

      <OrganizationOffenseStats
        gamesPlayed={totalGamesPlayed}
        gamesWon={record.wins}
        points={aggregationsData["PTS"] || 0}
        shots={
          (aggregationsData["1FGA"] || 0) +
          (aggregationsData["2FGA"] || 0) +
          (aggregationsData["3FGA"] || 0)
        }
        shotsMade={
          (aggregationsData["1FGM"] || 0) +
          (aggregationsData["2FGM"] || 0) +
          (aggregationsData["3FGM"] || 0)
        }
      />

      <div className="profile-stats-grid">
        <OrganizationShootingStats
          onePointFga={aggregationsData["1FGA"] || 0}
          onePointFgm={aggregationsData["1FGM"] || 0}
          twoPointFga={aggregationsData["2FGA"] || 0}
          twoPointFgm={aggregationsData["2FGM"] || 0}
          threePointFga={aggregationsData["3FGA"] || 0}
          threePointFgm={aggregationsData["3FGM"] || 0}
          fta={aggregationsData["FTA"] || 0}
          ftm={aggregationsData["FTM"] || 0}
        />

        <OrganizationFormStats id={id} form={form} />
      </div>

      <OrganizationAwards id={id} sport={"Basketball"} />
    </Fragment>
  );
}

export default BasketballStats;
