import { gql } from "@apollo/client";

const ES_SEARCH = gql`
  query esSearch($term: String!, $cursor: Int, $types: [String!]) {
    esSearch(term: $term, cursor: $cursor, types: $types) {
      total
      nextCursor
      hits {
        id
        node_type
        name
        handle
        profilePhoto
      }
    }
  }
`;

export default ES_SEARCH;
