import { gql } from "@apollo/client";

const FIXTURES = gql`
  query fixturesQuery($where: FixturesWhereInput!) {
    fixtures(where: $where) {
      id
      createdAt
      scheduledStart
      actualStart
      totalLegs
      legs {
        id
      }
      roles {
        id
        order
        type {
          id
          name
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      eventPart {
        id
      }
      placements(where: { type: { name: "RESULT" } }) {
        id
        order
        organization {
          id
        }
        profile {
          id
        }
        value
        valueString
      }
    }
  }
`;

export default FIXTURES;
