import "./EventResultsSummary.css";
import FixtureListItem from "../../../mobileComponents/FixtureListItem";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { EVENT_FIXTURES } from "../../../api/event";
import { useHistory, useLocation } from "react-router-dom";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function EventResultsSummary({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(EVENT_FIXTURES, {
    variables: { id, take: 4, filters: { completed: true }, orderBy: "desc" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventResultsSummary",
        query: "EVENT_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-results-summary-mobile">
        <div className="event-results-summary-mobile__title">
          <Placeholder style={{ height: "10px", width: "64px" }} pulse={true} />
        </div>

        <div className="event-results-summary-mobile__list">
          {[1, 2].map((fixture) => (
            <div
              key={"fixture-" + fixture}
              className="event-results-summary-mobile__list__item"
            >
              <div className="fixture-list-item-mobile">
                <div className="fixture-list-item-mobile__teams">
                  {[1, 2].map((p) => (
                    <div className="fixture-list-item-mobile__teams__item">
                      <div className="fixture-list-item-mobile__teams__item__image"></div>

                      <div className="fixture-list-item-mobile__teams__item__name">
                        <Placeholder
                          style={{ height: "10px", width: "160px" }}
                          pulse={true}
                        />
                      </div>

                      <div className="fixture-list-item-mobile__teams__item__score"></div>
                    </div>
                  ))}
                </div>

                <div className="fixture-list-item-mobile__time"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const fixtures = data.event?.fixtures || [];

  return (
    <div className="event-results-summary-mobile">
      <div className="event-results-summary-mobile__title">Recent Results</div>

      <div className="event-results-summary-mobile__list">
        {!fixtures?.length && (
          <div className="no-data">
            <div className="text">No results for this event yet</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        )}
        {fixtures.slice(0, 4).map((fixture) => (
          <div
            key={"fixture-" + fixture.id}
            className="event-results-summary-mobile__list__item"
          >
            <Link to={"/app/fixture/" + fixture.id}>
              <FixtureListItem fixture={fixture} />
            </Link>
          </div>
        ))}
      </div>
      {!!fixtures?.length && (
        <div className="event-results-summary-mobile__footer">
          <div
            className=""
            onClick={() => {
              history.replace(location.pathname + "?t=fixtures");
            }}
          >
            See all fixtures
          </div>
        </div>
      )}
    </div>
  );
}
