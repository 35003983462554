import "./Events.css";
// import Card from "../../components/Card";
// import Button from "../../components/Button";
import { useState, Fragment } from "react";
import moment from "moment";
import Placeholder from "../../components/Placeholder";
import { useAppState } from "../../utils/appState";
import { useQuery } from "@apollo/client";
import { EVENTS } from "../../api/event";
import Loader from "../../components/Loader";
import { EventItem, SportsList } from "../../mobileComponents/Events";
import Filters from "../../mobileComponents/Filters";
import { SPORTS } from "../../api/sport";
import LocationSearch from "../../mobileComponents/LocationSearch";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function NoEventsPlaceholder({ refetch }) {
  return (
    <div className="no-data">
      <div>There are no events for this selection.</div>
      <div
        className="link hover-pointer hover-underline"
        onClick={() => {
          refetch();
        }}
      >
        Check again
      </div>
    </div>
  );
}

function ListLoading() {
  return (
    <div className="events-page-mobile__list__item">
      <div className="events-page-mobile__list__item__cover"></div>

      <div className="events-page-mobile__list__item__text">
        <div className="events-page-mobile__list__item__text__primary">
          <Placeholder
            style={{ height: "14px", width: "200px" }}
            pulse={true}
          />
        </div>

        <div className="events-page-mobile__list__item__text__secondary">
          <Placeholder
            style={{ height: "10px", width: "60px", marginTop: "8px" }}
            pulse={true}
          />
        </div>

        <div className="events-page-mobile__list__item__text__secondary">
          <Placeholder
            style={{ height: "10px", width: "80px", marginTop: "8px" }}
            pulse={true}
          />
        </div>
      </div>
    </div>
  );
}

function Events() {
  usePageTracking({ title: "Events" });
  const [
    { eventSports, eventDateFilters, eventLocations, eventSport },
    setAppState,
  ] = useAppState();
  const [dateFilters, setDateFilters] = useState(eventDateFilters || []);
  const [sportFilters, setSportFilters] = useState(eventSports || []);
  const [locations, setLocations] = useState(eventLocations || []);
  const [sport, setSport] = useState(eventSport || "");
  const sportsQuery = useQuery(SPORTS);
  const { error, data, networkStatus, variables, fetchMore, refetch } =
    useQuery(EVENTS, {
      variables: { where: {} },
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Events",
        query: "EVENTS",
      },
    });
    console.log(error);
    return <div />;
  }

  const onSportSelect = (s) => {
    if (sport !== s) {
      setSport(s);
      refetch({
        where: {
          ...(variables.where || {}),
          sports: [...(sportFilters.map((s) => s.id) || []), ...(s ? [s] : [])],
        },
      });
      setAppState({ eventSport: s });
    } else {
      setSport("");
      refetch({
        where: {
          ...(variables.where || {}),
          sports: [...(sportFilters.map((s) => s.id) || [])],
        },
      });
      setAppState({ eventSport: "" });
    }
  };

  const onSportFilter = (item, selected) => {
    let newFilters;

    if (selected["Sports"].find((x) => x.id === item.id)) {
      newFilters = selected["Sports"].filter((x) => x.id !== item.id);
    } else {
      newFilters = [...selected["Sports"], item];
    }

    // console.log([...newFilters.map((x) => x.id), ...(sport ? [sport] : [])]);

    refetch({
      where: {
        ...(variables.where || {}),
        sports: [...newFilters.map((x) => x.id), ...(sport ? [sport] : [])],
      },
    });
    setSportFilters(newFilters);
    setAppState({ eventSports: newFilters });

    // console.log("New filters:", newFilters);
    return {
      ...selected,
      Sports: newFilters,
    };
  };

  const onStatusSelect = (item, selected) => {
    let newFilters;

    if (selected["Status"].find((x) => x.label === item.key)) {
      newFilters = selected["Status"].filter((x) => x.label !== item.key);
    } else {
      newFilters = [
        ...selected["Status"],
        { label: item.key, queries: item.queries },
      ];
    }

    refetch({
      where: {
        ...(variables.where || {}),
        dateFilters: [
          ...newFilters.map((x) => ({
            queries: x.queries,
          })),
        ],
      },
    });

    setDateFilters(newFilters);
    setAppState({ eventDateFilters: newFilters });

    return {
      ...selected,
      Status: newFilters,
    };
  };

  const onLocationSelect = (locations) => {
    setLocations(locations);
    refetch({
      where: {
        ...(variables.where || {}),
        cities: locations.map((s) => s.name),
      },
    });
    setAppState({ eventLocations: locations });
  };

  const events = data?.events || [];
  const sportsList = sportsQuery.data?.sports || [];

  return (
    <div className="events-page-mobile">
      <div className="events-page-mobile__filters">
        <SportsList
          onSportSelect={onSportSelect}
          defaultValue={eventSport}
          onFiltersClick={() => {
            setAppState({
              modal: (
                <Filters
                  defaultSelected={{
                    Sports: sportFilters,
                    Status: dateFilters,
                  }}
                  categories={[
                    {
                      label: "Sports",
                      items: sportsList.map((s) => ({
                        ...s,
                        label: s.name,
                      })),
                      itemFilter: (selected) => {
                        return (item) => item;
                      },
                      onSelect: onSportFilter,
                      isSelected: (selected, item) => {
                        return selected.find((x) => x.id === item.id);
                      },
                    },
                    {
                      label: "Status",
                      items: [
                        {
                          label: "Ongoing",
                          key: "ongoing",
                          queries: [
                            { scheduledStart: { lt: moment().toISOString() } },
                            { scheduledEnd: { gt: moment().toISOString() } },
                          ],
                        },
                        {
                          label: "Not Started",
                          key: "not started",
                          queries: [
                            { scheduledStart: { gt: moment().toISOString() } },
                          ],
                        },
                        {
                          label: "Completed",
                          key: "completed",
                          queries: [
                            { scheduledEnd: { lt: moment().toISOString() } },
                          ],
                        },
                        {
                          label: "TBA",
                          key: "tba",
                          queries: [{ scheduledStart: null }],
                        },
                      ],
                      // .filter((e) => e.sport.name === selectedSport)
                      // .map((e) => ({ ...e, label: e.name })),
                      itemFilter: (selected) => {
                        return (item) => item;
                      },
                      onSelect: onStatusSelect,
                      isSelected: (selected, item) => {
                        return selected.find((x) => x.label === item.key);
                      },
                    },
                  ]}
                />
              ),
            });
          }}
          onLocationClick={() => {
            setAppState({
              modal: (
                <LocationSearch
                  locations={locations}
                  onLocationSelect={onLocationSelect}
                />
              ),
            });
          }}
        />
      </div>

      <div className="events-page-mobile__list">
        {(networkStatus === 1 || networkStatus === 2) && (
          <Fragment>
            <ListLoading />
            <ListLoading />
            <ListLoading />
          </Fragment>
        )}

        {networkStatus !== 2 &&
          events.map((ev) => <EventItem key={ev.id} ev={ev} />)}

        {!(networkStatus === 1 || !data) && !events?.length && (
          <NoEventsPlaceholder refetch={refetch} />
        )}

        {!!events?.length && networkStatus !== 1 && networkStatus !== 2 && (
          <div className="show-more-container">
            <div
              className="primary small blue"
              onClick={() => {
                if (networkStatus === 3) return;
                fetchMore({
                  variables: {
                    ...variables,
                    cursor: events[events.length - 1].id,
                  },
                });
              }}
            >
              {networkStatus === 3 ? (
                <Loader theme={"small blue"} />
              ) : (
                "Show More"
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // return (
  //   <div className="events-page-mobile">
  //     <div className="filters">
  //       <div className="filters-indicator">
  //         <div className="filters-indicator-text">Filters</div>
  //       </div>
  //     </div>

  //     <div className="events-list">
  //       <Card className="event-card-large">
  //         <div className="head">
  //           <div className="date">
  //             <div className="month">FEB</div>
  //             <div className="day">22</div>
  //           </div>
  //           <div className="meta">
  //             <Link to="/app/event/1" className="primary">
  //               TAL Football Season 10
  //             </Link>
  //             <div className="secondary">
  //               <div className="icon"></div>
  //               <div className="text">FSV Arena, Bengaluru</div>
  //             </div>
  //           </div>
  //           <div className="options">
  //             <Button className="secondary small blue">Follow</Button>
  //           </div>
  //         </div>
  //         <div className="body">
  //           <div className="image"></div>
  //           <div className="blurb">
  //             Duis nec sodales eros, et tempus justo. Vestibulum ultrices velit
  //             malesuada interdum viverra. Nulla facilisi. Maecenas malesuada
  //             pulvinar mattis. Curabitur aliquet vehicula cursus.
  //           </div>
  //         </div>

  //         <div className="foot">
  //           <div className="followers">
  //             <div className="follower"></div>
  //             <div className="follower"></div>
  //             <div className="follower"></div>
  //             <div className="follower"></div>
  //             <div className="more">+32 more</div>
  //           </div>
  //           <div className="stats">
  //             <div className="stat-item">
  //               <div className="icon">I</div>
  //               <div className="value">741</div>
  //             </div>
  //           </div>
  //         </div>
  //       </Card>

  //       <Card className="event-card-large">
  //         <div className="head">
  //           <div className="date">
  //             <div className="month">FEB</div>
  //             <div className="day">22</div>
  //           </div>
  //           <div className="meta">
  //             <Link to="/app/event/1" className="primary">
  //               TAL Football Season 10
  //             </Link>
  //             <div className="secondary">
  //               <div className="icon"></div>
  //               <div className="text">FSV Arena, Bengaluru</div>
  //             </div>
  //           </div>
  //           <div className="options">
  //             <Button className="secondary small blue">Follow</Button>
  //           </div>
  //         </div>
  //         <div className="body">
  //           <div className="image"></div>
  //           <div className="blurb">
  //             Duis nec sodales eros, et tempus justo. Vestibulum ultrices velit
  //             malesuada interdum viverra. Nulla facilisi. Maecenas malesuada
  //             pulvinar mattis. Curabitur aliquet vehicula cursus.
  //           </div>
  //         </div>

  //         <div className="foot">
  //           <div className="followers">
  //             <div className="follower"></div>
  //             <div className="follower"></div>
  //             <div className="follower"></div>
  //             <div className="follower"></div>
  //             <div className="more">+32 more</div>
  //           </div>
  //           <div className="stats">
  //             <div className="stat-item">
  //               <div className="icon">I</div>
  //               <div className="value">741</div>
  //             </div>
  //           </div>
  //         </div>
  //       </Card>
  //     </div>
  //   </div>
  // );
}

export default Events;
