import { useState } from "react";
import "./AuthForm.css";
import Onboarding from "../Onboarding";
import { useMutation, useLazyQuery } from "@apollo/client";
import { SIGNUP, IS_UNIQUE_HANDLE } from "../../api";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../utils/appState";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as ProfileIcon } from "../../icons/profile.svg";
import { ReactComponent as AtIcon } from "../../icons/at-sign.svg";
import { ReactComponent as TimesCircleIcon } from "../../icons/times-circle.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import Loader from "../../components/Loader";
import Row from "../../components/Row";
import Alert from "../../components/Alert";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../Button/DebouncedButton";

function Signup() {
  usePageTracking({ title: "Signup" });
  const [, setAppState] = useAppState();

  // const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [handle, setHandle] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // const [invalidName, setInvalidName] = useState(false);
  const [invalidFirstname, setInvalidFirstname] = useState(false);
  const [invalidLastname, setInvalidLastname] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidHandle, setInvalidHandle] = useState(false);
  const [handleError, setHandleError] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const [signup, { loading }] = useMutation(SIGNUP, {
    update: (cache, { data: { signup } }) => {
      // console.log(signup);
      if (!signup.token || !signup.expiresAt) {
        // setError(true);
        return;
      }

      localStorage.setItem("sp_auth_token", signup.token);
      localStorage.setItem("sp_expires_at", signup.expiresAt);
      localStorage.setItem("sp_name", signup.name);
      setAppState({ modal: <Onboarding />, isLoggedIn: true });

      history.push("/app");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Signup",
          mutation: "SIGNUP",
        },
      });
      console.log(error);
      setErrorMessage(error.message);
    },
  });

  const [isUniqueHandle] = useLazyQuery(IS_UNIQUE_HANDLE, {
    onCompleted: (data) => {
      // console.log(data);
      if (!data?.isUniqueHandle?.valid) {
        setInvalidHandle(true);
        setHandleError("This handle is unavailable");
        return;
      }

      signup({
        variables: {
          name: `${firstname} ${lastname}`,
          firstname,
          lastname,
          handle,
          email,
          password,
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "query",
          component: "Singup",
          query: "IS_UNIQUE_HANDLE",
        },
      });
      console.log(error);
    },
  });

  return (
    <form
      onSubmit={async (e) => {
        // console.log("Form submitted");
        e.preventDefault();

        if (loading) return;

        if (!email || !password || !firstname || !lastname || !handle) {
          if (!email) {
            setInvalidEmail(true);
          }

          if (!password) {
            setInvalidPassword(true);
          }

          if (!firstname) {
            setInvalidFirstname(true);
          }

          if (!lastname) {
            setInvalidLastname(true);
          }

          if (!handle) {
            setInvalidHandle(true);
          }

          return;
        }

        if (handle.length < 8 || handle.length > 30) {
          setInvalidHandle(true);
          setHandleError("Handle must be between 8 & 30 characters long.");
          return;
        }

        const validateHandle = new RegExp("^[0-9a-zA-Z_.]+$");

        if (!validateHandle.test(handle)) {
          setInvalidHandle(true);
          setHandleError(
            "Handle can only include letters, numbers, underscores and periods."
          );
          return;
        }

        isUniqueHandle({
          variables: { handle },
        });
      }}
      className="auth-form"
    >
      <Row columns={2} gap={16}>
        <div
          className="form-group"
          style={{ gridColumnStart: 1, gridColumnEnd: 2 }}
        >
          <input
            type="text"
            id="firstname"
            value={firstname}
            className={
              "input " +
              (firstname ? "hasValue " : " ") +
              (invalidFirstname ? "invalid" : "")
            }
            onChange={(e) => {
              if (errorMessage) {
                setErrorMessage("");
              }
              setFirstname(e.target.value);
            }}
            onFocus={() => {
              setInvalidFirstname(false);
            }}
          />
          <label for="name">
            Firstname <span className="input-required-indicator">*</span>
          </label>
          <div className="static-icon">
            <ProfileIcon style={{ stroke: "var(--light-4)", height: 14 }} />
          </div>
        </div>

        <div
          className="form-group"
          style={{ gridColumnStart: 2, gridColumnEnd: 3 }}
        >
          <input
            type="text"
            id="lastname"
            value={lastname}
            className={
              "input " +
              (lastname ? "hasValue " : " ") +
              (invalidLastname ? "invalid" : "")
            }
            onChange={(e) => {
              if (errorMessage) {
                setErrorMessage("");
              }
              setLastname(e.target.value);
            }}
            onFocus={() => {
              setInvalidLastname(false);
            }}
          />
          <label for="name">
            Lastname <span className="input-required-indicator">*</span>
          </label>
          <div className="static-icon">
            <ProfileIcon style={{ stroke: "var(--light-4)", height: 14 }} />
          </div>
        </div>
      </Row>

      {/* <div className="form-group">
        <input
          type="text"
          id="name"
          value={name}
          className={
            "input " +
            (name ? "hasValue " : " ") +
            (invalidName ? "invalid" : "")
          }
          onChange={(e) => {
            setName(e.target.value);
          }}
          onFocus={() => {
            setInvalidName(false);
          }}
        />
        <label for="name">
          Name <span className="input-required-indicator">*</span>
        </label>
        <div className="static-icon">
          <ProfileIcon style={{ stroke: "var(--light-4)", height: 14 }} />
        </div>
      </div> */}

      <div className="form-group">
        <input
          type="text"
          id="username"
          value={handle}
          className={
            "input " +
            (handle ? "hasValue " : " ") +
            (invalidHandle ? "invalid" : "")
          }
          onChange={(e) => {
            if (errorMessage) {
              setErrorMessage("");
            }

            if (handleError) {
              setHandleError(false);
            }

            setHandle(e.target.value?.replace(/ /g, "").replace(/@/g, ""));
          }}
          onFocus={() => {
            setInvalidHandle(false);
          }}
        />
        <label for="username">
          Pick a unique handle{" "}
          <span className="input-required-indicator">*</span>
        </label>
        <div className="static-icon">
          <AtIcon style={{ stroke: "var(--light-4)", height: 14 }} />
        </div>
        {handleError && (
          <div className="assistive">
            <div className="assistive-icon">
              <TimesCircleIcon
                style={{ height: "10px", stroke: "var(--red-main)" }}
              />
            </div>
            <div className="assistive-text">{handleError}</div>
          </div>
        )}
      </div>

      <div className="form-group">
        <input
          type="email"
          id="xyz"
          value={email}
          className={
            "input " +
            (email ? "hasValue " : " ") +
            (invalidEmail ? "invalid" : "")
          }
          onChange={(e) => {
            if (errorMessage) {
              setErrorMessage("");
            }
            setEmail(e.target.value);
          }}
          onFocus={() => {
            setInvalidEmail(false);
          }}
        />
        <label for="xyz">
          Email <span className="input-required-indicator">*</span>
        </label>
        <div className="static-icon">
          <MailIcon style={{ fill: "var(--light-4)", height: 14 }} />
        </div>
      </div>

      <div className="form-group">
        <input
          type={showPassword ? "text" : "password"}
          id="new-password"
          value={password}
          className={
            "input " +
            (password ? "hasValue " : " ") +
            (invalidPassword ? "invalid" : "")
          }
          onChange={(e) => {
            if (errorMessage) {
              setErrorMessage("");
            }
            setPassword(e.target.value);
          }}
          autoComplete="new-password"
          onFocus={() => {
            setInvalidPassword(false);
          }}
        />
        <label for="new-password">
          Password <span className="input-required-indicator">*</span>
        </label>
        <div className="static-icon">
          <LockIcon style={{ fill: "var(--light-4)", height: 14 }} />
        </div>
        <div
          className="static-icon-right"
          onClick={() => {
            setShowPassword((prev) => !prev);
          }}
        >
          <EyeIcon
            style={{
              fill: showPassword ? "var(--blue-main)" : "var(--light-4)",
              height: 14,
            }}
          />
        </div>
      </div>

      {errorMessage && (
        <Alert
          theme="red login-error"
          head={errorMessage}
          dismiss={() => {
            setErrorMessage("");
          }}
        />
      )}

      <DebouncedButton
        type="submit"
        className="app-button primary large green submit"
        loading={loading}
        // style={errorMessage ? { marginTop: "24px" } : {}}
      >
        {loading ? <Loader theme="small" /> : "Submit"}
      </DebouncedButton>

      {/* <button type="submit" className="app-button primary large green submit">
        {loading ? <Loader /> : "Submit"}
      </button> */}

      <div className="terms">
        By signing up, I agree to the{" "}
        <span className="hover-pointer text-link">Terms of Service</span> and{" "}
        <span className="hover-pointer text-link">Privacy Policy</span>
      </div>
    </form>
  );
}

export default Signup;
