import { useState, useCallback } from "react";
import SelectWithoutSearch from "../../SelectWithoutSearch";
import "./AddEventOrgRoles.css";
import Card from "../../Card";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import { PROFILE_SEARCH } from "../../../api/user";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_EVENT } from "../../../api/event";
import captureException from "../../../utils/captureException";
import debounce from "lodash.debounce";
import NewDropdown from "../../NewDropdown";
import Input from "../../Input";
import Spinner from "../../Spinner";
import Avatar from "../../Avatar";
import Button from "../../Button";
import Loader from "../../Loader";
import { useAppState } from "../../../utils/appState";

function SearchProfile({ setProfile }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(PROFILE_SEARCH);

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = data?.search?.profiles || [];

  let placeholderArray = ["profiles"];

  return (
    <NewDropdown
      wrapperClass="search"
      targetWrapperClass="search-wrapper"
      placement={"bottom-start"}
      menuStyle={{ width: "100%" }}
      target={
        <Input
          iconLeft={
            <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
          }
          placeholder={`Search for ${placeholderArray.join("/")}`}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e);
          }}
          useExternalValue={true}
          value={searchTerm}
        />
      }
      menu={({ setShowMenu }) => (
        <Card className="app-search-menu">
          {loading && <Spinner />}
          {!loading && !profiles?.length && (
            <div className="no-data">
              {!searchTerm ? (
                <div>
                  Search for {placeholderArray.join("/")} by name or handle
                </div>
              ) : (
                <div>No results found</div>
              )}
            </div>
          )}
          {!!profiles?.length && (
            <div className="search-results-section">
              <div className="search-results-section__title">PROFILES</div>
              <div className="search-results-section__items">
                {profiles?.map((o, i) => (
                  <div
                    key={"venue-search-item-" + o.id + i}
                    onClick={() => {
                      // if (submitting) return;
                      setShowMenu(false);
                      setSearchTerm("");
                      setProfile(o);
                      // updateEvent({
                      //   variables: {
                      //     where: {
                      //       id: ev.id,
                      //     },
                      //     data: {
                      //       roles: {
                      //         create: {
                      //           type: { connect: { name: roleType } },
                      //           profile: { connect: { id: o.id } },
                      //           relationshipsTo: {
                      //             create: {
                      //               from: {
                      //                 connect: { id: roleId },
                      //               },
                      //             },
                      //           },
                      //         },
                      //       },
                      //     },
                      //   },
                      // });
                      // setSelectedProfiles((prev) => [...prev, p]);
                    }}
                    className="search-results-section__items__item"
                  >
                    <div className="search-results-section__items__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>
                    <div className="search-results-section__items__item__desc">
                      <div className="search-results-section__items__item__desc__primary">
                        {o.name}
                      </div>
                      <div className="search-results-section__items__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Card>
      )}
    />
  );
}

function EditRoles({ ev, query, targets = {}, roleType, roleId }) {
  const [, setAppState] = useAppState();
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(query);

  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
        roles {
          id
          type {
            id
            name
          }        
          profile {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          organization {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          relationshipsFrom{
            id
            to{                
                id
                type {
                    id
                    name
                }        
                profile {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
                organization {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
            }
          }
        }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        setAppState({ modal: false });
        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  // const getSuggestions = (term) => {
  //   console.log("Firing query");
  //   if (term) {
  //     search({ variables: { term: term.trim() } });
  //   }
  // };

  // const debouncedGetSuggestions = useCallback(
  //   debounce((term) => getSuggestions(term), 500),
  //   { leading: false, trailing: true }
  // );

  const profiles = targets.profiles ? data?.search?.profiles : [];
  const organizations = targets.organizations
    ? data?.search?.organizations
    : [];
  let placeholderArray = [];

  if (targets.profiles) {
    placeholderArray.push("profiles");
  }

  if (targets.organizations) {
    placeholderArray.push("pages");
  }

  return (
    <NewDropdown
      wrapperClass="search"
      targetWrapperClass="search-wrapper"
      placement={"bottom-start"}
      menuStyle={{ width: "100%" }}
      target={
        <Input
          iconLeft={
            <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
          }
          placeholder={`Search for ${placeholderArray.join("/")}`}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e);
          }}
          useExternalValue={true}
          value={searchTerm}
        />
      }
      menu={({ setShowMenu }) => (
        <Card className="app-search-menu">
          {loading && <Spinner />}
          {!loading && !profiles?.length && !organizations?.length && (
            <div className="no-data">
              {!searchTerm ? (
                <div>
                  Search for {placeholderArray.join("/")} by name or handle
                </div>
              ) : (
                <div>No results found</div>
              )}
            </div>
          )}
          {targets.profiles && !!profiles?.length && (
            <div className="search-results-section">
              <div className="search-results-section__title">PROFILES</div>
              <div className="search-results-section__items">
                {profiles?.map((o, i) => (
                  <div
                    key={"venue-search-item-" + o.id + i}
                    onClick={() => {
                      if (submitting) return;
                      setShowMenu(false);
                      setSearchTerm("");
                      updateEvent({
                        variables: {
                          where: {
                            id: ev.id,
                          },
                          data: {
                            roles: {
                              create: {
                                type: { connect: { name: roleType } },
                                profile: { connect: { id: o.id } },
                                relationshipsTo: {
                                  create: {
                                    from: {
                                      connect: { id: roleId },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      });
                      // setSelectedProfiles((prev) => [...prev, p]);
                    }}
                    className="search-results-section__items__item"
                  >
                    <div className="search-results-section__items__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>
                    <div className="search-results-section__items__item__desc">
                      <div className="search-results-section__items__item__desc__primary">
                        {o.name}
                      </div>
                      <div className="search-results-section__items__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {targets.organizations && !!organizations?.length && (
            <div className="search-results-section">
              <div className="search-results-section__title">PAGES</div>
              <div className="search-results-section__items">
                {organizations?.map((o, i) => (
                  <div
                    key={"venue-search-item-" + o.id + i}
                    onClick={() => {
                      if (submitting) return;
                      setShowMenu(false);
                      setSearchTerm("");
                      updateEvent({
                        variables: {
                          where: {
                            id: ev.id,
                          },
                          data: {
                            roles: {
                              create: {
                                type: { connect: { name: roleType } },
                                organization: { connect: { id: o.id } },
                                relationshipsTo: {
                                  create: {
                                    from: {
                                      connect: { id: roleId },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      });
                      // setSelectedProfiles((prev) => [...prev, p]);
                    }}
                    className="search-results-section__items__item"
                  >
                    <div className="search-results-section__items__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>
                    <div className="search-results-section__items__item__desc">
                      <div className="search-results-section__items__item__desc__primary">
                        {o.name}
                      </div>
                      <div className="search-results-section__items__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Card>
      )}
    />
  );
}

export default function AddEventOrgRoles({ ev, participants }) {
  const [, setAppState] = useAppState();
  const [roleId, setRoleId] = useState("");
  const [description, setDescription] = useState("");
  const [profile, setProfile] = useState("");

  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
        roles {
          id
          description
          type {
            id
            name
          }        
          profile {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          organization {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          relationshipsFrom{
            id
            to{                
                id
                type {
                    id
                    name
                }        
                profile {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
                organization {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
            }
          }
        }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        setAppState({ modal: false });
        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <Card className="add-event-org-roles">
      <div className="add-event-org-roles__title">
        <div className="add-event-org-roles__title__text">Add Team Role</div>

        <div
          className="add-event-org-roles__title__dismiss-btn"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="add-event-org-roles__title__dismiss-btn__icon" />
        </div>
      </div>

      <div className="add-event-org-roles__form">
        <div className="add-event-org-roles__form__group">
          <div className="add-event-org-roles__form__group__label">
            Select Team
          </div>
          <div className="add-event-org-roles__form__group__input">
            <SelectWithoutSearch
              items={participants.map((p) => ({
                ...p,
                name: p.organization?.name,
              }))}
              placeholder={""}
              onItemSelect={(item) => {
                setRoleId(item.id);
              }}
            />
          </div>
        </div>

        <div className="add-event-org-roles__form__group">
          <div className="add-event-org-roles__form__group__label">
            Select Profile
          </div>
          <div className="add-event-org-roles__form__group__input">
            {profile ? (
              <div className="add-fixture-referee__form__group__input__selected-profile">
                <div className="add-fixture-referee__form__group__input__selected-profile__desc">
                  {profile.name}
                </div>

                <div
                  className="add-fixture-referee__form__group__input__selected-profile__dismiss"
                  onClick={() => {
                    setProfile(false);
                  }}
                >
                  <TimesIcon className="add-fixture-referee__form__group__input__selected-profile__dismiss__icon" />
                </div>
              </div>
            ) : (
              <SearchProfile setProfile={setProfile} />
            )}
          </div>
        </div>

        <div className="add-event-org-roles__form__group">
          <div className="add-event-org-roles__form__group__label">
            Description
          </div>
          <div className="add-event-org-roles__form__group__input">
            <Input
              placeholder={`eg., Manager, Coach, etc..`}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              useExternalValue={true}
              value={description}
            />
          </div>
        </div>

        <Button
          className="blue primary large add-fixture-referee__form__submit"
          onClick={() => {
            if (submitting || !roleId || !description || !profile?.id) return;

            updateEvent({
              variables: {
                where: {
                  id: ev.id,
                },
                data: {
                  roles: {
                    create: {
                      type: { connect: { name: "TECHNICAL STAFF" } },
                      description,
                      profile: { connect: { id: profile.id } },
                      relationshipsTo: {
                        create: {
                          from: {
                            connect: { id: roleId },
                          },
                        },
                      },
                    },
                  },
                },
              },
            });
          }}
        >
          {submitting ? <Loader theme={"small"} /> : "Submit"}
        </Button>
      </div>
    </Card>
  );
}
