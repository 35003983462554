import { gql } from "@apollo/client";

const ORGANIZATION_MEDIA = gql`
  query organizationQuery($id: ID!, $type: String, $take: Int, $cursor: ID) {
    organization(id: $id) {
      id
      media(type: $type, take: $take, cursor: $cursor) {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
      }
      mediaSets {
        id
        name
        preview {
          id
          filename
        }
        source {
          id
          fixture {
            id
            scheduledStart
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              organization {
                id
                name
              }
              profile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default ORGANIZATION_MEDIA;
