import { gql } from "@apollo/client";

const UPDATE_EVENT_REGISTRATION = gql`
  mutation updateEventReg(
    $where: UniqueInput!
    $data: UpdateEventRegistrationInput!
  ) {
    updateRegistration(where: $where, data: $data) {
      id
      amountPaid
      paid
      jerseyNum
      profile {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default UPDATE_EVENT_REGISTRATION;
