// V1
import "./EventStandingsSummary.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import Placeholder from "../../Placeholder/Placeholder";
import { useHistory, useLocation } from "react-router-dom";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";
import { Link } from "react-router-dom";
import { EVENT } from "../../../api/functions";

function EventStandingsSummary({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(
    EVENT({
      selectionSet: `
      standings(take: 1) {
        id
        createdAt
        title
        event {
          id
          name
        }
        eventPart {
          id
          label
        }
        items {
          id
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          order
          metas {
            id
            label
            shortLabel
            value
            stringValue
          }
        }
      }
    `,
    }),
    {
      variables: { id, take: 1 },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventStandingsSummary",
        query: "EVENT_STANDINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !true) {
    return (
      <Card className="event-standings-summary">
        <div className="card-title" style={{ paddingBottom: 0 }}>
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="standings-table">
          <div className="standings-list">
            <div className="table-header">
              <div className="row">
                <div className="td" style={{ width: "10%" }}>
                  <Placeholder
                    style={{ height: "12px", width: "20px" }}
                    pulse={true}
                  />
                </div>
                <div className="td" style={{ width: "70%" }}>
                  <Placeholder
                    style={{ height: "12px", width: "30px" }}
                    pulse={true}
                  />
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  <Placeholder
                    style={{ height: "12px", width: "20px" }}
                    pulse={true}
                  />
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  <Placeholder
                    style={{ height: "12px", width: "20px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
            <div className="table-body">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <div key={"ess-row-" + i} className="row">
                  <div className="td" style={{ width: "10%" }}>
                    <Placeholder
                      style={{ height: "12px", width: "20px" }}
                      pulse={true}
                    />
                  </div>
                  <div className="td" style={{ width: "70%" }}>
                    <div className="logo"></div>
                    <Placeholder
                      style={{ height: "12px", width: "80px" }}
                      pulse={true}
                    />
                  </div>
                  <div className="td number" style={{ width: "10%" }}>
                    <Placeholder
                      style={{ height: "12px", width: "20px" }}
                      pulse={true}
                    />
                  </div>

                  <div className="td number bold" style={{ width: "10%" }}>
                    <Placeholder
                      style={{ height: "12px", width: "20px" }}
                      pulse={true}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="event-standings-summary-footer">
          <div className="">
            <Placeholder
              style={{ height: "12px", width: "60px" }}
              pulse={true}
            />
          </div>
        </div>
      </Card>
    );
  }

  const standing = data?.event?.standings?.[0];
  // const items = [...standing?.items].splice(0, 3);

  return (
    <Card className="event-standings-summary">
      <div className="card-title" style={!standing ? { paddingBottom: 0 } : {}}>
        Standings
      </div>
      {standing ? (
        <div className="standings-table">
          <div className="standings-list">
            <div className="table-header">
              <div className="row">
                <div className="td" style={{ width: "10%" }}>
                  Pos
                </div>
                <div className="td" style={{ width: "70%" }}>
                  Team
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  GP
                </div>
                <div className="td number" style={{ width: "10%" }}>
                  P
                </div>
              </div>
            </div>
            <div className="table-body">
              {standing.items.map((item) => (
                <div key={"standings-summary-item-" + item.id} className="row">
                  <div className="td" style={{ width: "10%" }}>
                    {item.order + 1}
                  </div>
                  <div className="td" style={{ width: "70%" }}>
                    <div className="logo">
                      {(item.organization?.profilePhoto ||
                        item.profile?.profilePhoto) && (
                        <Avatar
                          media={
                            item.organization?.profilePhoto ||
                            item.profile?.profilePhoto
                          }
                        />
                      )}
                    </div>
                    <Link
                      to={`/app/${item.organization ? "page" : "profile"}/${
                        item.organization?.id || item.profile?.id
                      }`}
                    >
                      {item.organization?.name || item.profile?.name}
                    </Link>
                  </div>
                  <div className="td number" style={{ width: "10%" }}>
                    {item.metas.find((m) => m.label === "Games Played")?.value}
                  </div>

                  <div className="td number bold" style={{ width: "10%" }}>
                    {item.metas.find((m) => m.label === "Points")?.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">
          <div className="text">No standings updated yet.</div>
          <div
            className="link hover-pointer hover-underline"
            onClick={() => {
              refetch();
            }}
          >
            Check again
          </div>
        </div>
      )}

      {standing && (
        <div className="event-standings-summary-footer hover-pointer">
          <div
            className=""
            onClick={() => {
              history.replace(location.pathname + "?t=standings");
            }}
          >
            See full standings
          </div>
        </div>
      )}
    </Card>
  );
}

export default EventStandingsSummary;
