// V1
import { useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client";
import { CREATE_AWARD, DELETE_AWARD } from "../../../api/awards";
import { FIXTURE_AWARD_TYPES } from "../../../api/fixture";
import { SEARCH_EVENT_REGISTRATIONS } from "../../../api/user";
import Spinner from "../../Spinner";
import Card from "../../Card";
import NewDropdown from "../../NewDropdown";
import Input from "../../Input";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import "./FixtureAwards.css";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function AddEventAward({
  eventId,
  fixtureId,
  awardType,
  placementType,
  placeholder,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [search, { loading, error, data }] = useLazyQuery(
    SEARCH_EVENT_REGISTRATIONS
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureAwards.AddEventAward",
        query: "SEARCH_EVENT_REGISTRATIONS",
      },
    });
    console.log(error);
  }

  const [createAward, { loading: submitting }] = useMutation(CREATE_AWARD, {
    update: (cache, { data: { createAward } }) => {
      cache.modify({
        id: cache.identify(awardType),
        fields: {
          placements(existingPlacementRefs, { readField }) {
            const newPlacementRef = cache.writeFragment({
              data: createAward,
              fragment: gql`
                fragment NewPlacement on Placement {
                  id
                  profile {
                    id
                    name
                    profilePhoto {
                      id
                      filename
                    }
                  }
                  type {
                    id
                    name
                  }
                }
              `,
            });

            if (
              existingPlacementRefs.some(
                (ref) => readField("id", ref) === createAward.id
              )
            ) {
              return existingPlacementRefs;
            }

            return [newPlacementRef, ...existingPlacementRefs];
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FixtureAwards.AddEventAward",
          mutation: "CREATE_AWARD",
        },
      });
      console.log(error);
    },
  });

  const getSuggestions = (term) => {
    // console.log("Firing query");
    if (term) {
      search({ variables: { term: term.trim(), eventId } });
    }
  };

  const debouncedGetSuggestions = useCallback(
    debounce((term) => getSuggestions(term), 500),
    { leading: false, trailing: true }
  );

  const profiles = data?.searchEventRegistrations?.profiles;
  // console.log(data);

  return (
    <div className="add-event-award-form">
      <NewDropdown
        wrapperClass="search"
        targetWrapperClass="search-wrapper"
        placement={"bottom-start"}
        menuStyle={{ width: "100%" }}
        target={
          <Input
            iconLeft={
              <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
            }
            placeholder={placeholder || "Search for profiles"}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedGetSuggestions(e.target.value);
            }}
            useExternalValue={true}
            value={searchTerm}
          />
        }
        menu={({ setShowMenu }) => (
          <Card className="app-search-menu">
            {loading && <Spinner />}
            {!loading && !profiles?.length && (
              <div className="no-data">
                {!searchTerm ? (
                  <div>Search for profiles</div>
                ) : (
                  <div>No results found</div>
                )}
              </div>
            )}
            {!!profiles?.length && (
              <div className="search-results-section">
                <div className="search-results-section__title">PROFILES</div>
                <div className="search-results-section__items">
                  {profiles?.map((p) => (
                    <div
                      onClick={() => {
                        if (submitting) return;

                        setShowMenu(false);
                        setSearchTerm("");
                        createAward({
                          variables: {
                            data: {
                              fixture: { connect: { id: fixtureId } },
                              profile: { connect: { id: p.id } },
                              type: { connect: { name: placementType } },
                              awardType: { connect: { id: awardType.id } },
                            },
                          },
                        });
                      }}
                      className="search-results-section__items__item"
                    >
                      <div className="search-results-section__items__item__image"></div>
                      <div className="search-results-section__items__item__desc">
                        <div className="search-results-section__items__item__desc__primary">
                          {p.name}
                        </div>
                        <div className="search-results-section__items__item__desc__secondary">
                          @{p.handle}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Card>
        )}
      />
    </div>
  );
}

function FixtureAwardType({ awardType, eventId, fixtureId, canManageFixture }) {
  const [deleteAward, { loading }] = useMutation(DELETE_AWARD, {
    update: (cache, { data: { deleteAward } }) => {
      cache.modify({
        id: cache.identify(awardType),
        fields: {
          placements(existingPlacementRefs, { readField }) {
            return existingPlacementRefs.filter(
              (placementRef) => deleteAward.id !== readField("id", placementRef)
            );
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FixtureAwards.FixtureAwardType",
          mutation: "DELETE_AWARD",
        },
      });
      console.log(error);
    },
  });
  const profile = awardType?.placements?.[0]?.profile;

  if (!profile && !canManageFixture) {
    return <div />;
  }

  return (
    <div className="fixture-awards-card__award-type">
      <div className="fixture-awards-card__award-type__img">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="fixture-awards-card__award-type__desc">
        <div className="fixture-awards-card__award-type__desc__primary">
          {awardType.name.toUpperCase()}
        </div>

        <div className="fixture-awards-card__award-type__desc__secondary">
          {profile?.name || ""}
          {!profile && canManageFixture && (
            <AddEventAward
              eventId={eventId}
              fixtureId={fixtureId}
              awardType={awardType}
              placementType={"AWARD"}
              placeholder={"Search"}
            />
          )}
        </div>

        {profile && (
          <div className="fixture-awards-card__award-type__desc__tertiary">
            @ {profile.handle}
          </div>
        )}
      </div>
      {profile && canManageFixture && (
        <div
          className="fixture-awards-card__award-type__option"
          onClick={() => {
            if (loading) return;

            deleteAward({
              variables: { id: awardType.placements[0].id },
            });
          }}
        >
          <TrashIcon className="fixture-awards-card__award-type__option__icon" />
        </div>
      )}
    </div>
  );
}

export default function FixtureAwards({ id, eventId, canManageFixture }) {
  const { loading, error, data } = useQuery(FIXTURE_AWARD_TYPES, {
    variables: {
      where: { event: { id: eventId }, subtype: "FIXTURE" },
      placementsWhere: { type: { name: "AWARD" }, fixture: { id } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureAwards",
        query: "FIXTURE_AWARD_TYPES",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const awardTypes = data?.awardTypes;

  if (!awardTypes?.length) {
    return <div />;
  }

  const placementsCount = data?.awardTypes.reduce((accum, curr) => {
    return accum + (curr.placements?.length || 0);
  }, 0);

  // console.log(awardTypes);

  if (!placementsCount && !canManageFixture) {
    return <div />;
  }

  return (
    <Card className="fixture-awards-card">
      <div className="fixture-awards-card__title">Awards</div>
      {awardTypes?.map((at) => (
        <FixtureAwardType
          awardType={at}
          eventId={eventId}
          fixtureId={id}
          canManageFixture={canManageFixture}
        />
      ))}
    </Card>
  );
}
