// V1
import { useState } from "react";
import "./OrganizationServicesSummary.css";
import Card from "../../../components/Card";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_SERVICES } from "../../../api/organization";
import { useHistory, useLocation } from "react-router-dom";
import Placeholder from "../../Placeholder";
import captureException from "../../../utils/captureException";

function SummaryServiceItem({ service }) {
  const [truncated] = useState(
    service.description?.length > 100 ? true : false
  );
  return (
    <div className="organization-overview-service-card">
      <div className="image"></div>
      <div className="text">
        <div className="primary-text">{service.name}</div>
        <div className="secondary-text">
          {service.description?.length < 100
            ? service.description
            : truncated
            ? service.description.substring(0, 100) + "..."
            : service.description}
        </div>
      </div>
    </div>
  );
}

function OrganizationServicesSummary() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data } = useQuery(ORGANIZATION_SERVICES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationServicesSummary",
        query: "ORGANIZATION_SERVICES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="organization-overview-services-card">
        <div className="card-title">
          <Placeholder style={{ width: "80px" }} pulse={true} />
        </div>
        <div className="organization-overview-services-list">
          <div className="organization-overview-service-card">
            <div className="image"></div>
            <div className="text" style={{ flexGrow: 1 }}>
              <div className="primary-text">
                <Placeholder style={{ width: "80px" }} pulse={true} />
              </div>
              <Placeholder
                style={{ width: "100%", marginTop: "8px" }}
                pulse={true}
              />
            </div>
          </div>

          <div className="organization-overview-service-card">
            <div className="image"></div>
            <div className="text" style={{ flexGrow: 1 }}>
              <div className="primary-text">
                <Placeholder style={{ width: "80px" }} pulse={true} />
              </div>
              <Placeholder
                style={{ width: "100%", marginTop: "8px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
        <div className="organization-overview-services-card-footer">
          <Placeholder style={{ width: "100%" }} pulse={true} />
        </div>
      </Card>
    );
  }

  const org = data?.organization;
  const services = org?.services;

  return (
    <Card className="organization-overview-services-card">
      <div className="card-title">Services</div>
      <div className="organization-overview-services-list">
        {services.map((s) => (
          <SummaryServiceItem service={s} key={s.id} />
        ))}

        {!services?.length && (
          <div className="no-data">
            <div>No services listed for this page.</div>
          </div>
        )}
      </div>
      {!!services?.length && (
        <div
          className="organization-overview-services-card-footer"
          onClick={() => {
            history.replace(location.pathname + "?t=services");
          }}
        >
          <div className="">See More</div>
        </div>
      )}
    </Card>
  );
}

export default OrganizationServicesSummary;
