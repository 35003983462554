import "./ManageEventRegistrations.css";
import Card from "../../Card";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../Spinner";
import {
  EVENT_REGISTRATIONS,
  EVENT_PARTICIPANTS,
  UPDATE_EVENT_REGISTRATION,
} from "../../../api/event";
import { useState } from "react";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import SelectWithoutSearch from "../../SelectWithoutSearch";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function TableRow({ id, r, participantType }) {
  const [editing, setEditing] = useState(false);
  const [team, setTeam] = useState(r.organization);
  const [amountPaid, setAmountPaid] = useState(r.amountPaid);
  const [jerseyNum, setJerseyNum] = useState(r.jerseyNum);
  const [amountPaidError, setAmountPaidError] = useState(false);
  const [paid, setPaid] = useState(r.paid);

  const [updateEventRegistration, { loading }] = useMutation(
    UPDATE_EVENT_REGISTRATION,
    {
      update: (cache, { data: { updateRegistration } }) => {
        const q = cache.readQuery({
          query: EVENT_REGISTRATIONS,
          variables: { id },
        });

        const itemIndex = q.event.registrations.findIndex((x) => x.id === r.id);

        if (itemIndex > -1) {
          const newRegistrations = [...q.event.registrations];
          newRegistrations[itemIndex] = updateRegistration;
          cache.writeQuery({
            query: EVENT_REGISTRATIONS,
            variables: { id },
            data: {
              event: {
                ...q.event,
                registrations: newRegistrations,
              },
            },
          });
        }

        setEditing(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRegistrations.TableRow",
            mutation: "UPDATE_EVENT_REGISTRATION",
          },
        });
        console.log(error);
      },
    }
  );

  if (editing) {
    return (
      <div className="registrations-table__row">
        <div
          className="registrations-table__row__td with-image"
          style={{ width: "25%" }}
        >
          <div className="td-image">
            {r.profile?.profilePhoto && (
              <Avatar media={r.profile?.profilePhoto} />
            )}
          </div>
          {r.profile.name}
        </div>
        {participantType === "TEAM" && (
          <div
            className="registrations-table__row__td"
            style={{ width: "25%", paddingRight: 0 }}
          >
            <SelectWithoutSearch
              placeholder={"Select team"}
              query={EVENT_PARTICIPANTS}
              variables={{ id }}
              items={(d) => d.event?.participants?.map((r) => r.organization)}
              onItemSelect={(item) => {
                setTeam(item);
              }}
              clearOnItemSelect={false}
              defaultValue={r.organization}
              disableSearch={true}
            />
          </div>
        )}

        <div
          className="registrations-table__row__td"
          style={{ width: "10%", paddingRight: 0, paddingLeft: "16px" }}
        >
          <SelectWithoutSearch
            placeholder={"Select"}
            items={[
              { name: "YES", value: true },
              { name: "NO", value: false },
            ]}
            onItemSelect={(item) => {
              setPaid(item);
            }}
            clearOnItemSelect={false}
            defaultValue={
              r.paid
                ? { name: "YES", value: true }
                : { name: "NO", value: false }
            }
          />
        </div>
        <div
          className="registrations-table__row__td"
          style={{ width: "15%", paddingRight: 0, paddingLeft: "16px" }}
        >
          <input
            type="number"
            value={amountPaid}
            onChange={(e) => {
              setAmountPaid(e.target.value);
            }}
            onFocus={() => {
              setAmountPaidError(false);
            }}
            className={"number-input " + (amountPaidError ? "error" : "")}
          />
        </div>

        {participantType === "TEAM" && (
          <div
            className="registrations-table__row__td"
            style={{ width: "10%", paddingRight: 0, paddingLeft: "16px" }}
          >
            <input
              type="number"
              value={jerseyNum}
              onChange={(e) => {
                setJerseyNum(e.target.value);
              }}
              className={"number-input "}
            />
          </div>
        )}

        <div className="registrations-table__row__td" style={{ width: "15%" }}>
          <div className="options">
            <button
              className="btn success"
              onClick={() => {
                if (loading) return;
                if (paid.value && !Number(amountPaid)) {
                  setAmountPaidError(true);
                  return;
                }
                updateEventRegistration({
                  variables: {
                    where: { id: r.id },
                    data: {
                      ...(team ? { organizationId: team.id } : {}),
                      amountPaid: amountPaid ? Number(amountPaid) : 0,
                      paid: paid.value || false,
                      ...(jerseyNum ? { jerseyNum: Number(jerseyNum) } : {}),
                    },
                  },
                });
              }}
            >
              <CheckIcon className="icon" />
            </button>
            <button
              className="btn cancel"
              onClick={() => {
                setEditing(false);
              }}
            >
              <TimesIcon className="icon" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="registrations-table__row">
      <div
        className="registrations-table__row__td with-image"
        style={{ width: "25%" }}
      >
        <div className="td-image">
          {r.profile?.profilePhoto && <Avatar media={r.profile.profilePhoto} />}
        </div>
        {r.profile.name}
      </div>
      {participantType === "TEAM" && (
        <div
          className="registrations-table__row__td with-image"
          style={{ width: "25%" }}
        >
          <div className="td-image">
            {r.organization?.profilePhoto && (
              <Avatar media={r.organization.profilePhoto} />
            )}
          </div>
          {r.organization?.name}
        </div>
      )}

      <div
        className="registrations-table__row__td"
        style={{ width: "10%", justifyContent: "center" }}
      >
        {r.paid ? "YES" : "NO"}
      </div>

      <div
        className="registrations-table__row__td"
        style={{ width: "15%", justifyContent: "flex-end" }}
      >
        {r.amountPaid}
      </div>
      {participantType === "TEAM" && (
        <div
          className="registrations-table__row__td"
          style={{ width: "10%", justifyContent: "flex-end" }}
        >
          {r.jerseyNum || "-"}
        </div>
      )}

      <div className="registrations-table__row__td" style={{ width: "15%" }}>
        <div
          className="edit-btn"
          onClick={() => {
            setEditing(true);
          }}
        >
          EDIT
        </div>
      </div>
    </div>
  );
}

function ManageEventRegistrations({ id }) {
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventRegistrations",
        query: "EVENT_REGISTRATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const registrations = ev?.registrations || [];
  return (
    <Card className="manage-event-registrations">
      <div className="card-title">Registrations</div>

      <div className="registrations-table">
        <div className="registrations-table__row header">
          <div
            className="registrations-table__row__td"
            style={{ width: "25%" }}
          >
            Profile
          </div>
          {ev.participantType === "TEAM" && (
            <div
              className="registrations-table__row__td"
              style={{ width: "25%" }}
            >
              Team
            </div>
          )}

          <div
            className="registrations-table__row__td"
            style={{ width: "10%", justifyContent: "center" }}
          >
            Paid?
          </div>

          <div
            className="registrations-table__row__td"
            style={{ width: "15%", justifyContent: "flex-end" }}
          >
            Amount Paid
          </div>
          {ev.participantType === "TEAM" && (
            <div
              className="registrations-table__row__td"
              style={{ width: "10%", justifyContent: "flex-end" }}
            >
              Jersey
            </div>
          )}

          <div
            className="registrations-table__row__td"
            style={{ width: "15%" }}
          ></div>
        </div>
        <div className="registrations-table__body">
          {registrations.map((r, i) => (
            <TableRow
              key={r.id}
              r={r}
              id={id}
              participantType={ev.participantType}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

export default ManageEventRegistrations;
