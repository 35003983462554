import { gql } from "@apollo/client";

const CRICKET_STATS = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      placements {
        id
        value
        valueString
        organization {
          id
          name
        }
      }
      metas {
        id
        label
        value
        stringValue
      }
      statTables {
        id
        order
        organization {
          id
        }
        profile {
          id
          name
        }
        statType {
          id
          name
        }
        value
      }
      stats {
        id
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
        }
        organization {
          id
          name
        }
        fixturePeriod {
          id
        }
        relationshipsFrom {
          id
          to {
            id
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
          }
        }
        relationshipsTo {
          id
          from {
            id
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default CRICKET_STATS;
