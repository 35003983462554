import "./Settings.css";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import PeriodSettings from "../PeriodSettings";
import LineupSettings from "../LineupSettings";
import PenaltyShootoutSettings from "../PenaltyShootoutSettings";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";

export default function Settings({ id, setStep }) {
  const history = useHistory();
  return (
    <div className="ScoringApp-football__settings">
      <div className="ScoringApp-football__header">
        <div className="ScoringApp-football__header__nav-left">
          <div
            className="ScoringApp-football__header__nav-left__back-btn"
            onClick={() => {
              history.replace("/app/fixture/" + id);
            }}
          >
            <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
            <div className="ScoringApp-football__header__nav-left__back-btn__text">
              Back to fixture
            </div>
          </div>
        </div>

        <div className="ScoringApp-football__header__main">Match Settings</div>

        <div className="ScoringApp-football__header__nav-right">
          <Button
            className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
            onClick={() => {
              setStep(1);
            }}
          >
            Proceed to collect stats
          </Button>
          {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
        </div>
      </div>

      <div className="ScoringApp-football__body">
        <div className="ScoringApp-football__settings__grid">
          <div className="ScoringApp-football__settings__grid__left-column">
            <PeriodSettings id={id} />
            <PenaltyShootoutSettings id={id} />
          </div>

          <div className="ScoringApp-football__settings__grid__right-column">
            <LineupSettings id={id} />
          </div>
        </div>
      </div>
    </div>
  );
}
