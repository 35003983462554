// V1
import { Fragment } from "react";
import "./EventItem.css";
import Card from "../../Card";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as PinIcon } from "../../../icons/pin.svg";
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg";
import moment from "moment";
import Avatar from "../../../components/Avatar";

function EventItem({ ev, showDate = true, participantCount = 4 }) {
  const history = useHistory();

  return (
    <Card className="event-item-card">
      <div className="head">
        <div className="image">
          {ev.profilePhoto && <Avatar media={ev.profilePhoto} />}
        </div>
        <div className="meta">
          <Link to={"/app/event/" + ev.id} className="primary">
            {ev.name}
          </Link>
          <div className="secondary">
            <div className="icon">
              <PinIcon className="meta-icon" />
            </div>
            <div className="text">
              {ev.venueLabel
                ? ev.venueLabel + (ev.city?.value ? ", " : "")
                : ""}{" "}
              {ev.city?.value || ""}
              {!ev.venueLabel && !ev.city?.value && "TBD"}
            </div>
          </div>
        </div>
        {showDate && (
          <div className="date-wrapper">
            <div className="date">
              {ev.scheduledStart ? (
                <Fragment>
                  <div className="month">
                    {moment(ev.scheduledStart).format("MMM")}
                  </div>
                  <div className="day">
                    {moment(ev.scheduledStart).format("DD")}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="month placeholder">
                    <CalendarIcon className="calendar-placeholder-icon" />
                  </div>
                  <div className="day placeholder">TBD</div>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="event-item-card-body">
        {/* {ev.coverPhoto && (
          <div className={"image " + (ev.coverPhoto ? "black" : "")}>
            <img src={makeFileUrl(ev.coverPhoto)} />
            <div className="overlay" />
          </div>
        )} */}
        {ev.description && <div className="blurb">{ev.description}</div>}
      </div>

      <div className="foot">
        <div className="followers">
          {!!ev.registrationsCount && (
            <div
              className="more"
              onClick={() => {
                history.push("/app/event/" + ev.id);
              }}
            >
              {ev.registrationsCount} Players
            </div>
          )}

          {!!ev.participantsCount && (
            <div
              className="more"
              onClick={() => {
                history.push("/app/event/" + ev.id);
              }}
            >
              {ev.participantsCount} Teams
            </div>
          )}
        </div>
        <div
          className="organizedBy"
          onClick={() => {
            history.push("/app/page/" + ev.organizedBy?.id);
          }}
        >
          <div className="desc">
            <div className="primary">{ev.organizedBy?.name}</div>
            <div className="secondary">Organizer</div>
          </div>
          <div className={"image " + (ev.organizedBy?.profilePhoto ? "" : "")}>
            {ev.organizedBy?.profilePhoto && (
              <Avatar media={ev.organizedBy.profilePhoto} />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default EventItem;
