// V!
import "./EventDescription.css";
import { useQuery } from "@apollo/client";
import Card from "../../Card";
import moment from "moment";
// import { EVENT } from "../../../api/event";
import MapContainer from "../../Map";
import Placeholder from "../../Placeholder/Placeholder";
import captureException from "../../../utils/captureException";
import { EVENT } from "../../../api/functions";

function EventDescription({ id }) {
  // console.log("EVENT DESC ID:", id);
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
      scheduledStart
      scheduledEnd
      registrationEnd
      registrationAmount
      description
      venueLabel
      venueValue
      venueLat
      venueLng
      city {
        id
        lat
        lng
      }
    `,
    }),
    { variables: { id } }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventDescription",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="event-description-card">
        <div className="meta">
          <div className="event-details">
            <div className="blurb">
              <Placeholder
                style={{ height: "12px", width: "200px" }}
                pulse={true}
              />
            </div>

            <div className="info-group">
              <div className="info-group-key">
                <Placeholder
                  style={{ height: "12px", width: "40px" }}
                  pulse={true}
                />
              </div>
              <div className="info-group-value">
                <Placeholder
                  style={{ height: "12px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>

            <div className="info-group">
              <div className="info-group-key">
                <Placeholder
                  style={{ height: "12px", width: "40px" }}
                  pulse={true}
                />
              </div>
              <div className="info-group-value">
                <Placeholder
                  style={{ height: "12px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>

            <div className="info-group">
              <div className="info-group-key">
                <Placeholder
                  style={{ height: "12px", width: "40px" }}
                  pulse={true}
                />
              </div>
              <div className="info-group-value">
                <Placeholder
                  style={{ height: "12px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>

            <div className="info-group">
              <div className="info-group-key">
                <Placeholder
                  style={{ height: "12px", width: "40px" }}
                  pulse={true}
                />
              </div>
              <div className="info-group-value">
                <Placeholder
                  style={{ height: "12px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>

          <div className="event-venue">
            <div className="map">
              <Placeholder style={{ height: "100%", width: "100%" }} />
            </div>
            <div className="org">
              <div className="image"></div>

              <div className="desc">
                <div className="primary-text">
                  <Placeholder
                    style={{
                      height: "12px",
                      width: "60px",
                      marginBottom: "8px",
                    }}
                    pulse={true}
                  />
                </div>
                <div className="secondary-text">
                  <Placeholder
                    style={{ height: "12px", width: "200px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
  // console.log("EVENT DESC DATA:", data);

  const ev = data?.event;
  const hideRegistrationDetails = !ev.scheduledStart
    ? false
    : moment().isAfter(ev.scheduledStart)
    ? true
    : false;
  return (
    <Card className="event-description-card">
      <div className="meta">
        <div className="event-details">
          <div className="blurb">
            {ev.description || "No event description added yet."}
          </div>

          <div className="info-group">
            <div className="info-group-key">Start Date</div>
            <div className="info-group-value">
              {ev.scheduledStart
                ? moment(ev.scheduledStart).format("Do MMM YYYY")
                : "TBD"}
            </div>
          </div>

          <div className="info-group">
            <div className="info-group-key">End Date</div>
            <div className="info-group-value">
              {ev.scheduledEnd
                ? moment(ev.scheduledEnd).format("Do MMM YYYY")
                : "TBD"}
            </div>
          </div>

          {!hideRegistrationDetails && (
            <div className="info-group">
              <div className="info-group-key">Registration End Date</div>
              <div className="info-group-value">
                {ev.registrationEnd
                  ? moment(ev.registrationEnd).format("Do MMM YYYY")
                  : "TBD"}
              </div>
            </div>
          )}

          {!hideRegistrationDetails && (
            <div className="info-group">
              <div className="info-group-key">Registration Amount</div>
              <div className="info-group-value">
                {ev.registrationAmount ? "INR " + ev.registrationAmount : "TBD"}
              </div>
            </div>
          )}
        </div>

        <div className="event-venue">
          <div className="map">
            <MapContainer
              venue={{
                label: ev.venueLabel,
                value: ev.venueValue,
                lat: ev.venueLat || ev.city?.lat,
                lng: ev.venueLng || ev.city?.lng,
              }}
            />
          </div>
          <div className="org">
            {!ev.venueLabel && <div className="image"></div>}

            <div className="desc">
              <div className="primary-text">{ev.venueLabel || "Venue TBD"}</div>
              <div className="secondary-text">{ev.venueValue}</div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default EventDescription;
