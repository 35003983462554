import { gql } from "@apollo/client";

const DELETE_MEDIA_SET = gql`
  mutation deleteMediaSet($id: ID!) {
    deleteMediaSet(id: $id) {
      id
    }
  }
`;

export default DELETE_MEDIA_SET;
