import "./RequestsNotification.css";
import { USER_REQUESTS } from "../../../api/user";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import captureException from "../../../utils/captureException";

export default function RequestsNotification() {
  const { error, data } = useQuery(USER_REQUESTS, {
    fetchPolicy: "network-only",
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "RequestsNotification",
        query: "USER_REQUESTS",
      },
    });
    console.log(error);
  }
  const requests = data?.userRequests;
  const count = requests?.length;
  // console.log("REQUESTS:", requests);
  if (!count) {
    return <div />;
  }

  return (
    <div className="home-page-mobile__profile-card__profile-requests">
      <div className="home-page-mobile__profile-card__profile-requests__text">
        You have {count} new request{count > 1 ? "s" : ""}
      </div>

      <Link
        to="/app/requests"
        className="home-page-mobile__profile-card__profile-requests__link"
      >
        View
      </Link>
    </div>
  );
}
