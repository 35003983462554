// V1
import "./OrganizationEvents.css";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Placeholder from "../Placeholder";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_EVENTS } from "../../api/organization";
import { SPORTS } from "../../api/sport";
import { EventItem } from "../Events";
import { useState } from "react";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import Loader from "../Loader";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import captureException from "../../utils/captureException";

function EventItemLoading() {
  return (
    <Card className="event-item-card">
      <div className="head">
        <div className="image"></div>
        <div className="meta">
          <div className="primary">
            <Placeholder
              style={{ height: "14px", width: "120px" }}
              pulse={true}
            />
          </div>
          <div className="secondary">
            <Placeholder
              style={{ width: "60px", marginTop: "8px" }}
              pulse={true}
            />
          </div>
        </div>
        <div className="date-wrapper">
          <div className="date"></div>
        </div>
      </div>
      <div className="event-item-card-body"></div>

      <div className="foot">
        <div className="followers">
          <div className="more" style={{ width: "80px" }}></div>
        </div>

        <div className="organizedBy">
          <div className="desc">
            <div className="primary">
              <Placeholder style={{ width: "100px" }} pulse={true} />
            </div>
            <div className="secondary">
              <Placeholder
                style={{ width: "60px", marginTop: "4px" }}
                pulse={true}
              />
            </div>
          </div>
          <div className={"image "}></div>
        </div>
      </div>
    </Card>
  );
}

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function OrganizationEvents() {
  const { id } = useParams();
  const [sportsFilter, setSportsFilter] = useState([]);
  const { error, data, refetch, variables, fetchMore, networkStatus } =
    useQuery(ORGANIZATION_EVENTS, {
      variables: { id, takeRegistrations: 4, take: 5 },
      notifyOnNetworkStatusChange: true,
    });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationEvents",
        query: "ORGANIZATION_EVENTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div className="profile-events">
        <div className="left-column">
          <div className="profile-events-list">
            <EventItemLoading />
            <EventItemLoading />
            <EventItemLoading />
          </div>
        </div>
        <div className="right-column"></div>
      </div>
    );
  }

  const org = data?.organization;
  const events = org?.events;
  return (
    <div className="organization-events">
      <div className="left-column">
        {!!events?.length && (
          <div className="organization-events-list">
            {events.map((ev) => (
              <EventItem key={ev.id} ev={ev} />
            ))}
            <div className="show-more-container">
              <Button
                className="primary small blue show-more-button"
                onClick={() => {
                  fetchMore({
                    variables: {
                      cursor: events[events.length - 1].id,
                    },
                  });
                }}
              >
                {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={"Enter a sport"}
            query={SPORTS}
            items={(d) => {
              return d?.sports;
            }}
            addFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  sports: sports.map((s) => s.name),
                },
              });
            }}
            removeFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  sports: sports.map((s) => s.name),
                },
              });
            }}
            defaultValue={sportsFilter}
            appStateKey={"sportsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default OrganizationEvents;
