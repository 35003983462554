import "./FootballStats.css";
import { useQuery } from "@apollo/client";
import { PROFILE_STATS, PROFILE_AWARDS } from "../../../../api/profile";
import Square from "../../../Square";
import PieChart from "../../../../components/PieChart";
import Pitch from "../../../../components/Pitch";
import VerticalText from "../../../VerticalText";
import captureException from "../../../../utils/captureException";

function ProfileAttackStats({
  appearances,
  goals,
  assists,
  keyPasses,
  shotsPerGame,
  minutesPerGame,
}) {
  return (
    <div className="profile-attack-stats-mobile">
      <div className="profile-attack-stats-mobile__title">ATTACK</div>
      <div className="profile-attack-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {appearances}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Appearances
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {goals}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Goals
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {assists}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Assists
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {keyPasses}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Key Passes
              </div>
            </div>
          )}
        </Square>

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {minutesPerGame}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Mins / Game
              </div>
            </div>
          )}
        </Square> */}

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {shotsPerGame}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Shots / Game
              </div>
            </div>
          )}
        </Square> */}
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats-mobile__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"ATTACK"}
              fontStyle={{
                fontSize: "76px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "57px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileShootingStats({
  goals,
  totalShots,
  shotsOnTarget,
  shotsOffTarget,
}) {
  return (
    <div className="profile-shooting-stats-mobile">
      <div className="profile-shooting-stats-mobile__title">SHOOTING</div>

      <div className="profile-shooting-stats-mobile__items">
        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              Total Shots
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {totalShots || 0}
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: totalShots ? "100%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              Shots On Target
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {shotsOnTarget || 0}
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{
                  width: totalShots
                    ? (shotsOnTarget / totalShots) * 100 + "%"
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              Shots Off Target
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {shotsOffTarget || 0}
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{
                  width: totalShots
                    ? (shotsOffTarget / totalShots) * 100 + "%"
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {!!totalShots && (
        <div className="profile-shooting-stats-mobile__doughnut">
          <div className="profile-shooting-stats-mobile__doughnut__bg">
            <PieChart
              value={shotsOnTarget}
              total={shotsOffTarget || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={shotsOnTarget}
            total={shotsOffTarget || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats-mobile__doughnut__meta">
            <div className="profile-shooting-stats-mobile__doughnut__meta__value">
              {Math.round((shotsOnTarget / totalShots) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats-mobile__doughnut__meta__key">
              Shot <br /> Accuracy
            </div>
          </div>
        </div>
      )}

      {!!totalShots && (
        <div className="profile-shooting-stats-mobile__doughnut">
          <div className="profile-shooting-stats-mobile__doughnut__bg">
            <PieChart
              value={shotsOnTarget}
              total={shotsOffTarget || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={goals}
            total={shotsOffTarget || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats-mobile__doughnut__meta">
            <div className="profile-shooting-stats-mobile__doughnut__meta__value">
              {Math.round((goals / totalShots) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats-mobile__doughnut__meta__key">
              Conversion <br /> Rate
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ProfilePassingStats({ successfulPasses, attemptedPasses }) {
  return (
    <div className="profile-passing-stats-mobile">
      <div className="profile-passing-stats-mobile__title">PASSING</div>
      <div className="profile-passing-stats-mobile__items">
        <div className="profile-passing-stats-mobile__items__item">
          <div className="profile-passing-stats-mobile__items__item__meta">
            <div className="profile-passing-stats-mobile__items__item__meta__key">
              Attempted Passes
            </div>

            <div className="profile-passing-stats-mobile__items__item__meta__value">
              {attemptedPasses || 0}
            </div>
          </div>

          <div className="profile-passing-stats-mobile__items__item__graph">
            <div className="profile-passing-stats-mobile__items__item__graph__track">
              <div
                className="profile-passing-stats-mobile__items__item__graph__fill"
                style={{
                  width: attemptedPasses
                    ? (successfulPasses / attemptedPasses) * 100 + "%"
                    : "1%",
                }}
              ></div>
            </div>
          </div>

          <div className="profile-passing-stats-mobile__items__item__meta">
            <div className="profile-passing-stats-mobile__items__item__meta__key">
              Successful Passes
            </div>

            <div className="profile-passing-stats-mobile__items__item__meta__value">
              {successfulPasses || 0}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-passing-stats-mobile__doughnut">
        <div className="profile-passing-stats-mobile__doughnut__bg">
          <PieChart
            value={successfulPasses || 0}
            total={
              attemptedPasses ? attemptedPasses - successfulPasses || 0 : 1
            }
            colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
          />
        </div>
        <PieChart
          value={successfulPasses || 0}
          total={attemptedPasses ? attemptedPasses - successfulPasses || 0 : 1}
          colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
        />
        <div className="profile-passing-stats-mobile__doughnut__meta">
          <div className="profile-passing-stats-mobile__doughnut__meta__value">
            {attemptedPasses
              ? Math.round((successfulPasses / attemptedPasses) * 100) + "%"
              : "-"}
          </div>

          <div className="profile-passing-stats-mobile__doughnut__meta__key">
            Pass <br /> Accuracy
          </div>
        </div>
      </div>

      <Square>
        {({ squareRef, dimensions }) => (
          <div
            className="profile-passing-stats-mobile__graphic"
            ref={squareRef}
          >
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"PASSING"}
              fontStyle={{
                fontSize: "64px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "49px",
                height: "64px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfilePositionsPlayed({ positions }) {
  return (
    <div className="profile-positions-played-mobile">
      <div className="profile-positions-played-mobile__title">
        POSITIONS PLAYED
      </div>

      <div className="profile-positions-played-mobile__pitch">
        <Pitch
          markers={
            positions?.map((p) => ({
              ...p,
              label: p.positionType.shortName || "",
            })) || []
          }
        />
      </div>
    </div>
  );
}

function ProfileDefenseStats({
  tackles,
  blocks,
  saves,
  ownGoals,
  redCards,
  yellowCards,
  fouls,
  offsides,
  interceptions,
  clearances,
}) {
  return (
    <div className="profile-attack-stats-mobile">
      <div className="profile-attack-stats-mobile__title">DEFENSE</div>
      <div className="profile-attack-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {tackles}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Tackles
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {blocks}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Blocks
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {saves}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Saves
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {ownGoals}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Own Goals
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {redCards}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Red Cards
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {yellowCards}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Yellow Cards
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {fouls}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Fouls
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {offsides}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Offsides
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {interceptions}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Interceptions
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {clearances}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Clearances
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats-mobile__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"DEFENSE"}
              fontStyle={{
                fontSize: "76px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "57px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileParticipationHistory({ participationHistory = [] }) {
  const rows = participationHistory.map((p) => ({
    ...p,
    stats: JSON.parse(p.stats.data),
  }));
  return (
    <div className="profile-participation-stats-mobile">
      <div className="profile-participation-stats-mobile__title">
        PARTICIPATION HISTORY
      </div>

      <div className="profile-participation-stats-mobile__table">
        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "50%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header with-image">
            Event
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td with-image " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {/* <div className="profile-participation-stats-mobile__table__td__image"></div> */}
                <div className="profile-participation-stats-mobile__table__td__meta">
                  <div className="profile-participation-stats-mobile__table__td__meta__primary">
                    {r.event?.name}
                  </div>

                  <div className="profile-participation-stats-mobile__table__td__meta__secondary">
                    {r.organization?.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            GP
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Appearances"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            G
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Goal"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            A
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Assist"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            RC
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Red Card"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats-mobile__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats-mobile__table__column__header">
            YC
          </div>

          <div className="profile-participation-stats-mobile__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats-mobile__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Yellow Card"] || 0}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileAwards({ id, sport }) {
  const { loading, error, data } = useQuery(PROFILE_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.FootballStats.ProfileAwards",
        query: "PROFILE_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const profile = data.profile;
  const placements = profile?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats-mobile">
      <div className="profile-awards-stats-mobile__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats-mobile__items">
          <div className="no-data">No awards for this player yet.</div>
        </div>
      )}

      <div className="profile-awards-stats-mobile__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats-mobile__items__item">
              <div className="profile-awards-stats-mobile__items__item__img"></div>
              <div className="profile-awards-stats-mobile__items__item__desc">
                <div className="profile-awards-stats-mobile__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats-mobile__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function FootballStats({ id, events }) {
  const { loading, error, data } = useQuery(PROFILE_STATS, {
    variables: {
      id,
      sport: "Football",
      statTypes: [
        "Goal",
        "Assist",
        "Key Pass",
        "Shot On Target",
        "Shot Off Target",
        "Tackle",
        "Block",
        "Save",
        "Own Goal",
        "Red Card",
        "Yellow Card",
        "Foul",
        "Offside",
        "Pass Attempted",
        "Pass Completed",
        "Minutes Played",
        "Interception",
        "Clearance",
      ],
      participationStatTypes: ["Goal", "Assist", "Red Card", "Yellow Card"],
      events,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.FootballStats",
        query: "PROFILE_STATS",
      },
    });
    console.log(error);
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const { appearances, positions, participationHistory } =
    data?.profile?.stats || {};

  const statsData = JSON.parse(
    data?.profile?.stats?.aggregations?.data || "{}"
  );

  // console.log("PH:", participationHistory);

  return (
    <div className="profile-stats-mobile__football">
      <ProfileAttackStats
        appearances={appearances}
        minutesPerGame={
          appearances
            ? (statsData["Minutes Played"] / appearances).toFixed(1)
            : 0
        }
        goals={statsData["Goal"] || 0}
        assists={statsData["Assist"] || 0}
        keyPasses={statsData["Key Pass"] || 0}
        shotsPerGame={
          appearances
            ? (
                ((statsData["Shot On Target"] || 0) +
                  (statsData["Shot Off Target"] || 0)) /
                appearances
              ).toFixed(1)
            : 0
        }
      />
      <ProfileShootingStats
        goals={statsData["Goal"] || 0}
        shotsOnTarget={statsData["Shot On Target"] || 0}
        shotsOffTarget={statsData["Shot Off Target"] || 0}
        totalShots={
          (statsData["Shot Off Target"] || 0) +
          (statsData["Shot On Target"] || 0)
        }
      />
      <ProfilePassingStats
        attemptedPasses={statsData["Pass Attempted"] || 0}
        successfulPasses={statsData["Pass Completed"] || 0}
      />
      <ProfilePositionsPlayed positions={positions} />
      <ProfileDefenseStats
        tackles={statsData["Tackle"] || 0}
        blocks={statsData["Block"] || 0}
        saves={statsData["Save"] || 0}
        ownGoals={statsData["Own Goal"] || 0}
        redCards={statsData["Red Card"] || 0}
        yellowCards={statsData["Yellow Card"] || 0}
        fouls={statsData["Foul"] || 0}
        offsides={statsData["Offside"] || 0}
        interceptions={statsData["Interception"] || 0}
        clearances={statsData["Clearance"] || 0}
      />
      <ProfileParticipationHistory
        participationHistory={participationHistory}
      />
      <ProfileAwards id={id} sport={"Football"} />
    </div>
  );
}
