import { gql } from "@apollo/client";

const SEARCH_EVENT_REGISTRATIONS = gql`
  query searchEventRegistrations($eventId: ID!, $term: String!) {
    searchEventRegistrations(eventId: $eventId, term: $term) {
      profiles {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default SEARCH_EVENT_REGISTRATIONS;
