import { gql } from "@apollo/client";

const SUGGESTED_PROFILES = gql`
  query suggestedProfiles {
    suggestedProfiles {
      profile {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default SUGGESTED_PROFILES;
