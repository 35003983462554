import "./BadmintonStats.css";
import Spinner from "../../../../components/Spinner";
import BoxScore from "./BoxScore";
import { useQuery } from "@apollo/client";
import { FIXTURE, BADMINTON_STATS } from "../../../../api/fixture";
// import StatsSummary from "./StatsSummary";
import PointsTimeline from "./PointsTimeline";
import captureException from "../../../../utils/captureException";

function MatchStatsItem({
  homeValue,
  awayValue,
  label,
  homeGraphWidth,
  awayGraphWidth,
}) {
  return (
    <div className="match-stats-item-mobile">
      <div className="match-stats-item-mobile__meta">
        <div className="match-stats-item-mobile__meta__value home">
          {homeValue}
        </div>

        <div className="match-stats-item-mobile__meta__label">{label}</div>

        <div className="match-stats-item-mobile__meta__value away">
          {awayValue}
        </div>
      </div>

      <div className="match-stats-item-mobile__graph">
        <div className="match-stats-item-mobile__graph__section home">
          <div
            className="match-stats-item-mobile__graph__section__fill"
            style={{ width: homeGraphWidth }}
          ></div>
        </div>

        <div className="match-stats-item-mobile__graph__section away">
          <div
            className="match-stats-item-mobile__graph__section__fill"
            style={{ width: awayGraphWidth }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function MatchStats({ fixture, homeParticipant, awayParticipant }) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixture.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture.BadmintonStats.MatchStats",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const homeStats = data?.fixture.statsSummary.find(
    (s) =>
      s.participantId ===
      (homeParticipant.profile?.id || homeParticipant.organization?.id)
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) =>
      s.participantId ===
      (awayParticipant.profile?.id || awayParticipant.organization?.id)
  );

  const homePointsWon = homeStats.pointsWon || 0;
  const awayPointsWon = awayStats.pointsWon || 0;
  const totalPointsWon = homePointsWon + awayPointsWon;
  //   const homeShotsOnTarget = homeStats.shotsOnTarget || 0;
  //   const awayShotsOnTarget = awayStats.shotsOnTarget || 0;

  const homeServes = homeStats.services || 0;
  const awayServes = awayStats.services || 0;
  const totalServes = homeServes + awayServes;

  const homeServesWon = homeStats.servicesWon || 0;
  const awayServesWon = awayStats.servicesWon || 0;
  const totalServesWon = homeServesWon + awayServesWon;

  const homeUnforcedErrors = homeStats.unforcedErrors || 0;
  const awayUnforcedErrors = awayStats.unforcedErrors || 0;
  const totalUnforcedErrors = homeUnforcedErrors + awayUnforcedErrors;

  // const homeOffRebs = homeStats.offensiveRebounds || 0;
  // const awayOffRebs = awayStats.offensiveRebounds || 0;
  // const totalOffRebs = homeOffRebs + awayOffRebs;

  // const homeDefRebs = homeStats.defensiveRebounds || 0;
  // const awayDefRebs = awayStats.defensiveRebounds || 0;
  // const totalDefRebs = homeDefRebs + awayDefRebs;

  // const homeTurnovers = homeStats.turnovers || 0;
  // const awayTurnovers = awayStats.turnovers || 0;
  // const totalTurnovers = homeTurnovers + awayTurnovers;

  // const homeFouls = homeStats.fouls || 0;
  // const awayFouls = awayStats.fouls || 0;
  // const totalFouls = homeFouls + awayFouls;

  return (
    <div className="fixture-stats-mobile__football__match-stats">
      <div className="fixture-stats-mobile__football__match-stats__title">
        Match Stats
      </div>

      <div className="fixture-stats-mobile__football__match-stats__items">
        <MatchStatsItem
          label={"Points Won"}
          homeValue={homePointsWon}
          awayValue={awayPointsWon}
          homeGraphWidth={
            totalPointsWon ? (homePointsWon / totalPointsWon) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalPointsWon ? (awayPointsWon / totalPointsWon) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Serves"}
          homeValue={homeServes}
          awayValue={awayServes}
          homeGraphWidth={
            totalServes ? (homeServes / totalServes) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalServes ? (awayServes / totalServes) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Serves Won"}
          homeValue={homeServesWon}
          awayValue={awayServesWon}
          homeGraphWidth={
            totalServesWon ? (homeServesWon / totalServesWon) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalServesWon ? (awayServesWon / totalServesWon) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Unforced Errors"}
          homeValue={homeUnforcedErrors}
          awayValue={awayUnforcedErrors}
          homeGraphWidth={
            totalUnforcedErrors
              ? (homeUnforcedErrors / totalUnforcedErrors) * 100 + "%"
              : "0%"
          }
          awayGraphWidth={
            totalUnforcedErrors
              ? (awayUnforcedErrors / totalUnforcedErrors) * 100 + "%"
              : "0%"
          }
        />
      </div>
    </div>
  );
}

export default function BadmintonStats({ fixture }) {
  const { loading, error, data } = useQuery(FIXTURE, {
    variables: { id: fixture.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture.BadmintonStats",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const homeParticipant = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayParticipant = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <div className="fixture-stats-mobile__badminton">
      <BoxScore
        periods={periods}
        fixtureId={fixture.id}
        homePlayer={homeParticipant}
        awayPlayer={awayParticipant}
      />

      <PointsTimeline
        fixtureId={fixture.id}
        periods={periods}
        homeParticipant={homeParticipant}
        awayParticipant={awayParticipant}
      />

      <MatchStats
        fixture={fixture}
        homeParticipant={homeParticipant}
        awayParticipant={awayParticipant}
      />
    </div>
  );
}
