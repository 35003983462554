import { useState, useCallback, Fragment } from "react";
import "./ESAppSearch.css";
import Input from "../Input";
import Card from "../Card";
import Spinner from "../Spinner";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import NewDropdown from "../NewDropdown";
import { useLazyQuery, useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { SEARCH, ES_SEARCH } from "../../api/user";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import captureException from "../../utils/captureException";

// function PagedViewWrapper({
//   selectedTab,
//   setShowMenu,
//   setSearchTerm,
//   dismiss,
//   setTab,
//   searchTerm,
// }) {
//   // const [selectedTab, setSelectedTab] = useState(defaultTab || "");

//   const pagedSearchSettings = {
//     profiles: {
//       variables: {
//         term: searchTerm,
//         disableOrganizations: true,
//         disableEvents: true,
//         take: 25,
//       },
//       getItems: (d) => d.search?.profiles,
//       title: "SEARCHING PROFILES",
//       linkTo: (item) => "/app/profile/" + item.id,
//     },
//     organizations: {
//       variables: {
//         term: searchTerm,
//         disableProfiles: true,
//         disableEvents: true,
//         take: 25,
//       },
//       getItems: (d) => d.search?.organizations,
//       title: "SEARCHING ORGANIZATIONS",
//       linkTo: (item) => "/app/page/" + item.id,
//     },
//     events: {
//       variables: {
//         term: searchTerm,
//         disableProfiles: true,
//         disableOrganizations: true,
//         take: 25,
//       },
//       getItems: (d) => d.search?.events,
//       title: "SEARCHING EVENTS",
//       linkTo: (item) => "/app/event/" + item.id,
//     },
//   };

//   return (
//     <div className="paged-view-wrapper">
//       <div className="paged-view-wrapper__tabs">
//         <div
//           className={
//             "paged-view-wrapper__tabs__tab " +
//             (selectedTab === "profiles" ? "active" : "")
//           }
//           onClick={() => {
//             setTab("profiles");
//           }}
//         >
//           PROFILES
//         </div>

//         <div
//           className={
//             "paged-view-wrapper__tabs__tab " +
//             (selectedTab === "organizations" ? "active" : "")
//           }
//           onClick={() => {
//             setTab("organizations");
//           }}
//         >
//           PAGES
//         </div>

//         <div
//           className={
//             "paged-view-wrapper__tabs__tab " +
//             (selectedTab === "events" ? "active" : "")
//           }
//           onClick={() => {
//             setTab("events");
//           }}
//         >
//           EVENTS
//         </div>
//       </div>

//       <div className="paged-view-wrapper__body">
//         <PagedSearch
//           variables={pagedSearchSettings[selectedTab].variables}
//           getItems={pagedSearchSettings[selectedTab].getItems}
//           title={pagedSearchSettings[selectedTab].title}
//           linkTo={pagedSearchSettings[selectedTab].linkTo}
//           setShowMenu={setShowMenu}
//           setSearchTerm={setSearchTerm}
//           dismiss={dismiss}
//         />
//       </div>
//     </div>
//   );
// }

// function PagedSearch({
//   variables: vars = {},
//   getItems,
//   title = "Searching ",
//   linkTo = () => {},
//   setShowMenu,
//   setSearchTerm,
//   dismiss,
// }) {
//   const { loading, error, data, fetchMore } = useQuery(SEARCH, {
//     variables: vars,
//     // notifyOnNetworkStatusChange: true,
//   });

//   if (error) {
//     console.log(error);
//     return <div>Error</div>;
//   }

//   if (loading || !data) {
//     return <Spinner />;
//   }

//   const items = getItems(data);
//   // console.log("Paged data:", data);
//   // console.log("Network status:", networkStatus);

//   return (
// <div className="search-results-section">
//   {/* <div className="search-results-section__title">
//     {title}
//     <div
//       className="search-results-section__title__show-more"
//       onClick={dismiss}
//     >
//       Search All
//     </div>
//   </div> */}
//   <div className="search-results-section__items">
//     {items?.map((item) => (
//       <Link
//         key={item.id}
//         to={linkTo(item)}
//         onClick={() => {
//           setShowMenu(false);
//           setSearchTerm("");
//         }}
//         className="search-results-section__items__item"
//       >
//         <div className="search-results-section__items__item__image">
//           {item.profilePhoto && <Avatar media={item.profilePhoto} />}
//         </div>
//         <div className="search-results-section__items__item__desc">
//           <div className="search-results-section__items__item__desc__primary">
//             {item.name}
//           </div>
//           {item.handle && (
//             <div className="search-results-section__items__item__desc__secondary">
//               @{item.handle}
//             </div>
//           )}
//         </div>
//       </Link>
//     ))}

//     {items.length && (
//       <div
//         className="search-results-section__footer__show-more"
//         onClick={() => {
//           // console.log("Clicked search");
//           fetchMore({
//             variables: {
//               cursor: items[items.length - 1].id,
//             },
//           });
//         }}
//       >
//         {loading ? "..." : "Show More"}
//       </div>
//     )}
//   </div>
// </div>
//   );
// }

// function AppSearchSection({
//   title,
//   defaultItems = [],
//   linkTo = () => {},
//   setShowMenu,
//   setSearchTerm,
//   loading,
//   showMore,
// }) {
//   const [items] = useState(defaultItems.slice(0, 5));

//   return (
//     <div className="search-results-section">
//       <div className="search-results-section__title">
//         {title}
//         {items.length && (
//           <div
//             className="search-results-section__title__show-more"
//             onClick={() => {
//               // console.log("Clicked search");
//               showMore();
//             }}
//           >
//             {loading ? "..." : "Show More"}
//           </div>
//         )}
//       </div>
//       <div className="search-results-section__items">
//         {items?.map((item) => (
//           <Link
//             key={item.id}
//             to={linkTo(item)}
//             onClick={() => {
//               setShowMenu(false);
//               setSearchTerm("");
//             }}
//             className="search-results-section__items__item"
//           >
//             <div className="search-results-section__items__item__image">
//               {item.profilePhoto && <Avatar media={item.profilePhoto} />}
//             </div>
//             <div className="search-results-section__items__item__desc">
//               <div className="search-results-section__items__item__desc__primary">
//                 {item.name}
//               </div>
//               {item.handle && (
//                 <div className="search-results-section__items__item__desc__secondary">
//                   @{item.handle}
//                 </div>
//               )}
//             </div>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// }

// function ESAppSearchSection({ types = [] }) {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [pagedSearchSection, setPagedSearchSection] = useState("");
//   const [search, { loading, error, data }] = useLazyQuery(ES_SEARCH);

//   if (error) {
//     captureException({
//       error,
//       info: {
//         type: "query",
//         component: "ESAppSearch",
//         query: "ES_SEARCH",
//       },
//     });
//     console.log(error);
//   }

//   const handleChange = (e) => {
//     if (e.target.value) {
//       // console.log("Firing query");
//       search({ variables: { term: e.target.value.trim() } });
//     }
//   };

//   const debouncedSearch = useCallback(
//     debounce(handleChange, 500, {
//       leading: false,
//       trailing: true,
//     }),
//     []
//   );

//   const total = data.total;
//   const nextCursor = data.nextCursor;
//   const hits = data.hits;

//   //   const profiles = data?.search?.profiles;
//   //   const organizations = data?.search?.organizations;
//   //   const events = data?.search?.events;

//   return (
//     <NewDropdown
//       wrapperClass="search"
//       targetWrapperClass="search-wrapper"
//       placement={"bottom-start"}
//       menuStyle={{ width: "100%" }}
//       onClose={() => {
//         setPagedSearchSection(false);
//       }}
//       target={
//         <Input
//           iconLeft={
//             <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
//           }
//           placeholder={"Search"}
//           onChange={(e) => {
//             setSearchTerm(e.target.value);
//             debouncedSearch(e);
//           }}
//           useExternalValue={true}
//           value={searchTerm}
//         />
//       }
//       menu={({ setShowMenu }) => (
//         <Card className="app-search-menu">
//           <Fragment>
//             {pagedSearchSection && searchTerm ? (
//               <PagedViewWrapper
//                 selectedTab={pagedSearchSection}
//                 setTab={setPagedSearchSection}
//                 setShowMenu={setShowMenu}
//                 setSearchTerm={setSearchTerm}
//                 dismiss={() => setPagedSearchSection("")}
//                 searchTerm={searchTerm}
//               />
//             ) : (
//               <Fragment>
//                 {loading && <Spinner />}
//                 {!loading &&
//                   !profiles?.length &&
//                   !organizations?.length &&
//                   !events?.length && (
//                     <div className="no-data">
//                       {!searchTerm ? (
//                         <div>Search for profiles, pages or events</div>
//                       ) : (
//                         <div>No results found</div>
//                       )}
//                     </div>
//                   )}

//                 {!!profiles?.length && (
//                   <AppSearchSection
//                     title={"PROFILES"}
//                     defaultItems={profiles}
//                     linkTo={(item) => "/app/profile/" + item.id}
//                     setShowMenu={setShowMenu}
//                     setSearchTerm={setSearchTerm}
//                     showMore={() => {
//                       setPagedSearchSection("profiles");
//                     }}
//                   />
//                 )}

//                 {!!organizations?.length && (
//                   <AppSearchSection
//                     title={"PAGES"}
//                     defaultItems={organizations}
//                     linkTo={(item) => "/app/page/" + item.id}
//                     setShowMenu={setShowMenu}
//                     setSearchTerm={setSearchTerm}
//                     showMore={() => {
//                       setPagedSearchSection("organizations");
//                     }}
//                   />
//                 )}

//                 {!!events?.length && (
//                   <AppSearchSection
//                     title={"EVENTS"}
//                     defaultItems={events}
//                     linkTo={(item) => "/app/event/" + item.id}
//                     setShowMenu={setShowMenu}
//                     setSearchTerm={setSearchTerm}
//                     showMore={() => {
//                       setPagedSearchSection("events");
//                     }}
//                   />
//                 )}
//               </Fragment>
//             )}
//           </Fragment>
//         </Card>
//       )}
//     />
//   );
// }

function linkTo(item) {
  const urlMapping = {
    profile: "profile",
    organization: "page",
    event: "event",
  };

  if (!item?.id) {
    return "";
  }

  return "/app/" + urlMapping[item.node_type] + "/" + item.id;
}

function ESAppSearchMenu({ setShowMenu }) {
  const [section, setSection] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [esSearch, { loading, error, data, fetchMore }] =
    useLazyQuery(ES_SEARCH);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ESAppSearch",
        query: "ES_SEARCH",
      },
    });
    console.log(error);
  }

  const handleChange = (e, section, nextCursor) => {
    if (e.target.value) {
      // console.log("Firing query:", section, nextCursor);
      esSearch({
        variables: {
          term: e.target.value.trim(),
          types: section ? [section] : null,
          cursor: 0,
        },
      });
    }
  };

  const handleSectionChange = (s) => {
    esSearch({
      variables: {
        term: searchTerm.trim(),
        types: s ? [s] : null,
        cursor: 0,
      },
    });
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  // console.log("Search Data:", data);

  const payload = data?.esSearch;
  const total = payload?.total || 0;
  const nextCursor = payload?.nextCursor || 0;
  const hits = payload?.hits || [];
  return (
    <div className="es-app-search-menu-wrapper">
      <div style={{ background: "white" }}>
        <Input
          iconLeft={
            <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
          }
          placeholder={"Search"}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e, section, nextCursor);
          }}
          useExternalValue={true}
          value={searchTerm}
          autofocus={true}
        />
      </div>

      <Card className="app-search-menu" style={{ marginTop: "4px" }}>
        <div className="paged-view-wrapper">
          {searchTerm && (
            <div className="paged-view-wrapper__tabs">
              <div
                className={
                  "paged-view-wrapper__tabs__tab " + (!section ? "active" : "")
                }
                onClick={() => {
                  setSection(false);
                  handleSectionChange(false);
                  //   debouncedSearch(
                  //     { target: { value: searchTerm } },
                  //     false,
                  //     nextCursor
                  //   );
                }}
              >
                ALL
              </div>
              <div
                className={
                  "paged-view-wrapper__tabs__tab " +
                  (section === "profile" ? "active" : "")
                }
                onClick={() => {
                  setSection("profile");
                  handleSectionChange("profile");
                  //   debouncedSearch(
                  //     { target: { value: searchTerm } },
                  //     "profile",
                  //     nextCursor
                  //   );
                }}
              >
                PROFILES
              </div>

              <div
                className={
                  "paged-view-wrapper__tabs__tab " +
                  (section === "organization" ? "active" : "")
                }
                onClick={() => {
                  setSection("organization");
                  handleSectionChange("organization");
                  //   debouncedSearch(
                  //     { target: { value: searchTerm } },
                  //     "organization",
                  //     nextCursor
                  //   );
                }}
              >
                PAGES
              </div>

              <div
                className={
                  "paged-view-wrapper__tabs__tab " +
                  (section === "event" ? "active" : "")
                }
                onClick={() => {
                  setSection("event");
                  handleSectionChange("event");
                  //   debouncedSearch(
                  //     { target: { value: searchTerm } },
                  //     "event",
                  //     nextCursor
                  //   );
                }}
              >
                EVENTS
              </div>
            </div>
          )}

          {!searchTerm ? (
            <div className="no-data">
              <div>Search for profiles, pages or events</div>
            </div>
          ) : (
            <div className="paged-view-wrapper__body">
              {loading ? (
                <div className="no-data">
                  <Spinner />
                </div>
              ) : (
                <div className="search-results-section">
                  <div className="search-results-section__items">
                    {hits?.map((item) => (
                      <Link
                        key={item.id}
                        to={linkTo(item)}
                        onClick={() => {
                          setShowMenu(false);
                          setSearchTerm("");
                          setSection(false);
                        }}
                        className="search-results-section__items__item"
                      >
                        <div className="search-results-section__items__item__image">
                          {item.profilePhoto && (
                            <Avatar media={{ filename: item.profilePhoto }} />
                          )}
                        </div>
                        <div className="search-results-section__items__item__desc">
                          <div className="search-results-section__items__item__desc__primary">
                            {item.name}
                          </div>
                          {item.handle && (
                            <div className="search-results-section__items__item__desc__secondary">
                              @{item.handle}
                            </div>
                          )}
                        </div>
                      </Link>
                    ))}

                    {!loading && !!hits?.length && hits.length < total && (
                      <div
                        className="search-results-section__footer__show-more"
                        onClick={() => {
                          // console.log("Clicked fetch more");
                          fetchMore({
                            variables: {
                              term: searchTerm.trim(),
                              cursor: nextCursor,
                              types: section ? [section] : null,
                            },
                          });
                        }}
                      >
                        {loading ? "..." : "Show More"}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

function ESAppSearch() {
  const [section, setSection] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [esSearch, { loading, error, data }] = useLazyQuery(ES_SEARCH);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ESAppSearch",
        query: "ES_SEARCH",
      },
    });
    console.log(error);
  }

  const handleChange = (e, section, nextCursor) => {
    if (e.target.value) {
      console.log("Firing query:", section, nextCursor);
      esSearch({
        variables: {
          term: e.target.value.trim(),
          types: section ? [section] : null,
          cursor: 0,
        },
      });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  console.log("Search Data:", data);

  const payload = data?.esSearch;
  const total = payload?.total || 0;
  const nextCursor = payload?.nextCursor || 0;
  const hits = payload?.hits || [];

  return (
    <NewDropdown
      wrapperClass="search"
      targetWrapperClass="search-wrapper"
      placement={"top-start"}
      menuStyle={{ width: "100%" }}
      onClose={() => {
        setSection(false);
      }}
      target={
        <div className="app-input">
          <div className={"input-wrapper "}>
            <div className="input-icon-left">
              <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
            </div>

            <span className="es-search-placeholder-text"> Search</span>
          </div>
        </div>
      }
      menu={({ setShowMenu }) => (
        <ESAppSearchMenu setShowMenu={setShowMenu} />
        // <Card className="app-search-menu">
        //   <div className="paged-view-wrapper">
        //     <div className="paged-view-wrapper__tabs">
        //       <div
        //         className={
        //           "paged-view-wrapper__tabs__tab " + (!section ? "active" : "")
        //         }
        //         onClick={() => {
        //           setSection(false);
        //         }}
        //       >
        //         ALL
        //       </div>
        //       <div
        //         className={
        //           "paged-view-wrapper__tabs__tab " +
        //           (section === "profile" ? "active" : "")
        //         }
        //         onClick={() => {
        //           setSection("profile");
        //         }}
        //       >
        //         PROFILES
        //       </div>

        //       <div
        //         className={
        //           "paged-view-wrapper__tabs__tab " +
        //           (section === "organization" ? "active" : "")
        //         }
        //         onClick={() => {
        //           setSection("organization");
        //         }}
        //       >
        //         PAGES
        //       </div>

        //       <div
        //         className={
        //           "paged-view-wrapper__tabs__tab " +
        //           (section === "event" ? "active" : "")
        //         }
        //         onClick={() => {
        //           setSection("event");
        //         }}
        //       >
        //         EVENTS
        //       </div>
        //     </div>

        //     <div className="paged-view-wrapper__body">
        //       <div className="search-results-section">
        //         <div className="search-results-section__items">
        //           {hits?.map((item) => (
        //             <Link
        //               key={item.id}
        //               to={linkTo(item)}
        //               onClick={() => {
        //                 setShowMenu(false);
        //                 setSearchTerm("");
        //                 setSection(false);
        //               }}
        //               className="search-results-section__items__item"
        //             >
        //               <div className="search-results-section__items__item__image">
        //                 {item.profilePhoto && (
        //                   <Avatar media={{ filename: item.profilePhoto }} />
        //                 )}
        //               </div>
        //               <div className="search-results-section__items__item__desc">
        //                 <div className="search-results-section__items__item__desc__primary">
        //                   {item.name}
        //                 </div>
        //                 {item.handle && (
        //                   <div className="search-results-section__items__item__desc__secondary">
        //                     @{item.handle}
        //                   </div>
        //                 )}
        //               </div>
        //             </Link>
        //           ))}

        //           {/* {hits?.length && hits.length < total && (
        //             <div
        //               className="search-results-section__footer__show-more"
        //               onClick={() => {
        //                 // console.log("Clicked search");
        //                 fetchMore({
        //                   variables: {
        //                     cursor: nextCursor,
        //                   },
        //                 });
        //               }}
        //             >
        //               {loading ? "..." : "Show More"}
        //             </div>
        //           )} */}
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </Card>
      )}
    />
  );
}

export default ESAppSearch;
