import { gql } from "@apollo/client";

const EVENTS = gql`
  query eventsQuery($where: EventWhereInput, $cursor: ID) {
    events(where: $where, cursor: $cursor) {
      id
      name
      venueLabel
      scheduledStart
      scheduledEnd
      description
      city {
        id
        value
      }
      profilePhoto {
        id
        filename
      }
      coverPhoto {
        id
        filename
      }
      registrationsCount
      participantsCount
      organizedBy {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default EVENTS;
