import { gql } from "@apollo/client";

const FIXTURE_STATS = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      completed
      uploaded
      event {
        id
        sport {
          id
          name
          statTypeGroups {
            id
            name
            types {
              id
              name
            }
          }
        }
      }
      metas {
        id
        label
        value
        stringValue
      }
      periods {
        id
        label
      }
      placements {
        id
        value
        valueString
        organization {
          id
          name
        }
      }
      statTables {
        id
        order
        organization {
          id
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        statType {
          id
          name
        }
        value
      }
      statsSummary {
        participantId
        totalShots
        shotsOnTarget
        goalsScored
        redCards
        yellowCards
        fouls
        offsides
        saves
        tackles
        blocks
        keyPasses
        assists
        corners
        attemptedPasses
        successfulPasses
        interceptions
        clearances
        form {
          id
        }
      }
      stats {
        id
        createdAt
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
        }
        fixturePeriod {
          id
          order
          label
        }
        relationshipsFrom {
          id
          to {
            id
            createdAt
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
            fixturePeriod {
              id
              order
              label
            }
            value
          }
        }
        relationshipsTo {
          id
          from {
            id
            createdAt
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
            fixturePeriod {
              id
              order
              label
            }
            value
          }
        }
      }
    }
  }
`;

export default FIXTURE_STATS;
