import "./ProfileVideos.css";
import { useState, useEffect, Fragment } from "react";
import { useQuery } from "@apollo/client";
import { PROFILE_VIDEOS, PROFILE_TAGGED_VIDEOS } from "../../../api/profile";
import VideoCard from "../../../mobileComponents/VideoCard";
import Loader from "../../../components/Loader";
import Spinner from "../../../components/Spinner";
import captureException from "../../../utils/captureException";
import Button from "../../../components/Button";

function VideosSection({
  title,
  query,
  vars,
  getVideos,
  totalVideos,
  noData,
  filters,
  processFilters,
}) {
  const { networkStatus, error, data, variables, fetchMore, refetch } =
    useQuery(query, {
      variables: vars,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  // useEffect(() => {
  //   const filterVars = processFilters(filters);

  //   if (filterVars) {
  //     refetch({
  //       ...vars,
  //       ...filterVars,
  //     });
  //   }
  // }, [filters]);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "VideosSection",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 2 || networkStatus === 4) {
    return (
      <div
        style={{
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const videos = getVideos(data);
  const videosCount = totalVideos(data);

  if (!videos.length) {
    return (
      <div className="profile-videos-gallery__section">
        <div
          className="profile-videos-gallery__section__title"
          style={{ paddingLeft: "16px" }}
        >
          {title}
        </div>

        {!videos.length && (
          <div className="no-videos-container" style={{ paddingTop: "8px" }}>
            <div className="text">{noData}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="profile-videos-gallery__section">
      <div
        className="profile-videos-gallery__section__title"
        style={{ paddingLeft: "16px" }}
      >
        {title}
      </div>

      {videos.map((v) => (
        <VideoCard key={v.id} media={v} videoHeight={180} />
      ))}

      {!!videos.length && videos.length < videosCount && (
        <div className="show-more-container">
          <Button
            className="primary small blue show-more-button"
            onClick={() => {
              fetchMore({
                variables: {
                  ...variables,
                  cursor: videos.length ? videos[videos.length - 1]?.id : null,
                },
              });
            }}
          >
            {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
          </Button>
        </div>
      )}
    </div>
  );
}

export default function ProfileVideos({ id, profile }) {
  // const [sportsFilter, setSportsFilter] = useState([]);
  // const [teamsFilter, setTeamsFilter] = useState([]);
  // const [eventsFilter, setEventsFilter] = useState([]);

  return (
    <div className="organization-videos-mobile">
      {/* {!videosCount && (
        <div className="no-data">
          <div className="text">No videos found.</div>
        </div>
      )} */}

      <div
        className="organization-videos-gallery"
        style={{ paddingTop: "16px" }}
      >
        <VideosSection
          title="Tagged Videos"
          query={PROFILE_TAGGED_VIDEOS}
          vars={{
            id,
            take: 2,
            filters: { sports: [], teams: [], events: [] },
          }}
          getVideos={(data) => data?.profile?.taggedMedia || []}
          totalVideos={(data) => data?.profile?.taggedVideosCount || 0}
          noData={`No tagged videos found.`}
          filters={{ sportsFilter: [], teamsFilter: [], eventsFilter: [] }}
          processFilters={(filters) => {
            return {
              filters: {
                sports: [],
                teams: [],
                events: [],
              },
            };
          }}
        />

        <VideosSection
          title={profile.name + "'s Matches"}
          query={PROFILE_VIDEOS}
          vars={{
            id,
            take: 2,
            filters: { sports: [], teams: [], events: [] },
          }}
          getVideos={(data) => data?.profile?.media || []}
          totalVideos={(data) => data?.profile?.videosCount || 0}
          noData={`No match videos found.`}
          filters={{ sportsFilter: [], teamsFilter: [], eventsFilter: [] }}
          processFilters={(filters) => {
            return {
              filters: {
                sports: [],
                teams: [],
                events: [],
              },
            };
          }}
        />
        {/* {videos.map((v) => (
          <VideoCard key={v.id} media={v} videoHeight={180} />
        ))} */}
      </div>

      {/* {videos.length < videosCount && (
        <div
          className="show-more-container"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: videos.length ? videos[videos.length - 1]?.id : null,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
        </div>
      )} */}
    </div>
  );
}
