import { useState } from "react";
import "./ManageFixture.css";

const manageEventTabs = [
  { label: "Details", component: () => <div>Details</div> },
];

function ManageFixture() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedTab = manageEventTabs[selectedTabIndex];
  const SelectedComponent = selectedTab.component;
  return (
    <div className="scoring_manage-event">
      <div className="scoring-app-page-title">Fixture Name Here</div>
      <div className="tabs">
        {manageEventTabs.map((t, i) => (
          <div
            className={
              "tab-item hover-pointer " +
              (selectedTabIndex === i ? "active" : "")
            }
            onClick={() => {
              setSelectedTabIndex(i);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>
      <div className="scoring_page-body">
        <SelectedComponent />
      </div>
    </div>
  );
}

export default ManageFixture;
