import { gql } from "@apollo/client";

const CAN_MANAGE_FIXTURE = gql`
  query canManageFixtureQuery($id: ID!) {
    canManageFixture(id: $id) {
      authorized
    }
  }
`;

export default CAN_MANAGE_FIXTURE;
