import "./FixtureListItem.css";
import { Fragment } from "react";
import { ReactComponent as ClockIcon } from "../../icons/clock.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import Avatar from "../../components/Avatar";

function determineScore(placements, participant) {
  if (participant?.organization) {
    const placement = placements?.find(
      (p) => p.organization?.id === participant?.organization?.id
    );
    if (!placement) {
      return "-";
    } else {
      return placement.valueString || placement.value;
    }
  } else if (participant?.profile) {
    const placement = placements?.find(
      (p) => p.profile?.id === participant?.profile?.id
    );
    if (!placement) {
      return "-";
    } else {
      return placement.valueString || placement.value;
    }
  } else {
    return "-";
  }
}

export default function FixtureListItem({ fixture, hideDate, className = "" }) {
  if (fixture.event?.sport?.name === "Badminton") {
    return (
      <Link
        to={"/app/fixture/" + fixture.id}
        className={"fixture-list-item-mobile " + className}
      >
        <div className="fixture-list-item-mobile__teams">
          {[...fixture.roles]
            .sort((a, b) => a.order - b.order)
            .filter((r) => r.type.name === "PARTICIPANT")
            .map((p) => (
              <div className="fixture-list-item-mobile__teams__item">
                <div className="fixture-list-item-mobile__teams__item__image">
                  {(p.organization?.profilePhoto ||
                    p.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        p.organization?.profilePhoto || p.profile?.profilePhoto
                      }
                    />
                  )}
                </div>

                <div className="fixture-list-item-mobile__teams__item__name">
                  {p.organization?.name || p.profile?.name || "TBD"}
                </div>

                <div className="fixture-list-item-mobile__teams__item__score">
                  {determineScore(fixture.placements, p)}
                </div>
              </div>
            ))}

          {/* <div className="fixture-list-item-mobile__teams__item">
            <div className="fixture-list-item-mobile__teams__item__image"></div>

            <div className="fixture-list-item-mobile__teams__item__name">
              Arsenal
            </div>

            <div className="fixture-list-item-mobile__teams__item__score">
              2
            </div>
          </div> */}
        </div>

        {!hideDate && (
          <div className="fixture-list-item-mobile__time">
            <div>4:00</div>
            <div>PM</div>
          </div>
        )}
      </Link>
    );
  } else {
    return (
      <Link
        to={"/app/fixture/" + fixture.id}
        className="fixture-list-item-mobile"
      >
        <div className="fixture-list-item-mobile__teams">
          {[...fixture.roles]
            .sort((a, b) => a.order - b.order)
            .filter((r) => r.type.name === "PARTICIPANT")
            .map((p) => (
              <div className="fixture-list-item-mobile__teams__item">
                <div className="fixture-list-item-mobile__teams__item__image">
                  {(p.organization?.profilePhoto ||
                    p.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        p.organization?.profilePhoto || p.profile?.profilePhoto
                      }
                    />
                  )}
                </div>

                <div className="fixture-list-item-mobile__teams__item__name">
                  {p.organization?.name || p.profile?.name || "TBD"}
                </div>

                <div className="fixture-list-item-mobile__teams__item__score">
                  {determineScore(fixture.placements, p)}
                </div>
              </div>
            ))}
        </div>

        {!hideDate && (
          <div className="fixture-list-item-mobile__time">
            {fixture.scheduledStart && (
              <Fragment>
                <div className="date">
                  {moment(fixture.scheduledStart).format("DD/MM")}
                </div>
                <div className="time">
                  {fixture.completed
                    ? "FT"
                    : moment(fixture.scheduledStart).format("HH:mm")}
                </div>
              </Fragment>
            )}

            {!fixture.scheduledStart && (
              <Fragment>
                <div className="date">
                  <ClockIcon style={{ stroke: "var(--dark-4)" }} />
                </div>
                <div className="time">TBD</div>
              </Fragment>
            )}
          </div>
        )}
      </Link>
    );
  }
}
