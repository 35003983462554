import { gql } from "@apollo/client";

const IS_FOLLOWED = gql`
  query isFollowed($id: ID!, $type: String!) {
    isFollowed(id: $id, type: $type) {
      id
      confirmed
      handled
      follower {
        id
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
      following {
        id
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export default IS_FOLLOWED;
