import { gql } from "@apollo/client";

const ORGANIZATION_MEMBERS = gql`
  query organizationQuery(
    $id: ID!
    $take: Int
    $where: OrganizationMembershipWhereInput
  ) {
    organization(id: $id) {
      id
      name
      members(take: $take, where: $where) {
        id
        createdAt
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        isAdmin
        role
        approved
      }
    }
  }
`;

export default ORGANIZATION_MEMBERS;
