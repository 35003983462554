import "./AppLayout.css";

import { Switch, Route } from "react-router-dom";

import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import OnboardingPrompt from "../../components/OnboardingPrompt";

import Home from "../../screens/Home";
import Directory from "../../screens/Directory";
import Events from "../../screens/Events";
import Profile from "../../screens/Profile";
import Organization from "../../screens/Organization";
import Event from "../../screens/Event";
import Fixture from "../../screens/Fixture";
import CreatePage from "../../screens/CreatePage";
import CreateEvent from "../../screens/CreateEvent";
import Account from "../../screens/Account";
import Scoring from "../../screens/Scoring";
import { useAppState } from "../../utils/appState";
import isOnboarded from "../../utils/isOnboarded";
import { USER_PROFILE } from "../../api/user";
import { useQuery } from "@apollo/client";
import captureException from "../../utils/captureException";

function AppLayout() {
  const [{ modal, isLoggedIn }] = useAppState();
  const { loading, data, error } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AppLayout",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "400px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const profile = data?.userProfile;
  // console.log(profile);
  const onboarded = isOnboarded(profile);
  const hasAlert = !isLoggedIn || !onboarded.onboarded;
  // const hasAlert = true;

  // console.log("HAS ALERT:", onboarded);

  return (
    <div className={"App " + (hasAlert ? "onboarding" : "")}>
      <Header />
      {hasAlert && !loading && <OnboardingPrompt onboardingInfo={onboarded} />}

      <Switch>
        <Route path="/app/manage">
          <Scoring />
        </Route>

        <Route path="/app/page/:id">
          <Organization />
        </Route>

        <Route path="/app/profile/:id">
          <Profile />
        </Route>

        <Route exact path="/app/create_event">
          <CreateEvent />
        </Route>

        <Route exact path="/app/create_page">
          <CreatePage />
        </Route>

        <Route path="/app/event/:id">
          <Event />
        </Route>

        <Route path="/app/fixture/:id">
          <Fixture />
        </Route>

        <Route path="/app/events">
          <Events />
        </Route>

        <Route path="/app/directory">
          <Directory />
        </Route>

        <Route path="/app/account">
          <Account />
        </Route>

        <Route path="/app">
          <Home />
        </Route>
      </Switch>

      <div className={"App-modal " + (modal ? "open" : "")}>
        {modal && <div className="App-modal-background">{modal}</div>}
      </div>
      {/*<Directory />*/}
      {/*<div className="buttons">
        <Button className="small blue primary">Small</Button>
        <Button className="small blue secondary">Small</Button>
        <Button className="small blue tertiary">Small</Button>
        <Button className="small blue round">S</Button>
      </div>

      <div className="buttons">
        <Button className="small green primary">Small</Button>
        <Button className="small green secondary">Small</Button>
        <Button className="small green tertiary">Small</Button>
        <Button className="small green round">S</Button>
      </div>

      <div className="buttons">
        <Button className="small red primary">Small</Button>
        <Button className="small red secondary">Small</Button>
        <Button className="small red tertiary">Small</Button>
        <Button className="small red round">S</Button>
      </div>

      <div className="buttons">
        <Button className="medium blue primary">Medium</Button>
        <Button className="medium blue secondary">Medium</Button>
        <Button className="medium blue tertiary">Medium</Button>
        <Button className="medium blue round">M</Button>
      </div>

      <div className="buttons">
        <Button className="medium green primary">Medium</Button>
        <Button className="medium green secondary">Medium</Button>
        <Button className="medium green tertiary">Medium</Button>
        <Button className="medium green round">M</Button>
      </div>

      <div className="buttons">
        <Button className="medium red primary">Medium</Button>
        <Button className="medium red secondary">Medium</Button>
        <Button className="medium red tertiary">Medium</Button>
        <Button className="medium red round">M</Button>
      </div>

      <div className="buttons">
        <Button className="large blue primary">Large</Button>
        <Button className="large blue secondary">Large</Button>
        <Button className="large blue tertiary">Large</Button>
        <Button className="large blue round">L</Button>
      </div>

      <div className="buttons">
        <Button className="large green primary">Large</Button>
        <Button className="large green secondary">Large</Button>
        <Button className="large green tertiary">Large</Button>
        <Button className="large green round">L</Button>
      </div>

      <div className="buttons">
        <Button className="large red primary">Large</Button>
        <Button className="large red secondary">Large</Button>
        <Button className="large red tertiary">Large</Button>
        <Button className="large red round">L</Button>
      </div>

      <div className="buttons">
        <Button className="large neutral">Secondary</Button>
        <Button className="large disabled">Disabled</Button>
      </div>

      <div className="section">
        <Input />
      </div>

      <div className="section">
        <Slider />
      </div>

      <div className="section">
        <Checkbox />
      </div>

      <div className="section">
        <Radio items={[{ label: "Item 1" }, { label: "Item 2" }]} />
      </div>

      <div className="section">
        <Toggle />
      </div>

      <div className="section">
        <Badge type="info" label={"INFO"} />
        <Badge type="success" label={"SUCCESS"} />
        <Badge type="warning" label={"WARNING"} />
        <Badge type="danger" label={"DANGER"} />
        <Badge type="reminder" label={"REMINDER"} />
        <Badge type="misc" label={"MISC"} />
        <Badge type="unavailable" label={"UNAVAILABLE"} />
        <Badge type="offline" label={"OFFLINE"} />
        <Badge type="primary" label={"PRIMARY"} />
      </div>

      <div className="">
        <div>
          <Alert
            width={240}
            indicator={false}
            head={"Alert Header"}
            dismiss={true}
            body={
              "This is some text describing the alert and comes with a CTA so that the user can take action."
            }
            foot={<Button className="small blue secondary">CTA</Button>}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Alert
            width={350}
            indicator={false}
            dismiss={false}
            head={"Some text is displayed here"}
            body={false}
            foot={false}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Alert
            width={350}
            indicator={true}
            dismiss={true}
            head={"Some text is displayed here"}
            body={false}
            foot={false}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Alert
            width={189}
            indicator={false}
            dismiss={true}
            head={"Alert header"}
            body={
              "This is some text describing the alert and comes with a CTA so that the user can take action."
            }
            foot={<Button className="small blue secondary">CTA</Button>}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Alert
            width={289}
            indicator={true}
            dismiss={true}
            head={"Dismiss with CTA"}
            body={<Button className="small blue secondary">CTA</Button>}
            foot={false}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Alert
            width={289}
            indicator={false}
            dismiss={false}
            head={"Text with right icon"}
            body={false}
            foot={false}
            headRight={<img src={"/info.svg"} />}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Alert
            width={289}
            indicator={false}
            dismiss={false}
            head={"Text with CTA"}
            body={false}
            foot={false}
            headRight={<Button className="small blue secondary">CTA</Button>}
          />
        </div>
      </div>*/}
    </div>
  );
}

export default AppLayout;
