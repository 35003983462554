import { gql } from "@apollo/client";

export function EVENT({ selectionSet }) {
  return gql`
      query event($id: ID!) {
        event(id: $id) {
          id
          ${selectionSet}
        }
      }
    `;
}

export function UPDATE_EVENT({ selectionSet }) {
  return gql`
      mutation updateEventMutation($where:UniqueInput!, $data: UpdateEventInput!) {
        updateEvent(where:$where, data: $data) {
          id
          ${selectionSet}
        }
      }
    `;
}
