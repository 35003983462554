import { gql } from "@apollo/client";

const REORDER_STANDING_ITEMS = gql`
  mutation reorderStandingItems($data: [EventPartReorderInput!]) {
    reorderStandingItems(data: $data) {
      id
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      profile {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      order
      metas {
        id
        label
        shortLabel
        value
        stringValue
      }
    }
  }
`;

export default REORDER_STANDING_ITEMS;
