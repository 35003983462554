// V1
import "./EventOverview.css";
import EventRoles from "./EventRoles";
import EventDescription from "./EventDescription";
import LatestHighlights from "./LatestHighlights";
import EventResultsSummary from "./EventResultsSummary";
import EventStandingsSummary from "./EventStandingsSummary";
import EventAwards from "./EventAwards";
import EventPlacements from "./EventPlacements";

function EventStarted({ id, setSelectedTabIndex, canManageEvent }) {
  return (
    <div className="event-overview">
      <div className="left-column">
        <EventDescription
          id={id}
          setSelectedTabIndex={setSelectedTabIndex}
          canManageEvent={canManageEvent}
        />

        <div className="sub-grid">
          <EventResultsSummary id={id} />
          <EventStandingsSummary
            id={id}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        </div>
      </div>
      <div className="right-column">
        <EventPlacements id={id} />
        <LatestHighlights id={id} setSelectedTabIndex={setSelectedTabIndex} />
        <EventRoles id={id} />
        <EventAwards id={id} eventId={id} canManageEvent={canManageEvent} />
      </div>

      {/* <EventRoles id={id} /> */}
    </div>
  );
}

function EventOverview({ id, setSelectedTabIndex, canManageEvent }) {
  return (
    <EventStarted
      id={id}
      setSelectedTabIndex={setSelectedTabIndex}
      canManageEvent={canManageEvent}
    />
  );
}

export default EventOverview;
