const makePlaceholderUrl = (media) => {
  const endpoint =
    "https://sportspaddock-filestore-blr.blr1.digitaloceanspaces.com/public/";

  // console.log(media.filename);

  //   return;

  const basename = media.filename.split(".")?.slice(0, -1).join(".");
  const extension = media.filename.split(".")?.slice(-1);

  return endpoint + basename + "--placeholder." + extension;
};

export default makePlaceholderUrl;
