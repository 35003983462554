import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/functions";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { useState } from "react";

function DlsDetails({ setEditing, runsTargetMeta, oversTargetMeta }) {
  return (
    <div
      className="ScoringApp-football__settings__period-settings__period"
      style={{ borderTop: "solid 1px var(--light-2)" }}
    >
      <div className="ScoringApp-football__settings__period-settings__period__details">
        {runsTargetMeta.value} Runs ({oversTargetMeta.value} Overs)
      </div>
      <div className="ScoringApp-football__settings__period-settings__period__options">
        <div
          className="ScoringApp-football__settings__period-settings__period__options__option edit"
          onClick={() => {
            setEditing(true);
          }}
        >
          <PencilIcon className="ScoringApp-football__settings__period-settings__period__options__option__icon" />
        </div>
      </div>
    </div>
  );
}

function EditDlsTargets({
  fixtureId,
  metas,
  participants,
  dismiss,
  defaultOvers,
  defaultRuns,
}) {
  const [runs, setRuns] = useState(defaultRuns || "");
  const [overs, setOvers] = useState(defaultOvers || "");
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
            metas{
              id
              label
              value
              stringValue
          }
            `,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            metas() {
              return updateFixture.metas;
            },
          },
        });

        dismiss();

        // setOverlay(false);
      },
    }
  );

  //   const dismiss = () => {
  //     // setOverlay(false);
  //   };

  const dlsStatusMeta = metas.find((m) => m.label === "dlsStatus");

  const runsTargetMeta = metas.find((m) => m.label === "dlsRunsTarget");

  const oversTargetMeta = metas.find((m) => m.label === "dlsOversTarget");

  const tossWinnerMeta = metas.find((m) => m.label === "tossWinner");

  const tossDecisionMeta = metas.find((m) => m.label === "tossDecision");

  const firstInningsParticipant =
    tossDecisionMeta.stringValue === "decided to bat"
      ? participants.find(
          (p) => p.organization?.id === tossWinnerMeta.stringValue
        )
      : participants.find(
          (p) => p.organization?.id !== tossWinnerMeta.stringValue
        );

  return (
    <div
      className="ScoringApp-football__settings__penalty-settings__body__content"
      style={{ borderTop: "solid 1px var(--light-2)" }}
    >
      <form
        className="ScoringApp-cricket__scoring__modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();
          if (
            loading ||
            !runs ||
            !overs ||
            !firstInningsParticipant?.organization?.id
          ) {
            return;
          }

          const metaVars = {};

          if (dlsStatusMeta?.id) {
            if (!metaVars.update) {
              metaVars.update = [];
            }
            metaVars.update.push({
              where: { id: dlsStatusMeta.id },
              data: {
                stringValue: firstInningsParticipant.organization.id,
              },
            });
          } else {
            if (!metaVars.create) {
              metaVars.create = [];
            }
            metaVars.create.push({
              label: "dlsStatus",
              stringValue: firstInningsParticipant.organization.id,
            });
          }

          if (runsTargetMeta?.id) {
            if (!metaVars.update) {
              metaVars.update = [];
            }
            metaVars.update.push({
              where: { id: runsTargetMeta.id },
              data: {
                value: Number(runs),
              },
            });
          } else {
            if (!metaVars.create) {
              metaVars.create = [];
            }
            metaVars.create.push({
              label: "dlsRunsTarget",
              value: Number(runs),
            });
          }

          if (oversTargetMeta?.id) {
            if (!metaVars.update) {
              metaVars.update = [];
            }
            metaVars.update.push({
              where: { id: oversTargetMeta.id },
              data: {
                value: Number(overs),
              },
            });
          } else {
            if (!metaVars.create) {
              metaVars.create = [];
            }
            metaVars.create.push({
              label: "dlsOversTarget",
              value: Number(overs),
            });
          }

          //   console.log("metas:", metaVars);
          //   return;

          updateFixture({
            variables: {
              where: { id: fixtureId },
              data: {
                metas: metaVars,
              },
            },
          });
        }}
      >
        <div className="ScoringApp-football__settings__period-settings__form__row">
          <div className="ScoringApp-football__settings__period-settings__form__row__group text">
            <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
              Runs Target
            </div>
            <input
              type="number"
              value={runs}
              onChange={(e) => {
                setRuns(e.target.value);
              }}
              className="ScoringApp-football__settings__period-settings__form__row__group__input label"
              min={1}
            />
          </div>

          <div className="ScoringApp-football__settings__period-settings__form__row__group">
            <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
              Overs Target
            </div>
            <input
              type="number"
              value={overs}
              onChange={(e) => {
                setOvers(e.target.value);
              }}
              className="ScoringApp-football__settings__period-settings__form__row__group__input duration"
              min={1}
              step={0.1}
            />
          </div>

          <div className="ScoringApp-football__settings__period-settings__form__row__options">
            <button
              type="submit"
              className={
                "ScoringApp-football__settings__period-settings__form__row__options__option submit " +
                (loading ? "disabled" : "")
              }
            >
              {loading ? (
                <Spinner size={12} color={"var(--green-darker)"} />
              ) : (
                <CheckIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
              )}
            </button>
            <div
              className={
                "ScoringApp-football__settings__period-settings__form__row__options__option cancel " +
                (loading ? "disabled" : "")
              }
              onClick={() => {
                dismiss();
              }}
            >
              <TimesIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default function DlsSettings({ id }) {
  const [editing, setEditing] = useState();
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
            metas{
                id
                label
                value
                stringValue
            }
            roles(where:{type:{name:"PARTICIPANT"}}){
                id
                order
                type{
                    id
                    name
                }
                organization {
                    id
                    name
                }
            }
        `,
    }),
    {
      variables: {
        id,
      },
    }
  );

  const [updateFixture, { loading: updating }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
          metas{
            id
            label
            value
            stringValue
        }
          `,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            metas() {
              return updateFixture.metas;
            },
          },
        });
      },
    }
  );

  if (error) {
    console.log(error);
    return <div className="ScoringApp-cricket__scoring__status">Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__status">
        <Loader theme={"small"} />
      </div>
    );
  }

  const metas = data?.fixture?.metas || [];

  const tossWinnerMeta = metas.find((m) => m.label === "tossWinner");

  const tossDecisionMeta = metas.find((m) => m.label === "tossDecision");

  if (!tossWinnerMeta?.stringValue || !tossDecisionMeta?.stringValue) {
    return <></>;
  }

  // String Value will be null if inactive, and contain the first innings batting teams id if active.
  const dlsStatusMeta = metas.find((m) => m.label === "dlsStatus");

  const runsTargetMeta = metas.find((m) => m.label === "dlsRunsTarget");

  const oversTargetMeta = metas.find((m) => m.label === "dlsOversTarget");

  const participants = [...(data?.fixture?.roles || [])].sort(
    (a, b) => a.order - b.order
  );

  console.log("Metas:", metas);

  return (
    <Card className="ScoringApp-football__settings__penalty-settings">
      <div className="ScoringApp-football__settings__penalty-settings__title">
        DLS Settings
      </div>

      <div className="ScoringApp-football__settings__penalty-settings__body">
        {!!dlsStatusMeta?.stringValue && (
          <div className="ScoringApp-football__settings__penalty-settings__body__content">
            <div className="ScoringApp-football__settings__penalty-settings__body__content__blurb">
              DLS is active.
            </div>

            <div className="ScoringApp-football__settings__penalty-settings__body__content__action">
              <Button
                className={"small red primary "}
                onClick={() => {
                  if (updating) return;

                  const metaVars = {
                    deleteMany: [],
                  };

                  if (dlsStatusMeta?.id) {
                    metaVars.deleteMany.push({
                      id: dlsStatusMeta.id,
                    });
                  }

                  if (runsTargetMeta?.id) {
                    metaVars.deleteMany.push({
                      id: runsTargetMeta.id,
                    });
                  }

                  if (oversTargetMeta?.id) {
                    metaVars.deleteMany.push({
                      id: oversTargetMeta.id,
                    });
                  }

                  updateFixture({
                    variables: {
                      where: { id },
                      data: {
                        metas: metaVars,
                      },
                    },
                  });
                }}
              >
                {updating ? <Loader theme={"small"} /> : "Deactivate"}
              </Button>
            </div>
          </div>
        )}

        {!dlsStatusMeta?.stringValue && !editing && (
          <div className="ScoringApp-football__settings__penalty-settings__body__content">
            <div className="ScoringApp-football__settings__penalty-settings__body__content__blurb">
              Click the button to activate DLS.
            </div>

            <div className="ScoringApp-football__settings__penalty-settings__body__content__action">
              <Button
                className="small green primary"
                onClick={() => {
                  setEditing(true);
                  //   setOverlay(
                  //     <EditDlsTargets
                  //       metas={data?.fixture?.metas}
                  //       participants={participants}
                  //     />
                  //   );
                  //   if (updating) return;
                  //   const vars = {};
                  //   vars.create = [
                  //     {
                  //       label: "Penalty Shootout",
                  //       order: 5,
                  //       duration: 0,
                  //       metas: {
                  //         create: [
                  //           {
                  //             label: "periodType",
                  //             stringValue: "PENALTY SHOOTOUT",
                  //           },
                  //         ],
                  //       },
                  //     },
                  //   ];
                  //   mutate({
                  //     variables: {
                  //       where: {
                  //         id,
                  //       },
                  //       data: {
                  //         periods: vars,
                  //       },
                  //     },
                  //   });
                }}
              >
                {updating ? <Loader theme={"small"} /> : "Activate"}
              </Button>
            </div>
          </div>
        )}

        {editing && (
          <EditDlsTargets
            metas={metas}
            participants={participants}
            dismiss={() => {
              setEditing(false);
            }}
            fixtureId={id}
            defaultRuns={runsTargetMeta?.value}
            defaultOvers={oversTargetMeta?.value}
          />
        )}

        {!!dlsStatusMeta?.stringValue && !editing && (
          <DlsDetails
            setEditing={setEditing}
            runsTargetMeta={runsTargetMeta}
            oversTargetMeta={oversTargetMeta}
          />
        )}
      </div>
    </Card>
  );
}
