import Card from "../../Card";
import "./FixtureEventsSummary.css";
import AppLink from "../../AppLink";
import { FIXTURE_TIMELINE } from "../../../api/fixture";
import { useQuery } from "@apollo/client";
import { ReactComponent as FootballIcon } from "../../../icons/football.svg";
import { ReactComponent as SwapIcon } from "../../../icons/swap.svg";
import { Fragment } from "react";
import Placeholder from "../../Placeholder/Placeholder";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function BasketballEventsSummary() {}

function OverItem({
  over,
  fixture,
  homeParticipant,
  awayParticipant,
  period,
  isLatestOver,
  score,
  wickets,
}) {
  // const overTotal = over.stats?.reduce((accum, curr) => {
  //   const runsScored =
  //     curr.relationshipsTo?.find(
  //       (x) => x.from?.statType?.name === "Runs Scored"
  //     )?.from?.value || 0;
  //   const extras = curr.relationshipsFrom
  //     ?.filter((x) =>
  //       ["Wide", "No Ball", "Bye", "Leg Bye"].includes(x.to.statType.name)
  //     )
  //     .reduce((a, c) => {
  //       a = a + c.to.value;
  //       return a;
  //     }, 0);

  //   accum = accum + runsScored + extras;
  //   return accum;
  // }, 0);

  // const getOverScore = (periodId, over = 0) => {
  //   // console.log("Called with:", periodId, over);
  //   let total = 0;
  //   for (let i = over; i > 0; i--) {
  //     // console.log("Loop:", i);
  //     const r = runsByPeriod[periodId][over] || 0;
  //     // console.log("Runs scored:", r);
  //     total += r;
  //     // console.log(over, r, total);
  //   }

  //   return total;
  // };

  // const score = getOverScore(runsByPeriod, period.id, over.overNumber);

  const runRate = (score / over.overNumber).toFixed(2);
  const sortedStats =
    over.stats.sort(
      (a, b) =>
        b.value - a.value ||
        (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1)
    ) || [];

  return (
    <div className="fixture-events-summary-over">
      {!isLatestOver && (
        <div className="fixture-events-summary-over__header">
          <div className="fixture-events-summary-over__header__primary">
            <div className="fixture-events-summary-over__header__primary__text">
              End of Over {over.overNumber}
            </div>

            <div
              className="fixture-events-summary-over__header__primary__text"
              style={{ marginLeft: "auto" }}
            >
              {score}/{wickets}
            </div>
          </div>

          <div className="fixture-events-summary-over__header__secondary">
            <div className="fixture-events-summary-over__header__primary__text">
              {over.runs} runs
            </div>

            <div
              className="fixture-events-summary-over__header__primary__text"
              style={{ marginLeft: "auto" }}
            >
              Run Rate: {runRate}
            </div>
          </div>
        </div>
      )}

      <div className="fixture-events-summary-over__stats">
        {sortedStats.map((s, i) => (
          <CricketEventItem
            key={s.id}
            fixture={fixture}
            stat={s}
            stats={fixture?.stats}
            homeParticipant={homeParticipant}
            awayParticipant={awayParticipant}
            period={period}
            prev={i + 1 <= sortedStats.length - 1 ? sortedStats[i + 1] : false}
          />
        ))}
      </div>
    </div>
  );
}

function CricketEventsSummary({ fixture, isMobile }) {
  const stats = fixture?.stats?.filter(
    (s) => s.statType.name === "Balls Bowled"
  );
  const periods = fixture?.periods || [];

  let runsByPeriod = {};
  let wicketsByPeriod = {};

  const statsByPeriod = stats
    .reduce((accum, curr) => {
      const p = curr.fixturePeriod;
      // console.log("Curr p:", p);

      const existingPeriodEntry = accum.findIndex(
        (x) => x.period?.id === p?.id
      );

      const overNumber = Math.floor(curr.value) + 1;

      const runsScoredStat = curr.relationshipsTo?.find(
        (x) => x.from?.statType?.name === "Runs Scored"
      )?.from;

      const runsScored = runsScoredStat?.value || 0;

      const extras = curr.relationshipsFrom
        ?.filter((x) =>
          ["Wide", "No Ball", "Bye", "Leg Bye"].includes(x.to.statType.name)
        )
        .reduce((a, c) => {
          a = a + c.to.value;
          return a;
        }, 0);

      const wicketStat = fixture?.stats?.find(
        (s) =>
          [
            "Bowled",
            "LBW",
            "Caught",
            "Caught & Bowled",
            "Run Out",
            "Stumped",
            "Retired",
          ].includes(s.statType.name) &&
          s.relationshipsTo?.find((r) => r.from?.id === runsScoredStat?.id)
      );

      // console.log(existingPeriodEntry);

      if (existingPeriodEntry < 0) {
        // console.log("Making New Group");
        accum.push({
          period: { ...p },
          statsByOver: [
            {
              overNumber,
              stats: [curr],
              runs: runsScored + extras,
            },
          ],
        });
      } else {
        // console.log("Existing Group");
        const overIndex = accum[existingPeriodEntry].statsByOver.findIndex(
          (x) => x.overNumber === overNumber
        );
        if (overIndex < 0) {
          accum[existingPeriodEntry].statsByOver.push({
            overNumber,
            stats: [curr],
            runs: runsScored + extras,
          });
        } else {
          accum[existingPeriodEntry].statsByOver[overIndex].stats.push(curr);
          accum[existingPeriodEntry].statsByOver[overIndex].runs =
            accum[existingPeriodEntry].statsByOver[overIndex].runs +
            runsScored +
            extras;
        }
      }

      // Update scores for each over
      if (runsByPeriod[p?.id]) {
        runsByPeriod[p?.id][overNumber] =
          (runsByPeriod[p?.id][overNumber] || 0) + runsScored + extras;
      } else {
        runsByPeriod[p?.id] = {
          [overNumber]: runsScored + extras,
        };
      }

      if (wicketStat) {
        if (wicketsByPeriod[p?.id]) {
          wicketsByPeriod[p?.id][overNumber] =
            (wicketsByPeriod[p?.id][overNumber] || 0) + 1;
        } else {
          wicketsByPeriod[p?.id] = {
            [overNumber]: 1,
          };
        }
      }

      return accum;
    }, [])
    .filter((g) => {
      const p = periods.find((x) => x.id === g.period.id);
      const isPenaltyShootout = p?.metas?.find(
        (m) => m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
      );
      if (isPenaltyShootout) {
        return false;
      } else {
        return true;
      }
    })
    .sort((a, b) => b.period.order - a.period.order);

  // console.log(runsByPeriod);

  const homeParticipant = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayParticipant = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  const getOverScore = (periodId, over = 0) => {
    // console.log("Called with:", periodId, over, runsByPeriod);
    let total = 0;
    for (let i = over; i > 0; i--) {
      // console.log("Loop:", i);
      const r = runsByPeriod[periodId]?.[i] || 0;
      // console.log("Runs scored:", r);
      total = total + r;
      // console.log("Total:", total);
      // console.log(over, r, total);
    }

    return total;
  };

  const getOverWickets = (periodId, over = 0) => {
    // console.log("Called with:", periodId, over, runsByPeriod);
    let total = 0;
    for (let i = over; i > 0; i--) {
      // console.log("Loop:", i);
      const r = wicketsByPeriod[periodId]?.[i] || 0;
      // console.log("Runs scored:", r);
      total = total + r;
      // console.log("Total:", total);
      // console.log(over, r, total);
    }

    return total;
  };

  // console.log("SBP:", statsByPeriod);

  return (
    <Card className={"fixture-events-summary " + (isMobile ? "mobile" : "")}>
      <div className="card-title">Match Events</div>
      <div className="fixture-events-summary__list">
        {!stats?.length && (
          <div className="no-data">No match events added yet</div>
        )}

        {statsByPeriod.map((group, i) => (
          <Fragment key={"stats-by-period-" + group.period?.id + i}>
            {fixture?.completed && (
              <div className="fixture-events-summary__list__period">
                FULL TIME
              </div>
            )}
            {group.statsByOver
              .sort((a, b) => b.overNumber - a.overNumber)
              .map((over, overIndex) => (
                <OverItem
                  key={"Over-" + group.period.id + "-" + overIndex}
                  over={over}
                  fixture={fixture}
                  homeParticipant={homeParticipant}
                  awayParticipant={awayParticipant}
                  period={group.period}
                  isLatestOver={
                    group.statsByOver.length - overIndex >=
                    group.statsByOver.length
                  }
                  score={getOverScore(group.period.id, over.overNumber)}
                  wickets={getOverWickets(group.period.id, over.overNumber)}
                />
              ))}

            {/* {group.stats
              .sort(
                (a, b) =>
                  b.timeStamp - a.timeStamp ||
                  (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1)
              )
              .map((s) => (
                <EventItem
                  key={s.id}
                  fixture={fixture}
                  stat={s}
                  stats={fixture?.stats}
                  homeParticipant={homeParticipant}
                  awayParticipant={awayParticipant}
                  period={group.period}
                />
              ))} */}

            <div className="fixture-events-summary__list__period">
              START OF {group.period?.label?.toUpperCase()}
            </div>
          </Fragment>
        ))}
      </div>
      {/* <div className="fixture-events-summary__footer hover-pointer">
    Show More
  </div> */}
    </Card>
  );
}

function BadmintonEventsSummary() {}

function TennisEventsSummary() {}

function GenericEventsSummary() {}

const determineCurrentTennisScore = (
  homePointsScored,
  awayPointsScored,
  isTieBreaker
) => {
  const output = { home: 0, away: 0 };
  const scoreMap = {
    1: 15,
    2: 30,
    3: 40,
  };

  if (isTieBreaker) {
    output.home = homePointsScored?.length || 0;
    output.away = awayPointsScored?.length || 0;
    return output;
  }

  if (homePointsScored?.length >= 3 && awayPointsScored?.length >= 3) {
    if (homePointsScored.length === awayPointsScored.length) {
      output.home = "Deuce";
      output.away = "Deuce";
    } else if (homePointsScored.length > awayPointsScored.length) {
      if (homePointsScored.length - awayPointsScored.length > 1) {
        output.home = "Game";
        output.away = "";
      } else {
        output.home = "Adv";
        output.away = "";
      }
    } else {
      if (awayPointsScored.length - homePointsScored.length > 1) {
        output.home = "";
        output.away = "Game";
      } else {
        output.home = "";
        output.away = "Adv";
      }
    }
  } else {
    if (homePointsScored?.length) {
      if (homePointsScored?.length > 3) {
        output.home = "Game";
      } else {
        output.home = scoreMap[homePointsScored.length];
      }
    } else {
      output.home = 0;
    }

    if (awayPointsScored?.length) {
      if (awayPointsScored?.length > 3) {
        output.away = "Game";
      } else {
        output.away = scoreMap[awayPointsScored.length];
      }
    } else {
      output.away = 0;
    }
  }
  return output;
};

const getTennisPointScore = ({
  stat,
  stats,
  server,
  isTieBreaker,
  homePlayer,
}) => {
  const orderedStats = stats.sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
  );
  // console.log("GET SCORE ORDERED STATS:", orderedStats);
  const statIndex = orderedStats.findIndex((s) => s.id === stat.id);
  const homePointsScored = orderedStats.filter(
    (s, i) =>
      (isTieBreaker ? homePlayer : server).profile?.id === s.profile?.id &&
      i <= statIndex
  );
  const awayPointsScored = orderedStats.filter(
    (s, i) =>
      (isTieBreaker ? homePlayer : server).profile?.id !== s.profile?.id &&
      i <= statIndex
  );

  const scores = determineCurrentTennisScore(
    homePointsScored,
    awayPointsScored,
    isTieBreaker
  );

  if (scores.home === "Deuce" || scores.away === "Deuce") {
    return "Deuce";
  } else if (scores.home === "Adv" || scores.away === "Adv") {
    return "Advantage";
  } else if (scores.home === "Game" || scores.away === "Game") {
    return "Game";
  } else {
    return scores.home + "-" + scores.away;
  }
};

function tennisScoreTillEvent({
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  period,
}) {
  const orderedStats = [...stats]
    .filter((s) => s.statType.name === "Games Played")
    .sort(
      (a, b) =>
        a.value - b.value ||
        (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
    );

  const statIndex = orderedStats.findIndex((x) => x.id === stat.id);

  const scores = orderedStats.reduce(
    (accum, curr, i) => {
      if (i <= statIndex) {
        const winner = curr.relationshipsFrom?.find(
          (r) => r.to.statType.name === "Games Won"
        )?.to;
        if (winner) {
          accum[winner.profile.id] = accum[winner.profile.id] + 1;
        }
      }

      return accum;
    },
    { [homeParticipant.profile.id]: 0, [awayParticipant.profile.id]: 0 }
  );

  return (
    scores[homeParticipant.profile.id] +
    "-" +
    scores[awayParticipant.profile.id]
  );

  // const homeScore = orderedStats.filter(
  //   (x, i) => x.profile.id === homeParticipant.profile.id && i <= statIndex
  // ).length;
  // const awayScore = orderedStats.filter(
  //   (x, i) => x.profile.id === awayParticipant.profile.id && i <= statIndex
  // ).length;
}

function badmintonScoreTillEvent({
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  period,
}) {
  const orderedStats = [...stats]
    .filter((s) => s.statType.name === "Point Won")
    .sort(
      (a, b) =>
        a.timeStamp - b.timeStamp ||
        (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
    );

  // if (period.order === 0) {
  //   console.log("Ordered Stats:", orderedStats, stat);
  // }

  // console.log("Target Stat:", stat);
  // let homeScore = 0;
  // let awayScore = 0;

  const statIndex = orderedStats.findIndex((x) => x.id === stat.id);

  if (statIndex < 0) {
    return "0-0";
  }

  const homeScore = orderedStats.filter(
    (x, i) =>
      (x.profile
        ? x.profile.id === homeParticipant.profile.id
        : x.organization.id === homeParticipant.organization.id) &&
      i <= statIndex
  ).length;
  const awayScore = orderedStats.filter(
    (x, i) =>
      (x.profile
        ? x.profile.id === awayParticipant.profile.id
        : x.organization.id === awayParticipant.organization.id) &&
      i <= statIndex
  ).length;

  // for (let i = 0; i < statIndex + 1; i++) {
  //   const curr = stats[i];
  //   if (curr.profile.id === homeParticipant.profile.id) {
  //     // console.log("Period:", period.label);
  //     homeScore += 1;
  //     // console.log("Incrementing Home Score:", homeScore);
  //   } else if (curr.profile.id === awayParticipant.profile.id) {
  //     // console.log("Period:", period.label);
  //     awayScore += 1;
  //     // console.log("Incrementing Away Score", awayScore);
  //   }
  //   console.log(homeScore, awayScore);
  // }

  // console.log("Stat Index:", statIndex);
  // console.log("Scores:", homeScore, awayScore);

  return homeScore + "-" + awayScore;
}

function CricketEventItem({
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  period,
  prev,
}) {
  // console.log("Cricket stat:", stat);
  // const ballsBowledStat = stat.relationshipsFrom.find(
  //   (s) => s.to.statType.name === "Balls Bowled"
  // )?.to;
  const isFreeHit =
    prev &&
    prev.relationshipsFrom?.find((r) => r.to?.statType?.name === "No Ball");

  const ballsBowledStat = stat;

  const runsScoredStat = stat.relationshipsTo.find(
    (s) => s.from?.statType?.name === "Runs Scored"
  )?.from;

  const extraStats = stats
    .find((s) => s.id === ballsBowledStat?.id)
    ?.relationshipsFrom?.filter((s) =>
      ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.to.statType.name)
    )
    .map((s) => s.to);

  const extraAbbrevs = {
    Wide: "w",
    "No Ball": "nb",
    Bye: "b",
    "Leg Bye": "lb",
  };

  const wicketStat = stats.find(
    (s) =>
      [
        "Bowled",
        "LBW",
        "Caught",
        "Caught & Bowled",
        "Run Out",
        "Stumped",
        "Retired",
      ].includes(s.statType.name) &&
      s.relationshipsTo?.find((r) => r.from?.id === runsScoredStat?.id)
  );

  // const wicketStat = runsScoredStat?.relationshipsFrom?.find((s) =>
  //   [
  //     "Bowled",
  //     "LBW",
  //     "Caught",
  //     "Caught & Bowled",
  //     "Run Out",
  //     "Stumped",
  //     "Retired",
  //   ].includes(s.to.statType.name)
  // )?.to;

  const wicketTakenStat =
    wicketStat &&
    stats
      .find((s) => s.id === wicketStat.id)
      .relationshipsFrom?.find((r) => r.to.statType.name === "Wicket Taken")
      ?.to;

  const fieldingStat =
    wicketStat &&
    wicketStat.statType.name !== "Bowled" &&
    wicketStat.statType.name !== "LBW" &&
    wicketStat.statType.name !== "Retired" &&
    wicketStat &&
    stats
      .find((s) => s.id === wicketStat.id)
      .relationshipsFrom?.find((r) =>
        ["Catch", "Run Out", "Stumping"].includes(r.to.statType.name)
      )?.to;

  const secondaryFieldingStat =
    wicketStat?.statType?.name === "Run Out" &&
    wicketStat.relationshipsFrom?.find(
      (r) => r.to.statType.name === "Run Out Stumping"
    )?.to;

  function dismissalStatement({
    wicketStat,
    wicketTakenStat,
    fieldingStat,
    secondaryFieldingStat,
  }) {
    const dismissalName = wicketStat.statType.name;

    if (dismissalName === "Bowled" || dismissalName === "LBW") {
      return (
        <span>
          {dismissalName} by{" "}
          <AppLink to={"/app/profile/" + wicketTakenStat.profile?.id}>
            <span className="bold">{wicketTakenStat.profile?.name}</span>
          </AppLink>
        </span>
      );
    } else if (dismissalName === "Retired") {
      return "";
    } else if (dismissalName === "Run Out") {
      if (secondaryFieldingStat) {
        return (
          <span>
            {dismissalName} (Throw by{" "}
            <AppLink to={"/app/profile/" + fieldingStat?.profile?.id}>
              <span className="bold">{fieldingStat?.profile?.name}</span>
            </AppLink>
            . Stumping by{" "}
            <AppLink to={"/app/profile/" + secondaryFieldingStat?.profile?.id}>
              <span className="bold">
                {secondaryFieldingStat?.profile?.name}
              </span>
            </AppLink>
            )
          </span>
        );
      } else {
        return (
          <span>
            {dismissalName} (Direct hit by{" "}
            <AppLink to={"/app/profile/" + fieldingStat?.profile?.id}>
              <span className="bold">{fieldingStat?.profile?.name}</span>
            </AppLink>
            )
          </span>
        );
      }
    } else if (
      dismissalName === "Caught" ||
      dismissalName === "Caught & Bowled" ||
      dismissalName === "Stumped"
    ) {
      return (
        <span>
          {dismissalName} by{" "}
          <AppLink to={"/app/profile/" + fieldingStat?.profile?.id}>
            <span className="bold">{fieldingStat?.profile?.name}</span>
          </AppLink>
        </span>
      );
    } else {
      return "";
    }
  }

  // console.log("Extra stats:", extraStats);

  return (
    <div className="fixture-events-summary__list__item cricket">
      <div className="ball">{ballsBowledStat?.stringValue}</div>

      <div
        className={
          "runs " +
          (runsScoredStat.value === 4 || runsScoredStat.value === 6
            ? "green"
            : "") +
          (wicketStat ? "red" : "")
        }
      >
        {wicketStat
          ? "W"
          : extraStats?.length
          ? extraStats.length > 1
            ? extraStats.reduce((accum, curr) => {
                accum += curr.value || 0;
                return accum;
              }, runsScoredStat.value) + "nb"
            : extraStats[0].value + extraAbbrevs[extraStats[0].statType.name]
          : runsScoredStat.value}
      </div>

      <div className="desc">
        {!!isFreeHit && (
          <div className="desc__title" style={{ marginBottom: "4px" }}>
            {/* <div className="desc__title__icon"></div> */}
            <div className="desc__title__text">FREE HIT</div>
          </div>
        )}

        <div className="desc-text">
          <AppLink to={"/app/profile/" + ballsBowledStat?.profile?.id}>
            <span className="bold">{ballsBowledStat?.profile?.name}</span>
          </AppLink>{" "}
          to{" "}
          <AppLink to={"/app/profile/" + runsScoredStat.profile?.id}>
            <span className="bold">{runsScoredStat.profile?.name}</span>
          </AppLink>
          {runsScoredStat.value ? ", " : ""}
          {runsScoredStat.value
            ? runsScoredStat.value +
              " Run" +
              (runsScoredStat.value === 1 ? "" : "s")
            : ""}{" "}
          {!!extraStats?.length ? ", " : ""}{" "}
          {extraStats?.map((x) => x.value + " " + x.statType.name).join(",")}
        </div>

        {wicketStat && (
          <Fragment>
            <div className="desc__title" style={{ marginBottom: "4px" }}>
              {/* <div className="desc__title__icon"></div> */}
              <div className="desc__title__text">WICKET!!!</div>
            </div>

            <div className="desc-text">
              <AppLink to={"/app/profile/" + wicketStat?.profile?.id}>
                <span className="bold">{wicketStat?.profile?.name}</span>
                {wicketStat?.statType?.name === "Retired"
                  ? " retired"
                  : " dismissed"}
                .{" "}
                {dismissalStatement({
                  wicketStat,
                  wicketTakenStat,
                  fieldingStat,
                  secondaryFieldingStat,
                })}
              </AppLink>
            </div>
          </Fragment>
        )}
      </div>
      <div className="profile-image">
        {runsScoredStat.profile?.profilePhoto && (
          <Avatar media={runsScoredStat.profile.profilePhoto} />
        )}
      </div>
      {runsScoredStat.organization && (
        <div className="team-image">
          {runsScoredStat.organization?.profilePhoto && (
            <Avatar media={runsScoredStat.organization.profilePhoto} />
          )}
        </div>
      )}
    </div>
  );
}

function RedCardEventItem({ stat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <div
              style={{
                height: "12px",
                width: "9px",
                background: "var(--red-main)",
                borderRadius: "2px",
                marginRight: "8px",
                marginTop: "1px",
              }}
            />
          </div>

          <div className="desc__title__text">Red Card</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            is sent off for{" "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>
            .
          </p>
        </div>
      </div>
      <div className="profile-image">
        {stat.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function YellowCardEventItem({ stat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <div
              style={{
                height: "12px",
                width: "9px",
                background: "var(--yellow-main)",
                borderRadius: "2px",
                marginRight: "8px",
                marginTop: "1px",
              }}
            />
          </div>

          <div className="desc__title__text">Yellow Card</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            of{" "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>{" "}
            gets a yellow card. .
          </p>
        </div>
      </div>
      <div className="profile-image">
        {stat.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function OwnGoalEventItem({ stat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <FootballIcon
              style={{
                height: "16px",
                fill: "var(--blue-main)",
              }}
            />
          </div>

          <div className="desc__title__text">OWN GOAL</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            of{" "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>{" "}
            scores an own goal.
          </p>
        </div>
      </div>
      <div className="profile-image">
        {stat.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function SubstitutionEventItem({ subOnStat, subOffStat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{subOffStat?.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <SwapIcon
              style={{
                height: "16px",
                fill: "var(--dark-4)",
                marginLeft: "-8px",
              }}
            />
          </div>

          <div className="desc__title__text">Substitution</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + subOffStat?.profile?.id}>
              <span className="bold">{subOffStat?.profile?.name}</span>
            </AppLink>{" "}
            of{" "}
            <AppLink to={"/app/page/" + subOffStat?.organization?.id}>
              <span className="bold">{subOffStat?.organization?.name}</span>
            </AppLink>{" "}
            comes off{subOnStat ? "and is replaced by " : "."}
            {subOnStat && (
              <AppLink to={"/app/profile/" + subOnStat?.profile?.id}>
                <span className="bold">{subOnStat?.profile?.name}</span>
              </AppLink>
            )}
          </p>
        </div>
      </div>
      <div className="profile-image">
        {subOffStat?.profile?.profilePhoto && (
          <Avatar media={subOffStat.profile.profilePhoto} />
        )}
      </div>

      <div className="profile-image">
        {subOnStat?.profile?.profilePhoto && (
          <Avatar media={subOnStat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {subOffStat?.organization?.profilePhoto && (
          <Avatar media={subOffStat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function GoalEventItem({ goalStatId, stats }) {
  const goalStat = stats.find((s) => s.id === goalStatId);
  const assistStat = goalStat.relationshipsFrom?.find(
    (s) => s?.to?.statType?.name === "Assist"
  )?.to;
  const keyPassStat = goalStat.relationshipsFrom?.find(
    (s) => s?.to?.statType?.name === "Key Pass"
  )?.to;
  const penaltyScored = goalStat.relationshipsFrom?.find(
    (s) => s?.to?.statType?.name === "Penalty Scored"
  )?.to;

  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{goalStat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <FootballIcon
              style={{
                height: "16px",
                fill: "var(--blue-main)",
                marginLeft: "-4px",
              }}
            />
          </div>

          <div className="desc__title__text">GOAL!!!</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + goalStat.profile?.id}>
              <span className="bold">{goalStat.profile?.name}</span>
            </AppLink>{" "}
            scores a {penaltyScored ? "penalty" : "goal"} for{" "}
            <AppLink to={"/app/page/" + goalStat.organization?.id}>
              <span className="bold">{goalStat.organization?.name}</span>
            </AppLink>
            .
          </p>
          {assistStat && (
            <p>
              Assisted by{" "}
              <AppLink to={"/app/profile/" + assistStat?.profile?.id}>
                <span className="bold">{assistStat?.profile?.name}</span>
              </AppLink>
              .
            </p>
          )}
          {keyPassStat && (
            <p>
              Key pass by{" "}
              <AppLink to={"/app/profile/" + keyPassStat?.profile?.id}>
                <span className="bold">{keyPassStat?.profile?.name}</span>
              </AppLink>
              .
            </p>
          )}
        </div>
      </div>
      <div className="profile-image">
        {goalStat.profile?.profilePhoto && (
          <Avatar media={goalStat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {goalStat.organization?.profilePhoto && (
          <Avatar media={goalStat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function TennisEventItem({
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  period,
}) {
  const isTieBreak = stat.relationshipsFrom.find(
    (r) => r.to.statType.name === "Tie Break"
  );
  const serve = stat.relationshipsFrom.find(
    (r) => r.to.statType.name === "Serve"
  )?.to;
  const wonBy = stat.relationshipsFrom.find(
    (r) => r.to.statType.name === "Games Won"
  )?.to;

  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp" style={{ fontWeight: 600 }}>
        {wonBy
          ? tennisScoreTillEvent({
              stat,
              stats: stats.filter((s) => s.fixturePeriod.id === period.id),
              homeParticipant,
              awayParticipant,
              period,
            })
          : "Ongoing"}
      </div>
      <div className="desc">
        {isTieBreak && (
          <div className="desc-text">
            <span className="bold" style={{ color: "var(--dark-4)" }}>
              Tie Breaker
            </span>

            {/* <span>{stat.statType.name} by</span>{" "}
          <AppLink to={"/app/profile/" + stat.profile?.id}>
            <span className="bold">{stat.profile?.name}</span>
          </AppLink>{" "}
          {stat.organization?.id && "for "}
          <AppLink to={"/app/page/" + stat.organization?.id}>
            <span className="bold">{stat.organization?.name}</span>
          </AppLink> */}
          </div>
        )}

        {wonBy && (
          <div className="desc desc__tennis" style={{ marginBottom: "16px" }}>
            <div className="desc-text">
              <AppLink to={"/app/profile/" + wonBy?.profile?.id}>
                <span className="bold">{wonBy?.profile?.name}</span>
              </AppLink>{" "}
              wins the game!
            </div>
          </div>
        )}

        {stat.relationshipsFrom
          .filter((r) => r.to.statType.name === "Points Won")
          .map((x) => x.to)
          .sort((a, b) =>
            new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
          )
          .map((s) => (
            <div
              key={s.id}
              className="desc desc__tennis"
              style={{ marginBottom: "8px" }}
            >
              <div
                className="desc__tennis__score"
                style={{ marginBottom: "4px" }}
              >
                <span className="bold" style={{ color: "var(--dark-4)" }}>
                  {getTennisPointScore({
                    stat: s,
                    stats: stat.relationshipsFrom
                      .filter((r) => r.to.statType.name === "Points Won")
                      .map((x) => x.to),
                    server: stat.relationshipsFrom.find(
                      (r) => r.to?.statType?.name === "Serve"
                    )?.to,
                    isTieBreaker: isTieBreak,
                    homePlayer: homeParticipant,
                  })}
                </span>
              </div>
              <div className="desc-text">
                <AppLink to={"/app/profile/" + s?.profile?.id}>
                  Point won by <span className="bold">{s?.profile?.name}</span>.
                </AppLink>{" "}
                {stats.find(
                  (x) =>
                    x.statType.name === "Unforced Error" &&
                    x.relationshipsTo?.find((r) => r.from.id === s.id)
                ) && (
                  <span>
                    Unforced error by{" "}
                    <AppLink
                      to={
                        "/app/profile/" +
                        stats.find(
                          (x) =>
                            x.statType.name === "Unforced Error" &&
                            x.relationshipsTo?.find((r) => r.from.id === s.id)
                        )?.profile?.id
                      }
                    >
                      <span className="bold">
                        {
                          stats.find(
                            (x) =>
                              x.statType.name === "Unforced Error" &&
                              x.relationshipsTo?.find((r) => r.from.id === s.id)
                          )?.profile?.name
                        }
                      </span>
                    </AppLink>
                  </span>
                )}
                {stats.find(
                  (x) =>
                    x.statType.name === "Double Fault" &&
                    x.relationshipsTo?.find((r) => r.from.id === s.id)
                ) && (
                  <span>
                    Double Fault by{" "}
                    <AppLink
                      to={
                        "/app/profile/" +
                        stats.find(
                          (x) =>
                            x.statType.name === "Double Fault" &&
                            x.relationshipsTo?.find((r) => r.from.id === s.id)
                        ).profile.id
                      }
                    >
                      <span className="bold">
                        {
                          stats.find(
                            (x) =>
                              x.statType.name === "Double Fault" &&
                              x.relationshipsTo?.find((r) => r.from.id === s.id)
                          )?.profile?.name
                        }
                      </span>
                    </AppLink>
                  </span>
                )}
                {stats.find(
                  (x) =>
                    x.statType.name === "Ace" &&
                    x.relationshipsTo?.find((r) => r.from.id === s.id)
                ) && <span>Ace!</span>}
              </div>
            </div>
          ))}

        {serve && (
          <div className="desc-text" style={{ marginTop: "16px" }}>
            <AppLink to={"/app/profile/" + serve.profile?.id}>
              <span className="bold">{serve.profile?.name}</span>
            </AppLink>{" "}
            to serve
          </div>
        )}
      </div>
      <div className="profile-image">
        {wonBy?.profile?.profilePhoto && (
          <Avatar media={wonBy.profile.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function BadmintonEventItem({
  fixture,
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  period,
}) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">
        {badmintonScoreTillEvent({
          stat,
          stats: stats.filter((s) => s.fixturePeriod.id === period.id),
          homeParticipant,
          awayParticipant,
          period,
        })}
      </div>
      <div className="desc">
        <div className="desc-text">
          <span>{stat.statType.name} by</span>{" "}
          <AppLink
            to={
              stat.profile
                ? "/app/profile/" + stat.profile?.id
                : "/app/page/" + stat.organization?.id
            }
          >
            <span className="bold">
              {stat.profile?.name || stat.organization?.name}
            </span>
          </AppLink>{" "}
        </div>
        {stat.relationshipsFrom.map((relation) => (
          <div key={relation.id} className="desc">
            <div className="desc-text">
              <span className="bold" style={{ color: "var(--dark-4)" }}>
                {relation?.to?.statType.name}
              </span>{" "}
              -{" "}
              <AppLink
                to={
                  relation?.to?.profile
                    ? "/app/profile/" + relation?.to?.profile?.id
                    : "/app/page/" + relation?.to?.organization?.id
                }
              >
                <span className="bold">
                  {relation?.to?.profile?.name ||
                    relation?.to?.organization?.name}
                </span>
              </AppLink>
            </div>
          </div>
        ))}
      </div>

      {stat.profile && (
        <div className="profile-image">
          {stat.profile?.profilePhoto && (
            <Avatar media={stat.profile.profilePhoto} />
          )}
        </div>
      )}

      {stat.organization && (
        <div className="team-image">
          {stat.organization?.profilePhoto && (
            <Avatar media={stat.organization.profilePhoto} />
          )}
        </div>
      )}
    </div>
  );
}

function EventItem({
  fixture,
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  period,
}) {
  if (fixture.event.sport.name === "Tennis") {
    return (
      <TennisEventItem
        stat={stat}
        stats={stats}
        homeParticipant={homeParticipant}
        awayParticipant={awayParticipant}
        period={period}
      />
    );
  } else if (fixture.event.sport.name === "Cricket") {
    return (
      <CricketEventItem
        stat={stat}
        stats={stats}
        homeParticipant={homeParticipant}
        awayParticipant={awayParticipant}
        period={period}
      />
    );
  } else if (fixture.event.sport.name === "Badminton") {
    return (
      <BadmintonEventItem
        stat={stat}
        stats={stats}
        homeParticipant={homeParticipant}
        awayParticipant={awayParticipant}
        period={period}
      />
    );
  } else if (
    stat.relationshipsFrom?.find((s) => s?.to?.statType.name === "Goal")
  ) {
    return (
      <GoalEventItem
        goalStatId={
          stat.relationshipsFrom?.find((s) => s?.to?.statType.name === "Goal")
            ?.to.id
        }
        stats={stats}
      />
    );
  } else if (stat.statType.name === "Goal") {
    return <GoalEventItem goalStatId={stat.id} stats={stats} />;
  } else if (stat.statType.name === "Own Goal") {
    return <OwnGoalEventItem stat={stat} />;
  } else if (stat.statType.name === "Red Card") {
    return <RedCardEventItem stat={stat} />;
  } else if (stat.statType.name === "Yellow Card") {
    return <YellowCardEventItem stat={stat} />;
  } else if (stat.statType.name === "Sub Off") {
    return (
      <SubstitutionEventItem
        subOffStat={stat}
        subOnStat={
          stat.relationshipsFrom.find((x) => x?.to?.statType.name === "Sub On")
            ?.to.id
        }
      />
    );
  } else {
    return (
      <div className="fixture-events-summary__list__item">
        <div className="timestamp">
          {fixture.event?.sport?.name === "Badminton"
            ? badmintonScoreTillEvent({
                stat,
                stats: stats.filter((s) => s.fixturePeriod.id === period.id),
                homeParticipant,
                awayParticipant,
                period,
              })
            : (stat.timeStamp || 1) + "'"}
        </div>
        <div className="desc">
          <div className="desc-text">
            <span>{stat.statType.name} by</span>{" "}
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            {stat.organization?.id && "for "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>
          </div>
          {stat.relationshipsFrom.map((relation) => (
            <div key={relation.id} className="desc">
              <div className="desc-text">
                <span className="bold" style={{ color: "var(--dark-4)" }}>
                  {relation?.to?.statType.name}
                </span>{" "}
                -{" "}
                <AppLink to={"/app/profile/" + relation?.to?.profile?.id}>
                  <span className="bold">{relation?.to?.profile?.name}</span>
                </AppLink>
              </div>
            </div>
          ))}
        </div>
        <div className="profile-image">
          {stat.profile?.profilePhoto && (
            <Avatar media={stat.profile.profilePhoto} />
          )}
        </div>
        {stat.organization && (
          <div className="team-image">
            {stat.organization?.profilePhoto && (
              <Avatar media={stat.organization.profilePhoto} />
            )}
          </div>
        )}
      </div>
    );
  }
}

function FixtureEventsSummary({ id, isMobile }) {
  const { loading, error, data } = useQuery(FIXTURE_TIMELINE, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureEventsSummary",
        query: "FIXTURE_TIMELINE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className={"fixture-events-summary " + (isMobile ? "mobile" : "")}>
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="fixture-events-summary__list">
          {[1, 2, 3].map((i) => (
            <div
              key={"fes-ph-" + i}
              className="fixture-events-summary__list__item"
            >
              <div className="timestamp">
                <Placeholder
                  style={{ height: "12px", width: "20px" }}
                  pulse={true}
                />
              </div>
              <div className="desc">
                <div className="desc-text">
                  <Placeholder
                    style={{ height: "12px", width: "160px" }}
                    pulse={true}
                  />
                </div>
              </div>
              <div className="profile-image"></div>
              <div className="team-image"></div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  // const uploaded = data?.fixture?.uploaded;
  const sport = data?.fixture?.event?.sport?.name;
  const stats = data?.fixture?.stats.filter((s) => !s.relationshipsTo?.length);
  const periods = data?.fixture?.periods || [];

  // console.log("RAW STATS:", data?.fixture?.stats);
  // console.log("FILTERED STATS:", stats);

  // if (uploaded) {
  //   return <div />;
  // }

  // console.log("Fixture Timeline:", data?.fixture);
  if (sport === "Cricket") {
    return <CricketEventsSummary fixture={data?.fixture} isMobile={isMobile} />;
  }

  const statsByPeriod = stats
    .reduce((accum, curr) => {
      const p = curr.fixturePeriod;
      // console.log("Curr p:", p);

      const existingPeriodEntry = accum.findIndex(
        (x) => x.period?.id === p?.id
      );

      // console.log(existingPeriodEntry);

      if (existingPeriodEntry < 0) {
        // console.log("Making New Group");
        accum.push({ period: { ...p }, stats: [curr] });
      } else {
        // console.log("Existing Group");
        accum[existingPeriodEntry].stats.push(curr);
      }

      return accum;
    }, [])
    .filter((g) => {
      const p = periods.find((x) => x.id === g.period.id);
      const isPenaltyShootout = p?.metas?.find(
        (m) => m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
      );
      if (isPenaltyShootout) {
        return false;
      } else {
        return true;
      }
    })
    .sort((a, b) => b.period.order - a.period.order);

  const homeParticipant = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayParticipant = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  // console.log("Home Participant:", homeParticipant);
  // console.log("Away Participant:", awayParticipant);
  // console.log("Stats by period:", statsByPeriod);

  return (
    <Card className={"fixture-events-summary " + (isMobile ? "mobile" : "")}>
      <div className="card-title">Match Events</div>
      <div className="fixture-events-summary__list">
        {!stats?.length && (
          <div className="no-data">No match events added yet</div>
        )}

        {statsByPeriod.map((group, i) => (
          <Fragment key={"stats-by-period-" + group.period?.id + i}>
            {data?.fixture?.completed && (
              <div className="fixture-events-summary__list__period">
                FULL TIME
              </div>
            )}
            {group.stats
              .sort(
                (a, b) =>
                  b.timeStamp - a.timeStamp ||
                  (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1)
              )
              .map((s) => (
                <EventItem
                  key={s.id}
                  fixture={data?.fixture}
                  stat={s}
                  stats={data?.fixture?.stats}
                  homeParticipant={homeParticipant}
                  awayParticipant={awayParticipant}
                  period={group.period}
                />
              ))}

            <div className="fixture-events-summary__list__period">
              START OF {group.period?.label?.toUpperCase()}
            </div>
          </Fragment>
        ))}
      </div>
      {/* <div className="fixture-events-summary__footer hover-pointer">
        Show More
      </div> */}
    </Card>
  );
}

export default FixtureEventsSummary;
