// V1
import "./ProfileGallerySummary.css";
import Card from "../../Card";
import { PROFILE_TAGGED_PHOTOS } from "../../../api/profile";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import Placeholder from "../../Placeholder";
import Avatar from "../../Avatar/Avatar";
import captureException from "../../../utils/captureException";

function ProfileGallerySummary({ id, profile }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data } = useQuery(PROFILE_TAGGED_PHOTOS, {
    variables: { id, type: "IMAGE", take: 8 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileGallerySummary",
        query: "PROFILE_MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="profile-overview-gallery-card">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "48px" }} pulse={true} />
        </div>
        <div className="gallery-grid">
          {[1, 2, 3, 4].map((m, i) => (
            <div
              key={"profile-gallery-summary-placeholder-" + i}
              className="gallery-grid-item"
              style={{ background: "var(--blue-subtle)" }}
            >
              <Placeholder
                style={{ height: "100%", width: "100%" }}
                pulse={true}
              />
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const photos = data?.profile?.taggedMedia || [];

  return (
    <Card className="profile-overview-gallery-card">
      <div className="card-title">
        <div>Photos of {profile.name}</div>
        <div
          className="card-title-link"
          onClick={() => {
            history.replace(location.pathname + "?t=photos");
          }}
        >
          See more photos
        </div>
      </div>
      {photos.length ? (
        <div className="gallery-grid">
          {photos.map((m) => (
            <div
              key={"profile-gallery-summary-" + m.id}
              className="gallery-grid-item hover-pointer"
              onClick={() => {
                history.replace(location.pathname + "?t=photos");
              }}
            >
              <Avatar media={m} />
              {/* <Image media={m} /> */}
            </div>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <div>{profile.name} has not been tagged in any photos yet.</div>
        </div>
      )}
    </Card>
  );
}

export default ProfileGallerySummary;
