// V1
import { useState, Fragment, useRef, useLayoutEffect } from "react";
import "./Directory.css";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Chip from "../../components/Chip";
import Spinner from "../../components/Spinner";
import FilterInput from "../../components/FilterInput";
import {
  DirectoryCard,
  PageCard,
  Prompt,
  DirectoryCategories,
} from "../../components/Directory";
import { useQuery } from "@apollo/client";
import { ORGANIZATIONS, ORGANIZATION_TYPES } from "../../api/organization";
import { SPORTS } from "../../api/sport";
import PageSearchBar from "../../components/PageSearchBar";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useAppState } from "../../utils/appState";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Placeholder from "../../components/Placeholder";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function ListLoading() {
  return (
    <Fragment>
      <Card className="directory-page-card">
        <div className={"image "}></div>
        <div className="meta">
          <div className="head">
            <div className="text">
              <div className="h6">
                <Placeholder
                  style={{ height: "14px", width: "120px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary-text">
                <Placeholder
                  style={{ height: "14px", width: "60px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary-text">
                <Placeholder
                  style={{ height: "14px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="directory-page-card">
        <div className={"image "}></div>
        <div className="meta">
          <div className="head">
            <div className="text">
              <div className="h6">
                <Placeholder
                  style={{ height: "14px", width: "120px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary-text">
                <Placeholder
                  style={{ height: "14px", width: "60px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary-text">
                <Placeholder
                  style={{ height: "14px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="directory-page-card">
        <div className={"image "}></div>
        <div className="meta">
          <div className="head">
            <div className="text">
              <div className="h6">
                <Placeholder
                  style={{ height: "14px", width: "120px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary-text">
                <Placeholder
                  style={{ height: "14px", width: "60px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary-text">
                <Placeholder
                  style={{ height: "14px", width: "60px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

function DirectoryLocationFilter({
  defaultValue,
  placeholder,
  searchOptions,
  addFilter,
  removeFilter,
  locations,
  setLocations,
}) {
  const [searchString, setSearchString] = useState("");
  const [, setAppState] = useAppState();

  const onChange = (x) => {
    setSearchString(x);
  };

  const onSelect = (address) => {
    // console.log(address);
    setSearchString("");
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const city = address?.split(",")[0].trim();
        const newLocations = [
          ...locations,
          { name: city, address: address, lat: latLng.lat, lng: latLng.lng },
        ];
        setLocations(newLocations);
        addFilter(newLocations);
        setAppState({ directoryLocations: newLocations });
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <div className="filter-group">
      <div className="filter-group-input">
        <PlacesAutocomplete
          value={searchString}
          onChange={onChange}
          onSelect={onSelect}
          debounce={200}
          searchOptions={
            searchOptions || {
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }
          }
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <div className="location-input-wrapper">
                <div className="app-input">
                  <div className="input-wrapper">
                    <input
                      {...getInputProps({
                        placeholder: placeholder || "Enter a location",
                        className: "location-search-input",
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="autocomplete-dropdown-container">
                {loading && (
                  <div>
                    <Spinner />
                  </div>
                )}

                {suggestions
                  .filter(
                    (suggestion) =>
                      suggestion?.description?.split(",")?.length &&
                      suggestion?.description?.split(",").length > 2
                  )
                  .map((suggestion, i) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={"suggestion-" + i}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style: {
                            padding: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            ...style,
                          },
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="filter-group-applied">
        {locations.map((t, i) => (
          <Chip
            key={"location-chip-" + i}
            onClick={() => {
              const newLocations = locations.filter((x) => x.name !== t.name);
              setLocations(newLocations);
              removeFilter(newLocations);
              setAppState({ directoryLocations: newLocations });
              // refetch({
              //   where: {
              //     ...(variables.where || {}),
              //     locations: locations.map((l) => l.id),
              //   },
              // });
            }}
          >
            {t.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  value,
  setValue,
}) {
  // const [value, setValue] = useState(defaultValue || []);
  const [, setAppState] = useAppState();

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            setAppState({ [appStateKey]: newValue });
          }}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            key={s.id}
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function NoOrgsPlaceholder({ refetch }) {
  return (
    <div className="no-data">
      <div>There are no pages for this selection.</div>
      <div
        className="link hover-pointer hover-underline"
        onClick={() => {
          refetch();
        }}
      >
        Check again
      </div>
    </div>
  );
}

function Directory() {
  usePageTracking({ title: "Directory" });
  const [
    {
      directorySearch = "",
      directoryCategory,
      directorySports,
      directoryTypes,
      directoryLocations,
    },
    setAppState,
  ] = useAppState();
  const [leftSticky, setLeftSticky] = useState("sticky-top");
  const [rightSticky, setRightSticky] = useState("sticky-top");
  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);
  const [sports, setSports] = useState(directorySports || []);
  const [types, setTypes] = useState(directoryTypes || []);
  const [locations, setLocations] = useState(directoryLocations || []);
  const [category, setCategory] = useState("");
  const { error, data, networkStatus, variables, fetchMore, refetch } =
    useQuery(ORGANIZATIONS, {
      variables: { where: {} },
      notifyOnNetworkStatusChange: true,
    });

  useLayoutEffect(() => {
    const elementHeight = leftColumnRef?.current.clientHeight;
    // const elementY = leftColumnRef?.current.offsetTop;
    const elementY = 80;
    const viewportHeight = window.innerHeight;

    // console.log(elementHeight, elementY, viewportHeight);

    if (elementY + elementHeight > viewportHeight) {
      // console.log("Setting left to sticky bottom");
      setLeftSticky("sticky-bottom");
    }
  }, []);

  useLayoutEffect(() => {
    const elementHeight = rightColumnRef?.current.clientHeight;
    const elementY = rightColumnRef?.current.offsetTop;
    const viewportHeight = window.innerHeight;

    // console.log(elementHeight, elementY, viewportHeight);
    if (elementY + elementHeight > viewportHeight - 32) {
      // console.log("Setting right to sticky bottom");
      setRightSticky("sticky-bottom");
    }
  }, []);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Directory",
        query: "ORGANIZATIONS",
      },
    });
    console.log(error);
    return <div />;
  }

  const onSearchSubmit = (searchTerm) => {
    refetch({
      where: {
        ...(variables.where || {}),
        name: searchTerm,
      },
    });
    setAppState({ directorySearch: searchTerm });
  };

  const onCategorySelect = (category) => {
    setCategory(category);
    refetch({
      where: {
        ...(variables.where || {}),
        types: [...(types || []), ...(category ? [category] : [])],
      },
    });
    setAppState({ directoryCategory: category });
  };

  const organizations = data?.organizations || [];
  const methodDoesNotExist = "";

  return (
    <div className="App-content">
      <div className="directory-page">
        <div className={"left-column " + leftSticky}>
          <div className={"sticky-wrapper " + leftSticky} ref={leftColumnRef}>
            <DirectoryCard />
          </div>
        </div>

        <div className="middle-column">
          <PageSearchBar
            onSearchSubmit={onSearchSubmit}
            placeholder={"Search the directory..."}
            defaultValue={directorySearch}
            disabled={networkStatus === 1}
          />
          <DirectoryCategories
            onCategorySelect={onCategorySelect}
            defaultValue={directoryCategory}
          />

          <div className="card-section">
            {(networkStatus === 1 || networkStatus === 2) && <ListLoading />}
            {networkStatus !== 2 &&
              organizations.map((o) => (
                <PageCard key={o.id} organization={o} variables={variables} />
              ))}
          </div>

          {!(networkStatus === 1 || !data) && !organizations?.length && (
            <NoOrgsPlaceholder refetch={refetch} />
          )}

          {!!organizations?.length &&
            networkStatus !== 1 &&
            networkStatus !== 2 && (
              <div className="show-more-container">
                <Button
                  className="primary small blue"
                  onClick={() => {
                    if (networkStatus === 3) return;
                    fetchMore({
                      variables: {
                        ...variables,
                        cursor: organizations[organizations.length - 1].id,
                      },
                    });
                  }}
                >
                  {networkStatus === 3 ? (
                    <Loader theme={"small"} />
                  ) : (
                    "Show More"
                  )}
                </Button>
              </div>
            )}
        </div>

        <div className={"right-column " + rightSticky}>
          <div className={"sticky-wrapper " + rightSticky} ref={rightColumnRef}>
            <Prompt />
            {networkStatus !== 1 && (
              <div className="card-section">
                <div className="card-section-title">
                  Filters
                  <div
                    className="reset hover-pointer"
                    onClick={() => {
                      refetch({ where: {} });
                      setSports([]);
                      setTypes([]);
                      setLocations([]);
                      setAppState({
                        directorySports: [],
                        directoryTypes: [],
                        directoryLocations: [],
                      });
                    }}
                  >
                    Reset
                  </div>
                </div>

                <DirectoryFilter
                  placeholder={"Enter a sport"}
                  query={SPORTS}
                  items={(d) => {
                    return d?.sports;
                  }}
                  addFilter={(sports) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        sports: sports.map((s) => s.id),
                      },
                    });
                  }}
                  removeFilter={(sports) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        sports: sports.map((s) => s.id),
                      },
                    });
                  }}
                  defaultValue={directorySports}
                  appStateKey={"directorySports"}
                  value={sports}
                  setValue={setSports}
                />

                <DirectoryFilter
                  placeholder={"Enter a category"}
                  query={ORGANIZATION_TYPES}
                  items={(d) => {
                    return d.organizationTypes;
                  }}
                  addFilter={(types) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        types: [
                          ...types.map((x) => x.id),
                          ...(category ? [category] : []),
                        ],
                      },
                    });
                  }}
                  removeFilter={(types) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        types: [
                          ...types.map((x) => x.id),
                          ...(category ? [category] : []),
                        ],
                      },
                    });
                  }}
                  defaultValue={directoryTypes}
                  appStateKey={"directoryTypes"}
                  value={types}
                  setValue={setTypes}
                />

                <DirectoryLocationFilter
                  defaultValue={directoryLocations}
                  placeholder={"Enter a location"}
                  addFilter={(cities) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        cities: cities.map((s) => s.name),
                      },
                    });
                  }}
                  removeFilter={(cities) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        cities: cities.map((s) => s.name),
                      },
                    });
                  }}
                  locations={locations}
                  setLocations={setLocations}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directory;
