import "./Event.css";
import { useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import {
  EventOverview,
  EventFixtures,
  EventPhotos,
  EventVideos,
  EventStandings,
  EventStats,
} from "../../mobileComponents/Event";
import useRouterQuery from "../../utils/useRouterQuery";
import { EVENT, UPDATE_EVENT } from "../../api/event";
import { CAN_MANAGE_EVENT } from "../../api/user";
import { useQuery } from "@apollo/client";
import Placeholder from "../../components/Placeholder";
import RegisterButton from "../../mobileComponents/RegisterButton";
import { Link } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import { useAppState } from "../../utils/appState";
import EditDisplayPhoto from "../../mobileComponents/EditDisplayPhoto";
import PhotoBrowser from "../../mobileComponents/PhotoBrowser";

const eventTabs = [
  { label: "Overview", component: EventOverview, key: "overview" },
  { label: "Fixtures", component: EventFixtures, key: "fixtures" },
  { label: "Standings", component: EventStandings, key: "standings" },
  { label: "Photos", component: EventPhotos, key: "photos" },
  { label: "Videos", component: EventVideos, key: "videos" },
  { label: "Statistics", component: EventStats, key: "statistics" },
];

function Event() {
  usePageTracking({ title: "Event" });
  const [, setAppState] = useAppState();
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const headerRef = useRef(null);
  const isOnScreen = useIntersectionObserver(headerRef, true);
  const tab = query.get("t") || "overview";
  const selectedTab = eventTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const authQuery = useQuery(CAN_MANAGE_EVENT, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(EVENT, {
    variables: { id },
  });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Event",
        query: "CAN_MANAGE_EVENT",
      },
    });
    console.log(error);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="organization-page-mobile">
        <div className="organization-page-mobile__nav">
          <div
            className="organization-page-mobile__nav__back-btn"
            onClick={() => {
              if (location.key) {
                history.goBack();
              } else {
                history.replace("/app/directory");
              }
            }}
          >
            <ArrowLeftIcon
              style={{ height: "16px", stroke: "var(--dark-4)" }}
            />
          </div>
        </div>
        <div className="organization-page-mobile__cover" ref={headerRef}></div>

        <div className="organization-page-mobile__header">
          <div className="organization-page-mobile__header__image"></div>

          <div className="organization-page-mobile__header__desc">
            <div className="organization-page-mobile__header__desc__primary">
              <Placeholder
                style={{ height: "12px", width: "100px" }}
                pulse={true}
              />
            </div>

            <div className="organization-page-mobile__header__desc__secondary">
              <Placeholder
                style={{ height: "12px", width: "60px", marginTop: "8px" }}
                pulse={true}
              />
            </div>

            <Placeholder
              style={{
                height: "24px",
                width: "80px",
                background: "var(--blue-subtle)",
                borderRadius: "8px",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-page-mobile__tabs">
          {[1, 2, 3].map((t, i) => (
            <div
              key={"org-tab-" + i}
              className={"organization-page-mobile__tabs__tab "}
            >
              <Placeholder
                style={{
                  height: "10px",
                  width: "40px",
                  background: "var(--light-2)",
                }}
                pulse={false}
              />
            </div>
          ))}
        </div>

        <div className="organization-page-mobile__body"></div>
      </div>
    );
  }

  const ev = data.event;
  const canManageEvent = authQuery?.data?.canManageEvent?.authorized;

  console.log("CAN MANAGE:", canManageEvent);

  return (
    <div className="event-page-mobile">
      <div className="event-page-mobile__nav">
        <div
          className="event-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app/events");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
        {!isOnScreen && (
          <div className="event-page-mobile__nav__content">
            <div className="event-page-mobile__nav__content__img">
              {ev.profilePhoto && <Avatar media={ev.profilePhoto} />}
            </div>
            {ev.name}
          </div>
        )}
      </div>

      <div className="event-page-mobile__cover" ref={headerRef}>
        <div
          className="profile-page-mobile__cover__pressable"
          onClick={() => {
            if (!ev?.coverPhoto) return;
            setAppState({
              modal: (
                <PhotoBrowser
                  query={EVENT}
                  vars={{ id: ev.id }}
                  getPhotos={(data) => data.event?.coverPhotos || []}
                  totalPhotos={(data) => data.event?.coverPhotos?.length || 0}
                  media={ev.coverPhoto}
                />
              ),
            });
          }}
        >
          {ev?.coverPhoto && <Avatar media={ev.coverPhoto} />}
        </div>

        {canManageEvent && (
          <div
            className="profile-page-mobile__cover__edit-btn"
            onClick={(e) => {
              e.stopPropagation();
              setAppState({
                modal: (
                  <EditDisplayPhoto
                    config={{
                      wrapper: {
                        title: "Cover Photo",
                        query: EVENT,
                        getPhotos: (data) => data?.event?.coverPhotos || [],
                        getSelectedPhoto: (data) => data?.event?.coverPhoto,
                        defaultUploadState: ev?.coverPhotos?.length
                          ? false
                          : true,
                        sourceId: ev?.id,
                        sourceType: "event",
                      },
                      select: {
                        mutation: UPDATE_EVENT(`coverPhoto{id filename}`),
                        mutationVars: (connectOrDisconnectInput) => {
                          return {
                            coverPhoto: connectOrDisconnectInput,
                          };
                        },
                        update: (cache, { data: { updateEvent } }) => {
                          const q = cache.readQuery({
                            query: EVENT,
                            variables: { id: ev.id },
                          });

                          cache.writeQuery({
                            query: EVENT,
                            variables: { id: ev.id },
                            data: {
                              event: {
                                ...q.event,
                                coverPhoto: updateEvent.coverPhoto,
                              },
                            },
                          });

                          // setAppState({ modal: false });
                        },
                      },
                      upload: {
                        accept: ".jpg,.png,.jpeg",
                        multiple: false,
                        type: "IMAGE",
                        subtype: "COVER PHOTO",
                        onCreateMedia: (cache, createMedia) => {
                          const q = cache.readQuery({
                            query: EVENT,
                            variables: { id: ev.id },
                          });

                          cache.writeQuery({
                            query: EVENT,
                            variables: { id: ev.id },
                            data: {
                              event: {
                                ...q.event,
                                coverPhoto: {
                                  ...createMedia,
                                },
                                coverPhotos: [
                                  ...q.event.coverPhotos,
                                  { ...createMedia },
                                ],
                              },
                            },
                          });

                          setAppState({ modal: false });
                        },
                        cropperWidth: 240,
                        cropperHeight: 40,
                      },
                    }}
                  />
                ),
              });
            }}
          >
            <PencilIcon className="icon" />
          </div>
        )}
      </div>

      <div className="event-page-mobile__header">
        <div className="event-page-mobile__header__image">
          <div
            className="profile-page-mobile__header__image__pressable"
            onClick={() => {
              if (!ev?.profilePhoto) return;
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={EVENT}
                    vars={{ id: ev.id }}
                    getPhotos={(data) => data.ev?.profilePhotos || []}
                    totalPhotos={(data) => data.ev?.profilePhotos?.length || 0}
                    media={ev.profilePhoto}
                  />
                ),
              });
            }}
          >
            {ev.profilePhoto && <Avatar media={ev.profilePhoto} />}
          </div>

          {canManageEvent && (
            <div
              className="profile-page-mobile__cover__edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setAppState({
                  modal: (
                    <EditDisplayPhoto
                      config={{
                        wrapper: {
                          title: "Profile Photo",
                          query: EVENT,
                          getPhotos: (data) => data?.event?.profilePhotos || [],
                          getSelectedPhoto: (data) => data?.event?.profilePhoto,
                          defaultUploadState: ev?.profilePhotos?.length
                            ? false
                            : true,
                          sourceId: ev.id,
                          sourceType: "event",
                        },
                        select: {
                          mutation: UPDATE_EVENT(`profilePhoto{id filename}`),
                          mutationVars: (connectOrDisconnectInput) => {
                            return {
                              profilePhoto: connectOrDisconnectInput,
                            };
                          },
                          update: (cache, { data: { updateEvent } }) => {
                            const q = cache.readQuery({
                              query: EVENT,
                              variables: { id: ev.id },
                            });

                            cache.writeQuery({
                              query: EVENT,
                              variables: { id: ev.id },
                              data: {
                                event: {
                                  ...q.event,
                                  profilePhoto: updateEvent.profilePhoto,
                                },
                              },
                            });

                            // setAppState({ modal: false });
                          },
                        },
                        upload: {
                          multiple: false,
                          accept: ".jpg,.png,.jpeg",
                          type: "IMAGE",
                          subtype: "PROFILE PHOTO",
                          onCreateMedia: (cache, createMedia) => {
                            const q = cache.readQuery({
                              query: EVENT,
                              variables: { id: ev.id },
                            });

                            cache.writeQuery({
                              query: EVENT,
                              variables: { id: ev.id },
                              data: {
                                event: {
                                  ...q.event,
                                  profilePhoto: {
                                    ...createMedia,
                                  },
                                  profilePhotos: [
                                    ...q.event.profilePhotos,
                                    { ...createMedia },
                                  ],
                                },
                              },
                            });

                            setAppState({ modal: false });
                          },
                          // cropperWidth: 240,
                          // cropperHeight: 40,
                        },
                      }}
                    />
                  ),
                });
              }}
            >
              <PencilIcon className="icon" />
            </div>
          )}
        </div>

        <div className="event-page-mobile__header__desc">
          <div className="event-page-mobile__header__desc__primary">
            {ev.name}
          </div>

          <div className="event-page-mobile__header__desc__secondary">
            {ev.venue ? (
              <Link
                to={"/app/page/" + ev.venue.id}
                style={{ color: "var(--blue-main" }}
              >
                {ev.venue.name}
              </Link>
            ) : (
              (ev.venueLabel
                ? ev.venueLabel + (ev.city?.value ? ", " : "")
                : "") + (ev.city?.value || "") || "Location TBA"
            )}{" "}
          </div>

          <RegisterButton id={id} />
          {/* <Button
            className={"primary blue medium option register-button "}
            onClick={() => {
              history.push("/app/register/" + id);
            }}
          >
            Register
          </Button> */}
          {/* <FollowButton
            id={id}
            type={"organization"}
            onFollow={(cache) => {
              const q = cache.readQuery({
                query: INTERACTION_STATS,
                variables: { id },
              });

              if (q?.interactionStats) {
                cache.writeQuery({
                  query: INTERACTION_STATS,
                  variables: { id },
                  data: {
                    interactionStats: {
                      ...q.interactionStats,
                      followers: q.interactionStats.followers + 1,
                    },
                  },
                });
              }
            }}
            onUnfollow={(cache) => {
              const q = cache.readQuery({
                query: INTERACTION_STATS,
                variables: { id },
              });

              if (q?.interactionStats) {
                cache.writeQuery({
                  query: INTERACTION_STATS,
                  variables: { id },
                  data: {
                    interactionStats: {
                      ...q.interactionStats,
                      followers: q.interactionStats.followers - 1,
                    },
                  },
                });
              }
            }}
          /> */}
        </div>
      </div>

      <div className="event-page-mobile__tabs">
        {eventTabs.map((t, i) => (
          <div
            key={"ev-tab-" + i}
            className={
              "event-page-mobile__tabs__tab " +
              (selectedTab.key === t.key ? "active" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}

        {/* <div className="organization-page-mobile__tabs__tab active">
          Overview
        </div>

        <div className="organization-page-mobile__tabs__tab">Events</div>

        <div className="organization-page-mobile__tabs__tab">Posts</div>

        <div className="organization-page-mobile__tabs__tab">Photos</div>

        <div className="organization-page-mobile__tabs__tab">Videos</div>

        <div className="organization-page-mobile__tabs__tab">Services</div>

        <div className="organization-page-mobile__tabs__tab">Statistics</div> */}
      </div>

      <div className="event-page-mobile__body">
        <SelectedComponent id={id} ev={ev} canManageEvent={canManageEvent} />
      </div>
    </div>
  );
}

export default Event;
