import "./TossSettings.css";
import { Fragment, useState } from "react";
import Card from "../../../components/Card";
import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/functions";
import Spinner from "../../../components/Spinner";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";

const tossSelectionSet = `
  metas {
    id
    label
    shortLabel
    value
    stringValue
  }
  roles(where:{type:{name:"PARTICIPANT"}}){
    id
    order
    type{
      id
      name
    }
    organization {
      id
      name
      profilePhoto {
        id
        filename
      }
    }
  }
`;

function EditToss({
  winnerMeta,
  decisionMeta,
  fixture,
  participants,
  dismiss,
}) {
  const [winner, setWinner] = useState(winnerMeta?.stringValue || "");
  const [decision, setDecision] = useState(decisionMeta?.stringValue || "");
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: tossSelectionSet,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            metas() {
              return updateFixture.metas;
            },
          },
        });

        dismiss();
      },
    }
  );

  return (
    <form
      className="ScoringApp-football__settings__period-settings__form"
      onSubmit={(e) => {
        e.preventDefault();

        if (loading) return;

        const winnerVars = {};
        const decisionVars = {};

        if (winnerMeta?.id) {
          winnerVars.update = {
            where: { id: winnerMeta.id },
            data: {
              stringValue: winner,
            },
          };
        } else {
          winnerVars.create = [
            {
              label: "tossWinner",
              stringValue: winner,
            },
          ];
        }

        if (decisionMeta?.id) {
          decisionVars.update = {
            where: { id: decisionMeta.id },
            data: {
              stringValue: decision,
            },
          };
        } else {
          decisionVars.create = [
            {
              label: "tossDecision",
              stringValue: decision,
            },
          ];
        }

        updateFixture({
          variables: {
            where: { id: fixture.id },
            data: {
              metas: winnerVars,
            },
          },
        });

        updateFixture({
          variables: {
            where: { id: fixture.id },
            data: {
              metas: decisionVars,
            },
          },
        });
      }}
    >
      <div className="ScoringApp-football__settings__period-settings__form__row">
        <div className="ScoringApp-football__settings__period-settings__form__row__group text">
          <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
            Winner
          </div>
          <select
            value={winner}
            onChange={(e) => {
              setWinner(e.target.value);
            }}
            className="ScoringApp-football__settings__period-settings__form__row__group__input"
          >
            <option value={""}>Select team</option>
            {participants.map((p) => (
              <option value={p.organization?.id}>{p.organization?.name}</option>
            ))}
          </select>
        </div>

        <div className="ScoringApp-football__settings__period-settings__form__row__group text">
          <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
            Decision
          </div>
          <select
            value={decision}
            onChange={(e) => {
              setDecision(e.target.value);
            }}
            className="ScoringApp-football__settings__period-settings__form__row__group__input"
          >
            <option value={""}>Select decision</option>
            <option value={"decided to bat"}>decided to bat</option>
            <option value={"decided to bowl"}>decided to bowl</option>
          </select>
        </div>

        <div className="ScoringApp-football__settings__period-settings__form__row__options">
          <button
            type="submit"
            className={
              "ScoringApp-football__settings__period-settings__form__row__options__option submit " +
              (loading ? "disabled" : "")
            }
          >
            {loading ? (
              <Spinner size={12} color={"var(--green-darker)"} />
            ) : (
              <CheckIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
            )}
          </button>
          <div
            className={
              "ScoringApp-football__settings__period-settings__form__row__options__option cancel " +
              (loading ? "disabled" : "")
            }
            onClick={() => {
              dismiss();
            }}
          >
            <TimesIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
          </div>
        </div>
      </div>
    </form>
  );
}

export default function TossSettings({ id }) {
  const [editing, setEditing] = useState(false);
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: tossSelectionSet,
    }),
    {
      variables: {
        id,
      },
    }
  );

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.PeriodSettings",
    //     query: "FIXTURE_STATS",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  const metas = data?.fixture?.metas;
  const dlsStatusMeta = metas?.find((m) => m.label === "dlsStatus");
  const tossWinnerMeta = metas?.find((m) => m.label === "tossWinner");
  const tossDecisionMeta = metas?.find((m) => m.label === "tossDecision");

  const participants = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );
  const tossWinner = participants?.find(
    (p) => p.organization.id === tossWinnerMeta?.stringValue
  );

  const statement = tossWinner
    ? tossWinner.organization?.name +
      " won the toss" +
      (tossDecisionMeta ? " and " + tossDecisionMeta.stringValue : ".")
    : "-";

  const canEdit = !dlsStatusMeta?.stringValue;

  return (
    <Card className="ScoringApp-football__settings__period-settings">
      <div className="ScoringApp-football__settings__period-settings__title">
        Edit toss results
      </div>

      <div className="ScoringApp-football__settings__period-settings__body">
        {loading || !data ? (
          <div className="ScoringApp-football__settings__period-settings__body__loading">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {editing ? (
              <EditToss
                winnerMeta={tossWinnerMeta}
                decisionMeta={tossDecisionMeta}
                dismiss={() => {
                  setEditing(false);
                }}
                fixture={data?.fixture}
                participants={participants}
              />
            ) : (
              <div className="ScoringApp-football__settings__period-settings__period">
                <div className="ScoringApp-football__settings__period-settings__period__details">
                  {statement}
                </div>
                <div className="ScoringApp-football__settings__period-settings__period__options">
                  <div
                    className={
                      "ScoringApp-football__settings__period-settings__period__options__option edit " +
                      (!canEdit ? "disabled" : "")
                    }
                    onClick={() => {
                      if (canEdit) {
                        setEditing(true);
                      }
                    }}
                  >
                    <PencilIcon className="ScoringApp-football__settings__period-settings__period__options__option__icon" />
                  </div>
                </div>
              </div>
            )}

            {/* {periods.map((p, i) => (
              <Period key={p.id} period={p} index={i} fixtureId={id} />
            ))}

            {adding ? (
              <CreateOrEditPeriod
                dismiss={() => {
                  setAdding(false);
                }}
                order={periods.length}
                fixtureId={id}
              />
            ) : (
              <div
                className="ScoringApp-football__settings__period-settings__body__add-period"
                onClick={() => {
                  setAdding(true);
                }}
              >
                + Add a period
              </div>
            )} */}
          </Fragment>
        )}
      </div>
    </Card>
  );
}
