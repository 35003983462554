// V1
import "./ProfileOverview.css";
import Card from "../../components/Card";
import Activity from "../../components/Activity";
import ProfileOrgsSummary from "./ProfileOrgsSummary";
import ProfileGallerySummary from "./ProfileGallerySummary";
import ProfileParticipationSummary from "./ProfileParticipationSummary";
import ProfilePostsSummary from "./ProfilePostsSummary";

function ProfileOverview({ id, profile }) {
  return (
    <div className="profile-overview">
      <div className="left-column">
        <Card className="profile-activity-card">
          <Activity />
        </Card>

        <ProfileGallerySummary id={id} profile={profile} />

        <ProfilePostsSummary id={id} />
      </div>

      <div className="right-column">
        <ProfileOrgsSummary id={id} profile={profile} />

        <ProfileParticipationSummary id={id} profile={profile} />
      </div>
    </div>
  );
}

export default ProfileOverview;
