// V1
import "./FixtureLineups.css";
import Card from "../Card";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import Spinner from "../Spinner";
import Pitch from "../Pitch";
import NewDropdown from "../NewDropdown";
import AppLink from "../AppLink";
import {
  // FIXTURE,
  UPDATE_FIXTURE_ROLE,
  CREATE_FIXTURE_ROLE,
  DELETE_FIXTURE_ROLE,
} from "../../api/fixture";
import { FIXTURE } from "../../api/functions";
import { EVENT_REGISTRATIONS } from "../../api/event";
import { FORMATIONS } from "../../api/sport";
import { Fragment, useState } from "react";
import { ReactComponent as ListIcon } from "../../icons/list.svg";
import { ReactComponent as PitchIcon } from "../../icons/pitch.svg";
import { ReactComponent as ChevronDown } from "../../icons/chevron-down.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { useAppState } from "../../utils/appState";
import Placeholder from "../Placeholder/Placeholder";
import Avatar from "../Avatar";
import captureException from "../../utils/captureException";

function DeleteLineupPlayer({ onClick }) {
  const [loading, setLoading] = useState(false);
  return (
    <div
      className="starters__body__list__item__option delete"
      onClick={() => {
        if (loading) return;
        setLoading(true);
        onClick();
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <TrashIcon className="starters__body__list__item__option__icon" />
      )}
    </div>
  );
}

function EditJerseyNumber({ role, canManageFixture, players }) {
  // console.log(players);

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(role.order || "");
  const [updateFixtureRole, { loading }] = useMutation(UPDATE_FIXTURE_ROLE, {
    update: (cache, { data: { updateFixtureRole } }) => {
      cache.modify({
        id: cache.identify(role),
        fields: {
          order() {
            return updateFixtureRole.order;
          },
        },
      });

      setEditing(false);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FixtureLineups.EditJerseyNumber",
          mutation: "UPDATE_FIXTURE_ROLE",
        },
      });
      console.log(error);
      setError(true);
    },
  });

  return (
    <Fragment>
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (loading) {
              return;
            }

            if (value && Number(value) === role.order) {
              setEditing(false);
            }

            const numTaken =
              value &&
              Number(value) &&
              players.find((x) => x.order === Number(value));
            if (numTaken) {
              // console.log("numTaken by:", numTaken);
              setError(true);
              return;
            }
            updateFixtureRole({
              variables: {
                where: { id: role.id },
                data: {
                  order: Number(value),
                },
              },
            });
          }}
        >
          <input
            type="text"
            disabled={loading}
            className={
              "starters__body__list__item__option placeholder " +
              (error ? "error " : " ")
            }
            value={value}
            onChange={(e) => {
              setError(false);
              setValue(e.target.value);
            }}
          />
        </form>
      ) : (
        <div
          className="starters__body__list__item__option placeholder"
          onClick={() => {
            if (canManageFixture) {
              setEditing(true);
            }
          }}
        >
          {typeof role.order === "number"
            ? role.order
            : canManageFixture
            ? "J.Num"
            : ""}
        </div>
      )}
    </Fragment>
  );
}

function FormationsList({ sportId, fixtureRole, setShowMenu }) {
  const { loading, error, data } = useQuery(FORMATIONS, {
    variables: { where: { sport: { id: sportId } } },
  });

  const [updateFixtureRole, { loading: submitting }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      update: (cache, { data: { updateFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixtureRole),
          fields: {
            formation(_, { INVALIDATE }) {
              if (!updateFixtureRole.formation) {
                return INVALIDATE;
              }
              const newFormationRef = cache.writeFragment({
                data: updateFixtureRole.formation,
                fragment: gql`
                  fragment NewFormation on Formation {
                    id
                    name
                    positions {
                      id
                      positionType {
                        id
                        shortName
                      }
                      x
                      y
                    }
                  }
                `,
              });

              return newFormationRef;
            },
          },
        });

        setShowMenu(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups.FormationsList",
            mutation: "UPDATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLineups.FormationsList",
        query: "FORMATIONS",
      },
    });
    return <div>Error</div>;
  }

  const formations = data?.formations || [];

  return (
    <Card className="select-formation-menu">
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="select-formation-menu__list">
          <div
            className="select-formation-menu__list__item"
            onClick={() => {
              if (submitting) {
                return;
              }

              updateFixtureRole({
                variables: {
                  where: {
                    id: fixtureRole.id,
                  },
                  data: {
                    formation: { disconnect: true },
                  },
                },
              });
            }}
          >
            None
          </div>
          {formations.map((f) => (
            <div
              className="select-formation-menu__list__item"
              onClick={() => {
                if (submitting) {
                  return;
                }

                updateFixtureRole({
                  variables: {
                    where: {
                      id: fixtureRole.id,
                    },
                    data: {
                      formation: { connect: { id: f.id } },
                    },
                  },
                });
              }}
            >
              {f.name}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function LineupsListItem({
  player,
  fixture,
  team,
  subtype = "STARTER",
  disable = () => false,
  onItemClick = () => {},
}) {
  const [loading, setLoading] = useState(false);

  const disabled = disable(player.profile.id);

  return (
    <div
      className={"lineup-list-item " + (disabled ? "disabled" : "")}
      onClick={() => {
        if (loading) return;
        setLoading(true);
        onItemClick(player.profile.id, player.jerseyNum);
      }}
    >
      <div className="image">
        {player.profile?.profilePhoto && (
          <Avatar media={player.profile.profilePhoto} />
        )}
      </div>
      <div className="name">
        <div className="name__primary">{player.profile.name}</div>
        <div className="name__secondary">@{player.profile.handle}</div>
      </div>
      <div className="num">{player.jerseyNum}</div>
      <div className="checkbox">{loading && <Spinner />}</div>
      {disabled && <div className="disabled" />}
    </div>
  );
}

function LineupList({ fixture, team, subtype, disable, label, onItemClick }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: {
      id: fixture.event.id,
      where: { organization: { id: team.organization.id } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLineups.LineupList",
        query: "EVENT_REGISTRATIONS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="lineups-page">
        <div className="lineup-editor">
          <Spinner />
        </div>
      </div>
    );
  }

  const registrations = data.event.registrations;

  return (
    <div className="fixture-lineups-list-container">
      <div className="fixture-lineups-list-container__header">
        {label || "Select Starters"}
        <div
          className="dismiss-btn"
          onClick={() => {
            setAppState({ modal: false });
            // dismiss();
          }}
        >
          Done
        </div>
      </div>
      <div className="lineup-list">
        {registrations.map((p) => (
          <LineupsListItem
            key={p.id}
            player={p}
            fixture={fixture}
            team={team}
            subtype={subtype}
            disable={disable}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
}

function StartersList({
  team,
  canManageFixture,
  players,
  fixture,
  onItemClick,
}) {
  return (
    <div className="starters__body__list">
      {team.formation ? (
        <div className="starters__body__list__items">
          {team.formation?.positions
            .map((p) => ({
              ...p,
              role: players.find(
                (r) =>
                  r.position?.id === p.id &&
                  r.subtypes.find((x) => x.name === "STARTER")
              ),
            }))
            .map((p) => (
              <div
                key={"formation-list-item-" + p.id}
                className="starters__body__list__item"
              >
                {canManageFixture && p.role && (
                  <div
                    className="starters__body__list__item__option delete"
                    onClick={() => {
                      onItemClick(p);
                    }}
                    style={{ marginLeft: 0, marginRight: "8px" }}
                  >
                    <TrashIcon className="starters__body__list__item__option__icon" />
                  </div>
                )}
                <div className="starters__body__list__item__image">
                  {p.role?.profile?.profilePhoto && (
                    <Avatar media={p.role.profile.profilePhoto} />
                  )}
                </div>
                <div className="starters__body__list__item__name">
                  {p.role ? (
                    <AppLink to={"/app/profile/" + p.role.profile.id}>
                      {p.role.profile.name}
                    </AppLink>
                  ) : (
                    <div
                      onClick={() => {
                        if (canManageFixture) {
                          onItemClick(p);
                        }
                      }}
                    >
                      {canManageFixture ? "Select player" : "-"}
                    </div>
                  )}
                </div>
                <div className="starters__body__list__item__options">
                  {p.role && (
                    <EditJerseyNumber
                      role={p.role}
                      canManageFixture={canManageFixture}
                      players={players}
                    />
                  )}

                  <div className="starters__body__list__item__option placeholder">
                    {p.positionType.shortName}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="starters__body__list__items">
          {!canManageFixture &&
            !players.filter((p) => {
              return p.subtypes.find((x) => x.name === "STARTER");
            }).length && <div className="no-data">No starters added yet.</div>}
          {players
            .filter((p) => {
              return p.subtypes.find((x) => x.name === "STARTER");
            })
            .map((p) => (
              <div
                key={"list-item-" + p.id}
                className="starters__body__list__item"
              >
                <div className="starters__body__list__item__image">
                  {p.profile?.profilePhoto && (
                    <Avatar media={p.profile.profilePhoto} />
                  )}
                </div>
                <div className="starters__body__list__item__name">
                  <AppLink to={"/app/profile/" + p.profile.id}>
                    {p.profile.name}
                  </AppLink>
                </div>
                <div className="starters__body__list__item__options">
                  <EditJerseyNumber
                    role={p}
                    canManageFixture={canManageFixture}
                    players={players}
                  />

                  {canManageFixture && (
                    <DeleteLineupPlayer
                      onClick={() => {
                        onItemClick({ role: p });
                      }}
                    />
                    // <div
                    //   className="starters__body__list__item__option delete"
                    //   onClick={() => {
                    //     onItemClick({ role: p });
                    //   }}
                    // >
                    //   <TrashIcon className="starters__body__list__item__option__icon" />
                    // </div>
                  )}
                </div>
              </div>
            ))}
          {canManageFixture && (
            <div
              className="starters__body__list__item hover-pointer"
              onClick={() => {
                onItemClick({});
              }}
            >
              <div className="starters__body__list__item__image"></div>
              <div className="starters__body__list__item__name">
                Add Starter
              </div>
            </div>
          )}
        </div>
      )}

      {/* {canManageFixture && (
        <div className="starters__body__list__footer">
          <Button
            className="primary orange medium"
            onClick={() => {
              setAppState({
                modal: (
                  <LineupList
                    fixture={fixture}
                    team={team}
                    disable={(role) =>
                      role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                    }
                  />
                ),
              });
            }}
          >
            Edit Starters
          </Button>
        </div>
      )} */}
    </div>
  );
}

function Starters({
  players,
  fixture,
  team,
  canManageFixture,
  addStarter,
  removeStarter,
}) {
  const [view, setView] = useState("list");
  const [, setAppState] = useAppState();

  return (
    <Card className="starters">
      <div className="card-title">Starters</div>
      {fixture.event.sport.name === "Football" && (
        <div className="starters__title">
          {canManageFixture ? (
            <NewDropdown
              target={
                <div className="starters__title__text">
                  {team.formation?.name || "Select Formation"}{" "}
                  <ChevronDown className="starters__title__text__icon" />
                </div>
              }
              menu={({ setShowMenu }) => (
                <FormationsList
                  sportId={fixture.event.sport.id}
                  fixtureRole={team}
                  setShowMenu={setShowMenu}
                />
              )}
            />
          ) : (
            <div className="starters__title__text">
              {team.formation?.name || "No formation added"}{" "}
              {/* <ChevronDown className="starters__title__text__icon" /> */}
            </div>
          )}

          <div className="starters__title__selection">
            <div
              className={
                "starters__title__selection__option " +
                (view === "list" ? "selected" : "")
              }
              onClick={() => {
                setView("list");
              }}
            >
              <ListIcon
                style={{ height: "14px" }}
                className="starters__title__selection__option__icon"
              />
            </div>

            {(canManageFixture || team.formation) && (
              <div
                className={
                  "starters__title__selection__option " +
                  (view === "pitch" ? "selected" : "")
                }
                onClick={() => {
                  setView("pitch");
                }}
              >
                <PitchIcon
                  style={{ height: "18px" }}
                  className="starters__title__selection__option__icon"
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="starters__body">
        {view === "pitch" ? (
          <Card className="pitch-card">
            <Pitch
              width={324}
              markers={
                team.formation?.positions.map((p) => ({
                  ...p,
                  label: p.positionType.shortName,
                  // secondaryLabel: lastname(
                  //   lineupRoles.find((lr) => lr.position?.id === p.id)?.profile
                  //     ?.name
                  // ),
                  secondaryLabel: "",
                  role: players.find(
                    (r) =>
                      r.position?.id === p.id &&
                      r.subtypes.find((x) => x.name === "STARTER")
                  ),
                })) || []
              }
              markerOnClick={(marker) => {
                if (marker.role) {
                  removeStarter(marker.role.id);
                } else {
                  setAppState({
                    modal: (
                      <LineupList
                        fixture={fixture}
                        team={team}
                        disable={
                          (profileId) => {
                            // console.log("PID:", profileId);
                            // console.log("Players:", players);
                            return players?.find(
                              (r) =>
                                r.profile.id === profileId &&
                                r.subtypes.find((x) =>
                                  ["STARTER", "SUBSTITUTE"].includes(x.name)
                                )
                            );
                          }

                          // role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                        }
                        onItemClick={(profileId, jerseyNum) => {
                          addStarter(
                            profileId,
                            team.organization.id,
                            marker.id,
                            "STARTER",
                            jerseyNum
                          );
                        }}
                      />
                    ),
                  });
                }
              }}
              lockEditing={false}
            />
          </Card>
        ) : (
          <StartersList
            team={team}
            canManageFixture={canManageFixture}
            fixture={fixture}
            players={players}
            onItemClick={(item) => {
              if (item.role) {
                removeStarter(item.role.id);
              } else {
                setAppState({
                  modal: (
                    <LineupList
                      fixture={fixture}
                      team={team}
                      disable={
                        (profileId) => {
                          // console.log("PID:", profileId);
                          // console.log("Players:", players);
                          return players?.find(
                            (r) =>
                              r.profile.id === profileId &&
                              r.subtypes.find((x) =>
                                ["STARTER", "SUBSTITUTE"].includes(x.name)
                              )
                          );
                        }

                        // role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                      }
                      onItemClick={(profileId, jerseyNum) => {
                        addStarter(
                          profileId,
                          team.organization.id,
                          item.id,
                          "STARTER",
                          jerseyNum
                        );
                      }}
                    />
                  ),
                });
              }
            }}
          />
        )}
      </div>
    </Card>
  );
}

function Subs({ players, fixture, team, canManageFixture, addSub, removeSub }) {
  const [, setAppState] = useAppState();

  return (
    <div className="starters">
      <div className="card-title">Subs</div>
      <div className="subs__body">
        <div className="starters__body__list">
          <div className="starters__body__list__items">
            {!canManageFixture &&
              !players.filter((p) => {
                return p.subtypes.find((x) => x.name === "SUBSTITUTE");
              }).length && <div className="no-data">No subs added yet.</div>}
            {players
              .filter((p) => {
                return p.subtypes.find((x) => x.name === "SUBSTITUTE");
              })
              .map((p) => (
                <div key={p.id} className="starters__body__list__item">
                  <div className="starters__body__list__item__image">
                    {p.profile?.profilePhoto && (
                      <Avatar media={p.profile.profilePhoto} />
                    )}
                  </div>
                  <div className="starters__body__list__item__name">
                    <AppLink to={"/app/profile/" + p.profile?.id}>
                      {p.profile?.name}
                    </AppLink>
                  </div>
                  <div className="starters__body__list__item__options">
                    <EditJerseyNumber
                      role={p}
                      canManageFixture={canManageFixture}
                      players={players}
                    />
                    {/* <div className="starters__body__list__item__option placeholder">
                      {p.order || (canManageFixture ? "J.Num" : "")}
                    </div> */}
                    {canManageFixture && (
                      <DeleteLineupPlayer
                        onClick={() => {
                          removeSub(p.id);
                        }}
                      />
                      // <div
                      //   className="starters__body__list__item__option delete"
                      //   onClick={() => {
                      //     removeSub(p.id);
                      //   }}
                      // >
                      //   <TrashIcon className="starters__body__list__item__option__icon" />
                      // </div>
                    )}
                  </div>
                </div>
              ))}

            {canManageFixture && (
              <div
                className="starters__body__list__item hover-pointer"
                onClick={() => {
                  setAppState({
                    modal: (
                      <LineupList
                        fixture={fixture}
                        team={team}
                        disable={
                          (profileId) => {
                            // console.log("PID:", profileId);
                            // console.log("Players:", players);
                            return players?.find(
                              (r) =>
                                r.profile?.id === profileId &&
                                r.subtypes.find((x) =>
                                  ["STARTER", "SUBSTITUTE"].includes(x.name)
                                )
                            );
                          }

                          // role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                        }
                        onItemClick={(profileId, jerseyNum) => {
                          addSub(
                            profileId,
                            team.organization.id,
                            false,
                            "SUBSTITUTE",
                            jerseyNum
                          );
                        }}
                      />
                    ),
                  });
                }}
              >
                <div className="starters__body__list__item__image"></div>
                <div className="starters__body__list__item__name">Add Sub</div>
              </div>
            )}
          </div>
          {/* {canManageFixture && (
            <div className="starters__body__list__footer">
              <Button
                className="primary orange medium"
                onClick={() => {
                  setAppState({
                    modal: (
                      <LineupList
                        fixture={fixture}
                        team={team}
                        subtype={"SUBSTITUTE"}
                        disable={(role) =>
                          role?.subtypes.find((x) => x.name === "STARTER")
                        }
                        label={"Select Substitutes"}
                      />
                    ),
                  });
                }}
              >
                Edit Subs
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

function FixtureLineups({ canManageFixture }) {
  const { id } = useParams();
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
      event {
        id
        sport {
          id
          name
        }
        name        
      }
      roles {
        id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          firstname
          lastname
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
        position {
          id
        }
      }
    `,
    }),
    { variables: { id } }
  );

  const [, setAppState] = useAppState();

  const [updateFixtureRole, { loading: updatingFixtureRole }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups",
            mutation: "UPDATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );
  const [createFixtureRole, { loading: creatingFixtureRole }] = useMutation(
    CREATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups",
            mutation: "CREATE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );
  const [deleteFixtureRole, { loading: deletingFixtureRole }] = useMutation(
    DELETE_FIXTURE_ROLE,
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureLineups",
            mutation: "DELETE_FIXTURE_ROLE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureLineups",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="fixture-lineups">
        <div className="left-column">
          <div className="meta-column">
            <Card className="top-players-card">
              <div className="starters">
                <div
                  className="card-title"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Placeholder
                    style={{ height: "12px", width: "40px" }}
                    pulse={true}
                  />
                </div>
                <div className="subs__body">
                  <div className="starters__body__list">
                    <div className="starters__body__list__items">
                      {[1, 2].map((p) => (
                        <div
                          key={"fl-subs-home-ph-" + p}
                          className="starters__body__list__item"
                        >
                          <div className="starters__body__list__item__image"></div>
                          <div className="starters__body__list__item__name">
                            <Placeholder
                              style={{ height: "12px", width: "80px" }}
                              pulse={true}
                            />
                          </div>
                          <div className="starters__body__list__item__options"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <Card className="starters">
            <div
              className="card-title"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Placeholder
                style={{ height: "12px", width: "40px" }}
                pulse={true}
              />
            </div>

            <div className="starters__body">
              <div className="starters__body__list">
                <div className="starters__body__list__items">
                  {[1, 2, 3, 4, 5].map((p) => (
                    <div
                      key={"fl-starters-home-ph-" + p}
                      className="starters__body__list__item"
                    >
                      <div className="starters__body__list__item__image"></div>
                      <div className="starters__body__list__item__name">
                        <Placeholder
                          style={{ height: "12px", width: "80px" }}
                          pulse={true}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="right-column">
          <Card className="starters">
            <div
              className="card-title"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Placeholder
                style={{ height: "12px", width: "40px" }}
                pulse={true}
              />
            </div>

            <div className="starters__body">
              <div className="starters__body__list">
                <div className="starters__body__list__items">
                  {[1, 2, 3, 4, 5].map((p) => (
                    <div
                      key={"fl-starters-away-ph-" + p}
                      className="starters__body__list__item"
                    >
                      <div className="starters__body__list__item__image"></div>
                      <div className="starters__body__list__item__name">
                        <Placeholder
                          style={{ height: "12px", width: "80px" }}
                          pulse={true}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Card>
          <div className="meta-column">
            <Card className="top-players-card">
              <div className="starters">
                <div
                  className="card-title"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Placeholder
                    style={{ height: "12px", width: "40px" }}
                    pulse={true}
                  />
                </div>
                <div className="subs__body">
                  <div className="starters__body__list">
                    <div className="starters__body__list__items">
                      {[1, 2].map((p) => (
                        <div
                          key={"fl-subs-away-ph-" + p}
                          className="starters__body__list__item"
                        >
                          <div className="starters__body__list__item__image"></div>
                          <div className="starters__body__list__item__name">
                            <Placeholder
                              style={{ height: "12px", width: "80px" }}
                              pulse={true}
                            />
                          </div>
                          <div className="starters__body__list__item__options"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  const fixture = data.fixture;
  const participants = fixture.roles.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const homeTeam = participants.find((x) => !x.order);
  const awayTeam = participants.find((x) => x.order);

  const homeLineupPlayers = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === homeTeam?.organization?.id
  );

  const awayLineupPlayers = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === awayTeam?.organization?.id
  );

  // console.log("HOMEPLAYERS:", homeLineupPlayers);

  const addLineupPlayer = (
    profileId,
    organizationId,
    formationPositionId,
    subtype,
    jerseyNum
  ) => {
    if (creatingFixtureRole || updatingFixtureRole) {
      return;
    }

    const existingRole = fixture.roles.find(
      (r) => r.type.name === "LINEUP PLAYER" && r.profile.id === profileId
    );

    if (existingRole) {
      // Update existing role

      updateFixtureRole({
        variables: {
          where: { id: existingRole.id },
          data: {
            subtypes: {
              connect: { name: subtype },
              ...(subtype === "STARTER"
                ? { disconnect: { name: "SUBSTITUTE" } }
                : { disconnect: { name: "STARTER" } }),
            },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
          },
        },
        update: (cache, { data: { updateFixtureRole } }) => {
          cache.modify({
            id: cache.identify(existingRole),
            fields: {
              subtypes() {
                return updateFixtureRole.subtypes;
              },
              position() {
                return updateFixtureRole.position;
              },
            },
          });

          setAppState({ modal: false });
        },
      });
    } else {
      // Add new role
      createFixtureRole({
        variables: {
          data: {
            fixture: { connect: { id: fixture.id } },
            type: { connect: { name: "LINEUP PLAYER" } },
            subtypes: { connect: { name: subtype } },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
            organization: { connect: { id: organizationId } },
            profile: { connect: { id: profileId } },
            ...(jerseyNum ? { order: jerseyNum } : {}),
          },
        },
        update: (cache, { data: { createFixtureRole } }) => {
          cache.modify({
            id: cache.identify(fixture),
            fields: {
              roles(existingRefs, { readField }) {
                const newRef = cache.writeFragment({
                  data: createFixtureRole,
                  fragment: gql`
                    fragment NewFixtureRole on FixtureRole {
                      id
                      order
                      type {
                        id
                        name
                      }
                      subtypes {
                        id
                        name
                      }
                      profile {
                        id
                        name
                        firstname
                        lastname
                        profilePhoto {
                          id
                          filename
                        }
                      }
                      organization {
                        id
                        name
                        profilePhoto {
                          id
                          filename
                        }
                      }
                      formation {
                        id
                        name
                        positions {
                          id
                          positionType {
                            id
                            shortName
                          }
                          x
                          y
                        }
                      }
                      position {
                        id
                      }
                    }
                  `,
                });

                return [newRef, ...existingRefs];
              },
            },
          });
          setAppState({ modal: false });
        },
      });
    }
  };

  const removeLineupPlayer = (roleId) => {
    if (deletingFixtureRole) return;
    deleteFixtureRole({
      variables: {
        id: roleId,
      },
      update: (cache, { data: { deleteFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            roles(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) => deleteFixtureRole.id !== readField("id", ref)
              );
            },
          },
        });
      },
    });
  };

  // console.log(participants);
  return (
    <div className="fixture-lineups">
      <div className="left-column">
        <div className="meta-column">
          <Card className="top-players-card">
            <Subs
              players={homeLineupPlayers}
              fixture={fixture}
              team={homeTeam}
              canManageFixture={canManageFixture}
              addSub={addLineupPlayer}
              removeSub={removeLineupPlayer}
            />
          </Card>
        </div>
        <Starters
          players={homeLineupPlayers}
          fixture={fixture}
          team={homeTeam}
          canManageFixture={canManageFixture}
          addStarter={addLineupPlayer}
          removeStarter={removeLineupPlayer}
        />
      </div>

      <div className="right-column">
        <Starters
          players={awayLineupPlayers}
          fixture={fixture}
          team={awayTeam}
          canManageFixture={canManageFixture}
          addStarter={addLineupPlayer}
          removeStarter={removeLineupPlayer}
        />
        <div className="meta-column">
          <Card className="top-players-card">
            <Subs
              players={awayLineupPlayers}
              fixture={fixture}
              team={awayTeam}
              canManageFixture={canManageFixture}
              addSub={addLineupPlayer}
              removeSub={removeLineupPlayer}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default FixtureLineups;
