import { gql } from "@apollo/client";

const UPDATE_AWARD = gql`
  mutation updateAward($where: UniqueInput!, $data: CreateAwardInput!) {
    updateAward(where: $where, data: $data) {
      id
      description
      event {
        id
      }
      fixture {
        id
      }
      profile {
        id
        name
        handle
      }
      organization {
        id
        name
        handle
      }
    }
  }
`;

export default UPDATE_AWARD;
