// V1
import { useState, Fragment } from "react";
import "./EventPhotos.css";
import {
  EVENT_PHOTOS,
  EVENT_ALBUMS,
  EVENT_PARTICIPANTS,
} from "../../api/event";
import Album from "../Album";
import { gql } from "@apollo/client";
import PhotosCard from "../PhotosCard";
import AlbumsCard from "../AlbumsCard";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function EventPhotos({ id, event, canManageEvent }) {
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [teamsFilter, setTeamsFilter] = useState([]);

  return (
    <div className="event-photos">
      <div className="left-column">
        {selectedAlbum ? (
          <Album
            id={selectedAlbum}
            dismiss={() => {
              setSelectedAlbum("");
            }}
          />
        ) : (
          <Fragment>
            <AlbumsCard
              query={EVENT_ALBUMS}
              vars={{
                id,
                filters: { teams: [] },
                take: 2,
              }}
              getAlbums={(data) => data?.event?.mediaSets || []}
              totalAlbums={(data) => data?.event?.albumsCount || 0}
              noData={`No albums tagged with ${event.name} yet.`}
              setSelectedAlbum={setSelectedAlbum}
              filters={{ teamsFilter }}
              processFilters={(filters) => {
                return {
                  filters: {
                    teams: teamsFilter,
                  },
                };
              }}
              addAlbum={
                canManageEvent
                  ? {
                      sourceId: id,
                      sourceType: "event",
                      onAddAlbum: (cache, { data: { createMediaSet } }) => {
                        cache.modify({
                          id: cache.identify(event),
                          fields: {
                            mediaSets(existingMediaRefs, { readField }) {
                              const newMediasetRef = cache.writeFragment({
                                data: createMediaSet,
                                fragment: gql`
                                  fragment NewMediaSet on MediaSet {
                                    id
                                    name
                                    preview {
                                      id
                                      filename
                                    }
                                    source {
                                      id
                                      fixture {
                                        id
                                        scheduledStart
                                        roles(
                                          where: {
                                            type: { name: "PARTICIPANT" }
                                          }
                                        ) {
                                          id
                                          organization {
                                            id
                                            name
                                          }
                                          profile {
                                            id
                                            name
                                          }
                                        }
                                      }
                                    }
                                  }
                                `,
                              });

                              // Quick safety check - if the new comment is already
                              // present in the cache, we don't need to add it again.
                              if (
                                existingMediaRefs.some(
                                  (ref) =>
                                    readField("id", ref) === createMediaSet.id
                                )
                              ) {
                                return existingMediaRefs;
                              }

                              return [newMediasetRef, ...existingMediaRefs];

                              // const newMediaSets = [
                              //   createMediaSet,
                              //   ...existingMediaRefs,
                              // ];
                              // console.log("new Media Sets:", newMediaSets);
                              // return newMediaSets;
                            },
                          },
                        });
                      },
                    }
                  : false
              }
            />

            <PhotosCard
              query={EVENT_PHOTOS}
              vars={{
                id,
                take: 16,
                filters: { teams: [] },
              }}
              getPhotos={(data) => data?.event?.media || []}
              totalPhotos={(data) => data?.event?.photosCount || 0}
              noData={`No tagged photos of ${event.name} yet.`}
              filters={{ teamsFilter }}
              processFilters={(filters) => {
                return {
                  filters: {
                    teams: teamsFilter,
                  },
                };
              }}
              onDelete={(cache, { data: { deleteMedia } }) => {
                cache.modify({
                  id: cache.identify(event),
                  fields: {
                    media(existingMediaRefs, { readField }) {
                      return existingMediaRefs.filter(
                        (mediaRef) =>
                          deleteMedia.id !== readField("id", mediaRef)
                      );
                    },
                  },
                });
              }}
              addPhoto={
                canManageEvent
                  ? {
                      sourceId: id,
                      sourceType: "event",
                      onAddPhoto: (cache, createMedia) => {
                        cache.modify({
                          id: cache.identify(event),
                          fields: {
                            media(existingMediaRefs, { readField }) {
                              const newMediaRef = cache.writeFragment({
                                data: createMedia.media,
                                fragment: gql`
                                  fragment NewMedia on Media {
                                    id
                                    createdAt
                                    type
                                    subtype
                                    isExternal
                                    filename
                                    public
                                    aspectRatio
                                    canManageMedia
                                  }
                                `,
                              });

                              // Quick safety check - if the new comment is already
                              // present in the cache, we don't need to add it again.
                              if (
                                existingMediaRefs.some(
                                  (ref) =>
                                    readField("id", ref) ===
                                    createMedia.media.id
                                )
                              ) {
                                return existingMediaRefs;
                              }

                              return [newMediaRef, ...existingMediaRefs];

                              // const newMediaSets = [
                              //   createMediaSet,
                              //   ...existingMediaRefs,
                              // ];
                              // console.log("new Media Sets:", newMediaSets);
                              // return newMediaSets;
                            },
                          },
                        });
                        // setAppState({ modal: false });
                      },
                    }
                  : false
              }
            />
          </Fragment>
        )}
      </div>

      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={"Enter a team"}
            query={EVENT_PARTICIPANTS}
            vars={{ id }}
            items={(d) => {
              return d?.event?.participants?.map((p) => p.organization) || [];
            }}
            addFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
            }}
            removeFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
            }}
            defaultValue={teamsFilter}
            appStateKey={"teamsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default EventPhotos;
