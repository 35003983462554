import { ReactComponent as MinusIcon } from "../../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../../icons/plus.svg";
import { ReactComponent as TimesIcon } from "../../../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../../../icons/check.svg";
import { useState } from "react";

function DismissalCell({ batsman, stats }) {
  // const [editing, setEditing] = useState(false);
  // const [val, setVal] = useState(value);
  const dismissal = stats.find(
    (s) =>
      (s?.profile?.id === batsman.id &&
        [
          "Bowled",
          "LBW",
          "Caught",
          "Caught & Bowled",
          "Stumped",
          "Retired",
        ].includes(s.statType.name)) ||
      (s?.profile?.id === batsman.id &&
        s.statType.name === "Run Out" &&
        s.relationshipsTo.find((y) => y.from.statType.name === "Runs Scored"))

    // (s?.profile?.id === batsman.id &&
    //   [
    //     "Bowled",
    //     "LBW",
    //     "Caught",
    //     "Caught & Bowled",
    //     "Stumped",
    //     "Retired",
    //   ].includes(s.statType.name)) ||
    // (s.statType.name === "Run Out" &&
    //   s.relationshipsTo.find((y) => y.from.statType.name === "Runs Scored"))
  );

  // console.log("Dismissal:", dismissal);
  return (
    <div className={"column__row__data "} style={{ paddingLeft: "24px" }}>
      {dismissal?.statType?.name}
    </div>
  );
}

function Cell({ value, update }) {
  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState(value);
  return (
    <div className={"column__row__data " + (value ? "bold" : "light")}>
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            update(val);
            setEditing(false);
          }}
          className="column__row__data__form"
        >
          <div className="column__row__data__form__input">
            <input
              type={"number"}
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
            />
          </div>
          <button type={"submit"}>
            <CheckIcon className="icon submit" />
          </button>
          <button
            onClick={() => {
              setVal(value);
              setEditing(false);
            }}
          >
            <TimesIcon className="icon cancel" />
          </button>
        </form>
      ) : (
        <div
          className="column__row__data__value hover"
          onClick={() => {
            setEditing(true);
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
}

export default function StatTable({
  organization,
  score,
  wickets,
  updateScore,
  rows,
  lineup,
  statTypes,
  setScore,
  setWickets,
  setStatTable,
  completed,
  stats,
}) {
  // console.log("STAT TABLE:", rows);
  // const battingStats = stats.filter(
  //   (s) =>
  //     s.organization.id !== organization.id && s.statType.name === "Runs Scored"
  // );

  // const groupedByOver = battingStats.reduce((accum, curr) => {
  //   const ballStat = curr.relationshipsFrom.find(
  //     (s) => s.to.statType.name === "Balls Bowled"
  //   );

  //   const runsConcededStat =
  //     ballStat &&
  //     stats.find(
  //       (s) =>
  //         s.statType.name === "Runs Conceded" &&
  //         s.relationshipsTo?.find((x) => x.from.id === ballStat?.to.id)
  //     );

  //   const group = Math.floor(ballStat.to.stringValue);

  //   const over = accum.findIndex((x) => x.group === group);

  //   const newStat = {
  //     ...curr,
  //     ball: ballStat.to,
  //     extra: stats
  //       .find((s) => s.id === ballStat.to.id)
  //       ?.relationshipsFrom?.filter((s) =>
  //         ["Wide", "No Ball"].includes(s.to.statType.name)
  //       )
  //       .map((s) => s.to),
  //     runsConceded: runsConcededStat,
  //   };

  //   if (over < 0) {
  //     accum.push({ group: group, stats: [newStat] });
  //   } else {
  //     accum[over].stats.push(newStat);
  //   }

  //   return accum;
  // }, []);

  // console.log(
  //   "Stats Runs Conceded:",
  //   stats.filter(
  //     (s) =>
  //       s.statType.name === "Runs Conceded" &&
  //       s.profile.id === "cl60bt8s60392mcurigmlaxbx"
  //   )
  // );
  // console.log("MAIDEN GROUPED OVERS:", groupedByOver);

  const sections = [
    {
      title: "Batting",
      statTypes: [
        {
          name: "Balls Faced",
        },
        {
          name: "Runs Scored",
        },
        {
          name: "Bowled",
        },
        {
          name: "LBW",
        },
        {
          name: "Caught & Bowled",
        },
        {
          name: "Caught",
        },
        {
          name: "Stumped",
        },
        {
          name: "Run Out",
        },
        {
          name: "Retired",
        },
        {
          name: "Not Out",
        },
      ],
    },
    {
      title: "Bowling & Fielding",
      statTypes: [
        {
          name: "Balls Bowled",
        },
        {
          name: "Runs Conceded",
        },
        {
          name: "Wicket Taken",
        },
        {
          name: "No Ball",
        },
        {
          name: "Wide",
        },
        {
          name: "Bye",
        },
        {
          name: "Leg Bye",
        },
        {
          name: "Catch",
        },
        {
          name: "Stumping",
        },
        {
          name: "Maiden Overs",
        },
      ],
    },
  ];
  return (
    <div className="stats-table">
      <div className="stats-table__header">
        <div className="stats-table__header__title">
          <div className="stats-table__header__title__logo"></div>
          <div className="stats-table__header__title__text">
            {organization?.name}
          </div>
        </div>

        <div className="stats-table__header__value-selector__wrapper">
          <div className="stats-table__header__value-selector">
            <div
              className="stats-table__header__value-selector__icon"
              onClick={() => {
                setScore((prev) => prev - 1);
              }}
            >
              <MinusIcon className="icon" />
            </div>
            <div className="stats-table__header__value-selector__val">
              {score}
            </div>
            <div
              className="stats-table__header__value-selector__icon"
              onClick={() => {
                setScore((prev) => prev + 1);
              }}
            >
              <PlusIcon className="icon" />
            </div>
          </div>

          <div className="stats-table__header__value-selector__seperator">
            /
          </div>

          <div className="stats-table__header__value-selector">
            <div
              className="stats-table__header__value-selector__icon"
              onClick={() => {
                setWickets((prev) => prev - 1);
              }}
            >
              <MinusIcon className="icon" />
            </div>
            <div className="stats-table__header__value-selector__val">
              {wickets}
            </div>
            <div
              className="stats-table__header__value-selector__icon"
              onClick={() => {
                setWickets((prev) => prev + 1);
              }}
            >
              <PlusIcon className="icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="stats-table__body">
        {sections.map((s) => (
          <div className="stats-table__body__section">
            <div className="stats-table__body__section__title">{s.title}</div>
            <div className="stats-table__body__section__body">
              <div className="column players">
                <div className="column__header">
                  <div className="column__row">
                    <div className="column__row__data">
                      <div className="column__row__data__value">Player</div>
                    </div>
                  </div>
                </div>
                {rows
                  .filter((x) => x.organization.id === organization.id)
                  .map((row) => (
                    <div className="column__row">
                      <div
                        className={
                          "column__row__data " +
                          (lineup.find((x) => x.profile.id === row.profile.id)
                            ? "highlight"
                            : "")
                        }
                      >
                        <div className="column__row__data__value">
                          {row.profile.name}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="column stats">
                <div className="column__header">
                  <div className="column__row">
                    {s.title === "Batting" && (
                      <div className="column__row__data">
                        <div className="column__row__data__value">
                          Dismissal
                        </div>
                      </div>
                    )}

                    {s.statTypes
                      .map((stName) =>
                        statTypes.find((st) => st.name === stName.name)
                      )
                      .filter((st) => !!st)
                      .map((st) => (
                        <div className="column__row__data">
                          <div className="column__row__data__value">
                            {st.name}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {rows
                  .filter((x) => x.organization.id === organization.id)
                  .map((row, i) => (
                    <div className="column__row">
                      {s.title === "Batting" && (
                        <DismissalCell batsman={row.profile} stats={stats} />
                      )}
                      {s.statTypes
                        .map((stName) => {
                          const target = statTypes.find(
                            (st) => st.name === stName.name
                          );
                          if (target) {
                            return {
                              ...target,
                              getValue: stName.value,
                            };
                          } else {
                            return target;
                          }
                        })
                        .filter((st) => !!st)
                        .map((st) => (
                          <Cell
                            value={row.stats[st.id]}
                            update={(val) => {
                              setStatTable((prev) => {
                                const newStatTable = [...prev];
                                const rowIndex = prev.findIndex(
                                  (x) => x.profile.id === row.profile.id
                                );
                                newStatTable[rowIndex].stats[st.id] = val;

                                return newStatTable;
                              });
                            }}
                          />
                        ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}

        {completed && (
          <div className="stats-table__body__overlay">
            This fixture has already been published, and stats are locked for
            updating. You can still update the final score of the match.
          </div>
        )}
      </div>
    </div>
  );
}
