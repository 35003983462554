import { useState, useEffect } from "react";

const useElementDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width, height, x, y, top, right, bottom, left } = boundingRect;
      setDimensions({
        width: Math.round(width),
        height: Math.round(height),
        x,
        y,
        top,
        right,
        bottom,
        left,
      });
    }
  }, [ref]);
  return dimensions;
};

export default useElementDimensions;
