import { Fragment } from "react";
import "./AccountRegistrations.css";
import Spinner from "../../Spinner";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { ACCOUNT_REGISTRATIONS } from "../../../api/account";
import moment from "moment";
import captureException from "../../../utils/captureException";
import { Link } from "react-router-dom";
import Avatar from "../../Avatar";

function AccountRegistrationItem({ registration }) {
  return (
    <Fragment>
      <div className="image">
        {registration.event?.profilePhoto && (
          <Avatar media={registration.event.profilePhoto} />
        )}
      </div>
      <div className="text">
        <Link
          to={"/app/event/" + registration.event?.id}
          className="primary-text"
        >
          {registration.event.name}
        </Link>

        <div className="secondary-text">
          {registration.organization?.name || "Team to be assigned"} | Paid ₹
          {registration.amountPaid || 0}
        </div>

        <div className="date">{moment(registration.createdAt).fromNow()}</div>
      </div>
    </Fragment>
  );
}

export default function AccountRegistrations() {
  const { loading, error, data } = useQuery(ACCOUNT_REGISTRATIONS);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AccountRegistrations",
        query: "ACCOUNT_REGISTRATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const registrations = data?.userAccount?.registrations || [];

  return (
    <div className="account-registrations">
      {registrations.map((r) => (
        <Card className="account-registrations__item">
          <AccountRegistrationItem registration={r} />
        </Card>
      ))}
    </div>
  );
}
