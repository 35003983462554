import "./ScoringLayout.css";
import { Scoring } from "../../scoring";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CAN_MANAGE_FIXTURE } from "../../api/user";
import captureException from "../../utils/captureException";
import Spinner from "../../components/Spinner";

export default function ScoringLayout() {
  const { fixtureId } = useParams();
  //   console.log("FixtureId:", fixtureId);
  // Check if user is authorized for this fixture
  const { loading, error, data } = useQuery(CAN_MANAGE_FIXTURE, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoringLayout",
        query: "CAN_MANAGE_FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div className="Scoring-layout">
      <Scoring />
    </div>
  );
}
