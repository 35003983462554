import "./FixtureSummary.css";
import { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../../api/fixture";
import FixtureDetails from "../FixtureDetails";
import FixtureTimeline from "../FixtureTimeline";
import FixtureLatestVideo from "../FixtureLatestVideo";
import FixtureEventsSummary from "../../../components/Fixture/FixtureEventsSummary/FixtureEventsSummary";
import FixtureReferees from "../FixtureReferees";
import captureException from "../../../utils/captureException";
import FootballEventsSummary from "../../../components/Fixture/FootballEventsSummary";

export default function FixtureSummary({ id, canManageFixture }) {
  const { loading, error, data } = useQuery(FIXTURE, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureSummary",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const fixture = data.fixture;
  // console.log(fixture);

  const uploaded = fixture?.uploaded;

  return (
    <div className="fixture-summary-mobile">
      {fixture.event.sport.name === "Football" && <FixtureTimeline id={id} />}
      <FixtureLatestVideo id={id} />
      {!uploaded && (
        <Fragment>
          {fixture.event.sport.name === "Football" ? (
            <FootballEventsSummary id={id} isMobile={true} />
          ) : (
            <FixtureEventsSummary id={id} isMobile={true} />
          )}
        </Fragment>
      )}

      <FixtureDetails id={id} canManageFixture={canManageFixture} />
      <FixtureReferees id={id} canManageFixture={canManageFixture} />
    </div>
  );
}
