import { useAppState } from "../../utils/appState";
import "./AddVideo.css";
import { ReactComponent as YoutubeIcon } from "../../icons/youtube.svg";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { CREATE_MEDIA } from "../../api/media";
import Uploader from "../../components/Uploader";
import Loader from "../../components/Loader";
import captureException from "../../utils/captureException";

function SourceSelector({ setExternal, setUploader }) {
  const [, setAppState] = useAppState();
  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">Add New Video</div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <div className="add-video-mobile-sources">
            <div
              className="add-video-mobile-sources__item"
              onClick={() => {
                setUploader(true);
                setExternal(true);
              }}
            >
              <div className="add-video-mobile-sources__item__icon">
                <YoutubeIcon style={{ stroke: "var(--red-lighter)" }} />
              </div>

              <div className="add-video-mobile-sources__item__desc">
                Embed YouTube
              </div>
            </div>

            <div
              className="add-video-mobile-sources__item"
              onClick={() => {
                setUploader(true);
                setExternal(false);
              }}
            >
              <div className="add-video-mobile-sources__item__icon">
                <UploadIcon style={{ stroke: "var(--blue-lighter)" }} />
              </div>

              <div className="add-video-mobile-sources__item__desc">
                Upload Video
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddYouTubeVideo({ config }) {
  const [, setAppState] = useAppState();
  const [link, setLink] = useState("");

  const [mutation, { loading }] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "AddVideo.AddYouTubeVideo",
          mutation: "CREATE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Enter YouTube Embed Link
          </div>

          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__secondary-text">
          To get your video's embed link, click the "Share" button on the
          video's YouTube page and click "Copy".
        </div>

        <div className="modal-form-mobile__body">
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();
              if (!link) {
                return;
              }

              const filename = link.replace(
                "https://youtu.be/",
                "https://www.youtube.com/embed/"
              );

              mutation({
                variables: {
                  type: "VIDEO",
                  filename,
                  private: false,
                  isExternal: true,
                  sourceId: config?.source?.id,
                  sourceType: config?.sourceType,
                },
                update: (cache, { data: { createMedia } }) => {
                  // console.log("Upload video update function");
                  cache.modify({
                    id: cache.identify(config.source),
                    fields: {
                      media(existingMediaRefs, { readField }) {
                        const newMediaRef = cache.writeFragment({
                          data: createMedia.media,
                          fragment: gql`
                            fragment NewMedia on Media {
                              id
                              createdAt
                              type
                              subtype
                              isExternal
                              filename
                              public
                              aspectRatio
                              canManageMedia
                            }
                          `,
                        });

                        // Quick safety check - if the new comment is already
                        // present in the cache, we don't need to add it again.
                        if (
                          existingMediaRefs.some(
                            (ref) =>
                              readField("id", ref) === createMedia.media.id
                          )
                        ) {
                          return existingMediaRefs;
                        }
                        // console.log("NEW VIDEO LIST:", [
                        //   newMediaRef,
                        //   ...existingMediaRefs,
                        // ]);
                        return [newMediaRef, ...existingMediaRefs];
                      },
                    },
                  });

                  setAppState({ modal: false });
                },
              });

              //   config.onSubmit({ fields, mutate });
            }}
          >
            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Enter YouTube link
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="text"
                  value={link}
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {loading ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  setAppState({ modal: false });
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function UploadVideo({ config }) {
  return (
    <div className="modal-form-mobile-wrapper">
      <Uploader
        accept=".mp4"
        label="Upload Video"
        multiple={false}
        sourceId={config?.source?.id}
        sourceType={config?.sourceType}
        type={"VIDEO"}
        maxFileSizeInBytes={10000000}
        onCreateMedia={(cache, createMedia) => {
          cache.modify({
            id: cache.identify(config.source),
            fields: {
              media(existingMediaRefs, { readField }) {
                const newMediaRef = cache.writeFragment({
                  data: createMedia.media,
                  fragment: gql`
                    fragment NewMedia on Media {
                      id
                      createdAt
                      type
                      subtype
                      isExternal
                      filename
                      public
                      aspectRatio
                      canManageMedia
                    }
                  `,
                });

                if (
                  existingMediaRefs.some(
                    (ref) => readField("id", ref) === createMedia.media.id
                  )
                ) {
                  return existingMediaRefs;
                }
                // console.log("NEW VIDEO LIST:", [
                //   newMediaRef,
                //   ...existingMediaRefs,
                // ]);
                return [newMediaRef, ...existingMediaRefs];
              },
            },
          });
        }}
      />
    </div>
  );
}

export default function AddVideo({ config }) {
  const [uploader, setUploader] = useState(false);
  const [external, setExternal] = useState(false);
  //   const [externalLink, setExternalLink] = useState("");
  //   const [, setAppState] = useAppState();

  //   const [createMedia] = useMutation(CREATE_MEDIA);

  if (uploader) {
    if (external) {
      return <AddYouTubeVideo config={config} />;
    } else {
      return <UploadVideo config={config} />;
    }
  } else {
    return (
      <div className="add-video-mobile">
        <SourceSelector setUploader={setUploader} setExternal={setExternal} />
      </div>
    );
  }
}
