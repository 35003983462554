import { Fragment } from "react";
import OverItem from "../OverItem";

export default function StatsList({
  id,
  fixture,
  battingTeam,
  battingLineup,
  bowlingLineup,
  statTypes,
  setOverlay,
  setAltOverlay,
  onDeleteLastBall,
  currentOverNumber,
  currentOver,
  setCurrentOver,
  selectedPeriodId,
  cache,
  currentBallIndex,
  setCurrentBallIndex,
  stats,
  groupedByOver,
}) {
  // const { loading, error, data } = useQuery(FIXTURE_STATS, {
  //   variables: { id },
  // });

  // if (error) {
  //   console.log(error);
  //   return <div>Error</div>;
  // }

  // if (loading || !data) {
  //   return <Spinner />;
  // }

  // const stats = data.fixture.stats;
  // console.log("Stats:", stats);

  // const battingStats = data.fixture.stats.filter(
  //   (s) =>
  //     s.organization.id === battingTeam.id && s.statType.name === "Runs Scored"
  // );
  // console.log("Batting Stats:", battingStats);

  // const groupedByOver = battingStats.reduce((accum, curr) => {
  //   const ballStat = curr.relationshipsFrom?.find(
  //     (s) => s.to.statType.name === "Balls Bowled"
  //   );

  //   const wicketStat = curr.relationshipsFrom?.find((s) =>
  //     [
  //       "Bowled",
  //       "LBW",
  //       "Caught",
  //       "Caught & Bowled",
  //       "Run Out",
  //       "Stumped",
  //       "Retired",
  //     ].includes(s.to.statType.name)
  //   )?.to;

  //   const wicketTakenStat =
  //     wicketStat &&
  //     stats
  //       .find((s) => s.id === wicketStat.id)
  //       ?.relationshipsFrom?.find((r) => r.to.statType.name === "Wicket Taken")
  //       ?.to;

  //   const runsConcededStat =
  //     ballStat &&
  //     stats
  //       .find((s) => s.id === ballStat.to.id)
  //       ?.relationshipsFrom?.find((r) => r.to.statType.name === "Runs Conceded")
  //       ?.to;

  //   const fieldingStat =
  //     wicketStat &&
  //     wicketStat.statType.name !== "Bowled" &&
  //     wicketStat.statType.name !== "LBW" &&
  //     wicketStat.statType.name !== "Retired" &&
  //     wicketStat &&
  //     stats
  //       .find((s) => s.id === wicketStat.id)
  //       ?.relationshipsFrom?.find((r) =>
  //         ["Catch", "Run Out", "Stumping"].includes(r.to.statType.name)
  //       )?.to;

  //   const group = Math.floor(ballStat.to.stringValue);

  //   const over = accum.findIndex((x) => x.group === group);

  //   const newStat = {
  //     ...curr,
  //     ball: ballStat.to,
  //     extra: stats
  //       .find((s) => s.id === ballStat.to.id)
  //       ?.relationshipsFrom?.filter((s) =>
  //         ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.to.statType.name)
  //       )
  //       .map((s) => s.to),
  //     wicket: wicketStat,
  //     wicketTaken: wicketTakenStat,
  //     runsConceded: runsConcededStat,
  //     fielding: fieldingStat,
  //   };

  //   if (over < 0) {
  //     accum.push({ group: group, stats: [newStat] });
  //   } else {
  //     accum[over].stats.push(newStat);
  //   }

  //   return accum;
  // }, []);

  // const sortedOvers = [...groupedByOver]
  //   .sort((a, b) => (b.group || 0) - (a.group || 0))
  //   .map((x) => ({
  //     ...x,
  //     stats: [...x.stats].sort(
  //       (a, b) =>
  //         (Number(b.ball.stringValue) || 0) -
  //           (Number(a.ball.stringValue) || 0) ||
  //         (b.createdAt > a.createdAt ? 1 : -1)
  //     ),
  //   }));

  // console.log("Grouped by over:", groupedByOver);
  // console.log("Sorted overs:", sortedOvers);

  return (
    <Fragment>
      <div className="column-title">Ball-by-Ball</div>
      <div className="overs">
        {groupedByOver.map((o, j) => (
          <div className="over" key={"overs-" + o.group}>
            <div className="over-title">Over {o.group + 1}</div>
            <div className="over-items">
              {o.stats.map((item, i) => (
                <OverItem
                  key={"over-item-" + item.ballsBowledStat.id}
                  overItem={item}
                  battingLineup={battingLineup}
                  bowlingLineup={bowlingLineup}
                  stats={stats}
                  fixture={fixture}
                  statTypes={statTypes}
                  setOverlay={setOverlay}
                  setAltOverlay={setAltOverlay}
                  canDelete={o.group === currentOverNumber && !i}
                  onDeleteLastBall={onDeleteLastBall}
                  previousGroupStats={groupedByOver[j]?.stats || []}
                  currentOverNumber={currentOverNumber}
                  itemIndex={o.stats.length - 1 - i}
                  currentOver={currentOver}
                  setCurrentOver={setCurrentOver}
                  fixtureId={id}
                  selectedPeriodId={selectedPeriodId}
                  cache={cache}
                  group={o.stats}
                />
                // <div className="over-item">
                //   <div className="label">{s.ball.stringValue}</div>
                //   <div className="run">{s.value}</div>
                //   <div className="text">
                //     <div className="primary">
                //       {s.ball.profile.name} to {s.profile.name}
                //     </div>
                //     <div className="secondary">
                //       {s.extra?.statType?.name || ""}{" "}
                //       {s.extra && s.wicket ? "|" : ""}{" "}
                //       {s.wicket?.statType.name || ""}
                //     </div>
                //   </div>
                //   <div className="edit">
                //     <PencilIcon
                //       style={{ height: "14px", fill: "var(--light-4" }}
                //     />
                //   </div>
                // </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
