// V1
import "./Album.css";
import { MEDIA_SET } from "../../api/media";
import { useQuery, gql } from "@apollo/client";
import Placeholder from "../../components/Placeholder";
import Uploader from "../../components/Uploader";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import Card from "../../components/Card";
import moment from "moment";
import { useAppState } from "../../utils/appState";
import PhotoBrowser from "../PhotoBrowser";
import Avatar from "../../components/Avatar/Avatar";
import captureException from "../../utils/captureException";

const getSource = (party) => {
  if (!party) {
    return;
  }
  if (party.fixture?.id) {
    return {
      sourceId: party.fixture.id,
      sourceType: "fixture",
      source: party.fixture,
    };
  } else if (party.event?.id) {
    return {
      sourceId: party.event.id,
      sourceType: "event",
      source: party.event,
    };
  } else if (party.organization?.id) {
    return {
      sourceId: party.organization.id,
      sourceType: "organization",
      source: party.organization,
    };
  } else if (party.profile?.id) {
    return {
      sourceId: party.profile.id,
      sourceType: "profile",
      source: party.profile,
    };
  }
};

function Album({ id, dismiss = () => {} }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(MEDIA_SET, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Album",
        query: "MEDIA_SET",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="app-album-container-mobile">
        <div className="app-album-container-mobile__head">
          <div className="app-album-container-mobile__head__link">
            <Placeholder
              style={{ height: "12px", width: "100px", marginLeft: "8px" }}
              pulse={true}
            />
          </div>
          <div className="app-album-container-mobile__head__title">
            <div className="app-album-container-mobile__head__title__text">
              <Placeholder
                style={{ height: "24px", width: "240px" }}
                pulse={true}
              />
            </div>
          </div>
          <div className="app-album-container-mobile__head__meta">
            <div className="app-album-container-mobile__head__timestamp">
              <Placeholder
                style={{ height: "12px", width: "80px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
        <div className="app-album-container-mobile__body">
          {[1, 2, 3].map((m) => (
            <div key={m.id} className="app-album-container-mobile__body__item">
              <div className="app-album-container-mobile__body__item__image">
                <Placeholder
                  style={{
                    height: "160px",
                    width: "100%",
                    background: "var(--blue-subtle)",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const mediaSet = data?.mediaSet;
  const mediaSetSource = getSource(mediaSet.source);
  // console.log("MEDIA SET:", mediaSet);

  return (
    <Card className="app-album-container-mobile">
      <div className="app-album-container-mobile__head">
        <div
          className="app-album-container-mobile__head__link"
          onClick={() => {
            dismiss();
          }}
        >
          <ChevronLeftIcon
            style={{ stroke: "var(--dark-4)", height: "12px" }}
          />
          Back to photos
        </div>
        <div className="app-album-container-mobile__head__title">
          <div className="app-album-container-mobile__head__title__text">
            {mediaSet?.source.fixture
              ? `${
                  mediaSet.source?.fixture?.scheduledStart
                    ? `[${moment(mediaSet.source.fixture.scheduledStart).format(
                        "DD-MM-YYYY"
                      )}] `
                    : ""
                }${mediaSet.source.fixture.roles
                  .map((r) => r.organization?.name || r.profile?.name)
                  .join(" vs ")}`
              : mediaSet.name}
          </div>
        </div>
        <div className="app-album-container-mobile__head__meta">
          <div className="app-album-container-mobile__head__timestamp">
            Created at {moment(mediaSet.createdAt).format("Do MMM YYYY")}
          </div>

          {mediaSet.canManageMediaSet && (
            <div
              className="app-album-container-mobile__head__add-photos-btn"
              onClick={() => {
                setAppState({
                  modal: (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Uploader
                        accept=".jpg,.png,.jpeg"
                        label="Event Photos"
                        multiple
                        sourceId={mediaSetSource.sourceId}
                        sourceType={mediaSetSource.sourceType}
                        mediaSetId={id}
                        onCreateMedia={(cache, createMedia) => {
                          cache.modify({
                            id: cache.identify(mediaSet),
                            fields: {
                              media(existingMediaRefs, { readField }) {
                                const newMediaRef = cache.writeFragment({
                                  data: createMedia.media,
                                  fragment: gql`
                                    fragment NewMedia on Media {
                                      id
                                      createdAt
                                      type
                                      subtype
                                      isExternal
                                      filename
                                      public
                                      aspectRatio
                                      canManageMedia
                                    }
                                  `,
                                });

                                if (
                                  existingMediaRefs.some(
                                    (ref) =>
                                      readField("id", ref) ===
                                      createMedia.media.id
                                  )
                                ) {
                                  return existingMediaRefs;
                                }

                                return [newMediaRef, ...existingMediaRefs];
                              },
                            },
                          });

                          cache.modify({
                            id: cache.identify(mediaSetSource.source),
                            fields: {
                              media(existingMediaRefs, { readField }) {
                                const newMediaRef = cache.writeFragment({
                                  data: createMedia.media,
                                  fragment: gql`
                                    fragment NewMedia on Media {
                                      id
                                      createdAt
                                      type
                                      subtype
                                      isExternal
                                      filename
                                      public
                                      aspectRatio
                                      canManageMedia
                                    }
                                  `,
                                });

                                if (
                                  existingMediaRefs.some(
                                    (ref) =>
                                      readField("id", ref) ===
                                      createMedia.media.id
                                  )
                                ) {
                                  return existingMediaRefs;
                                }

                                return [newMediaRef, ...existingMediaRefs];
                              },
                            },
                          });
                        }}
                      />
                    </div>
                  ),
                });
              }}
            >
              Add photos
            </div>
          )}
        </div>
      </div>
      <div className="app-album-container-mobile__body">
        {mediaSet?.media.map((m) => (
          <div
            key={m.id}
            className="app-album-container-mobile__body__item"
            onClick={() => {
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={MEDIA_SET}
                    vars={{ id }}
                    getPhotos={(data) => data?.mediaSet?.media || []}
                    totalPhotos={(data) => data?.mediaSet?.photosCount || 0}
                    media={m}
                  />
                ),
              });
            }}
          >
            <div className="app-album-container-mobile__body__item__image">
              <Avatar media={m} />
              {/* <img src={makeFileUrl(m)} /> */}

              <div className="overlay" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default Album;
