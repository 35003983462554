import "./EditLocations.css";
import { useAppState } from "../../utils/appState";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useMutation, useQuery } from "@apollo/client";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import LocationSearch from "../../components/LocationSearch";
import captureException from "../../utils/captureException";

export default function EditLocations({ title, config }) {
  const [, setAppState] = useAppState();

  const { data } = useQuery(config.query, {
    variables: { id: config.id },
  });

  const [mutation, { loading: submitting }] = useMutation(config.mutation, {
    update: config.update,
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EditLocations",
          mutation: "variable",
        },
      });
      console.log(error);
    },
  });

  const dismiss = () => {
    setAppState({ modal: false });
  };

  const locs = config.getLocations(data);

  return (
    <div className="modal-form-mobile-wrapper">
      <div
        className="modal-form-mobile"
        style={{ height: "90%", display: "flex", flexDirection: "column" }}
      >
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">{title}</div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div
          className="modal-form-mobile__body"
          style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
          <div className="modal-form-mobile__body__location-search">
            <div className="modal-form-mobile__body__location-search__input">
              <LocationSearch
                defaultSearchString={""}
                onLocationSelect={({ address, setSearchString }) => {
                  if (!address) {
                    return;
                  }

                  const city = address?.split(",")[0].trim();
                  const state = address?.split(",")[1].trim();
                  const country = address?.split(",")[2].trim();

                  geocodeByAddress(address)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                      mutation({
                        variables: {
                          where: { id: config.id },
                          data: {
                            cities: {
                              connect: [
                                {
                                  city,
                                  state,
                                  country,
                                  lat: latLng.lat,
                                  lng: latLng.lng,
                                },
                              ],
                            },
                          },
                        },
                      });
                      setSearchString("");
                    })
                    .catch((error) => console.error("Error", error));
                }}
                placeholder={"Search city"}
              />
            </div>

            <div className="modal-form-mobile__body__location-search__results"></div>

            <div className="modal-form-mobile__body__interests-grid">
              {locs.map((loc, i) => (
                <div
                  key={"profile-location-" + loc.id + i}
                  className={"interest hover-pointer "}
                  onClick={() => {
                    if (submitting) return;
                    mutation({
                      variables: {
                        where: { id: config.id },
                        data: {
                          cities: {
                            disconnect: [{ id: loc.id }],
                          },
                        },
                      },
                    });
                    // if (interests.find((i) => i.name === sport.name)) {
                    //   setInterests((prev) =>
                    //     prev.filter((i) => i.name !== sport.name)
                    //   );
                    // } else {
                    //   setInterests((prev) => [...prev, sport]);
                    // }
                  }}
                >
                  <div className="icon">
                    <TimesIcon
                      className="sports-icon-svg"
                      style={{ stroke: "white", fill: "none" }}
                    />
                  </div>
                  <div className="desc">{loc.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
