import { gql } from "@apollo/client";

const EVENT_PARTICIPANTS = gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      id
      participantType
      participants {
        id
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

export default EVENT_PARTICIPANTS;
