// V1
import { useQuery } from "@apollo/client";
import Placeholder from "../../Placeholder";
import Card from "../../Card";
import "./LatestEvent.css";
import { ORGANIZATION_EVENTS } from "../../../api/organization";
import { useHistory, useLocation, Link } from "react-router-dom";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function LatestEvent({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data } = useQuery(ORGANIZATION_EVENTS, {
    variables: { id, take: 5, takeRegistrations: 1 },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "LatestEvent",
        query: "ORGANIZATION_EVENTS",
      },
    });

    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="profile-overview-organizations-card">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "48px" }} pulse={true} />
        </div>
        <div className="profile-orgs-summary-list">
          {[1, 2, 3].map((p) => (
            <div
              key={"profile-orgs-summary-" + p}
              className="profile-orgs-summary-list-item"
            >
              <div className="image"></div>
              <div className="desc">
                <div className="primary-text">
                  <Placeholder
                    style={{ height: "12px", width: "120px" }}
                    pulse={true}
                  />
                </div>
                <div className="secondary-text" style={{ marginTop: "8px" }}>
                  <Placeholder
                    style={{ height: "12px", width: "60px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const org = data?.organization;
  const events = org?.events;
  // const latest = events?.length && events[0];

  // console.log("Latest:", data);

  return (
    <Card className="profile-overview-organizations-card">
      <div className="card-title">
        Events{" "}
        <div
          className="card-title-link"
          onClick={() => {
            history.replace(location.pathname + "?t=events");
          }}
        >
          See all events
        </div>
      </div>
      {events.length ? (
        <div className="profile-orgs-summary-list">
          {events.slice(0, 5).map((p) => (
            <Link
              key={"profile-orgs-summary-" + p.id}
              to={"/app/event/" + p.id}
              className="profile-orgs-summary-list-item"
            >
              <div className="image">
                {p.profilePhoto && <Avatar media={p.profilePhoto} />}
              </div>
              <div className="desc">
                <div className="primary-text">{p.name}</div>
                <div className="secondary-text">{p.organizedBy?.name}</div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <div>
            {org.name} has not participated
            <br /> in any events yet.
          </div>
        </div>
      )}
    </Card>
  );
}

export default LatestEvent;
