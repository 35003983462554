import { gql } from "@apollo/client";

const DELETE_MEDIA = gql`
  mutation deleteMedia($id: ID!) {
    deleteMedia(id: $id) {
      id
    }
  }
`;

export default DELETE_MEDIA;
