// V1
import { useState } from "react";
import "./DirectoryCategories.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_TYPES } from "../../../api/organization";
import Placeholder from "../../Placeholder";
import captureException from "../../../utils/captureException";

function DirectoryCategories({ onCategorySelect, defaultValue }) {
  const [selected, setSelected] = useState(defaultValue || "");
  const { loading, error, data } = useQuery(ORGANIZATION_TYPES);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "DirectoryCategories",
        query: "ORGANIZATION_TYPES",
      },
    });
    return null;
  }

  if (loading || !data) {
    return (
      <div className="directory-categories">
        <Placeholder
          style={{
            height: "32px",
            width: "120px",
            borderRadius: "24px",
            marginRight: "16px",
          }}
          pulse={true}
        />

        <Placeholder
          style={{
            height: "32px",
            width: "120px",
            borderRadius: "24px",
            marginRight: "16px",
          }}
          pulse={true}
        />

        <Placeholder
          style={{
            height: "32px",
            width: "120px",
            borderRadius: "24px",
            marginRight: "16px",
          }}
          pulse={true}
        />

        <Placeholder
          style={{
            height: "32px",
            width: "120px",
            borderRadius: "24px",
            marginRight: "16px",
          }}
          pulse={true}
        />

        <Placeholder
          style={{
            height: "32px",
            width: "120px",
            borderRadius: "24px",
            marginRight: "16px",
          }}
          pulse={true}
        />
      </div>
    );
  }

  const categories = data?.organizationTypes;

  // if (!categories) return null;

  return (
    <div className="directory-categories">
      <Card
        className={
          "directory-category hover-pointer " + (!selected ? "selected" : "")
        }
        onClick={() => {
          setSelected("");
          onCategorySelect("");
        }}
      >
        All Categories
      </Card>
      {categories?.map((c) => (
        <Card
          key={c.id}
          className={
            "directory-category hover-pointer " +
            (selected === c.id ? "selected" : "")
          }
          onClick={() => {
            setSelected(c.id);
            onCategorySelect(c.id);
          }}
        >
          {c.name}
        </Card>
      ))}
    </div>
  );
}

export default DirectoryCategories;
