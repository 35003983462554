// V1
import "./Prompt.css";
import Card from "../../Card";
import Button from "../../Button";
import { useHistory } from "react-router-dom";

function Prompt() {
  const history = useHistory();
  return (
    <Card className="create-prompt">
      {/* <div className="illustration">Some illustration here</div> */}
      <div className="blurb">
        Want to connect with India's largest sports community? Click the button
        below to create your event!
      </div>
      <Button
        className="primary medium green "
        onClick={() => {
          history.push("/app/create_event");
        }}
      >
        Create Event{" "}
      </Button>
    </Card>
  );
}

export default Prompt;
