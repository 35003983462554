import AboutProfile from "../AboutProfile";
import ProfileEventsSummary from "../ProfileEventsSummary";
import ProfileInteractionStats from "../ProfileInteractionStats";
import ProfilePagesSummary from "../ProfilePagesSummary";
import "./ProfileOverview.css";
import PhotosSummaryCard from "../../PhotosSummaryCard";
import VideosSummaryCard from "../../VideosSummaryCard";
import { PROFILE_MEDIA, PROFILE_TAGGED_PHOTOS } from "../../../api/profile";
import { ClaimProfile } from "../../../components/Profile";
import { useHistory } from "react-router-dom";

export default function ProfileOverview({ id, profile, canManageProfile }) {
  const history = useHistory();
  return (
    <div className="profile-overview-mobile">
      <div className="profile-overview-mobile__about-wrapper">
        <AboutProfile id={id} canManageProfile={canManageProfile} />
      </div>

      <div className="profile-overview-mobile__interaction-wrapper">
        <ProfileInteractionStats id={id} profile={profile} />
      </div>

      <div className="profile-overview-mobile__photos-wrapper">
        <PhotosSummaryCard
          id={id}
          query={PROFILE_TAGGED_PHOTOS}
          getPhotos={(d) => d?.profile?.taggedMedia || []}
          onClick={() => {
            history.push("/app/profile/" + id + "?t=photos");
          }}
        />
      </div>

      <div className="profile-overview-mobile__videos-wrapper">
        <VideosSummaryCard
          id={id}
          query={PROFILE_MEDIA}
          getVideo={(d) => d?.profile?.media?.[0] || null}
        />
      </div>

      <div className="profile-overview-mobile__pages-wrapper">
        <ProfilePagesSummary id={id} profile={profile} />
      </div>

      <div className="profile-overview-mobile__pages-wrapper">
        <ProfileEventsSummary id={id} />
      </div>

      <div className="profile-overview-mobile__claim-wrapper">
        <ClaimProfile profile={profile} />
      </div>
    </div>
  );
}
