// V1
import { useState } from "react";
import "./RequestCard.css";
import Card from "../../Card";
import Button from "../../Button";
import Spinner from "../../Spinner";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_FOLLOW, IS_FOLLOWED, CREATE_FOLLOW } from "../../../api/follow";

import {
  HANDLE_MEMBERSHIP,
  HANDLE_EVENT_ROLE,
  HANDLE_FIXTURE_ROLE,
} from "../../../api/user";
import { Fragment } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { useHistory } from "react-router-dom";
import Loader from "../../Loader";
import captureException from "../../../utils/captureException";
import Avatar from "../../Avatar";

function FixtureRoleRequest({ fixtureRole, setRequestCount }) {
  const [handled, setHandled] = useState(false);
  const [approved, setApproved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const history = useHistory();

  const [handleFixtureRole, { loading }] = useMutation(HANDLE_FIXTURE_ROLE, {
    update: () => {
      setHandled(true);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "RequestCard.FixtureRoleRequest",
          mutation: "HANDLE_EVENT_ROLE",
        },
      });
      console.log(error);
    },
  });

  if (deleted) {
    return <div />;
  }

  if (handled) {
    return (
      <Card className="home-request-card">
        <div
          className="dismiss-btn"
          onClick={() => {
            setRequestCount((prev) => prev - 1);
            setDeleted(true);
          }}
        >
          <TimesIcon style={{ stroke: "var(--dark-4)", height: "14px" }} />
        </div>
        <div className="meta">
          <div className="avatar">
            {fixtureRole?.fixture?.event?.profilePhoto && (
              <Avatar media={fixtureRole.fixture.event.profilePhoto} />
            )}
          </div>

          <div className="desc">
            {approved ? (
              <span>
                You accepted{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push(
                      "/app/event/" + fixtureRole?.fixture?.event?.id
                    );
                  }}
                >
                  {fixtureRole?.fixture?.event?.name}
                </span>
                's request to add you as a{" "}
                <span className="bold">{fixtureRole.description}</span> for a{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/fixture/" + fixtureRole.fixture.id);
                  }}
                >
                  fixture.
                </span>
              </span>
            ) : (
              <span>
                You declined{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push(
                      "/app/event/" + fixtureRole?.fixture?.event?.id
                    );
                  }}
                >
                  {fixtureRole?.fixture?.event?.name}
                </span>
                's request to add you as a{" "}
                <span className="bold">{fixtureRole.description}</span> for a{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/fixture/" + fixtureRole.fixture.id);
                  }}
                >
                  fixture.
                </span>
              </span>
            )}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="home-request-card">
      <div className="meta">
        <div className="avatar">
          {fixtureRole?.fixture?.event?.profilePhoto && (
            <Avatar media={fixtureRole.fixture.event.profilePhoto} />
          )}
        </div>

        <div className="desc">
          <span
            className="bold hover-pointer"
            onClick={() => {
              history.push("/app/event/" + fixtureRole.fixture?.event?.id);
            }}
          >
            {fixtureRole.fixture?.event?.name}
          </span>{" "}
          wants to add you as a{" "}
          <span className="bold">{fixtureRole.description}</span> for a{" "}
          <span
            className="bold hover-pointer"
            onClick={() => {
              history.push("/app/fixture/" + fixtureRole.fixture.id);
            }}
          >
            fixture.
          </span>
        </div>
      </div>

      <div className="request-actions">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Button
              className="medium blue primary action"
              onClick={() => {
                if (loading) return;
                setApproved(true);
                handleFixtureRole({
                  variables: {
                    id: fixtureRole.id,
                    approve: true,
                  },
                });
              }}
            >
              Confirm
            </Button>
            <Button
              className="medium neutral action"
              onClick={() => {
                if (loading) return;
                setApproved(false);
                handleFixtureRole({
                  variables: {
                    id: fixtureRole.id,
                    approve: false,
                  },
                });
              }}
            >
              Decline
            </Button>
          </Fragment>
        )}
      </div>
    </Card>
  );
}

function EventRoleRequest({ eventRole, setRequestCount }) {
  const [handled, setHandled] = useState(false);
  const [approved, setApproved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const history = useHistory();
  const hasRelation = eventRole.relationshipsTo?.[0];

  const [handleEventRole, { loading }] = useMutation(HANDLE_EVENT_ROLE, {
    update: () => {
      setHandled(true);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "RequestCard.EventRoleRequest",
          mutation: "HANDLE_EVENT_ROLE",
        },
      });
      console.log(error);
    },
  });

  if (deleted) {
    return <div />;
  }

  if (handled) {
    return (
      <Card className="home-request-card">
        <div
          className="dismiss-btn"
          onClick={() => {
            setRequestCount((prev) => prev - 1);
            setDeleted(true);
          }}
        >
          <TimesIcon style={{ stroke: "var(--dark-4)", height: "14px" }} />
        </div>
        <div className="meta">
          <div className="avatar">
            {eventRole?.organization?.profilePhoto && (
              <Avatar media={eventRole.organization.profilePhoto} />
            )}
          </div>

          <div className="desc">
            {approved ? (
              <span>
                You accepted{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/event/" + eventRole.event.id);
                  }}
                >
                  {eventRole.event.name}
                </span>
                's request to add{" "}
                {eventRole.organization ? (
                  <span
                    className="bold hover-pointer"
                    onClick={() => {
                      history.push(
                        "/app/organization/" + eventRole.organization.id
                      );
                    }}
                  >
                    {eventRole.organization.name}
                  </span>
                ) : (
                  "you "
                )}{" "}
                as a{" "}
                <span className="bold">
                  {eventRole.description || eventRole.type.name}
                </span>
                {hasRelation?.from?.organization ? (
                  <Fragment>
                    {" "}
                    for{" "}
                    <span
                      className="bold"
                      onClick={() => {
                        history.push(
                          "/app/organization/" +
                            hasRelation.from.organization.id
                        );
                      }}
                    >
                      {hasRelation.from.organization.name}
                    </span>
                  </Fragment>
                ) : (
                  "."
                )}
              </span>
            ) : (
              <span>
                You declined{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/event/" + eventRole.event.id);
                  }}
                >
                  {eventRole.event.name}
                </span>
                's request to add{" "}
                {eventRole.organization ? (
                  <span
                    className="bold hover-pointer"
                    onClick={() => {
                      history.push(
                        "/app/organization/" + eventRole.organization.id
                      );
                    }}
                  >
                    {eventRole.organization.name}
                  </span>
                ) : (
                  "you "
                )}{" "}
                as{" "}
                <span className="bold">
                  {eventRole.description || eventRole.type.name}
                </span>
                {hasRelation?.from?.organization ? (
                  <Fragment>
                    {" "}
                    for{" "}
                    <span
                      className="bold"
                      onClick={() => {
                        history.push(
                          "/app/organization/" +
                            hasRelation.from.organization.id
                        );
                      }}
                    >
                      {hasRelation.from.organization.name}
                    </span>
                  </Fragment>
                ) : (
                  "."
                )}
              </span>
            )}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="home-request-card">
      <div className="meta">
        <div className="avatar">
          {eventRole?.organization?.profilePhoto && (
            <Avatar media={eventRole.organization.profilePhoto} />
          )}
        </div>

        <div className="desc">
          <span
            className="bold hover-pointer"
            onClick={() => {
              history.push("/app/event/" + eventRole.event.id);
            }}
          >
            {eventRole.event.name}
          </span>{" "}
          wants to add{" "}
          {eventRole.organization ? (
            <span
              className="bold hover-pointer"
              onClick={() => {
                history.push("/app/organization/" + eventRole.organization.id);
              }}
            >
              {eventRole.organization.name}
            </span>
          ) : (
            "you "
          )}
          as a{" "}
          <span className="bold">
            {eventRole.description || eventRole.type.name}
          </span>
          {hasRelation?.from?.organization ? (
            <Fragment>
              {" "}
              for{" "}
              <span
                className="bold"
                onClick={() => {
                  history.push(
                    "/app/organization/" + hasRelation.from.organization.id
                  );
                }}
              >
                {hasRelation.from.organization.name}
              </span>
            </Fragment>
          ) : (
            "."
          )}
        </div>
      </div>

      <div className="request-actions">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Button
              className="medium blue primary action"
              onClick={() => {
                if (loading) return;
                setApproved(true);
                handleEventRole({
                  variables: {
                    id: eventRole.id,
                    approve: true,
                  },
                });
              }}
            >
              Confirm
            </Button>
            <Button
              className="medium neutral action"
              onClick={() => {
                if (loading) return;
                setApproved(false);
                handleEventRole({
                  variables: {
                    id: eventRole.id,
                    approve: false,
                  },
                });
              }}
            >
              Decline
            </Button>
          </Fragment>
        )}
      </div>
    </Card>
  );
}

function FollowBack({ id }) {
  const [followRequestSent, setFollowRequestSent] = useState(false);
  const { loading, error, data } = useQuery(IS_FOLLOWED, {
    variables: { id, type: "profile" },
  });

  const [createFollow, { loading: submitting }] = useMutation(CREATE_FOLLOW, {
    update: (cache, { data: { createFollow } }) => {
      if (createFollow) {
        // Update the is followed query
        cache.writeQuery({
          query: IS_FOLLOWED,
          variables: { id, type: "profile" },
          data: {
            isFollowed: {
              ...createFollow,
            },
          },
        });
      }

      setFollowRequestSent(true);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "RequestCard.FollowBack",
          mutation: "CREATE_FOLLOW",
        },
      });
      console.log(error);
    },
  });

  if (submitting) {
    return <Loader />;
  }

  if (followRequestSent) {
    return <span className="">You requested to support them back.</span>;
  }

  if (loading || !data || error) {
    return <span />;
  }

  const follow = data?.isFollowed;

  if (follow) {
    return <span />;
  }

  return (
    <span>
      <span
        className="bold hover-pointer"
        style={{ color: "var(--blue-main)" }}
        onClick={() => {
          if (submitting) return;
          createFollow({
            variables: {
              followingId: id,
              followingType: "profile",
            },
          });
        }}
      >
        Click here
      </span>{" "}
      to support them back.
    </span>
  );
}

function FollowRequest({ follow, setRequestCount }) {
  const [handled, setHandled] = useState(false);
  const [approved, setApproved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const history = useHistory();

  const [updateFollow, { loading }] = useMutation(UPDATE_FOLLOW, {
    update: () => {
      setHandled(true);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "RequestCard.FollowRequest",
          mutation: "UPDATE_FOLLOW",
        },
      });
      console.log(error);
      setHandled(true);
    },
  });

  if (deleted) {
    return <div />;
  }

  if (handled) {
    return (
      <Card className="home-request-card">
        <div
          className="dismiss-btn"
          onClick={() => {
            setRequestCount((prev) => prev - 1);
            setDeleted(true);
          }}
        >
          <TimesIcon style={{ stroke: "var(--dark-4)", height: "14px" }} />
        </div>
        <div className="meta">
          <div className="avatar">
            {follow.follower?.profile?.profilePhoto && (
              <Avatar media={follow.follower.profile.profilePhoto} />
            )}
          </div>

          <div className="desc">
            {approved ? (
              <span>
                You accepted{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/profile/" + follow.follower.profile.id);
                  }}
                >
                  {follow.follower.profile.name}
                </span>
                's request to become a fan.{" "}
                <FollowBack id={follow.follower.profile.id} />
              </span>
            ) : (
              <span>
                You declined{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/profile/" + follow.follower.profile.id);
                  }}
                >
                  {follow.follower.profile.name}
                </span>
                's request to become a fan.
              </span>
            )}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="home-request-card">
      <div className="meta">
        <div className="avatar">
          {follow.follower?.profile?.profilePhoto && (
            <Avatar media={follow.follower.profile.profilePhoto} />
          )}
        </div>

        <div className="desc">
          <span
            className="bold hover-pointer"
            onClick={() => {
              history.push("/app/profile/" + follow.follower.profile.id);
            }}
          >
            {follow.follower.profile.name}
          </span>{" "}
          wants to become a fan.
        </div>
      </div>

      <div className="request-actions">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Button
              className="medium blue primary action"
              onClick={() => {
                if (loading) return;
                setApproved(true);
                updateFollow({
                  variables: {
                    where: { id: follow.id },
                    data: { confirmed: true },
                  },
                });
              }}
            >
              Confirm
            </Button>
            <Button
              className="medium neutral action"
              onClick={() => {
                if (loading) return;
                setApproved(false);
                updateFollow({
                  variables: {
                    where: { id: follow.id },
                    data: { confirmed: false },
                  },
                });
              }}
            >
              Decline
            </Button>
          </Fragment>
        )}
      </div>
    </Card>
  );
}

function MembershipRequest({ membership, setRequestCount }) {
  const [handled, setHandled] = useState(false);
  const [approved, setApproved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const history = useHistory();

  const [handleMembership, { loading }] = useMutation(HANDLE_MEMBERSHIP, {
    update: () => {
      setHandled(true);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "RequestCard.MembershipRequest",
          mutation: "HANDLE_MEMBERSHIP",
        },
      });
      console.log(error);
    },
  });

  if (deleted) {
    return <div />;
  }

  if (handled) {
    return (
      <Card className="home-request-card">
        <div
          className="dismiss-btn"
          onClick={() => {
            setRequestCount((prev) => prev - 1);
            setDeleted(true);
          }}
        >
          <TimesIcon style={{ stroke: "var(--dark-4)", height: "14px" }} />
        </div>
        <div className="meta">
          <div className="avatar">
            {membership.organization?.profilePhoto && (
              <Avatar media={membership.organization.profilePhoto} />
            )}
          </div>

          <div className="desc">
            {approved ? (
              <span>
                You accepted{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/page/" + membership.organization.id);
                  }}
                >
                  {membership.organization.name}
                </span>
                's membership request.{" "}
              </span>
            ) : (
              <span>
                You declined{" "}
                <span
                  className="bold hover-pointer"
                  onClick={() => {
                    history.push("/app/page/" + membership.organization.id);
                  }}
                >
                  {membership.organization.name}
                </span>
                's membership request.
              </span>
            )}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="home-request-card">
      <div className="meta">
        <div className="avatar">
          {membership.organization?.profilePhoto && (
            <Avatar media={membership.organization.profilePhoto} />
          )}
        </div>

        <div className="desc">
          <span
            className="bold hover-pointer"
            onClick={() => {
              history.push("/app/page/" + membership.organization.id);
            }}
          >
            {membership.organization.name}
          </span>{" "}
          wants to add you as a member (
          {membership.isAdmin ? "Admin" : membership.role}
          ).
        </div>
      </div>

      <div className="request-actions">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Button
              className="medium blue primary action"
              onClick={() => {
                if (loading) return;
                setApproved(true);
                handleMembership({
                  variables: {
                    id: membership.id,
                    approve: true,
                  },
                });
              }}
            >
              Confirm
            </Button>
            <Button
              className="medium neutral action"
              onClick={() => {
                if (loading) return;
                setApproved(false);
                handleMembership({
                  variables: {
                    id: membership.id,
                    approve: false,
                  },
                });
              }}
            >
              Decline
            </Button>
          </Fragment>
        )}
      </div>
    </Card>
  );
}

function RequestCard({ request, setRequestCount }) {
  if (request.requestType === "follow") {
    return (
      <FollowRequest
        follow={request.follow}
        setRequestCount={setRequestCount}
      />
    );
  } else if (request.requestType === "membership") {
    return (
      <MembershipRequest
        membership={request.membership}
        setRequestCount={setRequestCount}
      />
    );
  } else if (request.requestType === "eventRole") {
    return (
      <EventRoleRequest
        eventRole={request.eventRole}
        setRequestCount={setRequestCount}
      />
    );
  } else if (request.requestType === "fixtureRole") {
    return (
      <FixtureRoleRequest
        fixtureRole={request.fixtureRole}
        setRequestCount={setRequestCount}
      />
    );
  } else {
    return null;
  }
}

export default RequestCard;
