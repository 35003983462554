import { gql } from "@apollo/client";

const CREATE_SUPPORT_TICKET = gql`
  mutation createSupportTicket($data: CreateSupportTicketInput!) {
    createSupportTicket(data: $data) {
      id
    }
  }
`;

export default CREATE_SUPPORT_TICKET;
