// V1
import { useState } from "react";

function ToggleState({ children }) {
  const [selectedStateIndex, setSelectedStateIndex] = useState(0);
  return children({
    index: selectedStateIndex,
    setIndex: setSelectedStateIndex,
  });
}

export default ToggleState;
