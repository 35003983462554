import "./Publish.css";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import { useQuery, useMutation } from "@apollo/client";
import {
  BADMINTON_STATS,
  PUBLISH_INDIVIDUAL_FIXTURE,
  PUBLISH_DOUBLES_FIXTURE,
} from "../../../api/fixture";
import { FIXTURE } from "../../../api/functions";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";

function PublishFixtureConfirmation({
  fixtureId,
  completed,
  homeParticipant,
  awayParticipant,
  periods,
}) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
    fetchPolicy: "network-only",
  });

  const updateFunction = (cache) => {
    cache.modify({
      id: "Fixture:" + fixtureId,
      fields: {
        completed() {
          return true;
        },
      },
    });
  };

  const [publishIndividualFixture, { loading: publishingIndividualFixture }] =
    useMutation(PUBLISH_INDIVIDUAL_FIXTURE, {
      update: updateFunction,
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreBadminton.PublishStatsConfirm",
        //     mutation: "PUBLISH_INDIVIDUAL_FIXTURE",
        //   },
        // });
        console.log(error);
      },
    });

  const [publishDoublesFixture, { loading: publishingDoublesFixture }] =
    useMutation(PUBLISH_DOUBLES_FIXTURE, {
      update: updateFunction,
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreBadminton.PublishStatsConfirm",
        //     mutation: "PUBLISH_INDIVIDUAL_FIXTURE",
        //   },
        // });
        console.log(error);
      },
    });

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreBadminton.PublishStatsConfirm",
    //     query: "BADMINTON_STATS",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="publish-stats">
        <Spinner />
      </div>
    );
  }

  const fixture = data?.fixture;
  const event = fixture?.event;
  const participantType = event.participantType;
  const sport = event?.sport;
  const statTypes = sport?.statTypes;
  const stats = data?.fixture?.stats;

  const placementValues = periods?.reduce(
    (accum, curr) => {
      const homePoints =
        stats.filter(
          (s) =>
            s.statType.name === "Point Won" &&
            s.fixturePeriod.id === curr.id &&
            (participantType === "INDIVIDUAL"
              ? s.profile?.id === homeParticipant.profile?.id
              : s.organization?.id === homeParticipant.organization?.id)
        ).length || 0;

      const awayPoints =
        stats.filter(
          (s) =>
            s.statType.name === "Point Won" &&
            s.fixturePeriod.id === curr.id &&
            (participantType === "INDIVIDUAL"
              ? s.profile?.id === awayParticipant.profile?.id
              : s.organization?.id === awayParticipant.organization?.id)
        ).length || 0;

      if (homePoints > awayPoints) {
        if (participantType === "INDIVIDUAL") {
          accum[homeParticipant.profile?.id]++;
        } else {
          accum[homeParticipant.organization?.id]++;
        }
      } else if (homePoints < awayPoints) {
        if (participantType === "INDIVIDUAL") {
          accum[awayParticipant.profile?.id]++;
        } else {
          accum[awayParticipant.organization?.id]++;
        }
      }

      return accum;
    },
    {
      [homeParticipant.profile?.id || homeParticipant.organization?.id]: 0,
      [awayParticipant.profile?.id || awayParticipant.organization?.id]: 0,
    }
  );

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // Games Played
  const gamesPlayedStatType = statTypes?.find((s) => s.name === "Games Played");
  const totalGamesPlayed =
    stats?.map((s) => s.fixturePeriod?.id).filter(onlyUnique).length || 0;

  // Games Won
  const gamesWonStatType = statTypes?.find((s) => s.name === "Games Won");
  const homePlayerGamesWon =
    placementValues[
      homeParticipant.profile?.id || homeParticipant.organization?.id
    ];
  const awayPlayerGamesWon =
    placementValues[
      awayParticipant.profile?.id || awayParticipant.organization?.id
    ];

  // Points Played
  const pointsPlayedStatType = statTypes?.find(
    (s) => s.name === "Point Played"
  );
  const totalPointsPlayed =
    stats?.filter((s) => s.statType.name === "Point Won").length || 0;

  // Points Won
  const pointsWonStatType = statTypes?.find((s) => s.name === "Point Won");
  const homePlayerPointsWon =
    stats.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        (participantType === "INDIVIDUAL"
          ? s.profile?.id === homeParticipant.profile?.id
          : s.organization?.id === homeParticipant.organization?.id)
    ).length || 0;
  const awayPlayerPointsWon =
    stats.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        (participantType === "INDIVIDUAL"
          ? s.profile?.id === awayParticipant.profile?.id
          : s.organization?.id === awayParticipant.organization?.id)
    ).length || 0;

  // Serve
  const serveStatType = statTypes?.find((s) => s.name === "Service");
  const serveWonStatType = statTypes?.find((s) => s.name === "Service Won");

  const serveSummary = stats
    .filter((s) => s.statType.name === "Point Won")
    .reduce(
      (accum, curr) => {
        const serve = curr.relationshipsFrom.find(
          (r) => r.to?.statType?.name === "Service"
        )?.to;

        accum[serve.profile?.id || serve.organization?.id].total =
          accum[serve.profile?.id || serve.organization?.id].total + 1;

        if (
          participantType === "INDIVIDUAL"
            ? curr.profile.id === serve.profile.id
            : curr.organization.id === serve.organization.id
        ) {
          accum[serve.profile?.id || serve.organization?.id].won =
            accum[serve.profile?.id || serve.organization?.id].won + 1;
        }

        return accum;
      },
      {
        [homeParticipant.profile?.id || homeParticipant.organization?.id]: {
          total: 0,
          won: 0,
        },
        [awayParticipant.profile?.id || awayParticipant.organization?.id]: {
          total: 0,
          won: 0,
        },
      }
    );

  // Unforced Error
  const unforcedErrorStatType = statTypes?.find(
    (s) => s.name === "Unforced Error"
  );
  const homeUnforcedErrors =
    stats.filter(
      (s) =>
        s.statType.name === "Unforced Error" &&
        (participantType === "INDIVIDUAL"
          ? s.profile?.id === homeParticipant.profile?.id
          : s.organization?.id === homeParticipant.organization?.id)
    ).length || 0;

  const awayUnforcedErrors =
    stats.filter(
      (s) =>
        s.statType.name === "Unforced Error" &&
        (participantType === "INDIVIDUAL"
          ? s.profile?.id === awayParticipant.profile?.id
          : s.organization?.id === awayParticipant.organization?.id)
    ).length || 0;

  const tables = [
    // Games Played
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),

      statTypeId: gamesPlayedStatType.id,
      value: totalGamesPlayed,
      //   label: "Games Played",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: gamesPlayedStatType.id,
      value: totalGamesPlayed,
      //   label: "Games Played",
    },
    // Games Won
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),
      statTypeId: gamesWonStatType.id,
      value: homePlayerGamesWon,
      //   label: "Games Won",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: gamesWonStatType.id,
      value: awayPlayerGamesWon,
      //   label: "Games Won",
    },
    // Points Played
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),
      statTypeId: pointsPlayedStatType.id,
      value: totalPointsPlayed,
      //   label: "Points Played",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: pointsPlayedStatType.id,
      value: totalPointsPlayed,
      //   label: "Points Played",
    },
    // Points Won
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),
      statTypeId: pointsWonStatType.id,
      value: homePlayerPointsWon,
      //   label: "Points Won",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: pointsWonStatType.id,
      value: awayPlayerPointsWon,
      //   label: "Points Won",
    },
    // Serve
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),
      statTypeId: serveStatType.id,
      value:
        serveSummary[
          participantType === "INDIVIDUAL"
            ? homeParticipant.profile.id
            : homeParticipant.organization.id
        ].total,
      //   label: "Serve",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: serveStatType.id,
      value:
        serveSummary[
          participantType === "INDIVIDUAL"
            ? awayParticipant.profile.id
            : awayParticipant.organization.id
        ].total,
      //   label: "Serve",
    },
    // Serve Won
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),
      statTypeId: serveWonStatType.id,
      value:
        serveSummary[
          participantType === "INDIVIDUAL"
            ? homeParticipant.profile.id
            : homeParticipant.organization.id
        ].won,
      //   label: "Serve Won",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: serveWonStatType.id,
      value:
        serveSummary[
          participantType === "INDIVIDUAL"
            ? awayParticipant.profile.id
            : awayParticipant.organization.id
        ].won,
      //   label: "Serve Won",
    },
    // Unforced Error
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: homeParticipant.profile.id }
        : { organizationId: homeParticipant.organization.id }),
      statTypeId: unforcedErrorStatType.id,
      value: homeUnforcedErrors,
      //   label: "Unforced Error",
    },
    {
      ...(participantType === "INDIVIDUAL"
        ? { profileId: awayParticipant.profile.id }
        : { organizationId: awayParticipant.organization.id }),
      statTypeId: unforcedErrorStatType.id,
      value: awayUnforcedErrors,
      //   label: "Unforced Error",
    },
  ];

  return (
    <Card
      className="ScoringApp-badminton__publish__confirm"
      style={{ width: "40%" }}
    >
      <div className="ScoringApp-badminton__publish__confirm__form">
        <div className="ScoringApp-badminton__publish__confirm__form__header">
          {completed
            ? "This fixture has been published"
            : "Are you sure you want to publish this fixture?"}
        </div>
        <div className="ScoringApp-badminton__publish__confirm__form__text">
          {completed ? (
            <p>
              Please contact us if you need to change the scores for this
              fixture.
            </p>
          ) : (
            <Fragment>
              <p>
                This cannot be undone. Please note, users will still be able to
                see the score for the fixture from the fixtures page without
                publishing.
              </p>
              <p>
                Once you confirm we will update individual player and event
                stats, and the fixture will be available for use in standings
                calculations, so do make sure all stats are accurate before you
                publish the fixture.
              </p>
            </Fragment>
          )}
        </div>

        <div className="ScoringApp-badminton__publish__confirm__form__options">
          {!completed && (
            <Button
              className="primary medium green"
              onClick={() => {
                if (publishingIndividualFixture || publishingDoublesFixture) {
                  return;
                }

                // console.log("Tyoes:", statTypes);
                console.log("Tables:", tables);
                console.log("Placement Values:", placementValues);
                console.log("Participant type:", participantType);

                if (participantType === "INDIVIDUAL") {
                  publishIndividualFixture({
                    variables: {
                      fixtureId,
                      placements: [
                        {
                          profileId: homeParticipant.profile.id,
                          value: placementValues[homeParticipant.profile.id],
                        },
                        {
                          profileId: awayParticipant.profile.id,
                          value: placementValues[awayParticipant.profile.id],
                        },
                      ],
                      tables,
                    },
                  });
                } else {
                  publishDoublesFixture({
                    variables: {
                      fixtureId,
                      placements: [
                        {
                          organizationId: homeParticipant.organization.id,
                          value:
                            placementValues[homeParticipant.organization.id],
                        },
                        {
                          organizationId: awayParticipant.organization.id,
                          value:
                            placementValues[awayParticipant.organization.id],
                        },
                      ],
                      tables,
                    },
                  });
                }
                // return;
              }}
              disabled={publishingIndividualFixture || publishingDoublesFixture}
            >
              {publishingIndividualFixture || publishingDoublesFixture ? (
                <Loader />
              ) : (
                "Yes, publish this fixture"
              )}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}

export default function Publish({ id, setStep, completed }) {
  const history = useHistory();
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      completed
      statsCacheState
      event{
        id
        participantType
        sport{
          id
          statTypes{
            id
            name
            group {
              id
              name
            }
            relationshipsFrom {
              id
              to {
                id
                name
              }
              type {
                id
                name
              }
            }
            relationshipsTo {
              id
              from {
                id
                name
              }
              type {
                id
                name
              }
            }
          }
        }
      }
      periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
      }
      roles{
        id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      
  `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-football__publish">
        <div className="ScoringApp-football__header">
          <div className="ScoringApp-football__header__nav-left">
            {completed ? (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  history.replace("/app/fixture/" + id);
                  // setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to fixture
                </div>
              </div>
            ) : (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to collect stats
                </div>
              </div>
            )}
          </div>

          <div className="ScoringApp-football__header__main">
            Verify Fixture Stats & Result
          </div>

          <div className="ScoringApp-football__header__nav-right">
            <div
              className="ScoringApp-football__header__nav-right__nav-btn square"
              onClick={() => {
                setStep(0);
              }}
            >
              <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
            </div>
          </div>
        </div>

        <div
          className="ScoringApp-football__body"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Spinner />
        </div>
      </div>
    );
  }

  const participants = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const homeParticipant = participants?.find((p) => !p.order);

  const awayParticipant = participants?.find((p) => p.order);

  // const players = data?.fixture?.roles?.filter(
  //   (r) => r.type.name === "LINEUP PLAYER"
  // );

  // const homeLineup = players?.filter(
  //   (r) => r.organization?.id === homeTeam?.organization?.id
  // );

  // const awayLineup = players?.filter(
  //   (r) => r.organization?.id === awayTeam?.organization?.id
  // );

  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <div className="ScoringApp-football__publish">
      <div className="ScoringApp-football__header">
        <div className="ScoringApp-football__header__nav-left">
          {completed ? (
            <div
              className="ScoringApp-football__header__nav-left__back-btn"
              onClick={() => {
                history.replace("/app/fixture/" + id);
                // setStep(1);
              }}
            >
              <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
              <div className="ScoringApp-football__header__nav-left__back-btn__text">
                Back to fixture
              </div>
            </div>
          ) : (
            <div
              className="ScoringApp-football__header__nav-left__back-btn"
              onClick={() => {
                setStep(1);
              }}
            >
              <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
              <div className="ScoringApp-football__header__nav-left__back-btn__text">
                Back to collect stats
              </div>
            </div>
          )}
        </div>

        <div className="ScoringApp-football__header__main">
          Verify Fixture Stats & Result
        </div>

        <div className="ScoringApp-football__header__nav-right">
          <div
            className="ScoringApp-football__header__nav-right__nav-btn square"
            onClick={() => {
              setStep(0);
            }}
          >
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          {/* <Button
            className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
            onClick={() => {
              // setStep(1);
              setOverlay(
                <PublishStatsConfirm
                  fixtureId={id}
                  completed={completed}
                  dismiss={() => {
                    setOverlay(false);
                  }}
                  placements={[
                    {
                      organizationId: homeTeam?.organization?.id,
                      value: Number(homeScore) || 0,
                      valueString: homeScore + "/" + homeWickets,
                    },
                    {
                      organizationId: awayTeam?.organization?.id,
                      value: Number(awayScore) || 0,
                      valueString: awayScore + "/" + awayWickets,
                    },
                  ]}
                  tables={rows.reduce((accum, curr) => {
                    for (let statTypeId in curr.stats) {
                      if (curr.stats[statTypeId]) {
                        accum.push({
                          profileId: curr.profile.id,
                          organizationId: curr.organization.id,
                          statTypeId,
                          value: Number(curr.stats[statTypeId] || 0),
                        });
                      }
                    }

                    return accum;
                  }, [])}
                />
              );
            }}
          >
            {completed ? "Update final score" : "Publish this fixture"}
          </Button> */}
          {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
          <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
        </div>
        <div className="ScoringApp-football__header__nav-right__nav-btn">
          Verify & Publish
        </div> */}
        </div>
      </div>

      <div
        className="ScoringApp-football__body"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PublishFixtureConfirmation
          fixtureId={id}
          periods={periods}
          homeParticipant={homeParticipant}
          awayParticipant={awayParticipant}
          completed={completed}
        />
        {/* {loading ? (
          <Spinner />
        ) : (
          <div className="ScoringApp-football__publish__grid">
            <div className="ScoringApp-football__publish__grid__container">
              <StatTable
                organization={homeTeam?.organization}
                rows={rows.filter(
                  (r) => r.organization?.id === homeTeam?.organization?.id
                )}
                score={homeScore}
                wickets={homeWickets}
                statTypes={statTypes}
                setRows={setRows}
                setScore={setHomeScore}
                setWickets={setHomeWickets}
                completed={completed}
                lineup={homeLineup}
              />
            </div>

            <div className="ScoringApp-football__publish__grid__container">
              <StatTable
                organization={awayTeam?.organization}
                rows={rows.filter(
                  (r) => r.organization?.id === awayTeam?.organization?.id
                )}
                score={awayScore}
                wickets={awayWickets}
                statTypes={statTypes}
                setRows={setRows}
                setScore={setAwayScore}
                setWickets={setAwayWickets}
                completed={completed}
                lineup={awayLineup}
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
