import { gql } from "@apollo/client";

const DELETE_FIXTURE_ROLE = gql`
  mutation deleteFixtureRole($id: ID!) {
    deleteFixtureRole(id: $id) {
      id
    }
  }
`;

export default DELETE_FIXTURE_ROLE;
