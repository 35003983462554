import { gql } from "@apollo/client";

const ORGANIZATION_AWARDS = gql`
  query organizationAwardsQuery($id: ID!, $sport: String) {
    organization(id: $id) {
      id
      placements(sport: $sport) {
        id
        order
        event {
          id
          name
        }
        awardType {
          id
          name
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
      }
    }
  }
`;

export default ORGANIZATION_AWARDS;
