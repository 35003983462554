import "./EventAwards.css";
import { useQuery } from "@apollo/client";
import { EVENT_AWARD_TYPES } from "../../../api/event";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import { Link } from "react-router-dom";

function EventAwardType({ awardType }) {
  const profile = awardType?.placements?.[0]?.profile;

  if (!profile) {
    return <div />;
  }

  return (
    <div className="fixture-awards-card__award-type">
      <div className="fixture-awards-card__award-type__img">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="fixture-awards-card__award-type__desc">
        <div className="fixture-awards-card__award-type__desc__primary">
          {awardType.name.toUpperCase()}
        </div>

        {profile ? (
          <Link
            to={"/app/profile/" + profile.id}
            className="fixture-awards-card__award-type__desc__secondary"
          >
            {profile.name}
          </Link>
        ) : (
          <div className="fixture-awards-card__award-type__desc__secondary">
            {profile?.name || "TBD"}
          </div>
        )}

        {profile && (
          <div className="fixture-awards-card__award-type__desc__tertiary">
            {profile?.handle && "@ " + profile.handle}
          </div>
        )}
      </div>
    </div>
  );
}

export default function EventAwards({ id }) {
  const { loading, error, data } = useQuery(EVENT_AWARD_TYPES, {
    variables: {
      where: { event: { id }, subtype: "EVENT" },
      placementsWhere: { type: { name: "AWARD" } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventAwards",
        query: "EVENT_AWARD_TYPES",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const awardTypes = data?.awardTypes;

  if (!awardTypes?.length) {
    return <div />;
  }

  const placementsCount = data?.awardTypes.reduce((accum, curr) => {
    return accum + (curr.placements?.length || 0);
  }, 0);

  // console.log(awardTypes);

  if (!placementsCount) {
    return <div />;
  }

  return (
    <div className="event-awards-mobile">
      <div className="event-awards-mobile__title">Awards</div>
      {awardTypes?.map((at) => (
        <EventAwardType key={at.id} awardType={at} />
      ))}
    </div>
  );
}
