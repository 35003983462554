// V1
import { useState } from "react";
import "./ManageEventVenue.css";
import Card from "../../Card";
import Dropdown from "../../Dropdown";
import Spinner from "../../Spinner";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import MapContainer from "../../Map";
import { useMutation, useQuery } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../../../api/event";
import captureException from "../../../utils/captureException";

function EditEventVenue({ id, event, dismiss }) {
  const [address, setAddress] = useState("");
  const [venue, setVenue] = useState({
    label: event.venueLabel,
    value: event.venueValue,
    lat: event.venueLat,
    lng: event.venueLng,
  });
  const [updating, setUpdating] = useState(false);
  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`id venueLabel venueValue venueLat venueLng`),
    {
      update: (cache, { data: { updateEvent } }) => {
        const q = cache.readQuery({
          query: EVENT,
          variables: {
            id,
          },
        });

        cache.writeQuery({
          query: EVENT,
          variables: { id },
          data: {
            event: {
              ...q.event,
              ...updateEvent,
            },
          },
        });
        setUpdating(false);
        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventVenue.EditEventVenue",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <Card className="manage-event-venue">
      <div className="card-title">Location</div>
      <div className="map">
        <MapContainer venue={venue} />
      </div>
      <div className="address">
        <PlacesAutocomplete
          value={address}
          onChange={(x) => {
            setAddress(x);
          }}
          onSelect={(loc) => {
            // console.log(loc);
            if (submitting) return;
            setAddress(loc);
            setUpdating(true);
            // const city = loc?.split(",")[0].trim();
            // const state = loc?.split(",")[1].trim();
            // const country = loc?.split(",")[2].trim();
            // setLocation(city);
            // setSelectedCity(city);
            // setSelectedState(state);
            // setSelectedCountry(country);
            geocodeByAddress(loc)
              .then((results) => getLatLng(results[0]))
              .then((latLng) => {
                const locTokens = loc.split(",");
                const newVenue = {
                  lat: latLng.lat,
                  lng: latLng.lng,
                  label: locTokens[0].trim(),
                  value: locTokens.splice(1, locTokens.length).join(","),
                };
                setVenue(newVenue);

                updateEvent({
                  variables: {
                    where: { id },
                    data: {
                      venueLabel: newVenue.label,
                      venueValue: newVenue.value,
                      venueLat: newVenue.lat,
                      venueLng: newVenue.lng,
                    },
                  },
                });
              })
              .catch((error) => console.error("Error", error));
          }}
          debounce={200}
          searchOptions={{
            // types: ["(cities)"],
            componentRestrictions: {
              country: "IN",
            },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <Dropdown
              className="address-input-wrapper"
              placement="top-start"
              style={{ width: "100%" }}
              onOpen={() => {
                setAddress("");
              }}
              target={
                <div className="address-input-wrapper__form">
                  <div className="address-input-wrapper__input">
                    <input
                      {...getInputProps({
                        className: "input " + (address ? "hasValue " : " "),
                        autoComplete: "off",
                      })}
                      value={address}
                      disabled={updating}
                      placeholder="Type to search"
                    />
                    {submitting && (
                      <div className="submitting">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  <div
                    className="address-input-wrapper__form__dismiss-btn"
                    onClick={() => {
                      dismiss();
                    }}
                  >
                    <TimesIcon className="address-input-wrapper__form__dismiss-btn__icon" />
                  </div>
                </div>
              }
              menu={({ setShowMenu }) => (
                <Card className="address-input-wrapper-menu">
                  {!address && (
                    <div className="placeholder-text">Type something</div>
                  )}

                  {address && !suggestions?.length && (
                    <div className="placeholder-text">No results found</div>
                  )}
                  {suggestions.map((suggestion, i) => (
                    <div
                      key={"address-suggestion-" + i}
                      {...getSuggestionItemProps(suggestion, {
                        className: "address-item",
                      })}
                    >
                      {suggestion.description}
                      {/*<span className="state">{city.within.value}</span>*/}
                    </div>
                  ))}
                </Card>
              )}
            />
          )}
        </PlacesAutocomplete>
      </div>
    </Card>
  );
}

function DeleteEventVenue({ ev }) {
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`id venueLabel venueValue venueLat venueLng`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            venueLabel: () => {
              return updateEvent.venueLabel;
            },
            venueValue: () => {
              return updateEvent.venueValue;
            },
            venueLat: () => {
              return updateEvent.venueLat;
            },
            venueLng: () => {
              return updateEvent.venueLng;
            },
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventVenue.EditEventVenue",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div
      className={"delete-btn " + (loading ? "disabled" : "")}
      onClick={() => {
        if (loading) {
          return;
        }

        updateEvent({
          variables: {
            where: { id: ev.id },
            data: {
              venueLabel: null,
              venueValue: null,
              venueLat: null,
              venueLng: null,
            },
          },
        });
      }}
    >
      <TrashIcon className="icon" />
    </div>
  );
}

function ManageEventVenue({ id }) {
  const [edit, setEdit] = useState(false);
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventVenue",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  // console.log("VENUE EVENT:", ev);

  if (edit) {
    return (
      <EditEventVenue
        id={id}
        event={ev}
        dismiss={() => {
          setEdit(false);
        }}
      />
    );
  } else {
    return (
      <div>
        <Card className="manage-event-venue">
          <div className="card-title">Location</div>
          <div className="map">
            <MapContainer
              venue={{
                label: ev.venueLabel,
                value: ev.venueValue,
                lat: ev.venueLat,
                lng: ev.venueLng,
              }}
            />
          </div>
          <div className="address">
            <div className="text">
              <div className="primary-text">
                {ev.venueLabel || "No venue added"}
              </div>
              <div className="secondary-text">{ev.venueValue || ""}</div>
            </div>

            <div
              className="edit-btn"
              onClick={() => {
                setEdit(true);
              }}
            >
              <PencilIcon className="icon" />
            </div>

            {ev.venueLabel && <DeleteEventVenue ev={ev} />}
          </div>
        </Card>
      </div>
    );
  }
}

export default ManageEventVenue;
