import { useState, Fragment } from "react";
import "./FixtureSummary.css";
import Card from "../Card";
import { ReactComponent as PinIcon } from "../../icons/pin.svg";
import { ReactComponent as ClockIcon } from "../../icons/clock.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
// import { UPDATE_FIXTURE } from "../../api/fixture";
import Spinner from "../Spinner";
import MapContainer from "../Map";
import moment from "moment";
import FixtureLatestVideo from "./FixtureLatestVideo";
import FixtureLineupsSummary from "./FixtureLineupsSummary";
import FixtureTimeline from "./FixtureTimeline";
import FixtureAwards from "./FixtureAwards";
import HeadToHead from "./HeadToHead";
import CricketBoxScore from "../CricketBoxScore";
import CricketBallByBall from "../CricketBallByBallGraph";
import FootballPenaltyShootout from "../FootballPenaltyShootout";
import FixtureEventsSummary from "./FixtureEventsSummary/FixtureEventsSummary";
import Placeholder from "../Placeholder/Placeholder";
import captureException from "../../utils/captureException";
import FootballEventsSummary from "./FootballEventsSummary";
import FixtureReferees from "./FixtureReferees";
import { FIXTURE, UPDATE_FIXTURE } from "../../api/functions";

function FixtureDate({ fixture, canManageFixture }) {
  const [editing, setEditing] = useState(false);
  const [date, setDate] = useState(
    fixture.scheduledStart ? new Date(fixture.scheduledStart).toISOString() : ""
  );
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
      scheduledStart
    `,
    }),
    {
      update() {
        setEditing(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureSummary.FixtureDate",
            mutation: "UPDATE_FIXTURE",
          },
        });
        console.log(error);
      },
    }
  );

  if (editing) {
    return (
      <div className="info-item">
        <div className="icon">
          <ClockIcon style={{ stroke: "var(--dark-4)", height: 16 }} />
        </div>
        <input
          type="datetime-local"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />

        {loading ? (
          <div className="info-item-loader">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            <div
              className="info-item-confirm-btn"
              onClick={() => {
                if (loading) return;
                updateFixture({
                  variables: {
                    where: { id: fixture.id },
                    data: {
                      scheduledStart: date ? moment(date).toISOString() : null,
                    },
                  },
                });
              }}
            >
              <CheckIcon className="info-item-confirm-btn__icon" />
            </div>

            <div
              className="info-item-cancel-btn"
              onClick={() => setEditing(false)}
            >
              <TimesIcon className="info-item-cancel-btn__icon" />
            </div>
          </Fragment>
        )}
      </div>
    );
  } else {
    return (
      <div className="info-item">
        <div className="icon">
          <ClockIcon style={{ stroke: "var(--dark-4)", height: 16 }} />
        </div>
        <div className="info-item-text">
          {fixture.scheduledStart
            ? moment(fixture.scheduledStart).format("Do MMMM YYYY HH:mm")
            : "TBD"}
        </div>
        {canManageFixture && (
          <div className="info-item-edit-btn" onClick={() => setEditing(true)}>
            <PencilIcon className="info-item-edit-btn__icon" />
          </div>
        )}
      </div>
    );
  }
}

function FixtureEndDate({ fixture, canManageFixture }) {
  const [editing, setEditing] = useState(false);
  const [date, setDate] = useState(
    fixture.scheduledEnd ? new Date(fixture.scheduledEnd).toISOString() : ""
  );
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
      scheduledEnd
    `,
    }),
    {
      update() {
        setEditing(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "FixtureSummary.FixtureEndDate",
            mutation: "UPDATE_FIXTURE",
          },
        });
        console.log(error);
      },
    }
  );

  if (editing) {
    return (
      <div className="info-item">
        <div className="icon">
          <ClockIcon style={{ stroke: "var(--dark-4)", height: 16 }} />
        </div>
        <input
          type="datetime-local"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />

        {loading ? (
          <div className="info-item-loader">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            <div
              className="info-item-confirm-btn"
              onClick={() => {
                if (loading) return;
                updateFixture({
                  variables: {
                    where: { id: fixture.id },
                    data: {
                      scheduledEnd: date ? moment(date).toISOString() : null,
                    },
                  },
                });
              }}
            >
              <CheckIcon className="info-item-confirm-btn__icon" />
            </div>

            <div
              className="info-item-cancel-btn"
              onClick={() => setEditing(false)}
            >
              <TimesIcon className="info-item-cancel-btn__icon" />
            </div>
          </Fragment>
        )}
      </div>
    );
  } else {
    return (
      <div className="info-item">
        <div className="icon">
          <ClockIcon style={{ stroke: "var(--dark-4)", height: 16 }} />
        </div>
        <div className="info-item-text">
          {fixture.scheduledEnd
            ? moment(fixture.scheduledEnd).format("Do MMMM YYYY HH:mm")
            : "TBD"}
        </div>
        {canManageFixture && (
          <div className="info-item-edit-btn" onClick={() => setEditing(true)}>
            <PencilIcon className="info-item-edit-btn__icon" />
          </div>
        )}
      </div>
    );
  }
}

function FixtureDetails({ id, canManageFixture }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      scheduledStart
      scheduledEnd
      venueLabel
      venueValue
      venueLat
      venueLng
      event {
        id
        venueLabel
        venueValue
        venueLat
        venueLng
      }
    `,
    }),
    { variables: { id } }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureSummary.FixtureDetails",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="match-details-card">
        <div
          className="title"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="info-map">
          <Placeholder
            style={{
              height: "100%",
              width: "100%",
              background: "var(--blue-subtle)",
            }}
          />
        </div>
        <div className="info-item">
          <div className="icon">
            <Placeholder
              style={{ height: "12px", width: "20px" }}
              pulse={true}
            />
          </div>
          <div className="info-item-text">
            <div className="bold">
              <Placeholder
                style={{ height: "12px", width: "60px" }}
                pulse={true}
              />
            </div>
            <div>
              <Placeholder
                style={{ height: "12px", width: "160px", marginTop: "8px" }}
                pulse={true}
              />
              <Placeholder
                style={{ height: "12px", width: "160px", marginTop: "8px" }}
                pulse={true}
              />
              <Placeholder
                style={{ height: "12px", width: "160px", marginTop: "8px" }}
                pulse={true}
              />
              <Placeholder
                style={{ height: "12px", width: "160px", marginTop: "8px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>

        <div className="match-details-card__info-item__label">
          <Placeholder style={{ height: "8px", width: "40px" }} pulse={true} />
        </div>
        <div className="info-item">
          <div className="icon">
            <Placeholder
              style={{ height: "8px", width: "20px" }}
              pulse={true}
            />
          </div>
          <div className="info-item-text">
            <Placeholder
              style={{ height: "8px", width: "80px" }}
              pulse={true}
            />
          </div>
        </div>
      </Card>
    );
  }

  const fixture = data.fixture;
  // console.log(fixture);

  return (
    <Card className="match-details-card">
      <div className="title">Match Details</div>
      <div className="info-map">
        <MapContainer
          venue={{
            label: fixture.venueLabel || fixture.event.venueLabel,
            value: fixture.venueValue || fixture.event.venueValue,
            lat: fixture.venueLat || fixture.event.venueLat,
            lng: fixture.venueLng || fixture.event.venueLng,
          }}
        />
      </div>
      <div className="info-item">
        <div className="icon">
          <PinIcon style={{ stroke: "var(--dark-4)", height: 16 }} />
        </div>
        <div className="info-item-text">
          <div className="bold">
            {fixture.venueLabel || fixture.event.venueLabel || "TBD"}
          </div>
          <div>{fixture.venueValue || fixture.event.venueValue || ""}</div>
        </div>
      </div>

      {canManageFixture && (
        <div className="match-details-card__info-item__label">Starts at</div>
      )}
      <FixtureDate fixture={fixture} canManageFixture={canManageFixture} />
      {canManageFixture && (
        <Fragment>
          <div className="match-details-card__info-item__label">Ends at</div>
          <FixtureEndDate
            fixture={fixture}
            canManageFixture={canManageFixture}
          />
        </Fragment>
      )}
    </Card>
  );
}

function FixtureSummary({ canManageFixture }) {
  const { id } = useParams();
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      uploaded
      event {
        id
        participantType
        sport {
          id
          name
        }
      }
      roles(where:{type:{name:"PARTICIPANT"}}) {
        id
        order
        type {
          id
          name
        }        
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }        
      }
    `,
    }),
    { variables: { id } }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureSummary",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;
  // console.log(fixture);

  const homeParticipant = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayParticipant = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  const uploaded = fixture?.uploaded;

  return (
    <div
      className="fixture-summary"
      style={
        fixture.event.sport.name === "Football" ? {} : { paddingTop: "32px" }
      }
    >
      {fixture.event.sport.name === "Football" && <FixtureTimeline id={id} />}
      <div className="left-column">
        <FixtureDetails id={id} canManageFixture={canManageFixture} />

        <FixtureAwards
          id={id}
          eventId={fixture.event.id}
          canManageFixture={canManageFixture}
        />
      </div>

      <div className="middle-column">
        {(fixture.event.sport.name === "Cricket" ||
          fixture.event.sport.name === "Box Cricket") && (
          <CricketBoxScore fixture={fixture} />
        )}
        {(fixture.event.sport.name === "Cricket" ||
          fixture.event.sport.name === "Box Cricket") &&
          !uploaded && (
            <CricketBallByBall
              fixtureId={fixture.id}
              homeParticipant={homeParticipant}
              awayParticipant={awayParticipant}
            />
          )}

        {fixture.event.sport.name === "Football" && (
          <FootballPenaltyShootout fixtureId={fixture.id} />
        )}
        <FixtureLatestVideo id={id} />

        {!uploaded && (
          <Fragment>
            {fixture.event.sport.name === "Football" ? (
              <FootballEventsSummary id={id} />
            ) : (
              <FixtureEventsSummary id={id} />
            )}
          </Fragment>
        )}
      </div>

      <div className="right-column">
        {fixture.event.participantType === "TEAM" ? (
          <FixtureLineupsSummary id={id} />
        ) : (
          <HeadToHead
            homeParticipant={homeParticipant}
            awayParticipant={awayParticipant}
            sport={fixture.event.sport.name}
          />
        )}

        <FixtureReferees id={id} canManageFixture={canManageFixture} />
      </div>
    </div>
  );
}

export default FixtureSummary;
