import { gql } from "@apollo/client";

const SEARCH_EVENT_PARTICIPANTS = gql`
  query searchEventParticipants($eventId: ID!, $term: String!) {
    searchEventParticipants(eventId: $eventId, term: $term) {
      organizations {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default SEARCH_EVENT_PARTICIPANTS;
