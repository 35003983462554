import "./ManageEventStandings.css";
import { useQuery, useMutation } from "@apollo/client";
import {
  EVENT_STANDINGS,
  CALCULATE_STANDINGS,
  DELETE_STANDING,
  REORDER_STANDING_ITEMS,
  UPDATE_STANDING_ITEM_META,
  EVENT_PARTS,
} from "../../../api/event";
import Spinner from "../../../components/Spinner";
import { useState, Fragment, useRef, useEffect } from "react";
import moment from "moment";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as LeftArrowIcon } from "../../../icons/arrow-left.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as UpDownIcon } from "../../../icons/up-down.svg";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import { useAppState } from "../../../utils/appState";
import Loader from "../../../components/Loader";
import Avatar from "../../../components/Avatar";
import Chip from "../../../components/Chip";
import captureException from "../../../utils/captureException";

function EditStandingField({ title, config, defaultValue }) {
  const [, setAppState] = useAppState();
  const [value, setValue] = useState(defaultValue);
  const dismiss = () => {
    setAppState({ modal: false });
  };

  const [updateStandingItemMeta, { loading }] = useMutation(
    UPDATE_STANDING_ITEM_META,
    {
      update(cache, { data: { updateStandingItemMeta } }) {
        // cache.modify
        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventStandings.EditStandingField",
            mutation: "UPDATE_STANDING_ITEM_META",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">{title}</div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();

              if (loading || !config.meta) {
                return;
              }
              updateStandingItemMeta({
                variables: {
                  where: { id: config.meta.id },
                  data: config.vars(value),
                },
              });
              // config.onSubmit({ fields, mutate });
            }}
          >
            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                {config.label}
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type={config.type || "number"}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {loading ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  dismiss();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function CalculateStandings({ id }) {
  const [, setAppState] = useAppState();
  const [eventPart, setEventPart] = useState("");
  // const [eventParts, setEventParts] = useState([]);
  // const [title, setTitle] = useState("");
  const [stageSelector, setStageSelector] = useState(false);
  const [pointsPerWin, setPointsPerWin] = useState(3);
  const [pointsPerLoss, setPointsPerLoss] = useState(0);
  const [pointsPerDraw, setPointsPerDraw] = useState(1);

  const { data, error } = useQuery(EVENT_PARTS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventStandings.CalculateStandings",
        query: "EVENT_PARTS",
      },
    });
  }

  const [calculateStandings, { loading: submitting }] = useMutation(
    CALCULATE_STANDINGS,
    {
      update: (cache, { data: { calculateStandings } }) => {
        const q = cache.readQuery({
          query: EVENT_STANDINGS,
          variables: { id },
        });

        cache.writeQuery({
          query: EVENT_STANDINGS,
          variables: { id },
          data: {
            event: {
              ...q.event,
              standings: [calculateStandings, ...q.event.standings],
            },
          },
        });

        setAppState({ modal: false });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventStandings.CalculateStandings",
            mutation: "CALCULATE_STANDINGS",
          },
        });
        console.log(error);
      },
    }
  );

  const dismiss = () => {
    setAppState({ modal: false });
  };

  const parts = data?.event?.parts || [];
  // console.log("EPs:", eventParts);

  if (stageSelector) {
    return (
      <div className="modal-form-mobile-wrapper">
        <div
          className="modal-form-mobile"
          style={{ height: "80%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="modal-form-mobile__title"
            style={{ paddingLeft: "12px" }}
          >
            <div
              className="modal-form-mobile__title__icon-left"
              onClick={() => {
                setStageSelector(false);
              }}
            >
              <LeftArrowIcon
                style={{
                  stroke: "var(--dark-4)",
                  height: "12px",

                  // marginRight: "8px",
                }}
              />
            </div>

            <div className="modal-form-mobile__title__text">Select stage</div>
            <div
              className="modal-form-mobile__title__dismiss-btn"
              onClick={() => {
                setAppState({ modal: false });
              }}
            >
              <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
            </div>
          </div>

          <div
            className="modal-form-mobile__body"
            style={{ flexGrow: 1, overflow: "auto" }}
          >
            <div
              className="edit-event-registration-mobile__team-selection-item"
              onClick={() => {
                setEventPart("");
                // setEventParts([]);
                setStageSelector(false);
              }}
            >
              {/* <div className="edit-event-registration-mobile__team-selection-item__img"></div> */}

              <div className="edit-event-registration-mobile__team-selection-item__text">
                <div className="edit-event-registration-mobile__team-selection-item__text__primary">
                  All Stages
                </div>

                {/* <div className="edit-event-registration-mobile__team-selection-item__text__secondary">
                    @{p.organization?.handle}
                  </div> */}
              </div>
            </div>
            {parts.map((p) => (
              <div
                className="edit-event-registration-mobile__team-selection-item"
                onClick={() => {
                  setEventPart(p);
                  // setEventParts((prev) => {
                  //   if (prev.find((x) => x.id === p.id)) {
                  //     return prev;
                  //   } else {
                  //     return [...prev, p];
                  //   }
                  // });
                  setStageSelector(false);
                }}
              >
                {/* <div className="edit-event-registration-mobile__team-selection-item__img"></div> */}

                <div className="edit-event-registration-mobile__team-selection-item__text">
                  <div className="edit-event-registration-mobile__team-selection-item__text__primary">
                    {p.label}
                  </div>

                  {/* <div className="edit-event-registration-mobile__team-selection-item__text__secondary">
                    @{p.organization?.handle}
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Calculate Standings
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body" style={{ overflow: "auto" }}>
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();

              if (submitting) return;

              // const eventPartIds = eventParts.map((x) => x.id);

              const vars = {
                eventId: id,
                eventPartId: eventPart?.id || null,
                // eventPartIds,
                pointsPerWin: Number(pointsPerWin),
                pointsPerDraw: Number(pointsPerDraw),
                pointsPerLoss: Number(pointsPerLoss),
                // title: eventParts.length > 1 ? title : null,
              };

              // console.log(vars);

              calculateStandings({
                variables: vars,
              });
              // config.onSubmit({ fields, mutate });
            }}
          >
            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">Stage</div>

              <div
                className="modal-form-mobile__form__group__input"
                onClick={() => {
                  setStageSelector(true);
                }}
              >
                <div className="modal-form-mobile__form__group__input__select">
                  <div className="modal-form-mobile__form__group__input__select__value">
                    {eventPart?.label || "All Stages"}
                  </div>
                  <div className="modal-form-mobile__form__group__input__select__icon">
                    <UpDownIcon
                      style={{ height: "12px", fill: "var(--light-3)" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* {!!eventParts?.length && (
              <div className="modal-form-mobile__form__group recalculate-event-standings-mobile__periods">
                {eventParts.map((p) => (
                  <Chip
                    className="recalculate-event-standings-mobile__periods__item"
                    key={"chip-" + p.id}
                    onClick={() => {
                      setEventParts((prev) => {
                        return eventParts.filter((x) => x?.id !== p?.id);
                      });
                    }}
                  >
                    {p.label}{" "}
                    <TimesIcon className="recalculate-event-standings-mobile__periods__item__icon" />
                  </Chip>
                ))}
              </div>
            )} */}

            {/* {eventParts.length > 1 && (
              <div className="modal-form-mobile__form__group">
                <div className="modal-form-mobile__form__group__label">
                  Standings Title
                </div>

                <div className="modal-form-mobile__form__group__input">
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
            )} */}

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Points per win
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="number"
                  value={pointsPerWin}
                  onChange={(e) => {
                    setPointsPerWin(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Points per draw
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="number"
                  value={pointsPerDraw}
                  onChange={(e) => {
                    setPointsPerDraw(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Points per loss
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="number"
                  value={pointsPerLoss}
                  onChange={(e) => {
                    setPointsPerLoss(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {submitting ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  dismiss();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function getTableConfig({ sport = {} }) {
  if (sport.name === "Football") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "88px" },
        value: (item) => {
          return item.order + 1;
        },
        canReorder: true,
        fixed: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        fixed: true,
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Games Played"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Won"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Drawn"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Lost"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },

      {
        label: "GF",
        tooltip: "Goals For",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Goals For")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Goals For"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "GA",
        tooltip: "Goals Against",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Goals Against")?.value || 0
          );
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Goals Against"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "GD",
        tooltip: "Goal Difference",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Difference")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Difference"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
        canEdit: (item) => ({
          type: "number",
          meta: item.metas.find((m) => m.label === "Points"),
          vars: (val) => ({ value: val ? Number(val) : 0 }),
        }),
      },
    ];
  } else if (sport.name?.includes("Basketball")) {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        canReorder: true,
        fixed: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        fixed: true,
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },

      {
        label: "Pct",
        tooltip: "Win Percentage",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Win Percentage")?.value || 0
          );
        },
      },
      {
        label: "For",
        tooltip: "Points Scored",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points For")?.value || 0;
        },
      },
      {
        label: "Against",
        tooltip: "Points Conceded",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Points Against")?.value || 0
          );
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name === "Cricket") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        fixed: true,
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "NRR",
        tooltip: "Net Run Rate",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return (
            item.metas
              .find((m) => m.label === "Net Run Rate")
              ?.value?.toFixed(3) || "-"
          );
        },
      },
      // {
      //   label: "For",
      //   tooltip: "Run Rate For",
      //   style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate For")?.stringValue ||
      //       "-"
      //     );
      //   },
      // },
      // {
      //   label: "Against",
      //   tooltip: "Run Rate Against ",
      //   style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate Against")
      //         ?.stringValue || "-"
      //     );
      //   },
      // },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name === "Badminton") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        placeholderWidth: "160px",
        fixed: true,
        hasLink: (item) => {
          return `/app/${item.organization ? "page" : "profile"}/${
            item.organization?.id || item.profile?.id
          }`;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },

      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "+/-",
        tooltip: "Point Difference",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          const scored =
            item.metas.find((m) => m.label === "Points For")?.value || 0;
          const conceded =
            item.metas.find((m) => m.label === "Points Against")?.value || 0;
          return scored - conceded;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "40px" },
        value: (item) => {
          return item.order + 1;
        },
        fixed: true,
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { flexGrow: 1 },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        fixed: true,
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "40px", justifyContent: "flex-end", flexShrink: 0 },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: {
          width: "40px",
          justifyContent: "flex-end",
          fontWeight: "700",
          flexShrink: 0,
        },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  }
}

function StandingsTable({ tableConfig, standing, eventId }) {
  const [, setAppState] = useAppState();
  const [isScrolled, setIsScrolled] = useState(0);
  const [isReordering, setIsReordering] = useState(false);
  const [isReorderingDirection, setIsReorderingDirection] = useState("");
  const scrollRef = useRef(null);

  useEffect(() => {
    const div = scrollRef?.current;
    const handleScroll = () => {
      // console.log(div.scrollLeft);
      if (div.scrollLeft > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => div?.removeEventListener("scroll", handleScroll);
  }, [scrollRef]);

  const [deleteStanding, { loading: deleting }] = useMutation(DELETE_STANDING, {
    update: (cache, { data: { deleteStanding } }) => {
      const q = cache.readQuery({
        query: EVENT_STANDINGS,
        variables: { id: eventId },
      });

      cache.writeQuery({
        query: EVENT_STANDINGS,
        variables: { id: eventId },
        data: {
          event: {
            ...q.event,
            standings: q.event.standings.filter(
              (s) => s.id !== deleteStanding.id
            ),
            parts: q.event.parts.map((p) => {
              return {
                ...p,
                standings: p.standings.filter(
                  (s) => s.id !== deleteStanding.id
                ),
              };
            }),
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventStandings.StandingsTable",
          mutation: "DELETE_STANDING",
        },
      });
      console.log(error);
    },
  });

  const [reorderStandingItems, { loading: reordering }] = useMutation(
    REORDER_STANDING_ITEMS,
    {
      update: (cache, { data: { reorderStandingItems } }) => {
        // console.log("SI:", reorderStandingItems);
        setIsReordering(false);
        setIsReorderingDirection("");
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventStandings.StandingsTable",
            mutation: "REORDER_STANDING_ITEMS",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="event-standings-mobile__tables__table">
      <div className="event-standings-mobile__tables__table__title">
        <div className="event-standings-mobile__tables__table__title__text">
          <div className="event-standings-mobile__tables__table__title__text__primary">
            {standing.title ||
              standing.eventPart?.label ||
              standing.event?.name}
          </div>

          <div className="event-standings-mobile__tables__table__title__text__secondary">
            Calculated at {moment(standing.createdAt).format("Do MMM YYYY")}
          </div>
        </div>

        <div
          className="delete-btn"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            // console.log("Clicked");
            if (deleting) return;
            deleteStanding({
              variables: {
                id: standing.id,
              },
            });
          }}
        >
          {deleting ? (
            <Spinner size={12} />
          ) : (
            <TrashIcon
              className="icon"
              style={{
                stroke: "var(--red-main)",
                height: "12px",
              }}
            />
          )}
        </div>
      </div>
      <div className="event-standings-mobile__tables__table__columns">
        <div
          className="event-standings-mobile__tables__table__columns-fixed"
          style={{ width: "70%" }}
        >
          <div className="event-standings-mobile__tables__table__header">
            <div className="row">
              {tableConfig
                .filter((c) => c.fixed)
                .map((c) => (
                  <div
                    key={"event-standings-header-" + standing.id + c.label}
                    className="td"
                    style={c.style}
                  >
                    {c.label}
                  </div>
                ))}
            </div>
          </div>
          <div className="event-standings-mobile__tables__table__body">
            {[...(standing.items || [])]
              ?.sort((a, b) => a.order - b.order)
              .map((item, i) => (
                <div key={"event-standings-item-" + item.id} className="row">
                  {tableConfig
                    .filter((c) => c.fixed)
                    .map((c) => (
                      <div
                        key={"event-standings-item-td-" + item.id + c.label}
                        className="td"
                        style={c.style}
                      >
                        {c.hasImage && (
                          <div className="logo">
                            {c.hasImage(item) && (
                              <Avatar media={c.hasImage(item)} />
                            )}
                          </div>
                        )}

                        {c.canReorder ? (
                          <div
                            className="event-standings-mobile__tables__table__body__order"
                            style={{ marginRight: "8px" }}
                          >
                            {c.value(item)}
                          </div>
                        ) : (
                          c.value(item)
                        )}

                        {c.canReorder && (
                          <Fragment>
                            {!!i ? (
                              <div
                                className="event-standings-mobile__tables__table__body__reorder up"
                                style={{
                                  transform: "rotateZ(180deg)",
                                  transformOrigin: "16px 16px",
                                }}
                                onClick={() => {
                                  if (reordering) return;

                                  setIsReordering(item.id);
                                  setIsReorderingDirection("up");

                                  const prevItem = standing.items.find(
                                    (x) => x.order === item.order - 1
                                  );
                                  // console.log(item);
                                  // console.log(prevItem);

                                  reorderStandingItems({
                                    variables: {
                                      data: [
                                        {
                                          where: { id: item.id },
                                          order: item.order - 1,
                                        },
                                        {
                                          where: { id: prevItem.id },
                                          order: prevItem.order + 1,
                                        },
                                      ],
                                    },
                                  });
                                }}
                              >
                                {isReordering === item.id &&
                                isReorderingDirection === "up" ? (
                                  <Spinner size={12} />
                                ) : (
                                  <ChevronDownIcon
                                    style={{
                                      height: "14px",
                                      stroke: "var(--dark-4)",
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="event-standings-mobile__tables__table__body__reorder up"></div>
                            )}

                            {i < (standing.items?.length || 0) - 1 && (
                              <div
                                className="event-standings-mobile__tables__table__body__reorder down"
                                onClick={() => {
                                  if (reordering) return;

                                  setIsReordering(item.id);
                                  setIsReorderingDirection("down");

                                  const nextItem = standing.items.find(
                                    (x) => x.order === item.order + 1
                                  );
                                  // console.log(item);
                                  // console.log(prevItem);

                                  reorderStandingItems({
                                    variables: {
                                      data: [
                                        {
                                          where: { id: item.id },
                                          order: item.order + 1,
                                        },
                                        {
                                          where: { id: nextItem.id },
                                          order: nextItem.order - 1,
                                        },
                                      ],
                                    },
                                  });
                                }}
                              >
                                {isReordering === item.id &&
                                isReorderingDirection === "down" ? (
                                  <Spinner size={12} />
                                ) : (
                                  <ChevronDownIcon
                                    style={{
                                      height: "14px",
                                      stroke: "var(--dark-4)",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>

        <div
          className={
            "event-standings-mobile__tables__table__columns-scroll " +
            (isScrolled ? "inset" : "")
          }
          style={{ width: "30%" }}
          ref={scrollRef}
        >
          <div className="event-standings-mobile__tables__table__header">
            <div className="row">
              {tableConfig
                .filter((c) => !c.fixed)
                .map((c) => (
                  <div
                    key={"event-standings-header-" + standing.id + c.label}
                    className="td"
                    style={c.style}
                  >
                    {c.label}
                  </div>
                ))}
            </div>
          </div>
          <div className="event-standings-mobile__tables__table__body">
            {[...(standing.items || [])]
              ?.sort((a, b) => a.order - b.order)
              .map((item) => (
                <div key={"event-standings-item-" + item.id} className="row">
                  {tableConfig
                    .filter((c) => !c.fixed)
                    .map((c) => (
                      <div
                        key={"event-standings-item-td-" + item.id + c.label}
                        className="td"
                        style={c.style}
                        onClick={() => {
                          if (!c.canEdit) return;

                          setAppState({
                            modal: (
                              <EditStandingField
                                title={"Edit standing"}
                                config={{
                                  ...c.canEdit(item),
                                  label: "Edit " + c.tooltip,
                                }}
                                defaultValue={c.value(item)}
                              />
                            ),
                          });
                        }}
                      >
                        {c.hasImage && (
                          <div className="logo">
                            {c.hasImage(item) && (
                              <Avatar media={c.hasImage(item)} />
                            )}
                          </div>
                        )}

                        {c.value(item)}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function StandingsList({ id }) {
  const { loading, error, data } = useQuery(EVENT_STANDINGS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "StandingsList",
        query: "EVENT_STANDINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const sport = data?.event?.sport;
  const standings = data?.event?.standings;
  const parts = data?.event?.parts;
  const tableConfig = getTableConfig({ sport });
  // console.log(standings);

  return (
    <div className="standings-list">
      {standings
        .filter((s) => !s.eventPart)
        .map((s) => (
          <StandingsTable
            key={s.id}
            standing={s}
            eventId={id}
            sport={sport}
            tableConfig={tableConfig}
          />
        ))}

      {/* {standings
        .filter((s) => !s.eventPart)
        .map((s) => (
          <Standing key={s.id} standing={s} eventId={id} sport={sport} />
        ))} */}

      {parts
        ?.map((p, i) => {
          if (p.standings?.length) {
            return (
              <StandingsTable
                key={p.standings[0].id + i}
                standing={p.standings[0]}
                eventId={id}
                sport={sport}
                tableConfig={tableConfig}
              />

              // <Standing
              //   key={p.standings[0].id}
              //   standing={p.standings[0]}
              //   eventId={id}
              //   sport={sport}
              // />
            );
          } else {
            return false;
          }
        })
        .filter((x) => !!x)}
    </div>
  );
}

export default function ManageEventStandings({ id }) {
  const [, setAppState] = useAppState();

  return (
    <div className="manage-event-standings-mobile">
      <div className="manage-event-standings-mobile__header">
        <div
          className="manage-event-standings-mobile__header__option"
          onClick={() => {
            setAppState({ modal: <CalculateStandings id={id} /> });
          }}
        >
          Calculate Standings
        </div>
      </div>

      <div className="manage-event-standings-mobile__list">
        <StandingsList id={id} />
      </div>
    </div>
  );
}
