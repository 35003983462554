import "./CricketStats.css";
import Card from "../../../Card";
import BarChart from "../../../BarChart";
// import ProfileAwards from "../ProfileAwards/ProfileAwards";
import { PROFILE_STATS, PROFILE_AWARDS } from "../../../../api/profile";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import captureException from "../../../../utils/captureException";
import { Fragment } from "react";
import Square from "../../../../mobileComponents/Square";
import VerticalText from "../../../../mobileComponents/VerticalText";

function ProfileBattingStats({
  gamesPlayed,
  runsScored,
  ballsFaced,
  strikeRate,
  fifties,
  hundreds,
}) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">BATTING</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Games Played
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {runsScored}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Runs Scored
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {ballsFaced}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Balls Faced
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {strikeRate}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Strike Rate
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {fifties}
              </div>
              <div className="profile-attack-stats__items__item__label">
                50s
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {hundreds}
              </div>
              <div className="profile-attack-stats__items__item__label">
                100s
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"BATTING"}
              fontStyle={{
                fontSize: "76px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "57px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileDismissalStats({
  bowled,
  lbw,
  caught,
  stumped,
  runOut,
  retired,
  caughtAndBowled,
  notOut,
}) {
  const total =
    bowled +
    lbw +
    caught +
    stumped +
    runOut +
    retired +
    caughtAndBowled +
    notOut;

  return (
    <div
      className="profile-shooting-stats"
      style={{ gridColumnStart: 1, gridColumnEnd: 4 }}
    >
      <div className="profile-shooting-stats__title">DISMISSALS</div>

      <div
        className="profile-shooting-stats__items"
        style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
      >
        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Bowled
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {bowled || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (bowled / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              LBW
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {lbw || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (lbw / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Caught
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {caught || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (caught / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Stumped
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {stumped || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (stumped / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Run Out
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {runOut || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (runOut / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Retired
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {retired || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (retired / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Caught & Bowled
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {caughtAndBowled || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (caughtAndBowled / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Not Out
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {notOut || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: total ? (notOut / total) * 100 + "%" : 0,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileFieldingStats({ catches, runOuts, stumpings }) {
  return (
    <div className="profile-fielding-stats">
      <div className="profile-fielding-stats__title">FIELDING</div>

      <div className="profile-fielding-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats__items__item__value">
                {catches}
              </div>

              <div className="profile-fielding-stats__items__item__key">
                Catches
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats__items__item__value">
                {runOuts}
              </div>

              <div className="profile-fielding-stats__items__item__key">
                Run Outs
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-fielding-stats__items__item"
              ref={squareRef}
              style={{ height: dimensions.width + "px" }}
            >
              <div className="profile-fielding-stats__items__item__value">
                {stumpings}
              </div>

              <div className="profile-fielding-stats__items__item__key">
                Stumpings
              </div>
            </div>
          )}
        </Square>
      </div>

      {/* <div className="profile-fielding-stats__graphic">
        <div className="profile-fielding-stats__graphic__text green">
          FIELDING
        </div>
        <div className="profile-fielding-stats__graphic__text blue">
          FIELDING
        </div>
      </div> */}
    </div>
  );
}

function ProfileBowlingStats({
  overs,
  maidens,
  runsConceded,
  economyRate,
  wicketsTaken,
}) {
  return (
    <div
      className="profile-attack-stats"
      // style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
    >
      <div className="profile-attack-stats__title">BOWLING</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {overs}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Overs
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {maidens}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Maidens
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {runsConceded}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Runs Conceded
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {economyRate}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Economy Rate
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {wicketsTaken}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Wickets
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"BOWLING"}
              fontStyle={{
                fontSize: "40px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "30px",
                height: "40px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function ProfileWicketsTakenStats({
  wicketsTaken,
  bowled,
  lbw,
  caught,
  caughtAndBowled,
  stumped,
}) {
  return (
    <div className="profile-wickets-taken">
      <div className="profile-attack-stats__title">
        DISTRIBUTION OF WICKETS TAKEN
      </div>
      {/* <div className="profile-wickets-taken__highlight">
        <div className="profile-wickets-taken__highlight__value">
          {wicketsTaken}
        </div>
        <div className="profile-wickets-taken__highlight__label">
          Wickets Taken
        </div>
      </div> */}
      <div className="profile-wickets-taken__highlight__graph">
        <BarChart
          labels={["Bowled", "LBW", "Caught", "C&B", "Stumped"]}
          datasets={[
            {
              data: [bowled, lbw, caught, caughtAndBowled, stumped],
              backgroundColor: "#b9e3fe",
            },
          ]}
        />
      </div>
    </div>
  );
}

function ProfileParticipationHistory({ participationHistory }) {
  const rows = participationHistory.map((p) => ({
    ...p,
    stats: JSON.parse(p.stats.data),
  }));
  return (
    <div className="profile-participation-stats">
      <div className="profile-participation-stats__title">
        PARTICIPATION HISTORY
      </div>

      <div className="profile-participation-stats__table">
        <div
          className="profile-participation-stats__table__column"
          style={{ width: "50%" }}
        >
          <div className="profile-participation-stats__table__column__header with-image">
            Event
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td with-image " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {/* <div className="profile-participation-stats__table__td__image"></div> */}
                <div className="profile-participation-stats__table__td__meta">
                  <Link
                    to={"/app/event/" + r.event?.id}
                    className="profile-participation-stats__table__td__meta__primary"
                    style={{ display: "block" }}
                  >
                    {r.event?.name}
                  </Link>

                  {r.organization ? (
                    <Link
                      to={"/app/page/" + r.organization.id}
                      className="profile-participation-stats__table__td__meta__secondary"
                    >
                      {r.organization.name}
                    </Link>
                  ) : (
                    <div className="profile-participation-stats__table__td__meta__secondary">
                      {r.organization?.name}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            GP
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Appearances"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            R
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Runs Scored"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            B
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Balls Faced"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            O
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Balls Bowled"]
                  ? Math.floor(r.stats?.["Balls Bowled"] / 6) +
                    (r.stats?.["Balls Bowled"] % 6)
                  : 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            W
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Wicket Taken"] || 0}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileAwards({ id, sport }) {
  const { loading, error, data } = useQuery(PROFILE_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.CricketStats.ProfileAwards",
        query: "PROFILE_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const profile = data.profile;
  const placements = profile?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats">
      <div className="profile-awards-stats__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats__items">
          <div className="no-data">No awards for this player yet.</div>
        </div>
      )}

      <div className="profile-awards-stats__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats__items__item">
              <div className="profile-awards-stats__items__item__img"></div>
              <div className="profile-awards-stats__items__item__desc">
                <div className="profile-awards-stats__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function CricketStats({ id, sport, events }) {
  const { loading, error, data } = useQuery(PROFILE_STATS, {
    variables: {
      id,
      sport: sport || "Cricket",
      statTypes: [
        "Runs Scored",
        "Balls Faced",
        "Runs Conceded",
        "Balls Bowled",
        "Wicket Taken",
        "Maiden Overs",
        "Catch",
        "Run Out",
        "Stumping",
        "Bowled",
        "LBW",
        "Caught",
        "Caught & Bowled",
        "Stumped",
        "Retired",
      ],
      participationStatTypes: [
        "Runs Scored",
        "Balls Faced",
        "Balls Bowled",
        "Wicket Taken",
      ],
      events,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile.CricketStats",
        query: "PROFILE_STATS",
      },
    });
    console.log(error);
  }

  if (loading) {
    return <div>Loading</div>;
  }

  // console.log("STATS:", data);

  const { appearances, participationHistory } = data?.profile?.stats || {};

  const statsData = JSON.parse(
    data?.profile?.stats?.aggregations?.data || "{}"
  );

  const dismissalsData = JSON.parse(
    data?.profile?.stats?.dismissals?.data || "{}"
  );

  const wicketsData = JSON.parse(
    data?.profile?.stats?.wicketsTaken?.data || "{}"
  );

  const participationData = {
    headers: [
      {
        label: "Team",
        weight: 30,
        hasImage: true,
        link: (d) => `/app/page/${d?.organization?.id}`,
      },
      { label: "Event", weight: 30, link: (d) => `/app/event/${d?.event?.id}` },
      { label: "GP", weight: 8, type: "number" },
      { label: "R", weight: 8, type: "number" },
      { label: "B", weight: 8, type: "number" },
      { label: "O", weight: 8, type: "number" },
      { label: "W", weight: 8, type: "number" },
    ],
    data: data?.profile?.stats?.participationHistory?.map((p) => {
      const participationStats = JSON.parse(p.stats.data);

      return [
        p.organization?.name,
        p.event?.name,
        participationStats["Appearances"],
        participationStats["Runs Scored"],
        participationStats["Balls Faced"],
        Math.floor(participationStats["Balls Bowled"] / 6) +
          (participationStats["Balls Bowled"] % 6),
        participationStats["Wicket Taken"],
      ];
    }),
  };

  // console.log("Wickets Data:", statsData);
  return (
    <Fragment>
      <ProfileBattingStats
        gamesPlayed={appearances}
        runsScored={statsData["Runs Scored"] || 0}
        ballsFaced={statsData["Balls Faced"] || 0}
        strikeRate={
          statsData["Balls Faced"]
            ? Math.floor(
                (statsData["Runs Scored"] / statsData["Balls Faced"]) * 100
              )
            : 0
        }
        fifties={0}
        hundreds={0}
      />

      <div className="profile-stats-grid">
        <ProfileDismissalStats
          bowled={dismissalsData["Bowled"] || 0}
          lbw={dismissalsData["LBW"] || 0}
          caught={dismissalsData["Caught"] || 0}
          stumped={dismissalsData["Stumped"] || 0}
          runOut={dismissalsData["Run Out"] || 0}
          retired={dismissalsData["Retired"] || 0}
          caughtAndBowled={dismissalsData["Caught & Bowled"] || 0}
          notOut={dismissalsData["Not Out"] || 0}
        />

        <ProfileFieldingStats
          catches={statsData["Catch"] || 0}
          runOuts={statsData["Run Out"] || 0}
          stumpings={statsData["Stumping"] || 0}
        />
      </div>

      <ProfileBowlingStats
        overs={
          Math.floor(statsData["Balls Bowled"] / 6) +
            "." +
            (statsData["Balls Bowled"] % 6) || 0
        }
        maidens={statsData["Maiden Overs"] || 0}
        runsConceded={statsData["Runs Conceded"] || 0}
        economyRate={
          Math.floor(
            (statsData["Runs Conceded"] /
              (Math.floor(statsData["Balls Bowled"] / 6) +
                (statsData["Balls Bowled"] % 6) / 6)) *
              100
          ) / 100 || 0
        }
        wicketsTaken={wicketsData["Wicket Taken"] || 0}
      />

      <ProfileWicketsTakenStats
        wicketsTaken={wicketsData["Wicket Taken"] || 0}
        bowled={wicketsData["Bowled"] || 0}
        lbw={wicketsData["LBW"] || 0}
        caught={wicketsData["Caught"] || 0}
        caughtAndBowled={wicketsData["Caught & Bowled"] || 0}
        stumped={wicketsData["Stumped"] || 0}
      />

      <ProfileParticipationHistory
        participationHistory={participationHistory}
      />

      <ProfileAwards id={id} sport={"Cricket"} />
    </Fragment>
  );
}

export default CricketStats;
