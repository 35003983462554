import { gql } from "@apollo/client";

const EVENT_STATS = gql`
  query eventStatsQuery($id: ID!, $statTypeId: ID!, $type: String!) {
    eventStats(id: $id, statTypeId: $statTypeId, type: $type) {
      id
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      profile {
        id
        name
        registrations(where: { event: { id: $id } }) {
          id
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        profilePhoto {
          id
          filename
        }
      }
      value
    }
  }
`;

export default EVENT_STATS;
