import shuffle from "./shuffle";

function generateLeagueCupFixtures({
  participants,
  meetings = 1,
  qualifiers = 1,
}) {
  ////////////////////////////////////////////////////

  const rounds = [
    { label: "Group Stage", order: 0, type: "Stage", index: 0 },
    { label: "Knockout Stage", order: 1, type: "Stage", index: 1 },
  ];

  const groupStageStartIndex = rounds.length;
  let qualifyingPlacements = [];
  const fixtures = [];
  const roles = [];
  const placements = [];

  for (let i = 0; i < participants.length; i++) {
    const curr = participants[i];
    const parentRoundIndex = rounds.length;
    const groupFixtures = generateLeagueFixtures({
      participants: curr.teams,
      meetings,
    });

    rounds.push({
      label: curr.label || "Group " + (i + 1),
      order: i,
      type: "Group",
      parent: 0,
      index: rounds.length,
    });

    for (let j = 0; j < qualifiers; j++) {
      qualifyingPlacements.push({
        group: parentRoundIndex,
        order: j,
      });
    }

    groupFixtures.rounds.forEach((r, x) => {
      const roundIndex = rounds.length;
      rounds.push({
        label: "Round " + (x + 1),
        order: x,
        type: "Round",
        parent: parentRoundIndex,
        index: roundIndex,
      });

      groupFixtures.fixtures.forEach((f, y) => {
        const fixtureIndex = fixtures.length;
        if (f.round === x) {
          fixtures.push({
            round: roundIndex,
            label: "Match " + (fixtures.length + 1),
            index: fixtureIndex,
          });

          groupFixtures.roles.forEach((role, z) => {
            if (role.fixture === y) {
              roles.push({
                order: role.order,
                type: "PARTICIPANT",
                fixture: fixtureIndex,
                organization: role.organization,
                index: roles.length,
              });
            }
          });
        }
      });
    });
  }

  // KNOCKOUT STAGES
  qualifyingPlacements = [
    ...shuffle([...qualifyingPlacements]),
    ...(participants.length % 2 ? ["DUMMY"] : []),
  ];

  let homeTeams = qualifyingPlacements.slice(
    0,
    qualifyingPlacements.length / 2
  );
  let awayTeams = qualifyingPlacements
    .slice(qualifyingPlacements.length / 2, qualifyingPlacements.length)
    .reverse();

  const knockoutStageStartIndex = rounds.length;

  rounds.push({
    label: "Round 1",
    order: 0,
    type: "Round",
    parent: 1,
    index: knockoutStageStartIndex,
  });

  for (let i = 0; i < homeTeams.length; i++) {
    fixtures.push({
      round: knockoutStageStartIndex,
      label: "Match " + (fixtures.length + 1),
      index: fixtures.length,
    });

    roles.push({
      order: 0,
      type: "PARTICIPANT",
      fixture: fixtures.length - 1,
      index: roles.length,
    });

    roles.push({
      order: 1,
      type: "PARTICIPANT",
      fixture: fixtures.length - 1,
      index: roles.length,
    });

    const fixturePlacements = [{ ...homeTeams[i] }, { ...awayTeams[i] }];

    fixturePlacements.forEach((p, x) => {
      placements.push({
        ...p,
        fixtureRole: roles.length - (2 - x),
        index: placements.length,
      });
    });

    rounds[rounds.length - 1].totalFixtures++;
  }

  while (rounds[rounds.length - 1].totalFixtures > 1) {
    const prevRound = rounds.length - 1;
    const currentRound = rounds.length;

    rounds.push({
      label: "Round " + (prevRound.order + 2),
      order: prevRound.order + 1,
      type: "Round",
      parent: 1,
      totalFixtures: 0,
      index: rounds.length,
    });

    const prevRoundFixtures = fixtures.filter((f) => f.round === prevRound);

    for (let i = 0; i < prevRoundFixtures.length; i++) {
      if (!(i % 2)) {
        const homeFixture = prevRoundFixtures[i];
        const awayFixture = prevRoundFixtures[i + 1];

        fixtures.push({
          round: currentRound,
          label: "Match " + (fixtures.length + 1),
          index: fixtures.length,
        });

        roles.push({
          order: 0,
          type: "PARTICIPANT",
          fixture: fixtures.length - 1,
          index: roles.length,
        });

        roles.push({
          order: 1,
          type: "PARTICIPANT",
          fixture: fixtures.length - 1,
          index: roles.length,
        });

        placements.push({
          fixture: homeFixture.index,
          order: 0,
          fixtureRole: roles.length - 2,
          index: placements.length,
        });

        placements.push({
          fixture: awayFixture.index,
          order: 0,
          fixtureRole: roles.length - 1,
          index: placements.length,
        });

        rounds[currentRound].totalFixtures++;
      }
    }
  }

  // console.log("League Cup Rounds:", rounds);
  // console.log("League Cup Fixtures:", fixtures);
  // console.log("League Cup Roles:", roles);
  // console.log("League Cup Placements:", placements);

  return { rounds, fixtures, roles, placements };
  /////////////////////////////////////////////////////////////
}

function generateSingleEliminationFixtures({ participants }) {
  const participantsList = [
    ...participants,
    ...(participants.length % 2 ? ["DUMMY"] : []),
  ];

  let homeTeams = participantsList.slice(0, participantsList.length / 2);
  let awayTeams = participantsList
    .slice(participantsList.length / 2, participantsList.length)
    .reverse();

  const rounds = [
    { label: "Round 1", order: 0, type: "Round", totalFixtures: 0, index: 0 },
  ];
  const fixtures = [];
  const roles = [];
  const placements = [];
  // let fixtureSum = 0;

  for (let i = 0; i < homeTeams.length; i++) {
    fixtures.push({
      round: 0,
      label: "Match " + (fixtures.length + 1),
      index: fixtures.length,
    });

    const teams = [{ ...homeTeams[i] }, { ...awayTeams[i] }];

    teams.forEach((t, x) => {
      if (t !== "DUMMY") {
        roles.push({
          order: x,
          fixture: fixtures.length - 1,
          type: "PARTICIPANT",
          organization: { ...t.organization },
          index: roles.length,
        });
      }
    });

    rounds[0].totalFixtures++;
    // fixtureSum++;
  }

  while (rounds[rounds.length - 1].totalFixtures > 1) {
    const prevRound = rounds.length - 1;
    const currentRound = rounds.length;

    rounds.push({
      label: "Round " + (currentRound + 1),
      order: rounds.length,
      type: "Round",
      totalFixtures: 0,
      index: rounds.length,
    });

    const prevRoundFixtures = fixtures.filter((f) => f.round === prevRound);

    for (let i = 0; i < prevRoundFixtures.length; i++) {
      if (!(i % 2)) {
        const homeFixture = prevRoundFixtures[i];
        const awayFixture = prevRoundFixtures[i + 1];

        fixtures.push({
          round: currentRound,
          label: "Match " + (fixtures.length + 1),
          index: fixtures.length,
          // homeTeam: "Winner Match " + homeFixture.serialId,
          // awayTeam: "Winner Match " + awayFixture.serialId,
        });

        roles.push({
          order: 0,
          type: "PARTICIPANT",
          fixture: fixtures.length - 1,
          index: roles.length,
        });

        roles.push({
          order: 1,
          type: "PARTICIPANT",
          fixture: fixtures.length - 1,
          index: roles.length,
        });

        placements.push({
          fixture: homeFixture.index,
          order: 0,
          fixtureRole: roles.length - 2,
          index: placements.length,
        });

        placements.push({
          fixture: awayFixture.index,
          order: 0,
          fixtureRole: roles.length - 1,
          index: placements.length,
        });

        rounds[currentRound].totalFixtures++;
        // fixtureSum++;
      }
    }
  }

  // console.log("Rounds:", rounds);
  // console.log("Fixtures:", fixtures);
  // console.log("Roles:", roles);
  // console.log("Placements:", placements);
  return { rounds, fixtures, roles, placements };
}

function generateLeagueFixtures({ participants, meetings }) {
  const { rounds, fixtures, roles } = generateRoundRobinFixtures(participants);

  if (meetings > 1) {
    let allRounds = [...rounds];
    let allFixtures = [...fixtures];
    let allRoles = [...roles];

    for (let i = 0; i < meetings - 1; i++) {
      allRounds = [
        ...allRounds,
        ...rounds.map((r, x) => ({
          ...r,
          label: `Gameweek ${allRounds.length + (x + 1)}`,
          order: allRounds.length + x,
          index: allRounds.length + x,
        })),
      ];

      allFixtures = [
        ...allFixtures,
        ...(i % 2
          ? fixtures.map((f, z) => {
              const fixtureRoles = roles.filter((role) => role.fixture === z);

              fixtureRoles.forEach((role) => {
                allRoles.push({
                  ...role,
                  fixture: allFixtures.length + z,
                  index: allRoles.length,
                });
              });

              return {
                ...f,
                round: f.round + allRounds.length - rounds.length,
                index: fixtures.length * (i + 1) + z,
              };
            })
          : fixtures.map((f, z) => {
              const fixtureRoles = roles.filter((role) => role.fixture === z);
              const flipped = flipFixtureTeams(fixtureRoles);
              flipped.forEach((role) => {
                allRoles.push({
                  ...role,
                  fixture: allFixtures.length + z,
                  index: allRoles.length,
                });
              });
              return {
                ...f,
                round: f.round + allRounds.length - rounds.length,
                index: fixtures.length * (i + 1) + z,
              };
            })),
      ];
    }

    // console.log("All fixtures:", allFixtures);
    // console.log("Rounds:", allRounds);
    // console.log("Roles:", allRoles);

    return { rounds: allRounds, fixtures: allFixtures, roles: allRoles };
  } else {
    // console.log("Rounds:", rounds);
    // console.log("Fixtures:", fixtures);
    // console.log("Roles:", roles);
    return { rounds, fixtures, roles };
  }
}

function flipFixtureTeams(roles) {
  return [
    { ...roles[1], order: 0 },
    { ...roles[0], order: 1 },
  ];
}

function generateRoundRobinFixtures(participants) {
  let participantsList = [...participants];

  if (participants.length % 2 === 1) {
    participantsList.push("DUMMY");
  }

  const participantCount = participantsList.length;
  console.log("Participant Count:", participantCount);
  const roundsCount = participantCount - 1;
  const half = participantCount / 2;

  let homeTeams = participantsList.slice(0, half);
  let awayTeams = participantsList.slice(half, participantCount).reverse();

  const rounds = [];
  const fixtures = [];
  const roles = [];

  for (let i = 0; i < roundsCount; i++) {
    // Add a stage
    const newRound = {
      label: `Gameweek ${i + 1}`,
      order: i,
      type: "Stage",
      index: rounds.length,
    };
    rounds.push(newRound);

    // Add fixtures for the stage
    for (let j = 0; j < homeTeams.length; j++) {
      const fixtureParticipants = [homeTeams[j], awayTeams[j]];

      if (!fixtureParticipants.includes("DUMMY")) {
        // Add fixture
        const newFixture = {
          round: i,
          label: "Match " + (fixtures.length + 1),
          index: fixtures.length,
        };

        fixtures.push(newFixture);

        // Add roles for the fixture
        fixtureParticipants.forEach((p, k) => {
          const newRole = {
            order: k,
            fixture: fixtures.length - 1,
            type: "PARTICIPANT",
            ...(p.organization
              ? { organization: { ...p.organization } }
              : { profile: { ...p.profile } }),
            index: roles.length,
          };

          roles.push(newRole);
        });
      }
    }

    // Rearrange the participants array
    let newHomeTeams = [
      homeTeams[0],
      awayTeams[0],
      ...homeTeams.slice(1, homeTeams.length - 1),
    ];
    let newAwayTeams = [
      ...awayTeams.slice(1, awayTeams.length),
      homeTeams[homeTeams.length - 1],
    ];

    homeTeams = newHomeTeams;
    awayTeams = newAwayTeams;
  }

  return { rounds, fixtures, roles };
}

// function roundRobinGenerator(participants) {
//   const participantsList = [
//     ...participants,
//     ...(participants.length % 2 ? ["DUMMY"] : []),
//   ];

//   // console.log("Participants List:", participantsList);

//   let homeTeams = participantsList.slice(0, participantsList.length / 2);
//   let awayTeams = participantsList
//     .slice(participantsList.length / 2, participantsList.length)
//     .reverse();

//   // console.log("Home Teams:", homeTeams);
//   // console.log("Away Teams:", awayTeams);

//   const rounds = [];
//   const fixtures = [];
//   const roles = [];

//   for (let i = 0; i < participantsList.length - 1; i++) {
//     rounds.push({
//       label: `Gameweek ${i + 1}`,
//       order: i,
//       type: "Stage",
//       index: rounds.length,
//     });
//     // let fixtures = [];
//     // push fixtures to round
//     for (let j = 0; j < homeTeams.length; j++) {
//       fixtures.push({
//         round: i,
//         label: "Match " + (fixtures.length + 1),
//         index: fixtures.length,
//       });

//       const teams = [{ ...homeTeams[j] }, { ...awayTeams[j] }];

//       teams.forEach((t, x) => {
//         if (t !== "DUMMY") {
//           roles.push({
//             order: x,
//             fixture: fixtures.length - 1,
//             type: "PARTICIPANT",
//             organization: { ...t.organization },
//             index: roles.length,
//           });
//         }
//       });
//     }

//     // rounds.push({ label: `Gameweek ${i + 1}`, fixtures });

//     // rearrange teams arrays
//     let newHomeTeams = [
//       homeTeams[0],
//       awayTeams[0],
//       ...homeTeams.slice(1, homeTeams.length - 1),
//     ];
//     let newAwayTeams = [
//       ...awayTeams.slice(1, awayTeams.length),
//       homeTeams[homeTeams.length - 1],
//     ];

//     homeTeams = newHomeTeams;
//     awayTeams = newAwayTeams;

//     // console.log("New Home Teams:", newHomeTeams);
//     // console.log("New Away Teams:", newAwayTeams);
//   }

//   return { rounds, fixtures, roles };
// }

function generateFixtures({
  type = "league",
  participants = [],
  meetings = 1,
  qualifiers = 1,
}) {
  if (type === "league") {
    // console.log("Participants:", participants);
    return generateLeagueFixtures({
      participants,
      meetings,
    });
  } else if (type === "single-elimination") {
    // console.log("Participants:", participants);
    // return generateSingleEliminationFixtures({
    //   participants,
    // });
  } else if (type === "league-cup") {
    // console.log("Participants:", participants);
    // return generateLeagueCupFixtures({
    //   participants,
    //   meetings,
    //   qualifiers,
    // });
  }
}

export default generateFixtures;
