import { gql } from "@apollo/client";

const FIXTURE = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      scheduledStart
      scheduledEnd
      actualStart
      venueLabel
      venueValue
      venueLat
      venueLng
      completed
      uploaded
      periods {
        id
        label
        order
        startDate
        endDate
        duration
      }
      metas {
        id
        label
        value
        stringValue
      }
      venue {
        id
        name
      }
      event {
        id
        sport {
          id
          name
        }
        participantType
        name
        venueLabel
        venueValue
        venueLat
        venueLng
      }
      roles {
        id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          firstname
          lastname
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
        position {
          id
        }
      }
      placements {
        id
        value
        valueString
        profile {
          id
          name
        }
        organization {
          id
          name
        }
      }
      highlights {
        id
        timeStamp
        statType {
          id
          name
        }
        profile {
          id
          name
        }
        organization {
          id
          name
        }
        relationshipsFrom {
          id
          to {
            id
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
          }
        }
        relationshipsTo {
          id
          from {
            id
            stringValue
            timeStamp
            statType {
              id
              name
            }
            profile {
              id
              name
            }
            organization {
              id
              name
            }
          }
        }
      }
      badmintonBoxScore {
        id
        label
        homeScore
        awayScore
      }
      tennisBoxScore {
        id
        label
        homeScore
        awayScore
      }
    }
  }
`;

export default FIXTURE;
