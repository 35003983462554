// V1
import "./EventRoles.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { titleCase } from "../../../utils/titleCase";
import Placeholder from "../../Placeholder/Placeholder";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";
import Square from "../../../mobileComponents/Square";
import { EVENT } from "../../../api/functions";

function EventRoles({ id }) {
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
      organizedBy {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      roles {
        id
        description
        type {
          id
          name
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        relationshipsTo {
          id          
        }
        confirmed
      }
      participants {
        id
        type {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }        
      }
    `,
    }),
    { variables: { id } }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventRoles",
        query: "EVENT_ROLES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="event-organizers-card">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="role-wrapper">
          <div className="role-item">
            <div className="image"></div>
            <div className="text">
              <div
                className="primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Placeholder
                  style={{ height: "12px", width: "60px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary">
                <Placeholder
                  style={{ height: "12px", width: "30px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="role-wrapper">
          <div className="role-item">
            <div className="image"></div>
            <div className="text">
              <div
                className="primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Placeholder
                  style={{ height: "12px", width: "60px" }}
                  pulse={true}
                />
              </div>
              <div className="secondary">
                <Placeholder
                  style={{ height: "12px", width: "30px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
  // console.log("EVENT ROLES DATA:", data);

  const ev = data?.event;
  // console.log("Roles:", ev);

  return (
    <Card className="event-organizers-card">
      <div className="card-title">Participants & Partners</div>
      <div className="event-organizers-card__list">
        {ev.organizedBy && (
          <Link to={"/app/page/" + ev.organizedBy.id} className="role-wrapper">
            <Square>
              {({ squareRef, dimensions }) => (
                <div className="role-item" ref={squareRef}>
                  <div className="image" style={{ height: dimensions.width }}>
                    {ev.organizedBy.profilePhoto && (
                      <Avatar media={ev.organizedBy.profilePhoto} />
                    )}
                  </div>
                  <div className="text">
                    <div className="primary">{ev.organizedBy.name}</div>
                    <div className="secondary">Organizer</div>
                  </div>
                </div>
              )}
            </Square>
          </Link>
        )}

        {ev.participants.map((role) => (
          <Link
            key={role.id}
            to={
              role.organization
                ? "/app/page/" + role.organization?.id
                : "/app/profile/" + role.profile?.id
            }
            className="role-wrapper"
          >
            <Square>
              {({ squareRef, dimensions }) => (
                <div className="role-item" ref={squareRef}>
                  <div className="image" style={{ height: dimensions.width }}>
                    {(role.organization?.profilePhoto ||
                      role.profile?.profilePhoto) && (
                      <Avatar
                        media={
                          role.organization?.profilePhoto ||
                          role.profile?.profilePhoto
                        }
                      />
                    )}
                  </div>
                  <div className="text">
                    <div className="primary">
                      {role.organization?.name || role.profile?.name}
                    </div>
                    <div className="secondary">{titleCase(role.type.name)}</div>
                  </div>
                </div>
              )}
            </Square>
          </Link>
        ))}

        {ev.roles
          .filter((r) => !r.relationshipsTo?.length)
          .map((role) => (
            <Link
              key={role.id}
              to={`/app/${role.organization ? "page" : "profile"}/${
                role.organization?.id || role.profile?.id
              }`}
              className="role-wrapper"
            >
              <Square>
                {({ squareRef, dimensions }) => (
                  <div className="role-item" ref={squareRef}>
                    <div className="image" style={{ height: dimensions.width }}>
                      {(role.organization?.profilePhoto ||
                        role.profile?.profilePhoto) && (
                        <Avatar
                          media={
                            role.organization?.profilePhoto ||
                            role.profile?.profilePhoto
                          }
                        />
                      )}
                    </div>
                    <div className="text">
                      <div className="primary">
                        {role.organization?.name || role.profile?.name}
                      </div>
                      <div className="secondary">
                        {titleCase(role.type.name)}
                      </div>
                    </div>
                  </div>
                )}
              </Square>
            </Link>
          ))}
      </div>
    </Card>
  );
}

export default EventRoles;
