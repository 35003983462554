// V1
import ManageEventRegistrations from "../ManageEventRegistrations";
import ManageEventParticipants from "../ManageEventParticipants";
import "./ManageEventParties.css";

function ManageEventParties({ id }) {
  return (
    <div className="manage-event-parties">
      <div className="left-column">
        <ManageEventParticipants id={id} />
      </div>
      <div className="right-column">
        <ManageEventRegistrations id={id} />
      </div>
    </div>
  );
}

export default ManageEventParties;
