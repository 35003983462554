import { Fragment, useState, useEffect } from "react";
import "./ScoreBasketball.css";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as PlayIcon } from "../../../icons/play.svg";
import { ReactComponent as PauseIcon } from "../../../icons/pause.svg";
import { ReactComponent as WhistleIcon } from "../../../icons/whistle.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { useAppState } from "../../../utils/appState";
import { useMutation, useQuery } from "@apollo/client";
import { STAT_TYPES } from "../../../api/sport";
import Spinner from "../../Spinner";
import Loader from "../../Loader";
import Card from "../../Card";
import Button from "../../Button";
import Checkbox from "../../Checkbox";
import {
  FIXTURE_STATS,
  FIXTURE_SETTINGS,
  CREATE_STAT,
  UPDATE_FIXTURE,
  DELETE_FIXTURE_STAT,
  PUBLISH_FIXTURE,
} from "../../../api/fixture";
import { EVENT_REGISTRATIONS } from "../../../api/event";
import Tooltip from "../../Tooltip";
import { useHistory } from "react-router-dom";
import Settings from "./Settings";
import NewDropdown from "../../NewDropdown/NewDropdown";
import { usePageTracking } from "../../../utils/usePageTracking";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

const sumToPeriod = (periods, id) => {
  let sum = 0;
  for (let i = 0; i < periods.findIndex((x) => x.id === id) || 0; i++) {
    sum += periods[i].duration;
  }
  return sum * 60;
};

function Statement({ stat, stats }) {
  const related = stats.filter((s) =>
    s.relationshipsTo?.find((x) => x.from.id === stat.id)
  );
  // console.log(stat.id, related.length);
  return (
    <Fragment>
      <div className="statement">
        <span>
          <span className="bold">{stat.statType.name}</span> by{" "}
          <span className="bold">{stat.profile.name}</span>
        </span>
      </div>
      {related.map((r) => (
        <Statement stat={r} stats={stats} />
      ))}
    </Fragment>
  );
}

function Cell({ value, update }) {
  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState(value);
  return (
    <div className={"column__row__data " + (value ? "bold" : "light")}>
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            update(val);
            setEditing(false);
          }}
          className="column__row__data__form"
        >
          <div className="column__row__data__form__input">
            <input
              type={"number"}
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
            />
          </div>
          <button type={"submit"}>
            <CheckIcon className="icon submit" />
          </button>
          <button
            onClick={() => {
              setVal(value);
              setEditing(false);
            }}
          >
            <TimesIcon className="icon cancel" />
          </button>
        </form>
      ) : (
        <div
          className="column__row__data__value hover"
          onClick={() => {
            setEditing(true);
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
}

function StatTable({
  organization,
  score,
  updateScore,
  rows,
  lineup,
  statTypes,
  setScore,
  setStatTable,
  completed,
}) {
  // console.log("STAT TABLE:", rows);
  return (
    <div className="stats-table">
      <div className="stats-table__header">
        <div className="stats-table__header__title">
          <div className="stats-table__header__title__logo"></div>
          <div className="stats-table__header__title__text">
            {organization?.name}
          </div>
        </div>

        <div className="stats-table__header__value-selector">
          <div
            className="stats-table__header__value-selector__icon"
            onClick={() => {
              setScore((prev) => prev - 1);
            }}
          >
            <MinusIcon className="icon" />
          </div>
          <div className="stats-table__header__value-selector__val">
            {score}
          </div>
          <div
            className="stats-table__header__value-selector__icon"
            onClick={() => {
              setScore((prev) => prev + 1);
            }}
          >
            <PlusIcon className="icon" />
          </div>
        </div>
      </div>

      <div className="stats-table__body">
        <div className="column players">
          <div className="column__header">
            <div className="column__row">
              <div className="column__row__data">
                <div className="column__row__data__value">Player</div>
              </div>
            </div>
          </div>
          {rows
            .filter((x) => x.organization.id === organization.id)
            .map((row) => (
              <div className="column__row">
                <div
                  className={
                    "column__row__data " +
                    (lineup.find((x) => x.profile.id === row.profile.id)
                      ? "highlight"
                      : "")
                  }
                >
                  <div className="column__row__data__value">
                    {row.profile.name}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="column stats">
          <div className="column__header">
            <div className="column__row">
              {statTypes.map((st) => (
                <div className="column__row__data">
                  <div className="column__row__data__value">{st.name}</div>
                </div>
              ))}
            </div>
          </div>
          {rows
            .filter((x) => x.organization.id === organization.id)
            .map((row, i) => (
              <div className="column__row">
                {statTypes.map((st) => (
                  <Cell
                    value={row.stats[st.id]}
                    update={(val) => {
                      setStatTable((prev) => {
                        const newStatTable = [...prev];
                        const rowIndex = prev.findIndex(
                          (x) => x.profile.id === row.profile.id
                        );
                        newStatTable[rowIndex].stats[st.id] = val;

                        return newStatTable;
                      });
                    }}
                  />
                ))}
              </div>
            ))}
        </div>

        {completed && (
          <div className="stats-table__body__overlay">
            This fixture has already been published, and stats are locked for
            updating. You can still update the final score of the match.
          </div>
        )}
      </div>
    </div>
  );
}

function PublishStatsConfirm({
  dismiss,
  fixtureId,
  placements,
  tables,
  completed,
}) {
  const [publishFixture, { loading }] = useMutation(PUBLISH_FIXTURE, {
    update: (cache, { data: { publishFixture } }) => {
      const q = cache.readQuery({
        query: FIXTURE_SETTINGS,
        variables: { id: fixtureId },
      });

      cache.writeQuery({
        query: FIXTURE_SETTINGS,
        variables: { id: fixtureId },
        data: {
          fixture: {
            ...q.fixture,
            completed: true,
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBasketball.PublishStatsConfirm",
          mutation: "PUBLISH_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="confirm-publish-stats">
      <div className="confirm-publish-stats__form">
        <div className="confirm-publish-stats__form__header">
          {completed
            ? "Are you sure you want to update the final score for this fixture?"
            : "Are you sure you want to publish this fixture?"}
        </div>
        <div className="confirm-publish-stats__form__text">
          {completed ? (
            <p>
              Please note, you may need to recalculate any relevant standings
              tables for the league.
            </p>
          ) : (
            <Fragment>
              <p>
                This cannot be undone. Please note, users will still be able to
                see the score for the fixture from the fixtures page without
                publishing.
              </p>
              <p>
                Once you confirm we will update individual player, team and
                event stats, and the fixture will be available for use in
                standings calculations, so do make sure all stats are accurate
                before you publish the fixture.
              </p>
            </Fragment>
          )}
        </div>

        <div className="confirm-publish-stats__form__options">
          <Button
            className="secondary medium"
            onClick={() => {
              dismiss();
            }}
            disabled={loading}
          >
            No, go back
          </Button>
          <Button
            className="primary medium green"
            onClick={() => {
              if (loading) return;
              publishFixture({
                variables: {
                  fixtureId,
                  placements,
                  tables,
                },
              });
            }}
            disabled={loading}
          >
            {loading ? <Loader /> : "Yes, publish this fixture"}
          </Button>
        </div>
      </div>
    </div>
  );
}

function PublishStatsWrapper({
  fixtureId,
  eventId,
  setPublish,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  completed,
  sport,
}) {
  const registrationsQuery = useQuery(EVENT_REGISTRATIONS, {
    variables: { id: eventId },
  });

  const statsQuery = useQuery(FIXTURE_STATS, {
    variables: { id: fixtureId },
  });

  const statTypesQuery = useQuery(STAT_TYPES, {
    variables: { sport: sport.name },
  });

  if (registrationsQuery.error) {
    captureException({
      error: registrationsQuery.error,
      info: {
        type: "query",
        component: "ScoreBasketball.PublishStatsWrapper",
        query: "EVENT_REGISTRATIONS",
      },
    });
    // console.log(registrationsQuery.error);
  }

  if (statsQuery.error) {
    captureException({
      error: statsQuery.error,
      info: {
        type: "query",
        component: "ScoreBasketball.PublishStatsWrapper",
        query: "FIXTURE_STATS",
      },
    });
    // console.log(statsQuery.error);
  }

  if (statTypesQuery.error) {
    captureException({
      error: statTypesQuery.error,
      info: {
        type: "query",
        component: "ScoreBasketball.PublishStatsWrapper",
        query: "STAT_TYPES",
      },
    });
    // console.log(statTypesQuery.error);
  }

  if (registrationsQuery.error || statsQuery.error || statTypesQuery.error) {
    // console.log(error);

    return <div>Error</div>;
  }

  if (
    registrationsQuery.loading ||
    statsQuery.loading ||
    statTypesQuery.loading
  ) {
    return <Spinner />;
  }

  const loading =
    registrationsQuery.loading || statsQuery.loading || statTypesQuery.loading;

  const stats = statsQuery.data?.fixture?.stats || [];
  const registrations = registrationsQuery.data?.event?.registrations || [];
  const statTypes = statTypesQuery.data?.statTypes || [];
  const placements = statsQuery.data?.fixture?.placements || [];

  const homeScore = completed
    ? placements.find((x) => x?.organization?.id === homeTeam?.organization?.id)
        ?.value || 0
    : stats
        .filter((s) => {
          return (
            (s.organization.id === homeTeam.organization.id &&
              s.statType.name === "1FGM") ||
            (s.organization.id === homeTeam.organization.id &&
              s.statType.name === "2FGM") ||
            (s.organization.id === homeTeam.organization.id &&
              s.statType.name === "3FGM") ||
            (s.organization.id === homeTeam.organization.id &&
              s.statType.name === "FTM")
          );
        })
        .reduce((accum, curr) => {
          if (curr.statType.name === "1FGM") {
            accum += 1;
          } else if (curr.statType.name === "2FGM") {
            accum += 2;
          } else if (curr.statType.name === "3FGM") {
            accum += 3;
          } else if (curr.statType.name === "FTM") {
            accum += 1;
          }

          return accum;
        }, 0);

  const awayScore = completed
    ? placements.find((x) => x?.organization?.id === awayTeam?.organization?.id)
        ?.value || 0
    : stats
        .filter((s) => {
          return (
            (s.organization.id === awayTeam.organization.id &&
              s.statType.name === "1FGM") ||
            (s.organization.id === awayTeam.organization.id &&
              s.statType.name === "2FGM") ||
            (s.organization.id === awayTeam.organization.id &&
              s.statType.name === "3FGM") ||
            (s.organization.id === awayTeam.organization.id &&
              s.statType.name === "FTM")
          );
        })
        .reduce((accum, curr) => {
          if (curr.statType.name === "1FGM") {
            accum += 1;
          } else if (curr.statType.name === "2FGM") {
            accum += 2;
          } else if (curr.statType.name === "3FGM") {
            accum += 3;
          } else if (curr.statType.name === "FTM") {
            accum += 1;
          }

          return accum;
        }, 0);

  const statTable = registrations
    .filter(
      (x) =>
        x?.organization?.id === homeTeam?.organization?.id ||
        x?.organization?.id === awayTeam?.organization?.id
    )
    .reduce((accum, curr) => {
      const newRow = {
        profile: curr.profile,
        organization: curr.organization,
        stats: {},
      };

      statTypes.forEach((statType) => {
        newRow.stats[statType.id] = stats.filter(
          (s) =>
            s?.statType?.id === statType?.id &&
            s?.profile?.id === curr?.profile?.id
        ).length;
      });

      const ptsStatType = statTypes.find((statType) => statType.name === "PTS");
      const points = stats
        .filter(
          (s) =>
            (s?.profile?.id === curr?.profile?.id &&
              s.statType.name === "1FGM") ||
            (s?.profile?.id === curr?.profile?.id &&
              s.statType.name === "2FGM") ||
            (s?.profile?.id === curr?.profile?.id &&
              s.statType.name === "3FGM") ||
            (s?.profile?.id === curr?.profile?.id && s.statType.name === "FTM")
        )
        .reduce((accum, curr) => {
          if (curr.statType.name === "1FGM") {
            accum += 1;
          } else if (curr.statType.name === "2FGM") {
            accum += 2;
          } else if (curr.statType.name === "3FGM") {
            accum += 3;
          } else if (curr.statType.name === "FTM") {
            accum += 1;
          }

          return accum;
        }, 0);

      newRow.stats[ptsStatType.id] = points || 0;

      accum.push(newRow);

      return accum;
    }, []);

  // if (loading) {
  //   return (
  //     <div className="publish-stats">
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <PublishStats
      fixtureId={fixtureId}
      eventId={eventId}
      setPublish={setPublish}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
      registrations={registrations}
      stats={stats}
      statTypes={statTypes}
      defaultHomeScore={homeScore}
      defaultAwayScore={awayScore}
      defaultStatTable={statTable}
      loading={loading}
      completed={completed}
    />
  );
}

function PublishStats({
  fixtureId,
  eventId,
  setPublish,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  registrations,
  stats,
  statTypes,
  defaultHomeScore,
  defaultAwayScore,
  defaultStatTable,
  loading,
  completed,
}) {
  const [, setAppState] = useAppState();
  const [confirm, setConfirm] = useState(false);
  const [homeScore, setHomeScore] = useState(defaultHomeScore);
  const [awayScore, setAwayScore] = useState(defaultAwayScore);
  const [statTable, setStatTable] = useState(defaultStatTable);
  const history = useHistory();

  // console.log("PUBLISH STATS TABLES:", statTable);

  return (
    <div className="publish-stats">
      <div className="publish-stats__header">
        <div
          className="close-scoring-btn"
          onClick={() => {
            if (!completed) {
              setPublish(false);
            } else {
              history.push("/app/fixture/" + fixtureId);
              setAppState({ modal: false });
            }
          }}
        >
          <div className="icon">
            <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
          </div>
          <div className="text">{completed ? "Back to fixture" : "Back"}</div>
        </div>
        <div className="publish-stats-title">
          {"Verify Fixture Stats & Result"}
        </div>

        {!loading && (
          <div className="publish-stats-btn-wrapper">
            <div
              className="publish-stats-btn"
              onClick={() => {
                setConfirm(true);
              }}
            >
              {completed ? "Update Final Score" : "Publish this fixture"}
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="publish-stats__body">
          <div className="stats-table-container">
            <StatTable
              organization={homeTeam.organization}
              score={homeScore}
              updateScore={() => {}}
              rows={statTable}
              lineup={homeLineup}
              statTypes={statTypes}
              setScore={setHomeScore}
              setStatTable={setStatTable}
              completed={completed}
            />
          </div>

          <div className="stats-table-container">
            <StatTable
              organization={awayTeam.organization}
              score={awayScore}
              updateScore={() => {}}
              rows={statTable}
              lineup={awayLineup}
              statTypes={statTypes}
              setScore={setAwayScore}
              setStatTable={setStatTable}
              completed={completed}
            />
          </div>
        </div>
      )}

      {confirm && (
        <PublishStatsConfirm
          dismiss={() => {
            setConfirm(false);
          }}
          fixtureId={fixtureId}
          placements={[
            { organizationId: homeTeam.organization.id, value: homeScore },
            { organizationId: awayTeam.organization.id, value: awayScore },
          ]}
          tables={statTable.reduce((accum, curr) => {
            for (let statTypeId in curr.stats) {
              if (curr.stats[statTypeId]) {
                accum.push({
                  profileId: curr.profile.id,
                  organizationId: curr.organization.id,
                  statTypeId,
                  value: curr.stats[statTypeId],
                });
              }
            }

            return accum;
          }, [])}
          completed={completed}
        />
      )}
    </div>
  );
}

function DeleteStatsConfirm({ dismiss, stat, stats, timestamp, fixtureId }) {
  const [deleteStat, { loading }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      if (deleteFixtureStat?.length) {
        const q = cache.readQuery({
          query: FIXTURE_STATS,
          variables: {
            id: fixtureId,
          },
        });

        const statIds = deleteFixtureStat.map((x) => x.id);

        cache.writeQuery({
          query: FIXTURE_STATS,
          variables: {
            id: fixtureId,
          },
          data: {
            fixture: {
              ...q.fixture,
              stats: {
                ...q.fixture.stats.filter((x) => !statIds.includes(x.id)),
              },
            },
          },
        });

        dismiss();
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBasketball.DeleteStatsConfirm",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="confirm-delete-stats">
      <div className="confirm-delete-stats__form">
        <div className="confirm-delete-stats__form__header">
          Are you sure you want to delete the following stats?
        </div>
        <div className="confirm-delete-stats__form__text">
          <div className="confirm-delete-stats__form__text__timestamp">
            {timestamp || 0}'
          </div>
          <div className="confirm-delete-stats__form__text__statements">
            <Statement stat={stat} stats={stats} />
          </div>
        </div>

        <div className="confirm-delete-stats__form__options">
          <Button
            className={"secondary medium cancel-btn "}
            disabled={loading}
            onClick={() => {
              dismiss();
            }}
          >
            No, cancel
          </Button>
          <Button
            className="primary medium green"
            disabled={loading}
            onClick={() => {
              deleteStat({ variables: { id: stat.id } });
            }}
          >
            {loading ? <Loader /> : "Yes, delete these stats"}
          </Button>
        </div>
      </div>
    </div>
  );
}

function OverlayNew({
  fixtureId,
  eventId,
  selectedStat,
  dismiss,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  isHomeStat,
  defaultTimestamp,
  selectedPeriodId,
  periods,
  sport,
}) {
  return (
    <div className="stat-overlay">
      <StatInputFormNew
        fixtureId={fixtureId}
        eventId={eventId}
        selectedStat={selectedStat}
        dismiss={dismiss}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeLineup={homeLineup}
        awayLineup={awayLineup}
        isHomeStat={isHomeStat}
        defaultTimestamp={defaultTimestamp}
        selectedPeriodId={selectedPeriodId}
        periods={periods}
        sport={sport}
      />
    </div>
  );
}

function TimerNew({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  timestamp,
  setTimestamp,
  cache,
  updateCache,
}) {
  const [secondsElapsed, setSecondsElapsed] = useState(timestamp || 0);
  const [running, setRunning] = useState(false);
  const [decrementPressed, setDecrementPressed] = useState(false);
  const [incrementPressed, setIncrementPressed] = useState(false);

  useEffect(() => {
    setSecondsElapsed(timestamp);
  }, [timestamp]);

  useEffect(() => {
    // console.log("Running interval effect");
    let timer;

    if (running) {
      // console.log("Setting interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev + 1;
            if (!(newTimestamp % 60)) {
              updateCache();
            }
            return newTimestamp;
          });
        }, 1000);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [running]);

  useEffect(() => {
    let timer;

    if (decrementPressed) {
      // console.log("Setting decrement interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev - 1;
            return newTimestamp;
          });
        }, 100);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [decrementPressed]);

  useEffect(() => {
    let timer;

    if (incrementPressed) {
      // console.log("Setting decrement interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev + 1;
            return newTimestamp;
          });
        }, 100);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [incrementPressed]);

  const minutes = Math.floor(secondsElapsed / 60);
  const seconds = secondsElapsed % 60;

  return (
    <div className="time">
      <NewDropdown
        target={
          <div className="selected-period-selector">
            {selectedPeriod?.label || "Select period to get started"}
            <ChevronDown className="selected-period-selector__icon" />
          </div>
        }
        menu={({ setShowMenu }) => (
          <div className="selected-period-selector-menu">
            {periods.map((p) => (
              <div
                className="selected-period-selector-menu__item"
                onClick={() => {
                  setRunning(false);
                  changePeriod(p.id);
                  setShowMenu(false);
                }}
              >
                {p.label}
              </div>
            ))}
          </div>
        )}
      />

      {selectedPeriod && (
        <Fragment>
          <div className="current-time">
            {!running && (
              <div
                className="option"
                onMouseDown={() => {
                  setDecrementPressed(true);
                }}
                onMouseUp={() => {
                  setDecrementPressed(false);
                  updateCache();
                }}
                onClick={() => {
                  setTimestamp((prev) => {
                    const newTimestamp = prev - 1;
                    if (!(newTimestamp % 60)) {
                      updateCache();
                    }
                    return newTimestamp;
                  });
                }}
              >
                <MinusIcon style={{ height: "14px" }} />
              </div>
            )}

            <div className="time">
              {minutes < 10 ? "0" + minutes : minutes} :{" "}
              {seconds < 10 ? "0" + seconds : seconds}
            </div>

            {!running && (
              <div
                className="option"
                onMouseDown={() => {
                  setIncrementPressed(true);
                }}
                onMouseUp={() => {
                  setIncrementPressed(false);
                  updateCache();
                }}
                onClick={() => {
                  setTimestamp((prev) => {
                    const newTimestamp = prev + 1;
                    if (!(newTimestamp % 60)) {
                      updateCache();
                    }
                    return newTimestamp;
                  });
                }}
              >
                <PlusIcon style={{ height: "14px" }} />
              </div>
            )}
          </div>

          {running ? (
            <div
              className="pause"
              onClick={() => {
                setRunning(false);
                updateCache();
              }}
            >
              <div className="icon">
                <PauseIcon style={{ height: "14px", fill: "white" }} />
              </div>
              {/* <div className="text">Pause {selectedPeriod?.label}</div> */}
            </div>
          ) : (
            <div
              className="start"
              onClick={() => {
                setRunning(true);
              }}
            >
              <div className="icon">
                <PlayIcon style={{ height: "14px", fill: "white" }} />
              </div>
              {/* <div className="text">
            {secondsElapsed ? "Resume " : "Start "} {selectedPeriod?.label}
          </div> */}
            </div>
          )}

          <div
            className="end-of-period"
            onClick={() => {
              setRunning(false);
              changePeriod();
              updateCache();
            }}
          >
            <div className="icon">
              <WhistleIcon style={{ height: "14px", fill: "white" }} />
            </div>
            {/* <div className="text">End {selectedPeriod?.label}</div> */}
          </div>
        </Fragment>
      )}
    </div>
  );
}

function LineupsListItemNew({
  player,
  fixtureId,
  team,
  lineupPlayers,
  fixture,
}) {
  // const [role, setRole] = useState(
  //   lineupPlayers.find(
  //     (r) =>
  //       r.type.name === "LINEUP PLAYER" && r.profile.id === player.profile.id
  //   )
  // );

  const [updateFixture, { loading }] = useMutation(UPDATE_FIXTURE, {
    update: (cache, { data: { updateFixture } }) => {
      // const q = cache.readQuery({
      //   query: FIXTURE_SETTINGS,
      //   variables: { id: fixtureId },
      // });

      // cache.writeQuery({
      //   query: FIXTURE_SETTINGS,
      //   variables: { id: fixtureId },
      //   data: {
      //     fixture: {
      //       ...q.fixture,
      //       roles: updateFixture.roles,
      //     },
      //   },
      // });

      cache.modify({
        id: cache.identify(fixture),
        fields: {
          roles(existingClaimRefs, { readField }) {
            return updateFixture.roles;
          },
        },
      });

      // setRole(
      //   updateFixture.roles.find(
      //     (r) =>
      //       r.type.name === "LINEUP PLAYER" &&
      //       r.profile.id === player.profile.id
      //   )
      // );
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBasketball.LineupsListItemNew",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  const role = lineupPlayers.find(
    (r) => r.type.name === "LINEUP PLAYER" && r.profile.id === player.profile.id
  );

  return (
    <div className="lineup-list-item">
      <div className="image"></div>
      <div className="name">{player.profile.name}</div>
      <div className="checkbox">
        {loading ? (
          <Spinner />
        ) : (
          <Checkbox
            onClick={() => {
              if (role) {
                updateFixture({
                  variables: {
                    where: { id: fixtureId },
                    data: {
                      roles: {
                        deleteMany: [{ id: role.id }],
                      },
                    },
                  },
                });
              } else {
                updateFixture({
                  variables: {
                    where: { id: fixtureId },
                    data: {
                      roles: {
                        create: [
                          {
                            order: lineupPlayers.length,
                            type: { connect: { name: "LINEUP PLAYER" } },
                            subtypes: { connect: { name: "SUBSTITUTE" } },
                            organization: {
                              connect: { id: team.organization.id },
                            },
                            profile: { connect: { id: player.profile.id } },
                          },
                        ],
                      },
                    },
                  },
                });
              }
            }}
            defaultValue={!!role}
          />
        )}
      </div>
    </div>
  );
}

function LineupsListNew({
  fixtureId,
  fixture,
  registrations,
  team,
  lineupPlayers,
}) {
  return (
    <div className="lineup-list">
      {registrations.map((p) => (
        <LineupsListItemNew
          key={p.id}
          player={p}
          fixtureId={fixtureId}
          fixture={fixture}
          team={team}
          lineupPlayers={lineupPlayers}
        />
      ))}
    </div>
  );
}

function LineupsPageNew({
  eventId,
  fixtureId,
  fixture,
  dismiss,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
}) {
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: { id: eventId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBasketball.LineupsPageNew",
        query: "EVENT_REGISTRATIONS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="lineups-page">
        <div className="lineup-editor">
          <Spinner />
        </div>
      </div>
    );
  }

  const teams = [homeTeam, awayTeam];
  const lineupPlayers = [...homeLineup, ...awayLineup];
  // const teams = fixture.roles
  //   .filter((r) => r.type.name === "PARTICIPANT")
  //   .sort((a, b) => a.order - b.order);

  const selectedTeam = teams[selectedTeamIndex];

  const registrations = data.event.registrations.filter(
    (x) => x.organization.id === selectedTeam?.organization.id
  );

  // const players = registrations.map((x) => ({
  //   ...x,
  //   isInLineup: fixture.roles.find(
  //     (r) => r.type.name === "LINEUP PLAYER" && r.profile.id === x.profile.id
  //   ),
  // }));

  return (
    <div className="lineups-page">
      <div className="lineups-header">
        Edit Lineups{" "}
        <div
          className="dismiss-btn"
          onClick={() => {
            dismiss();
          }}
        >
          Done
        </div>
      </div>
      <div className="lineup-editor">
        <div className="team-selector">
          {teams.map((t, i) => (
            <div
              className={
                "team-selector-item " +
                (selectedTeamIndex === i ? "active" : "")
              }
              onClick={() => {
                setSelectedTeamIndex(i);
              }}
            >
              {t?.organization?.name || ""}
            </div>
          ))}
        </div>

        <div className="lineup-list">
          <LineupsListNew
            fixtureId={fixtureId}
            fixture={fixture}
            registrations={registrations.filter(
              (x) => x.organization.id === selectedTeam.organization.id
            )}
            team={selectedTeam}
            lineupPlayers={lineupPlayers}
          />
        </div>
      </div>
    </div>
  );
}

function ScoringHeader({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  timestamp,
  setTimestamp,
  setEditSettings,
  setEditLineups,
  cache,
  updateCache,
  setPublish,
}) {
  const [, setAppState] = useAppState();
  const history = useHistory();
  return (
    <div className="header">
      <div
        className="close-scoring-btn"
        onClick={() => {
          history.push("/app/fixture/" + fixtureId);
          setAppState({ modal: false });
        }}
      >
        <div className="icon">
          <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
        </div>
        <div className="text">Back to fixture</div>
      </div>

      <TimerNew
        fixtureId={fixtureId}
        periods={periods}
        selectedPeriod={selectedPeriod}
        changePeriod={changePeriod}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        cache={cache}
        updateCache={updateCache}
      />

      <div className="settings">
        {/* <div
          className="edit-lineups-btn"
          onClick={() => {
            setEditLineups(true);
          }}
        >
          <ListIcon style={{ fill: "white", height: "14px" }} />
          Lineups
        </div> */}
        <div
          className="publish-stats-btn"
          onClick={() => {
            setPublish(true);
          }}
        >
          {"Verify & Publish"}
        </div>
        <div
          className="settings-btn"
          onClick={() => {
            setEditSettings(true);
          }}
        >
          <SettingsIcon style={{ stroke: "var(--light-1" }} />
        </div>
      </div>
    </div>
  );
}

function StatInputFormNew({
  fixtureId,
  eventId,
  selectedStat,
  dismiss,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  isHomeStat,
  defaultTimestamp,
  selectedPeriodId,
  periods,
  sport,
}) {
  const [currentStat] = useState(selectedStat);
  const [formIndex, setFormIndex] = useState(0);
  const [timestamp, setTimestamp] = useState(
    defaultTimestamp ? Math.ceil(defaultTimestamp / 60) : 1
  );
  const [primaryStat, setPrimaryStat] = useState("");
  const [associatedStats, setAssociatedStats] = useState([]);
  const [isHomeTeam, setIsHomeTeam] = useState(isHomeStat);
  const [periodId, setPeriodId] = useState(selectedPeriodId);

  const parents = currentStat.relationshipsTo
    .filter((r) => r.type.name === "Ancestor")
    .map((r) => r.from);

  const associates = currentStat.relationshipsFrom
    .filter((r) => r.type.name === "Sibling")
    .map((r) => r.to);

  const lineup = isHomeTeam ? homeLineup : awayLineup;

  const [createStat] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      const q = cache.readQuery({
        query: FIXTURE_STATS,
        variables: { id: fixtureId },
      });

      // console.log("Update:", createStat);
      const newStats = [
        createStat,
        ...createStat.relationshipsTo.map((r) => ({
          ...r.from,
          relationshipsFrom: [],
          relationshipsTo: [],
        })),
        ...createStat.relationshipsFrom.map((r) => ({
          ...r.to,
          relationshipsFrom: [],
          relationshipsTo: [],
        })),
      ];

      cache.writeQuery({
        query: FIXTURE_STATS,
        variables: { id: fixtureId },
        data: {
          fixture: {
            ...q.fixture,
            stats: [...newStats, ...q.fixture.stats],
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBasketball.StatInputFormNew",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const skip = () => {
    if (formIndex < associates.length - 1) {
      setFormIndex((prev) => prev + 1);
    } else {
      const vars = {
        event: { connect: { id: eventId } },
        timeStamp: timestamp,
        organization: {
          connect: { id: primaryStat.organization.id },
        },
        profile: { connect: { id: primaryStat.profile.id } },
        statType: { connect: { id: primaryStat.statType.id } },
        fixture: { connect: { id: fixtureId } },
        sport: { connect: { name: sport.name } },
        fixturePeriod: { connect: { id: periodId } },
        ...(parents?.length
          ? {
              relationshipsTo: {
                create: currentStat.relationshipsTo
                  .filter((r) => r.type.name === "Ancestor")
                  .map((p) => ({
                    from: {
                      create: {
                        event: { connect: { id: eventId } },
                        timeStamp: timestamp,
                        organization: {
                          connect: {
                            id: primaryStat.organization.id,
                          },
                        },
                        profile: {
                          connect: { id: primaryStat.profile.id },
                        },
                        statType: { connect: { id: p.from.id } },
                        fixture: { connect: { id: fixtureId } },
                        sport: { connect: { name: sport.name } },
                        fixturePeriod: { connect: { id: periodId } },
                      },
                    },
                    relationship: { connect: { id: p.id } },
                  })),
              },
            }
          : {}),
        ...(associatedStats?.length
          ? {
              relationshipsFrom: {
                create: associatedStats.map((p) => ({
                  to: {
                    create: {
                      event: { connect: { id: eventId } },
                      timeStamp: timestamp,
                      organization: {
                        connect: {
                          id: p.organization.id,
                        },
                      },
                      profile: {
                        connect: { id: p.profile.id },
                      },
                      statType: { connect: { id: p.statType.id } },
                      fixture: { connect: { id: fixtureId } },
                      sport: { connect: { name: sport.name } },
                      fixturePeriod: { connect: { id: periodId } },
                    },
                  },
                  relationship: {
                    connect: {
                      id: currentStat.relationshipsFrom
                        .filter((r) => r.type.name === "Sibling")
                        .find((x) => x.to.id === p.statType.id).id,
                    },
                  },
                })),
              },
            }
          : {}),
      };
      // console.log("Submitting form");
      createStat({
        variables: {
          data: vars,
        },
      });
    }
  };

  // console.log(homeLineup);
  const onPlayerClick = (role) => {
    if (!primaryStat) {
      const newPrimaryStat = {
        statType: { id: selectedStat.id, name: selectedStat.name },
        profile: role.profile,
        organization: role.organization,
      };
      setPrimaryStat(newPrimaryStat);

      if (!associates.length) {
        createStat({
          variables: {
            data: {
              event: { connect: { id: eventId } },
              timeStamp: timestamp,
              organization: {
                connect: { id: newPrimaryStat.organization.id },
              },
              profile: { connect: { id: newPrimaryStat.profile.id } },
              statType: {
                connect: { id: newPrimaryStat.statType.id },
              },
              fixture: { connect: { id: fixtureId } },
              sport: { connect: { name: sport.name } },
              fixturePeriod: { connect: { id: periodId } },
              ...(parents?.length
                ? {
                    relationshipsTo: {
                      create: currentStat.relationshipsTo
                        .filter((r) => r.type.name === "Ancestor")
                        .map((p) => ({
                          from: {
                            create: {
                              event: {
                                connect: { id: eventId },
                              },
                              timeStamp: timestamp,
                              organization: {
                                connect: {
                                  id: newPrimaryStat.organization.id,
                                },
                              },
                              profile: {
                                connect: {
                                  id: newPrimaryStat.profile.id,
                                },
                              },
                              statType: {
                                connect: { id: p.from.id },
                              },
                              fixture: { connect: { id: fixtureId } },
                              sport: {
                                connect: { name: sport.name },
                              },
                              fixturePeriod: {
                                connect: { id: periodId },
                              },
                            },
                          },
                          relationship: {
                            connect: { id: p.id },
                          },
                        })),
                    },
                  }
                : {}),
            },
          },
        });
      }
      return;
    }

    if (associates.length && formIndex < associates.length - 1) {
      const newAssociatedStats = [
        ...associatedStats,
        {
          statType: {
            id: associates[formIndex].id,
            name: associates[formIndex].name,
          },
          profile: role.profile,
          organization: role.organization,
        },
      ];
      setAssociatedStats(newAssociatedStats);
      setFormIndex((prev) => prev + 1);
    } else {
      const newAssociatedStats = [
        ...associatedStats,
        {
          statType: {
            id: associates[formIndex].id,
            name: associates[formIndex].name,
          },
          profile: role.profile,
          organization: role.organization,
        },
      ];
      setAssociatedStats(newAssociatedStats);

      const vars = {
        event: { connect: { id: eventId } },
        timeStamp: timestamp,
        organization: {
          connect: { id: primaryStat.organization.id },
        },
        profile: { connect: { id: primaryStat.profile.id } },
        statType: { connect: { id: primaryStat.statType.id } },
        fixture: { connect: { id: fixtureId } },
        sport: { connect: { name: sport.name } },
        fixturePeriod: { connect: { id: periodId } },
        ...(parents?.length
          ? {
              relationshipsTo: {
                create: currentStat.relationshipsTo
                  .filter((r) => r.type.name === "Ancestor")
                  .map((p) => ({
                    from: {
                      create: {
                        event: { connect: { id: eventId } },
                        timeStamp: timestamp,
                        organization: {
                          connect: {
                            id: primaryStat.organization.id,
                          },
                        },
                        profile: {
                          connect: { id: primaryStat.profile.id },
                        },
                        statType: { connect: { id: p.from.id } },
                        fixture: { connect: { id: fixtureId } },
                        sport: { connect: { name: sport.name } },
                        fixturePeriod: { connect: { id: periodId } },
                      },
                    },
                    relationship: { connect: { id: p.id } },
                  })),
              },
            }
          : {}),
        ...(newAssociatedStats?.length
          ? {
              relationshipsFrom: {
                create: newAssociatedStats.map((p) => ({
                  to: {
                    create: {
                      event: { connect: { id: eventId } },
                      timeStamp: timestamp,
                      organization: {
                        connect: {
                          id: p.organization.id,
                        },
                      },
                      profile: {
                        connect: { id: p.profile.id },
                      },
                      statType: { connect: { id: p.statType.id } },
                      fixture: { connect: { id: fixtureId } },
                      sport: { connect: { name: sport.name } },
                      fixturePeriod: { connect: { id: periodId } },
                    },
                  },
                  relationship: {
                    connect: {
                      id: currentStat.relationshipsFrom
                        .filter((r) => r.type.name === "Sibling")
                        .find((x) => x.to.id === p.statType.id).id,
                    },
                  },
                })),
              },
            }
          : {}),
      };
      // console.log("Submitting form with vars:", vars);
      createStat({
        variables: {
          data: vars,
        },
      });
    }
  };

  return (
    <div className="stat-input-form">
      <div className="stat-input-form__title">
        Add {selectedStat.name}
        <div className="dismiss-btn" onClick={dismiss}>
          <TimesIcon style={{ stroke: "var(--light-2", height: "20px" }} />
        </div>
      </div>

      <div className="timestamp-editor">
        <div className="label">Period</div>

        <div className="input">
          <select
            value={periodId}
            onChange={(e) => setPeriodId(e.target.value)}
          >
            {periods.map((p) => (
              <option value={p.id}>{p.label}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="timestamp-editor">
        <div className="label">Timestamp</div>
        <div className="input">
          <div
            className="icon"
            onClick={() => {
              setTimestamp((prev) => Math.max(0, prev - 1));
            }}
          >
            <MinusIcon style={{ stroke: "var(--dark-4", height: "14px" }} />
          </div>
          <div className="value">{timestamp}</div>
          <div
            className="icon"
            onClick={() => {
              setTimestamp((prev) => prev + 1);
            }}
          >
            <PlusIcon style={{ stroke: "var(--dark-4", height: "14px" }} />
          </div>
        </div>
      </div>

      {primaryStat && (
        <div className="saved-stats">
          <div className="saved-stat-item">
            <div className="timestamp">{timestamp}'</div>
            <div className="statement">
              <span className="bold">{primaryStat.statType.name}</span> by{" "}
              <span className="bold">{primaryStat.profile.name}</span>
            </div>
            <div className="edit-btn">Edit</div>
          </div>
          {associatedStats.map((a) => (
            <div className="saved-stat-item">
              <div className="timestamp">{timestamp}'</div>
              <div className="statement">
                <span className="bold">{a.statType.name}</span> by{" "}
                <span className="bold">{a.profile.name}</span>
              </div>
              {/* <div className="edit-btn">Edit</div> */}
            </div>
          ))}
        </div>
      )}

      {primaryStat && !!associates.length && (
        <div className="associated-stat-title">
          Add Associated {associates[formIndex].name}
          <div
            className="skip-associated-btn"
            onClick={() => {
              skip();
            }}
          >
            Skip
          </div>
        </div>
      )}

      <div className="select-player">
        <div className="team-selector">
          <div
            className={"team-selector__item " + (isHomeTeam ? "active" : "")}
            onClick={() => {
              setIsHomeTeam(true);
            }}
          >
            {homeTeam.organization.name}
          </div>

          <div
            className={"team-selector__item " + (!isHomeTeam ? "active" : "")}
            onClick={() => {
              setIsHomeTeam(false);
            }}
          >
            {awayTeam.organization.name}
          </div>
        </div>
      </div>

      <div className="player-list">
        {lineup.map((r) => (
          <div
            className="select-player__item"
            onClick={() => {
              onPlayerClick(r);
            }}
          >
            <div className="img">
              {r.profile?.profilePhoto && (
                <Avatar media={r.profile.profilePhoto} />
              )}
            </div>
            <div className="name">
              <div className="name__primary">{r.profile.name}</div>
              <div className="name__secondary">@{r.profile.handle}</div>
            </div>
            <div className="num-container">
              {/* <JerseyIcon
                style={{ fill: "var(--light-4)", position: "absolute" }}
              /> */}
              <div className="num-val">{r.order || "-"}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function StatsList({ fixtureId, homeTeam, awayTeam, onDelete }) {
  const { loading, error, data } = useQuery(FIXTURE_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBasketball.StatsList",
        query: "FIXTURE_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats = data.fixture.stats;
  // console.log("STATS:", stats);

  const homeScore = stats
    .filter((s) => {
      return (
        (s.organization.id === homeTeam.organization.id &&
          s.statType.name === "1FGM") ||
        (s.organization.id === homeTeam.organization.id &&
          s.statType.name === "2FGM") ||
        (s.organization.id === homeTeam.organization.id &&
          s.statType.name === "3FGM") ||
        (s.organization.id === homeTeam.organization.id &&
          s.statType.name === "FTM")
      );
    })
    .reduce((accum, curr) => {
      if (curr.statType.name === "1FGM") {
        accum += 1;
      } else if (curr.statType.name === "2FGM") {
        accum += 2;
      } else if (curr.statType.name === "3FGM") {
        accum += 3;
      } else if (curr.statType.name === "FTM") {
        accum += 1;
      }

      return accum;
    }, 0);

  const awayScore = stats
    .filter((s) => {
      return (
        (s.organization.id === awayTeam.organization.id &&
          s.statType.name === "1FGM") ||
        (s.organization.id === awayTeam.organization.id &&
          s.statType.name === "2FGM") ||
        (s.organization.id === awayTeam.organization.id &&
          s.statType.name === "3FGM") ||
        (s.organization.id === awayTeam.organization.id &&
          s.statType.name === "FTM")
      );
    })
    .reduce((accum, curr) => {
      if (curr.statType.name === "1FGM") {
        accum += 1;
      } else if (curr.statType.name === "2FGM") {
        accum += 2;
      } else if (curr.statType.name === "3FGM") {
        accum += 3;
      } else if (curr.statType.name === "FTM") {
        accum += 1;
      }

      return accum;
    }, 0);

  const statsByPeriod = stats
    .reduce((accum, curr) => {
      const p = curr.fixturePeriod;
      // console.log("Curr p:", p);

      const existingPeriodEntry = accum.findIndex(
        (x) => x.period?.id === p?.id
      );

      // console.log(existingPeriodEntry);

      if (existingPeriodEntry < 0) {
        // console.log("Making New Group");
        accum.push({ period: { ...p }, stats: [curr] });
      } else {
        // console.log("Existing Group");
        accum[existingPeriodEntry].stats.push(curr);
      }

      return accum;
    }, [])
    .sort((a, b) => b.period.order - a.period.order);

  // console.log("GBP:", statsByPeriod);

  return (
    <Fragment>
      <div className="match-participants">
        <div className="home-participant">
          <div className="logo">
            {homeTeam?.organization?.profilePhoto && (
              <Avatar media={homeTeam.organization.profilePhoto} />
            )}
          </div>
          <div className="name">{homeTeam.organization.name}</div>
        </div>
        <div className="score">
          {homeScore} : {awayScore}
        </div>
        <div className="away-participant">
          <div className="name">{awayTeam.organization.name}</div>
          <div className="logo">
            {awayTeam?.organization?.profilePhoto && (
              <Avatar media={awayTeam.organization.profilePhoto} />
            )}
          </div>
        </div>
      </div>
      <div className="stats-list">
        {statsByPeriod?.map((group) => (
          <Fragment>
            {group.stats
              .filter((s) => !s.relationshipsTo?.length)
              .sort(
                (a, b) => b.timeStamp - a.timeStamp || b.createdAt - a.createdAt
              )
              .map((s) => (
                <div key={s.id} className={"stat-item-container "}>
                  <div className="home-stat-item">
                    {s.organization.id === homeTeam?.organization.id && (
                      <Fragment>
                        <div className="options">
                          <div
                            className="option"
                            onClick={() => {
                              onDelete(s, stats, s.timeStamp);
                            }}
                          >
                            <TrashIcon
                              style={{
                                height: "14px",
                                stroke: "var(--red-lighter)",
                              }}
                            />{" "}
                          </div>
                          {/* <div className="option">
                        <PencilIcon
                          style={{
                            height: "14px",
                            fill: "var(--orange-lighter)",
                          }}
                        />
                      </div> */}
                        </div>
                        <Card className="statements">
                          <Statement stat={s} stats={stats} />
                        </Card>
                      </Fragment>
                    )}
                  </div>
                  <Card className="timestamp">{s.timeStamp || "-"}'</Card>
                  <div className="away-stat-item">
                    {s.organization.id === awayTeam?.organization.id && (
                      <Fragment>
                        <Card className="statements">
                          <Statement stat={s} stats={stats} />
                        </Card>
                        <div className="options">
                          {/* <div className="option">
                        <PencilIcon
                          style={{
                            height: "14px",
                            fill: "var(--orange-lighter)",
                          }}
                        />
                      </div> */}
                          <div
                            className="option"
                            onClick={() => {
                              onDelete(s, stats, s.timeStamp);
                            }}
                          >
                            <TrashIcon
                              style={{
                                height: "14px",
                                stroke: "var(--red-lighter)",
                              }}
                            />{" "}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              ))}

            <div className="stats-list__period">
              START OF {group.period?.label?.toUpperCase()}
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

function StatTypesList({ onClick, selectedPeriodId, sport }) {
  const { loading, error, data } = useQuery(STAT_TYPES, {
    variables: { sport: sport.name },
  });

  if (loading || !data) {
    return <Spinner />;
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBasketball.StatTypesList",
        query: "STAT_TYPES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const statTypes = data?.statTypes;
  const groups = ["Offense", "Defense"];

  return (
    <Fragment>
      {groups.map((g) => (
        <div className="stat-type-group">
          <div className="title">{g}</div>
          <div className="items">
            {statTypes
              .filter((st) => st.group?.name === g && st.name !== "PTS")
              .map((st) => (
                <Fragment>
                  {selectedPeriodId ? (
                    <Card
                      className="stat-type-item"
                      onClick={() => {
                        onClick(st);
                      }}
                    >
                      {st.name}
                    </Card>
                  ) : (
                    <Tooltip text={"You must select a period to enter stats"}>
                      <Card className="stat-type-item disabled">{st.name}</Card>
                    </Tooltip>
                  )}
                </Fragment>
              ))}
          </div>
        </div>
      ))}
    </Fragment>
  );
}

function ScoreBasketball({
  fixtureId,
  fixture,
  eventId,
  statsCacheState,
  periods,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  completed,
}) {
  const [cache, setCache] = useState(statsCacheState);
  const [editSettings, setEditSettings] = useState(!cache?.settings);
  const [deleteStat, setDeleteStat] = useState(false);
  const [publish, setPublish] = useState(false);
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    cache?.selectedPeriodId || ""
  );
  const [timestamp, setTimestamp] = useState(cache?.timestamp || 0);
  const [editLineups, setEditLineups] = useState(false);
  const [selectedStat, setSelectedStat] = useState("");
  const [isHomeStat, setIsHomeStat] = useState(true);

  const [updateFixture] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBasketball",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  const changePeriod = (newPeriodId) => {
    const newTimestamp = newPeriodId
      ? statsCacheState?.periods?.[newPeriodId] ||
        sumToPeriod(periods, newPeriodId)
      : 0;

    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...statsCacheState,
            selectedPeriodId: newPeriodId,
            timestamp: newTimestamp,
            periods: {
              ...(statsCacheState?.periods || {}),
              ...{ [selectedPeriodId]: timestamp },
            },
          },
        },
      },
      update: (cache, { data: { updateFixture } }) => {
        cache.writeQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
          data: {
            statsCacheState: updateFixture.statsCacheState,
            periods: updateFixture.periods,
          },
        });

        setCache(updateFixture.statsCacheState);
      },
    });
    setSelectedPeriodId(newPeriodId);
    setTimestamp(newTimestamp);
  };

  const updateCache = () => {
    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...statsCacheState,
            selectedPeriodId,
            timestamp,
            periods: {
              ...(statsCacheState?.periods || {}),
              ...{ [selectedPeriodId]: timestamp },
            },
          },
        },
      },
    });
  };

  const sport = fixture.event.sport;

  return (
    <div className="score-football">
      <ScoringHeader
        fixtureId={fixtureId}
        periods={periods}
        changePeriod={changePeriod}
        selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        setEditSettings={setEditSettings}
        setEditLineups={setEditLineups}
        cache={cache}
        updateCache={updateCache}
        setPublish={setPublish}
      />

      <div className="body">
        <div className="left-column">
          <StatTypesList
            onClick={(statType) => {
              setSelectedStat(statType);
              setIsHomeStat(true);
            }}
            selectedPeriodId={selectedPeriodId}
            sport={sport}
          />
        </div>
        <div className="middle-column">
          <StatsList
            fixtureId={fixtureId}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            onDelete={(s, st, t) => {
              setDeleteStat({ stat: s, stats: st, timestamp: t });
            }}
          />
        </div>
        <div className="right-column">
          <StatTypesList
            onClick={(statType) => {
              setSelectedStat(statType);
              setIsHomeStat(false);
            }}
            selectedPeriodId={selectedPeriodId}
            sport={sport}
          />
        </div>
      </div>

      {selectedStat && (
        <OverlayNew
          fixtureId={fixtureId}
          eventId={eventId}
          dismiss={() => {
            setSelectedStat(false);
          }}
          selectedStat={selectedStat}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          isHomeStat={isHomeStat}
          defaultTimestamp={timestamp}
          selectedPeriodId={selectedPeriodId}
          periods={periods}
          sport={sport}
        />
      )}

      {deleteStat && (
        <DeleteStatsConfirm
          fixtureId={fixtureId}
          dismiss={() => {
            setDeleteStat(false);
          }}
          stat={deleteStat.stat}
          stats={deleteStat.stats}
          timestamp={deleteStat.timestamp}
        />
      )}

      {editSettings && (
        <Settings
          fixtureId={fixtureId}
          setEditSettings={setEditSettings}
          statsCacheState={statsCacheState}
          defaultPeriods={periods}
        />
      )}

      {editLineups && (
        <LineupsPageNew
          fixtureId={fixtureId}
          fixture={fixture}
          eventId={eventId}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
          dismiss={() => {
            setEditLineups(false);
          }}
        />
      )}

      {(publish || completed) && (
        <PublishStatsWrapper
          fixtureId={fixtureId}
          eventId={eventId}
          setPublish={setPublish}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
          completed={completed}
          sport={sport}
        />
      )}
    </div>
  );
}

function ScoreBasketballWrapper({ id }) {
  usePageTracking({ title: "Basketball Scoring" });
  const { loading, error, data } = useQuery(FIXTURE_SETTINGS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || !data) {
    return <Spinner />;
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBasketball.ScoreBasketballWrapper",
        query: "FIXTURE_SETTINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const cache = data?.fixture?.statsCacheState;
  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  const homeTeam = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayTeam = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  const homeLineup = data?.fixture?.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === homeTeam?.organization?.id
  );

  const awayLineup = data?.fixture?.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization.id === awayTeam?.organization?.id
  );

  const eventId = data?.fixture?.event?.id;
  const completed = data?.fixture?.completed;
  // console.log(completed);

  return (
    <ScoreBasketball
      fixtureId={id}
      fixture={data.fixture}
      eventId={eventId}
      statsCacheState={cache}
      periods={periods}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
      completed={completed}
    />
  );
}

export default ScoreBasketballWrapper;
