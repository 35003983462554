import { gql } from "@apollo/client";

const MEDIA = gql`
  query media($id: ID!) {
    media(id: $id) {
      id
      type
      subtype
      createdAt
      clapCount
      isClapped
      createdByProfile {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
      description
      truncatedDescription
      contentLength
      canManageMedia
      tags {
        id
        party {
          id
          profile {
            id
            name
            handle
          }
          organization {
            id
            name
            handle
          }
          event {
            id
            name
          }
        }
      }
      source {
        id
        event {
          id
          name
          profilePhoto {
            id
            filename
          }
          organizedBy {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        fixture {
          id
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          roles {
            id
            order
            type {
              id
              name
            }
            subtypes {
              id
              name
            }
            profile {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            formation {
              id
              name
              positions {
                id
                positionType {
                  id
                  shortName
                }
                x
                y
              }
            }
          }
          placements(where: { type: { name: "RESULT" } }) {
            id
            order
            value
            valueString
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            profile {
              id
            }
          }
          badmintonBoxScore {
            id
            label
            homeScore
            awayScore
          }
          event {
            id
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            sport {
              id
              name
            }
          }
        }
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

export default MEDIA;
