import "./Scoring.css";
import { Switch, Route } from "react-router-dom";
import {
  EventsIndex,
  ManageEvent,
  ManageFixture,
  ScoreFixture,
} from "../../components/Scoring";

function Scoring() {
  return (
    <div className="scoring-app">
      <Switch>
        <Route path="/app/manage/fixture/:id/scoring">
          <ScoreFixture />
        </Route>

        <Route path="/app/manage/fixture/:id">
          <ManageFixture />
        </Route>

        <Route path="/app/manage/event/:id">
          <ManageEvent />
        </Route>

        <Route path="/app/manage/events">
          <EventsIndex />
        </Route>

        <Route path="/app/manage">
          <EventsIndex />
        </Route>
      </Switch>
    </div>
  );
}

export default Scoring;
