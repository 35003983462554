import { gql } from "@apollo/client";

const EVENT_MEDIA = gql`
  query eventQuery($id: ID!, $type: String) {
    event(id: $id) {
      id
      media(type: $type) {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        source {
          id
          event {
            id
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          fixture {
            id
            event {
              id
              organizedBy {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
      }
      mediaSets {
        id
        name
        preview {
          id
          filename
        }
        source {
          id
          fixture {
            id
            scheduledStart
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              organization {
                id
                name
              }
              profile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default EVENT_MEDIA;
