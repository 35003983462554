import { Fragment } from "react";
import "./FixtureListItem.css";
import Card from "../Card";

import moment from "moment";
import { ReactComponent as ClockIcon } from "../../icons/clock.svg";
import Avatar from "../Avatar";

function determineScore(placements, participant) {
  if (participant.organization) {
    const placement = placements.find(
      (p) => p.organization?.id === participant.organization?.id
    );
    if (!placement) {
      return "-";
    } else {
      return placement.valueString || placement.value;
    }
  } else if (participant.profile) {
    const placement = placements.find(
      (p) => p.profile?.id === participant.profile?.id
    );
    if (!placement) {
      return "-";
    } else {
      return placement.valueString || placement.value;
    }
  } else {
    return "-";
  }
}

function FixtureListItem({ fixture, hideDate, className = "" }) {
  // console.log("Fixture:", fixture);

  if (fixture.event?.sport?.name === "Badminton") {
    return (
      <Card className={"fixture-list-item " + className}>
        <div className="participants">
          {[...fixture.roles]
            .sort((a, b) => a.order - b.order)
            .filter((r) => r.type.name === "PARTICIPANT")
            .map((p) => (
              <div key={"fixture-role-" + p.id} className="participant">
                <div className="image">
                  {(p.organization?.profilePhoto ||
                    p.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        p.organization?.profilePhoto || p.profile?.profilePhoto
                      }
                    />
                  )}
                </div>
                <div className="name">
                  {p.organization?.name || p.profile?.name || "TBD"}
                </div>
              </div>
            ))}
        </div>

        <div className="score">
          {fixture.badmintonBoxScore.map((x, i) => (
            <div
              key={"box-score-item-" + fixture.id + "-" + i}
              className="score__box-score-period"
            >
              <div
                className={
                  "score__box-score-period__item " +
                  (x.homeScore > x.awayScore ? "bold" : "")
                }
              >
                {x.homeScore}
              </div>

              <div
                className={
                  "score__box-score-period__item " +
                  (x.awayScore > x.homeScore ? "bold" : "")
                }
              >
                {x.awayScore}
              </div>
            </div>
          ))}
          {/* {determineScore(
            fixture.placements.find(
              (result) =>
                result.organization?.id === p.organization?.id ||
                result.profile?.id === p.profile?.id
            )
          )} */}
        </div>

        {!hideDate && (
          <div className="datetime">
            {fixture.scheduledStart && (
              <Fragment>
                <div className="date">
                  {moment(fixture.scheduledStart).format("DD/MM")}
                </div>
                <div className="time">
                  {fixture.completed
                    ? "FT"
                    : moment(fixture.scheduledStart).format("HH:mm")}
                </div>
              </Fragment>
            )}

            {!fixture.scheduledStart && (
              <Fragment>
                <div className="date">
                  <ClockIcon style={{ stroke: "var(--dark-4)" }} />
                </div>
                <div className="time">TBD</div>
              </Fragment>
            )}
          </div>
        )}
      </Card>
    );
  } else {
    return (
      <Card className={"fixture-list-item " + className}>
        <div className="participants">
          {[...fixture.roles]
            .sort((a, b) => a.order - b.order)
            .filter((r) => r.type.name === "PARTICIPANT")
            .map((p) => (
              <div
                key={"fixture-list-item-participant-" + p.id}
                className="participant"
              >
                <div className="image">
                  {(p.organization?.profilePhoto ||
                    p.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        p.organization?.profilePhoto || p.profile?.profilePhoto
                      }
                    />
                  )}
                </div>
                <div className="name">
                  {p.organization?.name || p.profile?.name || "TBD"}
                </div>
                <div className="score">
                  {determineScore(fixture.placements, p)}
                </div>
              </div>
            ))}
        </div>

        {!hideDate && (
          <div className="datetime">
            {fixture.scheduledStart && (
              <Fragment>
                <div className="date">
                  {moment(fixture.scheduledStart).format("DD/MM")}
                </div>
                <div className="time">
                  {fixture.completed
                    ? "FT"
                    : moment(fixture.scheduledStart).format("HH:mm")}
                </div>
              </Fragment>
            )}

            {!fixture.scheduledStart && (
              <Fragment>
                <div className="date">
                  <ClockIcon style={{ stroke: "var(--dark-4)" }} />
                </div>
                <div className="time">TBD</div>
              </Fragment>
            )}
          </div>
        )}
      </Card>
    );
  }
}

export default FixtureListItem;
