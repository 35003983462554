import "./BallByBall.css";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";
import Spinner from "../../../components/Spinner";
import EditOverItem from "../EditOverItem";
import { useState } from "react";
import overItemSelectionSet from "../utils/overItemSelectionSet";
import AddBall from "../AddBall/AddBall";

function OverItem({ overItem, prev }) {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditOverItem
        overItem={overItem}
        dismiss={() => {
          setEditing(false);
        }}
      />
    );
  }

  const isFreeHit =
    prev && prev.extras?.find((x) => x.statType.name === "No Ball");

  return (
    <div className="ScoringApp-cricket__scoring__stats-list__section__items__item">
      <div className="ScoringApp-cricket__scoring__stats-list__section__items__item__label">
        {overItem.ballsBowled?.value?.toFixed(1)}
      </div>
      <div className="ScoringApp-cricket__scoring__stats-list__section__items__item__text">
        {!!isFreeHit && (
          <div className="ScoringApp-cricket__scoring__stats-list__section__items__item__text__muted">
            FREE HIT
          </div>
        )}
        <div className="ScoringApp-cricket__scoring__stats-list__section__items__item__text__primary">
          {overItem.ballsBowled?.profile?.name} to{" "}
          {overItem.runsScored?.profile?.name}
        </div>

        <div className="ScoringApp-cricket__scoring__stats-list__section__items__item__text__secondary">
          {overItem.extras?.length
            ? overItem.extras
                .map((x) => x.statType.name + `(${x.value})`)
                .join(", ")
            : ""}{" "}
          {overItem.extras?.length && overItem.wicket ? " | " : ""}{" "}
          {overItem.wicket?.statType.name || ""}
        </div>
      </div>
      <div className="ScoringApp-cricket__scoring__stats-list__section__items__item__runs">
        {overItem.runsScored.value}
      </div>
      <div
        className="ScoringApp-cricket__scoring__stats-list__section__items__item__edit"
        onClick={() => {
          setEditing(true);
        }}
      >
        <PencilIcon className="ScoringApp-cricket__scoring__stats-list__section__items__item__edit__icon" />
      </div>
    </div>
  );
}

export default function BallByBall() {
  const [
    {
      fixtureId,
      selectedPeriod,
      battingTeam,
      batsmen,
      bowler,
      strikeIndex,
      currentOverNumber,
    },
    setCricketScoringState,
  ] = useCricketScoringState();

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: overItemSelectionSet(selectedPeriod, currentOverNumber),
    }),
    {
      variables: {
        id: fixtureId,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__current-over">
        <Spinner />
      </div>
    );
  }

  const overItems = data?.fixture?.cricketLiveScoring?.overStats || [];
  // console.log([...overItems].reverse()[0]);
  const sortedOverItems = [...overItems].reverse();
  return (
    <div className="ScoringApp-cricket__scoring__stats-list">
      <div className="ScoringApp-cricket__scoring__stats-list__header">
        Ball-by-Ball
      </div>

      <div className="ScoringApp-cricket__scoring__stats-list__list">
        <div className="ScoringApp-cricket__scoring__stats-list__section">
          <div className="ScoringApp-cricket__scoring__stats-list__section__title">
            Over {currentOverNumber + 1}
          </div>
          <div className="ScoringApp-cricket__scoring__stats-list__section__items">
            {sortedOverItems.map((o, i) => (
              <OverItem overItem={o} prev={sortedOverItems[i + 1]} />
            ))}
          </div>
          <AddBall />
        </div>
      </div>
    </div>
  );
}
