import React, { useEffect, useState } from "react";

const Counter = ({ number, duration }) => {
  // label of counter
  // number to increment to
  // duration of count in seconds

  // number displayed by component
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = duration / number;

    let timer = setInterval(() => {
      setCount((prev) => {
        const newCount = prev + 1;
        if (newCount > number) {
          clearInterval(timer);
          return prev;
        } else {
          return prev + 1;
        }
      });
    }, increment);

    // dependency array
  }, [number, duration]);

  return <span>{count}</span>;
};

export default Counter;
