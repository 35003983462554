import "./EditDisplayPhoto.css";
import { useState, useRef } from "react";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as FolderIcon } from "../../icons/folder.svg";
import { useAppState } from "../../utils/appState";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../components/Spinner";
import makeFileUrl from "../../utils/makeFileUrl";
import Square from "../Square";
import Button from "../../components/Button";
import Cropper from "../../components/Cropper";
import captureException from "../../utils/captureException";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

function UploadDisplayPhoto({
  config,
  setUpload,
  hasUploadedPhotos,
  // type = "IMAGE",
  // subtype,
  // sourceId,
  // sourceType,
  // maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  // updateFilesCb = () => {},
  // onCreateMedia = () => {},
  // cropperWidth,
  // cropperHeight,
  // ...otherProps
}) {
  const {
    type = "IMAGE",
    subtype,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    updateFilesCb = () => {},
    onCreateMedia = () => {},
    cropperWidth,
    cropperHeight,
    multiple,
    accept,
  } = config?.upload || {};
  const { sourceId, sourceType } = config?.wrapper || {};

  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [, setSizeError] = useState(false);

  const filesLength = Object.keys(files).reduce((accum, curr) => {
    accum++;
    return accum;
  }, 0);

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!multiple) {
          return { file };
        }
        files[file.name] = file;
      } else {
        setSizeError(true);
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = (e) => {
    setSizeError(false);
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  // console.log(cropperWidth, cropperHeight);

  return (
    <div className="mobile-upload-display-photo">
      {!filesLength && (
        <div className="mobile-upload-display-photo__input-container">
          <FolderIcon className="input-icon" />

          <Button className="primary small blue browse-files-btn">
            Select a file
          </Button>
          <input
            type="file"
            ref={fileInputField}
            title=""
            value=""
            onChange={handleNewFileUpload}
            {...(accept ? { accept } : {})}
          />
        </div>
      )}

      {!!filesLength && (
        <Cropper
          image={files[Object.keys(files)[0]]}
          sourceId={sourceId}
          sourceType={sourceType}
          type={type}
          subtype={subtype}
          onCreateMedia={onCreateMedia}
          cropperWidth={cropperWidth}
          cropperHeight={cropperHeight}
        />
      )}

      {hasUploadedPhotos && !filesLength && (
        <div
          className="modal-form-mobile__body__select-img__footer"
          onClick={() => {
            setUpload(false);
          }}
          style={{ color: "var(--dark-4)" }}
        >
          Select an uploaded photo
        </div>
      )}
    </div>
  );
}

function SelectDisplayPhoto({
  config,
  photos,
  defaultSelectedPhoto,
  setUpload,
}) {
  const { mutation, mutationVars, update } = config?.select || {};
  const { sourceId } = config?.wrapper || {};
  const [selecting, setSelecting] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(defaultSelectedPhoto);
  const [mutate, { loading }] = useMutation(mutation, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EditDisplayPhoto.SelectDisplayPhoto",
          mutation: "variable",
        },
      });
      console.log(error);
    },
  });

  const updateDisplayPhoto = (media) => {
    if (loading) return;
    mutate({
      variables: {
        where: { id: sourceId },
        data: mutationVars(
          media?.id ? { connect: { id: media.id } } : { disconnect: true }
        ),
      },
      update: (cache, result) => {
        update(cache, result);
        setSelecting(false);
        setSelectedPhoto(media);
      },
    });
  };

  return (
    <div className="modal-form-mobile__body__select-img">
      <div className="modal-form-mobile__body__select-img__list">
        {photos.map((m) => (
          <Square>
            {({ squareRef, dimensions }) => (
              <div
                className="modal-form-mobile__body__select-img__list__item"
                ref={squareRef}
                style={{ height: dimensions.width / 2, marginBottom: "12px" }}
                onClick={() => {
                  if (selecting) {
                    return;
                  }

                  setSelecting(m.id);

                  if (selectedPhoto?.id === m.id) {
                    updateDisplayPhoto(false);
                  } else {
                    updateDisplayPhoto(m);
                  }
                }}
                // onClick={() => {
                //   if (selectedPhoto?.id === m.id) {
                //     setSelectedPhoto(false);
                //   } else {
                //     setSelectedPhoto(m);
                //   }

                //   // updateProfilePhoto(m);
                // }}
              >
                <img src={makeFileUrl(m)} alt="" />
                <div
                  className={
                    "modal-form-mobile__body__select-img__list__item__select " +
                    (m.id === selectedPhoto?.id ? "selected" : "")
                  }
                >
                  <CheckIcon className="select-profile-photo__images__item__select__icon" />
                </div>

                {selecting === m.id && (
                  <div
                    className={
                      "modal-form-mobile__body__select-img__list__item__select selected"
                    }
                    style={{ background: "none" }}
                  >
                    <Spinner height={"12px"} color={"#ffffff"} />
                  </div>
                )}
              </div>
            )}
          </Square>
        ))}
      </div>

      <div
        className="modal-form-mobile__body__select-img__footer"
        onClick={() => {
          setUpload(true);
        }}
      >
        Upload a new photo
      </div>
    </div>
  );
}

export default function EditDisplayPhoto({
  config,
  // config: {
  //   wrapper,
  //   item,
  //   // query,
  //   // getPhotos,
  //   // getSelectedPhoto,
  //   // title,
  //   type,
  //   subtype,
  //   sourceId,
  //   sourceType,
  //   maxFileSizeInBytes,
  //   updateFilesCb,
  //   onCreateMedia,
  //   cropperWidth,
  //   cropperHeight,
  // },
}) {
  const {
    title,
    query,
    getPhotos,
    getSelectedPhoto,
    defaultUploadState,
    sourceId,
  } = config.wrapper || {};

  const [, setAppState] = useAppState();

  const dismiss = () => {
    setAppState({ modal: false });
  };

  const [upload, setUpload] = useState(defaultUploadState);

  const { loading, error, data } = useQuery(query, {
    variables: { id: sourceId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EditDisplayPhoto",
        query: "variable",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="modal-form-mobile-wrapper">
        <Spinner />
      </div>
    );
  }

  const photos = getPhotos(data);
  const selectedPhoto = getSelectedPhoto(data);

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            {(upload ? "Upload " : "Select ") + title}
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          {upload ? (
            <UploadDisplayPhoto
              config={config}
              setUpload={setUpload}
              hasUploadedPhotos={!!photos.length}
              // type={type}
              // subtype={subtype}
              // sourceId={sourceId}
              // sourceType={sourceType}
              // maxFileSizeInBytes={maxFileSizeInBytes}
              // updateFilesCb={updateFilesCb}
              // onCreateMedia={onCreateMedia}
              // cropperWidth={cropperWidth}
              // cropperHeight={cropperHeight}
            />
          ) : (
            <SelectDisplayPhoto
              setUpload={setUpload}
              photos={photos}
              defaultSelectedPhoto={selectedPhoto}
              config={config}
            />
          )}
        </div>
      </div>
    </div>
  );
}
