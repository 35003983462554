import { useQuery } from "@apollo/client";
import "./CricketStatsNew.css";
import { useParams } from "react-router-dom";
import { FIXTURE } from "../../../../api/functions";
import Spinner from "../../../Spinner";
import Card from "../../../Card";
import { useState } from "react";
import Avatar from "../../../Avatar";
import { Link } from "react-router-dom";

const getDismissal = (row, notOuts = []) => {
  const wicket = row?.rowData?.wicket;
  const wicketTaken = wicket?.relationshipsFrom.find(
    (r) => r.to?.statType?.name === "Wicket Taken"
  )?.to;

  if (!wicket) {
    return <div>{notOuts.includes(row.profile.id) ? "Not Out" : ""}</div>;
  } else if (wicket.statType.name === "Caught & Bowled") {
    return (
      <div>
        {"c & b"} {wicketTaken.profile.name}
      </div>
    );
  } else if (wicket.statType.name === "Run Out") {
    const fielding = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Run Out"
    )?.to;
    const stumping = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Run Out Stumping"
    )?.to;
    if (stumping) {
      return (
        <div>
          run out ({fielding?.profile?.name} / {stumping?.profile?.name}){" "}
        </div>
      );
    } else {
      return <div>run out {fielding?.profile?.name}</div>;
    }
  } else if (wicket.statType.name === "Caught") {
    const fielding = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Catch"
    )?.to;
    return (
      <div>
        c {fielding?.profile?.name} b {wicketTaken?.profile?.name}
      </div>
    );
  } else if (wicket.statType.name === "LBW") {
    return <div>lbw {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Bowled") {
    return <div>b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Stumped") {
    const stumping = wicket.relationshipsFrom?.find(
      (r) => r.to?.statType?.name === "Stumping"
    )?.to;
    return (
      <div>
        st. {stumping?.profile?.name} b {wicketTaken?.profile?.name}
      </div>
    );
  } else if (wicket.statType.name === "Retired") {
    return <div>retired</div>;
  } else if (wicket.statType.name === "Obstruction") {
    return <div>obstruction</div>;
  } else if (wicket.statType.name === "Hit Wicket") {
    return <div>hit wicket b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Double Hit") {
    return <div>double hit b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Out of Bounds") {
    return <div>out of bounds</div>;
  } else if (wicket.statType.name === "Ball Handling") {
    return <div>ball handling</div>;
  } else if (wicket.statType.name === "Out by Dots") {
    return <div>out by dots b {wicketTaken?.profile?.name}</div>;
  } else if (wicket.statType.name === "Timed Out") {
    return <div>timed out </div>;
  }
};

const battingTableCells = [
  {
    label: "BATTING",
    width: "25%",
    style: {
      width: "25%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      //   fontWeight:"600"
    },
    data: (row) => row.profile.name,
    link: (row) => "/app/profile/" + row.profile.id,
    hasImage: (row) => {
      return row.profile.profilePhoto;
    },
  },
  {
    label: "",
    width: "25%",
    style: { width: "25%" },
    data: (row, ballsPerOver, notOuts) => {
      return getDismissal(row, notOuts);
      // return dismissalStat?.statType?.name || "";
    },
  },
  {
    label: "R",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row?.rowData?.runsScored || 0,
    legend: "Runs",
  },
  {
    label: "B",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row?.rowData?.ballsFaced || 0,
    legend: "Balls Faced",
  },
  {
    label: "4s",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row?.rowData?.fours || 0,
  },
  {
    label: "6s",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row?.rowData?.sixes || 0,
  },
  {
    label: "SR",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => {
      const ballsFaced = row?.rowData?.ballsFaced || 0;
      const runsScored = row?.rowData?.runsScored || 0;

      if (!ballsFaced) {
        return 0;
      } else {
        return ((runsScored / ballsFaced) * 100).toFixed(2);
      }
    },
    legend: "Strike Rate",
  },
];

const bowlingTableCells = [
  {
    label: "BOWLING",
    width: "10%",
    style: {
      width: "40%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    data: (row) => row.profile.name,
    link: (row) => "/app/profile/" + row.profile.id,
    hasImage: (row) => {
      return row.profile.profilePhoto;
    },
  },
  {
    label: "O",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row, ballsPerOver) => {
      const ballsBowled = row.rowData?.ballsBowled || 0;

      if (!ballsBowled) {
        return 0;
      } else {
        return (
          Math.floor(ballsBowled / ballsPerOver) +
          (ballsBowled % ballsPerOver) / 10
        );
      }
    },
    legend: "Overs",
  },
  {
    label: "R",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row.rowData?.runsConceded || 0,
    legned: "Runs Conceded",
  },
  {
    label: "W",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row.rowData?.wicketsTaken || 0,
    legend: "Wickets Taken",
  },
  {
    label: "ECON",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row, ballsPerOver) => {
      const ballsBowled = row.rowData?.ballsBowled || 0;
      if (!ballsBowled) return 0;

      const overs =
        Math.floor(ballsBowled / ballsPerOver) + ballsBowled / ballsPerOver;

      const runsConceded = row.rowData?.runsConceded || 0;

      return (runsConceded / overs).toFixed(2);
    },
    legend: "Economy Rate",
  },
  {
    label: "WD",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row.rowData?.wides || 0,
    legend: "Wide",
  },
  {
    label: "NB",
    width: "10%",
    style: { width: "10%", textAlign: "right" },
    data: (row) => row.rowData?.noBalls || 0,
    legend: "NB",
  },
];

export default function CricketStatsNew() {
  const { id } = useParams();
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      cricketLiveScoring{
        id
        scorecards{
            id
            items{
                id
                wicket{
                    id
                    statType{
                        id
                        name
                    }
                    relationshipsFrom{
                        id
                        to{
                            id
                            statType{
                                id
                                name
                            }
                            profile{
                                id
                                name                                
                            }
                        }
                    }
                }
                runsScored
                ballsFaced
                fours
                sixes
                ballsBowled
                runsConceded
                wicketsTaken
                wides
                noBalls
            }
        }
      }
      periods{
        id
        order
        label
      }
      metas {
        id
        label
        value
        stringValue
      }
      roles {
        id
        order
        sortOrder
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          firstname
          lastname
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
  `,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);

    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="no-data">
        <Spinner />
      </div>
    );
  }

  const fixture = data?.fixture;
  const periods = [...(fixture?.periods || [])].sort(
    (a, b) => a.order - b.order
  );

  const participants = fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const sortedParticipants = [...(participants || [])].sort(
    (a, b) => a.order - b.order
  );

  const homeTeam = sortedParticipants[0];
  const homeScorecard = fixture?.cricketLiveScoring?.scorecards?.find(
    (s) => s.id === homeTeam?.organization?.id
  );

  const awayTeam = sortedParticipants[1];
  const awayScorecard = fixture?.cricketLiveScoring?.scorecards?.find(
    (s) => s.id === awayTeam?.organization?.id
  );

  const players = fixture?.roles?.filter(
    (r) => r.type.name === "LINEUP PLAYER"
  );

  console.log(players);

  const homePlayers = players?.filter(
    (r) => r.organization?.id === homeTeam?.organization.id
  );

  const awayPlayers = players?.filter(
    (r) => r.organization?.id === awayTeam?.organization.id
  );

  const homeStarters = homePlayers.filter((r) =>
    r.subtypes.find((x) => x.name === "STARTER")
  );

  // const homeSubs = homePlayers.filter((r) =>
  //   r.subtypes.find((x) => x.name !== "STARTER")
  // );

  const awayStarters = awayPlayers.filter((r) =>
    r.subtypes.find((x) => x.name === "STARTER")
  );

  // const awaySubs = awayPlayers.filter((r) =>
  //   r.subtypes.find((x) => x.name !== "STARTER")
  // );

  const metas = data?.fixture?.metas;
  const ballsPerOverMeta = metas?.find((m) => m.label === "ballsPerOver");
  const ballsPerOver = ballsPerOverMeta?.value || 6;

  //   const metas = data?.fixture?.metas;
  //   const tossWinnerMeta = metas?.find((m) => m.label === "tossWinner");
  //   const tossDecisionMeta = metas?.find((m) => m.label === "tossDecision");
  const selectedTeam = sortedParticipants[selectedTeamIndex];
  const battingScorecard =
    selectedTeam?.organization?.id === homeTeam?.organization?.id
      ? homeScorecard
      : awayScorecard;

  const bowlingScorecard =
    selectedTeam?.organization?.id === homeTeam?.organization?.id
      ? awayScorecard
      : homeScorecard;
  const battingLineup = [
    ...(selectedTeam?.organization?.id === homeTeam?.organization?.id
      ? homeStarters
      : awayStarters),
  ]
    .sort((a, b) => {
      return (a.sortOrder || 100) - (b.sortOrder || 100);
    })
    .map((r) => ({
      ...r,
      rowData: battingScorecard?.items?.find((x) => x.id === r.profile?.id),
    }));

  const bowlingLineup = [
    ...(selectedTeam?.organization?.id === homeTeam?.organization?.id
      ? awayStarters
      : homeStarters),
  ]
    .map((r) => ({
      ...r,
      rowData: bowlingScorecard?.items?.find((x) => x.id === r.profile?.id),
    }))
    .filter((r) => r.rowData?.ballsBowled);

  let notOutsArray = [];

  [...battingLineup].reverse().forEach((role) => {
    if (!role.rowData.wicket) {
      notOutsArray.push(role.profile.id);
    }
  });

  const notOuts = notOutsArray.slice(-2);

  //   const bowlingLineup = selectedTeam.organization?.id === homeTeam?.organization?.id
  //   ? awayStarters
  //   : awayStarters

  console.log("HS:", homeScorecard);
  console.log("HL:", homeStarters);
  console.log("Not outs:", notOuts);

  return (
    <div className="fixture-stats-cricket-wrapper">
      <Card className="fixture-stats-cricket">
        <div className="fixture-stats-cricket__header">
          <div className="fixture-stats-cricket__header__title">Scorecard</div>

          <div className="fixture-stats-cricket__header__options">
            {sortedParticipants.map((p, i) => (
              <div
                className={
                  "fixture-stats-cricket__header__options__option " +
                  (selectedTeamIndex === i ? "selected" : "")
                }
                onClick={() => {
                  setSelectedTeamIndex(i);
                }}
              >
                {p?.organization?.name + " Innings"}
              </div>
            ))}
          </div>
        </div>

        <div className="fixture-stats-cricket__body">
          <div className="fixture-stats-cricket__table">
            <div className="fixture-stats-cricket__table__header">
              {battingTableCells.map((cell) => (
                <div
                  className="fixture-stats-cricket__table__row__td"
                  style={cell.style}
                >
                  {cell.label}
                </div>
              ))}
            </div>

            <div className="fixture-stats-cricket__table__body">
              {battingLineup.map((r) => (
                <div className="fixture-stats-cricket__table__row">
                  {battingTableCells.map((cell) => (
                    <div
                      className="fixture-stats-cricket__table__row__td"
                      style={cell.style}
                    >
                      {cell.hasImage && (
                        <div className="fixture-stats-cricket__table__row__td__img">
                          {cell.hasImage(r) && (
                            <Avatar media={cell.hasImage(r)} />
                          )}
                        </div>
                      )}
                      {cell.link ? (
                        <Link to={cell.link(r)}>
                          {cell.data(r, ballsPerOver)}
                        </Link>
                      ) : (
                        cell.data(r, ballsPerOver, notOuts)
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="fixture-stats-cricket__table__legend">
              {battingTableCells
                .filter((x) => !!x.legend)
                .map((x) => (
                  <div className="fixture-stats-cricket__table__legend__item">
                    {x.label} - {x.legend}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Card>

      <Card className="fixture-stats-cricket" style={{ marginTop: "24px" }}>
        <div className="fixture-stats-cricket__body">
          <div className="fixture-stats-cricket__table">
            <div className="fixture-stats-cricket__table__header">
              {bowlingTableCells.map((cell) => (
                <div
                  className="fixture-stats-cricket__table__row__td"
                  style={cell.style}
                >
                  {cell.label}
                </div>
              ))}
            </div>

            <div className="fixture-stats-cricket__table__body">
              {bowlingLineup.map((r) => (
                <div className="fixture-stats-cricket__table__row">
                  {bowlingTableCells.map((cell) => (
                    <div
                      className="fixture-stats-cricket__table__row__td"
                      style={cell.style}
                    >
                      {cell.hasImage && (
                        <div className="fixture-stats-cricket__table__row__td__img">
                          {cell.hasImage(r) && (
                            <Avatar media={cell.hasImage(r)} />
                          )}
                        </div>
                      )}
                      {cell.link ? (
                        <Link to={cell.link(r)}>
                          {cell.data(r, ballsPerOver)}
                        </Link>
                      ) : (
                        cell.data(r, ballsPerOver)
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="fixture-stats-cricket__table__legend">
              {bowlingTableCells
                .filter((x) => !!x.legend)
                .map((x) => (
                  <div className="fixture-stats-cricket__table__legend__item">
                    {x.label} - {x.legend}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
