import { gql } from "@apollo/client";

const PROFILE_ALBUMS = gql`
  query profileQuery(
    $id: ID!
    $take: Int
    $cursor: ID
    $filters: ProfileMediaFilters
  ) {
    profile(id: $id) {
      id
      albumsCount
      mediaSets(take: $take, cursor: $cursor, filters: $filters) {
        id
        name
        preview {
          id
          filename
        }
        source {
          id
          fixture {
            id
            scheduledStart
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              organization {
                id
                name
              }
              profile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default PROFILE_ALBUMS;
