import { gql } from "@apollo/client";

const ORGANIZATION_EVENT_PARTICIPANTS = gql`
  query organizationQuery($id: ID!) {
    organization(id: $id) {
      id
      eventParticipants {
        id
        name
      }
    }
  }
`;

export default ORGANIZATION_EVENT_PARTICIPANTS;
