import { gql } from "@apollo/client";

const AWARD_TYPE = gql`
  query awardType($id: ID!) {
    awardType(id: $id) {
      id
      placements {
        id
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        type {
          id
          name
        }
      }
    }
  }
`;

export default AWARD_TYPE;
