import "./FootballStats.css";
import Card from "../../../Card";
import Button from "../../../Button";
import Dropdown from "../../../Dropdown";
import BarChart from "../../../BarChart";
import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import { useQuery } from "@apollo/client";
import { STAT_TYPES } from "../../../../api/sport";
import { useState } from "react";
import { EVENT_STATS, EVENT_PROFILE_STATS } from "../../../../api/event";
import { Link } from "react-router-dom";
import { barChartTooltipAlt } from "../tooltips/barChartTooltipAlt";
import Avatar from "../../../Avatar";
import captureException from "../../../../utils/captureException";

function TopTeams({ statTypes, eventId }) {
  const defaultStatType = statTypes.find((x) => x.name === "Goal");
  const [selectedStat, setSelectedStat] = useState(defaultStatType);

  const { loading, error, data, refetch } = useQuery(EVENT_STATS, {
    variables: {
      id: eventId,
      statTypeId: defaultStatType.id,
      type: "organization",
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.FootballStats.TopTeams",
        query: "EVENT_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const tables = data?.eventStats;
  // console.log(tables);

  return (
    <Card className="top-teams">
      <div className="card-title">
        <div className="text">Team Performance</div>
        <Dropdown
          target={
            <Button className="secondary  small">
              {selectedStat.name} <ChevronDown className="icon" />
            </Button>
          }
          menu={({ setShowMenu }) => (
            <div className="stat-type-selection-menu">
              {statTypes.map((st) => (
                <div
                  className="stat-type-selection-item"
                  onClick={() => {
                    setSelectedStat(st);
                    setShowMenu(false);
                    refetch({
                      id: eventId,
                      statTypeId: st.id,
                      type: "organization",
                    });
                  }}
                >
                  {st.name}
                </div>
              ))}
            </div>
          )}
        />
      </div>
      <div className="card-chart">
        <BarChart
          labels={tables.map((t) => t.organization.name)}
          datasets={[
            {
              data: tables.map((t) => t.value),
              backgroundColor: tables.map((t, i) => {
                if (!i) {
                  return "#a1f291";
                } else {
                  return "#b9e3fe";
                }
              }),
            },
          ]}
          tooltip={barChartTooltipAlt}
        />
      </div>
    </Card>
  );
}

function TopPlayers({ statTypes, eventId }) {
  const defaultStatType = statTypes.find((x) => x.name === "Goal");
  const [selectedStat, setSelectedStat] = useState(defaultStatType);

  const { loading, error, data, refetch } = useQuery(EVENT_PROFILE_STATS, {
    variables: {
      id: eventId,
      statTypeId: defaultStatType.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.FootballStats.TopPlayers",
        query: "EVENT_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const tables = data?.eventProfileStats;
  // console.log(tables);

  return (
    <Card className="top-players">
      <div className="card-title">
        <div className="text">Player Performance</div>
        <Dropdown
          target={
            <Button className="secondary  small">
              {selectedStat.name} <ChevronDown className="icon" />
            </Button>
          }
          menu={({ setShowMenu }) => (
            <div className="stat-type-selection-menu">
              {statTypes.map((st) => (
                <div
                  className="stat-type-selection-item"
                  onClick={() => {
                    setSelectedStat(st);
                    setShowMenu(false);
                    refetch({
                      id: eventId,
                      statTypeId: st.id,
                      type: "profile",
                    });
                  }}
                >
                  {st.name}
                </div>
              ))}
            </div>
          )}
        />
      </div>

      <div className="top-players-list">
        {tables.map((t) => (
          <div className="top-players-list-item">
            <div className="player-image">
              {t.profile?.profilePhoto && (
                <Avatar media={t.profile.profilePhoto} />
              )}
              <div className="team-logo">
                {t.organization?.profilePhoto && (
                  <Avatar media={t.organization.profilePhoto} />
                )}
              </div>
            </div>
            <div className="player-meta">
              <div className="primary-text">
                <Link to={"/app/profile/" + t.profile.id}>
                  {t.profile.name}
                </Link>
              </div>
              <div className="secondary-text">
                <Link to={"/app/page/" + t.organization?.id}>
                  {t.organization?.name}
                </Link>
              </div>
            </div>
            <div className="value">{t.value}</div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function FootballStats({ id }) {
  const { loading, error, data } = useQuery(STAT_TYPES, {
    variables: { sport: "Football" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.FootballStats",
        query: "STAT_TYPES",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const statTypes = data?.statTypes;

  if (!statTypes.length) {
    return <div className="">No stat types added</div>;
  }

  return (
    <div className="event-stats-page">
      <div className="left-column">
        <TopTeams statTypes={statTypes} eventId={id} />
      </div>

      <div className="right-column">
        <TopPlayers statTypes={statTypes} eventId={id} />
      </div>
    </div>
  );
}

export default FootballStats;
