import "./DeleteVideo.css";
import { DELETE_MEDIA } from "../../../../api/media";
import { useMutation } from "@apollo/client";
import Loader from "../../../Loader";
import captureException from "../../../../utils/captureException";

export default function DeleteVideo({ media, dismiss, onDelete }) {
  const [deleteMedia, { loading }] = useMutation(DELETE_MEDIA, {
    update: onDelete,
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "DeleteVideo",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="delete-video-form">
      <div className="delete-video-form__desc">
        Are you sure you want to delete this video?
      </div>

      <div className="delete-video-form__options">
        <div
          className="delete-video-form__options__option delete"
          onClick={() => {
            if (loading) return;
            // console.log("Deleting");
            deleteMedia({ variables: { id: media.id } });
          }}
        >
          {loading ? (
            <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
              <Loader />
            </div>
          ) : (
            "Yes, Delete"
          )}
        </div>
        <div className="delete-video-form__options__option" onClick={dismiss}>
          No, Cancel
        </div>
      </div>
    </div>
  );
}
