import { useAppState } from "../../../../utils/appState";
import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../../../icons/chevron-left.svg";
import { ReactComponent as SettingsIcon } from "../../../../icons/settings.svg";
import InningsSelector from "../InningsSelector";

export default function ScoringHeader({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  setEditSettings,
  setEditLineups,
  cache,
  updateCache,
  setPublish,
  roles,
}) {
  const [, setAppState] = useAppState();
  const history = useHistory();
  return (
    <div className="header">
      <div
        className="close-scoring-btn"
        onClick={() => {
          history.push("/app/fixture/" + fixtureId);
          setAppState({ modal: false });
        }}
      >
        <div className="icon">
          <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
        </div>
        <div className="text">Back to fixture</div>
      </div>

      <InningsSelector
        fixtureId={fixtureId}
        periods={periods}
        selectedPeriod={selectedPeriod}
        changePeriod={changePeriod}
        cache={cache}
        updateCache={updateCache}
      />

      <div className="settings">
        {/* <div
          className="edit-lineups-btn"
          onClick={() => {
            setEditLineups(true);
          }}
        >
          <ListIcon style={{ fill: "white", height: "14px" }} />
          Lineups
        </div> */}
        <div
          className="publish-stats-btn"
          onClick={() => {
            setPublish(true);
          }}
        >
          {"Verify & Publish"}
        </div>
        <div
          className="settings-btn"
          onClick={() => {
            setEditSettings(true);
          }}
        >
          <SettingsIcon style={{ stroke: "var(--light-1" }} />
        </div>
      </div>
    </div>
  );
}
