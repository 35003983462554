import "./Publish.css";
import { useState, Fragment } from "react";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { FIXTURE } from "../../../api/functions";
import { PUBLISH_FIXTURE } from "../../../api/fixture";
import Spinner from "../../../components/Spinner";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

function PublishStatsConfirm({
  fixtureId,
  completed,
  dismiss,
  placements,
  tables,
}) {
  const [publishFixture, { loading }] = useMutation(PUBLISH_FIXTURE, {
    update: (cache, { data: { publishFixture } }) => {
      cache.modify({
        id: "Fixture:" + fixtureId,
        fields: {
          completed: () => {
            return true;
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreFootball.PublishStatsConfirm",
      //     mutation: "PUBLISH_FIXTURE",
      //   },
      // });
      console.log(error);
    },
  });

  return (
    <div className="ScoringApp-football__publish__confirm">
      <div className="ScoringApp-football__publish__confirm__form">
        <div className="ScoringApp-football__publish__confirm__form__header">
          {completed
            ? "Are you sure you want to update the final score for this fixture?"
            : "Are you sure you want to publish this fixture?"}
        </div>
        <div className="ScoringApp-football__publish__confirm__form__text">
          {completed ? (
            <p>
              Please note, you may need to recalculate any relevant standings
              tables for the league.
            </p>
          ) : (
            <Fragment>
              <p>
                This cannot be undone. Please note, users will still be able to
                see the score for the fixture from the fixtures page without
                publishing.
              </p>
              <p>
                Once you confirm we will update individual player, team and
                event stats, and the fixture will be available for use in
                standings calculations, so do make sure all stats are accurate
                before you publish the fixture.
              </p>
            </Fragment>
          )}
        </div>

        <div className="ScoringApp-football__publish__confirm__form__options">
          <Button
            className="secondary medium"
            onClick={() => {
              dismiss();
            }}
            disabled={loading}
          >
            No, go back
          </Button>
          <Button
            className="primary medium green"
            onClick={() => {
              if (loading) return;
              const vars = {
                fixtureId,
                placements,
                tables,
              };
              // console.log(vars);
              // return;
              publishFixture({
                variables: {
                  fixtureId,
                  placements,
                  tables,
                },
              });
            }}
            disabled={loading}
          >
            {loading ? <Loader /> : "Yes, publish this fixture"}
          </Button>
        </div>
      </div>
    </div>
  );
}

function EditScore({ score, dismiss, onUpdate }) {
  const [value, setValue] = useState(score || 0);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onUpdate(Number(value));
        dismiss();
      }}
      className="ScoringApp-football__publish__table__header__form"
    >
      <input
        type="number"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="ScoringApp-football__publish__table__header__form__input"
      />

      <button
        type="submit"
        className="ScoringApp-football__publish__table__header__form__btn submit"
      >
        <CheckIcon className="ScoringApp-football__publish__table__header__form__btn__icon" />
      </button>
      <button
        className="ScoringApp-football__publish__table__header__form__btn"
        onClick={() => {
          dismiss();
        }}
      >
        <TimesIcon className="ScoringApp-football__publish__table__header__form__btn__icon" />
      </button>
    </form>
  );
}

function Cell({ value, update }) {
  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState(value);

  return (
    <div className="ScoringApp-football__publish__table__body__column__row__data">
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            update && update(val);
            setEditing(false);
          }}
          className="ScoringApp-football__publish__table__body__column__row__data__form"
        >
          <div className="ScoringApp-football__publish__table__body__column__row__data__form__input">
            <input
              type={"number"}
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
            />
          </div>
          <button
            type={"submit"}
            className="ScoringApp-football__publish__table__body__column__row__data__form__btn submit"
          >
            <CheckIcon className="ScoringApp-football__publish__table__body__column__row__data__form__btn__icon" />
          </button>
          <button
            onClick={() => {
              setVal(value);
              setEditing(false);
            }}
            className="ScoringApp-football__publish__table__body__column__row__data__form__btn cancel"
          >
            <TimesIcon className="ScoringApp-football__publish__table__body__column__row__data__form__btn__icon" />
          </button>
        </form>
      ) : (
        <div
          className={
            "ScoringApp-football__publish__table__body__column__row__data__value " +
            (value ? "bold" : "light")
          }
          onClick={() => {
            setEditing(true);
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
}

function StatTable({
  organization,
  score,
  rows,
  statTypes,
  setRows,
  setScore,
  completed,
}) {
  const [editingScore, setEditingScore] = useState(false);
  return (
    <div className="ScoringApp-football__publish__table">
      <div className="ScoringApp-football__publish__table__header">
        <div className="ScoringApp-football__publish__table__header__title">
          <div className="ScoringApp-football__publish__table__header__title__logo"></div>

          <div className="ScoringApp-football__publish__table__header__title__text">
            {organization.name}
          </div>
        </div>

        {editingScore ? (
          <EditScore
            score={score}
            dismiss={() => {
              setEditingScore(false);
            }}
            onUpdate={(val) => {
              setScore(val);
            }}
          />
        ) : (
          <div className="ScoringApp-football__publish__table__header__value">
            <div className="ScoringApp-football__publish__table__header__value__score">
              {score}
            </div>
            <div
              className="ScoringApp-football__publish__table__header__value__option"
              onClick={() => {
                setEditingScore(true);
              }}
            >
              <PencilIcon className="ScoringApp-football__publish__table__header__value__option__icon" />
            </div>
          </div>
        )}
      </div>
      <div className="ScoringApp-football__publish__table__body">
        {completed && (
          <div className="ScoringApp-football__publish__table__body__overlay">
            <div className="ScoringApp-football__publish__table__body__overlay__text">
              This fixture has already been published, and stats are locked for
              editing. You can still update the final score of the match.
            </div>
          </div>
        )}
        <div className="ScoringApp-football__publish__table__body__column players">
          <div className="ScoringApp-football__publish__table__body__column__header">
            <div className="ScoringApp-football__publish__table__body__column__row">
              <div className="ScoringApp-football__publish__table__body__column__row__data">
                <div className="ScoringApp-football__publish__table__body__column__row__data__value">
                  Player
                </div>
              </div>
            </div>
          </div>
          {rows.map((row) => (
            <div className="ScoringApp-football__publish__table__body__column__row">
              <div
                className={
                  "ScoringApp-football__publish__table__body__column__row__data " +
                  (row.isInLineup ? "highlight" : "")
                }
              >
                <div className="ScoringApp-football__publish__table__body__column__row__data__value">
                  {row.profile.name}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="ScoringApp-football__publish__table__body__column stats">
          <div className="ScoringApp-football__publish__table__body__column__header">
            <div className="ScoringApp-football__publish__table__body__column__row">
              {statTypes
                .filter(
                  (st) =>
                    ![
                      "Penalty Shootout Goal",
                      "Penalty Shootout Miss",
                    ].includes(st.name)
                )
                .map((st) => (
                  <div className="ScoringApp-football__publish__table__body__column__row__data">
                    <div className="ScoringApp-football__publish__table__body__column__row__data__value">
                      {st.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {rows.map((row) => (
            <div className="ScoringApp-football__publish__table__body__column__row">
              {statTypes
                .filter(
                  (st) =>
                    ![
                      "Penalty Shootout Goal",
                      "Penalty Shootout Miss",
                    ].includes(st.name)
                )
                .map((st) => (
                  <Cell
                    value={row.stats[st.id]}
                    update={(val) => {
                      setRows((prev) => {
                        let newStatTable = [...prev];

                        const rowIndex = prev.findIndex(
                          (x) => x.profile.id === row.profile.id
                        );
                        // console.log(rowIndex);
                        // console.log(newStatTable[rowIndex]);

                        newStatTable[rowIndex] = {
                          ...prev[rowIndex],
                          stats: {
                            ...prev[rowIndex].stats,
                            [st.id]: Number(val) || 0,
                          },
                        };

                        return newStatTable;
                      });
                    }}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function PublishTables({ id, setStep, summary, loading, completed }) {
  const history = useHistory();
  const [overlay, setOverlay] = useState(false);
  const [rows, setRows] = useState(summary?.items || []);
  const [homeScore, setHomeScore] = useState(summary?.homeScore || 0);
  const [awayScore, setAwayScore] = useState(summary?.awayScore || 0);
  const homeTeam = summary?.homeParticipant;
  const awayTeam = summary?.awayParticipant;
  // const homeScore = summary?.homeScore;
  // const awayScore = summary?.awayScore;
  const statTypes = summary?.statTypes || [];
  // const items = summary?.items || [];

  const hasPenaltyShootout = summary?.hasPenaltyShootout;
  const homePenalties = summary?.homePenalties;
  const awayPenalties = summary?.awayPenalties;

  return (
    <div className="ScoringApp-football__publish">
      <div className="ScoringApp-football__header">
        <div className="ScoringApp-football__header__nav-left">
          {completed ? (
            <div
              className="ScoringApp-football__header__nav-left__back-btn"
              onClick={() => {
                history.replace("/app/fixture/" + id);
                // setStep(1);
              }}
            >
              <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
              <div className="ScoringApp-football__header__nav-left__back-btn__text">
                Back to fixture
              </div>
            </div>
          ) : (
            <div
              className="ScoringApp-football__header__nav-left__back-btn"
              onClick={() => {
                setStep(1);
              }}
            >
              <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
              <div className="ScoringApp-football__header__nav-left__back-btn__text">
                Back to collect stats
              </div>
            </div>
          )}
        </div>

        <div className="ScoringApp-football__header__main">
          Verify Fixture Stats & Result
        </div>

        <div className="ScoringApp-football__header__nav-right">
          <Button
            className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
            onClick={() => {
              // setStep(1);
              setOverlay(
                <PublishStatsConfirm
                  fixtureId={id}
                  completed={completed}
                  dismiss={() => {
                    setOverlay(false);
                  }}
                  placements={[
                    {
                      organizationId: homeTeam?.organization?.id,
                      value: Number(homeScore) || 0,
                      ...(hasPenaltyShootout
                        ? {
                            valueString: `${homeScore} (${homePenalties || 0})`,
                          }
                        : {}),
                    },
                    {
                      organizationId: awayTeam?.organization?.id,
                      value: Number(awayScore) || 0,
                      ...(hasPenaltyShootout
                        ? {
                            valueString: `${awayScore} (${awayPenalties || 0})`,
                          }
                        : {}),
                    },
                  ]}
                  tables={rows.reduce((accum, curr) => {
                    for (let statTypeId in curr.stats) {
                      if (curr.stats[statTypeId]) {
                        accum.push({
                          profileId: curr.profile.id,
                          organizationId: curr.organization.id,
                          statTypeId,
                          value: Number(curr.stats[statTypeId] || 0),
                        });
                      }
                    }

                    return accum;
                  }, [])}
                />
              );
            }}
          >
            {completed ? "Update final score" : "Publish this fixture"}
          </Button>
          {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
        </div>
      </div>

      <div
        className="ScoringApp-football__body"
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div className="ScoringApp-football__publish__grid">
            <div className="ScoringApp-football__publish__grid__container">
              <StatTable
                organization={homeTeam?.organization}
                rows={rows.filter(
                  (r) => r.organization?.id === homeTeam?.organization?.id
                )}
                score={homeScore}
                statTypes={statTypes}
                setRows={setRows}
                setScore={setHomeScore}
                completed={completed}
              />
            </div>

            <div className="ScoringApp-football__publish__grid__container">
              <StatTable
                organization={awayTeam?.organization}
                rows={rows.filter(
                  (r) => r.organization?.id === awayTeam?.organization?.id
                )}
                score={awayScore}
                statTypes={statTypes}
                setRows={setRows}
                setScore={setAwayScore}
                completed={completed}
              />
            </div>
          </div>
        )}
      </div>

      {!!overlay && (
        <div className="ScoringApp-football__scoring__overlay">{overlay}</div>
      )}
    </div>
  );
}

export default function Publish({ id, setStep, completed }) {
  console.log(completed);
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
        publishStatsSummary{
          statTypes{
            id
            name
          }
          homeScore
          awayScore
          hasPenaltyShootout
          homePenalties
          awayPenalties
          homeParticipant{
            id            
            organization{
              id
              name
              profilePhoto{
                id
                filename
              }
            }            
          }
          awayParticipant{
            id            
            organization{
              id
              name
              profilePhoto{
                id
                filename
              }
            }
          }
          items{
            isInLineup
            profile{
              id
              name
            }
            organization{
              id
              name
            }
            stats
          }
        }
      `,
    }),
    { variables: { id }, fetchPolicy: "network-only" }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const summary = data?.fixture?.publishStatsSummary;

  // console.log(summary);

  if (loading || !data) {
    return (
      <div className="ScoringApp-football__publish">
        <div className="ScoringApp-football__header">
          <div className="ScoringApp-football__header__nav-left">
            {completed ? (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  // setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to fixture
                </div>
              </div>
            ) : (
              <div
                className="ScoringApp-football__header__nav-left__back-btn"
                onClick={() => {
                  setStep(1);
                }}
              >
                <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
                <div className="ScoringApp-football__header__nav-left__back-btn__text">
                  Back to collect stats
                </div>
              </div>
            )}
          </div>

          <div className="ScoringApp-football__header__main">
            Verify Fixture Stats & Result
          </div>

          <div className="ScoringApp-football__header__nav-right">
            <Button
              className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
              disabled={true}
            >
              Publish this fixture
            </Button>
            {/* <div className="ScoringApp-football__header__nav-right__nav-btn">
            <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
          </div>
          <div className="ScoringApp-football__header__nav-right__nav-btn">
            Verify & Publish
          </div> */}
          </div>
        </div>

        <div
          className="ScoringApp-football__body"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <PublishTables
        id={id}
        setStep={setStep}
        summary={summary}
        loading={loading || !data}
        completed={completed}
      />
    );
  }
}
