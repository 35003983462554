import { gql } from "@apollo/client";

const FOLLOWERS = gql`
  query followersQuery($id: ID!, $take: Int, $cursor: ID) {
    followers(id: $id, take: $take, cursor: $cursor) {
      id
      profile {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default FOLLOWERS;
