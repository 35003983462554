// V1
import "./DirectoryCard.css";
import Card from "../../Card";
import Placeholder from "../../Placeholder";
import { ReactComponent as DirectoryIcon } from "../../../icons/directory.svg";
import { useQuery } from "@apollo/client";
import { PAGES } from "../../../api/profile";
import { USER_PROFILE } from "../../../api/user";
import { Link, useHistory } from "react-router-dom";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";

function UserPages({ id }) {
  const history = useHistory();
  const { loading, error, data } = useQuery(PAGES, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "DirectoryCard.UserPages",
        query: "PAGES",
      },
    });
    console.log(error);
    // return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="user-pages">
        <div className="user-pages-title">
          <Placeholder style={{ width: "60px" }} pulse={true} />
        </div>
        <div className="user-page">
          <div className={"image "}></div>
          <div className="meta">
            <div className="primary">
              <Placeholder style={{ width: "100px" }} pulse={true} />
            </div>
            <div className="secondary">
              <Placeholder style={{ width: "50px" }} pulse={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const pages = data.pages || [];

  return (
    <div className="user-pages">
      {!!pages?.length && <div className="user-pages-title">Your Pages</div>}
      {pages.map((page) => (
        <Link
          key={page.id}
          to={"/app/page/" + page.organization.id}
          className="user-page"
        >
          <div
            className={
              "image " + (page.organization.profilePhoto ? "black" : "")
            }
          >
            {page.organization.profilePhoto && (
              <Avatar media={page.organization.profilePhoto} />
            )}
          </div>
          <div className="meta">
            <div className="primary">{page.organization.name}</div>
            <div className="secondary">
              {page.isAdmin ? "Admin" : page.role}
            </div>
          </div>
        </Link>
      ))}
      {!pages?.length && (
        <div className="no-data">
          <div>You have no pages yet.</div>
          {/* <div>Let's change that.</div> */}
          <div
            className="link hover-pointer hover-underline"
            onClick={() => {
              history.push("/app/create_page");
            }}
          >
            Add a page to the directory
          </div>
        </div>
      )}
    </div>
  );
}

function DirectoryCard() {
  const { error, data } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "DirectoryCard",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    // return <div>Error</div>;
  }

  const profileId = data?.userProfile?.id;

  return (
    <Card className="directory-card">
      <div className="header">
        <DirectoryIcon style={{ height: 32, stroke: "var(--blue-lighter)" }} />
        <div className="text">Directory</div>
      </div>
      <div className="info">
        <p>
          Discover teams, venues, academies and tons of other sports related
          businesses and organizations near you with the Sports Paddock
          Directory.
        </p>
      </div>
      {profileId && <UserPages id={profileId} />}
    </Card>
  );
}

export default DirectoryCard;
