import "./Pitch.css";
import { useRef, useEffect, useState, Fragment } from "react";
import { ReactComponent as ProfileIcon } from "../../icons/profile.svg";
import Avatar from "../Avatar";

function formationName(profile) {
  if (!profile) return "";
  if (profile.lastname?.length > 3) {
    return profile.lastname;
  }

  return profile.firstname;
}

function Pitch({
  markers = [],
  markerOnClick,
  coordinates,
  updatePosition,
  lockEditing,
}) {
  const [width, setWidth] = useState(0);
  //   console.log("Coordinates:", coordinates);
  // 3:2 pitch dimensions
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef?.current?.offsetWidth) {
      setWidth(containerRef.current?.offsetWidth);
    }
  }, [containerRef]);
  const height = (width * 3) / 2;
  const totalBands = 11;
  const bandWidth = width / totalBands;
  const horizontalBands = new Array(totalBands).fill(0);
  const backgroundColor = "var(--green-main)";
  const verticalBandsLength = Math.ceil(height / bandWidth);
  const verticalBands = new Array(verticalBandsLength || 1).fill(0);
  const lineWidth = 2;
  const pitchPadding = 0.02 * width;
  const markerDiameter = Math.floor(width * 0.1);
  const lines = [
    {
      // TOP LINE
      height: lineWidth,
      // width: "100%",
      position: "absolute",
      top: pitchPadding,
      left: pitchPadding,
      right: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // BOTTOM LINE
    {
      height: lineWidth,
      // width: "100%",
      position: "absolute",
      bottom: pitchPadding,
      left: pitchPadding,
      right: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // LEFT LINE
    {
      // height: lineWidth,
      width: lineWidth,
      position: "absolute",
      top: pitchPadding,
      left: pitchPadding,
      bottom: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // RIGHT LINE
    {
      // height: lineWidth,
      width: lineWidth,
      position: "absolute",
      top: pitchPadding,
      right: pitchPadding,
      bottom: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // CENTER LINE
    {
      height: lineWidth,
      // width: lineWidth,
      position: "absolute",
      top: height / 2 - lineWidth / 2,
      left: pitchPadding,
      right: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // CENTER CIRCLE
    {
      height: 0.2 * width,
      width: 0.2 * width,
      position: "absolute",
      top: height / 2 - (0.2 * width) / 2,
      left: width / 2 - (0.2 * width) / 2,
      // background: "rgba(255,255,255,0.8)",
      border: `solid ${lineWidth}px rgba(255,255,255,0.8)`,
      borderRadius: "100%",
    },
    // BOX TOP LEFT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX TOP RIGHT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      right: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX TOP BOTTOM
    {
      height: lineWidth,
      width: 0.6 * width + lineWidth,
      position: "absolute",
      top: 0.2 * height + lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX TOP LEFT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX TOP RIGHT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      right: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX TOP BOTTOM
    {
      height: lineWidth,
      width: 0.4 * width + lineWidth,
      position: "absolute",
      top: 0.1 * height + lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },

    // BOX BOTTOM LEFT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX BOTTOM RIGHT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      right: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX BOTTOM BOTTOM
    {
      height: lineWidth,
      width: 0.6 * width + lineWidth,
      position: "absolute",
      bottom: 0.2 * height + lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX BOTTOM LEFT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX BOTTOM RIGHT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      right: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX BOTTOM BOTTOM
    {
      height: lineWidth,
      width: 0.4 * width + lineWidth,
      position: "absolute",
      bottom: 0.1 * height + lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
  ];

  // console.log("Markers:", markers);

  return (
    <div
      className="fp-container"
      style={{ height, position: "relative" }}
      ref={containerRef}
    >
      <div
        className="fp-background"
        style={{
          width: "100%",
          height,
          background: backgroundColor,
          position: "absolute",
          top: 0,
          left: 0,
          overflow: "hidden",
          // zIndex: -1,
        }}
      >
        {horizontalBands.map((b, i) => (
          <div
            style={{
              height: "100%",
              width: bandWidth + "px",
              position: "absolute",
              top: 0,
              left: i * bandWidth,
              background: `rgba(255, 255, 255, ${i % 2 ? 0 : 0.1})`,
            }}
          ></div>
        ))}

        {verticalBands.map((b, i) => (
          <div
            style={{
              height: bandWidth + "px",
              width: "100%",
              position: "absolute",
              top: i * bandWidth,
              left: 0,
              background: `rgba(255, 255, 255, ${i % 2 ? 0 : 0.1})`,
            }}
          ></div>
        ))}

        {lines.map((line) => (
          <div style={line}></div>
        ))}
      </div>

      {markers.map((m, i) => (
        <div
          className={"marker " + (markerOnClick ? "hover-pointer" : "")}
          style={{
            position: "absolute",
            top: m.y * width - markerDiameter,
            left: m.x * width - markerDiameter,
            height: markerDiameter,
            width: markerDiameter,
            borderRadius: "100%",
            background: "var(--blue-main)",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontWeight: 600,
          }}
          onClick={() => {
            markerOnClick && markerOnClick(m);
          }}
        >
          {m.role?.profile ? (
            <Fragment>
              {m.role.profile.profilePhoto ? (
                <div
                  className="marker-img-wrapper"
                  style={{
                    height: markerDiameter,
                    width: markerDiameter,
                    borderRadius: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Avatar media={m.role.profile.profilePhoto} />
                </div>
              ) : (
                <ProfileIcon
                  className="marker-icon"
                  style={{ height: "14px", stroke: "var(--light-1)" }}
                />
              )}
              <div
                className="secondary-label"
                style={{ position: "absolute", top: markerDiameter + 4 }}
              >
                {formationName(m.role.profile)}
              </div>
            </Fragment>
          ) : (
            m.label
          )}

          {/* {m.label}
          {m.secondaryLabel && (
            <div
              className="secondary-label"
              style={{ position: "absolute", top: markerDiameter + 4 }}
            >
              {m.secondaryLabel}
            </div>
          )} */}
        </div>
      ))}
    </div>
  );
}

export default Pitch;
