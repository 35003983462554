import { gql } from "@apollo/client";

const DELETE_AWARD_TYPE = gql`
  mutation deleteAwardType($id: ID!) {
    deleteAwardType(id: $id) {
      id
    }
  }
`;

export default DELETE_AWARD_TYPE;
