// V1
import "./OrganizationOverview.css";
import Card from "../../components/Card";
import Activity from "../../components/Activity";
import { useParams } from "react-router-dom";
import OrganizationGallerySummary from "./OrganizationGallerySummary";
import OrganizationMembers from "./OrganizationMembers";
import OrganizationPostsSummary from "./OrganizationPostsSummary";
import LatestEvent from "./LatestEvent";
import OrganizationDescription from "./OrganizationDescription";
import OrganizationServicesSummary from "./OrganizationServicesSummary";

function OrganizationOverview({
  setSelectedTabIndex,
  canManagePage,
  organization,
}) {
  const { id } = useParams();
  return (
    <div className="organization-overview">
      <div className="left-column">
        <OrganizationDescription id={id} canManagePage={canManagePage} />

        <Card className="profile-activity-card">
          <Activity />
        </Card>

        <OrganizationGallerySummary
          id={id}
          setSelectedTabIndex={setSelectedTabIndex}
          organization={organization}
        />

        <OrganizationPostsSummary id={id} />
      </div>

      <div className="right-column">
        <OrganizationMembers id={id} canManagePage={canManagePage} />

        <LatestEvent id={id} />

        <OrganizationServicesSummary
          id={id}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>
    </div>
  );
}

export default OrganizationOverview;
