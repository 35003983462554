import { gql } from "@apollo/client";

const PROFILE_SEARCH = gql`
  query search($term: String!) {
    search(term: $term) {
      profiles {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default PROFILE_SEARCH;
