import "./OrganizationPostsSummary.css";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_POSTS } from "../../../api/organization";
import Post from "../../Post";
import Button from "../../Button";
import { useHistory, useLocation } from "react-router-dom";
import captureException from "../../../utils/captureException";

function OrganizationPostsSummary({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { error, data } = useQuery(ORGANIZATION_POSTS, {
    variables: { id, take: 3 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationPostsSummary",
        query: "ORGANIZATION_POSTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  const org = data?.organization;
  const posts = org?.posts;

  //   console.log(posts);
  return (
    <div className="organization-overview-posts">
      {posts?.slice(0, 3).map((post) => (
        <Post key={post.id} post={post} />
      ))}

      {!!posts?.length && (
        <div className="show-more-container">
          <Button
            className="primary small blue show-more-button"
            onClick={() => {
              history.replace(location.pathname + "?t=posts");
            }}
          >
            See all posts
          </Button>
        </div>
      )}
    </div>
  );
}

export default OrganizationPostsSummary;
