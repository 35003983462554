import "./Toggle.css";
import { useState } from "react";

function Toggle({ label, defaultValue, onToggle = () => {} }) {
  const [selected, setSelected] = useState(defaultValue);

  return (
    <div
      className="toggle-wrapper"
      onClick={() => {
        const newValue = !selected;
        setSelected(newValue);
        onToggle(newValue, setSelected);
      }}
    >
      <div className={"app-toggle " + (selected ? "selected" : "")}>
        <div className="toggle-handle" />
      </div>
      {label && <div className="toggle-label">{label}</div>}
    </div>
  );
}

export default Toggle;
