import { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";

function PieChart({
  value,
  total,
  colors = ["#a1f291", "#dae2e7"],
  cutout = "80%",
}) {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const chartConfig = {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [value, total],
          backgroundColor: colors,
          borderColor: ["transparent", "transparent"],
          hoverOffset: 4,
        },
      ],
    },
    options: {
      // segmentShowStroke: false,
      legend: { display: false },
      cutout,
      hover: { mode: null },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }

      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  return <canvas style={{ zIndex: 1 }} ref={chartContainer} />;
}

export default PieChart;
