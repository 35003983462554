export default function makeStatVars({
  formDatum,
  fixtureId,
  eventId,
  timestamp,
  periodId,
  statTypes,
}) {
  const statType = statTypes.find((st) => st.name === formDatum.label);
  const parents = statType.relationshipsTo.filter(
    (r) => r.type.name === "Ancestor"
  );

  console.log(statType);
  console.log(parents);

  return {
    event: { connect: { id: eventId } },
    timeStamp: Number(timestamp),
    organization: { connect: { id: formDatum.data.organization.id } },
    profile: { connect: { id: formDatum.data.profile.id } },
    fixture: { connect: { id: fixtureId } },
    sport: { connect: { name: "Football" } },
    fixturePeriod: { connect: { id: periodId } },
    statType: { connect: { id: statType.id } },
    ...(parents?.length
      ? {
          relationshipsTo: {
            create: parents.map((p) => ({
              relationship: { connect: { id: p.id } },
              from: {
                create: makeStatVars({
                  formDatum: { label: p.from.name, data: formDatum.data },
                  fixtureId,
                  eventId,
                  timestamp,
                  periodId,
                  statTypes,
                }),
              },
            })),
          },
        }
      : {}),
  };
}
