import { Link } from "react-router-dom";
import "./AppLink.css";

export default function AppLink({ to, children }) {
  // const history = useHistory();
  if (!to) {
    return children;
  }

  return (
    <Link to={to} className="app-link">
      {children}
    </Link>
  );
}
