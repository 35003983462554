import Button from "../../../Button";
import { useMutation } from "@apollo/client";
import { UPDATE_FIXTURE } from "../../../../api/fixture";
import ballsCompleted from "../utils/ballsCompleted";
import NewDropdown from "../../../NewDropdown";
import Card from "../../../Card";
import { ReactComponent as ChevronDownIcon } from "../../../../icons/chevron-down.svg";
import captureException from "../../../../utils/captureException";

const extrasAbbrevs = {
  Wide: "W",
  "No Ball": "NB",
  Bye: "B",
  "Leg Bye": "LB",
};

export default function CurrentOver({
  currentOverNumber,
  setCurrentOverNumber,
  strikeIndex,
  setStrikeIndex,
  setOverlay,
  setBowler,
  fixtureId,
  cache,
  selectedPeriodId,
  battingTeam,
  bowlingTeam,
  batsmen,
  bowler,
  stats,
  groupedByOver,
}) {
  const [updateFixture] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.CurrentOver",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  const currOver = groupedByOver.find((g) => g.group === currentOverNumber);
  const currOverStats = currOver?.stats ? [...currOver.stats].reverse() : [];
  // console.log("CURR OVER;", currOver);
  // console.log("CURR OVER STATS:", currOverStats);

  const currentBallLabel = ballsCompleted({
    overNumber: currentOverNumber,
    groupedStats: groupedByOver,
  });

  const overNumbers = groupedByOver.length
    ? groupedByOver.map((g) => g.group)
    : [0];

  return (
    <div className="current-over">
      <NewDropdown
        target={
          <div className="label">
            <div className="secondary">CURRENT OVER</div>
            <div className="primary">
              {currentOverNumber + "." + currentBallLabel}
              <ChevronDownIcon className="icon" />
            </div>
          </div>
        }
        menu={({ setShowMenu }) => (
          <Card className="current-over__menu">
            {overNumbers.map((o) => (
              <div
                className="current-over__menu__item"
                onClick={() => {
                  setCurrentOverNumber(o);
                  setShowMenu(false);
                  updateFixture({
                    variables: {
                      where: { id: fixtureId },
                      data: {
                        statsCacheState: {
                          ...cache,
                          periods: {
                            [selectedPeriodId]: {
                              ...cache.periods[selectedPeriodId],
                              currentOverNumber: o,
                              // currentBallLabel: newBallLabel,
                              // currentOver: newOver,
                              // currentBallIndex: newBallIndex,
                            },
                          },
                        },
                      },
                    },
                  });
                }}
              >
                {o + 1}
              </div>
            ))}
          </Card>
        )}
        placement={"top-end"}
      />

      <div className="over-items">
        {/* {currentOver.map((o, i) => (
          <div className="over-item">
            <div
              className={
                "over-item-tag " +
                (i < currentBallIndex ? "completed " : "") +
                (o.extra?.length ? "extra " : "")
              }
            >
              {o.extra?.map((x) => extrasAbbrevs[x]).join("+") || ""}
            </div>
            <div
              className={
                "over-item-run " + (currentBallIndex == i ? "active" : "")
              }
            >
              {o.runs || ""}
            </div>

            <div className="over-item-wicket">
              {o.wicket?.toUpperCase() || ""}
            </div>
          </div>
        ))} */}
        {currOverStats.map((item, i) => (
          <div className="over-item" key={"current-over-item-" + item.id}>
            <div
              className={
                "over-item-tag completed " +
                // (i < currentBallIndex ? "completed " : "") +
                (item.extraStats?.length ? "extra " : "")
              }
            >
              {item.extraStats
                ?.map((x) => extrasAbbrevs[x.statType.name])
                .join("+") || ""}
            </div>
            <div className={"over-item-run "}>{item.runsScoredStat.value}</div>

            <div className="over-item-wicket">
              {item.wicketStat?.statType.name.toUpperCase() || ""}
            </div>
          </div>
        ))}
      </div>
      <Button
        className={
          "secondary medium blue " + (currOverStats?.length ? "" : "disabled")
        }
        onClick={() => {
          // const newOver = [{}, {}, {}, {}, {}, {}];
          // setCurrentOver(newOver);
          // const newBallIndex = 0;
          // setCurrentBallIndex(newBallIndex);
          // const newBallLabel = 1;
          // setCurrentBallLabel(newBallLabel);
          if (!currOverStats?.length) {
            return;
          }

          const newOverNumber = currentOverNumber + 1;
          setCurrentOverNumber(newOverNumber);

          const newStrikeIndex = strikeIndex ? 0 : 1;
          setStrikeIndex(newStrikeIndex);

          setOverlay({
            title: "Select Bowler",
            onSelect: (player) => {
              setBowler(player);

              updateFixture({
                variables: {
                  where: { id: fixtureId },
                  data: {
                    statsCacheState: {
                      ...cache,
                      periods: {
                        [selectedPeriodId]: {
                          battingTeam,
                          bowlingTeam,
                          batsmen,
                          bowler,
                          strikeIndex: newStrikeIndex,
                          currentOverNumber: newOverNumber,
                          // currentBallLabel: newBallLabel,
                          // currentOver: newOver,
                          // currentBallIndex: newBallIndex,
                        },
                      },
                    },
                  },
                },
              });
            },
            defaultTeam: bowlingTeam,
          });
        }}
      >
        Over Complete
      </Button>
    </div>
  );
}
