import "./Requests.css";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import Spinner from "../../components/Spinner";
import { useQuery } from "@apollo/client";
import { USER_REQUESTS } from "../../api/user";
import Notification from "../../components/Notification";
import { RequestCard } from "../../components/Home";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function RequestsListWrapper({ requests, loading }) {
  const location = useLocation();
  const history = useHistory();
  const [requestCount, setRequestCount] = useState(requests?.length || 0);

  //   if (!requestCount) {
  //     return <div />;
  //   }

  return (
    <div className="requests-list-mobile">
      <div className="requests-list-mobile__nav">
        <div
          className="requests-list-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app/directory");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>

        <div className="requests-list-mobile__nav__content">
          {/* <div className="requests-list-mobile__nav__content__img"></div> */}
          Your Requests
        </div>
        {requests?.length && (
          <div className="requests-list-mobile__nav__indicator">
            <Notification count={requestCount} />
          </div>
        )}
      </div>

      <div className="requests-list-mobile__body">
        {loading && <Spinner />}

        {!loading && !requestCount && (
          <div className="no-data">No pending requests found</div>
        )}

        {requests?.map((request, i) => (
          <RequestCard
            key={"request-" + i}
            request={request}
            setRequestCount={setRequestCount}
          />
        ))}
      </div>
    </div>
  );
}

export default function Requests() {
  usePageTracking({ title: "Requests" });
  const { loading, error, data } = useQuery(USER_REQUESTS, {
    fetchPolicy: "network-only",
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Requests",
        query: "USER_REQUESTS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const requests = data?.userRequests;

  return <RequestsListWrapper requests={requests} loading={loading || !data} />;
}
