import { gql } from "@apollo/client";

const CREATE_BROADCAST_SESSION = gql`
  mutation createBroadcastSession($fixtureId: ID!) {
    createBroadcastSession(fixtureId: $fixtureId) {
      id
    }
  }
`;

export default CREATE_BROADCAST_SESSION;
