import { useState, useEffect } from "react";
import "./VideoCardDescription.css";
import EditVideoCardDescription from "../EditVideoCardDescription";
import DeleteVideo from "../DeleteVideo";
import ReactQuill from "react-quill";

export default function VideoCardDescription({ media, onDelete }) {
  const [truncated, setTruncated] = useState(
    (media.contentLength || 0) > 50 ? true : false
  );

  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const onMentionClick = (e) => {
      e.stopImmediatePropagation();
      window.open(process.env.REACT_APP_ENDPOINT + e.value.link, "_blank");
    };
    window.addEventListener("mention-clicked", onMentionClick, false);

    return () => {
      window.removeEventListener("mention-clicked", onMentionClick, false);
    };
  });

  if (editing) {
    return (
      <div className="edit-video-desc-wrapper">
        <EditVideoCardDescription
          media={media}
          dismiss={() => {
            setEditing(false);
          }}
        />
      </div>
    );
  }

  if (deleting) {
    return (
      <div className="edit-video-desc-wrapper">
        <DeleteVideo
          media={media}
          dismiss={() => {
            setDeleting(false);
          }}
          onDelete={onDelete}
        />
      </div>
    );
  }

  return (
    <div className="video-info__desc">
      <ReactQuill
        id={"media-description-" + media.id}
        value={truncated ? media.truncatedDescription : media.description}
        readOnly={true}
        modules={{ toolbar: false }}
      />
      {truncated ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(false);
          }}
        >
          See more
        </div>
      ) : media.contentLength > 50 ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(true);
          }}
        >
          See less
        </div>
      ) : (
        false
      )}
      {media.canManageMedia && (
        <div
          className="edit-description-btn hover-pointer"
          onClick={() => {
            setEditing(true);
          }}
        >
          Edit Description
        </div>
      )}

      {media.canManageMedia && (
        <div
          className="delete-video-btn hover-pointer"
          onClick={() => {
            setDeleting(true);
          }}
        >
          Delete Video
        </div>
      )}
    </div>
  );
}
