import "./Profile.css";
import { useRef } from "react";
import {
  ProfileOverview,
  ProfilePosts,
  ProfilePhotos,
  ProfileVideos,
  // ProfileTimeline,
  ProfileStats,
  ProfileEvents,
  // ClaimProfile,
} from "../../mobileComponents/Profile";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import useRouterQuery from "../../utils/useRouterQuery";
import { PROFILE, INTERACTION_STATS, UPDATE_PROFILE } from "../../api/profile";
import { CAN_MANAGE_PROFILE } from "../../api/user";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import Placeholder from "../../components/Placeholder";
import FollowButton from "../../components/FollowButton";
import { useAppState } from "../../utils/appState";
import EditDisplayPhoto from "../../mobileComponents/EditDisplayPhoto";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import Avatar from "../../components/Avatar";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import NewDropdown from "../../components/NewDropdown/NewDropdown";
import Card from "../../components/Card";
import PhotoBrowser from "../../mobileComponents/PhotoBrowser";

const profileTabs = [
  { label: "Overview", component: ProfileOverview, key: "overview" },
  { label: "Events", component: ProfileEvents, key: "events" },
  { label: "Tagged Posts", component: ProfilePosts, key: "posts" },
  { label: "Photos", component: ProfilePhotos, key: "photos" },
  { label: "Videos", component: ProfileVideos, key: "videos" },
  // { label: "Timeline", component: ProfileTimeline, key: "timeline" },
  { label: "Statistics", component: ProfileStats, key: "statistics" },
];

function Profile() {
  usePageTracking({ title: "Profile" });
  const { id } = useParams();
  const [, setAppState] = useAppState();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const headerRef = useRef(null);
  const isOnScreen = useIntersectionObserver(headerRef, true);
  const tab = query.get("t") || "overview";
  const selectedTab = profileTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const authQuery = useQuery(CAN_MANAGE_PROFILE, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id },
  });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Profile",
        query: "CAN_MANAGE_PROFILE",
      },
    });
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Profile",
        query: "PROFILE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="profile-page-mobile">
        <div className="profile-page-mobile__nav">
          <div
            className="profile-page-mobile__nav__back-btn"
            onClick={() => {
              if (location.key) {
                history.goBack();
              } else {
                history.replace("/app");
              }
            }}
          >
            <ArrowLeftIcon
              style={{ height: "16px", stroke: "var(--dark-4)" }}
            />
          </div>
        </div>
        <div className="profile-page-mobile__cover" ref={headerRef}></div>

        <div className="profile-page-mobile__header">
          <div className="profile-page-mobile__header__image"></div>

          <div className="profile-page-mobile__header__desc">
            <div className="profile-page-mobile__header__desc__primary">
              <Placeholder
                style={{ height: "12px", width: "100px" }}
                pulse={true}
              />
            </div>

            <div className="profile-page-mobile__header__desc__secondary">
              <Placeholder
                style={{ height: "12px", width: "60px", marginTop: "8px" }}
                pulse={true}
              />
            </div>

            <Placeholder
              style={{
                height: "24px",
                width: "80px",
                background: "var(--blue-subtle)",
                borderRadius: "8px",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="profile-page-mobile__tabs">
          {[1, 2, 3].map((t, i) => (
            <div
              key={"org-tab-" + i}
              className={"profile-page-mobile__tabs__tab "}
            >
              <Placeholder
                style={{
                  height: "10px",
                  width: "40px",
                  background: "var(--light-2)",
                }}
                pulse={false}
              />
            </div>
          ))}
        </div>

        <div className="profile-page-mobile__body"></div>
      </div>
    );
  }

  const profile = data.profile;
  const canManageProfile = authQuery?.data?.canManageProfile?.authorized;

  return (
    <div className="profile-page-mobile">
      <div className="profile-page-mobile__nav">
        <div
          className="profile-page-mobile__nav__back-btn"
          onClick={() => {
            // console.log(history.length);
            // return;

            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
        {!isOnScreen && (
          <div className="profile-page-mobile__nav__content">
            <div className="profile-page-mobile__nav__content__img">
              {profile.profilePhoto && <Avatar media={profile.profilePhoto} />}
            </div>
            {profile.name}
          </div>
        )}

        {canManageProfile && (
          <NewDropdown
            wrapperClass="profile-page-mobile__nav__align-right"
            target={
              <div
                className="profile-page-mobile__nav__back-btn"
                onClick={() => {
                  // console.log(history.length);
                  // return;
                }}
              >
                <EllipsisIcon
                  style={{
                    height: "16px",
                    fill: "var(--dark-4)",
                    transform: "rotate(90deg)",
                  }}
                />
              </div>
            }
            menu={({ setShowMenu }) => (
              <Card className="account-button-menu">
                <div
                  className="account-button-menu-item"
                  onClick={() => {
                    history.push("/logout");
                    setShowMenu(false);
                  }}
                >
                  {/* <div className="icon">
                    <LogoutIcon className="account-button-menu-icon" />
                  </div> */}
                  <div className="desc">Logout</div>
                </div>
              </Card>
            )}
          />
        )}
      </div>

      <div className="profile-page-mobile__cover" ref={headerRef}>
        <div
          className="profile-page-mobile__cover__pressable"
          onClick={() => {
            if (!profile?.coverPhoto) return;
            setAppState({
              modal: (
                <PhotoBrowser
                  query={PROFILE}
                  vars={{ id: profile.id }}
                  getPhotos={(data) => data.profile?.coverPhotos || []}
                  totalPhotos={(data) => data.profile?.coverPhotos?.length || 0}
                  media={profile.coverPhoto}
                />
              ),
            });
          }}
        >
          {profile?.coverPhoto && <Avatar media={profile.coverPhoto} />}
        </div>

        {canManageProfile && (
          <div
            className="profile-page-mobile__cover__edit-btn"
            onClick={(e) => {
              e.stopPropagation();
              setAppState({
                modal: (
                  <EditDisplayPhoto
                    config={{
                      wrapper: {
                        title: "Cover Photo",
                        query: PROFILE,
                        getPhotos: (data) => data?.profile?.coverPhotos || [],
                        getSelectedPhoto: (data) => data?.profile?.coverPhoto,
                        defaultUploadState: profile?.coverPhotos?.length
                          ? false
                          : true,
                        sourceId: profile.id,
                        sourceType: "profile",
                      },
                      select: {
                        mutation: UPDATE_PROFILE(`coverPhoto{id filename}`),
                        mutationVars: (connectOrDisconnectInput) => {
                          return {
                            coverPhoto: connectOrDisconnectInput,
                          };
                        },
                        update: (cache, { data: { updateProfile } }) => {
                          const q = cache.readQuery({
                            query: PROFILE,
                            variables: { id: profile.id },
                          });

                          cache.writeQuery({
                            query: PROFILE,
                            variables: { id: profile.id },
                            data: {
                              profile: {
                                ...q.profile,
                                coverPhoto: updateProfile.coverPhoto,
                              },
                            },
                          });

                          // setAppState({ modal: false });
                        },
                      },
                      upload: {
                        accept: ".jpg,.png,.jpeg",
                        multiple: false,
                        type: "IMAGE",
                        subtype: "COVER PHOTO",
                        onCreateMedia: (cache, createMedia) => {
                          const q = cache.readQuery({
                            query: PROFILE,
                            variables: { id: profile.id },
                          });

                          cache.writeQuery({
                            query: PROFILE,
                            variables: { id: profile.id },
                            data: {
                              profile: {
                                ...q.profile,
                                coverPhoto: {
                                  ...createMedia,
                                },
                                coverPhotos: [
                                  ...q.profile.coverPhotos,
                                  { ...createMedia },
                                ],
                              },
                            },
                          });

                          setAppState({ modal: false });
                        },
                        cropperWidth: 240,
                        cropperHeight: 40,
                      },
                    }}
                  />
                ),
              });
            }}
          >
            <PencilIcon className="icon" />
          </div>
        )}
      </div>

      <div className="profile-page-mobile__header">
        <div className="profile-page-mobile__header__image">
          <div
            className="profile-page-mobile__header__image__pressable"
            onClick={() => {
              if (!profile?.profilePhoto) return;
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={PROFILE}
                    vars={{ id: profile.id }}
                    getPhotos={(data) => data.profile?.profilePhotos || []}
                    totalPhotos={(data) =>
                      data.profile?.profilePhotos?.length || 0
                    }
                    media={profile.profilePhoto}
                  />
                ),
              });
            }}
          >
            {profile.profilePhoto && <Avatar media={profile.profilePhoto} />}
          </div>

          {canManageProfile && (
            <div
              className="profile-page-mobile__cover__edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setAppState({
                  modal: (
                    <EditDisplayPhoto
                      config={{
                        wrapper: {
                          title: "Profile Photo",
                          query: PROFILE,
                          getPhotos: (data) =>
                            data?.profile?.profilePhotos || [],
                          getSelectedPhoto: (data) =>
                            data?.profile?.profilePhoto,
                          defaultUploadState: profile?.profilePhotos?.length
                            ? false
                            : true,
                          sourceId: profile.id,
                          sourceType: "profile",
                        },
                        select: {
                          mutation: UPDATE_PROFILE(`profilePhoto{id filename}`),
                          mutationVars: (connectOrDisconnectInput) => {
                            return {
                              profilePhoto: connectOrDisconnectInput,
                            };
                          },
                          update: (cache, { data: { updateProfile } }) => {
                            const q = cache.readQuery({
                              query: PROFILE,
                              variables: { id: profile.id },
                            });

                            cache.writeQuery({
                              query: PROFILE,
                              variables: { id: profile.id },
                              data: {
                                profile: {
                                  ...q.profile,
                                  profilePhoto: updateProfile.profilePhoto,
                                },
                              },
                            });

                            // setAppState({ modal: false });
                          },
                        },
                        upload: {
                          multiple: false,
                          accept: ".jpg,.png,.jpeg",
                          type: "IMAGE",
                          subtype: "PROFILE PHOTO",
                          onCreateMedia: (cache, createMedia) => {
                            const q = cache.readQuery({
                              query: PROFILE,
                              variables: { id: profile.id },
                            });

                            cache.writeQuery({
                              query: PROFILE,
                              variables: { id: profile.id },
                              data: {
                                profile: {
                                  ...q.profile,
                                  profilePhoto: {
                                    ...createMedia,
                                  },
                                  profilePhotos: [
                                    ...q.profile.profilePhotos,
                                    { ...createMedia },
                                  ],
                                },
                              },
                            });

                            setAppState({ modal: false });
                          },
                          // cropperWidth: 240,
                          // cropperHeight: 40,
                        },
                      }}
                    />
                  ),
                });
              }}
            >
              <PencilIcon className="icon" />
            </div>
          )}
        </div>

        <div className="profile-page-mobile__header__desc">
          <div className="profile-page-mobile__header__desc__primary">
            {profile.name}
          </div>

          <div className="profile-page-mobile__header__desc__secondary">
            @{profile.handle}
          </div>

          <FollowButton
            id={id}
            type={"profile"}
            // onFollow={(cache) => {
            //   const q = cache.readQuery({
            //     query: INTERACTION_STATS,
            //     variables: { id },
            //   });

            //   if (q?.interactionStats) {
            //     cache.writeQuery({
            //       query: INTERACTION_STATS,
            //       variables: { id },
            //       data: {
            //         interactionStats: {
            //           ...q.interactionStats,
            //           followers: q.interactionStats.followers + 1,
            //         },
            //       },
            //     });
            //   }
            // }}
            onUnfollow={(cache, follow) => {
              if (follow.confirmed) {
                const q = cache.readQuery({
                  query: INTERACTION_STATS,
                  variables: { id },
                });

                if (q?.interactionStats) {
                  cache.writeQuery({
                    query: INTERACTION_STATS,
                    variables: { id },
                    data: {
                      interactionStats: {
                        ...q.interactionStats,
                        followers: q.interactionStats.followers - 1,
                      },
                    },
                  });
                }
              }
            }}
          />
        </div>
      </div>

      <div className="profile-page-mobile__tabs">
        {profileTabs.map((t, i) => (
          <div
            key={"profile-tab-" + i}
            className={
              "profile-page-mobile__tabs__tab " +
              (selectedTab.key === t.key ? "active" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>

      <div className="organization-page-mobile__body">
        <SelectedComponent
          id={id}
          profile={profile}
          canManageProfile={canManageProfile}
        />
      </div>
    </div>
  );
}

export default Profile;
