import Card from "../../../Card";
import RunContainer from "../RunContainer";

export default function Actions({
  fixtureId,
  eventId,
  selectedTag,
  setSelectedTag,
  selectedWicket,
  setSelectedWicket,
  setOverlay,
  setSelectedFielder,
  setBatsmenOverlay,
  setSelectedBatsman,
  batsmen,
  bowler,
  strikeIndex,
  statTypes,
  battingTeam,
  bowlingTeam,
  selectedFielder,
  selectedBatsman,
  currentOverNumber,
  currentBallIndex,
  onAddStat,
  selectedPeriodId,
  stats,
  groupedByOver,
}) {
  return (
    <div className="actions">
      <div className="tags">
        <Card
          className={"tag " + (selectedTag.includes("Wide") ? "active" : "")}
          onClick={() => {
            if (selectedTag.includes("Wide")) {
              setSelectedTag([]);
            } else {
              setSelectedTag(["Wide"]);
            }
          }}
        >
          WIDE
        </Card>
        <Card
          className={"tag " + (selectedTag.includes("No Ball") ? "active" : "")}
          onClick={() => {
            if (selectedTag.includes("No Ball")) {
              setSelectedTag([]);
            } else {
              setSelectedTag(["No Ball"]);
            }
          }}
        >
          NO BALL
        </Card>
        <Card
          className={"tag " + (selectedTag.includes("Bye") ? "active" : "")}
          onClick={() => {
            if (selectedTag.includes("Bye")) {
              if (selectedTag.includes("No Ball")) {
                setSelectedTag([...selectedTag].filter((t) => t !== "Bye"));
              } else {
                setSelectedTag([]);
              }
            } else {
              if (selectedTag.includes("No Ball")) {
                setSelectedTag(
                  [...selectedTag, "Bye"].filter((t) => t !== "Leg Bye")
                );
              } else {
                setSelectedTag(["Bye"]);
              }
            }
          }}
        >
          BYE
        </Card>
        <Card
          className={"tag " + (selectedTag.includes("Leg Bye") ? "active" : "")}
          onClick={() => {
            if (selectedTag.includes("Leg Bye")) {
              if (selectedTag.includes("No Ball")) {
                setSelectedTag([...selectedTag].filter((t) => t !== "Leg Bye"));
              } else {
                setSelectedTag([]);
              }
            } else {
              if (selectedTag.includes("No Ball")) {
                setSelectedTag(
                  [...selectedTag, "Leg Bye"].filter((t) => t !== "Bye")
                );
              } else {
                setSelectedTag(["Leg Bye"]);
              }
            }
          }}
        >
          LEG BYE
        </Card>
      </div>
      <div className="wickets">
        <Card
          className={"wicket " + (selectedWicket === "Bowled" ? "active" : "")}
          onClick={() => {
            if (selectedWicket === "Bowled") {
              setSelectedWicket("");
            } else {
              setSelectedWicket("Bowled");
            }
          }}
        >
          BOWLED
        </Card>
        <Card
          className={"wicket " + (selectedWicket === "LBW" ? "active" : "")}
          onClick={() => {
            if (selectedWicket === "LBW") {
              setSelectedWicket("");
            } else {
              setSelectedWicket("LBW");
            }
          }}
        >
          LBW
        </Card>
        <Card
          className={"wicket " + (selectedWicket === "Caught" ? "active" : "")}
          onClick={() => {
            if (selectedWicket === "Caught") {
              setSelectedWicket("");
            } else {
              setSelectedWicket("Caught");
              setOverlay({
                title: "Select Catcher",
                onSelect: (player) => {
                  setSelectedFielder(player.profile);
                },
              });
            }
          }}
        >
          CAUGHT
        </Card>
        <Card
          className={
            "wicket " + (selectedWicket === "Caught & Bowled" ? "active" : "")
          }
          onClick={() => {
            if (selectedWicket === "Caught & Bowled") {
              setSelectedWicket("");
            } else {
              setSelectedWicket("Caught & Bowled");
            }
          }}
        >
          {"C & B"}
        </Card>
        <Card
          className={"wicket " + (selectedWicket === "Stumped" ? "active" : "")}
          onClick={() => {
            if (selectedWicket === "Stumped") {
              setSelectedWicket("");
            } else {
              setSelectedWicket("Stumped");
              setOverlay({
                title: "Select Wicketkeeper",
                onSelect: (player) => {
                  setSelectedFielder(player.profile);
                },
              });
            }
          }}
        >
          STUMPED
        </Card>
        <Card
          className={"wicket " + (selectedWicket === "Run Out" ? "active" : "")}
          onClick={() => {
            if (selectedWicket === "Run Out") {
              setSelectedWicket("");
            } else {
              setSelectedWicket("Run Out");
              setOverlay({
                title: "Select Fielder",
                onSelect: (player) => {
                  setSelectedFielder(player.profile);
                  setBatsmenOverlay({
                    title: "Select batsmen who was dismissed",
                    onSelect: (b) => {
                      setSelectedBatsman(b);
                    },
                  });
                },
              });
            }
          }}
        >
          RUN OUT
        </Card>
        <Card
          className={"wicket " + (selectedWicket === "Retired" ? "active" : "")}
          onClick={() => {
            if (selectedWicket === "Retired") {
              setSelectedWicket("");
              setSelectedBatsman("");
            } else {
              setSelectedWicket("Retired");
              setBatsmenOverlay({
                title: "Select Retired Batsman",
                onSelect: (player) => {
                  setSelectedBatsman(player);
                },
              });
            }
          }}
        >
          RETIRED
        </Card>
      </div>
      <div className="runs">
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"0"}
          value={0}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(0);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"1"}
          value={1}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(1);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"2"}
          value={2}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(2);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"3"}
          value={3}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(3);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"4"}
          value={4}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(4);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"5"}
          value={5}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(5);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"6"}
          value={6}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(6);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
        <RunContainer
          fixtureId={fixtureId}
          eventId={eventId}
          label={"..."}
          value={0}
          batsman={batsmen[strikeIndex]}
          bowler={bowler}
          statTypes={statTypes}
          battingOrganization={battingTeam}
          bowlingOrganization={bowlingTeam}
          extra={selectedTag}
          wicket={selectedWicket}
          fielder={selectedFielder}
          dismissedBatsman={selectedBatsman}
          currentOverNumber={currentOverNumber}
          currentBallIndex={currentBallIndex}
          selectedPeriodId={selectedPeriodId}
          onAddStat={() => {
            onAddStat(0);
          }}
          stats={stats}
          groupedByOver={groupedByOver}
        />
      </div>
    </div>
  );
}
