import "./FootballStats.css";
import Card from "../../../Card";
import {
  ORGANIZATION_STATS,
  ORGANIZATION_AWARDS,
} from "../../../../api/organization";
import { useQuery } from "@apollo/client";
import ShotsSummary from "../../../ShotsSummary";
// import OrganizationFormStats from "../../../OrganizationFormStats";
// import OrganizationAwards from "../OrganizationAwards/OrganizationAwards";
import captureException from "../../../../utils/captureException";
import { Fragment } from "react";
import Square from "../../../../mobileComponents/Square";
import VerticalText from "../../../../mobileComponents/VerticalText";
import PieChart from "../../../PieChart";
import Avatar from "../../../Avatar";
import { Link } from "react-router-dom";
import moment from "moment";
import { ReactComponent as GoldMedalIcon } from "../../../../icons/medal.svg";
import { ReactComponent as SilverMedalIcon } from "../../../../icons/medal-silver.svg";
import { ReactComponent as BronzeMedalIcon } from "../../../../icons/medal-bronze.svg";

function OrganizationRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record">
      <div className="organization-record__meta">
        <div className="organization-record__meta__title">RECORD</div>

        <div className="organization-record__meta__highlight">
          <div className="organization-record__meta__highlight__value">
            {total}
          </div>
          <div className="organization-record__meta__highlight__label">
            Games Played
          </div>
        </div>
      </div>

      <div className="organization-record__items">
        <div
          className="organization-record__items__item"
          style={{ marginTop: "8px" }}
        >
          <div className="organization-record__items__item__label">Win</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ width: total ? (wins / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">{wins}</div>
        </div>

        <div
          className="organization-record__items__item"
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div className="organization-record__items__item__label">Loss</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ width: total ? (losses / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">
            {losses}
          </div>
        </div>

        <div
          className="organization-record__items__item"
          style={{ marginBottom: "8px" }}
        >
          <div className="organization-record__items__item__label">Draw</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ width: total ? (draws / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">{draws}</div>
        </div>
      </div>
    </div>
  );
}

function OrganizationAttackStats({
  gamesPlayed,
  gamesWon,
  goals,
  shots,
  corners,
}) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">ATTACK</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (goals / gamesPlayed) % 1
                    ? (goals / gamesPlayed).toFixed(1)
                    : goals / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Goals <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (shots / gamesPlayed) % 1
                    ? (shots / gamesPlayed).toFixed(1)
                    : shots / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Shots <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {assists}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Penalties
                <br /> Won
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {keyPasses}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Penalties <br /> Scored
              </div>
            </div>
          )}
        </Square> */}

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (corners / gamesPlayed) % 1
                    ? (corners / gamesPlayed).toFixed(1)
                    : corners / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Corners <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? Math.round((gamesWon / gamesPlayed) * 100) + "%"
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Win Rate <br /> (%)
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"ATTACK"}
              fontStyle={{
                fontSize: "48px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "36px",
                height: "48px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function OrganizationShootingStats({
  goals,
  totalShots,
  shotsOnTarget,
  shotsOffTarget,
}) {
  return (
    <div className="profile-shooting-stats">
      <div className="profile-shooting-stats__title">SHOOTING</div>

      <div className="profile-shooting-stats__items">
        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Total Shots
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {totalShots || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{ width: totalShots ? "100%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Shots On Target
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {shotsOnTarget || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: totalShots
                    ? (shotsOnTarget / totalShots) * 100 + "%"
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats__items__item">
          <div className="profile-shooting-stats__items__item__meta">
            <div className="profile-shooting-stats__items__item__meta__key">
              Shots Off Target
            </div>

            <div className="profile-shooting-stats__items__item__meta__value">
              {shotsOffTarget || 0}
            </div>
          </div>

          <div className="profile-shooting-stats__items__item__graph">
            <div className="profile-shooting-stats__items__item__graph__track">
              <div
                className="profile-shooting-stats__items__item__graph__fill"
                style={{
                  width: totalShots
                    ? (shotsOffTarget / totalShots) * 100 + "%"
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {!!totalShots && (
        <div className="profile-shooting-stats__doughnut">
          <div className="profile-shooting-stats__doughnut__bg">
            <PieChart
              value={shotsOnTarget}
              total={shotsOffTarget || 0}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={shotsOnTarget}
            total={shotsOffTarget || 0}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats__doughnut__meta">
            <div className="profile-shooting-stats__doughnut__meta__value">
              {Math.round((shotsOnTarget / totalShots) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats__doughnut__meta__key">
              Shot <br /> Accuracy
            </div>
          </div>
        </div>
      )}

      {!!totalShots && (
        <div className="profile-shooting-stats__doughnut">
          <div className="profile-shooting-stats__doughnut__bg">
            <PieChart
              value={goals}
              total={(totalShots || 0) - (goals || 0)}
              colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
            />
          </div>
          <PieChart
            value={goals}
            total={(totalShots || 0) - (goals || 0)}
            colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
          />
          <div className="profile-shooting-stats__doughnut__meta">
            <div className="profile-shooting-stats__doughnut__meta__value">
              {Math.round((goals / totalShots) * 100) + "%"}
            </div>

            <div className="profile-shooting-stats__doughnut__meta__key">
              Conversion <br /> Rate
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function OrganizationPassingStats({ successfulPasses, attemptedPasses }) {
  return (
    <div className="profile-passing-stats">
      <div className="profile-passing-stats__title">PASSING</div>
      <div className="profile-passing-stats__items">
        <div className="profile-passing-stats__items__item">
          <div className="profile-passing-stats__items__item__meta">
            <div className="profile-passing-stats__items__item__meta__key">
              Attempted Passes
            </div>

            <div className="profile-passing-stats__items__item__meta__value">
              {attemptedPasses || 0}
            </div>
          </div>

          <div className="profile-passing-stats__items__item__graph">
            <div className="profile-passing-stats__items__item__graph__track">
              <div
                className="profile-passing-stats__items__item__graph__fill"
                style={{
                  width: attemptedPasses
                    ? (successfulPasses / attemptedPasses) * 100 + "%"
                    : "1%",
                }}
              ></div>
            </div>
          </div>

          <div className="profile-passing-stats__items__item__meta">
            <div className="profile-passing-stats__items__item__meta__key">
              Successful Passes
            </div>

            <div className="profile-passing-stats__items__item__meta__value">
              {successfulPasses || 0}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-passing-stats__doughnut">
        <div className="profile-passing-stats__doughnut__bg">
          <PieChart
            value={successfulPasses || 0}
            total={
              attemptedPasses ? attemptedPasses - successfulPasses || 0 : 1
            }
            colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
          />
        </div>
        <PieChart
          value={successfulPasses || 0}
          total={attemptedPasses ? attemptedPasses - successfulPasses || 0 : 1}
          colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
        />
        <div className="profile-passing-stats__doughnut__meta">
          <div className="profile-passing-stats__doughnut__meta__value">
            {attemptedPasses
              ? Math.round((successfulPasses / attemptedPasses) * 100) + "%"
              : "-"}
          </div>

          <div className="profile-passing-stats__doughnut__meta__key">
            Pass <br /> Accuracy
          </div>
        </div>
      </div>

      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-passing-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"PASSING"}
              fontStyle={{
                fontSize: "64px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "49px",
                height: "64px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function OrganizationFormStats({ id, form }) {
  // const formFixtures = form.filter((f) =>
  //   f.roles.find((r) => r.organization.id !== id)
  // );

  // console.log("Form:", form);

  return (
    <div className="organization-form">
      <div className="organization-form__title">FORM</div>

      <div className="organization-form__items">
        {!form?.length && (
          <div className="no-data">No previous matches found</div>
        )}
        {form.map((fixture) => (
          <FormItem id={id} fixture={fixture} />
        ))}
      </div>
    </div>
  );
}

function FormItem({ id, fixture }) {
  const orgPlacement = fixture.placements?.find(
    (p) => p.organization.id === id
  );
  const opponentPlacement = fixture.placements?.find(
    (p) => p.organization.id !== id
  );

  const opponentRole = fixture.roles.find((r) => r.organization.id !== id);
  const result =
    (orgPlacement?.value || 0) > (opponentPlacement?.value || 0)
      ? "W"
      : (orgPlacement?.value || 0) < (opponentPlacement?.value || 0)
      ? "L"
      : "D";

  return (
    <div className="organization-form__items__item">
      <Link
        to={"/app/fixture/" + fixture.id}
        className="organization-form__items__item__meta"
      >
        <div className="organization-form__items__item__meta__image">
          {opponentRole?.organization?.profilePhoto && (
            <Avatar media={opponentRole.organization.profilePhoto} />
          )}
        </div>

        <div className="organization-form__items__item__meta__text">
          <div className="organization-form__items__item__meta__text__primary">
            {opponentRole?.organization?.name}
          </div>

          <div className="organization-form__items__item__meta__text__secondary">
            {fixture.scheduledStart
              ? moment(fixture.scheduledStart).format("Do MMM YYYY")
              : "-"}
          </div>
        </div>
      </Link>

      <div className="organization-form__items__item__result">
        <div
          className={
            "organization-form__items__item__result__text " +
            (result === "W" ? "green" : result === "L" ? "red" : "orange")
          }
        >
          {result === "W" ? "WIN" : result === "L" ? "LOSS" : "DRAW"}
        </div>

        <div className="organization-form__items__item__result__score">
          {orgPlacement?.value || 0} - {opponentPlacement?.value || 0}
        </div>
      </div>
    </div>
  );
}

function OrganizationDefenseStats({
  gamesPlayed,
  tackles,
  blocks,
  saves,
  ownGoals,
  redCards,
  yellowCards,
  fouls,
  offsides,
  interceptions,
  clearances,
}) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">DEFENSE</div>
      <div className="profile-attack-stats__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (tackles / gamesPlayed) % 1
                    ? (tackles / gamesPlayed).toFixed(1)
                    : tackles / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Tackles <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (blocks / gamesPlayed) % 1
                    ? (blocks / gamesPlayed).toFixed(1)
                    : blocks / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Blocks <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (saves / gamesPlayed) % 1
                    ? (saves / gamesPlayed).toFixed(1)
                    : saves / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Saves <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (ownGoals / gamesPlayed) % 1
                    ? (ownGoals / gamesPlayed).toFixed(1)
                    : ownGoals / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Own Goals <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (redCards / gamesPlayed) % 1
                    ? (redCards / gamesPlayed).toFixed(1)
                    : redCards / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Red Cards <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (yellowCards / gamesPlayed) % 1
                    ? (yellowCards / gamesPlayed).toFixed(1)
                    : yellowCards / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Yellow Cards <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (fouls / gamesPlayed) % 1
                    ? (fouls / gamesPlayed).toFixed(1)
                    : fouls / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Fouls <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (offsides / gamesPlayed) % 1
                    ? (offsides / gamesPlayed).toFixed(1)
                    : offsides / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Offsides <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (interceptions / gamesPlayed) % 1
                    ? (interceptions / gamesPlayed).toFixed(1)
                    : interceptions / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Interceptions <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {gamesPlayed
                  ? (clearances / gamesPlayed) % 1
                    ? (clearances / gamesPlayed).toFixed(1)
                    : clearances / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Clearances <br /> (per game)
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"DEFENSE"}
              fontStyle={{
                fontSize: "76px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "57px",
                height: "76px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function OrganizationAwards({ id, sport }) {
  const { loading, error, data } = useQuery(ORGANIZATION_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.FootballStats.OrganizationAwards",
        query: "ORGANIZATION_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const organization = data.organization;
  const placements = organization?.placements;
  // console.log(placements);
  const groupedPlacements = placements
    ?.filter((x) => !!x.awardType?.id)
    .reduce((accum, curr) => {
      if (!accum[curr.awardType.id]) {
        accum[curr.awardType.id] = {
          awardType: curr.awardType,
          event: curr.awardType.event,
          placements: [curr],
        };
      } else {
        accum[curr.awardType.id].placements.push(curr);
      }

      return accum;
    }, {});

  const eventPlacements = placements?.filter(
    (x) => x.event && !x.awardType && typeof x.order === "number"
  );
  const eventPlacementIcons = {
    0: <GoldMedalIcon style={{ height: "32px" }} />,
    1: <SilverMedalIcon style={{ height: "32px" }} />,
    2: <BronzeMedalIcon style={{ height: "32px", opacity: 0.8 }} />,
  };
  const eventPlacementLabels = {
    0: "Winner",
    1: "Runner Up",
    2: "Third Place",
  };

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats">
      <div className="profile-awards-stats__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats__items">
          <div className="no-data">No awards for this team yet.</div>
        </div>
      )}

      <div className="profile-awards-stats__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats__items__item">
              <div className="profile-awards-stats__items__item__img"></div>
              <div className="profile-awards-stats__items__item__desc">
                <Link
                  to={"/app/event/" + item.event.id}
                  className="profile-awards-stats__items__item__desc__primary"
                >
                  {item.event.name}
                </Link>
                <div className="profile-awards-stats__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}

        {eventPlacements.map((p) => (
          <div className="profile-awards-stats__items__item">
            <div className="profile-awards-stats__items__item__img">
              {eventPlacementIcons[p.order]}
            </div>
            <div className="profile-awards-stats__items__item__desc">
              <Link
                to={"/app/event/" + p.event.id}
                className="profile-awards-stats__items__item__desc__primary"
              >
                {p.event.name}
              </Link>
              <div className="profile-awards-stats__items__item__desc__secondary">
                {eventPlacementLabels[p.order]}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function FootballStats({ id, events }) {
  const { loading, error, data } = useQuery(ORGANIZATION_STATS, {
    variables: {
      id,
      sport: "Football",
      statTypes: [
        "Shot On Target",
        "Shot Off Target",
        "Goal",

        "Pass Completed",
        "Pass Attempted",
        "Corner",
        "Tackle",
        "Block",
        "Save",
        "Own Goal",
        "Red Card",
        "Yellow Card",
        "Foul",
        "Offside",
        "Interception",
        "Clearance",
      ],
      events,
      rolesWhereInput: { type: { name: "PARTICIPANT" } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.FootballStats",
        query: "ORGANIZATION_STATS",
      },
    });
    console.log(error);
  }

  if (loading) {
    return <div>Loading</div>;
  }

  // console.log("STATS:", data);

  const { totalGamesPlayed, form } = data?.organization?.stats || {};

  const record = JSON.parse(data?.organization?.stats?.record?.data || "{}");
  const aggregationsData = JSON.parse(
    data?.organization?.stats?.aggregations?.data || "{}"
  );

  return (
    <Fragment>
      <OrganizationRecordStats
        total={totalGamesPlayed}
        wins={record.wins}
        losses={record.losses}
        draws={record.draws}
      />

      <OrganizationAttackStats
        gamesPlayed={totalGamesPlayed}
        gamesWon={record.wins}
        goals={aggregationsData["Goal"] || 0}
        shots={
          (aggregationsData["Shot On Target"] || 0) +
          (aggregationsData["Shot Off Target"] || 0)
        }
        corners={aggregationsData["Corner"] || 0}
      />

      <OrganizationShootingStats
        goals={aggregationsData["Goal"] || 0}
        shotsOnTarget={aggregationsData["Shot On Target"] || 0}
        shotsOffTarget={aggregationsData["Shot Off Target"] || 0}
        totalShots={
          (aggregationsData["Shot Off Target"] || 0) +
          (aggregationsData["Shot On Target"] || 0)
        }
      />

      <div className="profile-stats-grid">
        <OrganizationPassingStats
          successfulPasses={aggregationsData["Pass Completed"] || 0}
          attemptedPasses={aggregationsData["Pass Attempted"] || 0}
        />
        <OrganizationFormStats id={id} form={form} />
      </div>

      <OrganizationDefenseStats
        gamesPlayed={totalGamesPlayed}
        tackles={aggregationsData["Tackle"] || 0}
        blocks={aggregationsData["Block"] || 0}
        saves={aggregationsData["Save"] || 0}
        ownGoals={aggregationsData["Own Goal"] || 0}
        redCards={aggregationsData["Red Card"] || 0}
        yellowCards={aggregationsData["Yellow Card"] || 0}
        fouls={aggregationsData["Foul"] || 0}
        offsides={aggregationsData["Offside"] || 0}
        interceptions={aggregationsData["Interception"] || 0}
        clearances={aggregationsData["Clearance"] || 0}
      />

      <OrganizationAwards id={id} sport={"Football"} />
    </Fragment>
  );
}

export default FootballStats;
