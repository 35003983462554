import "./PenaltyShootoutSettings.css";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import Spinner from "../../../components/Spinner";
import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/functions";

export default function PenaltyShootoutSettings({ id }) {
  const [mutate, { loading: updating }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
        periods {
          id
          label
          order
          startDate
          endDate
          duration
          metas {
            id
            label
            stringValue
          }
          canDelete
        }          
      `,
    }),
    {
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            periods() {
              return updateFixture.periods;
            },
          },
        });
      },
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.PenaltyShootoutSettings",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
        periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
        canDelete
      }        
    `,
    }),
    {
      variables: {
        id,
      },
    }
  );

  if (loading || !data) {
    return (
      <Card className="ScoringApp-football__settings__penalty-settings">
        <div className="ScoringApp-football__settings__penalty-settings__title">
          Penalty Shootout Settings
        </div>
        <div className="ScoringApp-football__settings__penalty-settings__body">
          <Spinner />
        </div>
      </Card>
    );
  }

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.PeriodSettings",
    //     query: "FIXTURE_STATS",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  const shootout = data?.fixture?.periods?.find((p) =>
    p.metas.find(
      (m) => m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
    )
  );

  return (
    <Card className="ScoringApp-football__settings__penalty-settings">
      <div className="ScoringApp-football__settings__penalty-settings__title">
        Penalty Shootout Settings
      </div>

      <div className="ScoringApp-football__settings__penalty-settings__body">
        {shootout ? (
          <div className="ScoringApp-football__settings__penalty-settings__body__content">
            <div className="ScoringApp-football__settings__penalty-settings__body__content__blurb">
              Penalty shootout has been activated.
            </div>

            <div className="ScoringApp-football__settings__penalty-settings__body__content__action">
              <Button
                className={
                  "small red primary " +
                  (!shootout.canDelete || updating ? "disabled" : "")
                }
                onClick={() => {
                  if (updating) return;
                  const vars = {};
                  vars.deleteMany = [{ id: shootout.id }];

                  mutate({
                    variables: {
                      where: { id },
                      data: {
                        periods: vars,
                      },
                    },
                  });
                }}
              >
                {updating ? <Loader theme={"small"} /> : "Deactivate"}
              </Button>
            </div>
          </div>
        ) : (
          <div className="ScoringApp-football__settings__penalty-settings__body__content">
            <div className="ScoringApp-football__settings__penalty-settings__body__content__blurb">
              Did this game go to penalties? Click the button to activate
              penalty shootouts.
            </div>

            <div className="ScoringApp-football__settings__penalty-settings__body__content__action">
              <Button
                className="small green primary"
                onClick={() => {
                  if (updating) return;
                  const vars = {};

                  vars.create = [
                    {
                      label: "Penalty Shootout",
                      order: 5,
                      duration: 0,
                      metas: {
                        create: [
                          {
                            label: "periodType",
                            stringValue: "PENALTY SHOOTOUT",
                          },
                        ],
                      },
                    },
                  ];

                  mutate({
                    variables: {
                      where: {
                        id,
                      },
                      data: {
                        periods: vars,
                      },
                    },
                  });
                }}
              >
                {updating ? <Loader theme={"small"} /> : "Activate"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
