import "./FootballStats.css";
import { useState, useRef, useEffect } from "react";
import { ReactComponent as FootballIcon } from "../../../../icons/football.svg";
import { ReactComponent as UpDown } from "../../../../icons/up-down.svg";
import { useQuery } from "@apollo/client";
import Spinner from "../../../../components/Spinner";
import NewDropdown from "../../../../components/NewDropdown";
import Card from "../../../../components/Card";
import { FIXTURE_STATS } from "../../../../api/fixture";
import Square from "../../../Square/Square";
import Avatar from "../../../../components/Avatar";
import captureException from "../../../../utils/captureException";
// import useIntersectionObserver from "../../../../utils/useIntersectionObserver";

const tableCells = [
  // {
  //   label: "Player",
  //   tooltip: "Player",
  //   width: "16%",
  //   style: { width: "16%" },
  //   data: (row) => row.profile.name,
  //   onClick: (row) => "/app/profile/" + row.profile.id,
  // },
  {
    label: "Minutes",
    groups: ["Attack"],
    tooltip: "Minutes Played",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Minutes Played"] || 0,
  },
  {
    label: "Goal",
    groups: ["Attack"],
    tooltip: "Goal",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Goal"] || 0,
  },
  {
    label: "Assist",
    groups: ["Passing"],
    tooltip: "Assist",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Assist"] || 0,
  },
  {
    label: "Attempted",
    groups: ["Passing"],
    tooltip: "Attempted Passes",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Pass Attempted"] || 0,
  },
  {
    label: "Successful",
    groups: ["Passing"],
    tooltip: "Successful Passes",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Pass Completed"] || 0,
  },
  {
    label: "Accuracy",
    groups: ["Passing"],
    tooltip: "Pass Accuracy",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) =>
      row["Pass Completed"]
        ? Math.round((row["Pass Completed"] / row["Pass Attempted"]) * 100) +
          "%"
        : 0,
  },
  {
    label: "Key Pass",
    groups: ["Attack"],
    tooltip: "Key Pass",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Key Pass"] || 0,
  },
  {
    label: "Shot",
    groups: ["Attack"],
    tooltip: "Total Shots",
    width: "10%",
    style: { width: "12.5%", textAlign: "right" },
    data: (row) => (row["Shot On Target"] || 0) + (row["Shot Off Target"] || 0),
  },
  {
    label: "Shot on Target",
    groups: ["Attack"],
    tooltip: "Shot on Target",
    width: "20%",
    style: { width: "17.5%", textAlign: "right" },
    data: (row) => row["Shot On Target"] || 0,
  },

  {
    label: "Tackle",
    groups: ["Defense"],
    tooltip: "Tackle",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Tackle"] || 0,
  },
  {
    label: "Block",
    groups: ["Defense"],
    tooltip: "Block",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Block"] || 0,
  },
  {
    label: "Own Goal",
    groups: ["Defense"],
    tooltip: "Own Goal",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Own Goal"] || 0,
  },
  {
    label: "Interception",
    groups: ["Defense"],
    tooltip: "Interception",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Interception"] || 0,
  },
  {
    label: "Clearance",
    groups: ["Defense"],
    tooltip: "Clearance",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Clearance"] || 0,
  },
  {
    label: "Save",
    groups: ["Goalkeeping"],
    tooltip: "Save",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Save"] || 0,
  },
  {
    label: "Clean Sheet",
    groups: ["Goalkeeping"],
    tooltip: "Clean Sheet",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Clean Sheet"] || 0,
  },
  {
    label: "Offside",
    groups: ["Discipline"],
    tooltip: "Offside",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Offside"] || 0,
  },
  {
    label: "Foul",
    groups: ["Discipline"],
    tooltip: "Foul",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Foul"] || 0,
  },
  {
    label: "Yellow Card",
    groups: ["Discipline"],
    tooltip: "Yellow Card",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Yellow Card"] || 0,
  },
  {
    label: "Red Card",
    groups: ["Discipline"],
    tooltip: "Red Card",
    width: "15%",
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Red Card"] || 0,
  },
];

function TableHeader({ label, style = {} }) {
  // const [scroll, setScroll] = useState(0);
  // const handleScroll = (e) => {
  //   console.log(e.target.scrollTop);
  //   setScroll(e.target.scrollTop);
  // };
  // useEffect(() => {
  //   console.log("Running effect for:", label);
  //   const element = document.getElementById("fixture-scroll-container");

  //   if (element) {
  //     element.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     element.removeEventListener("scroll", handleScroll);
  //   };
  // }, [label]);

  return (
    <Square>
      {({ squareRef, dimensions }) => {
        // console.log("Dimensions:", dimensions);
        return (
          <div
            className={"match-player-stats-mobile__table__header "}
            style={style}
            ref={squareRef}
          >
            {label}
          </div>
        );
      }}
    </Square>
  );
}

function PlayerStats({ fixture, homeTeam, awayTeam, homeLineup, awayLineup }) {
  const [isScrolled, setIsScrolled] = useState(0);
  const scrollRef = useRef(null);
  useEffect(() => {
    const div = scrollRef?.current;
    const handleScroll = () => {
      // console.log(div.scrollLeft);
      if (div.scrollLeft > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => div?.removeEventListener("scroll", handleScroll);
  }, [scrollRef]);
  const categories = [...fixture.event?.sport?.statTypeGroups].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const [selectedTeam, setSelectedTeam] = useState(homeTeam);
  const [selectedCategory, setSelectedCategory] = useState(categories?.[0]);
  const [sortField] = useState("name");
  const [sortOrder] = useState("asc");

  const rows = fixture.statTables;

  const teamRows = rows
    .filter((r) => r.organization?.id === selectedTeam.organization?.id)
    .reduce((accum, curr) => {
      const playerRowIndex = accum.findIndex(
        (x) => x.profile?.id === curr.profile?.id
      );
      if (playerRowIndex < 0) {
        accum.push({
          profile: curr.profile,
          [curr.statType.name]: curr.value || 0,
        });
      } else {
        accum[playerRowIndex][curr.statType.name] = curr.value || 0;
      }
      return accum;
    }, []);

  const playersWithNoStats = (
    selectedTeam.id === homeTeam.id ? homeLineup : awayLineup
  ).filter((l) => !teamRows.find((r) => r.profile?.id === l.profile?.id));

  const tableRows = [...teamRows, ...playersWithNoStats];

  const sortedTableRows = tableRows?.sort((a, b) => {
    if (sortField === "name") {
      if (sortOrder === "desc") {
        return b.profile?.name.localeCompare(a.profile?.name);
      } else {
        return a.profile?.name.localeCompare(b.profile?.name);
      }
    } else {
      const targetCell = tableCells.find((x) => x.label === sortField);
      if (sortOrder === "desc") {
        return (targetCell?.data(b) || 0) - (targetCell?.data(a) || 0);
      } else {
        return (targetCell?.data(a) || 0) - (targetCell?.data(b) || 0);
      }
    }
  });

  // console.log("Team rows:", sortedTableRows);

  return (
    <div className="match-player-stats-mobile">
      <div className="match-player-stats-mobile__header">
        <div className="match-player-stats-mobile__header__wrapper">
          <NewDropdown
            target={
              <div className="match-player-stats-mobile__header__team-selector">
                <div className="match-player-stats-mobile__header__team-selector__image">
                  {selectedTeam?.organization?.profilePhoto && (
                    <Avatar media={selectedTeam.organization.profilePhoto} />
                  )}
                </div>
                {selectedTeam?.organization?.name}
                <UpDown
                  style={{
                    fill: "var(--light-3)",
                    height: "10px",
                    marginLeft: "8px",
                  }}
                />
              </div>
            }
            menu={({ setShowMenu }) => (
              <Card className="fixture-stats-page__player-stats__header__team__menu">
                <div
                  className="fixture-stats-page__player-stats__header__team__menu__item"
                  onClick={() => {
                    setSelectedTeam(homeTeam);
                    setShowMenu(false);
                  }}
                >
                  {homeTeam?.organization?.name}
                </div>
                <div
                  className="fixture-stats-page__player-stats__header__team__menu__item"
                  onClick={() => {
                    setSelectedTeam(awayTeam);
                    setShowMenu(false);
                  }}
                >
                  {awayTeam?.organization?.name}
                </div>
              </Card>
            )}
          />

          <NewDropdown
            wrapperClass="fixture-stats-page__player-stats__header__category__wrapper"
            target={
              <div className="match-player-stats-mobile__header__category-selector">
                {selectedCategory?.name}
                <UpDown
                  style={{
                    fill: "var(--light-3)",
                    height: "10px",
                    marginLeft: "8px",
                  }}
                />
              </div>
            }
            menu={({ setShowMenu }) => (
              <Card className="fixture-stats-page__player-stats__header__category__menu">
                {categories
                  .filter((c) =>
                    [
                      "Attack",
                      "Passing",
                      "Defense",
                      "Discipline",
                      "Goalkeeping",
                    ].includes(c.name)
                  )
                  .map((category) => (
                    <div
                      className="fixture-stats-page__player-stats__header__category__menu__item"
                      onClick={() => {
                        setSelectedCategory(category);
                        setShowMenu(false);
                      }}
                    >
                      {category.name}
                    </div>
                  ))}
              </Card>
            )}
          />
        </div>
      </div>

      <div className="match-player-stats-mobile__table">
        <div
          className={
            "match-player-stats-mobile__table__fixed-columns " +
            (isScrolled ? "inset" : "")
          }
        >
          <div className="match-player-stats-mobile__table__column">
            <TableHeader
              label={"PLAYER"}
              style={{ fontWeight: "600", color: "var(--dark-1)" }}
            />
            {/* <div
              className="match-player-stats-mobile__table__header"
              style={{ fontWeight: "600", color: "var(--dark-1)" }}
            >
              PLAYER
            </div> */}
            <div className="match-player-stats-mobile__table__rows">
              {sortedTableRows?.map((row, i) => (
                <div className="match-player-stats-mobile__table__rows__row">
                  <div
                    className={
                      "match-player-stats-mobile__table__rows__row__td " +
                      (i % 2 ? "even" : "odd")
                    }
                    style={{ fontWeight: "600", color: "var(--dark-2)" }}
                  >
                    <div className="match-player-stats-mobile__table__rows__row__td__image">
                      {row.profile?.profilePhoto && (
                        <Avatar media={row.profile?.profilePhoto} />
                      )}
                    </div>

                    <div className="match-player-stats-mobile__table__rows__row__td__text">
                      {row.profile?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={
            "match-player-stats-mobile__table__scroll-columns " +
            (isScrolled ? "inset" : "")
          }
          ref={scrollRef}
        >
          {tableCells
            .filter((cell) => cell.groups.includes(selectedCategory.name))
            .map((cell) => (
              <div className="match-player-stats-mobile__table__column">
                <div className="match-player-stats-mobile__table__header">
                  {cell.label.toUpperCase()}{" "}
                </div>
                <div className="match-player-stats-mobile__table__rows">
                  {sortedTableRows?.map((row, i) => (
                    <div className="match-player-stats-mobile__table__rows__row">
                      <div
                        className={
                          "match-player-stats-mobile__table__rows__row__td " +
                          (i % 2 ? "even" : "odd")
                        }
                        style={{
                          color: "var(--dark-4)",
                          ...(cell.data && cell.data(row)
                            ? { fontWeight: "700" }
                            : { color: "var(--light-3)" }),
                        }}
                      >
                        <div className="match-player-stats-mobile__table__rows__row__td__text">
                          {cell.data ? cell.data(row) : ""}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function MatchStatsItem({
  homeValue,
  awayValue,
  label,
  homeGraphWidth,
  awayGraphWidth,
}) {
  return (
    <div className="match-stats-item-mobile">
      <div className="match-stats-item-mobile__meta">
        <div className="match-stats-item-mobile__meta__value home">
          {homeValue}
        </div>

        <div className="match-stats-item-mobile__meta__label">{label}</div>

        <div className="match-stats-item-mobile__meta__value away">
          {awayValue}
        </div>
      </div>

      <div className="match-stats-item-mobile__graph">
        <div className="match-stats-item-mobile__graph__section home">
          <div
            className="match-stats-item-mobile__graph__section__fill"
            style={{ width: homeGraphWidth }}
          ></div>
        </div>

        <div className="match-stats-item-mobile__graph__section away">
          <div
            className="match-stats-item-mobile__graph__section__fill"
            style={{ width: awayGraphWidth }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function MatchStats({
  homeTeam,
  awayTeam,
  homeStats = {},
  awayStats = {},
  uploaded,
}) {
  const homeShots = homeStats.totalShots || 0;
  const awayShots = awayStats.totalShots || 0;
  const totalShots = homeShots + awayShots;
  const homeShotsOnTarget = homeStats.shotsOnTarget || 0;
  const awayShotsOnTarget = awayStats.shotsOnTarget || 0;

  const homeFouls = homeStats.fouls || 0;
  const awayFouls = awayStats.fouls || 0;
  const totalFouls = homeFouls + awayFouls;

  const homeYellowCards = homeStats.yellowCards || 0;
  const awayYellowCards = awayStats.yellowCards || 0;
  const totalYellowCards = homeStats.yellowCards + awayStats.yellowCards;

  const homeRedCards = homeStats.redCards || 0;
  const awayRedCards = awayStats.redCards || 0;
  const totalRedCards = homeStats.redCards + awayStats.redCards;

  const homeOffsides = homeStats.offsides || 0;
  const awayOffsides = awayStats.offsides || 0;
  const totalOffsides = homeOffsides + awayOffsides;

  const homeSaves = homeStats.saves || 0;
  const awaySaves = awayStats.saves || 0;
  const totalSaves = homeSaves + awaySaves;

  const homeTackles = homeStats.tackles || 0;
  const awayTackles = awayStats.tackles || 0;
  const totalTackles = homeTackles + awayTackles;

  const homeBlocks = homeStats.blocks || 0;
  const awayBlocks = awayStats.blocks || 0;
  const totalBlocks = homeBlocks + awayBlocks;

  const homeKeyPasses = homeStats.keyPasses || 0;
  const awayKeyPasses = awayStats.keyPasses || 0;
  const totalKeyPasses = homeKeyPasses + awayKeyPasses;

  const homeCorners = homeStats.corners || 0;
  const awayCorners = awayStats.corners || 0;
  const totalCorners = homeCorners + awayCorners;

  const homePasses = homeStats.successfulPasses || 0;
  const awayPasses = awayStats.successfulPasses || 0;
  const totalPasses = homePasses + awayPasses;

  const homeAttemptedPasses = homeStats.attemptedPasses || 0;
  const awayAttemptedPasses = awayStats.attemptedPasses || 0;
  const totalAttemptedPasses = homeAttemptedPasses + awayAttemptedPasses;

  const homeInterceptions = homeStats.interceptions || 0;
  const awayInterceptions = awayStats.interceptions || 0;
  const totalInterceptions = homeInterceptions + awayInterceptions;

  const homeClearances = homeStats.clearances || 0;
  const awayClearances = awayStats.clearances || 0;
  const totalClearances = homeClearances + awayClearances;

  const homePassAccuracy = homePasses
    ? Math.round((homePasses / homeAttemptedPasses) * 100)
    : "";
  const awayPassAccuracy = awayPasses
    ? Math.round((awayPasses / awayAttemptedPasses) * 100)
    : "";
  //   const totalPassAccuracy = homePasses + awayPasses;

  const homePossession =
    homePasses + awayPasses
      ? Math.round((homePasses / (homePasses + awayPasses)) * 100)
      : "";
  const awayPossession =
    homePasses + awayPasses
      ? Math.round((awayPasses / (homePasses + awayPasses)) * 100)
      : "";

  return (
    <div className="fixture-stats-mobile__football__match-stats">
      <div className="fixture-stats-mobile__football__match-stats__title">
        Match Stats
      </div>

      <div className="fixture-stats-mobile__football__match-stats__items">
        {!uploaded && (
          <div className="fixture-stats-mobile__football__match-stats__possession-graph">
            <div className="fixture-stats-mobile__football__match-stats__possession-graph__meta">
              <div className="fixture-stats-mobile__football__match-stats__possession-graph__meta__team home">
                <div className="fixture-stats-mobile__football__match-stats__possession-graph__meta__team__logo ">
                  {homeTeam?.organization?.profilePhoto && (
                    <Avatar media={homeTeam.organization.profilePhoto} />
                  )}
                </div>
              </div>
              <div className="fixture-stats-mobile__football__match-stats__possession-graph__meta__label">
                Possession
              </div>
              <div className="fixture-stats-mobile__football__match-stats__possession-graph__meta__team away">
                <div className="fixture-stats-mobile__football__match-stats__possession-graph__meta__team__logo ">
                  {awayTeam?.organization?.profilePhoto && (
                    <Avatar media={awayTeam.organization.profilePhoto} />
                  )}
                </div>
              </div>
            </div>

            <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph">
              <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph__value home">
                {typeof homePossession === "number"
                  ? homePossession + "%"
                  : "-%"}
              </div>
              <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph__track">
                <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section home">
                  <div
                    className="fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section__fill"
                    style={{ width: (homePossession || 0) + "%" }}
                  ></div>
                </div>

                <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section away">
                  <div
                    className="fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section__fill"
                    style={{ width: (awayPossession || 0) + "%" }}
                  ></div>
                </div>

                <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph__track__icon">
                  <FootballIcon
                    style={{ height: "14px", fill: "var(--light-3)" }}
                  />
                </div>
              </div>
              <div className="fixture-stats-mobile__football__match-stats__possession-graph__graph__value away">
                {typeof awayPossession === "number"
                  ? awayPossession + "%"
                  : "-%"}
              </div>
            </div>
          </div>
        )}

        <MatchStatsItem
          label={"Total Shots"}
          homeValue={homeShots}
          awayValue={awayShots}
          homeGraphWidth={
            totalShots ? (homeShots / totalShots) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalShots ? (awayShots / totalShots) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Shots On Target"}
          homeValue={homeShotsOnTarget}
          awayValue={awayShotsOnTarget}
          homeGraphWidth={
            totalShots ? (homeShotsOnTarget / totalShots) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalShots ? (awayShotsOnTarget / totalShots) * 100 + "%" : "0%"
          }
        />

        {!uploaded && (
          <MatchStatsItem
            label={"Total Passes"}
            homeValue={homePasses}
            awayValue={awayPasses}
            homeGraphWidth={
              totalPasses ? (homePasses / totalPasses) * 100 + "%" : "0%"
            }
            awayGraphWidth={
              totalPasses ? (awayPasses / totalPasses) * 100 + "%" : "0%"
            }
          />
        )}

        {!uploaded && (
          <MatchStatsItem
            label={"Pass Accuracy"}
            homeValue={homePassAccuracy}
            awayValue={awayPassAccuracy}
            homeGraphWidth={(homePassAccuracy || 0) + "%"}
            awayGraphWidth={(awayPassAccuracy || 0) + "%"}
          />
        )}

        <MatchStatsItem
          label={"Fouls"}
          homeValue={homeFouls}
          awayValue={awayFouls}
          homeGraphWidth={
            totalFouls ? (homeFouls / totalFouls) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalFouls ? (awayFouls / totalFouls) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Yellow Cards"}
          homeValue={homeYellowCards}
          awayValue={awayYellowCards}
          homeGraphWidth={
            totalYellowCards
              ? (homeYellowCards / totalYellowCards) * 100 + "%"
              : "0%"
          }
          awayGraphWidth={
            totalYellowCards
              ? (awayYellowCards / totalYellowCards) * 100 + "%"
              : "0%"
          }
        />

        <MatchStatsItem
          label={"Red Cards"}
          homeValue={homeRedCards}
          awayValue={awayRedCards}
          homeGraphWidth={
            totalRedCards ? (homeRedCards / totalRedCards) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalRedCards ? (awayRedCards / totalRedCards) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Offsides"}
          homeValue={homeOffsides}
          awayValue={awayOffsides}
          homeGraphWidth={
            totalOffsides ? (homeOffsides / totalOffsides) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalOffsides ? (awayOffsides / totalOffsides) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Saves"}
          homeValue={homeSaves}
          awayValue={awaySaves}
          homeGraphWidth={
            totalSaves ? (homeSaves / totalSaves) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalSaves ? (awaySaves / totalSaves) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Tackles"}
          homeValue={homeTackles}
          awayValue={awayTackles}
          homeGraphWidth={
            totalTackles ? (homeTackles / totalTackles) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalTackles ? (awayTackles / totalTackles) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Interceptions"}
          homeValue={homeInterceptions}
          awayValue={awayInterceptions}
          homeGraphWidth={
            totalInterceptions
              ? (homeInterceptions / totalInterceptions) * 100 + "%"
              : "0%"
          }
          awayGraphWidth={
            totalInterceptions
              ? (awayInterceptions / totalInterceptions) * 100 + "%"
              : "0%"
          }
        />

        <MatchStatsItem
          label={"Blocks"}
          homeValue={homeBlocks}
          awayValue={awayBlocks}
          homeGraphWidth={
            totalBlocks ? (homeBlocks / totalBlocks) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalBlocks ? (awayBlocks / totalBlocks) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Clearances"}
          homeValue={homeClearances}
          awayValue={awayClearances}
          homeGraphWidth={
            totalClearances
              ? (homeClearances / totalClearances) * 100 + "%"
              : "0%"
          }
          awayGraphWidth={
            totalClearances
              ? (awayClearances / totalClearances) * 100 + "%"
              : "0%"
          }
        />

        <MatchStatsItem
          label={"Key Passes"}
          homeValue={homeKeyPasses}
          awayValue={awayKeyPasses}
          homeGraphWidth={
            totalKeyPasses ? (homeKeyPasses / totalKeyPasses) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalKeyPasses ? (awayKeyPasses / totalKeyPasses) * 100 + "%" : "0%"
          }
        />

        <MatchStatsItem
          label={"Corners"}
          homeValue={homeCorners}
          awayValue={awayCorners}
          homeGraphWidth={
            totalCorners ? (homeCorners / totalCorners) * 100 + "%" : "0%"
          }
          awayGraphWidth={
            totalCorners ? (awayCorners / totalCorners) * 100 + "%" : "0%"
          }
        />
      </div>
    </div>
  );
}

export default function FootballStats({ fixture }) {
  const { loading, error, data } = useQuery(FIXTURE_STATS, {
    variables: { id: fixture.id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture.FootballStats",
        query: "FIXTURE_STATS",
      },
    });
    console.log(error);

    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeTeam = participants[0];
  const awayTeam = participants[1];

  const homeLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[0]?.organization?.id
  );

  const awayLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[1]?.organization?.id
  );

  const homeStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === homeTeam.organization.id
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === awayTeam.organization.id
  );

  return (
    <div className="fixture-stats-mobile__football">
      <MatchStats
        fixture={fixture}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeStats={homeStats}
        awayStats={awayStats}
        uploaded={data?.fixture?.uploaded}
      />

      <PlayerStats
        fixture={data?.fixture}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeLineup={homeLineup}
        awayLineup={awayLineup}
      />
    </div>
  );
}
