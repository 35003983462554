// V1
import { useState, Fragment } from "react";
import "./Event.css";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Loader from "../../components/Loader";
import PhotoBrowser from "../../components/PhotoBrowser";
import UploaderWithCropper from "../../components/UploaderWithCropper";
import ClickOutside from "../../components/ClickOutside";
import Image from "../../components/Image";
import Register from "../../components/Event/Register";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import {
  EventOverview,
  EventFixtures,
  EventPhotos,
  EventVideos,
  EventStandings,
  EventStats,
} from "../../components/Event";
import Avatar from "../../components/Avatar";

import { CAN_MANAGE_EVENT } from "../../api/user";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
// import { UPDATE_EVENT } from "../../api/event";
import { EVENT, UPDATE_EVENT } from "../../api/functions";
import { DELETE_MEDIA } from "../../api/media";
import moment from "moment";
import { useAppState } from "../../utils/appState";
import useRouterQuery from "../../utils/useRouterQuery";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import { gql } from "@apollo/client";

const eventTabs = [
  { label: "Overview", component: EventOverview, key: "overview" },
  { label: "Fixtures", component: EventFixtures, key: "fixtures" },
  { label: "Standings", component: EventStandings, key: "standings" },
  { label: "Photos", component: EventPhotos, key: "photos" },
  { label: "Videos", component: EventVideos, key: "videos" },
  { label: "Statistics", component: EventStats, key: "statistics" },
];

function SelectProfilePhotoList({ event, setUpload }) {
  const [, setAppState] = useAppState();
  const [selectedPhoto, setSelectedPhoto] = useState(event.profilePhoto);

  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
        profilePhotos{
          id
          filename
        }                
    `,
    }),
    { variables: { id: event.id } }
  );

  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      // refetch();

      if (!((data?.event?.profilePhotos?.length || 0) - 1 > 0)) {
        // console.log("NO PROFILE PHOTOS LEFT");
        setUpload(true);
      }

      // console.log("Passed update 1");

      cache.modify({
        id: cache.identify(event),
        fields: {
          profilePhotos(existingMediaRefs, { readField }) {
            return existingMediaRefs.filter(
              (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
            );
          },
          ...(deleteMedia.id === event.profilePhoto?.id
            ? { profilePhoto: () => null }
            : {}),
        },
      });
      // console.log("Passed update 2");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Event.SelectProfilePhotoList",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [updateEvent, { loading: updating }] = useMutation(
    UPDATE_EVENT({ selectionSet: "profilePhoto{id filename}" }),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Event.SelectProfilePhotoList",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const updateProfilePhoto = () => {
    updateEvent({
      variables: {
        where: { id: event.id },
        data: {
          profilePhoto: selectedPhoto?.id
            ? { connect: { id: selectedPhoto.id } }
            : { disconnect: true },
        },
      },
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(event),
          fields: {
            profilePhoto() {
              return updateEvent.profilePhoto;
            },
          },
        });

        setAppState({ modal: false });
      },
    });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.SelectProfilePhotoList",
        query: "EVENT",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const photos = data?.event?.profilePhotos;

  return (
    <Fragment>
      <div className="select-profile-photo__images">
        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 0)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 1)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 2)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 3)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      if (deleting) return;
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader theme={"blue small"} />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-profile-photo-list-options">
        <Button
          className="primary medium blue"
          onClick={() => {
            if (updating) return;
            updateProfilePhoto(selectedPhoto);
          }}
        >
          {updating ? <Loader theme={"small"} /> : "Save"}
        </Button>
        <Button
          className="tertiary"
          style={{ marginTop: "16px" }}
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
}

function SelectProfilePhoto({ event }) {
  const [upload, setUpload] = useState(false);
  const [, setAppState] = useAppState();

  if (upload) {
    return (
      <UploaderWithCropper
        accept=".jpg,.png,.jpeg"
        label="Upload profile photo"
        multiple={false}
        sourceId={event.id}
        sourceType={"event"}
        subtype={"PROFILE PHOTO"}
        onCreateMedia={(cache, createMedia) => {
          if (createMedia?.media) {
            const newMediaRef = cache.writeFragment({
              data: createMedia?.media,
              fragment: gql`
                fragment NewMedia on Media {
                  id
                  filename
                }
              `,
            });

            cache.modify({
              id: cache.identify(event),
              fields: {
                profilePhoto() {
                  return newMediaRef;
                },
                profilePhotos(existingMediaRefs, { readField }) {
                  return [newMediaRef, ...existingMediaRefs];
                },
              },
            });
          }

          setAppState({ modal: false });
        }}
      />
    );
  } else {
    return (
      <ClickOutside
        onClickOutside={() => {
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div className="select-profile-photo-wrapper" ref={wrapperRef}>
            <Card className="select-profile-photo">
              <div className="select-profile-photo__header">
                <div className="select-profile-photo__header__title">
                  Select profile image
                </div>
                <div
                  className="select-profile-photo__header__link"
                  onClick={() => {
                    setUpload(true);
                  }}
                >
                  Upload New
                </div>
              </div>

              <SelectProfilePhotoList event={event} setUpload={setUpload} />
            </Card>
          </div>
        )}
      </ClickOutside>
    );
  }
}

function SelectCoverPhotoList({ event, setUpload }) {
  // console.log("Cover photo list event:", event);
  const [, setAppState] = useAppState();
  const [selectedPhoto, setSelectedPhoto] = useState(event.coverPhoto);

  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
        coverPhotos{
          id
          filename
        }                
    `,
    }),
    { variables: { id: event.id } }
  );

  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      // refetch();

      if (!((data?.event?.coverPhotos?.length || 0) - 1 > 0)) {
        // console.log("NO PROFILE PHOTOS LEFT");
        setUpload(true);
      }

      // console.log("Passed update 1");

      cache.modify({
        id: cache.identify(event),
        fields: {
          coverPhotos(existingMediaRefs, { readField }) {
            return existingMediaRefs.filter(
              (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
            );
          },
          ...(deleteMedia.id === event.coverPhoto?.id
            ? { coverPhoto: () => null }
            : {}),
        },
      });
      // console.log("Passed update 2");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Event.SelectCoverPhotoList",
          mutation: "DELETE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const [updateEvent, { loading: updating }] = useMutation(
    UPDATE_EVENT({ selectionSet: "coverPhoto{id filename}" }),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "Event.SelectCoverPhotoList",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const updateProfilePhoto = () => {
    updateEvent({
      variables: {
        where: { id: event.id },
        data: {
          coverPhoto: selectedPhoto?.id
            ? { connect: { id: selectedPhoto.id } }
            : { disconnect: true },
        },
      },
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(event),
          fields: {
            coverPhoto(existingMediaRefs, { readField }) {
              return updateEvent.coverPhoto;
            },
          },
        });

        setAppState({ modal: false });
      },
    });
  };

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.SelectCoverPhotoList",
        query: "EVENT",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const photos = data?.event?.coverPhotos;

  return (
    <Fragment>
      <div className="select-profile-photo__images">
        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 0)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 1)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 2)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="select-profile-photo__images__column">
          {photos
            .filter((_, i) => i % 4 === 3)
            .map((m) => (
              <div key={m.id} className="select-profile-photo__images__item">
                <div
                  className="select-profile-photo__images__item__wrapper"
                  onClick={() => {
                    if (selectedPhoto?.id === m.id) {
                      setSelectedPhoto(false);
                    } else {
                      setSelectedPhoto(m);
                    }

                    // updateProfilePhoto(m);
                  }}
                >
                  <Image media={m} />
                  <div
                    className={
                      "select-profile-photo__images__item__select " +
                      (m.id === selectedPhoto?.id ? "selected" : "")
                    }
                  >
                    <TimesIcon className="select-profile-photo__images__item__select__icon-delete" />
                    <CheckIcon className="select-profile-photo__images__item__select__icon" />
                  </div>
                </div>

                <div className="select-profile-photo__images__item__options">
                  <div
                    className="select-profile-photo__images__item__options__icon"
                    onClick={() => {
                      if (deleting) return;
                      deleteMedia({ variables: { id: m.id } });
                    }}
                  >
                    {deleting ? (
                      <Loader theme={"blue small"} />
                    ) : (
                      <TrashIcon className="select-profile-photo__images__item__options__icon__delete" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-profile-photo-list-options">
        <Button
          className="primary medium blue"
          onClick={() => {
            if (updating) return;
            updateProfilePhoto(selectedPhoto);
          }}
        >
          {updating ? <Loader theme={"small"} /> : "Save"}
        </Button>
        <Button
          className="tertiary"
          style={{ marginTop: "16px" }}
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
}

function SelectCoverPhoto({ event }) {
  const [upload, setUpload] = useState(false);
  const [, setAppState] = useAppState();

  if (upload) {
    return (
      <UploaderWithCropper
        accept=".jpg,.png,.jpeg"
        label="Upload cover photo"
        multiple={false}
        sourceId={event.id}
        sourceType={"event"}
        subtype={"COVER PHOTO"}
        cropperWidth={240}
        cropperHeight={40}
        onCreateMedia={(cache, createMedia) => {
          // console.log(createMedia);
          if (createMedia?.media) {
            const newMediaRef = cache.writeFragment({
              data: createMedia?.media,
              fragment: gql`
                fragment NewMedia on Media {
                  id
                  filename
                }
              `,
            });

            cache.modify({
              id: cache.identify(event),
              fields: {
                coverPhoto() {
                  return newMediaRef;
                },
                coverPhotos(existingMediaRefs, { readField }) {
                  return [newMediaRef, ...existingMediaRefs];
                },
              },
            });
          }

          setAppState({ modal: false });

          // setUpload(false);
        }}
      />
    );
  } else {
    return (
      <ClickOutside
        onClickOutside={() => {
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div className="select-profile-photo-wrapper" ref={wrapperRef}>
            <Card className="select-profile-photo">
              <div className="select-profile-photo__header">
                <div className="select-profile-photo__header__title">
                  Select cover photo
                </div>
                <div
                  className="select-profile-photo__header__link hover-pointer"
                  onClick={() => {
                    setUpload(true);
                  }}
                >
                  Upload New
                </div>
              </div>

              <SelectCoverPhotoList event={event} setUpload={setUpload} />
            </Card>
          </div>
        )}
      </ClickOutside>
    );
  }
}

function Event() {
  usePageTracking({ title: "Event" });
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const tab = query.get("t") || "overview";
  const [{ isLoggedIn }, setAppState] = useAppState();
  const selectedTab = eventTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const authQuery = useQuery(CAN_MANAGE_EVENT, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
        name
        scheduledStart
        scheduledEnd
        registrationStart
        registrationEnd      
        venueLabel
        coverPhoto{
          id
          filename
        }        
        profilePhoto{
          id
          filename
        }        
        venue{
          id
          name
        }      
        city{
          id
          value        
        }
        userIsRegistered{
          id
        }   
    `,
    }),
    { variables: { id } }
  );

  if (authQuery.error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event",
        query: "CAN_MANAGE_EVENT",
      },
    });
    console.log(error);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data.event;
  const canManageEvent = authQuery?.data?.canManageEvent?.authorized;
  // console.log("Event:", ev);
  // console.log("CAN MANAGE EVENT:", canManageEvent);

  return (
    <div className="App-content">
      <div className="event-page">
        <div className="cover">
          {ev.coverPhoto && <Avatar media={ev.coverPhoto} />}
          {ev.coverPhoto && (
            <div
              className="overlay"
              onClick={() => {
                // console.log("Launching phtot");
                setAppState({
                  modal: (
                    <PhotoBrowser
                      query={EVENT({
                        selectionSet: `coverPhotos{id filename}`,
                      })}
                      vars={{ id }}
                      getPhotos={(data) => data.event?.coverPhotos || []}
                      totalPhotos={(data) =>
                        data.event?.coverPhotos?.length || 0
                      }
                      media={ev.coverPhoto}
                    />
                  ),
                });
              }}
            />
          )}
          {canManageEvent && (
            <div
              className="edit-cover-btn"
              onClick={() => {
                setAppState({
                  modal: (
                    <SelectCoverPhoto
                      event={ev}
                      canManageEvent={canManageEvent}
                    />
                  ),
                });
              }}
            >
              <PencilIcon className="icon" /> Edit cover photo
            </div>
          )}
        </div>
        <div className="head">
          <div className="organizer">
            <div
              className="image"
              onClick={() => {
                setAppState({
                  modal: (
                    <PhotoBrowser
                      query={EVENT({
                        selectionSet: `profilePhotos{id filename}`,
                      })}
                      vars={{ id }}
                      getPhotos={(data) => data.event?.profilePhotos || []}
                      totalPhotos={(data) =>
                        data.event?.profilePhotos?.length || 0
                      }
                      media={ev.profilePhoto}
                    />
                  ),
                });
              }}
            >
              {ev.profilePhoto && <Avatar media={ev.profilePhoto} />}
            </div>

            {canManageEvent && (
              <div
                className="edit-profile-photo-btn"
                onClick={() => {
                  setAppState({
                    modal: (
                      <SelectProfilePhoto
                        event={ev}
                        canManageEvent={canManageEvent}
                      />
                    ),
                  });
                }}
              >
                <PencilIcon className="icon" style={{ height: "10px" }} />
              </div>
            )}
          </div>
          <div className="meta">
            <div className="title">{ev.name}</div>
            <div className="secondary">
              {ev.venue ? (
                <Link
                  to={"/app/page/" + ev.venue.id}
                  style={{ color: "var(--blue-main" }}
                >
                  {ev.venue.name}
                </Link>
              ) : (
                (ev.venueLabel
                  ? ev.venueLabel + (ev.city?.value ? ", " : "")
                  : "") + (ev.city?.value || "") || "Location TBA"
              )}{" "}
              &nbsp; |&nbsp;{" "}
              {ev.scheduledStart
                ? moment(ev.scheduledStart).format("Do MMM YYYY")
                : "Start date TBA"}{" "}
              -{" "}
              {ev.scheduledEnd
                ? moment(ev.scheduledEnd).format("Do MMM YYYY")
                : "End date TBA"}{" "}
            </div>
          </div>
          <div className="options">
            {canManageEvent && (
              <Button
                className="primary orange large option"
                onClick={() => {
                  history.push("/app/manage/event/" + id);
                }}
              >
                Manage Event
              </Button>
            )}
            <Button
              className={
                "primary blue large option " +
                (!isLoggedIn ||
                !ev.registrationStart ||
                (ev.registrationStart &&
                  moment(ev.registrationStart).isAfter(moment())) ||
                (ev.registrationEnd &&
                  moment(ev.registrationEnd).isBefore(moment())) ||
                ev.userIsRegistered
                  ? "disabled"
                  : "")
              }
              onClick={() => {
                setAppState({
                  modal: <Register event={ev} />,
                });
              }}
            >
              Register
            </Button>
          </div>
        </div>

        <div className="tabs">
          {eventTabs.map((t, i) => (
            <div
              key={"event-tab-" + t.key}
              className={
                "tab-item hover-pointer " +
                (selectedTab.key === t.key ? "active" : "")
              }
              onClick={() => {
                history.replace(location.pathname + "?t=" + t.key);
              }}
            >
              {t.label}
            </div>
          ))}
        </div>

        <div className="body">
          <SelectedComponent
            id={id}
            canManageEvent={canManageEvent}
            event={ev}
          />
        </div>
      </div>
    </div>
  );
}

export default Event;
