import "./PhotosCard.css";
import { useQuery } from "@apollo/client";
import Square from "../../mobileComponents/Square";
import Loader from "../../components/Loader";
import PhotoBrowser from "../PhotoBrowser";
import { useAppState } from "../../utils/appState";
import Uploader from "../../components/Uploader";
import Avatar from "../../components/Avatar/Avatar";
import captureException from "../../utils/captureException";

export default function PhotosCard({
  title = "Photos",
  query,
  vars,
  getPhotos = () => [],
  totalPhotos = () => 0,
  noData = "",
  filters = {},
  processFilters = () => {
    return false;
  },
  onDelete = () => {},
  style = {},
  addPhoto,
}) {
  const [, setAppState] = useAppState();
  const { networkStatus, error, data, variables, fetchMore } = useQuery(query, {
    variables: vars,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  //   useEffect(() => {
  //     const filterVars = processFilters(filters);

  //     if (filterVars) {
  //       refetch({
  //         ...vars,
  //         ...filterVars,
  //       });
  //     }
  //   }, [filters]);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PhotosCard",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 2 || networkStatus === 4) {
    return (
      <div className="photos-card-mobile">
        <div className="photos-card-mobile__header">
          <div className="photos-card-mobile__header__title">{title}</div>
        </div>

        <div className="photos-card-mobile__gallery">
          {[1, 2, 3, 4].map((photo) => (
            <Square key={"pcl-" + photo}>
              {({ squareRef, dimensions }) => (
                <div
                  className="photos-card-mobile__gallery__item"
                  ref={squareRef}
                  style={{ height: dimensions.width }}
                ></div>
              )}
            </Square>
          ))}
        </div>

        <div className="photos-card-mobile__footer">
          <div className="photos-card-mobile__footer__show-more">Show More</div>
        </div>
      </div>
    );
  }

  const photos = getPhotos(data);
  const photosCount = totalPhotos(data);

  return (
    <div className="photos-card-mobile">
      <div className="photos-card-mobile__header">
        <div className="photos-card-mobile__header__title">{title}</div>
        {addPhoto && (
          <div
            className="photos-card-mobile__header__btn"
            onClick={() => {
              setAppState({
                modal: (
                  <div className="modal-form-mobile-wrapper">
                    <Uploader
                      accept=".jpg,.png,.jpeg"
                      label="Event Photos"
                      multiple
                      sourceId={addPhoto.sourceId}
                      sourceType={addPhoto.sourceType}
                      mediaSetId={false}
                      onCreateMedia={addPhoto.onAddPhoto}
                    />
                  </div>
                ),
              });
            }}
          >
            Add Photo
          </div>
        )}
      </div>

      {!!photos.length && (
        <div className="photos-card-mobile__gallery">
          {photos?.map((photo) => (
            <Square key={"photo-card-item-" + photo.id}>
              {({ squareRef, dimensions }) => (
                <div
                  className="photos-card-mobile__gallery__item"
                  ref={squareRef}
                  style={{ height: dimensions.width }}
                  onClick={() => {
                    setAppState({
                      modal: (
                        <PhotoBrowser
                          query={query}
                          vars={vars}
                          getPhotos={getPhotos}
                          totalPhotos={totalPhotos}
                          media={photo}
                        />
                      ),
                    });
                  }}
                >
                  <Avatar media={photo} />
                  {/* <img src={makeFileUrl(photo)} /> */}
                </div>
              )}
            </Square>
          ))}
        </div>
      )}

      {!photos?.length && <div className="no-data">{noData}</div>}

      {photos?.length < photosCount && (
        <div className="photos-card-mobile__footer">
          <div
            className="photos-card-mobile__footer__show-more"
            onClick={() => {
              // setCanShowEnd(true);
              fetchMore({
                variables: {
                  ...variables,
                  cursor: photos[photos.length - 1]?.id,
                },
              });
            }}
          >
            {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
          </div>
        </div>
      )}
    </div>
  );
}
