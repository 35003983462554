import { gql } from "@apollo/client";

const PUBLISH_DOUBLES_FIXTURE = gql`
  mutation publishDoublesFixture(
    $fixtureId: ID!
    $placements: [PublishFixturePlacementsInput!]
    $tables: [PublishFixtureTableInput!]
  ) {
    publishDoublesFixture(
      fixtureId: $fixtureId
      placements: $placements
      tables: $tables
    ) {
      id
    }
  }
`;

export default PUBLISH_DOUBLES_FIXTURE;
