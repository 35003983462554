// V1
import "./ManageEventPosts.css";
import CreatePost from "../../CreatePost";
import Spinner from "../../Spinner";
import Post from "../../Post";
import { useQuery } from "@apollo/client";
import {
  EVENT_POSTS,
  EVENT_PHOTOS,
  EVENT_VIDEOS,
  EVENT_ALBUMS,
} from "../../../api/event";
import captureException from "../../../utils/captureException";
import MatchReport from "../../MatchReport";
import { Fragment } from "react";

function ManageEventPosts({ id }) {
  const { loading, error, data } = useQuery(EVENT_POSTS, { variables: { id } });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventPosts",
        query: "EVENT_POSTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }
  if (loading) {
    <div>
      <Spinner />
    </div>;
  }

  const ev = data?.event;
  const posts = ev?.posts || [];

  // console.log("MANAGE EVENT POSTS:", posts);

  return (
    <div className="manage-event-posts">
      <div className="left-column">
        {posts.map((post) => (
          <Fragment>
            {post.postType === "REPORT" ? (
              <MatchReport post={post} />
            ) : (
              <Post
                key={post.id}
                post={post}
                queryInfo={{
                  query: EVENT_POSTS,
                  variables: { id },
                  onDeleteData: (prevData, deletePost) => {
                    if (!prevData?.event) {
                      return [];
                    }
                    return {
                      event: {
                        ...prevData.event,
                        posts: [...prevData.event.posts].filter(
                          (p) => p.id !== deletePost.id
                        ),
                      },
                    };
                  },
                  onUpdateData: (prevData, updatePost) => {
                    if (!prevData?.event) {
                      return [];
                    }

                    const postIndex = prevData.event.posts.findIndex(
                      (p) => p.id === updatePost.id
                    );

                    if (postIndex > -1) {
                      const newPosts = [...prevData.event.posts];
                      newPosts[postIndex] = updatePost;
                      // console.log("New updatedpost index:", postIndex);
                      // console.log("New posts array:", newPosts);
                      return {
                        event: {
                          ...prevData.event,
                          posts: newPosts,
                        },
                      };
                    } else {
                      return { event: { ...prevData.event } };
                    }
                  },
                }}
                options={["edit", "delete"]}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div className="right-column">
        <CreatePost
          sourceId={id}
          sourceType={"event"}
          onCreatePost={(cache, createPost) => {
            const q = cache.readQuery({
              query: EVENT_POSTS,
              variables: { id },
            });

            // console.log("q:", q);
            cache.writeQuery({
              query: EVENT_POSTS,
              variables: { id },
              data: {
                event: {
                  ...q.event,
                  posts: [createPost, ...q.event.posts],
                },
              },
            });
          }}
          mediaQueries={{
            photos: {
              query: EVENT_PHOTOS,
              getPhotos: (data) => {
                return data?.event?.media || [];
              },
              getPhotosCount: (data) => {
                return data?.event?.photosCount || 0;
              },
              onUploadPhoto: (cache, createMedia) => {
                // console.log("Updating post photo cache");
                const q = cache.readQuery({
                  query: EVENT_PHOTOS,
                  variables: { id },
                });

                // console.log(
                //   "Updating post photo cache 2:",
                //   q.organization,
                //   createMedia
                // );

                cache.writeQuery({
                  query: EVENT_PHOTOS,
                  variables: { id },
                  data: {
                    event: {
                      ...q.event,
                      photosCount: q.event.photosCount + 1,
                      media: [createMedia.media, ...q.event.media],
                    },
                  },
                });
              },
            },
            videos: {
              query: EVENT_VIDEOS,
              getVideos: (data) => {
                return data?.event?.media || [];
              },
              getVideosCount: (data) => {
                return data?.event?.videosCount || 0;
              },
              onUploadVideo: (cache, createMedia) => {
                const q = cache.readQuery({
                  query: EVENT_VIDEOS,
                  variables: { id },
                });

                cache.writeQuery({
                  query: EVENT_VIDEOS,
                  variables: { id },
                  data: {
                    event: {
                      ...q.event,
                      videosCount: q.event.videosCount + 1,
                      media: [createMedia.media, ...q.event.media],
                    },
                  },
                });
              },
            },
            albums: {
              query: EVENT_ALBUMS,
              getAlbums: (data) => {
                return data?.event?.mediaSets || [];
              },
              getAlbumsCount: (data) => {
                return data?.event?.albumsCount || 0;
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default ManageEventPosts;
