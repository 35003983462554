import { useState } from "react";
import "./Header.css";
import Button from "../Button";
import Dropdown from "../Dropdown";
import NotificationsMenu from "../NotificationsMenu";
import CreateMenu from "../CreateMenu";
import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import { ReactComponent as DirectoryIcon } from "../../icons/directory.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as NotificationIcon } from "../../icons/notifications.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus-square.svg";
import { useLocation, Link } from "react-router-dom";
import { useAppState } from "../../utils/appState";
import AppSearch from "../AppSearch";
import ESAppSearch from "../ESAppSearch";
import AccountButton from "../AccountButton";

function Header() {
  const [{ isLoggedIn }] = useAppState();
  const location = useLocation();
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [createMenuVisible, setCreateMenuVisible] = useState(false);
  return (
    <div className="app-header">
      <Link to="/app" className="logo">
        <img src={"/logo.svg"} alt="" />
      </Link>

      {/* <AppSearch /> */}
      <ESAppSearch />

      <div className="options">
        <Link
          to={"/app"}
          className={"option " + (location.pathname === "/app" ? "active" : "")}
        >
          <HomeIcon style={{ height: 17 }} />
        </Link>

        <Link
          to={"/app/directory"}
          className={
            "option " + (location.pathname === "/app/directory" ? "active" : "")
          }
        >
          <DirectoryIcon style={{ height: 16, marginTop: 2 }} />
        </Link>
        <Link
          to={"/app/events"}
          className={
            "option " + (location.pathname === "/app/events" ? "active" : "")
          }
        >
          <CalendarIcon style={{ height: 16 }} />
        </Link>

        <Dropdown
          target={
            <div
              className={"option "}
              style={notificationsVisible ? { stroke: "var(--blue-main)" } : {}}
            >
              <NotificationIcon style={{ height: 17, marginTop: 2 }} />
            </div>
          }
          menu={({ setShowMenu }) => (
            <NotificationsMenu
              setShowMenu={setShowMenu}
              setNotificationsVisible={setNotificationsVisible}
            />
          )}
          onOpen={() => {
            setNotificationsVisible(true);
          }}
          onClose={() => {
            setNotificationsVisible(false);
          }}
        />

        <Dropdown
          target={
            <Button
              className="medium green primary create-button"
              style={
                createMenuVisible ? { background: "var(--green-darker)" } : {}
              }
            >
              <div className="icon">
                <PlusIcon style={{ height: 14 }} />
              </div>
              Create
            </Button>
          }
          menu={<CreateMenu />}
          onOpen={() => {
            setCreateMenuVisible(true);
          }}
          onClose={() => {
            setCreateMenuVisible(false);
          }}
        />

        <AccountButton isLoggedIn={isLoggedIn} />
      </div>
    </div>
  );
}

export default Header;
