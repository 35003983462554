import { useState } from "react";
import "./AuthForm.css";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../api";
import { useHistory } from "react-router-dom";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import { Link } from "react-router-dom";

import { useAppState } from "../../utils/appState";
import Loader from "../../components/Loader";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

function Login({ setFormType }) {
  usePageTracking({ title: "Login" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [, setAppState] = useAppState();

  const history = useHistory();

  const [login, { loading }] = useMutation(LOGIN, {
    update: (cache, { data: { login } }) => {
      // console.log(login);
      if (!login.token || !login.expiresAt) {
        // setError(true);
        return;
      }

      localStorage.setItem("sp_auth_token", login.token);
      localStorage.setItem("sp_expires_at", login.expiresAt);
      localStorage.setItem("sp_name", login.name);
      setAppState({ isLoggedIn: true });

      history.push("/app");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Login",
          mutation: "LOGIN",
        },
      });
      console.log(error);
      setErrorMessage(error.message);
    },
  });

  return (
    <div className="mobile-auth-form">
      <div className="mobile-auth-form__header">Login</div>

      <form
        className="mobile-auth-form__form"
        onSubmit={(e) => {
          // console.log("Form submitted");
          e.preventDefault();

          if (loading) return;

          // setErrorMessage("");

          if (!email || !password) {
            if (!email) {
              setInvalidEmail(true);
            }

            if (!password) {
              setInvalidPassword(true);
            }

            setErrorMessage("All fields are required");
            return;
          }

          login({
            variables: {
              email,
              password,
            },
          });
        }}
      >
        <div className="mobile-auth-form__form__group">
          <div className="mobile-auth-form__form__group__label">
            Email <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <div
            className={
              "mobile-auth-form__form__group__field " +
              (invalidEmail ? "error" : "")
            }
          >
            <div className="mobile-auth-form__form__group__field__icon">
              <MailIcon style={{ fill: "var(--light-3)", height: 14 }} />
            </div>

            <input
              type="email"
              className="mobile-auth-form__form__group__field__input"
              value={email}
              onChange={(e) => {
                if (errorMessage) {
                  setErrorMessage("");
                }
                setEmail(e.target.value);
              }}
              onFocus={() => {
                setInvalidEmail(false);
              }}
              autoFocus
            />
          </div>
        </div>

        <div
          className="mobile-auth-form__form__group"
          style={{ marginBottom: "32px" }}
        >
          <div className="mobile-auth-form__form__group__label">
            Password <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <div
            className={
              "mobile-auth-form__form__group__field " +
              (invalidPassword ? "error" : "")
            }
          >
            <div className="mobile-auth-form__form__group__field__icon">
              <LockIcon style={{ fill: "var(--light-3)", height: 14 }} />
            </div>

            <input
              type={showPassword ? "text" : "password"}
              id="current-password"
              value={password}
              className="mobile-auth-form__form__group__field__input"
              autoComplete="current-password"
              onChange={(e) => {
                if (errorMessage) {
                  setErrorMessage("");
                }
                setPassword(e.target.value);
              }}
              onFocus={() => {
                setInvalidPassword(false);
              }}
            />

            <div
              className="mobile-auth-form__form__group__field__icon"
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            >
              <EyeIcon
                style={{
                  fill: showPassword ? "var(--blue-main)" : "var(--light-4)",
                  height: 14,
                }}
              />
            </div>
          </div>
        </div>

        {errorMessage && (
          <div className="mobile-auth-form__form__error">{errorMessage}</div>
        )}

        <DebouncedButton
          type="submit"
          className={"mobile-auth-form__form__submit "}
          loading={loading}
          disableDefaultStyles={true}
        >
          {loading ? <Loader theme="small" /> : "Submit"}
        </DebouncedButton>
        {/* <button
          type="submit"
          className={
            "mobile-auth-form__form__submit " + (loading ? "disabled" : "")
          }
        >
          {loading ? <Loader theme="small" /> : "Submit"}
        </button> */}
      </form>

      <div className="mobile-auth-form__footer">
        <div className="mobile-auth-form__footer__statement">
          Dont have an account?{" "}
          <Link
            to={"/auth?s=signup"}
            onClick={() => {
              setFormType("signup");
            }}
            className="mobile-auth-form__footer__statement__link"
          >
            Sign up now
          </Link>
        </div>

        <div className="mobile-auth-form__footer__statement">
          Forgot your password?{" "}
          <Link
            to={"/forgot-password"}
            className="mobile-auth-form__footer__statement__link"
          >
            Click here
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
