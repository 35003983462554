import { useState, Fragment } from "react";
import "./ForgotPassword.css";
import { usePageTracking } from "../../utils/usePageTracking";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../api/auth";
import captureException from "../../utils/captureException";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import DebouncedButton from "../../components/Button/DebouncedButton";
import Loader from "../../components/Loader";

function ForgotPassword() {
  usePageTracking({ title: "Forgot Password" });
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    update: (cache, { data: { forgotPassword } }) => {
      if (forgotPassword.success) {
        setEmailSent(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ForgotPassword",
          mutation: "FORGOT_PASSWORD",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="mobile-auth-page__wrapper">
      <div className="mobile-auth-page">
        <Link to="/" className="mobile-auth-page__logo">
          <img src="/logo.svg" alt="" />
        </Link>

        <div className="mobile-auth-form">
          <div className="mobile-auth-form__header">Forgot your password?</div>

          {!emailSent ? (
            <Fragment>
              {/* <div className="mobile-auth-form__secondary-header">
                Enter your email below to reset your password
              </div> */}
              <form
                className="mobile-auth-form__form"
                onSubmit={(e) => {
                  // console.log("Form submitted");
                  e.preventDefault();

                  if (loading) return;

                  setErrorMessage("");

                  if (!email) {
                    setInvalidEmail(true);
                    setErrorMessage("Please enter a valid email");
                    return;
                  }

                  forgotPassword({
                    variables: {
                      email,
                    },
                  });
                }}
              >
                <div className="mobile-auth-form__form__group">
                  <div className="mobile-auth-form__form__group__label">
                    Enter your email below to reset your password
                  </div>

                  <div
                    className={
                      "mobile-auth-form__form__group__field " +
                      (invalidEmail ? "error" : "")
                    }
                  >
                    <div className="mobile-auth-form__form__group__field__icon">
                      <MailIcon
                        style={{ fill: "var(--light-3)", height: 14 }}
                      />
                    </div>

                    <input
                      type="email"
                      className="mobile-auth-form__form__group__field__input"
                      value={email}
                      onChange={(e) => {
                        if (errorMessage) {
                          setErrorMessage("");
                        }
                        setEmail(e.target.value);
                      }}
                      onFocus={() => {
                        setInvalidEmail(false);
                        setErrorMessage("");
                      }}
                      autoFocus
                    />
                  </div>
                </div>

                {errorMessage && (
                  <div className="mobile-auth-form__form__error">
                    {errorMessage}
                  </div>
                )}

                <DebouncedButton
                  type="submit"
                  className={"mobile-auth-form__form__submit "}
                  loading={loading}
                  disableDefaultStyles={true}
                >
                  {loading ? <Loader theme="small" /> : "Submit"}
                </DebouncedButton>
                {/* <button
          type="submit"
          className={
            "mobile-auth-form__form__submit " + (loading ? "disabled" : "")
          }
        >
          {loading ? <Loader theme="small" /> : "Submit"}
        </button> */}
              </form>
            </Fragment>
          ) : (
            <Fragment>
              <div className="mobile-auth-form__secondary-header">
                A link to reset your password has been sent to your registered
                email address.
              </div>
            </Fragment>
          )}

          <div className="mobile-auth-form__footer">
            <div className="mobile-auth-form__footer__statement">
              <Link
                to={"/auth?s=login"}
                // onClick={() => {
                //   setFormType("signup");
                // }}
                className="mobile-auth-form__footer__statement__link"
              >
                Back to login
              </Link>
            </div>
          </div>
        </div>

        <div className="mobile-auth-page__graphic">
          <img src="/circle-bg.svg" alt="" />
        </div>

        <div className="mobile-auth-page__logo-bottom">
          <img src="/logo-white.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
