// NEW FOOTBALL SCORING APP
import "./ScoreBadminton.css";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FIXTURE_SETTINGS } from "../../api/fixture";
import Spinner from "../../components/Spinner";
import Settings from "./Settings";
import Scoring from "./Scoring";
import Publish from "./Publish";
import { FIXTURE } from "../../api/functions";

// function ScoringHeader() {
//   return (
//     <div className="ScoringApp-football__header">
//       <div className="ScoringApp-football__header__nav-left">
//         <div className="ScoringApp-football__header__nav-left__back-btn">
//           <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
//           <div className="ScoringApp-football__header__nav-left__back-btn__text">
//             Back to fixture
//           </div>
//         </div>
//       </div>

//       <div className="ScoringApp-football__header__main"></div>

//       <div className="ScoringApp-football__header__nav-right">
//         <div className="ScoringApp-football__header__nav-right__settings-btn"></div>
//       </div>
//     </div>
//   );
// }

function ScoreBadminton({ id, completed }) {
  const [step, setStep] = useState(completed ? 2 : 0);

  return (
    <div className="ScoringApp-football">
      {step === 0 && <Settings id={id} setStep={setStep} />}
      {step === 1 && <Scoring id={id} setStep={setStep} />}
      {step === 2 && (
        <Publish id={id} setStep={setStep} completed={completed} />
      )}
    </div>
  );
}

export default function ScoreBadmintonWrapper({ id }) {
  // // usePageTracking({ title: "Football Scoring" });
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
    completed    
  `,
    }),
    {
      variables: { id },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.ScoreFootballWrapper",
    //     query: "FIXTURE_SETTINGS",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  // const cache = data?.fixture?.statsCacheState;
  // const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
  //   return a.order - b.order;
  // });

  // const homeTeam = data?.fixture?.roles
  //   .filter((r) => r.type.name === "PARTICIPANT")
  //   .find((p) => !p.order);

  // const awayTeam = data?.fixture?.roles
  //   .filter((r) => r.type.name === "PARTICIPANT")
  //   .find((p) => p.order);

  // const homeLineup = data?.fixture?.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization.id === homeTeam?.organization?.id
  // );

  // const awayLineup = data?.fixture?.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization.id === awayTeam?.organization?.id
  // );

  // const eventId = data?.fixture?.event?.id;
  const completed = data?.fixture?.completed;
  console.log(completed);

  return <ScoreBadminton id={id} completed={completed} />;
}
