import "./Scoring.css";
import { useState, Fragment, useEffect } from "react";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as PauseIcon } from "../../../icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../../icons/play.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as WhistleIcon } from "../../../icons/whistle.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import NewDropdown from "../../../components/NewDropdown";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";
import Avatar from "../../../components/Avatar";
import {
  useBadmintonScoringState,
  BadmintonStatsContextProvider,
} from "../utils/useBadmintonScoringState";
import Spinner from "../../../components/Spinner";
import { FIXTURE_STATS, UPDATE_FIXTURE } from "../../../api/functions/fixture";
// import StatInputForm from "../StatInputForm";
// import DeleteStat from "../DeleteStat";
// import Statement from "../Statement";
// import PenaltyShootout from "../PenaltyShootout";
// import sumToPeriod from "../utils/sumToPeriod";
// import showStat from "../utils/showStat";
import { useHistory } from "react-router-dom";
import CurrentSet from "../CurrentSet";
import BoxScore from "../BoxScore";
import StatsList from "../StatsList";

// function EditTimer({ timestamp, dismiss }) {
//   const [{ cache, selectedPeriod }, setFootballScoringState] =
//     useFootballScoringState();
//   const [minutes, setMinutes] = useState(Math.floor(timestamp / 60));
//   const [seconds, setSeconds] = useState(timestamp % 60);

//   return (
//     <div className="ScoringApp-football__scoring__period__header__timing">
//       <form
//         className="ScoringApp-football__scoring__period__header__timing__current"
//         onSubmit={(e) => {
//           e.preventDefault();

//           // if (!Number(minutes) || !Number(seconds)) {
//           //   return;
//           // }

//           const newTimestamp =
//             (Number(minutes) || 0) * 60 + (Number(seconds) || 0);

//           setFootballScoringState({
//             timestamp: newTimestamp,
//             cache: {
//               ...cache,
//               periods: {
//                 ...(cache.periods || {}),
//                 [selectedPeriod.id]: newTimestamp,
//               },
//             },
//           });

//           dismiss();
//         }}
//       >
//         <div
//           className="ScoringApp-football__scoring__period__header__timing__current__time"
//           style={{ paddingLeft: "8px", width: "auto" }}
//         >
//           <input
//             type="number"
//             className="ScoringApp-football__scoring__period__header__timing__current__time__input"
//             value={minutes}
//             onChange={(e) => {
//               setMinutes(e.target.value);
//             }}
//             step={1}
//             min={0}
//           />
//           <div className="ScoringApp-football__scoring__period__header__timing__current__time__seperator">
//             :
//           </div>
//           <input
//             type="number"
//             className="ScoringApp-football__scoring__period__header__timing__current__time__input"
//             value={seconds}
//             onChange={(e) => {
//               setSeconds(e.target.value);
//             }}
//             step={1}
//             min={0}
//             max={59}
//           />
//         </div>

//         <button
//           type="submit"
//           className="ScoringApp-football__scoring__period__header__timing__current__option"
//         >
//           <CheckIcon className="ScoringApp-football__scoring__period__header__timing__current__option__icon" />
//         </button>

//         <div
//           className="ScoringApp-football__scoring__period__header__timing__current__option"
//           onClick={(e) => {
//             e.preventDefault();
//             dismiss();
//           }}
//         >
//           <TimesIcon
//             className="ScoringApp-football__scoring__period__header__timing__current__option__icon"
//             style={{ stroke: "var(--light-2)" }}
//           />
//         </div>
//       </form>
//     </div>
//   );
// }

// function TimerNew({}) {
//   const [
//     { selectedPeriod, timestamp, running, cache },
//     setFootballScoringState,
//   ] = useFootballScoringState();
//   const [editing, setEditing] = useState(false);
//   // const [secondsElapsed, setSecondsElapsed] = useState(timestamp || 0);
//   // const [running, setRunning] = useState(false);
//   const [timerStart, setTimerStart] = useState(false);
//   // const [timerBase, setTimerBase] = useState(timestamp || 0);

//   // useEffect(() => {
//   //   setSecondsElapsed(timestamp);
//   // }, [timestamp]);

//   useEffect(() => {
//     console.log("Running interval effect");
//     let timer;

//     if (running) {
//       // console.log("Timer started at timestamp:", secondsElapsed);
//       if (!timer) {
//         timer = setInterval(() => {
//           const now = new Date().getTime();
//           const elapsed = now - timerStart;
//           // console.log("Timer: ", timerStart, now, elapsed);
//           const newTimestamp = (timestamp || 0) + Math.floor(elapsed / 1000);
//           // if (!(newTimestamp % 60)) {
//           //   updateCache();
//           // }

//           setFootballScoringState({
//             timestamp: newTimestamp,
//           });
//         }, 1000);
//       }
//     } else {
//       // console.log("Clearing interval");
//       // console.log("Timer stopped at timestamp:", secondsElapsed);
//       // setTimerBase(timestamp);
//       if (timer) {
//         clearInterval(timer);
//         timer = false;
//       }
//     }

//     return () => {
//       clearInterval(timer);
//     };
//   }, [running]);

//   const minutes = Math.floor(timestamp / 60);
//   const seconds = timestamp % 60;

//   if (editing) {
//     return (
//       <EditTimer
//         timestamp={timestamp}
//         dismiss={() => {
//           setEditing(false);
//         }}
//       />
//     );
//   }

//   return (
//     <div className="ScoringApp-football__scoring__period__header__timing">
//       <div className="ScoringApp-football__scoring__period__header__timing__current">
//         {/* {!running && (
//           <div
//             className="ScoringApp-football__scoring__period__header__timing__current__option"
//             onMouseDown={() => {
//               setDecrementPressed(true);
//             }}
//             onMouseUp={() => {
//               setDecrementPressed(false);
//               // updateCache();
//             }}
//             onClick={() => {
//               const newTimestamp = Math.max(timestamp - 1, 0);

//               setFootballScoringState({
//                 timestamp: newTimestamp,
//               });
//             }}
//           >
//             <MinusIcon className="ScoringApp-football__scoring__period__header__timing__current__option__icon" />
//           </div>
//         )} */}

//         <div className="ScoringApp-football__scoring__period__header__timing__current__time">
//           {minutes < 10 ? "0" + minutes : minutes} :{" "}
//           {seconds < 10 ? "0" + seconds : seconds}
//         </div>

//         {!running && (
//           <div
//             className="ScoringApp-football__scoring__period__header__timing__current__option"
//             onClick={() => {
//               setEditing(true);
//             }}
//           >
//             <PencilIcon className="ScoringApp-football__scoring__period__header__timing__current__option__icon" />
//           </div>
//         )}
//       </div>

//       {running ? (
//         <div
//           className="ScoringApp-football__scoring__period__header__timing__opt"
//           onClick={() => {
//             setFootballScoringState({
//               running: false,
//               cache: {
//                 ...cache,
//                 periods: {
//                   ...(cache.periods || {}),
//                   [selectedPeriod.id]: timestamp,
//                 },
//               },
//             });
//           }}
//         >
//           <PauseIcon className="ScoringApp-football__scoring__period__header__timing__opt__icon" />
//         </div>
//       ) : (
//         <div
//           className="ScoringApp-football__scoring__period__header__timing__opt"
//           onClick={() => {
//             setTimerStart(new Date().getTime());
//             setFootballScoringState({
//               running: true,
//             });
//           }}
//         >
//           <PlayIcon className="ScoringApp-football__scoring__period__header__timing__opt__icon" />
//         </div>
//       )}

//       {/* <div
//             className="ScoringApp-football__scoring__period__header__timing__opt"
//             onClick={() => {
//               setRunning(false);
//               changePeriod();
//               // updateCache();
//             }}
//           >
//             <WhistleIcon className="ScoringApp-football__scoring__period__header__timing__opt__icon" />

//           </div> */}
//     </div>
//   );
// }

// function StatTypesList({
//   fixtureId,
//   fixture,
//   eventId,
//   statGroups,
//   homeTeam,
//   awayTeam,
//   homeLineup,
//   awayLineup,
//   isHome,
//   periods,
//   statTypes,
// }) {
//   const [{ setOverlay, selectedPeriod, timestamp }] = useFootballScoringState();
//   // console.log(selectedPeriod);
//   const isPenaltyShootout = !!selectedPeriod?.metas?.find(
//     (m) => m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
//   );

//   return (
//     <div
//       className={
//         "ScoringApp-football__scoring__stat-types " +
//         (!selectedPeriod || isPenaltyShootout ? "hidden" : "")
//       }
//     >
//       <Card className="ScoringApp-football__scoring__stat-types__groups">
//         {statGroups.map((group) => (
//           <div
//             className="ScoringApp-football__scoring__stat-types__group"
//             key={(isHome ? "home" : "away") + group.label}
//           >
//             <div className="ScoringApp-football__scoring__stat-types__group__label">
//               {group.label.toUpperCase()}
//             </div>

//             <div className="ScoringApp-football__scoring__stat-types__group__types">
//               {group.types
//                 .filter((t) => !t.hidden)
//                 .map((type) => (
//                   <div
//                     key={(isHome ? "home" : "away") + type.label}
//                     className="ScoringApp-football__scoring__stat-types__group__types__type"
//                     onClick={() => {
//                       setOverlay(
//                         <StatInputForm
//                           fixtureId={fixtureId}
//                           fixture={fixture}
//                           eventId={eventId}
//                           selectedStat={type}
//                           homeTeam={homeTeam}
//                           awayTeam={awayTeam}
//                           homeLineup={homeLineup}
//                           awayLineup={awayLineup}
//                           defaultSelectedTeamIndex={isHome ? 0 : 1}
//                           periods={periods}
//                           selectedPeriod={selectedPeriod}
//                           statTypes={statTypes}
//                           defaultTimestamp={timestamp}
//                         />
//                       );
//                     }}
//                   >
//                     {type.label}
//                   </div>
//                 ))}
//             </div>
//           </div>
//         ))}
//       </Card>
//     </div>
//   );
// }

// function ShowMoreStatsListItems({ id }) {
//   // console.log("Show more vars:", variables);

//   const [{ stats, selectedPeriod }, setFootballScoringState] =
//     useFootballScoringState();
//   const [hidden, setHidden] = useState(false);
//   const [loadMore, { called, loading }] = useLazyQuery(FIXTURE_STATS, {
//     onCompleted: (data) => {
//       // console.log("Fetch more complete:", data);
//       setFootballScoringState({
//         stats: {
//           ...stats,
//           [selectedPeriod.id]: [
//             ...stats[selectedPeriod.id],
//             ...data.fixture.stats,
//           ],
//         },
//       });

//       // if (!data.fixture?.stats?.length) {
//       //   setHidden(true);
//       // }
//     },
//   });

//   if (hidden) {
//     return <div />;
//   }

//   return (
//     <div className="ScoringApp-football__scoring__period__list__show-more">
//       {loading ? (
//         <Spinner />
//       ) : (
//         <Card
//           className="ScoringApp-football__scoring__period__list__show-more__btn"
//           onClick={() => {
//             console.log("Clicked");
//             loadMore({
//               variables: {
//                 id,
//                 where: {
//                   fixturePeriod: {
//                     id: {
//                       in: [selectedPeriod.id],
//                     },
//                   },
//                 },
//                 take: 25,
//                 cursor: stats?.[selectedPeriod?.id]?.at(-1).id,
//               },
//             });
//           }}
//         >
//           Show More
//         </Card>
//       )}
//     </div>
//   );
// }

// function StatsListItemRow({ s, i, homeTeam, awayTeam, items, fixture }) {
//   const [{ setOverlay }] = useFootballScoringState();
//   const onDelete = () => {
//     setOverlay(<DeleteStat stat={s} fixture={fixture} />);
//   };
//   return (
//     <div className={"ScoringApp-football__scoring__period__list__item "}>
//       <div className="ScoringApp-football__scoring__period__list__item__section home">
//         {s.organization?.id === homeTeam?.organization.id && (
//           <Fragment>
//             <div className="ScoringApp-football__scoring__period__list__item__section__options">
//               {s.isTemp ? (
//                 <div className="ScoringApp-football__scoring__period__list__item__section__options__option">
//                   <Spinner />
//                 </div>
//               ) : (
//                 <div
//                   className="ScoringApp-football__scoring__period__list__item__section__options__option"
//                   onClick={() => {
//                     onDelete();
//                   }}
//                 >
//                   <TrashIcon
//                     style={{
//                       height: "14px",
//                       stroke: "var(--red-lighter)",
//                     }}
//                   />{" "}
//                 </div>
//               )}
//             </div>
//             <Card className="ScoringApp-football__scoring__period__list__item__section__statements">
//               <Statement stat={s} stats={items} />
//             </Card>
//           </Fragment>
//         )}
//       </div>
//       <Card className="ScoringApp-football__scoring__period__list__item__section__timestamp">
//         {s.timeStamp || "-"}'
//       </Card>
//       <div className="ScoringApp-football__scoring__period__list__item__section away">
//         {s.organization?.id === awayTeam?.organization.id && (
//           <Fragment>
//             <Card className="ScoringApp-football__scoring__period__list__item__section__statements">
//               <Statement stat={s} stats={items} />
//             </Card>
//             <div className="ScoringApp-football__scoring__period__list__item__section__options">
//               {s.isTemp ? (
//                 <div className="ScoringApp-football__scoring__period__list__item__section__options__option">
//                   <Spinner />
//                 </div>
//               ) : (
//                 <div
//                   className="ScoringApp-football__scoring__period__list__item__section__options__option"
//                   onClick={() => {
//                     onDelete();
//                   }}
//                 >
//                   <TrashIcon
//                     style={{
//                       height: "14px",
//                       stroke: "var(--red-lighter)",
//                     }}
//                   />{" "}
//                 </div>
//               )}
//             </div>
//           </Fragment>
//         )}
//       </div>
//     </div>
//   );
// }

// function StatsListItems({
//   selectedPeriod,
//   fixtureId,
//   fixture,
//   homeTeam,
//   awayTeam,
// }) {
//   const [{ stats = {} }, setFootballScoringState] = useFootballScoringState();

//   // console.log(fixtureId, selectedPeriod.id);

//   const { loading, error, data, networkStatus, variables } = useQuery(
//     FIXTURE_STATS,
//     {
//       variables: {
//         id: fixtureId,
//         where: {
//           fixturePeriod: {
//             id: {
//               in: [selectedPeriod.id],
//             },
//           },
//         },
//         take: 25,
//       },
//       // notifyOnNetworkStatusChange: true,
//       onCompleted: (data) => {
//         // console.log("Stats query:", data);
//         if (!stats[selectedPeriod.id]?.length) {
//           setFootballScoringState({
//             stats: {
//               ...stats,
//               [selectedPeriod.id]: data.fixture.stats,
//             },
//           });
//         }
//       },
//     }
//   );

//   if (error) {
//     console.log(error);
//     return <div>Error</div>;
//   }

//   // console.log("Network Status:", networkStatus);

//   if (loading || !data) {
//     return (
//       <div>
//         <Spinner />
//       </div>
//     );
//   }

//   // const stats = data?.fixture?.stats || [];
//   const items = stats[selectedPeriod.id] || [];

//   // console.log(stats);

//   return (
//     <div className="ScoringApp-football__scoring__period__list">
//       {items
//         ?.filter((s) => {
//           return showStat(s, items);
//         })
//         .map((s, i) => (
//           <StatsListItemRow
//             key={s.id + "-" + i}
//             s={s}
//             i={i}
//             homeTeam={homeTeam}
//             awayTeam={awayTeam}
//             items={items}
//             fixture={fixture}
//           />
//         ))}
//       {!!items.length && <ShowMoreStatsListItems id={fixtureId} />}
//     </div>
//   );
// }

// function StatsList({
//   id,
//   homeTeam,
//   awayTeam,
//   periods,
//   fixture,
//   statTypes,
//   homeLineup,
//   awayLineup,
//   eventId,
// }) {
//   const [{ stats, selectedPeriod, cache }, setFootballScoringState] =
//     useFootballScoringState();

//   const isPenaltyShootout = !!selectedPeriod?.metas?.find(
//     (m) => m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
//   );
//   // console.log(cache);

//   return (
//     <Card className="ScoringApp-football__scoring__period">
//       <div className="ScoringApp-football__scoring__period__header">
//         <NewDropdown
//           target={
//             <div className="ScoringApp-football__scoring__period__header__selected-period">
//               {selectedPeriod?.label || "Select a period to get started"}{" "}
//               <ChevronDown className="ScoringApp-football__scoring__period__header__selected-period__icon" />
//             </div>
//             // <div className="selected-period-selector">
//             //   {selectedPeriod?.label || "Select period to get started"}
//             //   <ChevronDown className="selected-period-selector__icon" />
//             // </div>
//           }
//           menu={({ setShowMenu }) => (
//             <Card className="ScoringApp-football__scoring__period__header__selected-period__menu">
//               {periods.map((p) => (
//                 <div
//                   key={p.id}
//                   className="ScoringApp-football__scoring__period__header__selected-period__menu__item"
//                   onClick={() => {
//                     // setRunning(false);
//                     // changePeriod(p.id);
//                     setFootballScoringState({
//                       selectedPeriod: p,
//                       timestamp:
//                         cache?.periods?.[p.id] ||
//                         sumToPeriod(periods, p.id) ||
//                         0,
//                       running: false,
//                     });
//                     setShowMenu(false);
//                   }}
//                 >
//                   {p.label}
//                 </div>
//               ))}
//             </Card>
//           )}
//         />
//         {selectedPeriod && !isPenaltyShootout && (
//           <div className="ScoringApp-football__scoring__period__header__timing">
//             <TimerNew />
//           </div>
//         )}
//       </div>

//       {selectedPeriod && (
//         <Fragment>
//           {isPenaltyShootout ? (
//             <PenaltyShootout
//               statTypes={statTypes}
//               selectedPeriod={selectedPeriod}
//               fixtureId={id}
//               fixture={fixture}
//               homeTeam={homeTeam}
//               awayTeam={awayTeam}
//               homeLineup={homeLineup}
//               awayLineup={awayLineup}
//               periods={periods}
//               eventId={eventId}
//             />
//           ) : (
//             <StatsListItems
//               homeTeam={homeTeam}
//               awayTeam={awayTeam}
//               selectedPeriod={selectedPeriod}
//               fixtureId={id}
//               fixture={fixture}
//             />
//           )}
//         </Fragment>
//       )}
//     </Card>
//   );
// }

// function Score({ id, homeTeam, awayTeam }) {
//   const { loading, error, data } = useQuery(
//     FIXTURE({
//       selectionSet: `
//     highlights{
//       id
//       statType {
//         id
//         name
//       }
//       organization {
//         id
//         name
//       }
//     }
//   `,
//     }),
//     {
//       variables: {
//         id,
//       },
//     }
//   );

//   if (error) {
//     console.log(error);
//     return <div className="ScoringApp-football__scoring__header__score" />;
//   }

//   if (loading || !data) {
//     return <div className="ScoringApp-football__scoring__header__score" />;
//   }

//   const stats = data?.fixture?.highlights || [];
//   const homeScore = stats.filter(
//     (s) =>
//       (s.statType.name === "Goal" &&
//         s.organization?.id === homeTeam.organization?.id) ||
//       (s.statType.name === "Own Goal" &&
//         s.organization?.id === awayTeam.organization?.id)
//   ).length;

//   const awayScore = stats.filter(
//     (s) =>
//       (s.statType.name === "Goal" &&
//         s.organization?.id === awayTeam.organization?.id) ||
//       (s.statType.name === "Own Goal" &&
//         s.organization?.id === homeTeam.organization?.id)
//   ).length;

//   console.log("Score:", stats, homeScore);

//   return (
//     <div className="ScoringApp-football__scoring__header__score">
//       <div className="ScoringApp-football__scoring__header__score__value">
//         {homeScore}
//       </div>

//       <div className="ScoringApp-football__scoring__header__score__seperator">
//         :
//       </div>

//       <div className="ScoringApp-football__scoring__header__score__value">
//         {awayScore}
//       </div>
//     </div>
//   );
// }

// function Stats({
//   id,
//   homeTeam,
//   awayTeam,
//   periods,
//   fixture,
//   statTypes,
//   homeLineup,
//   awayLineup,
//   eventId,
// }) {
//   return (
//     <div className="ScoringApp-football__scoring__middle-column">
//       <div className="ScoringApp-football__scoring__header">
//         <div className="ScoringApp-football__scoring__header__team home">
//           <div className="ScoringApp-football__scoring__header__team__img">
//             {homeTeam?.organization?.profilePhoto && (
//               <Avatar media={homeTeam.organization.profilePhoto} />
//             )}
//           </div>

//           <div className="ScoringApp-football__scoring__header__team__name">
//             {homeTeam?.organization?.name}
//           </div>
//         </div>

//         <Score id={id} homeTeam={homeTeam} awayTeam={awayTeam} />

//         <div className="ScoringApp-football__scoring__header__team away">
//           <div className="ScoringApp-football__scoring__header__team__img">
//             {awayTeam?.organization?.profilePhoto && (
//               <Avatar media={awayTeam.organization.profilePhoto} />
//             )}
//           </div>

//           <div className="ScoringApp-football__scoring__header__team__name">
//             {awayTeam?.organization?.name}
//           </div>
//         </div>
//       </div>

//       <StatsList
//         id={id}
//         homeTeam={homeTeam}
//         awayTeam={awayTeam}
//         periods={periods}
//         fixture={fixture}
//         statTypes={statTypes}
//         homeLineup={homeLineup}
//         awayLineup={awayLineup}
//         eventId={eventId}
//       />
//     </div>
//   );
// }

function ScoringHeader({ id, setStep, homeParticipant, awayParticipant }) {
  const history = useHistory();
  return (
    <div className="ScoringApp-cricket__scoring__header">
      <div className="ScoringApp-cricket__scoring__header__left-column">
        <div
          className="ScoringApp-football__header__nav-left__back-btn"
          onClick={() => {
            history.replace("/app/fixture/" + id);
          }}
        >
          <ChevronLeft className="ScoringApp-football__header__nav-left__back-btn__icon" />
          <div className="ScoringApp-football__header__nav-left__back-btn__text">
            Back to fixture
          </div>
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__header__middle-column">
        <div className="ScoringApp-cricket__scoring__header__middle-column__team home">
          <div className="ScoringApp-cricket__scoring__header__middle-column__team__img">
            {(homeParticipant?.organization?.profilePhoto ||
              homeParticipant?.profile?.profilePhoto) && (
              <Avatar
                media={
                  homeParticipant?.organization?.profilePhoto ||
                  homeParticipant?.profile.profilePhoto
                }
              />
            )}
          </div>

          <div className="ScoringApp-cricket__scoring__header__middle-column__team__name">
            {homeParticipant?.organization?.name ||
              homeParticipant?.profile?.name}
          </div>
        </div>

        <div className="ScoringApp-cricket__scoring__header__score">
          <div className="ScoringApp-cricket__scoring__header__score__value"></div>

          <div className="ScoringApp-cricket__scoring__header__score__seperator">
            vs
          </div>

          <div className="ScoringApp-cricket__scoring__header__score__value"></div>
        </div>

        <div className="ScoringApp-cricket__scoring__header__middle-column__team away">
          <div className="ScoringApp-cricket__scoring__header__middle-column__team__img">
            {(awayParticipant?.organization?.profilePhoto ||
              awayParticipant?.profile?.profilePhoto) && (
              <Avatar
                media={
                  awayParticipant?.organization?.profilePhoto ||
                  awayParticipant?.profile.profilePhoto
                }
              />
            )}
          </div>

          <div className="ScoringApp-cricket__scoring__header__middle-column__team__name">
            {awayParticipant?.organization?.name ||
              awayParticipant?.profile?.name}
          </div>
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__header__right-column">
        <div
          className="ScoringApp-football__header__nav-right__nav-btn square"
          onClick={() => {
            setStep(0);
          }}
        >
          <SettingsIcon className="ScoringApp-football__header__nav-right__nav-btn__icon" />
        </div>
        <Button
          className="primary green medium ScoringApp-football__header__nav-right__nav-btn"
          onClick={() => {
            setStep(2);
          }}
        >
          Verify & Publish
        </Button>
      </div>
    </div>
  );
}

function ScoringBody({ periods, homeParticipant, awayParticipant }) {
  const [{ selectedPeriod, participantType, fixtureId }] =
    useBadmintonScoringState();

  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
    badmintonLiveScoring{
      id
      periodScores{
        id
        scores{
          id
          value          
        }
      }
    }
  `,
    }),
    {
      variables: { id: fixtureId },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__body">
        <div
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 13,
            height: "160px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      </div>
    );
  }

  const periodScores = data?.fixture?.badmintonLiveScoring?.periodScores;
  console.log("PeriodScores:", periodScores);

  return (
    <div className="ScoringApp-cricket__scoring__body">
      <div className="ScoringApp-cricket__scoring__body__left-column">
        <CurrentSet
          periodScores={periodScores}
          periods={periods}
          homeParticipant={homeParticipant}
          awayParticipant={awayParticipant}
        />

        {selectedPeriod && (
          <BoxScore
            periodScores={periodScores}
            periods={periods}
            homeParticipant={homeParticipant}
            awayParticipant={awayParticipant}
            participantType={participantType}
          />
        )}
      </div>
      <div className="ScoringApp-cricket__scoring__body__right-column">
        {selectedPeriod && (
          <StatsList
            homeParticipant={homeParticipant}
            awayParticipant={awayParticipant}
            participantType={participantType}
          />
        )}
      </div>
    </div>
  );
}

export default function Scoring({ id, setStep }) {
  const history = useHistory();
  const [overlay, setOverlay] = useState(false);

  const [updateCache] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
    statsCacheState
  `,
    })
  );

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      completed
      statsCacheState
      event{
        id
        participantType
        sport{
          id
          statTypes{
            id
            name
            group {
              id
              name
            }
            relationshipsFrom {
              id
              to {
                id
                name
              }
              type {
                id
                name
              }
            }
            relationshipsTo {
              id
              from {
                id
                name
              }
              type {
                id
                name
              }
            }
          }
        }
      }
      periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
      }
      roles{
        id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      
  `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-football__scoring">
        <div />
        <Spinner color="#ffffff" />
      </div>
    );
  }

  // console.log(data?.fixture?.roles);

  const participants = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );

  const homeParticipant = participants?.find((p) => !p.order);

  const awayParticipant = participants?.find((p) => p.order);

  // const players = data?.fixture?.roles?.filter(
  //   (r) => r.type.name === "LINEUP PLAYER"
  // );

  // const homeLineup = players?.filter(
  //   (r) => r.organization?.id === homeTeam?.organization?.id
  // );

  // const awayLineup = players?.filter(
  //   (r) => r.organization?.id === awayTeam?.organization?.id
  // );

  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  const eventId = data?.fixture?.event?.id;
  const participantType = data?.fixture?.event?.participantType;
  const statTypes = data?.fixture?.event?.sport?.statTypes;
  // const stats = data?.fixture?.stats;
  const cache = data?.fixture?.statsCacheState;

  const initialState = {
    selectedPeriod: cache?.selectedPeriodId
      ? periods.find((x) => x.id === cache.selectedPeriodId)
      : false,
    fixtureId: id,
    eventId,
    setOverlay: setOverlay,
    timestamp: cache?.periods?.[cache?.selectedPeriodId] || 0,
    currentServer: cache?.currentServer || "",
    cache,
    participantType,
    statTypes,
    updateCache,
  };

  return (
    <BadmintonStatsContextProvider initialState={initialState}>
      <div className="ScoringApp-cricket__scoring">
        <ScoringHeader
          id={id}
          setStep={setStep}
          homeParticipant={homeParticipant}
          awayParticipant={awayParticipant}
        />

        <ScoringBody
          periods={periods}
          homeParticipant={homeParticipant}
          awayParticipant={awayParticipant}
        />
        {/* <ScoringHeader id={id} setStep={setStep} /> */}
      </div>
    </BadmintonStatsContextProvider>
  );
}
