import "./PeriodSettings.css";
import { Fragment, useState } from "react";
import Card from "../../../components/Card";
import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/functions";
import Spinner from "../../../components/Spinner";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";

const periodsSelectionSet = `
  periods {
    id
    label
    order
    startDate
    endDate
    duration
    metas {
      id
      label
      stringValue
    }
    canDelete
  }
`;

function CreateOrEditPeriod({
  id,
  defaultLabel,
  defaultDuration,
  order,
  fixtureId,
  dismiss,
}) {
  const [label, setLabel] = useState(defaultLabel || "");
  const [duration, setDuration] = useState(defaultDuration || 0);
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: periodsSelectionSet,
    }),
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.CreateOrEditPeriod",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  return (
    <form
      className="ScoringApp-football__settings__period-settings__form"
      onSubmit={(e) => {
        e.preventDefault();

        if (loading) return;
        const vars = {};

        if (id) {
          vars.update = {
            where: { id },
            data: {
              label: label,
              order: order,
              duration: Number(duration) || 0,
            },
          };
        } else {
          vars.create = [
            {
              label: label,
              order: order,
              duration: Number(duration) || 0,
            },
          ];
        }

        updateFixture({
          variables: {
            where: { id: fixtureId },
            data: {
              periods: vars,
            },
          },
          update: (cache, { data: { updateFixture } }) => {
            // console.log(updateFixture);
            cache.modify({
              id: cache.identify(updateFixture),
              fields: {
                periods() {
                  return updateFixture.periods;
                },
              },
            });

            dismiss();
          },
        });
      }}
    >
      <div className="ScoringApp-football__settings__period-settings__form__row">
        <div className="ScoringApp-football__settings__period-settings__form__row__group text">
          <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
            Label
          </div>
          <input
            type="text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            className="ScoringApp-football__settings__period-settings__form__row__group__input label"
            placeholder="Enter label here"
          />
        </div>

        <div className="ScoringApp-football__settings__period-settings__form__row__group">
          <div className="ScoringApp-football__settings__period-settings__form__row__group__label">
            Minutes
          </div>
          <input
            type="number"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            className="ScoringApp-football__settings__period-settings__form__row__group__input duration"
          />
        </div>

        <div className="ScoringApp-football__settings__period-settings__form__row__options">
          <button
            type="submit"
            className={
              "ScoringApp-football__settings__period-settings__form__row__options__option submit " +
              (loading ? "disabled" : "")
            }
          >
            {loading ? (
              <Spinner size={12} color={"var(--green-darker)"} />
            ) : (
              <CheckIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
            )}
          </button>
          <div
            className={
              "ScoringApp-football__settings__period-settings__form__row__options__option cancel " +
              (loading ? "disabled" : "")
            }
            onClick={() => {
              dismiss();
            }}
          >
            <TimesIcon className="ScoringApp-football__settings__period-settings__form__row__options__option__icon" />
          </div>
        </div>
      </div>
    </form>
  );
}

function Period({ period, index, fixtureId }) {
  const [editing, setEditing] = useState(false);

  const [mutate, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: periodsSelectionSet,
    }),
    {
      update: (cache, { data: { updateFixture } }) => {
        // console.log(updateFixture);
        cache.modify({
          id: cache.identify(updateFixture),
          fields: {
            periods() {
              return updateFixture.periods;
            },
          },
        });
      },
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.Period",
        //     mutation: "UPDATE_FIXTURE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  if (editing) {
    return (
      <CreateOrEditPeriod
        id={period.id}
        defaultLabel={period.label}
        defaultDuration={period.duration}
        order={period.order}
        fixtureId={fixtureId}
        dismiss={() => {
          setEditing(false);
        }}
      />
    );
  }

  return (
    <div className="ScoringApp-football__settings__period-settings__period">
      <div className="ScoringApp-football__settings__period-settings__period__order">
        {index + 1}.
      </div>
      <div className="ScoringApp-football__settings__period-settings__period__details">
        {period.label} ({period.duration || 0} Mins)
      </div>
      <div className="ScoringApp-football__settings__period-settings__period__options">
        <div
          className="ScoringApp-football__settings__period-settings__period__options__option edit"
          onClick={() => {
            setEditing(true);
          }}
        >
          <PencilIcon className="ScoringApp-football__settings__period-settings__period__options__option__icon" />
        </div>

        <div
          className={
            "ScoringApp-football__settings__period-settings__period__options__option delete " +
            (!period.canDelete || loading ? "disabled" : "")
          }
          onClick={() => {
            if (!period.canDelete || loading) return;

            mutate({
              variables: {
                where: { id: fixtureId },
                data: {
                  periods: {
                    deleteMany: [{ id: period.id }],
                  },
                },
              },
            });
          }}
        >
          <TrashIcon className="ScoringApp-football__settings__period-settings__period__options__option__icon" />
        </div>
      </div>
    </div>
  );
}

export default function PeriodSettings({ id }) {
  const [adding, setAdding] = useState(false);
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: periodsSelectionSet,
    }),
    {
      variables: {
        id,
      },
    }
  );

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.PeriodSettings",
    //     query: "FIXTURE_STATS",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  const periods =
    data?.fixture?.periods
      ?.filter(
        (p) =>
          !p.metas.find(
            (m) =>
              m.label === "periodType" && m.stringValue === "PENALTY SHOOTOUT"
          )
      )
      ?.sort((a, b) => a.order - b.order) || [];

  return (
    <Card className="ScoringApp-football__settings__period-settings">
      <div className="ScoringApp-football__settings__period-settings__title">
        Edit periods in this fixture
      </div>

      <div className="ScoringApp-football__settings__period-settings__body">
        {loading || !data ? (
          <div className="ScoringApp-football__settings__period-settings__body__loading">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {periods.map((p, i) => (
              <Period key={p.id} period={p} index={i} fixtureId={id} />
            ))}

            {adding ? (
              <CreateOrEditPeriod
                dismiss={() => {
                  setAdding(false);
                }}
                order={periods.length}
                fixtureId={id}
              />
            ) : (
              <div
                className="ScoringApp-football__settings__period-settings__body__add-period"
                onClick={() => {
                  setAdding(true);
                }}
              >
                + Add a period
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Card>
  );
}
