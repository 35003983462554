import "./MobileLandingLayout.css";
import { Switch, Route } from "react-router-dom";

import Landing from "../../mobileScreens/Landing";
import Auth from "../../mobileScreens/Auth";
import ProfileOnboarding from "../../mobileScreens/ProfileOnboarding";
import ForgotPassword from "../../mobileScreens/ForgotPassword";
import ResetPassword from "../../mobileScreens/ResetPassword";

function MobileLandingLayout() {
  return (
    <div className="landing-layout">
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>

        <Route path="/auth">
          <Auth />
        </Route>

        <Route path="/onboarding">
          <ProfileOnboarding />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/reset-password/:token">
          <ResetPassword />
        </Route>
      </Switch>
    </div>
  );
}

export default MobileLandingLayout;
