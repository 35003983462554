// V1
import "./Fixture.css";
import {
  FixtureSummary,
  FixtureLineups,
  FixturePhotos,
  FixtureVideos,
  FixtureReport,
  FixtureStats,
} from "../../components/Fixture";
import Spinner from "../../components/Spinner";
import AppLink from "../../components/AppLink";
import ScoreFixture from "../../components/Scoring/ScoreFixture";
import { useQuery } from "@apollo/client";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
// import { FIXTURE } from "../../api/fixture";
import { CAN_MANAGE_FIXTURE } from "../../api/user";
import { useAppState } from "../../utils/appState";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import moment from "moment";
import useRouterQuery from "../../utils/useRouterQuery";
import { usePageTracking } from "../../utils/usePageTracking";
import Avatar from "../../components/Avatar/Avatar";
import captureException from "../../utils/captureException";
import { FIXTURE } from "../../api/functions";

function FixtureHighlights({ highlights, isHome }) {
  // console.log("HIGHLIHJTS:", highlights);

  const goals = highlights
    ?.filter(
      (h) => h.statType.name === "Goal" || h.statType.name === "Own Goal"
    )
    .sort((a, b) => a.timeStamp - b.timeStamp);
  return (
    <div className="highlights">
      <div className="highlights__item">
        {!!goals.length && !isHome && (
          <FootballIcon className={"highlights__item__icon away"} />
        )}
        {goals.map((h, i) => (
          <Link to={"/app/profile/" + h.profile?.id}>
            <span
              key={h.id}
              style={{ marginRight: "8px", marginBottom: "8px" }}
            >
              {h.profile?.name} ({h.timeStamp}'){" "}
              {h.relationshipsFrom.find(
                (r) => r.to?.statType?.name === "Penalty Scored"
              )
                ? "(P)"
                : ""}
              {h.statType?.name === "Own Goal" ? "(OG)" : ""}{" "}
              {i >= goals.length - 1 ? "" : ","}{" "}
            </span>
          </Link>
        ))}
        {!!goals.length && isHome && (
          <FootballIcon className="highlights__item__icon home" />
        )}
      </div>
    </div>
  );
}

const fixtureTabs = [
  {
    label: "Summary",
    component: FixtureSummary,
    hide: (fixture) => false,
    key: "summary",
  },
  {
    label: "Lineups",
    component: FixtureLineups,
    hide: (fixture) =>
      fixture.event.participantType !== "TEAM" ||
      fixture.event.sport.name === "Badminton" ||
      fixture.event.sport.name === "Tennis",
    key: "lineups",
  },
  {
    label: "Photos",
    component: FixturePhotos,
    hide: (fixture) => false,
    key: "photos",
  },
  {
    label: "Videos",
    component: FixtureVideos,
    hide: (fixture) => false,
    key: "videos",
  },
  {
    label: "Statistics",
    component: FixtureStats,
    hide: (fixture) => false,
    key: "statistics",
  },
  {
    label: "Report",
    component: FixtureReport,
    hide: (fixture) => false,
    key: "report",
  },
];

export default function Fixture() {
  usePageTracking({ title: "Fixture" });
  const [, setAppState] = useAppState();
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const tab = query.get("t") || "summary";
  const selectedTab = fixtureTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const authQuery = useQuery(CAN_MANAGE_FIXTURE, {
    variables: { id },
  });

  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
      scheduledStart
      scheduledEnd
      event {
        id
        sport {
          id
          name
        }
        participantType
        name        
      }
      roles(where:{type:{name:"PARTICIPANT"}}) {
        id
        order
        type {
          id
          name
        }        
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }        
      }
      placements {
        id
        value
        valueString
        profile {
          id
        }
        organization {
          id
        }
      }
      highlights {
        id
        timeStamp
        statType {
          id
          name
        }
        profile {
          id
          name
        }
        organization {
          id
          name
        }
        relationshipsFrom {
          id
          to {
            id
            statType {
              id
              name
            }            
          }
        }        
      }    
    `,
    }),
    { variables: { id } }
  );

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Fixture",
        query: "CAN_MANAGE_FIXTURE",
      },
    });
    console.log(error);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;
  const canManageFixture = authQuery?.data?.canManageFixture?.authorized;
  const participantType = fixture?.event?.participantType;
  // console.log("FIXTURE:", fixture);
  const participants = fixture?.roles
    ?.filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeScore = fixture?.placements?.find((p) => {
    if (participantType === "INDIVIDUAL") {
      return p.profile?.id === participants[0].profile?.id;
    } else {
      return p.organization?.id === participants[0].organization?.id;
    }
  });

  const awayScore = fixture?.placements?.find((p) => {
    if (participantType === "INDIVIDUAL") {
      return p.profile?.id === participants[1].profile?.id;
    } else {
      return p.organization?.id === participants[1].organization?.id;
    }
  });

  const homeLiveScore = fixture?.highlights?.filter(
    (h) =>
      (h.statType?.name === "Goal" &&
        h.organization?.id === participants[0].organization?.id) ||
      (h.statType?.name === "Own Goal" &&
        h.organization?.id === participants[1].organization?.id)
  ).length;

  const awayLiveScore = fixture?.highlights?.filter(
    (h) =>
      (h.statType?.name === "Goal" &&
        h.organization?.id === participants[1].organization?.id) ||
      (h.statType?.name === "Own Goal" &&
        h.organization?.id === participants[0].organization?.id)
  ).length;

  const isLive =
    fixture?.scheduledStart &&
    fixture?.scheduledEnd &&
    moment().isAfter(fixture.scheduledStart) &&
    moment().isBefore(fixture.scheduledEnd);

  const completedText = {
    Cricket: "Final",
    "Box Cricket": "Final",
    Badminton: "Final",
  };

  // console.log(fixture?.roles);

  // console.log("BOX SCOER:", fixture.badmintonBoxScore, fixture.tennisBoxScore);

  return (
    <div className="App-content">
      <div className="fixture-page">
        {canManageFixture && (
          <div
            className="can-manage-fixture"
            onClick={() => {
              if (
                fixture.event?.sport?.name === "Cricket" ||
                fixture.event?.sport?.name === "Box Cricket" ||
                fixture.event?.sport?.name === "Badminton" ||
                fixture.event?.sport?.name === "Football"
              ) {
                history.push("/score/" + id);
              } else {
                setAppState({
                  modal: (
                    <ScoreFixture
                      id={id}
                      sport={fixture.event.sport.name}
                      fixture={fixture}
                    />
                  ),
                });
              }
            }}
          >
            Click here to add stats and scores for this fixture.
          </div>
        )}
        <div
          className="event-head"
          onClick={() => {
            history.push("/app/event/" + fixture.event.id);
          }}
        >
          {fixture.event.name}
        </div>
        <div className="head">
          <div className="fixture-org home-org">
            <div className="meta">
              <div className="logo-large">
                {(participants[0].organization?.profilePhoto ||
                  participants[0].profile?.profilePhoto) && (
                  <Avatar
                    media={
                      participants[0].organization?.profilePhoto ||
                      participants[0].profile?.profilePhoto
                    }
                  />
                )}
              </div>
              <AppLink
                to={
                  `/app/${participantType === "TEAM" ? "page" : "profile"}/` +
                  (participants[0].organization?.id ||
                    participants[0].profile?.id)
                }
              >
                <div className="org-name">
                  {participants[0].organization?.name ||
                    participants[0].profile?.name}
                </div>
              </AppLink>

              {fixture.event.sport.name !== "Badminton" ? (
                <div className="result">
                  {homeScore
                    ? homeScore.valueString || homeScore.value || 0
                    : homeLiveScore || (awayLiveScore ? "0" : "")}
                </div>
              ) : (
                <div className="result"></div>
              )}
            </div>
            <FixtureHighlights
              isHome={true}
              highlights={fixture.highlights?.filter(
                (x) =>
                  (x.organization?.id === participants[0]?.organization?.id &&
                    x.statType?.name === "Goal") ||
                  (x.organization?.id === participants[1]?.organization?.id &&
                    x.statType?.name === "Own Goal")
              )}
            />
          </div>
          <div className="seperator">
            {fixture.event.sport.name === "Badminton" ? (
              <div className="badminton-score">
                {" "}
                {fixture.badmintonBoxScore
                  ?.filter((x) => x.homeScore || x.awayScore)
                  .map((x) => x.homeScore + "-" + x.awayScore)
                  .join(", ")}{" "}
              </div>
            ) : fixture.event.sport.name === "Tennis" ? (
              <div className="badminton-score">
                {" "}
                {fixture.tennisBoxScore
                  ?.filter((x) => x.homeScore || x.awayScore)
                  .map((x) => x.homeScore + "-" + x.awayScore)
                  .join(", ")}{" "}
              </div>
            ) : (
              <div>
                {fixture.completed ? (
                  <>{completedText[fixture.event?.sport?.name] || "FT"}</>
                ) : isLive ? (
                  <div className="live-indicator">LIVE</div>
                ) : (
                  "-"
                )}
              </div>
            )}
          </div>
          <div className="fixture-org away-org">
            <div className="meta">
              <div className="logo-large">
                {(participants[1].organization?.profilePhoto ||
                  participants[1].profile?.profilePhoto) && (
                  <Avatar
                    media={
                      participants[1].organization?.profilePhoto ||
                      participants[1].profile?.profilePhoto
                    }
                  />
                )}
              </div>
              <AppLink
                to={
                  `/app/${participantType === "TEAM" ? "page" : "profile"}/` +
                  (participants[1].organization?.id ||
                    participants[1].profile?.id)
                }
              >
                <div className="org-name">
                  {participants[1].organization?.name ||
                    participants[1].profile?.name}
                </div>
              </AppLink>

              {fixture.event.sport.name !== "Badminton" ? (
                <div className="result">
                  {awayScore
                    ? awayScore.valueString || awayScore.value || 0
                    : awayLiveScore || (homeLiveScore ? "0" : "")}
                </div>
              ) : (
                <div className="result"></div>
              )}
            </div>
            <FixtureHighlights
              isHome={false}
              highlights={fixture.highlights.filter(
                (x) =>
                  (x.organization?.id === participants[1]?.organization?.id &&
                    x.statType?.name === "Goal") ||
                  (x.organization?.id === participants[0]?.organization?.id &&
                    x.statType.name === "Own Goal")
              )}
            />
          </div>
        </div>

        <div className="tabs">
          {fixtureTabs.map((t, i) => {
            if (t.hide(fixture)) {
              return <span />;
            } else {
              return (
                <div
                  key={"fixture-tab-" + t.key}
                  className={
                    "tab hover-pointer " +
                    (selectedTab.key === t.key ? "active" : "")
                  }
                  onClick={() => {
                    history.replace(location.pathname + "?t=" + t.key);
                  }}
                >
                  {t.label}
                </div>
              );
            }
          })}
        </div>

        <div className="body">
          <SelectedComponent id={id} canManageFixture={canManageFixture} />
        </div>
      </div>
    </div>
  );
}
