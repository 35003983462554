import "./LineupSettings.css";
import Card from "../../../components/Card";
import NewDropdown from "../../../components/NewDropdown";
import Pitch from "../../../components/Pitch";
import { useState, Fragment } from "react";
import { FIXTURE } from "../../../api/functions";
import {
  UPDATE_FIXTURE_ROLE,
  CREATE_FIXTURE_ROLE,
  DELETE_FIXTURE_ROLE,
} from "../../../api/fixture";
import { FORMATIONS } from "../../../api/sport";
import { EVENT_REGISTRATIONS } from "../../../api/event";
import { useQuery, useMutation, gql } from "@apollo/client";
import Spinner from "../../../components/Spinner";
import Avatar from "../../../components/Avatar";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as ListIcon } from "../../../icons/list.svg";
import { ReactComponent as PitchIcon } from "../../../icons/pitch.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";

function LineupsListItem({
  player,
  fixture,
  team,
  subtype = "STARTER",
  disable = () => false,
  onItemClick = () => {},
}) {
  const disabled = disable(player?.profile?.id);
  console.log(player?.profile?.id, disabled);

  return (
    <div
      className={"lineup-list-item " + (disabled ? "disabled" : "")}
      onClick={() => {
        if (disabled) return;
        // console.log(player.jerseyNum);
        onItemClick(player?.profile?.id, player?.jerseyNum);
      }}
    >
      <div className="image">
        {player.profile?.profilePhoto && (
          <Avatar media={player.profile.profilePhoto} />
        )}
      </div>
      <div className="name">
        <div className="name__primary">{player?.profile?.name}</div>
        <div className="name__secondary">@{player?.profile?.handle}</div>
      </div>
      <div className="num">{player?.jerseyNum}</div>
      {/* <div className="checkbox">{loading && <Spinner />}</div> */}
      {disabled && <div className="disabled" />}
    </div>
  );
}

function LineupList({
  fixture,
  team,
  subtype,
  disable,
  label,
  onItemClick,
  setOverlay,
}) {
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: {
      id: fixture.event.id,
      where: { organization: { id: team.organization.id } },
    },
  });

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.LineupList",
    //     query: "EVENT_RIGISTRATIONS",
    //   },
    // });
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="lineups-page">
        <div className="lineup-editor">
          <Spinner />
        </div>
      </div>
    );
  }

  const registrations = data.event.registrations;

  return (
    <div className="fixture-lineups-list-container">
      <div className="fixture-lineups-list-container__header">
        {label || "Select Starters"}
        <div
          className="dismiss-btn"
          onClick={() => {
            setOverlay(false);
            // dismiss();
          }}
        >
          Done
        </div>
      </div>
      <div className="lineup-list">
        {!registrations?.length && (
          <div className="no-data">No players registered for this team.</div>
        )}
        {registrations.map((p) => (
          <LineupsListItem
            key={p.id}
            player={p}
            fixture={fixture}
            team={team}
            subtype={subtype}
            disable={disable}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
}

function EditJerseyNumber({ role, canManageFixture, players }) {
  // console.log(players);

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(role.order || "");
  const [updateFixtureRole, { loading }] = useMutation(UPDATE_FIXTURE_ROLE, {
    update: (cache, { data: { updateFixtureRole } }) => {
      cache.modify({
        id: cache.identify(role),
        fields: {
          order() {
            return updateFixtureRole.order;
          },
        },
      });

      setEditing(false);
    },
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreFootball.Settings.EditJerseyNumber",
      //     mutation: "UPDATE_FIXTURE_ROLE",
      //   },
      // });
      console.log(error);
      setError(true);
    },
  });

  return (
    <Fragment>
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (loading) {
              return;
            }

            if (value && Number(value) === role.order) {
              setEditing(false);
            }

            const numTaken =
              value &&
              Number(value) &&
              players.find((x) => x.order === Number(value));
            if (numTaken) {
              setError(true);
              return;
            }
            updateFixtureRole({
              variables: {
                where: { id: role.id },
                data: {
                  order: Number(value),
                },
              },
            });
          }}
        >
          <input
            type="text"
            className={
              "starters__body__list__item__option placeholder " +
              (error ? "error" : "")
            }
            value={value}
            onChange={(e) => {
              setError(false);
              setValue(e.target.value);
            }}
          />
        </form>
      ) : (
        <div
          className="starters__body__list__item__option placeholder"
          onClick={() => {
            setEditing(true);
          }}
        >
          {typeof role.order === "number" ? role.order : "J.Num"}
        </div>
      )}
    </Fragment>
  );
}

function StartersList({ team, players, fixture, onItemClick }) {
  return (
    <div className="starters__body__list">
      {team.formation ? (
        <div className="starters__body__list__items">
          {team.formation?.positions
            .map((p) => ({
              ...p,
              role: players.find(
                (r) =>
                  r.position?.id === p.id &&
                  r.subtypes.find((x) => x.name === "STARTER")
              ),
            }))
            .map((p) => (
              <div key={p.id} className="starters__body__list__item">
                {p.role && (
                  <div
                    className="starters__body__list__item__option delete"
                    onClick={() => {
                      onItemClick(p);
                    }}
                    style={{ marginLeft: 0, marginRight: "8px" }}
                  >
                    <TrashIcon className="starters__body__list__item__option__icon" />
                  </div>
                )}
                <div className="starters__body__list__item__image">
                  {p.role?.profile?.profilePhoto && (
                    <Avatar media={p.role.profile.profilePhoto} />
                  )}
                </div>
                <div className="starters__body__list__item__name">
                  {p.role ? (
                    p.role.profile?.name
                  ) : (
                    <div
                      onClick={() => {
                        onItemClick(p);
                      }}
                    >
                      {"Select player"}
                    </div>
                  )}
                </div>
                <div className="starters__body__list__item__options">
                  {p.role && (
                    <EditJerseyNumber role={p.role} players={players} />
                  )}

                  <div className="starters__body__list__item__option placeholder">
                    {p.positionType.shortName}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="starters__body__list__items">
          {/* {!players.filter((p) => {
            return p.subtypes.find((x) => x.name === "STARTER");
          }).length && <div className="no-data">No starters added yet.</div>} */}
          {players
            .filter((p) => {
              return p.subtypes.find((x) => x.name === "STARTER");
            })
            .map((p) => (
              <div key={p.id} className="starters__body__list__item">
                <div className="starters__body__list__item__image"></div>
                <div className="starters__body__list__item__name">
                  {p.profile?.name}
                </div>
                <div className="starters__body__list__item__options">
                  <EditJerseyNumber role={p} players={players} />

                  <div
                    className="starters__body__list__item__option delete"
                    onClick={() => {
                      onItemClick({ role: p });
                    }}
                  >
                    <TrashIcon className="starters__body__list__item__option__icon" />
                  </div>
                </div>
              </div>
            ))}
          <div
            className="starters__body__list__item"
            onClick={() => {
              onItemClick({});
            }}
          >
            <div className="starters__body__list__item__image"></div>
            <div className="starters__body__list__item__name hover-pointer">
              Add Starter
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function FormationsList({ sportId, fixtureRole, setShowMenu }) {
  const { loading, error, data } = useQuery(FORMATIONS, {
    variables: { where: { sport: { id: sportId } } },
  });

  const [updateFixtureRole, { loading: submitting }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      update: (cache, { data: { updateFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixtureRole),
          fields: {
            formation(_, { INVALIDATE }) {
              if (!updateFixtureRole.formation) {
                return INVALIDATE;
              }
              const newFormationRef = cache.writeFragment({
                data: updateFixtureRole.formation,
                fragment: gql`
                  fragment NewFormation on Formation {
                    id
                    name
                    positions {
                      id
                      positionType {
                        id
                        shortName
                      }
                      x
                      y
                    }
                  }
                `,
              });

              return newFormationRef;
            },
          },
        });

        setShowMenu(false);
      },
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.FormationsList",
        //     mutation: "UPDATE_FIXTURE_ROLE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.FormationsList",
    //     query: "FORMATIONS",
    //   },
    // });
    return <div>Error</div>;
  }

  const formations = data?.formations || [];

  return (
    <Card className="select-formation-menu">
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="select-formation-menu__list">
          <div
            className="select-formation-menu__list__item"
            onClick={() => {
              if (submitting) {
                return;
              }

              updateFixtureRole({
                variables: {
                  where: {
                    id: fixtureRole.id,
                  },
                  data: {
                    formation: { disconnect: true },
                  },
                },
              });
            }}
          >
            None
          </div>
          {formations.map((f) => (
            <div
              key={f.id}
              className="select-formation-menu__list__item"
              onClick={() => {
                if (submitting) {
                  return;
                }

                updateFixtureRole({
                  variables: {
                    where: {
                      id: fixtureRole.id,
                    },
                    data: {
                      formation: { connect: { id: f.id } },
                    },
                  },
                });
              }}
            >
              {f.name}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Starters({
  setOverlay,
  players,
  fixture,
  team,
  addStarter,
  removeStarter,
}) {
  const [view, setView] = useState("list");
  return (
    <Card className="starters">
      <div className="card-title">Starters</div>
      <div className="starters__title">
        <NewDropdown
          target={
            <div className="starters__title__text">
              {team.formation?.name || "Select Formation"}{" "}
              <ChevronDown className="starters__title__text__icon" />
            </div>
          }
          menu={({ setShowMenu }) => (
            <FormationsList
              sportId={fixture.event.sport.id}
              fixtureRole={team}
              setShowMenu={setShowMenu}
            />
          )}
        />

        <div className="starters__title__selection">
          <div
            className={
              "starters__title__selection__option " +
              (view === "list" ? "selected" : "")
            }
            onClick={() => {
              setView("list");
            }}
          >
            <ListIcon
              style={{ height: "14px" }}
              className="starters__title__selection__option__icon"
            />
          </div>
          <div
            className={
              "starters__title__selection__option " +
              (view === "pitch" ? "selected" : "")
            }
            onClick={() => {
              setView("pitch");
            }}
          >
            <PitchIcon
              style={{ height: "18px" }}
              className="starters__title__selection__option__icon"
            />
          </div>
        </div>
      </div>

      <div className="starters__body">
        {view === "pitch" ? (
          <Card className="pitch-card">
            <Pitch
              width={324}
              markers={
                team.formation?.positions.map((p) => ({
                  ...p,
                  label: p.positionType.shortName,
                  // secondaryLabel: lastname(
                  //   lineupRoles.find((lr) => lr.position?.id === p.id)?.profile
                  //     ?.name
                  // ),
                  secondaryLabel: "",
                  role: players.find(
                    (r) =>
                      r.position?.id === p.id &&
                      r.subtypes.find((x) => x.name === "STARTER")
                  ),
                })) || []
              }
              markerOnClick={(marker) => {
                if (marker.role) {
                  removeStarter(marker.role.id);
                } else {
                  setOverlay({
                    onItemClick: (profileId, jerseyNum) => {
                      addStarter(
                        profileId,
                        team.organization.id,
                        marker.id,
                        "STARTER",
                        jerseyNum
                      );
                    },
                  });
                  // setAppState({
                  //   modal: (
                  //     <LineupList
                  //       fixture={fixture}
                  //       team={team}
                  //       disable={(role) =>
                  //         role?.subtypes.find((x) => x.name === "SUBSTITUTE")
                  //       }
                  //       onItemClick={(profileId) => {
                  //         addStarter(
                  //           profileId,
                  //           team.organization.id,
                  //           marker.id,
                  //           "STARTER"
                  //         );
                  //       }}
                  //     />
                  //   ),
                  // });
                }
              }}
              lockEditing={false}
            />
          </Card>
        ) : (
          <StartersList
            team={team}
            fixture={fixture}
            players={players}
            onItemClick={(item) => {
              if (item.role) {
                removeStarter(item.role.id);
              } else {
                setOverlay({
                  onItemClick: (profileId, jerseyNum) => {
                    addStarter(
                      profileId,
                      team.organization.id,
                      item.id,
                      "STARTER",
                      jerseyNum
                    );
                  },
                });
                // setAppState({
                //   modal: (
                //     <LineupList
                //       fixture={fixture}
                //       team={team}
                //       onItemClick={(profileId) => {
                //         addStarter(
                //           profileId,
                //           team.organization.id,
                //           item.id,
                //           "STARTER"
                //         );
                //       }}
                //     />
                //   ),
                // });
              }
            }}
          />
        )}
      </div>
    </Card>
  );
}

function Subs({ setOverlay, players, fixture, team, addSub, removeSub }) {
  return (
    <Card className="starters">
      <div className="card-title">Subs</div>
      <div className="subs__body">
        <div className="starters__body__list">
          <div className="starters__body__list__items">
            {players
              .filter((p) => {
                return p.subtypes.find((x) => x.name === "SUBSTITUTE");
              })
              .map((p) => (
                <div key={p.id} className="starters__body__list__item">
                  <div className="starters__body__list__item__image">
                    {p.profile?.profilePhoto && (
                      <Avatar media={p.profile.profilePhoto} />
                    )}
                  </div>
                  <div className="starters__body__list__item__name">
                    {p.profile?.name}
                  </div>
                  <div className="starters__body__list__item__options">
                    <EditJerseyNumber role={p} players={players} />
                    {/* <div className="starters__body__list__item__option placeholder">
                      {p.order || (canManageFixture ? "J.Num" : "")}
                    </div> */}
                    <div
                      className="starters__body__list__item__option delete"
                      onClick={() => {
                        removeSub(p.id);
                      }}
                    >
                      <TrashIcon className="starters__body__list__item__option__icon" />
                    </div>
                  </div>
                </div>
              ))}
            <div
              className="starters__body__list__item hover-pointer"
              onClick={() => {
                setOverlay({
                  onItemClick: (profileId, jerseyNum) => {
                    // console.log("J Num:", jerseyNum);
                    addSub(
                      profileId,
                      team.organization.id,
                      false,
                      "SUBSTITUTE",
                      jerseyNum
                    );
                  },
                });
              }}
            >
              <div className="starters__body__list__item__image"></div>
              <div className="starters__body__list__item__name">Add Sub</div>
            </div>
          </div>
          {/* {canManageFixture && (
          <div className="starters__body__list__footer">
            <Button
              className="primary orange medium"
              onClick={() => {
                setAppState({
                  modal: (
                    <LineupList
                      fixture={fixture}
                      team={team}
                      subtype={"SUBSTITUTE"}
                      disable={(role) =>
                        role?.subtypes.find((x) => x.name === "STARTER")
                      }
                      label={"Select Substitutes"}
                    />
                  ),
                });
              }}
            >
              Edit Subs
            </Button>
          </div>
        )} */}
        </div>
      </div>
    </Card>
  );
}

export default function LineupSettings({ id }) {
  const [overlay, setOverlay] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const { loading, data, error } = useQuery(
    FIXTURE({
      selectionSet: `
      event{
        id
        sport{
          id
          name
        }
      }
    roles{
      id
        order
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          firstname
          lastname
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
        position {
          id
        }
    }
  `,
    }),
    { variables: { id } }
  );

  const [updateFixtureRole, { loading: updatingFixtureRole }] = useMutation(
    UPDATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.LineupSettings",
        //     mutation: "UPDATE_FIXTURE_ROLE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  const [createFixtureRole, { loading: creatingFixtureRole }] = useMutation(
    CREATE_FIXTURE_ROLE,
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.LineupSettings",
        //     mutation: "CREATE_FIXTURE_ROLE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  const [deleteFixtureRole, { loading: deletingFixtureRole }] = useMutation(
    DELETE_FIXTURE_ROLE,
    {
      onError: (error) => {
        // captureException({
        //   error,
        //   info: {
        //     type: "mutation",
        //     component: "ScoreFootball.Settings.LineupSettings",
        //     mutation: "DELETE_FIXTURE_ROLE",
        //   },
        // });
        console.log(error);
      },
    }
  );

  if (error) {
    // captureException({
    //   error,
    //   info: {
    //     type: "query",
    //     component: "ScoreFootball.Settings.LineupSettings",
    //     query: "FIXTURE",
    //   },
    // });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="ScoringApp-football__settings__lineup-settings">
        <div className="ScoringApp-football__settings__lineup-settings__title">
          Edit lineups
        </div>
        <div
          style={{
            height: "160px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      </Card>
    );
  }

  const fixture = data?.fixture;
  const participants = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const addLineupPlayer = (
    profileId,
    organizationId,
    formationPositionId,
    subtype,
    jerseyNum
  ) => {
    // console.log("Jersey NUm:", jerseyNum);
    if (creatingFixtureRole || updatingFixtureRole) {
      return;
    }

    const existingRole = fixture.roles.find(
      (r) => r.type.name === "LINEUP PLAYER" && r.profile?.id === profileId
    );

    if (existingRole) {
      // Update existing role

      updateFixtureRole({
        variables: {
          where: { id: existingRole.id },
          data: {
            subtypes: {
              connect: { name: subtype },
              ...(subtype === "STARTER"
                ? { disconnect: { name: "SUBSTITUTE" } }
                : { disconnect: { name: "STARTER" } }),
            },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
          },
        },
        update: (cache, { data: { updateFixtureRole } }) => {
          cache.modify({
            id: cache.identify(existingRole),
            fields: {
              subtypes() {
                return updateFixtureRole.subtypes;
              },
              position() {
                return updateFixtureRole.position;
              },
            },
          });

          setOverlay(false);
        },
      });
    } else {
      // Add new role
      createFixtureRole({
        variables: {
          data: {
            fixture: { connect: { id: fixture.id } },
            type: { connect: { name: "LINEUP PLAYER" } },
            subtypes: { connect: { name: subtype } },
            ...(formationPositionId
              ? { position: { connect: { id: formationPositionId } } }
              : {}),
            organization: { connect: { id: organizationId } },
            profile: { connect: { id: profileId } },
            ...(jerseyNum ? { order: jerseyNum } : {}),
          },
        },
        update: (cache, { data: { createFixtureRole } }) => {
          cache.modify({
            id: cache.identify(fixture),
            fields: {
              roles(existingRefs, { readField }) {
                const newRef = cache.writeFragment({
                  data: createFixtureRole,
                  fragment: gql`
                    fragment NewFixtureRole on FixtureRole {
                      id
                      order
                      type {
                        id
                        name
                      }
                      subtypes {
                        id
                        name
                      }
                      profile {
                        id
                        name
                      }
                      organization {
                        id
                        name
                      }
                      formation {
                        id
                        name
                        positions {
                          id
                          positionType {
                            id
                            shortName
                          }
                          x
                          y
                        }
                      }
                      position {
                        id
                      }
                    }
                  `,
                });

                return [newRef, ...existingRefs];
              },
            },
          });
          setOverlay(false);
        },
      });
    }
  };

  const removeLineupPlayer = (roleId) => {
    if (deletingFixtureRole) return;
    deleteFixtureRole({
      variables: {
        id: roleId,
      },
      update: (cache, { data: { deleteFixtureRole } }) => {
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            roles(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) => deleteFixtureRole.id !== readField("id", ref)
              );
            },
          },
        });
      },
    });
  };

  console.log("Fixture roles:", fixture.roles);

  return (
    <Card className="lineup-settings-card">
      <div
        className={"lineup-settings-card__overlay " + (overlay ? "show" : "")}
      >
        <LineupList
          fixture={fixture}
          team={participants[selectedItemIndex]}
          disable={(profileId) => {
            return fixture.roles.find(
              (r) =>
                r.type.name === "LINEUP PLAYER" && r.profile?.id === profileId
            );
          }}
          onItemClick={(profileId, jerseyNum) => {
            overlay?.onItemClick(profileId, jerseyNum);
            // addStarter(
            //   profileId,
            //   team.organization.id,
            //   marker.id,
            //   "STARTER"
            // );
          }}
          setOverlay={setOverlay}
        />
      </div>
      <div className="lineup-settings-card__title">Edit lineups</div>
      <div className="lineup-settings-card__body">
        <div className="lineup-settings-card__body__selector">
          {participants.map((p, i) => (
            <div
              key={p.id}
              className={
                "lineup-settings-card__body__selector__item " +
                (selectedItemIndex === i ? "selected" : "")
              }
              onClick={() => {
                setSelectedItemIndex(i);
              }}
            >
              <div className="lineup-settings-card__body__selector__item__img">
                {p?.organization?.profilePhoto && (
                  <Avatar media={p.organization.profilePhoto} />
                )}
              </div>
              <div className="lineup-settings-card__body__selector__item__text">
                {p?.organization?.name}
              </div>
            </div>
          ))}
        </div>

        <div className="lineup-settings-card__body__starters">
          <Starters
            setOverlay={setOverlay}
            players={fixture.roles.filter(
              (r) =>
                r.type.name === "LINEUP PLAYER" &&
                r.organization.id ===
                  participants[selectedItemIndex]?.organization?.id
            )}
            fixture={fixture}
            team={participants[selectedItemIndex]}
            addStarter={addLineupPlayer}
            removeStarter={removeLineupPlayer}
          />
        </div>

        <div className="lineup-settings-card__body__subs">
          <Subs
            setOverlay={setOverlay}
            players={fixture.roles.filter(
              (r) =>
                r.type.name === "LINEUP PLAYER" &&
                r.organization.id ===
                  participants[selectedItemIndex]?.organization?.id
            )}
            fixture={fixture}
            team={participants[selectedItemIndex]}
            addSub={addLineupPlayer}
            removeSub={removeLineupPlayer}
          />
        </div>
      </div>
    </Card>
  );
}
