// V1
import "./AlbumsCard.css";
import Card from "../Card";
import Loader from "../Loader";
import { useQuery, useMutation } from "@apollo/client";
import { ReactComponent as ImageIcon } from "../../icons/image.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import moment from "moment";
import { useEffect, useState, Fragment } from "react";
import { CREATE_MEDIA_SET } from "../../api/media";
import Placeholder from "../Placeholder";
import Avatar from "../Avatar/Avatar";
import captureException from "../../utils/captureException";

function AddAlbumForm({
  sourceId,
  sourceType,
  dismiss,
  onAddAlbum = () => {},
}) {
  const [name, setName] = useState("");
  const [createMediaSet, { loading }] = useMutation(CREATE_MEDIA_SET, {
    update: (cache, result) => {
      onAddAlbum(cache, result);
      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "AlbumsCard.AddAlbumForm",
          mutation: "CREATE_MEDIA_SET",
        },
      });
      console.log(error);
    },
  });

  return (
    <form
      className="add-album-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (loading) return;
        createMediaSet({
          variables: {
            name,
            sourceId,
            sourceType,
          },
        });
      }}
    >
      <input
        className="add-album-form__input"
        type="text"
        placeholder="Enter new album name"
        maxLength="40"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <div className="add-album-form__option success">
        <CheckIcon className="add-album-form__option__icon" />
      </div>
      <div className="add-album-form__option cancel" onClick={dismiss}>
        <TimesIcon className="add-album-form__option__icon" />
      </div>
    </form>
  );
}

function AlbumsCard({
  query,
  vars,
  getAlbums = () => [],
  totalAlbums = () => 0,
  noData = "",
  setSelectedAlbum,
  filters = {},
  processFilters = () => {
    return false;
  },
  addAlbum,
  customAlbums,
}) {
  const [adding, setAdding] = useState(false);
  const { networkStatus, error, data, variables, fetchMore, refetch } =
    useQuery(query, {
      variables: vars,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    const filterVars = processFilters(filters);

    if (filterVars) {
      refetch({
        ...vars,
        ...filterVars,
      });
    }
  }, [filters]);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AlbumsCard",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 2 || networkStatus === 4) {
    // console.log("Loader:", networkStatus);
    return (
      <Card className="app-albums-card">
        <div className="app-albums-card__card-title">
          <Placeholder style={{ height: "14px", width: "80px" }} pulse={true} />
        </div>

        <div className="app-albums-card__gallery">
          {[1, 2].map((album) => (
            <Fragment key={album}>
              <div className="app-albums-card__gallery__gallery-item">
                <div
                  className={"app-albums-card__gallery__gallery-item__image "}
                ></div>
                <div className="app-albums-card__gallery__gallery-item__desc">
                  <div>
                    <Placeholder
                      style={{ height: "14px", width: "160px" }}
                      pulse={true}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>

        <div className="photos-card__show-more-container">
          <Placeholder style={{ height: "14px", width: "80px" }} pulse={true} />
        </div>
      </Card>
    );
  }

  const albums = getAlbums(data);
  const albumsCount = totalAlbums(data);

  // console.log(albums.length, albumsCount);
  // console.log("Network status:", networkStatus);
  // console.log("Albums data:", data);

  return (
    <Card className="app-albums-card">
      <div className="app-albums-card__card-title">
        Albums{" "}
        {addAlbum && (
          <Fragment>
            {adding ? (
              <AddAlbumForm
                dismiss={() => {
                  setAdding(false);
                }}
                sourceId={addAlbum.sourceId}
                sourceType={addAlbum.sourceType}
                onAddAlbum={addAlbum.onAddAlbum}
              />
            ) : (
              <div
                className="add-album-btn"
                onClick={() => {
                  setAdding(true);
                }}
              >
                Add Album
              </div>
            )}
          </Fragment>
        )}
      </div>

      <div className="app-albums-card__gallery">
        {customAlbums?.map((album) => (
          <Fragment key={album.id}>
            {/* {album.preview && ( */}
            <div
              className="app-albums-card__gallery__gallery-item"
              onClick={() => {
                setSelectedAlbum(album.id);
              }}
            >
              <div
                className={
                  "app-albums-card__gallery__gallery-item__image " +
                  (album.preview ? "has-image" : "")
                }
              >
                {album.preview ? (
                  <Avatar media={album.preview} />
                ) : (
                  <ImageIcon className="placeholder-icon" />
                )}

                {album.preview && <div className="overlay" />}
              </div>
              <div className="app-albums-card__gallery__gallery-item__desc">
                <div>
                  {album.source?.fixture
                    ? album.source.fixture.roles
                        .map((r) => r.organization?.name || r.profile?.name)
                        .join(" vs ")
                    : album.name}
                </div>
                {album.source?.fixture && (
                  <Fragment>
                    {album.source?.fixture?.scheduledStart ? (
                      <div>
                        [
                        {moment(album.source?.fixture?.scheduledStart).format(
                          "DD-MM-YYYY"
                        )}
                        ]
                      </div>
                    ) : (
                      <span />
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            {/* )} */}
          </Fragment>
        ))}

        {albums?.map((album) => (
          <Fragment key={album.id}>
            {/* {album.preview && ( */}
            <div
              className="app-albums-card__gallery__gallery-item"
              onClick={() => {
                setSelectedAlbum(album.id);
              }}
            >
              <div
                className={
                  "app-albums-card__gallery__gallery-item__image " +
                  (album.preview ? "has-image" : "")
                }
              >
                {album.preview ? (
                  <Avatar media={album.preview} />
                ) : (
                  <ImageIcon className="placeholder-icon" />
                )}

                {album.preview && <div className="overlay" />}
              </div>
              <div className="app-albums-card__gallery__gallery-item__desc">
                <div>
                  {album.source?.fixture
                    ? album.source?.fixture.roles
                        .map((r) => r.organization?.name || r.profile?.name)
                        .join(" vs ")
                    : album.name}
                </div>
                {album.source?.fixture && (
                  <Fragment>
                    {album.source?.fixture?.scheduledStart ? (
                      <div>
                        [
                        {moment(album.source?.fixture?.scheduledStart).format(
                          "DD-MM-YYYY"
                        )}
                        ]
                      </div>
                    ) : (
                      <span />
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            {/* )} */}
          </Fragment>
        ))}
      </div>

      {!albums?.filter((x) => x.preview)?.length && (
        <div className="no-data">{noData}</div>
      )}

      {albums?.length < albumsCount && (
        <div
          className="photos-card__show-more-container"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: albums[albums.length - 1]?.id,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "See More"}
        </div>
      )}

      {/*<div className="show-more-container">
                <Button className="secondary blue medium">Show More</Button>
                          </div>*/}
    </Card>
  );
}

export default AlbumsCard;
