import { gql } from "@apollo/client";

const HANDLE_EVENT_ROLE = gql`
  mutation handleEventRole($id: ID!, $approve: Boolean!) {
    handleEventRole(id: $id, approve: $approve) {
      id
    }
  }
`;

export default HANDLE_EVENT_ROLE;
