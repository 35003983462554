import { gql } from "@apollo/client";

const EVENT_STANDINGS = gql`
  query eventFixturesQuery($id: ID!, $take: Int) {
    event(id: $id) {
      id
      sport {
        id
        name
      }
      parts {
        id
        label
        standings(take: $take) {
          id
          createdAt
          event {
            id
            name
          }
          eventPart {
            id
            label
          }
          items {
            id
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            profile {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            order
            metas {
              id
              label
              shortLabel
              value
              stringValue
            }
          }
        }
      }
      standings(take: $take) {
        id
        createdAt
        title
        event {
          id
          name
        }
        eventPart {
          id
          label
        }
        items {
          id
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          order
          metas {
            id
            label
            shortLabel
            value
            stringValue
          }
        }
      }
    }
  }
`;

export default EVENT_STANDINGS;
