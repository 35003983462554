import { useState } from "react";
import "./Auth.css";
import { Login, Signup } from "../../mobileComponents/Auth";
import useRouterQuery from "../../utils/useRouterQuery";
import { Link } from "react-router-dom";

function Auth({ hideLogo, text }) {
  const query = useRouterQuery();
  const selected = query.get("s") || "login";
  const [formType, setFormType] = useState(selected);

  return (
    <div className="mobile-auth-page__wrapper">
      <div className="mobile-auth-page">
        <Link to="/" className="mobile-auth-page__logo">
          <img src="/logo.svg" alt="" />
        </Link>

        {formType === "login" ? (
          <Login setFormType={setFormType} />
        ) : (
          <Signup setFormType={setFormType} />
        )}

        <div className="mobile-auth-page__graphic">
          <img src="/circle-bg.svg" alt="" />
        </div>

        <div className="mobile-auth-page__logo-bottom">
          <img src="/logo-white.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Auth;
