import "./BadmintonStats.css";
// import { PROFILE_STATS } from "../../../../api/profile";
import { ORGANIZATION_STATS } from "../../../../api/organization";
import { useQuery } from "@apollo/client";
import Card from "../../../Card";
import PieChart from "../../../PieChart";
import makeFileUrl from "../../../../utils/makeFileUrl";
import { Link } from "react-router-dom";
import captureException from "../../../../utils/captureException";
import { Fragment } from "react";

function ProfileRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record__wrapper">
      <div
        className="organization-record"
        style={{ boxShadow: "none", margin: 0 }}
      >
        <div className="organization-record__meta">
          <div className="organization-record__meta__title">RECORD</div>

          <div className="organization-record__meta__highlight">
            <div className="organization-record__meta__highlight__value">
              {total}
            </div>
            <div className="organization-record__meta__highlight__label">
              Games Played
            </div>
          </div>
        </div>

        <div
          className="organization-record__items"
          style={{
            width: "50%",
          }}
        >
          <div
            className="organization-record__items__item"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="organization-record__items__item__label">Win</div>

            <div className="organization-record__items__item__graph">
              <div className="organization-record__items__item__graph__track">
                <div
                  className="organization-record__items__item__graph__fill"
                  style={{ width: total ? (wins / total) * 100 + "%" : "1%" }}
                ></div>
              </div>
            </div>

            <div className="organization-record__items__item__value">
              {wins}
            </div>
          </div>

          <div
            className="organization-record__items__item"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="organization-record__items__item__label">Loss</div>

            <div className="organization-record__items__item__graph">
              <div className="organization-record__items__item__graph__track">
                <div
                  className="organization-record__items__item__graph__fill"
                  style={{
                    width: total ? (losses / total) * 100 + "%" : "1%",
                  }}
                ></div>
              </div>
            </div>

            <div className="organization-record__items__item__value">
              {losses}
            </div>
          </div>
        </div>

        <div
          className="profile-shooting-stats-mobile__doughnut__wrapper"
          style={{ width: "25%", padding: "8px" }}
        >
          <div className="profile-shooting-stats-mobile__doughnut">
            <div className="profile-shooting-stats-mobile__doughnut__bg">
              <PieChart
                value={wins || 0}
                total={losses || 0}
                colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
              />
            </div>
            <PieChart
              value={wins || 0}
              total={losses || 0}
              colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
            />
            <div className="profile-shooting-stats-mobile__doughnut__meta">
              <div className="profile-shooting-stats-mobile__doughnut__meta__value">
                {total ? Math.round((wins / total) * 100) + "%" : "0%"}
              </div>

              <div className="profile-shooting-stats-mobile__doughnut__meta__key">
                Win Rate <br /> (%)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfilePoints({ total, wins, losses, unforcedErrors }) {
  return (
    <div className="organization-record__wrapper">
      <div
        className="organization-record"
        style={{ boxShadow: "none", margin: 0 }}
      >
        <div className="organization-record__meta">
          <div className="organization-record__meta__title">POINTS</div>

          <div className="organization-record__meta__highlight">
            <div className="organization-record__meta__highlight__value">
              {total}
            </div>
            <div className="organization-record__meta__highlight__label">
              Points Played
            </div>
          </div>
        </div>

        <div
          className="organization-record__items"
          style={{
            width: "50%",
          }}
        >
          <div
            className="organization-record__items__item"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="organization-record__items__item__label">Win</div>

            <div className="organization-record__items__item__graph">
              <div className="organization-record__items__item__graph__track">
                <div
                  className="organization-record__items__item__graph__fill"
                  style={{ width: total ? (wins / total) * 100 + "%" : "1%" }}
                ></div>
              </div>
            </div>

            <div className="organization-record__items__item__value">
              {wins}
            </div>
          </div>

          <div
            className="organization-record__items__item"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="organization-record__items__item__label">Loss</div>

            <div className="organization-record__items__item__graph">
              <div className="organization-record__items__item__graph__track">
                <div
                  className="organization-record__items__item__graph__fill"
                  style={{
                    width: total ? (losses / total) * 100 + "%" : "1%",
                  }}
                ></div>
              </div>
            </div>

            <div className="organization-record__items__item__value">
              {losses}
            </div>
          </div>
        </div>

        <div
          className="profile-shooting-stats-mobile__doughnut__wrapper"
          style={{ padding: "8px", width: "25%" }}
        >
          <div className="profile-shooting-stats-mobile__doughnut">
            <div className="profile-shooting-stats-mobile__doughnut__bg">
              <PieChart
                value={unforcedErrors || 0}
                total={losses - unforcedErrors || 0}
                colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
              />
            </div>
            <PieChart
              value={unforcedErrors || 0}
              total={losses - unforcedErrors || 0}
              colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
            />
            <div className="profile-shooting-stats-mobile__doughnut__meta">
              <div className="profile-shooting-stats-mobile__doughnut__meta__value">
                {total
                  ? Math.round((unforcedErrors / losses) * 100) + "%"
                  : "0%"}
              </div>

              <div className="profile-shooting-stats-mobile__doughnut__meta__key">
                Unforced Error <br /> (%)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileServes({ total, wins, losses }) {
  return (
    <div className="organization-record__wrapper">
      <div
        className="organization-record"
        style={{ boxShadow: "none", margin: 0 }}
      >
        <div className="organization-record__meta">
          <div className="organization-record__meta__title">SERVES</div>

          <div className="organization-record__meta__highlight">
            <div className="organization-record__meta__highlight__value">
              {total}
            </div>
            <div className="organization-record__meta__highlight__label">
              Serves
            </div>
          </div>
        </div>

        <div
          className="organization-record__items"
          style={{
            width: "50%",
          }}
        >
          <div
            className="organization-record__items__item"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="organization-record__items__item__label">Win</div>

            <div className="organization-record__items__item__graph">
              <div className="organization-record__items__item__graph__track">
                <div
                  className="organization-record__items__item__graph__fill"
                  style={{ width: total ? (wins / total) * 100 + "%" : "1%" }}
                ></div>
              </div>
            </div>

            <div className="organization-record__items__item__value">
              {wins}
            </div>
          </div>

          <div
            className="organization-record__items__item"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="organization-record__items__item__label">Loss</div>

            <div className="organization-record__items__item__graph">
              <div className="organization-record__items__item__graph__track">
                <div
                  className="organization-record__items__item__graph__fill"
                  style={{
                    width: total ? (losses / total) * 100 + "%" : "1%",
                  }}
                ></div>
              </div>
            </div>

            <div className="organization-record__items__item__value">
              {losses}
            </div>
          </div>
        </div>

        <div
          className="profile-shooting-stats-mobile__doughnut__wrapper"
          style={{ padding: "8px", width: "25%" }}
        >
          <div className="profile-shooting-stats-mobile__doughnut">
            <div className="profile-shooting-stats-mobile__doughnut__bg">
              <PieChart
                value={wins || 0}
                total={losses || 0}
                colors={["rgba(99, 234, 71, 0.24)", "#ffffff"]}
              />
            </div>
            <PieChart
              value={wins || 0}
              total={losses || 0}
              colors={["rgba(19, 160, 250, 0.48)", "#dae2e7"]}
            />
            <div className="profile-shooting-stats-mobile__doughnut__meta">
              <div className="profile-shooting-stats-mobile__doughnut__meta__value">
                {total ? Math.round((wins / total) * 100) + "%" : "0%"}
              </div>

              <div className="profile-shooting-stats-mobile__doughnut__meta__key">
                Serves Won <br /> (%)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileParticipationHistory({ participationHistory }) {
  const rows = participationHistory.map((p) => ({
    ...p,
    stats: JSON.parse(p.stats.data),
  }));
  return (
    <div
      className="profile-participation-stats"
      style={{ marginBottom: "40px" }}
    >
      <div className="profile-participation-stats__title">
        PARTICIPATION HISTORY
      </div>

      <div className="profile-participation-stats__table">
        <div
          className="profile-participation-stats__table__column"
          style={{ width: "50%" }}
        >
          <div className="profile-participation-stats__table__column__header with-image">
            Event
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td with-image " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {/* <div className="profile-participation-stats__table__td__image"></div> */}
                <div className="profile-participation-stats__table__td__meta">
                  <Link
                    to={"/app/event/" + r.event?.id}
                    className="profile-participation-stats__table__td__meta__primary"
                  >
                    {r.event?.name}
                  </Link>

                  {/* <div className="profile-participation-stats__table__td__meta__secondary">
                        {r.organization?.name}
                      </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            GP
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Appearances"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            G
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Goal"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            A
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Assist"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            RC
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Red Card"] || 0}
              </div>
            ))}
          </div>
        </div>

        <div
          className="profile-participation-stats__table__column"
          style={{ width: "10%" }}
        >
          <div className="profile-participation-stats__table__column__header">
            YC
          </div>

          <div className="profile-participation-stats__table__column__rows">
            {rows.map((r, i) => (
              <div
                className={
                  "profile-participation-stats__table__td " +
                  (i % 2 ? "odd" : "even")
                }
              >
                {r.stats?.["Yellow Card"] || 0}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function BadmintonStats({ id, events }) {
  // console.log(id);
  const { loading, error, data } = useQuery(ORGANIZATION_STATS, {
    variables: {
      id,
      sport: "Badminton",
      statTypes: [
        "Point Played",
        "Point Won",
        "Service",
        "Service Won",
        "Unforced Error",
        "Games Played",
        "Games Won",
      ],
      participationStatTypes: [
        "Point Played",
        "Point Won",
        "Service",
        "Unforced Error",
      ],
      events,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.BadmintonStats",
        query: "ORGANIZATION_STATS",
      },
    });
    // console.log(error);
  }

  if (loading) {
    return <div>Loading</div>;
  }

  // console.log("STATS:", data);

  const { totalGamesPlayed } = data?.organization?.stats || {};
  const { appearances, participationHistory, servicesWon } =
    data?.profile?.stats || {};

  const statsData = JSON.parse(
    data?.organization?.stats?.aggregations?.data || "{}"
  );

  // console.log("Record:", data?.profile?.stats?.record);
  const record = JSON.parse(data?.organization?.stats?.record?.data || {});

  // const {
  //   "Point Played",

  // } = statsData;

  const participationData = {
    headers: [
      { label: "Event", weight: 30, link: (d) => `/app/event/${d?.event?.id}` },
      { label: "GP", weight: 8, type: "number" },
      { label: "G", weight: 8, type: "number" },
      { label: "A", weight: 8, type: "number" },
      { label: "RC", weight: 8, type: "number" },
      { label: "YC", weight: 8, type: "number" },
    ],
    data: data?.organization?.stats?.participationHistory?.map((p) => {
      const participationStats = JSON.parse(p.stats.data);

      return [
        p.event?.name,
        participationStats["Appearances"],
        participationStats["Goal"],
        participationStats["Assist"],
        participationStats["Red Card"],
        participationStats["Yellow Card"],
      ];
    }),
  };

  // console.log("PD: ", participationData);
  return (
    <Fragment>
      <ProfileRecordStats
        total={totalGamesPlayed}
        wins={record.wins}
        losses={record.losses}
      />

      <ProfilePoints
        total={statsData["Point Played"] || 0}
        wins={statsData["Point Won"] || 0}
        losses={
          (statsData["Point Played"] || 0) - (statsData["Point Won"] || 0)
        }
        unforcedErrors={statsData["Unforced Error"] || 0}
      />

      <ProfileServes
        total={statsData["Service"]}
        wins={servicesWon}
        losses={(statsData["Service"] || 0) - (servicesWon || 0)}
      />

      <ProfileParticipationHistory
        participationHistory={participationHistory || []}
      />
    </Fragment>
  );
}
