import "./OrganizationServices.css";
import Card from "../../components/Card";
import { useParams } from "react-router-dom";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { useQuery, useMutation } from "@apollo/client";
import UploaderWithCropper from "../UploaderWithCropper";
import {
  ORGANIZATION_SERVICES,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
} from "../../api/organization";
import Spinner from "../Spinner";
import Button from "../Button";
import { useState } from "react";
import { useAppState } from "../../utils/appState";
import Avatar from "../Avatar";
import captureException from "../../utils/captureException";

function DeleteService({ id, service, setForm }) {
  const [deleteService, { loading: deleting }] = useMutation(DELETE_SERVICE, {
    update: (cache, { data: { deleteService } }) => {
      const q = cache.readQuery({
        query: ORGANIZATION_SERVICES,
        variables: { id },
      });

      const serviceIndex = q.organization.services.findIndex(
        (s) => s.id === deleteService.id
      );

      if (serviceIndex > -1) {
        const newServices = [...q.organization.services].filter(
          (s) => s.id !== deleteService.id
        );
        cache.writeQuery({
          query: ORGANIZATION_SERVICES,
          variables: { id },
          data: {
            organization: {
              ...q.organization,
              services: newServices,
            },
          },
        });
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "OrganizationServices.DeleteService",
          mutation: "DELETE_SERVICE",
        },
      });
      console.log(error);
    },
  });

  return (
    <Card className="organization-service-card">
      <div className="image">
        {service?.profilePhoto && <Avatar media={service.profilePhoto} />}
        {service?.profilePhoto && <div className="overlay" />}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (deleting) return;
          deleteService({
            variables: {
              id: service.id,
            },
          });
        }}
      >
        <div className="primary-text">{service.name}</div>
        <div className="secondary-text">
          Are you sure you want to delete this service?
        </div>
        <div className="options">
          <button
            className="app-button primary medium red delete-button"
            type="submit"
          >
            Yes, delete this service
          </button>
          <Button
            className="tertiary"
            onClick={() => {
              setForm(false);
            }}
          >
            No, Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
}

function CreateOrEditService({ id, setForm, service }) {
  const [name, setName] = useState(service?.name || "");
  const [description, setDescription] = useState(service?.description || "");

  const [createService] = useMutation(CREATE_SERVICE, {
    update: (cache, { data: { createService } }) => {
      const q = cache.readQuery({
        query: ORGANIZATION_SERVICES,
        variables: { id },
      });

      cache.writeQuery({
        query: ORGANIZATION_SERVICES,
        variables: { id },
        data: {
          organization: {
            ...q.organization,
            services: [createService, ...(q.organization.services || [])],
          },
        },
      });

      setForm(false);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "OrganizationServices.CreateOrEditService",
          mutation: "CREATE_SERVICE",
        },
      });
      console.log(error);
    },
  });

  const [updateService] = useMutation(UPDATE_SERVICE, {
    update: (cache, { data: { updateService } }) => {
      const q = cache.readQuery({
        query: ORGANIZATION_SERVICES,
        variables: { id },
      });

      const serviceIndex = q.organization.services.findIndex(
        (s) => s.id === updateService.id
      );

      if (serviceIndex > -1) {
        const newServices = [...q.organization.services];
        newServices[serviceIndex] = updateService;
        cache.writeQuery({
          query: ORGANIZATION_SERVICES,
          variables: { id },
          data: {
            organization: {
              ...q.organization,
              services: newServices,
            },
          },
        });
      }

      setForm(false);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "OrganizationServices.CreateOrUpdateService",
          mutation: "UPDATE_SERVICE",
        },
      });
      console.log(error);
    },
  });

  return (
    <Card className="organization-service-card">
      <div className="image">
        {service?.profilePhoto && <Avatar media={service.profilePhoto} />}
        {service?.profilePhoto && <div className="overlay" />}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (name.length > 100 || description.length > 1000) {
            return;
          }

          if (service?.id) {
            updateService({
              variables: {
                where: { id: service.id },
                data: {
                  name,
                  description,
                },
              },
            });
          } else {
            createService({
              variables: {
                data: {
                  name,
                  description,
                  organization: {
                    connect: { id },
                  },
                },
              },
            });
          }
        }}
      >
        <div className="primary-text">
          <input
            type="text"
            placeholder={"Enter service name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <div
            className={
              "character-count-label " + (name.length > 100 ? "error" : "")
            }
          >
            {name.length}/100
          </div>
        </div>
        <div className="secondary-text">
          <textarea
            placeholder={"Enter service description"}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <div
            className={
              "character-count-label " +
              (description.length > 1000 ? "error" : "")
            }
          >
            {description.length}/1000
          </div>
        </div>
        <div className="options">
          <button
            className={
              "app-button primary medium blue submit-button " +
              (!name.length || name.length > 100 || description.length > 1000
                ? "disabled"
                : "")
            }
            type="submit"
          >
            Save
          </button>

          <Button
            className="tertiary"
            onClick={() => {
              setForm(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
}

function ServiceCard({ id, service, canManagePage }) {
  const [update, setUpdate] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [collapsed, setCollapsed] = useState(
    service.description?.length > 250 ? true : false
  );
  const [, setAppState] = useAppState();

  if (update) {
    return (
      <CreateOrEditService id={id} service={service} setForm={setUpdate} />
    );
  }

  if (deleting) {
    return <DeleteService id={id} service={service} setForm={setDeleting} />;
  }

  return (
    <Card className="organization-service-card">
      <div className="image">
        {service.profilePhoto && <Avatar media={service.profilePhoto} />}
        {service.profilePhoto && <div className="overlay" />}
        {canManagePage && (
          <div
            className="organization-service-card__image__edit-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <UploaderWithCropper
                    accept=".jpg,.png,.jpeg"
                    label="Upload service photo"
                    multiple={false}
                    sourceId={service.id}
                    sourceType={"service"}
                    subtype={"PROFILE PHOTO"}
                    cropperHeight={152}
                    cropperWidth={300}
                    onCreateMedia={(cache, createMedia) => {
                      const q = cache.readQuery({
                        query: ORGANIZATION_SERVICES,
                        variables: { id },
                      });

                      const serviceIndex = q.organization.services.findIndex(
                        (s) => s.id === service.id
                      );

                      if (serviceIndex > -1) {
                        const newServices = [...q.organization.services];
                        newServices[serviceIndex] = {
                          ...q.organization.services[serviceIndex],
                          profilePhoto: {
                            id: createMedia.media.id,
                            filename: createMedia.media.filename,
                          },
                        };
                        cache.writeQuery({
                          query: ORGANIZATION_SERVICES,
                          variables: { id },
                          data: {
                            organization: {
                              ...q.organization,
                              services: newServices,
                            },
                          },
                        });
                      }

                      setAppState({ modal: false });
                    }}
                  />
                ),
              });
            }}
          >
            <PencilIcon
              style={{ fill: "var(--orange-main)", height: "16px" }}
            />
          </div>
        )}
      </div>
      <div className="primary-text">{service.name}</div>
      <div className="secondary-text">
        {service.description?.length < 250
          ? service.description
          : collapsed
          ? service.description.substring(0, 250) + "..."
          : service.description}
      </div>
      {service.description?.length > 250 && collapsed && (
        <div
          className="secondary-text link"
          onClick={() => {
            setCollapsed(false);
          }}
        >
          See More
        </div>
      )}

      {service.description?.length > 250 && !collapsed && (
        <div
          className="secondary-text link"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          See Less
        </div>
      )}
      <div className="organization-service-card__options">
        {canManagePage && (
          <div
            className="organization-service-card__options__option edit"
            onClick={() => {
              setUpdate(true);
            }}
          >
            Edit service details
          </div>
        )}

        {canManagePage && (
          <div
            className="organization-service-card__options__option delete"
            onClick={() => {
              setDeleting(true);
            }}
          >
            Delete service
          </div>
        )}
      </div>

      <div className="options">
        <Button
          className={
            "secondary medium blue enquire-button " +
            (canManagePage ? "disabled" : "")
          }
        >
          Enquire
        </Button>
      </div>
    </Card>
  );
}

function OrganizationServices({ canManagePage }) {
  const { id } = useParams();
  const [form, setForm] = useState(false);
  const { loading, error, data } = useQuery(ORGANIZATION_SERVICES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationServices",
        query: "ORGANIZATION_SERVICES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "160px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const org = data?.organization;
  const services = org?.services;

  return (
    <div className="organization-services">
      {!services?.length && !form && (
        <div className="no-services-container">
          <div className="text">
            No services have been listed for this page yet.
          </div>
          {canManagePage && (
            <Button
              className="primary green large"
              onClick={() => {
                setForm(true);
              }}
            >
              Add a Service
            </Button>
          )}
        </div>
      )}

      {!!services.length && !form && canManagePage && (
        <div className="organization-services-header">
          <Button
            className="primary green medium"
            onClick={() => {
              setForm(true);
            }}
          >
            Add a Service
          </Button>
        </div>
      )}

      {(!!services.length || form) && (
        <div className="organization-services-list">
          {form && <CreateOrEditService id={id} setForm={setForm} />}
          {services.map((s) => (
            <ServiceCard
              key={s.id}
              id={id}
              service={s}
              canManagePage={canManagePage}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default OrganizationServices;
