import { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { EVENT_REGISTRATIONS } from "../../../../api/event";
import {
  FIXTURE_SETTINGS,
  FIXTURE_STATS,
  PUBLISH_FIXTURE,
} from "../../../../api/fixture";
import { STAT_TYPES } from "../../../../api/sport";
import Spinner from "../../../Spinner";
import Button from "../../../Button";
import Loader from "../../../Loader";
import StatTable from "../StatTable";
import { useAppState } from "../../../../utils/appState";
import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../../../icons/chevron-left.svg";
import captureException from "../../../../utils/captureException";

function getMaidenOvers(profile, groupedOvers) {
  let maidenCount = 0;
  // console.log("Grouped Overs length:", groupedOvers.length);

  for (let i = 0; i < groupedOvers.length; i++) {
    // console.log("Grouped over index log:", profile.name, i);
    let over = groupedOvers[i];
    // console.log("Maiden log:", profile.name, over);

    const ballsBowled =
      over.stats?.filter(
        (s) => s.ball?.profile.id === profile.id && !s.extra?.length
      ).length || 0;

    // if (profile.name === "G Prabhu") {
    //   console.log("G Prabhu Balls bowled:", over, ballsBowled);
    // }
    if (ballsBowled >= 6) {
      const runsConceded = over.stats
        ?.filter((s) => s.ball?.profile.id === profile.id)
        .reduce((accum, curr) => {
          accum += curr.runsConceded?.value || 0;
          accum +=
            curr.extra?.reduce((a, c) => {
              a += c.value;
              return a;
            }, 0) || 0;
          return accum;
        }, 0);

      if (runsConceded < 1) {
        // console.log("Maiden for ", profile.name, " :", over);
        maidenCount += 1;
      }
    }
  }
  return maidenCount;
}

export default function PublishStatsWrapper({
  fixtureId,
  eventId,
  setPublish,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  completed,
  cache,
}) {
  const registrationsQuery = useQuery(EVENT_REGISTRATIONS, {
    variables: { id: eventId },
  });

  const statsQuery = useQuery(FIXTURE_STATS, {
    variables: { id: fixtureId },
  });

  const statTypesQuery = useQuery(STAT_TYPES, {
    variables: { sport: "Cricket" },
  });

  if (registrationsQuery.error) {
    captureException({
      error: registrationsQuery.error,
      info: {
        type: "query",
        component: "ScoreCricketNew.PublishStatsWrapper",
        query: "EVENT_REGISTRATIONS",
      },
    });
    console.log(registrationsQuery.error);
  }

  if (statsQuery.error) {
    captureException({
      error: statsQuery.error,
      info: {
        type: "query",
        component: "ScoreCricketNew.PublishStatsWrapper",
        query: "FIXTURE_STATS",
      },
    });
    console.log(statsQuery.error);
  }

  if (statTypesQuery.error) {
    captureException({
      error: statTypesQuery.error,
      info: {
        type: "query",
        component: "ScoreCricketNew.PublishStatsWrapper",
        query: "STAT_TYPES",
      },
    });
    console.log(statTypesQuery.error);
  }

  if (registrationsQuery.error || statsQuery.error || statTypesQuery.error) {
    // console.log(error);

    return <div>Error</div>;
  }

  if (
    registrationsQuery.loading ||
    statsQuery.loading ||
    statTypesQuery.loading
  ) {
    return <Spinner />;
  }

  const loading =
    registrationsQuery.loading || statsQuery.loading || statTypesQuery.loading;

  const stats = statsQuery.data?.fixture?.stats || [];
  const registrations = registrationsQuery.data?.event?.registrations || [];
  const statTypes = statTypesQuery.data?.statTypes || [];
  const placements = statsQuery.data?.fixture?.placements || [];

  const homeScore = completed
    ? placements.find((x) => x.organization.id === homeTeam.organization.id)
        ?.value || 0
    : stats
        .filter(
          (s) =>
            (s.organization.id === homeTeam.organization.id &&
              s.statType.name === "Runs Scored") ||
            (s.organization.id !== homeTeam.organization.id &&
              ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name))
        )
        .reduce((accum, curr) => {
          accum += curr.value;
          return accum;
        }, 0);

  const homeWickets = completed
    ? placements
        .find((x) => x.organization.id === homeTeam.organization.id)
        ?.valueString?.split("/")?.[1]
      ? Number(
          placements
            .find((x) => x.organization.id === homeTeam.organization.id)
            ?.valueString?.split("/")?.[1]
        )
      : 0
    : stats.filter(
        (s) =>
          s.organization.id === homeTeam.organization.id &&
          [
            "Bowled",
            "LBW",
            "Caught",
            "Caught & Bowled",
            "Stumped",
            "Run Out",
            "Retired",
          ].includes(s.statType.name) &&
          s.relationshipsTo.find((x) => x.from.statType.name === "Runs Scored")
      ).length;

  const awayScore = completed
    ? placements.find((x) => x.organization.id === awayTeam.organization.id)
        ?.value || 0
    : stats
        .filter(
          (s) =>
            (s.organization.id === awayTeam.organization.id &&
              s.statType.name === "Runs Scored") ||
            (s.organization.id !== awayTeam.organization.id &&
              ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name))
        )
        .reduce((accum, curr) => {
          accum += curr.value;
          return accum;
        }, 0);

  const awayWickets = completed
    ? placements
        .find((x) => x.organization.id === awayTeam.organization.id)
        ?.valueString?.split("/")?.[1]
      ? Number(
          placements
            .find((x) => x.organization.id === awayTeam.organization.id)
            ?.valueString?.split("/")?.[1]
        )
      : 0
    : stats.filter(
        (s) =>
          s.organization.id === awayTeam.organization.id &&
          [
            "Bowled",
            "LBW",
            "Caught",
            "Caught & Bowled",
            "Stumped",
            "Run Out",
            "Retired",
          ].includes(s.statType.name) &&
          s.relationshipsTo.find((x) => x.from.statType.name === "Runs Scored")
      ).length;

  const statTable = registrations
    .filter(
      (x) =>
        x.organization.id === homeTeam?.organization.id ||
        x.organization.id === awayTeam?.organization.id
    )
    .reduce((accum, curr) => {
      const newRow = {
        profile: curr.profile,
        organization: curr.organization,
        stats: {},
      };

      statTypes.forEach((statType) => {
        newRow.stats[statType.id] = stats.filter(
          (s) =>
            s.statType.id === statType.id && s.profile.id === curr.profile.id
        );
      });

      accum.push(newRow);

      return accum;
    }, []);

  for (let periodId in cache?.periods || {}) {
    const batsmen = cache.periods[periodId].batsmen;
    const notOutStatType = statTypes.find((st) => st.name === "Not Out");

    batsmen.forEach((b) => {
      if (b.profile?.id) {
        const statTableIndex = statTable.findIndex(
          (t) => t.profile.id === b.profile.id
        );
        if (statTableIndex > -1) {
          statTable[statTableIndex].stats[notOutStatType.id] = [
            {
              statType: { id: notOutStatType.id, name: "Not Out" },
              value: 1,
              profile: b.profile,
              organization: statTable[statTableIndex].organization,
            },
          ];
        }
      }
    });
  }

  // if (loading) {
  //   return (
  //     <div className="publish-stats">
  //       <Spinner />
  //     </div>
  //   );
  // }

  const battingStats = stats.filter((s) => s.statType.name === "Runs Scored");
  const groupedByOver = battingStats.reduce((accum, curr) => {
    const ballStat = curr.relationshipsFrom.find(
      (s) => s.to.statType.name === "Balls Bowled"
    );

    const runsConcededStat =
      ballStat &&
      stats.find(
        (s) =>
          s.statType.name === "Runs Conceded" &&
          s.relationshipsTo?.find((x) => x.from.id === ballStat?.to.id)
      );

    const group = Math.floor(ballStat.to.stringValue);

    const over = accum.findIndex((x) => x.group === group);

    const newStat = {
      ...curr,
      ball: ballStat.to,
      extra: stats
        .find((s) => s.id === ballStat.to.id)
        ?.relationshipsFrom?.filter((s) =>
          ["Wide", "No Ball"].includes(s.to.statType.name)
        )
        .map((s) => s.to),
      runsConceded: runsConcededStat,
    };

    if (over < 0) {
      accum.push({ group: group, stats: [newStat] });
    } else {
      accum[over].stats.push(newStat);
    }

    return accum;
  }, []);

  const statTypeValues = {
    "Balls Faced": (statsArray) => {
      return statsArray.length;
    },
    "Runs Scored": (statsArray) => {
      return statsArray.reduce((accum, curr) => {
        accum += curr.value;
        return accum;
      }, 0);
    },
    Bowled: (statsArray) => {
      return statsArray.length;
    },
    LBW: (statsArray) => {
      return statsArray.length;
    },
    "Caught & Bowled": (statsArray) => {
      return statsArray.length;
    },
    Caught: (statsArray) => {
      return statsArray.length;
    },
    Stumped: (statsArray) => {
      return statsArray.length;
    },
    // "Run Out": (statsArray) => {
    //   return statsArray.length;
    // },
    Retired: (statsArray) => {
      return statsArray.length;
    },
    "Not Out": (statsArray) => {
      return statsArray.length;
    },
    "Balls Bowled": (statsArray) => {
      // console.log("Balls bowled array:", statsArray);
      return statsArray.reduce((accum, curr) => {
        if (
          curr.relationshipsFrom?.find((r) =>
            ["Wide", "No Ball"].includes(r.to?.statType?.name)
          )
        ) {
          return accum;
        } else {
          accum += 1;
          return accum;
        }
      }, 0);
    },
    "Runs Conceded": (statsArray, row) => {
      // console.log("RUns conceded for player: ", row.profile.name, statsArray);
      return statsArray.reduce((accum, curr) => {
        accum += curr.value;
        return accum;
      }, 0);
    },
    "Wicket Taken": (statsArray) => {
      return statsArray.length;
    },
    "No Ball": (statsArray) => {
      return statsArray.length;
    },
    Wide: (statsArray) => {
      return statsArray.reduce((accum, curr) => {
        accum += curr.value;
        return accum;
      }, 0);
    },
    Bye: (statsArray) => {
      return statsArray.reduce((accum, curr) => {
        accum += curr.value;
        return accum;
      }, 0);
    },
    "Leg Bye": (statsArray) => {
      return statsArray.reduce((accum, curr) => {
        accum += curr.value;
        return accum;
      }, 0);
    },
    Catch: (statsArray) => {
      return statsArray.length;
    },
    "Run Out": (statsArray) => {
      return statsArray.filter((x) =>
        x.relationshipsTo.find((y) => y.from.statType.name === "Runs Scored")
      ).length;
    },
    Stumping: (statsArray) => {
      return statsArray.length;
    },
    "Maiden Overs": (statsArray, row) => {
      return getMaidenOvers(row.profile, groupedByOver) || 0;
    },
  };

  // console.log("STAT TYPES:", statTypes);
  // console.log("PUBLISH SOURCE TABLES:", statTable);
  const statTableWithValues = statTable.map((table) => {
    const mappedStats = {};
    for (let statTypeId in table.stats) {
      const statType = statTypes.find((x) => x.id === statTypeId);
      mappedStats[statTypeId] = statTypeValues[statType.name](
        table.stats[statTypeId],
        table
      );
    }
    return {
      ...table,
      stats: mappedStats,
    };
  });

  return (
    <PublishStats
      fixtureId={fixtureId}
      eventId={eventId}
      setPublish={setPublish}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
      registrations={registrations}
      stats={stats}
      statTypes={statTypes}
      defaultHomeScore={homeScore}
      defaultHomeWickets={homeWickets}
      defaultAwayScore={awayScore}
      defaultAwayWickets={awayWickets}
      defaultStatTable={statTableWithValues}
      loading={loading}
      completed={completed}
    />
  );
}

function PublishStats({
  fixtureId,
  eventId,
  setPublish,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  registrations,
  stats,
  statTypes,
  defaultHomeScore,
  defaultAwayScore,
  defaultHomeWickets,
  defaultAwayWickets,
  defaultStatTable,
  loading,
  completed,
  defaultDismissals = [],
}) {
  const [, setAppState] = useAppState();
  const [confirm, setConfirm] = useState(false);
  const [homeScore, setHomeScore] = useState(defaultHomeScore);
  const [awayScore, setAwayScore] = useState(defaultAwayScore);
  const [homeWickets, setHomeWickets] = useState(defaultHomeWickets);
  const [awayWickets, setAwayWickets] = useState(defaultAwayWickets);
  const [statTable, setStatTable] = useState(defaultStatTable);
  const history = useHistory();
  // const [dismissals, setDismissals] = useState(defaultDismissals);

  // console.log("PUBLISH STATS TABLES:", statTable);

  return (
    <div className="publish-stats">
      <div className="publish-stats__header">
        <div
          className="close-scoring-btn"
          onClick={() => {
            if (!completed) {
              setPublish(false);
            } else {
              history.push("/app/fixture/" + fixtureId);
              setAppState({ modal: false });
            }
          }}
        >
          <div className="icon">
            <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
          </div>
          <div className="text">{completed ? "Back to fixture" : "Back"}</div>
        </div>
        <div className="publish-stats-title">
          {"Verify Fixture Stats & Result"}
        </div>

        {!loading && (
          <div className="publish-stats-btn-wrapper">
            <div
              className="publish-stats-btn"
              onClick={() => {
                setConfirm(true);
              }}
            >
              {completed ? "Update Final Score" : "Publish this fixture"}
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="publish-stats__body">
          <div className="stats-table-container">
            <StatTable
              organization={homeTeam.organization}
              score={homeScore}
              wickets={homeWickets}
              updateScore={() => {}}
              rows={statTable}
              lineup={homeLineup}
              statTypes={statTypes}
              setScore={setHomeScore}
              setWickets={setHomeWickets}
              setStatTable={setStatTable}
              completed={completed}
              stats={stats}
            />
          </div>

          <div className="stats-table-container">
            <StatTable
              organization={awayTeam.organization}
              score={awayScore}
              wickets={awayWickets}
              updateScore={() => {}}
              rows={statTable}
              lineup={awayLineup}
              statTypes={statTypes}
              setScore={setAwayScore}
              setWickets={setAwayWickets}
              setStatTable={setStatTable}
              completed={completed}
              stats={stats}
            />
          </div>
        </div>
      )}

      {confirm && (
        <PublishStatsConfirm
          dismiss={() => {
            setConfirm(false);
          }}
          fixtureId={fixtureId}
          placements={[
            {
              organizationId: homeTeam.organization.id,
              value: homeScore,
              valueString: homeScore + "/" + homeWickets,
            },
            {
              organizationId: awayTeam.organization.id,
              value: awayScore,
              valueString: awayScore + "/" + awayWickets,
            },
          ]}
          tables={statTable.reduce((accum, curr) => {
            for (let statTypeId in curr.stats) {
              if (curr.stats[statTypeId]) {
                const statType = statTypes.find((x) => x.id === statTypeId);
                if (!statType) {
                  // console.log("Stat type not found:", statTypeId, statTypes);
                }
                accum.push({
                  profileId: curr.profile.id,
                  organizationId: curr.organization.id,
                  statTypeId,
                  value: Number(curr.stats[statTypeId] || 0),
                });
              }
            }

            return accum;
          }, [])}
          completed={completed}
        />
      )}
    </div>
  );
}

function PublishStatsConfirm({
  dismiss,
  fixtureId,
  placements,
  tables,
  completed,
}) {
  const [publishFixture, { loading }] = useMutation(PUBLISH_FIXTURE, {
    update: (cache, { data: { publishFixture } }) => {
      const q = cache.readQuery({
        query: FIXTURE_SETTINGS,
        variables: { id: fixtureId },
      });

      cache.writeQuery({
        query: FIXTURE_SETTINGS,
        variables: { id: fixtureId },
        data: {
          fixture: {
            ...q.fixture,
            completed: true,
          },
        },
      });

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.PublishStatsWrapper",
          mutation: "PUBLISH_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="confirm-publish-stats">
      <div className="confirm-publish-stats__form">
        <div className="confirm-publish-stats__form__header">
          {completed
            ? "Are you sure you want to update the final score for this fixture?"
            : "Are you sure you want to publish this fixture?"}
        </div>
        <div className="confirm-publish-stats__form__text">
          {completed ? (
            <p>
              Please note, you may need to update any relevant standings tables
              for this league on the event's page.
            </p>
          ) : (
            <Fragment>
              <p>
                This cannot be undone. Please note, users will still be able to
                see the score for the fixture from the fixtures page without
                publishing.
              </p>
              <p>
                Once you confirm we will update individual player, team and
                event stats, and the fixture will be available for use in
                standings calculations, so do make sure all stats are accurate
                before you publish the fixture.
              </p>
            </Fragment>
          )}
        </div>

        <div className="confirm-publish-stats__form__options">
          <Button
            className="secondary medium"
            onClick={() => {
              dismiss();
            }}
            disabled={loading}
          >
            No, go back
          </Button>
          <Button
            className="primary medium green"
            onClick={() => {
              if (loading) return;
              publishFixture({
                variables: {
                  fixtureId,
                  placements,
                  tables,
                },
              });
            }}
            disabled={loading}
          >
            {loading ? <Loader /> : "Yes, publish this fixture"}
          </Button>
        </div>
      </div>
    </div>
  );
}
