import { gql } from "@apollo/client";

const EVENT_REGISTRATION_FILTERS = gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      id
      participantType
      registrationFilters {
        id
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

export default EVENT_REGISTRATION_FILTERS;
