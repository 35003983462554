export default function ballsCompleted(overItems) {
  // const totalBalls = overItems?.length || 0;
  // const totalExtras =
  //   overItems?.filter((item) =>
  //     item.extras?.find((s) => ["Wide", "No Ball"].includes(s.statType.name))
  //   ).length || 0;

  // return totalBalls - totalExtras;
  console.log("Balls completed running");
  const filteredOverItems = [...overItems].filter(
    (item) =>
      !item.extras?.find((s) => ["Wide", "No Ball"].includes(s.statType.name))
  );

  if (!filteredOverItems?.length) {
    return 0;
  }

  const sortedOverItems = [...filteredOverItems].sort((a, b) => {
    return (b.ballsBowled?.value || 0) - (a.ballsBowled?.value || 0);
  });

  const latestBallInOver = sortedOverItems[0];
  console.log("Filtered:", filteredOverItems);
  console.log("Sorted:", sortedOverItems);
  console.log("Latest:", latestBallInOver);

  return (
    Number(latestBallInOver.ballsBowled.value.toFixed(1).split(".")?.[1]) || 0
  );
}
