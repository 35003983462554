import "./Organization.css";
import { useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import FollowButton from "../../components/FollowButton";
import { INTERACTION_STATS } from "../../api/profile";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import {
  OrganizationOverview,
  OrganizationEvents,
  OrganizationPhotos,
  OrganizationVideos,
  OrganizationStatistics,
  OrganizationPosts,
} from "../../mobileComponents/Organization";
import useRouterQuery from "../../utils/useRouterQuery";
import { ORGANIZATION, UPDATE_ORGANIZATION } from "../../api/organization";
import { CAN_MANAGE_PAGE } from "../../api/user";
import { useQuery } from "@apollo/client";
import Placeholder from "../../components/Placeholder";
import { useAppState } from "../../utils/appState";
import EditDisplayPhoto from "../../mobileComponents/EditDisplayPhoto";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import Avatar from "../../components/Avatar";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import PhotoBrowser from "../../mobileComponents/PhotoBrowser";

const organizationTabs = [
  { label: "Overview", component: OrganizationOverview, key: "overview" },
  { label: "Events", component: OrganizationEvents, key: "events" },
  { label: "Posts", component: OrganizationPosts, key: "posts" },
  { label: "Photos", component: OrganizationPhotos, key: "photos" },
  { label: "Videos", component: OrganizationVideos, key: "videos" },
  // { label: "Timeline", component: <div />, key: "timeline" },
  // { label: "Services", component: <div />, key: "services" },
  { label: "Statistics", component: OrganizationStatistics, key: "statistics" },
];

function Organization({ backTo }) {
  usePageTracking({ title: "Organization" });
  const { id } = useParams();
  const [, setAppState] = useAppState();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const headerRef = useRef(null);
  const isOnScreen = useIntersectionObserver(headerRef, true);
  const tab = query.get("t") || "overview";
  const selectedTab = organizationTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const authQuery = useQuery(CAN_MANAGE_PAGE, {
    variables: { id },
  });

  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id },
  });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Organization",
        query: "CAN_MANAGE_PAGE",
      },
    });
    console.log(authQuery.error);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization",
        query: "ORGANIZATION",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="organization-page-mobile">
        <div className="organization-page-mobile__nav">
          <div
            className="organization-page-mobile__nav__back-btn"
            onClick={() => {
              if (location.key) {
                history.goBack();
              } else {
                history.replace("/app/directory");
              }
            }}
          >
            <ArrowLeftIcon
              style={{ height: "16px", stroke: "var(--dark-4)" }}
            />
          </div>
        </div>
        <div className="organization-page-mobile__cover" ref={headerRef}></div>

        <div className="organization-page-mobile__header">
          <div className="organization-page-mobile__header__image"></div>

          <div className="organization-page-mobile__header__desc">
            <div className="organization-page-mobile__header__desc__primary">
              <Placeholder
                style={{ height: "12px", width: "100px" }}
                pulse={true}
              />
            </div>

            <div className="organization-page-mobile__header__desc__secondary">
              <Placeholder
                style={{ height: "12px", width: "60px", marginTop: "8px" }}
                pulse={true}
              />
            </div>

            <Placeholder
              style={{
                height: "24px",
                width: "80px",
                background: "var(--blue-subtle)",
                borderRadius: "8px",
                marginTop: "8px",
              }}
              pulse={false}
            />
          </div>
        </div>

        <div className="organization-page-mobile__tabs">
          {[1, 2, 3].map((t, i) => (
            <div
              key={"org-tab-" + i}
              className={"organization-page-mobile__tabs__tab "}
            >
              <Placeholder
                style={{
                  height: "10px",
                  width: "40px",
                  background: "var(--light-2)",
                }}
                pulse={false}
              />
            </div>
          ))}
        </div>

        <div className="organization-page-mobile__body"></div>
      </div>
    );
  }

  const org = data.organization;
  const canManagePage = authQuery?.data?.canManagePage?.authorized;
  // return <div>Organization</div>;
  return (
    <div className="organization-page-mobile">
      <div className="organization-page-mobile__nav">
        <div
          className="organization-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app/directory");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
        {!isOnScreen && (
          <div className="organization-page-mobile__nav__content">
            <div className="organization-page-mobile__nav__content__img">
              {org.profilePhoto && <Avatar media={org.profilePhoto} />}
            </div>
            {org.name}
          </div>
        )}
      </div>
      <div className="organization-page-mobile__cover" ref={headerRef}>
        <div
          className="profile-page-mobile__cover__pressable"
          onClick={() => {
            if (!org?.coverPhoto) return;
            setAppState({
              modal: (
                <PhotoBrowser
                  query={ORGANIZATION}
                  vars={{ id: org.id }}
                  getPhotos={(data) => data.organization?.coverPhotos || []}
                  totalPhotos={(data) =>
                    data.organization?.coverPhotos?.length || 0
                  }
                  media={org.coverPhoto}
                />
              ),
            });
          }}
        >
          {org?.coverPhoto && <Avatar media={org.coverPhoto} />}
        </div>

        {canManagePage && (
          <div
            className="profile-page-mobile__cover__edit-btn"
            onClick={(e) => {
              e.stopPropagation();
              setAppState({
                modal: (
                  <EditDisplayPhoto
                    config={{
                      wrapper: {
                        title: "Cover Photo",
                        query: ORGANIZATION,
                        getPhotos: (data) =>
                          data?.organization?.coverPhotos || [],
                        getSelectedPhoto: (data) =>
                          data?.organization?.coverPhoto,
                        defaultUploadState: org?.coverPhotos?.length
                          ? false
                          : true,
                        sourceId: org?.id,
                        sourceType: "organization",
                      },
                      select: {
                        mutation: UPDATE_ORGANIZATION(
                          `coverPhoto{id filename}`
                        ),
                        mutationVars: (connectOrDisconnectInput) => {
                          return {
                            coverPhoto: connectOrDisconnectInput,
                          };
                        },
                        update: (cache, { data: { updateOrganization } }) => {
                          const q = cache.readQuery({
                            query: ORGANIZATION,
                            variables: { id: org.id },
                          });

                          cache.writeQuery({
                            query: ORGANIZATION,
                            variables: { id: org.id },
                            data: {
                              organization: {
                                ...q.organization,
                                coverPhoto: updateOrganization.coverPhoto,
                              },
                            },
                          });

                          // setAppState({ modal: false });
                        },
                      },
                      upload: {
                        accept: ".jpg,.png,.jpeg",
                        multiple: false,
                        type: "IMAGE",
                        subtype: "COVER PHOTO",
                        onCreateMedia: (cache, createMedia) => {
                          const q = cache.readQuery({
                            query: ORGANIZATION,
                            variables: { id: org.id },
                          });

                          cache.writeQuery({
                            query: ORGANIZATION,
                            variables: { id: org.id },
                            data: {
                              organization: {
                                ...q.organization,
                                coverPhoto: {
                                  ...createMedia,
                                },
                                coverPhotos: [
                                  ...q.organization.coverPhotos,
                                  { ...createMedia },
                                ],
                              },
                            },
                          });

                          setAppState({ modal: false });
                        },
                        cropperWidth: 240,
                        cropperHeight: 40,
                      },
                    }}
                  />
                ),
              });
            }}
          >
            <PencilIcon className="icon" />
          </div>
        )}
      </div>

      <div className="organization-page-mobile__header">
        <div className="organization-page-mobile__header__image">
          <div
            className="profile-page-mobile__header__image__pressable"
            onClick={() => {
              if (!org?.profilePhoto) return;
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={ORGANIZATION}
                    vars={{ id: org.id }}
                    getPhotos={(data) => data.organization?.profilePhotos || []}
                    totalPhotos={(data) =>
                      data.organization?.profilePhotos?.length || 0
                    }
                    media={org.profilePhoto}
                  />
                ),
              });
            }}
          >
            {org.profilePhoto && <Avatar media={org.profilePhoto} />}
          </div>

          {canManagePage && (
            <div
              className="profile-page-mobile__cover__edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setAppState({
                  modal: (
                    <EditDisplayPhoto
                      config={{
                        wrapper: {
                          title: "Profile Photo",
                          query: ORGANIZATION,
                          getPhotos: (data) =>
                            data?.organization?.profilePhotos || [],
                          getSelectedPhoto: (data) =>
                            data?.organization?.profilePhoto,
                          defaultUploadState: org?.profilePhotos?.length
                            ? false
                            : true,
                          sourceId: org.id,
                          sourceType: "organization",
                        },
                        select: {
                          mutation: UPDATE_ORGANIZATION(
                            `profilePhoto{id filename}`
                          ),
                          mutationVars: (connectOrDisconnectInput) => {
                            return {
                              profilePhoto: connectOrDisconnectInput,
                            };
                          },
                          update: (cache, { data: { updateOrganization } }) => {
                            const q = cache.readQuery({
                              query: ORGANIZATION,
                              variables: { id: org.id },
                            });

                            cache.writeQuery({
                              query: ORGANIZATION,
                              variables: { id: org.id },
                              data: {
                                organization: {
                                  ...q.organization,
                                  profilePhoto: updateOrganization.profilePhoto,
                                },
                              },
                            });

                            // setAppState({ modal: false });
                          },
                        },
                        upload: {
                          multiple: false,
                          accept: ".jpg,.png,.jpeg",
                          type: "IMAGE",
                          subtype: "PROFILE PHOTO",
                          onCreateMedia: (cache, createMedia) => {
                            const q = cache.readQuery({
                              query: ORGANIZATION,
                              variables: { id: org.id },
                            });

                            cache.writeQuery({
                              query: ORGANIZATION,
                              variables: { id: org.id },
                              data: {
                                organization: {
                                  ...q.organization,
                                  profilePhoto: {
                                    ...createMedia,
                                  },
                                  profilePhotos: [
                                    ...q.organization.profilePhotos,
                                    { ...createMedia },
                                  ],
                                },
                              },
                            });

                            setAppState({ modal: false });
                          },
                          // cropperWidth: 240,
                          // cropperHeight: 40,
                        },
                      }}
                    />
                  ),
                });
              }}
            >
              <PencilIcon className="icon" />
            </div>
          )}
        </div>

        <div className="organization-page-mobile__header__desc">
          <div className="organization-page-mobile__header__desc__primary">
            {org.name}
          </div>

          <div className="organization-page-mobile__header__desc__secondary">
            @{org.handle}
          </div>

          <FollowButton
            id={id}
            type={"organization"}
            onFollow={(cache) => {
              const q = cache.readQuery({
                query: INTERACTION_STATS,
                variables: { id },
              });

              if (q?.interactionStats) {
                cache.writeQuery({
                  query: INTERACTION_STATS,
                  variables: { id },
                  data: {
                    interactionStats: {
                      ...q.interactionStats,
                      followers: q.interactionStats.followers + 1,
                    },
                  },
                });
              }
            }}
            onUnfollow={(cache) => {
              const q = cache.readQuery({
                query: INTERACTION_STATS,
                variables: { id },
              });

              if (q?.interactionStats) {
                cache.writeQuery({
                  query: INTERACTION_STATS,
                  variables: { id },
                  data: {
                    interactionStats: {
                      ...q.interactionStats,
                      followers: q.interactionStats.followers - 1,
                    },
                  },
                });
              }
            }}
          />
        </div>
      </div>

      <div className="organization-page-mobile__tabs">
        {organizationTabs.map((t, i) => (
          <div
            key={"org-tab-" + i}
            className={
              "organization-page-mobile__tabs__tab " +
              (selectedTab.key === t.key ? "active" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}

        {/* <div className="organization-page-mobile__tabs__tab active">
          Overview
        </div>

        <div className="organization-page-mobile__tabs__tab">Events</div>

        <div className="organization-page-mobile__tabs__tab">Posts</div>

        <div className="organization-page-mobile__tabs__tab">Photos</div>

        <div className="organization-page-mobile__tabs__tab">Videos</div>

        <div className="organization-page-mobile__tabs__tab">Services</div>

        <div className="organization-page-mobile__tabs__tab">Statistics</div> */}
      </div>

      <div className="organization-page-mobile__body">
        <SelectedComponent
          id={id}
          organization={org}
          canManagePage={canManagePage}
        />
      </div>
    </div>
  );
}

export default Organization;
