export default function getScore(period, participant, scores) {
  if (!period || !participant) return 0;
  const periodScore = scores.find((p) => p.id === period.id);
  const score = periodScore?.scores?.find(
    (p) =>
      p.id ===
      period.id + (participant.profile?.id || participant?.organization?.id)
  );

  return score?.value || 0;
}
