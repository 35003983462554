import "./PageOnboarding.css";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import { ReactComponent as IndiaFlagIcon } from "../../icons/india-flag.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../icons/tennis.svg";
import Loader from "../../components/Loader";
import Dropdown from "../../components/Dropdown";
import Chip from "../../components/Chip";
import Spinner from "../../components/Spinner";
import Card from "../../components/Card";
import Square from "../../mobileComponents/Square";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  UPDATE_ORGANIZATION,
  ORGANIZATION_TYPES,
} from "../../api/organization";
import Placeholder from "../../components/Placeholder";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

function ContactInfo({ id, setStep }) {
  const [phone, setPhone] = useState("");
  const [invalidPhone, setInvalidPhone] = useState("");
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [location, setLocation] = useState("");
  // const [locationError, setLocationError] = useState("");
  const [invalidLocation, setInvalidLocation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cities, setCities] = useState([]);

  const [updateOrganization, { loading }] = useMutation(
    UPDATE_ORGANIZATION(`id`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        // const q = cache.readQuery({
        //   query: ORGANIZATIONS,
        //   variables: { where: {} },
        // });

        // // console.log("Q:", q);
        // if (q?.organizations) {
        //   cache.writeQuery({
        //     query: ORGANIZATIONS,
        //     variables: { where: {} },
        //     data: {
        //       organizations: [createOrganization, ...q.organizations],
        //     },
        //   });
        // }

        // history.replace("/app/onboard_page/" + createOrganization.id);
        setStep(2);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "PageOnboarding.ContactInfo",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="onboard-page-mobile__contact">
      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Set up your page
          </div>

          <div className="onboarding-form-mobile__title__secondary">
            Step 1 of 3 : Enter contact info
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (loading) return;

            setErrorMessage("");

            const v = {
              data: {},
            };

            if (!cities.length) {
              setInvalidLocation(true);
              setErrorMessage("Location is required.");
              return;
            } else {
              v.data.cities = { connect: cities };
            }

            if (phone) {
              v.data.phone = phone;
            }

            if (email) {
              v.data.email = email;
            }

            updateOrganization({
              variables: {
                where: { id },
                data: v.data,
              },
            });

            // if (!name || !handle) {
            //   if (!name) {
            //     setInvalidName(true);
            //   }

            //   if (!handle) {
            //     setInvalidHandle(true);
            //   }

            //   setErrorMessage("All fields are required");
            //   return;
            // }

            // isUniqueHandle({
            //   variables: { handle: handle },
            // });

            // login({
            //   variables: {
            //     email,
            //     password,
            //   },
            // });
          }}
        >
          <div className="onboarding-form-mobile__groups">
            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Location <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <PlacesAutocomplete
                value={location}
                onChange={(x) => {
                  setLocation(x);
                  setErrorMessage("");
                }}
                onSelect={(loc) => {
                  // console.log(loc);
                  const city = loc?.split(",")[0]?.trim();
                  const state = loc?.split(",")[1]?.trim();
                  const country = loc?.split(",")[2]?.trim();

                  geocodeByAddress(loc)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                      setCities((prev) => {
                        return [
                          ...prev,
                          {
                            city,
                            state,
                            country,
                            lat: latLng.lat,
                            lng: latLng.lng,
                          },
                        ];
                      });
                    })
                    .catch((error) => console.error("Error", error));
                  setLocation("");
                }}
                debounce={200}
                searchOptions={{
                  types: ["(cities)"],
                  componentRestrictions: {
                    country: "IN",
                  },
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Dropdown
                    className="onboarding-form-mobile__dropdown-input-wrapper"
                    placement="bottom-start"
                    style={{ width: "100%" }}
                    target={
                      <div
                        className={
                          "onboarding-form-mobile__group__field multi " +
                          (invalidLocation ? "error" : "")
                        }
                      >
                        <div className="onboarding-form-mobile__group__field__icon">
                          <LocationIcon
                            style={{ stroke: "var(--light-3)", height: 14 }}
                          />
                        </div>
                        <div className="onboarding-form-mobile__group__field__multi-select-wrapper">
                          {cities.map((c, i) => (
                            <Chip
                              key={"location" + i}
                              className="multi-select-item"
                              onClick={() => {
                                setCities((prev) => {
                                  return [
                                    ...prev.slice(0, i),
                                    ...prev.slice(i + 1, prev.length),
                                  ];
                                });
                              }}
                            >
                              <span className="multi-select-item__text">
                                {c.city}
                              </span>{" "}
                              <TimesIcon
                                style={{
                                  stroke: "var(--light-1)",
                                  height: "14px",
                                }}
                              />{" "}
                            </Chip>
                          ))}
                          {cities.length < 2 && (
                            <input
                              {...getInputProps({
                                className:
                                  "onboarding-form-mobile__group__field__input",
                                onFocus: () => {
                                  setInvalidLocation(false);
                                },
                                autoComplete: "off",
                              })}
                              value={location}
                              id="location"
                            />
                          )}

                          {/* </div> */}
                        </div>

                        <div
                          className="static-icon"
                          style={{ marginRight: "8px" }}
                        >
                          <IndiaFlagIcon />
                        </div>
                      </div>
                    }
                    menu={({ setShowMenu }) => (
                      <Card className="onboarding-form-mobile__group__field__dropdown-menu">
                        {loading && <Spinner />}
                        {suggestions
                          .filter(
                            (suggestion) =>
                              suggestion?.description?.split(",")?.length &&
                              suggestion?.description?.split(",").length > 2
                          )
                          .map((suggestion, i) => (
                            <div
                              key={"city-suggestion-" + i}
                              {...getSuggestionItemProps(suggestion, {
                                className:
                                  "onboarding-form-mobile__group__field__dropdown-menu__item",
                              })}
                            >
                              {suggestion.description}
                              {/*<span className="state">{city.within.value}</span>*/}
                            </div>
                          ))}
                      </Card>
                    )}
                    onClose={() => {
                      setLocation("");
                    }}
                  />
                )}
              </PlacesAutocomplete>

              {/* <div
                className={
                  "onboarding-form-mobile__group__field " +
                  (invalidName ? "error" : "")
                }
              >
                <div className="onboarding-form-mobile__group__field__icon">
                  <DirectoryIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div>

                <input
                  type="text"
                  className="onboarding-form-mobile__group__field__input"
                  value={name}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setName(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidName(false);
                  }}
                  autoFocus
                />
              </div> */}
            </div>

            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Contact Number{" "}
                {/* <span style={{ color: "var(--red-main)" }}>*</span> */}
              </div>

              <div
                className={
                  "onboarding-form-mobile__group__field " +
                  (invalidPhone ? "error" : "")
                }
              >
                <div className="onboarding-form-mobile__group__field__icon">
                  <PhoneIcon style={{ stroke: "var(--light-3)", height: 14 }} />
                </div>

                <input
                  type="tel"
                  pattern={"[0-9]{5}-[0-9]{5}"}
                  value={phone}
                  className="onboarding-form-mobile__group__field__input"
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setPhone(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidPhone(false);
                  }}
                />
              </div>
            </div>

            <div className="onboarding-form-mobile__group">
              <div className="onboarding-form-mobile__group__label">
                Contact Email{" "}
                {/* <span style={{ color: "var(--red-main)" }}>*</span> */}
              </div>

              <div
                className={
                  "onboarding-form-mobile__group__field " +
                  (invalidEmail ? "error" : "")
                }
              >
                <div className="onboarding-form-mobile__group__field__icon">
                  <MailIcon style={{ fill: "var(--light-3)", height: 14 }} />
                </div>

                <input
                  type="email"
                  value={email}
                  className="onboarding-form-mobile__group__field__input"
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setEmail(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidEmail(false);
                  }}
                />
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="onboarding-form-mobile__error">{errorMessage}</div>
          )}

          <DebouncedButton
            type="submit"
            className="onboarding-form-mobile__submit"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Next"}
          </DebouncedButton>

          {/* <button
            type="submit"
            className={
              "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            }
          >
            {loading ? <Loader theme="small" /> : "Next"}
          </button> */}
        </form>
      </div>
    </div>
  );
}

function PageCategories({ id, setStep }) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { loading, data, error } = useQuery(ORGANIZATION_TYPES);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PageOnboarding.PageCategories",
        query: "ORGANIZATION_TYPES",
      },
    });
  }

  const [updateOrganization, updateOrganizationMutation] = useMutation(
    UPDATE_ORGANIZATION(`id`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        // const q = cache.readQuery({
        //   query: ORGANIZATIONS,
        //   variables: { where: {} },
        // });

        // // console.log("Q:", q);
        // if (q?.organizations) {
        //   cache.writeQuery({
        //     query: ORGANIZATIONS,
        //     variables: { where: {} },
        //     data: {
        //       organizations: [createOrganization, ...q.organizations],
        //     },
        //   });
        // }

        // history.replace("/app/onboard_page/" + createOrganization.id);
        setStep(3);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "PageOnboarding.PageCategories",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const categories = data?.organizationTypes;

  return (
    <div className="onboard-page-mobile__categories">
      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Set up your page
          </div>

          <div className="onboarding-form-mobile__title__secondary">
            Step 2 of 3: Select page categories
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (updateOrganizationMutation.loading) return;

            if (!selectedCategories.length) {
              setStep(3);
              return;
            }

            updateOrganization({
              variables: {
                where: { id },
                data: {
                  types: {
                    connect: selectedCategories.map((i) => ({ id: i.id })),
                  },
                },
              },
            });
          }}
        >
          <div className="onboard-page-mobile__categories__subtitle">
            What categories apply to this page?
          </div>

          {loading ? (
            <div className="onboard-page-mobile__categories__list">
              <Placeholder
                style={{
                  width: "80px",
                  height: "24px",
                  borderRadius: "8px",
                  marginTop: "16px",
                  marginRight: "8px ",
                }}
                pulse={true}
              />

              <Placeholder
                style={{
                  width: "80px",
                  height: "24px",
                  borderRadius: "8px",
                  marginTop: "16px",
                  marginRight: "8px ",
                }}
                pulse={true}
              />

              <Placeholder
                style={{
                  width: "240px",
                  height: "24px",
                  borderRadius: "8px",
                  marginTop: "16px",
                  marginRight: "8px ",
                }}
                pulse={true}
              />
            </div>
          ) : (
            <div className="onboard-page-mobile__categories__list">
              {categories?.map((c) => (
                <div
                  className={
                    "onboard-page-mobile__categories__list__item " +
                    (selectedCategories.find((x) => x.id === c.id)
                      ? "selected"
                      : "")
                  }
                  onClick={() => {
                    setSelectedCategories((prev) => {
                      if (prev.find((x) => x.id === c.id)) {
                        return prev.filter((x) => x.id !== c.id);
                      } else {
                        return [...prev, c];
                      }
                    });
                  }}
                >
                  {c.name}
                </div>
              ))}
            </div>
          )}

          {loading ? (
            <Placeholder
              style={{
                height: "36px",
                width: "100%",
                borderRadius: "8px",
                background: "var(--blue-subtle)",
                marginTop: "32px",
              }}
              pulse={false}
            />
          ) : (
            <DebouncedButton
              type="submit"
              className="onboarding-form-mobile__submit"
              loading={loading || updateOrganizationMutation.loading}
            >
              {updateOrganizationMutation.loading ? (
                <Loader theme="small" />
              ) : (
                "Next"
              )}
            </DebouncedButton>
            // <button
            //   type="submit"
            //   className={
            //     "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            //   }
            // >
            //   {updateOrganizationMutation.loading ? (
            //     <Loader theme="small" />
            //   ) : (
            //     "Next"
            //   )}
            // </button>
          )}
        </form>
      </div>
    </div>
  );
}

function RelatedSports({ id, setStep }) {
  const history = useHistory();
  const [interests, setInterests] = useState([]);

  const [updateOrganization, { loading }] = useMutation(
    UPDATE_ORGANIZATION(`id`),
    {
      update: (cache, { data: { updateOrganization } }) => {
        // const q = cache.readQuery({
        //   query: ORGANIZATIONS,
        //   variables: { where: {} },
        // });

        // // console.log("Q:", q);
        // if (q?.organizations) {
        //   cache.writeQuery({
        //     query: ORGANIZATIONS,
        //     variables: { where: {} },
        //     data: {
        //       organizations: [createOrganization, ...q.organizations],
        //     },
        //   });
        // }

        // history.replace("/app/onboard_page/" + createOrganization.id);
        // setStep(3);
        history.replace("/app/page/" + id);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "PageOnboarding.RelatedSports",
            mutation: "UPDATE_ORGANIZATION",
          },
        });
        console.log(error);
      },
    }
  );

  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
  };

  return (
    <div className="onboard-page-mobile__sports">
      <div className="onboarding-form-mobile__wrapper">
        <div className="onboarding-form-mobile__title">
          <div className="onboarding-form-mobile__title__primary">
            Set up your page
          </div>

          <div className="onboarding-form-mobile__title__secondary">
            Step 3 of 3: Select related sports
          </div>
        </div>

        <form
          className="onboarding-form-mobile"
          onSubmit={(e) => {
            // console.log("Form submitted");
            e.preventDefault();

            if (loading) return;

            if (!interests.length) {
              history.replace("/app/page/" + id);
              return;
            }

            updateOrganization({
              variables: {
                where: { id },
                data: {
                  interests: {
                    connect: interests.map((i) => ({ name: i.name })),
                  },
                },
              },
            });

            // setErrorMessage("");

            // if (!name || !handle) {
            //   if (!name) {
            //     setInvalidName(true);
            //   }

            //   if (!handle) {
            //     setInvalidHandle(true);
            //   }

            //   setErrorMessage("All fields are required");
            //   return;
            // }

            // isUniqueHandle({
            //   variables: { handle: handle },
            // });

            // login({
            //   variables: {
            //     email,
            //     password,
            //   },
            // });
          }}
        >
          <div className="onboard-page-mobile__categories__subtitle">
            What sports is this page related to?
          </div>

          <div className="onboard-page-mobile__sports__list">
            {["Football", "Cricket", "Basketball", "Tennis", "Badminton"].map(
              (s) => (
                <Square key={"Sport-" + s}>
                  {({ squareRef, dimensions }) => (
                    <div
                      ref={squareRef}
                      className={
                        "onboard-page-mobile__sports__list__item " +
                        (interests.find((i) => i.name === s) ? "selected" : "")
                      }
                      onClick={() => {
                        setInterests((prev) => {
                          if (prev.find((i) => i.name === s)) {
                            return prev.filter((i) => i.name !== s);
                          } else {
                            return [...prev, { name: s }];
                          }
                        });
                      }}
                      style={{ height: dimensions.width }}
                    >
                      <div className="sport-icon">{sportsIcons[s]}</div>
                      <div className="sport-text">{s}</div>
                    </div>
                  )}
                </Square>
              )
            )}
          </div>

          <DebouncedButton
            type="submit"
            className="onboarding-form-mobile__submit"
            loading={loading}
          >
            {loading ? <Loader theme="small" /> : "Finish"}
          </DebouncedButton>
          {/* <button
            type="submit"
            className={
              "onboarding-form-mobile__submit " + (loading ? "disabled" : "")
            }
          >
            {loading ? <Loader theme="small" /> : "Finish"}
          </button> */}
        </form>
      </div>
    </div>
  );
}

export default function PageOnboarding() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [step, setStep] = useState(1);

  return (
    <div className="onboard-page-mobile">
      <div className="onboard-page-mobile__nav">
        <div
          className="onboard-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
      </div>

      {step === 1 && <ContactInfo id={id} setStep={setStep} />}
      {step === 2 && <PageCategories id={id} setStep={setStep} />}
      {step === 3 && <RelatedSports id={id} setStep={setStep} />}

      <div className="onboarding-form-mobile__graphic">
        <img src="/circle-bg.svg" alt="" />
      </div>

      <div className="onboarding-form-mobile__logo-bottom">
        <img src="/logo-white.svg" alt="" />
      </div>
    </div>
  );
}
