import { gql } from "@apollo/client";

const UPDATE_EVENT = (selectionSet) => {
  return gql`
    mutation updateEventMutation($where:UniqueInput!, $data: UpdateEventInput!) {
      updateEvent(where:$where, data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
};

export default UPDATE_EVENT;
