import "./LocationSearch.css";
import { useState } from "react";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import ClickOutside from "../../components/ClickOutside";
import { useAppState } from "../../utils/appState";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function LocationSearch({
  locations = [],
  onLocationSelect = () => {},
}) {
  const [searchString, setSearchString] = useState("");
  const [selectedLocations, setSelectedLocations] = useState(locations);
  const [, setAppState] = useAppState();

  const onChange = (x) => {
    setSearchString(x);
  };

  const onSelect = (address) => {
    setSearchString("");
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const city = address?.split(",")[0].trim();
        const newLocations = [
          ...selectedLocations,
          { name: city, address: address, lat: latLng.lat, lng: latLng.lng },
        ];
        setSelectedLocations(newLocations);
        onLocationSelect(newLocations);
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="location-search-mobile">
          <PlacesAutocomplete
            value={searchString}
            onChange={onChange}
            onSelect={onSelect}
            debounce={200}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="location-search-mobile__content" ref={wrapperRef}>
                <div className="location-search-mobile__content__search">
                  <div
                    className="location-search-mobile__content__search__back-btn"
                    onClick={() => {
                      setAppState({ modal: false });
                    }}
                  >
                    <ArrowLeftIcon
                      style={{ height: "14px", stroke: "var(--light-4)" }}
                    />
                  </div>

                  <div className="location-search-mobile__content__search__input">
                    <input
                      {...getInputProps({
                        placeholder: "Enter a location",
                      })}
                      autoFocus
                    />
                  </div>
                </div>

                {!!selectedLocations.length && (
                  <div className="location-search-mobile__content__selected">
                    {selectedLocations.map((l, i) => (
                      <div
                        key={"selected-loc-" + i}
                        className="location-search-mobile__content__selected__item"
                        onClick={() => {
                          const newLocations = selectedLocations.filter(
                            (x) => x.name !== l.name
                          );
                          setSelectedLocations(newLocations);
                          onLocationSelect(newLocations);
                        }}
                      >
                        {l.name}
                        <TimesIcon
                          style={{ height: "8px", stroke: "var(--light-1)" }}
                        />
                      </div>
                    ))}
                  </div>
                )}

                <div className="location-search-mobile__content__items">
                  {suggestions
                    .filter(
                      (suggestion) =>
                        suggestion?.description?.split(",")?.length &&
                        suggestion?.description?.split(",").length > 2
                    )
                    .map((suggestion, i) => {
                      const className = suggestion.active
                        ? "location-search-mobile__content__items__item--active"
                        : "location-search-mobile__content__items__item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={"suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style: {
                              padding: "8px",
                              paddingLeft: "16px",
                              paddingRight: "16px",
                              ...style,
                            },
                          })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      )}
    </ClickOutside>
  );
}
