import { ReactComponent as JerseyIcon } from "../../../../icons/jersey.svg";
import { ReactComponent as TimesIcon } from "../../../../icons/times.svg";

export default function SelectBatsmen({
  title,
  onSelect,
  defaultTeam,
  batsmen,
  dismiss,
}) {
  return (
    <div className="stat-overlay">
      <div className="stat-input-form">
        <div className="stat-input-form__title">
          {title}
          <div className="dismiss-btn" onClick={dismiss}>
            <TimesIcon style={{ stroke: "var(--light-2", height: "20px" }} />
          </div>
        </div>
        {/* <div className="select-player">
            <div className="team-selector">
              <div
                className={"team-selector__item " + (isHomeTeam ? "active" : "")}
                onClick={() => {
                  setIsHomeTeam(true);
                }}
              >
                {homeTeam.organization.name}
              </div>
  
              <div
                className={"team-selector__item " + (!isHomeTeam ? "active" : "")}
                onClick={() => {
                  setIsHomeTeam(false);
                }}
              >
                {awayTeam.organization.name}
              </div>
            </div>
          </div> */}

        <div className="player-list">
          {batsmen.map((r) => (
            <div
              className="select-player__item"
              onClick={() => {
                onSelect(r);
                dismiss();
              }}
            >
              <div className="img"></div>
              <div className="name">{r.profile.name}</div>
              <div className="num-container">
                <JerseyIcon
                  style={{ fill: "var(--light-4)", position: "absolute" }}
                />
                <div className="num-val">{r.order || "-"}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
