// HOME PAGE FOR MOBILE

import "./Home.css";
// import { Card } from "../../components";
// import { ReactComponent as BookmarkIcon } from "../../icons/bookmark.svg";
// import { ReactComponent as ClapIcon } from "../../icons/clap.svg";
// import { Link } from "react-router-dom";
import {
  ProfileCard,
  PagesList,
  UpcomingEvents,
} from "../../mobileComponents/Home";

import { PostsList } from "../../components/Home";
import { usePageTracking } from "../../utils/usePageTracking";

function Home() {
  usePageTracking({ title: "Home" });
  return (
    <div className="App-content">
      <div className="home-page-mobile">
        <ProfileCard />

        <PagesList />

        <UpcomingEvents />

        <div className="home-page-mobile__posts-list">
          <PostsList />
        </div>
      </div>
    </div>
  );
}

export default Home;
