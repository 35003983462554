import "./CreateSupportTicket.css";
import Button from "../Button";
import Card from "../Card";
import Spinner from "../Spinner";
import { useAppState } from "../../utils/appState";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { CREATE_SUPPORT_TICKET, USER_PROFILE } from "../../api/user";
import captureException from "../../utils/captureException";
import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import Loader from "../Loader";

function TicketSubmitted({
  successMessage = {
    title: "Thanks for getting in touch!",
    message:
      "A representative from Sports Paddock will be in touch with you shortly.",
  },
}) {
  const [, setAppState] = useAppState();
  return (
    <Card className="ticket-submitted">
      <div className="ticket-submitted__title">
        <div className="ticket-submitted__title__text">
          {successMessage.title}
        </div>
        <div
          className="ticket-submitted__title__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="ticket-submitted__title__dismiss__icon" />
        </div>
      </div>

      <div className="ticket-submitted__desc">{successMessage.message}</div>
    </Card>
  );
}

function CreateSupportTicketForm({
  email: defaultEmail,
  phone: defaultPhone,
  type,
  onSuccess,
  fields,
  messageLabel = "Message",
  messageRequired,
  getVars,
}) {
  const [email, setEmail] = useState(defaultEmail || "");
  const [phone, setPhone] = useState(defaultPhone || "");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const [mutate, { loading }] = useMutation(CREATE_SUPPORT_TICKET, {
    update: (cache, { data: { createSupportTicket } }) => {
      if (createSupportTicket?.id) {
        onSuccess();
      }
    },
    onError: (err) => {
      // captureException({
      //   error: err,
      //   info: {
      //     type: "mutation",
      //     component: "CreateSupportTicket",
      //     mutation: "CREATE_SUPPORT_TICKET",
      //   },
      // });
      setError(
        "Oops, something went wrong. Please check your internet connection and try again."
      );
    },
  });

  return (
    <Fragment>
      <div className="create-support-ticket__body">
        {(fields.email || fields.phone) && (
          <div className="create-support-ticket__body__row">
            {fields.email && (
              <div className="create-support-ticket__body__group">
                <div className="create-support-ticket__body__group__label">
                  Email <span className="required">*</span>
                </div>

                <div className="create-support-ticket__body__group__input">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            )}

            {fields.phone && (
              <div
                className="create-support-ticket__body__group"
                style={{ paddingLeft: "8px" }}
              >
                <div className="create-support-ticket__body__group__label">
                  Phone <span className="required">*</span>
                </div>

                <div className="create-support-ticket__body__group__input">
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {fields.subject && (
          <div className="create-support-ticket__body__group">
            <div className="create-support-ticket__body__group__label">
              Subject <span className="required">*</span>
            </div>

            <div className="create-support-ticket__body__group__input">
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className="create-support-ticket__body__group">
          <div className="create-support-ticket__body__group__label">
            {messageLabel}{" "}
            {messageRequired && <span className="required">*</span>}
          </div>

          <div className="create-support-ticket__body__group__input">
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>

      {error && <div className="create-support-ticket__error">{error}</div>}

      <div className="create-support-ticket__footer">
        <Button
          className="primary blue large"
          onClick={() => {
            if (loading) return;

            if (messageRequired && !description) {
              setError("Please fill out all required fields.");
              return;
            }

            if (fields.email && !email) {
              setError("Please fill out all required fields.");
              return;
            }

            if (fields.phone && !phone) {
              setError("Please fill out all required fields.");
              return;
            }

            if (fields.subject && !subject) {
              setError("Please fill out all required fields.");
              return;
            }

            setError("");

            const vars = getVars({ email, phone, subject, description });

            mutate({
              variables: {
                data: vars,
              },
            });
          }}
        >
          {loading ? <Loader /> : "Submit"}
        </Button>
      </div>
    </Fragment>
  );
}

export default function CreateSupportTicket({
  title,
  type,
  fields,
  messageLabel,
  messageRequired,
  getVars,
  successMessage,
}) {
  const [, setAppState] = useAppState();
  const [success, setSuccess] = useState(false);
  const { loading, error, data } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "CreateSupportTicket",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <Card>
        <Spinner />
      </Card>
    );
  }

  const userProfile = data.userProfile;

  if (!userProfile) {
    return (
      <Card>
        <div>You must be loggin in to do this</div>
        <Button className="primary medium blue">Login</Button>
      </Card>
    );
  }

  console.log(userProfile);

  if (success) {
    return <TicketSubmitted successMessage={successMessage} />;
  }

  return (
    <Card className="create-support-ticket">
      <div className="create-support-ticket__header">
        <div className="create-support-ticket__header__title">{title}</div>

        <div
          className="create-support-ticket__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="create-support-ticket__header__dismiss__icon" />
        </div>
      </div>

      <CreateSupportTicketForm
        email={userProfile.email}
        phone={userProfile.phone}
        type={type}
        fields={fields}
        onSuccess={() => {
          setSuccess(true);
        }}
        messageLabel={messageLabel}
        messageRequired={messageRequired}
        getVars={getVars}
      />
    </Card>
  );
}
