import "./PostMediaBrowser.css";
import { useState, useEffect, Fragment } from "react";
import { useAppState } from "../../utils/appState";
import makeFileUrl from "../../utils/makeFileUrl";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right.svg";
import { ReactComponent as ClapIcon } from "../../icons/clap.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import YouTubeVideo from "../YouTubeVideo";
import Avatar from "../Avatar";
import Spinner from "../Spinner";
import PhotoBrowserDescription from "../PhotoBrowser/components/PhotoBrowserDescription";
import moment from "moment";
import { CLAP, UNCLAP } from "../../api/user";
import { MEDIA } from "../../api/media";
import { useQuery, useMutation } from "@apollo/client";
import captureException from "../../utils/captureException";
import NewDropdown from "../NewDropdown/NewDropdown";
import Card from "../Card";
import CreateSupportTicket from "../CreateSupportTicket";

function PhotoBrowserSidebarOptionsRight({ media }) {
  const source = media.source;
  if (source.fixture) {
    return (
      <Fragment>
        <div className="app-photo-browser__info__options__option__text">
          <div className="app-photo-browser__info__options__option__text__primary">
            {source.fixture.event.name}
          </div>
          <div className="app-photo-browser__info__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="app-photo-browser__info__options__option__image">
          {source.fixture.event?.profilePhoto && (
            <Avatar media={source.fixture.event?.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else if (source.event) {
    return (
      <Fragment>
        <div className="app-photo-browser__info__options__option__text">
          <div className="app-photo-browser__info__options__option__text__primary">
            {source.event.organizedBy.name}
          </div>

          <div className="app-photo-browser__info__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="app-photo-browser__info__options__option__image">
          {source.event.organizedBy?.profilePhoto && (
            <Avatar media={source.event.organizedBy?.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else if (source.organization) {
    return (
      <Fragment>
        <div className="app-photo-browser__info__options__option__text">
          <div className="app-photo-browser__info__options__option__text__primary">
            {media.createdByProfile.name}
          </div>
          <div className="app-photo-browser__info__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="app-photo-browser__info__options__option__image">
          {media.createdByProfile?.profilePhoto && (
            <Avatar media={media.createdByProfile?.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else if (source.profile) {
    return (
      <Fragment>
        <div className="app-photo-browser__info__options__option__text">
          <div className="app-photo-browser__info__options__option__text__primary">
            {source.profile.name}
          </div>
          <div className="app-photo-browser__info__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="app-photo-browser__info__options__option__image">
          {source.profile?.profilePhoto && (
            <Avatar media={source.profile?.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
}

function PhotoBrowserSidebarOptions({ media }) {
  const [, setAppState] = useAppState();
  const [clapped, setClapped] = useState(media.isClapped);
  const [clapCount, setClapCount] = useState(media.clapCount);
  const [clap, { loading: clapping }] = useMutation(CLAP, {
    update: (cache, { data }) => {
      if (data.clap?.id) {
        setClapped(true);
        setClapCount((prev) => prev + 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PhotoBrowser.PhotoBrowserSidebarOptions",
          mutation: "CLAP",
        },
      });
      console.log(error);
    },
  });
  const [unclap, { loading: unclapping }] = useMutation(UNCLAP, {
    update: (cache, { data }) => {
      if (data.unclap?.id) {
        setClapped(false);
        setClapCount((prev) => prev - 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PhotoBroswer.PhotoBrowserSidebarOptions",
          mutation: "UNCLAP",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="app-photo-browser__info__options">
      <div
        className={
          "app-photo-browser__info__options__option " +
          (clapping || unclapping ? "disabled" : "")
        }
        onClick={() => {
          if (clapping || unclapping) {
            return;
          }

          if (clapped) {
            unclap({ variables: { mediaId: media.id } });
          } else {
            clap({ variables: { mediaId: media.id } });
          }
        }}
      >
        <div className="icon">
          <ClapIcon
            style={{
              fill: clapped ? "var(--blue-main)" : "var(--dark-4)",
              height: "20px",
            }}
          />
        </div>
        <div className="value">{clapCount || ""}</div>
      </div>
      <div className={"app-photo-browser__info__options__option right "}>
        <PhotoBrowserSidebarOptionsRight media={media} />
      </div>
      <NewDropdown
        target={
          <div className="app-photo-browser__info__options__option__more">
            <EllipsisIcon
              style={{
                height: "18px",
                fill: "var(--dark-4)",
                transform: "rotate(90deg)",
              }}
            />
          </div>
        }
        menu={
          <Card className="app-post-card__options-menu">
            <div
              className="app-post-card__options-menu__item"
              onClick={() => {
                setAppState({
                  modal: (
                    <CreateSupportTicket
                      title={"Report Photo/Video"}
                      type={"FLAGGED_MEDIA"}
                      fields={{
                        email: false,
                        phone: false,
                        subject: false,
                      }}
                      messageLabel={"Describe your issue (optional)"}
                      messageRequired={false}
                      getVars={({ email, phone, subject, description }) => {
                        const v = {
                          type: "FLAGGED_MEDIA",
                          description: description || undefined,
                          media: {
                            connect: {
                              id: media.id,
                            },
                          },
                        };

                        return v;
                      }}
                      successMessage={{
                        title: "Thank you for your feedback.",
                        message:
                          "Our team will review this photo/video and take any necessary action.",
                      }}
                    />
                  ),
                });
              }}
            >
              Report this post
            </div>
          </Card>
        }
      />
    </div>
  );
}

function PhotoBrowserSidebarSource({ media }) {
  const source = media.source;
  if (source.fixture) {
    const fixture = source.fixture;
    const participants = fixture.roles
      .filter((r) => r.type.name === "PARTICIPANT")
      .sort((a, b) => a.order - b.order);
    const homeTeam = participants[0];
    const awayTeam = participants[1];
    const homeScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[0]?.organization?.id
    );

    const awayScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[1]?.organization?.id
    );
    return (
      <div className="app-photo-browser__info__source">
        <div className="app-photo-browser__info__source__fixture">
          <div className="app-photo-browser__info__source__fixture__team home">
            <div className="app-photo-browser__info__source__fixture__team__image">
              {homeTeam.organization.profilePhoto && (
                <Avatar media={homeTeam.organization.profilePhoto} />
              )}
            </div>

            <div className="app-photo-browser__info__source__fixture__team__title">
              {homeTeam.organization.name}
            </div>

            <div className="app-photo-browser__info__source__fixture__team__score">
              {homeScore?.value}
            </div>
          </div>
          <div className="app-photo-browser__info__source__fixture__seperator">
            -
          </div>
          <div className="app-photo-browser__info__source__fixture__team away">
            <div className="app-photo-browser__info__source__fixture__team__score">
              {awayScore?.value}
            </div>
            <div className="app-photo-browser__info__source__fixture__team__title">
              {awayTeam.organization.name}
            </div>
            <div className="app-photo-browser__info__source__fixture__team__image">
              {awayTeam.organization.profilePhoto && (
                <Avatar media={awayTeam.organization.profilePhoto} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (source.event) {
    const event = source.event;
    return (
      <div className="app-photo-browser__info__source">
        <div className="app-photo-browser__info__source__image">
          {event.profilePhoto && <Avatar media={event.profilePhoto} />}
        </div>
        <div className="app-photo-browser__info__source__text">
          <div className="app-photo-browser__info__source__text__primary">
            {event.name}
          </div>
        </div>
      </div>
    );
  } else if (source.organization) {
    const organization = source.organization;
    return (
      <div className="app-photo-browser__info__source">
        <div className="app-photo-browser__info__source__image">
          {organization.profilePhoto && (
            <Avatar media={organization.profilePhoto} />
          )}
        </div>
        <div className="app-photo-browser__info__source__text">
          <div className="app-photo-browser__info__source__text__primary">
            {organization.name}
          </div>
          <div className="app-photo-browser__info__source__text__secondary">
            @ {organization.handle}
          </div>
        </div>
      </div>
    );
  } else if (source.profile) {
    const profile = source.profile;
    return (
      <div className="app-photo-browser__info__source">
        <div className="app-photo-browser__info__source__image">
          {profile.profilePhoto && <Avatar media={profile.profilePhoto} />}
        </div>
        <div className="app-photo-browser__info__source__text">
          <div className="app-photo-browser__info__source__text__primary">
            {profile.name}
          </div>
          <div className="app-photo-browser__info__source__text__secondary">
            @ {profile.handle}
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="app-photo-browser__info__source"></div>;
  }
}

function PhotoBrowserSidebar({ id }) {
  const { loading, error, data } = useQuery(MEDIA, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PhotoBrowser.PhotoBrowserSidebar",
        query: "MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const media = data.media;
  // console.log("SIDEBAR ID:", id);
  // console.log("SIDEBAR MEDIA:", media);

  return (
    <div className="app-photo-browser__info">
      <PhotoBrowserSidebarSource media={media} />

      <div className="app-photo-browser__info__description">
        <PhotoBrowserDescription media={media} />
      </div>
      {/* <PhotoBrowserSidebarTags
        media={media}
        selectedTab={selectedTagTab}
        setSelectedTab={setSelectedTagTab}
      /> */}
      <PhotoBrowserSidebarOptions media={media} />
    </div>
  );
}

function PostMediaBrowser({ items, media }) {
  const [, setAppState] = useAppState();
  const [selectedMedia, setSelectedMedia] = useState(media);

  useEffect(() => {
    const handleKeyDown = async (e) => {
      const key = e.key;
      console.log("Key:", key);
      if (key === "ArrowLeft") {
        if (currentMediaIndex > 0) {
          setSelectedMedia(items[currentMediaIndex - 1]);
        }
      } else if (key === "ArrowRight") {
        if (currentMediaIndex < items.length - 1) {
          setSelectedMedia(items[currentMediaIndex + 1]);
        }
      } else if (key === "Escape") {
        setAppState({ modal: false });
      } else {
        // Do nothing
      }
    };

    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const mediaCount = items.length;

  const currentMediaIndex = items.findIndex((m) => m?.id === selectedMedia?.id);
  return (
    <div className="app-photo-browser">
      <div className="app-photo-browser__image">
        {selectedMedia?.type === "IMAGE" && (
          <img src={makeFileUrl(selectedMedia)} alt="" />
        )}
        {selectedMedia?.type === "VIDEO" && selectedMedia?.isExternal && (
          <YouTubeVideo filename={selectedMedia.filename} />
        )}
        {selectedMedia?.type === "VIDEO" && !selectedMedia?.isExternal && (
          <video controls controlsList="nodownload">
            <source src={makeFileUrl(selectedMedia)} type="video/mp4" />
          </video>
        )}

        <div
          className="app-photo-browser__image__close"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="icon" />
        </div>

        {currentMediaIndex > 0 && (
          <div
            className="app-photo-browser__image__prev"
            onClick={() => {
              setSelectedMedia(items[currentMediaIndex - 1]);
            }}
          >
            <ChevronLeftIcon className="icon" />
          </div>
        )}

        {currentMediaIndex < mediaCount - 1 && (
          <div
            className="app-photo-browser__image__next"
            onClick={async () => {
              if (currentMediaIndex < items.length - 1) {
                setSelectedMedia(items[currentMediaIndex + 1]);
              }
            }}
          >
            <ChevronRightIcon className="icon" />
          </div>
        )}
      </div>
      <PhotoBrowserSidebar id={selectedMedia?.id} />
    </div>
  );
}

export default PostMediaBrowser;
