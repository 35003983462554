// V1
import "./ProfileParticipationSummary.css";
import Card from "../../Card";
import Placeholder from "../../Placeholder";
import { useQuery } from "@apollo/client";
import { PROFILE_EVENTS } from "../../../api/profile";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function ProfileParticipationSummary({ id, profile }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data } = useQuery(PROFILE_EVENTS, {
    variables: { id, take: 5 },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileParticipationSummary",
        query: "PROFILE_EVENTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="profile-overview-participation-card">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "48px" }} pulse={true} />
        </div>
        <div className="profile-overview-participation-card-list">
          {[1, 2, 3].map((ev) => (
            <div
              key={"profile-overview-participation-" + ev}
              className="profile-overview-participation-card-list-item"
            >
              <div className="image"></div>
              <div className="desc">
                <div className="primary-text">
                  <Placeholder
                    style={{ height: "12px", width: "120px" }}
                    pulse={true}
                  />
                </div>
                <div className="secondary-text" style={{ marginTop: "8px" }}>
                  <Placeholder
                    style={{ height: "12px", width: "60px" }}
                    pulse={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const org = data?.profile;
  const events = org?.events || [];

  // console.log(events);
  return (
    <Card className="profile-overview-participation-card">
      <div className="card-title">
        <div>Events</div>{" "}
        <div
          className="card-title-link"
          onClick={() => {
            history.replace(location.pathname + "?t=events");
          }}
        >
          See all events
        </div>
      </div>
      {events.length ? (
        <div className="profile-overview-participation-card-list">
          {events.slice(0, 5).map((ev) => (
            <Link
              key={"profile-overview-participation-" + ev.id}
              to={"/app/event/" + ev.id}
              className="profile-overview-participation-card-list-item"
            >
              <div className="image">
                {ev.profilePhoto && <Avatar media={ev.profilePhoto} />}
              </div>
              <div className="desc">
                <div className="primary-text">{ev.name}</div>
                <div className="secondary-text">{ev.type?.name}</div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <div>
            {profile.name} has not particpated
            <br /> in any events yet.
          </div>
        </div>
      )}
    </Card>
  );
}

export default ProfileParticipationSummary;
