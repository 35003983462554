import "./EventVideos.css";
import { useQuery } from "@apollo/client";
import { EVENT_VIDEOS } from "../../../api/event";
import VideoCard from "../../../mobileComponents/VideoCard";
import Loader from "../../../components/Loader";
import AddVideo from "../../AddVideo";
import { useAppState } from "../../../utils/appState";
import Button from "../../../components/Button";
import captureException from "../../../utils/captureException";

export default function EventVideos({ id, canManageEvent }) {
  const [, setAppState] = useAppState();
  const { error, data, variables, fetchMore, networkStatus } = useQuery(
    EVENT_VIDEOS,
    {
      variables: {
        id,
        type: "VIDEO",
        take: 5,
        filters: {
          sports: [],
          teams: [],
          events: [],
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventVideos",
        query: "EVENT_VIDEOS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const videos = data?.event?.media || [];
  const videosCount = data?.event?.videosCount || 0;

  return (
    <div className="event-videos-mobile">
      {canManageEvent && (
        <Button
          className="primary medium blue"
          style={{ margin: "16px" }}
          onClick={() => {
            setAppState({
              modal: (
                <AddVideo
                  config={{
                    source: data?.event,
                    sourceType: "event",
                  }}
                />
              ),
            });
          }}
        >
          Add Video
        </Button>
      )}

      {!videosCount && (
        <div className="no-data">
          <div className="text">No tagged videos for this page yet.</div>
        </div>
      )}

      <div className="event-videos-gallery">
        {videos.map((v) => (
          <VideoCard key={v.id} media={v} videoHeight={180} />
        ))}
      </div>

      {videos.length < videosCount && (
        <div
          className="show-more-container"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: videos.length ? videos[videos.length - 1]?.id : null,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme="blue small" /> : "Show More"}
        </div>
      )}
    </div>
  );
}
