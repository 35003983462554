import { gql } from "@apollo/client";

const CREATE_POST = gql`
  mutation createPost($data: CreatePostInput!) {
    createPost(data: $data) {
      id
      createdAt
      sourceType
      isSaved
      clapCount
      isClapped
      canManagePost
      postType
      source {
        id
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        event {
          id
          name
          profilePhoto {
            id
            filename
          }
          organizedBy {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        fixture {
          id
          completed
          event {
            id
            name
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          roles {
            id
            order
            type {
              id
              name
            }
            subtypes {
              id
              name
            }
            profile {
              id
              name
              firstname
              lastname
              profilePhoto {
                id
                filename
              }
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          placements {
            id
            value
            valueString
            profile {
              id
              name
            }
            organization {
              id
              name
            }
          }
        }
      }
      author {
        id
        name
      }
      title
      content
      truncatedContent
      contentLength
      tags {
        id
        media {
          id
          type
          filename
          isExternal
        }
        mediaSet {
          id
          preview {
            id
            filename
          }
        }
      }
    }
  }
`;

export default CREATE_POST;
