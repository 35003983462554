import { gql } from "@apollo/client";

const PROFILE_PHOTOS = gql`
  query profileQuery(
    $id: ID!
    $take: Int
    $cursor: ID
    $filters: ProfileMediaFilters
  ) {
    profile(id: $id) {
      id
      photosCount
      media(type: "IMAGE", take: $take, cursor: $cursor, filters: $filters) {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        clapCount
        isClapped
        canManageMedia
        contentLength
      }
    }
  }
`;

export default PROFILE_PHOTOS;
