import "./ProfilePosts.css";
import { PROFILE_POSTS } from "../../../api/profile";
import { useQuery } from "@apollo/client";
import Post from "../../../components/Post";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import captureException from "../../../utils/captureException";
import { Fragment } from "react";
import MatchReport from "../../../components/MatchReport";

export default function ProfilePosts({ id }) {
  const { error, data, fetchMore, networkStatus } = useQuery(PROFILE_POSTS, {
    variables: { id, take: 25 },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfilePosts",
        query: "PROFILE_POSTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div
        style={{
          height: 160,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const profile = data?.profile;
  const posts = profile?.posts;
  return (
    <div className="organization-posts-mobile">
      {!!posts?.length && (
        <div className="organization-posts-list">
          {posts.map((post) => (
            <Fragment>
              {post.postType === "REPORT" ? (
                <MatchReport post={post} />
              ) : (
                <Post
                  key={post.id}
                  post={post}
                  queryInfo={{
                    query: PROFILE_POSTS,
                    variables: { id },
                    onDeleteData: (prevData, deletePost) => {
                      if (!prevData?.profile) {
                        return [];
                      }
                      return {
                        profile: {
                          ...prevData.profile,
                          posts: [...prevData.profile.posts].filter(
                            (p) => p.id !== deletePost.id
                          ),
                        },
                      };
                    },
                    onUpdateData: (prevData, updatePost) => {
                      if (!prevData?.profile) {
                        return [];
                      }

                      const postIndex = prevData.profile.posts.findIndex(
                        (p) => p.id === updatePost.id
                      );

                      if (postIndex > -1) {
                        const newPosts = [...prevData.profile.posts];
                        newPosts[postIndex] = updatePost;
                        // console.log("New updatedpost index:", postIndex);
                        // console.log("New posts array:", newPosts);
                        return {
                          profile: {
                            ...prevData.profile,
                            posts: newPosts,
                          },
                        };
                      } else {
                        return { profile: { ...prevData.profile } };
                      }
                    },
                  }}
                  options={["edit", "delete"]}
                />
              )}
            </Fragment>
          ))}

          <div className="show-more-container">
            <Button
              className="primary small blue show-more-button"
              onClick={() => {
                fetchMore({
                  variables: {
                    cursor: posts[posts.length - 1].id,
                  },
                });
              }}
            >
              {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
            </Button>
          </div>
        </div>
      )}

      {!posts?.length && (
        <div className="no-data">
          <div>No posts to show.</div>
        </div>
      )}
    </div>
  );
}
