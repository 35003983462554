import "./ScoreFixture.css";
import ScoreFootball from "../ScoreFootball";
import ScoreBasketball from "../ScoreBasketball";
import ScoreBadminton from "../ScoreBadminton";
import ScoreCricketNew from "../ScoreCricket";
import ScoreTennis from "../ScoreTennis";
import ScoreIndividual from "../ScoreIndividual";

function ScoreFixture({ id, sport, fixture }) {
  return (
    <div className="score-fixture">
      {sport === "Football" && <ScoreFootball id={id} fixture={fixture} />}
      {(sport === "Basketball" ||
        sport === "Basketball 3x3" ||
        sport === "Basketball 5x5") && (
        <ScoreBasketball id={id} fixture={fixture} />
      )}
      {sport === "Cricket" && <ScoreCricketNew id={id} fixture={fixture} />}
      {sport === "Badminton" && <ScoreBadminton id={id} fixture={fixture} />}
      {sport === "Tennis" && <ScoreTennis id={id} fixture={fixture} />}
      {sport === "Other" && <ScoreIndividual id={id} fixture={fixture} />}
    </div>
  );
}

export default ScoreFixture;
