import { Fragment, useState, useEffect } from "react";
import "./ScoreBadminton.css";
import { ReactComponent as SettingsIcon } from "../../../icons/settings.svg";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SwapIcon } from "../../../icons/swap.svg";
import { useAppState } from "../../../utils/appState";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../Spinner";
import Loader from "../../Loader";
import Card from "../../Card";
import Button from "../../Button";
import {
  BADMINTON_STATS,
  FIXTURE_SETTINGS,
  CREATE_STAT,
  UPDATE_FIXTURE,
  DELETE_FIXTURE_STAT,
  PUBLISH_INDIVIDUAL_FIXTURE,
  UPDATE_STAT,
} from "../../../api/fixture";
import { useHistory } from "react-router-dom";
import Settings from "./Settings";
import NewDropdown from "../../NewDropdown/NewDropdown";
import { usePageTracking } from "../../../utils/usePageTracking";
import captureException from "../../../utils/captureException";

// const sumToPeriod = (periods, id) => {
//   let sum = 0;
//   for (let i = 0; i < periods.findIndex((x) => x.id === id) || 0; i++) {
//     sum += periods[i].duration;
//   }
//   return sum * 60;
// };

function Statement({ stat, stats }) {
  const related = stats.filter((s) =>
    s.relationshipsTo?.find((x) => x.from.id === stat.id)
  );
  // console.log(stat.id, related.length);
  return (
    <Fragment>
      <div className="statement">
        <span>
          <span className="bold">{stat.statType.name}</span> by{" "}
          <span className="bold">{stat.profile.name}</span>
        </span>
      </div>
      {related.map((r) => (
        <Statement stat={r} stats={stats} />
      ))}
    </Fragment>
  );
}

function PublishStatsConfirm({
  dismiss,
  fixtureId,
  completed,
  setPublish,
  homePlayer,
  awayPlayer,
  periods,
}) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  const [publishFixture, { loading: publishing }] = useMutation(
    PUBLISH_INDIVIDUAL_FIXTURE,
    {
      update: (cache, { data: { publishIndividualFixture } }) => {
        const q = cache.readQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
        });

        cache.writeQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
          data: {
            fixture: {
              ...q.fixture,
              completed: true,
            },
          },
        });

        dismiss();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ScoreBadminton.PublishStatsConfirm",
            mutation: "PUBLISH_INDIVIDUAL_FIXTURE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBadminton.PublishStatsConfirm",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const fixture = data?.fixture;
  const event = fixture?.event;
  const sport = event?.sport;
  const statTypes = sport?.statTypes;
  const stats = data?.fixture?.stats;
  const placementValues = periods?.reduce(
    (accum, curr) => {
      const homePoints =
        stats.filter(
          (s) =>
            s.statType.name === "Point Won" &&
            s.fixturePeriod.id === curr.id &&
            s.profile.id === homePlayer.profile.id
        ).length || 0;

      const awayPoints =
        stats.filter(
          (s) =>
            s.statType.name === "Point Won" &&
            s.fixturePeriod.id === curr.id &&
            s.profile.id === awayPlayer.profile.id
        ).length || 0;

      if (homePoints > awayPoints) {
        accum[homePlayer.profile.id]++;
      } else if (homePoints < awayPoints) {
        accum[awayPlayer.profile.id]++;
      }

      return accum;
    },
    { [homePlayer.profile.id]: 0, [awayPlayer.profile.id]: 0 }
  );
  // const periods = data?.fixture?.periods

  // const pointsPlayedStatType = statTypes?.find(
  //   (s) => s.name === "Point Played"
  // );
  // const totalPointsPlayed =
  //   stats?.filter((s) => s.statType.name === "Point Won").length || 0;

  // let tables = stats?.reduce(
  //   (accum, curr) => {
  //     const rowIndex = accum.findIndex(
  //       (s) =>
  //         s.statTypeId === curr.statType.id && s.profileId === curr.profile.id
  //     );
  //     if (rowIndex < 0) {
  //       accum.push({
  //         profileId: curr.profile.id,
  //         statTypeId: curr.statType.id,
  //         value: 1,
  //       });
  //     } else {
  //       accum[rowIndex].value = accum[rowIndex].value + 1;
  //     }
  //     return accum;
  //   },
  //   [
  //     {
  //       profileId: homePlayer.profile.id,
  //       statTypeId: pointsPlayedStatType.id,
  //       value: totalPointsPlayed,
  //     },
  //     {
  //       profileId: awayPlayer.profile.id,
  //       statTypeId: pointsPlayedStatType.id,
  //       value: totalPointsPlayed,
  //     },
  //   ]
  // );

  // const periods = data?.fixture?.periods
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // Games Played
  const gamesPlayedStatType = statTypes?.find((s) => s.name === "Games Played");
  const totalGamesPlayed =
    stats?.map((s) => s.fixturePeriod?.id).filter(onlyUnique).length || 0;

  // Games Won
  const gamesWonStatType = statTypes?.find((s) => s.name === "Games Won");
  const homePlayerGamesWon = placementValues[homePlayer.profile.id];
  const awayPlayerGamesWon = placementValues[awayPlayer.profile.id];

  // // Games Played
  // const gamesPlayedStatType = statTypes?.find((s) => s.name === "Games Played");
  // const totalGamesPlayed =
  //   stats?.filter((s) => s.statType.name === "Games Won").length || 0;

  // // Games Won
  // const gamesWonStatType = statTypes?.find((s) => s.name === "Games Won");
  // const homePlayerGamesWon =
  //   stats.filter(
  //     (s) =>
  //       s.statType.name === "Games Won" &&
  //       s.profile.id === homePlayer.profile.id
  //   ).length || 0;
  // const awayPlayerGamesWon =
  //   stats.filter(
  //     (s) =>
  //       s.statType.name === "Games Won" &&
  //       s.profile.id === awayPlayer.profile.id
  //   ).length || 0;

  // Points Played
  const pointsPlayedStatType = statTypes?.find(
    (s) => s.name === "Point Played"
  );
  const totalPointsPlayed =
    stats?.filter((s) => s.statType.name === "Point Won").length || 0;

  // Points Won
  const pointsWonStatType = statTypes?.find((s) => s.name === "Point Won");
  const homePlayerPointsWon =
    stats.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.profile.id === homePlayer.profile.id
    ).length || 0;
  const awayPlayerPointsWon =
    stats.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.profile.id === awayPlayer.profile.id
    ).length || 0;

  // Serve
  const serveStatType = statTypes?.find((s) => s.name === "Service");
  const serveWonStatType = statTypes?.find((s) => s.name === "Service Won");

  const serveSummary = stats
    .filter((s) => s.statType.name === "Point Won")
    .reduce(
      (accum, curr) => {
        const serve = curr.relationshipsFrom.find(
          (r) => r.to?.statType?.name === "Service"
        )?.to;

        accum[serve.profile.id].total = accum[serve.profile.id].total + 1;

        if (curr.profile.id === serve.profile.id) {
          accum[serve.profile.id].won = accum[serve.profile.id].won + 1;
        }

        return accum;
      },
      {
        [homePlayer.profile.id]: { total: 0, won: 0 },
        [awayPlayer.profile.id]: { total: 0, won: 0 },
      }
    );

  // Unforced Error
  const unforcedErrorStatType = statTypes?.find(
    (s) => s.name === "Unforced Error"
  );
  const homeUnforcedErrors =
    stats.filter(
      (s) =>
        s.statType.name === "Unforced Error" &&
        s.profile.id === homePlayer.profile.id
    ).length || 0;

  const awayUnforcedErrors =
    stats.filter(
      (s) =>
        s.statType.name === "Unforced Error" &&
        s.profile.id === awayPlayer.profile.id
    ).length || 0;

  const tables = [
    // Games Played
    {
      profileId: homePlayer.profile.id,
      statTypeId: gamesPlayedStatType.id,
      value: totalGamesPlayed,
      // label: "Games Played",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: gamesPlayedStatType.id,
      value: totalGamesPlayed,
      // label: "Games Played",
    },
    // Games Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: gamesWonStatType.id,
      value: homePlayerGamesWon,
      // label: "Games Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: gamesWonStatType.id,
      value: awayPlayerGamesWon,
      // label: "Games Won",
    },
    // Points Played
    {
      profileId: homePlayer.profile.id,
      statTypeId: pointsPlayedStatType.id,
      value: totalPointsPlayed,
      // label: "Points Played",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: pointsPlayedStatType.id,
      value: totalPointsPlayed,
      // label: "Points Played",
    },
    // Points Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: pointsWonStatType.id,
      value: homePlayerPointsWon,
      // label: "Points Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: pointsWonStatType.id,
      value: awayPlayerPointsWon,
      // label: "Points Won",
    },
    // Serve
    {
      profileId: homePlayer.profile.id,
      statTypeId: serveStatType.id,
      value: serveSummary[homePlayer.profile.id].total,
      // label: "Serve",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: serveStatType.id,
      value: serveSummary[awayPlayer.profile.id].total,
      // label: "Serve",
    },
    // Serve Won
    {
      profileId: homePlayer.profile.id,
      statTypeId: serveWonStatType.id,
      value: serveSummary[homePlayer.profile.id].won,
      // label: "Serve Won",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: serveWonStatType.id,
      value: serveSummary[awayPlayer.profile.id].won,
      // label: "Serve Won",
    },
    // Unforced Error
    {
      profileId: homePlayer.profile.id,
      statTypeId: unforcedErrorStatType.id,
      value: homeUnforcedErrors,
      // label: "Unforced Error",
    },
    {
      profileId: awayPlayer.profile.id,
      statTypeId: unforcedErrorStatType.id,
      value: awayUnforcedErrors,
      // label: "Unforced Error",
    },
  ];

  // console.log("Stat Types;", statTypes);

  return (
    <div className="publish-stats">
      <div className="publish-stats__header">
        <div
          className="close-scoring-btn"
          onClick={() => {
            if (!completed) {
              setPublish(false);
            } else {
              // history.push("/app/fixture/" + fixtureId);
              // setAppState({ modal: false });
            }
          }}
        >
          <div className="icon">
            <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
          </div>
          <div className="text">{completed ? "Back to fixture" : "Back"}</div>
        </div>
        <div className="publish-stats-title">
          {"Verify Fixture Stats & Result"}
        </div>

        {!loading && (
          <div className="publish-stats-btn-wrapper">
            <div
              className="publish-stats-btn"
              onClick={() => {
                // setConfirm(true);
              }}
            >
              {completed ? "Update Final Score" : "Publish this fixture"}
            </div>
          </div>
        )}
      </div>
      <div className="confirm-publish-stats">
        <div className="confirm-publish-stats__form">
          <div className="confirm-publish-stats__form__header">
            {completed
              ? "This fixture has already been published"
              : "Are you sure you want to publish this fixture?"}
          </div>
          <div className="confirm-publish-stats__form__text">
            {completed ? (
              <p>
                Please contact us if you need to change the scores for this
                fixture.
              </p>
            ) : (
              <Fragment>
                <p>
                  This cannot be undone. Please note, users will still be able
                  to see the score for the fixture from the fixtures page
                  without publishing.
                </p>
                <p>
                  Once you confirm we will update individual player and event
                  stats, and the fixture will be available for use in standings
                  calculations, so do make sure all stats are accurate before
                  you publish the fixture.
                </p>
              </Fragment>
            )}
          </div>

          <div className="confirm-publish-stats__form__options">
            <Button
              className="secondary medium"
              onClick={() => {
                if (!completed) {
                  dismiss();
                } else {
                  setAppState({ modal: false });
                }
              }}
              disabled={loading}
            >
              {completed ? "Go back to fixture" : "No, go back"}
            </Button>
            {!completed && (
              <Button
                className="primary medium green"
                onClick={() => {
                  if (publishing) return;
                  // console.log("Tyoes:", statTypes);
                  // console.log("Tables:", tables);
                  // console.log("Placement Values:", placementValues);

                  // return;
                  publishFixture({
                    variables: {
                      fixtureId,
                      placements: [
                        {
                          profileId: homePlayer.profile.id,
                          value: placementValues[homePlayer.profile.id],
                        },
                        {
                          profileId: awayPlayer.profile.id,
                          value: placementValues[awayPlayer.profile.id],
                        },
                      ],
                      tables,
                    },
                  });
                }}
                disabled={publishing}
              >
                {publishing ? <Loader /> : "Yes, publish this fixture"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TimerNew({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  timestamp,
  setTimestamp,
  cache,
  updateCache,
}) {
  const [, setSecondsElapsed] = useState(timestamp || 0);
  const [running, setRunning] = useState(false);
  const [decrementPressed] = useState(false);
  const [incrementPressed] = useState(false);

  useEffect(() => {
    setSecondsElapsed(timestamp);
  }, [timestamp]);

  useEffect(() => {
    // console.log("Running interval effect");
    let timer;

    if (running) {
      // console.log("Setting interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev + 1;
            if (!(newTimestamp % 60)) {
              updateCache();
            }
            return newTimestamp;
          });
        }, 1000);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [running]);

  useEffect(() => {
    let timer;

    if (decrementPressed) {
      // console.log("Setting decrement interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev - 1;
            return newTimestamp;
          });
        }, 100);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [decrementPressed]);

  useEffect(() => {
    let timer;

    if (incrementPressed) {
      // console.log("Setting decrement interval");
      if (!timer) {
        timer = setInterval(() => {
          setTimestamp((prev) => {
            const newTimestamp = prev + 1;
            return newTimestamp;
          });
        }, 100);
      }
    } else {
      // console.log("Clearing interval");
      if (timer) {
        clearInterval(timer);
        timer = false;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [incrementPressed]);

  return (
    <div className="time">
      <NewDropdown
        target={
          <div className="selected-period-selector">
            {selectedPeriod?.label || "Select game to get started"}
            <ChevronDown className="selected-period-selector__icon" />
          </div>
        }
        menu={({ setShowMenu }) => (
          <div className="selected-period-selector-menu">
            {periods.map((p) => (
              <div
                className="selected-period-selector-menu__item"
                onClick={() => {
                  setRunning(false);
                  changePeriod(p.id);
                  setShowMenu(false);
                }}
              >
                {p.label}
              </div>
            ))}
          </div>
        )}
      />

      {/* {selectedPeriod && (
        <Fragment>
          <div className="current-time">
            {!running && (
              <div
                className="option"
                onMouseDown={() => {
                  setDecrementPressed(true);
                }}
                onMouseUp={() => {
                  setDecrementPressed(false);
                  updateCache();
                }}
                onClick={() => {
                  setTimestamp((prev) => {
                    const newTimestamp = prev - 1;
                    if (!(newTimestamp % 60)) {
                      updateCache();
                    }
                    return newTimestamp;
                  });
                }}
              >
                <MinusIcon style={{ height: "14px" }} />
              </div>
            )}

            <div className="time">
              {minutes < 10 ? "0" + minutes : minutes} :{" "}
              {seconds < 10 ? "0" + seconds : seconds}
            </div>

            {!running && (
              <div
                className="option"
                onMouseDown={() => {
                  setIncrementPressed(true);
                }}
                onMouseUp={() => {
                  setIncrementPressed(false);
                  updateCache();
                }}
                onClick={() => {
                  setTimestamp((prev) => {
                    const newTimestamp = prev + 1;
                    if (!(newTimestamp % 60)) {
                      updateCache();
                    }
                    return newTimestamp;
                  });
                }}
              >
                <PlusIcon style={{ height: "14px" }} />
              </div>
            )}
          </div>

          {running ? (
            <div
              className="pause"
              onClick={() => {
                setRunning(false);
                updateCache();
              }}
            >
              <div className="icon">
                <PauseIcon style={{ height: "14px", fill: "white" }} />
              </div>
              
            </div>
          ) : (
            <div
              className="start"
              onClick={() => {
                setRunning(true);
              }}
            >
              <div className="icon">
                <PlayIcon style={{ height: "14px", fill: "white" }} />
              </div>
              
            </div>
          )}

          <div
            className="end-of-period"
            onClick={() => {
              setRunning(false);
              changePeriod();
              updateCache();
            }}
          >
            <div className="icon">
              <WhistleIcon style={{ height: "14px", fill: "white" }} />
            </div>
            
          </div>
        </Fragment>
      )} */}
    </div>
  );
}

function ScoringHeader({
  fixtureId,
  periods,
  changePeriod,
  selectedPeriod,
  timestamp,
  setTimestamp,
  setEditSettings,
  setEditLineups,
  cache,
  updateCache,
  setPublish,
}) {
  const [, setAppState] = useAppState();
  const history = useHistory();
  return (
    <div className="header">
      <div
        className="close-scoring-btn"
        onClick={() => {
          history.push("/app/fixture/" + fixtureId);
          setAppState({ modal: false });
        }}
      >
        <div className="icon">
          <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
        </div>
        <div className="text">Back to fixture</div>
      </div>

      {/* <TimerNew
        fixtureId={fixtureId}
        periods={periods}
        selectedPeriod={selectedPeriod}
        changePeriod={changePeriod}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        cache={cache}
        updateCache={updateCache}
      /> */}
      <div className="time"></div>

      <div className="settings">
        {/* <div
          className="edit-lineups-btn"
          onClick={() => {
            setEditLineups(true);
          }}
        >
          <ListIcon style={{ fill: "white", height: "14px" }} />
          Lineups
        </div> */}
        <div
          className="publish-stats-btn"
          onClick={() => {
            setPublish(true);
          }}
        >
          {"Verify & Publish"}
        </div>
        <div
          className="settings-btn"
          onClick={() => {
            setEditSettings(true);
          }}
        >
          <SettingsIcon style={{ stroke: "var(--light-1" }} />
        </div>
      </div>
    </div>
  );
}

function CurrentSet({
  fixtureId,
  periods,
  selectedPeriod,
  changePeriod,
  timestamp,
  setTimestamp,
  cache,
  updateCache,
  homePlayer,
  awayPlayer,
  currentServer,
  setCurrentServer,
}) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  const [createStat, { loading: submitting }] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      const q = cache.readQuery({
        query: BADMINTON_STATS,
        variables: { id: fixtureId },
      });

      // console.log("Update:", createStat);
      const newStats = [
        createStat,
        ...createStat.relationshipsTo.map((r) => ({
          ...r.from,
          relationshipsFrom: [],
          relationshipsTo: [],
        })),
        ...createStat.relationshipsFrom.map((r) => ({
          ...r.to,
          relationshipsFrom: [],
          relationshipsTo: [],
        })),
      ];

      cache.writeQuery({
        query: BADMINTON_STATS,
        variables: { id: fixtureId },
        data: {
          fixture: {
            ...q.fixture,
            stats: [...newStats, ...q.fixture.stats],
          },
        },
      });

      if (createStat.profile.id !== currentServer.profile.id) {
        if (createStat.profile.id === homePlayer.profile.id) {
          setCurrentServer(homePlayer);
          updateCache(homePlayer);
        } else {
          setCurrentServer(awayPlayer);
          updateCache(awayPlayer);
        }
      }
      // dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.CurrentSet",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [deleteStat, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      if (deleteFixtureStat?.length) {
        const q = cache.readQuery({
          query: BADMINTON_STATS,
          variables: {
            id: fixtureId,
          },
        });

        const statIds = deleteFixtureStat.map((x) => x.id);

        cache.writeQuery({
          query: BADMINTON_STATS,
          variables: {
            id: fixtureId,
          },
          data: {
            fixture: {
              ...q.fixture,
              stats: [
                ...q.fixture.stats.filter((x) => !statIds.includes(x.id)),
              ],
            },
          },
        });

        // dismiss();
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.CurrentSet",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBadminton.CurrentSet",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const fixture = data?.fixture;
  const ev = fixture?.event;
  const sport = ev?.sport;
  const statTypes = sport?.statTypes || [];

  const stats =
    data?.fixture?.stats?.filter(
      (s) => s.fixturePeriod?.id === selectedPeriod?.id
    ) || [];
  // console.log("STATS:", stats);

  const homeScore =
    stats.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.profile?.id === homePlayer.profile?.id
    )?.length || 0;

  const awayScore =
    stats.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.profile?.id === awayPlayer.profile?.id
    )?.length || 0;

  const incrementScore = ({ profileId }) => {
    if (!currentServer || submitting) return;

    createStat({
      variables: {
        data: {
          event: { connect: { id: ev.id } },
          timeStamp: stats.filter(
            (s) =>
              s.statType.name === "Point Won" &&
              s.fixturePeriod.id === selectedPeriod.id
          ).length,
          profile: { connect: { id: profileId } },
          statType: {
            connect: { id: statTypes.find((st) => st.name === "Point Won").id },
          },
          fixture: { connect: { id: fixture.id } },
          sport: { connect: { name: "Badminton" } },
          fixturePeriod: { connect: { id: selectedPeriod.id } },
          relationshipsFrom: {
            create: {
              to: {
                create: {
                  event: { connect: { id: ev.id } },
                  profile: { connect: { id: currentServer.profile.id } },
                  statType: {
                    connect: {
                      id: statTypes.find((st) => st.name === "Service").id,
                    },
                  },
                  fixture: { connect: { id: fixture.id } },
                  sport: { connect: { name: "Badminton" } },
                  fixturePeriod: { connect: { id: selectedPeriod.id } },
                },
              },
            },
          },
        },
      },
    });
  };

  const decrementScore = ({ profileId }) => {
    if (deleting) return;
    const statToDelete = stats.find(
      (s) => s.statType.name === "Point Won" && s.profile.id === profileId
    );

    if (statToDelete) {
      deleteStat({ variables: { id: statToDelete.id } });
    }
  };

  return (
    <div className="score-badminton-current-set">
      <TimerNew
        fixtureId={fixtureId}
        periods={periods}
        selectedPeriod={selectedPeriod}
        changePeriod={changePeriod}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        cache={cache}
        updateCache={updateCache}
      />
      <div className="score-badminton-current-set__player">
        <div className="score-badminton-current-set__player__details home">
          <div className="score-badminton-current-set__player__details__image"></div>
          <div className="score-badminton-current-set__player__details__info">
            <div className="score-badminton-current-set__player__details__info__name">
              {homePlayer.profile.name}
            </div>

            <div
              className={
                "score-badminton-current-set__player__details__info__service-indicator " +
                (currentServer.id === homePlayer.id ? "selected" : "")
              }
              onClick={() => {
                setCurrentServer(homePlayer);
                updateCache(homePlayer);
              }}
            >
              Serve
            </div>
          </div>
        </div>

        <div className="score-badminton-current-set__score">
          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              incrementScore({ profileId: homePlayer.profile.id });
            }}
          >
            {submitting ? <Spinner /> : <PlusIcon className="icon" />}
          </div>

          <div className="score-badminton-current-set__score__value">
            {homeScore}
          </div>

          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              decrementScore({ profileId: homePlayer.profile.id });
            }}
          >
            {deleting ? <Spinner /> : <MinusIcon className="icon" />}
          </div>
        </div>
      </div>

      <div className="score-badminton-current-set__player">
        <div className="score-badminton-current-set__player__details away">
          <div className="score-badminton-current-set__player__details__image"></div>
          <div className="score-badminton-current-set__player__details__info">
            <div className="score-badminton-current-set__player__details__info__name">
              {awayPlayer.profile.name}
            </div>

            <div
              className={
                "score-badminton-current-set__player__details__info__service-indicator " +
                (currentServer.id === awayPlayer.id ? "selected" : "")
              }
              onClick={() => {
                setCurrentServer(awayPlayer);
                updateCache(awayPlayer);
              }}
            >
              Serve
            </div>
          </div>
        </div>

        <div className="score-badminton-current-set__score">
          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              incrementScore({ profileId: awayPlayer.profile.id });
            }}
          >
            {submitting ? <Spinner /> : <PlusIcon className="icon" />}
          </div>

          <div className="score-badminton-current-set__score__value">
            {awayScore}
          </div>

          <div
            className="score-badminton-current-set__score__action"
            onClick={() => {
              decrementScore({ profileId: awayPlayer.profile.id });
            }}
          >
            {deleting ? <Spinner /> : <MinusIcon className="icon" />}
          </div>
        </div>
      </div>
    </div>
  );
}

function BoxScore({ periods, fixtureId, homePlayer, awayPlayer }) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBadminton.BoxScore",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats = data?.fixture?.stats;
  return (
    <div className="score-badminton-box-score">
      <div className="score-badminton-box-score__column players">
        <div className="score-badminton-box-score__column__label">Player</div>
        <div className="score-badminton-box-score__column__value">
          <div className="score-badminton-box-score__column__value__image"></div>
          {homePlayer?.profile?.name}
        </div>
        <div className="score-badminton-box-score__column__value">
          <div className="score-badminton-box-score__column__value__image"></div>
          {awayPlayer?.profile?.name}
        </div>
      </div>

      {periods.map((period) => (
        <div className="score-badminton-box-score__column period">
          <div className="score-badminton-box-score__column__label">
            {period.label}
          </div>
          <div className="score-badminton-box-score__column__value">
            {
              stats.filter(
                (s) =>
                  s.statType?.name === "Point Won" &&
                  s.fixturePeriod?.id === period.id &&
                  s.profile?.id === homePlayer?.profile?.id
              ).length
            }
          </div>
          <div className="score-badminton-box-score__column__value">
            {
              stats.filter(
                (s) =>
                  s.statType?.name === "Point Won" &&
                  s.fixturePeriod?.id === period.id &&
                  s.profile?.id === awayPlayer?.profile?.id
              ).length
            }
          </div>
        </div>
      ))}
    </div>
  );
}

function EventListItem({
  stat,
  stats = [],
  statTypes,
  homePlayer,
  awayPlayer,
  fixtureId,
  fixture,
  eventId,
  selectedPeriod,
}) {
  const [createStat] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      const q = cache.readQuery({
        query: BADMINTON_STATS,
        variables: { id: fixtureId },
      });

      // console.log("Update:", createStat);
      const newStats = [createStat, ...q.fixture.stats];

      const parentStatIndex = newStats.findIndex(
        (s) => s.id === createStat.relationshipsTo[0].from.id
      );

      // console.log("Parent Stat index:", parentStatIndex);

      if (parentStatIndex > -1) {
        newStats[parentStatIndex] = {
          ...newStats[parentStatIndex],
          relationshipsFrom: [
            ...newStats[parentStatIndex].relationshipsFrom,
            {
              id: createStat.relationshipsTo[0].id,
              to: { ...createStat },
            },
          ],
        };
      }

      // console.log("New stats:", newStats);

      cache.writeQuery({
        query: BADMINTON_STATS,
        variables: { id: fixtureId },
        data: {
          fixture: {
            ...q.fixture,
            stats: [...newStats],
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.EventListItem",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [deleteStat, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      // if (deleteFixtureStat?.length) {
      //   cache.modify({
      //     id: cache.identify(stat),
      //     fields: {
      //       relationshipsFrom(existingRefs, { readField }) {
      //         const rel = stat.relationshipsFrom.find(
      //           (x) => x.to.id === deleteFixtureStat[0].id
      //         );
      //         return existingRefs.filter(
      //           (relRef) => rel.id !== readField("id", relRef)
      //         );
      //       },
      //     },
      //   });
      //   cache.modify({
      //     id: cache.identify(fixture),
      //     fields: {
      //       stats(existingRefs, { readField }) {
      //         return existingRefs.filter(
      //           (statRef) =>
      //             deleteFixtureStat[0].id !== readField("id", statRef)
      //         );
      //       },
      //     },
      //   });
      // }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.EventListItem",
          mutation: "DELETE_FIXTURE_STAT",
        },
      });
      console.log(error);
    },
  });

  const [updateStat, { loading: updating }] = useMutation(UPDATE_STAT, {
    update: (cache, { data: { updateStat } }) => {
      // console.log("US:", updateStat);
      cache.modify({
        id: cache.identify(updateStat),
        fields: {
          profile() {
            return updateStat.profile;
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.EventListItem",
          mutation: "UPDATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const points = stats
    .filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.timeStamp <= stat.timeStamp &&
        s.fixturePeriod.id === selectedPeriod?.id
    )
    .sort((a, b) => b.timeStamp - a.timeStamp);

  const homeScore =
    points.filter((p) => p.profile.id === homePlayer?.profile?.id).length || 0;

  const awayScore =
    points.filter((p) => p.profile.id === awayPlayer?.profile?.id).length || 0;

  const serviceStat = stat.relationshipsFrom.find(
    (s) => s.to.statType.name === "Service"
  )?.to;

  const unforcedErrorStat = stat.relationshipsFrom.find(
    (s) => s.to.statType.name === "Unforced Error"
  )?.to;

  const doubleFaultStat = stat.relationshipsFrom.find(
    (s) => s.to.statType.name === "Double Fault"
  )?.to;

  return (
    <Card className="score-badminton-event-list-item">
      <div className="score-badminton-event-list-item__order">
        <div className="score-badminton-event-list-item__order__text">
          {homeScore} - {awayScore}
        </div>
        <div
          className="score-badminton-event-list-item__order__delete"
          onClick={() => {
            if (deleting) return;
            deleteStat({
              variables: {
                id: stat.id,
              },
              update: (cache, { data: { deleteFixtureStat } }) => {
                if (deleteFixtureStat?.length) {
                  // cache.modify({
                  //   id: cache.identify(stat),
                  //   fields: {
                  //     relationshipsFrom(existingRefs, { readField }) {
                  //       const rel = stat.relationshipsFrom.find(
                  //         (x) => x.to.id === deleteFixtureStat[0].id
                  //       );
                  //       return existingRefs.filter(
                  //         (relRef) => rel.id !== readField("id", relRef)
                  //       );
                  //     },
                  //   },
                  // });

                  const deletedIds = deleteFixtureStat.map((x) => x.id);

                  cache.modify({
                    id: cache.identify(fixture),
                    fields: {
                      stats(existingRefs, { readField }) {
                        return existingRefs.filter(
                          (statRef) =>
                            !deletedIds.includes(readField("id", statRef))
                        );
                      },
                    },
                  });
                }
              },
            });
          }}
        >
          {deleting ? <Spinner /> : <TrashIcon className="icon" />}
        </div>
      </div>

      <div className="score-badminton-event-list-item__body">
        <div className="score-badminton-event-list-item__body__statement">
          <div className="score-badminton-event-list-item__body__statement__text">
            Point won by <span className="bold">{stat.profile.name}</span>
          </div>
          <div
            className="score-badminton-event-list-item__body__statement__switch"
            onClick={() => {
              if (updating) return;
              updateStat({
                variables: {
                  where: { id: stat.id },
                  data: {
                    profile: {
                      connect: {
                        id:
                          stat.profile.id === homePlayer.profile.id
                            ? awayPlayer.profile.id
                            : homePlayer.profile.id,
                      },
                    },
                  },
                },
              });
            }}
          >
            {updating ? <Spinner /> : <SwapIcon className="icon" />}
          </div>
        </div>

        <div className="score-badminton-event-list-item__body__relations">
          {unforcedErrorStat && (
            <div className="score-badminton-event-list-item__body__relations__relation">
              <div className="score-badminton-event-list-item__body__relations__relation__text">
                Unforced error by {unforcedErrorStat.profile.name}
              </div>

              <div
                className="score-badminton-event-list-item__body__relations__relation__option"
                onClick={() => {
                  if (deleting) return;
                  deleteStat({
                    variables: {
                      id: unforcedErrorStat.id,
                    },
                    update: (cache, { data: { deleteFixtureStat } }) => {
                      if (deleteFixtureStat?.length) {
                        cache.modify({
                          id: cache.identify(stat),
                          fields: {
                            relationshipsFrom(existingRefs, { readField }) {
                              const rel = stat.relationshipsFrom.find(
                                (x) => x.to.id === deleteFixtureStat[0].id
                              );
                              return existingRefs.filter(
                                (relRef) => rel.id !== readField("id", relRef)
                              );
                            },
                          },
                        });

                        cache.modify({
                          id: cache.identify(fixture),
                          fields: {
                            stats(existingRefs, { readField }) {
                              return existingRefs.filter(
                                (statRef) =>
                                  deleteFixtureStat[0].id !==
                                  readField("id", statRef)
                              );
                            },
                          },
                        });
                      }
                    },
                  });
                }}
              >
                {deleting ? <Spinner /> : <TrashIcon className="icon" />}
              </div>
            </div>
          )}

          {doubleFaultStat && (
            <div className="score-badminton-event-list-item__body__relations__relation">
              <div className="score-badminton-event-list-item__body__relations__relation__text">
                Double fault by {doubleFaultStat.profile.name}
              </div>

              <div
                className="score-badminton-event-list-item__body__relations__relation__option"
                onClick={() => {
                  if (deleting) return;
                  deleteStat({
                    variables: {
                      id: doubleFaultStat.id,
                    },
                    update: (cache, { data: { deleteFixtureStat } }) => {
                      if (deleteFixtureStat?.length) {
                        cache.modify({
                          id: cache.identify(stat),
                          fields: {
                            relationshipsFrom(existingRefs, { readField }) {
                              const rel = stat.relationshipsFrom.find(
                                (x) => x.to.id === deleteFixtureStat[0].id
                              );
                              return existingRefs.filter(
                                (relRef) => rel.id !== readField("id", relRef)
                              );
                            },
                          },
                        });

                        cache.modify({
                          id: cache.identify(fixture),
                          fields: {
                            stats(existingRefs, { readField }) {
                              return existingRefs.filter(
                                (statRef) =>
                                  deleteFixtureStat[0].id !==
                                  readField("id", statRef)
                              );
                            },
                          },
                        });
                      }
                    },
                  });
                }}
              >
                {deleting ? <Spinner /> : <TrashIcon className="icon" />}
              </div>
            </div>
          )}
        </div>

        <div className="score-badminton-event-list-item__body__options">
          {!unforcedErrorStat && !doubleFaultStat && (
            <div
              className="score-badminton-event-list-item__body__options__option"
              onClick={() => {
                createStat({
                  variables: {
                    data: {
                      event: { connect: { id: eventId } },
                      profile: {
                        connect: {
                          id:
                            stat.profile?.id === homePlayer?.profile?.id
                              ? awayPlayer?.profile.id
                              : homePlayer?.profile.id,
                        },
                      },
                      statType: {
                        connect: {
                          id: statTypes.find(
                            (st) => st.name === "Unforced Error"
                          ).id,
                        },
                      },
                      fixture: { connect: { id: fixtureId } },
                      sport: { connect: { name: "Badminton" } },
                      fixturePeriod: { connect: { id: selectedPeriod.id } },
                      relationshipsTo: {
                        create: {
                          from: {
                            connect: {
                              id: stat.id,
                            },
                          },
                        },
                      },
                    },
                  },
                });
              }}
            >
              <PlusIcon className="icon" /> Unforced Error
            </div>
          )}

          {!doubleFaultStat && stat.profile.id !== serviceStat.profile.id && (
            <div
              className="score-badminton-event-list-item__body__options__option"
              onClick={() => {
                createStat({
                  variables: {
                    data: {
                      event: { connect: { id: eventId } },
                      profile: {
                        connect: {
                          id:
                            stat.profile?.id === homePlayer?.profile?.id
                              ? awayPlayer?.profile.id
                              : homePlayer?.profile.id,
                        },
                      },
                      statType: {
                        connect: {
                          id: statTypes.find((st) => st.name === "Double Fault")
                            .id,
                        },
                      },
                      fixture: { connect: { id: fixtureId } },
                      sport: { connect: { name: "Badminton" } },
                      fixturePeriod: { connect: { id: selectedPeriod.id } },
                      relationshipsTo: {
                        create: {
                          from: {
                            connect: {
                              id: stat.id,
                            },
                          },
                        },
                      },
                    },
                  },
                });
              }}
            >
              <PlusIcon className="icon" /> Double Fault
            </div>
          )}
        </div>
      </div>

      <div className="score-badminton-event-list-item__server">
        <div className="score-badminton-event-list-item__server__text">
          Server: {serviceStat?.profile.name}
        </div>

        <div
          className="score-badminton-event-list-item__server__switch"
          onClick={() => {
            if (updating) return;
            updateStat({
              variables: {
                where: { id: serviceStat.id },
                data: {
                  profile: {
                    connect: {
                      id:
                        serviceStat.profile.id === homePlayer.profile.id
                          ? awayPlayer.profile.id
                          : homePlayer.profile.id,
                    },
                  },
                },
              },
            });
          }}
        >
          {updating ? <Spinner /> : <SwapIcon className="icon" />}
        </div>
      </div>
    </Card>
  );
}

function EventsList({ fixtureId, selectedPeriod, homePlayer, awayPlayer }) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBadminton.EventsList",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const stats =
    data?.fixture?.stats?.filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.fixturePeriod.id === selectedPeriod?.id
    ) || [];
  // console.log("STATS:", data?.fixture?.stats);

  return (
    <div className="score-badminton-events-list">
      {stats.map((stat) => (
        <EventListItem
          stat={stat}
          stats={data?.fixture?.stats}
          statTypes={data?.fixture?.event?.sport?.statTypes}
          homePlayer={homePlayer}
          awayPlayer={awayPlayer}
          fixtureId={fixtureId}
          eventId={data?.fixture?.event?.id}
          selectedPeriod={selectedPeriod}
          fixture={data?.fixture}
        />
      ))}
    </div>
  );
}

function ScoreBadminton({
  fixtureId,
  fixture,
  eventId,
  statsCacheState,
  periods,
  homePlayer,
  awayPlayer,
  completed,
}) {
  const [cache, setCache] = useState(statsCacheState);
  const [editSettings, setEditSettings] = useState(!cache?.settings);
  const [publish, setPublish] = useState(false);
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    cache?.selectedPeriodId || ""
  );
  const [timestamp, setTimestamp] = useState(cache?.timestamp || 0);
  const [currentServer, setCurrentServer] = useState(
    statsCacheState?.currentServer || ""
  );

  const [updateFixture] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  const changePeriod = (newPeriodId) => {
    // const newTimestamp = newPeriodId
    //   ? statsCacheState?.periods?.[newPeriodId] ||
    //     sumToPeriod(periods, newPeriodId)
    //   : 0;

    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...statsCacheState,
            selectedPeriodId: newPeriodId,
            // periods: {
            //   ...(statsCacheState?.periods || {}),
            //   ...{ [selectedPeriodId]: timestamp },
            // },
          },
        },
      },
      update: (cache, { data: { updateFixture } }) => {
        cache.writeQuery({
          query: FIXTURE_SETTINGS,
          variables: { id: fixtureId },
          data: {
            statsCacheState: updateFixture.statsCacheState,
            periods: updateFixture.periods,
          },
        });

        setCache(updateFixture.statsCacheState);
      },
    });
    setSelectedPeriodId(newPeriodId);
    // setTimestamp(newTimestamp);
  };

  const updateCache = (server) => {
    updateFixture({
      variables: {
        where: {
          id: fixtureId,
        },
        data: {
          statsCacheState: {
            ...statsCacheState,
            selectedPeriodId,
            // timestamp,
            // periods: {
            //   ...(statsCacheState?.periods || {}),
            //   ...{ [selectedPeriodId]: timestamp },
            // },
            currentServer: server || currentServer,
          },
        },
      },
    });
  };

  return (
    <div className="score-football">
      <ScoringHeader
        fixtureId={fixtureId}
        periods={periods}
        changePeriod={changePeriod}
        selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        setEditSettings={setEditSettings}
        cache={cache}
        updateCache={updateCache}
        setPublish={setPublish}
      />

      <div className="body">
        <div className="score-badminton-body__main">
          <div className="score-badminton-body__main__current-set">
            <CurrentSet
              fixtureId={fixtureId}
              periods={periods}
              changePeriod={changePeriod}
              selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
              timestamp={timestamp}
              setTimestamp={setTimestamp}
              setEditSettings={setEditSettings}
              cache={cache}
              updateCache={updateCache}
              setPublish={setPublish}
              homePlayer={homePlayer}
              awayPlayer={awayPlayer}
              currentServer={currentServer}
              setCurrentServer={setCurrentServer}
            />
          </div>

          <div className="score-badminton-body__main__box-score">
            <BoxScore
              fixtureId={fixtureId}
              periods={periods}
              homePlayer={homePlayer}
              awayPlayer={awayPlayer}
            />
          </div>
        </div>

        <div className="score-badminton-body__events">
          <EventsList
            fixtureId={fixtureId}
            selectedPeriod={periods.find((s) => s.id === selectedPeriodId)}
            homePlayer={homePlayer}
            awayPlayer={awayPlayer}
          />
        </div>
        {/* <div className="left-column">
          <StatTypesList
            onClick={(statType) => {
              setSelectedStat(statType);
              setIsHomeStat(true);
            }}
            selectedPeriodId={selectedPeriodId}
          />
        </div>
        <div className="middle-column">
          <StatsList
            fixtureId={fixtureId}
            homePlayer={homePlayer}
            awayPlayer={awayPlayer}
            onDelete={(s, st, t) => {
              setDeleteStat({ stat: s, stats: st, timestamp: t });
            }}
          />
        </div>
        <div className="right-column">
          <StatTypesList
            onClick={(statType) => {
              setSelectedStat(statType);
              setIsHomeStat(false);
            }}
            selectedPeriodId={selectedPeriodId}
          />
        </div> */}
      </div>

      {/* {selectedStat && (
        <OverlayNew
          fixtureId={fixtureId}
          eventId={eventId}
          dismiss={() => {
            setSelectedStat(false);
          }}
          selectedStat={selectedStat}
          homePlayer={homePlayer}
          awayPlayer={awayPlayer}
          isHomeStat={isHomeStat}
          defaultTimestamp={timestamp}
          selectedPeriodId={selectedPeriodId}
          periods={periods}
        />
      )} */}

      {/* {deleteStat && (
        <DeleteStatsConfirm
          fixtureId={fixtureId}
          dismiss={() => {
            setDeleteStat(false);
          }}
          stat={deleteStat.stat}
          stats={deleteStat.stats}
          timestamp={deleteStat.timestamp}
        />
      )} */}

      {editSettings && (
        <Settings
          fixtureId={fixtureId}
          setEditSettings={setEditSettings}
          statsCacheState={statsCacheState}
          defaultPeriods={periods}
        />
      )}

      {/* {completed && <div>Published</div>} */}

      {(publish || completed) && (
        // <PublishStatsWrapper
        //   fixtureId={fixtureId}
        //   eventId={eventId}
        //   setPublish={setPublish}
        //   homePlayer={homePlayer}
        //   awayPlayer={awayPlayer}
        //   completed={completed}
        // />
        <PublishStatsConfirm
          dismiss={() => {
            setPublish(false);
          }}
          fixtureId={fixtureId}
          setPublish={setPublish}
          periods={periods}
          completed={completed}
          homePlayer={homePlayer}
          awayPlayer={awayPlayer}
          // placements={[
          //   { organizationId: homeTeam.organization.id, value: homeScore },
          //   { organizationId: awayTeam.organization.id, value: awayScore },
          // ]}
          // tables={statTable.reduce((accum, curr) => {
          //   for (let statTypeId in curr.stats) {
          //     if (curr.stats[statTypeId]) {
          //       accum.push({
          //         profileId: curr.profile.id,
          //         organizationId: curr.organization.id,
          //         statTypeId,
          //         value: curr.stats[statTypeId],
          //       });
          //     }
          //   }

          //   return accum;
          // }, [])}
        />
      )}
    </div>
  );
}

function ScoreBadmintonWrapper({ id }) {
  usePageTracking({ title: "Badminton Scoring" });
  const { loading, error, data } = useQuery(FIXTURE_SETTINGS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBadminton.ScoreBadmintonWrapper",
        query: "FIXTURE_SETTINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const cache = data?.fixture?.statsCacheState;
  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  const homePlayer = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayPlayer = data?.fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  //   const homeLineup = data?.fixture?.roles.filter(
  //     (r) =>
  //       r.type.name === "LINEUP PLAYER" &&
  //       r.organization.id === homeTeam?.organization?.id
  //   );

  //   const awayLineup = data?.fixture?.roles.filter(
  //     (r) =>
  //       r.type.name === "LINEUP PLAYER" &&
  //       r.organization.id === awayTeam?.organization?.id
  //   );

  const eventId = data?.fixture?.event?.id;
  const completed = data?.fixture?.completed;
  // console.log("Completed:", completed);

  return (
    <ScoreBadminton
      fixtureId={id}
      fixture={data.fixture}
      eventId={eventId}
      statsCacheState={cache}
      periods={periods}
      homePlayer={homePlayer}
      awayPlayer={awayPlayer}
      completed={completed}
    />
  );
}

export default ScoreBadmintonWrapper;
