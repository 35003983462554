import PhotosSummaryCard from "../../PhotosSummaryCard";
import VideosSummaryCard from "../../VideosSummaryCard";
import AboutOrg from "../AboutOrg";
import OrgInteractionStats from "../OrgInteractionStats";
import { ORGANIZATION_MEDIA } from "../../../api/organization";
import "./OrganizationOverview.css";
import { useHistory } from "react-router-dom";

export default function OrganizationOverview({
  id,
  organization,
  canManagePage,
}) {
  const history = useHistory();
  return (
    <div className="organization-overview-mobile">
      <div className="organization-overview-mobile__interaction-wrapper">
        <OrgInteractionStats id={id} organization={organization} />
      </div>

      <div className="organization-overview-mobile__about-wrapper">
        <AboutOrg id={id} canManagePage={canManagePage} />
      </div>

      <div className="organization-overview-mobile__photos-wrapper">
        <PhotosSummaryCard
          id={id}
          query={ORGANIZATION_MEDIA}
          getPhotos={(d) => d?.organization?.media || []}
          onClick={() => {
            history.push("/app/page/" + id + "?t=photos");
          }}
        />
      </div>

      <div className="organization-overview-mobile__videos-wrapper">
        <VideosSummaryCard
          id={id}
          query={ORGANIZATION_MEDIA}
          getVideo={(d) => d?.organization?.media?.[0] || null}
        />
      </div>

      {/* <div className="organization-overview-mobile__events-wrapper">
        <OrgEventsSummary />
      </div> */}
    </div>
  );
}
