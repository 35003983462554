import "./FootballPenaltyShootout.css";
import Card from "../Card";
import { useQuery } from "@apollo/client";
import Avatar from "../Avatar";
import captureException from "../../utils/captureException";
import { FIXTURE } from "../../api/functions";

export default function FootballPenaltyShootout({ fixtureId }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `      
      roles(where:{type:{name:"PARTICIPANT"}}){
        id
        order
        type {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
      stats(where:{statType:{name:{in:["Penalty Shootout Goal", "Penalty Shootout Miss"]}}}){
        id
        timeStamp
        value
        stringValue
        statType {
          id
          name
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        fixturePeriod {
          id
          order
          label
        }
      }
    `,
    }),
    {
      variables: { id: fixtureId },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FootballPenaltyShootout",
        query: "FIXTURE_TIMELINE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const homeTeam = data?.fixture?.roles?.find(
    (r) => !r.order && r.type.name === "PARTICIPANT"
  );
  const awayTeam = data?.fixture?.roles?.find(
    (r) => r.order && r.type.name === "PARTICIPANT"
  );

  const stats = data?.fixture?.stats.filter(
    (s) =>
      s.statType.name === "Penalty Shootout Goal" ||
      s.statType.name === "Penalty Shootout Miss"
  );

  // console.log("PENALTY TIMESTAMP STATS:", stats);
  if (!stats?.length) {
    return <div />;
  }

  const homeStats = stats
    .filter((s) => s.organization.id === homeTeam.organization.id)
    .sort((a, b) => a.timeStamp - b.timeStamp);
  const awayStats = stats
    .filter((s) => s.organization.id === awayTeam.organization.id)
    .sort((a, b) => a.timeStamp - b.timeStamp);

  const homeGoals = homeStats.filter(
    (s) => s.statType.name === "Penalty Shootout Goal"
  ).length;
  const awayGoals = awayStats.filter(
    (s) => s.statType.name === "Penalty Shootout Goal"
  ).length;

  const rowsCount = Math.max(homeStats.length, awayStats.length);

  return (
    <Card className="football-penalty-shootout">
      <div className="football-penalty-shootout__title">Penalty Shootout</div>
      <div className="football-penalty-shootout__header">
        <div className="football-penalty-shootout__header__section">
          <div className="football-penalty-shootout__header__team">
            <div className="football-penalty-shootout__header__team__image">
              {homeTeam.organization?.profilePhoto && (
                <Avatar media={homeTeam.organization?.profilePhoto} />
              )}
            </div>

            <div className="football-penalty-shootout__header__team__name">
              {homeTeam.organization?.name}
            </div>
          </div>
          <div className="football-penalty-shootout__header__score">
            {homeGoals}
          </div>
        </div>

        <div className="football-penalty-shootout__header__section">
          <div className="football-penalty-shootout__header__score">
            {awayGoals}
          </div>
          <div className="football-penalty-shootout__header__team away">
            <div className="football-penalty-shootout__header__team__image">
              {awayTeam.organization?.profilePhoto && (
                <Avatar media={awayTeam.organization?.profilePhoto} />
              )}
            </div>
            <div className="football-penalty-shootout__header__team__name">
              {awayTeam.organization?.name}
            </div>
          </div>
        </div>
      </div>

      <div className="football-penalty-shootout__rows">
        {[...Array(rowsCount).keys()].map((i) => {
          const homeStat = homeStats.find((s) => s.timeStamp === i + 1);
          const awayStat = awayStats.find((s) => s.timeStamp === i + 1);
          return (
            <div
              key={"shootout-row-" + i}
              className="football-penalty-shootout__rows__row"
            >
              <div className="football-penalty-shootout__rows__row__section">
                <div className="football-penalty-shootout__rows__row__section__img">
                  {homeStat?.profile?.profilePhoto && (
                    <Avatar media={homeStat?.profile?.profilePhoto} />
                  )}
                </div>
                <div className="football-penalty-shootout__rows__row__section__name">
                  {homeStat?.profile?.name}
                </div>
                <div className="football-penalty-shootout__rows__row__section__indicator">
                  <div
                    className={
                      "football-penalty-shootout__rows__row__section__indicator__icon " +
                      (homeStat?.statType?.name
                        ? homeStat?.statType?.name === "Penalty Shootout Goal"
                          ? "green"
                          : "red"
                        : "")
                    }
                  />
                </div>
              </div>
              <div className="football-penalty-shootout__rows__row__section away">
                <div className="football-penalty-shootout__rows__row__section__img">
                  {awayStat?.profile?.profilePhoto && (
                    <Avatar media={awayStat?.profile?.profilePhoto} />
                  )}
                </div>
                <div className="football-penalty-shootout__rows__row__section__name">
                  {awayStat?.profile?.name}
                </div>
                <div className="football-penalty-shootout__rows__row__section__indicator">
                  <div
                    className={
                      "football-penalty-shootout__rows__row__section__indicator__icon " +
                      (awayStat?.statType?.name
                        ? awayStat?.statType?.name === "Penalty Shootout Goal"
                          ? "green"
                          : "red"
                        : "")
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
}
