import "./Placeholder.css";

export default function Placeholder({ style, pulse }) {
  return (
    <div
      className={"app-placeholder " + (pulse ? "pulse" : "")}
      style={style}
    ></div>
  );
}
