import "./Checkbox.css";
import { useState } from "react";

function Checkbox({ onClick, defaultValue, label, disabled = false }) {
  const [checked, setChecked] = useState(defaultValue);
  return (
    <div
      className={"app-checkbox " + (checked ? "checked" : "")}
      onClick={() => {
        if (disabled) return;
        const newChecked = !checked;
        setChecked(newChecked);
        onClick(newChecked);
      }}
    >
      <div className="checkbox-indicator">
        {checked && <img src={"/check.svg"} alt="" />}
      </div>
      {label && <div className="checkbox-label">{label}</div>}
    </div>
  );
}

export default Checkbox;
