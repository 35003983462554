import { useRef, useState } from "react";
import "./Select.css";
import Dropdown from "../Dropdown";
import Spinner from "../Spinner";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";

function SelectMenu({
  loading,
  wrapper,
  items,
  onItemSelect,
  setShowMenu,
  setSearch,
  clearOnItemSelect = true,
}) {
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="filter-input-menu"
      style={{ width: wrapper?.current?.offsetWidth || "100%" }}
    >
      <div className="filter-input-menu-list">
        {items.map((item) => (
          <div
            className="filter-input-menu-list-item"
            onClick={() => {
              onItemSelect(item);
              clearOnItemSelect ? setSearch("") : setSearch(item.name);
              setShowMenu(false);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

function SelectDropdown({
  wrapperRef,
  placeholder,
  items,
  onItemSelect,
  clearOnItemSelect,
}) {
  const [search, setSearch] = useState("");

  const list = items.filter((c) => {
    if (!search) {
      return true;
    } else {
      const regex = new RegExp(search, "i");
      return regex.test(c.name);
    }
  });

  return (
    <div ref={wrapperRef} className="filter-input-dropdown">
      <Dropdown
        target={
          <div className="filter-input-dropdown__select">
            <div className="filter-input-dropdown__select__value">
              {placeholder}
            </div>
            <div className="filter-input-dropdown__select__icon">
              <ChevronDownIcon
                style={{
                  height: 17,
                  marginTop: 2,
                  stroke: "var(--blue-lighter)",
                }}
              />
            </div>
          </div>
        }
        menu={({ setShowMenu }) => (
          <SelectMenu
            items={list}
            wrapper={wrapperRef}
            onItemSelect={onItemSelect}
            setShowMenu={setShowMenu}
            setSearch={setSearch}
            clearOnItemSelect={clearOnItemSelect}
          />
        )}
      />
    </div>
  );
}

function Select({
  items = [],
  placeholder,
  query,
  variables,
  onItemSelect,
  clearOnItemSelect,
}) {
  const wrapperRef = useRef(null);

  return (
    <SelectDropdown
      query={query}
      variables={variables}
      items={items}
      placeholder={placeholder}
      wrapperRef={wrapperRef}
      onItemSelect={onItemSelect}
      clearOnItemSelect={clearOnItemSelect}
    />
  );
}

export default Select;
