import { gql } from "@apollo/client";

const FIXTURE_MEDIA = gql`
  query fixture($id: ID!, $type: String) {
    fixture(id: $id) {
      id
      photosCount
      videosCount
      broadcastSession {
        id
      }
      pinnedMedia {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        clapCount
        isClapped
        canManageMedia
        contentLength
        source {
          id
          fixture {
            id
            event {
              id
              organizedBy {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
      }
      media(type: $type) {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        clapCount
        isClapped
        canManageMedia
        contentLength
        source {
          id
          fixture {
            id
            event {
              id
              organizedBy {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
      }
      mediaSet {
        id
        name
        preview {
          id
          filename
        }
        source {
          id
          fixture {
            id
            scheduledStart
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              organization {
                id
                name
              }
              profile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default FIXTURE_MEDIA;
