import { gql } from "@apollo/client";

const CREATE_MEDIA = gql`
  mutation createMedia(
    $type: String!
    $subtype: String
    $filename: String!
    $private: Boolean
    $isExternal: Boolean
    $sourceId: ID!
    $sourceType: String!
    $aspectRatio: Float
    $mediaSetId: ID
  ) {
    createMedia(
      type: $type
      subtype: $subtype
      filename: $filename
      private: $private
      isExternal: $isExternal
      sourceId: $sourceId
      sourceType: $sourceType
      aspectRatio: $aspectRatio
      mediaSetId: $mediaSetId
    ) {
      url
      placeholderUrl
      media {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        height
        width
        canManageMedia
      }
    }
  }
`;

export default CREATE_MEDIA;
