import { gql } from "@apollo/client";

const FIXTURE_REPORT = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      report {
        id
        createdAt
        sourceType
        isSaved
        clapCount
        isClapped
        canManagePost
        source {
          id
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          event {
            id
            name
            organizedBy {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
          fixture {
            id
            completed
            event {
              id
              name
              participantType
              organizedBy {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
            roles {
              id
              order
              type {
                id
                name
              }
              subtypes {
                id
                name
              }
              profile {
                id
                name
                firstname
                lastname
                profilePhoto {
                  id
                  filename
                }
              }
              organization {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
            placements {
              id
              value
              valueString
              profile {
                id
                name
              }
              organization {
                id
                name
              }
            }
          }
        }
        author {
          id
          name
        }
        title
        content
        truncatedContent
        contentLength
        tags {
          id
          media {
            id
            type
            filename
            isExternal
          }
          mediaSet {
            id
            preview {
              id
              filename
            }
          }
        }
      }
    }
  }
`;

export default FIXTURE_REPORT;
