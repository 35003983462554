import { gql } from "@apollo/client";

const FIXTURE_AWARD_TYPES = gql`
  query awardTypes(
    $where: AwardTypeWhereInput
    $placementsWhere: EventPlacementWhereInput
  ) {
    awardTypes(where: $where) {
      id
      name
      subtype
      placements(where: $placementsWhere, take: 1) {
        id
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        type {
          id
          name
        }
      }
    }
  }
`;

export default FIXTURE_AWARD_TYPES;
