import { gql } from "@apollo/client";

const PUBLISH_INDIVIDUAL_FIXTURE = gql`
  mutation publishIndividualFixture(
    $fixtureId: ID!
    $placements: [PublishFixturePlacementsInput!]
    $tables: [PublishFixtureTableInput!]
  ) {
    publishIndividualFixture(
      fixtureId: $fixtureId
      placements: $placements
      tables: $tables
    ) {
      id
    }
  }
`;

export default PUBLISH_INDIVIDUAL_FIXTURE;
