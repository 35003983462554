import { useState } from "react";
import "./EventVideos.css";
import Button from "../Button";
import { useQuery, useMutation, gql } from "@apollo/client";
import { EVENT_PARTICIPANTS, EVENT_VIDEOS } from "../../api/event";
import { CREATE_MEDIA } from "../../api/media";
import VideoCard from "../VideoCard";
import ClickOutside from "../ClickOutside";
import Card from "../Card";
import Uploader from "../Uploader";
import { ReactComponent as YoutubeIcon } from "../../icons/youtube.svg";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
import { useAppState } from "../../utils/appState";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import Loader from "../Loader";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import captureException from "../../utils/captureException";

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function AddVideo({ id, event, updateVariables }) {
  const [uploader, setUploader] = useState(false);
  const [external, setExternal] = useState(false);
  const [externalLink, setExternalLink] = useState("");
  const [, setAppState] = useAppState();

  const [createMedia] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EventVideos.AddVideo",
          mutation: "CREATE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  if (uploader) {
    if (external) {
      return (
        <ClickOutside
          onClickOutside={() => {
            setAppState({ modal: false });
          }}
        >
          {(wrapperRef) => (
            <div className="add-video-wrapper" ref={wrapperRef}>
              <Card className={"add-video-container"}>
                <div className="add-video-container__title">
                  Enter YouTube Embed Link
                </div>
                <div className="add-video-container__secondary-title">
                  To get your video's embed link, click the "Share" button{" "}
                  <br /> on the video's YouTube page and click "Copy".
                </div>
                <input
                  type="text"
                  placeholder={"Enter YouTube link here"}
                  className="add-video-container__link-input"
                  value={externalLink}
                  onChange={(e) => {
                    setExternalLink(e.target.value);
                  }}
                />
                <Button
                  className="primary green large"
                  onClick={() => {
                    if (!externalLink) return;
                    const filename = externalLink.replace(
                      "https://youtu.be/",
                      "https://www.youtube.com/embed/"
                    );
                    createMedia({
                      variables: {
                        type: "VIDEO",
                        filename,
                        private: false,
                        isExternal: true,
                        sourceId: id,
                        sourceType: "event",
                      },
                      update: (cache, { data: { createMedia } }) => {
                        // console.log("Upload video update function");
                        cache.modify({
                          id: cache.identify(event),
                          fields: {
                            media(existingMediaRefs, { readField }) {
                              const newMediaRef = cache.writeFragment({
                                data: createMedia.media,
                                fragment: gql`
                                  fragment NewMedia on Media {
                                    id
                                    createdAt
                                    type
                                    subtype
                                    isExternal
                                    filename
                                    public
                                    aspectRatio
                                    canManageMedia
                                  }
                                `,
                              });

                              // Quick safety check - if the new comment is already
                              // present in the cache, we don't need to add it again.
                              if (
                                existingMediaRefs.some(
                                  (ref) =>
                                    readField("id", ref) ===
                                    createMedia.media.id
                                )
                              ) {
                                return existingMediaRefs;
                              }
                              // console.log("NEW VIDEO LIST:", [
                              //   newMediaRef,
                              //   ...existingMediaRefs,
                              // ]);
                              return [newMediaRef, ...existingMediaRefs];

                              // const newMediaSets = [
                              //   createMediaSet,
                              //   ...existingMediaRefs,
                              // ];
                              // console.log("new Media Sets:", newMediaSets);
                              // return newMediaSets;
                            },
                          },
                        });

                        // const q = cache.readQuery({
                        //   query: EVENT_VIDEOS,
                        //   variables: updateVariables,
                        // });

                        // cache.writeQuery({
                        //   query: EVENT_VIDEOS,
                        //   variables: updateVariables,
                        //   data: {
                        //     event: {
                        //       ...q.event,
                        //       media: [
                        //         { ...createMedia.media },
                        //         ...q.event.media,
                        //       ],
                        //     },
                        //   },
                        // });

                        setAppState({ modal: false });
                      },
                    });
                  }}
                >
                  Add Video
                </Button>
              </Card>
            </div>
          )}
        </ClickOutside>
      );
    } else {
      return (
        <Uploader
          accept=".mp4"
          label="Upload Video"
          multiple={false}
          sourceId={id}
          sourceType={"event"}
          type={"VIDEO"}
          maxFileSizeInBytes={10000000}
          onCreateMedia={(cache, createMedia) => {
            cache.modify({
              id: cache.identify(event),
              fields: {
                media(existingMediaRefs, { readField }) {
                  const newMediaRef = cache.writeFragment({
                    data: createMedia.media,
                    fragment: gql`
                      fragment NewMedia on Media {
                        id
                        createdAt
                        type
                        subtype
                        isExternal
                        filename
                        public
                        aspectRatio
                        canManageMedia
                      }
                    `,
                  });

                  // Quick safety check - if the new comment is already
                  // present in the cache, we don't need to add it again.
                  if (
                    existingMediaRefs.some(
                      (ref) => readField("id", ref) === createMedia.media.id
                    )
                  ) {
                    return existingMediaRefs;
                  }
                  // console.log("NEW VIDEO LIST:", [
                  //   newMediaRef,
                  //   ...existingMediaRefs,
                  // ]);
                  return [newMediaRef, ...existingMediaRefs];

                  // const newMediaSets = [
                  //   createMediaSet,
                  //   ...existingMediaRefs,
                  // ];
                  // console.log("new Media Sets:", newMediaSets);
                  // return newMediaSets;
                },
              },
            });
            // const q = cache.readQuery({
            //   query: EVENT_VIDEOS,
            //   variables: { id, type: "VIDEO" },
            // });

            // cache.writeQuery({
            //   query: EVENT_VIDEOS,
            //   variables: { id, type: "VIDEO" },
            //   data: {
            //     event: {
            //       ...q.event,
            //       media: [{ ...createMedia }, ...q.event.media],
            //     },
            //   },
            // });
          }}
        />
      );
    }
  }

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="add-video-wrapper" ref={wrapperRef}>
          <Card className={"add-video-container"}>
            <div className="add-video-container__title">Add New Video</div>
            {/*<div className="add-video-container__secondary-title">
                Select video type
        </div>*/}
            <div className="add-video-container__options">
              <Card
                className="add-video-container__option"
                onClick={() => {
                  setUploader(true);
                  setExternal(true);
                }}
              >
                <div className="icon">
                  <YoutubeIcon style={{ stroke: "var(--red-lighter)" }} />
                </div>
                <div className="desc">Embed YouTube </div>
              </Card>

              <Card
                className="add-video-container__option"
                onClick={() => {
                  setUploader(true);
                }}
              >
                <div className="icon">
                  <UploadIcon style={{ stroke: "var(--blue-lighter)" }} />
                </div>
                <div className="desc">Upload Video</div>
              </Card>
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

function EventVideos({ id, canManageEvent }) {
  const [, setAppState] = useAppState();
  const [teamsFilter, setTeamsFilter] = useState([]);
  const { error, data, refetch, variables, fetchMore, networkStatus } =
    useQuery(EVENT_VIDEOS, {
      variables: {
        id,
        type: "VIDEO",
        take: 5,
        filters: {
          sports: [],
          teams: [],
          events: [],
        },
      },
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventVideos",
        query: "EVENT_VIDEOS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const videos = data?.event?.media || [];
  const videosCount = data?.event?.videosCount || 0;

  return (
    <div className="profile-videos">
      <div className="left-column">
        {canManageEvent && (
          <div className="upload-btn-container">
            <Button
              className="primary blue large"
              onClick={async () => {
                setAppState({
                  modal: (
                    <AddVideo
                      id={id}
                      event={data.event}
                      updateVariables={variables}
                    />
                  ),
                });
              }}
            >
              Add Video
            </Button>
          </div>
        )}
        <div className="profile-videos-gallery">
          {videos.map((v) => (
            <VideoCard
              key={v.id}
              media={v}
              onDelete={(cache, { data: { deleteMedia } }) => {
                cache.modify({
                  id: cache.identify(data.event),
                  fields: {
                    media(existingMediaRefs, { readField }) {
                      return existingMediaRefs.filter(
                        (mediaRef) =>
                          deleteMedia.id !== readField("id", mediaRef)
                      );
                    },
                  },
                });
              }}
            />
          ))}
        </div>

        {videos.length < videosCount - 1 && (
          <Card
            className="show-more-container"
            onClick={() => {
              fetchMore({
                variables: {
                  ...variables,
                  cursor: videos.length ? videos[videos.length - 1]?.id : null,
                },
              });
            }}
          >
            {!networkStatus === 7 ? <Loader theme="blue" /> : "Show More"}
          </Card>
        )}
      </div>

      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={"Enter a team"}
            query={EVENT_PARTICIPANTS}
            vars={{ id, historical: true }}
            items={(d) => {
              return d?.event?.participants?.map((x) => x.organization);
            }}
            addFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  teams: orgs.map((s) => s.id),
                },
              });
            }}
            removeFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
              refetch({
                ...variables,
                filters: {
                  ...variables.filters,
                  teams: orgs.map((s) => s.id),
                },
              });
            }}
            defaultValue={teamsFilter}
            appStateKey={"teamsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default EventVideos;
