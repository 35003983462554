import "./ResetPassword.css";
import { useMutation } from "@apollo/client";
import { useState, Fragment } from "react";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import Loader from "../../components/Loader";
import { useHistory, useParams } from "react-router-dom";
import { RESET_PASSWORD } from "../../api/auth";
import Alert from "../../components/Alert";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function ResetPassword() {
  usePageTracking({ title: "Reset Password" });
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState();
  const [invalidPassword, setInvalidPassword] = useState();
  const [resetted, setResetted] = useState(false);
  const [resetError, setResetError] = useState(false);
  const history = useHistory();
  const { token } = useParams();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    update: (cache, { data: { resetPassword } }) => {
      if (resetPassword.error) {
        setResetError(resetPassword.error);
      } else {
        setResetted(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ResetPassword",
          mutation: "RESET_PASSWORD",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="auth-page">
      <div className="form-container">
        <div className="logo">
          <img src={"/logo.svg"} alt="" />
        </div>

        <div className="auth-page-title">Reset your password</div>

        {resetted ? (
          <div className="auth-page-secondary-title">
            You have successfully reset your password!{" "}
            <span
              className="text-link hover-pointer"
              onClick={() => {
                history.push("/auth#login");
              }}
            >
              Head over to the login page
            </span>{" "}
            to get started.
          </div>
        ) : (
          <Fragment>
            <div className="auth-page-secondary-title">
              Enter your new password below
            </div>
            <form
              onSubmit={(e) => {
                // console.log("Form submitted");
                e.preventDefault();
                if (!password) {
                  setInvalidPassword(true);
                  return;
                }

                // console.log(token);

                resetPassword({
                  variables: {
                    password,
                    token,
                  },
                });
                // resetPassword({
                //   variables: {
                //     email,
                //   },
                // });
              }}
              className="auth-form"
            >
              <div className="form-group">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  className={
                    "input " +
                    (password ? "hasValue " : " ") +
                    (invalidPassword ? "invalid" : "")
                  }
                  autoComplete="new-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidPassword(false);
                  }}
                />
                <label for="password">
                  Password <span className="input-required-indicator">*</span>
                </label>
                <div className="static-icon">
                  <LockIcon style={{ fill: "var(--light-4)", height: 14 }} />
                </div>

                <div
                  className="static-icon-right"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                >
                  <EyeIcon
                    style={{
                      fill: showPassword
                        ? "var(--blue-main)"
                        : "var(--light-4)",
                      height: 14,
                    }}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="app-button primary large green submit"
              >
                {loading ? <Loader /> : "Submit"}
              </button>

              {resetError && (
                <Alert
                  theme="red"
                  head={
                    <div className="">
                      {resetError}{" "}
                      <span
                        className="text-link hover-pointer"
                        onClick={() => {
                          history.push("/forgot-password");
                        }}
                      >
                        Click here
                      </span>{" "}
                      to get a new token.
                    </div>
                  }
                />
              )}

              <div className="terms">
                <span
                  className="text-link hover-pointer"
                  onClick={() => {
                    history.push("/auth#login");
                  }}
                >
                  Back to login page
                </span>
              </div>
            </form>
          </Fragment>
        )}
      </div>

      <div className="auth-carousel">
        <div className="carousel-content-wrapper"></div>
      </div>
    </div>
  );
}

export default ResetPassword;
