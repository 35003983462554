// V1
import { useRef, useEffect, useState } from "react";
import "./Cropper.css";
import "./croppie.css";
import Croppie from "croppie";
import Button from "../Button";
import { CREATE_MEDIA } from "../../api/media";
import { useMutation } from "@apollo/client";
import axios from "axios";
import Compressor from "compressorjs";
import captureException from "../../utils/captureException";

const uploadFile = async ({
  url,
  file,
  blob,
  headers = {},
  onProgress = () => {},
}) => {
  // console.log("Uploading the file:", file)
  const options = {
    headers: {
      ...headers,
      "Content-Type": file.type,
    },
    onUploadProgress: (p) => {
      // console.log("Progress:", p.loaded, p.total);
      onProgress(Math.round((p.loaded / p.total) * 100));
    },
  };

  blob.lastModifiedDate = new Date();
  blob.name = file.name;

  const response = await axios.put(url, blob, options);

  return response;
};

export default function Cropper({
  image,
  sourceId,
  sourceType,
  type,
  subtype,
  onCreateMedia,
  cropperWidth = 152,
  cropperHeight = 152,
}) {
  const cropperRef = useRef(null);
  const [url, setUrl] = useState("");
  const [croppie, setCroppie] = useState(null);
  const [progress, setProgress] = useState(0);

  const [createMedia, { loading }] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Cropper",
          mutation: "CREATE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  const reader = new FileReader();
  reader.readAsDataURL(image);
  reader.onload = function (e) {
    setUrl(e.target.result);
  };

  const onProgress = (p, file) => {
    setProgress(p);
  };

  // console.log("Cropper Image:", image);
  // console.log("Cropper Dimensions:", cropperHeight, cropperWidth);
  useEffect(() => {
    if (cropperRef?.current && url) {
      // console.log("Instantiating croppie");
      const croppieInstance = new Croppie(cropperRef.current, {
        viewport: {
          width: cropperWidth,
          height: cropperHeight,
        },
        boundary: {
          height: 300,
          // width: 400,
        },
      });
      croppieInstance.bind({
        url,
      });
      setCroppie(croppieInstance);
    }
  }, [cropperRef, url]);

  return (
    <div className="profile-image-cropper">
      <div className="profile-image-cropper__container" ref={cropperRef}></div>
      <Button
        className="primary green large upload-btn"
        onClick={async () => {
          if (!sourceId || !sourceType) {
            console.error("No source details");
            return;
          }

          croppie
            .result({
              type: "blob",
              // size: {
              //   width: cropperWidth,
              //   height: cropperHeight,
              // },
              size: "original",
            })
            .then((blob) => {
              // console.log("BLOB:", blob);
              createMedia({
                variables: {
                  type,
                  subtype,
                  filename: image.name,
                  private: false,
                  isExternal: false,
                  sourceId,
                  sourceType,
                  aspectRatio: 1,
                },
                update: async (cache, { data: { createMedia } }) => {
                  new Compressor(blob, {
                    width: 20,
                    success: async (result) => {
                      // Full file
                      await uploadFile({
                        url: createMedia.url,
                        file: image,
                        blob,
                        headers: {
                          "x-amz-acl": "public-read",
                        },
                        onProgress,
                      });

                      // Placeholder file
                      await uploadFile({
                        url: createMedia.placeholderUrl,
                        file: image,
                        blob: result,
                        headers: {
                          "x-amz-acl": "public-read",
                        },
                        onProgress: () => {},
                      });

                      onCreateMedia(cache, createMedia);
                    },
                    error(err) {
                      console.log(err.message);
                    },
                  });
                },
              });
            });
        }}
      >
        {progress || loading ? `Uploading ${progress}%` : "Upload Image"}
        <div
          className="upload-btn-progress"
          style={{ width: `${progress}%` }}
        />
      </Button>
    </div>
  );
}
