import Placeholder from "../../../components/Placeholder";
import RequestsNotification from "../RequestsNotification";
import "./ProfileCard.css";
import { useQuery } from "@apollo/client";
import { USER_PROFILE } from "../../../api/user";
import getNames from "../../../utils/getNames";
import { useHistory } from "react-router-dom";
import Avatar from "../../../components/Avatar/Avatar";
import captureException from "../../../utils/captureException";

export default function ProfileCard() {
  const history = useHistory();
  const { loading, error, data } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileCard",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <div className="home-page-mobile__profile-card">
        <div className="home-page-mobile__profile-card__profile-info">
          <div className="home-page-mobile__profile-card__profile-info__image"></div>

          <div className="home-page-mobile__profile-card__profile-info__text">
            <div className="home-page-mobile__profile-card__profile-info__text__primary">
              <Placeholder
                style={{ width: "120px", height: "16px" }}
                pulse={true}
              />
            </div>

            <div className="home-page-mobile__profile-card__profile-info__text__secondary">
              <Placeholder
                style={{ width: "60px", height: "12px", marginTop: "8px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const userProfile = data.userProfile;

  return (
    <div className="home-page-mobile__profile-card">
      <div
        className="home-page-mobile__profile-card__profile-info"
        onClick={() => {
          history.push("/app/profile/" + userProfile.id);
        }}
      >
        <div className="home-page-mobile__profile-card__profile-info__image">
          {userProfile.profilePhoto && (
            <Avatar media={userProfile.profilePhoto} />
          )}
        </div>

        <div className="home-page-mobile__profile-card__profile-info__text">
          <div className="home-page-mobile__profile-card__profile-info__text__primary">
            Hello {getNames(userProfile.name).firstname}!
          </div>

          <div className="home-page-mobile__profile-card__profile-info__text__secondary">
            @{userProfile.handle}
          </div>
        </div>
      </div>

      <RequestsNotification />

      {/* {<OnboardingPrompt />} */}
    </div>
  );
}
