import "./StatsListItem.css";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SwapIcon } from "../../../icons/swap.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { useBadmintonScoringState } from "../utils/useBadmintonScoringState";
import {
  CREATE_STAT,
  DELETE_FIXTURE_STAT,
  UPDATE_STAT,
  DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION,
} from "../../../api/fixture";
import Spinner from "../../../components/Spinner";
import { useMutation } from "@apollo/client";
import { FIXTURE_STATS } from "../../../api/functions";

export default function StatsListItem({
  stat,
  stats,
  homeParticipant,
  awayParticipant,
  participantType,
}) {
  const [{ selectedPeriod, fixtureId, eventId, statTypes }] =
    useBadmintonScoringState();

  const [createStat, { loading: creating }] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {},
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreBadminton.EventListItem",
      //     mutation: "CREATE_STAT",
      //   },
      // });
      console.log(error);
    },
  });

  const [deleteStat, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {},
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreBadminton.EventListItem",
      //     mutation: "DELETE_FIXTURE_STAT",
      //   },
      // });
      console.log(error);
    },
  });

  const [
    deleteStatWithoutPropagation,
    { loading: deletingWithoutPropagation },
  ] = useMutation(DELETE_FIXTURE_STAT_WITHOUT_PROPAGATION, {
    update: (cache, { data: { deleteFixtureStat } }) => {},
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreBadminton.EventListItem",
      //     mutation: "DELETE_FIXTURE_STAT",
      //   },
      // });
      console.log(error);
    },
  });

  const [updateStat, { loading: updating }] = useMutation(UPDATE_STAT, {
    onError: (error) => {
      // captureException({
      //   error,
      //   info: {
      //     type: "mutation",
      //     component: "ScoreBadminton.EventListItem",
      //     mutation: "UPDATE_STAT",
      //   },
      // });
      console.log(error);
    },
  });

  const points = stats
    .filter(
      (s) =>
        s.statType.name === "Point Won" &&
        s.timeStamp <= stat.timeStamp &&
        s.fixturePeriod.id === selectedPeriod?.id
    )
    .sort((a, b) => b.timeStamp - a.timeStamp);

  const homeScore =
    points.filter((p) => {
      if (participantType === "INDIVIDUAL") {
        return p.profile?.id === homeParticipant?.profile?.id;
      } else {
        return p.organization?.id === homeParticipant?.organization?.id;
      }
    }).length || 0;

  const awayScore =
    points.filter((p) => {
      if (participantType === "INDIVIDUAL") {
        return p.profile?.id === awayParticipant?.profile?.id;
      } else {
        return p.organization?.id === awayParticipant?.organization?.id;
      }
    }).length || 0;

  const serviceStat = stats.find(
    (s) =>
      s.statType.name === "Service" &&
      s.relationshipsTo?.find((r) => r.from?.id === stat.id)
  );

  const unforcedErrorStat = stats.find(
    (s) =>
      s.statType.name === "Unforced Error" &&
      s.relationshipsTo?.find((r) => r.from?.id === stat.id)
  );

  return (
    <div className="ScoringApp-badminton__scoring__stats-list__list__item">
      <div className="ScoringApp-badminton__scoring__stats-list__list__item__order">
        <div className="ScoringApp-badminton__scoring__stats-list__list__item__order__text">
          {homeScore} - {awayScore}
        </div>

        <div
          className="ScoringApp-badminton__scoring__stats-list__list__item__order__delete"
          onClick={() => {
            deleteStat({
              variables: {
                id: stat.id,
              },
              update: (cache, { data: { deleteFixtureStat } }) => {
                if (deleteFixtureStat?.length) {
                  const deletedIds = deleteFixtureStat.map((x) => x.id);

                  cache.modify({
                    id: "Fixture:" + fixtureId,
                    fields: {
                      stats(existingRefs, { readField }) {
                        return existingRefs.filter(
                          (statRef) =>
                            !deletedIds.includes(readField("id", statRef))
                        );
                      },
                    },
                  });

                  cache.modify({
                    id:
                      "BadmintonPeriodScoreItem:" +
                      selectedPeriod.id +
                      (stat.profile?.id || stat.organization?.id),
                    fields: {
                      value(curr) {
                        return curr - 1;
                      },
                    },
                  });
                }
              },
            });
          }}
        >
          {deleting ? <Spinner /> : <TrashIcon className="icon" />}
        </div>
      </div>

      <div className="ScoringApp-badminton__scoring__stats-list__list__item__body">
        <div className="ScoringApp-badminton__scoring__stats-list__list__item__body__statement">
          <div className="ScoringApp-badminton__scoring__stats-list__list__item__body__statement__text">
            Point won by{" "}
            <span className="bold">
              {stat.profile?.name || stat.organization?.name}
            </span>
          </div>

          <div
            className="ScoringApp-badminton__scoring__stats-list__list__item__body__statement__switch"
            onClick={() => {
              if (updating) return;
              updateStat({
                variables: {
                  where: { id: stat.id },
                  data:
                    participantType === "INDIVIDUAL"
                      ? {
                          profile: {
                            connect: {
                              id:
                                stat.profile.id === homeParticipant.profile.id
                                  ? awayParticipant.profile.id
                                  : homeParticipant.profile.id,
                            },
                          },
                        }
                      : {
                          organization: {
                            connect: {
                              id:
                                stat.organization.id ===
                                homeParticipant.organization.id
                                  ? awayParticipant.organization.id
                                  : homeParticipant.organization.id,
                            },
                          },
                        },
                },
                update: (cache, { data: { updateStat } }) => {
                  cache.modify({
                    id: cache.identify(updateStat),
                    fields:
                      participantType === "INDIVIDUAL"
                        ? {
                            profile() {
                              return updateStat.profile;
                            },
                          }
                        : {
                            organization() {
                              return updateStat.organization;
                            },
                          },
                  });

                  cache.modify({
                    id:
                      "BadmintonPeriodScoreItem:" +
                      selectedPeriod.id +
                      (homeParticipant?.profile?.id ||
                        homeParticipant?.organization?.id),
                    fields: {
                      value(curr) {
                        const incrementParticipantId =
                          updateStat.profile?.id ||
                          updateStat?.organization?.id;
                        if (
                          incrementParticipantId ===
                          (homeParticipant?.profile?.id ||
                            homeParticipant?.organization?.id)
                        ) {
                          return curr + 1;
                        } else {
                          return curr - 1;
                        }
                      },
                    },
                  });

                  cache.modify({
                    id:
                      "BadmintonPeriodScoreItem:" +
                      selectedPeriod.id +
                      (awayParticipant?.profile?.id ||
                        awayParticipant?.organization?.id),
                    fields: {
                      value(curr) {
                        const incrementParticipantId =
                          updateStat.profile?.id ||
                          updateStat?.organization?.id;
                        if (
                          incrementParticipantId ===
                          (awayParticipant?.profile?.id ||
                            awayParticipant?.organization?.id)
                        ) {
                          return curr + 1;
                        } else {
                          return curr - 1;
                        }
                      },
                    },
                  });
                },
              });
            }}
          >
            {updating ? <Spinner /> : <SwapIcon className="icon" />}
          </div>
        </div>

        {unforcedErrorStat && (
          <div className="ScoringApp-badminton__scoring__stats-list__list__item__body__relations">
            <div className="ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation">
              <div className="ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation__text">
                Unforced error by{" "}
                <span className="bold">
                  {unforcedErrorStat.profile?.name ||
                    unforcedErrorStat.organization?.name}
                </span>
              </div>

              <div
                className="ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation__option"
                onClick={() => {
                  if (deletingWithoutPropagation) return;
                  deleteStatWithoutPropagation({
                    variables: {
                      id: unforcedErrorStat.id,
                    },
                    update: (
                      cache,
                      { data: { deleteFixtureStatWithoutPropagation } }
                    ) => {
                      cache.modify({
                        id: "Fixture:" + fixtureId,
                        fields: {
                          stats(existingRefs, { readField }) {
                            return existingRefs.filter(
                              (statRef) =>
                                deleteFixtureStatWithoutPropagation.id !==
                                readField("id", statRef)
                            );
                          },
                        },
                      });
                    },
                  });
                }}
              >
                {deletingWithoutPropagation ? (
                  <Spinner />
                ) : (
                  <TrashIcon className="icon" />
                )}
              </div>
            </div>
          </div>
        )}

        {!unforcedErrorStat && (
          <div className="ScoringApp-badminton__scoring__stats-list__list__item__body__options">
            <div
              className="ScoringApp-badminton__scoring__stats-list__list__item__body__options__option"
              onClick={() => {
                if (creating) return;
                createStat({
                  variables: {
                    data: {
                      event: { connect: { id: eventId } },
                      ...(participantType === "INDIVIDUAL"
                        ? {
                            profile: {
                              connect: {
                                id:
                                  stat.profile?.id ===
                                  homeParticipant?.profile?.id
                                    ? awayParticipant?.profile.id
                                    : homeParticipant?.profile.id,
                              },
                            },
                          }
                        : {
                            organization: {
                              connect: {
                                id:
                                  stat.organization?.id ===
                                  homeParticipant?.organization?.id
                                    ? awayParticipant?.organization?.id
                                    : homeParticipant?.organization?.id,
                              },
                            },
                          }),
                      statType: {
                        connect: {
                          id: statTypes.find(
                            (st) => st.name === "Unforced Error"
                          ).id,
                        },
                      },
                      fixture: { connect: { id: fixtureId } },
                      sport: { connect: { name: "Badminton" } },
                      fixturePeriod: { connect: { id: selectedPeriod.id } },
                      relationshipsTo: {
                        create: {
                          from: {
                            connect: {
                              id: stat.id,
                            },
                          },
                        },
                      },
                    },
                  },
                  update(cache, { data: { createStat } }) {
                    const q = cache.readQuery({
                      query: FIXTURE_STATS,
                      variables: {
                        id: fixtureId,
                        where: {
                          fixturePeriod: {
                            id: { in: [selectedPeriod?.id] },
                          },
                        },
                      },
                    });

                    const newStats = [createStat];

                    cache.writeQuery({
                      query: FIXTURE_STATS,
                      variables: {
                        id: fixtureId,
                        where: {
                          fixturePeriod: {
                            id: { in: [selectedPeriod?.id] },
                          },
                        },
                      },
                      data: {
                        fixture: {
                          ...q.fixture,
                          stats: [...q.fixture.stats, ...newStats],
                        },
                      },
                    });
                  },
                });
              }}
            >
              {creating ? <Spinner /> : <PlusIcon className="icon" />}
              Unforced Error
            </div>
          </div>
        )}
      </div>

      <div className="ScoringApp-badminton__scoring__stats-list__list__item__server">
        <div className="ScoringApp-badminton__scoring__stats-list__list__item__server__text">
          Server:{" "}
          {serviceStat?.profile?.name || serviceStat?.organization?.name}
        </div>

        <div
          className="ScoringApp-badminton__scoring__stats-list__list__item__server__switch"
          onClick={() => {
            if (updating) return;
            updateStat({
              variables: {
                where: { id: serviceStat.id },
                data:
                  participantType === "INDIVIDUAL"
                    ? {
                        profile: {
                          connect: {
                            id:
                              serviceStat.profile.id ===
                              homeParticipant.profile.id
                                ? awayParticipant.profile.id
                                : homeParticipant.profile.id,
                          },
                        },
                      }
                    : {
                        organization: {
                          connect: {
                            id:
                              serviceStat.organization.id ===
                              homeParticipant.organization.id
                                ? awayParticipant.organization.id
                                : homeParticipant.organization.id,
                          },
                        },
                      },
              },
            });
          }}
        >
          {updating ? <Spinner /> : <SwapIcon className="icon" />}
        </div>
      </div>
    </div>
  );
}
