// V1
import "./PageSearchBar.css";
import Card from "../Card";
import Button from "../Button";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow-right.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { useState } from "react";
import { useAppState } from "../../utils/appState";

function PageSearchBar({
  onSearchSubmit,
  placeholder,
  defaultValue,
  disabled,
}) {
  const [, setAppState] = useAppState();
  const [search, setSearch] = useState(defaultValue || "");
  const [searchTemp, setSearchTemp] = useState(defaultValue || "");

  const onSubmit = (e) => {
    e && e.preventDefault();
    setSearch(searchTemp);
    onSearchSubmit(searchTemp);
  };

  const reset = () => {
    setSearch("");
    setSearchTemp("");
    onSearchSubmit("");
    setAppState({ directorySearch: "" });
  };

  return (
    <Card className={"page-search-bar " + (disabled ? "disabled" : "")}>
      <SearchIcon style={{ stroke: "var(--dark-4)", height: 12 }} />
      <form className="page-search-bar-form" onSubmit={onSubmit}>
        <input
          type="text"
          value={searchTemp}
          onChange={(e) => {
            setSearchTemp(e.target.value);
          }}
          className="page-search-bar-input"
          placeholder={disabled ? "" : placeholder}
          disabled={!!search}
        />
        <Button
          className={
            `primary ${
              search ? "red" : "blue"
            } medium submit-page-search-btn ` +
            (searchTemp ? "" : "submit-disabled")
          }
          onClick={() => {
            if (search) {
              reset();
            } else {
              onSubmit();
            }
          }}
        >
          {search ? (
            <TimesIcon style={{ height: 14, stroke: "white" }} />
          ) : (
            <ArrowRightIcon style={{ height: 14, stroke: "white" }} />
          )}
        </Button>
      </form>
    </Card>
  );
}

export default PageSearchBar;
