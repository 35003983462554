import "./TabSelector.css";

function TabSelectorItem({ children, selected, onClick }) {
  return (
    <div
      className={
        "tab-selector-option hover-pointer " + (selected ? "selected" : "")
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default TabSelectorItem;
