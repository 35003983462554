import "./BasketballStats.css";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { STAT_TYPES } from "../../../../api/sport";
import { EVENT_STATS, EVENT_PROFILE_STATS } from "../../../../api/event";
import NewDropdown from "../../../../components/NewDropdown/NewDropdown";
import { ReactComponent as UpDown } from "../../../../icons/up-down.svg";
import Avatar from "../../../../components/Avatar";
import captureException from "../../../../utils/captureException";

function TeamStats({ statTypes, eventId }) {
  const defaultStatType = statTypes.find((x) => x.name === "PTS");
  const [selectedStat, setSelectedStat] = useState(defaultStatType);

  const { loading, error, data, refetch } = useQuery(EVENT_STATS, {
    variables: {
      id: eventId,
      statTypeId: defaultStatType.id,
      type: "organization",
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.BasketballStats.TeamStats",
        query: "EVENT_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const tables = data?.eventStats || [];
  console.log("Team performance:", tables);
  const max = Math.max(1, tables[0]?.value);

  return (
    <div className="event-team-stats-mobile">
      <div className="event-team-stats-mobile__header">
        <div className="event-team-stats-mobile__header__title">
          Team Performance
        </div>

        <div className="event-team-stats-mobile__header__stat-type-selector">
          <NewDropdown
            target={
              <div className="event-team-stats-mobile__header__stat-type-selector__target">
                {selectedStat.name}{" "}
                <UpDown className="event-team-stats-mobile__header__stat-type-selector__target__icon" />
              </div>
            }
            menu={({ setShowMenu }) => (
              <div className="event-team-stats-mobile__header__stat-type-selector__menu">
                {statTypes.map((st) => (
                  <div
                    className="event-team-stats-mobile__header__stat-type-selector__menu__item"
                    onClick={() => {
                      setSelectedStat(st);
                      setShowMenu(false);
                      refetch({
                        id: eventId,
                        statTypeId: st.id,
                        type: "organization",
                      });
                    }}
                  >
                    {st.name}
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      </div>

      <div className="event-team-stats-mobile__items">
        {tables.map((table) => (
          <div className="event-team-stats-mobile__items__item">
            <div className="event-team-stats-mobile__items__item__image">
              {table.organization?.profilePhoto && (
                <Avatar media={table.organization.profilePhoto} />
              )}
            </div>

            <div className="event-team-stats-mobile__items__item__meta">
              <div className="event-team-stats-mobile__items__item__meta__text">
                <div className="event-team-stats-mobile__items__item__meta__participant">
                  {table.organization?.name}
                </div>

                <div className="event-team-stats-mobile__items__item__meta__value">
                  {table.value}
                </div>
              </div>

              <div className="event-team-stats-mobile__items__item__meta__graph">
                <div className="event-team-stats-mobile__items__item__meta__graph__track">
                  <div
                    className="event-team-stats-mobile__items__item__meta__graph__track__fill"
                    style={{ width: (table.value / max) * 100 + "%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function PlayerStats({ statTypes, eventId }) {
  const defaultStatType = statTypes.find((x) => x.name === "PTS");
  const [selectedStat, setSelectedStat] = useState(defaultStatType);

  const { loading, error, data, refetch } = useQuery(EVENT_PROFILE_STATS, {
    variables: {
      id: eventId,
      statTypeId: defaultStatType.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.BasketballStats.PlayerStats",
        query: "EVENT_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const tables = data?.eventProfileStats;
  // console.log("playerTables:", tables);
  const max = Math.max(1, tables[0]?.value);

  return (
    <div className="event-team-stats-mobile">
      <div className="event-team-stats-mobile__header">
        <div className="event-team-stats-mobile__header__title">
          Player Performance
        </div>

        <div className="event-team-stats-mobile__header__stat-type-selector">
          <NewDropdown
            target={
              <div className="event-team-stats-mobile__header__stat-type-selector__target">
                {selectedStat.name}{" "}
                <UpDown className="event-team-stats-mobile__header__stat-type-selector__target__icon" />
              </div>
            }
            menu={({ setShowMenu }) => (
              <div className="event-team-stats-mobile__header__stat-type-selector__menu">
                {statTypes.map((st) => (
                  <div
                    className="event-team-stats-mobile__header__stat-type-selector__menu__item"
                    onClick={() => {
                      setSelectedStat(st);
                      setShowMenu(false);
                      refetch({
                        id: eventId,
                        statTypeId: st.id,
                        type: "profile",
                      });
                    }}
                  >
                    {st.name}
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      </div>

      <div className="event-team-stats-mobile__items">
        {tables.map((table, i) => (
          <div
            key={"player-table-" + i}
            className="event-team-stats-mobile__items__item"
          >
            <div
              className="event-team-stats-mobile__items__item__image"
              style={{ height: "48px", width: "48px" }}
            >
              {table.profile?.profilePhoto && (
                <Avatar media={table.profile.profilePhoto} />
              )}
            </div>

            <div className="event-team-stats-mobile__items__item__meta">
              <div className="event-team-stats-mobile__items__item__meta__text">
                <div className="event-team-stats-mobile__items__item__meta__participant">
                  <div className="event-team-stats-mobile__items__item__meta__participant__primary">
                    {table.profile?.name}
                  </div>

                  <div className="event-team-stats-mobile__items__item__meta__participant__secondary">
                    {table.organization?.name}
                  </div>
                </div>

                <div
                  className="event-team-stats-mobile__items__item__meta__value"
                  style={{ fontSize: "20px" }}
                >
                  {table.value}
                </div>
              </div>

              <div className="event-team-stats-mobile__items__item__meta__graph">
                <div className="event-team-stats-mobile__items__item__meta__graph__track">
                  <div
                    className="event-team-stats-mobile__items__item__meta__graph__track__fill"
                    style={{ width: (table.value / max) * 100 + "%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function BasketballStats({ ev }) {
  const { loading, error, data } = useQuery(STAT_TYPES, {
    variables: { sport: ev?.sport?.name || "Basketball" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Event.BasketballStats",
        query: "STAT_TYPES",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (loading) {
    return <div>Loading</div>;
  }

  const statTypes = data?.statTypes;

  if (!statTypes.length) {
    // console.log("No stat types added");
    return <div className=""></div>;
  }

  return (
    <div className="event-stats-mobile__football">
      <TeamStats statTypes={statTypes} eventId={ev.id} />

      <PlayerStats statTypes={statTypes} eventId={ev.id} />
    </div>
  );
}
