// V1
import { useState, useRef } from "react";
import "./UploaderWithCropper.css";
import Card from "../Card";
import Button from "../Button";
import { ReactComponent as FolderIcon } from "../../icons/folder.svg";
import ClickOutside from "../ClickOutside";
import { useAppState } from "../../utils/appState";
import Cropper from "../Cropper";
import { isMobile } from "react-device-detect";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

function UploaderWithCropper({
  type = "IMAGE",
  subtype,
  sourceId,
  sourceType,
  label,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  updateFilesCb = () => {},
  onCreateMedia = () => {},
  mediaSetId,
  cropperWidth,
  cropperHeight,
  ...otherProps
}) {
  const fileInputField = useRef(null);
  const [, setAppState] = useAppState();
  const [files, setFiles] = useState({});
  const [sizeError, setSizeError] = useState(false);

  const filesLength = Object.keys(files).reduce((accum, curr) => {
    accum++;
    return accum;
  }, 0);

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      } else {
        setSizeError(true);
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = (e) => {
    setSizeError(false);
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  // console.log("ARs:", aspectRatios);
  // console.log("Media Set Id:", mediaSetId);
  // console.log("UPLOADER SOURCE ID:", sourceId);
  // console.log("UPLOADER SOURCE TYPE:", sourceType);

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div
          className="app-uploader-wrapper"
          ref={wrapperRef}
          style={isMobile ? { width: "90%" } : {}}
        >
          <Card
            className="app-uploader"
            style={!!filesLength ? { paddingBottom: "104px" } : {}}
          >
            <div className="title">Upload Files</div>
            {!filesLength && (
              <div className="input-container">
                <FolderIcon className="input-icon" />
                <div className="input-placeholder">
                  Drag and drop your files here or
                </div>
                <Button className="primary small blue browse-files-btn">
                  Browse files
                </Button>
                <input
                  type="file"
                  ref={fileInputField}
                  title=""
                  value=""
                  onChange={handleNewFileUpload}
                  {...otherProps}
                />
              </div>
            )}

            {!!filesLength && (
              <Cropper
                image={files[Object.keys(files)[0]]}
                sourceId={sourceId}
                sourceType={sourceType}
                type={type}
                subtype={subtype}
                onCreateMedia={onCreateMedia}
                cropperWidth={cropperWidth}
                cropperHeight={cropperHeight}
              />
            )}

            {sizeError && (
              <div className="app-uploader-size-error">
                This file is too big. Max file size is 2 MB.
              </div>
            )}
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

export default UploaderWithCropper;
