import { gql } from "@apollo/client";

const UPDATE_STANDING_ITEM_META = gql`
  mutation updateStandingItemMeta(
    $where: UniqueInput!
    $data: UpdateMetaInput!
  ) {
    updateStandingItemMeta(where: $where, data: $data) {
      id
      label
      shortLabel
      value
      stringValue
    }
  }
`;

export default UPDATE_STANDING_ITEM_META;
