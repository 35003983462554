import Button from "../Button";
import Card from "../Card";
import "./DeleteMatchReport.css";
import { useMutation } from "@apollo/client";
import { DELETE_POST } from "../../api/post";
import captureException from "../../utils/captureException";
import { Fragment } from "react";
import Loader from "../Loader";

export default function DeleteMatchReport({ dismiss, postId, fixtureId }) {
  const [deletePost, { loading }] = useMutation(DELETE_POST, {
    update: (cache, { data: { deletePost } }) => {
      cache.modify({
        id: "Fixture:" + fixtureId,
        fields: {
          report() {
            return null;
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "DeleteMatchReport",
          mutation: "DELETE_POST",
        },
      });
      console.log(error);
    },
  });
  return (
    <Card className={"delete-match-report"}>
      <div className="delete-match-report__body">
        Are you sure you want to delete this match report?
      </div>
      <div className="delete-match-report__options">
        {loading ? (
          <div className="delete-match-report__options__loading">
            <Loader theme={"blue"} />
          </div>
        ) : (
          <Fragment>
            <Button
              className="primary medium delete-match-report__options__option delete red"
              onClick={() => {
                if (!loading) {
                  deletePost({ variables: { id: postId } });
                }
              }}
            >
              Yes, delete
            </Button>
            <Button
              className="primary medium delete-match-report__options__option neutral"
              onClick={dismiss}
            >
              No, cancel
            </Button>
          </Fragment>
        )}
      </div>
    </Card>
  );
}
