import { gql } from "@apollo/client";

export const UPDATE_FIXTURE = ({ selectionSet }) => {
  return gql`
    mutation updateFixture($where: UniqueInput!, $data: UpdateFixtureInput!) {
    updateFixture(where: $where, data: $data) {
        id
        ${selectionSet}
    }
    }
`;
};
