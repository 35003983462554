import { gql } from "@apollo/client";

const UPDATE_FIXTURE = gql`
  mutation updateFixture($where: UniqueInput!, $data: UpdateFixtureInput!) {
    updateFixture(where: $where, data: $data) {
      id
      scheduledStart
      scheduledEnd
      actualStart
      totalLegs
      legs {
        id
      }
      periods {
        id
        label
        order
        startDate
        endDate
        duration
        metas {
          id
          label
          stringValue
        }
      }
      metas {
        id
        label
        value
        stringValue
      }
      roles {
        id
        order
        description
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
        }
        organization {
          id
          name
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
      }
      eventPart {
        id
      }
      statsCacheState
    }
  }
`;

export default UPDATE_FIXTURE;
