import "./UpcomingEvents.css";
import { useState, useRef, useEffect, Fragment } from "react";
import { ReactComponent as ChevronLeftIcon } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../../icons/chevron-right.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { USER_FIXTURES, UPCOMING_FIXTURES } from "../../../api/user";
import FixtureListItem from "../../FixtureListItem";
import Spinner from "../../../components/Spinner";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

const dayOfWeekMap = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function LoadingItem() {
  return (
    <div className="fixture-list-item-mobile">
      <div className="fixture-list-item-mobile__teams">
        {[1, 2].map((p) => (
          <div
            key={"loading-" + p}
            className="fixture-list-item-mobile__teams__item"
          >
            <div className="fixture-list-item-mobile__teams__item__image"></div>

            <div className="fixture-list-item-mobile__teams__item__name">
              <Placeholder
                style={{ height: "12px", width: "80px" }}
                pulse={true}
              />
            </div>

            <div className="fixture-list-item-mobile__teams__item__score"></div>
          </div>
        ))}
      </div>

      <div className="fixture-list-item-mobile__time">
        <Fragment>
          <div className="date">
            <Placeholder
              style={{ height: "10px", width: "40px" }}
              pulse={true}
            />
          </div>
          <div className="time" style={{ marginTop: "8px" }}>
            <Placeholder
              style={{ height: "10px", width: "20px" }}
              pulse={true}
            />
          </div>
        </Fragment>
      </div>
    </div>
  );
}

export default function UpcomingEvents() {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [eventsDate, setEventsDate] = useState(moment().toISOString());
  const [selectedDate, setSelectedDate] = useState(moment().toISOString());
  const startOfMonth = moment(selectedDate).startOf("month");
  const daysInMonth = moment(selectedDate).daysInMonth();
  const days = [...Array(daysInMonth).keys()].map((n) => {
    const cloned = startOfMonth.clone();
    const incremented = cloned.add(n, "days");
    // console.log(incremented.date());
    return {
      date: incremented,
      day: incremented.day(),
    };
  });

  useEffect(() => {
    const width = window.innerWidth;
    const elementWidth = (width - 68) / 4;
    // console.log("Window width:", width);
    // console.log("CI Element Width:", elementWidth);
    // console.log("CI Element Index:", moment(selectedDate).date());
    if (scrollRef?.current) {
      scrollRef.current.scrollLeft =
        elementWidth * (moment(selectedDate).date() - 1) +
        12 * (moment(selectedDate).date() - 1);
    }
  }, [scrollRef]);

  // console.log(days);

  // const weeks = new Array(daysInMonth + startOfMonthDay)
  //   .fill()
  //   .map((_, i) => {
  //     if (i < startOfMonthDay) {
  //       return 0;
  //     } else {
  //       return i + 1 - startOfMonthDay;
  //     }
  //   })
  //   .reduce((accum, curr, i) => {
  //     const idx = Math.floor(i / 7);
  //     if (!accum[idx]) {
  //       accum[idx] = [];
  //     }

  //     accum[idx].push(curr);
  //     return accum;
  //   }, []);

  const { loading, error, data, refetch } = useQuery(UPCOMING_FIXTURES, {
    variables: {
      where: {
        startDate: moment().startOf("month").toISOString(),
        endDate: moment().endOf("month").toISOString(),
      },
    },
    // notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "UpcomingEvents",
        query: "USER_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const fixtures = data?.upcomingFixtures || [];
  // console.log("F:", fixtures);

  const selectedDayFixtures = fixtures.filter(
    (f) =>
      moment(eventsDate).startOf("day").isBefore(f.scheduledStart) &&
      moment(eventsDate).endOf("day").isAfter(f.scheduledStart)
  );

  const resetScroll = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollLeft = 0;
    }
  };

  const prev = () => {
    const newDate = moment(selectedDate)
      .startOf("month")
      .subtract(1, "d")
      .startOf("month")
      .toISOString();
    setSelectedDate(newDate);

    setEventsDate(newDate);
    refetch({
      where: {
        startDate: moment(newDate).startOf("month").toISOString(),
        endDate: moment(newDate).endOf("month").toISOString(),
      },
    });
    resetScroll();
  };

  const next = () => {
    const newDate = moment(selectedDate)
      .endOf("month")
      .add(1, "d")
      .toISOString();
    setSelectedDate(newDate);

    setEventsDate(newDate);
    refetch({
      where: {
        startDate: moment(newDate).startOf("month").toISOString(),
        endDate: moment(newDate).endOf("month").toISOString(),
      },
    });
    resetScroll();
  };

  return (
    <div className="home-page-mobile__upcoming-events-card">
      <div className="home-page-mobile__upcoming-events-card__header">
        <div className="home-page-mobile__upcoming-events-card__title">
          Upcoming Events
        </div>

        <div className="home-page-mobile__upcoming-events-card__month-selector">
          <div
            className="home-page-mobile__upcoming-events-card__month-selector__option"
            onClick={prev}
          >
            <ChevronLeftIcon style={{ height: "14px", width: "14px" }} />
          </div>

          <div className="home-page-mobile__upcoming-events-card__month-selector__current">
            {moment(selectedDate).format("MMM YYYY")}
          </div>

          <div
            className="home-page-mobile__upcoming-events-card__month-selector__option"
            onClick={next}
          >
            <ChevronRightIcon style={{ height: "14px", width: "14px" }} />
          </div>
        </div>
      </div>

      <div
        className="home-page-mobile__upcoming-events-card__days"
        ref={scrollRef}
      >
        {days.map((d) => (
          <div
            className={
              "home-page-mobile__upcoming-events-card__days__item " +
              (moment(selectedDate).date() === d.date.date() ? "active" : "")
            }
            onClick={() => {
              // if (!day) return;
              // const newDate = moment(selectedDate)
              //   .date(day)
              //   .toISOString();
              const newDate = d.date.toISOString();
              setSelectedDate(newDate);
              setEventsDate(newDate);
            }}
          >
            <div className="home-page-mobile__upcoming-events-card__days__item__month">
              {moment(selectedDate).format("MMM")}
            </div>

            <div className="home-page-mobile__upcoming-events-card__days__item__date">
              {d.date.format("DD")}
            </div>

            <div className="home-page-mobile__upcoming-events-card__days__item__day">
              {dayOfWeekMap[d.day].toUpperCase()}
            </div>
          </div>
        ))}
        {/* {[...Array(daysInMonth).keys()].map((n) => (
          <div className="home-page-mobile__upcoming-events-card__days__item">
            <div className="home-page-mobile__upcoming-events-card__days__item__month">
              {moment(selectedDate).format("MMM").toUpperCase()}
            </div>

            <div className="home-page-mobile__upcoming-events-card__days__item__date">
              {n + 1 < 10 ? "0" + (n + 1) : n + 1}
            </div>

            <div className="home-page-mobile__upcoming-events-card__days__item__day">
              MON
            </div>
          </div>
        ))} */}

        {/* <div className="home-page-mobile__upcoming-events-card__days__item active">
          <div className="home-page-mobile__upcoming-events-card__days__item__month">
            JAN
          </div>

          <div className="home-page-mobile__upcoming-events-card__days__item__date">
            02
          </div>

          <div className="home-page-mobile__upcoming-events-card__days__item__day">
            MON
          </div>
        </div>

        <div className="home-page-mobile__upcoming-events-card__days__item">
          <div className="home-page-mobile__upcoming-events-card__days__item__month">
            JAN
          </div>

          <div className="home-page-mobile__upcoming-events-card__days__item__date">
            03
          </div>

          <div className="home-page-mobile__upcoming-events-card__days__item__day">
            MON
          </div>
        </div> */}
      </div>

      <div className="home-page-mobile__upcoming-events-card__fixtures">
        {loading && <LoadingItem />}
        {!loading &&
          selectedDayFixtures.map((fixture) => (
            <FixtureListItem fixture={fixture} />
          ))}

        {!loading && !selectedDayFixtures?.length && (
          <div className="no-data">
            <div>You have no events scheduled for this day.</div>
            {/* <div>Let's change that.</div> */}
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                history.push("/app/events");
              }}
            >
              Find Events Near You
            </div>
          </div>
        )}
        {/* <div className="home-page-mobile__upcoming-events-card__fixtures__item">
          <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams">
            <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item">
              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__image"></div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__name">
                Manchester United
              </div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__score">
                3
              </div>
            </div>

            <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item">
              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__image"></div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__name">
                Arsenal
              </div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__score">
                2
              </div>
            </div>
          </div>

          <div className="home-page-mobile__upcoming-events-card__fixtures__item__time">
            <div>4:00</div>
            <div>PM</div>
          </div>
        </div> */}

        {/* <div className="home-page-mobile__upcoming-events-card__fixtures__item">
          <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams">
            <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item">
              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__image"></div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__name">
                Manchester United
              </div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__score">
                3
              </div>
            </div>

            <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item">
              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__image"></div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__name">
                Arsenal
              </div>

              <div className="home-page-mobile__upcoming-events-card__fixtures__item__teams__item__score">
                2
              </div>
            </div>
          </div>

          <div className="home-page-mobile__upcoming-events-card__fixtures__item__time">
            <div>4:00</div>
            <div>PM</div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
