import { gql } from "@apollo/client";

const ACCOUNT_REGISTRATIONS = gql`
  query accountRegistrations {
    userAccount {
      registrations {
        id
        createdAt
        amountPaid
        paid
        organization {
          id
          name
        }
        event {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

export default ACCOUNT_REGISTRATIONS;
