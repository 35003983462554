import { gql } from "@apollo/client";

const CALCULATE_STANDINGS = gql`
  mutation calculateStandings(
    $eventId: ID!
    $eventPartId: ID
    $pointsPerWin: Float
    $pointsPerDraw: Float
    $pointsPerLoss: Float
  ) {
    calculateStandings(
      eventId: $eventId
      eventPartId: $eventPartId
      pointsPerWin: $pointsPerWin
      pointsPerDraw: $pointsPerDraw
      pointsPerLoss: $pointsPerLoss
    ) {
      id
      createdAt
      title
      event {
        id
        name
      }
      eventPart {
        id
        label
      }
      items {
        id
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        order
        metas {
          id
          label
          shortLabel
          value
        }
      }
    }
  }
`;

export default CALCULATE_STANDINGS;
