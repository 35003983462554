import { gql } from "@apollo/client";

const FIXTURE_ROLES = gql`
  query fixture($id: ID!, $where: EventRoleWhereInput) {
    fixture(id: $id) {
      id
      roles(where: $where) {
        id
        order
        description
        confirmed
        type {
          id
          name
        }
        subtypes {
          id
          name
        }
        profile {
          id
          name
          firstname
          lastname
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        formation {
          id
          name
          positions {
            id
            positionType {
              id
              shortName
            }
            x
            y
          }
        }
        position {
          id
        }
      }
    }
  }
`;

export default FIXTURE_ROLES;
