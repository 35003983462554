// V1
import { Fragment, useState } from "react";
import "./ManageEventInfo.css";
import Card from "../../Card";
import ToggleState from "../../ToggleState";
import Spinner from "../../Spinner";
import { EVENT, UPDATE_EVENT } from "../../../api/event";
import { useMutation, useQuery } from "@apollo/client";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import moment from "moment";
import Loader from "../../Loader";
import captureException from "../../../utils/captureException";

function InfoGroup({ label, value, type, setIndex }) {
  if (type === "vertical") {
    return (
      <Fragment>
        <div className={"info-group column"}>
          <div className="info-group-key">{label}</div>

          <div
            className="info-group-edit-btn"
            onClick={() => {
              setIndex(1);
            }}
          >
            <PencilIcon className="icon" />
          </div>
        </div>
        <div className="info-group-value">{value}</div>
      </Fragment>
    );
  }
  return (
    <div className={"info-group"}>
      <div className="info-group-key">{label}</div>
      <div className="info-group-value">{value}</div>
      <div
        className="info-group-edit-btn"
        onClick={() => {
          setIndex(1);
        }}
      >
        <PencilIcon className="icon" />
      </div>
    </div>
  );
}

function InfoGroupForm({
  id,
  type = "text",
  defaultValue,
  label,
  setIndex,
  vars,
  selectionSet,
}) {
  const [value, setValue] = useState(defaultValue);
  const [updateEvent, { loading }] = useMutation(UPDATE_EVENT(selectionSet), {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventInfo.InfoGroupForm",
          mutation: "UPDATE_EVENT",
        },
      });
      console.log(error);
    },
  });
  return (
    <form
      className="info-group-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (loading) return;

        updateEvent({
          variables: {
            where: { id },
            data: vars(value),
          },
        });
        setIndex(0);
      }}
    >
      <div className="info-group-form-label">Edit {label}</div>
      <div className="info-group-form-body">
        <div className="info-group-form-input">
          {type === "textarea" ? (
            <textarea
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          ) : (
            <input
              type={type}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
            />
          )}
        </div>
        {loading ? (
          <div className="options">
            <div
              style={{
                width: "64px",
                height: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader theme={"blue small"} />
            </div>
          </div>
        ) : (
          <div className="options">
            <button type={"submit"} className="submit">
              <CheckIcon className="icon" style={{ fill: "white" }} />
            </button>
            <button
              onClick={() => {
                setIndex(0);
              }}
              className="cancel"
            >
              <TimesIcon className="icon" />
            </button>
          </div>
        )}
      </div>
    </form>
  );
}

function ManageEventInfo({ id }) {
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventInfo",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;

  return (
    <div>
      <Card className="manage-event-info">
        <div className="card-title">Event Info</div>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Name"}
                    value={ev.name}
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"text"}
                    defaultValue={ev.name}
                    label={"name"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({ name: val })}
                    selectionSet={`name`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Start Date"}
                    value={
                      ev.scheduledStart
                        ? moment(ev.scheduledStart).format("Do MMM YYYY")
                        : "-"
                    }
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"date"}
                    defaultValue={
                      ev.scheduledStart
                        ? moment(ev.scheduledStart).format("YYYY-MM-DD")
                        : ""
                    }
                    label={"start date"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({
                      scheduledStart: moment(val).toISOString(),
                    })}
                    selectionSet={`scheduledStart`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"End Date"}
                    value={
                      ev.scheduledEnd
                        ? moment(ev.scheduledEnd).format("Do MMM YYYY")
                        : "-"
                    }
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"date"}
                    defaultValue={
                      ev.scheduledEnd
                        ? moment(ev.scheduledEnd).format("YYYY-MM-DD")
                        : ""
                    }
                    label={"end date"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({
                      scheduledEnd: moment(val).toISOString(),
                    })}
                    selectionSet={`scheduledEnd`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Registration Start Date"}
                    value={
                      ev.registrationStart
                        ? moment(ev.registrationStart).format("Do MMM YYYY")
                        : "-"
                    }
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"date"}
                    defaultValue={
                      ev.registrationStart
                        ? moment(ev.registrationStart).format("YYYY-MM-DD")
                        : ""
                    }
                    label={"registration start date"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({
                      registrationStart: moment(val)
                        .startOf("day")
                        .toISOString(),
                    })}
                    selectionSet={`registrationStart`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Registration End Date"}
                    value={
                      ev.registrationEnd
                        ? moment(ev.registrationEnd).format("Do MMM YYYY")
                        : "-"
                    }
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"date"}
                    defaultValue={
                      ev.registrationEnd
                        ? moment(ev.registrationEnd).format("YYYY-MM-DD")
                        : ""
                    }
                    label={"registration end date"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => {
                      // console.log("Raw end date:", val);
                      // console.log("Processed date:", moment(val).toISOString());
                      // console.log(
                      //   "Processed end date:",
                      //   moment(val).endOf("day").toISOString()
                      // );
                      return {
                        registrationEnd: moment(val).endOf("day").toISOString(),
                      };
                    }}
                    selectionSet={`registrationEnd`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Registration Amount"}
                    value={ev.registrationAmount || "-"}
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"number"}
                    defaultValue={ev.registrationAmount || ""}
                    label={"registration amount"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({ registrationAmount: Number(val) })}
                    selectionSet={`registrationAmount`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Sport"}
                    value={ev.sport?.name || "-"}
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"select"}
                    defaultValue={ev.sport}
                    label={"sport"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({ sport: { connect: { id: val } } })}
                    selectionSet={`sport{id name}`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <InfoGroup
                    label={"Description"}
                    value={ev.description || "-"}
                    type="vertical"
                    setIndex={setIndex}
                  />
                );
              case 1:
                return (
                  <InfoGroupForm
                    type={"textarea"}
                    defaultValue={ev.description}
                    label={"description"}
                    setIndex={setIndex}
                    id={id}
                    vars={(val) => ({ description: val })}
                    selectionSet={`description`}
                  />
                );
              default:
                return <div />;
            }
          }}
        </ToggleState>
      </Card>
    </div>
  );
}

export default ManageEventInfo;
