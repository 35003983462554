import { gql } from "@apollo/client";

const ORGANIZATION_EVENTS = gql`
  query organizationQuery(
    $id: ID!
    $take: Int
    $filters: OrganizationEventFilters
    $cursor: ID
  ) {
    organization(id: $id) {
      id
      events(take: $take, filters: $filters, cursor: $cursor) {
        id
        name
        venueLabel
        scheduledStart
        description
        sport {
          id
          name
        }
        city {
          id
          value
        }
        profilePhoto {
          id
          filename
        }
        coverPhoto {
          id
          filename
        }
        registrationsCount
        organizedBy {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

export default ORGANIZATION_EVENTS;
