// V!
import "./EventResultsSummary.css";
import Card from "../../Card";
import FixtureListItem from "../../FixtureListItem";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { EVENT_FIXTURES } from "../../../api/event";
import Placeholder from "../../Placeholder/Placeholder";
import { useHistory, useLocation } from "react-router-dom";
import captureException from "../../../utils/captureException";
import { EVENT } from "../../../api/functions";

function EventResultsSummary({ id }) {
  const history = useHistory();
  const location = useLocation();
  // const { loading, error, data, refetch } = useQuery(EVENT_FIXTURES, {
  //   variables: { id, take: 4, filters: { completed: true }, orderBy: "desc" },
  // });

  const { loading, error, data, refetch } = useQuery(
    EVENT({
      selectionSet: `    
      fixtures(
        take:4,
        filters:{
          completed:true
        },
        orderBy:"desc"
      ){
        id
        createdAt
        scheduledStart
        actualStart
        totalLegs
        legs {
          id
        }
        roles {
          id
          order
          type {
            id
            name
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }        
        placements(where: { type: { name: "RESULT" } }) {
          id
          order
          organization {
            id
          }
          profile {
            id
          }
          value
          valueString
        }
        badmintonBoxScore {
          id
          label
          homeScore
          awayScore
        }
      }
    `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventResultsSummary",
        query: "EVENT_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="event-results-summary">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="list">
          {[1, 2].map((i) => (
            <div
              key={"er-placefolder-" + i}
              className="calendar-fixture-list-item"
            >
              <Card className="fixture-list-item">
                <div className="participants">
                  {[1, 2].map((p) => (
                    <div key={"er-pp-" + p} className="participant">
                      <div className="image"></div>
                      <div className="name">
                        <Placeholder
                          style={{ height: "12px", width: "80px" }}
                          pulse={true}
                        />
                      </div>
                      <div className="score">
                        <Placeholder
                          style={{ height: "12px", width: "20px" }}
                          pulse={true}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="datetime"></div>
              </Card>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const fixtures = data.event?.fixtures || [];

  return (
    <Card className="event-results-summary">
      <div className="card-title">Results</div>
      <div className="list">
        {!fixtures?.length && (
          <div className="no-data">
            <div className="text">No results for this event yet</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        )}
        {fixtures.slice(0, 4).map((fixture) => (
          <div
            key={"fixture-" + fixture.id}
            className="calendar-fixture-list-item"
          >
            <Link to={"/app/fixture/" + fixture.id}>
              <FixtureListItem fixture={fixture} />
            </Link>
          </div>
        ))}
      </div>
      {!!fixtures?.length && (
        <div className="event-results-summary-footer">
          <div
            className=""
            onClick={() => {
              history.replace(location.pathname + "?t=fixtures");
            }}
          >
            See all fixtures
          </div>
        </div>
      )}
    </Card>
  );
}

export default EventResultsSummary;
