import "./ManageEventTeams.css";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../../../components/Spinner";
import {
  EVENT_PARTICIPANTS,
  EVENT_SUGGESTED_PARTICIPANTS,
  UPDATE_EVENT,
  EVENT_ROLES,
} from "../../../api/event";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import Card from "../../../components/Card";
import { useAppState } from "../../../utils/appState";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import AddEventOrgRoles from "../../../components/Scoring/AddEventOrgRoles";

function ParticipantItem({ id, role, status }) {
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`
    participants {
      id
      organization {
        id
        name
        profilePhoto{
          id
          filename
        }
      }
    }
    suggestedParticipants {
      id
      name
      profilePhoto{
        id
        filename
      }
    }
  `),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventTeams.ParticipantItem",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="manage-event-teams-mobile__list__item">
      <div className="manage-event-teams-mobile__list__item__image">
        {role.organization.profilePhoto && (
          <Avatar media={role.organization.profilePhoto} />
        )}
      </div>

      <div className="manage-event-teams-mobile__list__item__text">
        <div className="manage-event-teams-mobile__list__item__text__primary">
          {role.organization.name}
        </div>

        <div
          className={
            "manage-event-teams-mobile__list__item__text__secondary " +
            (status ? "" : "orange")
          }
        >
          {status ? "Confirmed" : "Requested"}
        </div>
      </div>

      {status ? (
        <div
          className="manage-event-teams-mobile__list__item__action undo"
          onClick={() => {
            if (loading) return;
            updateEvent({
              variables: {
                where: { id },
                data: {
                  roles: {
                    deleteMany: [{ id: role.id }],
                  },
                },
              },
            });
          }}
        >
          Undo
        </div>
      ) : (
        <div
          className="manage-event-teams-mobile__list__item__action confirm"
          onClick={() => {
            if (loading) return;
            updateEvent({
              variables: {
                where: { id },
                data: {
                  roles: {
                    create: {
                      type: { connect: { name: "PARTICIPANT" } },
                      organization: { connect: { id: role.organization.id } },
                    },
                  },
                },
              },
            });
          }}
        >
          Confirm
        </div>
      )}
    </div>
  );
}

function SuggestedParticipantsList({ id, confirmed }) {
  const { loading, error, data } = useQuery(EVENT_SUGGESTED_PARTICIPANTS, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventTeams.SuggestedParticipantList",
        query: "EVENT_SUGGESTED_PARTICIPANTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const participants = ev?.suggestedParticipants || [];

  if (!participants?.length) {
    return <div />;
  }

  // console.log("Participants:", participants);
  return (
    <div className="manage-event-teams-mobile__list">
      {participants
        .filter((p) => !confirmed.includes(p.id))
        .map((organization) => (
          <ParticipantItem
            key={"suggested-participant-" + organization.id}
            id={id}
            role={{ organization }}
            status={false}
          />
          // <div className="manage-event-teams-mobile__list__item">
          //   <div className="manage-event-teams-mobile__list__item__image">
          //     {organization.profilePhoto && (
          //       <img src={makeFileUrl(organization.profilePhoto)} />
          //     )}
          //   </div>

          //   <div className="manage-event-teams-mobile__list__item__text">
          //     <div className="manage-event-teams-mobile__list__item__text__primary">
          //       {organization.name}
          //     </div>

          //     <div className="manage-event-teams-mobile__list__item__text__secondary orange">
          //       Requested
          //     </div>
          //   </div>

          //   <div className="manage-event-teams-mobile__list__item__action confirm">
          //     Confirm
          //   </div>
          // </div>
        ))}
    </div>
  );
}

function EventOrgRoles({ id, participants }) {
  const [, setAppState] = useAppState();
  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
        roles {
          id
          description
          type {
            id
            name
          }        
          profile {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          organization {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          relationshipsTo{
            id
            from{                
                id
                type {
                    id
                    name
                }        
                profile {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
                organization {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
            }
          }
        }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        setAppState({ modal: false });
        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );
  const { loading, error, data, refetch } = useQuery(EVENT_ROLES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventRoles",
        query: "EVENT_ROLES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const roles = ev?.roles?.filter((r) =>
    ["TECHNICAL STAFF"].includes(r.type.name)
  );

  console.log(roles);

  const groupedRoles = roles.reduce((accum, curr) => {
    const groupIndex = accum.findIndex(
      (x) =>
        x.organization?.id === curr.relationshipsTo?.[0]?.from?.organization?.id
    );

    if (groupIndex < 0) {
      accum.push({
        organization: curr.relationshipsTo?.[0]?.from?.organization,
        roles: [curr],
      });
    } else {
      accum[groupIndex].roles.push(curr);
    }

    return accum;
  }, []);

  return (
    <Card className={"manage-event-participants__roles"}>
      <div className="card-title">
        Team Roles
        <div
          className={"manage-event-participants__roles__add-btn"}
          onClick={() => {
            setAppState({
              modal: (
                <div className="modal-form-mobile-wrapper">
                  <AddEventOrgRoles ev={ev} participants={participants} />
                </div>
              ),
            });
          }}
        >
          Add
        </div>
      </div>

      <div className="manage-event-participants__roles__list">
        {groupedRoles.map((group) => (
          <div className="manage-event-participants__roles__list__group">
            <div className="manage-event-participants__roles__list__group__title">
              {group.organization?.name}
            </div>
            <div className="manage-event-participants__roles__list__group__items">
              {group?.roles?.map((r) => (
                <div
                  className="manage-event-participants__roles__list__item"
                  key={"venue-roles-" + r.id}
                >
                  <div className="manage-event-participants__roles__list__item__image">
                    {(r.profile?.profilePhoto ||
                      r.organization?.profilePhoto) && (
                      <Avatar
                        media={
                          r.profile?.profilePhoto ||
                          r.organization?.profilePhoto
                        }
                      />
                    )}
                  </div>
                  <div className="manage-event-participants__roles__list__item__content">
                    <div className="manage-event-participants__roles__list__item__content__primary">
                      {r.profile?.name || r.organization?.name}{" "}
                      {!r.confirmed && "(Request sent)"}
                    </div>
                    <div className="manage-event-participants__roles__list__item__content__secondary">
                      {r.description}
                    </div>
                  </div>
                  <div className="manage-event-participants__roles__list__item__options">
                    <div
                      className="manage-event-participants__roles__list__item__options__delete"
                      onClick={() => {
                        if (submitting) return;
                        updateEvent({
                          variables: {
                            where: { id: ev.id },
                            data: {
                              roles: {
                                deleteMany: [{ id: r.id }],
                              },
                            },
                          },
                        });
                      }}
                    >
                      <TrashIcon
                        style={{ stroke: "var(--red-main)", height: "14px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default function ManageEventTeams({ id }) {
  const { loading, error, data } = useQuery(EVENT_PARTICIPANTS, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventTeams",
        query: "EVENT_PARTICIPANTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const participants = ev?.participants || [];

  return (
    <div className="manage-event-teams-mobile">
      <div className="manage-event-teams-mobile__card">
        <div className="manage-event-teams-mobile__title">Teams</div>
        <div className="manage-event-teams-mobile__list">
          {participants.map((role) => (
            <ParticipantItem
              key={"epm-" + role.id}
              id={id}
              role={role}
              status={true}
            />
            // <div className="manage-event-teams-mobile__list__item">
            //   <div className="manage-event-teams-mobile__list__item__image">
            //     {role.organization.profilePhoto && (
            //       <img src={makeFileUrl(role.organization.profilePhoto)} />
            //     )}
            //   </div>

            //   <div className="manage-event-teams-mobile__list__item__text">
            //     <div className="manage-event-teams-mobile__list__item__text__primary">
            //       {role.organization.name}
            //     </div>

            //     <div className="manage-event-teams-mobile__list__item__text__secondary">
            //       Confirmed
            //     </div>
            //   </div>

            //   <div className="manage-event-teams-mobile__list__item__action undo">
            //     Undo
            //   </div>
            // </div>
          ))}
        </div>

        <SuggestedParticipantsList
          id={id}
          confirmed={participants.map((p) => p.organization.id)}
        />
      </div>

      {ev.participantType === "TEAM" && (
        <EventOrgRoles id={id} participants={participants} />
      )}
    </div>
  );
}
