import { useState, Fragment } from "react";
import "./ForgotPassword.css";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { useHistory } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../api/auth";
import { useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

// function Submitted() {
//   return (
//     <div className="forgot-password-submitted">
//       A link to reset your password has been sent to your registered email.
//     </div>
//   );
// }

function ForgotPassword() {
  usePageTracking({ title: "Forgot Password" });
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const history = useHistory();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    update: (cache, { data: { forgotPassword } }) => {
      if (forgotPassword.success) {
        setEmailSent(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ForgotPassword",
          mutation: "FORGOT_PASSWORD",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="auth-page">
      <div className="form-container">
        {/* <div className="logo">
          <img src={"/logo.svg"} />
        </div> */}

        <div className="auth-page-title">
          {" "}
          <div className="logo">
            <img src={"/logo.svg"} alt="" />
          </div>
          Forgot password?
        </div>
        {!emailSent ? (
          <Fragment>
            <div className="auth-page-secondary-title">
              Enter your email below to reset your password
            </div>
            <form
              onSubmit={(e) => {
                // console.log("Form submitted");
                e.preventDefault();
                if (!email) {
                  setInvalidEmail(true);
                  return;
                }

                forgotPassword({
                  variables: {
                    email,
                  },
                });
              }}
              className="auth-form"
            >
              <div className="form-group">
                <input
                  type="email"
                  id="forgot-password-email"
                  value={email}
                  className={
                    "input " +
                    (email ? "hasValue " : " ") +
                    (invalidEmail ? "invalid" : "")
                  }
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidEmail(false);
                  }}
                />
                <label for="xyz">
                  Email <span className="input-required-indicator">*</span>
                </label>
                <div className="static-icon">
                  <MailIcon style={{ fill: "var(--light-4)", height: 14 }} />
                </div>
              </div>

              <button
                type="submit"
                className="app-button primary large green submit"
              >
                {loading ? <Loader /> : "Submit"}
              </button>

              <div className="terms">
                <span
                  className="text-link hover-pointer"
                  onClick={() => {
                    history.push("/auth#login");
                  }}
                >
                  Back to login page
                </span>
              </div>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <div className="auth-page-secondary-title">
              A link to reset your password has been sent to your registered
              email address.
            </div>
            <div className="auth-form">
              <div className="terms">
                <span
                  className="text-link hover-pointer"
                  onClick={() => {
                    history.push("/auth#login");
                  }}
                >
                  Back to login page
                </span>
              </div>
            </div>
          </Fragment>
        )}
      </div>

      <div className="auth-carousel">
        <div className="carousel-content-wrapper"></div>
      </div>
    </div>
  );
}

export default ForgotPassword;
