import { gql } from "@apollo/client";

const CAN_MANAGE_EVENT = gql`
  query canManageEventQuery($id: ID!) {
    canManageEvent(id: $id) {
      authorized
    }
  }
`;

export default CAN_MANAGE_EVENT;
