import { gql } from "@apollo/client";

const AWARD_TYPES = gql`
  query awardTypes($where: AwardTypeWhereInput) {
    awardTypes(where: $where) {
      id
      name
      subtype
    }
  }
`;

export default AWARD_TYPES;
