import { useCricketScoringState } from "../utils/useCricketScoringState";
import "./ErrorNotification.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";

export default function ErrorNotification({ title, message }) {
  const [{ setOverlay }] = useCricketScoringState();
  const dismiss = () => {
    setOverlay(false);
  };
  return (
    <div
      className="ScoringApp-cricket__scoring__modal-form"
      style={{ height: "auto" }}
    >
      <div
        className="ScoringApp-cricket__scoring__modal-form__title"
        style={{
          background: "var(--red-main)",
        }}
      >
        {title}
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={dismiss}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__error-msg">
        {message}
      </div>
    </div>
  );
}
