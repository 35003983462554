import { gql } from "@apollo/client";

const CLAIM_PROFILE = gql`
  mutation claimProfile($profileId: ID!) {
    claimProfile(profileId: $profileId) {
      id
    }
  }
`;

export default CLAIM_PROFILE;
