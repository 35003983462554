import "./BadmintonStats.css";
import Spinner from "../../../Spinner";
import BoxScore from "./BoxScore";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../../../api/fixture";
import StatsSummary from "./StatsSummary";
import PointsTimeline from "./PointsTimeline";

export default function BadmintonStats() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FIXTURE, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;

  const homeParticipant = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => !p.order);

  const awayParticipant = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .find((p) => p.order);

  const periods = [...(data?.fixture?.periods || [])].sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <div className="fixture-badminton-stats">
      <div className="fixture-badminton-stats__column">
        <BoxScore
          periods={periods}
          fixtureId={id}
          homePlayer={homeParticipant}
          awayPlayer={awayParticipant}
        />
        <StatsSummary
          fixtureId={id}
          homeParticipant={homeParticipant}
          awayParticipant={awayParticipant}
        />
      </div>

      <div className="fixture-badminton-stats__column">
        <PointsTimeline
          fixtureId={id}
          periods={periods}
          homeParticipant={homeParticipant}
          awayParticipant={awayParticipant}
        />
      </div>
    </div>
  );
}
