import { useRef, useState } from "react";
import "./FootballStats.css";
import Card from "../../../Card";
import { ReactComponent as ChevronDown } from "../../../../icons/chevron-down.svg";
import { ReactComponent as PlayIcon } from "../../../../icons/play.svg";
import { FIXTURE, FIXTURE_STATS } from "../../../../api/fixture";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../Spinner";
import AppLink from "../../../AppLink";
import Tooltip from "../../../Tooltip";
import NewDropdown from "../../../NewDropdown";
import Avatar from "../../../Avatar";
import useElementDimensions from "../../../../utils/useElementDimensions";

const tableCells = [
  // {
  //   label: "Player",
  //   tooltip: "Player",
  //   width: "16%",
  //   style: { width: "16%" },
  //   data: (row) => row.profile.name,
  //   onClick: (row) => "/app/profile/" + row.profile.id,
  // },
  {
    label: "Minutes",
    groups: ["Attack"],
    tooltip: "Minutes Played",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Minutes Played"] || 0,
  },
  {
    label: "Goal",
    groups: ["Attack"],
    tooltip: "Goal",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Goal"] || 0,
  },
  {
    label: "Assist",
    groups: ["Passing"],
    tooltip: "Assist",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Assist"] || 0,
  },
  {
    label: "Attempted",
    groups: ["Passing"],
    tooltip: "Attempted Passes",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Pass Attempted"] || 0,
  },
  {
    label: "Successful",
    groups: ["Passing"],
    tooltip: "Successful Passes",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Pass Completed"] || 0,
  },
  {
    label: "Accuracy",
    groups: ["Passing"],
    tooltip: "Pass Accuracy",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) =>
      row["Pass Completed"]
        ? Math.round((row["Pass Completed"] / row["Pass Attempted"]) * 100) +
          "%"
        : 0,
  },
  {
    label: "Key Pass",
    groups: ["Attack"],
    tooltip: "Key Pass",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Key Pass"] || 0,
  },
  {
    label: "Shot",
    groups: ["Attack"],
    tooltip: "Total Shots",
    width: "10%",
    widthPercent: 10,
    style: { width: "12.5%", textAlign: "right" },
    data: (row) => (row["Shot On Target"] || 0) + (row["Shot Off Target"] || 0),
  },
  {
    label: "Shot on Target",
    groups: ["Attack"],
    tooltip: "Shot on Target",
    width: "20%",
    widthPercent: 20,
    style: { width: "17.5%", textAlign: "right" },
    data: (row) => row["Shot On Target"] || 0,
  },

  {
    label: "Tackle",
    groups: ["Defense"],
    tooltip: "Tackle",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Tackle"] || 0,
  },
  {
    label: "Block",
    groups: ["Defense"],
    tooltip: "Block",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Block"] || 0,
  },
  {
    label: "Own Goal",
    groups: ["Defense"],
    tooltip: "Own Goal",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Own Goal"] || 0,
  },
  {
    label: "Interception",
    groups: ["Defense"],
    tooltip: "Interception",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Interception"] || 0,
  },
  {
    label: "Clearance",
    groups: ["Defense"],
    tooltip: "Clearance",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Clearance"] || 0,
  },
  {
    label: "Save",
    groups: ["Goalkeeping"],
    tooltip: "Save",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Save"] || 0,
  },
  {
    label: "Clean Sheet",
    groups: ["Goalkeeping"],
    tooltip: "Clean Sheet",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Clean Sheet"] || 0,
  },
  {
    label: "Offside",
    groups: ["Discipline"],
    tooltip: "Offside",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Offside"] || 0,
  },
  {
    label: "Foul",
    groups: ["Discipline"],
    tooltip: "Foul",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Foul"] || 0,
  },
  {
    label: "Yellow Card",
    groups: ["Discipline"],
    tooltip: "Yellow Card",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Yellow Card"] || 0,
  },
  {
    label: "Red Card",
    groups: ["Discipline"],
    tooltip: "Red Card",
    width: "15%",
    widthPercent: 15,
    style: { width: "15%", textAlign: "right" },
    data: (row) => row["Red Card"] || 0,
  },
];

function MatchStats({
  homeStats = {},
  awayStats = {},
  homeTeam,
  awayTeam,
  uploaded,
}) {
  const successfulHomePasses = homeStats.successfulPasses || 0;
  const successfulAwayPasses = awayStats.successfulPasses || 0;
  const attemptedHomePasses = homeStats.attemptedPasses || 0;
  const attemptedAwayPasses = awayStats.attemptedPasses || 0;
  return (
    <div className="fixture-stats-page__football__match-stats">
      <Card className="match-stats">
        <div className="card-title">Match Stats</div>
        <div className="match-stats__teams">
          <div className="match-stats__teams__team home">
            <div className="match-stats__teams__team__image">
              {homeTeam?.organization?.profilePhoto && (
                <Avatar media={homeTeam.organization.profilePhoto} />
              )}
            </div>
            <div className="match-stats__teams__team__name">
              {homeTeam.organization.name}
            </div>
          </div>

          <div className="match-stats__teams__team away">
            <div className="match-stats__teams__team__name">
              {awayTeam.organization.name}
            </div>
            <div className="match-stats__teams__team__image">
              {awayTeam?.organization?.profilePhoto && (
                <Avatar media={awayTeam.organization.profilePhoto} />
              )}
            </div>
          </div>
        </div>
        <div className="match-stats-list">
          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.fouls || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.fouls / (homeStats.fouls + awayStats.fouls) ||
                        0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.fouls > awayStats.fouls
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Fouls</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.fouls / (homeStats.fouls + awayStats.fouls) ||
                        0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.fouls > homeStats.fouls
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.fouls || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.yellowCards || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.yellowCards /
                        (homeStats.yellowCards + awayStats.yellowCards) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.yellowCards > awayStats.yellowCards
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Yellow Cards</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.yellowCards /
                        (homeStats.yellowCards + awayStats.yellowCards) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.yellowCards > homeStats.yellowCards
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.yellowCards || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.redCards || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.redCards /
                        (homeStats.redCards + awayStats.redCards) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.redCards > awayStats.redCards
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Red Cards</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.redCards /
                        (homeStats.redCards + awayStats.redCards) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.redCards > homeStats.redCards
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.redCards || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.offsides || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.offsides /
                        (homeStats.offside + awayStats.offsides) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.offsides > awayStats.offsides
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Offsides</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.offsides /
                        (homeStats.offsides + awayStats.offsides) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.offsides > homeStats.offsides
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.offsides || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.saves || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.saves / (homeStats.saves + awayStats.saves) ||
                        0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.saves < awayStats.saves
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Saves</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.saves / (homeStats.saves + awayStats.saves) ||
                        0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.saves < homeStats.saves
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.saves || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.tackles || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.tackles /
                        (homeStats.tackles + awayStats.tackles) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.tackles < awayStats.tackles
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Tackles</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.tackles /
                        (homeStats.tackles + awayStats.tackles) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.tackles < homeStats.tackles
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.tackles || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.interceptions || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.interceptions /
                        (homeStats.interceptions + awayStats.interceptions) ||
                        0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.tackles < awayStats.tackles
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Interceptions</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.interceptions /
                        (homeStats.interceptions + awayStats.interceptions) ||
                        0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.tackles < homeStats.tackles
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.interceptions || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.blocks || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.blocks /
                        (homeStats.blocks + awayStats.blocks) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.blocks < awayStats.blocks
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Blocks</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.blocks /
                        (homeStats.blocks + awayStats.blocks) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.blocks < homeStats.blocks
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.blocks || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.clearances || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.clearances /
                        (homeStats.clearances + awayStats.clearances) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.blocks < awayStats.blocks
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Clearances</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.clearances /
                        (homeStats.clearances + awayStats.clearances) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.blocks < homeStats.blocks
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.clearances || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.keyPasses || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.keyPasses /
                        (homeStats.keyPasses + awayStats.keyPasses) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.keyPasses < awayStats.keyPasses
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Key Passes</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.keyPasses /
                        (homeStats.keyPasses + awayStats.keyPasses) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.keyPasses < homeStats.keyPasses
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.keyPasses || 0}</div>
          </div>

          <div className="match-stats-list-item">
            <div className="home-value">{homeStats.corners || 0}</div>
            <div className="home-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (homeStats.corners /
                        (homeStats.corners + awayStats.corners) || 0) *
                        100 +
                      "%",
                    // background:
                    //   homeStats.corners < awayStats.corners
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="desc">Corners</div>
            <div className="away-bar">
              <div className="bar-container">
                <div
                  className="fill"
                  style={{
                    width:
                      (awayStats.corners /
                        (homeStats.corners + awayStats.corners) || 0) *
                        100 +
                      "%",
                    // background:
                    //   awayStats.corners < homeStats.corners
                    //     ? "var(--red-lighter)"
                    //     : "var(--green-lighter)",
                  }}
                />
              </div>
            </div>
            <div className="away-value">{awayStats.corners || 0}</div>
          </div>

          {!uploaded && (
            <div className="match-stats-list-item">
              <div className="home-value">{successfulHomePasses || 0}</div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (successfulHomePasses /
                          (successfulHomePasses + successfulAwayPasses) || 0) *
                          100 +
                        "%",
                      // background:
                      //   homeStats.corners < awayStats.corners
                      //     ? "var(--red-lighter)"
                      //     : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Total Passes</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (successfulAwayPasses /
                          (successfulHomePasses + successfulAwayPasses) || 0) *
                          100 +
                        "%",
                      // background:
                      //   awayStats.corners < homeStats.corners
                      //     ? "var(--red-lighter)"
                      //     : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">{successfulAwayPasses || 0}</div>
            </div>
          )}

          {!uploaded && (
            <div className="match-stats-list-item">
              <div className="home-value">
                {successfulHomePasses
                  ? Math.round(
                      (successfulHomePasses / attemptedHomePasses) * 100
                    ) + "%"
                  : "-%"}
              </div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (successfulHomePasses / attemptedHomePasses || 0) *
                          100 +
                        "%",
                      // background:
                      //   homeStats.corners < awayStats.corners
                      //     ? "var(--red-lighter)"
                      //     : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Pass Accuracy</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        (successfulAwayPasses / attemptedAwayPasses || 0) *
                          100 +
                        "%",
                      // background:
                      //   awayStats.corners < homeStats.corners
                      //     ? "var(--red-lighter)"
                      //     : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">
                {successfulAwayPasses
                  ? Math.round(
                      (successfulAwayPasses / attemptedAwayPasses) * 100
                    ) + "%"
                  : "-%"}
              </div>
            </div>
          )}

          {!uploaded && (
            <div className="match-stats-list-item">
              <div className="home-value">
                {successfulHomePasses + successfulAwayPasses
                  ? Math.round(
                      (successfulHomePasses /
                        (successfulHomePasses + successfulAwayPasses)) *
                        100
                    ) + "%"
                  : "-%"}
              </div>
              <div className="home-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        successfulHomePasses + successfulAwayPasses
                          ? Math.round(
                              (successfulHomePasses /
                                (successfulHomePasses + successfulAwayPasses)) *
                                100
                            ) + "%"
                          : "0%",
                      // background:
                      //   homeStats.corners < awayStats.corners
                      //     ? "var(--red-lighter)"
                      //     : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="desc">Possession</div>
              <div className="away-bar">
                <div className="bar-container">
                  <div
                    className="fill"
                    style={{
                      width:
                        successfulHomePasses + successfulAwayPasses
                          ? Math.round(
                              (successfulAwayPasses /
                                (successfulHomePasses + successfulAwayPasses)) *
                                100
                            ) + "%"
                          : "0%",
                      // background:
                      //   awayStats.corners < homeStats.corners
                      //     ? "var(--red-lighter)"
                      //     : "var(--green-lighter)",
                    }}
                  />
                </div>
              </div>
              <div className="away-value">
                {successfulHomePasses + successfulAwayPasses
                  ? Math.round(
                      (successfulAwayPasses /
                        (successfulHomePasses + successfulAwayPasses)) *
                        100
                    ) + "%"
                  : "-%"}
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

function ShotsSummary({ homeTeam, awayTeam, homeStats, awayStats }) {
  const homeShots = homeStats.totalShots || 0;
  const awayShots = awayStats.totalShots || 0;
  const totalShots = homeShots + awayShots;
  const homeShotsOnTarget = homeStats.shotsOnTarget || 0;
  const awayShotsOnTarget = awayStats.shotsOnTarget || 0;

  const homeTotalShotsWidth = totalShots ? (homeShots / totalShots) * 100 : 0;
  const awayTotalShotsWidth = totalShots ? (awayShots / totalShots) * 100 : 0;

  const homeShotsOnTargetWidth = totalShots
    ? (homeShotsOnTarget / totalShots) * 100
    : 0;
  const awayShotsOnTargetWidth = totalShots
    ? (awayShotsOnTarget / totalShots) * 100
    : 0;

  const homeTotalShotsHeight =
    homeShots > awayShots
      ? 100
      : homeShots === awayShots
      ? 100
      : (homeShots / awayShots) * 100;
  const awayTotalShotsHeight =
    awayShots > homeShots
      ? 100
      : homeShots === awayShots
      ? 100
      : (awayShots / homeShots) * 100;

  const homeShotsOnTargetHeight = homeShots
    ? (homeShotsOnTarget / homeShots) * homeTotalShotsHeight
    : 0;
  const awayShotsOnTargetHeight = awayShots
    ? (awayShotsOnTarget / awayShots) * awayTotalShotsHeight
    : 0;

  return (
    <Card className="shots-summary">
      <div className="card-title">
        <div className="card-title__team home">
          <div className="card-title__team__image">
            {homeTeam?.organization?.profilePhoto && (
              <Avatar media={homeTeam.organization.profilePhoto} />
            )}
          </div>
          <div className="card-title__team__name">
            {homeTeam.organization.name}
          </div>
        </div>
        <div className="card-title__label"> Shots (on Goal)</div>
        <div className="card-title__team away">
          <div className="card-title__team__name">
            {awayTeam.organization.name}
          </div>
          <div className="card-title__team__image">
            {awayTeam?.organization?.profilePhoto && (
              <Avatar media={awayTeam.organization.profilePhoto} />
            )}
          </div>
        </div>
      </div>
      {/* <div className="match-stats__teams">
        <div className="match-stats__teams__team home">
          <div className="match-stats__teams__team__image">
            {homeTeam?.organization?.profilePhoto && (
              <img src={makeFileUrl(homeTeam.organization.profilePhoto)} />
            )}
          </div>
          <div className="match-stats__teams__team__name">
            {homeTeam.organization.name}
          </div>
        </div>

        <div className="match-stats__teams__team away">
          <div className="match-stats__teams__team__name">
            {awayTeam.organization.name}
          </div>
          <div className="match-stats__teams__team__image">
            {awayTeam?.organization?.profilePhoto && (
              <img src={makeFileUrl(awayTeam.organization.profilePhoto)} />
            )}
          </div>
        </div>
      </div> */}

      <div className="shots-summary__body">
        <div className="shots-summary__body__section home">
          <div className="shots-summary__body__section__title">
            <div className="shots-summary__body__section__title__value">
              {homeShots} ({homeShotsOnTarget})
            </div>
          </div>
          <div className="shots-summary__body__section__graphic">
            <div
              className="shots-summary__body__section__graphic__total-shots"
              style={{
                height: homeTotalShotsHeight + "%",
                width: homeTotalShotsWidth + "%",
              }}
            ></div>
            <div
              className="shots-summary__body__section__graphic__shots-on-goal"
              style={{
                height: homeShotsOnTargetHeight + "%",
                width: homeShotsOnTargetWidth + "%",
              }}
            ></div>
          </div>
        </div>

        <div className="shots-summary__body__section away">
          <div className="shots-summary__body__section__title">
            <div className="shots-summary__body__section__title__value">
              {awayShots} ({awayShotsOnTarget})
            </div>
          </div>
          <div className="shots-summary__body__section__graphic">
            <div
              className="shots-summary__body__section__graphic__total-shots"
              style={{
                height: awayTotalShotsHeight + "%",
                width: awayTotalShotsWidth + "%",
              }}
            ></div>
            <div
              className="shots-summary__body__section__graphic__shots-on-goal"
              style={{
                height: awayShotsOnTargetHeight + "%",
                width: awayShotsOnTargetWidth + "%",
              }}
            ></div>
          </div>
        </div>
      </div>
    </Card>
  );
}

function PlayerStats({ fixture, homeTeam, awayTeam, homeLineup, awayLineup }) {
  // console.log("PLAYER STATS FIXTURE:", fixture);
  const categories = [...fixture.event?.sport?.statTypeGroups].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const [selectedTeam, setSelectedTeam] = useState(homeTeam);
  const [selectedCategory, setSelectedCategory] = useState(categories?.[0]);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef();
  const tableDimensions = useElementDimensions(tableRef);

  const rows = fixture.statTables;

  const teamRows = rows
    .filter((r) => r.organization?.id === selectedTeam.organization?.id)
    .reduce((accum, curr) => {
      const playerRowIndex = accum.findIndex(
        (x) => x.profile?.id === curr.profile?.id
      );
      if (playerRowIndex < 0) {
        accum.push({
          profile: curr.profile,
          [curr.statType.name]: curr.value || 0,
        });
      } else {
        accum[playerRowIndex][curr.statType.name] = curr.value || 0;
      }
      return accum;
    }, []);

  // const awayTeamRows = rows
  //   .filter((r) => r.organization?.id === awayTeam.organization?.id)
  //   .reduce((accum, curr) => {
  //     const playerRowIndex = accum.findIndex(
  //       (x) => x.profile.id === curr.profile.id
  //     );
  //     if (playerRowIndex < 0) {
  //       accum.push({
  //         profile: curr.profile,
  //         [curr.statType.name]: curr.value || 0,
  //       });
  //     } else {
  //       accum[playerRowIndex][curr.statType.name] = curr.value || 0;
  //     }
  //     return accum;
  //   }, []);

  const playersWithNoStats = (
    selectedTeam.id === homeTeam.id ? homeLineup : awayLineup
  ).filter((l) => !teamRows.find((r) => r.profile?.id === l.profile?.id));
  // const awayPlayersWithNoStats = awayLineup.filter(
  //   (l) => !awayTeamRows.find((r) => r.profile?.id === l.profile?.id)
  // );

  const tableRows = [...teamRows, ...playersWithNoStats];
  const sortedTableRows = tableRows?.sort((a, b) => {
    if (sortField === "name") {
      if (sortOrder === "desc") {
        return b.profile?.name.localeCompare(a.profile?.name);
      } else {
        return a.profile?.name.localeCompare(b.profile?.name);
      }
    } else {
      const targetCell = tableCells.find((x) => x.label === sortField);
      if (sortOrder === "desc") {
        return (targetCell?.data(b) || 0) - (targetCell?.data(a) || 0);
      } else {
        return (targetCell?.data(a) || 0) - (targetCell?.data(b) || 0);
      }
    }
  });

  // console.log("Team rows:", sortedTableRows);
  // console.log("Team rows:", playersWithNoStats)

  const selectedTableCells = tableCells
    .filter((cell) => cell.groups.includes(selectedCategory.name))
    .map((c) => ({
      ...c,
      pixelWidth: (c.widthPercent * (tableDimensions?.width || 0)) / 100,
    }));

  const primaryColumnWidth = tableDimensions?.width
    ? tableDimensions.width -
      selectedTableCells.reduce((accum, curr) => {
        accum = accum + curr.pixelWidth;
        return accum;
      }, 0)
    : 0;

  return (
    <Card className="fixture-stats-page__player-stats">
      <div className="fixture-stats-page__player-stats__header" ref={tableRef}>
        <NewDropdown
          target={
            <div className="fixture-stats-page__player-stats__header__team">
              <div className="fixture-stats-page__player-stats__header__team__image">
                {selectedTeam?.organization?.profilePhoto && (
                  <Avatar media={selectedTeam.organization.profilePhoto} />
                )}
              </div>

              <div className="fixture-stats-page__player-stats__header__team__name">
                {selectedTeam.organization?.name}
              </div>

              <ChevronDown
                style={{ stroke: "var(--dark-2)", height: "14px" }}
              />
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="fixture-stats-page__player-stats__header__team__menu">
              <div
                className="fixture-stats-page__player-stats__header__team__menu__item"
                onClick={() => {
                  setSelectedTeam(homeTeam);
                  setShowMenu(false);
                }}
              >
                {homeTeam?.organization?.name}
              </div>
              <div
                className="fixture-stats-page__player-stats__header__team__menu__item"
                onClick={() => {
                  setSelectedTeam(awayTeam);
                  setShowMenu(false);
                }}
              >
                {awayTeam?.organization?.name}
              </div>
            </Card>
          )}
        />

        <NewDropdown
          wrapperClass="fixture-stats-page__player-stats__header__category__wrapper"
          target={
            <div className="fixture-stats-page__player-stats__header__category">
              {selectedCategory?.name}
              <ChevronDown
                style={{ stroke: "var(--dark-2)", height: "14px" }}
              />
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="fixture-stats-page__player-stats__header__category__menu">
              {categories
                .filter((c) =>
                  [
                    "Attack",
                    "Passing",
                    "Defense",
                    "Discipline",
                    "Goalkeeping",
                  ].includes(c.name)
                )
                .map((category) => (
                  <div
                    className="fixture-stats-page__player-stats__header__category__menu__item"
                    onClick={() => {
                      setSelectedCategory(category);
                      setShowMenu(false);
                    }}
                  >
                    {category.name}
                  </div>
                ))}
            </Card>
          )}
        />
      </div>
      <div className="fixture-stats-page__player-stats__table">
        <div className="fixture-stats-page__player-stats__table__header">
          <div className="table-stats__row">
            <div
              className="table-stats__row__data hover-pointer"
              style={{ width: primaryColumnWidth + "px" }}
              onClick={() => {
                setSortField("name");
                setSortOrder((prev) => {
                  if (prev === "asc") {
                    return "desc";
                  } else {
                    return "asc";
                  }
                });
              }}
            >
              Player{" "}
              {sortField === "name" ? (
                <PlayIcon
                  style={{
                    transform:
                      sortOrder === "asc" ? "rotate(-90deg)" : "rotate(90deg)",
                    fill: "var(--blue-lighter)",
                    height: "10px",
                  }}
                />
              ) : (
                ""
              )}
            </div>
            {selectedTableCells.map((cell) => (
              <div
                className="table-stats__row__data hover-pointer"
                style={{
                  ...cell.style,
                  flexShrink: 0,
                  width: cell.pixelWidth + "px",
                }}
                onClick={() => {
                  setSortField(cell.label);
                  setSortOrder((prev) => {
                    if (prev === "asc") {
                      return "desc";
                    } else {
                      return "asc";
                    }
                  });
                }}
              >
                <Tooltip text={cell.tooltip} className="text-clamp">
                  {cell.label}{" "}
                  {sortField === cell.label ? (
                    <PlayIcon
                      style={{
                        transform:
                          sortOrder === "asc"
                            ? "rotate(-90deg)"
                            : "rotate(90deg)",
                        fill: "var(--blue-lighter)",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Tooltip>
              </div>
            ))}
          </div>
        </div>

        <div className="fixture-stats-page__player-stats__table__body">
          {sortedTableRows?.map((row, i) => (
            <div
              className="table-stats__row"
              style={{ ...(i % 2 ? { background: "var(--light-2)" } : {}) }}
            >
              <div
                className="table-stats__row__data hover-pointer"
                style={{ width: primaryColumnWidth + "px" }}
                onClick={() => history.push("/app/profile/" + row.profile?.id)}
              >
                {row.profile?.name}
              </div>
              {selectedTableCells.map((cell) => (
                <div
                  className="table-stats__row__data"
                  style={{
                    ...cell.style,
                    flexShrink: 0,
                    width: cell.pixelWidth + "px",
                  }}
                >
                  {cell.onClick ? (
                    <AppLink to={cell.onClick(row)}>
                      {cell.data ? cell.data(row) : ""}
                    </AppLink>
                  ) : cell.data ? (
                    <Tooltip text={cell.tooltip}>
                      <span
                        style={{
                          ...(!cell.data(row)
                            ? { opacity: 0.2 }
                            : { fontWeight: 700 }),
                        }}
                      >
                        {cell.data(row) || 0}
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ))}

          {/* {playersWithNoStats.map((row) => (
            <div className="table-stats__row">
              <div
                className="table-stats__row__data"
                style={{ width: "25%" }}
                onClick={(row) => "/app/profile/" + row.profile.id}
              >
                {row.profile.name}
              </div>
              {tableCells
                .filter((cell) => cell.groups.includes(selectedCategory.name))
                .map((cell) => (
                  <div
                    className="table-stats__row__data"
                    style={{
                      ...cell.style,
                      ...(!cell.data(row)
                        ? { opacity: 0.2 }
                        : { fontWeight: 700 }),
                    }}
                  >
                    {cell.onClick ? (
                      <AppLink to={cell.onClick(row)}>
                        {cell.data ? cell.data(row) : ""}
                      </AppLink>
                    ) : cell.data ? (
                      cell.data(row) || 0
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
          ))} */}
        </div>
      </div>
    </Card>
  );
}

function FootballStatsContent({
  id,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
}) {
  // console.log(id, homeTeam, awayTeam);
  const { loading, error, data } = useQuery(FIXTURE_STATS, {
    variables: { id },
  });

  if (error) {
    console.log(error);

    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const homeStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === homeTeam.organization.id
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === awayTeam.organization.id
  );

  // console.log(homeStats);
  // console.log(awayStats);

  return (
    <div className="fixture-stats-page">
      <div className="left-column">
        <MatchStats
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeStats={homeStats}
          awayStats={awayStats}
          uploaded={data?.fixture?.uploaded}
        />

        <ShotsSummary
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeStats={homeStats}
          awayStats={awayStats}
        />
      </div>
      <div className="right-column">
        <PlayerStats
          fixture={data.fixture}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeLineup={homeLineup}
          awayLineup={awayLineup}
        />
      </div>

      {/* <TableStats
        fixture={data.fixture}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homeLineup={homeLineup}
        awayLineup={awayLineup}
      /> */}
    </div>
  );
}

function FootballStats() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FIXTURE, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;

  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[0]?.organization?.id
  );

  const awayLineup = fixture.roles.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === participants[1]?.organization?.id
  );

  // console.log("Table Fixture:", fixture);

  return (
    <FootballStatsContent
      id={id}
      homeTeam={participants[0]}
      awayTeam={participants[1]}
      homeLineup={homeLineup}
      awayLineup={awayLineup}
    />
  );
}

export default FootballStats;
