import "./PhotoBrowser.css";
import { useState, Fragment } from "react";
import { useAppState } from "../../utils/appState";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right.svg";
import { ReactComponent as ClapIcon } from "../../icons/clap.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import makeFileUrl from "../../utils/makeFileUrl";
import { useQuery, useMutation } from "@apollo/client";
import { MEDIA } from "../../api/media";
import { CLAP, UNCLAP } from "../../api/user";
import Spinner from "../../components/Spinner";
import ReactQuill from "react-quill";
import moment from "moment";
import Avatar from "../../components/Avatar";
import captureException from "../../utils/captureException";
import NewDropdown from "../../components/NewDropdown/NewDropdown";
import Card from "../../components/Card";

function PhotoBrowserSidebarOptionsRight({ media }) {
  const source = media.source;
  if (source.fixture) {
    return (
      <Fragment>
        <div className="photo-browser-mobile__sidebar__options__option__text">
          <div className="photo-browser-mobile__sidebar__options__option__text__primary">
            {source.fixture.event.name}
          </div>
          <div className="photo-browser-mobile__sidebar__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="photo-browser-mobile__sidebar__options__option__image">
          {source.fixture.event?.profilePhoto && (
            <Avatar media={source.fixture.event?.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else if (source.event) {
    return (
      <Fragment>
        <div className="photo-browser-mobile__sidebar__options__option__text">
          <div className="app-photo-browser__info__options__option__text__primary">
            {source.event.organizedBy.name}
          </div>

          <div className="photo-browser-mobile__sidebar__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="photo-browser-mobile__sidebar__options__option__image">
          {source.event.organizedBy?.profilePhoto && (
            <Avatar media={source.event.organizedBy.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else if (source.organization) {
    return (
      <Fragment>
        <div className="photo-browser-mobile__sidebar__options__option__text">
          <div className="photo-browser-mobile__sidebar__options__option__text__primary">
            {media.createdByProfile.name}
          </div>
          <div className="photo-browser-mobile__sidebar__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="photo-browser-mobile__sidebar__options__option__image">
          {media.createdByProfile?.profilePhoto && (
            <Avatar media={media.createdByProfile.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else if (source.profile) {
    return (
      <Fragment>
        <div className="photo-browser-mobile__sidebar__options__option__text">
          <div className="photo-browser-mobile__sidebar__options__option__text__primary">
            {source.profile.name}
          </div>
          <div className="photo-browser-mobile__sidebar__options__option__text__secondary">
            {moment(media.createdAt).format("Do MMM YYYY @ h:mm a")}
          </div>
        </div>

        <div className="photo-browser-mobile__sidebar__options__option__image">
          {source.profile?.profilePhoto && (
            <Avatar media={source.profile.profilePhoto} />
          )}
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
}

function PhotoBrowserSidebarSource({ media }) {
  const source = media.source;
  if (source?.fixture) {
    const fixture = source.fixture;
    const participants = fixture.roles
      .filter((r) => r.type.name === "PARTICIPANT")
      .sort((a, b) => a.order - b.order);
    const homeTeam = participants[0];
    const awayTeam = participants[1];
    const homeScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[0]?.organization?.id
    );

    const awayScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[1]?.organization?.id
    );

    return (
      <div className="photo-browser-mobile__sidebar__source">
        <div className="photo-browser-mobile__sidebar__source__fixture">
          <div className="photo-browser-mobile__sidebar__source__fixture__team home">
            <div className="photo-browser-mobile__sidebar__source__fixture__team__image">
              {homeTeam.organization.profilePhoto && (
                <Avatar media={homeTeam.organization.profilePhoto} />
              )}
            </div>

            <div className="photo-browser-mobile__sidebar__source__fixture__team__name">
              {homeTeam.organization.name}
            </div>

            <div className="photo-browser-mobile__sidebar__source__fixture__team__score">
              {homeScore?.value}
            </div>
          </div>
          <div className="photo-browser-mobile__sidebar__source__fixture__seperator">
            -
          </div>
          <div className="photo-browser-mobile__sidebar__source__fixture__team away">
            <div className="photo-browser-mobile__sidebar__source__fixture__team__score">
              {awayScore?.value}
            </div>

            <div className="photo-browser-mobile__sidebar__source__fixture__team__name">
              {awayTeam.organization.name}
            </div>
            <div className="photo-browser-mobile__sidebar__source__fixture__team__image">
              {awayTeam.organization.profilePhoto && (
                <Avatar media={awayTeam.organization.profilePhoto} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (source?.profile) {
    const profile = source.profile;

    return (
      <div className="photo-browser-mobile__sidebar__source">
        <div className="photo-browser-mobile__sidebar__source__row">
          <div className="photo-browser-mobile__sidebar__source__row__image">
            {profile.profilePhoto && <Avatar media={profile.profilePhoto} />}
          </div>

          <div className="photo-browser-mobile__sidebar__source__row__text">
            <div className="photo-browser-mobile__sidebar__source__row__text__primary">
              {profile.name}
            </div>

            <div className="photo-browser-mobile__sidebar__source__row__text__secondary">
              @ {profile.handle}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (source?.organization) {
    const organization = source.organization;
    return (
      <div className="photo-browser-mobile__sidebar__source">
        <div className="photo-browser-mobile__sidebar__source__row">
          <div className="photo-browser-mobile__sidebar__source__row__image">
            {organization.profilePhoto && (
              <Avatar media={organization.profilePhoto} />
            )}
          </div>

          <div className="photo-browser-mobile__sidebar__source__row__text">
            <div className="photo-browser-mobile__sidebar__source__row__text__primary">
              {organization.name}
            </div>

            <div className="photo-browser-mobile__sidebar__source__row__text__secondary">
              @ {organization.handle}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (source?.event) {
    const event = source.event;
    return (
      <div className="photo-browser-mobile__sidebar__source">
        <div className="photo-browser-mobile__sidebar__source__row">
          <div className="photo-browser-mobile__sidebar__source__row__image">
            {event.profilePhoto && <Avatar media={event.profilePhoto} />}
          </div>

          <div className="photo-browser-mobile__sidebar__source__row__text">
            <div className="photo-browser-mobile__sidebar__source__row__text__primary">
              {event.name}
            </div>

            {/* <div className="photo-browser-mobile__sidebar__source__row__text__secondary">
              @ {organization.handle}
              </div> */}
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="photo-browser-mobile__sidebar__source"></div>;
  }
}

function PhotoBrowserSidebarDescription({ media }) {
  const [truncated, setTruncated] = useState(
    (media.contentLength || 0) > 200 ? true : false
  );

  if (!media.description) {
    return <div />;
  }

  return (
    <div
      className={
        "photo-browser-mobile__sidebar__description " +
        (!truncated ? "expanded" : "")
      }
    >
      <ReactQuill
        id={"media-description-" + media.id}
        value={truncated ? media.truncatedDescription : media.description}
        readOnly={true}
        modules={{ toolbar: false }}
      />
      {truncated ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(false);
          }}
        >
          See more
        </div>
      ) : media.contentLength > 200 ? (
        <div
          className="truncate-button"
          onClick={() => {
            setTruncated(true);
          }}
        >
          See less
        </div>
      ) : (
        false
      )}
    </div>
  );
}

function PhotoBrowserSidebarOptions({ media }) {
  const [clapped, setClapped] = useState(media.isClapped);
  const [clapCount, setClapCount] = useState(media.clapCount);
  const [clap, { loading: clapping }] = useMutation(CLAP, {
    update: (cache, { data }) => {
      if (data.clap?.id) {
        setClapped(true);
        setClapCount((prev) => prev + 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PhotoBrowser.PhotoBrowserSidebarOptions",
          mutation: "CLAP",
        },
      });
      console.log(error);
    },
  });
  const [unclap, { loading: unclapping }] = useMutation(UNCLAP, {
    update: (cache, { data }) => {
      if (data.unclap?.id) {
        setClapped(false);
        setClapCount((prev) => prev - 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "PhotoBrowser.PhotoBrowserSidebarOptions",
          mutation: "UNCLAP",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="photo-browser-mobile__sidebar__options">
      <div
        className={
          "photo-browser-mobile__sidebar__options__option " +
          (clapping || unclapping ? "disabled" : "")
        }
        onClick={() => {
          if (clapping || unclapping) {
            return;
          }

          if (clapped) {
            unclap({ variables: { mediaId: media.id } });
          } else {
            clap({ variables: { mediaId: media.id } });
          }
        }}
      >
        <div className="icon">
          <ClapIcon
            style={{
              fill: clapped ? "var(--blue-main)" : "var(--dark-4)",
              height: "20px",
            }}
          />
        </div>
        <div className="value">{clapCount || ""}</div>
      </div>
      <div className={"photo-browser-mobile__sidebar__options__option right "}>
        <PhotoBrowserSidebarOptionsRight media={media} />
      </div>
      <NewDropdown
        target={
          <div className="photo-browser-mobile__sidebar__options__option__more">
            <EllipsisIcon
              style={{
                height: "18px",
                fill: "var(--dark-4)",
                transform: "rotate(90deg)",
              }}
            />
          </div>
        }
        menu={
          <Card className="app-post-card__options-menu">
            <div
              className="app-post-card__options-menu__item"
              style={{ color: "var(--dark-2)" }}
            >
              Report this post
            </div>
          </Card>
        }
      />
    </div>
  );
}

function PhotoBrowserSidebar({ id }) {
  const { loading, error, data } = useQuery(MEDIA, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PhotoBrowser.PhotoBrowserSidebar",
        query: "MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const media = data.media;
  return (
    <div className="photo-browser-mobile__sidebar">
      <PhotoBrowserSidebarSource media={media} />

      <PhotoBrowserSidebarDescription media={media} />
      {/* <PhotoBrowserSidebarTags
  media={media}
  selectedTab={selectedTagTab}
  setSelectedTab={setSelectedTagTab}
/> */}
      <PhotoBrowserSidebarOptions media={media} />
    </div>
  );
}

export default function PhotoBrowser({
  query,
  vars,
  getPhotos = () => [],
  totalPhotos = () => 0,
  onDelete,
  media,
}) {
  const [, setAppState] = useAppState();
  const [selectedMedia, setSelectedMedia] = useState(media);
  const { error, data, fetchMore, variables, networkStatus } = useQuery(query, {
    variables: vars,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PhotoBrowser",
        query: "variable",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  // if (loading || !data) {
  //   return <Spinner />;
  // }

  const mediaList = getPhotos(data);
  const mediaCount = totalPhotos(data);

  const currentMediaIndex = mediaList.findIndex(
    (m) => m?.id === selectedMedia?.id
  );

  // console.log("PASSED MEDIA:", media);

  const imagePadding = media.contentLength ? 200 : 80;

  return (
    <div className="photo-browser-mobile">
      <div
        className="photo-browser-mobile__image"
        style={{ paddingBottom: imagePadding + "px" }}
      >
        {selectedMedia && <img src={makeFileUrl(selectedMedia)} alt="" />}
        <div
          className="photo-browser-mobile__image__close"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="icon" />
        </div>

        {currentMediaIndex > 0 && (
          <div
            className="photo-browser-mobile__image__prev"
            style={{ paddingBottom: imagePadding + "px" }}
            onClick={() => {
              setSelectedMedia(mediaList[currentMediaIndex - 1]);
            }}
          >
            <ChevronLeftIcon style={{ stroke: "var(--light-1)" }} />
          </div>
        )}

        {currentMediaIndex < mediaCount - 1 && (
          <div
            className="photo-browser-mobile__image__next"
            style={{ paddingBottom: imagePadding + "px" }}
            onClick={async () => {
              if (networkStatus !== 7) return;

              if (currentMediaIndex < mediaList.length - 1) {
                setSelectedMedia(mediaList[currentMediaIndex + 1]);
              } else {
                // console.log("start");
                const nextSet = await fetchMore({
                  variables: {
                    ...variables,
                    cursor: mediaList[mediaList.length - 1]?.id,
                  },
                });
                // console.log("Nextset:", nextSet);
                // console.log("end");
                setSelectedMedia(getPhotos(nextSet.data)?.[0]);
              }
            }}
          >
            <ChevronRightIcon style={{ stroke: "var(--light-1)" }} />
          </div>
        )}
      </div>
      <PhotoBrowserSidebar id={selectedMedia?.id} />
    </div>
  );
}
