import "./TabSelector.css";
import { useState } from "react";

function TabSelector({ children }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  return (
    <div className="app-tab-selector">
      {children(selectedTabIndex, setSelectedTabIndex)}
    </div>
  );
}

export default TabSelector;
