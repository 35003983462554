// V1
import "./ManageEventParticipants.css";
import Card from "../../Card";
import FilterInput from "../../FilterInput";
import ToggleState from "../../ToggleState";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../Spinner";
import {
  EVENT_PARTICIPANTS,
  UPDATE_EVENT,
  EVENT_SUGGESTED_PARTICIPANTS,
  EVENT_ROLES,
} from "../../../api/event";
import { Fragment, useState } from "react";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";
import { useAppState } from "../../../utils/appState";
import AddEventOrgRoles from "../AddEventOrgRoles";

function ParticipantTypeForm({ id, setIndex }) {
  const [participantType, setParticipantType] = useState("");
  const [updateEvent] = useMutation(
    UPDATE_EVENT(`
    participantType
    `),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventParticipants.ParticipantTypeForm",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );
  return (
    <form
      className="participant-type-form"
      onSubmit={(e) => {
        e.preventDefault();
        updateEvent({
          variables: {
            where: { id },
            data: {
              participantType,
            },
          },
          update: () => {
            setIndex(0);
          },
        });
      }}
    >
      <div className="form-group-input">
        <FilterInput
          placeholder={"Select participant type"}
          items={[{ name: "TEAM" }, { name: "INDIVIDUAL" }]}
          onItemSelect={(item) => {
            setParticipantType(item.name);
          }}
          clearOnItemSelect={false}
        />
      </div>

      <div className="options">
        <button className="btn submit" type="submit">
          <CheckIcon className="icon" />
        </button>
        <button
          className="btn cancel"
          onClick={() => {
            setIndex(0);
          }}
        >
          <TimesIcon className="icon" />
        </button>
      </div>
    </form>
  );
}

function ParticipantItem({ id, role, status }) {
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`
    participants {
      id
      organization {
        id
        name
        profilePhoto{
          id
          filename
        }
      }
    }
    suggestedParticipants {
      id
      name
      profilePhoto{
        id
        filename
      }
    }
  `),
    {
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventParticipants.ParticipantItem",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="event-participant">
      <div className="image">
        {role.organization.profilePhoto && (
          <Avatar media={role.organization.profilePhoto} />
        )}
      </div>
      <div className="text">
        <div className="primary-text">{role.organization.name}</div>
        <div className={"secondary-text " + (status ? "green" : "red")}>
          {status ? "Confirmed" : "Pending"}
        </div>
      </div>
      <div className="options">
        {status ? (
          <div
            className="btn"
            onClick={() => {
              if (loading) return;
              updateEvent({
                variables: {
                  where: { id },
                  data: {
                    roles: {
                      deleteMany: [{ id: role.id }],
                    },
                  },
                },
              });
            }}
          >
            Undo
          </div>
        ) : (
          <div
            className="btn success"
            onClick={() => {
              if (loading) return;
              updateEvent({
                variables: {
                  where: { id },
                  data: {
                    roles: {
                      create: {
                        type: { connect: { name: "PARTICIPANT" } },
                        organization: { connect: { id: role.organization.id } },
                      },
                    },
                  },
                },
              });
            }}
          >
            Confirm
          </div>
        )}
      </div>
    </div>
  );
}

function EventOrgRoles({ id, participants }) {
  const [, setAppState] = useAppState();
  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
        roles {
          id
          description
          type {
            id
            name
          }        
          profile {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          organization {
            id
            name
            handle
                profilePhoto {
                  id
                  filename
                }
          }
          relationshipsTo{
            id
            from{                
                id
                type {
                    id
                    name
                }        
                profile {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
                organization {
                    id
                    name
                    handle
                        profilePhoto {
                        id
                        filename
                        }
                }
            }
          }
        }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        setAppState({ modal: false });
        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );
  const { loading, error, data, refetch } = useQuery(EVENT_ROLES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventRoles",
        query: "EVENT_ROLES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const roles = ev?.roles?.filter((r) =>
    ["TECHNICAL STAFF"].includes(r.type.name)
  );

  console.log(roles);

  const groupedRoles = roles.reduce((accum, curr) => {
    const groupIndex = accum.findIndex(
      (x) =>
        x.organization?.id === curr.relationshipsTo?.[0]?.from?.organization?.id
    );

    if (groupIndex < 0) {
      accum.push({
        organization: curr.relationshipsTo?.[0]?.from?.organization,
        roles: [curr],
      });
    } else {
      accum[groupIndex].roles.push(curr);
    }

    return accum;
  }, []);

  return (
    <Card className={"manage-event-participants__roles"}>
      <div className="card-title">
        Team Roles
        <div
          className={"manage-event-participants__roles__add-btn"}
          onClick={() => {
            setAppState({
              modal: <AddEventOrgRoles ev={ev} participants={participants} />,
            });
          }}
        >
          Add
        </div>
      </div>

      <div className="manage-event-participants__roles__list">
        {groupedRoles.map((group) => (
          <div className="manage-event-participants__roles__list__group">
            <div className="manage-event-participants__roles__list__group__title">
              {group.organization?.name}
            </div>
            <div className="manage-event-participants__roles__list__group__items">
              {group?.roles?.map((r) => (
                <div
                  className="manage-event-participants__roles__list__item"
                  key={"venue-roles-" + r.id}
                >
                  <div className="manage-event-participants__roles__list__item__image">
                    {(r.profile?.profilePhoto ||
                      r.organization?.profilePhoto) && (
                      <Avatar
                        media={
                          r.profile?.profilePhoto ||
                          r.organization?.profilePhoto
                        }
                      />
                    )}
                  </div>
                  <div className="manage-event-participants__roles__list__item__content">
                    <div className="manage-event-participants__roles__list__item__content__primary">
                      {r.profile?.name || r.organization?.name}{" "}
                      {!r.confirmed && "(Request sent)"}
                    </div>
                    <div className="manage-event-participants__roles__list__item__content__secondary">
                      {r.description}
                    </div>
                  </div>
                  <div className="manage-event-participants__roles__list__item__options">
                    <div
                      className="manage-event-participants__roles__list__item__options__delete"
                      onClick={() => {
                        if (submitting) return;
                        updateEvent({
                          variables: {
                            where: { id: ev.id },
                            data: {
                              roles: {
                                deleteMany: [{ id: r.id }],
                              },
                            },
                          },
                        });
                      }}
                    >
                      <TrashIcon
                        style={{ stroke: "var(--red-main)", height: "14px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function SuggestedParticipantsList({ id, confirmed }) {
  const { loading, error, data } = useQuery(EVENT_SUGGESTED_PARTICIPANTS, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventParticipants.SuggestedParticipantsList",
        query: "EVENT_SUGGESTED_PARTICIPANTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const participants = ev?.suggestedParticipants || [];

  if (!participants?.length) {
    return <div />;
  }

  // console.log("Participants:", participants);
  return (
    <div className="suggested-participants-list">
      {participants
        .filter((p) => !confirmed.includes(p.id))
        .map((o) => (
          <ParticipantItem
            key={"suggested-participant-" + o.id}
            id={id}
            role={{ organization: o }}
            status={false}
          />
        ))}
    </div>
  );
}

function ManageEventParticipants({ id }) {
  const { loading, error, data } = useQuery(EVENT_PARTICIPANTS, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventParticipants",
        query: "EVENT_PARTICIPANTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const participants = ev?.participants || [];
  // console.log(participants);
  return (
    <Fragment>
      <Card className="participant-type-card">
        <ToggleState>
          {({ index, setIndex }) => {
            switch (index) {
              case 0:
                return (
                  <div className="info-group">
                    <div className="info-group-label">Participant Type</div>
                    <div className="info-group-value">
                      {ev.participantType || "-"}
                    </div>
                    <div
                      className="edit-btn"
                      onClick={() => {
                        setIndex(1);
                      }}
                    >
                      <PencilIcon className="icon" />
                    </div>
                  </div>
                );
              case 1:
                return <ParticipantTypeForm id={id} setIndex={setIndex} />;
              default:
                return <div />;
            }
          }}
        </ToggleState>

        <div className="participant-type-card__info">
          <div className="participant-type-card__info__icon">
            <InfoIcon />
          </div>
          {ev.participantType === "TEAM"
            ? "Fixtures in this event will be played between teams."
            : ev.participantType === "INDIVIDUAL"
            ? "Fixtures in this event will be played between individuals."
            : "Select whether fixtures in this event will be between teams or individuals."}
        </div>
      </Card>
      {ev.participantType === "TEAM" && (
        <Card className={"manage-event-participants"}>
          <div className="card-title">Teams</div>

          <div className="participants-list">
            {participants.map((r) => (
              <ParticipantItem
                key={"event-participant-" + r.id}
                id={id}
                role={r}
                status={true}
              />
            ))}

            {ev.participantType === "TEAM" && !participants?.length && (
              <div className="manage-event-participants__info">
                <div className="manage-event-participants__info__title">
                  <div className="participant-type-card__info__icon">
                    <InfoIcon />
                  </div>
                  How to add teams
                </div>

                <div className="manage-event-participants__info__description">
                  Sports Paddock users can register for this event as a part of
                  team. These teams will be shown here for your confirmation.
                </div>
              </div>
            )}
          </div>
          <SuggestedParticipantsList
            id={id}
            confirmed={participants.map((p) => p.organization.id)}
          />
        </Card>
      )}

      {ev.participantType === "TEAM" && (
        <EventOrgRoles id={id} participants={participants} />
      )}
    </Fragment>
  );
}

export default ManageEventParticipants;
