// V1
import { useQuery, useMutation } from "@apollo/client";
import {
  EVENT_FIXTURES,
  EVENT_PARTICIPANTS,
  EVENT_REGISTRATIONS,
} from "../../../api/event";
import Spinner from "../../Spinner";
import "./ManageEventFixtures.css";
import Card from "../../Card";
import { ReactComponent as PlayIcon } from "../../../icons/play.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevron-down.svg";
import { Fragment, useState } from "react";
import { UPDATE_EVENT, UPDATE_EVENT_PART } from "../../../api/event";
import {
  CREATE_FIXTURE,
  GENERATE_FIXTURES,
  UPDATE_FIXTURE,
} from "../../../api/fixture";
import moment from "moment";
import { Link } from "react-router-dom";
import NewDropdown from "../../NewDropdown";
import ClickOutside from "../../ClickOutside";
import Loader from "../../Loader";
import SelectWithoutSearch from "../../SelectWithoutSearch";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";
import DebouncedButton from "../../Button/DebouncedButton";
import generateFixtures from "../../../utils/generateFixtures";
import { EVENT } from "../../../api/functions";

function determineScore(placements, participant) {
  if (participant.organization) {
    const placement = placements.find(
      (p) => p.organization.id === participant.organization.id
    );
    if (!placement) {
      return "-";
    } else {
      return placement.valueString || placement.value;
    }
  } else if (participant.profile) {
    const placement = placements.find(
      (p) => p.profile.id === participant.profile.id
    );
    if (!placement) {
      return "-";
    } else {
      return placement.valueString || placement.value;
    }
  } else {
    return "-";
  }
}

function EditEventPartOrder({ ev, part, prev, next }) {
  const [updateEventPart, { loading }] = useMutation(UPDATE_EVENT_PART, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventFixtures.EditEventPartOrder",
          mutation: "UPDATE_EVENT_PART",
        },
      });
      console.log(error);
    },
  });

  return (
    <div
      className={
        "manage-event-fixtures-part__header__move-btn " +
        (next ? "right" : "left")
      }
      onClick={() => {
        if (loading) return;

        updateEventPart({
          variables: {
            where: { id: part.id },
            data: {
              order: next ? part.order + 1 : part.order - 1,
            },
          },
        });

        updateEventPart({
          variables: {
            where: { id: next ? next.id : prev.id },
            data: {
              order: next ? next.order - 1 : prev.order + 1,
            },
          },
        });
      }}
    >
      {loading ? <Spinner size={12} /> : <PlayIcon className="icon" />}
    </div>
  );
}

function EditFixtureEventPartMenuItem({ fixture, part, parts, parent }) {
  const subparts = part ? parts.filter((p) => p.partOf?.id === part?.id) : [];
  const [updateFixture, { loading }] = useMutation(UPDATE_FIXTURE, {
    update: (cache, { data: { updateFixture } }) => {
      cache.modify({
        id: cache.identify(fixture),
        fields: {
          eventPart() {
            return updateFixture.eventPart;
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventFixtures.EditFixtureEventPartMenuItem",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="calendar-fixture-list-item__edit-part__menu__title__list__item">
      <div
        className={
          "calendar-fixture-list-item__edit-part__menu__title__list__item__label " +
          (subparts.length ? "disabled" : "")
        }
        onClick={() => {
          if (subparts.length) return;
          if (loading) return;

          updateFixture({
            variables: {
              where: { id: fixture.id },
              data: {
                eventPart: {
                  connect: {
                    id: part.id,
                  },
                },
              },
            },
          });
        }}
      >
        {part.label}
      </div>
      <div className="calendar-fixture-list-item__edit-part__menu__title__list__item__sub-items">
        {subparts.map((p) => (
          <EditFixtureEventPartMenuItem
            key={"move-to-" + fixture.id + p.id}
            part={p}
            parts={parts}
            fixture={fixture}
          />
        ))}
      </div>
    </div>
  );
}

function EditFixtureEventPartMenu({ parts, fixture }) {
  return (
    <Card className="calendar-fixture-list-item__edit-part__menu">
      <div className="calendar-fixture-list-item__edit-part__menu__title">
        Select a stage
      </div>
      <div className="calendar-fixture-list-item__edit-part__menu__title__list">
        {parts
          .filter((p) => !p.partOf?.id)
          .map((p) => (
            <EditFixtureEventPartMenuItem
              key={"move-to-menu-" + fixture.id + p.id}
              part={p}
              parts={parts}
              fixture={fixture}
            />
          ))}
      </div>
    </Card>
  );
}

function EditEventPartLabel({ part, ev, canDelete }) {
  const [editing, setEditing] = useState(false);
  const [label, setLabel] = useState(part.label);
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`
    parts {
      id
      type
      label
      order
      partOf {
        id
      }
    }
  `),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            parts() {
              return updateEvent.parts;
            },
          },
        });

        setEditing(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventFixtures.EditEventPartLabel",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );
  if (editing) {
    return (
      <ClickOutside
        onClickOutside={() => {
          setEditing(false);
        }}
      >
        {(wrapperRef) => {
          return (
            <form
              ref={wrapperRef}
              className="edit-event-part-label-form"
              onSubmit={(e) => {
                e.preventDefault();
                if (loading || !label) return;

                updateEvent({
                  variables: {
                    where: { id: ev.id },
                    data: {
                      parts: {
                        update: {
                          where: { id: part.id },
                          data: { label },
                        },
                      },
                    },
                  },
                });
              }}
            >
              <input
                type="text"
                value={label}
                disabled={loading}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
                autoFocus
              />
            </form>
          );
        }}
      </ClickOutside>
    );
  } else {
    return (
      <div className="part-name">
        {part.label || "Unnamed Stage"}{" "}
        <NewDropdown
          target={
            <div className="manage-event-fixtures-part__header__options">
              <ChevronDownIcon
                style={{ stroke: "var(--blue-lighter)", height: "14px" }}
              />
            </div>
          }
          menu={
            <Card className="manage-event-fixtures-part__header__menu">
              <div
                className="manage-event-fixtures-part__header__menu__item"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Edit
              </div>

              {canDelete && <DeleteEventPart ev={ev} part={part} />}

              {/* <div className="manage-event-fixtures-part__header__menu__item">
                Delete
              </div> */}
            </Card>
          }
        />
      </div>
    );
  }
}

function EditFixtureEventPart({ parts, fixture }) {
  const [form, setForm] = useState(false);

  if (form) {
    return <EditFixtureEventPartMenu parts={parts} fixture={fixture} />;
  } else {
    return (
      <div
        className="calendar-fixture-list-item__edit-part"
        onClick={() => {
          setForm(true);
        }}
      >
        <div className="calendar-fixture-list-item__edit-part__target">
          Edit fixture stage
        </div>
      </div>
    );
  }
}

function DeleteEventPart({ ev, part }) {
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(
      `parts {
    id
    type
    label
    order
    partOf {
      id
    }
  }`,
      {
        update: (cache, { data: { updateEvent } }) => {
          cache.modify({
            id: cache.identify(ev),
            fields: {
              parts() {
                return updateEvent.parts;
              },
            },
          });

          // setEditing(false);
        },
        onError: (error) => {
          captureException({
            error,
            info: {
              type: "mutation",
              component: "ManageEventFixtures.DeleteEventPart",
              mutation: "UDPATE_EVENT",
            },
          });
          console.log(error);
        },
      }
    )
  );

  return (
    <div
      className="manage-event-fixtures-part__header__menu__item"
      onClick={() => {
        if (loading) return;
        // console.log("DELETE EVENT ID:", eventId);
        updateEvent({
          variables: {
            where: { id: ev.id },
            data: {
              parts: {
                deleteMany: [{ id: part.id }],
              },
            },
          },
        });
      }}
    >
      Delete {loading && <Spinner size={12} />}
    </div>
  );
}

function AddEventPart({ eventId, order, partOfId, partOf }) {
  const [form, setForm] = useState(false);
  const [name, setName] = useState("");
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(`parts {
    id
    type
    label
    order
    partOf {
      id
    }
  }`),
    {
      update: () => {
        setName("");
        setForm(false);
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventFixtures.AddEventPart",
            mutation: "UDPATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );
  return (
    <Fragment>
      {form ? (
        <div
          className={"manage-event-fixtures-part create"}
          style={partOf ? { marginTop: "12px" } : {}}
        >
          <form
            className="add-event-part-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (loading || !name) return;
              updateEvent({
                variables: {
                  where: { id: eventId },
                  data: {
                    parts: {
                      create: {
                        order: order || 0,
                        type: "Stage",
                        label: name,
                        ...(partOfId
                          ? { partOf: { connect: { id: partOfId } } }
                          : {}),
                      },
                    },
                  },
                },
              });
            }}
          >
            <input
              type="text"
              placeholder="Enter stage name..."
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            {loading ? (
              <div
                style={{
                  width: "64px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader theme={"small blue"} />
              </div>
            ) : (
              <Fragment>
                <button
                  type="submit"
                  className="add-event-part-form__btn success"
                >
                  <CheckIcon className="icon" />
                </button>
                <button
                  onClick={() => {
                    setForm(false);
                  }}
                  className="add-event-part-form__btn cancel"
                >
                  <TimesIcon className="icon" />
                </button>
              </Fragment>
            )}
          </form>
        </div>
      ) : (
        <div className={"manage-event-fixtures-part"}>
          <div
            className={"add-part-btn " + (partOf ? "" : "header")}
            onClick={() => {
              setForm(true);
            }}
          >
            Add stage
            {partOf ? " to " + partOf.label : ""}
          </div>
        </div>
      )}
    </Fragment>
  );
}

function GenerateFixturesForm({ id, type = "league", dismiss, refetch }) {
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
      participantType
      participants {
        id
        organization {
          id          
        }
        profile {
          id          
        }
      }
      registrations{
        id
        profile {
          id          
        }
        organization {
          id          
        }
      }

    `,
    }),
    { variables: { id } }
  );

  const [mutate, { loading: updating }] = useMutation(GENERATE_FIXTURES, {
    onError: (err) => {
      console.log(err);
    },
    update: (cache, { data: { generateFixtures } }) => {
      if (generateFixtures.success) {
        console.log("Refetching");
        refetch({ id });
      }
    },
  });

  const [meetings, setMeetings] = useState(1);
  const [groups, setGroups] = useState(2);
  const [qualifiers, setQualifiers] = useState(2);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const ev = data?.event;
  const participantType = ev?.participantType;
  const participants =
    participantType === "TEAM" ? ev.participants : ev.registrations;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (updating) return;
        const generated = generateFixtures({
          type,
          participants,
          meetings: Number(meetings),
          groups: Number(groups),
          qualifiers: Number(qualifiers),
        });
        console.log(generated);
        // return;
        mutate({
          variables: {
            eventId: id,
            rounds: generated.rounds.map((round) => ({
              order: round.order,
              type: round.type,
              label: round.label,
              parent: round.parent,
            })),
            fixtures: generated.fixtures.map((fixture) => ({
              round: fixture.round,
              label: fixture.label,
            })),
            roles: generated.roles.map((role) => ({
              order: role.order,
              fixture: role.fixture,
              type: role.type,
              ...(role.organization
                ? { organizationId: role.organization.id }
                : {}),
              ...(role.profile ? { profileId: role.profile.id } : {}),
            })),
          },
        });
      }}
      className="generate-fixtures-form"
    >
      <div className="generate-fixtures-form__title">
        Generate {type} fixtures
      </div>

      <div className="generate-fixtures-form__body">
        {type === "league" && (
          <div className="generate-fixtures-form__body__form-group">
            <div className="generate-fixtures-form__body__form-group__label">
              How many times will teams play each other?
            </div>
            <div className="generate-fixtures-form__body__form-group__input">
              <input
                type="number"
                value={meetings}
                onChange={(e) => {
                  setMeetings(e.target.value);
                }}
                min={1}
                max={2}
              />
            </div>
          </div>
        )}

        {type === "league-cup" && (
          <Fragment>
            <div className="generate-fixtures-form__body__form-group">
              <div className="generate-fixtures-form__body__form-group__label">
                How many groups in the group stage?
              </div>
              <div className="generate-fixtures-form__body__form-group__input">
                <input
                  type="number"
                  value={groups}
                  onChange={(e) => {
                    setGroups(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="generate-fixtures-form__body__form-group">
              <div className="generate-fixtures-form__body__form-group__label">
                How many times will teams play each other in each group?
              </div>
              <div className="generate-fixtures-form__body__form-group__input">
                <input
                  type="number"
                  value={meetings}
                  onChange={(e) => {
                    setMeetings(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="generate-fixtures-form__body__form-group">
              <div className="generate-fixtures-form__body__form-group__label">
                How many teams qualify from each group to the knockout phase?
              </div>
              <div className="generate-fixtures-form__body__form-group__input">
                <input
                  type="number"
                  value={qualifiers}
                  onChange={(e) => {
                    setQualifiers(e.target.value);
                  }}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>

      <div className="generate-fixtures-form__footer">
        <DebouncedButton
          type="submit"
          className="app-button primary medium green submit"
          loading={updating}
          // style={errorMessage ? { marginTop: "24px" } : {}}
        >
          {updating ? <Loader theme="small" /> : "Generate fixtures"}
        </DebouncedButton>
        {/* <button
          className="app-button generate-fixtures-form__footer_option success"
          type={"submit"}
        >
          Generate Fixtures!
        </button> */}
        <div
          className="generate-fixtures-form__footer_option cancel"
          onClick={dismiss}
        >
          Cancel
        </div>
      </div>
    </form>
  );
}

function GenerateFixtures({ id, refetch }) {
  const [form, setForm] = useState(false);

  return form ? (
    <GenerateFixturesForm
      id={id}
      type={form}
      dismiss={() => {
        setForm(false);
      }}
      refetch={refetch}
    />
  ) : (
    <div className="generate-fixtures">
      <div className="generate-fixtures__title">Generate Fixtures</div>
      <div className="generate-fixtures__section">
        <div className="generate-fixtures__section__title">
          Choose from one of the below formats to automatically generate
          fixtures for an entire season. You can also use this as a template and
          add, remove or edit the generated fixtures.
        </div>

        <div className="generate-fixtures__section__options">
          <Card
            className="generate-fixtures__section__options__item"
            onClick={() => {
              setForm("league");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="generate-fixtures__section__options__item__title">
              Round Robin
            </div>

            <div className="generate-fixtures__section__options__item__description">
              Traditional league type competition. eg. Premier League, La Liga
            </div>
          </Card>

          <Card
            className="generate-fixtures__section__options__item"
            onClick={() => {
              // setForm("single-elimination");
            }}
            style={{ opacity: 0.5 }}
          >
            <div className="generate-fixtures__section__options__item__title">
              Cup
            </div>

            <div className="generate-fixtures__section__options__item__description">
              Single elimination cup tournament eg. FA Cup, Wimbledon
            </div>
          </Card>

          <Card
            className="generate-fixtures__section__options__item"
            onClick={() => {
              // setForm("league-cup");
            }}
            style={{ opacity: 0.5 }}
          >
            <div className="generate-fixtures__section__options__item__title">
              League Cup
            </div>

            <div className="generate-fixtures__section__options__item__description">
              Group stages followed by a knockout stage. eg. Champions League
            </div>
          </Card>

          {/* <Card className="generate-fixtures__section__options__item">
            <div className="generate-fixtures__section__options__item__title">
              Custom
            </div>

            <div className="generate-fixtures__section__options__item__description">
              Add stages, groups, rounds and fixtures manually.
            </div>
          </Card> */}
        </div>
      </div>
    </div>
  );
}

function AddFixture({ eventId, participantType, part }) {
  const [form, setForm] = useState(false);
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [scheduledStart, setScheduledStart] = useState("");

  const [createFixture, { loading }] = useMutation(CREATE_FIXTURE, {
    update: (cache, { data: { createFixture } }) => {
      const q = cache.readQuery({
        query: EVENT_FIXTURES,
        variables: { id: eventId },
      });

      cache.writeQuery({
        query: EVENT_FIXTURES,
        variables: { id: eventId },
        data: {
          event: {
            ...q.event,
            fixtures: [...q.event.fixtures, createFixture],
          },
        },
      });

      setForm(false);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventFixtures.AddFixture",
          mutation: "CREATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  if (form) {
    return (
      <Card className="add-fixture-form-container">
        <form
          className="add-fixture-form"
          onSubmit={(e) => {
            e.preventDefault();

            if (loading) return;

            if (!homeTeam || !awayTeam) return;

            createFixture({
              variables: {
                data: {
                  event: { connect: { id: eventId } },
                  eventPart: { connect: { id: part.id } },
                  ...(scheduledStart
                    ? { scheduledStart: moment(scheduledStart).toISOString() }
                    : {}),
                  periods: {
                    create: [
                      {
                        order: 0,
                        label: "First Half",
                      },
                      {
                        order: 1,
                        label: "Second Half",
                      },
                    ],
                  },
                  roles: {
                    create: [
                      {
                        order: 0,
                        type: { connect: { name: "PARTICIPANT" } },
                        ...(participantType === "TEAM"
                          ? { organization: { connect: { id: homeTeam.id } } }
                          : { profile: { connect: { id: homeTeam.id } } }),
                      },
                      {
                        order: 1,
                        type: { connect: { name: "PARTICIPANT" } },
                        ...(participantType === "TEAM"
                          ? { organization: { connect: { id: awayTeam.id } } }
                          : { profile: { connect: { id: awayTeam.id } } }),
                      },
                    ],
                  },
                },
              },
            });
          }}
        >
          <div className="add-fixture-form__title">
            Add fixture to {part.label}
          </div>
          <div className="add-fixture-form__form-group">
            <SelectWithoutSearch
              placeholder={
                participantType === "TEAM"
                  ? "Select home team"
                  : "Select player"
              }
              items={(d) => {
                if (participantType === "TEAM") {
                  return (
                    d?.event?.participants?.map((p) => p.organization) || []
                  );
                } else {
                  return d?.event?.registrations?.map((p) => p.profile) || [];
                }
              }}
              query={
                participantType === "TEAM"
                  ? EVENT_PARTICIPANTS
                  : EVENT_REGISTRATIONS
              }
              variables={{ id: eventId }}
              onItemSelect={(item) => {
                setHomeTeam(item);
              }}
              clearOnItemSelect={false}
            />
          </div>

          <div className="add-fixture-form__form-group">
            <SelectWithoutSearch
              placeholder={
                participantType === "TEAM"
                  ? "Select away team"
                  : "Select player"
              }
              items={(d) => {
                if (participantType === "TEAM") {
                  return (
                    d?.event?.participants?.map((p) => p.organization) || []
                  );
                } else {
                  return d?.event?.registrations?.map((p) => p.profile) || [];
                }
              }}
              query={
                participantType === "TEAM"
                  ? EVENT_PARTICIPANTS
                  : EVENT_REGISTRATIONS
              }
              variables={{ id: eventId }}
              onItemSelect={(item) => {
                setAwayTeam(item);
              }}
              clearOnItemSelect={false}
            />
          </div>

          <div className="add-fixture-form__form-group">
            <input
              placeholder={"Enter start date"}
              type="datetime-local"
              className="start-date-input"
              value={scheduledStart}
              onChange={(e) => {
                setScheduledStart(e.target.value);
              }}
            />
          </div>

          <div className="add-fixture-form__footer">
            <DebouncedButton
              type="submit"
              className="add-fixture-form__footer__btn success"
              loading={loading}
            >
              {loading ? <Loader theme={"small"} /> : "SAVE"}
            </DebouncedButton>
            {/* <button
              type="submit"
              className="add-fixture-form__footer__btn success"
            ></button> */}

            <button
              onClick={(e) => {
                setForm(false);
              }}
              className="add-fixture-form__footer__btn cancel"
            >
              CANCEL
            </button>
          </div>
        </form>
      </Card>
    );
  }
  return (
    <div
      className="add-fixture-btn"
      onClick={() => {
        setForm(true);
      }}
    >
      Add Fixture
    </div>
  );
}

function FixtureScore({ placements, role }) {
  // console.log("PROPS:", placements, role);
  const score = determineScore(placements, role);
  // console.log("Placement:", placement);
  return <div className="score">{score}</div>;
}

function EventPart({
  eventId,
  ev,
  part,
  parts,
  fixtures = [],
  parentId,
  participantType,
  prev,
  next,
}) {
  const subparts = part
    ? parts
        .filter((p) => p.partOf?.id === part?.id)
        .sort((a, b) => a.order - b.order)
    : [];
  const f = !part
    ? fixtures
    : fixtures.filter((f) => f?.eventPart?.id === part?.id);

  // console.log(
  //   "event part f:",
  //   f[0]?.roles
  //     ?.filter((r) => r.type.name === "PARTICIPANT")
  //     .sort((a, b) => a.order - b.order),
  //   f[0]?.placements
  // );
  return (
    <div className="manage-event-fixtures-part">
      <div
        className="manage-event-fixtures-part__header"
        style={parentId !== part.id ? { marginTop: "12px" } : {}}
      >
        {prev ? (
          <EditEventPartOrder ev={ev} part={part} prev={prev} />
        ) : (
          <div className="manage-event-fixtures-part__header__move-btn" />
        )}

        {/* <div className="manage-event-fixtures-part__header__move-btn left">
          <PlayIcon style={{ fill: "var(--blue-lightest)", height: "12px" }} />
        </div> */}
        <EditEventPartLabel
          part={part}
          ev={ev}
          canDelete={!f.length && !subparts.length}
        />
        {/* <div className="part-name">
          {part.label || "Unnamed Stage"}{" "}
          <NewDropdown
            target={
              <div className="manage-event-fixtures-part__header__options">
                <ChevronDownIcon
                  style={{ stroke: "var(--blue-lighter)", height: "14px" }}
                />
              </div>
            }
            menu={
              <Card className="manage-event-fixtures-part__header__menu">
                <div className="manage-event-fixtures-part__header__menu__item">
                  Edit
                </div>

                <div className="manage-event-fixtures-part__header__menu__item">
                  Delete
                </div>
              </Card>
            }
          />
        </div> */}
        {next ? (
          <EditEventPartOrder ev={ev} part={part} next={next} />
        ) : (
          <div className="manage-event-fixtures-part__header__move-btn " />
        )}

        {/* <div className="manage-event-fixtures-part__header__move-btn right">
          <PlayIcon style={{ fill: "var(--blue-lightest)", height: "12px" }} />
        </div> */}
      </div>

      <div className="subparts">
        {!!subparts.length &&
          subparts.map((subpart, i) => (
            <EventPart
              key={subpart.id}
              eventId={eventId}
              ev={ev}
              part={subpart}
              parts={parts}
              fixtures={fixtures}
              parentId={part.id}
              participantType={participantType}
              prev={!!i && subparts[i - 1]}
              next={i < subparts.length - 1 && subparts[i + 1]}
            />
          ))}
        {!f.length && (
          <AddEventPart
            eventId={eventId}
            order={subparts.length}
            partOfId={parentId}
            partOf={part}
          />
        )}
      </div>
      <div className="manage-event-fixtures-part__options">
        {/* {!f.length && !subparts.length && (
          <DeleteEventPart eventId={eventId} part={part} />
        )} */}

        {!subparts.length && part && (
          <AddFixture
            participantType={participantType}
            eventId={eventId}
            part={part}
          />
        )}

        {/* {!f.length && (
          <AddEventPart
            eventId={eventId}
            order={subparts.length}
            partOfId={parentId}
            partOf={part}
          />
        )} */}

        {/* <AddEventPart
          eventId={eventId}
          order={subparts.length}
          partOfId={parentId}
          partOf={part}
        /> */}
      </div>

      <div className="fixtures-list">
        {f.map((fixture) => (
          <Card key={fixture.id} className="calendar-fixture-list-item">
            <div className="calendar-fixture-list-item__wrapper">
              <div className="participants">
                {fixture.roles
                  .filter((r) => r.type.name === "PARTICIPANT")
                  .sort((a, b) => a.order - b.order)
                  .map((r) => (
                    <Link
                      key={r.id}
                      to={"/app/fixture/" + fixture.id}
                      className="participant"
                    >
                      <div className="image">
                        {r.organization?.profilePhoto ? (
                          <Avatar media={r.organization.profilePhoto} />
                        ) : r.profile?.profilePhoto ? (
                          <Avatar media={r.profile.profilePhoto} />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="name">
                        {r.organization?.name || r.profile?.name || "TBD"}
                      </div>
                      <FixtureScore placements={fixture.placements} role={r} />
                    </Link>
                  ))}
              </div>

              <div className="datetime">
                <div className="date">
                  {fixture.scheduledStart
                    ? moment(fixture.scheduledStart).format("DD/MM")
                    : "-"}
                </div>
              </div>
            </div>

            <EditFixtureEventPart parts={parts} fixture={fixture} />
          </Card>
        ))}
      </div>
    </div>
  );
}

function ManageEventFixtures({ id }) {
  const { loading, error, data, refetch } = useQuery(EVENT_FIXTURES, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventFixtures",
        query: "EVENT_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const parts = ev?.parts || [];
  const fixtures = ev?.fixtures || [];
  const sortedParts = parts
    .filter((p) => !p.partOf)
    .sort((a, b) => a.order - b.order);

  // console.log(ev, parts);
  // console.log("Event fixtures:", fixtures);
  return (
    <div className="manage-event-fixtures">
      {/* <div className="manage-event-fixtures-filters">FILTERS HERE</div> */}
      <div
        className={
          "manage-event-fixtures-list " + (parts.length ? "expanded" : "")
        }
      >
        {sortedParts.map((part, i) => (
          <EventPart
            key={part.id}
            eventId={id}
            ev={ev}
            part={part}
            parts={parts}
            fixtures={fixtures}
            parentId={part.id}
            participantType={ev.participantType}
            prev={!!i && sortedParts[i - 1]}
            next={i < sortedParts.length - 1 && sortedParts[i + 1]}
          />
        ))}

        {fixtures.find((f) => !f.eventPart) && (
          <EventPart
            eventId={id}
            ev={ev}
            part={false}
            parts={parts}
            fixtures={fixtures.filter((f) => !f.eventPart)}
            parentId={false}
            participantType={ev.participantType}
          />
        )}
        <AddEventPart expanded={true} eventId={id} order={parts.length} />
      </div>
      {!parts.length && !fixtures.length && (
        <Card className="manage-event-fixtures-form-container">
          <GenerateFixtures id={id} refetch={refetch} />
        </Card>
      )}
    </div>
  );
}

export default ManageEventFixtures;
