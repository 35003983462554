import { useState } from "react";
import "./Auth.css";
import { Login, Signup } from "../../components/Auth";
import useRouterQuery from "../../utils/useRouterQuery";

function Auth({ hideLogo, text }) {
  const query = useRouterQuery();
  const selected = query.get("s") || "signup";
  const referrer = query.get("r") || false;
  const [formType, setFormType] = useState(selected);
  return (
    <div className="auth-page">
      <div className="form-container">
        {/* {!hideLogo && (
          <div className="logo">
            <img src={"/logo.svg"} />
          </div>
        )} */}

        <div
          className="auth-page-title"
          style={hideLogo ? { marginTop: 0 } : {}}
        >
          <div className="logo">
            <img src={"/logo.svg"} alt="" />
          </div>
          Get Started
        </div>
        <div className="auth-page-secondary-title">
          {text || ""}
          {text && <br />}
          {formType === "login" ? (
            <div>
              Don't have an account?{" "}
              <span
                onClick={() => {
                  setFormType("signup");
                }}
                className="hover-pointer text-link"
              >
                Sign Up Now
              </span>
            </div>
          ) : (
            <div>
              Already have an account?{" "}
              <span
                onClick={() => {
                  setFormType("login");
                }}
                className="hover-pointer text-link"
              >
                Sign In
              </span>
            </div>
          )}
        </div>
        {formType === "login" ? (
          <Login referrer={referrer} />
        ) : (
          <Signup referrer={referrer} />
        )}
      </div>

      <div className="auth-carousel">
        <div className="carousel-content-wrapper"></div>
      </div>
    </div>
  );
}

export default Auth;
