import "./ScoreIndividual.css";
import { usePageTracking } from "../../../utils/usePageTracking";

function ScoreIndividual({ id }) {
  usePageTracking({ title: "Individual Scoring" });
  return (
    <div className="score-fixture">
      <div className="left-column"></div>
      <div className="middle-column"></div>
      <div className="right-column"></div>
    </div>
  );
}

export default ScoreIndividual;
