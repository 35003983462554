import "./DeleteStat.css";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import Statement from "../Statement";
import { useFootballScoringState } from "../utils/useFootballScoringState";
import { useMutation } from "@apollo/client";
import { DELETE_FIXTURE_STAT } from "../../../api/fixture";
import Card from "../../../components/Card";

export default function DeleteStat({ stat, fixture }) {
  const [{ setOverlay, stats, selectedPeriod }, setFootballScoringState] =
    useFootballScoringState();

  const dismiss = () => {
    setOverlay(false);
  };

  const [mutate, { loading }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      const deleteIds = deleteFixtureStat.map((x) => x.id);
      if (deleteFixtureStat?.[0]?.isTemp) {
        // Mark as in progress
        setFootballScoringState({
          stats: {
            ...stats,
            [selectedPeriod.id]: stats[selectedPeriod.id].map((x) => {
              if (deleteIds.includes(x.id)) {
                return { ...x, isTemp: true };
              } else {
                return x;
              }
            }),
          },
        });

        setOverlay(false);
      } else {
        // Remove from scores
        // console.log("Removing scores:", cache.identify(fixture));
        cache.modify({
          id: cache.identify(fixture),
          fields: {
            highlights: (existingRefs, { readField }) => {
              //   console.log("DeleteIds:", deleteIds);
              //   console.log(
              //     "Highlights:",
              //     existingRefs.map((x) => readField("id", x))
              //   );
              return existingRefs.filter(
                (ref) => !deleteIds.includes(readField("id", ref))
              );
            },
          },
        });

        // Remove from list
        setFootballScoringState({
          stats: {
            ...stats,
            [selectedPeriod.id]: stats[selectedPeriod.id].filter((x) => {
              return !deleteIds.includes(x.id);
            }),
          },
        });
      }
    },
  });

  //   const [deleteStat, { loading }] = useMutation(DELETE_FIXTURE_STAT, {
  //     update: (cache, { data: { deleteFixtureStat } }) => {
  //       if (deleteFixtureStat?.length) {
  //         const q = cache.readQuery({
  //           query: FIXTURE_STATS,
  //           variables: {
  //             id: fixtureId,
  //           },
  //         });

  //         const statIds = deleteFixtureStat.map((x) => x.id);

  //         cache.writeQuery({
  //           query: FIXTURE_STATS,
  //           variables: {
  //             id: fixtureId,
  //           },
  //           data: {
  //             fixture: {
  //               ...q.fixture,
  //               stats: {
  //                 ...q.fixture.stats.filter((x) => !statIds.includes(x.id)),
  //               },
  //             },
  //           },
  //         });

  //         dismiss();
  //       }
  //     },
  //     onError: (error) => {
  //       captureException({
  //         error,
  //         info: {
  //           type: "mutation",
  //           component: "ScoreFootball.DeleteStatsConfirm",
  //           mutation: "DELETE_FIXTURE_STAT",
  //           stat: stat,
  //         },
  //       });
  //       console.log(error);
  //     },
  //   });
  return (
    <Card className="ScoringApp-football__scoring__delete-stats-form">
      <div className="ScoringApp-football__scoring__delete-stats-form__header">
        Are you sure you want to delete the following stats?
      </div>
      <div className="ScoringApp-football__scoring__delete-stats-form__body">
        <div className="ScoringApp-football__scoring__delete-stats-form__body__timestamp">
          {stat.timeStamp || 0}'
        </div>
        <div className="ScoringApp-football__scoring__delete-stats-form__body__statements">
          <Statement stat={stat} stats={stats[selectedPeriod.id]} />
        </div>
      </div>

      <div className="ScoringApp-football__scoring__delete-stats-form__options">
        <Button
          className={
            "medium ScoringApp-football__scoring__delete-stats-form__options__option neutral"
          }
          disabled={loading}
          onClick={() => {
            dismiss();
          }}
        >
          No, cancel
        </Button>
        <Button
          className="primary medium green ScoringApp-football__scoring__delete-stats-form__options__option"
          disabled={loading}
          onClick={() => {
            if (loading) return;
            mutate({
              variables: { id: stat.id },
              optimisticResponse: {
                deleteFixtureStat: [{ id: stat.id, isTemp: true }],
              },
            });
          }}
        >
          {loading ? <Loader /> : "Yes, delete these stats"}
        </Button>
      </div>
    </Card>
  );
}
