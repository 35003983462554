import { gql } from "@apollo/client";

const CREATE_FIXTURE_ROLE = gql`
  mutation createFixtureRole($data: CreateFixtureRoleInput!) {
    createFixtureRole(data: $data) {
      id
      order
      sortOrder
      type {
        id
        name
      }
      subtypes {
        id
        name
      }
      profile {
        id
        name
        firstname
        lastname
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
      }
      formation {
        id
        name
        positions {
          id
          positionType {
            id
            shortName
          }
          x
          y
        }
      }
      position {
        id
      }
    }
  }
`;

export default CREATE_FIXTURE_ROLE;
