import "./LineChart.css";
import { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";

function LineChart({
  labels = ["5", "4", "3", "2", "1"],
  datasets = [],
  suggestedMax = 5,
  suggestedMin = -5,
  wrapper,
  tooltip,
}) {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  // const labels = ["5", "4", "3", "2", "1"];
  // console.log(datasets);

  const chartConfig = {
    type: "line",
    data: {
      labels: labels,
      datasets: datasets,
      //   [
      //     {
      //       data: [3, 1, -2, 0, 1],
      //       fill: false,
      //       borderColor: "#78c9fc",
      //       tension: 0,
      //     },
      //     {
      //       data: [-3, 2, 1, -1, 3],
      //       fill: false,
      //       borderColor: "#058ee6",
      //       tension: 0,
      //     },
      //   ],
    },
    options: {
      elements: {
        point: {
          backgroundColor: (context, options) => {
            const value = context.dataset.data[context.dataIndex];
            if (value > 0) {
              return "#a1f291";
            } else if (value < 0) {
              return "#f28282";
            } else {
              return "#fff7eb";
            }
          },
          borderWidth: 3,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxis: {
          ticks: { display: false },
          grid: {
            borderColor: "#ebf7ff",
            color: "#ebf7ff",
            drawTicks: false,
          },
        },
        yAxis: {
          suggestedMax,
          suggestedMin,
          ticks: {
            display: false,
            stepSize: 1,
          },
          grid: {
            borderColor: "#ebf7ff",
            color: "#ebf7ff",
            drawTicks: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        ...(tooltip ? { tooltip } : {}),
      },
      // legend: { display: false },
      // cutout: "80%",
      // hover: { mode: null },
      // plugins: {
      //   tooltip: {
      //     enabled: false,
      //   },
      // },
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }

      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [datasets]);

  return (
    <canvas
      ref={chartContainer}
      height={wrapper?.current?.clientHeight + "px"}
      width={wrapper?.current?.clientWidth + "px"}
    />
  );
}

export default LineChart;
