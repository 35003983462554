import "./SelectMedia.css";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import Uploader from "../../components/Uploader";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import YouTubeVideo from "../../components/YouTubeVideo";
// import VideoCard from "../VideoCard";
// import Image from "../Image";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as ImageIcon } from "../../icons/image.svg";
import makeFileUrl from "../../utils/makeFileUrl";
import moment from "moment";
import Avatar from "../../components/Avatar";
import captureException from "../../utils/captureException";

function SelectPhotos({
  query,
  vars,
  getItems,
  getItemsCount,
  onSelect,
  defaultValue = [],
}) {
  const [selected, setSelected] = useState(defaultValue);
  const { error, data, fetchMore, variables, networkStatus } = useQuery(query, {
    variables: vars,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "SelectMedia",
        query: "variable",
      },
    });
    console.log(error);
  }

  const items = getItems(data);
  const total = getItemsCount(data);

  // console.log("SELECT PHOTOS DATA:", data);

  return (
    <div className="select-media__images" style={{ padding: "16px" }}>
      {networkStatus === 1 && <Spinner />}
      {items.map((m) => (
        <div
          className="select-media__images__item"
          onClick={() => {
            if (selected.includes(m.id)) {
              setSelected(selected.filter((x) => x !== m.id));
            } else {
              setSelected([...selected, m.id]);
            }

            onSelect && onSelect(m);
            //   updateProfilePhoto(m);
          }}
        >
          <img src={makeFileUrl(m)} alt="" />
          <div
            className={
              "select-media__images__item__select " +
              (selected.includes(m.id) ? "selected" : "")
            }
          >
            <TimesIcon className="select-media__images__item__select__icon-delete" />
            <CheckIcon className="select-media__images__item__select__icon" />
          </div>
        </div>
      ))}

      {items && items.length < total && (
        <div
          className="select-media__show-more"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: items[items.length - 1]?.id,
              },
            });
          }}
        >
          {!networkStatus === 7 ? <Loader theme="blue" /> : "Show More"}
        </div>
      )}
    </div>
  );
}

function SelectVideos({
  query,
  vars,
  getItems,
  getItemsCount,
  onSelect,
  defaultValue = [],
}) {
  const [selected, setSelected] = useState(defaultValue);
  const { error, data, fetchMore, variables, networkStatus } = useQuery(query, {
    variables: vars,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "SelectMedia.SelectVideos",
        query: "variable",
      },
    });
    console.log(error);
  }

  const items = getItems(data);
  const total = getItemsCount(data);
  // console.log("Videos:", items);

  return (
    <div className="select-media__videos" style={{ padding: "16px" }}>
      {networkStatus === 1 && <Spinner />}
      {items.map((m) => (
        <div
          className="select-media__videos__item"
          onClick={() => {
            if (selected.includes(m.id)) {
              setSelected(selected.filter((x) => x !== m.id));
            } else {
              setSelected([...selected, m.id]);
            }

            onSelect && onSelect(m);
            //   updateProfilePhoto(m);
          }}
        >
          <div className="video">
            {m.isExternal ? (
              <YouTubeVideo filename={m.filename} height={160} />
            ) : (
              <video controlsList="nodownload">
                <source src={makeFileUrl(m)} type="video/mp4" />
              </video>
            )}
          </div>
          <div className="select-media__videos__item__overlay">
            <div
              className={
                "select-media__videos__item__select " +
                (selected.includes(m.id) ? "selected" : "")
              }
            >
              <TimesIcon className="select-media__videos__item__select__icon-delete" />
              <CheckIcon className="select-media__videos__item__select__icon" />
            </div>
          </div>
        </div>
      ))}

      {items && items.length < total && (
        <div
          className="select-media__show-more"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: items[items.length - 1]?.id,
              },
            });
          }}
        >
          {!networkStatus === 7 ? <Loader theme="blue" /> : "Show More"}
        </div>
      )}
    </div>
  );
}

function SelectAlbums({
  query,
  vars,
  getItems,
  getItemsCount,
  onSelect,
  defaultValue = [],
}) {
  const [selected, setSelected] = useState(defaultValue);
  const { error, data, fetchMore, variables, networkStatus } = useQuery(query, {
    variables: vars,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "SelectMedia.SelectAlbums",
        query: "variable",
      },
    });
    console.log(error);
  }

  const items = getItems(data);
  const total = getItemsCount(data);

  return (
    <div className="select-media__albums" style={{ padding: "16px" }}>
      {networkStatus === 1 && <Spinner />}
      {items.map((album) => (
        <div
          className="select-media__albums__item"
          onClick={() => {
            if (selected.includes(album.id)) {
              setSelected(selected.filter((x) => x !== album.id));
            } else {
              setSelected([...selected, album.id]);
            }
            //
            onSelect && onSelect(album);
          }}
        >
          <div
            className={
              "select-media__albums__item__image " +
              (album.preview ? "has-image" : "")
            }
          >
            {album.preview ? (
              <Avatar media={album.preview} />
            ) : (
              <ImageIcon className="placeholder-icon" />
            )}
          </div>
          <div className="select-media__albums__item__desc">
            <div>
              {album.source.fixture
                ? album.source.fixture.roles
                    .map((r) => r.organization?.name || r.profile?.name)
                    .join(" vs ")
                : album.name}
            </div>
            {album.source.fixture && (
              <div>
                [
                {moment(album.source.fixture.scheduledStart).format(
                  "DD-MM-YYYY"
                )}
                ]
              </div>
            )}
          </div>
          <div className="select-media__albums__item__overlay">
            <div
              className={
                "select-media__albums__item__select " +
                (selected.includes(album.id) ? "selected" : "")
              }
            >
              <TimesIcon className="select-media__albums__item__select__icon-delete" />
              <CheckIcon className="select-media__albums__item__select__icon" />
            </div>
          </div>
        </div>
      ))}

      {items && items.length < total && (
        <div
          className="select-media__show-more"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: items[items.length - 1]?.id,
              },
            });
          }}
        >
          {!networkStatus === 7 ? <Loader theme="blue" /> : "Show More"}
        </div>
      )}
    </div>
  );
}

function SelectMedia({
  sourceId,
  sourceType,
  photosQuery,
  photosQueryVars,
  videosQuery,
  videosQueryVars,
  albumsQuery,
  albumsQueryVars,
  getPhotos,
  getPhotosCount,
  getVideos,
  getVideosCount,
  getAlbums,
  getAlbumsCount,
  onSubmitSelection,
  defaultValue = {},
  onUploadPhoto,
  onUploadVideo,
  dismiss,
}) {
  const [upload, setUpload] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [selectedPhotos, setSelectedPhotos] = useState(
    defaultValue.photos || []
  );
  const [selectedVideos, setSelectedVideos] = useState(
    defaultValue.videos || []
  );
  const [selectedAlbums, setSelectedAlbums] = useState(
    defaultValue.albums || []
  );

  const selectedMediaStatement = (pc, vc, ac) => {
    const tokens = [];
    if (pc) {
      tokens.push(pc + (pc > 1 ? " photos" : " photo"));
    }

    if (vc) {
      tokens.push(vc + (vc > 1 ? " videos" : " video"));
    }

    if (ac) {
      tokens.push(ac + (ac > 1 ? " albums" : " album"));
    }

    if (tokens.length === 1) {
      return tokens[0];
    } else if (tokens.length === 2) {
      return tokens.join(" and ");
    } else {
      return (
        tokens.slice(0, tokens.length - 1).join(", ") +
        " and " +
        tokens[tokens.length - 1]
      );
    }
  };

  if (upload) {
    return (
      <Uploader
        accept={selectedTabIndex === 0 ? ".jpg,.png,.jpeg" : ".mp4"}
        label="Upload profile photo"
        multiple={false}
        sourceId={sourceId}
        sourceType={sourceType}
        type={selectedTabIndex === 0 ? "IMAGE" : "VIDEO"}
        subtype={""}
        dismiss={() => {
          setUpload(false);
        }}
        onCreateMedia={(cache, createMedia) => {
          // setUpload(false);
          // const q = cache.readQuery({
          //   query: PROFILE,
          //   variables: { id: profile.id },
          // });

          // cache.writeQuery({
          //   query: PROFILE,
          //   variables: { id: profile.id },
          //   data: {
          //     profile: {
          //       ...q.profile,
          //       profilePhoto: {
          //         ...createMedia,
          //       },
          //       profilePhotos: [...q.profile.profilePhotos, { ...createMedia }],
          //     },
          //   },
          // });

          if (selectedTabIndex === 0) {
            onUploadPhoto && onUploadPhoto(cache, createMedia);
            setSelectedPhotos([...selectedPhotos, createMedia.media]);
          } else if (selectedTabIndex === 1) {
            onUploadVideo && onUploadVideo(cache, createMedia);
            setSelectedVideos([...selectedVideos, createMedia.media]);
          }

          setUpload(false);
        }}
        {...(selectedTabIndex === 1 ? { maxFileSizeInBytes: 10000000 } : {})}
      />
    );
  } else {
    return (
      <div className="select-media-wrapper" style={{ width: "90%" }}>
        <Card className="select-media" style={{ maxHeight: "90%" }}>
          <div
            className="select-media__primary-header"
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            Select post media
            <div
              className="select-media__primary-header__dismiss"
              onClick={() => {
                dismiss();
                // setAppState({ modal: false });
              }}
            >
              <TimesIcon style={{ stroke: "var(--dark-4)", height: "16px" }} />
            </div>
          </div>
          <div
            className="select-media__header"
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            {/*<div className="select-media__header__title">
                  {label || "Select Media"}
        </div>*/}
            <div className="select-media__header__tabs">
              <div
                className={
                  "select-media__header__tabs__tab " +
                  (selectedTabIndex === 0 ? "selected" : "")
                }
                onClick={() => {
                  setSelectedTabIndex(0);
                }}
              >
                Photos
              </div>
              <div
                className={
                  "select-media__header__tabs__tab " +
                  (selectedTabIndex === 1 ? "selected" : "")
                }
                onClick={() => {
                  setSelectedTabIndex(1);
                }}
              >
                Videos
              </div>
              <div
                className={
                  "select-media__header__tabs__tab " +
                  (selectedTabIndex === 2 ? "selected" : "")
                }
                onClick={() => {
                  setSelectedTabIndex(2);
                }}
              >
                Albums
              </div>

              {selectedTabIndex !== 2 && (
                <Button
                  className="tertiary medium blue upload-new-btn"
                  onClick={() => {
                    setUpload(true);
                  }}
                  style={{ marginRight: 0, paddingRight: 0 }}
                >
                  Upload New
                </Button>
              )}
            </div>
            {/*selectedPhotos.length ? (
                  <div
                    className="select-media__header__link"
                    onClick={() => {
                      //   setUpload(true);
                    }}
                  >
                    Done
                  </div>
                ) : (
                  <div
                    className="select-media__header__link"
                    onClick={() => {
                      setUpload(true);
                    }}
                  >
                    Upload New
                  </div>
                )*/}
          </div>

          <div className="select-media__body" style={{ overflowY: "auto" }}>
            {selectedTabIndex === 0 && (
              <SelectPhotos
                query={photosQuery}
                vars={photosQueryVars}
                getItems={getPhotos}
                getItemsCount={getPhotosCount}
                onSelect={(m) => {
                  if (selectedPhotos.find((x) => x.id === m.id)) {
                    setSelectedPhotos(
                      selectedPhotos.filter((x) => x.id !== m.id)
                    );
                  } else {
                    setSelectedPhotos([...selectedPhotos, m]);
                  }
                }}
                defaultValue={selectedPhotos.map((m) => m.id)}
              />
            )}

            {selectedTabIndex === 1 && (
              <SelectVideos
                query={videosQuery}
                vars={videosQueryVars}
                getItems={getVideos}
                getItemsCount={getVideosCount}
                onSelect={(m) => {
                  if (selectedVideos.find((x) => x.id === m.id)) {
                    setSelectedVideos(
                      selectedVideos.filter((x) => x.id !== m.id)
                    );
                  } else {
                    setSelectedVideos([...selectedVideos, m]);
                  }
                }}
                defaultValue={selectedVideos.map((m) => m.id)}
              />
            )}

            {selectedTabIndex === 2 && (
              <SelectAlbums
                query={albumsQuery}
                vars={albumsQueryVars}
                getItems={getAlbums}
                getItemsCount={getAlbumsCount}
                onSelect={(m) => {
                  if (selectedAlbums.find((x) => x.id === m.id)) {
                    setSelectedAlbums(
                      selectedAlbums.filter((x) => x.id !== m.id)
                    );
                  } else {
                    setSelectedAlbums([...selectedAlbums, m]);
                  }
                }}
                defaultValue={selectedAlbums.map((m) => m.id)}
              />
            )}

            {(!!selectedPhotos?.length ||
              !!selectedVideos?.length ||
              !!selectedAlbums?.length) && (
              <div
                className="select-media__footer"
                onClick={() => {
                  onSubmitSelection(
                    selectedPhotos,
                    selectedVideos,
                    selectedAlbums
                  );

                  dismiss();
                  // setAppState({ modal: false });
                }}
              >
                Add{" "}
                {selectedMediaStatement(
                  selectedPhotos.length,
                  selectedVideos.length,
                  selectedAlbums.length
                )}{" "}
                to Post
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
}

export default SelectMedia;
