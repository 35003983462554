import { useState } from "react";
import "./ManageEventRegistrations.css";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as UpDownIcon } from "../../../icons/up-down.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as LeftArrowIcon } from "../../../icons/arrow-left.svg";
import Loader from "../../../components/Loader";
import { useQuery, useMutation } from "@apollo/client";
import {
  EVENT_REGISTRATIONS,
  UPDATE_EVENT_REGISTRATION,
  EVENT_PARTICIPANTS,
} from "../../../api/event";
import Spinner from "../../../components/Spinner";
import { useAppState } from "../../../utils/appState";
import captureException from "../../../utils/captureException";

function EditRegistration({ registration, participantType, eventId }) {
  const [, setAppState] = useAppState();
  const [jerseyNum, setJerseyNum] = useState(registration.jerseyNum);
  const [amountPaid, setAmountPaid] = useState(registration.amountPaid);
  const [teamSelector, setTeamSelector] = useState(false);
  const [team, setTeam] = useState(registration.organization);
  const [paid, setPaid] = useState(registration.paid);

  const { data, error } = useQuery(EVENT_PARTICIPANTS, {
    variables: { id: eventId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventRegistrations",
        query: "EVENT_PARTICIPANTS",
      },
    });
    console.log(error);
  }

  const [updateEventRegistration, { loading: submitting }] = useMutation(
    UPDATE_EVENT_REGISTRATION,
    {
      update: (cache, { data: { updateRegistration } }) => {
        const q = cache.readQuery({
          query: EVENT_REGISTRATIONS,
          variables: { id: eventId },
        });

        const itemIndex = q.event.registrations.findIndex(
          (x) => x.id === registration.id
        );

        if (itemIndex > -1) {
          const newRegistrations = [...q.event.registrations];
          newRegistrations[itemIndex] = updateRegistration;
          cache.writeQuery({
            query: EVENT_REGISTRATIONS,
            variables: { id: eventId },
            data: {
              event: {
                ...q.event,
                registrations: newRegistrations,
              },
            },
          });
        }

        setAppState({ modal: false });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRegistrations.EditRegistration",
            mutation: "UPDATE_EVENT_REGISTRATION",
          },
        });
        console.log(error);
      },
    }
  );

  const participants = data?.event?.participants || [];
  // console.log(participants);

  if (teamSelector) {
    return (
      <div className="modal-form-mobile-wrapper">
        <div
          className="modal-form-mobile"
          style={{ height: "80%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="modal-form-mobile__title"
            style={{ paddingLeft: "12px" }}
          >
            <div
              className="modal-form-mobile__title__icon-left"
              onClick={() => {
                setTeamSelector(false);
              }}
            >
              <LeftArrowIcon
                style={{
                  stroke: "var(--dark-4)",
                  height: "12px",

                  // marginRight: "8px",
                }}
              />
            </div>

            <div className="modal-form-mobile__title__text">Select team</div>
            <div
              className="modal-form-mobile__title__dismiss-btn"
              onClick={() => {
                setAppState({ modal: false });
              }}
            >
              <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
            </div>
          </div>

          <div
            className="modal-form-mobile__body"
            style={{ flexGrow: 1, overflow: "auto" }}
          >
            {participants.map((p) => (
              <div
                className="edit-event-registration-mobile__team-selection-item"
                onClick={() => {
                  setTeam(p.organization);
                  setTeamSelector(false);
                }}
              >
                <div className="edit-event-registration-mobile__team-selection-item__img"></div>

                <div className="edit-event-registration-mobile__team-selection-item__text">
                  <div className="edit-event-registration-mobile__team-selection-item__text__primary">
                    {p.organization?.name}
                  </div>

                  <div className="edit-event-registration-mobile__team-selection-item__text__secondary">
                    @{p.organization?.handle}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-form-mobile-wrapper">
      <div className="modal-form-mobile">
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">
            Edit {registration.profile.name}'s registration
          </div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div className="modal-form-mobile__body">
          <form
            className="modal-form-mobile__form"
            onSubmit={(e) => {
              e.preventDefault();
              if (submitting) return;

              updateEventRegistration({
                variables: {
                  where: { id: registration.id },
                  data: {
                    ...(team ? { organizationId: team.id } : {}),
                    amountPaid: (amountPaid && Number(amountPaid)) || 0,
                    paid: paid || false,
                    ...(jerseyNum ? { jerseyNum: Number(jerseyNum) } : {}),
                  },
                },
              });

              // config.onSubmit({ fields, mutate });
            }}
          >
            {participantType === "TEAM" && (
              <div className="modal-form-mobile__form__group">
                <div className="modal-form-mobile__form__group__label">
                  Team
                </div>

                <div
                  className="modal-form-mobile__form__group__input"
                  onClick={() => {
                    setTeamSelector(true);
                  }}
                >
                  <div className="modal-form-mobile__form__group__input__select">
                    <div className="modal-form-mobile__form__group__input__select__value">
                      {team?.name}
                    </div>
                    <div className="modal-form-mobile__form__group__input__select__icon">
                      <UpDownIcon
                        style={{ height: "12px", fill: "var(--light-3)" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Jersey number
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="number"
                  value={jerseyNum}
                  onChange={(e) => {
                    setJerseyNum(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">Paid?</div>

              <div
                className="modal-form-mobile__form__group__input"
                onClick={() => {
                  setPaid((prev) => !prev);
                }}
              >
                <div className="modal-form-mobile__form__group__input__select">
                  <div className="modal-form-mobile__form__group__input__select__value">
                    {paid ? "YES" : "NO"}
                  </div>
                  <div className="modal-form-mobile__form__group__input__select__icon">
                    <UpDownIcon
                      style={{ height: "12px", fill: "var(--light-3)" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-form-mobile__form__group">
              <div className="modal-form-mobile__form__group__label">
                Amount Paid
              </div>

              <div className="modal-form-mobile__form__group__input">
                <input
                  type="number"
                  value={amountPaid}
                  onChange={(e) => {
                    setAmountPaid(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="modal-form-mobile__form__options">
              <button
                type="submit"
                className="modal-form-mobile__form__options__submit"
              >
                {submitting ? <Loader theme={"small"} /> : "Submit"}
              </button>

              <button
                className="modal-form-mobile__form__options__cancel"
                onClick={(e) => {
                  e.preventDefault();
                  setAppState({ modal: false });
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function ManageEventRegistrations({ id }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT_REGISTRATIONS, {
    variables: { id },
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventRegistrations",
        query: "EVENT_REGISTRATIONS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const registrations = ev?.registrations || [];

  return (
    <div className="manage-event-registrations-mobile">
      <div className="manage-event-registrations-mobile__header">
        <div className="manage-event-registrations-mobile__header__selector">
          <div className="manage-event-registrations-mobile__header__selector__text">
            All Teams
          </div>

          <div className="manage-event-registrations-mobile__header__selector__icon">
            <UpDownIcon style={{ height: "12px", fill: "var(--light-3)" }} />
          </div>
        </div>

        <div className="manage-event-registrations-mobile__header__search">
          <SearchIcon style={{ height: "14px", stroke: "var(--light-3)" }} />{" "}
          Search
        </div>
      </div>

      <div className="manage-event-registrations-mobile__list">
        {registrations.map((r, i) => (
          <div
            className="manage-event-registrations-mobile__list__item"
            key={r.id}
          >
            <div className="manage-event-registrations-mobile__list__item__image"></div>

            <div className="manage-event-registrations-mobile__list__item__text">
              <div className="manage-event-registrations-mobile__list__item__text__primary">
                {r.profile?.name}
              </div>

              <div className="manage-event-registrations-mobile__list__item__text__secondary">
                {r.organization?.name}
              </div>

              <div className="manage-event-registrations-mobile__list__item__text__tertiary">
                {`Amount Paid: ${r.amountPaid || "-"} | Jersey Number: ${
                  r.jerseyNum || "-"
                }`}
              </div>
            </div>

            <div
              className="manage-event-registrations-mobile__list__item__edit-btn"
              onClick={() => {
                setAppState({
                  modal: (
                    <EditRegistration
                      registration={r}
                      participantType={ev.participantType}
                      eventId={id}
                    />
                  ),
                });
              }}
            >
              <PencilIcon style={{ height: "10px" }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
