import { gql } from "@apollo/client";

const PROFILE_EVENTS = gql`
  query profileQuery(
    $id: ID!
    $filters: ProfileEventFilters
    $take: Int
    $cursor: ID
  ) {
    profile(id: $id) {
      id
      events(filters: $filters, take: $take, cursor: $cursor) {
        id
        name
        venueLabel
        scheduledStart
        description
        type {
          id
          name
        }
        profilePhoto {
          id
          filename
        }
        city {
          id
          value
        }
        profilePhoto {
          id
          filename
        }
        coverPhoto {
          id
          filename
        }
        registrations(take: 4) {
          id
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
        }
        registrationsCount
        userIsRegistered {
          id
        }
        organizedBy {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        sport {
          id
          name
        }
      }
    }
  }
`;

export default PROFILE_EVENTS;
