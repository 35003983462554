import { useState } from "react";
import "./AuthForm.css";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../api";
import { useHistory } from "react-router-dom";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import { useAppState } from "../../utils/appState";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../Button/DebouncedButton";

function Login() {
  usePageTracking({ title: "Login" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [, setAppState] = useAppState();

  const history = useHistory();

  const [login, { loading }] = useMutation(LOGIN, {
    update: (cache, { data: { login } }) => {
      // console.log(login);
      if (!login.token || !login.expiresAt) {
        // setError(true);
        return;
      }

      localStorage.setItem("sp_auth_token", login.token);
      localStorage.setItem("sp_expires_at", login.expiresAt);
      localStorage.setItem("sp_name", login.name);
      setAppState({ isLoggedIn: true });

      console.log(localStorage.getItem("sp_auth_token"));

      history.replace("/app");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Login",
          mutation: "LOGIN",
        },
      });
      console.log(error);
      setErrorMessage(error.message);
    },
  });

  return (
    <form
      onSubmit={(e) => {
        // console.log("Form submitted");
        e.preventDefault();

        if (loading) return;

        // setErrorMessage("");

        if (!email || !password) {
          if (!email) {
            setInvalidEmail(true);
          }

          if (!password) {
            setInvalidPassword(true);
          }
          return;
        }

        login({
          variables: {
            email,
            password,
          },
        });
      }}
      className="auth-form"
    >
      <div className="form-group">
        <input
          type="text"
          id="xyz"
          value={email}
          className={
            "input " +
            (email ? "hasValue " : " ") +
            (invalidEmail ? "invalid" : "")
          }
          onChange={(e) => {
            if (errorMessage) {
              setErrorMessage("");
            }
            setEmail(e.target.value);
          }}
          onFocus={() => {
            setInvalidEmail(false);
          }}
        />
        <label for="xyz">
          Email <span className="input-required-indicator">*</span>
        </label>
        <div className="static-icon">
          <MailIcon style={{ fill: "var(--light-4)", height: 14 }} />
        </div>
      </div>

      <div className="form-group">
        <input
          type={showPassword ? "text" : "password"}
          id="current-password"
          value={password}
          className={
            "input " +
            (password ? "hasValue " : " ") +
            (invalidPassword ? "invalid" : "")
          }
          autoComplete="current-password"
          onChange={(e) => {
            if (errorMessage) {
              setErrorMessage("");
            }
            setPassword(e.target.value);
          }}
          onFocus={() => {
            setInvalidPassword(false);
          }}
        />
        <label for="current-password">
          Password <span className="input-required-indicator">*</span>
        </label>
        <div className="static-icon">
          <LockIcon style={{ fill: "var(--light-4)", height: 14 }} />
        </div>
        <div
          className="static-icon-right"
          onClick={() => {
            setShowPassword((prev) => !prev);
          }}
        >
          <EyeIcon
            style={{
              fill: showPassword ? "var(--blue-main)" : "var(--light-4)",
              height: 14,
            }}
          />
        </div>
      </div>

      {errorMessage && (
        <Alert
          theme="red login-error"
          head={errorMessage}
          dismiss={() => {
            setErrorMessage("");
          }}
        />
      )}

      <DebouncedButton
        type="submit"
        className="app-button primary large green submit"
        style={errorMessage ? { marginTop: "24px" } : {}}
        loading={loading}
      >
        {loading ? <Loader theme="small" /> : "Submit"}
      </DebouncedButton>

      {/* <button
        type="submit"
        className="app-button primary large green submit"
        style={errorMessage ? { marginTop: "24px" } : {}}
      >
        {loading ? <Loader /> : "Submit"}
      </button> */}

      <div className="terms">
        Forgot your password?{" "}
        <span
          className="text-link hover-pointer"
          onClick={() => {
            history.push("/forgot-password");
          }}
        >
          Click here
        </span>
      </div>
    </form>
  );
}

export default Login;
