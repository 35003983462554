export default function ballsCompleted({ overNumber, groupedStats }) {
  const group = groupedStats.find((g) => g.group === overNumber);
  const totalBalls = group?.stats?.length || 0;
  const totalExtras =
    group?.stats?.filter((item) =>
      item.extraStats?.find((s) =>
        ["Wide", "No Ball"].includes(s.statType.name)
      )
    ).length || 0;

  // console.log("Total Balls:", totalBalls);
  // console.log("Total Extras:", totalExtras);
  return totalBalls - totalExtras;
}
