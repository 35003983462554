import "./ProfilePhotos.css";
import { useState, Fragment } from "react";
import Album from "../../Album";
import AlbumsCard from "../../AlbumsCard";
import PhotosCard from "../../PhotosCard";
import {
  PROFILE_ALBUMS,
  PROFILE_TAGGED_PHOTOS,
  PROFILE,
  PROFILE_PHOTOS,
} from "../../../api/profile";
import { gql } from "@apollo/client";
import { useAppState } from "../../../utils/appState";
import Card from "../../../components/Card";
import { ReactComponent as ChevronLeftIcon } from "../../../icons/chevron-left.svg";
import PhotoBrowser from "../../PhotoBrowser";
import Avatar from "../../../components/Avatar";

function ProfilePhotosAlbum({ profile, dismiss }) {
  const [, setAppState] = useAppState();
  return (
    <Card className="app-album-container-mobile">
      <div className="app-album-container-mobile__head">
        <div
          className="app-album-container-mobile__head__link"
          onClick={() => {
            dismiss();
          }}
        >
          <ChevronLeftIcon
            style={{ stroke: "var(--dark-4)", height: "12px" }}
          />
          Back to photos
        </div>
        <div className="app-album-container-mobile__head__title">
          <div className="app-album-container-mobile__head__title__text">
            Profile Photos
          </div>
        </div>
        <div className="app-album-container-mobile__head__meta"></div>
      </div>
      <div className="app-album-container-mobile__body">
        {profile?.profilePhotos?.map((m) => (
          <div
            key={m.id}
            className="app-album-container-mobile__body__item"
            onClick={() => {
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={PROFILE}
                    vars={{ id: profile.id }}
                    getPhotos={(data) => data.profile?.profilePhotos || []}
                    totalPhotos={(data) =>
                      data.profile?.profilePhotos?.length || 0
                    }
                    media={m}
                  />
                ),
              });
            }}
          >
            <div className="app-album-container-mobile__body__item__image">
              <Avatar media={m} />
              {/* <img src={makeFileUrl(m)} /> */}

              <div className="overlay" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function CoverPhotosAlbum({ profile, dismiss }) {
  const [, setAppState] = useAppState();
  return (
    <Card className="app-album-container-mobile">
      <div className="app-album-container-mobile__head">
        <div
          className="app-album-container-mobile__head__link"
          onClick={() => {
            dismiss();
          }}
        >
          <ChevronLeftIcon
            style={{ stroke: "var(--dark-4)", height: "12px" }}
          />
          Back to photos
        </div>
        <div className="app-album-container-mobile__head__title">
          <div className="app-album-container-mobile__head__title__text">
            Cover Photos
          </div>
        </div>
        <div className="app-album-container-mobile__head__meta"></div>
      </div>
      <div className="app-album-container-mobile__body">
        {profile?.coverPhotos?.map((m) => (
          <div
            key={m.id}
            className="app-album-container-mobile__body__item"
            onClick={() => {
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={PROFILE}
                    vars={{ id: profile.id }}
                    getPhotos={(data) => data.profile?.coverPhotos || []}
                    totalPhotos={(data) =>
                      data.profile?.coverPhotos?.length || 0
                    }
                    media={m}
                  />
                ),
              });
            }}
          >
            <div className="app-album-container-mobile__body__item__image">
              <Avatar media={m} />
              {/* <img src={makeFileUrl(m)} /> */}

              <div className="overlay" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default function ProfilePhotos({ id, profile, canManagePage }) {
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [sportsFilter] = useState([]);
  const [teamsFilter] = useState([]);
  const [eventsFilter] = useState([]);

  const customAlbums = [
    ...(profile.profilePhotos?.length
      ? [
          {
            id: "profilePhotos",
            name: "Profile Photos",
            preview: profile.profilePhoto,
          },
        ]
      : []),
    ...(profile.coverPhotos?.length
      ? [
          {
            id: "coverPhotos",
            name: "Cover Photos",
            preview: profile.coverPhoto,
          },
        ]
      : []),
  ];

  return (
    <div className="organization-photos-mobile">
      {selectedAlbum ? (
        <Fragment>
          {selectedAlbum === "profilePhotos" ? (
            <ProfilePhotosAlbum
              profile={profile}
              dismiss={() => {
                setSelectedAlbum("");
              }}
            />
          ) : selectedAlbum === "coverPhotos" ? (
            <CoverPhotosAlbum
              profile={profile}
              dismiss={() => {
                setSelectedAlbum("");
              }}
            />
          ) : (
            <Album
              id={selectedAlbum}
              dismiss={() => {
                setSelectedAlbum("");
              }}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          <AlbumsCard
            query={PROFILE_ALBUMS}
            vars={{ id, take: 2 }}
            getAlbums={(data) => [
              ...customAlbums,
              ...(data?.profile?.mediaSets || []),
            ]}
            totalAlbums={(data) => data?.profile?.albumsCount || 0}
            noData={`No albums found.`}
            setSelectedAlbum={setSelectedAlbum}
            filters={{ sportsFilter, teamsFilter, eventsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  sports: sportsFilter,
                  teams: teamsFilter,
                  events: eventsFilter,
                },
              };
            }}
          />

          <PhotosCard
            title="Tagged Photos"
            query={PROFILE_TAGGED_PHOTOS}
            vars={{ id, take: 16 }}
            getPhotos={(data) => data?.profile?.taggedMedia || []}
            totalPhotos={(data) => data?.profile?.taggedPhotosCount || 0}
            noData={`No tagged photos of ${profile.name} yet.`}
            filters={{ sportsFilter, teamsFilter, eventsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  sports: sportsFilter,
                  teams: teamsFilter,
                  events: eventsFilter,
                },
              };
            }}
            onDelete={(cache, { data: { deleteMedia } }) => {
              cache.modify({
                id: cache.identify(profile),
                fields: {
                  media(existingMediaRefs, { readField }) {
                    return existingMediaRefs.filter(
                      (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
                    );
                  },
                },
              });
            }}
          />

          <PhotosCard
            title={profile.name + "'s Matches"}
            query={PROFILE_PHOTOS}
            vars={{ id, take: 16 }}
            getPhotos={(data) => data?.profile?.media || []}
            totalPhotos={(data) => data?.profile?.photosCount || 0}
            noData={`No tagged photos of ${profile.name} yet.`}
            filters={{ sportsFilter, teamsFilter, eventsFilter }}
            processFilters={(filters) => {
              return {
                filters: {
                  sports: sportsFilter,
                  teams: teamsFilter,
                  events: eventsFilter,
                },
              };
            }}
            onDelete={(cache, { data: { deleteMedia } }) => {
              cache.modify({
                id: cache.identify(profile),
                fields: {
                  media(existingMediaRefs, { readField }) {
                    return existingMediaRefs.filter(
                      (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
                    );
                  },
                },
              });
            }}
          />
        </Fragment>
      )}
    </div>
  );
}
