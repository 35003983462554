// V1
import "./LatestHighlights.css";
import Card from "../../Card";
import { useQuery } from "@apollo/client";
import YouTubeVideo from "../../YouTubeVideo";
import makeFileUrl from "../../../utils/makeFileUrl";
import Placeholder from "../../Placeholder/Placeholder";
import { useHistory, useLocation } from "react-router-dom";
import captureException from "../../../utils/captureException";
import { EVENT } from "../../../api/functions";

function LatestHighlights({ id }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(
    EVENT({
      selectionSet: `
      media(type: "VIDEO", take:1) {
        id
        isExternal
        filename        
      }
    `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "LatestHighlights",
        query: "EVENT_MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="latest-highlights-card">
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="video-wrapper">
          <div
            className="video-container"
            style={{ height: "240px", backgroundColor: "var(--blue-subtle)" }}
          ></div>
        </div>

        <div className="latest-highlights-card-footer">
          <Placeholder
            style={{ height: "12px", width: "160px" }}
            pulse={true}
          />
        </div>
      </Card>
    );
  }

  const video = data?.event?.media?.[0];
  // console.log(video);

  return (
    <Card className="latest-highlights-card">
      <div className="card-title">Latest Highlights</div>
      <div className="video-wrapper">
        {video && (
          <div
            className="video-container"
            style={{
              height: "280px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {video.isExternal ? (
              <YouTubeVideo filename={video.filename} />
            ) : (
              <video controls controlsList="nodownload">
                <source src={makeFileUrl(video)} type="video/mp4" />
              </video>
            )}
          </div>
        )}

        {!video && (
          <div className="no-data">
            <div className="text">No videos added to this event yet.</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        )}
      </div>
      {video && (
        <div
          className="latest-highlights-card-footer hover-pointer"
          onClick={() => {
            history.replace(location.pathname + "?t=videos");
          }}
        >
          See More Videos
        </div>
      )}
    </Card>
  );
}

export default LatestHighlights;
