// V1
import { useState } from "react";
import "./FixtureLineupsSummary.css";
import Card from "../../Card";
import { FIXTURE_LINEUPS } from "../../../api/fixture";
import { useQuery } from "@apollo/client";
import AppLink from "../../AppLink";
import Placeholder from "../../Placeholder/Placeholder";
import Avatar from "../../Avatar";

function FixtureLineupsSummary({ id }) {
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  const { loading, error, data } = useQuery(FIXTURE_LINEUPS, {
    variables: { id },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="match-lineups-card">
        <div
          className="card-title"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="tab-selector">
          {[1, 2].map((p, i) => (
            <div
              key={"fls-ph-" + i}
              className={
                "tab-selector-option " +
                (selectedTeamIndex === i ? "selected" : "")
              }
            >
              <div className="logo"></div>
              <div className="formation">
                <Placeholder
                  style={{ height: "12px", width: "40px" }}
                  pulse={true}
                />
              </div>
            </div>
          ))}
        </div>

        {/*<div className="pitch" />*/}

        <div className="lineup-list">
          <div className="section">
            {/*<div className="section-title">Starters</div>*/}
            {[1, 2, 3, 4].map((i) => (
              <div className="lineup-list-item" key={"fls-ph-lli-" + i}>
                <div className="number">
                  <Placeholder
                    style={{ height: "12px", width: "20px" }}
                    pulse={true}
                  />
                </div>
                <div className="profile-pic"></div>
                <div>
                  <div className="name">
                    <Placeholder
                      style={{ height: "12px", width: "80px" }}
                      pulse={true}
                    />
                  </div>
                </div>

                <div className="pos">
                  <Placeholder
                    style={{ height: "12px", width: "40px" }}
                    pulse={true}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    );
  }

  const participants = data?.fixture?.roles
    ?.filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);
  const selectedTeam = participants[selectedTeamIndex];
  const starters = data?.fixture?.roles?.filter(
    (r) =>
      r.type.name === "LINEUP PLAYER" &&
      r.organization?.id === selectedTeam.organization.id
  );

  // console.log("STARTERS:", starters);

  return (
    <Card className="match-lineups-card">
      <div className="card-title">Squad List</div>
      <div className="tab-selector">
        {participants.map((p, i) => (
          <div
            key={"tab-" + p.id}
            className={
              "tab-selector-option " +
              (selectedTeamIndex === i ? "selected" : "")
            }
            onClick={() => {
              setSelectedTeamIndex(i);
            }}
          >
            <div className="logo">
              {p.organization?.profilePhoto && (
                <Avatar media={p.organization.profilePhoto} />
              )}
            </div>
            <div className="formation">{p.organization?.name}</div>
          </div>
        ))}
      </div>

      {/*<div className="pitch" />*/}

      <div className="lineup-list">
        <div className="section">
          {/*<div className="section-title">Starters</div>*/}
          {starters.map((role) => (
            <div
              key={"lineup-list-item-" + role.id}
              className="lineup-list-item"
            >
              <div className="number">{role.order || "-"}</div>
              <div className="profile-pic">
                {role.profile?.profilePhoto && (
                  <Avatar media={role.profile.profilePhoto} />
                )}
              </div>
              <AppLink to={"/app/profile/" + role.profile?.id}>
                <div className="name">{role.profile?.name}</div>
              </AppLink>

              <div className="pos">
                {role.position?.positionType?.shortName || ""}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default FixtureLineupsSummary;
