// V1
import "./VideoCard.css";
import Card from "../Card";
import YouTubeVideo from "../YouTubeVideo";
import makeFileUrl from "../../utils/makeFileUrl";
import VideoCardDescription from "./components/VideoCardDescription";
import { MEDIA } from "../../api/media";
import { useQuery } from "@apollo/client";
import Placeholder from "../Placeholder";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import captureException from "../../utils/captureException";
import FixtureListItem from "../FixtureListItem";

function VideoCardSidebarSource({ media }) {
  const source = media.source;
  if (source.fixture) {
    const fixture = source.fixture;
    const participants = fixture.roles
      .filter((r) => r.type.name === "PARTICIPANT")
      .sort((a, b) => a.order - b.order);
    const homeTeam = participants[0];
    const awayTeam = participants[1];
    const homeScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[0].organization?.id
    );

    const awayScore = fixture.placements?.find(
      (p) => p.organization?.id === participants[1].organization?.id
    );
    return (
      <Link to={"/app/fixture/" + fixture.id} className="video-info__source">
        <FixtureListItem
          fixture={fixture}
          hideDate={true}
          className="video-card-fixture-source"
        />
        {/* <div className="video-info__source__fixture">
          <div className="video-info__source__fixture__team home">
            <div className="video-info__source__fixture__team__image">
              {homeTeam.organization?.profilePhoto && (
                <Avatar media={homeTeam.organization.profilePhoto} />
              )}
            </div>

            <div className="video-info__source__fixture__team__title">
              {homeTeam?.organization?.name}
            </div>

            <div className="video-info__source__fixture__team__score">
              {homeScore?.value}
            </div>
          </div>
          <div className="video-info__source__fixture__seperator">-</div>
          <div className="video-info__source__fixture__team home">
            <div className="video-info__source__fixture__team__image">
              {awayTeam.organization?.profilePhoto && (
                <Avatar media={awayTeam.organization.profilePhoto} />
              )}
            </div>
            <div className="video-info__source__fixture__team__title">
              {awayTeam?.organization?.name}
            </div>
            <div className="video-info__source__fixture__team__score">
              {awayScore?.value}
            </div>
          </div>
        </div> */}
      </Link>
    );
  } else if (source.event) {
    const event = source.event;
    return (
      <Link to={"/app/event/" + event.id} className="video-info__source">
        <div className="video-info__source__image">
          {event?.profilePhoto && <Avatar media={event.profilePhoto} />}
        </div>
        <div className="video-info__source__text">
          <div className="video-info__source__text__primary">{event.name}</div>
        </div>
      </Link>
    );
  } else if (source.organization) {
    const organization = source.organization;
    return (
      <Link
        to={"/app/organization/" + organization.id}
        className="video-info__source"
      >
        <div className="video-info__source__image">
          {organization?.profilePhoto && (
            <Avatar media={organization.profilePhoto} />
          )}
        </div>
        <div className="video-info__source__text">
          <div className="video-info__source__text__primary">
            {organization.name}
          </div>
          <div className="video-info__source__text__secondary">
            @ {organization.handle}
          </div>
        </div>
      </Link>
    );
  } else if (source.profile) {
    const profile = source.profile;
    return (
      <Link to={"/app/profile/" + profile.id} className="video-info__source">
        <div className="video-info__source__image">
          {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
        </div>
        <div className="video-info__source__text">
          <div className="video-info__source__text__primary">
            {profile.name}
          </div>
          <div className="video-info__source__text__secondary">
            @ {profile.handle}
          </div>
        </div>
      </Link>
    );
  } else {
    return <div className="video-info__source"></div>;
  }
}

function VideoCardSidebar({ id, onDelete }) {
  const { loading, error, data } = useQuery(MEDIA, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "VideoCard.VideoCardSidebar",
        query: "MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="video-info">
        <div className="video-info__source">
          <div className="video-info__source__image"></div>
          <div className="video-info__source__text">
            <div className="video-info__source__text__primary">
              <Placeholder
                style={{ height: "12px", width: "80px" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
        <div className="video-info__desc">
          <Placeholder style={{ height: "10px", width: "100%" }} pulse={true} />
          <Placeholder
            style={{ height: "10px", width: "80%", marginTop: "8px" }}
            pulse={true}
          />
        </div>
      </div>
    );
  }

  const media = data.media;

  return (
    <div className="video-info">
      <VideoCardSidebarSource media={media} />
      <VideoCardDescription media={media} onDelete={onDelete} />
    </div>
  );
}

function VideoCard({ media, onDelete, videoHeight = 320 }) {
  return (
    <Card className="video-list-item">
      <div className="video-wrapper">
        <div className="video" style={{ height: videoHeight + "px" }}>
          {media.isExternal ? (
            <YouTubeVideo filename={media.filename} />
          ) : (
            <video controls controlsList="nodownload">
              <source src={makeFileUrl(media)} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
      <VideoCardSidebar id={media.id} onDelete={onDelete} />
    </Card>
  );
}

export default VideoCard;
