import "./Button.css";
import { useMemo, useEffect, useState } from "react";
import debounce from "lodash.debounce";

function DebouncedButton({
  className,
  children,
  style = {},
  onClick = () => {},
  type,
  loading,
  disableDefaultStyles,
}) {
  const handlePress = (e) => {
    e.preventDefault();
    // console.log("Button pressed");
    onClick();
  };

  const debouncedHandlePress = useMemo(() => {
    return debounce(handlePress, 500);
  });

  // console.log("Button loading:", loading);

  return (
    <button
      className={(disableDefaultStyles ? "" : "app-button ") + className}
      style={style}
      onClick={debouncedHandlePress}
      type={type}
      disabled={loading}
    >
      {children}
    </button>
  );
}

export default DebouncedButton;
