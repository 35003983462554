import "./ProfileStats.css";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import Loader from "../../../components/Loader";
import { PROFILE_EVENTS } from "../../../api/profile";
import {
  FootballStats,
  BasketballStats,
  CricketStats,
  BadmintonStats,
} from "../Stats";
import { ReactComponent as EqualizerIcon } from "../../../icons/equalizer.svg";
import { ReactComponent as UpDownIcon } from "../../../icons/up-down.svg";
import { ReactComponent as FootballIcon } from "../../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../../icons/basketball.svg";
import { ReactComponent as Basketball3sIcon } from "../../../icons/basketball-3x3.svg";
import { ReactComponent as Basketball5sIcon } from "../../../icons/basketball-5x5.svg";
import { ReactComponent as BadmintonIcon } from "../../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../../icons/tennis.svg";
import { useAppState } from "../../../utils/appState";
import Filters from "../../Filters";
import captureException from "../../../utils/captureException";

const sportsIcons = {
  Football: <FootballIcon className="sports-icon-svg" />,
  Basketball: <BasketballIcon className="sports-icon-svg" />,
  "Basketball 3x3": <Basketball3sIcon className="sports-icon-svg" />,
  "Basketball 5x5": <Basketball5sIcon className="sports-icon-svg" />,
  Cricket: <CricketIcon className="sports-icon-svg" />,
  Badminton: <BadmintonIcon className="sports-icon-svg" />,
  Tennis: <TennisIcon className="sports-icon-svg" />,
};

export default function ProfileStats({ id }) {
  const [, setAppState] = useAppState();
  const [sport, setSport] = useState("");
  const [events, setEvents] = useState([]);

  const { loading, error, data, refetch } = useQuery(PROFILE_EVENTS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileStats",
        query: "PROFILE_EVENTS",
      },
    });
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const userEvents = data?.profile?.events;
  // console.log(userEvents);

  const userSports = userEvents
    ?.map((e) => e.sport?.name)
    .filter(function (value, index, self) {
      return value && self.indexOf(value) === index;
    });

  const selectedSport = sport || userSports?.[0];
  // console.log("SelectedSport:", selectedSport);
  // console.log("User Events:", userEvents);

  return (
    <div className="profile-stats-mobile">
      <div
        className="profile-stats-mobile__filters"
        onClick={() => {
          setAppState({
            modal: (
              <Filters
                defaultSelected={{
                  Sport: selectedSport,
                  Events: events,
                }}
                categories={[
                  {
                    label: "Sport",
                    items: userSports.map((s) => ({
                      label: s,
                    })),
                    itemFilter: (selected) => {
                      return (item) => item;
                    },
                    onSelect: (item, selected) => {
                      setSport(item.label);
                      return {
                        ...selected,
                        Sport: item.label,
                      };
                    },
                    isSelected: (selected, item) => {
                      return item.label === selected;
                    },
                  },
                  {
                    label: "Events",
                    items: userEvents.map((e) => ({ ...e, label: e.name })),
                    // .filter((e) => e.sport.name === selectedSport)
                    // .map((e) => ({ ...e, label: e.name })),
                    itemFilter: (selected) => {
                      return (item) => item.sport.name === selected["Sport"];
                    },
                    onSelect: (item, selected) => {
                      let newFilters;

                      if (selected["Events"].find((x) => x.id === item.id)) {
                        newFilters = selected["Events"].filter(
                          (x) => x.id !== item.id
                        );
                      } else {
                        newFilters = [...selected["Events"], item];
                      }

                      setEvents(newFilters);

                      // console.log("New filters:", newFilters);
                      return {
                        ...selected,
                        Events: newFilters,
                      };
                    },
                    isSelected: (selected, item) => {
                      return selected.find((x) => x.id === item.id);
                    },
                  },
                ]}
              />
            ),
          });
        }}
      >
        <div className="profile-stats-mobile__filters__sport">
          {selectedSport && sportsIcons[selectedSport] && (
            <div className="profile-stats-mobile__filters__sport__icon">
              {sportsIcons[selectedSport]}
            </div>
          )}
          <div className="profile-stats-mobile__filters__sport__name">
            {selectedSport || "Select a sport"}
          </div>
          <div className="profile-stats-mobile__filters__sport__icon">
            <UpDownIcon className="profile-stats-mobile__filters__sport__icon__svg" />
          </div>
        </div>

        <div className="profile-stats-mobile__filters__filters-btn">
          <EqualizerIcon className="profile-stats-mobile__filters__filters-btn__icon" />{" "}
          Filters
        </div>
      </div>
      {!userEvents?.length && (
        <div style={{ gridColumnStart: 1, gridColumnEnd: 9 }}>
          <div className="no-data">
            <div>There are no stats for this page yet.</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                refetch();
              }}
            >
              Check again
            </div>
          </div>
        </div>
      )}

      {selectedSport === "Football" && (
        <FootballStats id={id} events={events.map((x) => x.id)} />
      )}

      {selectedSport?.includes("Basketball") && (
        <BasketballStats
          id={id}
          sport={selectedSport}
          events={events.map((x) => x.id)}
        />
      )}

      {selectedSport === "Cricket" && (
        <CricketStats id={id} events={events.map((x) => x.id)} />
      )}

      {selectedSport === "Badminton" && (
        <BadmintonStats id={id} events={events.map((x) => x.id)} />
      )}
    </div>
  );
}
