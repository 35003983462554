import { gql } from "@apollo/client";

const DELETE_FIXTURE_STAT = gql`
  mutation deleteFixtureStat($id: ID!) {
    deleteFixtureStat(id: $id) {
      id
    }
  }
`;

export default DELETE_FIXTURE_STAT;
