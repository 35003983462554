import { gql } from "@apollo/client";

const ORGANIZATION_SERVICES = gql`
  query organizationServices($id: ID!, $take: Int) {
    organization(id: $id) {
      id
      services(take: $take) {
        id
        name
        description
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default ORGANIZATION_SERVICES;
