import "./Fixture.css";
import { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import useRouterQuery from "../../utils/useRouterQuery";
import {
  FixtureSummary,
  FixtureLineups,
  FixturePhotos,
  FixtureVideos,
  FixtureReport,
  FixtureStats,
} from "../../mobileComponents/Fixture";
import { useQuery } from "@apollo/client";
import { FIXTURE } from "../../api/fixture";
import { CAN_MANAGE_FIXTURE } from "../../api/user";
import Spinner from "../../components/Spinner";
import moment from "moment";
import AppLink from "../../components/AppLink";
import { ReactComponent as FootballIcon } from "../../icons/football.svg";
import { Fragment } from "react";
import { usePageTracking } from "../../utils/usePageTracking";
import Avatar from "../../components/Avatar/Avatar";
import captureException from "../../utils/captureException";

const fixtureTabs = [
  {
    label: "Summary",
    component: FixtureSummary,
    hide: (fixture) => false,
    key: "summary",
  },
  {
    label: "Lineups",
    component: FixtureLineups,
    hide: (fixture) =>
      fixture.event.participantType !== "TEAM" ||
      fixture.event.sport.name === "Badminton" ||
      fixture.event.sport.name === "Tennis",
    key: "lineups",
  },
  {
    label: "Photos",
    component: FixturePhotos,
    hide: (fixture) => false,
    key: "photos",
  },
  {
    label: "Videos",
    component: FixtureVideos,
    hide: (fixture) => false,
    key: "videos",
  },
  {
    label: "Statistics",
    component: FixtureStats,
    hide: (fixture) => false,
    key: "statistics",
  },
  {
    label: "Report",
    component: FixtureReport,
    hide: (fixture) => false,
    key: "report",
  },
];

function FixtureHighlights({ highlights, homeTeam, awayTeam }) {
  const [expanded, setExpanded] = useState(false);
  const goals = highlights
    .filter((h) => h.statType.name === "Goal" || h.statType.name === "Own Goal")
    .sort((a, b) => a.timeStamp - b.timeStamp);

  if (!goals?.length) {
    return <div />;
  }

  const sliceValue = expanded ? goals.length : 3;

  return (
    <div className="fixture-page-mobile__highlights">
      {goals.slice(0, sliceValue).map((g) => (
        <div className="fixture-page-mobile__highlights__row">
          <div className="fixture-page-mobile__highlights__row__meta home">
            {((g.statType?.name === "Goal" &&
              g.organization?.id === homeTeam?.id) ||
              (g.statType?.name === "Own Goal" &&
                g.organization?.id === awayTeam?.id)) && (
              <Fragment>
                <div className="fixture-page-mobile__highlights__row__meta__icon">
                  <FootballIcon
                    className={
                      "fixture-page-mobile__highlights__row__meta__icon__svg"
                    }
                  />
                </div>
                <div className="fixture-page-mobile__highlights__row__meta__name">
                  {g.profile?.name}
                </div>
              </Fragment>
            )}
          </div>

          <div className="fixture-page-mobile__highlights__row__timestamp">
            {g.timeStamp}'
          </div>

          <div className="fixture-page-mobile__highlights__row__meta away">
            {((g.statType?.name === "Goal" &&
              g.organization?.id === awayTeam?.id) ||
              (g.statType?.name === "Own Goal" &&
                g.organization?.id === homeTeam?.id)) && (
              <Fragment>
                <div className="fixture-page-mobile__highlights__row__meta__icon">
                  <FootballIcon
                    className={
                      "fixture-page-mobile__highlights__row__meta__icon__svg"
                    }
                  />
                </div>
                <div
                  className="fixture-page-mobile__highlights__row__meta__name"
                  style={{ textAlign: "right" }}
                >
                  {g.profile?.name}{" "}
                  {g.relationshipsFrom.find(
                    (r) => r.to?.statType?.name === "Penalty Scored"
                  )
                    ? "(P)"
                    : ""}
                  {g.statType?.name === "Own Goal" ? "(OG)" : ""}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      ))}

      {goals.length > 3 && (
        <div className="fixture-page-mobile__highlights__row">
          <div className="fixture-page-mobile__highlights__row__meta home"></div>

          <div
            className="fixture-page-mobile__highlights__row__timestamp"
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          >
            <EllipsisIcon
              style={{ height: "12px", fill: "var(--blue-main)" }}
            />
          </div>

          <div className="fixture-page-mobile__highlights__row__meta away"></div>
        </div>
      )}
    </div>
  );
}

function Fixture() {
  usePageTracking({ title: "Fixture" });
  const { id } = useParams();
  const query = useRouterQuery();
  const location = useLocation();
  const history = useHistory();
  const tab = query.get("t") || "summary";
  const selectedTab = fixtureTabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const authQuery = useQuery(CAN_MANAGE_FIXTURE, {
    variables: { id },
  });

  const { loading, data, error } = useQuery(FIXTURE, { variables: { id } });

  if (authQuery.error) {
    captureException({
      error: authQuery.error,
      info: {
        type: "query",
        component: "Fixture",
        query: "CAN_MANAGE_FIXTURE",
      },
    });
    console.log(authQuery.error);
  }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Fixture",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const fixture = data.fixture;
  const canManageFixture = authQuery?.data?.canManageFixture?.authorized;
  const participantType = fixture.event.participantType;
  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeScore = fixture.placements?.find((p) => {
    if (participantType === "INDIVIDUAL") {
      return p.profile?.id === participants[0].profile?.id;
    } else {
      return p.organization?.id === participants[0].organization?.id;
    }
  });

  const awayScore = fixture.placements?.find((p) => {
    if (participantType === "INDIVIDUAL") {
      return p.profile?.id === participants[1].profile?.id;
    } else {
      return p.organization?.id === participants[1].organization?.id;
    }
  });

  const homeLiveScore = fixture.highlights.filter(
    (h) =>
      (h.statType?.name === "Goal" &&
        h.organization?.id === participants[0].organization?.id) ||
      (h.statType?.name === "Own Goal" &&
        h.organization?.id === participants[1].organization?.id)
  ).length;

  const awayLiveScore = fixture.highlights.filter(
    (h) =>
      (h.statType?.name === "Goal" &&
        h.organization?.id === participants[1].organization?.id) ||
      (h.statType?.name === "Own Goal" &&
        h.organization?.id === participants[0].organization?.id)
  ).length;

  const isLive =
    fixture.scheduledStart &&
    fixture.scheduledEnd &&
    moment().isAfter(fixture.scheduledStart) &&
    moment().isBefore(fixture.scheduledEnd);

  const completedText = {
    Cricket: "Final",
    "Box Cricket": "Final",
    Badminton: "Final",
  };

  return (
    <div className="fixture-page-mobile" id="fixture-scroll-container">
      <div className="fixture-page-mobile__nav">
        <div
          className="fixture-page-mobile__nav__back-btn"
          onClick={() => {
            if (location.key) {
              history.goBack();
            } else {
              history.replace("/app/events");
            }
          }}
        >
          <ArrowLeftIcon style={{ height: "16px", stroke: "var(--dark-4)" }} />
        </div>
      </div>

      <div
        className="fixture-page-mobile__event"
        onClick={() => {
          history.push("/app/event/" + fixture.event.id);
        }}
      >
        {fixture.event.name}
      </div>

      <div className="fixture-page-mobile__header">
        <div className="fixture-page-mobile__header__participant home">
          <div className="fixture-page-mobile__header__participant__meta">
            <div className="fixture-page-mobile__header__participant__meta__image">
              {(participants[0].organization?.profilePhoto ||
                participants[0].profile?.profilePhoto) && (
                <Avatar
                  media={
                    participants[0].organization?.profilePhoto ||
                    participants[0].profile?.profilePhoto
                  }
                />
              )}
            </div>

            <AppLink
              to={
                `/app/${participantType === "TEAM" ? "page" : "profile"}/` +
                (participants[0].organization?.id ||
                  participants[0].profile?.id)
              }
            >
              <div className="fixture-page-mobile__header__participant__meta__name">
                {participants[0].organization?.name ||
                  participants[0].profile?.name}
              </div>
            </AppLink>
          </div>

          {fixture.event.sport.name !== "Badminton" ? (
            <div className="fixture-page-mobile__header__participant__result">
              {homeScore
                ? homeScore.valueString || homeScore.value || 0
                : homeLiveScore || (awayLiveScore ? "0" : "")}
            </div>
          ) : (
            <div className="fixture-page-mobile__header__participant__result"></div>
          )}
        </div>

        <div className="fixture-page-mobile__header__seperator">
          {fixture.event.sport.name === "Badminton" ? (
            <div className="badminton-score">
              {" "}
              {fixture.badmintonBoxScore
                .filter((x) => x.homeScore || x.awayScore)
                .map((x) => x.homeScore + "-" + x.awayScore)
                .join(", ")}{" "}
            </div>
          ) : fixture.event.sport.name === "Tennis" ? (
            <div className="badminton-score">
              {" "}
              {fixture.tennisBoxScore
                .filter((x) => x.homeScore || x.awayScore)
                .map((x) => x.homeScore + "-" + x.awayScore)
                .join(", ")}{" "}
            </div>
          ) : (
            <div>
              {fixture.completed ? (
                <>{completedText[fixture.event?.sport?.name] || "FT"}</>
              ) : isLive ? (
                <div className="live-indicator">LIVE</div>
              ) : (
                "-"
              )}
            </div>
          )}
        </div>

        <div className="fixture-page-mobile__header__participant away">
          <div className="fixture-page-mobile__header__participant__meta">
            <div className="fixture-page-mobile__header__participant__meta__image">
              {(participants[1].organization?.profilePhoto ||
                participants[1].profile?.profilePhoto) && (
                <Avatar
                  media={
                    participants[1].organization?.profilePhoto ||
                    participants[1].profile?.profilePhoto
                  }
                />
              )}
            </div>

            <AppLink
              to={
                `/app/${participantType === "TEAM" ? "page" : "profile"}/` +
                (participants[1].organization?.id ||
                  participants[1].profile?.id)
              }
            >
              <div
                className="fixture-page-mobile__header__participant__meta__name"
                style={{ textAlign: "right" }}
              >
                {participants[1].organization?.name ||
                  participants[1].profile?.name}
              </div>
            </AppLink>
          </div>

          {fixture.event.sport.name !== "Badminton" ? (
            <div className="fixture-page-mobile__header__participant__result">
              {awayScore
                ? awayScore.valueString || awayScore.value || 0
                : awayLiveScore || (homeLiveScore ? "0" : "")}
            </div>
          ) : (
            <div className="fixture-page-mobile__header__participant__result"></div>
          )}
        </div>
      </div>

      <FixtureHighlights
        highlights={fixture.highlights}
        homeTeam={participants[0]?.organization}
        awayTeam={participants[1]?.organization}
      />

      <div className="fixture-page-mobile__tabs">
        {fixtureTabs.map((t, i) => {
          if (t.hide(fixture)) {
            return <span />;
          } else {
            return (
              <div
                key={"ev-tab-" + i}
                className={
                  "fixture-page-mobile__tabs__tab " +
                  (selectedTab.key === t.key ? "active" : "")
                }
                onClick={() => {
                  history.replace(location.pathname + "?t=" + t.key);
                }}
              >
                {t.label}
              </div>
            );
          }
        })}
      </div>

      <div className="fixture-page-mobile__body">
        <SelectedComponent
          id={id}
          fixture={fixture}
          canManageFixture={canManageFixture}
        />
      </div>
    </div>
  );
}

export default Fixture;
