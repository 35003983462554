import { gql } from "@apollo/client";

const CREATE_SERVICE = gql`
  mutation createService($data: CreateServiceInput!) {
    createService(data: $data) {
      id
      name
      description
      profilePhoto {
        id
        filename
      }
    }
  }
`;

export default CREATE_SERVICE;
