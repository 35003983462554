import "./BasketballStats.css";
import { useQuery } from "@apollo/client";
import {
  ORGANIZATION_STATS,
  ORGANIZATION_AWARDS,
} from "../../../../api/organization";
import Square from "../../../Square";
import VerticalText from "../../../VerticalText";
import moment from "moment";
import Avatar from "../../../../components/Avatar";
import captureException from "../../../../utils/captureException";

function OrganizationRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record-mobile">
      <div className="organization-record-mobile__meta">
        <div className="organization-record-mobile__meta__title">RECORD</div>

        <div className="organization-record-mobile__meta__highlight">
          <div className="organization-record-mobile__meta__highlight__value">
            {total}
          </div>
          <div className="organization-record-mobile__meta__highlight__label">
            Games Played
          </div>
        </div>
      </div>

      <div className="organization-record-mobile__items">
        <div className="organization-record-mobile__items__item">
          <div className="organization-record-mobile__items__item__value">
            {wins}
          </div>

          <div className="organization-record-mobile__items__item__graph">
            <div className="organization-record-mobile__items__item__graph__track">
              <div
                className="organization-record-mobile__items__item__graph__fill"
                style={{ height: total ? (wins / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record-mobile__items__item__label">
            W
          </div>
        </div>

        <div className="organization-record-mobile__items__item">
          <div className="organization-record-mobile__items__item__value">
            {losses}
          </div>

          <div className="organization-record-mobile__items__item__graph">
            <div className="organization-record-mobile__items__item__graph__track">
              <div
                className="organization-record-mobile__items__item__graph__fill"
                style={{ height: total ? (losses / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record-mobile__items__item__label">
            L
          </div>
        </div>

        <div className="organization-record-mobile__items__item">
          <div className="organization-record-mobile__items__item__value">
            {draws}
          </div>

          <div className="organization-record-mobile__items__item__graph">
            <div className="organization-record-mobile__items__item__graph__track">
              <div
                className="organization-record-mobile__items__item__graph__fill"
                style={{ height: total ? (draws / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record-mobile__items__item__label">
            D
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganizationOffenseStats({
  gamesPlayed,
  gamesWon,
  points,
  shots,
  shotsMade,
}) {
  return (
    <div className="profile-attack-stats-mobile">
      <div className="profile-attack-stats-mobile__title">OFFENSE</div>
      <div className="profile-attack-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {gamesPlayed
                  ? (points / gamesPlayed) % 1
                    ? (points / gamesPlayed).toFixed(1)
                    : points / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Points <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {gamesPlayed
                  ? (shots / gamesPlayed) % 1
                    ? (shots / gamesPlayed).toFixed(1)
                    : shots / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Shots <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        {/* <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {assists}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Penalties
                <br /> Won
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {keyPasses}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Penalties <br /> Scored
              </div>
            </div>
          )}
        </Square> */}

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {shots
                  ? (shotsMade / shots) % 1
                    ? ((shotsMade / shots) * 100).toFixed(1)
                    : (shotsMade / shots) * 100
                  : 0}
                %
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Shot <br /> Accuracy
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{ height: dimensions.width + "px" }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {gamesPlayed
                  ? Math.round((gamesWon / gamesPlayed) * 100) + "%"
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Win Rate <br /> (%)
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats-mobile__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"ATTACK"}
              fontStyle={{
                fontSize: "64px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "49px",
                height: "64px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function OrganizationShootingStats({
  onePointFgm = 0,
  onePointFga = 0,
  twoPointFgm = 0,
  twoPointFga = 0,
  threePointFgm = 0,
  threePointFga = 0,
  ftm = 0,
  fta = 0,
}) {
  const onePointFgp = onePointFga ? (onePointFgm / onePointFga) * 100 : 0;
  const twoPointFgp = twoPointFga ? (twoPointFgm / twoPointFga) * 100 : 0;
  const threePointFgp = threePointFga
    ? (threePointFgm / threePointFga) * 100
    : 0;

  const ftp = fta ? (ftm / fta) * 100 : 0;

  return (
    <div className="profile-shooting-stats-mobile">
      <div className="profile-shooting-stats-mobile__title">SHOOTING</div>

      <div className="profile-shooting-stats-mobile__items">
        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              1 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {onePointFgm || 0} / {onePointFga || 0} ({onePointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: onePointFga ? onePointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              2 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {twoPointFgm || 0} / {twoPointFga || 0} ({twoPointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: twoPointFga ? twoPointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              3 Pt. Field Goals
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {threePointFgm || 0} / {threePointFga || 0} (
              {threePointFgp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: threePointFga ? threePointFgp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="profile-shooting-stats-mobile__items__item">
          <div className="profile-shooting-stats-mobile__items__item__meta">
            <div className="profile-shooting-stats-mobile__items__item__meta__key">
              Free Throws
            </div>

            <div className="profile-shooting-stats-mobile__items__item__meta__value">
              {ftm || 0} / {fta || 0} ({ftp.toFixed(1)}
              %)
            </div>
          </div>

          <div className="profile-shooting-stats-mobile__items__item__graph">
            <div className="profile-shooting-stats-mobile__items__item__graph__track">
              <div
                className="profile-shooting-stats-mobile__items__item__graph__fill"
                style={{ width: fta ? ftp + "%" : "0%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganizationDefenseStats({
  gamesPlayed,
  steals,
  blocks,
  turnovers,
  fouls,
}) {
  return (
    <div className="profile-attack-stats-mobile">
      <div className="profile-attack-stats-mobile__title">DEFENSE</div>
      <div className="profile-attack-stats-mobile__items">
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {blocks
                  ? (blocks / gamesPlayed) % 1
                    ? (blocks / gamesPlayed).toFixed(1)
                    : blocks / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Blocks <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {steals
                  ? (steals / gamesPlayed) % 1
                    ? (steals / gamesPlayed).toFixed(1)
                    : steals / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Steals <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {turnovers
                  ? (turnovers / gamesPlayed) % 1
                    ? (turnovers / gamesPlayed).toFixed(1)
                    : turnovers / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Turnovers <br /> (per game)
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats-mobile__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats-mobile__items__item__value">
                {fouls
                  ? (fouls / gamesPlayed) % 1
                    ? (fouls / gamesPlayed).toFixed(1)
                    : fouls / gamesPlayed
                  : 0}
              </div>
              <div className="profile-attack-stats-mobile__items__item__label">
                Fouls <br /> (per game)
              </div>
            </div>
          )}
        </Square>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div className="profile-attack-stats-mobile__graphic" ref={squareRef}>
            <VerticalText
              height={dimensions.width}
              width={dimensions.height}
              text={"DEFENSE"}
              fontStyle={{
                fontSize: "64px",
                letterSpacing: "-6px",
                fontWeight: "700",
                fontStyle: "italic",
                lineHeight: "49px",
                height: "64px",
              }}
            />
          </div>
        )}
      </Square>
    </div>
  );
}

function FormItem({ id, fixture }) {
  const orgPlacement = fixture.placements?.find(
    (p) => p.organization.id === id
  );
  const opponentPlacement = fixture.placements?.find(
    (p) => p.organization.id !== id
  );

  const opponentRole = fixture.roles.find((r) => r.organization.id !== id);
  const result =
    (orgPlacement?.value || 0) > (opponentPlacement?.value || 0)
      ? "W"
      : (orgPlacement?.value || 0) < (opponentPlacement?.value || 0)
      ? "L"
      : "D";

  return (
    <div className="organization-form-mobile__items__item">
      <div className="organization-form-mobile__items__item__meta">
        <div className="organization-form-mobile__items__item__meta__image">
          {opponentRole?.organization?.profilePhoto && (
            <Avatar media={opponentRole.organization.profilePhoto} />
          )}
        </div>

        <div className="organization-form-mobile__items__item__meta__text">
          <div className="organization-form-mobile__items__item__meta__text__primary">
            {opponentRole?.organization?.name}
          </div>

          <div className="organization-form-mobile__items__item__meta__text__secondary">
            {fixture.scheduledStart
              ? moment(fixture.scheduledStart).format("Do MMM YYYY")
              : "-"}
          </div>
        </div>
      </div>

      <div className="organization-form-mobile__items__item__result">
        <div
          className={
            "organization-form-mobile__items__item__result__text " +
            (result === "W" ? "green" : result === "L" ? "red" : "orange")
          }
        >
          {result === "W" ? "WIN" : result === "L" ? "LOSS" : "DRAW"}
        </div>

        <div className="organization-form-mobile__items__item__result__score">
          {orgPlacement?.value || 0} - {opponentPlacement?.value || 0}
        </div>
      </div>
    </div>
  );
}

function OrganizationFormStats({ id, form }) {
  // const formFixtures = form.filter((f) =>
  //   f.roles.find((r) => r.organization.id !== id)
  // );

  // console.log("Form:", form);

  return (
    <div className="organization-form-mobile">
      <div className="organization-form-mobile__title">FORM</div>

      <div className="organization-form-mobile__items">
        {!form?.length && (
          <div className="no-data">No previous matches found</div>
        )}
        {form.map((fixture) => (
          <FormItem id={id} fixture={fixture} />
        ))}
      </div>
    </div>
  );
}

function OrganizationAwards({ id, sport }) {
  const { loading, error, data } = useQuery(ORGANIZATION_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.BasketballStats.OrganizationAwards",
        query: "ORGANIZATION_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const organization = data.organization;
  const placements = organization?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats-mobile">
      <div className="profile-awards-stats-mobile__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats-mobile__items">
          <div className="no-data">No awards for this team yet.</div>
        </div>
      )}

      <div className="profile-awards-stats-mobile__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats-mobile__items__item">
              <div className="profile-awards-stats-mobile__items__item__img"></div>
              <div className="profile-awards-stats-mobile__items__item__desc">
                <div className="profile-awards-stats-mobile__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats-mobile__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function BasketballStats({ id, sport, events }) {
  const { loading, error, data } = useQuery(ORGANIZATION_STATS, {
    variables: {
      id,
      sport: sport || "Basketball",
      statTypes: [
        "1FGM",
        "1FGA",
        "2FGM",
        "2FGA",
        "3FGA",
        "3FGM",
        "FTM",
        "FTA",
        "PTS",
        "BLK",
        "STL",
        "T/O",
        "FOULS",
      ],
      events,
      rolesWhereInput: { type: { name: "PARTICIPANT" } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.BasketballStats",
        query: "ORGANIZATION_STATS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const { totalGamesPlayed, form } = data?.organization?.stats || {};

  const record = JSON.parse(data?.organization?.stats?.record?.data || "{}");
  const aggregationsData = JSON.parse(
    data?.organization?.stats?.aggregations?.data || "{}"
  );

  // console.log("OS", aggregationsData);

  return (
    <div className="organization-stats-mobile__football">
      <OrganizationRecordStats
        total={totalGamesPlayed}
        wins={record.wins}
        losses={record.losses}
        draws={record.draws}
      />
      <OrganizationOffenseStats
        gamesPlayed={totalGamesPlayed}
        gamesWon={record.wins}
        points={aggregationsData["PTS"] || 0}
        shots={
          (aggregationsData["1FGA"] || 0) +
          (aggregationsData["2FGA"] || 0) +
          (aggregationsData["3FGA"] || 0)
        }
        shotsMade={
          (aggregationsData["1FGM"] || 0) +
          (aggregationsData["2FGM"] || 0) +
          (aggregationsData["3FGM"] || 0)
        }
      />
      <OrganizationShootingStats
        onePointFga={aggregationsData["1FGA"] || 0}
        onePointFgm={aggregationsData["1FGM"] || 0}
        twoPointFga={aggregationsData["2FGA"] || 0}
        twoPointFgm={aggregationsData["2FGM"] || 0}
        threePointFga={aggregationsData["3FGA"] || 0}
        threePointFgm={aggregationsData["3FGM"] || 0}
        fta={aggregationsData["FTA"] || 0}
        ftm={aggregationsData["FTM"] || 0}
      />

      <OrganizationFormStats id={id} form={form} />
      <OrganizationDefenseStats
        gamesPlayed={totalGamesPlayed}
        steals={aggregationsData["STL"] || 0}
        blocks={aggregationsData["BLK"] || 0}
        turnovers={aggregationsData["T/O"] || 0}
        fouls={aggregationsData["FOULS"] || 0}
      />

      <OrganizationAwards id={id} sport={"Basketball"} />
    </div>
  );
}
