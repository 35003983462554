import "./FixturePhotos.css";
import { FIXTURE_MEDIA } from "../../../api/fixture";
import { CREATE_MEDIA_SET } from "../../../api/media";
import PhotosCard from "../../PhotosCard";
import { useAppState } from "../../../utils/appState";
import { useQuery, useMutation, gql } from "@apollo/client";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import Uploader from "../../../components/Uploader";
import captureException from "../../../utils/captureException";

export default function FixturePhotos({ id, canManageFixture }) {
  const [, setAppState] = useAppState();

  const { loading, error, data } = useQuery(FIXTURE_MEDIA, {
    variables: { id, type: "IMAGE" },
  });

  const [createMediaSet] = useMutation(CREATE_MEDIA_SET, {
    update: (cache, { data: { createMediaSet } }) => {
      const q = cache.readQuery({
        query: FIXTURE_MEDIA,
        variables: { id, type: "IMAGE" },
      });

      cache.writeQuery({
        query: FIXTURE_MEDIA,
        variables: { id, type: "IMAGE" },
        data: {
          fixture: {
            ...q.fixture,
            mediaSet: {
              ...createMediaSet,
            },
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FixturePhotos",
          mutation: "CREATE_MEDIA_SET",
        },
      });
      console.log(error);
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixturePhotos",
        query: "FIXTURE_MEDIA",
      },
    });
    console.error(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const mediaSet = data?.fixture?.mediaSet;

  return (
    <div className="fixture-photos-mobile">
      {canManageFixture && (
        <div className="upload-btn-container">
          <Button
            className="primary blue medium"
            style={{ marginTop: "16px" }}
            onClick={async () => {
              let mediaSetId = mediaSet?.id;

              if (!mediaSet) {
                const newMediaSet = await createMediaSet({
                  variables: {
                    name: "Fixture Album",
                    sourceId: id,
                    sourceType: "fixture",
                  },
                });

                mediaSetId = newMediaSet.id;
                // console.log("New Media Set:", newMediaSet);
              }

              setAppState({
                modal: (
                  <div className="modal-form-mobile-wrapper">
                    <Uploader
                      accept=".jpg,.png,.jpeg"
                      label="Profile Image(s)"
                      multiple
                      sourceId={id}
                      sourceType={"fixture"}
                      mediaSetId={mediaSetId}
                      onCreateMedia={(cache, createMedia) => {
                        cache.modify({
                          id: cache.identify(data.fixture),
                          fields: {
                            media(existingMediaRefs, { readField }) {
                              const newMediaRef = cache.writeFragment({
                                data: createMedia.media,
                                fragment: gql`
                                  fragment NewMedia on Media {
                                    id
                                    createdAt
                                    type
                                    subtype
                                    isExternal
                                    filename
                                    public
                                    aspectRatio
                                    clapCount
                                    isClapped
                                    canManageMedia
                                    source {
                                      id
                                      fixture {
                                        id
                                        event {
                                          id
                                          organizedBy {
                                            id
                                            name
                                            profilePhoto {
                                              id
                                              filename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                `,
                              });

                              if (
                                existingMediaRefs.some(
                                  (ref) =>
                                    readField("id", ref) ===
                                    createMedia.media.id
                                )
                              ) {
                                return existingMediaRefs;
                              }

                              return [newMediaRef, ...existingMediaRefs];
                            },
                          },
                        });
                      }}
                    />
                  </div>
                ),
              });
            }}
          >
            Upload Images
          </Button>
        </div>
      )}

      <PhotosCard
        query={FIXTURE_MEDIA}
        vars={{
          id,
          type: "IMAGE",
        }}
        getPhotos={(data) => data?.fixture?.media || []}
        totalPhotos={(data) => data?.fixture?.photosCount || 0}
        noData={`No photos added for this fixture yet.`}
        filters={false}
        processFilters={(filters) => {
          return {};
        }}
        onDelete={(cache, { data: { deleteMedia } }) => {
          // cache.modify({
          //   id: cache.identify(data.fixture),
          //   fields: {
          //     media(existingMediaRefs, { readField }) {
          //       return existingMediaRefs.filter(
          //         (mediaRef) => deleteMedia.id !== readField("id", mediaRef)
          //       );
          //     },
          //   },
          // });
        }}
        style={{
          gridColumnStart: 3,
          gridColumnEnd: 11,
        }}
      />
    </div>
  );
}
