import { gql } from "@apollo/client";

const ORGANIZATION = gql`
  query organizationQuery($id: ID!) {
    organization(id: $id) {
      id
      name
      handle
      description
      types {
        id
        name
      }
      profilePhoto {
        id
        filename
      }
      profilePhotos {
        id
        filename
      }
      coverPhoto {
        id
        filename
      }
      coverPhotos {
        id
        filename
      }
    }
  }
`;

export default ORGANIZATION;
