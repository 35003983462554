import { gql } from "@apollo/client";

const EVENT_PLACEMENTS = gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      id
      participantType
      placements {
        id
        order
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        awardType {
          id
        }
        eventPart {
          id
        }
        fixture {
          id
        }
      }
    }
  }
`;

export default EVENT_PLACEMENTS;
