import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../utils/appState";
import { client } from "../../utils/client";

function Logout() {
  const history = useHistory();
  const [, setAppState] = useAppState();
  useEffect(() => {
    localStorage.setItem("sp_auth_token", "");
    localStorage.setItem("sp_expires_at", "");
    localStorage.setItem("sp_name", "");
    setAppState({ isLoggedIn: false });
    client.cache.reset();

    history.push("/");
  });

  return <div />;
}

export default Logout;
