import { gql } from "@apollo/client";

const CREATE_STAT = gql`
  mutation createStat($data: CreateStatInput!) {
    createStat(data: $data) {
      id
      createdAt
      timeStamp
      value
      stringValue
      statType {
        id
        name
      }
      profile {
        id
        name
      }
      organization {
        id
        name
      }
      fixturePeriod {
        id
        order
        label
      }
      relationshipsFrom {
        id
        to {
          id
          createdAt
          stringValue
          value
          timeStamp
          statType {
            id
            name
          }
          profile {
            id
            name
          }
          organization {
            id
            name
          }
          fixturePeriod {
            id
            label
          }
        }
      }
      relationshipsTo {
        id
        from {
          id
          createdAt
          stringValue
          value
          timeStamp
          statType {
            id
            name
          }
          profile {
            id
            name
          }
          organization {
            id
            name
          }
          fixturePeriod {
            id
            label
          }
        }
      }
    }
  }
`;

export default CREATE_STAT;
