// V1
import { useState, Fragment } from "react";
import "./DirectoryCategories.css";
// import Card from "../../Card";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_TYPES } from "../../../api/organization";
import Placeholder from "../../../components/Placeholder";
import { ReactComponent as CaretDownIcon } from "../../../icons/caret-down.svg";
import { ReactComponent as EqualizerIcon } from "../../../icons/equalizer.svg";
import { ReactComponent as PinIcon } from "../../../icons/pin.svg";
import captureException from "../../../utils/captureException";

function DirectoryCategories({
  onCategorySelect,
  onFiltersClick,
  onLocationClick,
  defaultValue,
}) {
  const [selected, setSelected] = useState(defaultValue || "");
  const { loading, error, data } = useQuery(ORGANIZATION_TYPES);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "DirectoryCategories",
        query: "ORGANIZATION_TYPES",
      },
    });
    return null;
  }

  if (loading || !data) {
    return (
      <Fragment>
        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />

        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />
        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />
        <Placeholder
          style={{
            height: "24px",
            width: "76px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
          pulse={true}
        />
      </Fragment>
    );
  }

  const categories = data?.organizationTypes;

  if (!categories) return null;

  return (
    <Fragment>
      <div
        className="directory-page-mobile__filters__item"
        style={{ paddingLeft: "4px", paddingRight: "4px", fontWeight: "600" }}
        onClick={onFiltersClick}
      >
        <EqualizerIcon style={{ marginRight: "8px" }} />
        Filters
        <CaretDownIcon style={{ marginLeft: "4px" }} />
      </div>

      <div
        className="directory-page-mobile__filters__item"
        style={{ paddingLeft: "4px", paddingRight: "4px", fontWeight: "600" }}
        onClick={onLocationClick}
      >
        <PinIcon
          style={{
            stroke: "var(--light-4)",
            marginRight: "8px",
            height: "10px",
            width: "10px",
          }}
        />
        Location
        <CaretDownIcon style={{ marginLeft: "4px" }} />
      </div>

      {categories?.map((c) => (
        <div
          key={c.id}
          className={
            "directory-page-mobile__filters__item " +
            (selected === c.id ? "selected" : "")
          }
          onClick={() => {
            if (selected !== c.id) {
              setSelected(c.id);
            } else {
              setSelected("");
            }

            onCategorySelect(c.id);
          }}
        >
          {c.name}
        </div>
      ))}
    </Fragment>
  );
}

export default DirectoryCategories;
