import "./MobileLayout.css";
import { useState } from "react";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import { ReactComponent as DirectoryIcon } from "../../icons/directory.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as NotificationIcon } from "../../icons/notifications.svg";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";

import { Switch, Route, Link, useLocation, useHistory } from "react-router-dom";
// import Input from "../../components/Input";
import Spinner from "../../components/Spinner";
import { useQuery } from "@apollo/client";
import { USER_PROFILE } from "../../api/user";

import {
  Home,
  Event,
  ManageEvent,
  Organization,
  Profile,
  Fixture,
  Events,
  Directory,
  Search,
  CreatePage,
  CreateEvent,
  PageOnboarding,
  EventOnboarding,
  Notifications,
  Requests,
  Register,
} from "../../mobileScreens";
import { useAppState } from "../../utils/appState";
import Avatar from "../../components/Avatar/Avatar";
import captureException from "../../utils/captureException";

function MobileLayout() {
  const [{ modal }] = useAppState();
  const location = useLocation();
  const history = useHistory();
  const [actionSheet, setActionSheet] = useState(false);
  const { loading, data, error } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "MobileLayout",
        query: "USER_PROFILE",
      },
    });
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "400px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }
  // if (!isLoggedIn) {
  //   history.replace("/auth?s=login");
  // }

  const hideNav =
    location.pathname.includes("/app/page/") ||
    location.pathname.includes("/app/event/") ||
    location.pathname.includes("/app/fixture/") ||
    location.pathname.includes("/app/profile/") ||
    location.pathname.includes("/app/search") ||
    location.pathname.includes("/app/create_page") ||
    location.pathname.includes("/app/create_event") ||
    location.pathname.includes("/app/onboard_page") ||
    location.pathname.includes("/app/onboard_event") ||
    location.pathname.includes("/app/register") ||
    location.pathname.includes("/app/requests");
  const userProfile = data?.userProfile;

  if (!userProfile) {
    history.replace("/auth?s=login");
  }

  return (
    <div className={"App-mobile-layout " + (hideNav ? "hide-nav" : "")}>
      <div className="App-mobile-layout__header">
        <div className="App-mobile-layout__header__left-content">
          <img src={"/logo.svg"} alt="" />
        </div>

        <div className="App-mobile-layout__header__search-wrapper">
          <Link to="/app/search" className="App-mobile-layout__header__search">
            <div className="App-mobile-layout__header__search__icon">
              <SearchIcon
                style={{ height: "12px", stroke: "var(--light-4)" }}
              />
            </div>

            <div className="App-mobile-layout__header__search__text">
              Search
            </div>
          </Link>
        </div>

        <div className="App-mobile-layout__header__right-content">
          {userProfile && (
            <Link
              to={"/app/profile/" + userProfile.id}
              className="App-mobile-layout__header__profile-icon"
            >
              {userProfile.profilePhoto && (
                <Avatar media={userProfile.profilePhoto} />
              )}
            </Link>
          )}
        </div>
      </div>

      <div className="App-mobile-layout__body">
        <Switch>
          <Route path="/app/onboard_page/:id">
            <PageOnboarding />
          </Route>

          <Route path="/app/onboard_event/:id">
            <EventOnboarding />
          </Route>

          <Route path="/app/create_page">
            <CreatePage />
          </Route>

          <Route path="/app/create_event">
            <CreateEvent />
          </Route>

          <Route path="/app/register/:id">
            <Register />
          </Route>

          <Route path="/app/event/:id/manage">
            <ManageEvent />
          </Route>

          <Route path="/app/page/:id">
            <Organization />
          </Route>

          <Route path="/app/profile/:id">
            <Profile />
          </Route>

          <Route path="/app/event/:id">
            <Event />
          </Route>

          <Route path="/app/fixture/:id">
            <Fixture />
          </Route>

          <Route path="/app/requests">
            <Requests />
          </Route>

          <Route path="/app/notifications">
            <Notifications />
          </Route>

          <Route path="/app/events">
            <Events />
          </Route>

          <Route path="/app/directory">
            <Directory />
          </Route>

          <Route path="/app/search">
            <Search />
          </Route>

          <Route path="/app">
            <Home />
          </Route>
        </Switch>
      </div>

      <div className="App-mobile-layout__nav">
        <Link
          to="/app"
          className={
            "App-mobile-layout__nav__item " +
            (location.pathname === "/app" ? "active" : "")
          }
          onClick={() => {
            setActionSheet(false);
          }}
        >
          <div className="App-mobile-layout__nav__item__icon">
            <HomeIcon style={{ height: "20px" }} />
          </div>

          <div className="App-mobile-layout__nav__item__text">HOME</div>
        </Link>

        <Link
          to="/app/directory"
          className={
            "App-mobile-layout__nav__item " +
            (location.pathname === "/app/directory" ? "active" : "")
          }
          onClick={() => {
            setActionSheet(false);
          }}
        >
          <div className="App-mobile-layout__nav__item__icon">
            <DirectoryIcon style={{ height: "20px" }} />
          </div>

          <div className="App-mobile-layout__nav__item__text">PAGES</div>
        </Link>

        <div
          className="App-mobile-layout__nav__item"
          onClick={() => {
            setActionSheet(true);
          }}
        >
          <div className="App-mobile-layout__nav__item__btn">
            <PlusIcon />
          </div>
        </div>

        <Link
          to="/app/events"
          className={
            "App-mobile-layout__nav__item " +
            (location.pathname === "/app/events" ? "active" : "")
          }
          onClick={() => {
            setActionSheet(false);
          }}
        >
          <div className="App-mobile-layout__nav__item__icon">
            <CalendarIcon style={{ height: "20px" }} />
          </div>

          <div className="App-mobile-layout__nav__item__text">EVENTS</div>
        </Link>

        <Link
          to="/app/notifications"
          className={
            "App-mobile-layout__nav__item " +
            (location.pathname === "/app/notifications" ? "active" : "")
          }
          onClick={() => {
            setActionSheet(false);
          }}
        >
          <div className="App-mobile-layout__nav__item__icon">
            <NotificationIcon style={{ height: "20px" }} />
          </div>

          <div className="App-mobile-layout__nav__item__text">ALERTS</div>
        </Link>
      </div>

      <div
        className={
          "App-mobile-layout__action-sheet-overlay " +
          (actionSheet ? "show" : "")
        }
        onClick={() => {
          setActionSheet(false);
        }}
      ></div>

      <div
        className={
          "App-mobile-layout__action-sheet " + (actionSheet ? "show" : "")
        }
      >
        {/* <div className="App-mobile-layout__action-sheet__dismiss-wrapper">
          <div className="App-mobile-layout__action-sheet__dismiss-wrapper__btn">
            <ChevronDownIcon
              style={{ height: "14px", stroke: "var(--dark-4)" }}
            />
          </div>
        </div> */}

        <div className="App-mobile-layout__action-sheet__item">
          <div className="App-mobile-layout__action-sheet__item__icon"></div>

          <Link
            to={"/app/create_page"}
            className="App-mobile-layout__action-sheet__item__text"
            onClick={() => {
              setActionSheet(false);
            }}
          >
            <div className="App-mobile-layout__action-sheet__item__text__primary">
              Create a page
            </div>

            <div className="App-mobile-layout__action-sheet__item__text__secondary">
              Make a page for your team, venue, academy or sports business and
              get listed on the Directory.
            </div>
          </Link>
        </div>

        <div className="App-mobile-layout__action-sheet__item">
          <div className="App-mobile-layout__action-sheet__item__icon"></div>

          <Link
            to={"/app/create_event"}
            className="App-mobile-layout__action-sheet__item__text"
            onClick={() => {
              setActionSheet(false);
            }}
          >
            <div className="App-mobile-layout__action-sheet__item__text__primary">
              Create an event
            </div>

            <div className="App-mobile-layout__action-sheet__item__text__secondary">
              Easily create, manage and promote your sporting events using the
              tools provided by Sports Paddock.
            </div>
          </Link>
        </div>
      </div>

      <div className={"App-mobile-layout__modal " + (modal ? "open" : "")}>
        {modal && (
          <div className="App-mobile-layout__modal__background">{modal}</div>
        )}
      </div>
    </div>
  );
}

export default MobileLayout;
