// V1
import { Fragment } from "react";
import "./CricketBoxScore.css";
import Card from "../Card";
import { useQuery } from "@apollo/client";
import { FIXTURE_STATS } from "../../api/fixture";
import { FIXTURE } from "../../api/functions";
import Placeholder from "../Placeholder";
import Avatar from "../Avatar";
import oversToBalls from "../../scoring/ScoreCricket/utils/oversToBalls";

const cricketBoxScoreSelectionSet = `
  completed
  uploaded
  metas{
    id
    label
    value
    stringValue
  }
  periods{
    id
    order
    label
  }
  placements {
    id
    value
    valueString
    organization {
      id
      name
    }
  }
  cricketLiveScoring{
    id
    periodScores{
      id
      runs
      wickets
      overs
    }
  }    
`;

function getParticipantPeriodIndex({ participant, tossWinner, tossDecision }) {
  if (tossWinner.id === participant.id) {
    // Participant won the toss
    if (tossDecision === "decided to bat") {
      // Won the toss and decided to bat
      return 0;
    } else {
      // Won the toss and decided to bowl
      return 1;
    }
  } else {
    // Participant lost the toss
    if (tossDecision === "decided to bat") {
      // Lost the toss and opponent decided to bat first
      return 1;
    } else {
      // Lost the toss and opponent decided to bowl first
      return 0;
    }
  }
}

function getParticipantScore({
  participant,
  tossWinner,
  tossDecision,
  periodsWithScores,
}) {
  if (!tossWinner || !tossDecision) {
    return "";
  }

  const participantPeriodIndex = getParticipantPeriodIndex({
    participant,
    tossWinner,
    tossDecision,
  });

  return periodsWithScores[participantPeriodIndex].score;
}

function getCompletedStatement({
  winningParticipant,
  tossWinner,
  tossDecision,
  wicketsPerInningMeta,
  winnerWickets,
  homeRuns,
  awayRuns,
}) {
  if (
    !winningParticipant ||
    !tossWinner ||
    !tossDecision ||
    !wicketsPerInningMeta ||
    !winnerWickets
  ) {
    return "";
  }
  const winningParticipantIndex = getParticipantPeriodIndex({
    participant: winningParticipant,
    tossWinner,
    tossDecision,
  });

  const statement = winningParticipantIndex
    ? `by ${wicketsPerInningMeta?.value - Number(winnerWickets || 0)} wickets`
    : `by ${homeRuns - awayRuns} runs`;

  return statement;
}

function CompletedStatus({
  homePlacement,
  awayPlacement,
  metas,
  tossWinner,
  tossDecision,
  participants,
}) {
  const dlsStatusMeta = metas.find((m) => m.label === "dlsStatus");
  const runsTargetMeta = metas.find((m) => m.label === "dlsRunsTarget");
  const oversTargetMeta = metas.find((m) => m.label === "dlsOversTarget");
  const wicketsPerInningMeta =
    metas.find((m) => m.label === "wicketsPerInning") || 10;

  const homeRuns = dlsStatusMeta?.stringValue
    ? homePlacement.organization?.id === dlsStatusMeta.stringValue
      ? runsTargetMeta.value
      : homePlacement.value
    : homePlacement?.value;

  const awayRuns = dlsStatusMeta?.stringValue
    ? awayPlacement.organization?.id === dlsStatusMeta.stringValue
      ? runsTargetMeta.value
      : awayPlacement.value
    : awayPlacement?.value;

  const winner = homeRuns > awayRuns ? homePlacement : awayPlacement;
  const winningParticipant = participants.find(
    (p) => p.organization?.id === winner?.organization?.id
  );
  const winnerWickets = winner.valueString?.split("/")?.[1];

  // const homeParticipantIndex = getParticipantPeriodIndex({});
  const statement = getCompletedStatement({
    winningParticipant,
    tossWinner,
    tossDecision,
    wicketsPerInningMeta,
    winnerWickets,
    homeRuns,
    awayRuns,
  });

  return (
    <>
      {winner && (
        <div className="cricket-box-score__footer">
          <div className="cricket-box-score__footer__statement">
            {winner.organization?.name} wins the game {statement}{" "}
            {dlsStatusMeta?.stringValue && (
              <span>
                (Revised target: {runsTargetMeta.value} runs in{" "}
                {oversTargetMeta.value} overs)
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function SecondInningsStatus({ periodsWithScores, metas, participants }) {
  console.log();
  if (
    !periodsWithScores?.[1]?.score.overs ||
    periodsWithScores[1].score.overs <= 0
  ) {
    return <></>;
  }

  const dlsStatusMeta = metas.find((x) => x.label === "dlsStatus");
  const runsTargetMeta = metas.find((m) => m.label === "dlsRunsTarget");
  const oversTargetMeta = metas.find((m) => m.label === "dlsOversTarget");
  const ballsPerOverMeta = metas.find((x) => x.label === "ballsPerOver");
  const oversPerInningMeta = metas.find((x) => x.label === "oversPerInning");

  if (!ballsPerOverMeta.value || !oversPerInningMeta?.value) {
    return <></>;
  }

  const ballsPerInning =
    (dlsStatusMeta?.stringValue ? oversTargetMeta : oversPerInningMeta).value *
    ballsPerOverMeta.value;
  console.log("BPI:", ballsPerInning);

  const completedBalls = oversToBalls(
    periodsWithScores[1].score.overs,
    ballsPerOverMeta.value
  );
  const remainingBalls = ballsPerInning - completedBalls;

  const baseScore =
    (dlsStatusMeta?.stringValue
      ? runsTargetMeta.value
      : periodsWithScores[0].score.runs) - periodsWithScores[1].score.runs;

  const runDiff = baseScore + 1;

  const tossWinnerMeta = metas.find((x) => x.label === "tossWinner");
  const tossDecisionMeta = metas.find((x) => x.label === "tossDecision");

  const participant =
    tossDecisionMeta.stringValue === "decided to bat"
      ? participants.find(
          (p) => p.organization?.id !== tossWinnerMeta.stringValue
        )
      : participants.find(
          (p) => p.organization?.id === tossWinnerMeta.stringValue
        );

  return (
    <div className="cricket-box-score__footer">
      {runDiff > 0 ? (
        <div className="cricket-box-score__footer__statement">
          {participant.organization?.name} needs {runDiff} runs in{" "}
          {remainingBalls} balls to win{" "}
          {dlsStatusMeta?.stringValue && (
            <span>
              (DLS Target: {runsTargetMeta.value} runs in{" "}
              {oversTargetMeta.value} overs)
            </span>
          )}
        </div>
      ) : (
        <div className="cricket-box-score__footer__statement">
          {participant.organization?.name} wins{" "}
          {dlsStatusMeta?.stringValue && (
            <span>
              (Revised Target: {runsTargetMeta.value} runs in{" "}
              {oversTargetMeta.value} overs)
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default function CricketBoxScore({ fixture }) {
  const { loading, error, data } = useQuery(
    FIXTURE({ selectionSet: cricketBoxScoreSelectionSet }),
    {
      variables: { id: fixture.id },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="cricket-box-score">
        <div className="cricket-box-score__main">
          <div className="cricket-box-score__main__section home">
            <div className="cricket-box-score__main__section__team">
              <div className="cricket-box-score__main__section__team__logo"></div>

              <div className="cricket-box-score__main__section__team__name">
                <Placeholder
                  style={{ height: "12px", width: "88px" }}
                  pulse={true}
                />
              </div>
            </div>

            <div className="cricket-box-score__main__section__score">
              <div className="cricket-box-score__main__section__score__runs">
                <Placeholder
                  style={{ height: "14px", width: "32px" }}
                  pulse={true}
                />
              </div>

              <div className="cricket-box-score__main__section__score__overs">
                <Placeholder
                  style={{ height: "4px", width: "32px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>

          <div className="cricket-box-score__main__section away">
            <div className="cricket-box-score__main__section__score">
              <div className="cricket-box-score__main__section__score__runs">
                <Placeholder
                  style={{ height: "14px", width: "32px" }}
                  pulse={true}
                />
              </div>

              <div className="cricket-box-score__main__section__score__overs">
                <Placeholder
                  style={{ height: "4px", width: "32px" }}
                  pulse={true}
                />
              </div>
            </div>
            <div className="cricket-box-score__main__section__team">
              <div className="cricket-box-score__main__section__team__logo"></div>

              <div className="cricket-box-score__main__section__team__name">
                <Placeholder
                  style={{ height: "12px", width: "88px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="cricket-box-score__footer">
          <div className="cricket-box-score__footer__statement"></div>
        </div>
      </Card>
    );
  }

  const participants = fixture?.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const periods = data?.fixture?.periods;

  const homeParticipant = participants[0];
  const awayParticipant = participants[1];

  const metas = data?.fixture?.metas;
  // console.log("Fixture metas:", metas);
  const tossWinnerMeta = metas.find((m) => m.label === "tossWinner");
  const tossDecisionMeta = metas.find((m) => m.label === "tossDecision");

  const tossWinner = participants.find(
    (p) => p?.organization?.id === tossWinnerMeta?.stringValue
  );
  const tossDecision = tossDecisionMeta?.stringValue;
  const placements = data?.fixture?.placements;
  const completed = data?.fixture?.completed;
  const periodScores = data?.fixture?.cricketLiveScoring?.periodScores;
  const periodsWithScores = [...periods]
    .sort((a, b) => a.order - b.order)
    .map((p) => ({
      ...p,
      score: periodScores.find((x) => x.id === p.id),
    }));

  // const stats = data?.fixture?.stats;
  // const hasHomeStats = stats.find(
  //   (s) =>
  //     s.organization?.id === awayParticipant?.organization?.id &&
  //     s.statType?.name === "Balls Bowled"
  // );
  // const hasAwayStats = stats.find(
  //   (s) =>
  //     s.organization?.id === homeParticipant?.organization?.id &&
  //     s.statType?.name === "Balls Bowled"
  // );
  const homeScore = getParticipantScore({
    participant: homeParticipant,
    tossWinner,
    tossDecision,
    periodsWithScores,
  });

  const awayScore = getParticipantScore({
    participant: awayParticipant,
    tossWinner,
    tossDecision,
    periodsWithScores,
  });

  // const homeRuns = stats
  //   .filter(
  //     (s) =>
  //       (s?.organization?.id === homeParticipant?.organization?.id &&
  //         s?.statType.name === "Runs Scored") ||
  //       (s?.organization?.id !== homeParticipant?.organization?.id &&
  //         ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name))
  //   )
  //   .reduce((accum, curr) => {
  //     accum += curr.value;
  //     return accum;
  //   }, 0);
  // const awayRuns = stats
  //   .filter(
  //     (s) =>
  //       (s?.organization?.id === awayParticipant?.organization?.id &&
  //         s.statType.name === "Runs Scored") ||
  //       (s?.organization?.id !== awayParticipant?.organization?.id &&
  //         ["Wide", "No Ball", "Bye", "Leg Bye"].includes(s.statType.name))
  //   )
  //   .reduce((accum, curr) => {
  //     accum += curr.value;
  //     return accum;
  //   }, 0);

  // const homeWickets = stats.filter(
  //   (s) =>
  //     s?.organization?.id === homeParticipant?.organization?.id &&
  //     [
  //       "Bowled",
  //       "LBW",
  //       "Caught",
  //       "Caught & Bowled",
  //       "Stumped",
  //       "Run Out",
  //       "Retired",
  //     ].includes(s.statType.name) &&
  //     s.relationshipsTo.find((x) => x.from?.statType?.name === "Runs Scored")
  // ).length;

  // const awayWickets = stats.filter(
  //   (s) =>
  //     s?.organization?.id === awayParticipant?.organization?.id &&
  //     [
  //       "Bowled",
  //       "LBW",
  //       "Caught",
  //       "Caught & Bowled",
  //       "Stumped",
  //       "Run Out",
  //       "Retired",
  //     ].includes(s.statType.name) &&
  //     s.relationshipsTo.find((x) => x?.from?.statType?.name === "Runs Scored")
  // ).length;

  // const homeOvers = stats
  //   .filter(
  //     (s) =>
  //       s?.statType?.name === "Balls Bowled" &&
  //       s?.organization?.id === awayParticipant?.organization?.id
  //   )
  //   .sort(
  //     (a, b) =>
  //       (Number(b.stringValue) || 0) - (Number(a.stringValue) || 0) ||
  //       (b.createdAt > a.createdAt ? 1 : -1)
  //   )[0];

  // const awayOvers = stats
  //   .filter(
  //     (s) =>
  //       s?.statType?.name === "Balls Bowled" &&
  //       s?.organization?.id === homeParticipant?.organization?.id
  //   )
  //   .sort(
  //     (a, b) =>
  //       (Number(b.stringValue) || 0) - (Number(a.stringValue) || 0) ||
  //       (b.createdAt > a.createdAt ? 1 : -1)
  //   )[0];

  const homePlacement = data?.fixture?.placements?.find(
    (p) => p.organization?.id === homeParticipant?.organization?.id
  );

  const awayPlacement = data?.fixture?.placements?.find(
    (p) => p.organization?.id === awayParticipant?.organization?.id
  );

  return (
    <Card className="cricket-box-score">
      {tossWinner && tossDecision && (
        <div className="cricket-box-score__footer">
          <div className="cricket-box-score__footer__statement">
            {tossWinner.organization?.name +
              " won the toss and " +
              tossDecision}
          </div>
        </div>
      )}

      <div className="cricket-box-score__main">
        <div className="cricket-box-score__main__section home">
          <div className="cricket-box-score__main__section__team">
            <div className="cricket-box-score__main__section__team__logo">
              {homeParticipant?.organization?.profilePhoto && (
                <Avatar media={homeParticipant.organization?.profilePhoto} />
              )}
            </div>

            <div className="cricket-box-score__main__section__team__name">
              {homeParticipant.organization.name}
            </div>
          </div>

          <div className="cricket-box-score__main__section__score">
            {completed ? (
              <div className="cricket-box-score__main__section__score__runs">
                {homePlacement.valueString}
              </div>
            ) : (
              <div className="cricket-box-score__main__section__score__runs">
                {homeScore && homeScore.overs > 0
                  ? `${homeScore.runs}/${homeScore.wickets}`
                  : "-"}
              </div>
            )}

            <div className="cricket-box-score__main__section__score__overs">
              {homeScore?.overs > 0 && `(${homeScore.overs.toFixed(1)})`}
            </div>
          </div>
        </div>

        <div className="cricket-box-score__main__section away">
          <div className="cricket-box-score__main__section__score">
            {completed ? (
              <div className="cricket-box-score__main__section__score__runs">
                {awayPlacement.valueString}
              </div>
            ) : (
              <div className="cricket-box-score__main__section__score__runs">
                {awayScore && awayScore.overs > 0
                  ? `${awayScore.runs}/${awayScore.wickets}`
                  : "-"}
              </div>
            )}

            <div className="cricket-box-score__main__section__score__overs">
              {awayScore?.overs > 0 && `(${awayScore.overs.toFixed(1)})`}
            </div>
          </div>
          <div className="cricket-box-score__main__section__team">
            <div className="cricket-box-score__main__section__team__logo">
              {awayParticipant?.organization?.profilePhoto && (
                <Avatar media={awayParticipant.organization?.profilePhoto} />
              )}
            </div>

            <div className="cricket-box-score__main__section__team__name">
              {awayParticipant.organization.name}
            </div>
          </div>
        </div>
      </div>

      {completed ? (
        <CompletedStatus
          homePlacement={homePlacement}
          awayPlacement={awayPlacement}
          metas={metas}
          tossWinner={tossWinner}
          tossDecision={tossDecision}
          participants={participants}
        />
      ) : (
        <SecondInningsStatus
          periodsWithScores={periodsWithScores}
          metas={metas}
          participants={participants}
        />
      )}

      {/* {tossWinner && !data?.fixture?.completed && (
        <div className="cricket-box-score__footer">
          <div className="cricket-box-score__footer__statement">
            {hasHomeStats && hasAwayStats ? (
              <Fragment>
                {battingSecond?.organization.name} need{" "}
                {battingSecond?.id === homeParticipant.id
                  ? awayRuns + 1
                  : homeRuns + 1}{" "}
                runs to win
              </Fragment>
            ) : (
              <Fragment>
                {tossWinner && tossWinner.organization.name + " won the toss"}
                {tossWinner && tossDecision && " and " + tossDecision}
              </Fragment>
            )}
          </div>
        </div>
      )} */}

      {/* {data?.fixture?.completed && (
        <div className="cricket-box-score__footer">
          <div className="cricket-box-score__footer__statement">
            {homePlacement?.value > awayPlacement?.value && (
              <span>{homeParticipant?.organization?.name} wins the game.</span>
            )}

            {awayPlacement?.value > homePlacement?.value && (
              <span>{awayParticipant?.organization?.name} wins the game.</span>
            )}
          </div>
        </div>
      )} */}
    </Card>
  );
}
