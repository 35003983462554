import { gql } from "@apollo/client";

const PAGES = gql`
  query pagesQuery($id: ID!, $take: Int, $historical: Boolean, $cursor: ID) {
    pages(id: $id, take: $take, historical: $historical, cursor: $cursor) {
      id
      role
      isAdmin
      organization {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default PAGES;
