import { gql } from "@apollo/client";

const ACCOUNT_NOTIFICATIONS = gql`
  query accountNotifications($take: Int, $cursor: ID) {
    userAccount {
      notifications(take: $take, cursor: $cursor) {
        id
        createdAt
        type
        source {
          id
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          fixture {
            id
            event {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              order
              profile {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
              organization {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
        destination {
          id
          profile {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          organization {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          fixture {
            id
            event {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            roles(where: { type: { name: "PARTICIPANT" } }) {
              id
              order
              profile {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
              organization {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
        meta
      }
    }
  }
`;

export default ACCOUNT_NOTIFICATIONS;
