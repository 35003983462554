import { gql } from "@apollo/client";

const DELETE_STANDING = gql`
  mutation deleteStandings($id: ID!) {
    deleteStanding(id: $id) {
      id
    }
  }
`;

export default DELETE_STANDING;
