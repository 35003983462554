import { gql } from "@apollo/client";

const NOTIFICATIONS = gql`
  query notificationsQuery {
    notifications {
      id
      createdAt
      type
      source {
        id
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        event {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        fixture {
          id
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          roles(where: { type: { name: "PARTICIPANT" } }) {
            id
            order
            profile {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
        }
      }
      destination {
        id
        profile {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        event {
          id
          name
          profilePhoto {
            id
            filename
          }
        }
        fixture {
          id
          event {
            id
            name
            profilePhoto {
              id
              filename
            }
          }
          roles(where: { type: { name: "PARTICIPANT" } }) {
            id
            order
            profile {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
            organization {
              id
              name
              profilePhoto {
                id
                filename
              }
            }
          }
        }
      }
      meta
    }
  }
`;

export default NOTIFICATIONS;
