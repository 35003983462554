import { useState, Fragment } from "react";
import "./FixtureVideos.css";
import Button from "../Button";
import Card from "../Card";
import Spinner from "../Spinner";
import { useAppState } from "../../utils/appState";
import Uploader from "../Uploader";
import { CREATE_BROADCAST_SESSION, FIXTURE_MEDIA } from "../../api/fixture";
import { UPDATE_FIXTURE } from "../../api/fixture/functions";
import { useMutation, useQuery } from "@apollo/client";
import { ReactComponent as YoutubeIcon } from "../../icons/youtube.svg";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
import ClickOutside from "../ClickOutside";
import VideoCard from "../VideoCard";
import Loader from "../Loader";
import { CREATE_MEDIA } from "../../api/media";
import captureException from "../../utils/captureException";
import Toggle from "../Toggle";

function VideoPinner({ media, fixtureId, unpin }) {
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({
      selectionSet: `
      pinnedMedia{
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        clapCount
        isClapped
        canManageMedia
        contentLength
        source {
          id
          fixture {
            id
            event {
              id
              organizedBy {
                id
                name
                profilePhoto {
                  id
                  filename
                }
              }
            }
          }
        }
      }
  `,
    }),
    {
      update: (cache, { data: { updateFixture } }) => {
        cache.modify({
          id: "Fixture:" + fixtureId,
          fields: {
            pinnedMedia() {
              return updateFixture.pinnedMedia;
            },
          },
        });
      },
    }
  );
  return (
    <div className="fixture-videos__pinner">
      <div
        className="fixture-videos__pinner__btn"
        onClick={() => {
          updateFixture({
            variables: {
              where: { id: fixtureId },
              data: {
                pinnedMedia: unpin
                  ? { disconnect: true }
                  : { connect: { id: media.id } },
              },
            },
          });
        }}
      >
        {loading ? (
          <Loader theme="blue small" />
        ) : unpin ? (
          "Unpin from top"
        ) : (
          "Pin to top"
        )}
      </div>
    </div>
  );
}

function AddVideo({ id }) {
  const [uploader, setUploader] = useState(false);
  const [external, setExternal] = useState(false);
  const [externalLink, setExternalLink] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [, setAppState] = useAppState();

  const [createMedia] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FixtureVideos.AddVideo",
          mutation: "CREATE_MEDIA",
        },
      });
      console.log(error);
    },
  });

  if (uploader) {
    if (external) {
      return (
        <ClickOutside
          onClickOutside={() => {
            setAppState({ modal: false });
          }}
        >
          {(wrapperRef) => (
            <div className="add-video-wrapper" ref={wrapperRef}>
              <Card className={"add-video-container"}>
                <div className="add-video-container__title">
                  Enter YouTube Embed Link
                </div>
                <div className="add-video-container__secondary-title">
                  To get your video's embed link, click the "Share" button{" "}
                  <br /> on the video's YouTube page and click "Copy".
                </div>
                <input
                  type="text"
                  placeholder={"Enter YouTube link here"}
                  className="add-video-container__link-input"
                  value={externalLink}
                  onChange={(e) => {
                    setExternalLink(e.target.value);
                  }}
                />

                <div className="add-video-container__is-live-stream">
                  <div className="add-video-container__is-live-stream__label">
                    Is this video a live stream?
                  </div>

                  <div className="add-video-container__is-live-stream__input">
                    <Toggle
                      defaultValue={isLive}
                      onToggle={(val) => {
                        setIsLive(val);
                      }}
                    />
                  </div>
                </div>
                <Button
                  className="primary green large"
                  onClick={() => {
                    if (!externalLink) return;
                    const filename = isLive
                      ? externalLink.replace(
                          "https://www.youtube.com/live/",
                          "https://www.youtube.com/embed/"
                        )
                      : externalLink.replace(
                          "https://youtu.be/",
                          "https://www.youtube.com/embed/"
                        );

                    // console.log(isLive);
                    // return;

                    createMedia({
                      variables: {
                        type: "VIDEO",
                        filename,
                        private: false,
                        isExternal: true,
                        sourceId: id,
                        sourceType: "fixture",
                      },
                      update: (cache, { data: { createMedia } }) => {
                        const q = cache.readQuery({
                          query: FIXTURE_MEDIA,
                          variables: { id, type: "VIDEO" },
                        });

                        cache.writeQuery({
                          query: FIXTURE_MEDIA,
                          variables: { id, type: "VIDEO" },
                          data: {
                            fixture: {
                              ...q.fixture,
                              media: [{ ...createMedia }, ...q.fixture.media],
                            },
                          },
                        });

                        setAppState({ modal: false });
                      },
                    });
                  }}
                >
                  Add Video
                </Button>
              </Card>
            </div>
          )}
        </ClickOutside>
      );
    } else {
      return (
        <Uploader
          accept=".mp4"
          label="Upload Video"
          multiple={false}
          sourceId={id}
          sourceType={"fixture"}
          type={"VIDEO"}
          maxFileSizeInBytes={10000000}
          onCreateMedia={(cache, createMedia) => {
            const q = cache.readQuery({
              query: FIXTURE_MEDIA,
              variables: { id, type: "VIDEO" },
            });

            cache.writeQuery({
              query: FIXTURE_MEDIA,
              variables: { id, type: "VIDEO" },
              data: {
                fixture: {
                  ...q.fixture,
                  media: [{ ...createMedia }, ...q.fixture.media],
                },
              },
            });
          }}
        />
      );
    }
  }

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="add-video-wrapper" ref={wrapperRef}>
          <Card className={"add-video-container"}>
            <div className="add-video-container__title">Add New Video</div>
            {/*<div className="add-video-container__secondary-title">
                Select video type
        </div>*/}
            <div className="add-video-container__options">
              <Card
                className="add-video-container__option"
                onClick={() => {
                  setUploader(true);
                  setExternal(true);
                }}
              >
                <div className="icon">
                  <YoutubeIcon style={{ stroke: "var(--red-lighter)" }} />
                </div>
                <div className="desc">Embed YouTube </div>
              </Card>

              <Card
                className="add-video-container__option"
                onClick={() => {
                  setUploader(true);
                }}
              >
                <div className="icon">
                  <UploadIcon style={{ stroke: "var(--blue-lighter)" }} />
                </div>
                <div className="desc">Upload Video</div>
              </Card>
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

function BroadcastingLink({ fixtureId, broadcastSession }) {
  const [createBroadcastSession, { loading }] = useMutation(
    CREATE_BROADCAST_SESSION,
    {
      onError: (error) => {
        console.log(error);
      },
      update: (cache, { data: { createBroadcastSession } }) => {
        cache.modify({
          id: "Fixture:" + fixtureId,
          fields: {
            broadcastSession(existingMediaRefs, { readField }) {
              return createBroadcastSession;
            },
          },
        });

        window
          .open(
            process.env.REACT_APP_BROADCAST_ENDPOINT +
              "/manage/" +
              createBroadcastSession.id,
            "_blank"
          )
          .focus();
      },
    }
  );

  return (
    <Button
      className="secondary blue large"
      style={{ marginRight: "16px" }}
      disabled={loading}
      onClick={async () => {
        if (broadcastSession?.id) {
          console.log(process.env.REACT_APP_BROADCAST_ENDPOINT);
          window
            .open(
              process.env.REACT_APP_BROADCAST_ENDPOINT +
                "/manage/" +
                broadcastSession.id,
              "_blank"
            )
            .focus();
        } else {
          //
          createBroadcastSession({ variables: { fixtureId } });
        }
      }}
    >
      {loading ? <Loader /> : "Broadcasting Tools"}
    </Button>
  );
}

function FixtureVideos({ id, canManageFixture }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(FIXTURE_MEDIA, {
    variables: { id, type: "VIDEO" },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureVideos",
        query: "FIXTURE_MEDIA",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const pinnedVideo = data?.fixture?.pinnedMedia;
  const videos = data?.fixture?.media?.filter(
    (m) => m.type === "VIDEO" && m.id !== pinnedVideo?.id
  );
  const broadcastSession = data?.fixture?.broadcastSession;
  // console.log(videos);

  return (
    <div className="fixture-videos">
      {canManageFixture && (
        <div className="upload-btn-container">
          <BroadcastingLink
            fixtureId={id}
            broadcastSession={broadcastSession}
          />

          <Button
            className="primary blue large"
            onClick={async () => {
              setAppState({
                modal: <AddVideo id={id} />,
              });
            }}
          >
            Add Video
          </Button>
        </div>
      )}
      <div className="video-list">
        {pinnedVideo && (
          <Fragment>
            {canManageFixture && (
              <VideoPinner media={pinnedVideo} fixtureId={id} unpin={true} />
            )}
            <VideoCard
              media={pinnedVideo}
              onDelete={(cache, { data: { deleteMedia } }) => {
                cache.modify({
                  id: cache.identify(data.fixture),
                  fields: {
                    media(existingMediaRefs, { readField }) {
                      return existingMediaRefs.filter(
                        (mediaRef) =>
                          deleteMedia.id !== readField("id", mediaRef)
                      );
                    },
                  },
                });
              }}
            />
          </Fragment>
        )}

        {videos.map((v) => (
          <Fragment>
            {canManageFixture && (
              <VideoPinner media={v} fixtureId={id} unpin={false} />
            )}
            <VideoCard
              key={v.id}
              media={v}
              onDelete={(cache, { data: { deleteMedia } }) => {
                cache.modify({
                  id: cache.identify(data.fixture),
                  fields: {
                    media(existingMediaRefs, { readField }) {
                      return existingMediaRefs.filter(
                        (mediaRef) =>
                          deleteMedia.id !== readField("id", mediaRef)
                      );
                    },
                  },
                });
              }}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default FixtureVideos;
