// V1
import "./ProfileEvents.css";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PROFILE_EVENTS, PROFILE_TEAMS } from "../../api/profile";
import { SPORTS } from "../../api/sport";
import { EventItem } from "../Events";
import { Fragment, useState } from "react";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import Loader from "../Loader";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import Placeholder from "../Placeholder";
import captureException from "../../utils/captureException";
import { Link } from "react-router-dom";

function EventItemLoading() {
  return (
    <Card className="event-item-card">
      <div className="head">
        <div className="image"></div>
        <div className="meta">
          <div className="primary">
            <Placeholder
              style={{ height: "14px", width: "120px" }}
              pulse={true}
            />
          </div>
          <div className="secondary">
            <Placeholder
              style={{ width: "60px", marginTop: "8px" }}
              pulse={true}
            />
          </div>
        </div>
        <div className="date-wrapper">
          <div className="date"></div>
        </div>
      </div>
      <div className="event-item-card-body"></div>

      <div className="foot">
        <div className="followers">
          <div className="more" style={{ width: "80px" }}></div>
        </div>

        <div className="organizedBy">
          <div className="desc">
            <div className="primary">
              <Placeholder style={{ width: "100px" }} pulse={true} />
            </div>
            <div className="secondary">
              <Placeholder
                style={{ width: "60px", marginTop: "4px" }}
                pulse={true}
              />
            </div>
          </div>
          <div className={"image "}></div>
        </div>
      </div>
    </Card>
  );
}

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function ListLoading() {
  return (
    <div className="profile-events-list">
      <EventItemLoading />
      <EventItemLoading />
      <EventItemLoading />
    </div>
  );
}

function ProfileEvents() {
  const { id } = useParams();
  const [sportsFilter, setSportsFilter] = useState([]);
  const [teamsFilter, setTeamsFilter] = useState([]);
  const { error, data, refetch, variables, fetchMore, networkStatus, loading } =
    useQuery(PROFILE_EVENTS, {
      variables: { id, take: 5 },
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ProfileEvents",
        query: "PROFILE_EVENTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div className="profile-events">
        <div className="left-column">
          <ListLoading />
        </div>
        <div className="right-column"></div>
      </div>
    );
  }

  const org = data?.profile;
  const events = org?.events;

  console.log(networkStatus);

  // const participatedEvents = events?.filter((e) => e.userIsRegistered?.id);
  // const otherEvents = events?.filter((e) => !e.userIsRegistered?.id);

  return (
    <div className="profile-events">
      <div className="left-column">
        {!events?.length && networkStatus !== 2 && (
          <div className="no-events-container">
            <div className="text">No events found for this profile.</div>
            <Link to={"/app/events"} className="link">
              Find events around you
            </Link>
          </div>
        )}

        {!!events?.length && (
          <Fragment>
            {networkStatus === 2 ? (
              <ListLoading />
            ) : (
              <div className="profile-events-list">
                {events.map((ev) => (
                  <EventItem key={ev.id} ev={ev} />
                ))}
                <div className="show-more-container">
                  <Button
                    className="primary small blue show-more-button"
                    onClick={() => {
                      fetchMore({
                        variables: {
                          cursor: events[events.length - 1].id,
                        },
                      });
                    }}
                  >
                    {networkStatus === 3 ? (
                      <Loader theme={"small"} />
                    ) : (
                      "Show More"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>

      <div className="right-column">
        {networkStatus !== 1 && (
          <div className="card-section">
            <div className="card-section-title">
              Filters<div className="reset">Reset</div>
            </div>

            <DirectoryFilter
              placeholder={"Enter a sport"}
              query={SPORTS}
              items={(d) => {
                return d?.sports;
              }}
              addFilter={(sports) => {
                setSportsFilter(sports);
                refetch({
                  ...variables,
                  filters: {
                    ...variables.filters,
                    sports: sports.map((s) => s.name),
                  },
                });
              }}
              removeFilter={(sports) => {
                setSportsFilter(sports);
                refetch({
                  ...variables,
                  filters: {
                    ...variables.filters,
                    sports: sports.map((s) => s.name),
                  },
                });
              }}
              defaultValue={sportsFilter}
              appStateKey={"sportsFilter"}
            />

            <DirectoryFilter
              placeholder={"Enter a team"}
              query={PROFILE_TEAMS}
              vars={{ id }}
              items={(d) => {
                return d?.profile?.teamHistory;
              }}
              addFilter={(orgs) => {
                setTeamsFilter(orgs.map((s) => s.id));
                refetch({
                  ...variables,
                  filters: {
                    ...variables.filters,
                    teams: orgs.map((s) => s.id),
                  },
                });
              }}
              removeFilter={(orgs) => {
                setTeamsFilter(orgs.map((s) => s.id));
                refetch({
                  ...variables,
                  filters: {
                    ...variables.filters,
                    teams: orgs.map((s) => s.id),
                  },
                });
              }}
              defaultValue={teamsFilter}
              appStateKey={"teamsFilter"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileEvents;
