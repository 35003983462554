import "./EventStats.css";
import { useQuery } from "@apollo/client";
import { EVENT } from "../../../api/event";
import {
  FootballStats,
  CricketStats,
  BasketballStats,
  BadmintonStats,
} from "../Stats";
import Spinner from "../../../components/Spinner";
import captureException from "../../../utils/captureException";

export default function EventStats({ id }) {
  const { loading, error, data } = useQuery(EVENT, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventStats",
        query: "EVENT",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div
        style={{
          height: "160px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const event = data.event;
  const sport = event.sport.name;

  return (
    <div className="event-stats-mobile">
      {sport === "Football" && <FootballStats ev={event} />}

      {sport === "Cricket" && <CricketStats ev={event} />}

      {sport.includes("Basketball") && <BasketballStats ev={event} />}

      {sport === "Badminton" && <BadmintonStats ev={event} />}
    </div>
  );
}
