import "./AboutProfile.css";
import { ReactComponent as FootballIcon } from "../../../icons/football.svg";
import { ReactComponent as CricketIcon } from "../../../icons/cricket.svg";
import { ReactComponent as BasketballIcon } from "../../../icons/basketball.svg";
import { ReactComponent as BadmintonIcon } from "../../../icons/badminton.svg";
import { ReactComponent as TennisIcon } from "../../../icons/tennis.svg";
import { ReactComponent as ControllerIcon } from "../../../icons/controller.svg";
import { ReactComponent as EllipsisIcon } from "../../../icons/ellipsis.svg";
import { ReactComponent as BuildingIcon } from "../../../icons/building.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { useQuery } from "@apollo/client";
import { PROFILE_BIO, UPDATE_PROFILE } from "../../../api/profile";
import { UPDATE_USER_PROFILE } from "../../../api/user";
import moment from "moment";
import ModalForm from "../../ModalForm";
import { useAppState } from "../../../utils/appState";
import EditInterests from "../../EditInterests/EditInterests";
import EditLocations from "../../EditLocations";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function AboutProfile({ id, canManageProfile }) {
  const [, setAppState] = useAppState();
  const sportsIcons = {
    Football: <FootballIcon className="sports-icon-svg" />,
    Basketball: <BasketballIcon className="sports-icon-svg" />,
    Cricket: <CricketIcon className="sports-icon-svg" />,
    Badminton: <BadmintonIcon className="sports-icon-svg" />,
    Tennis: <TennisIcon className="sports-icon-svg" />,
    "E-Gaming": <ControllerIcon className="sports-icon-svg" />,
  };

  const { loading, error, data } = useQuery(PROFILE_BIO, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "AboutProfile",
        query: "PROFILE_BIO",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="profile-overview-mobile__about">
        <div className="profile-overview-mobile__about__title">
          <Placeholder style={{ height: "10px", width: "64px" }} pulse={true} />
        </div>
        <div className="profile-overview-mobile__about__groups">
          <div className="profile-overview-mobile__about__groups__group row">
            <Placeholder
              style={{ height: "10px", width: "100%" }}
              pulse={true}
            />
          </div>

          <div className="profile-overview-mobile__about__groups__group row">
            <Placeholder
              style={{ height: "10px", width: "100%" }}
              pulse={true}
            />
          </div>

          <div className="profile-overview-mobile__about__groups__group row">
            <Placeholder
              style={{ height: "10px", width: "100%" }}
              pulse={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const profile = data.profile;

  const onEditAgeClick = () => {
    setAppState({
      modal: (
        <ModalForm
          title={"Edit your profile"}
          config={{
            fields: [
              {
                label: "Date of birth",
                type: "date",
                key: "dob",
                formValue: profile.dob
                  ? moment(profile.dob).format("YYYY-MM-DD")
                  : "",
              },
            ],
            mutation: UPDATE_USER_PROFILE,
            onSubmit: ({ fields, mutate }) => {
              // console.log("Submitted:", fields);
              const val = fields[0].formValue;
              const vars = {
                dob: val ? moment(val).toISOString() : null,
              };
              mutate({
                variables: {
                  data: vars,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  const onEditInterestsClick = () => {
    setAppState({
      modal: (
        <EditInterests
          title={"Edit your profile"}
          config={{
            id: profile.id,
            default: profile.interests,
            // fields: [
            //   {
            //     label: "Date of birth",
            //     type: "date",
            //     key: "dob",
            //     formValue: profile.dob
            //       ? moment(profile.dob).format("YYYY-MM-DD")
            //       : "",
            //   },
            // ],
            mutation: UPDATE_PROFILE("interests{id name}"),
            update: (cache, { data: { updateProfile } }) => {
              const q = cache.readQuery({
                query: PROFILE_BIO,
                variables: { id },
              });

              cache.writeQuery({
                query: PROFILE_BIO,
                variables: { id },
                data: {
                  profile: { ...q.profile, interests: updateProfile.interests },
                },
              });

              setAppState({ modal: false });
            },
          }}
        />
      ),
    });
  };

  const onEditLocationsClick = () => {
    setAppState({
      modal: (
        <EditLocations
          title={"Edit your profile"}
          config={{
            id: profile.id,
            getLocations: (data) => data?.profile?.cities || [],
            query: PROFILE_BIO,
            mutation: UPDATE_PROFILE("cities{id value lat lng}"),
            update: (cache, { data: { updateProfile } }) => {
              const q = cache.readQuery({
                query: PROFILE_BIO,
                variables: { id },
              });

              cache.writeQuery({
                query: PROFILE_BIO,
                variables: { id },
                data: {
                  profile: { ...q.profile, cities: updateProfile.cities },
                },
              });

              // setIndex(0);
            },
          }}
        />
      ),
    });
  };

  return (
    <div className="profile-overview-mobile__about">
      <div className="profile-overview-mobile__about__title">About</div>
      <div className="profile-overview-mobile__about__groups">
        <div className="profile-overview-mobile__about__groups__group row">
          <div className="profile-overview-mobile__about__groups__group__key">
            Age
          </div>

          <div className="profile-overview-mobile__about__groups__group__value">
            {(profile.dob && moment().diff(profile.dob, "years")) || "-"}
          </div>

          {canManageProfile && (
            <div
              className="profile-overview-mobile__about__groups__group__edit-btn"
              onClick={onEditAgeClick}
            >
              <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
            </div>
          )}
        </div>

        <div className="profile-overview-mobile__about__groups__group">
          <div className="profile-overview-mobile__about__groups__group__key">
            Interests
            {canManageProfile && (
              <div
                className="profile-overview-mobile__about__groups__group__edit-btn"
                style={{ marginLeft: "auto" }}
                onClick={onEditInterestsClick}
              >
                <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
              </div>
            )}
          </div>

          <div className="profile-overview-mobile__about__groups__group__value grid">
            {profile.interests.map((i, x) => (
              <div
                key={"profile-bio-card-interests-" + i.id + x}
                className="chip"
              >
                <div className="icon">
                  {sportsIcons[i.name] || (
                    <EllipsisIcon className="sports-icon-svg" />
                  )}
                </div>
                <div className="desc">{i.name}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="profile-overview-mobile__about__groups__group">
          <div className="profile-overview-mobile__about__groups__group__key">
            Locations
            {canManageProfile && (
              <div
                className="profile-overview-mobile__about__groups__group__edit-btn"
                style={{ marginLeft: "auto" }}
                onClick={onEditLocationsClick}
              >
                <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
              </div>
            )}
          </div>

          <div className="profile-overview-mobile__about__groups__group__value grid">
            {profile.cities?.map((i) => (
              <div key={"profile-bio-card-interests-" + i.id} className="chip">
                <div className="icon">
                  <BuildingIcon className="sports-icon-svg" />
                </div>
                <div className="desc">{i.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
