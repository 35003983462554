import "./SavedPosts.css";
import Spinner from "../../Spinner";
import Post from "../../Post";
import { useQuery } from "@apollo/client";
import { ACCOUNT_SAVED_POSTS } from "../../../api/account";
import captureException from "../../../utils/captureException";

export default function SavedPosts() {
  const { loading, error, data } = useQuery(ACCOUNT_SAVED_POSTS);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "SavedPosts",
        query: "ACCOUNT_SAVED_POSTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const posts = data?.userAccount?.savedPosts || [];

  return (
    <div className="account-saved-posts">
      {posts.map((p) => (
        <div className="account-saved-posts__wrapper">
          <Post post={p} />
        </div>
      ))}
    </div>
  );
}
