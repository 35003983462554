import { useFootballScoringState } from "../utils/useFootballScoringState";
import "./PenaltyShootout.css";
import { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { FIXTURE_STATS } from "../../../api/functions/fixture";
import Spinner from "../../../components/Spinner";
import StatInputForm from "../StatInputForm";
import DeleteStat from "../DeleteStat";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";

function ShootoutRowItem({
  stat,
  isAway,
  goalStatType,
  missStatType,
  timestamp,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  statTypes,
  periods,
  fixture,
  eventId,
}) {
  const [{ setOverlay, fixtureId, selectedPeriod }] = useFootballScoringState();
  const scaledTimestamp = timestamp * 60;
  return (
    <div
      className={
        "ScoringApp-football__scoring__period__list__penalty-row__section " +
        (isAway ? "away" : "")
      }
    >
      <div className="ScoringApp-football__scoring__period__list__penalty-row__section__options">
        {stat && (
          <Fragment>
            {stat.isTemp ? (
              <div className="ScoringApp-football__scoring__period__list__penalty-row__section__options__option">
                <Spinner />
              </div>
            ) : (
              <div
                className="ScoringApp-football__scoring__period__list__penalty-row__section__options__option"
                onClick={() => {
                  setOverlay(<DeleteStat stat={stat} fixture={fixture} />);
                  //   onDelete(stat, [], stat.timeStamp);
                }}
              >
                <TrashIcon
                  style={{
                    height: "14px",
                    stroke: "var(--red-lighter)",
                  }}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className="ScoringApp-football__scoring__period__list__penalty-row__section__body">
        {stat ? (
          <div className="ScoringApp-football__scoring__period__list__penalty-row__section__body__statement">
            {stat.profile?.name}
          </div>
        ) : (
          <Fragment>
            <div
              className="ScoringApp-football__scoring__period__list__penalty-row__section__body__option"
              onClick={() => {
                setOverlay(
                  <StatInputForm
                    fixtureId={fixtureId}
                    fixture={fixture}
                    eventId={eventId}
                    selectedStat={goalStatType}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    homeLineup={homeLineup}
                    awayLineup={awayLineup}
                    defaultSelectedTeamIndex={isAway ? 1 : 0}
                    periods={periods}
                    selectedPeriod={selectedPeriod}
                    statTypes={statTypes}
                    defaultTimestamp={scaledTimestamp}
                    hideTimestamp={false}
                  />
                );
                // onStatTypeClick(goalStatType, !isAway, timestamp);
              }}
            >
              Scored
            </div>

            <div
              className="ScoringApp-football__scoring__period__list__penalty-row__section__body__option"
              onClick={() => {
                setOverlay(
                  <StatInputForm
                    fixtureId={fixtureId}
                    fixture={fixture}
                    eventId={eventId}
                    selectedStat={missStatType}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    homeLineup={homeLineup}
                    awayLineup={awayLineup}
                    defaultSelectedTeamIndex={isAway ? 1 : 0}
                    periods={periods}
                    selectedPeriod={selectedPeriod}
                    statTypes={statTypes}
                    defaultTimestamp={scaledTimestamp}
                    hideTimestamp={false}
                  />
                );
                // onStatTypeClick(missStatType, !isAway, timestamp);
              }}
            >
              Missed
            </div>
          </Fragment>
        )}
      </div>
      <div className="ScoringApp-football__scoring__period__list__penalty-row__indicator">
        <div
          className={
            "ScoringApp-football__scoring__period__list__penalty-row__indicator__icon " +
            (stat
              ? stat.statType.name === "Penalty Shootout Goal"
                ? "green"
                : "red"
              : "")
          }
        />
      </div>
    </div>
  );
}

export default function PenaltyShootout({
  fixtureId,
  fixture,
  selectedPeriod,
  statTypes,
  homeTeam,
  awayTeam,
  homeLineup,
  awayLineup,
  periods,
  eventId,
}) {
  const [{ stats = {} }, setFootballScoringState] = useFootballScoringState();

  // console.log(fixtureId, selectedPeriod.id);

  const { loading, error, data, networkStatus, variables } = useQuery(
    FIXTURE_STATS,
    {
      variables: {
        id: fixtureId,
        where: {
          fixturePeriod: {
            id: {
              in: [selectedPeriod.id],
            },
          },
        },
        take: 25,
      },
      // notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // console.log("Stats query:", data);
        if (!stats[selectedPeriod.id]?.length) {
          setFootballScoringState({
            stats: {
              ...stats,
              [selectedPeriod.id]: data.fixture.stats,
            },
          });
        }
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  // console.log("Network Status:", networkStatus);

  if (loading || !data) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  //   const goalStatType = statTypes?.find(
  //     (st) => st.name === "Penalty Shootout Goal"
  //   );

  const goalStatType = { label: "Penalty Shootout Goal" };

  //   const missStatType = statTypes?.find(
  //     (st) => st.name === "Penalty Shootout Miss"
  //   );
  const missStatType = { label: "Penalty Shootout Miss" };

  const items = stats[selectedPeriod.id] || [];

  const homeStats = items
    .filter((s) => s.organization?.id === homeTeam?.organization?.id)
    .sort((a, b) => a.timeStamp - b.timeStamp);

  const awayStats = items
    .filter((s) => s.organization?.id === awayTeam?.organization?.id)
    .sort((a, b) => a.timeStamp - b.timeStamp);

  const rowCount = Math.max(homeStats.length, awayStats.length);

  return (
    <div className="ScoringApp-football__scoring__period__list">
      {[...Array(rowCount + 1).keys()].map((i) => (
        <div
          className="ScoringApp-football__scoring__period__list__penalty-row"
          key={"penalty-shootout-" + i}
        >
          <ShootoutRowItem
            stat={homeStats.find((s) => s.timeStamp === i + 1)}
            goalStatType={goalStatType}
            missStatType={missStatType}
            // onStatTypeClick={onStatTypeClick}
            timestamp={i + 1}
            // onDelete={onDelete}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            homeLineup={homeLineup}
            awayLineup={awayLineup}
            statTypes={statTypes}
            periods={periods}
            fixture={fixture}
            eventId={eventId}
          />

          <ShootoutRowItem
            isAway={true}
            stat={awayStats.find((s) => s.timeStamp === i + 1)}
            goalStatType={goalStatType}
            missStatType={missStatType}
            // onStatTypeClick={onStatTypeClick}
            timestamp={i + 1}
            // onDelete={onDelete}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            homeLineup={homeLineup}
            awayLineup={awayLineup}
            statTypes={statTypes}
            periods={periods}
            fixture={fixture}
            eventId={eventId}
          />
        </div>
      ))}
    </div>
  );
}
