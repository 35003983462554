import { gql } from "@apollo/client";

const PROFILE_TEAMS = gql`
  query profileQuery($id: ID!) {
    profile(id: $id) {
      id
      teamHistory {
        id
        name
      }
    }
  }
`;

export default PROFILE_TEAMS;
