// V1
import { Fragment, useState } from "react";
import "./FollowersSummary.css";
import Card from "../../Card";
import { TabSelector, TabSelectorItem } from "../../TabSelector";
import { useQuery } from "@apollo/client";
import {
  FOLLOWERS,
  FOLLOWING,
  PROFILE,
  INTERACTION_STATS,
} from "../../../api/profile";
import { SUGGESTED_PROFILES } from "../../../api/user";
import { Link } from "react-router-dom";
import { useAppState } from "../../../utils/appState";
import PartyList from "../../PartyList";
import Placeholder from "../../Placeholder";
import Avatar from "../../Avatar/Avatar";
import captureException from "../../../utils/captureException";
import Square from "../../../mobileComponents/Square";

function FollowersList({ id }) {
  const { loading, error, data } = useQuery(FOLLOWERS, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FollowersSummary.FollowersList",
        query: "FOLLOWERS",
      },
    });
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <div className="connections-list">
        {[0, 1, 2, 3, 4, 5].map((x) => (
          <div key={x} className="connection">
            <div className="avatar">
              <Placeholder
                style={{ height: "100%", width: "100%" }}
                pulse={true}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  const list = data.followers;

  return (
    <div className="connections-list">
      {list.map((party) => (
        <Square>
          {({ squareRef, dimensions }) => (
            <Link
              to={`/app/${party.profile ? "profile" : "page"}/${
                party.profile ? party.profile.id : party.organization.id
              }`}
              className="connection"
              key={party.id}
              ref={squareRef}
            >
              <div
                className="avatar"
                style={{ height: dimensions.width + "px" }}
              >
                {party.profile
                  ? party.profile.profilePhoto && (
                      // <img src={makeFileUrl(party.profile.profilePhoto)} />
                      <Avatar media={party.profile.profilePhoto} />
                    )
                  : party.organization?.profilePhoto && (
                      <Avatar media={party.organization.profilePhoto} />
                    )}
              </div>

              <div className="name">
                {party.profile?.name || party.organization?.name}
              </div>
            </Link>
          )}
        </Square>
      ))}

      {!list?.length && (
        <div className="no-data">You have no followers yet.</div>
      )}
    </div>
  );
}

function FollowingList({ id }) {
  const [setAppState] = useAppState();
  const { loading, error, data } = useQuery(FOLLOWING, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FollowersSummary.FollowingList",
        query: "FOLLOWING",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="connections-list">
        {[0, 1, 2, 3, 4, 5].map((x) => (
          <div key={x} className="connection">
            <div className="avatar">
              <Placeholder
                style={{ height: "100%", width: "100%" }}
                pulse={true}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  const list = (data.following || []).slice(0, 6);

  return (
    <div className="connections-list">
      {list.map((party) => (
        <Square>
          {({ squareRef, dimensions }) => (
            <Link
              key={party.id}
              to={`/app/${party.profile ? "profile" : "page"}/${
                party.profile ? party.profile.id : party.organization.id
              }`}
              className="connection"
              ref={squareRef}
            >
              <div className="avatar" style={{ height: dimensions.width }}>
                {party.profile
                  ? party.profile.profilePhoto && (
                      <Avatar media={party.profile.profilePhoto} />
                    )
                  : party.organization?.profilePhoto && (
                      <Avatar media={party.organization.profilePhoto} />
                    )}
              </div>

              <div className="name">
                {party.profile?.name || party.organization?.name}
              </div>
            </Link>
          )}
        </Square>
      ))}

      {!list?.length && (
        <Fragment>
          <div className="no-data">
            <div>You aren't following anyone yet.</div>
            <div>Let's change that.</div>
            <div
              className="link hover-pointer hover-underline"
              onClick={() => {
                setAppState({
                  modal: (
                    <PartyList
                      title={"Suggested Profiles"}
                      query={SUGGESTED_PROFILES}
                      getData={(d) => d?.suggestedProfiles || []}
                      secondaryText={(item) =>
                        item.organization?.handle || item.profile?.handle
                          ? "@" +
                            (item.organization?.handle || item.profile?.handle)
                          : ""
                      }
                    />
                  ),
                });
              }}
            >
              View Suggested Profiles
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

function FollowersSummary({ profileId }) {
  const [, setAppState] = useAppState();
  const [selected, setSelected] = useState(0);
  const { loading, error, data } = useQuery(INTERACTION_STATS, {
    variables: { id: profileId },
    fetchPolicy: "network-only",
  });

  // const { loading, error } = useQuery(PROFILE, {
  //   variables: { id: profileId },
  // });

  // if (error) {
  //   captureException({
  //     error,
  //     info: {
  //       type: "query",
  //       component: "FollowersSummary.FollowersSummary",
  //       query: "PROFILE",
  //     },
  //   });
  //   console.log(error);
  // }

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FollowersSummary.FollowersSummary",
        query: "INTERACTION_STATS",
      },
    });

    return <div />;
    // console.log(interactionStatsQuery.error);
  }

  // if (error || interactionStatsQuery.error) {
  //   return <div />;
  // }

  if (loading || !data) {
    return (
      <Card className="home-followers-summary">
        <Placeholder style={{ height: "30px" }} pulse={true} />

        {selected ? (
          <FollowersList id={profileId} />
        ) : (
          <FollowingList id={profileId} />
        )}

        <div className="connections-card-footer">
          <Placeholder style={{ height: "12px" }} pulse={true} />
        </div>
      </Card>
    );
  }

  const interactionStats = data?.interactionStats;

  return (
    <Card className="home-followers-summary">
      <TabSelector>
        {(selectedTabIndex, setSelectedTabIndex) => (
          <Fragment>
            <TabSelectorItem
              selected={selectedTabIndex === 0}
              onClick={() => {
                setSelectedTabIndex(0);
                setSelected(0);
              }}
            >
              Supporting
            </TabSelectorItem>
            <TabSelectorItem
              selected={selectedTabIndex === 1}
              onClick={() => {
                setSelectedTabIndex(1);
                setSelected(1);
              }}
            >
              Fans
            </TabSelectorItem>
          </Fragment>
        )}
      </TabSelector>

      {selected ? (
        <FollowersList id={profileId} />
      ) : (
        <FollowingList id={profileId} />
      )}

      <div
        className="connections-card-footer"
        onClick={() => {
          setAppState({
            modal: (
              <PartyList
                title={
                  selected
                    ? `Fans (${interactionStats.followers})`
                    : `Supporting (${interactionStats.following})`
                }
                query={selected ? FOLLOWERS : FOLLOWING}
                variables={{ id: profileId }}
                getData={(d) => (selected ? d?.followers : d?.following) || []}
                secondaryText={(item) =>
                  item.organization?.handle || item.profile?.handle
                    ? "@" + (item.organization?.handle || item.profile?.handle)
                    : ""
                }
                noData={() =>
                  selected ? (
                    <div className="no-data">
                      <div>No fans yet.</div>
                    </div>
                  ) : (
                    <div className="no-data">
                      <div>You arent supporting anyone yet.</div>
                    </div>
                  )
                }
                totalItems={
                  selected
                    ? interactionStats.followers
                    : interactionStats.following
                }
                hideFollow={selected ? false : true}
              />
            ),
          });
        }}
      >
        See All
      </div>
    </Card>
  );
}

export default FollowersSummary;
