import { useQuery } from "@apollo/client";
import { TENNIS_STATS } from "../../../../api/fixture";
import Spinner from "../../../Spinner";
import Card from "../../../Card";

export default function StatsSummary({
  fixtureId,
  homeParticipant,
  awayParticipant,
}) {
  const { loading, error, data } = useQuery(TENNIS_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const homeStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === homeParticipant.profile.id
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) => s.participantId === awayParticipant.profile.id
  );

  // console.log("SUMMARY:", data?.fixture.statsSummary);
  return (
    <Card className="fixture-badminton-stats__summary">
      <div className="fixture-badminton-stats__summary__header">
        <div className="fixture-badminton-stats__summary__header__player home">
          <div className="fixture-badminton-stats__summary__header__player__image"></div>
          {homeParticipant?.profile?.name}
        </div>
        <div className="fixture-badminton-stats__summary__header__title">
          Match Stats
        </div>
        <div className="fixture-badminton-stats__summary__header__player away">
          {awayParticipant?.profile?.name}
          <div className="fixture-badminton-stats__summary__header__player__image"></div>
        </div>
      </div>

      <div className="match-stats-list">
        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.tennisPointsWon || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.tennisPointsWon /
                      (homeStats.tennisPointsWon + awayStats.tennisPointsWon) ||
                      0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.pointsWon > awayStats.pointsWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Points Won</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.tennisPointsWon /
                      (homeStats.tennisPointsWon + awayStats.tennisPointsWon) ||
                      0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.pointsWon > homeStats.pointsWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.tennisPointsWon || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.tennisServes || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.tennisServes /
                      (homeStats.tennisServes + awayStats.tennisServes) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.services > awayStats.services
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Serves</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.tennisServes /
                      (homeStats.tennisServes + awayStats.tennisServes) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.services > homeStats.services
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.tennisServes || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.tennisServesWon || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.tennisServesWon /
                      (homeStats.tennisServesWon + awayStats.tennisServesWon) ||
                      0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.servicesWon > awayStats.servicesWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Serves Won</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.tennisServesWon /
                      (homeStats.tennisServesWon + awayStats.tennisServesWon) ||
                      0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.servicesWon > homeStats.servicesWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.tennisServesWon || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.tennisAces || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.tennisAces /
                      (homeStats.tennisAces + awayStats.tennisAces) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.servicesWon > awayStats.servicesWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Aces</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.tennisAces /
                      (homeStats.tennisAces + awayStats.tennisAces) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.servicesWon > homeStats.servicesWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.tennisAces || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.tennisDoubleFaults || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.tennisDoubleFaults /
                      (homeStats.tennisDoubleFaults +
                        awayStats.tennisDoubleFaults) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.doubleFaults > awayStats.doubleFaults
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Double Faults</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.tennisDoubleFaults /
                      (homeStats.tennisDoubleFaults +
                        awayStats.tennisDoubleFaults) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.doubleFaults > homeStats.doubleFaults
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.tennisDoubleFaults || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">
            {homeStats.tennisUnforcedErrors || 0}
          </div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.tennisUnforcedErrors /
                      (homeStats.tennisUnforcedErrors +
                        awayStats.tennisUnforcedErrors) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.unforcedErrors < awayStats.unforcedErrors
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Unforced Errors</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.tennisUnforcedErrors /
                      (homeStats.tennisUnforcedErrors +
                        awayStats.tennisUnforcedErrors) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.unforcedErrors < homeStats.unforcedErrors
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">
            {awayStats.tennisUnforcedErrors || 0}
          </div>
        </div>
      </div>
    </Card>
  );
}
