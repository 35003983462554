import "./EditMatchReport.css";
import Square from "../../mobileComponents/Square";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as ImageIcon } from "../../icons/image.svg";
import { useState, useRef, Fragment, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import mentionModule from "../../utils/mentionModule";
import CustomEditorToolbar from "../../components/CustomEditorToolbar";
import Button from "../../components/Button";
import { useAppState } from "../../utils/appState";
import makeFileUrl from "../../utils/makeFileUrl";
import UploaderWithCropper from "../../components/UploaderWithCropper";
import { useMutation } from "@apollo/client";
import { UPDATE_POST } from "../../api/post";
import captureException from "../../utils/captureException";
import Loader from "../../components/Loader";

export default function EditMatchReport({
  post,
  queryInfo,
  sourceId,
  sourceType,
  postType,
  mediaQueries,
  onCreatePost,
  dismiss,
}) {
  const [editing, setEditing] = useState(true);
  const [, setAppState] = useAppState();
  const editorRef = useRef(null);
  const [headline, setHeadline] = useState(post.title || "");
  const [content, setContent] = useState(post.content);
  const [contentLength, setContentLength] = useState(0);
  const [postPhotos, setPostPhotos] = useState(
    post.tags
      .filter((t) => t.media && t.media.type === "IMAGE")
      .map((t) => t.media) || []
  );
  const [truncatedContent, setTruncatedContent] = useState(
    post.truncatedContent
  );
  const [uploadingMedia, setUploadingMedia] = useState(false);

  useEffect(() => {
    if (editorRef?.current) {
      // console.log("Focusing editor");
      //   editorRef.current.focus();
      setContentLength(editorRef.current.getEditor().getLength());
    }
  }, [editing]);

  const [updatePost, { loading }] = useMutation(UPDATE_POST, {
    update: (cache, { data: { updatePost } }) => {
      if (queryInfo?.query) {
        const q = cache.readQuery({
          query: queryInfo.query,
          variables: queryInfo.variables,
        });

        cache.writeQuery({
          query: queryInfo.query,
          variables: queryInfo.variables,
          data: { ...queryInfo.onUpdateData(q, updatePost) },
        });
      } else {
        if (queryInfo?.onUpdateData) {
          queryInfo.onUpdateData(cache, updatePost);
        }
      }

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "EditMatchReport",
          mutation: "UPDATE_POST",
        },
      });
      console.log(error);
    },
  });

  const headlineLength = headline?.length || 0;

  const onContentChange = (newContent, delta, source, editor) => {
    // console.log(editor.getContents());

    const text = editor.getText().trim();
    if (!text) {
      setTruncatedContent(false);
      setContent(false);
    } else {
      if (editor.getLength() > 200) {
        setTruncatedContent(editor.getContents(0, 200).insert("..."));
      } else {
        setTruncatedContent(editor.getContents());
      }
      setContent(editor.getContents());
    }

    setContentLength(editor.getLength());
  };

  if (uploadingMedia) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <UploaderWithCropper
          accept={".jpg,.png,.jpeg"}
          label="Upload match report photo"
          multiple={false}
          sourceId={sourceId}
          sourceType={sourceType}
          type={"IMAGE"}
          subtype={"MATCH REPORT IMAGE"}
          cropperWidth={240}
          cropperHeight={80}
          onCreateMedia={(cache, createMedia) => {
            if (mediaQueries.photos.onUploadPhoto) {
              mediaQueries.photos.onUploadPhoto(cache, createMedia);
            }

            setPostPhotos([createMedia.media]);

            // setUpload(false);
            setUploadingMedia(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="match-report-form">
      <div className="match-report-form__header">
        <div className="match-report-form__header__title">
          Edit Match Report
        </div>
        <div className="match-report-form__header__dismiss" onClick={dismiss}>
          Dismiss
        </div>
      </div>
      <Square>
        {({ squareRef, dimensions }) => (
          <div
            className="match-report-form__image-uploader"
            ref={squareRef}
            style={{ height: dimensions.width / 3 + "px" }}
          >
            {!!postPhotos.length ? (
              <div className="match-report-form__image-uploader__preview">
                <img src={makeFileUrl(postPhotos[0])} alt="" />
                <div
                  className="match-report-form__image-uploader__preview__dismiss"
                  onClick={() => {
                    setPostPhotos([]);
                  }}
                >
                  <TimesIcon className="match-report-form__image-uploader__preview__dismiss__icon" />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="match-report-form__image-uploader__icon">
                  <ImageIcon style={{ stroke: "var(--light-1)" }} />
                </div>
                <div className="match-report-form__image-uploader__btn">
                  <Button
                    className="secondary blue small"
                    onClick={() => {
                      setUploadingMedia(true);
                    }}
                  >
                    Upload an image
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </Square>

      <div className="match-report-form__headline-editor">
        <div className="match-report-form__headline-editor__label">
          <div className="match-report-form__headline-editor__label__text">
            Headline
          </div>
          {!!headlineLength && (
            <div className="match-report-form__headline-editor__label__character-count">
              {headlineLength}/80
            </div>
          )}
        </div>
        <div className="match-report-form__headline-editor__input">
          <textarea
            value={headline}
            onChange={(e) => {
              setHeadline(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="match-report-form__content-editor">
        <div className="match-report-form__content-editor__label">
          <div className="match-report-form__content-editor__label__text">
            Report
          </div>
          <div className="match-report-form__content-editor__label__character-count">
            {contentLength - 2 < 0 ? 0 : contentLength - 2}/1500
          </div>
        </div>
        <div className="match-report-form__content-editor__input">
          <ReactQuill
            id={"match-report-editor"}
            ref={editorRef}
            defaultValue={content}
            onChange={onContentChange}
            placeholder={"Say something..."}
            modules={{
              toolbar: {
                container: "#custom-toolbar",
              },
              // toolbar: [["bold", "italic", "underline", "link", "emoji"]],
              mention: mentionModule,
              "emoji-toolbar": true,
              "emoji-textarea": false,
              "emoji-shortname": true,
            }}
          />
        </div>
        <div className="match-report-form__content-editor__footer">
          <div className="match-report-form__content-editor__footer__toolbar">
            <CustomEditorToolbar />
          </div>
          <div className="match-report-form__content-editor__footer__submit">
            <Button
              className={"primary blue medium"}
              onClick={() => {
                if (loading) return;

                if (!content) {
                  return;
                }

                if (contentLength - 2 > 1500) {
                  return;
                }

                if (headlineLength > 80) {
                  return;
                }

                const vars = {
                  title: headline || null,
                  truncatedContent,
                  content,
                  tags: {
                    profiles: [],
                    organizations: [],
                    media: [...postPhotos.map((x) => x.id)],
                    mediaSets: [],
                  },
                  contentLength: contentLength - 2,
                };

                content?.ops?.forEach((op) => {
                  if (op.insert?.mention) {
                    vars.tags[op.insert.mention.type + "s"].push(
                      op.insert.mention.id
                    );
                  }
                });

                // console.log(vars);

                // console.log(editorRef.current.getText());

                // return;

                updatePost({
                  variables: {
                    where: { id: post.id },
                    data: vars,
                  },
                });
              }}
            >
              {loading ? <Loader /> : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
