// V1
import "./OrganizationMembers.css";
import Card from "../../Card";
import PartyList from "../../PartyList";
import Placeholder from "../../Placeholder";
import { useQuery } from "@apollo/client";
import { ORGANIZATION_MEMBERS } from "../../../api/organization";
import { INTERACTION_STATS } from "../../../api/profile";
import { Link } from "react-router-dom";
import { useAppState } from "../../../utils/appState";
import ManageOrganizationMembers from "../ManageOrganizationMembers.js";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";
// import makeFileUrl from "../../../utils/makeFileUrl";

function OrganizationMembers({ id, canManagePage }) {
  const [, setAppState] = useAppState();
  const { loading, error, data, refetch } = useQuery(ORGANIZATION_MEMBERS, {
    variables: { id, take: 6, where: { toDate: null, approved: true } },
  });

  const interactionStatsQuery = useQuery(INTERACTION_STATS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "OrganizationMembers",
        query: "ORGANIZATION_MEMBERS",
      },
    });

    console.log(error);
  }

  if (interactionStatsQuery.error) {
    captureException({
      error: interactionStatsQuery.error,
      info: {
        type: "query",
        component: "OrganizationMembers",
        query: "INTERACTION_STATS",
      },
    });
    console.log(interactionStatsQuery.error);
  }

  if (
    loading ||
    interactionStatsQuery.loading ||
    !data ||
    !interactionStatsQuery.data
  ) {
    return (
      <Card className="organization-overview-members-card">
        <div className="admins-list">
          <div className="admin-list-item">
            <div className={"image "}></div>
            <div className="text">
              <div className="primary">
                <Placeholder style={{ width: "80px" }} pulse={true} />
              </div>
              <div className="secondary">
                <Placeholder
                  style={{ width: "40px", marginTop: "8px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="members-list">
          <div className="members-list-item">
            <div className={"image "}></div>
            <div className="text">
              <div className="primary">
                <Placeholder style={{ width: "60px" }} pulse={true} />
              </div>
              <div className="secondary">
                <Placeholder
                  style={{ width: "30px", marginTop: "4px" }}
                  pulse={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="foot">
          <Placeholder style={{ width: "100%" }} pulse={true} />
          <div className="link"></div>
        </div>
      </Card>
    );
  }

  const org = data?.organization;
  const members = org?.members;
  // console.log("Members:", members);
  const admins = members?.filter((m) => m.isAdmin) || [];
  // console.log("Admins:", admins);
  const others = members?.filter((m) => !m.isAdmin) || [];
  const interactionStats = interactionStatsQuery.data?.interactionStats;

  // console.log("MEMBERS:", members);

  return (
    <Card className="organization-overview-members-card">
      <div className="admins-list">
        {!admins?.length && (
          <div className="no-data">
            <div>No admins for this page.</div>
          </div>
        )}
        {admins.map((admin) => (
          <Link
            to={"/app/profile/" + admin.profile.id}
            className="admin-list-item"
            key={admin.id}
          >
            <div
              className={
                "image " + (admin.profile?.profilePhoto ? "has-image" : "")
              }
            >
              {admin.profile?.profilePhoto && (
                <Avatar media={admin.profile?.profilePhoto} />
              )}
            </div>
            <div className="text">
              <div className="primary">{admin.profile.name}</div>
              <div className="secondary">Admin</div>
            </div>
          </Link>
        ))}
      </div>

      {!!others.length && (
        <div className="members-list">
          {others.map((mem) => (
            <Link
              key={mem.id}
              to={"/app/profile/" + mem.profile.id}
              className="members-list-item"
            >
              <div
                className={
                  "image " + (mem.profile?.profilePhoto ? "has-image" : "")
                }
              >
                {mem.profile?.profilePhoto && (
                  <Avatar media={mem.profile?.profilePhoto} />
                )}
              </div>
              <div className="text">
                <div className="primary">{mem.profile.name}</div>
                <div className="secondary">{mem.role}</div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {!others?.length && (
        <div className="no-data">
          <div>No members for this page.</div>
        </div>
      )}

      <div
        className="foot"
        onClick={() => {
          setAppState({
            modal: canManagePage ? (
              <ManageOrganizationMembers
                organization={org}
                refetchSummary={refetch}
              />
            ) : (
              <PartyList
                title={`${org.name}'s Members`}
                query={ORGANIZATION_MEMBERS}
                variables={{ id, where: { toDate: null } }}
                getData={((d) => d?.organization?.members) || []}
                noData={() => (
                  <div className="no-data">
                    <div>{org.name} has no members yet.</div>
                  </div>
                )}
                secondaryText={(item) => item.role}
                totalItems={interactionStats.memberships}
              />
            ),
          });
        }}
      >
        <div className="link">
          {canManagePage ? "Manage Organization Members" : "See all members"}
        </div>
      </div>
    </Card>
  );
}

export default OrganizationMembers;
