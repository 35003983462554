import { gql } from "@apollo/client";

const ORGANIZATION_BIO = gql`
  query organizationQuery($id: ID!) {
    organization(id: $id) {
      id
      name
      city {
        id
        value
        lat
        lng
      }
      cities {
        id
        value
        lat
        lng
      }
      state {
        id
        value
      }
      country {
        id
        value
      }
      interests {
        id
        name
      }
      founded
      phone
      email
      mapsUrl
      locationLabel
      locationValue
      locationLat
      locationLng
    }
  }
`;

export default ORGANIZATION_BIO;
