// V1
import "./FollowButton.css";
import { useQuery, useMutation } from "@apollo/client";
import { IS_FOLLOWED, CREATE_FOLLOW, UNFOLLOW } from "../../api/follow";
import { USER_PROFILE } from "../../api/user";
import Button from "../Button";
import Loader from "../Loader";
import captureException from "../../utils/captureException";
import { useAppState } from "../../utils/appState";
import EditProfile from "../EditProfile";

function FollowButton({ id, type, onFollow, onUnfollow }) {
  const [, setAppState] = useAppState();
  const { loading: loadingUserProfile, data: userProfileData } =
    useQuery(USER_PROFILE);

  const { loading, error, data } = useQuery(IS_FOLLOWED, {
    variables: { id, type },
  });

  const [createFollow, { loading: submitting }] = useMutation(CREATE_FOLLOW, {
    update: (cache, { data: { createFollow } }) => {
      if (createFollow) {
        // Update the is followed query
        cache.writeQuery({
          query: IS_FOLLOWED,
          variables: { id, type },
          data: {
            isFollowed: {
              ...createFollow,
            },
          },
        });

        onFollow && onFollow(cache);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FollowButton",
          mutation: "CREATE_FOLLOW",
        },
      });
      console.log(error);
    },
  });

  const [unfollow, { loading: unfollowing }] = useMutation(UNFOLLOW, {
    update: (cache, { data: { unfollow } }) => {
      if (unfollow) {
        // Update the is followed query
        cache.writeQuery({
          query: IS_FOLLOWED,
          variables: { id, type },
          data: {
            isFollowed: null,
          },
        });

        onUnfollow && onUnfollow(cache, unfollow);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "FollowButton",
          mutation: "UNFOLLOW",
        },
      });
      console.log(error);
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FollowButton",
        query: "IS_FOLLOWED",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const follow = data?.isFollowed;

  // console.log("IS FOLLOWED:", follow);

  const handleButtonClick = (e) => {
    if (loading || submitting || unfollowing) {
      return;
    }

    if (follow && !follow.confirmed) {
      //Cancel request
      unfollow({
        variables: { id: follow.id },
      });
      return;
    }

    if (follow?.confirmed) {
      unfollow({
        variables: { id: follow.id },
      });
      return;
    }

    createFollow({
      variables: {
        followingId: id,
        followingType: type,
      },
    });
  };

  if (loading || loadingUserProfile) {
    return (
      <div className={"app-button primary blue disabled"}>
        <Loader theme={"small"} />
      </div>
    );
  }

  const isCurrentUserProfile = userProfileData.userProfile?.id === id;

  if (isCurrentUserProfile) {
    return (
      <Button
        className="medium primary orange follow-button"
        style={{ width: "100%" }}
        onClick={() => {
          // Edit profile name
          setAppState({
            modal: <EditProfile profile={userProfileData.userProfile} />,
          });
        }}
      >
        Edit Profile
      </Button>
    );
  }

  return (
    <Button
      className={`medium ${
        !follow ? "primary" : "secondary"
      } blue follow-button `}
      onClick={handleButtonClick}
    >
      {submitting || loading || unfollowing ? (
        <Loader theme={"small"} />
      ) : !follow ? (
        "Support"
      ) : follow.confirmed ? (
        <div>
          <span className="hide-on-hover">Supporting</span>
          <span className="show-on-hover">Stop supporting</span>
        </div>
      ) : (
        <div>
          <span className="hide-on-hover">Requested</span>
          <span className="show-on-hover">Cancel Request</span>
        </div>
      )}
    </Button>
  );
}

export default FollowButton;
