import Avatar from "../../../components/Avatar";
import "./BoxScore.css";
import getScore from "../utils/getScore";

export default function BoxScore({
  periodScores = [],
  periods,
  homeParticipant,
  awayParticipant,
  participantType,
}) {
  return (
    <div className="ScoringApp-badminton__scoring__box-score">
      <div
        className="ScoringApp-badminton__scoring__box-score__column"
        style={{ flexGrow: 1 }}
      >
        <div className="ScoringApp-badminton__scoring__box-score__column__header">
          {participantType === "INDIVIDUAL" ? "Player" : "Team"}
        </div>

        <div className="ScoringApp-badminton__scoring__box-score__column__row">
          {(homeParticipant?.organization?.profilePhoto ||
            homeParticipant?.profile?.profilePhoto) && (
            <div className="ScoringApp-badminton__scoring__box-score__column__row__img">
              <Avatar
                media={
                  homeParticipant?.organization?.profilePhoto ||
                  homeParticipant?.profile?.profilePhoto
                }
              />
            </div>
          )}

          {homeParticipant?.organization?.name ||
            homeParticipant?.profile?.name}
        </div>

        <div className="ScoringApp-badminton__scoring__box-score__column__row">
          {(awayParticipant?.organization?.profilePhoto ||
            awayParticipant?.profile?.profilePhoto) && (
            <div className="ScoringApp-badminton__scoring__box-score__column__row__img">
              <Avatar
                media={
                  awayParticipant?.organization?.profilePhoto ||
                  awayParticipant?.profile?.profilePhoto
                }
              />
            </div>
          )}

          {awayParticipant?.organization?.name ||
            awayParticipant?.profile?.name}
        </div>
      </div>

      {periods?.map((p) => (
        <div className="ScoringApp-badminton__scoring__box-score__column">
          <div
            className="ScoringApp-badminton__scoring__box-score__column__header"
            style={{ justifyContent: "center" }}
          >
            {p.label}
          </div>

          <div
            className="ScoringApp-badminton__scoring__box-score__column__row"
            style={{ justifyContent: "center" }}
          >
            {getScore(p, homeParticipant, periodScores)}
          </div>

          <div
            className="ScoringApp-badminton__scoring__box-score__column__row"
            style={{ justifyContent: "center" }}
          >
            {getScore(p, awayParticipant, periodScores)}
          </div>
        </div>
      ))}
    </div>
  );
}
