import { gql } from "@apollo/client";

const FOLLOWING = gql`
  query followingQuery($id: ID!, $take: Int, $cursor: ID) {
    following(id: $id, take: $take, cursor: $cursor) {
      id
      profile {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default FOLLOWING;
