import { gql } from "@apollo/client";

const PROFILE = gql`
  query profileQuery($id: ID!) {
    profile(id: $id) {
      id
      name
      handle
      profilePhoto {
        id
        filename
      }
      profilePhotos {
        id
        filename
      }
      coverPhoto {
        id
        filename
      }
      coverPhotos {
        id
        filename
      }
      canBeClaimed {
        claimId
        canBeClaimed
      }
    }
  }
`;

export default PROFILE;
