import { gql } from "@apollo/client";

const REGISTER = gql`
  mutation register($eventId: ID!, $organizationId: ID) {
    register(eventId: $eventId, organizationId: $organizationId) {
      id
    }
  }
`;

export default REGISTER;
