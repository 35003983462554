import { useState, useCallback } from "react";
import debounce from "lodash.debounce";
import "./ManageEventAwards.css";
import {
  AWARD_TYPES,
  CREATE_AWARD_TYPE,
  AWARD_TYPE,
  DELETE_AWARD_TYPE,
  CREATE_AWARD,
  DELETE_AWARD,
} from "../../../api/awards";
import { UPDATE_EVENT, EVENT_PLACEMENTS } from "../../../api/event";
import {
  SEARCH_EVENT_REGISTRATIONS,
  SEARCH_EVENT_PARTICIPANTS,
} from "../../../api/user";
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";
import Spinner from "../../Spinner";
import Card from "../../Card";
import NewDropdown from "../../NewDropdown";
import Input from "../../Input";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import makeFileUrl from "../../../utils/makeFileUrl";
import Loader from "../../Loader";
import Avatar from "../../Avatar";
import captureException from "../../../utils/captureException";

function AddEventPlacement({ event, placeholder, order }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const query =
    event.participantType === "INDIVIDUAL"
      ? SEARCH_EVENT_REGISTRATIONS
      : SEARCH_EVENT_PARTICIPANTS;
  const [search, { loading, error, data }] = useLazyQuery(query);

  if (error) {
    console.log(error);
  }

  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(
      `id 
       placements{
        id 
        order 
        organization{
          id 
          name 
          handle
          profilePhoto{
            id 
            filename
          }
        }
        profile{
          id 
          name 
          handle
          profilePhoto{
            id 
            filename
          }
        }
       }`
    ),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(event),
          fields: {
            placements(existingPlacementRefs, { readField }) {
              return updateEvent.placements;
            },
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventAwards",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim(), eventId: event.id } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  // const getSuggestions = (term) => {
  //   console.log("Firing query");
  //   if (term) {
  //     search({ variables: { term: term.trim(), eventId: event.id } });
  //   }
  // };

  // const debouncedGetSuggestions = useCallback(
  //   debounce((term) => getSuggestions(term), 500),
  //   { leading: false, trailing: true }
  // );

  const participants =
    event.participantType === "INDIVIDUAL"
      ? data?.searchEventRegistrations?.profiles
      : data?.searchEventParticipants?.organizations;
  // console.log("PARTICIPANTS:", data);

  return (
    <div className="add-event-award-form" style={{ marginTop: "16px" }}>
      <NewDropdown
        wrapperClass="search"
        targetWrapperClass="search-wrapper"
        placement={"bottom-start"}
        menuStyle={{ width: "100%" }}
        target={
          <Input
            iconLeft={
              <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
            }
            placeholder={placeholder || "Search participants"}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSearch(e);
            }}
            useExternalValue={true}
            value={searchTerm}
          />
        }
        menu={({ setShowMenu }) => (
          <Card className="app-search-menu">
            {loading && <Spinner />}
            {!loading && !participants?.length && (
              <div className="no-data">
                {!searchTerm ? (
                  <div>Search for profiles</div>
                ) : (
                  <div>No results found</div>
                )}
              </div>
            )}
            {!!participants?.length && (
              <div className="search-results-section">
                <div className="search-results-section__title">
                  PARTICIPANTS
                </div>
                <div className="search-results-section__items">
                  {participants?.map((p) => (
                    <div
                      key={"add-placement-" + p.id}
                      onClick={() => {
                        setShowMenu(false);
                        setSearchTerm("");
                        if (submitting) return;
                        updateEvent({
                          variables: {
                            where: { id: event.id },
                            data: {
                              placements: {
                                create: {
                                  order,
                                  ...(event.participantType === "INDIVIDUAL"
                                    ? {
                                        profile: {
                                          connect: {
                                            id: p.id,
                                          },
                                        },
                                      }
                                    : {
                                        organization: {
                                          connect: {
                                            id: p.id,
                                          },
                                        },
                                      }),
                                },
                              },
                            },
                          },
                        });
                      }}
                      className="search-results-section__items__item"
                    >
                      <div className="search-results-section__items__item__image">
                        {p.profilePhoto && <Avatar media={p.profilePhoto} />}
                      </div>
                      <div className="search-results-section__items__item__desc">
                        <div className="search-results-section__items__item__desc__primary">
                          {p.name}
                        </div>
                        <div className="search-results-section__items__item__desc__secondary">
                          @{p.handle}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Card>
        )}
      />
    </div>
  );
}

function DeleteEventPlacement({ event, placement }) {
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT(
      `id 
       placements{
        id 
        order 
        organization{
          id 
          name 
          handle
          profilePhoto{
            id 
            filename
          }
        }
        profile{
          id 
          name 
          handle
          profilePhoto{
            id 
            filename
          }
        }
       }`
    ),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(event),
          fields: {
            placements(existingPlacementRefs, { readField }) {
              return updateEvent.placements;
            },
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventAwards.DeleteEventPlacement",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  const participant =
    event.participantType === "INDIVIDUAL"
      ? placement.profile
      : placement.organization;
  const image = participant?.profilePhoto;

  return (
    <div className="delete-event-placement">
      <div className="delete-event-placement__image">
        {image && <img src={makeFileUrl(image)} alt="" />}
      </div>
      <div className="delete-event-placement__text">
        <div className="delete-event-placement__text__primary">
          {participant?.name}
        </div>

        <div className="delete-event-placement__text__secondary">
          @{participant?.handle}
        </div>
      </div>
      <div
        className="delete-event-placement__option"
        onClick={() => {
          if (loading) return;

          updateEvent({
            variables: {
              where: { id: event.id },
              data: {
                placements: {
                  deleteMany: [{ id: placement.id }],
                },
              },
            },
          });
        }}
      >
        {loading ? (
          <Spinner size={12} />
        ) : (
          <TrashIcon style={{ height: "14px", stroke: "var(--red-main" }} />
        )}
      </div>
    </div>
  );
}

function EventPlacementItem({ title, order, event, placement }) {
  return (
    <Card className="event-placements-list__item">
      <div className="event-placements-list__item__title">{title}</div>
      {placement ? (
        <DeleteEventPlacement event={event} placement={placement} />
      ) : (
        <AddEventPlacement event={event} order={order} />
      )}
    </Card>
  );
}

function EventPlacements({ id }) {
  const { loading, error, data } = useQuery(EVENT_PLACEMENTS, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventAwards.EventPlacements",
        query: "EVENT_PLACEMENTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const event = data?.event;
  const placements = event?.placements;

  const eventPlacements = placements?.filter(
    (p) => !p.awardType && !p.fixture && !p.eventPart
  );
  const winner = eventPlacements?.find((p) => p.order === 0);
  const runnerUp = eventPlacements?.find((p) => p.order === 1);
  const secondRunnerUp = eventPlacements?.find((p) => p.order === 2);

  return (
    <div className="event-placements-list">
      <EventPlacementItem
        title={"Winner"}
        order={0}
        event={event}
        placement={winner}
      />
      <EventPlacementItem
        title={"Runner-up"}
        order={1}
        event={event}
        placement={runnerUp}
      />
      <EventPlacementItem
        title={"Second Runner-up"}
        order={2}
        event={event}
        placement={secondRunnerUp}
      />
    </div>
  );
}

function AddEventAward({ eventId, awardType, placementType, placeholder }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [search, { loading, error, data }] = useLazyQuery(
    SEARCH_EVENT_REGISTRATIONS
  );

  if (error) {
    console.log(error);
  }

  const [createAward] = useMutation(CREATE_AWARD, {
    update: (cache, { data: { createAward } }) => {
      cache.modify({
        id: cache.identify(awardType),
        fields: {
          placements(existingPlacementRefs, { readField }) {
            const newPlacementRef = cache.writeFragment({
              data: createAward,
              fragment: gql`
                fragment NewPlacement on Placement {
                  id
                  profile {
                    id
                    name
                    profilePhoto {
                      id
                      filename
                    }
                  }
                  type {
                    id
                    name
                  }
                }
              `,
            });

            if (
              existingPlacementRefs.some(
                (ref) => readField("id", ref) === createAward.id
              )
            ) {
              return existingPlacementRefs;
            }

            return [newPlacementRef, ...existingPlacementRefs];
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventAwards.AddEventAward",
          mutation: "CREATE_AWARD",
        },
      });
      console.log(error);
    },
  });

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim(), eventId } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = data?.searchEventRegistrations?.profiles;
  // console.log(data);

  return (
    <div className="add-event-award-form">
      <NewDropdown
        wrapperClass="search"
        targetWrapperClass="search-wrapper"
        placement={"bottom-start"}
        menuStyle={{ width: "100%" }}
        target={
          <Input
            iconLeft={
              <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
            }
            placeholder={placeholder || "Search for profiles"}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSearch(e);
            }}
            useExternalValue={true}
            value={searchTerm}
          />
        }
        menu={({ setShowMenu }) => (
          <Card className="app-search-menu">
            {loading && <Spinner />}
            {!loading && !profiles?.length && (
              <div className="no-data">
                {!searchTerm ? (
                  <div>Search for profiles</div>
                ) : (
                  <div>No results found</div>
                )}
              </div>
            )}
            {!!profiles?.length && (
              <div className="search-results-section">
                <div className="search-results-section__title">PROFILES</div>
                <div className="search-results-section__items">
                  {profiles?.map((p) => (
                    <div
                      key={"suggestion-" + p.id}
                      onClick={() => {
                        setShowMenu(false);
                        setSearchTerm("");
                        createAward({
                          variables: {
                            data: {
                              event: { connect: { id: eventId } },
                              profile: { connect: { id: p.id } },
                              type: { connect: { name: placementType } },
                              awardType: { connect: { id: awardType.id } },
                            },
                          },
                        });
                      }}
                      className="search-results-section__items__item"
                    >
                      <div className="search-results-section__items__item__image">
                        {p.profilePhoto && <Avatar media={p.profilePhoto} />}
                      </div>
                      <div className="search-results-section__items__item__desc">
                        <div className="search-results-section__items__item__desc__primary">
                          {p.name}
                        </div>
                        <div className="search-results-section__items__item__desc__secondary">
                          @{p.handle}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Card>
        )}
      />
    </div>
  );
}

function EventAwardPlayer({ awardType, placement }) {
  const [deleteAward, { loading: deleting }] = useMutation(DELETE_AWARD, {
    update: (cache, { data: { deleteAward } }) => {
      cache.modify({
        id: cache.identify(awardType),
        fields: {
          placements(existingPlacementRefs, { readField }) {
            return existingPlacementRefs.filter(
              (placementRef) => deleteAward.id !== readField("id", placementRef)
            );
          },
        },
      });
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventAwards.EventAwardPlayer",
          mutation: "DELETE_AWARD",
        },
      });
      console.log(error);
    },
  });
  return (
    <div className="event-award-type-card__section__list__item">
      <div className="event-award-type-card__section__list__item__image">
        {placement.profile?.profilePhoto && (
          <Avatar media={placement.profile.profilePhoto} />
        )}
      </div>

      <div className="event-award-type-card__section__list__item__desc">
        <div className="event-award-type-card__section__list__item__desc__primary">
          {placement.profile.name}
        </div>

        <div className="event-award-type-card__section__list__item__desc__secondary">
          @ {placement.profile.handle}
        </div>
      </div>

      <div className="event-award-type-card__section__list__item__options">
        <div
          className="event-award-type-card__section__list__item__options__option delete"
          onClick={() => {
            if (deleting) return;
            deleteAward({ variables: { id: placement.id } });
          }}
        >
          {deleting ? (
            <Spinner size={12} />
          ) : (
            <TrashIcon className="event-award-type-card__section__list__item__options__option__icon" />
          )}
        </div>
      </div>
    </div>
  );
}

function EventAwardTypeCard({ awardType, eventId }) {
  const { loading, error, data } = useQuery(AWARD_TYPE, {
    variables: { id: awardType.id },
  });

  const [deleteAwardType, { loading: deleting }] = useMutation(
    DELETE_AWARD_TYPE,
    {
      update: (cache, { data: { deleteAwardType } }) => {
        const q = cache.readQuery({
          query: AWARD_TYPES,
          variables: {
            where: { event: { id: eventId } },
          },
        });

        cache.writeQuery({
          query: AWARD_TYPES,
          variables: {
            where: { event: { id: eventId } },
          },
          data: {
            awardTypes: [...q.awardTypes].filter(
              (x) => x.id !== deleteAwardType.id
            ),
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventAwards.EventAwardTypeCard",
            mutation: "DELETE_AWARD_TYPE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventAwards.EventAwardTypeCard",
        query: "AWARD_TYPE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const winner = data?.awardType?.placements?.find(
    (x) => x.type.name === "AWARD"
  );
  const nominees = data?.awardType?.placements?.filter(
    (x) => x.type.name === "AWARD_NOMINATION"
  );

  const canDelete =
    loading || !data ? false : !data?.awardType?.placements?.length;

  return (
    <Card className="event-award-type-card">
      <div className="event-award-type-card__title">
        {awardType.name}
        {canDelete && (
          <div
            className="event-award-type-card__title__delete-btn"
            onClick={() => {
              if (deleting) return;
              deleteAwardType({ variables: { id: awardType.id } });
            }}
          >
            {deleting ? (
              <Spinner size={12} />
            ) : (
              <TrashIcon className="event-award-type-card__title__delete-btn__icon" />
            )}
          </div>
        )}
      </div>
      <div className="event-award-type-card__section">
        <div className="event-award-type-card__section__title">Winner</div>
        <div className="event-award-type-card__section__list">
          {winner ? (
            <EventAwardPlayer awardType={awardType} placement={winner} />
          ) : (
            <AddEventAward
              eventId={eventId}
              awardType={awardType}
              placementType={"AWARD"}
              placeholder={"Add a winner"}
            />
          )}
        </div>
      </div>

      <div className="event-award-type-card__section">
        <div className="event-award-type-card__section__title">Nominees</div>
        <AddEventAward
          eventId={eventId}
          awardType={awardType}
          placementType={"AWARD_NOMINATION"}
          placeholder={"Add a nominee"}
        />
        <div className="event-award-type-card__section__list">
          {nominees?.map((n) => (
            <EventAwardPlayer key={n.id} placement={n} awardType={awardType} />
          ))}
        </div>
      </div>
    </Card>
  );
}

function FixtureAwardTypeCard({ awardType, eventId }) {
  const { loading, error, data } = useQuery(AWARD_TYPE, {
    variables: { id: awardType.id },
  });

  const [deleteAwardType, { loading: deleting }] = useMutation(
    DELETE_AWARD_TYPE,
    {
      update: (cache, { data: { deleteAwardType } }) => {
        const q = cache.readQuery({
          query: AWARD_TYPES,
          variables: {
            where: { event: { id: eventId } },
          },
        });

        cache.writeQuery({
          query: AWARD_TYPES,
          variables: {
            where: { event: { id: eventId } },
          },
          data: {
            awardTypes: [...q.awardTypes].filter(
              (x) => x.id !== deleteAwardType.id
            ),
          },
        });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventAwards.FixtureAwardTypeCard",
            mutation: "DELETE_AWARD_TYPE",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventAwards.FixtureAwardTypeCard",
        query: "AWARD_TYPE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  const canDelete =
    loading || !data ? false : !data?.awardType?.placements?.length;

  return (
    <Card className="fixture-award-type-card">
      <div className="fixture-award-type-card__title">{awardType.name}</div>
      {canDelete && (
        <div className="fixture-award-type-card__options">
          <div
            className="fixture-award-type-card__options__option delete"
            onClick={() => {
              if (deleting) return;
              deleteAwardType({ variables: { id: awardType.id } });
            }}
          >
            {deleting ? (
              <Spinner size={12} />
            ) : (
              <TrashIcon className="fixture-award-type-card__options__option__icon" />
            )}
          </div>
        </div>
      )}
    </Card>
  );
}

function AddAwardType({ eventId, subtype, dismiss, title = "New Award Type" }) {
  const [name, setName] = useState("");
  const [createAwardType, { loading }] = useMutation(CREATE_AWARD_TYPE, {
    update: (cache, { data: { createAwardType } }) => {
      const q = cache.readQuery({
        query: AWARD_TYPES,
        variables: {
          where: { event: { id: eventId } },
        },
      });

      cache.writeQuery({
        query: AWARD_TYPES,
        variables: {
          where: { event: { id: eventId } },
        },
        data: {
          awardTypes: [...q.awardTypes, { ...createAwardType }],
        },
      });

      dismiss();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ManageEventAwards.AddAwardType",
          mutation: "CREATE_AWARD_TYPE",
        },
      });
      console.log(error);
    },
  });
  return (
    <Card className="add-award-type-form__wrapper">
      <form
        className="add-award-type-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (loading || !name) return;
          createAwardType({
            variables: {
              data: {
                event: { connect: { id: eventId } },
                name,
                subtype,
              },
            },
          });
        }}
      >
        <div className="add-award-type-form__title">{title}</div>
        <input
          className="add-award-type-form__input"
          value={name}
          placeholder={"Enter award name here"}
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <div className="add-award-type-form__options">
          <div
            type="submit"
            className="add-award-type-form__options__option success"
            onClick={() => {
              if (loading || !name) return;
              createAwardType({
                variables: {
                  data: {
                    event: { connect: { id: eventId } },
                    name,
                    subtype,
                  },
                },
              });
            }}
          >
            {loading ? <Loader theme={"small"} /> : "Submit"}
          </div>

          <div
            className="add-award-type-form__options__option cancel"
            onClick={dismiss}
          >
            Cancel
          </div>
        </div>
      </form>
    </Card>
  );
}

export default function ManageEventAwards({ id }) {
  const [addingEventAward, setAddingEventAward] = useState(false);
  const [addingFixtureAward, setAddingFixtureAward] = useState(false);

  const { loading, error, data } = useQuery(AWARD_TYPES, {
    variables: {
      where: {
        event: { id },
      },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventAwards",
        query: "AWARD_TYPES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const awardTypes = data?.awardTypes;
  const eventAwardTypes = awardTypes?.filter((x) => x.subtype === "EVENT");
  const fixtureAwardTypes = awardTypes?.filter((x) => x.subtype === "FIXTURE");

  // console.log("awardTypes:", awardTypes);
  return (
    <div className="manage-event-awards">
      <div className="manage-event-awards__section left">
        <div className="manage-event-awards__section__title">
          <div className="manage-event-awards__section__title__text">
            Event Winners
          </div>
        </div>
        <div className="manage-event-awards__section__description">
          Use this section to declare the winner, first runner-up and second
          runner-up for the event.
        </div>

        <EventPlacements id={id} />
      </div>

      <div className="manage-event-awards__section middle">
        <div className="manage-event-awards__section__title">
          <div className="manage-event-awards__section__title__text">
            Event Awards
          </div>
          {/* {addingEventAward ? (
            <AddAwardType
              eventId={id}
              subtype="EVENT"
              dismiss={() => {
                setAddingEventAward(false);
              }}
            />
          ) : (
            <div
              className="manage-event-awards__section__title__add-type-btn"
              onClick={() => {
                setAddingEventAward(true);
              }}
            >
              Add Award
            </div>
          )} */}
        </div>
        <div className="manage-event-awards__section__description">
          Use this section to define the awards that will be given out for the
          entire event, usually once the event ends eg. Player of the Tournament
          or MVP. You can also select the winners and nominees for the award
          here.
        </div>
        {addingEventAward ? (
          <AddAwardType
            eventId={id}
            subtype="EVENT"
            dismiss={() => {
              setAddingEventAward(false);
            }}
          />
        ) : (
          <div
            onClick={() => {
              setAddingEventAward(true);
            }}
            className="manage-event-awards__add-btn"
          >
            Add Award
          </div>
        )}

        <div className="manage-event-awards__section__grid">
          {eventAwardTypes?.map((a) => (
            <EventAwardTypeCard key={a.id} awardType={a} eventId={id} />
          ))}
        </div>
      </div>

      <div className="manage-event-awards__section right">
        <div className="manage-event-awards__section__title">
          <div className="manage-event-awards__section__title__text">
            Fixture Awards
          </div>
          {/* {addingFixtureAward ? (
            <AddAwardType
              eventId={id}
              subtype="FIXTURE"
              dismiss={() => {
                setAddingFixtureAward(false);
              }}
            />
          ) : (
            <div
              className="manage-event-awards__section__title__add-type-btn"
              onClick={() => {
                setAddingFixtureAward(true);
              }}
            >
              Add Award
            </div>
          )} */}
        </div>
        <div className="manage-event-awards__section__description">
          Use this section to define awards that will be given out for each
          fixture in the event eg. Man of the Match.
          <p>
            {" "}
            Note: You can select the winners of the awards from the fixture's
            page.
          </p>
        </div>
        {addingFixtureAward ? (
          <AddAwardType
            eventId={id}
            subtype="FIXTURE"
            dismiss={() => {
              setAddingFixtureAward(false);
            }}
          />
        ) : (
          <div
            onClick={() => {
              setAddingFixtureAward(true);
            }}
            className="manage-event-awards__add-btn"
          >
            Add Award
          </div>
        )}
        <div className="manage-event-awards__section__list">
          {fixtureAwardTypes?.map((a) => (
            <FixtureAwardTypeCard key={a.id} awardType={a} eventId={id} />
          ))}
        </div>
      </div>
    </div>
  );
}
