// V1
import { useState } from "react";
import "./ProfilePhotos.css";
import { Fragment } from "react";
import {
  PROFILE_EVENTS,
  PROFILE_PHOTOS,
  PROFILE_TAGGED_PHOTOS,
} from "../../api/profile";
import { PROFILE, PROFILE_ALBUMS } from "../../api/profile";
import { PAGES } from "../../api/profile";
import { SPORTS } from "../../api/sport";
import Album from "../Album";
import PhotosCard from "../PhotosCard";
import AlbumsCard from "../AlbumsCard";
import FilterInput from "../FilterInput";
import Chip from "../Chip";
import Card from "../Card";
import PhotoBrowser from "../PhotoBrowser";
import Avatar from "../Avatar";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import { useAppState } from "../../utils/appState";

function DirectoryFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  vars,
}) {
  const [value, setValue] = useState(defaultValue || []);

  return (
    <div className="filter-group">
      {/*<div className="filter-group-title">Sport</div>*/}
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...value, item];
            setValue(newValue);
            addFilter(newValue);
            // setAppState({ [appStateKey]: newValue });
          }}
          variables={vars}
        />
      </div>
      <div className="filter-group-applied">
        {value.map((s) => (
          <Chip
            onClick={() => {
              const newValue = value.filter((x) => x.id !== s.id);
              setValue(newValue);
              removeFilter(newValue);
              // setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function ProfilePhotosAlbum({ profile, dismiss }) {
  const [, setAppState] = useAppState();
  return (
    <Card className="app-album-container">
      <div className="app-album-container__head">
        <div
          className="app-album-container__head__link"
          onClick={() => {
            dismiss();
          }}
        >
          <ChevronLeftIcon
            style={{ stroke: "var(--dark-4)", height: "12px" }}
          />
          Back to photos
        </div>
        <div className="app-album-container__head__title">
          <div className="app-album-container__head__title__text">
            Profile Photos
          </div>
        </div>
        <div className="app-album-container__head__meta"></div>
      </div>
      <div className="app-album-container__body">
        {profile?.profilePhotos?.map((m) => (
          <div
            key={m.id}
            className="app-album-container__body__item"
            onClick={() => {
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={PROFILE}
                    vars={{ id: profile.id }}
                    getPhotos={(data) => data.profile?.profilePhotos || []}
                    totalPhotos={(data) =>
                      data.profile?.profilePhotos?.length || 0
                    }
                    media={m}
                  />
                ),
              });
            }}
          >
            <div className="app-album-container__body__item__image">
              <Avatar media={m} />
              {/* <img src={makeFileUrl(m)} /> */}

              <div className="overlay" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function CoverPhotosAlbum({ profile, dismiss }) {
  const [, setAppState] = useAppState();
  return (
    <Card className="app-album-container">
      <div className="app-album-container__head">
        <div
          className="app-album-container__head__link"
          onClick={() => {
            dismiss();
          }}
        >
          <ChevronLeftIcon
            style={{ stroke: "var(--dark-4)", height: "12px" }}
          />
          Back to photos
        </div>
        <div className="app-album-container__head__title">
          <div className="app-album-container__head__title__text">
            Cover Photos
          </div>
        </div>
        <div className="app-album-container__head__meta"></div>
      </div>
      <div className="app-album-container__body">
        {profile?.coverPhotos?.map((m) => (
          <div
            key={m.id}
            className="app-album-container__body__item"
            onClick={() => {
              setAppState({
                modal: (
                  <PhotoBrowser
                    query={PROFILE}
                    vars={{ id: profile.id }}
                    getPhotos={(data) => data.profile?.coverPhotos || []}
                    totalPhotos={(data) =>
                      data.profile?.coverPhotos?.length || 0
                    }
                    media={m}
                  />
                ),
              });
            }}
          >
            <div className="app-album-container__body__item__image">
              <Avatar media={m} />
              {/* <img src={makeFileUrl(m)} /> */}

              <div className="overlay" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

function ProfilePhotos({ id, profile }) {
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [sportsFilter, setSportsFilter] = useState([]);
  const [teamsFilter, setTeamsFilter] = useState([]);
  const [eventsFilter, setEventsFilter] = useState([]);

  const customAlbums = [
    ...(profile.profilePhotos?.length
      ? [
          {
            id: "profilePhotos",
            name: "Profile Photos",
            preview: profile.profilePhoto,
          },
        ]
      : []),
    ...(profile.coverPhotos?.length
      ? [
          {
            id: "coverPhotos",
            name: "Cover Photos",
            preview: profile.coverPhoto,
          },
        ]
      : []),
  ];

  // console.log("PP:", profile);

  return (
    <div className="profile-photos">
      <div className="left-column">
        {selectedAlbum ? (
          <Fragment>
            {selectedAlbum === "profilePhotos" ? (
              <ProfilePhotosAlbum
                profile={profile}
                dismiss={() => {
                  setSelectedAlbum("");
                }}
              />
            ) : selectedAlbum === "coverPhotos" ? (
              <CoverPhotosAlbum
                profile={profile}
                dismiss={() => {
                  setSelectedAlbum("");
                }}
              />
            ) : (
              <Album
                id={selectedAlbum}
                dismiss={() => {
                  setSelectedAlbum("");
                }}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <AlbumsCard
              query={PROFILE_ALBUMS}
              vars={{
                id,
                take: 2,
                filters: { sports: [], teams: [], events: [] },
              }}
              getAlbums={(data) => [
                ...customAlbums,
                ...(data?.profile?.mediaSets || []),
              ]}
              totalAlbums={(data) => data?.profile?.albumsCount || 0}
              noData={`No albums tagged with ${profile.name} yet.`}
              setSelectedAlbum={setSelectedAlbum}
              filters={{ sportsFilter, teamsFilter, eventsFilter }}
              processFilters={(filters) => {
                return {
                  filters: {
                    sports: sportsFilter,
                    teams: teamsFilter,
                    events: eventsFilter,
                  },
                };
              }}
            />

            <PhotosCard
              title="Tagged Photos"
              query={PROFILE_TAGGED_PHOTOS}
              vars={{
                id,
                take: 16,
                filters: { sports: [], teams: [], events: [] },
              }}
              getPhotos={(data) => data?.profile?.taggedMedia || []}
              totalPhotos={(data) => data?.profile?.taggedPhotosCount || 0}
              noData={`No tagged photos of ${profile.name} yet.`}
              filters={{ sportsFilter, teamsFilter, eventsFilter }}
              processFilters={(filters) => {
                return {
                  filters: {
                    sports: sportsFilter,
                    teams: teamsFilter,
                    events: eventsFilter,
                  },
                };
              }}
              onDelete={(cache, { data: { deleteMedia } }) => {
                cache.modify({
                  id: cache.identify(profile),
                  fields: {
                    taggedMedia(existingMediaRefs, { readField }) {
                      return existingMediaRefs.filter(
                        (mediaRef) =>
                          deleteMedia.id !== readField("id", mediaRef)
                      );
                    },
                  },
                });
              }}
            />

            <PhotosCard
              title={profile.name + "'s Matches"}
              query={PROFILE_PHOTOS}
              vars={{
                id,
                take: 16,
                filters: { sports: [], teams: [], events: [] },
              }}
              getPhotos={(data) => data?.profile?.media || []}
              totalPhotos={(data) => data?.profile?.photosCount || 0}
              noData={`No tagged photos of ${profile.name} yet.`}
              filters={{ sportsFilter, teamsFilter, eventsFilter }}
              processFilters={(filters) => {
                return {
                  filters: {
                    sports: sportsFilter,
                    teams: teamsFilter,
                    events: eventsFilter,
                  },
                };
              }}
              onDelete={(cache, { data: { deleteMedia } }) => {
                cache.modify({
                  id: cache.identify(profile),
                  fields: {
                    media(existingMediaRefs, { readField }) {
                      return existingMediaRefs.filter(
                        (mediaRef) =>
                          deleteMedia.id !== readField("id", mediaRef)
                      );
                    },
                  },
                });
              }}
            />
          </Fragment>
        )}
      </div>

      <div className="right-column">
        <div className="card-section">
          <div className="card-section-title">
            Filters<div className="reset">Reset</div>
          </div>

          <DirectoryFilter
            placeholder={"Enter a sport"}
            query={SPORTS}
            items={(d) => {
              return d?.sports;
            }}
            addFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
            }}
            removeFilter={(sports) => {
              setSportsFilter(sports.map((s) => s.name));
            }}
            defaultValue={sportsFilter}
            appStateKey={"sportsFilter"}
          />

          <DirectoryFilter
            placeholder={"Enter a team"}
            query={PAGES}
            vars={{ id, historical: true }}
            items={(d) => {
              return d?.pages?.map((x) => x.organization);
            }}
            addFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
            }}
            removeFilter={(orgs) => {
              setTeamsFilter(orgs.map((s) => s.id));
            }}
            defaultValue={teamsFilter}
            appStateKey={"teamsFilter"}
          />

          <DirectoryFilter
            placeholder={"Enter an event"}
            query={PROFILE_EVENTS}
            vars={{ id }}
            items={(d) => {
              return d?.profile?.events;
            }}
            addFilter={(events) => {
              setEventsFilter(events.map((s) => s.id));
            }}
            removeFilter={(events) => {
              setEventsFilter(events.map((s) => s.id));
            }}
            defaultValue={eventsFilter}
            appStateKey={"eventsFilter"}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfilePhotos;
