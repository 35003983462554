import { useQuery } from "@apollo/client";
import { BADMINTON_STATS } from "../../../../api/fixture";
import Spinner from "../../../Spinner";
import Card from "../../../Card";
import Avatar from "../../../Avatar";

export default function StatsSummary({
  fixtureId,
  homeParticipant,
  awayParticipant,
}) {
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const participantType = data?.fixture?.event?.participantType;

  const homeStats = data?.fixture.statsSummary.find(
    (s) =>
      s.participantId ===
      (homeParticipant?.profile?.id || homeParticipant?.organization?.id)
  );
  const awayStats = data?.fixture.statsSummary.find(
    (s) =>
      s.participantId ===
      (awayParticipant?.profile?.id || awayParticipant?.organization?.id)
  );

  // console.log("SUMMARY:", data?.fixture.statsSummary);
  return (
    <Card className="fixture-badminton-stats__summary">
      <div className="fixture-badminton-stats__summary__header">
        <div className="fixture-badminton-stats__summary__header__player home">
          <div className="fixture-badminton-stats__summary__header__player__image">
            {(homeParticipant?.profile?.profilePhoto ||
              homeParticipant?.organization?.profilePhoto) && (
              <Avatar
                media={
                  homeParticipant?.profile?.profilePhoto ||
                  homeParticipant?.organization?.profilePhoto
                }
              />
            )}
          </div>
          {homeParticipant?.profile?.name ||
            homeParticipant?.organization?.name}
        </div>
        <div className="fixture-badminton-stats__summary__header__title">
          Match Stats
        </div>
        <div className="fixture-badminton-stats__summary__header__player away">
          {awayParticipant?.profile?.name ||
            awayParticipant?.organization?.name}
          <div className="fixture-badminton-stats__summary__header__player__image">
            {(awayParticipant?.profile?.profilePhoto ||
              awayParticipant?.organization?.profilePhoto) && (
              <Avatar
                media={
                  awayParticipant?.profile?.profilePhoto ||
                  awayParticipant?.organization?.profilePhoto
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className="match-stats-list">
        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.pointsWon || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.pointsWon /
                      (homeStats.pointsWon + awayStats.pointsWon) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.pointsWon > awayStats.pointsWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Points Won</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.pointsWon /
                      (homeStats.pointsWon + awayStats.pointsWon) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.pointsWon > homeStats.pointsWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.pointsWon || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.services || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.services /
                      (homeStats.services + awayStats.services) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.services > awayStats.services
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Serves</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.services /
                      (homeStats.services + awayStats.services) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.services > homeStats.services
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.services || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.servicesWon || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.servicesWon /
                      (homeStats.servicesWon + awayStats.servicesWon) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.servicesWon > awayStats.servicesWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Serves Won</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.servicesWon /
                      (homeStats.servicesWon + awayStats.servicesWon) || 0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.servicesWon > homeStats.servicesWon
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.servicesWon || 0}</div>
        </div>

        <div className="match-stats-list-item">
          <div className="home-value">{homeStats.unforcedErrors || 0}</div>
          <div className="home-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (homeStats.unforcedErrors /
                      (homeStats.unforcedErrors + awayStats.unforcedErrors) ||
                      0) *
                      100 +
                    "%",
                  //   background:
                  //     homeStats.unforcedErrors < awayStats.unforcedErrors
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="desc">Unforced Errors</div>
          <div className="away-bar">
            <div className="bar-container">
              <div
                className="fill"
                style={{
                  width:
                    (awayStats.unforcedErrors /
                      (homeStats.unforcedErrors + awayStats.unforcedErrors) ||
                      0) *
                      100 +
                    "%",
                  //   background:
                  //     awayStats.unforcedErrors < homeStats.unforcedErrors
                  //       ? "var(--red-lighter)"
                  //       : "var(--green-lighter)",
                }}
              />
            </div>
          </div>
          <div className="away-value">{awayStats.unforcedErrors || 0}</div>
        </div>
      </div>
    </Card>
  );
}
