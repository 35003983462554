// V1
import { useRef, useState } from "react";
import "./FilterInput.css";
import Dropdown from "../Dropdown";
import Input from "../Input";
import Spinner from "../Spinner";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import { useQuery } from "@apollo/client";
import captureException from "../../utils/captureException";

function FilterInputMenu({
  loading,
  wrapper,
  items,
  onItemSelect,
  setShowMenu,
  setSearch,
  clearOnItemSelect = true,
}) {
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="filter-input-menu"
      style={{ width: wrapper?.current?.offsetWidth || "100%" }}
    >
      <div className="filter-input-menu-list">
        {items.map((item) => (
          <div
            key={item.id}
            className="filter-input-menu-list-item"
            onClick={() => {
              onItemSelect(item);
              clearOnItemSelect ? setSearch("") : setSearch(item.name);
              setShowMenu(false);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

function InputWithQuery({
  query,
  variables,
  items,
  placeholder,
  wrapperRef,
  onItemSelect,
  clearOnItemSelect,
}) {
  const { loading, error, data } = useQuery(query, {
    ...(variables ? { variables } : {}),
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FilterInput.InputWithQuery",
        query: "variable",
      },
    });
    console.log(error);
  }

  const list = (data && items(data)) || [];
  console.log("INPUT LIST : " + placeholder + ":", list);

  return (
    <FilterDropdown
      wrapperRef={wrapperRef}
      placeholder={placeholder}
      items={list}
      loading={loading}
      onItemSelect={onItemSelect}
      clearOnItemSelect={clearOnItemSelect}
    />
  );
}

function InputWithoutQuery({
  items,
  placeholder,
  wrapperRef,
  onItemSelect,
  clearOnItemSelect,
}) {
  return (
    <FilterDropdown
      wrapperRef={wrapperRef}
      placeholder={placeholder}
      items={items}
      onItemSelect={onItemSelect}
      clearOnItemSelect={clearOnItemSelect}
    />
  );
}

function FilterDropdown({
  wrapperRef,
  placeholder,
  items,
  onItemSelect,
  clearOnItemSelect,
}) {
  const [search, setSearch] = useState("");

  const list = items.filter((c) => {
    if (!search) {
      return true;
    } else {
      const regex = new RegExp(search, "i");
      return regex.test(c.name);
    }
  });

  return (
    <div ref={wrapperRef} className="filter-input-dropdown">
      <Dropdown
        target={
          <Input
            placeholder={placeholder}
            iconRight={
              <ChevronDownIcon
                style={{
                  height: 17,
                  marginTop: 2,
                  stroke: "var(--blue-lighter)",
                }}
              />
            }
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            useExternalValue={true}
            value={search}
          />
        }
        menu={({ setShowMenu }) => (
          <FilterInputMenu
            items={list}
            wrapper={wrapperRef}
            onItemSelect={onItemSelect}
            setShowMenu={setShowMenu}
            setSearch={setSearch}
            clearOnItemSelect={clearOnItemSelect}
          />
        )}
      />
    </div>
  );
}

function FilterInput({
  items = [],
  placeholder,
  query,
  variables,
  onItemSelect,
  clearOnItemSelect,
}) {
  const wrapperRef = useRef(null);
  const FilterComponent = query ? InputWithQuery : InputWithoutQuery;

  return (
    <FilterComponent
      query={query}
      variables={variables}
      items={items}
      placeholder={placeholder}
      wrapperRef={wrapperRef}
      onItemSelect={onItemSelect}
      clearOnItemSelect={clearOnItemSelect}
    />
  );
}

export default FilterInput;
