import { useState } from "react";
import "./AuthForm.css";
// import Onboarding from "../Onboarding";
import { useMutation, useLazyQuery } from "@apollo/client";
import { SIGNUP, IS_UNIQUE_HANDLE } from "../../api";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../utils/appState";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as ProfileIcon } from "../../icons/profile.svg";
import { ReactComponent as AtIcon } from "../../icons/at-sign.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import { ReactComponent as TimesCircleIcon } from "../../icons/times-circle.svg";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";

function Signup({ setFormType }) {
  usePageTracking({ title: "Signup" });
  const [, setAppState] = useAppState();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [handle, setHandle] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [invalidFirstname, setInvalidFirstname] = useState(false);
  const [invalidLastname, setInvalidLastname] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidHandle, setInvalidHandle] = useState(false);
  const [handleError, setHandleError] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const [signup, { loading }] = useMutation(SIGNUP, {
    onError: (e) => {
      console.log(e.message);
      setErrorMessage(e.message);
    },
    update: (cache, { data: { signup } }) => {
      // console.log(signup);
      if (!signup.token || !signup.expiresAt) {
        // setError(true);
        return;
      }

      localStorage.setItem("sp_auth_token", signup.token);
      localStorage.setItem("sp_expires_at", signup.expiresAt);
      localStorage.setItem("sp_name", signup.name);
      setAppState({ isLoggedIn: true });

      history.replace("/onboarding");
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Signup",
          mutation: "SIGNUP",
        },
      });
      console.log(error);
      setErrorMessage(error.message);
    },
  });

  const [isUniqueHandle, isUniqueHandleQuery] = useLazyQuery(IS_UNIQUE_HANDLE, {
    onCompleted: (data) => {
      // console.log(data);
      if (!data?.isUniqueHandle?.valid) {
        setInvalidHandle(true);
        setHandleError("This handle is unavailable");
        return;
      }

      signup({
        variables: {
          name: `${firstname} ${lastname}`,
          firstname,
          lastname,
          handle,
          email,
          password,
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="mobile-auth-form">
      <div className="mobile-auth-form__header">Create an Account</div>

      <form
        className="mobile-auth-form__form"
        onSubmit={(e) => {
          // console.log("Form submitted");
          e.preventDefault();

          if (loading) return;

          // setErrorMessage("");

          if (!email || !password || !firstname || !lastname || !handle) {
            if (!email) {
              setInvalidEmail(true);
            }

            if (!password) {
              setInvalidPassword(true);
            }

            if (!firstname) {
              setInvalidFirstname(true);
            }

            if (!lastname) {
              setInvalidLastname(true);
            }

            if (!handle) {
              setInvalidHandle(true);
            }
            return;
          }

          if (handle.length < 8 || handle.length > 30) {
            setInvalidHandle(true);
            setHandleError("Handle must be between 8 & 30 characters long.");
            return;
          }

          const validateHandle = new RegExp("^[0-9a-zA-Z_.]+$");

          if (!validateHandle.test(handle)) {
            setInvalidHandle(true);
            setHandleError(
              "Handle can only include letters, numbers, underscores and periods."
            );
            return;
          }

          isUniqueHandle({
            variables: { handle },
          });
        }}
      >
        <div className="mobile-auth-form__form__row">
          <div className="mobile-auth-form__form__row__column">
            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                Firstname <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <div
                className={
                  "mobile-auth-form__form__group__field " +
                  (invalidFirstname ? "error" : "")
                }
              >
                <div className="mobile-auth-form__form__group__field__icon">
                  <ProfileIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div>

                <input
                  type="text"
                  className="mobile-auth-form__form__group__field__input"
                  value={firstname}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setFirstname(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidFirstname(false);
                  }}
                  autoFocus
                />
              </div>
            </div>
          </div>
          <div className="mobile-auth-form__form__row__column">
            <div className="mobile-auth-form__form__group">
              <div className="mobile-auth-form__form__group__label">
                Lastname <span style={{ color: "var(--red-main)" }}>*</span>
              </div>

              <div
                className={
                  "mobile-auth-form__form__group__field " +
                  (invalidLastname ? "error" : "")
                }
              >
                <div className="mobile-auth-form__form__group__field__icon">
                  <ProfileIcon
                    style={{ stroke: "var(--light-3)", height: 14 }}
                  />
                </div>

                <input
                  type="text"
                  className="mobile-auth-form__form__group__field__input"
                  value={lastname}
                  onChange={(e) => {
                    if (errorMessage) {
                      setErrorMessage("");
                    }
                    setLastname(e.target.value);
                  }}
                  onFocus={() => {
                    setInvalidLastname(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-auth-form__form__group">
          <div className="mobile-auth-form__form__group__label">
            Handle <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <div
            className={
              "mobile-auth-form__form__group__field " +
              (invalidHandle ? "error" : "")
            }
          >
            <div className="mobile-auth-form__form__group__field__icon">
              <AtIcon style={{ stroke: "var(--light-3)", height: 14 }} />
            </div>

            <input
              type="text"
              className="mobile-auth-form__form__group__field__input"
              value={handle}
              onChange={(e) => {
                if (errorMessage) {
                  setErrorMessage("");
                }

                if (handleError) {
                  setHandleError(false);
                }

                setHandle(e.target.value?.replace(/ /g, "").replace(/@/g, ""));
              }}
              onFocus={() => {
                setInvalidHandle(false);
              }}
            />
          </div>
          {handleError && (
            <div className="mobile-auth-form__form__group__assistive">
              <div className="mobile-auth-form__form__group__assistive-icon">
                <TimesCircleIcon
                  style={{
                    height: "10px",
                    width: "10px",
                    stroke: "var(--red-main)",
                  }}
                />
              </div>
              <div className="mobile-auth-form__form__group__assistive-text">
                {handleError}
              </div>
            </div>
          )}
        </div>

        <div className="mobile-auth-form__form__group">
          <div className="mobile-auth-form__form__group__label">
            Email <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <div
            className={
              "mobile-auth-form__form__group__field " +
              (invalidEmail ? "error" : "")
            }
          >
            <div className="mobile-auth-form__form__group__field__icon">
              <MailIcon style={{ fill: "var(--light-3)", height: 14 }} />
            </div>

            <input
              type="email"
              className="mobile-auth-form__form__group__field__input"
              value={email}
              onChange={(e) => {
                if (errorMessage) {
                  setErrorMessage("");
                }
                setEmail(e.target.value);
              }}
              onFocus={() => {
                setInvalidEmail(false);
              }}
            />
          </div>
        </div>

        <div
          className="mobile-auth-form__form__group"
          style={{ marginBottom: "32px" }}
        >
          <div className="mobile-auth-form__form__group__label">
            Password <span style={{ color: "var(--red-main)" }}>*</span>
          </div>

          <div
            className={
              "mobile-auth-form__form__group__field " +
              (invalidPassword ? "error" : "")
            }
          >
            <div className="mobile-auth-form__form__group__field__icon">
              <LockIcon style={{ fill: "var(--light-3)", height: 14 }} />
            </div>

            <input
              type={showPassword ? "text" : "password"}
              id="new-password"
              value={password}
              className="mobile-auth-form__form__group__field__input"
              autoComplete="new-password"
              onChange={(e) => {
                if (errorMessage) {
                  setErrorMessage("");
                }
                setPassword(e.target.value);
              }}
              onFocus={() => {
                setInvalidPassword(false);
              }}
            />

            <div
              className="mobile-auth-form__form__group__field__icon"
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            >
              <EyeIcon
                style={{
                  fill: showPassword ? "var(--blue-main)" : "var(--light-4)",
                  height: 14,
                }}
              />
            </div>
          </div>
        </div>

        {errorMessage && (
          <div className="mobile-auth-form__form__error">{errorMessage}</div>
        )}

        <DebouncedButton
          type="submit"
          className={"mobile-auth-form__form__submit "}
          loading={loading || isUniqueHandleQuery.loading}
          disableDefaultStyles={true}
        >
          {loading || isUniqueHandleQuery.loading ? (
            <Loader theme="small" />
          ) : (
            "Submit"
          )}
        </DebouncedButton>

        {/* <button
          type="submit"
          className={
            "mobile-auth-form__form__submit " + (loading ? "disabled" : "")
          }
        >
          {loading || isUniqueHandleQuery.loading ? (
            <Loader theme="small" />
          ) : (
            "Submit"
          )}
        </button> */}
      </form>

      <div className="mobile-auth-form__footer">
        <div className="mobile-auth-form__footer__statement">
          Already have an account?{" "}
          <Link
            to={"/auth?s=login"}
            onClick={() => {
              setFormType("login");
            }}
            className="mobile-auth-form__footer__statement__link"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
