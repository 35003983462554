import "./FootballEventsSummary.css";
import Card from "../../Card";
import Placeholder from "../../Placeholder";
import AppLink from "../../AppLink";
import Avatar from "../../Avatar";
import { Fragment, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { FIXTURE_STATS } from "../../../api/functions";
import { ReactComponent as FootballIcon } from "../../../icons/football.svg";
import { ReactComponent as SwapIcon } from "../../../icons/swap.svg";
import showStat from "../../../scoring/ScoreFootball/utils/showStat";
import Loader from "../../Loader";

function ShowMoreStats({ id, cursor, setStats }) {
  // console.log("Show more vars:", variables);
  const [hidden, setHidden] = useState(false);
  const [loadMore, { loading }] = useLazyQuery(FIXTURE_STATS, {
    onCompleted: (data) => {
      if (!data?.fixture?.stats?.length) {
        setHidden(true);
      }
      setStats((prev) => [...prev, ...(data?.fixture?.stats || [])]);
    },
  });

  if (hidden) {
    return (
      <div className="fixture-events-summary__list__period">END OF LIST</div>
    );
  }

  return (
    <div
      className="fixture-events-summary__list__show-more"
      onClick={() => {
        loadMore({
          variables: {
            id,
            where: {
              statType: {
                name: {
                  in: [
                    "Goal",
                    "Red Card",
                    "Yellow Card",
                    "Shot On Target",
                    "Shot Off Target",
                    "Save",
                    "Sub On",
                    "Sub Off",
                    "Penalty Won",
                    "Penalty Scored",
                    "Penalty Missed",
                    "Assist",
                    "Key Pass",
                    "Own Goal",
                  ],
                },
              },
            },
            take: 25,
            cursor: cursor,
          },
        });
      }}
    >
      {loading ? <Loader /> : "SHOW MORE"}
    </div>
  );
}

function RedCardEventItem({ stat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <div
              style={{
                height: "12px",
                width: "9px",
                background: "var(--red-main)",
                borderRadius: "2px",
                marginRight: "8px",
                marginTop: "1px",
              }}
            />
          </div>

          <div className="desc__title__text">Red Card</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            is sent off for{" "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>
            .
          </p>
        </div>
      </div>
      <div className="profile-image">
        {stat.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function YellowCardEventItem({ stat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <div
              style={{
                height: "12px",
                width: "9px",
                background: "var(--yellow-main)",
                borderRadius: "2px",
                marginRight: "8px",
                marginTop: "1px",
              }}
            />
          </div>

          <div className="desc__title__text">Yellow Card</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            of{" "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>{" "}
            gets a yellow card. .
          </p>
        </div>
      </div>
      <div className="profile-image">
        {stat.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function OwnGoalEventItem({ stat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <FootballIcon
              style={{
                height: "16px",
                fill: "var(--blue-main)",
              }}
            />
          </div>

          <div className="desc__title__text">OWN GOAL</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            of{" "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>{" "}
            scores an own goal.
          </p>
        </div>
      </div>
      <div className="profile-image">
        {stat.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function SubstitutionEventItem({ subOnStat, subOffStat }) {
  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{subOffStat?.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <SwapIcon
              style={{
                height: "16px",
                fill: "var(--dark-4)",
                marginLeft: "-8px",
              }}
            />
          </div>

          <div className="desc__title__text">Substitution</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + subOffStat?.profile?.id}>
              <span className="bold">{subOffStat?.profile?.name}</span>
            </AppLink>{" "}
            of{" "}
            <AppLink to={"/app/page/" + subOffStat?.organization?.id}>
              <span className="bold">{subOffStat?.organization?.name}</span>
            </AppLink>{" "}
            comes off{subOnStat ? "and is replaced by " : "."}
            {subOnStat && (
              <AppLink to={"/app/profile/" + subOnStat?.profile?.id}>
                <span className="bold">{subOnStat?.profile?.name}</span>
              </AppLink>
            )}
          </p>
        </div>
      </div>
      <div className="profile-image">
        {subOffStat?.profile?.profilePhoto && (
          <Avatar media={subOffStat.profile.profilePhoto} />
        )}
      </div>

      <div className="profile-image">
        {subOnStat?.profile?.profilePhoto && (
          <Avatar media={subOnStat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {subOffStat?.organization?.profilePhoto && (
          <Avatar media={subOffStat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function GoalEventItem({ stat, stats }) {
  //   const goalStat = stats.find((s) => s.id === goalStatId);
  const assistStat = stats.find(
    (s) =>
      s.statType.name === "Assist" &&
      s.relationshipsTo?.find((r) => r.from?.id === stat?.id)
  );
  const keyPassStat = stats.find(
    (s) =>
      s.statType.name === "Key Pass" &&
      s.relationshipsTo?.find((r) => r.from?.id === stat?.id)
  );
  const penaltyScored = stats.find(
    (s) =>
      s.statType.name === "Penalty Scored" &&
      s.relationshipsTo?.find((r) => r.from?.id === stat?.id)
  );

  //   const assistStat = goalStat.relationshipsFrom?.find(
  //     (s) => s?.to?.statType?.name === "Assist"
  //   )?.to;
  //   const keyPassStat = goalStat.relationshipsFrom?.find(
  //     (s) => s?.to?.statType?.name === "Key Pass"
  //   )?.to;
  //   const penaltyScored = goalStat.relationshipsFrom?.find(
  //     (s) => s?.to?.statType?.name === "Penalty Scored"
  //   )?.to;

  return (
    <div className="fixture-events-summary__list__item">
      <div className="timestamp">{stat?.timeStamp || 1}'</div>
      <div className="desc">
        <div className="desc__title">
          <div className="desc__title__icon">
            <FootballIcon
              style={{
                height: "16px",
                fill: "var(--blue-main)",
                marginLeft: "-4px",
              }}
            />
          </div>

          <div className="desc__title__text">GOAL!!!</div>
        </div>
        <div className="desc-text">
          <p>
            <AppLink to={"/app/profile/" + stat?.profile?.id}>
              <span className="bold">{stat?.profile?.name}</span>
            </AppLink>{" "}
            scores a {penaltyScored ? "penalty" : "goal"} for{" "}
            <AppLink to={"/app/page/" + stat?.organization?.id}>
              <span className="bold">{stat?.organization?.name}</span>
            </AppLink>
            .
          </p>
          {assistStat && (
            <p>
              Assisted by{" "}
              <AppLink to={"/app/profile/" + assistStat?.profile?.id}>
                <span className="bold">{assistStat?.profile?.name}</span>
              </AppLink>
              .
            </p>
          )}
          {keyPassStat && (
            <p>
              Key pass by{" "}
              <AppLink to={"/app/profile/" + keyPassStat?.profile?.id}>
                <span className="bold">{keyPassStat?.profile?.name}</span>
              </AppLink>
              .
            </p>
          )}
        </div>
      </div>
      <div className="profile-image">
        {stat?.profile?.profilePhoto && (
          <Avatar media={stat.profile.profilePhoto} />
        )}
      </div>
      <div className="team-image">
        {stat?.organization?.profilePhoto && (
          <Avatar media={stat.organization.profilePhoto} />
        )}
      </div>
    </div>
  );
}

function EventItem({ stat, stats }) {
  const isShown = showStat(stat, stats);

  if (!isShown) {
    return <div />;
  }

  if (stat.statType.name === "Goal") {
    return <GoalEventItem stat={stat} stats={stats} />;
  } else if (stat.statType.name === "Own Goal") {
    return <OwnGoalEventItem stat={stat} />;
  } else if (stat.statType.name === "Red Card") {
    return <RedCardEventItem stat={stat} />;
  } else if (stat.statType.name === "Yellow Card") {
    return <YellowCardEventItem stat={stat} />;
  } else if (stat.statType.name === "Sub Off") {
    return (
      <SubstitutionEventItem
        subOffStat={stat}
        subOnStat={
          stat.relationshipsFrom?.find((x) => x?.to?.statType.name === "Sub On")
            ?.to.id
        }
      />
    );
  } else {
    return (
      <div className="fixture-events-summary__list__item">
        <div className="timestamp">{(stat.timeStamp || 1) + "'"}</div>
        <div className="desc">
          <div className="desc-text">
            <span>{stat.statType.name} by</span>{" "}
            <AppLink to={"/app/profile/" + stat.profile?.id}>
              <span className="bold">{stat.profile?.name}</span>
            </AppLink>{" "}
            {stat.organization?.id && "for "}
            <AppLink to={"/app/page/" + stat.organization?.id}>
              <span className="bold">{stat.organization?.name}</span>
            </AppLink>
          </div>
          {/* {stat.relationshipsFrom.map((relation) => (
            <div key={relation.id} className="desc">
              <div className="desc-text">
                <span className="bold" style={{ color: "var(--dark-4)" }}>
                  {relation?.to?.statType.name}
                </span>{" "}
                -{" "}
                <AppLink to={"/app/profile/" + relation?.to?.profile?.id}>
                  <span className="bold">{relation?.to?.profile?.name}</span>
                </AppLink>
              </div>
            </div>
          ))} */}
        </div>
        <div className="profile-image">
          {stat.profile?.profilePhoto && (
            <Avatar media={stat.profile.profilePhoto} />
          )}
        </div>
        {stat.organization && (
          <div className="team-image">
            {stat.organization?.profilePhoto && (
              <Avatar media={stat.organization.profilePhoto} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default function FootballEventsSummary({ id }) {
  const [stats, setStats] = useState([]);
  const { loading, error, data } = useQuery(FIXTURE_STATS, {
    variables: {
      id,
      where: {
        statType: {
          name: {
            in: [
              "Goal",
              "Red Card",
              "Yellow Card",
              "Shot On Target",
              "Shot Off Target",
              "Save",
              "Sub On",
              "Sub Off",
              "Penalty Won",
              "Penalty Scored",
              "Penalty Missed",
              "Assist",
              "Key Pass",
              "Own Goal",
            ],
          },
        },
      },
      take: 25,
    },
    onCompleted: (data) => {
      // console.log("Stats query:", data);
      setStats([...data.fixture.stats]);
    },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  //   console.log(networkStatus);

  if (loading || !data) {
    return (
      <Card className={"fixture-events-summary " + (isMobile ? "mobile" : "")}>
        <div className="card-title">
          <Placeholder style={{ height: "12px", width: "80px" }} pulse={true} />
        </div>
        <div className="fixture-events-summary__list">
          {[1, 2, 3].map((i) => (
            <div
              key={"fes-ph-" + i}
              className="fixture-events-summary__list__item"
            >
              <div className="timestamp">
                <Placeholder
                  style={{ height: "12px", width: "20px" }}
                  pulse={true}
                />
              </div>
              <div className="desc">
                <div className="desc-text">
                  <Placeholder
                    style={{ height: "12px", width: "160px" }}
                    pulse={true}
                  />
                </div>
              </div>
              <div className="profile-image"></div>
              <div className="team-image"></div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  const statsByPeriod = stats.reduce((accum, curr) => {
    const p = curr.fixturePeriod;
    const existingPeriodEntry = accum.findIndex((x) => x.period?.id === p?.id);

    // console.log(existingPeriodEntry);

    if (existingPeriodEntry < 0) {
      // console.log("Making New Group");
      accum.push({ period: { ...p }, stats: [curr] });
    } else {
      // console.log("Existing Group");
      accum[existingPeriodEntry].stats.push(curr);
    }

    return accum;
  }, []);

  // console.log("Stats by period:", statsByPeriod);

  return (
    <Card className={"fixture-events-summary " + (isMobile ? "mobile" : "")}>
      <div className="card-title">Match Events</div>
      <div className="fixture-events-summary__list">
        {!stats?.length && (
          <div className="no-data">No match events added yet</div>
        )}

        {statsByPeriod.map((group, i) => (
          <Fragment key={"stats-by-period-" + group.period?.id + i}>
            {data?.fixture?.completed ? (
              <div className="fixture-events-summary__list__period">
                FULL TIME
              </div>
            ) : (
              <div className="fixture-events-summary__list__period">
                {group.period?.label?.toUpperCase()}
              </div>
            )}
            {group.stats
              .sort(
                (a, b) =>
                  b.timeStamp - a.timeStamp ||
                  (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1)
              )
              .map((s) => (
                // <div>Stats</div>
                <EventItem key={s.id} stat={s} stats={stats} />
              ))}

            {/* <div className="fixture-events-summary__list__period">
              START OF {group.period?.label?.toUpperCase()}
            </div> */}
          </Fragment>
        ))}

        {!!stats.length && (
          <ShowMoreStats
            id={id}
            cursor={stats[stats.length - 1].id}
            setStats={setStats}
          />
        )}

        {/* <div
          className="fixture-events-summary__list__show-more"
          onClick={() => {
            if (stats.length) {
              console.log(stats[stats.length - 1].id);
              fetchMore({
                variables: {
                  cursor: stats[stats.length - 1].id,
                },
              });
            }
          }}
        >
          {networkStatus === 3 ? <Loader /> : "SHOW MORE"}
        </div> */}
      </div>
      {/* <div className="fixture-events-summary__footer hover-pointer">
      Show More
    </div> */}
    </Card>
  );
}
