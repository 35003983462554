import "./MatchReport.css";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { CLAP, UNCLAP, SAVE_POST, UNSAVE_POST } from "../../api/user";
import Card from "../Card";
import Avatar from "../Avatar";
import { ReactComponent as ClapIcon } from "../../icons/clap.svg";
import { ReactComponent as BookmarkIcon } from "../../icons/bookmark.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import captureException from "../../utils/captureException";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import NewDropdown from "../NewDropdown/NewDropdown";
import { useAppState } from "../../utils/appState";
import CreateSupportTicket from "../CreateSupportTicket";
import { isMobile } from "react-device-detect";

export default function MatchReport({
  post,
  options,
  canEdit,
  canDelete,
  hideHeader,
  hideLeagueName,
  contentExpanded,
}) {
  const [, setAppState] = useAppState();
  const [clapped, setClapped] = useState(post.isClapped);
  const [clapCount, setClapCount] = useState(post.clapCount);
  const [saved, setSaved] = useState(post.isSaved);
  const [truncated, setTruncated] = useState(
    contentExpanded ? false : (post.contentLength || 0) > 200 ? true : false
  );

  const [clap, { loading: clapping }] = useMutation(CLAP, {
    update: (cache, { data }) => {
      if (data.clap?.id) {
        setClapped(true);
        setClapCount((prev) => prev + 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "CLAP",
        },
      });
      console.log(error);
    },
  });
  const [unclap, { loading: unclapping }] = useMutation(UNCLAP, {
    update: (cache, { data }) => {
      if (data.unclap?.id) {
        setClapped(false);
        setClapCount((prev) => prev - 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "UNCLAP",
        },
      });
      console.log(error);
    },
  });
  const [save, { loading: saving }] = useMutation(SAVE_POST, {
    update: (cache, { data }) => {
      if (data.savePost?.id) {
        setSaved(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "SAVE_POST",
        },
      });
      console.log(error);
    },
  });
  const [unsave, { loading: unsaving }] = useMutation(UNSAVE_POST, {
    update: (cache, { data }) => {
      if (data.unsavePost?.id) {
        setSaved(false);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "UNSAVE_POST",
        },
      });
      console.log(error);
    },
  });

  useEffect(() => {
    const onMentionClick = (e) => {
      e.stopImmediatePropagation();
      window.open(process.env.REACT_APP_ENDPOINT + e.value.link, "_blank");
    };
    window.addEventListener("mention-clicked", onMentionClick, false);

    return () => {
      window.removeEventListener("mention-clicked", onMentionClick, false);
    };
  });

  const fixture = post.source?.fixture;
  const event = fixture?.event;
  const participantType = event?.participantType;

  const participants = fixture.roles
    .filter((r) => r.type.name === "PARTICIPANT")
    .sort((a, b) => a.order - b.order);

  const homeScore = fixture.placements?.find((p) => {
    if (participantType === "INDIVIDUAL") {
      return p.profile?.id === participants[0].profile?.id;
    } else {
      return p.organization?.id === participants[0].organization?.id;
    }
  });

  const awayScore = fixture.placements?.find((p) => {
    if (participantType === "INDIVIDUAL") {
      return p.profile?.id === participants[1].profile?.id;
    } else {
      return p.organization?.id === participants[1].organization?.id;
    }
  });

  const media = post.tags.filter((x) => !!x.media);

  console.log("Match report media:", media);

  return (
    <Card
      className="app-match-report"
      style={isMobile ? { borderRadius: "0px" } : {}}
    >
      <div className="app-match-report__header">
        <div className="app-match-report__header__highlight">
          <div className="app-match-report__header__highlight__item green" />
          <div className="app-match-report__header__highlight__item blue" />
        </div>
        <div className="app-match-report__header__title">Match Report</div>

        <div className="app-match-report__header__options">
          {canEdit && (
            <div
              className="app-match-report__header__options__option edit"
              onClick={canEdit}
            >
              Edit
            </div>
          )}

          {canDelete && (
            <div
              className="app-match-report__header__options__option delete"
              onClick={canDelete}
            >
              Delete
            </div>
          )}

          {!hideLeagueName && (
            <Link
              to={"/app/event/" + event.id}
              className="app-match-report__header__options__option"
            >
              {event?.name}
            </Link>
          )}
        </div>
      </div>

      {!hideHeader && (
        <Link
          to={"/app/fixture/" + fixture.id}
          className="app-match-report__fixture"
        >
          <div className="app-match-report__fixture__team home">
            <div className="app-match-report__fixture__team__image">
              {(participants[0].organization?.profilePhoto ||
                participants[0].profile?.profilePhoto) && (
                <Avatar
                  media={
                    participants[0].organization?.profilePhoto ||
                    participants[0].profile?.profilePhoto
                  }
                />
              )}
            </div>

            <div className="app-match-report__fixture__team__name">
              {participants[0].organization?.name ||
                participants[0].profile?.name}
            </div>

            {fixture.completed && (
              <div className="app-match-report__fixture__team__score">
                {homeScore?.valueString || homeScore?.value || 0}
              </div>
            )}
          </div>

          <div className="app-match-report__fixture__seperator">
            {fixture.completed ? "FT" : "VS"}
          </div>

          <div className="app-match-report__fixture__team away">
            {fixture.completed && (
              <div className="app-match-report__fixture__team__score">
                {awayScore?.valueString || awayScore?.value || 0}
              </div>
            )}

            <div className="app-match-report__fixture__team__name">
              {participants[1].organization?.name ||
                participants[1].profile?.name}
            </div>
            <div className="app-match-report__fixture__team__image">
              {(participants[1].organization?.profilePhoto ||
                participants[1].profile?.profilePhoto) && (
                <Avatar
                  media={
                    participants[1].organization?.profilePhoto ||
                    participants[1].profile?.profilePhoto
                  }
                />
              )}
            </div>
          </div>
        </Link>
      )}

      {!!media?.length && (
        <div className="app-match-report__image">
          <Avatar media={media[0].media} />
        </div>
      )}

      {post.title && (
        <div className="app-match-report__headline">
          <Link to={`/app/fixture/${fixture.id}?t=report`}>{post.title}</Link>
        </div>
      )}

      <div className="app-match-report__content">
        <ReactQuill
          id={"post-content-" + post.id}
          value={truncated ? post.truncatedContent : post.content}
          readOnly={true}
          modules={{ toolbar: false }}
        />

        {truncated && (
          <Link
            to={`/app/fixture/${fixture.id}?t=report`}
            className="app-match-report__content__truncate-btn"
          >
            Read the full report
          </Link>
        )}
      </div>
      <div className="app-match-report__footer">
        <div
          className="app-match-report__footer__option"
          onClick={() => {
            if (clapping || unclapping) {
              return;
            }

            if (clapped) {
              unclap({ variables: { postId: post.id } });
            } else {
              clap({ variables: { postId: post.id } });
            }
          }}
        >
          <ClapIcon
            style={{
              fill: clapped ? "var(--blue-main)" : "var(--dark-4)",
              height: "20px",
            }}
          />
          <div className="clap-count">{clapCount || ""}</div>
        </div>

        <div className="app-match-report__footer__options right">
          <div
            className="app-match-report__footer__option"
            onClick={() => {
              if (saving || unsaving) {
                return;
              }

              if (saved) {
                unsave({ variables: { id: post.id } });
              } else {
                save({ variables: { id: post.id } });
              }
            }}
          >
            <BookmarkIcon
              style={{
                height: "18px",
                stroke: saved ? "var(--blue-darker)" : "var(--dark-4)",
                ...(saved ? { fill: "var(--blue-main)" } : {}),
              }}
            />
          </div>

          <NewDropdown
            target={
              <div className={"app-match-report__footer__option"}>
                <EllipsisIcon
                  style={{
                    height: "18px",
                    fill: "var(--dark-4)",
                    transform: "rotate(90deg)",
                  }}
                />
              </div>
            }
            menu={
              <Card className="app-post-card__options-menu">
                <div
                  className="app-post-card__options-menu__item"
                  onClick={() => {
                    setAppState({
                      modal: (
                        <CreateSupportTicket
                          title={"Report Post"}
                          type={"FLAGGED_POST"}
                          fields={{
                            email: false,
                            phone: false,
                            subject: false,
                          }}
                          messageLabel={"Describe your issue (optional)"}
                          messageRequired={false}
                          getVars={({ email, phone, subject, description }) => {
                            const v = {
                              type: "FLAGGED_POST",
                              description: description || undefined,
                              post: {
                                connect: {
                                  id: post.id,
                                },
                              },
                            };

                            return v;
                          }}
                          successMessage={{
                            title: "Thank you for your feedback.",
                            message:
                              "Our team will review this post and take any necessary action.",
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Report this post
                </div>
              </Card>
            }
          />
        </div>
      </div>
    </Card>
  );
}
