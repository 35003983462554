// V1
import { useQuery } from "@apollo/client";
import { useState } from "react";
import RequestCard from "../RequestCard";
import Notification from "../../Notification";
import { USER_REQUESTS } from "../../../api/user";
import captureException from "../../../utils/captureException";

function RequestsListWrapper({ requests }) {
  const [requestCount, setRequestCount] = useState(requests.length);

  if (!requestCount) {
    return <div />;
  }

  return (
    <div className="card-section" style={{ marginBottom: "32px" }}>
      <div className="card-section-title">
        Requests <Notification count={requestCount} />
      </div>
      {requests.map((request, i) => (
        <RequestCard
          key={"request-" + i}
          request={request}
          setRequestCount={setRequestCount}
        />
      ))}
    </div>
  );
}

function RequestsList() {
  const { error, data } = useQuery(USER_REQUESTS, {
    fetchPolicy: "network-only",
  });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "RequestsList",
        query: "USER_REQUESTS",
      },
    });
    console.log(error);
  }
  const requests = data?.userRequests;
  console.log("REQUESTS:", requests);
  if (!requests?.length) {
    return <div />;
  }

  return <RequestsListWrapper requests={requests} />;
}

export default RequestsList;
