import { useState } from "react";
import "./Settings.css";
import { ReactComponent as ChevronLeft } from "../../../../icons/chevron-left.svg";
import { ReactComponent as PencilIcon } from "../../../../icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../../icons/trash.svg";
import { ReactComponent as CheckIcon } from "../../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../../icons/times.svg";
import Card from "../../../Card";
import Spinner from "../../../Spinner";
import { useQuery, useMutation } from "@apollo/client";
import {
  BADMINTON_STATS,
  FIXTURE,
  UPDATE_FIXTURE,
} from "../../../../api/fixture";
import { useAppState } from "../../../../utils/appState";
import { useHistory } from "react-router-dom";
import captureException from "../../../../utils/captureException";

function Period({ period, index, fixtureId, setPeriods, canDelete }) {
  const [editing, setEditing] = useState(false);
  const [updateFixture, { loading }] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.Settings.Period",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  if (editing) {
    return (
      <CreateOrEditPeriod
        id={period.id}
        defaultLabel={period.label}
        defaultDuration={period.duration}
        order={period.order}
        setPeriods={setPeriods}
        fixtureId={fixtureId}
        dismiss={() => {
          setEditing(false);
        }}
      />
    );
  }

  return (
    <div className="period-settings-card__body__item">
      <div className="period-settings-card__body__item__details">
        <div className="order">{index + 1}.</div>
        {period.label}
      </div>
      <div className="period-settings-card__body__item__options">
        <button
          className="period-settings-card__body__item__options__option edit"
          onClick={() => {
            setEditing(true);
          }}
        >
          <PencilIcon className="period-settings-card__body__item__options__option__icon edit" />
        </button>
        <button
          className={
            "period-settings-card__body__item__options__option delete " +
            (canDelete || loading ? "" : "disabled")
          }
          onClick={() => {
            if (!canDelete || loading) return;

            updateFixture({
              variables: {
                where: { id: fixtureId },
                data: {
                  periods: {
                    deleteMany: [{ id: period.id }],
                  },
                },
              },
              update: (cache, { data: { updateFixture } }) => {
                // console.log(updateFixture);
                const q = cache.readQuery({
                  query: FIXTURE,
                  variables: { id: fixtureId },
                });

                cache.writeQuery({
                  query: FIXTURE,
                  variables: { id: fixtureId },
                  data: {
                    fixture: {
                      ...q.fixture,
                      periods: [...updateFixture.periods],
                    },
                  },
                });

                setPeriods(
                  [...updateFixture.periods].sort((a, b) => a.order - b.order)
                );
              },
            });
          }}
        >
          <TrashIcon className="period-settings-card__body__item__options__option__icon delete" />
        </button>
      </div>
    </div>
  );
}

function CreateOrEditPeriod({
  id,
  defaultLabel,
  defaultDuration,
  order,
  setPeriods,
  fixtureId,
  dismiss,
}) {
  const [label, setLabel] = useState(defaultLabel || "");
  const [duration] = useState(defaultDuration || 0);
  const [updateFixture, { loading }] = useMutation(UPDATE_FIXTURE, {
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreBadminton.Settings.CreateOrEditPeriod",
          mutation: "UPDATE_FIXTURE",
        },
      });
      console.log(error);
    },
  });

  return (
    <form
      className="period-form"
      onSubmit={(e) => {
        e.preventDefault();
        const vars = {};

        if (id) {
          vars.update = {
            where: { id },
            data: {
              label: label,
              order: order,
              duration: Number(duration) || 0,
            },
          };
        } else {
          vars.create = [
            {
              label: label,
              order: order,
              duration: Number(duration) || 0,
            },
          ];
        }

        updateFixture({
          variables: {
            where: { id: fixtureId },
            data: {
              periods: vars,
            },
          },
          update: (cache, { data: { updateFixture } }) => {
            // console.log(updateFixture);
            const q = cache.readQuery({
              query: FIXTURE,
              variables: { id: fixtureId },
            });

            cache.writeQuery({
              query: FIXTURE,
              variables: { id: fixtureId },
              data: {
                fixture: {
                  ...q.fixture,
                  periods: [...updateFixture.periods],
                },
              },
            });

            setPeriods(
              [...updateFixture.periods].sort((a, b) => a.order - b.order)
            );
            dismiss();
          },
        });
      }}
    >
      <div className="period-form__row">
        <div className="period-form__group text">
          <div className="period-form__group__label">Label</div>
          <input
            type="text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            className="period-form__input"
            placeholder="Enter label here"
          />
        </div>

        {/* <div className="period-form__group">
          <div className="period-form__group__label">Minutes</div>
          <input
            type="number"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            className="period-form__input duration"
          />
        </div> */}

        <div className="period-form__options">
          <button
            type="submit"
            className={
              "period-form__options__option submit " +
              (loading ? "disbled" : "")
            }
          >
            {loading ? <Spinner /> : <CheckIcon className="icon" />}
          </button>
          <div
            className={
              "period-form__options__option cancel " +
              (loading ? "disbled" : "")
            }
            onClick={() => {
              dismiss();
            }}
          >
            <TimesIcon className="icon" />
          </div>
        </div>
      </div>
    </form>
  );
}

function PeriodSettings({ defaultPeriods, fixtureId }) {
  const [periods, setPeriods] = useState(defaultPeriods);
  const [adding, setAdding] = useState(false);
  const { loading, error, data } = useQuery(BADMINTON_STATS, {
    variables: { id: fixtureId },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ScoreBadminton.Settings.PeriodSettings",
        query: "BADMINTON_STATS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <Card className="period-settings-card">
        <div className="period-settings-card__title">
          Edit periods in this fixture
        </div>
        <div className="period-settings-card__body">
          <Spinner />
        </div>
      </Card>
    );
  }

  const stats = data?.fixture?.stats;

  return (
    <Card className="period-settings-card">
      <div className="period-settings-card__title">
        Edit periods in this fixture
      </div>
      <div className="period-settings-card__body">
        {periods?.map((p, i) => (
          <Period
            key={p.id}
            period={p}
            index={i}
            // durations={durations}
            // setDurations={setDurations}
            setPeriods={setPeriods}
            fixtureId={fixtureId}
            canDelete={
              stats?.find((x) => x.fixturePeriod?.id === p.id) ? false : true
            }
          />
        ))}
        {adding ? (
          <CreateOrEditPeriod
            dismiss={() => {
              setAdding(false);
            }}
            order={periods.length}
            fixtureId={fixtureId}
            setPeriods={setPeriods}
          />
        ) : (
          <div
            className="period-settings-card__add-period"
            onClick={() => {
              setAdding(true);
            }}
          >
            + Add a period
          </div>
        )}
      </div>
    </Card>
  );
}

export default function Settings({
  fixtureId,
  setEditSettings,
  statsCacheState,
  defaultPeriods,
}) {
  const [, setAppState] = useAppState();
  const history = useHistory();

  return (
    <div className="football-settings">
      <div className="football-settings-header">
        <div
          className="close-scoring-btn"
          onClick={() => {
            history.push("/app/fixture/" + fixtureId);
            setAppState({ modal: false });
          }}
        >
          <div className="icon">
            <ChevronLeft style={{ stroke: "var(--light-1", height: "20px" }} />
          </div>
          <div className="text">Back to fixture</div>
        </div>
        <div className="settings-title">Match Settings</div>

        {defaultPeriods?.length && (
          <div
            className="dismiss"
            onClick={() => {
              setEditSettings(false);
            }}
          >
            Proceed to Collect Stats
          </div>
        )}

        {/* {statsCacheState?.settings && (
          <div
            className="dismiss"
            onClick={() => {
              setEditSettings(false);
            }}
          >
            Done
          </div>
        )} */}
      </div>

      <div className="settings-form-new">
        <div className="settings-form-new__body">
          <div className="period-settings-new">
            <PeriodSettings
              defaultPeriods={defaultPeriods}
              fixtureId={fixtureId}
            />
          </div>
          {/* <div className="lineup-settings-new">
                <LineupSettings id={fixtureId} />
            </div> */}
        </div>

        {/* <Button
          className="primary green medium"
          onClick={async () => {
            updateFixture({
              variables: {
                where: { id: fixtureId },
                data: {
                  statsCacheState: {
                    ...statsCacheState,
                    settings: true,
                  },
                },
              },
              update: (cache, { data: { updateFixture } }) => {
                const q = cache.readQuery({
                  query: FIXTURE_SETTINGS,
                  variables: { id: fixtureId },
                });

                cache.writeQuery({
                  query: FIXTURE_SETTINGS,
                  variables: { id: fixtureId },
                  data: {
                    fixture: {
                      ...q.fixture,
                      statsCacheState: updateFixture.statsCacheState,
                      periods: [...updateFixture.periods],
                    },
                  },
                });
                setEditSettings(false);
              },
            });
          }}
        >
          {updateFixtureMutation.loading ? (
            <Spinner />
          ) : (
            "Save Settings & Continue"
          )}
        </Button> */}
      </div>
    </div>
  );
}
