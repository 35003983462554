import { useState } from "react";
import makePlaceholderUrl from "../../utils/makePlaceholderUrl";
import makeFileUrl from "../../utils/makeFileUrl";
import "./Avatar.css";

function Avatar({ className = "", style = {}, media }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div
      className={"app-avatar " + (loaded ? "loaded " : "") + className}
      style={{
        ...style,
        ...(loaded
          ? {}
          : { backgroundImage: `url("${makePlaceholderUrl(media)}")` }),
      }}
    >
      <img
        src={makeFileUrl(media)}
        loading="lazy"
        onLoad={() => {
          setLoaded(true);
        }}
        alt=""
      />
    </div>
  );
}

export default Avatar;
