import "./FixtureDetails.css";
import { useQuery } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixture";
import MapContainer from "../../../components/Map";
import { ReactComponent as ClockIcon } from "../../../icons/clock.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import moment from "moment";
import { useAppState } from "../../../utils/appState";
import ModalForm from "../../ModalForm";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

export default function FixtureDetails({ id, canManageFixture }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(FIXTURE, { variables: { id } });
  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "FixtureDetails",
        query: "FIXTURE",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="fixture-summary-mobile__details">
        <div className="fixture-summary-mobile__details__title">
          <Placeholder style={{ height: "10px", width: "80px" }} pulse={true} />
        </div>
        <div className="fixture-summary-mobile__details__location">
          <div className="fixture-summary-mobile__details__location__map">
            <Placeholder
              style={{ height: "100%", width: "100%" }}
              pulse={true}
            />
          </div>
          <div className="fixture-summary-mobile__details__location__text">
            <div className="fixture-summary-mobile__details__location__text__primary">
              <Placeholder
                style={{ height: "10px", width: "100px" }}
                pulse={true}
              />
            </div>

            <div className="fixture-summary-mobile__details__location__text__secondary">
              <Placeholder
                style={{ height: "10px", width: "100%" }}
                pulse={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const fixture = data?.fixture;

  const onEditDateClick = (flag) => {
    const date =
      flag === "start" ? fixture.scheduledStart : fixture.scheduledEnd;
    setAppState({
      modal: (
        <ModalForm
          title={"Edit fixture"}
          config={{
            fields: [
              {
                label: flag === "start" ? "Starts At" : "Ends At",
                type: "datetime",
                key: flag === "start" ? "scheduledStart" : "scheduledEnd",
                formValue: date ? moment(date).format("YYYY‑MM‑DDTHH:mm") : "",
              },
            ],
            mutation: UPDATE_FIXTURE,
            onSubmit: ({ fields, mutate }) => {
              // console.log("Submitted:", fields);
              const val = fields[0].formValue;
              const vars = {};

              if (flag === "start") {
                vars.scheduledStart = val ? moment(val).toISOString() : null;
              } else {
                vars.scheduledEnd = val ? moment(val).toISOString() : null;
              }

              mutate({
                variables: {
                  where: { id: fixture.id },
                  data: vars,
                },
                // update: () => {
                //   setAppState({ modal: false });
                // },
              });
            },
          }}
        />
      ),
    });
  };

  return (
    <div className="fixture-summary-mobile__details">
      <div className="fixture-summary-mobile__details__title">
        Match Details
      </div>
      <div className="fixture-summary-mobile__details__location">
        <div className="fixture-summary-mobile__details__location__map">
          <MapContainer
            venue={{
              label: fixture.venueLabel || fixture.event.venueLabel,
              value: fixture.venueValue || fixture.event.venueValue,
              lat: fixture.venueLat || fixture.event.venueLat,
              lng: fixture.venueLng || fixture.event.venueLng,
            }}
          />
        </div>
        <div className="fixture-summary-mobile__details__location__text">
          <div className="fixture-summary-mobile__details__location__text__primary">
            {fixture.venueLabel || fixture.event.venueLabel || "TBD"}
          </div>

          <div className="fixture-summary-mobile__details__location__text__secondary">
            {fixture.venueValue || fixture.event.venueValue || ""}
          </div>

          <div className="fixture-summary-mobile__details__location__text__datetime__wrapper">
            <div className="fixture-summary-mobile__details__location__text__datetime__label">
              Starts At
            </div>
            <div className="fixture-summary-mobile__details__location__text__datetime">
              <div className="fixture-summary-mobile__details__location__text__datetime__icon">
                <ClockIcon
                  style={{ stroke: "var(--dark-4)", height: "14px" }}
                />
              </div>
              <div className="fixture-summary-mobile__details__location__text__datetime__value">
                {fixture.scheduledStart
                  ? moment(fixture.scheduledStart).format("Do MMMM YYYY HH:mm")
                  : "TBD"}
              </div>

              {canManageFixture && (
                <div
                  className="profile-overview-mobile__about__groups__group__edit-btn"
                  onClick={() => {
                    onEditDateClick("start");
                  }}
                  style={{ marginLeft: "auto" }}
                >
                  <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
                </div>
              )}
            </div>
          </div>
          {canManageFixture && (
            <div className="fixture-summary-mobile__details__location__text__datetime__wrapper">
              <div className="fixture-summary-mobile__details__location__text__datetime__label">
                Ends At
              </div>
              <div className="fixture-summary-mobile__details__location__text__datetime">
                <div className="fixture-summary-mobile__details__location__text__datetime__icon">
                  <ClockIcon
                    style={{ stroke: "var(--dark-4)", height: "14px" }}
                  />
                </div>
                <div className="fixture-summary-mobile__details__location__text__datetime__value">
                  {fixture.scheduledEnd
                    ? moment(fixture.scheduledEnd).format("Do MMMM YYYY HH:mm")
                    : "TBD"}
                </div>

                <div
                  className="profile-overview-mobile__about__groups__group__edit-btn"
                  onClick={() => {
                    onEditDateClick("end");
                  }}
                  style={{ marginLeft: "auto" }}
                >
                  <PencilIcon className="profile-overview-mobile__about__groups__group__edit-btn__icon" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
