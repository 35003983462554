import { gql } from "@apollo/client";

const EVENT_ROLES = gql`
  query eventRolesQuery($id: ID!) {
    event(id: $id) {
      id
      organizedBy {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
      roles {
        id
        description
        type {
          id
          name
        }
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        relationshipsTo {
          id
          from {
            id
            organization {
              id
              name
            }
          }
        }
        confirmed
      }
      participants {
        id
        type {
          id
          name
        }
        profile {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
        confirmed
      }
    }
  }
`;

export default EVENT_ROLES;
