// V1
import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import Spinner from "../../Spinner";
import Button from "../../Button";
import Loader from "../../Loader";
import "./PostsList.css";
import Post from "../../Post";
import { POSTS } from "../../../api/post";
import captureException from "../../../utils/captureException";
import MatchReport from "../../MatchReport";

function PostsList() {
  const { error, data, fetchMore, variables, networkStatus } = useQuery(POSTS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "PostsList",
        query: "POSTS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div
        style={{
          height: 160,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const posts = data.posts;

  return (
    <Fragment>
      {posts.map((post) => (
        <Fragment key={post.id}>
          {post.postType === "REPORT" ? (
            <div key={post.id} className="card-section">
              <MatchReport post={post} />
            </div>
          ) : (
            <div key={post.id} className="card-section">
              <Post post={post} />
            </div>
          )}
        </Fragment>
      ))}
      <div className="show-more-container">
        <Button
          className="primary small blue show-more-button"
          onClick={() => {
            fetchMore({
              variables: {
                ...variables,
                cursor: posts[posts.length - 1].id,
              },
            });
          }}
        >
          {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
        </Button>
      </div>
    </Fragment>
  );
}

export default PostsList;
