// import { setContext } from "apollo-link-context";
// import { BatchHttpLink } from "apollo-link-batch-http";
import { setContext } from "@apollo/client/link/context";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { ApolloClient, InMemoryCache } from "@apollo/client";

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem("sp_auth_token") || null;
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token,
//     },
//   };
// });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("sp_auth_token") || null;
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const link = authLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          organizations: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Merge Function:", args);
              // console.log("Incoming length:", incoming.length);
              // console.log("Existing length:", existing.length);
              // console.log(incoming);
              if (args.cursor) {
                return [...existing, ...incoming];
              } else {
                return [...incoming];
              }
            },
          },
          events: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Merge Function:", args);
              // console.log("Incoming length:", incoming.length);
              // console.log("Existing length:", existing.length);
              // console.log(incoming);
              if (args.cursor) {
                return [...existing, ...incoming];
              } else {
                return [...incoming];
              }
            },
          },
          posts: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = []) => {
              return [...existing, ...incoming];
            },
          },
          pages: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = []) => {
              const index = {};
              const output = [...existing, ...incoming];
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          followers: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = []) => {
              const index = {};
              const output = [...existing, ...incoming];
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          following: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = []) => {
              const index = {};
              const output = [...existing, ...incoming];
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          search: {
            keyArgs: ["term"],
            merge: (existing = {}, incoming = {}, { args }) => {
              // console.log("Merge Function:", args);
              // console.log("Incoming :", incoming);
              // console.log("Existing :", existing);
              // console.log(incoming);

              const existingProfiles = existing.profiles || [];
              const incomingProfiles = incoming.profiles || [];

              const existingOrganizations = existing.organizations || [];
              const incomingOrganizations = incoming.organizations || [];

              const existingEvents = existing.events || [];
              const incomingEvents = incoming.events || [];

              return {
                profiles: [...existingProfiles, ...incomingProfiles],
                organizations: [
                  ...existingOrganizations,
                  ...incomingOrganizations,
                ],
                events: [...existingEvents, ...incomingEvents],
              };

              // if (args.cursor) {
              //   return {
              //     profiles: [...existingProfiles, ...incomingProfiles],
              //     organizations: [
              //       ...existingOrganizations,
              //       ...incomingOrganizations,
              //     ],
              //     events: [...existingEvents, ...incomingEvents],
              //   };
              // } else {
              //   return {
              //     profiles: [...incomingProfiles],
              //     organizations: [...incomingOrganizations],
              //     events: [...incomingEvents],
              //   };
              // }
            },
          },
          esSearch: {
            keyArgs: ["term", "types"],
            merge: (existing = {}, incoming = {}, { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              if (!existing?.hits?.length) {
                return incoming;
              } else {
                return {
                  ...incoming,
                  hits: [...existing.hits, ...incoming.hits],
                };
              }
            },
          },
        },
      },
      Organization: {
        fields: {
          media: {
            keyArgs: ["id", "type", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          mediaSets: {
            keyArgs: ["id", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          events: {
            keyArgs: [],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          posts: {
            keyArgs: [],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
        },
      },
      Profile: {
        fields: {
          media: {
            keyArgs: ["id", "type", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          mediaSets: {
            keyArgs: ["id", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          events: {
            keyArgs: ["id", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          posts: {
            keyArgs: [],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
        },
      },

      Event: {
        fields: {
          media: {
            keyArgs: ["id", "type", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
          mediaSets: {
            keyArgs: ["id", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
        },
      },

      CricketLiveScoringStats: {
        fields: {
          bowlingSummaries: {
            merge: (existing = [], incoming = [], { args }) => {
              console.log(incoming);
              return incoming;
            },
          },
          battingSummaries: {
            merge: (existing = [], incoming = [], { args }) => {
              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
        },
      },

      Account: {
        fields: {
          notifications: {
            keyArgs: ["id", "take", "filters"],
            merge: (existing = [], incoming = [], { args }) => {
              // console.log("Existing:", existing);
              // console.log("Incoming:", incoming);

              const index = {};
              const output = [...(existing || []), ...(incoming || [])];
              // console.log(output);
              return output.filter((o) => {
                if (!index[o.__ref]) {
                  index[o.__ref] = true;
                  return true;
                } else {
                  return false;
                }
              });
            },
          },
        },
      },

      // Fixture: {
      //   fields: {
      //     stats: {
      //       keyArgs: [],
      //       merge: (existing = [], incoming = [], { args }) => {
      //         console.log("Existing:", existing);
      //         console.log("Incoming:", incoming);
      //         console.log("ARgs:", args);

      //         const index = {};
      //         const output = [...(existing || []), ...(incoming || [])];
      //         // console.log(output);
      //         return output.filter((o) => {
      //           if (!index[o.__ref]) {
      //             index[o.__ref] = true;
      //             return true;
      //           } else {
      //             return false;
      //           }
      //         });
      //       },
      //     },
      //   },
      // },
    },
  }),
});
