// V1
import { useState, useEffect } from "react";
import "./Post.css";
import Card from "../Card";
import Button from "../Button";
import YouTubeVideo from "../YouTubeVideo";
import { Link } from "react-router-dom";
import { ReactComponent as ClapIcon } from "../../icons/clap.svg";
import { ReactComponent as BookmarkIcon } from "../../icons/bookmark.svg";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import ReactQuill from "react-quill";
import makeFileUrl from "../../utils/makeFileUrl";
import PostMediaBrowser from "../PostMediaBrowser";
import { default as MobilePostMediaBrowser } from "../../mobileComponents/PostMediaBrowser";
import { useAppState } from "../../utils/appState";
import moment from "moment";
import NewDropdown from "../NewDropdown/NewDropdown";
import { DELETE_POST } from "../../api/post";
import { CLAP, UNCLAP, SAVE_POST, UNSAVE_POST } from "../../api/user";
import { useMutation } from "@apollo/client";
import EditPost from "../EditPost";
import Avatar from "../Avatar/Avatar";
import captureException from "../../utils/captureException";
import { isMobile } from "react-device-detect";
import CreateSupportTicket from "../CreateSupportTicket";

function DeletePost({ id, dismiss, queryInfo }) {
  const [deletePost, { loading }] = useMutation(DELETE_POST, {
    update: (cache, { data: { deletePost } }) => {
      if (!queryInfo) {
        return;
      }

      const q = cache.readQuery({
        query: queryInfo.query,
        variables: queryInfo.variables,
      });

      if (q) {
        cache.writeQuery({
          query: queryInfo.query,
          variables: queryInfo.variables,
          data: queryInfo.onDeleteData(q, deletePost),
        });
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post.DeletePost",
          mutation: "DELETE_POST",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="delete-app-post">
      <div className="delete-app-post__text">
        Are you sure you want to delete this post?
      </div>
      <div className="delete-app-post__options">
        <Button className="secondary medium" onClick={dismiss}>
          No, cancel
        </Button>
        <Button
          className="primary red medium"
          onClick={() => {
            if (!loading) {
              deletePost({ variables: { id } });
            }
          }}
        >
          Yes, delete this post
        </Button>
      </div>
    </div>
  );
}

function PostMedia({ media }) {
  if (media.type === "IMAGE") {
    return <img src={makeFileUrl(media)} alt="" />;
  } else if (media.type === "VIDEO") {
    if (media.isExternal) {
      return <YouTubeVideo filename={media.filename} />;
    } else {
      return (
        <video controls controlsList="nodownload">
          <source src={makeFileUrl(media)} type="video/mp4" />
        </video>
      );
    }
  } else {
    return <div></div>;
  }
}

function getPostMediaGridValues(length, index) {
  if (length === 1) {
    return {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 1,
      gridRowEnd: 3,
    };
  } else if (length === 2) {
    return {
      gridColumnStart: index + 1,
      gridColumnEnd: index + 2,
      gridRowStart: 1,
      gridRowEnd: 3,
    };
  } else if (length === 3) {
    if (!index) {
      return {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 3,
      };
    } else {
      return {
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: index,
        gridRowEnd: index + 1,
      };
    }
  } else {
    return {};
  }
}

const sourceUrlRoute = (sourceType) => {
  if (sourceType === "organization") {
    return "page";
  } else {
    return sourceType;
  }
};

const sourceUrlId = (sourceType, source) => {
  if (sourceType === "profile") {
    return source.profile.id;
  } else if (sourceType === "organization") {
    return source.organization.id;
  } else if (sourceType === "event") {
    return source.event?.id;
  } else if (sourceType === "fixture") {
    return source.fixture?.id;
  }
};

function Post({ post, queryInfo, options = [], hideHeader }) {
  const [, setAppState] = useAppState();
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const media = post.tags.filter((x) => !!x.media);
  // const mediaSets = post.tags.filter((x) => !!x.mediaSet);
  const [clapped, setClapped] = useState(post.isClapped);
  const [clapCount, setClapCount] = useState(post.clapCount);
  const [saved, setSaved] = useState(post.isSaved);
  const [truncated, setTruncated] = useState(
    (post.contentLength || 0) > 200 ? true : false
  );
  const source =
    post.source.profile ||
    post.source.organization ||
    post.source.event ||
    post.source.fixture?.event.organizedBy;

  const sourceType = post.sourceType;
  const MediaBrowserComponent = isMobile
    ? MobilePostMediaBrowser
    : PostMediaBrowser;

  const [clap, { loading: clapping }] = useMutation(CLAP, {
    update: (cache, { data }) => {
      if (data.clap?.id) {
        setClapped(true);
        setClapCount((prev) => prev + 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "CLAP",
        },
      });
      console.log(error);
    },
  });
  const [unclap, { loading: unclapping }] = useMutation(UNCLAP, {
    update: (cache, { data }) => {
      if (data.unclap?.id) {
        setClapped(false);
        setClapCount((prev) => prev - 1);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "UNCLAP",
        },
      });
      console.log(error);
    },
  });
  const [save, { loading: saving }] = useMutation(SAVE_POST, {
    update: (cache, { data }) => {
      if (data.savePost?.id) {
        setSaved(true);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "SAVE_POST",
        },
      });
      console.log(error);
    },
  });
  const [unsave, { loading: unsaving }] = useMutation(UNSAVE_POST, {
    update: (cache, { data }) => {
      if (data.unsavePost?.id) {
        setSaved(false);
      }
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "Post",
          mutation: "UNSAVE_POST",
        },
      });
      console.log(error);
    },
  });

  useEffect(() => {
    const onMentionClick = (e) => {
      e.stopImmediatePropagation();
      window.open(process.env.REACT_APP_ENDPOINT + e.value.link, "_blank");
    };
    window.addEventListener("mention-clicked", onMentionClick, false);

    return () => {
      window.removeEventListener("mention-clicked", onMentionClick, false);
    };
  });

  // console.log("POST ITEM POST:", post);

  if (editing) {
    return (
      <EditPost
        post={post}
        dismiss={() => {
          setEditing(false);
        }}
        queryInfo={queryInfo}
        sourceId={source.id}
        sourceType={sourceType}
      />
    );
  }

  return (
    <Card
      className="app-post-card"
      style={isMobile ? { borderRadius: "0px" } : {}}
    >
      {deleting && (
        <div className="app-post-card__overlay">
          <DeletePost
            id={post.id}
            dismiss={() => {
              setDeleting(false);
            }}
            queryInfo={queryInfo}
          />
        </div>
      )}

      {!hideHeader && (
        <div className="head">
          <div className="avatar">
            {source?.profilePhoto && <Avatar media={source.profilePhoto} />}
          </div>
          <div className="meta">
            <Link
              to={`/app/${sourceUrlRoute(sourceType)}/${sourceUrlId(
                sourceType,
                post.source
              )}`}
              className="primary hover-underline"
            >
              {source?.name}
            </Link>
            <div className="secondary">{moment(post.createdAt).fromNow()}</div>
          </div>

          {!!options.length && post.canManagePost && (
            <NewDropdown
              target={
                <div className="feed-item-options-button">
                  <img src={"/more-vertical.svg"} alt="" />
                </div>
              }
              menu={({ setShowMenu }) => (
                <Card className="post-menu">
                  {options.includes("edit") && post.canManagePost && (
                    <div
                      className="post-menu__item"
                      onClick={() => {
                        setEditing(true);
                        setShowMenu(false);
                      }}
                    >
                      Edit post
                    </div>
                  )}
                  {options.includes("delete") && post.canManagePost && (
                    <div
                      className="post-menu__item"
                      onClick={() => {
                        setDeleting(true);
                        setShowMenu(false);
                      }}
                    >
                      Delete post
                    </div>
                  )}
                </Card>
              )}
            />
          )}
        </div>
      )}

      <div className="text">
        <ReactQuill
          id={"post-content-" + post.id}
          value={truncated ? post.truncatedContent : post.content}
          readOnly={true}
          modules={{ toolbar: false }}
        />
        {truncated ? (
          <div
            className="truncate-button"
            onClick={() => {
              setTruncated(false);
            }}
          >
            See more
          </div>
        ) : post.contentLength > 200 ? (
          <div
            className="truncate-button"
            onClick={() => {
              setTruncated(true);
            }}
          >
            See less
          </div>
        ) : (
          false
        )}
      </div>
      {!!media.length && (
        <div className="app-post-card__media">
          {[...media].slice(0, 3).map((m, i) => (
            <div
              key={m.id}
              className="app-post-card__media__item"
              style={getPostMediaGridValues(media.length, i)}
              onClick={() => {
                setAppState({
                  modal: (
                    <MediaBrowserComponent
                      items={media.map((x) => x.media)}
                      media={m.media}
                    />
                  ),
                });
              }}
            >
              <PostMedia media={m.media} />
              <div className="app-post-card__media__item__overlay" />
            </div>
          ))}

          {media.length === 4 && (
            <div
              className="app-post-card__media__item"
              onClick={() => {
                setAppState({
                  modal: (
                    <MediaBrowserComponent
                      items={media.map((x) => x.media)}
                      media={media[3].media}
                    />
                  ),
                });
              }}
            >
              <PostMedia media={media[3].media} />
              <div className="app-post-card__media__item__overlay" />
            </div>
          )}

          {media.length > 4 && (
            <div
              className="app-post-card__media__item"
              onClick={() => {
                setAppState({
                  modal: (
                    <MediaBrowserComponent
                      items={media.map((x) => x.media)}
                      media={media[3].media}
                    />
                  ),
                });
              }}
            >
              <PostMedia media={media[3].media} />
              <div className="app-post-card__media__item__overlay">
                + {media.length - 3}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="foot">
        <div className="options-left">
          <div
            className={"option " + (clapping || unclapping ? "disabled" : "")}
            onClick={() => {
              if (clapping || unclapping) {
                return;
              }

              if (clapped) {
                unclap({ variables: { postId: post.id } });
              } else {
                clap({ variables: { postId: post.id } });
              }
            }}
          >
            <div className="icon">
              <ClapIcon
                style={{
                  fill: clapped ? "var(--blue-main)" : "var(--dark-4)",
                  height: "20px",
                }}
              />
            </div>
            <div className="value">{clapCount || ""}</div>
          </div>
        </div>
        <div className="options-right">
          <div
            className={"option " + (saving || unsaving ? "disabled" : "")}
            onClick={() => {
              if (saving || unsaving) {
                return;
              }

              if (saved) {
                unsave({ variables: { id: post.id } });
              } else {
                save({ variables: { id: post.id } });
              }
            }}
          >
            <div className="icon">
              <BookmarkIcon
                style={{
                  height: "18px",
                  stroke: saved ? "var(--blue-darker)" : "var(--dark-4)",
                  ...(saved ? { fill: "var(--blue-main)" } : {}),
                }}
              />
            </div>
          </div>

          <NewDropdown
            target={
              <div className={"option "}>
                <div className="icon">
                  <EllipsisIcon
                    style={{
                      height: "18px",
                      fill: "var(--dark-4)",
                      transform: "rotate(90deg)",
                    }}
                  />
                </div>
              </div>
            }
            menu={
              <Card className="app-post-card__options-menu">
                <div
                  className="app-post-card__options-menu__item"
                  onClick={() => {
                    setAppState({
                      modal: (
                        <CreateSupportTicket
                          title={"Report Post"}
                          type={"FLAGGED_POST"}
                          fields={{
                            email: false,
                            phone: false,
                            subject: false,
                          }}
                          messageLabel={"Describe your issue (optional)"}
                          messageRequired={false}
                          getVars={({ email, phone, subject, description }) => {
                            const v = {
                              type: "FLAGGED_POST",
                              description: description || undefined,
                              post: {
                                connect: {
                                  id: post.id,
                                },
                              },
                            };

                            return v;
                          }}
                          successMessage={{
                            title: "Thank you for your feedback.",
                            message:
                              "Our team will review this post and take any necessary action.",
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Report this post
                </div>
              </Card>
            }
          />
        </div>
      </div>
    </Card>
  );
}

export default Post;
