import "./CurrentSet.css";
import { useBadmintonScoringState } from "../utils/useBadmintonScoringState";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";
import Avatar from "../../../components/Avatar";
import Loader from "../../../components/Loader";
import { useMutation, useQuery, gql } from "@apollo/client";
import { FIXTURE, FIXTURE_STATS } from "../../../api/functions";
import getScore from "../utils/getScore";
import { CREATE_STAT, DELETE_FIXTURE_STAT } from "../../../api/fixture";
import Spinner from "../../../components/Spinner";
import { client } from "../../../utils/client";

export default function CurrentSet({
  periodScores = [],
  periods,
  homeParticipant,
  awayParticipant,
}) {
  const [
    {
      fixtureId,
      eventId,
      selectedPeriod,
      currentServer,
      cache,
      participantType,
      statTypes,
      updateCache,
    },
    setBadmintonScoringState,
  ] = useBadmintonScoringState();

  const [createStat, { loading: submitting }] = useMutation(CREATE_STAT, {
    update: (cache, { data: { createStat } }) => {
      // const q = cache.readQuery({
      //   query: BADMINTON_STATS,
      //   variables: { id: fixtureId },
      // });
      // // console.log("Update:", createStat);
      // const newStats = [
      //   createStat,
      //   ...createStat.relationshipsTo.map((r) => ({
      //     ...r.from,
      //     relationshipsFrom: [],
      //     relationshipsTo: [],
      //   })),
      //   ...createStat.relationshipsFrom.map((r) => ({
      //     ...r.to,
      //     relationshipsFrom: [],
      //     relationshipsTo: [],
      //   })),
      // ];
      // cache.writeQuery({
      //   query: BADMINTON_STATS,
      //   variables: { id: fixtureId },
      //   data: {
      //     fixture: {
      //       ...q.fixture,
      //       stats: [...newStats, ...q.fixture.stats],
      //     },
      //   },
      // });
      // if (createStat.profile.id !== currentServer.profile.id) {
      //   if (createStat.profile.id === homePlayer.profile.id) {
      //     setCurrentServer(homePlayer);
      //     updateCache(homePlayer);
      //   } else {
      //     setCurrentServer(awayPlayer);
      //     updateCache(awayPlayer);
      //   }
      // }
      // dismiss();
    },
  });

  const [deleteStat, { loading: deleting }] = useMutation(DELETE_FIXTURE_STAT, {
    update: (cache, { data: { deleteFixtureStat } }) => {
      // if (deleteFixtureStat?.length) {
      //   const q = cache.readQuery({
      //     query: BADMINTON_STATS,
      //     variables: {
      //       id: fixtureId,
      //     },
      //   });
      //   const statIds = deleteFixtureStat.map((x) => x.id);
      //   cache.writeQuery({
      //     query: BADMINTON_STATS,
      //     variables: {
      //       id: fixtureId,
      //     },
      //     data: {
      //       fixture: {
      //         ...q.fixture,
      //         stats: [
      //           ...q.fixture.stats.filter((x) => !statIds.includes(x.id)),
      //         ],
      //       },
      //     },
      //   });
      //   // dismiss();
      // }
    },
  });

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      metas {
        id
        label
        shortLabel
        value
        stringValue
      }
      cricketLiveScoring{
        id
        periodScores{
          id
          runs
          wickets
          overs
        }        
      }
  `,
    }),
    {
      variables: {
        id: fixtureId,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  //   if (loading || !data) {
  //     return <Loader theme="small" />;
  //   }
  const homeScore = getScore(selectedPeriod, homeParticipant, periodScores);
  const awayScore = getScore(selectedPeriod, awayParticipant, periodScores);

  const incrementScore = ({ participant }) => {
    if (!currentServer?.id || submitting) return;
    let timeStamp = 0;
    const q = client.readQuery({
      query: FIXTURE_STATS,
      variables: {
        id: fixtureId,
        where: {
          fixturePeriod: {
            id: { in: [selectedPeriod?.id] },
          },
        },
      },
    });

    const stats = q.fixture?.stats;

    if (!stats?.length) {
      timeStamp = 0;
    } else {
      const sortedStats = stats
        .filter((s) => s.statType.name === "Point Won")
        .sort((a, b) => b.timeStamp - a.timeStamp);

      timeStamp = sortedStats[0].timeStamp + 1;
    }

    createStat({
      variables: {
        data: {
          event: { connect: { id: eventId } },
          fixture: { connect: { id: fixtureId } },
          fixturePeriod: { connect: { id: selectedPeriod.id } },
          timeStamp,
          ...(participantType === "INDIVIDUAL"
            ? { profile: { connect: { id: participant.id } } }
            : { organization: { connect: { id: participant.id } } }),
          statType: {
            connect: { id: statTypes.find((st) => st.name === "Point Won").id },
          },
          sport: { connect: { name: "Badminton" } },
          relationshipsFrom: {
            create: {
              to: {
                create: {
                  event: { connect: { id: eventId } },
                  ...(participantType === "INDIVIDUAL"
                    ? { profile: { connect: { id: currentServer.id } } }
                    : { organization: { connect: { id: currentServer.id } } }),
                  statType: {
                    connect: {
                      id: statTypes.find((st) => st.name === "Service").id,
                    },
                  },
                  fixture: { connect: { id: fixtureId } },
                  sport: { connect: { name: "Badminton" } },
                  fixturePeriod: { connect: { id: selectedPeriod.id } },
                },
              },
            },
          },
        },
      },
      update: (cache, { data: { createStat } }) => {
        // Update the stats list
        const q = cache.readQuery({
          query: FIXTURE_STATS,
          variables: {
            id: fixtureId,
            where: {
              fixturePeriod: {
                id: { in: [selectedPeriod?.id] },
              },
            },
          },
        });

        const newStats = [
          createStat,
          ...createStat.relationshipsTo.map((r) => ({
            ...r.from,
            relationshipsFrom: [],
            relationshipsTo: [],
          })),
          ...createStat.relationshipsFrom.map((r) => ({
            ...r.to,
            relationshipsFrom: [],
            relationshipsTo: [],
          })),
        ];

        cache.writeQuery({
          query: FIXTURE_STATS,
          variables: {
            id: fixtureId,
            where: {
              fixturePeriod: {
                id: { in: [selectedPeriod?.id] },
              },
            },
          },
          data: {
            fixture: {
              ...q.fixture,
              stats: [...newStats, ...q.fixture.stats],
            },
          },
        });

        // Update the current set score && box score
        cache.modify({
          id: "BadmintonPeriodScoreItem:" + selectedPeriod.id + participant.id,
          fields: {
            value(curr) {
              return curr + 1;
            },
          },
        });

        // Update the server
        // const addedStatParticipantId =
        //   createStat?.profile?.id || createStat?.organization?.id;
        if (
          participant.id !==
          (currentServer?.profile?.id || currentServer?.organization?.id)
        ) {
          if (
            participant.id ===
            (homeParticipant?.profile?.id || homeParticipant?.organization?.id)
          ) {
            setBadmintonScoringState({
              currentServer:
                homeParticipant.profile || homeParticipant.organization,
            });
            // updateCache(homePlayer);
            updateCache({
              variables: {
                where: { id: fixtureId },
                data: {
                  statsCacheState: {
                    selectedPeriodId: selectedPeriod.id,
                    currentServer:
                      homeParticipant.profile || homeParticipant.organization,
                  },
                },
              },
              update: (c, { data: { updateFixture } }) => {
                setBadmintonScoringState({
                  cache: updateFixture.statsCacheState,
                });
              },
            });
          } else {
            setBadmintonScoringState({
              currentServer:
                awayParticipant.profile || awayParticipant.organization,
            });
            // updateCache(awayPlayer);
            updateCache({
              variables: {
                where: { id: fixtureId },
                data: {
                  statsCacheState: {
                    selectedPeriodId: selectedPeriod.id,
                    currentServer:
                      awayParticipant.profile || awayParticipant.organization,
                  },
                },
              },
              update: (c, { data: { updateFixture } }) => {
                setBadmintonScoringState({
                  cache: updateFixture.statsCacheState,
                });
              },
            });
          }
        }
      },
    });
  };

  const decrementScore = ({ participant }) => {
    if (deleting) return;
    const q = client.readQuery({
      query: FIXTURE_STATS,
      variables: {
        id: fixtureId,
        where: {
          fixturePeriod: {
            id: { in: [selectedPeriod?.id] },
          },
        },
      },
    });

    const stats = q.fixture?.stats;

    if (!stats?.length) {
      return;
    } else {
      const participantStats = stats.filter((s) => {
        if (participantType === "INDIVIDUAL") {
          return (
            s.profile?.id === participant.id && s.statType.name === "Point Won"
          );
        } else {
          return (
            s.organization?.id === participant.id &&
            s.statType.name === "Point Won"
          );
        }
      });
      const sortedStats = [...participantStats].sort(
        (a, b) => b.timeStamp - a.timeStamp
      );

      console.log("Last point:", sortedStats[0]);
      deleteStat({
        variables: { id: sortedStats[0].id },
        update: (cache, { data: { deleteFixtureStat } }) => {
          if (deleteFixtureStat?.length) {
            const q = cache.readQuery({
              query: FIXTURE_STATS,
              variables: {
                id: fixtureId,
                where: {
                  fixturePeriod: {
                    id: { in: [selectedPeriod?.id] },
                  },
                },
              },
            });

            const statIds = deleteFixtureStat.map((x) => x.id);

            cache.writeQuery({
              query: FIXTURE_STATS,
              variables: {
                id: fixtureId,
                where: {
                  fixturePeriod: {
                    id: { in: [selectedPeriod?.id] },
                  },
                },
              },
              data: {
                fixture: {
                  ...q.fixture,
                  stats: [
                    ...q.fixture.stats.filter((x) => !statIds.includes(x.id)),
                  ],
                },
              },
            });

            cache.modify({
              id:
                "BadmintonPeriodScoreItem:" +
                selectedPeriod.id +
                participant.id,
              fields: {
                value(curr) {
                  return curr - 1;
                },
              },
            });

            // dismiss();
          }
        },
      });
    }
  };

  // console.log("SEREVER:.", currentServer);

  return (
    <div
      className="ScoringApp-cricket__scoring__current-innings"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="ScoringApp-cricket__scoring__current-innings__header">
        <NewDropdown
          target={
            <div className="ScoringApp-cricket__scoring__current-innings__header__selected-period">
              {selectedPeriod?.label || "Select a period to get started"}{" "}
              <ChevronDown className="ScoringApp-cricket__scoring__current-innings__header__selected-period__icon" />
            </div>
            // <div className="selected-period-selector">
            //   {selectedPeriod?.label || "Select period to get started"}
            //   <ChevronDown className="selected-period-selector__icon" />
            // </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu">
              {periods.map((p) => (
                <div
                  key={p.id}
                  className="ScoringApp-cricket__scoring__current-innings__header__selected-period__menu__item"
                  onClick={() => {
                    // setRunning(false);
                    // changePeriod(p.id);
                    console.log(cache);
                    // return;

                    setBadmintonScoringState({
                      selectedPeriod: p,
                    });
                    setShowMenu(false);
                  }}
                >
                  {p.label}
                </div>
              ))}
            </Card>
          )}
        />
      </div>

      <div
        className="ScoringApp-cricket__scoring__current-innings__body"
        style={{ flexGrow: 1 }}
      >
        {selectedPeriod && (
          <div className="ScoringApp-badminton__scoring__current-set__body">
            <div className="ScoringApp-badminton__scoring__current-set__body__section home">
              <div className="ScoringApp-badminton__scoring__current-set__body__section__header">
                <div className="ScoringApp-badminton__scoring__current-set__body__section__header__img">
                  {(homeParticipant?.organization?.profilePhoto ||
                    homeParticipant?.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        homeParticipant?.organization?.profilePhoto ||
                        homeParticipant?.profile?.profilePhoto
                      }
                    />
                  )}
                </div>

                <div className="ScoringApp-badminton__scoring__current-set__body__section__header__desc">
                  <div className="ScoringApp-badminton__scoring__current-set__body__section__header__desc__name">
                    {homeParticipant?.organization?.name ||
                      homeParticipant?.profile?.name}
                  </div>

                  <div
                    onClick={() => {
                      setBadmintonScoringState({
                        currentServer:
                          homeParticipant.profile ||
                          homeParticipant.organization,
                      });
                    }}
                    className={
                      "ScoringApp-badminton__scoring__current-set__body__section__header__desc__server " +
                      (currentServer.id ===
                      (homeParticipant.profile?.id ||
                        homeParticipant?.organization?.id)
                        ? "selected"
                        : "")
                    }
                  >
                    Serve
                  </div>
                </div>
              </div>

              <div className="ScoringApp-badminton__scoring__current-set__body__section__score">
                <div
                  className="ScoringApp-badminton__scoring__current-set__body__section__score__action"
                  onClick={() => {
                    incrementScore({
                      participant:
                        homeParticipant.profile || homeParticipant.organization,
                    });
                  }}
                >
                  {submitting ? <Spinner /> : <PlusIcon className="icon" />}
                </div>
                <div className="ScoringApp-badminton__scoring__current-set__body__section__score__value">
                  {homeScore}
                </div>
                <div
                  className="ScoringApp-badminton__scoring__current-set__body__section__score__action"
                  onClick={() => {
                    decrementScore({
                      participant:
                        homeParticipant.profile || homeParticipant.organization,
                    });
                  }}
                >
                  {deleting ? <Spinner /> : <MinusIcon className="icon" />}
                </div>
              </div>
            </div>

            <div className="ScoringApp-badminton__scoring__current-set__body__section away">
              <div className="ScoringApp-badminton__scoring__current-set__body__section__header">
                <div className="ScoringApp-badminton__scoring__current-set__body__section__header__img">
                  {(awayParticipant?.organization?.profilePhoto ||
                    awayParticipant?.profile?.profilePhoto) && (
                    <Avatar
                      media={
                        awayParticipant?.organization?.profilePhoto ||
                        awayParticipant?.profile?.profilePhoto
                      }
                    />
                  )}
                </div>

                <div className="ScoringApp-badminton__scoring__current-set__body__section__header__desc">
                  <div className="ScoringApp-badminton__scoring__current-set__body__section__header__desc__name">
                    {awayParticipant?.organization?.name ||
                      awayParticipant?.profile?.name}
                  </div>

                  <div
                    onClick={() => {
                      setBadmintonScoringState({
                        currentServer:
                          awayParticipant.profile ||
                          awayParticipant.organization,
                      });
                    }}
                    className={
                      "ScoringApp-badminton__scoring__current-set__body__section__header__desc__server " +
                      (currentServer.id ===
                      (awayParticipant.profile?.id ||
                        awayParticipant?.organization?.id)
                        ? "selected"
                        : "")
                    }
                  >
                    Serve
                  </div>
                </div>
              </div>

              <div className="ScoringApp-badminton__scoring__current-set__body__section__score">
                <div
                  className="ScoringApp-badminton__scoring__current-set__body__section__score__action"
                  onClick={() => {
                    incrementScore({
                      participant:
                        awayParticipant.profile || awayParticipant.organization,
                    });
                  }}
                >
                  {submitting ? <Spinner /> : <PlusIcon className="icon" />}
                </div>
                <div className="ScoringApp-badminton__scoring__current-set__body__section__score__value">
                  {awayScore}
                </div>
                <div
                  className="ScoringApp-badminton__scoring__current-set__body__section__score__action"
                  onClick={() => {
                    decrementScore({
                      participant:
                        awayParticipant.profile || awayParticipant.organization,
                    });
                  }}
                >
                  {deleting ? <Spinner /> : <MinusIcon className="icon" />}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
