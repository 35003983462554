import "./EventStandings.css";
import Card from "../Card";
import { useQuery } from "@apollo/client";
import { EVENT_STANDINGS } from "../../api/event";
import Placeholder from "../Placeholder/Placeholder";
import Avatar from "../Avatar";
import captureException from "../../utils/captureException";
import { Link } from "react-router-dom";

function getTableConfig({ sport = {} }) {
  if (sport.name === "Football") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { width: "30%" },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        hasLink: (item) => {
          return "/app/page/" + item.organization?.id;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },

      {
        label: "GF",
        tooltip: "Goals For",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Goals For")?.value || 0;
        },
      },
      {
        label: "GA",
        tooltip: "Goals Against",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Goals Against")?.value || 0
          );
        },
      },
      {
        label: "GD",
        tooltip: "Goal Difference",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Difference")?.value || 0;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.5%", justifyContent: "flex-end", fontWeight: "700" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name?.includes("Basketball")) {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
        legend: false,
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { width: "29%" },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        hasLink: (item) => {
          return "/app/page/" + item.organization?.id;
        },
        legend: false,
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
        legend: true,
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
        legend: true,
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
        legend: true,
      },

      {
        label: "Win %",
        tooltip: "Win Percentage",
        style: { width: "8.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Win Percentage")?.value || 0
          ).toFixed(2);
        },
        legend: true,
      },
      {
        label: "PF",
        tooltip: "Points For",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points For")?.value || 0;
        },
        legend: true,
      },
      {
        label: "PA",
        tooltip: "Points Against",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas.find((m) => m.label === "Points Against")?.value || 0
          );
        },
        legend: true,
      },
      {
        label: "+/-",
        tooltip: "Point Difference",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            (item.metas.find((m) => m.label === "Points For")?.value || 0) -
            (item.metas.find((m) => m.label === "Points Against")?.value || 0)
          );
        },
        legend: true,
      },
      {
        label: "Pts",
        tooltip: "Points",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
        legend: true,
      },
    ];
  } else if (sport.name === "Cricket") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
      },
      {
        label: "Team",
        tooltip: "Team",
        style: { width: "46%" },
        value: (item) => {
          return item.organization.name;
        },
        hasImage: (item) => {
          return item.organization?.profilePhoto;
        },
        hasLink: (item) => {
          return "/app/page/" + item.organization?.id;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.2%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "NRR",
        tooltip: "Net Run Rate",
        style: { width: "8%", justifyContent: "flex-end" },
        value: (item) => {
          return (
            item.metas
              .find((m) => m.label === "Net Run Rate")
              ?.value?.toFixed(3) || "-"
          );
        },
      },
      // {
      //   label: "For",
      //   tooltip: "Run Rate For",
      //   style: { width: "8%", justifyContent: "flex-end" },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate For")?.stringValue ||
      //       "-"
      //     );
      //   },
      // },
      // {
      //   label: "Against",
      //   tooltip: "Run Rate Against ",
      //   style: { width: "8%", justifyContent: "flex-end" },
      //   value: (item) => {
      //     return (
      //       item.metas.find((m) => m.label === "Run Rate Against")
      //         ?.stringValue || "-"
      //     );
      //   },
      // },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.2%", justifyContent: "flex-end", fontWeight: "700" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else if (sport.name === "Badminton") {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { width: "67.5%" },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        placeholderWidth: "160px",
        hasLink: (item) => {
          return `/app/${item.organization ? "page" : "profile"}/${
            item.organization?.id || item.profile?.id
          }`;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "+/-",
        tooltip: "Point Difference",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          const scored =
            item.metas.find((m) => m.label === "Points For")?.value || 0;
          const conceded =
            item.metas.find((m) => m.label === "Points Against")?.value || 0;
          return scored - conceded;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  } else {
    return [
      {
        label: "Pos",
        tooltip: "Position",
        style: { width: "10%" },
        value: (item) => {
          return item.order + 1;
        },
      },
      {
        label: "Participant",
        tooltip: "Participant",
        style: { width: "67.5%" },
        value: (item) => {
          return item.profile?.name || item.organization?.name;
        },
        hasImage: (item) => {
          return item.profile?.profilePhoto || item.organization?.profilePhoto;
        },
        placeholderWidth: "160px",
        hasLink: (item) => {
          return `/app/${item.organization ? "page" : "profile"}/${
            item.organization?.id || item.profile?.id
          }`;
        },
      },
      {
        label: "GP",
        tooltip: "Games Played",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Games Played")?.value || 0;
        },
      },
      {
        label: "W",
        tooltip: "Won",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Won")?.value || 0;
        },
      },
      {
        label: "D",
        tooltip: "Drawn",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Drawn")?.value || 0;
        },
      },
      {
        label: "L",
        tooltip: "Lost",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Lost")?.value || 0;
        },
      },
      {
        label: "P",
        tooltip: "Points",
        style: { width: "7.5%", justifyContent: "flex-end" },
        value: (item) => {
          return item.metas.find((m) => m.label === "Points")?.value || 0;
        },
      },
    ];
  }
}

function EventStandings({ id }) {
  const { loading, error, data } = useQuery(EVENT_STANDINGS, {
    variables: { id, take: 1 },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventStandings",
        query: "EVENT_STANDINGS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    const dummyConfig = getTableConfig({ sport: false });
    return (
      <div className="event-standings">
        <div className="event-standings__tables">
          <Card className="standings-table">
            <div className="card-title">
              <Placeholder
                style={{ height: "12px", width: "80px" }}
                pulse={true}
              />
            </div>

            <div className="standings-list">
              <div className="table-header">
                <div className="row">
                  {dummyConfig.map((c, i) => (
                    <div
                      key={"event-standings-ph-header-" + i}
                      className="td"
                      style={c.style}
                    >
                      <Placeholder
                        style={{
                          height: "12px",
                          width: "20px",
                        }}
                        pulse={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="table-body">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                  <div key={"event-standings-ph-row-" + item} className="row">
                    {dummyConfig.map((c) => (
                      <div
                        key={"event-standings-ph-td-" + c.label + item}
                        className="td"
                        style={c.style}
                      >
                        {c.hasImage && <div className="logo"></div>}

                        <Placeholder
                          style={{
                            height: "12px",
                            width: c.placeholderWidth || "20px",
                          }}
                          pulse={true}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </div>

        {/* <Card className="standings-evolution"></Card> */}
      </div>
    );
  }

  const sport = data?.event?.sport;
  const standing = data?.event?.standings?.[0];
  const parts = data?.event?.parts;
  const tableConfig = getTableConfig({ sport });
  // console.log("Standing:", standing);

  return (
    <div className="event-standings">
      <div className="event-standings__tables">
        {standing && !standing.eventPart?.id && (
          <Card className="standings-table">
            <div className="card-title">Standings</div>

            <div className="standings-list">
              <div className="table-header">
                <div className="row">
                  {tableConfig.map((c) => (
                    <div
                      key={"event-standings-header-" + standing.id + c.label}
                      className="td"
                      style={c.style}
                    >
                      {c.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="table-body">
                {standing.items.map((item) => (
                  <div key={"event-standings-item-" + item.id} className="row">
                    {tableConfig.map((c) => (
                      <div
                        key={"event-standings-item-td-" + item.id + c.label}
                        className="td"
                        style={c.style}
                      >
                        {c.hasImage && (
                          <div className="logo">
                            {c.hasImage(item) && (
                              <Avatar media={c.hasImage(item)} />
                            )}
                          </div>
                        )}

                        {c.hasLink ? (
                          <Link to={c.hasLink(item)}>{c.value(item)}</Link>
                        ) : (
                          c.value(item)
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div className="standings-table__legend">
              {tableConfig
                .filter((x) => !!x.legend)
                .map((x) => (
                  <div className="standings-table__legend__item">
                    {x.label} - {x.tooltip}
                  </div>
                ))}
            </div>
          </Card>
        )}

        {parts?.map((p) => {
          if (p.standings?.length) {
            return (
              <Card
                key={"event-part-standings-card-" + p.id}
                className="standings-table"
              >
                <div className="card-title">{p.label} Standings</div>
                <div className="standings-list">
                  <div className="table-header">
                    <div className="row">
                      {tableConfig.map((c) => (
                        <div
                          key={
                            "event-part-standings-header-" +
                            p.standings[0].id +
                            c.label
                          }
                          className="td"
                          style={c.style}
                        >
                          {c.label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="table-body">
                    {p.standings[0].items.map((item) => (
                      <div
                        key={"event-part-standings-item-" + item.id}
                        className="row"
                      >
                        {tableConfig.map((c) => (
                          <div
                            key={"event-part-standings-td-" + item.id + c.label}
                            className="td"
                            style={c.style}
                          >
                            {c.hasImage && (
                              <div className="logo">
                                {c.hasImage(item) && (
                                  <Avatar media={c.hasImage(item)} />
                                )}
                              </div>
                            )}

                            {c.hasLink ? (
                              <Link to={c.hasLink(item)}>{c.value(item)}</Link>
                            ) : (
                              c.value(item)
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            );
          } else {
            return <div key={"empty-part-" + p.id} />;
          }
        })}

        {!standing && !parts.filter((p) => p.standings?.length).length && (
          <div className="no-data">
            <div className="text">No standings for this event yet.</div>
          </div>
        )}
      </div>

      {/* <Card className="standings-evolution"></Card> */}
    </div>
  );
}

export default EventStandings;
