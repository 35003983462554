import "./ManageEventPartners.css";
import { useState, useCallback } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { EVENT_ROLES, UPDATE_EVENT } from "../../../api/event";
import { ORGANIZATION_SEARCH, PROFILE_SEARCH, SEARCH } from "../../../api/user";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import Spinner from "../../../components/Spinner";
import { useAppState } from "../../../utils/appState";
import Input from "../../../components/Input";
import debounce from "lodash.debounce";
import Avatar from "../../../components/Avatar";
import captureException from "../../../utils/captureException";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

function SearchProfile({ setProfile }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [search, { loading, data }] = useLazyQuery(PROFILE_SEARCH);

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = data?.search?.profiles || [];

  let placeholderArray = ["profiles"];

  return (
    <NewDropdown
      wrapperClass="search"
      targetWrapperClass="search-wrapper"
      placement={"bottom-start"}
      menuStyle={{ width: "100%" }}
      target={
        <Input
          iconLeft={
            <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
          }
          placeholder={`Search for ${placeholderArray.join("/")}`}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e);
          }}
          useExternalValue={true}
          value={searchTerm}
        />
      }
      menu={({ setShowMenu }) => (
        <Card className="app-search-menu">
          {loading && <Spinner />}
          {!loading && !profiles?.length && (
            <div className="no-data">
              {!searchTerm ? (
                <div>
                  Search for {placeholderArray.join("/")} by name or handle
                </div>
              ) : (
                <div>No results found</div>
              )}
            </div>
          )}
          {!!profiles?.length && (
            <div className="search-results-section">
              <div className="search-results-section__title">PROFILES</div>
              <div className="search-results-section__items">
                {profiles?.map((o, i) => (
                  <div
                    key={"venue-search-item-" + o.id + i}
                    onClick={() => {
                      // if (submitting) return;
                      setShowMenu(false);
                      setSearchTerm("");
                      setProfile(o);
                      // updateEvent({
                      //   variables: {
                      //     where: {
                      //       id: ev.id,
                      //     },
                      //     data: {
                      //       roles: {
                      //         create: {
                      //           type: { connect: { name: roleType } },
                      //           profile: { connect: { id: o.id } },
                      //           relationshipsTo: {
                      //             create: {
                      //               from: {
                      //                 connect: { id: roleId },
                      //               },
                      //             },
                      //           },
                      //         },
                      //       },
                      //     },
                      //   },
                      // });
                      // setSelectedProfiles((prev) => [...prev, p]);
                    }}
                    className="search-results-section__items__item"
                  >
                    <div className="search-results-section__items__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>
                    <div className="search-results-section__items__item__desc">
                      <div className="search-results-section__items__item__desc__primary">
                        {o.name}
                      </div>
                      <div className="search-results-section__items__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Card>
      )}
    />
  );
}

function AddRoleWithDescription({ ev, label, placeholder, roleType }) {
  const [, setAppState] = useAppState();
  const [profile, setProfile] = useState("");
  const [description, setDescription] = useState("");

  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
      roles {
        id
        description
        type {
          id
          name
        }        
        profile {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
        organization {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
      }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        cache.modify({
          id: cache.identify(ev),
          fields: {
            roles() {
              return updateEvent.roles;
            },
          },
        });

        setAppState({ modal: false });

        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventRoles.EditPhotographerRoles",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="modal-form-mobile-wrapper">
      <Card className="add-fixture-referee">
        <div className="add-fixture-referee__title">
          <div className="add-fixture-referee__title__text">{label}</div>

          <div
            className="add-fixture-referee__title__dismiss-btn"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon className="add-fixture-referee__title__dismiss-btn__icon" />
          </div>
        </div>

        <div className="add-fixture-referee__form">
          <div className="add-fixture-referee__form__group">
            <div className="add-fixture-referee__form__group__label">
              Select profile
            </div>

            <div className="add-fixture-referee__form__group__input">
              {profile ? (
                <div className="add-fixture-referee__form__group__input__selected-profile">
                  <div className="add-fixture-referee__form__group__input__selected-profile__desc">
                    {profile.name}
                  </div>

                  <div
                    className="add-fixture-referee__form__group__input__selected-profile__dismiss"
                    onClick={() => {
                      setProfile(false);
                    }}
                  >
                    <TimesIcon className="add-fixture-referee__form__group__input__selected-profile__dismiss__icon" />
                  </div>
                </div>
              ) : (
                <SearchProfile setProfile={setProfile} />
              )}
            </div>
          </div>

          <div className="add-fixture-referee__form__group">
            <div className="add-fixture-referee__form__group__label">
              Description
            </div>

            <div className="add-fixture-referee__form__group__input">
              <Input
                placeholder={placeholder}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                useExternalValue={true}
                value={description}
              />
              {/* <input
              type="text"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            /> */}
            </div>
          </div>

          <Button
            className="blue primary large add-fixture-referee__form__submit"
            onClick={() => {
              if (submitting || !description || !profile?.id) return;

              updateEvent({
                variables: {
                  where: {
                    id: ev.id,
                  },
                  data: {
                    roles: {
                      create: {
                        type: { connect: { name: roleType } },
                        profile: { connect: { id: profile.id } },
                        description,
                      },
                    },
                  },
                },
              });
            }}
          >
            {submitting ? <Loader theme={"small"} /> : "Submit"}
          </Button>
        </div>
      </Card>
    </div>
  );
}

function AddEventPartner({ title, ev, roleType, query, targets }) {
  const [, setAppState] = useAppState();
  const [searchTerm, setSearchTerm] = useState("");
  const [search, { data, error }] = useLazyQuery(query);
  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
  roles {
    id
    type {
      id
      name
    }
    profile {
      id
      name
      handle
          profilePhoto {
            id
            filename
          }
    }
    organization {
      id
      name
      handle
          profilePhoto {
            id
            filename
          }
    }
  }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        // cache.modify({
        // })
        setAppState({ modal: false });
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventPartners.AddEventPartner",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventPartners.AddEventPartner",
        query: "ORGANIZATION_SEARCH",
      },
    });
  }

  // const { loading, error, data } = useQuery(config.query, {
  //   variables: { id: config.id },
  // });

  // const [mutation, { loading: submitting }] = useMutation(config.mutation, {
  //   update: config.update,
  // });

  const dismiss = () => {
    setAppState({ modal: false });
  };

  const handleChange = (e) => {
    if (e.target.value) {
      // console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const profiles = targets.profiles ? data?.search?.profiles : [];
  const organizations = targets.organizations
    ? data?.search?.organizations
    : [];

  return (
    <div className="modal-form-mobile-wrapper">
      <div
        className="modal-form-mobile"
        style={{ height: "90%", display: "flex", flexDirection: "column" }}
      >
        <div className="modal-form-mobile__title">
          <div className="modal-form-mobile__title__text">{title}</div>
          <div
            className="modal-form-mobile__title__dismiss-btn"
            onClick={dismiss}
          >
            <TimesIcon style={{ stroke: "var(--dark-3)", height: "12px" }} />
          </div>
        </div>

        <div
          className="modal-form-mobile__body"
          style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
          <div className="modal-form-mobile__body__location-search">
            <div className="modal-form-mobile__body__location-search__input">
              <Input
                iconLeft={
                  <SearchIcon style={{ height: 14, stroke: "var(--dark-3)" }} />
                }
                placeholder={"Search for organizations"}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debouncedSearch(e);
                }}
                useExternalValue={true}
                value={searchTerm}
              />
            </div>

            <div
              className="modal-form-mobile__body__location-search__results"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                background: "white",
              }}
            >
              {targets.profiles &&
                profiles?.map((o) => (
                  <div
                    className="manage-event-partners-mobile__section__list__item"
                    onClick={() => {
                      if (submitting) return;
                      updateEvent({
                        variables: {
                          where: {
                            id: ev.id,
                          },
                          data: {
                            roles: {
                              create: {
                                type: { connect: { name: roleType } },
                                profile: { connect: { id: o.id } },
                              },
                            },
                          },
                        },
                      });
                    }}
                  >
                    <div className="manage-event-partners-mobile__section__list__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>

                    <div className="manage-event-partners-mobile__section__list__item__desc">
                      <div className="manage-event-partners-mobile__section__list__item__desc__primary">
                        {o.name}{" "}
                      </div>

                      <div className="manage-event-partners-mobile__section__list__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
              {targets.organizations &&
                organizations?.map((o) => (
                  <div
                    className="manage-event-partners-mobile__section__list__item"
                    onClick={() => {
                      if (submitting) return;
                      updateEvent({
                        variables: {
                          where: {
                            id: ev.id,
                          },
                          data: {
                            roles: {
                              create: {
                                type: { connect: { name: roleType } },
                                organization: { connect: { id: o.id } },
                              },
                            },
                          },
                        },
                      });
                    }}
                  >
                    <div className="manage-event-partners-mobile__section__list__item__image">
                      {o.profilePhoto && <Avatar media={o.profilePhoto} />}
                    </div>

                    <div className="manage-event-partners-mobile__section__list__item__desc">
                      <div className="manage-event-partners-mobile__section__list__item__desc__primary">
                        {o.name}{" "}
                      </div>

                      <div className="manage-event-partners-mobile__section__list__item__desc__secondary">
                        @{o.handle}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EventPartner({ role, ev, hasDescription }) {
  const [updateEvent, { loading: submitting }] = useMutation(
    UPDATE_EVENT(`
      roles {
        id
        description
        type {
          id
          name
        }
        profile {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
        organization {
          id
          name
          handle
              profilePhoto {
                id
                filename
              }
        }
      }`),
    {
      update: (cache, { data: { updateEvent } }) => {
        // refetch();
      },
      onError: (error) => {
        captureException({
          error,
          info: {
            type: "mutation",
            component: "ManageEventPartners.EventPartner",
            mutation: "UPDATE_EVENT",
          },
        });
        console.log(error);
      },
    }
  );

  return (
    <div className="manage-event-partners-mobile__section__list__item">
      <div className="manage-event-partners-mobile__section__list__item__image">
        {(role.organization?.profilePhoto || role.profile?.profilePhoto) && (
          <Avatar
            media={
              role.organization?.profilePhoto || role.profile?.profilePhoto
            }
          />
        )}
      </div>

      <div className="manage-event-partners-mobile__section__list__item__desc">
        <div className="manage-event-partners-mobile__section__list__item__desc__primary">
          {role.organization?.name || role.profile?.name}{" "}
          {!role.confirmed && "(Request sent)"}
        </div>

        <div className="manage-event-partners-mobile__section__list__item__desc__secondary">
          {hasDescription
            ? role.description
            : `@${role.organization?.handle || role.profile?.handle}`}
        </div>
      </div>

      <div
        className="manage-event-partners-mobile__section__list__item__option"
        onClick={() => {
          if (submitting) return;
          updateEvent({
            variables: {
              where: { id: ev.id },
              data: {
                roles: {
                  deleteMany: [{ id: role.id }],
                },
              },
            },
          });
        }}
      >
        <TrashIcon style={{ stroke: "var(--red-main)", height: "14px" }} />
      </div>
    </div>
  );
}

export default function ManageEventPartners({ id }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(EVENT_ROLES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventPartners",
        query: "EVENT_ROLES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const roles = ev?.roles;
  const venues = roles?.filter((r) => r.type.name === "VENUE");
  const sponsors = roles?.filter((r) => r.type.name === "SPONSOR");
  const partners = roles?.filter((r) => r.type.name === "PARTNER");
  const mediaTeam = roles?.filter((r) => r.type.name === "MEDIA STAFF");
  const operationsTeam = roles?.filter(
    (r) => r.type.name === "OPERATIONS STAFF"
  );

  return (
    <div className="manage-event-partners-mobile">
      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Venues
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPartner
                    title={"Tag a venue"}
                    roleType={"VENUE"}
                    ev={ev}
                    targets={{ profiles: false, organizations: true }}
                    query={ORGANIZATION_SEARCH}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Is your event venue on Sports Paddock? You can tag them and have the
          event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {venues?.map((r) => (
            <EventPartner role={r} ev={ev} />
          ))}
        </div>
      </div>

      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Sponsors
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPartner
                    title={"Tag a sponsor"}
                    roleType={"SPONSOR"}
                    ev={ev}
                    targets={{ profiles: false, organizations: true }}
                    query={ORGANIZATION_SEARCH}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Are your event sponsors on Sports Paddock? You can tag them and have
          the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {sponsors?.map((r) => (
            <EventPartner role={r} ev={ev} />
          ))}
        </div>
      </div>

      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Partners
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPartner
                    title={"Tag a partner"}
                    roleType={"PARTNER"}
                    ev={ev}
                    targets={{ profiles: false, organizations: true }}
                    query={ORGANIZATION_SEARCH}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Are your event partners on Sports Paddock? You can tag them and have
          the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {partners?.map((r) => (
            <EventPartner role={r} ev={ev} />
          ))}
        </div>
      </div>

      {/* <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Photographers
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPartner
                    title={"Tag a photographer"}
                    roleType={"PHOTOGRAPHER"}
                    ev={ev}
                    targets={{ profiles: true, organizations: true }}
                    query={SEARCH}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Are your event's photographers on Sports Paddock? You can tag them and
          have the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {photographers?.map((r) => (
            <EventPartner role={r} ev={ev} />
          ))}
        </div>
      </div>

      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Videographers
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPartner
                    title={"Tag a videographer"}
                    roleType={"VIDEOGRAPHER"}
                    ev={ev}
                    targets={{ profiles: true, organizations: true }}
                    query={SEARCH}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Are your event's videographers on Sports Paddock? You can tag them and
          have the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {videographers?.map((r) => (
            <EventPartner role={r} ev={ev} />
          ))}
        </div>
      </div>

      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Physios
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventPartner
                    title={"Tag a physio"}
                    roleType={"PHYSIO"}
                    ev={ev}
                    targets={{ profiles: true, organizations: true }}
                    query={SEARCH}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Are your event's physios on Sports Paddock? You can tag them and have
          the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {physios?.map((r) => (
            <EventPartner role={r} ev={ev} />
          ))}
        </div>
      </div> */}

      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Media Team
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddRoleWithDescription
                    label={"Tag media team"}
                    roleType={"MEDIA STAFF"}
                    ev={ev}
                    placeholder={"eg. Photographer, Videographer, etc.,"}
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Is your event's media team on Sports Paddock? You can tag them and
          have the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {mediaTeam?.map((r) => (
            <EventPartner role={r} ev={ev} hasDescription={true} />
          ))}
        </div>
      </div>

      <div className="manage-event-partners-mobile__section">
        <div className="manage-event-partners-mobile__section__title">
          <div className="manage-event-partners-mobile__section__title__text">
            Operations Team
          </div>

          <div
            className="manage-event-partners-mobile__section__title__option"
            onClick={() => {
              setAppState({
                modal: (
                  <AddRoleWithDescription
                    label={"Tag operations team"}
                    roleType={"OPERATIONS STAFF"}
                    ev={ev}
                    placeholder={
                      "eg. Medical Manager, Operations Manager, etc.,"
                    }
                  />
                ),
              });
            }}
          >
            Add
          </div>
        </div>

        <div className="manage-event-partners-mobile__section__desc">
          Is your event's operations team on Sports Paddock? You can tag them
          and have the event show up on their page for greater visibility.
        </div>

        <div className="manage-event-partners-mobile__section__list">
          {operationsTeam?.map((r) => (
            <EventPartner role={r} ev={ev} hasDescription={true} />
          ))}
        </div>
      </div>
    </div>
  );
}
