import { gql } from "@apollo/client";

const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($data: UserProfileUpdateInput!) {
    updateUserProfile(data: $data) {
      id
      name
      handle
      phone
      city {
        id
        type {
          id
          name
        }
        value
      }
      cities {
        id
        type {
          id
          name
        }
        value
      }
      dob
      gender
      worksInSport
      playsSport
      interests {
        id
        name
      }
      emailVerified
    }
  }
`;

export default UPDATE_USER_PROFILE;
