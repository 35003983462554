import "./ManageEventPosts.css";
import { useAppState } from "../../../utils/appState";
import { useQuery } from "@apollo/client";
import {
  EVENT_POSTS,
  EVENT_PHOTOS,
  EVENT_VIDEOS,
  EVENT_ALBUMS,
} from "../../../api/event";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import CreatePost from "../../CreatePost";
import Post from "../../../components/Post";
import Loader from "../../../components/Loader";
import captureException from "../../../utils/captureException";
import { Fragment } from "react";
import MatchReport from "../../../components/MatchReport";

export default function ManageEventPosts({ id }) {
  const [, setAppState] = useAppState();
  const { error, data, fetchMore, networkStatus } = useQuery(EVENT_POSTS, {
    variables: { id, take: 25 },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "ManageEventPosts",
        query: "EVENT_POSTS",
      },
    });
    console.log(error);
    return <div>error</div>;
  }

  if (networkStatus === 1) {
    return (
      <div
        style={{
          height: 160,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const ev = data?.event;
  const posts = ev?.posts;
  return (
    <div className="organization-posts-mobile">
      <Button
        className="primary medium blue"
        style={{ margin: "16px" }}
        onClick={() => {
          setAppState({
            modal: (
              <div className="modal-form-mobile-wrapper">
                <CreatePost
                  sourceId={id}
                  sourceType={"event"}
                  mediaQueries={{
                    photos: {
                      query: EVENT_PHOTOS,
                      getPhotos: (data) => {
                        return data?.event?.media || [];
                      },
                      getPhotosCount: (data) => {
                        return data?.event?.photosCount || 0;
                      },
                      onUploadPhoto: (cache, createMedia) => {
                        // console.log("Updating post photo cache");
                        const q = cache.readQuery({
                          query: EVENT_PHOTOS,
                          variables: { id },
                        });

                        // console.log(
                        //   "Updating post photo cache 2:",
                        //   q.organization,
                        //   createMedia
                        // );

                        cache.writeQuery({
                          query: EVENT_PHOTOS,
                          variables: { id },
                          data: {
                            event: {
                              ...q.event,
                              photosCount: q.event.photosCount + 1,
                              media: [createMedia.media, ...q.event.media],
                            },
                          },
                        });
                      },
                    },
                    videos: {
                      query: EVENT_VIDEOS,
                      getVideos: (data) => {
                        return data?.event?.media || [];
                      },
                      getVideosCount: (data) => {
                        return data?.event?.videosCount || 0;
                      },
                      onUploadVideo: (cache, createMedia) => {
                        const q = cache.readQuery({
                          query: EVENT_VIDEOS,
                          variables: { id },
                        });

                        cache.writeQuery({
                          query: EVENT_VIDEOS,
                          variables: { id },
                          data: {
                            event: {
                              ...q.event,
                              videosCount: q.event.videosCount + 1,
                              media: [createMedia.media, ...q.event.media],
                            },
                          },
                        });
                      },
                    },
                    albums: {
                      query: EVENT_ALBUMS,
                      getAlbums: (data) => {
                        return data?.event?.mediaSets || [];
                      },
                      getAlbumsCount: (data) => {
                        return data?.event?.albumsCount || 0;
                      },
                    },
                  }}
                  onCreatePost={(cache, createPost) => {
                    const q = cache.readQuery({
                      query: EVENT_POSTS,
                      variables: { id },
                    });

                    // console.log("q:", q);
                    cache.writeQuery({
                      query: EVENT_POSTS,
                      variables: { id },
                      data: {
                        event: {
                          ...q.event,
                          posts: [createPost, ...q.event.posts],
                        },
                      },
                    });

                    cache.modify({
                      id: cache.identify({
                        __typename: "InteractionSummary",
                        id,
                      }),
                      fields: {
                        posts(count) {
                          // console.log("Old posts count:", count);
                          return posts.length + 1;
                        },
                      },
                    });
                  }}
                />
              </div>
            ),
          });
        }}
      >
        Create Post
      </Button>

      {!!posts?.length && (
        <div className="organization-posts-list">
          {posts.map((post) => (
            <Fragment>
              {post.postType === "REPORT" ? (
                <MatchReport post={post} />
              ) : (
                <Post
                  key={post.id}
                  post={post}
                  queryInfo={{
                    query: EVENT_POSTS,
                    variables: { id },
                    onDeleteData: (prevData, deletePost) => {
                      if (!prevData?.event) {
                        return [];
                      }
                      return {
                        event: {
                          ...prevData.event,
                          posts: [...prevData.event.posts].filter(
                            (p) => p.id !== deletePost.id
                          ),
                        },
                      };
                    },
                    onUpdateData: (prevData, updatePost) => {
                      if (!prevData?.event) {
                        return [];
                      }

                      const postIndex = prevData.event.posts.findIndex(
                        (p) => p.id === updatePost.id
                      );

                      if (postIndex > -1) {
                        const newPosts = [...prevData.event.posts];
                        newPosts[postIndex] = updatePost;
                        // console.log("New updatedpost index:", postIndex);
                        // console.log("New posts array:", newPosts);
                        return {
                          event: {
                            ...prevData.event,
                            posts: newPosts,
                          },
                        };
                      } else {
                        return { event: { ...prevData.event } };
                      }
                    },
                  }}
                  options={["edit", "delete"]}
                />
              )}
            </Fragment>
          ))}

          <div className="show-more-container">
            <Button
              className="primary small blue show-more-button"
              onClick={() => {
                fetchMore({
                  variables: {
                    cursor: posts[posts.length - 1].id,
                  },
                });
              }}
            >
              {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
            </Button>
          </div>
        </div>
      )}

      {!posts?.length && (
        <div className="no-data">
          <div>No posts to show.</div>
        </div>
      )}
    </div>
  );
}
