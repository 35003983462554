import "./EventFixtures.css";
import { useQuery } from "@apollo/client";
import { EVENT_FIXTURES } from "../../../api/event";
import FixtureListItem from "../../FixtureListItem";
import captureException from "../../../utils/captureException";
import Placeholder from "../../../components/Placeholder";

function EventPart({ part, parts, fixtures }) {
  return (
    <div className="event-part-mobile">
      <div className="event-part-mobile__title">
        {part?.label ? makeEventPartName(part, parts) : "Ungrouped fixtures"}
      </div>

      <div className="event-part-mobile__fixtures">
        {(part ? part?.fixtures : fixtures)?.map((fixture) => (
          <FixtureListItem fixture={fixture} />
        ))}
      </div>
    </div>
  );
}

function makeEventPartName(part, parts) {
  const output = part.label;

  if (part.partOf) {
    return (
      makeEventPartName(
        parts.find((p) => p.id === part.partOf.id),
        parts
      ) +
      " - " +
      output
    );
  } else {
    return output;
  }
}

export default function EventFixtures({ id }) {
  const { loading, error, data } = useQuery(EVENT_FIXTURES, {
    variables: { id },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "EventFixtures",
        query: "EVENT_FIXTURES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="event-fixtures-mobile">
        <div className="event-part-mobile">
          <div className="event-part-mobile__title">
            <Placeholder
              style={{ height: "10px", width: "80px" }}
              pulse={true}
            />
          </div>

          <div className="event-part-mobile__fixtures">
            {[1, 2].map((fixture) => (
              <div
                key={"fixture-" + fixture}
                className="event-results-summary-mobile__list__item"
              >
                <div className="fixture-list-item-mobile">
                  <div className="fixture-list-item-mobile__teams">
                    {[1, 2].map((p) => (
                      <div className="fixture-list-item-mobile__teams__item">
                        <div className="fixture-list-item-mobile__teams__item__image"></div>

                        <div className="fixture-list-item-mobile__teams__item__name">
                          <Placeholder
                            style={{ height: "10px", width: "160px" }}
                            pulse={true}
                          />
                        </div>

                        <div className="fixture-list-item-mobile__teams__item__score"></div>
                      </div>
                    ))}
                  </div>

                  <div className="fixture-list-item-mobile__time"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const fixtures = data.event?.fixtures || [];
  const parts = data.event?.parts || [];
  const sortedParts = [...parts]
    .sort((a, b) => {
      return a.order - b.order;
    })
    .map((part) => {
      const partFixtures = fixtures?.filter((f) => f.eventPart?.id === part.id);
      let fixturesWithNoDates = [];
      let fixturesWithDates = [];
      for (let i = 0; i < partFixtures.length; i++) {
        const f = partFixtures[i];
        if (f.scheduledStart) {
          fixturesWithDates.push(f);
        } else {
          fixturesWithNoDates.push(f);
        }
      }
      const sortedFixturesWithNoDates = fixturesWithNoDates.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const sortedFixturesWithDates = fixturesWithDates.sort((a, b) => {
        return new Date(a.scheduledStart) - new Date(b.scheduledStart);
      });

      return {
        ...part,
        fixtures: [...sortedFixturesWithDates, ...sortedFixturesWithNoDates],
      };
    });

  // console.log("Fixture parts:", parts);

  return (
    <div className="event-fixtures-mobile">
      {!fixtures.length && (
        <div className="no-data">No fixtures added yet.</div>
      )}
      {sortedParts
        ?.filter((part) => part.fixtures?.length)
        .map((part) => (
          <EventPart
            key={"event-fixtures-part-" + part.id}
            part={part}
            parts={parts}
            fixtures={fixtures}
          />
        ))}

      {fixtures.find((f) => !f.eventPart) && (
        <EventPart
          part={false}
          fixtures={fixtures?.filter((f) => !f.eventPart)}
        />
      )}
    </div>
  );
}
