// V1
import { useState, Fragment, useRef, useLayoutEffect } from "react";
import "./Events.css";
import Card from "../../components/Card";
import FilterInput from "../../components/FilterInput";
import Chip from "../../components/Chip";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import PageSearchBar from "../../components/PageSearchBar";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { EventsCard, EventItem, Prompt } from "../../components/Events";
import { useQuery } from "@apollo/client";
import { EVENTS } from "../../api/event";
import { SPORTS } from "../../api/sport";
import { useAppState } from "../../utils/appState";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Placeholder from "../../components/Placeholder";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";

function ListLoading() {
  return (
    <Card className="event-item-card">
      <div className="head">
        <div className="image"></div>
        <div className="meta">
          <div className="primary">
            <Placeholder style={{ width: "160px" }} pulse={true} />
          </div>
          <div className="secondary">
            <Placeholder
              style={{ width: "64px", marginTop: "8px" }}
              pulse={true}
            />
          </div>
        </div>
        <div className="date-wrapper">
          <div className="date"></div>
        </div>
      </div>
      <div className="event-item-card-body"></div>

      <div className="foot">
        <div className="followers">
          <div className="more"></div>

          <div className="more"></div>
        </div>
        <div className="organizedBy">
          <div className="desc">
            <div className="primary">
              <Placeholder style={{ width: "40px" }} pulse={true} />
            </div>
            <div className="secondary">
              <Placeholder
                style={{ width: "24px", marginTop: "4px" }}
                pulse={true}
              />
            </div>
          </div>
          <div className={"image "}></div>
        </div>
      </div>
    </Card>
  );
}

function EventsLocationFilter({
  defaultValue,
  placeholder,
  searchOptions,
  addFilter,
  removeFilter,
  locations,
  setLocations,
}) {
  const [searchString, setSearchString] = useState("");
  const [, setAppState] = useAppState();

  const onChange = (x) => {
    setSearchString(x);
  };

  const onSelect = (address) => {
    setSearchString("");
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const city = address?.split(",")[0].trim();
        const newLocations = [
          ...locations,
          { name: city, address: address, lat: latLng.lat, lng: latLng.lng },
        ];
        setLocations(newLocations);
        addFilter(newLocations);
        setAppState({ eventLocations: newLocations });
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <div className="filter-group">
      <div className="filter-group-input">
        <PlacesAutocomplete
          value={searchString}
          onChange={onChange}
          onSelect={onSelect}
          debounce={200}
          searchOptions={
            searchOptions || {
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }
          }
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <div className="location-input-wrapper">
                <div className="app-input">
                  <div className="input-wrapper">
                    <input
                      {...getInputProps({
                        placeholder: placeholder || "Enter a location",
                        className: "location-search-input",
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="autocomplete-dropdown-container">
                {loading && (
                  <div>
                    <Spinner />
                  </div>
                )}
                {suggestions
                  .filter(
                    (suggestion) =>
                      suggestion?.description?.split(",")?.length &&
                      suggestion?.description?.split(",").length > 2
                  )
                  .map((suggestion, i) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={"suggestion-" + i}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style: {
                            padding: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            ...style,
                          },
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="filter-group-applied">
        {locations.map((t, i) => (
          <Chip
            key={"location-chip-" + i}
            onClick={() => {
              const newLocations = locations.filter((x) => x.name !== t.name);
              setLocations(newLocations);
              removeFilter(newLocations);
              setAppState({ eventLocations: newLocations });
            }}
          >
            {t.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function EventsFilter({
  placeholder,
  query,
  items,
  addFilter,
  removeFilter,
  defaultValue,
  refetch,
  appStateKey,
  sportFilters,
  setSportFilters,
}) {
  const [, setAppState] = useAppState();

  return (
    <div className="filter-group">
      <div className="filter-group-input">
        <FilterInput
          placeholder={placeholder}
          query={query}
          items={items}
          onItemSelect={(item) => {
            const newValue = [...sportFilters, item];
            setSportFilters(newValue);
            addFilter(newValue);
            setAppState({ [appStateKey]: newValue });
          }}
        />
      </div>
      <div className="filter-group-applied">
        {sportFilters.map((s) => (
          <Chip
            key={s.id}
            onClick={() => {
              const newValue = sportFilters.filter((x) => x.id !== s.id);
              setSportFilters(newValue);
              removeFilter(newValue);
              setAppState({ [appStateKey]: newValue });
            }}
          >
            {s.name} <TimesIcon style={{ stroke: "white", height: 12 }} />
          </Chip>
        ))}
      </div>
    </div>
  );
}

function EventStatusFilters({
  defaultValue,
  addFilter,
  dateFilters,
  setDateFilters,
}) {
  const [, setAppState] = useAppState();

  return (
    <div className="filter-group">
      <div className="filter-group-options">
        <div
          className={
            "filter-group-option-item " +
            (dateFilters.find((x) => x.label === "ongoing") ? "selected" : "")
          }
          onClick={() => {
            let newDateFilters;
            if (dateFilters.find((f) => f.label === "ongoing")) {
              newDateFilters = [...dateFilters].filter(
                (x) => x.label !== "ongoing"
              );
            } else {
              newDateFilters = [
                ...dateFilters,
                {
                  label: "ongoing",
                  queries: [
                    { scheduledStart: { lt: moment().toISOString() } },
                    { scheduledEnd: { gt: moment().toISOString() } },
                  ],
                },
              ];
            }
            setDateFilters(newDateFilters);

            addFilter(newDateFilters);

            setAppState({ eventDateFilters: newDateFilters });
          }}
        >
          Ongoing
        </div>

        <div
          className={
            "filter-group-option-item " +
            (dateFilters.find((x) => x.label === "not started")
              ? "selected"
              : "")
          }
          onClick={() => {
            let newDateFilters;
            // console.log("Old date filters:", dateFilters);
            if (dateFilters.find((f) => f.label === "not started")) {
              newDateFilters = [...dateFilters].filter(
                (x) => x.label !== "not started" && x.label !== "tba"
              );

              // console.log("New date filters:", newDateFilters);
            } else {
              newDateFilters = [
                ...dateFilters,
                {
                  label: "not started",
                  queries: [{ scheduledStart: { gt: moment().toISOString() } }],
                },
                {
                  label: "tba",
                  queries: [{ scheduledStart: null }],
                },
              ];
            }
            setDateFilters(newDateFilters);

            addFilter(newDateFilters);

            setAppState({ eventDateFilters: newDateFilters });
          }}
        >
          Not Started
        </div>

        <div
          className={
            "filter-group-option-item " +
            (dateFilters.find((x) => x.label === "completed") ? "selected" : "")
          }
          onClick={() => {
            let newDateFilters;
            if (dateFilters.find((f) => f.label === "completed")) {
              newDateFilters = [...dateFilters].filter(
                (x) => x.label !== "completed"
              );
            } else {
              newDateFilters = [
                ...dateFilters,
                {
                  label: "completed",
                  queries: [{ scheduledEnd: { lt: moment().toISOString() } }],
                },
              ];
            }
            setDateFilters(newDateFilters);

            addFilter(newDateFilters);

            setAppState({ eventDateFilters: newDateFilters });
          }}
        >
          Completed
        </div>
      </div>
    </div>
  );
}

function NoEventsPlaceholder({ refetch }) {
  return (
    <div className="no-data">
      <div>There are no events for this selection.</div>
      <div
        className="link hover-pointer hover-underline"
        onClick={() => {
          refetch();
        }}
      >
        Check again
      </div>
    </div>
  );
}

function Events() {
  usePageTracking({ title: "Events" });
  const [
    { eventSearch, eventSports, eventDateFilters, eventLocations },
    setAppState,
  ] = useAppState();
  const [leftSticky, setLeftSticky] = useState("sticky-top");
  const [rightSticky, setRightSticky] = useState("sticky-top");
  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);
  const [dateFilters, setDateFilters] = useState(eventDateFilters || []);
  const [sportFilters, setSportFilters] = useState(eventSports || []);
  const [locations, setLocations] = useState(eventLocations || []);

  const { error, data, networkStatus, variables, fetchMore, refetch } =
    useQuery(EVENTS, {
      variables: { where: {} },
      notifyOnNetworkStatusChange: true,
    });

  useLayoutEffect(() => {
    const elementHeight = leftColumnRef?.current?.clientHeight || 0;
    // const elementY = leftColumnRef?.current?.offsetTop || 0;
    const elementY = 80;
    const viewportHeight = window.innerHeight;

    if (elementY + elementHeight > viewportHeight) {
      setLeftSticky("sticky-bottom");
    }
  }, []);

  useLayoutEffect(() => {
    const elementHeight = rightColumnRef?.current?.clientHeight || 0;
    const elementY = rightColumnRef?.current?.offsetTop || 0;
    const viewportHeight = window.innerHeight;

    if (elementY + elementHeight > viewportHeight - 32) {
      setRightSticky("sticky-bottom");
    }
  }, []);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Events",
        query: "EVENTS",
      },
    });
    console.log(error);
    return <div />;
  }

  const onSearchSubmit = (searchTerm) => {
    refetch({
      where: {
        ...(variables.where || {}),
        name: searchTerm,
      },
    });
    setAppState({ eventSearch: searchTerm });
  };

  const events = data?.events || [];

  return (
    <div className="App-content">
      <div className="events-page">
        <div className={"left-column " + leftSticky}>
          <div className={"sticky-wrapper " + leftSticky} ref={leftColumnRef}>
            <EventsCard />
          </div>
        </div>

        <div className="middle-column">
          <PageSearchBar
            placeholder={"Search for events..."}
            onSearchSubmit={onSearchSubmit}
            defaultValue={eventSearch}
            disabled={networkStatus === 1}
          />

          <div className="card-section">
            {(networkStatus === 1 || networkStatus === 2) && (
              <Fragment>
                <ListLoading />
                <ListLoading />
                <ListLoading />
              </Fragment>
            )}
            {networkStatus !== 2 &&
              events.map((ev) => <EventItem key={ev.id} ev={ev} />)}
          </div>

          {!(networkStatus === 1 || !data) && !events?.length && (
            <NoEventsPlaceholder refetch={refetch} />
          )}

          {!!events?.length && (
            <div className="show-more-container">
              <Button
                className="primary small blue show-more-button"
                onClick={() => {
                  fetchMore({
                    variables: {
                      ...variables,
                      cursor: events[events.length - 1].id,
                    },
                  });
                }}
              >
                {networkStatus === 3 ? <Loader theme={"small"} /> : "Show More"}
              </Button>
            </div>
          )}
        </div>

        <div className={"right-column " + rightSticky}>
          <div className={"sticky-wrapper " + rightSticky} ref={rightColumnRef}>
            <Prompt />
            {networkStatus !== 1 && (
              <div className="card-section">
                <div className="card-section-title">
                  Filters
                  <div
                    className="reset hover-pointer"
                    onClick={() => {
                      refetch({ where: {} });
                      setDateFilters([]);
                      setSportFilters([]);
                      setLocations([]);
                      setAppState({
                        eventSports: [],
                        eventDateFilters: [],
                        eventLocations: [],
                      });
                    }}
                  >
                    Reset
                  </div>
                </div>

                <EventStatusFilters
                  dateFilters={dateFilters}
                  setDateFilters={setDateFilters}
                  defaultValue={eventDateFilters}
                  addFilter={(newDateFilters) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        dateFilters: [
                          ...newDateFilters.map((x) => ({
                            queries: x.queries,
                          })),
                        ],
                      },
                    });
                  }}
                />

                <EventsFilter
                  placeholder={"Enter a sport"}
                  query={SPORTS}
                  items={(d) => {
                    return d?.sports;
                  }}
                  addFilter={(sports) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        sports: sports.map((s) => s.id),
                      },
                    });
                  }}
                  removeFilter={(sports) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        sports: sports.map((s) => s.id),
                      },
                    });
                  }}
                  defaultValue={eventSports}
                  appStateKey={"eventSports"}
                  sportFilters={sportFilters}
                  setSportFilters={setSportFilters}
                />

                <EventsLocationFilter
                  defaultValue={eventLocations}
                  placeholder={"Enter a location"}
                  addFilter={(cities) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        cities: cities.map((s) => s.name),
                      },
                    });
                  }}
                  removeFilter={(cities) => {
                    refetch({
                      where: {
                        ...(variables.where || {}),
                        cities: cities.map((s) => s.name),
                      },
                    });
                  }}
                  locations={locations}
                  setLocations={setLocations}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
