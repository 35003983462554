import "./BarChart.css";
import { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";

function BarChart({ labels, datasets = [], tooltip }) {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  // const labels = [
  //   "MUFC",
  //   "CHE",
  //   "MCI",
  //   "ARS",
  //   "AST",
  //   "TOT",
  //   "EVE",
  //   "LEE",
  //   "WHU",
  //   "LEI",
  // ];

  const chartConfig = {
    type: "bar",
    data: {
      labels: labels,
      datasets: datasets,
      // [
      //   {
      //     data: [22, 19, 16, 16, 14, 10, 9, 7, 3, 1],
      //     backgroundColor: [
      //       "#a1f291",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //       "#78c9fc",
      //     ],
      //   },
      // ],
    },
    options: {
      scales: {
        // y: {
        //   beginAtZero: true,
        // },
        xAxis: {
          // ticks: { display: false },
          grid: {
            borderColor: "#ebf7ff",
            color: "#ebf7ff",
            drawTicks: false,
          },
          ticks: {
            font: {
              family: "var(--nhg-display)",
              size: 10,
              weight: 600,
            },
            // fontFamily: "var(--nhg-display)",
            // fontSize: "10px",
            // fontWeight: "700",
          },
        },
        yAxis: {
          ticks: {
            display: false,
          },
          grid: {
            borderColor: "#ebf7ff",
            color: "#ebf7ff",
            drawTicks: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        ...(tooltip
          ? {
              tooltip,
            }
          : {}),
      },
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }

      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, datasets]);

  return <canvas ref={chartContainer} />;
}

export default BarChart;
