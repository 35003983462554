import "./LandingLayout.css";
import { Switch, Route } from "react-router-dom";

import Landing from "../../screens/Landing";
import LandingNew from "../../screens/LandingNew";
import Auth from "../../screens/Auth";
import ForgotPassword from "../../screens/ForgotPassword";
import ResetPassword from "../../screens/ResetPassword";

function LandingLayout() {
  return (
    <div className="landing-layout">
      <Switch>
        <Route exact path="/">
          <LandingNew />
        </Route>

        {/* <Route exact path="/test">
          <LandingNew />
        </Route> */}

        <Route path="/auth">
          <Auth />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/reset-password/:token">
          <ResetPassword />
        </Route>
      </Switch>
    </div>
  );
}

export default LandingLayout;
