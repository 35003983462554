import { gql } from "@apollo/client";

const EVENT_VIDEOS = gql`
  query eventQuery(
    $id: ID!
    $type: String
    $take: Int
    $cursor: ID
    $filters: EventMediaFilters
  ) {
    event(id: $id) {
      id
      videosCount
      media(type: $type, take: $take, cursor: $cursor, filters: $filters) {
        id
        createdAt
        type
        subtype
        isExternal
        filename
        public
        aspectRatio
        canManageMedia
      }
    }
  }
`;

export default EVENT_VIDEOS;
