import { useState, Fragment } from "react";
import "./CreateEvent.css";
import Spinner from "../../components/Spinner";
import Row from "../../components/Row";
import Card from "../../components/Card";
import Chip from "../../components/Chip";
import Onboarding from "../../components/Onboarding";
import Dropdown from "../../components/Dropdown";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as LocationIcon } from "../../icons/pin.svg";
import { ReactComponent as IndiaFlagIcon } from "../../icons/india-flag.svg";
import { ReactComponent as TimesIcon } from "../../icons/times.svg";
import { ReactComponent as BuildingIcon } from "../../icons/building.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useQuery, useMutation } from "@apollo/client";
import { USER_ORGANIZATIONS, USER_PROFILE } from "../../api/user";
import { SPORTS } from "../../api/sport";
import EVENT_TYPES from "../../api/event/eventTypes";
import { CREATE_EVENT, EVENTS } from "../../api/event";
import { PAGES } from "../../api/profile";
import { useHistory } from "react-router-dom";
import isOnboarded from "../../utils/isOnboarded";
import Alert from "../../components/Alert";
import { useAppState } from "../../utils/appState";
import { Link } from "react-router-dom";
import moment from "moment";
import { usePageTracking } from "../../utils/usePageTracking";
import captureException from "../../utils/captureException";
import DebouncedButton from "../../components/Button/DebouncedButton";
import Loader from "../../components/Loader";

function EventDatesForm({ setStep, vars, setVars }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const history = useHistory();

  const [createEvent, { loading }] = useMutation(CREATE_EVENT, {
    update: (cache, { data: { createEvent } }) => {
      const q = cache.readQuery({
        query: EVENTS,
        variables: { where: {} },
      });

      if (q?.events) {
        cache.writeQuery({
          query: EVENTS,
          variables: { where: {} },
          data: {
            events: [createEvent, ...q.events],
          },
        });
      }
      history.push("/app/event/" + createEvent.id);
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "CreateEvent.EventDatesForm",
          mutation: "CREATE_EVENT",
        },
      });
      console.log(error);
    },
  });

  return (
    <div className="onboarding-form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // console.log(vars);
          if (loading) {
            return;
          }

          const v = {
            data: {
              name: vars.name,
              city: vars.city,
              state: vars.state,
              country: vars.country,
            },
          };

          if (vars.lat) {
            v.data.lat = vars.lat;
          }

          if (vars.lng) {
            v.data.lng = vars.lng;
          }

          if (vars.organizedBy) {
            v.data.organizedBy = { connect: { id: vars.organizedBy.id } };
          }

          if (vars.sport) {
            v.data.sport = { connect: { id: vars.sport.id } };
          }

          if (vars.type) {
            v.data.type = { connect: { id: vars.type.id } };
          }

          if (vars.participantType) {
            v.data.participantType = vars.participantType.value;
          }

          if (startDate) {
            v.data.scheduledStart = moment(startDate).toISOString();
          }

          if (endDate) {
            v.data.scheduledEnd = moment(endDate).toISOString();
          }

          if (registrationStartDate) {
            v.data.registrationStart = moment(
              registrationStartDate
            ).toISOString();
          }

          if (registrationEndDate) {
            v.data.registrationEnd = moment(registrationEndDate).toISOString();
          }

          // console.log(v);
          // return;

          createEvent({
            variables: v,
          });

          // if (vars.email) {
          //   v.data.email = vars.email;
          // }

          // if (vars.lat) {
          //   v.data.lat = vars.lat;
          // }

          // if (vars.lng) {
          //   v.data.lng = vars.lng;
          // }

          // if (selectedCategories.length) {
          //   v.data.types = {
          //     connect: selectedCategories.map((i) => ({ id: i.id })),
          //   };
          // }

          // if (interests.length) {
          //   v.data.interests = {
          //     connect: interests.map((i) => ({ name: i.name })),
          //   };
          // }

          // // console.log(v);
          // // return;

          // createOrganization({
          //   variables: v,
          // });
          // if (!name || !location) {
          //   if (!name) {
          //     setNameError(true);
          //   }

          //   if (!location) {
          //     setLocationError(true);
          //   }

          //   return;
          // }
          // setStep(1);
        }}
      >
        <Row columns={24} gap={16}>
          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 25 }}
          >
            <input
              type="date"
              id="startDate"
              value={startDate}
              className={"input " + (startDate ? "hasValue " : " ")}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              style={{ paddingLeft: 0 }}
            />
            <label for="startDate" style={{ left: 0 }}>
              Event Start Date
            </label>
          </div>

          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 25 }}
          >
            <input
              type="date"
              id="endDate"
              value={endDate}
              className={"input " + (endDate ? "hasValue " : " ")}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              style={{ paddingLeft: 0 }}
            />
            <label for="dob" style={{ left: 0 }}>
              Event End Date
            </label>
          </div>
        </Row>

        <Row columns={24} gap={16}>
          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 25 }}
          >
            <input
              type="date"
              id="regStartDate"
              value={registrationStartDate}
              className={"input " + (registrationStartDate ? "hasValue " : " ")}
              onChange={(e) => {
                setRegistrationStartDate(e.target.value);
              }}
              style={{ paddingLeft: 0 }}
            />
            <label for="regStartDate" style={{ left: 0 }}>
              Registration Start Date
            </label>
          </div>

          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 25 }}
          >
            <input
              type="date"
              id="regEndDate"
              value={registrationEndDate}
              className={"input " + (registrationEndDate ? "hasValue " : " ")}
              onChange={(e) => {
                setRegistrationEndDate(e.target.value);
              }}
              style={{ paddingLeft: 0 }}
            />
            <label for="dob" style={{ left: 0 }}>
              Registration End Date
            </label>
          </div>
        </Row>

        <div className="options-group">
          <DebouncedButton
            type="submit"
            className="app-button primary large blue submit"
            loading={loading}
            // style={errorMessage ? { marginTop: "24px" } : {}}
          >
            {loading ? <Loader theme="small" /> : "Finish"}
          </DebouncedButton>
          {/* <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Finish{" "}
          </button> */}
        </div>
      </form>
    </div>
  );
}

function BasicInfoForm({ setStep, setVars, vars }) {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [nameError, setNameError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [organizedBy, setOrganizedBy] = useState(false);
  const [organizedByError, setOrganizedByError] = useState(false);

  const { loading, error, data } = useQuery(USER_ORGANIZATIONS);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "CreateEvent.BasicInfoForm",
        query: "USER_ORGANIZATIONS",
      },
    });
    console.log(error);
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const orgs = data?.userOrganizations?.filter((o) => o.isAdmin) || [];
  // console.log(orgs);

  return (
    <div className="onboarding-form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!name || !selectedCity || !selectedCountry || !organizedBy) {
            if (!name) {
              setNameError(true);
            }

            if (!organizedBy) {
              setOrganizedByError(true);
            }

            if (!selectedCity || !selectedCountry) {
              setLocationError(true);
            }

            return;
          }
          setStep(1);
        }}
      >
        <div className="form-group">
          <input
            type="text"
            id="username"
            value={name}
            className={
              "input " +
              (name ? "hasValue " : " ") +
              (nameError ? "invalid" : "")
            }
            onChange={(e) => {
              setName(e.target.value);
              setVars({
                ...vars,
                name: e.target.value,
              });
            }}
            onFocus={() => {
              setNameError(false);
            }}
            autoComplete={"off"}
          />
          <label for="username">
            Enter the name of your event{" "}
            <span className="input-required-indicator">*</span>
          </label>
          <div className="static-icon">
            <CalendarIcon
              style={{ stroke: "var(--light-4)", height: "16px" }}
            />
          </div>
        </div>

        <div
          className="form-group"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            // zIndex: 11,
          }}
        >
          <Dropdown
            style={{ width: "100%" }}
            className="organized-by-dropdown"
            onOpen={() => {
              setOrganizedByError(false);
            }}
            target={
              <Fragment>
                <input
                  type="text"
                  id="organized-by"
                  value={organizedBy?.name || ""}
                  className={
                    "input " +
                    (organizedBy ? "hasValue " : " ") +
                    (organizedByError ? "invalid" : "")
                  }
                  onChange={(e) => {
                    // setOrganizedBy(e.target.value);
                    // setVars({
                    //   ...vars,
                    //   name: e.target.value,
                    // });
                  }}
                  onFocus={() => {
                    setOrganizedByError(false);
                  }}
                  autoComplete={"off"}
                />
                <label for="username">
                  Event Organizer{" "}
                  <span className="input-required-indicator">*</span>
                </label>
                <div className="static-icon">
                  <BuildingIcon
                    style={{ fill: "var(--light-4)", height: "16px" }}
                  />
                </div>
              </Fragment>
            }
            menu={({ setShowMenu }) => (
              <Card className="organizers-menu">
                {loading && <Spinner />}
                {orgs.map((o) => (
                  <div
                    className="organizers-menu-item"
                    key={o.organization.id}
                    onClick={() => {
                      setOrganizedBy(o.organization);
                      setVars({
                        ...vars,
                        organizedBy: o.organization,
                      });
                      setShowMenu(false);
                    }}
                  >
                    {o.organization.name}
                  </div>
                ))}
              </Card>
            )}
          />
          {organizedBy && (
            <div
              className="dismiss-icon"
              onClick={() => {
                setOrganizedBy(false);
                setVars({
                  ...vars,
                  organizedBy: false,
                });
              }}
            >
              <TimesIcon
                style={{ stroke: "var(--red-main)", height: "14px" }}
              />
            </div>
          )}
        </div>

        <div
          className="form-group"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            //  zIndex: 10
          }}
        >
          <PlacesAutocomplete
            value={location}
            onChange={(x) => {
              setLocation(x);
            }}
            onSelect={(loc) => {
              const city = loc?.split(",")[0]?.trim();
              const state = loc?.split(",")[1]?.trim();
              const country = loc?.split(",")[2]?.trim();
              setSelectedCity(city);
              setSelectedState(state);
              setSelectedCountry(country);
              geocodeByAddress(loc)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                  setVars({
                    ...vars,
                    city,
                    state,
                    country,
                    lat: latLng.lat,
                    lng: latLng.lng,
                  });
                })
                .catch((error) => console.error("Error", error));
              setLocation("");
            }}
            debounce={200}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: "IN",
              },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Dropdown
                className="dropdown-input-wrapper"
                placement="bottom-start"
                style={{ width: "100%" }}
                target={
                  <div
                    className={
                      "dropdown-input-wrapper__multi-select-container " +
                      (locationError ? "invalid" : "")
                    }
                  >
                    <div className="static-icon">
                      <LocationIcon
                        style={{ stroke: "var(--light-4)", height: 14 }}
                      />
                    </div>
                    <div className="dropdown-input-wrapper__multi-select-container__inputs-wrapper">
                      {selectedCity && (
                        <Chip
                          className="multi-select-item"
                          onClick={() => {
                            setSelectedCity("");
                            setSelectedState("");
                            setSelectedCountry("");

                            setVars({
                              ...vars,
                              city: "",
                              state: "",
                              country: "",
                              lat: "",
                              lng: "",
                            });
                          }}
                        >
                          <span className="multi-select-item__text">
                            {selectedCity}
                          </span>{" "}
                          <TimesIcon
                            style={{ stroke: "var(--light-1)", height: "14px" }}
                          />{" "}
                        </Chip>
                      )}

                      <input
                        {...getInputProps({
                          className:
                            "input " +
                            (location || selectedCity ? "hasValue " : " ") +
                            (locationError ? "invalid " : ""),
                          onFocus: () => {
                            setLocationError(false);
                          },
                          autocomplete: "off",
                        })}
                        value={location}
                        id="location"
                        {...(selectedCity ? { disabled: "disabled" } : {})}
                      />

                      {/* </div> */}

                      <label for="location">
                        Location{" "}
                        <span className="input-required-indicator">*</span>
                      </label>
                    </div>

                    <div
                      className="static-icon"
                      style={{ right: 0, left: "auto", bottom: 10 }}
                    >
                      <IndiaFlagIcon />
                    </div>
                  </div>
                }
                menu={({ setShowMenu }) => (
                  <Card className="dropdown-input-wrapper-menu">
                    {loading && <Spinner />}
                    {suggestions
                      .filter(
                        (suggestion) =>
                          suggestion?.description?.split(",")?.length &&
                          suggestion?.description?.split(",").length > 2
                      )
                      .map((suggestion, i) => (
                        <div
                          key={"city-suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className: "city-item",
                          })}
                        >
                          {suggestion.description}
                          {/*<span className="state">{city.within.value}</span>*/}
                        </div>
                      ))}
                  </Card>
                )}
                onClose={() => {
                  setLocation("");
                }}
              />
            )}
          </PlacesAutocomplete>
        </div>

        <div className="options-group">
          <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Next{" "}
          </button>
        </div>
      </form>
    </div>
  );
}

function EventDetailsForm({ setStep, vars, setVars }) {
  const [eventType, setEventType] = useState("");
  const [eventTypeError, setEventTypeError] = useState("");
  const [sport, setSport] = useState("");
  const [sportError, setSportError] = useState("");
  const [participantType, setParticipantType] = useState("");
  const [participantTypeError, setParticipantTypeError] = useState("");

  const sportsQuery = useQuery(SPORTS);
  const eventTypesQuery = useQuery(EVENT_TYPES);

  if (sportsQuery.error) {
    captureException({
      error: sportsQuery.error,
      info: {
        type: "query",
        component: "CreateEvent.EventDetailsForm",
        query: "SPORTS",
      },
    });
  }

  if (eventTypesQuery.error) {
    captureException({
      error: eventTypesQuery.error,
      info: {
        type: "query",
        component: "CreateEvent.EventDetailsForm",
        query: "EVENT_TYPES",
      },
    });
  }

  const sports = sportsQuery?.data?.sports || [];
  const eventTypes = eventTypesQuery?.data?.eventTypes || [];
  const participantTypes = [
    { label: "Teams", value: "TEAM" },
    { label: "Individuals", value: "INDIVIDUAL" },
  ];

  // console.log(sports);

  return (
    <div className="onboarding-form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!sport || !eventType || !participantType) {
            if (!sport) {
              setSportError(true);
            }

            if (!eventType) {
              setEventTypeError(true);
            }

            if (!participantType) {
              setParticipantTypeError(true);
            }

            return;
          }

          setStep(2);
        }}
      >
        <div
          className="form-group"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            zIndex: 11,
          }}
        >
          <Dropdown
            style={{ width: "100%" }}
            className="organized-by-dropdown"
            placement={"bottom-start"}
            onOpen={() => {
              setSportError(false);
            }}
            target={
              <Fragment>
                <input
                  type="text"
                  id="sport"
                  value={sport.name || ""}
                  className={
                    "input " +
                    (sport ? "hasValue " : " ") +
                    (sportError ? "invalid" : "")
                  }
                  onChange={(e) => {
                    // setOrganizedBy(e.target.value);
                    // setVars({
                    //   ...vars,
                    //   name: e.target.value,
                    // });
                  }}
                  onFocus={() => {
                    setSportError(false);
                  }}
                  autoComplete={"off"}
                />
                <label for="username">
                  Sport <span className="input-required-indicator">*</span>
                </label>
                <div className="static-icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-4)", height: "16px" }}
                  />
                </div>
              </Fragment>
            }
            menu={({ setShowMenu }) => (
              <Card className="organizers-menu">
                {sportsQuery.loading && <Spinner />}
                {sports
                  .filter((s) => s.name !== "Basketball")
                  .map((s) => (
                    <div
                      className="organizers-menu-item"
                      key={s.id}
                      onClick={() => {
                        // console.log("SS:", s);
                        // console.log("SS PT:", s.participantType);

                        const sportParticipantType = s.participantType || false;
                        const formattedParticipantType =
                          sportParticipantType &&
                          participantTypes.find(
                            (x) => x.value === sportParticipantType
                          );

                        setSport(s);
                        if (formattedParticipantType) {
                          setParticipantType(formattedParticipantType);
                        }

                        setVars({
                          ...vars,
                          sport: s,
                          ...(formattedParticipantType
                            ? { participantType: formattedParticipantType }
                            : {}),
                        });
                        setShowMenu(false);
                      }}
                    >
                      {s.name}
                    </div>
                  ))}
              </Card>
            )}
          />
          {sport && (
            <div
              className="dismiss-icon"
              onClick={() => {
                setSport(false);
                setVars({
                  ...vars,
                  sport: false,
                });
              }}
            >
              <TimesIcon
                style={{ stroke: "var(--red-main)", height: "14px" }}
              />
            </div>
          )}
        </div>

        <div
          className="form-group"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            zIndex: 9,
          }}
        >
          <Dropdown
            style={{ width: "100%" }}
            className="organized-by-dropdown"
            onOpen={() => {
              setEventTypeError(false);
            }}
            target={
              <Fragment>
                <input
                  type="text"
                  id="event-type"
                  value={eventType?.name || ""}
                  className={
                    "input " +
                    (eventType ? "hasValue " : " ") +
                    (eventTypeError ? "invalid" : "")
                  }
                  onChange={(e) => {
                    // setOrganizedBy(e.target.value);
                    // setVars({
                    //   ...vars,
                    //   name: e.target.value,
                    // });
                  }}
                  onFocus={() => {
                    // setEventTypeError(false);
                  }}
                  autoComplete={"off"}
                />
                <label for="username">
                  Event Type <span className="input-required-indicator">*</span>
                </label>
                <div className="static-icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-4)", height: "16px" }}
                  />
                </div>
              </Fragment>
            }
            menu={({ setShowMenu }) => (
              <Card className="organizers-menu">
                {eventTypesQuery.loading && <Spinner />}
                {eventTypes.map((o) => (
                  <div
                    className="organizers-menu-item"
                    key={o.name}
                    onClick={() => {
                      setEventType(o);
                      setVars({
                        ...vars,
                        type: o,
                      });
                      setShowMenu(false);
                    }}
                  >
                    {o.name}
                  </div>
                ))}
              </Card>
            )}
          />
          {eventType && (
            <div
              className="dismiss-icon"
              onClick={() => {
                setEventType(false);
                setVars({
                  ...vars,
                  type: false,
                });
              }}
            >
              <TimesIcon
                style={{ stroke: "var(--red-main)", height: "14px" }}
              />
            </div>
          )}
        </div>

        <div
          className="form-group"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
          }}
        >
          <Dropdown
            style={{ width: "100%", zIndex: 8 }}
            className="organized-by-dropdown"
            onOpen={() => {
              setParticipantTypeError(false);
            }}
            target={
              <Fragment>
                <input
                  type="text"
                  id="participant-type"
                  value={participantType.label || ""}
                  className={
                    "input " +
                    (participantType ? "hasValue " : " ") +
                    (participantTypeError ? "invalid" : "")
                  }
                  onChange={(e) => {
                    // setOrganizedBy(e.target.value);
                    // setVars({
                    //   ...vars,
                    //   name: e.target.value,
                    // });
                  }}
                  onFocus={() => {
                    // setNameError(false);
                  }}
                  autoComplete={"off"}
                />
                <label for="username">
                  Participant Type{" "}
                  <span className="input-required-indicator">*</span>
                </label>
                <div className="static-icon">
                  <CalendarIcon
                    style={{ stroke: "var(--light-4)", height: "16px" }}
                  />
                </div>
              </Fragment>
            }
            menu={({ setShowMenu }) => (
              <Card className="organizers-menu">
                {participantTypes.map((pt) => (
                  <div
                    key={pt.label}
                    className="organizers-menu-item"
                    onClick={() => {
                      setParticipantType(pt);
                      setVars({
                        ...vars,
                        participantType: pt,
                      });
                      setShowMenu(false);
                    }}
                  >
                    {pt.label}
                  </div>
                ))}
              </Card>
            )}
          />
          {participantType && (
            <div
              className="dismiss-icon"
              onClick={() => {
                setParticipantType(false);
                setVars({
                  ...vars,
                  participantType: false,
                });
              }}
            >
              <TimesIcon
                style={{ stroke: "var(--red-main)", height: "14px" }}
              />
            </div>
          )}
        </div>

        <div className="options-group">
          <button
            type="submit"
            className="app-button primary blue large submit-button"
          >
            Next{" "}
          </button>
        </div>
      </form>
    </div>
  );
}

function CreateEventFormPagesWrapper({ id, step, setStep, vars, setVars }) {
  const { loading, error, data } = useQuery(PAGES, { variables: { id } });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "CreateEvent.CreateEventFormPagesWrapper",
        query: "PAGES",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const pages = data?.pages.filter((p) => p.isAdmin);

  if (!pages || !pages.length) {
    return (
      <div className="auth-alert">
        <Alert
          theme="red"
          head="No event organizers detected"
          body={
            <div>
              <div>
                Every event must have an event organizer who is registered in
                the directory. You are currently not an admin of any registered
                organizations.
              </div>
              <div>
                You can either{" "}
                <Link to="/app/create_page" className="auth-alert-link">
                  create one
                </Link>{" "}
                or contact an admin of an existing organization to add you as an
                admin.
              </div>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <Fragment>
      {!step && (
        <BasicInfoForm setStep={setStep} setVars={setVars} vars={vars} />
      )}
      {step === 1 && (
        <EventDetailsForm setStep={setStep} vars={vars} setVars={setVars} />
      )}
      {step === 2 && <EventDatesForm setStep={setStep} vars={vars} />}
    </Fragment>
  );
}

function CreateEventFormOnboardingWrapper({ step, setStep, vars, setVars }) {
  const [, setAppState] = useAppState();
  const { loading, data, error } = useQuery(USER_PROFILE);

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "CreateEvent.CreateEventFormOnboardingWrapper",
        query: "USER_PROFILE",
      },
    });
    console.log(error);
    return <div className="">Error</div>;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const profile = data?.userProfile;
  const onboarded = isOnboarded(profile);

  if (!onboarded) {
    return (
      <div className="auth-alert">
        <Alert
          theme="red"
          head="Your profile is incomplete"
          body={
            <div>
              <div>
                You must complete the entire onboarding process to create events
                and pages.
              </div>
              <div
                onClick={() => {
                  setAppState({ modal: <Onboarding /> });
                }}
                className="auth-alert-link"
              >
                Click here to complete onboarding
              </div>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <CreateEventFormPagesWrapper
      step={step}
      setStep={setStep}
      vars={vars}
      setVars={setVars}
      id={profile.id}
    />
  );
}

function CreateEvent() {
  usePageTracking({ title: "Create Event" });
  // 1. name, location, organized by, startdate, enddate, 2. sport, type, participant type
  const [step, setStep] = useState(0);
  const [vars, setVars] = useState({});
  const [{ isLoggedIn }] = useAppState();

  // console.log("Vars:", vars);

  return (
    <div className="create-page">
      <Card className="create-page-card">
        <div className="content-wrapper">
          <div className="page-title">{vars.name || "New Event"}</div>

          <div className="page-subtitle">
            Let's get your event setup for you...
          </div>

          <div className="create-page-form-container">
            {!isLoggedIn ? (
              <div className="auth-alert">
                <Alert
                  theme="red"
                  head="You are not logged in"
                  body={
                    <div>
                      <div>
                        You must be logged in and verified to create events and
                        pages.
                      </div>
                      <Link to="/auth" className="auth-alert-link">
                        Click here to login
                      </Link>
                    </div>
                  }
                />
              </div>
            ) : (
              <CreateEventFormOnboardingWrapper
                step={step}
                setStep={setStep}
                vars={vars}
                setVars={setVars}
              />
            )}
          </div>
        </div>

        <div className="graphic-wrapper"></div>
      </Card>
    </div>
  );
}

export default CreateEvent;
