import { gql } from "@apollo/client";

const USER_ORGANIZATIONS = gql`
  query userOrganizations {
    userOrganizations {
      id
      isAdmin
      role
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default USER_ORGANIZATIONS;
