// V1
import { Fragment, useState } from "react";
import "./EventCalendar.css";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right.svg";
import moment from "moment";
import Placeholder from "../Placeholder";

function EventCalendar({
  onNextClick = () => {},
  onPrevClick = () => {},
  onDateSelect = () => {},
  defaultDate,
  fixtures = [],
  loading,
}) {
  const [selectedDate, setSelectedDate] = useState(
    defaultDate || moment().toISOString()
  );
  const startOfMonth = moment(selectedDate).startOf("month");
  const startOfMonthDay = startOfMonth.day();
  const daysInMonth = moment(selectedDate).daysInMonth();

  const weeks = new Array(daysInMonth + startOfMonthDay)
    .fill()
    .map((_, i) => {
      if (i < startOfMonthDay) {
        return 0;
      } else {
        return i + 1 - startOfMonthDay;
      }
    })
    .reduce((accum, curr, i) => {
      const idx = Math.floor(i / 7);
      if (!accum[idx]) {
        accum[idx] = [];
      }

      accum[idx].push(curr);
      return accum;
    }, []);

  const prev = () => {
    const newDate = moment(selectedDate)
      .startOf("month")
      .subtract(1, "d")
      .toISOString();
    setSelectedDate(newDate);
    onPrevClick(newDate);
  };

  const next = () => {
    const newDate = moment(selectedDate)
      .endOf("month")
      .add(1, "d")
      .toISOString();
    setSelectedDate(newDate);
    onNextClick(newDate);
  };

  const hasEvent = (day) => {
    const monthStart = moment(selectedDate).startOf("month").toISOString();
    if (!day) {
      return false;
    } else {
      if (
        fixtures.filter((f) => {
          if (
            moment(monthStart)
              .add(day - 1, "d")
              .startOf("day")
              .isBefore(f.scheduledStart) &&
            moment(monthStart)
              .add(day - 1, "d")
              .endOf("day")
              .isAfter(f.scheduledStart)
          ) {
            return true;
          } else {
            return false;
          }
        }).length
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const hasUserFixture = (day) => {
    const monthStart = moment(selectedDate).startOf("month").toISOString();
    if (!day) {
      return false;
    } else {
      if (
        fixtures
          .filter((f) => {
            if (
              moment(monthStart)
                .add(day - 1, "d")
                .startOf("day")
                .isBefore(f.scheduledStart) &&
              moment(monthStart)
                .add(day - 1, "d")
                .endOf("day")
                .isAfter(f.scheduledStart)
            ) {
              return true;
            } else {
              return false;
            }
          })
          .filter((f) => f.isUserFixture).length
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  if (loading) {
    return (
      <Fragment>
        <div className="app-events-calendar-header">
          <div className="app-events-calendar-title">
            <Placeholder
              style={{
                height: "12px",
                width: "80px",
              }}
              pulse={true}
            />{" "}
          </div>

          <div className="app-events-calendar-scroll">
            <Placeholder
              style={{
                height: "12px",
                width: "80px",
              }}
              pulse={true}
            />
          </div>
        </div>

        <div className="app-events-calendar-calendar">
          <div className="calendar-header">
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
            <div className="calendar-header-item">
              <Placeholder
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: "var(--blue-subtle)",
                }}
                pulse={false}
              />
            </div>
          </div>

          <div className="calendar-body">
            {weeks.map((week, i) => (
              <div key={"week-" + i} className="calendar-row">
                {week.map((day, j) => (
                  <div key={"day-" + j} className={"calendar-row-item "}>
                    <div className={"calendar-row-item-data hover-pointer  "}>
                      <Placeholder
                        style={{
                          height: "20px",
                          width: "20px",
                          backgroundColor: "var(--light-2)",
                        }}
                        pulse={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  }

  // console.log("WEEKS:", weeks);

  return (
    <Fragment>
      <div className="app-events-calendar-header">
        <div className="app-events-calendar-title">Events Near You</div>

        <div className="app-events-calendar-scroll">
          <div className="prev hover-pointer" onClick={prev}>
            <ChevronLeftIcon
              style={{
                height: 16,
                stroke: "var(--blue-main)",
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="current">
            {moment(selectedDate).format("MMM YYYY")}
          </div>
          <div className="next hover-pointer" onClick={next}>
            <ChevronRightIcon
              style={{
                height: 16,
                stroke: "var(--blue-main)",
                pointerEvents: "none",
              }}
            />
          </div>
        </div>
      </div>

      <div className="app-events-calendar-calendar">
        <div className="calendar-header">
          <div className="calendar-header-item">S</div>
          <div className="calendar-header-item">M</div>
          <div className="calendar-header-item">T</div>
          <div className="calendar-header-item">W</div>
          <div className="calendar-header-item">T</div>
          <div className="calendar-header-item">F</div>
          <div className="calendar-header-item">S</div>
        </div>

        <div className="calendar-body">
          {weeks.map((week, i) => (
            <div key={"week-" + i} className="calendar-row">
              {week.map((day, j) => (
                <div key={"day-" + j} className={"calendar-row-item "}>
                  <div
                    className={
                      "calendar-row-item-data hover-pointer  " +
                      (hasUserFixture(day) ? "highlighted " : "") +
                      (moment(selectedDate).date() === day ? "selected" : "")
                    }
                    onClick={() => {
                      if (!day) return;
                      const newDate = moment(selectedDate)
                        .date(day)
                        .toISOString();
                      setSelectedDate(newDate);
                      onDateSelect(newDate);
                    }}
                  >
                    {day || ""}{" "}
                    {hasEvent(day) && <div className="event-indicator" />}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default EventCalendar;
