import { gql } from "@apollo/client";

const CREATE_EVENT = gql`
  mutation createEvent($data: CreateEventInput!) {
    createEvent(data: $data) {
      id
    }
  }
`;

export default CREATE_EVENT;
