import { gql } from "@apollo/client";

const ORGANIZATION_SEARCH = gql`
  query search($term: String!) {
    search(term: $term) {
      organizations {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default ORGANIZATION_SEARCH;
