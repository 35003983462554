import { gql } from "@apollo/client";

const UNSAVE_POST = gql`
  mutation unsavePost($id: ID!) {
    unsavePost(id: $id) {
      id
    }
  }
`;

export default UNSAVE_POST;
